App.Controllers.Base.BaseController = require('./base/base_controller.coffee')

module.exports = class App.Controllers.RestrictedEvopayOrdersController extends App.Controllers.Base.BaseController

  routeIndex: ($container) ->
    @displayIndex({
      $container
    })

  displayIndex: (options) ->
    {
      $container
    } = options
    _$container = $container

    _paymentsCollection = new App.Collections.V3.RestrictedEvopayOrders(null, {
        mapping: C.Mappings.Direct.RestrictedEvopayOrders.Payments
      }
    )

    _evopaymentsView = new App.Views.RestrictedEvopayOrders.PaymentsTable({
      $container: _$container
      collection: _paymentsCollection
    })
