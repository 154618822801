module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<%\nvar isBlock = (title) ? \'alert-block\' : \'\';\nvar alertType = \'alert-\' + type;\n%>\n<div class="alert <%= isBlock %> <%= alertType %>" style="\nmargin-bottom: 0px;\n-webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, .9);\nbox-shadow: 0px 0px 4px 0px rgba(0, 0, 0, .8);">\n  <button type="button" class="close" data-dismiss="alert">&times;</button>\n<%\nif (title) {\n%>\n  <h4><%= title %></h4>\n<%\n}\n%>\n  <%= message %>\n</div>', __filename = "js/views/shared/message.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            var isBlock = title ? "alert-block" : "";
            var alertType = "alert-" + type;
            __line = 4;
            __append('\n<div class="alert ');
            __line = 5;
            __append(escapeFn(isBlock));
            __append(" ");
            __append(escapeFn(alertType));
            __append('" style="\nmargin-bottom: 0px;\n-webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, .9);\nbox-shadow: 0px 0px 4px 0px rgba(0, 0, 0, .8);">\n  <button type="button" class="close" data-dismiss="alert">&times;</button>\n');
            __line = 10;
            if (title) {
                __line = 12;
                __append("\n  <h4>");
                __line = 13;
                __append(escapeFn(title));
                __append("</h4>\n");
                __line = 14;
            }
            __line = 16;
            __append("\n  ");
            __line = 17;
            __append(escapeFn(message));
            __append("\n</div>");
            __line = 18;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}