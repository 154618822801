App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
# Use backbone-validation.
_.extend(parent, Backbone.Validation.mixin)
module.exports = App.Models.V3.Address = App.Models.Base.V3BaseModel.extend

  urlRoot: () ->
    if (!@clientId)
      @clientId = @collection.clientId
    # We're hackily saving a single model though the collection endpoint.
    return "/api_direct/v9/clients/#{ @clientId }/addresses"

  initialize: (attributes, options = {}) ->
    {
      @clientId
    } = options
    parent.initialize.call(@, attributes, options)

  validation:
    _streetAddress:
      required: true
    _locality:
      required: true
    _postalCode:
      required: true
    _region:
      required: true
    _countryCode:
      required: true
  labels: # Humanization
    _streetAddress: 'Street address'
    _locality: 'City'
    _postalCode: 'Zip'
    _region: 'State'
    _countryCode: 'Country'
    _crossStreet: 'Cross Street'

  parse: (response, options) ->
    # We're hackily saving a single model though the collection endpoint.
    if (response.addresses && response.addresses.length)
      return response.addresses[0]
    else
      return response

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.Order.PaymentsCreditCardAddress)
      attrs._countryCode = json.country_code
      attrs._extendedAddress = json.extended_address
      attrs._id = json.id
      attrs._isPoBox = json.po_box
      attrs._isPrimary = json.primary
      attrs._label = json.label
      attrs._latitude = json.latitude
      attrs._locality = json.locality
      attrs._longitude = json.longitude
      attrs._postalCode = json.postal_code
      attrs._region = json.region
      attrs._streetAddress = json.street_address
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.TicketCosts.PatronAddress)
      attrs._countryCode = json.country_code
      attrs._extendedAddress = json.extended_address
      attrs._id = json.id
      attrs._isPoBox = json.po_box
      attrs._isPrimary = json.primary
      attrs._label = json.label
      attrs._latitude = json.latitude
      attrs._locality = json.locality
      attrs._longitude = json.longitude
      attrs._postalCode = json.postal_code
      attrs._region = json.region
      attrs._streetAddress = json.street_address
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.FilteredTickets.Address)
      attrs._locality = json.locality
      attrs._region = json.region
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.EvoQuotes.Address)
      attrs._countryCode = json.country_code
      attrs._extended = json.extended_address
      attrs._id = json.id
      attrs._isPoBox = json.po_box
      attrs._isPrimary = json.primary
      attrs._label = json.label
      attrs._latitude = json.latitude
      attrs._locality = json.locality
      attrs._longitude = json.longitude
      attrs._postalCode = json.postal_code
      attrs._region = json.region
      attrs._streetAddress = json.street_address
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.CreditMemos.CreditCardAddress)
      attrs._countryCode = json.country_code
      attrs._extendedAddress = json.extended_address
      attrs._id = json.id
      attrs._label = json.label
      attrs._latitude = json.latitude
      attrs._locality = json.locality
      attrs._longitude = json.longitude
      attrs._isPOBox = json.po_box
      attrs._postalCode = json.postal_code
      attrs._isPrimary = json.primary
      attrs._region = json.region
      attrs._streetAddress = json.street_address
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.CreditMemos.HolderAddress)
      attrs._countryCode = json.country_code
      attrs._extendedAddress = json.extended_address
      attrs._id = json.id
      attrs._label = json.label
      attrs._latitude = json.latitude
      attrs._locality = json.locality
      attrs._longitude = json.longitude
      attrs._isPOBox = json.po_box
      attrs._postalCode = json.postal_code
      attrs._isPrimary = json.primary
      attrs._region = json.region
      attrs._streetAddress = json.street_address

    if (@getMapping() == C.Mappings.Direct.CreditMemos.OwnerAddress)
      attrs._countryCode = json.country_code
      attrs._extendedAddress = json.extended_address
      attrs._id = json.id
      attrs._label = json.label
      attrs._latitude = json.latitude
      attrs._locality = json.locality
      attrs._longitude = json.longitude
      attrs._isPOBox = json.po_box
      attrs._postalCode = json.postal_code
      attrs._isPrimary = json.primary
      attrs._region = json.region
      attrs._streetAddress = json.street_address

    if (@getMapping() == C.Mappings.Direct.CreditMemo.HolderAddress)
      attrs._countryCode = json.country_code
      attrs._extendedAddress = json.extended_address
      attrs._id = json.id
      attrs._label = json.label
      attrs._latitude = json.latitude
      attrs._locality = json.locality
      attrs._longitude = json.longitude
      attrs._isPOBox = json.po_box
      attrs._postalCode = json.postal_code
      attrs._isPrimary = json.primary
      attrs._region = json.region
      attrs._streetAddress = json.street_address

    if (@getMapping() == C.Mappings.Direct.CreditMemo.OwnerAddress)
      attrs._countryCode = json.country_code
      attrs._extendedAddress = json.extended_address
      attrs._id = json.id
      attrs._label = json.label
      attrs._latitude = json.latitude
      attrs._locality = json.locality
      attrs._longitude = json.longitude
      attrs._isPOBox = json.po_box
      attrs._postalCode = json.postal_code
      attrs._isPrimary = json.primary
      attrs._region = json.region
      attrs._streetAddress = json.street_address

    if (@getMapping() == C.Mappings.Direct.OrderItems.Address)
      attrs._locality = json.locality
      attrs._region = json.region
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Order.SubstitutionVenueAddress)
      attrs._locality = json.locality
      attrs._region = json.region
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Order.ShipmentSnapshotAddress)
      attrs._countryCode = json.country_code
      attrs._extendedAddress = json.extended_address
      attrs._isPOBox = (json.po_box == "true") # Bool comes as string.  #FIXTHEAPI
      attrs._label = json.label
      attrs._locality = json.locality
      attrs._postalCode = json.postal_code
      attrs._region = json.region
      attrs._streetAddress = json.street_address

    if (@getMapping() == C.Mappings.Direct.Order.VenueAddress)
      attrs._locality = json.locality
      attrs._region = json.region
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.OrdersLightweight.VenueAddress)
      attrs._locality = json.locality
      attrs._region = json.region
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.CreditCards.Address)
      attrs._countryCode = json.country_code
      attrs._extendedAddress = json.extended_address
      attrs._id = json.id
      attrs._isPOBox = json.po_box
      attrs._isPrimary = json.primary
      attrs._label = json.label
      attrs._latitude = json.latitude
      attrs._locality = json.locality
      attrs._longitude = json.longitude
      attrs._name = json.name
      attrs._postalCode = json.postal_code
      attrs._region = json.region
      attrs._streetAddress = json.street_address
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Order.Addresses)
      attrs._countryCode = json.country_code
      attrs._extendedAddress = json.extended_address
      attrs._id = json.id
      attrs._isPOBox = json.po_box
      attrs._isPrimary = json.primary
      attrs._label = json.label
      attrs._latitude = json.latitude
      attrs._locality = json.locality
      attrs._longitude = json.longitude
      attrs._postalCode = json.postal_code
      attrs._region = json.region
      attrs._streetAddress = json.street_address
      attrs._crossStreet = json.cross_street
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Order.Address)
      attrs._countryCode = json.country_code
      attrs._extendedAddress = json.extended_address
      attrs._id = json.id
      attrs._isPOBox = json.po_box
      attrs._isPrimary = json.primary
      attrs._label = json.label
      attrs._latitude = json.latitude
      attrs._locality = json.locality
      attrs._longitude = json.longitude
      attrs._postalCode = json.postal_code
      attrs._region = json.region
      attrs._streetAddress = json.street_address
      attrs._crossStreet = json.cross_street
      attrs._name = json.name
    # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Offices.Address)
      attrs._countryCode = json.country_code
      attrs._extendedAddress = json.extended_address
      attrs._id = json.id
      attrs._isPOBox = json.po_box
      attrs._isPrimary = json.primary
      attrs._label = json.label
      attrs._latitude = json.latitude
      attrs._locality = json.locality
      attrs._longitude = json.longitude
      attrs._postalCode = json.postal_code
      attrs._region = json.region
      attrs._streetAddress = json.street_address
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.OrderShowLite.PatronAddress)
      attrs._countryCode = json.country_code
      attrs._extendedAddress = json.extended_address
      attrs._id = json.id
      attrs._isPOBox = json.po_box
      attrs._isPrimary = json.primary
      attrs._label = json.label
      attrs._latitude = json.latitude
      attrs._locality = json.locality
      attrs._longitude = json.longitude
      attrs._postalCode = json.postal_code
      attrs._region = json.region
      attrs._streetAddress = json.street_address
      attrs._crossStreet = json.cross_street
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Clients.Addresses)
      attrs._countryCode = json.country_code
      attrs._extendedAddress = json.extended_address
      attrs._id = json.id
      attrs._isPOBox = json.po_box
      attrs._isPrimaryBilling = json.primary_billing_address || false
      attrs._isPrimaryShipping = json.primary_shipping_address || false
      attrs._label = json.label
      attrs._latitude = json.latitude
      attrs._locality = json.locality
      attrs._longitude = json.longitude
      attrs._name = json.name
      attrs._postalCode = json.postal_code
      attrs._region = json.region
      attrs._streetAddress = json.street_address
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Carts.Address)
      attrs._countryCode = json.country_code
      attrs._extendedAddress = json.extended_address
      attrs._id = json.id
      attrs._isPOBox = json.po_box
      attrs._isPrimary = json.primary
      attrs._label = json.label
      attrs._latitude = json.latitude
      attrs._locality = json.locality
      attrs._longitude = json.longitude
      attrs._postalCode = json.postal_code
      attrs._region = json.region
      attrs._streetAddress = json.street_address

    if (@getMapping() == C.Mappings.Direct.OrderItemsByEvent.Address)
      attrs._locality = json.locality
      attrs._region = json.region
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.CreditMemos.CreditMemo.Address)
      attrs._countryCode = json.country_code
      attrs._extendedAddress = json.extended_address
      attrs._id = json.id
      attrs._isPOBox = json.po_box
      attrs._isPrimary = json.primary
      attrs._label = json.label
      attrs._latitude = json.latitude
      attrs._locality = json.locality
      attrs._longitude = json.longitude
      attrs._postalCode = json.postal_code
      attrs._region = json.region
      attrs._streetAddress = json.street_address
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  toJSON: (options = {}) ->
    json = {}
    json.country_code = @get('_countryCode')
    json.extended_address = @get('_extendedAddress')
    json.id = @get('_id')
    json.label = @get('_label')
    json.locality = @get('_locality')
    json.postal_code = @get('_postalCode')
    json.is_primary_billing = @get('_isPrimaryBilling')
    json.is_primary_shipping = @get('_isPrimaryShipping')
    json.region = @get('_region')
    json.street_address = @get('_streetAddress')
    json.cross_street = @get('_crossStreet')
    # Dumbly needs to be wrapped in an array. #badapi
    # Seriously I hate the API.
    if (
      !options.preventWrapping &&
      @isNew() && (
        @getMapping() == C.Mappings.Direct.CreditCards.Address ||
        @getMapping() == C.Mappings.Direct.Clients.Addresses ||
        @getMapping() == C.Mappings.Manual
      )
    )
      return { addresses: [json] }
    else
      return json

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {
      cid: @cid
      cityState: @cityState()
      countryCode: @get('_countryCode') || ''
      streetAddress: @get('_streetAddress') || ''
      extendedAddress: @get('_extendedAddress') || ''
      crossStreet: @get('_crossStreet') || ''
      isPrimaryBilling: !!@get('_isPrimaryBilling')
      isPrimaryShipping: !!@get('_isPrimaryShipping')
      label: @get('_label') || ''
      locality: @get('_locality') || ''
      multiLine: @multiLine()
      postalCode: @get('_postalCode') || ''
      region: @get('_region') || ''
      singleLine: @singleLine()
    })
    return presented

  cityState: () ->
    line = []
    line.push(@get('_locality') || 'No City')
    line.push(',')
    line.push(@get('_region') || 'No State')
    return line.join(' ')

  singleLine: () ->
    anything = @get('_streetAddress') ||
      @get('_extendedAddress') ||
      @get('_locality') ||
      @get('_region') ||
      @get('_postalCode') ||
      @get('_countryCode')
    if (anything)
      line = []
      line.push(@get('_streetAddress') || 'No Street')
      line.push(' ')
      line.push(@get('_extendedAddress') || '')
      line.push(', ')
      if @get('_crossStreet')
        line.push(@get('_crossStreet') || '')
        line.push(', ')
      line.push(@get('_locality') || 'No City')
      line.push(', ')
      line.push(@get('_region') || 'No State')
      line.push(' ')
      line.push(@get('_postalCode') || 'No Zip')
      line.push(' ')
      line.push(@get('_countryCode') || '')
      return line.join('')
    else
      return 'No Address'

  multiLine: () ->
    anything = @get('_streetAddress') ||
        @get('_extendedAddress') ||
        @get('_locality') ||
        @get('_region') ||
        @get('_postalCode') ||
        @get('_countryCode')
    if (anything)
      html = []
      if (@get('_name'))
        html.push(@get('_name') || '')
        html.push('<br/>')
      html.push(@get('_streetAddress') || 'No Street')
      html.push('<br/>')
      if (@get('_extendedAddress'))
        html.push(@get('_extendedAddress') || '')
        html.push('<br/>')
      if @get('_crossStreet')
        html.push(@get('_crossStreet') || '')
        html.push('<br/>')
      html.push(@get('_locality') || 'No City')
      html.push(', ')
      html.push(@get('_region') || 'No State')
      html.push(' ')
      html.push(@get('_postalCode') || 'No Zip')
      html.push(' ')
      html.push(@get('_countryCode') || '')
      return html.join('')
    else
      return 'No Address'
  #///////////////////////////////////////////////////////////////////////////