template = require('./email_table.ejs')

module.exports = App.Views.Email.EmailTable = Backbone.View.extend

  template: template

  dataTableOptions: {
    bJQueryUI: true
    sDom: '<"">t<"F"fp>'
    bScrollInfinite: true
    bScrollCollapse: false
    iDisplayLength: 1000
    sScrollY: '700px'
    aaSorting: [
      [ 4, 'desc']  # Date
      [ 1, 'asc']
    ]
    aoColumns: [
      null
      null
      null
      null
      { sType: 'date' }
      null
    ]
  }

  initialize: (options = {}) ->
    {
      @collection
      @$container
      @filterView
    } = options
    @render()

  render: () ->

    data = {
      emails: @collection.toPresenterJSON()
    }
    @$el.html(@template(data))
    @$container.html(@$el)
    @formErrors = new App.Utils.FormErrorManager($('#formErrors'))
    @delegateEvents()

    @endlessDataTable = new App.Utils.EndlessDataTable({
      @collection
      @filterView
      @dataTableOptions
    })
    @initializeContextMenu()
    return @$el

# VIEW EVENTS //////////////////////////////////////////////////////////////
  events:
    # CONTEXT MENU ITEMS
    'click .dropdown-menu .resend': 'onResendClick'


  onResendClick: (e) ->
    data = @getRowData(e)
    id = data.id
    id = encodeURIComponent(id)

    url = "/api_direct/v9/emails/#{id}/resend"

    $.ajax({
      type: 'PUT'
      url
      success: (data, status, xhr) =>

        @collection.fetchPromise()
        .then (collection) =>
          @render()
          @formErrors.show({
            type: 'success'
            message: 'Email has been resent'
          })
        .fail (error) =>
          @formErrors.show({
            type: 'failed'
            message: "Error fetching emails Errors: #{error}"
          })
        .done()

      error: (error) =>
        @formErrors.show({
            type: 'failed'
            message: "The Email could not be resent. Errors: #{error}"
          })
    })

  getRowData: (e) ->
    $tr = @$(e.target).closest('tr.email')
    trData = $tr.data()
    emailId = trData.emailId
    data = {
      id: emailId
    }
    return data


  initializeContextMenu: () ->
    @$el.on 'click', 'button.dropdown-toggle', (e) =>
      $tr = @$(e.target).closest('tr.email')
      data = $tr.data()
      html = ['<ul class="dropdown-menu" style="left:0">']
      html.push('<li><a class="resend">Resend</a></li>')
      html.push('</ul>')
      $tr.find('.btn-group').append(html.join(''))


  getDataTable: () ->
    return @endlessDataTable