module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div class="modal-header" style="width: 500px;">\n  <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n  <h3 class="modal-title">Upload Proof Of Transfer</h3>\n</div>\n\n<div class="modal-body">\n  <form id="uploadProofOfTransferForm">\n    <div id="formErrors"></div>\n    <div class="form-group">\n      <label for="type">Type</label>\n      <select id="type" class="form-control" name="type" required>\n        <option disabled value="">Select Type</option>\n        <% if (shipmentIsDelivered) { %>\n          <option>Proof of Transfer</option>\n        <% } %>\n        <option>Proof of Delivery Delay</option>\n        <option>Primary Correspondence</option>\n        <option>Proof of Acceptance</option>\n        <option>Other Information</option>\n      </select>\n    </div>\n    <div class="form-group">\n      <label for="files">Files</label>\n      <input id="files" type="file" style="width: 400px;" multiple required />\n    </div>\n    <div style="margin-top: 20px;">\n      <input type="submit" id="uploadProofOfTransferSubmit" class="btn btn-primary" style="margin-right: 10px;">\n      <button type="button" data-dismiss="modal" class="btn">Cancel</button>\n    </div>\n  </form>\n</div>\n', __filename = "js/views/shipping/modal_upload_proof_of_transfer.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div class="modal-header" style="width: 500px;">\n  <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n  <h3 class="modal-title">Upload Proof Of Transfer</h3>\n</div>\n\n<div class="modal-body">\n  <form id="uploadProofOfTransferForm">\n    <div id="formErrors"></div>\n    <div class="form-group">\n      <label for="type">Type</label>\n      <select id="type" class="form-control" name="type" required>\n        <option disabled value="">Select Type</option>\n        ');
            __line = 13;
            if (shipmentIsDelivered) {
                __append("\n          <option>Proof of Transfer</option>\n        ");
                __line = 15;
            }
            __append('\n        <option>Proof of Delivery Delay</option>\n        <option>Primary Correspondence</option>\n        <option>Proof of Acceptance</option>\n        <option>Other Information</option>\n      </select>\n    </div>\n    <div class="form-group">\n      <label for="files">Files</label>\n      <input id="files" type="file" style="width: 400px;" multiple required />\n    </div>\n    <div style="margin-top: 20px;">\n      <input type="submit" id="uploadProofOfTransferSubmit" class="btn btn-primary" style="margin-right: 10px;">\n      <button type="button" data-dismiss="modal" class="btn">Cancel</button>\n    </div>\n  </form>\n</div>\n');
            __line = 32;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}