module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = "<select id=\"creditCards\">\n  <% if (credit_cards && credit_cards.length > 0 ) { %>\n  <% _.each(credit_cards, function(credit_card) { %>\n\n  <option data-credit-card-id=\"<%=credit_card.get('id') %>\"><%= credit_card.get('card_company') %> <%= credit_card.get('name') %> x<%= credit_card.get('last_digits') %> (<%= credit_card.get('expiration_month')%>/<%= credit_card.get('expiration_year') %>)</option>\n  <% }); %>\n  <option id=\"addNewCard\" name=\"new_card\">Add New Card</option>\n  <% } else { %>\n  <option>No Cards Stored</option>\n  <option id=\"addNewCard\" name=\"new_card\">Add New Card</option>\n  <% } %>\n</select>", __filename = "js/views/credit_cards/client_cards.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<select id="creditCards">\n  ');
            __line = 2;
            if (credit_cards && credit_cards.length > 0) {
                __append("\n  ");
                __line = 3;
                _.each(credit_cards, function(credit_card) {
                    __append('\n\n  <option data-credit-card-id="');
                    __line = 5;
                    __append(escapeFn(credit_card.get("id")));
                    __append('">');
                    __append(escapeFn(credit_card.get("card_company")));
                    __append(" ");
                    __append(escapeFn(credit_card.get("name")));
                    __append(" x");
                    __append(escapeFn(credit_card.get("last_digits")));
                    __append(" (");
                    __append(escapeFn(credit_card.get("expiration_month")));
                    __append("/");
                    __append(escapeFn(credit_card.get("expiration_year")));
                    __append(")</option>\n  ");
                    __line = 6;
                });
                __append('\n  <option id="addNewCard" name="new_card">Add New Card</option>\n  ');
                __line = 8;
            } else {
                __append('\n  <option>No Cards Stored</option>\n  <option id="addNewCard" name="new_card">Add New Card</option>\n  ');
                __line = 11;
            }
            __append("\n</select>");
            __line = 12;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}