module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div class="modal-header">\n  <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n  <span class="icon"><i class="fa-solid fa-triangle-exclamation"></i></span>\n  <h3 class="modal-title"><%= errorTitle || \'Sorry, a problem has occured.\' %></h3>\n</div>\n<div class="modal-body">\n  <%\n  if (showDetails) {\n  %>\n\n  <div id="errorDetails" style="margin-top: 30px;">\n    <ul>\n      <%\n      _.each(errors, function(error) {\n      %>\n      <li><%- error %></li>\n      <%\n      });\n      %>\n    </ul>\n  </div>\n\n  <%\n  } else {\n  %>\n\n  <p>\n    If you are experiencing a reoccurring issue please contact <a href="mailto: support@ticketevolution.com">support@ticketevolution.com</a>\n  </p>\n  <a class="btn" onclick="javascript: (function() { $(\'#errorDetails\').show(); })();">Show Details</a>\n  <div id="errorDetails" style="display: none; margin-top: 30px;">\n    <ul>\n      <%\n  _.each(errors, function(error) {\n  %>\n      <li><%= error %></li>\n      <%\n  });\n  %>\n    </ul>\n  </div>\n\n  <%\n  }\n  %>\n</div>\n<div class="modal-footer">\n  <a href="#" class="btn" data-dismiss="modal">Close</a>\n</div>\n', __filename = "js/views/shared/invalid_model.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div class="modal-header">\n  <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n  <span class="icon"><i class="fa-solid fa-triangle-exclamation"></i></span>\n  <h3 class="modal-title">');
            __line = 4;
            __append(escapeFn(errorTitle || "Sorry, a problem has occured."));
            __append('</h3>\n</div>\n<div class="modal-body">\n  ');
            __line = 7;
            if (showDetails) {
                __line = 9;
                __append('\n\n  <div id="errorDetails" style="margin-top: 30px;">\n    <ul>\n      ');
                __line = 13;
                _.each(errors, function(error) {
                    __line = 15;
                    __append("\n      <li>");
                    __line = 16;
                    __append(error);
                    __append("</li>\n      ");
                    __line = 17;
                });
                __line = 19;
                __append("\n    </ul>\n  </div>\n\n  ");
                __line = 23;
            } else {
                __line = 25;
                __append('\n\n  <p>\n    If you are experiencing a reoccurring issue please contact <a href="mailto: support@ticketevolution.com">support@ticketevolution.com</a>\n  </p>\n  <a class="btn" onclick="javascript: (function() { $(\'#errorDetails\').show(); })();">Show Details</a>\n  <div id="errorDetails" style="display: none; margin-top: 30px;">\n    <ul>\n      ');
                __line = 33;
                _.each(errors, function(error) {
                    __line = 35;
                    __append("\n      <li>");
                    __line = 36;
                    __append(escapeFn(error));
                    __append("</li>\n      ");
                    __line = 37;
                });
                __line = 39;
                __append("\n    </ul>\n  </div>\n\n  ");
                __line = 43;
            }
            __line = 45;
            __append('\n</div>\n<div class="modal-footer">\n  <a href="#" class="btn" data-dismiss="modal">Close</a>\n</div>\n');
            __line = 50;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}