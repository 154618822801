module.exports = App.Models.EvoPayTransaction = Backbone.Model.extend({

  url: function(){
    return '/api_direct/v9/accounts/'+ SESSION.office_id + '/transactions'
  },

  defaults: {
    displayDate: '',
    displayCredit: '',
    displayDebit: '',
    displayType: '',
    displayBalance: '',
    displayOrders: ''
  },

  getDisplayName: function(officeOrClient) {
    if(officeOrClient){
      if (officeOrClient.type == 'Office') {
        return officeOrClient.brokerage.name;
      } else {
        return officeOrClient.name;
      }
    }
    return "";
  },


  parse: function(json, options) {
    var that = this;

    if (json.transactions && json.transactions.length){
      json = json.transactions[0];
    }

    var attrs = {
      id: json.id,
      state: (function() {
        if (!C.Options.EvoPay.States[json.state]) {
          console.log('WARNING: Unhandled state in EvoPayTransaction.', json.state);
          return '';
        }
        return C.Options.EvoPay.States[json.state];
      })(),
      recipient: new App.Models.V3.Patron(json.recipient, {
        type: C.Office,
        mapping: C.Mappings.EvoPayTransactions.Patron
      }),
      sender: new App.Models.V3.Patron(json.sender, {
        type: C.Office,
        mapping: C.Mappings.EvoPayTransactions.Patron
      }),
      displayDate: (json.state == C.Completed) ?
        (json.updated_at && App.Utils.makeTimestampHuman(json.updated_at, C.DateFormats.TableDateWithTime)) :
        (json.created_at && App.Utils.makeTimestampHuman(json.created_at, C.DateFormats.TableDateWithTime)),

      displayType:  C.Options.EvoPay.Types[json.type.toLowerCase()],
      /*(function() {
          if (json.sender && json.recipient) {
            return 'Transfer';
          } else if (json.sender) {
            return 'Withdrawal';
          } else if (json.recipient) {
            return 'Deposit';
          }
       })(),*/

      displayCredit: (json.recipient && json.recipient.id == SESSION.office_id) ? App.Utils.valueToCurrency(json.amount) : '',

      displayDebit: (json.sender && json.sender.id == SESSION.office_id) ? App.Utils.valueToCurrency(json.amount) : '',

      displayBalance: (json.state === C.Completed) ? App.Utils.valueToCurrency(json.balance) : '<span style="width:100%;text-align:center;display:block">-</span>',

      displayOrders: (function() {
        var html = [''];
        _.each(json.order_ids_with_group, function(orderId) {
          var parts = orderId.split('-');
          var linkId = parts[1];
          html.push('<a href="/order/', linkId, '" style="display: block; padding: 4px 10px 4px 0px;">');
          html.push(orderId);
          html.push('</a>');
        });
        return html.join('');
      })(),

      displayOffice: (json.sender && json.sender.id == SESSION.office_id) ?
        this.getDisplayName(json.recipient) :
        this.getDisplayName(json.sender),

      notes: json.notes,

      stateSortPriority: this.stateSortPriority()

    };
    return attrs;
  },

  stateSortPriority: function() {
    switch (this.get('state')) {
      case 'canceled':
        return 3;
      case 'completed':
        return 2;
      case 'exported':
        return 4;
      case 'pending':
        return 1;
      default:
        return 0;
    }
  },

  toDataTableRowArray: function() {
    var attrs = this.attributes;
    return [
      this.stateSortPriority(),
      attrs.id.toString(),
      attrs.displayDate,
      attrs.displayType,
      attrs.state,
      attrs.displayOffice,
      attrs.displayCredit,
      attrs.displayDebit,
      attrs.displayBalance,
      attrs.notes,
      attrs.displayOrders
    ];
  }

});