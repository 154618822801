App.Collections.Base.PaginatedBase = require('../base/paginated_base_collection.coffee')
App.Models.V3.EvoQuote = require('../../models/v3/evoquote_model.coffee')

parent = App.Collections.Base.PaginatedBase.prototype
module.exports = App.Collections.V3.EvoQuotes = App.Collections.Base.PaginatedBase.extend

  model: App.Models.V3.EvoQuote

  url: '/api_direct/v9/quotes'

  initialize: (models, options) ->
    parent.initialize.call(@, models, options)

  parse: (response, options) ->
    response.quotes