template = require('./list.ejs')
balanceTemplate = require('./balances.ejs')

App.Views.Base.FilteredTable = require('../base/filtered_table_view.coffee')

module.exports = App.Views.EvoPay.List = App.Views.Base.FilteredTable.extend

  template: template

  balanceTemplate: balanceTemplate

  initialize: (options = {}) ->
    {
      @model
      @$container
    } = options

    ### PROMISE EXPERIMENTS
    fakePromise = () ->
      deferred = Q.defer()
      setTimeout(() =>
        deferred.reject({ fun: true })
      , 2000)
      return deferred.promise

    fooPromise = () ->
      return fakePromise()
      .then (obj) ->
        console.log('yay', obj)
        return obj
      .fail (errors) ->
        console.log('errors', errors)
        return Q.reject(errors)

    fooPromise()
    .then (obj) ->
      console.log('foo YES', obj)
    .fail (errors) ->
      console.log('foo NO', errors)
      return errors
    .done()
    ###

    @listenTo(@model.get('transactions'), 'reset', @onCollectionReset)
    @render()
    @listenTo(@model, "evopaybalances:change", @renderBalances)

  render: () ->
    @$container.html(
      @$el.html(
        @template()
      )
    )
    @delegateEvents()
    #@$('input[type=checkbox],input[type=radio],input[type=file]').uniform()

    $content = @$('#filter_content')
    $collapseButton = @$('#filter_collapse_button')
    isHorizontalSplit = true
    options = {
      $content
      $collapseButton
      isHorizontalSplit
    }
    @collapsibleFilters = new App.Utils.CollapsibleWidget(options)

    App.Utils.dateRangePicker(@$('#completed_on_date_range'), @onCompletedOnDateRangeChanged, @, C.DatePickerConfig.Past)
    App.Utils.dateRangePicker(@$('#created_at_date_range'), @onCreatedAtDateRangeChanged, @, C.DatePickerConfig.Past)
    @buttonLoader = new App.Utils.LoadingButton(@$('#apply_filter_button'))
    @initializeDropdowns()
    @initializeAutocomplete()
    @renderEvopayACHButtons()
    @renderTable(true)
    @startLoad()
    return @$el

  renderTable: (isInitialRender = false) ->
    @table?.remove()
    @table = new App.Views.EvoPay.TransactionTable({
      filterView: @
      $container: @$('#transaction_table_container')
      collection: @model.get("transactions")
    })
    @tableLoader = new App.Utils.LoadingBox(@table.$el)
    if (!isInitialRender)
      @stopLoad()

  renderBalances: () ->
    balance = @model.get('balance') || 0
    pending_sent = @model.get('pending_sent') || 0
    restricted = @model.get('restricted') || 0
    spendable = balance - pending_sent - restricted

    @$("#list-balances").html(
      @balanceTemplate({
        balance:       App.Utils.valueToCurrency(balance)
        pending_sent:  App.Utils.valueToCurrency(pending_sent)
        restricted:    App.Utils.valueToCurrency(restricted)
        spendable:     App.Utils.valueToCurrency(spendable)
      })
    )

  renderEvopayACHButtons: () ->
    if (SESSION.role.cannot(App.Access.Resources.Orders.EvoPay.ManageACHFunds))
      @$el.find('#deposit_funds_button').hide()
      @$el.find('#withdraw_funds_button').hide()

  # VIEW EVENTS ////////////////////////////////////////////////////////////////
  events:
    'click #apply_filter_button':   'onApplyFilterButtonClick'
    'click #reset_filter_button':   'onResetFilterButtonClick'
    'click #sendPaymentButton':     'onSendPaymentButtonClick'
    'click #deposit_funds_button':  'onDepositFundsButtonClick'
    'click #withdraw_funds_button': 'onWithdrawFundsButtonClick'
    'click #printButton':           'onPrintButtonClick'
    'click #exportButton':          'onExportButtonClick'
    'click #restricted_orders_button': 'onRestrictedOrdersClick'

  onResetFilterButtonClick: (e) ->
    App.Controllers.evoPayController.resetTransactionIndex()

  onApplyFilterButtonClick: (e) ->
    @buttonLoader.startLoad()
    @tableLoader.startLoad()
    App.Controllers.evoPayController.filterTransactionIndex()

  onSendPaymentButtonClick: (e) ->
    modal = new App.Views.EvoPay.SendPaymentModal({ model: @model })
    modal.render()

  onDepositFundsButtonClick: (e) ->
    modal = new App.Views.EvoPay.DepositFunds({ model: @model })
    modal.render()

  onWithdrawFundsButtonClick: (e) ->
    modal = new App.Views.EvoPay.WithdrawFunds({ model: @model })
    modal.render()

  onPrintButtonClick: (e) ->
    header = "EvoPay Transactions"
    subHeader = ""
    if (@createdAtDateRange && @createdAtDateRange.start && @createdAtDateRange.end)
      subHeader = "#{ App.Utils.makeTimestampHuman(@createdAtDateRange.start, C.DateFormats.LongNoTime) } to #{App.Utils.makeTimestampHuman(@createdAtDateRange.end, C.DateFormats.LongNoTime) }"
    options = {
      header
      subHeader
    }
    App.Exporter.printDataTable(@table, options)

  onExportButtonClick: (e) ->

    generateCSV = () =>
      filePrefix = "evopay_transactions"
      filename = "#{ filePrefix }_#{ App.Utils.makeTimestampHuman(Date.now(), C.DateFormats.File) }"
      options = {
        filename
      }
      App.Exporter.csvDataTable(@table, options)

    startFetch = () =>
      @tableLoader.startLoad()
      options = {
        data: @getFilterParamsOfCurrentResults()
        success: (collection, response, options) =>
          @tableLoader.stopLoad()
          @onCollectionReset()
          generateCSV()
      }
      @model.fetchAll(options)

    confirm = new App.Views.Shared.BasicModal({
      isConfirmation: true
      header: "This could take a while"
      message: "This will fetch all evopay transactions matching your filters (taking up to several minutes).<br/>Do you want to proceed?"
      onYes: () ->
        confirm.$el.modal('hide')
        startFetch()
    })

  onRestrictedOrdersClick: (e) ->
    # Do not let Backbone Routing handle clicking this link. If the Backbone router
    # handles this button then the endless table view on the next page does not
    # load with the correct header definitions.
    e.stopPropagation()

  #/////////////////////////////////////////////////////////////////////////////

  onCompletedOnDateRangeChanged: (startDate, endDate) ->
    @completedOnDateRange = {
      start: startDate
      end: endDate
    }

  onCreatedAtDateRangeChanged: (startDate, endDate) ->
    @createdAtDateRange = {
      start: startDate
      end: endDate
    }

  filterParamMappings: () ->
    return {
      created_at:
        type: C.FilterTypes.DateRange
        el: '#created_at_date_range'
        val: @createdAtDateRange
      completed_at:
        type: C.FilterTypes.DateRange
        el: '#completed_on_date_range'
        val: @completedOnDateRange
      'amount.gte':
        type: C.FilterTypes.Text
        el: '#amount_min_text'
      'amount.lte':
        type: C.FilterTypes.Text
        el: '#amount_max_text'
      notes:
        type: C.FilterTypes.Text
        el: '#notes_text'
      sender_or_recipient_id:
        type: C.FilterTypes.Autocomplete
        el: '#office_autocomplete'
        val: @recipientOfficeId
        sendsId: true
      order_id:
        type: C.FilterTypes.Text
        el: '#order_text'
      order_group_id:
        type: C.FilterTypes.Text
        el: '#order_group_text'
      state:
        type: C.FilterTypes.Select2
        el: '#transaction_state_select'
        ignoreAll: true
      type:
        type: C.FilterTypes.Select2
        el: '#transaction_type_select'
        ignoreAll: true
      credit_type:
        type: C.FilterTypes.Select2
        el: '#credit_type_select'
        ignoreAll: true
      broker_type:
        type: C.FilterTypes.Select2
        el: '#broker_type_select'
        ignoreAll: true
    }

  # MODEL EVENTS /////////////////////////////////////////////////////////////
  onCollectionReset: () ->
    @renderTable()
  #///////////////////////////////////////////////////////////////////////////

  startLoad: () ->
    @tableLoader.startLoad()
    @buttonLoader.startLoad()

  stopLoad: () ->
    @tableLoader.stopLoad()
    @buttonLoader.stopLoad()

  initializeAutocomplete: () ->
    callback = (itemId) =>
      @recipientOfficeId = itemId
    $el = @$('#office_autocomplete')
    shouldReturnId = true
    options = {
      $el
      shouldReturnId
      callback
    }
    App.Utils.clientOfficeAutocomplete(options)

  initializeDropdowns: () ->
    stateTypes = C.Options.EvoPay.States
    stateTypes = _.map stateTypes, (key, val) ->
      return { id: val, text: key }

    @$('#transaction_state_select').select2({
      placeholder: 'All'
      data: stateTypes
      initSelection: (element, callback) ->
        data = {
          id: element.val()
          text: C.Options.EvoPay.States[element.val()]
        }
        callback(data)
    })

    transactionTypes = C.Options.EvoPay.Types
    transactionTypes = _.map(transactionTypes, (key, val) ->
      return { id: val, text: key }
    )
    @$('#transaction_type_select').select2({
      placeholder: 'All'
      data: transactionTypes
      initSelection: (element, callback) ->
        data = {
          id: element.val()
          text: C.Options.EvoPay.Types[element.val()]
        }
        callback(data)
    })

    creditTypes = C.Options.EvoPay.CreditType
    creditTypes = _.map(creditTypes, (key, val) ->
      return { id: val, text: key }
    )
    @$('#credit_type_select').select2({
      placeholder: 'All'
      data: creditTypes
      initSelection: (element, callback) ->
        data = {
          id: element.val()
          text: C.Options.EvoPay.CreditType[element.val()]
        }
        callback(data)
    })

    brokerTypes = C.Options.EvoPay.BrokerType
    brokerTypes = _.map(brokerTypes, (key, val) ->
      return { id: val, text: key }
    )
    @$('#broker_type_select').select2({
      placeholder: 'All'
      data: brokerTypes
      initSelection: (element, callback) ->
        data = {
          id: element.val()
          text: C.Options.EvoPay.BrokerType[element.val()]
        }
        callback(data)
    })