module.exports = App.Models.Shipment = Backbone.Model.extend({

  urlRoot: "/shipments",

  parse: function(options) {
    if(options.address) {
      options.address = new App.Models.V3.Address(options.address);
    }
    if(options.airbill) {
      options.airbill = new App.Models.Airbill({
        url: options.airbill,
        shipment_id: options.id
      });
    }
    return options;
  },
//validations
  validate: function() {



  },
//introspective
  isValid: function() {
    this.validate();
    return _.isEmpty(this.errors);
  },

  isFedex: function() {
    return (this.get("type") === C.FedEx);
  },
//mutators
  addressify:  function(){
    var args = {street_address: this.get("street_address"), extended_address: this.get("extended_address"), locality: this.get("locality"),
      postal_code: this.get("postal_code"), region: this.get("region"), country_code: this.get("country_code")};
    return new App.Models.V3.Address(args);
  },
//actions
  cancel: function(successCallback, errorCallback) {
    $.post("/shipments/" + this.get("id") + "/cancel", successCallback).error(errorCallback);
  },
  deliver: function(successCallback, errorCallback) {
    $.post("/shipments/" + this.get("id") + "/deliver", successCallback).error(errorCallback);
  },
  pend: function(successCallback, errorCallback) {
    $.post("/shipments/" + this.get("id") + "/pend", successCallback).error(errorCallback);
  },
  track: function(uid){
    params = uid ? "?unique_identifier=" + uid : "" ;
    return $.get("/shipments/" + this.get("id") + "/track" + params);
  },
  processFedex: function(){
    if(!this.canHaveAirbill()){
      throw "can't have airbill for anything but fedex shipments";
    }
    return $.post("/shipments/" + this.id + "/airbill");
  },
  sendEmails:    function(emails){
    return $.post(this.url() + "/email_airbill", {recipients:emails} );
  },
  emailAirbill : function(emails){
    return $.post(this.url() + "/email_airbill", {recipients:emails} );
  }
},
{
//////////////////////////////////////////////////////////////////
//Static Methods
/////////////////////////////////////////////////////////////////
  signature_types: function(filtered_type){
    var types = [
      {key:"",                      text: "No Signature Type"},
      {key:"INDIRECT",              text: "Indirect"},
      {key:"NO_SIGNATURE_REQUIRED", text: "No Signature Required"},
      {key:"ADULT",                 text: "Adult"},
      {key:"DIRECT",                text: "Direct"},
      {key:"SERVICE_DEFAULT",       text: "Service Default"}
    ];
    return _.filter(types, function(type){ return App.Utils.falsyToEmptyString(filtered_type) !== type.key});
  },
  signature_to_s: function(signatureType){
    var signature_type = App.Utils.falsyToEmptyString(signatureType);
    var types = App.Models.Shipment.signature_types();
    types     =  _.filter(types, function(type){ return type.key === signature_type});
    return (types[0] ? types[0].text : "No Signature Type");
  }

});
