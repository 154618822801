App.Collections.V2.EticketThumbnails  = require('../../collections/v2/eticket_thumbnails_collection.coffee')
App.Models.Base.BaseModel             = require('../base/base_model.coffee')
App.Models.V2.Event                   = require('./event_model.coffee')

# READ ONLY MODEL
# THIS MORE RESEMBLES A V3 MODEL WHERE WE HAVE STANDARD FIELDS, NOT JUST _'D DISPLAY ONLY FIELDS.
parent = App.Models.Base.BaseModel.prototype
module.exports = App.Models.V2.OrderItem = App.Models.Base.BaseModel.extend

  defaults:
    _eTicketThumbnails: new App.Collections.V2.EticketThumbnails(null, {
      mapping: C.Mappings.OrderEndpoint.EticketThumbnail
    })

  initialize: (attributes, options) ->
    parent.initialize.call(this, attributes, options)
    json = attributes
    attrs = {}

    if (@getMapping() == C.Mappings.V2OrderEndpoint.OrderItem)
      attrs.cost = json.cost
      ###
      created_at: "2014-02-07T17:38:28Z"
      eticket_available: false
      eticket_delivery: false
      eticket_downloaded_at: null
      eticket_downloaded_by: null
      eticket_file_size: null
      eticket_finalized: null
      eticket_finalized_at: null
      eticket_pdf_pages: null
      eticket_thumbnails: Array[0]
      eticket_updated_at: null
      has_spec_tickets: false
      id: 26048
      needs_eticket: false
      needs_sub: false
      order_item_id: 62890
      price: "11.0"
      quantity: 11
      ticket_group: Object
      updated_at: "2014-02-07T17:38:28Z"
      ###

    if (@getMapping() == C.Mappings.OrderEndpoint.OrderItem)
      attrs.cost = json.cost
      attrs.createdAt = Date.parse(json.created_at)
      attrs.deliveryId = json.delivery_id
      attrs.eTicketDownloadedAt = Date.parse(json.eticket_downloaded_at)
      attrs.eTicketDownloadedBy = json.eticket_downloaded_by
      attrs.eTicketFileSize = json.eticket_file_size
      attrs._eTicketPDFPages = json.eticket_pdf_pages
      attrs._eTicketThumbnails = new App.Collections.V2.EticketThumbnails(json.eticket_thumbnails, {
        mapping: C.Mappings.OrderEndpoint.EticketThumbnail
      })
      #eticket_thumbnails: [{page:1,…}, {page:2,…}, {page:3,…}, {page:4,…}, {page:5,…}, {page:6,…}, {page:7,…}, {page:8,…},…]
      attrs.eTicketFinalizedAt = Date.parse(json.eticket_finalized_at)
      if json.ticket_group
        attrs.event = new App.Models.V2.Event(json.ticket_group.event, {
          mapping: C.Mappings.OrderEndpoint.Event
        })
      attrs.hasSpecTickets = json.has_spec_tickets
      attrs.id = json.id
      attrs._isEticket = json.eticket_delivery
      attrs._isEticketFinalized = json.eticket_finalized
      attrs.orderItemId = json.order_item_id
      attrs.orderItemLinkId = json.id
      attrs.price = json.price
      attrs.quantity = json.quantity
      #server_message: "Generally returned by successful GET requests. "
      #status_code: 200
      attrs.seats = json.seats
      attrs.ticketGroup = new App.Models.V2.TicketGroup(json.ticket_group, {
        mapping: C.Mappings.OrderEndpoint.TicketGroup
      })
      attrs.updatedAt = Date.parse(json.updated_at)
      attrs.url = json.url
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      flags: @flags()
      ticketGroup: @get('ticketGroup')?.toPresenterJSON()
      eTicketPDFPages: @get('_eTicketPDFPages')
      eTicketThumbnails: @get('_eTicketThumbnails')?.toPresenterJSON()
      isEticket: @get('_isEticket')
      isEticketFinalized: @get('_isEticketFinalized')
      @originalSubbedTicketGroup

    })
    return presented

  flags: () ->
    flags = ''
    if (@get('hasSpecTickets'))
      flags += C.FlagBadges.Spec
    if (@get('ticketGroup')?.get('_format') == C.TicketFormats.Eticket)
        if (@get('_isEticketFinalized'))
          flags += C.FlagBadges.EticketUploadedAll()
        else
          flags += C.FlagBadges.EticketUploadedIncomplete()
    if (@get('ticketGroup'))
      if (@get('ticketGroup').get('_notesPublic'))
        flags += C.FlagBadges.Notes

    #Instant Delivery, Consignment, Notes)
    console.log(@)
    return flags
  #///////////////////////////////////////////////////////////////////////////

  convertToEticket: (callback) ->
    $.ajax {
      type:'POST'
      url: "/orders/#{@get('orderId')}/convert_to_etickets"
      data: order_item_id: @id
      success: (response) =>
        @clear()
        @initialize(response)
        callback()
      error: (response) => callback(new Error('Error converting to etickets.'))
    }

  event: () ->
    if (@get('ticket_group'))
      return _.extend({
        offices: {}
      }, @get('ticket_group').event)

  office: () ->
    if (@get('ticket_group'))
      return _.extend({ ticket_groups: [] }, @get('ticket_group').office)

  ticketGroupOfficeId: () ->
    if (@get('ticket_group'))
      return @get('ticket_group').office?.id || @get('ticket_group').office_id

  ticketGroup: () ->
    if (@get('ticket_group'))
      ticketGroup = _.extend({}, @get('ticket_group'))
      delete ticketGroup.event
      delete ticketGroup.office
      return ticketGroup