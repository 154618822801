module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div id="addTransactionContainer">\n\n</div>\n\n<div id="transactionFormErrors"></div>\n\n<table class="table table-bordered table-striped table-hover data-table" style="border-top: 1px solid #ddd;">\n  <thead>\n  <tr>\n    <th>ID</th>\n    <th>Transaction Type</th>\n    <th>Date</th>\n    <th>Payment Type</th>\n    <th>State</th>\n    <th>Details</th>\n    <th>Amount</th>\n    <th>Actions</th>\n  </tr>\n  </thead>\n  <tbody>\n  <%\n  order.payments.forEach(function(payment, index) {\n  %>\n  <tr data-payment-id="<%= payment.id %>">\n    <td><%= payment.id %></td>\n    <td><%= payment.transactionType %></td>\n    <td><%= App.Utils.makeTimestampHuman(payment.updatedAt) %></td>\n    <td><%= payment.type %></td>\n    <td><%= payment.state %></td>\n    <td><%- payment.details %></td>\n    <td><%= App.Utils.valueToCurrency(payment.amount) %></td>\n    <td>\n      <%\n      var options = {\n        orderType: order.orderType,\n        paymentState: payment.state,\n        paymentType: payment.type\n      };\n      %>\n      <% if (App.OrderPermissions.canApplyPayment(options)) { %>\n        <button\n        class="btn btn-success applyPendingPaymentButton"\n        data-loading-text="Applying..."\n        data-payment-id="<%= payment.id %>">\n          Apply\n        </button>\n      <% } %>\n      <% if (App.OrderPermissions.canCancelPayment(options)) { %>\n        <button\n        class="btn cancelPendingPaymentButton"\n        data-loading-text="Cancelling..."\n        data-payment-id="<%= payment.id %>">\n          Cancel\n        </button>\n      <% } %>\n    </td>\n  </tr>\n  <%\n  });\n  %>\n  </tbody>\n</table>', __filename = "js/views/order/transactions.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div id="addTransactionContainer">\n\n</div>\n\n<div id="transactionFormErrors"></div>\n\n<table class="table table-bordered table-striped table-hover data-table" style="border-top: 1px solid #ddd;">\n  <thead>\n  <tr>\n    <th>ID</th>\n    <th>Transaction Type</th>\n    <th>Date</th>\n    <th>Payment Type</th>\n    <th>State</th>\n    <th>Details</th>\n    <th>Amount</th>\n    <th>Actions</th>\n  </tr>\n  </thead>\n  <tbody>\n  ');
            __line = 21;
            order.payments.forEach(function(payment, index) {
                __line = 23;
                __append('\n  <tr data-payment-id="');
                __line = 24;
                __append(escapeFn(payment.id));
                __append('">\n    <td>');
                __line = 25;
                __append(escapeFn(payment.id));
                __append("</td>\n    <td>");
                __line = 26;
                __append(escapeFn(payment.transactionType));
                __append("</td>\n    <td>");
                __line = 27;
                __append(escapeFn(App.Utils.makeTimestampHuman(payment.updatedAt)));
                __append("</td>\n    <td>");
                __line = 28;
                __append(escapeFn(payment.type));
                __append("</td>\n    <td>");
                __line = 29;
                __append(escapeFn(payment.state));
                __append("</td>\n    <td>");
                __line = 30;
                __append(payment.details);
                __append("</td>\n    <td>");
                __line = 31;
                __append(escapeFn(App.Utils.valueToCurrency(payment.amount)));
                __append("</td>\n    <td>\n      ");
                __line = 33;
                var options = {
                    orderType: order.orderType,
                    paymentState: payment.state,
                    paymentType: payment.type
                };
                __line = 39;
                __append("\n      ");
                __line = 40;
                if (App.OrderPermissions.canApplyPayment(options)) {
                    __append('\n        <button\n        class="btn btn-success applyPendingPaymentButton"\n        data-loading-text="Applying..."\n        data-payment-id="');
                    __line = 44;
                    __append(escapeFn(payment.id));
                    __append('">\n          Apply\n        </button>\n      ');
                    __line = 47;
                }
                __append("\n      ");
                __line = 48;
                if (App.OrderPermissions.canCancelPayment(options)) {
                    __append('\n        <button\n        class="btn cancelPendingPaymentButton"\n        data-loading-text="Cancelling..."\n        data-payment-id="');
                    __line = 52;
                    __append(escapeFn(payment.id));
                    __append('">\n          Cancel\n        </button>\n      ');
                    __line = 55;
                }
                __append("\n    </td>\n  </tr>\n  ");
                __line = 58;
            });
            __line = 60;
            __append("\n  </tbody>\n</table>");
            __line = 62;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}