module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = "<div class='client-input'>\n  <label> <%= type %> </label>\n  <div class='input-append'> \n    <input \n      class= 'client'\n      type=  'text'       \n      name=  'client_name'\n      value= '<%= _.isEmpty(name) ? \"\" : name %>' \n      <%= (addOnly || fullDisable) ? \"disabled\" : \"\" %>\n    >\n      <button \n        class='btn new_client'\n        title='New Client'\n        type= 'button'\n        <%= fullDisable ? \"disabled\" : \"\" %>\n      >\n      <img src='/dist/img/ico_new_client.png'/>\n    </button>\n  </div>\n</div>\n", __filename = "js/views/clients/client_field.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append("<div class='client-input'>\n  <label> ");
            __line = 2;
            __append(escapeFn(type));
            __append(" </label>\n  <div class='input-append'> \n    <input \n      class= 'client'\n      type=  'text'       \n      name=  'client_name'\n      value= '");
            __line = 8;
            __append(escapeFn(_.isEmpty(name) ? "" : name));
            __append("' \n      ");
            __line = 9;
            __append(escapeFn(addOnly || fullDisable ? "disabled" : ""));
            __append("\n    >\n      <button \n        class='btn new_client'\n        title='New Client'\n        type= 'button'\n        ");
            __line = 15;
            __append(escapeFn(fullDisable ? "disabled" : ""));
            __append("\n      >\n      <img src='/dist/img/ico_new_client.png'/>\n    </button>\n  </div>\n</div>\n");
            __line = 21;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}