template = require('./list.ejs')
App.Views.Base.FilteredTable = require('../base/filtered_table_view.coffee')

module.exports = App.Views.CreditMemo.List = App.Views.Base.FilteredTable.extend

  template: template

  initialize: (options = {}) ->
    {
      @model
      @$container
    } = options
    @listenTo(@model, 'change:collection', @onCollectionChange)
    @listenTo(@model, 'change', @onModelChange)
    coll = @model.get('collection')
    @listenTo(coll, 'reset', @onCollectionReset)
    @render()

  render: () ->
    @$container.html(
      @$el.html(
        @template()
      )
    )
    App.Utils.dateRangePicker(@$('#date_created_range'), @onDateRangeChanged, @, C.DatePickerConfig.Past)
    $content = @$('#filter_content')
    $collapseButton = @$('#filter_collapse_button')
    options = {
      $content
      $collapseButton
    }
    @collapsibleFilters = new App.Utils.CollapsibleWidget(options)
    @buttonLoader = new App.Utils.LoadingButton(@$('#apply_filter_button'))
    @initializeDropdowns()
    @initializeAutocomplete()
    @renderTable(true)
    @startLoad()

    aggregateView = new App.Views.CreditMemo.Aggregates({
      @model
      $container: @$('#aggregates_container')
    })

    @delegateEvents()
    return @$el

  renderTable: (isInitialRender = false) ->
    if (@creditMemoTable)
        @creditMemoTable.remove()

    @creditMemoTable = new App.Views.CreditMemo.CreditMemoTable({
      filterView: @
      $container: @$('#creditMemo_table_container')
      collection: @model.get('collection')
    })
    @tableLoader = new App.Utils.LoadingBox(@creditMemoTable.$el)
    if (!isInitialRender)
      @stopLoad() # Shows empty loading table during initial fetch.


  # VIEW EVENTS /////////////////////////////////////////////////////////////
  events:
    'click #apply_filter_button': 'onApplyFilterButtonClick'
    'click #reset_filter_button': 'onResetFilterButtonClick'

  onResetFilterButtonClick: () ->
    App.Controllers.creditMemoController.resetCreditMemoIndex()

  onApplyFilterButtonClick: () ->
    @buttonLoader.startLoad()
    @tableLoader.startLoad()
    App.Controllers.creditMemoController.filterCreditMemoIndex()

  onDateRangeChanged: (startDate, endDate) ->
    @dateRange = {
      start: startDate
      end: endDate
    }
  #/////////////////////////////////////////////////////////////////////////////

  # COLLECTION EVENTS //////////////////////////////////////////////////////////
  onCollectionChange: () ->
    @renderTable()
    coll = @model.get('collection')
    @listenTo(coll, 'reset', @onCollectionReset)

  onCollectionReset: () ->
    @renderTable()

  onModelChange: () ->
    aggregateView = new App.Views.CreditMemo.Aggregates({
      @model
      $container: @$('#aggregates_container')
    })
  #/////////////////////////////////////////////////////////////////////////////

  filterParamMappings: () ->
    return {
      holder_id:
        type: C.FilterTypes.Autocomplete
        el: '#customer_autocomplete'
        val: @filterOwnerId
        sendsId: true
      created_at:
        type: C.FilterTypes.DateRange
        el: '#date_created_range'
        val: @dateRange
      'original_value.gte':
        type: C.FilterTypes.Text
        el: '#amount_min_text'
      'original_value.lte':
        type: C.FilterTypes.Text
        el: '#amount_max_text'
      holder_type:
        type: C.FilterTypes.Select2
        el: '#clients_or_offices_select'
        ignoreAll: true
      created_by:
        type: C.FilterTypes.Select2
        el: '#created_by_autocomplete'
      'stored_balance.ne':
        type: C.FilterTypes.FixedValForSelection
        el: '#state_select'
        condition: 'open'
        val: 0
      'stored_balance.eq':
        type: C.FilterTypes.FixedValForSelection
        el: '#state_select'
        condition: 'closed'
        val: 0
      creating_order_id:
        type: C.FilterTypes.Text
        el: '#order_id_text'

    }

  startLoad: () ->
    @tableLoader.startLoad()
    @buttonLoader.startLoad()

  stopLoad: () ->
    @tableLoader.stopLoad()
    @buttonLoader.stopLoad()


  initializeAutocomplete: () ->

    callback = (itemId) =>
      @filterOwnerId = itemId

    $el = @$('#customer_autocomplete')
    shouldReturnId = true
    options = {
      $el
      shouldReturnId
      callback
    }
    App.Utils.clientOfficeAutocomplete(options)

  initializeDropdowns: () ->

    creditMemoTypes =  _.map(C.Options.CreditMemo.Types, (key, val) ->
      return { id: val, text: key }
    )

    @$('#clients_or_offices_select').select2({
      placeholder: 'All'
      data: creditMemoTypes
      initSelection: (element, callback) ->
        data = {
          id: element.val()
          text: C.Options.CreditMemo.Types[element.val()]
        }
        callback(data)
    })

    options = _.map(C.Options.CreditMemo.States, (key, val) ->
      return { id: val, text: key }
    )
    @$('#state_select').select2({
      placeholder: 'All'
      data: options
      initSelection: (element, callback) ->
        data = {
          id: element.val()
          text: C.Options.CreditMemo.States[element.val()]
        }
        callback(data)
    })

    App.Utils.createdByBrokerageUsersAutocomplete(@$('#created_by_autocomplete'))