template = require('./sales_table.ejs')

module.exports = App.Views.Reports.SalesTable = Backbone.View.extend

  template: template

  initialize: (options = {}) ->
    {
      @collection
      @$container
      @filterView
      @model
    } = options
    @render()

  render: () ->
    groupedModels = @getGroupedModels()
    templateData = {
      groupedModels: groupedModels
    }
    @$container.html(
      @$el.html(
        @template(templateData)
      )
    )
    @delegateEvents()
    return @$el

  # VIEW EVENTS ////////////////////////////////////////////////////////////////

  #/////////////////////////////////////////////////////////////////////////////

  exportHeadings: () ->
    return [
      'Order #'
      'Order Date'
      'Customer'
      'Cost'
      'Subtotal'
      'Shipping'
      'Service Fee'
      'Additional Fees'
      'Tax'
      'Discount'
      'Total'
    ]

  exportData: () ->
    dataGroups = []
    groupedModels = @getGroupedModels()
    for own key, group of groupedModels
      data = group.map (item) ->
        return [
          item.orderLink
          App.Utils.makeTimestampHuman(item.orderDate, C.DateFormats.TableDateWithTime)
          item.orderCustomer
          item.cost
          item.subtotal
          item.shipping
          item.serviceFee
          item.additionalFee
          item.tax
          item.discount
          item.total
        ]
      totals = group.totals
      totalRow =
      "<tr class='total_row'>
        <td>#{ totals.count } Orders</td>
        <td>Average #{ totals.avg }</td>
        <td></td>
        <td>#{ totals.cost }</td>
        <td>#{ totals.subtotal }</td>
        <td>#{ totals.shipping }</td>
        <td>#{ totals.serviceFee }</td>
        <td>#{ totals.additionalFee }</td>
        <td>#{ totals.tax }</td>
        <td>#{ totals.discount }</td>
        <td>#{ totals.total }</td>
      </tr>"

      exportGroup = {
        title: group.groupTitle
        data
        totalRow
      }
      dataGroups.push(exportGroup)
    return dataGroups

  exportTotals: () ->
    m = @model
    return {
      'Orders': m.get('order_count')
      'Tickets': m.get('ticket_count')
      'Order Revenue': App.Utils.valueToCurrency(m.get('revenue_sum'))
      'Ticket Cost': App.Utils.valueToCurrency(m.get('cost_sum'))
      'Ticket Profit': App.Utils.valueToCurrency(m.get('profit_sum'))
      'Service Fees': App.Utils.valueToCurrency(m.get('service_fee_sum'))
    }

  getGroupedModels: () ->
    models = @collection.toPresenterJSON()
    groups = {}
    groupBy = @filterView.getGroupBy()

    _.each models, (model) ->
      key = model[groupBy]
      group = groups[key]
      if (!group)
        group = []
        group.totals = {
          additionalFee: 0
          cost: 0
          count: 0
          subtotal: 0
          shipping: 0
          serviceFee: 0
          additionalFee: 0
          tax: 0
          discount: 0
          total: 0
        }
      if (groupBy == 'order_created_at_date')
        group.groupTitle = model.orderDay
      else if (groupBy == 'order_placer_id')
        coll = App.Collections.V2.brokerageUsers
        user = coll.get(key)
        title = (user && user.get('name')) || 'From Automated Process'
        group.groupTitle = title
      group.totals.count += 1
      group.totals.cost += parseFloat(model.order_cost_total)
      group.totals.additionalFee += parseFloat(model.order_additional_expense)
      group.totals.subtotal += parseFloat(model.order_price_sub_total)
      group.totals.shipping += parseFloat(model.order_shipping)
      group.totals.serviceFee += parseFloat(model.order_service_fee)
      group.totals.tax += parseFloat(model.order_tax)
      group.totals.discount += parseFloat(model.order_discount)
      group.totals.total += parseFloat(model.order_price_total)
      group.push(model)
      groups[key] = group

    _.each groups, (group) ->
      totals = group.totals
      totals.avg = totals.total / totals.count
      totals.avg = App.Utils.valueToCurrency(totals.avg)
      totals.additionalFee = App.Utils.valueToCurrency(totals.additionalFee)
      totals.cost = App.Utils.valueToCurrency(totals.cost)
      totals.subtotal = App.Utils.valueToCurrency(totals.subtotal)
      totals.shipping = App.Utils.valueToCurrency(totals.shipping)
      totals.serviceFee = App.Utils.valueToCurrency(totals.serviceFee)
      totals.tax = App.Utils.valueToCurrency(totals.tax)
      totals.discount = App.Utils.valueToCurrency(totals.discount)
      totals.total = App.Utils.valueToCurrency(totals.total)
      group.totals = totals

    return groups