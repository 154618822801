template = require('./credit_memo_aggregates.ejs');

module.exports = App.Views.CreditMemo.Aggregates = Backbone.View.extend({

  template: template,

  initialize: function (options) {
    var that = this;
    var options = options || {};
    this.model = options.model;
    this.$container = options.$container;
    this.render();
  },

  render: function () {
    this.$el.html(
      this.template(
        this.model.toPresenterJSON()
      )
    );
    this.$container.html(this.$el);
    this.delegateEvents();
    return this.$el;
  }

});