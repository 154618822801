template = require('./client_table.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Clients.ClientTable = App.Views.Base.BaseView.extend

  template: template

  dataTableOptions: {
    bJQueryUI: true
    sDom: '<"">t<"F"fp>'
    bScrollInfinite: true
    bScrollCollapse: false
    iDisplayLength: 1000
    sScrollY: '700px'
    aaSorting: [
      [ 1, 'asc']
    ]
    oLanguage: {
      sSearch: 'Filter Table: '
      sEmptyTable: 'No Customers Found'
    }
  }

  initialize: (options = {}) ->
    {
      @collection
      @$container
      @filterView
    } = options
    @render()

  render: () ->
    data = {
      clients: @collection.toPresenterJSON()
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )
    @endlessDataTable = new App.Utils.EndlessDataTable({
      @collection
      @filterView
      @dataTableOptions
    })
    @$('.popover_notes').tooltip()
    return @$el

  getDataTable: () ->
    return @endlessDataTable
