module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div class="widget-box collapsible" style="margin: 0px;">\n  <div class="widget-title">\n    <div class="widget-title">\n      <div class="pull-right" style="width: 300px;">\n        <div style="margin-top: 4px;">\n          <div id="created_by_filter">\n            <!-- POPULATED DYNAMICALLY USING SELECT2 -->\n          </div>\n        </div>\n      </div>\n      <span class="icon"><i class="fa-solid fa-barcode"></i></span>\n      <h5>Orders</h5>\n    </div>\n  </div>\n  <div class="widget-content nopadding">\n    <table class="table table-bordered table-striped data-table">\n      <thead>\n        <tr>\n          <th>Order ID</th>\n          <th>Created At</th>\n          <th>Customer</th>\n          <th>Created By</th>\n          <th>Price Per</th>\n          <th>Qty.</th>\n          <th>Unfilled</th>\n          <th>Section</th>\n          <th>Row</th>\n          <th>Etickets</th>\n          <th>Details</th>\n        </tr>\n      </thead>\n      <tbody>\n        <% orderItems.forEach(function(orderItem) { %>\n        <tr class="order_row" data-order-id="<%= orderItem.orderId %>" data-order-item-id="<%= orderItem.id %>">\n          <td><a href="/order/<%= orderItem.orderId %>"><%= orderItem.orderId %></a></td>\n          <td><%= App.Utils.makeTimestampHuman(orderItem.createdAt, C.DateFormats.TableDateWithTime) %></td>\n          <td><%= orderItem.customer %></td>\n          <td><%= orderItem.createdBy %></td>\n          <td><%= App.Utils.valueToCurrency(orderItem.price) %></td>\n          <td><%= orderItem.quantity %></td>\n          <td><%= orderItem.unfilledCount %></td>\n          <td><%= orderItem.section %></td>\n          <td><%= orderItem.row %></td>\n          <td><%= orderItem.eTicketsMissing %></td>\n          <td><%- orderItem.flags %></td>\n        </tr>\n        <% }); %>\n      </tbody>\n    </table>\n  </div>\n</div>\n', __filename = "js/views/events/management_orders.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div class="widget-box collapsible" style="margin: 0px;">\n  <div class="widget-title">\n    <div class="widget-title">\n      <div class="pull-right" style="width: 300px;">\n        <div style="margin-top: 4px;">\n          <div id="created_by_filter">\n            <!-- POPULATED DYNAMICALLY USING SELECT2 -->\n          </div>\n        </div>\n      </div>\n      <span class="icon"><i class="fa-solid fa-barcode"></i></span>\n      <h5>Orders</h5>\n    </div>\n  </div>\n  <div class="widget-content nopadding">\n    <table class="table table-bordered table-striped data-table">\n      <thead>\n        <tr>\n          <th>Order ID</th>\n          <th>Created At</th>\n          <th>Customer</th>\n          <th>Created By</th>\n          <th>Price Per</th>\n          <th>Qty.</th>\n          <th>Unfilled</th>\n          <th>Section</th>\n          <th>Row</th>\n          <th>Etickets</th>\n          <th>Details</th>\n        </tr>\n      </thead>\n      <tbody>\n        ');
            __line = 33;
            orderItems.forEach(function(orderItem) {
                __append('\n        <tr class="order_row" data-order-id="');
                __line = 34;
                __append(escapeFn(orderItem.orderId));
                __append('" data-order-item-id="');
                __append(escapeFn(orderItem.id));
                __append('">\n          <td><a href="/order/');
                __line = 35;
                __append(escapeFn(orderItem.orderId));
                __append('">');
                __append(escapeFn(orderItem.orderId));
                __append("</a></td>\n          <td>");
                __line = 36;
                __append(escapeFn(App.Utils.makeTimestampHuman(orderItem.createdAt, C.DateFormats.TableDateWithTime)));
                __append("</td>\n          <td>");
                __line = 37;
                __append(escapeFn(orderItem.customer));
                __append("</td>\n          <td>");
                __line = 38;
                __append(escapeFn(orderItem.createdBy));
                __append("</td>\n          <td>");
                __line = 39;
                __append(escapeFn(App.Utils.valueToCurrency(orderItem.price)));
                __append("</td>\n          <td>");
                __line = 40;
                __append(escapeFn(orderItem.quantity));
                __append("</td>\n          <td>");
                __line = 41;
                __append(escapeFn(orderItem.unfilledCount));
                __append("</td>\n          <td>");
                __line = 42;
                __append(escapeFn(orderItem.section));
                __append("</td>\n          <td>");
                __line = 43;
                __append(escapeFn(orderItem.row));
                __append("</td>\n          <td>");
                __line = 44;
                __append(escapeFn(orderItem.eTicketsMissing));
                __append("</td>\n          <td>");
                __line = 45;
                __append(orderItem.flags);
                __append("</td>\n        </tr>\n        ");
                __line = 47;
            });
            __append("\n      </tbody>\n    </table>\n  </div>\n</div>\n");
            __line = 52;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}