App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.CreditMemoCollectionWrapper = App.Models.Base.V3BaseModel.extend

  urlRoot: '/api_direct/v9/credit_memos'

  defaults:
    collection: new App.Collections.V3.CreditMemos(null, {
        mapping: C.Mappings.Direct.CreditMemos.CreditMemo
        parentWrapper: @
      })
    _balance: '...'
    _count:   '...'
    _total:   '...'

  initialize: (attributes, options = {}) ->
    parent.initialize.call(@, attributes, options)

  parse: (response, options = {}) ->
    collection = new App.Collections.V3.CreditMemos(response.credit_memos, {
      mapping: C.Mappings.Direct.CreditMemos.CreditMemo
      parentWrapper: @
    })
    @set('collection', collection)
    delete response.credit_memos
    return response

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.CreditMemos.CreditMemoCollectionWrapper)
      attrs._balance = json.balance_sum
      attrs._count = json.total_entries
      attrs._currentPage = json.current_page
      attrs._perPage = json.per_page
      attrs._total = json.total_sum
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {
      balance: @get('_balance')
      count: @get('_count')
      total: @get('_total')
    })
    return presented