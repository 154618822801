module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<td>\n  <% if (item.heldForType != \'Cart\') { %>\n  <div class="btn-group">\n    <button class="btn btn-small dropdown-toggle" data-toggle="dropdown">\n      <i class="fa-solid fa-plus" />\n    </button>\n  </div>\n  <% } %>\n</td>\n<td><%= item.quantity %></td>\n<td><%- item.heldFor %></td>\n<td><%= App.Utils.seatsToSeatRange(item.seats) %></td>\n<td><%= App.Utils.valueToCurrency(item.price) %></td>\n<td><%= item.createdBy %></td>\n<td><%= App.Utils.makeTimestampHuman(item.createdAt, C.DateFormats.TableDateWithTime) %></td>\n<td><%= App.Utils.makeTimestampHuman(item.holdUntil, C.DateFormats.TableDateWithTime) %></td>\n<td><%- item.flags %></td>\n', __filename = "js/views/ticket_holds/ticket_hold_row.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append("<td>\n  ");
            __line = 2;
            if (item.heldForType != "Cart") {
                __append('\n  <div class="btn-group">\n    <button class="btn btn-small dropdown-toggle" data-toggle="dropdown">\n      <i class="fa-solid fa-plus" />\n    </button>\n  </div>\n  ');
                __line = 8;
            }
            __append("\n</td>\n<td>");
            __line = 10;
            __append(escapeFn(item.quantity));
            __append("</td>\n<td>");
            __line = 11;
            __append(item.heldFor);
            __append("</td>\n<td>");
            __line = 12;
            __append(escapeFn(App.Utils.seatsToSeatRange(item.seats)));
            __append("</td>\n<td>");
            __line = 13;
            __append(escapeFn(App.Utils.valueToCurrency(item.price)));
            __append("</td>\n<td>");
            __line = 14;
            __append(escapeFn(item.createdBy));
            __append("</td>\n<td>");
            __line = 15;
            __append(escapeFn(App.Utils.makeTimestampHuman(item.createdAt, C.DateFormats.TableDateWithTime)));
            __append("</td>\n<td>");
            __line = 16;
            __append(escapeFn(App.Utils.makeTimestampHuman(item.holdUntil, C.DateFormats.TableDateWithTime)));
            __append("</td>\n<td>");
            __line = 17;
            __append(item.flags);
            __append("</td>\n");
            __line = 18;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}