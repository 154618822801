template = require('./chrome_only.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Shared.ChromeOnly = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    {
      @$container
    } = options
    @render()

  render: () ->
    if (SESSION?.role?.can(App.Access.Resources.NonChromeBrowsers))
      return null

    if (
      !!window.chrome ||
      !!navigator.userAgent.match('CriOS') ||
      window.location.href.match(/sessions/i) ||
      window.location.href.match(/localhost/i) ||
      window.location.href.match(/core.staging.ticketevolution.com/i) ||
      window.location.href.match(/core.sandbox.ticketevolution.com/i)
    )
      return null

    @$container.html(
      @$el.html(
        @template()
      )
    )
    return @$el