module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div class="existingTransferSourceContainer form-horizontal">\n  <div class="control-group">\n    <label class="control-label" style="text-align: left;">\n      Transfer Source*\n    </label>\n    <div class="controls" style="white-space: nowrap;">\n      <select\n        class="selectedTransferSource"\n        name="selectedTransferSource"\n        style="margin-right: 10px;"\n      >\n        <option disabled selected value> Select a transfer source</option>\n        <% _.each(transferSourceOptions, function(val, key) { %>\n        <option value="<%= key %>">\n          <%= val %>\n        </option>\n        <% }); %>\n      </select>\n    </div>\n  </div>\n</div>\n', __filename = "js/views/buy_sell/transfer_source_form.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div class="existingTransferSourceContainer form-horizontal">\n  <div class="control-group">\n    <label class="control-label" style="text-align: left;">\n      Transfer Source*\n    </label>\n    <div class="controls" style="white-space: nowrap;">\n      <select\n        class="selectedTransferSource"\n        name="selectedTransferSource"\n        style="margin-right: 10px;"\n      >\n        <option disabled selected value> Select a transfer source</option>\n        ');
            __line = 13;
            _.each(transferSourceOptions, function(val, key) {
                __append('\n        <option value="');
                __line = 14;
                __append(escapeFn(key));
                __append('">\n          ');
                __line = 15;
                __append(escapeFn(val));
                __append("\n        </option>\n        ");
                __line = 17;
            });
            __append("\n      </select>\n    </div>\n  </div>\n</div>\n");
            __line = 22;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}