module.exports = App.Utils.Countries = [
  {
    name: "Afghanistan"
    abbreviation: "AF"
    states: [
      {
        abbreviation: ""
        name: "Badakhshan"
      }
      {
        abbreviation: ""
        name: "Badghis"
      }
      {
        abbreviation: ""
        name: "Baghlan"
      }
      {
        abbreviation: ""
        name: "Bamyan"
      }
      {
        abbreviation: ""
        name: "Farah"
      }
      {
        abbreviation: ""
        name: "Faryab"
      }
      {
        abbreviation: ""
        name: "Ghazni"
      }
      {
        abbreviation: ""
        name: "Ghor"
      }
      {
        abbreviation: ""
        name: "Helmand"
      }
      {
        abbreviation: ""
        name: "Herat"
      }
      {
        abbreviation: ""
        name: "Kabul"
      }
      {
        abbreviation: ""
        name: "Kapisa"
      }
      {
        abbreviation: ""
        name: "Lowgar"
      }
      {
        abbreviation: ""
        name: "Lowgar"
      }
      {
        abbreviation: ""
        name: "Nangarhar"
      }
      {
        abbreviation: ""
        name: "Nimruz"
      }
      {
        abbreviation: ""
        name: "Kandahar"
      }
      {
        abbreviation: ""
        name: "Kondoz"
      }
      {
        abbreviation: ""
        name: "Takhar"
      }
      {
        abbreviation: ""
        name: "Vardak"
      }
      {
        abbreviation: ""
        name: "Zabol"
      }
      {
        abbreviation: ""
        name: "Paktika"
      }
      {
        abbreviation: ""
        name: "Balkh"
      }
      {
        abbreviation: ""
        name: "Jowzjan"
      }
      {
        abbreviation: ""
        name: "Samangan"
      }
      {
        abbreviation: ""
        name: "Sar-e Pol"
      }
      {
        abbreviation: ""
        name: "Konar"
      }
      {
        abbreviation: ""
        name: "Laghman"
      }
      {
        abbreviation: ""
        name: "Paktia"
      }
      {
        abbreviation: ""
        name: "Khost"
      }
      {
        abbreviation: ""
        name: "Nurestan"
      }
      {
        abbreviation: ""
        name: "Oru-zga-n"
      }
      {
        abbreviation: ""
        name: "Oru-zga-n"
      }
      {
        abbreviation: ""
        name: "Parva-n"
      }
      {
        abbreviation: ""
        name: "Daikondi"
      }
      {
        abbreviation: ""
        name: "Panjshir"
      }
    ]
  }
  {
    name: "Albania"
    abbreviation: "AL"
    states: [
      {
        abbreviation: ""
        name: "Berat County"
      }
      {
        abbreviation: ""
        name: "Dibree County"
      }
      {
        abbreviation: ""
        name: "Durrees County"
      }
      {
        abbreviation: ""
        name: "Elbasan County"
      }
      {
        abbreviation: ""
        name: "Fier County"
      }
      {
        abbreviation: ""
        name: "Gjirokasteer County"
      }
      {
        abbreviation: ""
        name: "Korce County"
      }
      {
        abbreviation: ""
        name: "Kukees County"
      }
      {
        abbreviation: ""
        name: "Lezhee County"
      }
      {
        abbreviation: ""
        name: "Shkodeer County"
      }
      {
        abbreviation: ""
        name: "Tirana County"
      }
      {
        abbreviation: ""
        name: "Vloree County"
      }
    ]
  }
  {
    name: "Algeria"
    abbreviation: "DZ"
    states: [
      {
        abbreviation: ""
        name: "Alger"
      }
      {
        abbreviation: ""
        name: "Batna"
      }
      {
        abbreviation: ""
        name: "Constantine"
      }
      {
        abbreviation: ""
        name: "Medea"
      }
      {
        abbreviation: ""
        name: "Mostaganem"
      }
      {
        abbreviation: ""
        name: "Oran"
      }
      {
        abbreviation: ""
        name: "Saida"
      }
      {
        abbreviation: ""
        name: "Setif"
      }
      {
        abbreviation: ""
        name: "Tiaret"
      }
      {
        abbreviation: ""
        name: "Tizi Ouzou"
      }
      {
        abbreviation: ""
        name: "Tlemcen"
      }
      {
        abbreviation: ""
        name: "Bejayah"
      }
      {
        abbreviation: ""
        name: "Biskra"
      }
      {
        abbreviation: ""
        name: "Blida"
      }
      {
        abbreviation: ""
        name: "Bouira"
      }
      {
        abbreviation: ""
        name: "Djelfa"
      }
      {
        abbreviation: ""
        name: "Guelma"
      }
      {
        abbreviation: ""
        name: "Jijel"
      }
      {
        abbreviation: ""
        name: "Laghouat"
      }
      {
        abbreviation: ""
        name: "Muaskar"
      }
      {
        abbreviation: ""
        name: "M'Sila"
      }
      {
        abbreviation: ""
        name: "Oum el Bouaghi"
      }
      {
        abbreviation: ""
        name: "Sidi Bel Abbes"
      }
      {
        abbreviation: ""
        name: "Skikda"
      }
      {
        abbreviation: ""
        name: "Tebessa"
      }
      {
        abbreviation: ""
        name: "Adrar"
      }
      {
        abbreviation: ""
        name: "Aien Defla"
      }
      {
        abbreviation: ""
        name: "Ain Temouchent"
      }
      {
        abbreviation: ""
        name: "Annaba"
      }
      {
        abbreviation: ""
        name: "Bechar"
      }
      {
        abbreviation: ""
        name: "Bordj Bou Arririj"
      }
      {
        abbreviation: ""
        name: "Boumerdes"
      }
      {
        abbreviation: ""
        name: "Chlef"
      }
      {
        abbreviation: ""
        name: "El Bayadh"
      }
      {
        abbreviation: ""
        name: "El Oued"
      }
      {
        abbreviation: ""
        name: "Gardayah"
      }
      {
        abbreviation: ""
        name: "Illizi"
      }
      {
        abbreviation: ""
        name: "Khenchela"
      }
      {
        abbreviation: ""
        name: "Mila"
      }
      {
        abbreviation: ""
        name: "Naama"
      }
      {
        abbreviation: ""
        name: "Ouargla"
      }
      {
        abbreviation: ""
        name: "Relizane"
      }
      {
        abbreviation: ""
        name: "Souk Ahras"
      }
      {
        abbreviation: ""
        name: "Tamanghasset"
      }
      {
        abbreviation: ""
        name: "Tindouf"
      }
      {
        abbreviation: ""
        name: "Tipaza"
      }
      {
        abbreviation: ""
        name: "Tissemsilt"
      }
      {
        abbreviation: ""
        name: "El Tarf"
      }
    ]
  }
  {
    name: "American Samoa"
    abbreviation: "AS"
    states: [
      {
        abbreviation: ""
        name: ""
      }
      {
        abbreviation: ""
        name: ""
      }
      {
        abbreviation: ""
        name: ""
      }
    ]
  }
  {
    name: "Andorra"
    abbreviation: "AD"
    states: [
      {
        abbreviation: ""
        name: "Canillo Parish"
      }
      {
        abbreviation: ""
        name: "Encamp Parish"
      }
      {
        abbreviation: ""
        name: "La Massana Parish"
      }
      {
        abbreviation: ""
        name: "Ordino Parish"
      }
      {
        abbreviation: ""
        name: "Sant Julia de Loria Parish"
      }
      {
        abbreviation: ""
        name: "Andorra la Vella Parish"
      }
      {
        abbreviation: ""
        name: "Escaldes-Engordany Parish"
      }
    ]
  }
  {
    name: "Angola"
    abbreviation: "AO"
    states: [
      {
        abbreviation: ""
        name: "Benguela"
      }
      {
        abbreviation: ""
        name: "Bie"
      }
      {
        abbreviation: ""
        name: "Cabinda"
      }
      {
        abbreviation: ""
        name: "Cuando Cubango"
      }
      {
        abbreviation: ""
        name: "Cuanza Norte"
      }
      {
        abbreviation: ""
        name: "Cuanza Sul"
      }
      {
        abbreviation: ""
        name: "Cunene"
      }
      {
        abbreviation: ""
        name: "Huambo"
      }
      {
        abbreviation: ""
        name: "Huila"
      }
      {
        abbreviation: ""
        name: "Malanje"
      }
      {
        abbreviation: ""
        name: "Namibe"
      }
      {
        abbreviation: ""
        name: "Moxico"
      }
      {
        abbreviation: ""
        name: "Uige"
      }
      {
        abbreviation: ""
        name: "Zaire"
      }
      {
        abbreviation: ""
        name: "Lunda Norte"
      }
      {
        abbreviation: ""
        name: "Lunda Sul"
      }
      {
        abbreviation: ""
        name: "Bengo"
      }
      {
        abbreviation: ""
        name: "Luanda"
      }
    ]
  }
  {
    name: "Antigua and Barbuda"
    abbreviation: "AG"
    states: [
      {
        abbreviation: ""
        name: "Barbuda Dependency"
      }
      {
        abbreviation: ""
        name: "Saint George Parish"
      }
      {
        abbreviation: ""
        name: "Saint John Parish"
      }
      {
        abbreviation: ""
        name: "Saint Mary Parish"
      }
      {
        abbreviation: ""
        name: "Saint Paul Parish"
      }
      {
        abbreviation: ""
        name: "Saint Peter Parish"
      }
      {
        abbreviation: ""
        name: "Saint Philip Parish"
      }
      {
        abbreviation: ""
        name: "Redonda Dependency"
      }
      {
        abbreviation: ""
        name: "Redonda Dependency"
      }
    ]
  }
  {
    name: "Argentina"
    abbreviation: "AR"
    states: [
      {
        abbreviation: ""
        name: "Buenos Aires"
      }
      {
        abbreviation: ""
        name: "Catamarca"
      }
      {
        abbreviation: ""
        name: "Chaco"
      }
      {
        abbreviation: ""
        name: "Chubut"
      }
      {
        abbreviation: ""
        name: "Cordoba"
      }
      {
        abbreviation: ""
        name: "Corrientes"
      }
      {
        abbreviation: ""
        name: "Distrito Federal"
      }
      {
        abbreviation: ""
        name: "Entre Rios"
      }
      {
        abbreviation: ""
        name: "Formosa"
      }
      {
        abbreviation: ""
        name: "Jujuy"
      }
      {
        abbreviation: ""
        name: "La Pampa"
      }
      {
        abbreviation: ""
        name: "La Rioja"
      }
      {
        abbreviation: ""
        name: "Mendoza"
      }
      {
        abbreviation: ""
        name: "Misiones"
      }
      {
        abbreviation: ""
        name: "Neuquen"
      }
      {
        abbreviation: ""
        name: "Rio Negro"
      }
      {
        abbreviation: ""
        name: "Salta"
      }
      {
        abbreviation: ""
        name: "San Juan"
      }
      {
        abbreviation: ""
        name: "San Luis"
      }
      {
        abbreviation: ""
        name: "Santa Cruz"
      }
      {
        abbreviation: ""
        name: "Santa Fe"
      }
      {
        abbreviation: ""
        name: "Santiago del Estero"
      }
      {
        abbreviation: ""
        name: "Tierra del Fuego"
      }
      {
        abbreviation: ""
        name: "Tucuman"
      }
    ]
  }
  {
    name: "Armenia"
    abbreviation: "AM"
    states: [
      {
        abbreviation: ""
        name: "Aragatsotn"
      }
      {
        abbreviation: ""
        name: "Ararat"
      }
      {
        abbreviation: ""
        name: "Armavir"
      }
      {
        abbreviation: ""
        name: "Geghark'unik'"
      }
      {
        abbreviation: ""
        name: "Abovyan K'aghak'"
      }
      {
        abbreviation: ""
        name: ""
      }
      {
        abbreviation: ""
        name: "Shirak"
      }
      {
        abbreviation: ""
        name: "Syunik'"
      }
      {
        abbreviation: ""
        name: "Tavush"
      }
      {
        abbreviation: ""
        name: "Vayots' Dzor"
      }
      {
        abbreviation: ""
        name: "Yerevan"
      }
    ]
  }
  {
    name: "Australia"
    abbreviation: "AU"
    states: [
      {
        abbreviation: "ACT"
        name: "Australian Capital Territory"
      }
      {
        abbreviation: "NW"
        name: "New South Wales State"
      }
      {
        abbreviation: "NT"
        name: "Northern Territory"
      }
      {
        abbreviation: "QLD"
        name: "Queensland State"
      }
      {
        abbreviation: "SA"
        name: "South Australia State"
      }
      {
        abbreviation: "TAS"
        name: "Tasmania State"
      }
      {
        abbreviation: "VIC"
        name: "Victoria State"
      }
      {
        abbreviation: "WA"
        name: "Western Australia State"
      }
      {
        abbreviation: ""
        name: "Ashmore and Cartier Islands"
      }
      {
        abbreviation: ""
        name: "Coral Sea Islands Terriotory"
      }
    ]
  }
  {
    name: "Austria"
    abbreviation: "AT"
    states: [
      {
        abbreviation: ""
        name: "Burgenland State"
      }
      {
        abbreviation: ""
        name: "Carinthia State"
      }
      {
        abbreviation: ""
        name: "Lower Austria State"
      }
      {
        abbreviation: ""
        name: "Upper Austria State"
      }
      {
        abbreviation: ""
        name: "Salzburg State"
      }
      {
        abbreviation: ""
        name: "Styria State"
      }
      {
        abbreviation: ""
        name: "Tyrol State"
      }
      {
        abbreviation: ""
        name: "Vorarlberg State"
      }
      {
        abbreviation: ""
        name: "Vienna State"
      }
    ]
  }
  {
    name: "Azerbaijan"
    abbreviation: "AZ"
    states: [
      {
        abbreviation: ""
        name: ""
      }
      {
        abbreviation: ""
        name: ""
      }
      {
        abbreviation: ""
        name: "Abseron Rayon"
      }
      {
        abbreviation: ""
        name: "Agcabadi Rayon"
      }
      {
        abbreviation: ""
        name: "Ag(dam Rayon"
      }
      {
        abbreviation: ""
        name: "Agdas Rayon"
      }
      {
        abbreviation: ""
        name: "Agstafa Rayon"
      }
      {
        abbreviation: ""
        name: "Agsu Rayon"
      }
      {
        abbreviation: ""
        name: "Ali Bayramli Sahari"
      }
      {
        abbreviation: ""
        name: "Astara Rayon"
      }
      {
        abbreviation: ""
        name: "Baki Sahari"
      }
      {
        abbreviation: ""
        name: "Balakan Rayon"
      }
      {
        abbreviation: ""
        name: "Barda Rayon"
      }
      {
        abbreviation: ""
        name: "Beylaqan Rayon"
      }
      {
        abbreviation: ""
        name: "Bilasuvar Rayon"
      }
      {
        abbreviation: ""
        name: "Cabrayil Rayon"
      }
      {
        abbreviation: ""
        name: "Calilabad Rayon"
      }
      {
        abbreviation: ""
        name: "Daskasan Rayon"
      }
      {
        abbreviation: ""
        name: "Davaci Rayon"
      }
      {
        abbreviation: ""
        name: "Fuzuli Rayon"
      }
      {
        abbreviation: ""
        name: "Gadabay Rayon"
      }
      {
        abbreviation: ""
        name: "Gana Sahari"
      }
      {
        abbreviation: ""
        name: "Goranboy Rayon"
      }
      {
        abbreviation: ""
        name: "Goycay Rayon"
      }
      {
        abbreviation: ""
        name: "Haciqabul  Rayon"
      }
      {
        abbreviation: ""
        name: "Imisli Rayon"
      }
      {
        abbreviation: ""
        name: "Ismayilli Rayon"
      }
      {
        abbreviation: ""
        name: "Kalbacar Rayon"
      }
      {
        abbreviation: ""
        name: "Kurdamir Rayon"
      }
      {
        abbreviation: ""
        name: "Lacin Rayon"
      }
      {
        abbreviation: ""
        name: "Lankaran Rayon"
      }
      {
        abbreviation: ""
        name: "Lankaran Sahari Rayon"
      }
      {
        abbreviation: ""
        name: "Lerik Rayon"
      }
      {
        abbreviation: ""
        name: "Masalli Rayon"
      }
      {
        abbreviation: ""
        name: "Mingacevir Sahari City"
      }
      {
        abbreviation: ""
        name: "Naftalan Sahari"
      }
      {
        abbreviation: ""
        name: "Nakhichevan Autonomous Republic"
      }
      {
        abbreviation: ""
        name: "Neftcala Rayon"
      }
      {
        abbreviation: ""
        name: "Oguz Rayon"
      }
      {
        abbreviation: ""
        name: "Qabala Rayon"
      }
      {
        abbreviation: ""
        name: "Qax Rayon"
      }
      {
        abbreviation: ""
        name: "Qazax Rayon"
      }
      {
        abbreviation: ""
        name: "Qobustan Rayon"
      }
      {
        abbreviation: ""
        name: "Quba Rayon"
      }
      {
        abbreviation: ""
        name: "Qubadli Rayon"
      }
      {
        abbreviation: ""
        name: "Qusar Rayon"
      }
      {
        abbreviation: ""
        name: "Saatli Rayon"
      }
      {
        abbreviation: ""
        name: "Sabirabad Rayon"
      }
      {
        abbreviation: ""
        name: "Saki Rayon"
      }
      {
        abbreviation: ""
        name: "Saki Sahari"
      }
      {
        abbreviation: ""
        name: "Salyan Rayon"
      }
      {
        abbreviation: ""
        name: "Samaxi Rayon"
      }
      {
        abbreviation: ""
        name: "Samkir Rayon"
      }
      {
        abbreviation: ""
        name: "Samux Rayon"
      }
      {
        abbreviation: ""
        name: "Siyazan Rayon"
      }
      {
        abbreviation: ""
        name: "Sumqayit Sahari"
      }
      {
        abbreviation: ""
        name: "Susa Rayon"
      }
      {
        abbreviation: ""
        name: "Susa Sahar"
      }
      {
        abbreviation: ""
        name: "Tartar Rayon"
      }
      {
        abbreviation: ""
        name: "Tovuz Rayon"
      }
      {
        abbreviation: ""
        name: "Ucar Rayon"
      }
      {
        abbreviation: ""
        name: "Xacmaz Rayon"
      }
      {
        abbreviation: ""
        name: "Xankandi Sahari City"
      }
      {
        abbreviation: ""
        name: "Xanlar Rayon"
      }
      {
        abbreviation: ""
        name: "Xizi Rayon"
      }
      {
        abbreviation: ""
        name: "Xocali Rayon"
      }
      {
        abbreviation: ""
        name: "Xocavand Rayon"
      }
      {
        abbreviation: ""
        name: "Yardimli Rayon"
      }
      {
        abbreviation: ""
        name: "Yevlax Rayon"
      }
      {
        abbreviation: ""
        name: "Yevlax City"
      }
      {
        abbreviation: ""
        name: "Zangilan Rayon"
      }
      {
        abbreviation: ""
        name: "Zaqatala Rayon"
      }
      {
        abbreviation: ""
        name: "Zardab Rayon"
      }
    ]
  }
  {
    name: "Bahamas"
    abbreviation: "BS"
    states: [
      {
        abbreviation: ""
        name: "Bimini District"
      }
      {
        abbreviation: ""
        name: "Cat Island District"
      }
      {
        abbreviation: ""
        name: "Exuma District"
      }
      {
        abbreviation: ""
        name: "Inagua District"
      }
      {
        abbreviation: ""
        name: "Long Island District"
      }
      {
        abbreviation: ""
        name: "Mayaguana District"
      }
      {
        abbreviation: ""
        name: "Ragged Island District"
      }
      {
        abbreviation: ""
        name: "Harbour Island District"
      }
      {
        abbreviation: ""
        name: "New Providence District"
      }
      {
        abbreviation: ""
        name: "Acklins and Crooked Islands District"
      }
      {
        abbreviation: ""
        name: "Freeport District"
      }
      {
        abbreviation: ""
        name: "Governor's Harbour District"
      }
      {
        abbreviation: ""
        name: "Green Turtle Cay District"
      }
      {
        abbreviation: ""
        name: "High Rock District"
      }
      {
        abbreviation: ""
        name: "Kemps Bay District"
      }
      {
        abbreviation: ""
        name: "Marsh Harbour District"
      }
      {
        abbreviation: ""
        name: "Nichollstown and Berry Islands District"
      }
      {
        abbreviation: ""
        name: "Rock Sound District"
      }
      {
        abbreviation: ""
        name: "Sandy Point District"
      }
      {
        abbreviation: ""
        name: "San Salvador and Rum Cay District"
      }
    ]
  }
  {
    name: "Bahrain"
    abbreviation: "BH"
    states: [
      {
        abbreviation: ""
        name: "Mintaqat Juzur Hawar"
      }
      {
        abbreviation: ""
        name: "Al Manamah"
      }
      {
        abbreviation: ""
        name: "Al Muharraq"
      }
      {
        abbreviation: ""
        name: "Al Mintaqah ash Shamaliyah"
      }
      {
        abbreviation: ""
        name: "Al Mintaqah al Wusta"
      }
    ]
  }
  {
    name: "Bangladesh"
    abbreviation: "BD"
    states: [
      {
        abbreviation: ""
        name: "Bandarban"
      }
      {
        abbreviation: ""
        name: "Comilla"
      }
      {
        abbreviation: ""
        name: "Mymensingh"
      }
      {
        abbreviation: ""
        name: "Noakhali"
      }
      {
        abbreviation: ""
        name: "Patuakhali"
      }
      {
        abbreviation: ""
        name: "Bagerhat"
      }
      {
        abbreviation: ""
        name: "Bhola"
      }
      {
        abbreviation: ""
        name: "Bogra"
      }
      {
        abbreviation: ""
        name: "Barguna"
      }
      {
        abbreviation: ""
        name: "Brahmanbaria"
      }
      {
        abbreviation: ""
        name: "Chandpur"
      }
      {
        abbreviation: ""
        name: "Chapai Nawabganj"
      }
      {
        abbreviation: ""
        name: "Chuadanga"
      }
      {
        abbreviation: ""
        name: "Cox's Bazar"
      }
      {
        abbreviation: ""
        name: "Dinajpur"
      }
      {
        abbreviation: ""
        name: "Faridpur"
      }
      {
        abbreviation: ""
        name: "Feni"
      }
      {
        abbreviation: ""
        name: "Gaibandha"
      }
      {
        abbreviation: ""
        name: "Gazipur"
      }
      {
        abbreviation: ""
        name: "Gopalganj"
      }
      {
        abbreviation: ""
        name: "Habiganj"
      }
      {
        abbreviation: ""
        name: "Jaipurhat"
      }
      {
        abbreviation: ""
        name: "Jamalpur"
      }
      {
        abbreviation: ""
        name: "Jessore"
      }
      {
        abbreviation: ""
        name: "Jhalakati"
      }
      {
        abbreviation: ""
        name: "Jhenaidah"
      }
      {
        abbreviation: ""
        name: "Khagrachari"
      }
      {
        abbreviation: ""
        name: "Kishorganj"
      }
      {
        abbreviation: ""
        name: "Kurigram"
      }
      {
        abbreviation: ""
        name: "Kushtia"
      }
      {
        abbreviation: ""
        name: "Laksmipur"
      }
      {
        abbreviation: ""
        name: "Lalmonirhat"
      }
      {
        abbreviation: ""
        name: "Madaripur"
      }
      {
        abbreviation: ""
        name: "Magura"
      }
      {
        abbreviation: ""
        name: "Manikganj"
      }
      {
        abbreviation: ""
        name: "Meherpur"
      }
      {
        abbreviation: ""
        name: "Moulavibazar"
      }
      {
        abbreviation: ""
        name: "Munshiganj"
      }
      {
        abbreviation: ""
        name: "Naogaon"
      }
      {
        abbreviation: ""
        name: "Narail"
      }
      {
        abbreviation: ""
        name: "Narayanganj"
      }
      {
        abbreviation: ""
        name: "Narsingdi"
      }
      {
        abbreviation: ""
        name: "Nator"
      }
      {
        abbreviation: ""
        name: "Netrakona"
      }
      {
        abbreviation: ""
        name: "Nilphamari"
      }
      {
        abbreviation: ""
        name: "Pabna"
      }
      {
        abbreviation: ""
        name: "Panchagar"
      }
      {
        abbreviation: ""
        name: "Pirojpur"
      }
      {
        abbreviation: ""
        name: "Rajbari"
      }
      {
        abbreviation: ""
        name: "Rangpur"
      }
      {
        abbreviation: ""
        name: "Satkhira"
      }
      {
        abbreviation: ""
        name: "Shariyatpur"
      }
      {
        abbreviation: ""
        name: "Sherpur"
      }
      {
        abbreviation: ""
        name: "Sirajganj"
      }
      {
        abbreviation: ""
        name: "Sunamganj"
      }
      {
        abbreviation: ""
        name: "Tangail"
      }
      {
        abbreviation: ""
        name: "Thakurgaon"
      }
      {
        abbreviation: ""
        name: "Dhaka Division"
      }
      {
        abbreviation: ""
        name: "Khulna Division"
      }
      {
        abbreviation: ""
        name: "Ra-jsha-hi Division"
      }
      {
        abbreviation: ""
        name: "Chittagong Division"
      }
      {
        abbreviation: ""
        name: "Barisa-l Division"
      }
      {
        abbreviation: ""
        name: "Sylhet Division"
      }
    ]
  }
  {
    name: "Barbados"
    abbreviation: "BB"
    states: [
      {
        abbreviation: ""
        name: "Parish of Christ Church"
      }
      {
        abbreviation: ""
        name: "Parish of Saint Andrew"
      }
      {
        abbreviation: ""
        name: "Parish of Saint George"
      }
      {
        abbreviation: ""
        name: "Parish of Saint James"
      }
      {
        abbreviation: ""
        name: "Parish of Saint John"
      }
      {
        abbreviation: ""
        name: "Parish of Saint Joseph"
      }
      {
        abbreviation: ""
        name: "Parish of Saint Lucy"
      }
      {
        abbreviation: ""
        name: "Parish of Saint Michael"
      }
      {
        abbreviation: ""
        name: "Parish of Saint Peter"
      }
      {
        abbreviation: ""
        name: "Parish of Saint Philip"
      }
      {
        abbreviation: ""
        name: "Parish of Saint Thomas"
      }
    ]
  }
  {
    name: "Belarus"
    abbreviation: "BY"
    states: [
      {
        abbreviation: ""
        name: "Brest"
      }
      {
        abbreviation: ""
        name: "Homyel"
      }
      {
        abbreviation: ""
        name: "Hrodna"
      }
      {
        abbreviation: ""
        name: "Horad Minsk"
      }
      {
        abbreviation: ""
        name: "Horad Minsk"
      }
      {
        abbreviation: ""
        name: "Minsk"
      }
      {
        abbreviation: ""
        name: "Mahilyow"
      }
      {
        abbreviation: ""
        name: "Vitsyebsk"
      }
    ]
  }
  {
    name: "Belgium"
    abbreviation: "BE"
    states: [
      {
        abbreviation: ""
        name: "Antwerpen"
      }
      {
        abbreviation: ""
        name: "Hainaut"
      }
      {
        abbreviation: ""
        name: "Liege"
      }
      {
        abbreviation: ""
        name: "Limburg"
      }
      {
        abbreviation: ""
        name: "Luxembourg"
      }
      {
        abbreviation: ""
        name: "Namur"
      }
      {
        abbreviation: ""
        name: "Oost-Vlaanderen"
      }
      {
        abbreviation: ""
        name: "West-Vlaanderen"
      }
      {
        abbreviation: ""
        name: "Walloon Brabant"
      }
      {
        abbreviation: ""
        name: "Brussels-Capital Region"
      }
      {
        abbreviation: ""
        name: "Flemish Brabant"
      }
    ]
  }
  {
    name: "Belize"
    abbreviation: "BZ"
    states: [
      {
        abbreviation: ""
        name: "Belize District"
      }
      {
        abbreviation: ""
        name: "Cayo District"
      }
      {
        abbreviation: ""
        name: "Corozal District"
      }
      {
        abbreviation: ""
        name: "Orange Walk District"
      }
      {
        abbreviation: ""
        name: "Stann Creek District"
      }
      {
        abbreviation: ""
        name: "Toledo District"
      }
    ]
  }
  {
    name: "Benin"
    abbreviation: "BJ"
    states: [
      {
        abbreviation: ""
        name: "Alibori Department"
      }
      {
        abbreviation: ""
        name: "Atakora Department"
      }
      {
        abbreviation: ""
        name: "Atlantique Department"
      }
      {
        abbreviation: ""
        name: "Borgou Department"
      }
      {
        abbreviation: ""
        name: "Collines Department"
      }
      {
        abbreviation: ""
        name: "Kouffo Department"
      }
      {
        abbreviation: ""
        name: "Donga Department"
      }
      {
        abbreviation: ""
        name: "Littoral Department"
      }
      {
        abbreviation: ""
        name: "Mono Department"
      }
      {
        abbreviation: ""
        name: "Oueme Department"
      }
      {
        abbreviation: ""
        name: "Plateau Department"
      }
      {
        abbreviation: ""
        name: "Zou Department"
      }
    ]
  }
  {
    name: "Bermuda"
    abbreviation: "BM"
    states: [
      {
        abbreviation: ""
        name: "Devonshire Parish"
      }
      {
        abbreviation: ""
        name: "Hamilton Parish"
      }
      {
        abbreviation: ""
        name: "Hamilton Municipality"
      }
      {
        abbreviation: ""
        name: "Paget Parish"
      }
      {
        abbreviation: ""
        name: ""
      }
      {
        abbreviation: ""
        name: "Saint George Municipality"
      }
      {
        abbreviation: ""
        name: "Saint George's Parish"
      }
      {
        abbreviation: ""
        name: "Sandys Parish"
      }
      {
        abbreviation: ""
        name: "Smith's Parish"
      }
      {
        abbreviation: ""
        name: ""
      }
      {
        abbreviation: ""
        name: "Warwick Parish"
      }
    ]
  }
  {
    name: "Bhutan"
    abbreviation: "BT"
    states: [
      {
        abbreviation: ""
        name: "Bumthang District"
      }
      {
        abbreviation: ""
        name: "Chhukha District"
      }
      {
        abbreviation: ""
        name: "Chirang"
      }
      {
        abbreviation: ""
        name: "Dagana District"
      }
      {
        abbreviation: ""
        name: "Haa (Ha) District"
      }
      {
        abbreviation: ""
        name: "Lhuntse (Lhuntshi) District"
      }
      {
        abbreviation: ""
        name: "Mongar District"
      }
      {
        abbreviation: ""
        name: "Paro District"
      }
      {
        abbreviation: ""
        name: "Pemagatshel (Pemagatsel) District"
      }
      {
        abbreviation: ""
        name: "Punakha District"
      }
      {
        abbreviation: ""
        name: "Samtse (Samchi) District"
      }
      {
        abbreviation: ""
        name: "Samdrup Jongkhar District"
      }
      {
        abbreviation: ""
        name: "Zhemgang (Shemgang) District"
      }
      {
        abbreviation: ""
        name: "Trashigang (Tashigang) District"
      }
      {
        abbreviation: ""
        name: "Thimphu District"
      }
      {
        abbreviation: ""
        name: "Trongsa (Tongsa) District"
      }
      {
        abbreviation: ""
        name: "Wangdue Phodrang (Wangdi Phodrang) District"
      }
    ]
  }
  {
    name: "Bolivia"
    abbreviation: "BO"
    states: [
      {
        abbreviation: ""
        name: "Chuquisaca Department"
      }
      {
        abbreviation: ""
        name: "Cochabamba Department"
      }
      {
        abbreviation: ""
        name: "El Beni Department"
      }
      {
        abbreviation: ""
        name: "La Paz Department"
      }
      {
        abbreviation: ""
        name: "Oruro Department"
      }
      {
        abbreviation: ""
        name: "Pando Department"
      }
      {
        abbreviation: ""
        name: "Potosi Department"
      }
      {
        abbreviation: ""
        name: "Santa Cruz Department"
      }
      {
        abbreviation: ""
        name: "Tarija Department"
      }
    ]
  }
  {
    name: "Bosnia and Herzegovina"
    abbreviation: "BA"
    states: [
      {
        abbreviation: ""
        name: "Federacija Bosne i Hercegovine"
      }
      {
        abbreviation: ""
        name: "Republika Srpska Internationally Supervised District"
      }
    ]
  }
  {
    name: "Botswana"
    abbreviation: "BW"
    states: [
      {
        abbreviation: ""
        name: "Central District"
      }
      {
        abbreviation: ""
        name: "Ghanzi District"
      }
      {
        abbreviation: ""
        name: "Kgalagadi District"
      }
      {
        abbreviation: ""
        name: "Kgatleng District"
      }
      {
        abbreviation: ""
        name: "Kweneng District"
      }
      {
        abbreviation: ""
        name: "North East District"
      }
      {
        abbreviation: ""
        name: "South East District"
      }
      {
        abbreviation: ""
        name: "Southern District"
      }
      {
        abbreviation: ""
        name: "North West District"
      }
    ]
  }
  {
    name: "Brazil"
    abbreviation: "BR"
    states: [
      {
        abbreviation: ""
        name: "Acre State"
      }
      {
        abbreviation: ""
        name: "Alagoas State"
      }
      {
        abbreviation: ""
        name: "Amapa State"
      }
      {
        abbreviation: ""
        name: "Amazonas State"
      }
      {
        abbreviation: ""
        name: "Bahia State"
      }
      {
        abbreviation: ""
        name: "Ceara State"
      }
      {
        abbreviation: ""
        name: "Distrito Federal Federal District"
      }
      {
        abbreviation: ""
        name: "Espirito Santo State"
      }
      {
        abbreviation: ""
        name: "Mato Grosso do Sul State"
      }
      {
        abbreviation: ""
        name: "Maranhao State"
      }
      {
        abbreviation: ""
        name: "Mato Grosso State"
      }
      {
        abbreviation: ""
        name: "Minas Gerais State"
      }
      {
        abbreviation: ""
        name: "Para State"
      }
      {
        abbreviation: ""
        name: "Paraiba State"
      }
      {
        abbreviation: ""
        name: "Parana State"
      }
      {
        abbreviation: ""
        name: "Piaui State"
      }
      {
        abbreviation: ""
        name: "Rio de Janeiro State"
      }
      {
        abbreviation: ""
        name: "Rio Grande do Norte State"
      }
      {
        abbreviation: ""
        name: "Rio Grande do Sul State"
      }
      {
        abbreviation: ""
        name: "Rondonia State"
      }
      {
        abbreviation: ""
        name: "Roraima State"
      }
      {
        abbreviation: ""
        name: "Santa Catarina State"
      }
      {
        abbreviation: ""
        name: "Sao Paulo State"
      }
      {
        abbreviation: ""
        name: "Sergipe State"
      }
      {
        abbreviation: ""
        name: "Goias State"
      }
      {
        abbreviation: ""
        name: "Pernambuco State"
      }
      {
        abbreviation: ""
        name: "Tocantins State"
      }
    ]
  }
  {
    name: "Brunei Darussalam"
    abbreviation: "BN"
    states: [
      {
        abbreviation: ""
        name: "Belait"
      }
      {
        abbreviation: ""
        name: "Brunei and Muara"
      }
      {
        abbreviation: ""
        name: "Temburong"
      }
      {
        abbreviation: ""
        name: "Tutong"
      }
    ]
  }
  {
    name: "Bulgaria"
    abbreviation: "BG"
    states: [
      {
        abbreviation: ""
        name: "Blagoevgrad"
      }
      {
        abbreviation: ""
        name: "Burgas"
      }
      {
        abbreviation: ""
        name: "Dobrich"
      }
      {
        abbreviation: ""
        name: "Gabrovo"
      }
      {
        abbreviation: ""
        name: "Grad Sofiya"
      }
      {
        abbreviation: ""
        name: "Khaskovo"
      }
      {
        abbreviation: ""
        name: "Kurdzhali"
      }
      {
        abbreviation: ""
        name: "Kyustendil"
      }
      {
        abbreviation: ""
        name: "Lovech"
      }
      {
        abbreviation: ""
        name: "Montana"
      }
      {
        abbreviation: ""
        name: "Pazardzhik"
      }
      {
        abbreviation: ""
        name: "Pernik"
      }
      {
        abbreviation: ""
        name: "Pleven"
      }
      {
        abbreviation: ""
        name: "Plovdiv"
      }
      {
        abbreviation: ""
        name: "Razgrad"
      }
      {
        abbreviation: ""
        name: "Ruse"
      }
      {
        abbreviation: ""
        name: "Shumen"
      }
      {
        abbreviation: ""
        name: "Silistra"
      }
      {
        abbreviation: ""
        name: "Sliven"
      }
      {
        abbreviation: ""
        name: "Smolyan"
      }
      {
        abbreviation: ""
        name: "Sofia"
      }
      {
        abbreviation: ""
        name: "Stara Zagora"
      }
      {
        abbreviation: ""
        name: "Turgovishte"
      }
      {
        abbreviation: ""
        name: "Varna"
      }
      {
        abbreviation: ""
        name: "Veliko Tarnovo"
      }
      {
        abbreviation: ""
        name: "Vidin"
      }
      {
        abbreviation: ""
        name: "Vratsa"
      }
      {
        abbreviation: ""
        name: "Yambol"
      }
    ]
  }
  {
    name: "Burkina Faso"
    abbreviation: "BF"
    states: [
      {
        abbreviation: ""
        name: "Bam"
      }
      {
        abbreviation: ""
        name: "Boulkiemde"
      }
      {
        abbreviation: ""
        name: "Ganzourgou"
      }
      {
        abbreviation: ""
        name: "Gnagna"
      }
      {
        abbreviation: ""
        name: "Kouritenga"
      }
      {
        abbreviation: ""
        name: "Oudalan"
      }
      {
        abbreviation: ""
        name: "Passore"
      }
      {
        abbreviation: ""
        name: "Sanguie"
      }
      {
        abbreviation: ""
        name: "Soum"
      }
      {
        abbreviation: ""
        name: "Tapoa"
      }
      {
        abbreviation: ""
        name: "Zoundweogo"
      }
      {
        abbreviation: ""
        name: "Bale"
      }
      {
        abbreviation: ""
        name: "Banwa"
      }
      {
        abbreviation: ""
        name: "Bazega"
      }
      {
        abbreviation: ""
        name: "Bougouriba"
      }
      {
        abbreviation: ""
        name: "Boulgou"
      }
      {
        abbreviation: ""
        name: "Gourma"
      }
      {
        abbreviation: ""
        name: "Houet"
      }
      {
        abbreviation: ""
        name: "Ioba"
      }
      {
        abbreviation: ""
        name: "Kadiogo"
      }
      {
        abbreviation: ""
        name: "Kenedougou"
      }
      {
        abbreviation: ""
        name: ""
      }
      {
        abbreviation: ""
        name: "Komondjari"
      }
      {
        abbreviation: ""
        name: "Kompienga"
      }
      {
        abbreviation: ""
        name: "Kossi"
      }
      {
        abbreviation: ""
        name: "Koulpelogo"
      }
      {
        abbreviation: ""
        name: "Kourweogo"
      }
      {
        abbreviation: ""
        name: "Leraba"
      }
      {
        abbreviation: ""
        name: "Loroum"
      }
      {
        abbreviation: ""
        name: "Mouhoun"
      }
      {
        abbreviation: ""
        name: "Namentenga"
      }
      {
        abbreviation: ""
        name: "Naouri"
      }
      {
        abbreviation: ""
        name: "Nayala"
      }
      {
        abbreviation: ""
        name: "Noumbiel"
      }
      {
        abbreviation: ""
        name: "Oubritenga"
      }
      {
        abbreviation: ""
        name: "Poni"
      }
      {
        abbreviation: ""
        name: "Sanmatenga"
      }
      {
        abbreviation: ""
        name: "Seno"
      }
      {
        abbreviation: ""
        name: "Sissili"
      }
      {
        abbreviation: ""
        name: "Sourou"
      }
      {
        abbreviation: ""
        name: "Tuy"
      }
      {
        abbreviation: ""
        name: "Yagha"
      }
      {
        abbreviation: ""
        name: "Yatenga"
      }
      {
        abbreviation: ""
        name: "Ziro"
      }
      {
        abbreviation: ""
        name: "Zondoma"
      }
    ]
  }
  {
    name: "Burundi"
    abbreviation: "BI"
    states: [
      {
        abbreviation: ""
        name: "Muramvya"
      }
      {
        abbreviation: ""
        name: "Mwaro"
      }
      {
        abbreviation: ""
        name: "Bujumbura Rural"
      }
      {
        abbreviation: ""
        name: "Bubanza"
      }
      {
        abbreviation: ""
        name: "Bururi"
      }
      {
        abbreviation: ""
        name: "Cankuzo"
      }
      {
        abbreviation: ""
        name: "Cibitoke"
      }
      {
        abbreviation: ""
        name: "Gitega"
      }
      {
        abbreviation: ""
        name: "Karuzi"
      }
      {
        abbreviation: ""
        name: "Kayanza"
      }
      {
        abbreviation: ""
        name: "Kirundo"
      }
      {
        abbreviation: ""
        name: "Makamba"
      }
      {
        abbreviation: ""
        name: "Muyinga"
      }
      {
        abbreviation: ""
        name: "Ngozi"
      }
      {
        abbreviation: ""
        name: "Rutana"
      }
      {
        abbreviation: ""
        name: "Ruyigi"
      }
    ]
  }
  {
    name: "Cambodia"
    abbreviation: "KH"
    states: [
      {
        abbreviation: ""
        name: "Kampong Cham"
      }
      {
        abbreviation: ""
        name: "Kampong Chhnang"
      }
      {
        abbreviation: ""
        name: "Kampong Spce"
      }
      {
        abbreviation: ""
        name: "Kampong Thum"
      }
      {
        abbreviation: ""
        name: "Kandal"
      }
      {
        abbreviation: ""
        name: "Kaoh Kong"
      }
      {
        abbreviation: ""
        name: "Kracheh"
      }
      {
        abbreviation: ""
        name: "Mondol Kiri"
      }
      {
        abbreviation: ""
        name: "Pouthisat"
      }
      {
        abbreviation: ""
        name: "Preah Vihear"
      }
      {
        abbreviation: ""
        name: "Prey Veng"
      }
      {
        abbreviation: ""
        name: "Stceng Treng"
      }
      {
        abbreviation: ""
        name: "Svay Rieng"
      }
      {
        abbreviation: ""
        name: "Takev"
      }
      {
        abbreviation: ""
        name: "Kampot"
      }
      {
        abbreviation: ""
        name: "Phnom Penh"
      }
      {
        abbreviation: ""
        name: "Rotanokiri"
      }
      {
        abbreviation: ""
        name: "Siemreab"
      }
      {
        abbreviation: ""
        name: "Batdambang"
      }
      {
        abbreviation: ""
        name: "Krong Keb"
      }
      {
        abbreviation: ""
        name: "Otdar Meanchey"
      }
      {
        abbreviation: ""
        name: "Krong Preah Sihanouk"
      }
      {
        abbreviation: ""
        name: "Batdambang"
      }
      {
        abbreviation: ""
        name: "Krong Pailin"
      }
    ]
  }
  {
    name: "Cameroon"
    abbreviation: "CM"
    states: [
      {
        abbreviation: ""
        name: "Est"
      }
      {
        abbreviation: ""
        name: "Littoral"
      }
      {
        abbreviation: ""
        name: "Nord-Ouest"
      }
      {
        abbreviation: ""
        name: "Ouest"
      }
      {
        abbreviation: ""
        name: "Sud-Ouest"
      }
      {
        abbreviation: ""
        name: "Adamaoua"
      }
      {
        abbreviation: ""
        name: "Centre"
      }
      {
        abbreviation: ""
        name: "Extreme-Nord"
      }
      {
        abbreviation: ""
        name: "Nord"
      }
      {
        abbreviation: ""
        name: "Sud"
      }
    ]
  }
  {
    name: "Canada"
    abbreviation: "CA"
    states: [
      {
        abbreviation: "AB"
        name: "Alberta"
      }
      {
        abbreviation: "BC"
        name: "British Columbia"
      }
      {
        abbreviation: "MB"
        name: "Manitoba"
      }
      {
        abbreviation: "NB"
        name: "New Brunswick"
      }
      {
        abbreviation: "NL"
        name: "Newfoundland"
      }
      {
        abbreviation: "NS"
        name: "Nova Scotia"
      }
      {
        abbreviation: "ON"
        name: "Ontario"
      }
      {
        abbreviation: "PE"
        name: "Prince Edward Island"
      }
      {
        abbreviation: "QC"
        name: "Quebec"
      }
      {
        abbreviation: "SK"
        name: "Saskatchewan"
      }
      {
        abbreviation: "YT"
        name: "Yukon"
      }
      {
        abbreviation: "NT"
        name: "Northwest Territories"
      }
      {
        abbreviation: "NU"
        name: "Nunavut"
      }
    ]
  }
  {
    name: "Cape Verde"
    abbreviation: "CV"
    states: [
      {
        abbreviation: ""
        name: "Boa Vista"
      }
      {
        abbreviation: ""
        name: "Brava"
      }
      {
        abbreviation: ""
        name: "Maio"
      }
      {
        abbreviation: ""
        name: "Paul"
      }
      {
        abbreviation: ""
        name: "Ribeira Grande"
      }
      {
        abbreviation: ""
        name: "Sal"
      }
      {
        abbreviation: ""
        name: "Sao Nicolau"
      }
      {
        abbreviation: ""
        name: "Sao Vicente"
      }
      {
        abbreviation: ""
        name: "Mosteiros"
      }
      {
        abbreviation: ""
        name: "Praia"
      }
      {
        abbreviation: ""
        name: "Santa Catarina"
      }
      {
        abbreviation: ""
        name: "Santa Cruz"
      }
      {
        abbreviation: ""
        name: "Sao Domingos"
      }
      {
        abbreviation: ""
        name: "Sao Filipe"
      }
      {
        abbreviation: ""
        name: "Sao Miguel"
      }
      {
        abbreviation: ""
        name: "Tarrafal"
      }
    ]
  }
  {
    name: "Central African Republic"
    abbreviation: "CF"
    states: [
      {
        abbreviation: ""
        name: "Nana-Grebizi"
      }
      {
        abbreviation: ""
        name: "Bamingui-Bangoran Prefecture"
      }
      {
        abbreviation: ""
        name: "Basse-Kotto Prefecture"
      }
      {
        abbreviation: ""
        name: "Haute-Kotto Prefecture"
      }
      {
        abbreviation: ""
        name: "Mambee-Kadei Prefecture"
      }
      {
        abbreviation: ""
        name: "Haut-Mbomou Prefecture"
      }
      {
        abbreviation: ""
        name: "Kemo"
      }
      {
        abbreviation: ""
        name: "Lobaye"
      }
      {
        abbreviation: ""
        name: "Mbomou"
      }
      {
        abbreviation: ""
        name: "Nana-Mambere Prefecture"
      }
      {
        abbreviation: ""
        name: "Ouaka"
      }
      {
        abbreviation: ""
        name: "Ouham"
      }
      {
        abbreviation: ""
        name: "Ouham-Pende Prefecture"
      }
      {
        abbreviation: ""
        name: "Vakaga"
      }
      {
        abbreviation: ""
        name: "Sangha-Mbaere Economic Prefecture"
      }
      {
        abbreviation: ""
        name: "Ombella-Mpoko Prefecture"
      }
      {
        abbreviation: ""
        name: "Bangui"
      }
    ]
  }
  {
    name: "Chad"
    abbreviation: "TD"
    states: [
      {
        abbreviation: ""
        name: "Batha Prefecture"
      }
      {
        abbreviation: ""
        name: "Biltine Prefecture"
      }
      {
        abbreviation: ""
        name: "Borkou-Ennedi-Tibesti Prefecture"
      }
      {
        abbreviation: ""
        name: "Guera Prefecture"
      }
      {
        abbreviation: ""
        name: "Kanem Prefecture"
      }
      {
        abbreviation: ""
        name: "Lac Prefecture"
      }
      {
        abbreviation: ""
        name: "Logone Occidental Prefecture"
      }
      {
        abbreviation: ""
        name: "Logone Oriental Prefecture"
      }
      {
        abbreviation: ""
        name: "Mayo-Kebbi Prefecture"
      }
      {
        abbreviation: ""
        name: "Ouaddai Prefecture"
      }
      {
        abbreviation: ""
        name: "Salamat Prefecture"
      }
      {
        abbreviation: ""
        name: "Tandjile Prefecture"
      }
      {
        abbreviation: ""
        name: "Chari-Baguirmi Prefecture"
      }
      {
        abbreviation: ""
        name: "Mayo-Kebbi Est"
      }
      {
        abbreviation: ""
        name: "Moyen-Chari Prefecture"
      }
      {
        abbreviation: ""
        name: "Hadjer Lamis"
      }
      {
        abbreviation: ""
        name: "Mandoul"
      }
      {
        abbreviation: ""
        name: "Mayo-Kebbi Ouest"
      }
    ]
  }
  {
    name: "Chile"
    abbreviation: "CL"
    states: [
      {
        abbreviation: ""
        name: "Valparaiso Region"
      }
      {
        abbreviation: ""
        name: "Aisen del General Carlos Ibanez del Campo Region"
      }
      {
        abbreviation: ""
        name: "Antofagasta Region"
      }
      {
        abbreviation: ""
        name: "Araucania Region"
      }
      {
        abbreviation: ""
        name: "Atacama Region"
      }
      {
        abbreviation: ""
        name: "Biobio Region"
      }
      {
        abbreviation: ""
        name: "Coquimbo Region"
      }
      {
        abbreviation: ""
        name: "Libertador General Bernardo O'Higgins Region"
      }
      {
        abbreviation: ""
        name: "Magallanes y Antartica Chilena Region"
      }
      {
        abbreviation: ""
        name: "Maule Region"
      }
      {
        abbreviation: ""
        name: "Santiago Metropolitan Region"
      }
      {
        abbreviation: ""
        name: "Los Lagos Region"
      }
      {
        abbreviation: ""
        name: "Tarapaca Region"
      }
      {
        abbreviation: ""
        name: ""
      }
      {
        abbreviation: ""
        name: ""
      }
    ]
  }
  {
    name: "China"
    abbreviation: "CN"
    states: [
      {
        abbreviation: ""
        name: "Anhui"
      }
      {
        abbreviation: ""
        name: "Zhejiang"
      }
      {
        abbreviation: ""
        name: "Jiangxi"
      }
      {
        abbreviation: ""
        name: "Jiangsu"
      }
      {
        abbreviation: ""
        name: "Jilin"
      }
      {
        abbreviation: ""
        name: "Qinghai"
      }
      {
        abbreviation: ""
        name: "Fujian"
      }
      {
        abbreviation: ""
        name: "Heilongjiang"
      }
      {
        abbreviation: ""
        name: "Henan"
      }
      {
        abbreviation: ""
        name: "Hebei"
      }
      {
        abbreviation: ""
        name: "Hunan"
      }
      {
        abbreviation: ""
        name: "Hubei"
      }
      {
        abbreviation: ""
        name: "Xinjiang Uygur Autonomous Region"
      }
      {
        abbreviation: ""
        name: "Xizang Autonomous Region"
      }
      {
        abbreviation: ""
        name: "Gansu"
      }
      {
        abbreviation: ""
        name: "Guangxi Zhuang Autonomous Region"
      }
      {
        abbreviation: ""
        name: "Guizhou"
      }
      {
        abbreviation: ""
        name: "Liaoning"
      }
      {
        abbreviation: ""
        name: "Nei Mongol Autonomous Region"
      }
      {
        abbreviation: ""
        name: "Ningxia Hui Autonomous Region"
      }
      {
        abbreviation: ""
        name: "Beijing Municipality"
      }
      {
        abbreviation: ""
        name: "Shanghai Municipality"
      }
      {
        abbreviation: ""
        name: "Shanxi"
      }
      {
        abbreviation: ""
        name: "Shandong"
      }
      {
        abbreviation: ""
        name: "Shaanxi"
      }
      {
        abbreviation: ""
        name: "Tianjin Municipality"
      }
      {
        abbreviation: ""
        name: "Yunnan"
      }
      {
        abbreviation: ""
        name: "Guangdong"
      }
      {
        abbreviation: ""
        name: "Hainan"
      }
      {
        abbreviation: ""
        name: "Sichuan"
      }
      {
        abbreviation: ""
        name: "Chongqing Municipality"
      }
    ]
  }
  {
    name: "Colombia"
    abbreviation: "CO"
    states: [
      {
        abbreviation: ""
        name: "Anjouan Island"
      }
      {
        abbreviation: ""
        name: "Grande Comore Island"
      }
      {
        abbreviation: ""
        name: "Moheli Island"
      }
    ]
  }
  {
    name: "Comoros"
    abbreviation: "KM"
    states: [

    ]
  }
  {
    name: "Congo"
    abbreviation: "CG"
    states: [
      {
        abbreviation: ""
        name: "Bouenza Region"
      }
      {
        abbreviation: ""
        name: "Kouilou Region"
      }
      {
        abbreviation: ""
        name: "Lekoumou Region"
      }
      {
        abbreviation: ""
        name: "Likouala Region"
      }
      {
        abbreviation: ""
        name: "Niari Region"
      }
      {
        abbreviation: ""
        name: "Plateaux Region"
      }
      {
        abbreviation: ""
        name: "Sangha Region"
      }
      {
        abbreviation: ""
        name: "Pool Region"
      }
      {
        abbreviation: ""
        name: "Brazzaville Commune"
      }
      {
        abbreviation: ""
        name: "Cuvette Region"
      }
      {
        abbreviation: ""
        name: "Cuvette-Ouest Region"
      }
    ]
  }
  {
    name: "Costa Rica"
    abbreviation: "CR"
    states: [
      {
        abbreviation: ""
        name: "Alajuela"
      }
      {
        abbreviation: ""
        name: "Cartago"
      }
      {
        abbreviation: ""
        name: "Guanacaste"
      }
      {
        abbreviation: ""
        name: "Heredia"
      }
      {
        abbreviation: ""
        name: "Limon"
      }
      {
        abbreviation: ""
        name: "Puntarenas"
      }
      {
        abbreviation: ""
        name: "San Jose"
      }
    ]
  }
  {
    name: "Cote D'Ivoire"
    abbreviation: "CI"
    states: [
      {
        abbreviation: ""
        name: "Agneby Region"
      }
      {
        abbreviation: ""
        name: "Bafing Region"
      }
      {
        abbreviation: ""
        name: "Bas-Sassandra Region"
      }
      {
        abbreviation: ""
        name: "Denguele Region"
      }
      {
        abbreviation: ""
        name: "Dix-Huit Montagnes Region"
      }
      {
        abbreviation: ""
        name: "Fromager Region"
      }
      {
        abbreviation: ""
        name: "Haut-Sassandra Region"
      }
      {
        abbreviation: ""
        name: "Lacs Region"
      }
      {
        abbreviation: ""
        name: "Lagunes Region"
      }
      {
        abbreviation: ""
        name: "Marahoue Region"
      }
      {
        abbreviation: ""
        name: "Moyen-Cavally Region"
      }
      {
        abbreviation: ""
        name: "Moyen-Comoe Region"
      }
      {
        abbreviation: ""
        name: "N'zi-Comoe Region"
      }
      {
        abbreviation: ""
        name: "Savanes Region"
      }
      {
        abbreviation: ""
        name: "Sud-Bandama Region"
      }
      {
        abbreviation: ""
        name: "Sud-Comoe Region"
      }
      {
        abbreviation: ""
        name: "Vallee du Bandama Region"
      }
      {
        abbreviation: ""
        name: "Worodougou Region"
      }
      {
        abbreviation: ""
        name: "Zanzan Region"
      }
    ]
  }
  {
    name: "Croatia"
    abbreviation: "HR"
    states: [
      {
        abbreviation: ""
        name: "Bjelovarska-Bilogorska County"
      }
      {
        abbreviation: ""
        name: "Brodsko-Posavska County"
      }
      {
        abbreviation: ""
        name: "Dubrovacko-Neretvanska County"
      }
      {
        abbreviation: ""
        name: "Istarska County"
      }
      {
        abbreviation: ""
        name: "Karlovacka County"
      }
      {
        abbreviation: ""
        name: "Koprivnicko-Krizevaka County"
      }
      {
        abbreviation: ""
        name: "Krapinsko-Zagorska County"
      }
      {
        abbreviation: ""
        name: "Licko-Senjska County"
      }
      {
        abbreviation: ""
        name: "Medimurska County"
      }
      {
        abbreviation: ""
        name: "Osjecko-Baranjska County"
      }
      {
        abbreviation: ""
        name: "Pozesko-Slavonska County"
      }
      {
        abbreviation: ""
        name: "Primorsko-Goranska Kotar County"
      }
      {
        abbreviation: ""
        name: "Sibenik-Knin County"
      }
      {
        abbreviation: ""
        name: "Sisacko-Moslavacka County"
      }
      {
        abbreviation: ""
        name: "Splitsko-Dalmatinska County"
      }
      {
        abbreviation: ""
        name: "Varazdinska County"
      }
      {
        abbreviation: ""
        name: "Viroviticko-Podravska County"
      }
      {
        abbreviation: ""
        name: "Vukovarsko-Srijemska County"
      }
      {
        abbreviation: ""
        name: "Zadarska County"
      }
      {
        abbreviation: ""
        name: "Zagrebacka"
      }
      {
        abbreviation: ""
        name: "Grad Zagreb City"
      }
    ]
  }
  {
    name: "Cuba"
    abbreviation: "CU"
    states: [
      {
        abbreviation: ""
        name: "Pinar del Rio"
      }
      {
        abbreviation: ""
        name: "Ciudad de La Habana"
      }
      {
        abbreviation: ""
        name: "Matanzas"
      }
      {
        abbreviation: ""
        name: "Isla de la Juventud Special Municipality"
      }
      {
        abbreviation: ""
        name: "Camaguey"
      }
      {
        abbreviation: ""
        name: "Ciego de Avila"
      }
      {
        abbreviation: ""
        name: "Cienfuegos"
      }
      {
        abbreviation: ""
        name: "Granma"
      }
      {
        abbreviation: ""
        name: "Guantanamo"
      }
      {
        abbreviation: ""
        name: "La Habana"
      }
      {
        abbreviation: ""
        name: "Holguin"
      }
      {
        abbreviation: ""
        name: "Las Tunas"
      }
      {
        abbreviation: ""
        name: "Sancti Spiritus"
      }
      {
        abbreviation: ""
        name: "Santiago de Cuba"
      }
      {
        abbreviation: ""
        name: "Villa Clara"
      }
    ]
  }
  {
    name: "Curacao"
    abbreviation: "CW"
    states: [
      abbreviation: ""
      name: "Willemstad"
    ]
  }
  {
    name: "Cyprus"
    abbreviation: "CY"
    states: [
      {
        abbreviation: ""
        name: "Famagusta District"
      }
      {
        abbreviation: ""
        name: "Kyrenia District"
      }
      {
        abbreviation: ""
        name: "Larnaca District"
      }
      {
        abbreviation: ""
        name: "Nicosia District"
      }
      {
        abbreviation: ""
        name: "Limassol District"
      }
      {
        abbreviation: ""
        name: "Paphos District"
      }
    ]
  }
  {
    name: "Czech Republic"
    abbreviation: "CZ"
    states: [
      {
        abbreviation: ""
        name: "Hlavni Mesto Praha"
      }
      {
        abbreviation: ""
        name: "Praha (capital city)"
      }
      {
        abbreviation: ""
        name: "Jihomoravsky Region"
      }
      {
        abbreviation: ""
        name: "Jihocesky Region"
      }
      {
        abbreviation: ""
        name: "Vysocina Region"
      }
      {
        abbreviation: ""
        name: "Karlovarsky Region"
      }
      {
        abbreviation: ""
        name: "Kralovehradecky Region"
      }
      {
        abbreviation: ""
        name: "Liberecky Region"
      }
      {
        abbreviation: ""
        name: "Olomoucky Region"
      }
      {
        abbreviation: ""
        name: "Moravskoslezsky Region"
      }
      {
        abbreviation: ""
        name: "Pardubicky  Region"
      }
      {
        abbreviation: ""
        name: "Plzensky Kraj"
      }
      {
        abbreviation: ""
        name: "Stredocesky Region"
      }
      {
        abbreviation: ""
        name: "Ustecky Region"
      }
      {
        abbreviation: ""
        name: "Zlinsky Region"
      }
    ]
  }
  {
    name: "Denmark"
    abbreviation: "DK"
    states: [
      {
        abbreviation: ""
        name: "Arhus County"
      }
      {
        abbreviation: ""
        name: "Bornholm Regional Municipality"
      }
      {
        abbreviation: ""
        name: "Frederiksborg County"
      }
      {
        abbreviation: ""
        name: "Fyn County"
      }
      {
        abbreviation: ""
        name: "Kobenhavn municipal"
      }
      {
        abbreviation: ""
        name: "Kobenhavn County"
      }
      {
        abbreviation: ""
        name: "Nordjylland County"
      }
      {
        abbreviation: ""
        name: "Ribe County"
      }
      {
        abbreviation: ""
        name: "Ringkobing County"
      }
      {
        abbreviation: ""
        name: "Roskilde County"
      }
      {
        abbreviation: ""
        name: "Sonderjylland County"
      }
      {
        abbreviation: ""
        name: "Storstrom County"
      }
      {
        abbreviation: ""
        name: "Vejle County"
      }
      {
        abbreviation: ""
        name: "Vestsjaelland County"
      }
      {
        abbreviation: ""
        name: "Viborg County"
      }
      {
        abbreviation: ""
        name: "Frederiksberg municipal"
      }
      {
        abbreviation: ""
        name: "Capital Region"
      }
      {
        abbreviation: ""
        name: "Central Jutland Region"
      }
      {
        abbreviation: ""
        name: "North Jutland Region"
      }
      {
        abbreviation: ""
        name: "Zealand Region"
      }
      {
        abbreviation: ""
        name: "South Denmark Region"
      }
    ]
  }
  {
    name: "Djibouti"
    abbreviation: "DJ"
    states: [
      {
        abbreviation: ""
        name: "Ali Sabieh District"
      }
      {
        abbreviation: ""
        name: "Obock District"
      }
      {
        abbreviation: ""
        name: "Tadjourah District"
      }
      {
        abbreviation: ""
        name: "Dikhil District"
      }
      {
        abbreviation: ""
        name: "Djibouti District"
      }
      {
        abbreviation: ""
        name: "Arta District"
      }
    ]
  }
  {
    name: "Dominica"
    abbreviation: "DM"
    states: [
      {
        abbreviation: ""
        name: "Saint Andrew Parish"
      }
      {
        abbreviation: ""
        name: "Saint David Parish"
      }
      {
        abbreviation: ""
        name: "Saint George Parish"
      }
      {
        abbreviation: ""
        name: "Saint John Parish"
      }
      {
        abbreviation: ""
        name: "Saint Joseph Parish"
      }
      {
        abbreviation: ""
        name: "Saint Luke Parish"
      }
      {
        abbreviation: ""
        name: "Saint Mark Parish"
      }
      {
        abbreviation: ""
        name: "Saint Patrick Parish"
      }
      {
        abbreviation: ""
        name: "Saint Paul Parish"
      }
      {
        abbreviation: ""
        name: "Saint Peter Parish"
      }
    ]
  }
  {
    name: "Dominican Republic"
    abbreviation: "DO"
    states: [
      {
        abbreviation: ""
        name: "La Romana"
      }
      {
        abbreviation: ""
        name: "Monte Cristi"
      }
      {
        abbreviation: ""
        name: "Pedernales"
      }
      {
        abbreviation: ""
        name: "Puerto Plata"
      }
      {
        abbreviation: ""
        name: "Salcedo"
      }
      {
        abbreviation: ""
        name: "Samana"
      }
      {
        abbreviation: ""
        name: "San Juan"
      }
      {
        abbreviation: ""
        name: "San Pedro De Macoris"
      }
      {
        abbreviation: ""
        name: "Santiago"
      }
      {
        abbreviation: ""
        name: "Hato Mayor"
      }
      {
        abbreviation: ""
        name: "La Vega"
      }
      {
        abbreviation: ""
        name: "Monte Plata"
      }
      {
        abbreviation: ""
        name: "San Cristobal"
      }
      {
        abbreviation: ""
        name: "Azua"
      }
      {
        abbreviation: ""
        name: "Baoruco"
      }
      {
        abbreviation: ""
        name: "Dajaban"
      }
      {
        abbreviation: ""
        name: "Duarte"
      }
      {
        abbreviation: ""
        name: "Espaillat"
      }
      {
        abbreviation: ""
        name: "Independencia"
      }
      {
        abbreviation: ""
        name: "La Altagracia"
      }
      {
        abbreviation: ""
        name: "Elias Pina"
      }
      {
        abbreviation: ""
        name: "La Romana"
      }
      {
        abbreviation: ""
        name: "Maria Trinidad Sanchez"
      }
      {
        abbreviation: ""
        name: "Monte Cristi"
      }
      {
        abbreviation: ""
        name: "Pedernales"
      }
      {
        abbreviation: ""
        name: "Puerto Plata"
      }
      {
        abbreviation: ""
        name: "Salcedo"
      }
      {
        abbreviation: ""
        name: "Samana"
      }
      {
        abbreviation: ""
        name: "Sanchez Ramirez"
      }
      {
        abbreviation: ""
        name: "San Juan"
      }
      {
        abbreviation: ""
        name: "San Pedro de Macoris"
      }
      {
        abbreviation: ""
        name: "Santiago"
      }
      {
        abbreviation: ""
        name: "Santiago Rodriguez"
      }
      {
        abbreviation: ""
        name: "Valverde"
      }
      {
        abbreviation: ""
        name: "El Seibo"
      }
      {
        abbreviation: ""
        name: "Hato Mayor"
      }
      {
        abbreviation: ""
        name: "La Vega"
      }
      {
        abbreviation: ""
        name: "Monsenor Nouel"
      }
      {
        abbreviation: ""
        name: "Monte Plata"
      }
      {
        abbreviation: ""
        name: "San Cristobal"
      }
      {
        abbreviation: ""
        name: "Distrito Nacional"
      }
      {
        abbreviation: ""
        name: "Peravia"
      }
      {
        abbreviation: ""
        name: "San Jose de Ocoa"
      }
    ]
  }
  {
    name: "Ecuador"
    abbreviation: "EC"
    states: [
      {
        abbreviation: ""
        name: "Galapagos"
      }
      {
        abbreviation: ""
        name: "Azuay"
      }
      {
        abbreviation: ""
        name: "Bolivar"
      }
      {
        abbreviation: ""
        name: "Canar"
      }
      {
        abbreviation: ""
        name: "Carchi"
      }
      {
        abbreviation: ""
        name: "Chimborazo"
      }
      {
        abbreviation: ""
        name: "Cotopaxi"
      }
      {
        abbreviation: ""
        name: "El Oro"
      }
      {
        abbreviation: ""
        name: "Esmeraldas"
      }
      {
        abbreviation: ""
        name: "Guayas"
      }
      {
        abbreviation: ""
        name: "Imbabura"
      }
      {
        abbreviation: ""
        name: "Loja"
      }
      {
        abbreviation: ""
        name: "Los Rios"
      }
      {
        abbreviation: ""
        name: "Manabi"
      }
      {
        abbreviation: ""
        name: "Morona-Santiago"
      }
      {
        abbreviation: ""
        name: "Pastaza"
      }
      {
        abbreviation: ""
        name: "Pichincha"
      }
      {
        abbreviation: ""
        name: "Tungurahua"
      }
      {
        abbreviation: ""
        name: "Zamora-Chinchipe"
      }
      {
        abbreviation: ""
        name: "Sucumbios"
      }
      {
        abbreviation: ""
        name: "Napo"
      }
      {
        abbreviation: ""
        name: "Orellana"
      }
    ]
  }
  {
    name: "Egypt"
    abbreviation: "EG"
    states: [
      {
        abbreviation: ""
        name: "Ad Daqahli-yah Governorate"
      }
      {
        abbreviation: ""
        name: "Al Bah r al Ahmar Governorate"
      }
      {
        abbreviation: ""
        name: "Al Buhayrah Governorate"
      }
      {
        abbreviation: ""
        name: "Al Fayyu-m Governorate"
      }
      {
        abbreviation: ""
        name: "Al Gharbi-yah Governorate"
      }
      {
        abbreviation: ""
        name: "Al Iskandari-yah Governorate"
      }
      {
        abbreviation: ""
        name: "Al Isma-`i-li-yah Governorate"
      }
      {
        abbreviation: ""
        name: "Al Ji-zah Governorate"
      }
      {
        abbreviation: ""
        name: "Al Minu-fi-yah Governorate"
      }
      {
        abbreviation: ""
        name: "Al Minya- Governorate"
      }
      {
        abbreviation: ""
        name: "Al Qa-hirah Governorate"
      }
      {
        abbreviation: ""
        name: "Al Qalyu-bi-yah Governorate"
      }
      {
        abbreviation: ""
        name: "Al Wa-di- al Jadi-d Governorate"
      }
      {
        abbreviation: ""
        name: "Ash Sharqi-yah Governorate"
      }
      {
        abbreviation: ""
        name: "As Suways Governorate"
      }
      {
        abbreviation: ""
        name: "Aswa-n Governorate"
      }
      {
        abbreviation: ""
        name: "Asyu-t Governorate"
      }
      {
        abbreviation: ""
        name: "Bani- Suwayf Governorate"
      }
      {
        abbreviation: ""
        name: "Bu-r Sa`i-d Governorate"
      }
      {
        abbreviation: ""
        name: "Dumya-t Governorate"
      }
      {
        abbreviation: ""
        name: "Kafr ash Shaykh Governorate"
      }
      {
        abbreviation: ""
        name: "Matru-h Governorate"
      }
      {
        abbreviation: ""
        name: "Qina- Governorate"
      }
      {
        abbreviation: ""
        name: "Su-ha-j Governorate"
      }
      {
        abbreviation: ""
        name: "Janu-b Si-na-' Governorate"
      }
      {
        abbreviation: ""
        name: "Shama-l Si-na-' Governorate"
      }
    ]
  }
  {
    name: "El Salvador"
    abbreviation: "SV"
    states: [
      {
        abbreviation: ""
        name: "Ahuachapan Department"
      }
      {
        abbreviation: ""
        name: "Cabanas Department"
      }
      {
        abbreviation: ""
        name: "Chalatenango Department"
      }
      {
        abbreviation: ""
        name: "Cuscatlan Department"
      }
      {
        abbreviation: ""
        name: "La Libertad Department"
      }
      {
        abbreviation: ""
        name: "La Paz Department"
      }
      {
        abbreviation: ""
        name: "La Union Department"
      }
      {
        abbreviation: ""
        name: "Morazan Department"
      }
      {
        abbreviation: ""
        name: "San Miguel Department"
      }
      {
        abbreviation: ""
        name: "San Salvador Department"
      }
      {
        abbreviation: ""
        name: "Santa Ana Department"
      }
      {
        abbreviation: ""
        name: "San Vicente Department"
      }
      {
        abbreviation: ""
        name: "Sonsonate Department"
      }
      {
        abbreviation: ""
        name: "Usulutan Department"
      }
    ]
  }
  {
    name: "Equatorial Guinea"
    abbreviation: "GQ"
    states: [
      {
        abbreviation: ""
        name: "Annobon"
      }
      {
        abbreviation: ""
        name: "Bioko Norte"
      }
      {
        abbreviation: ""
        name: "Bioko Sur"
      }
      {
        abbreviation: ""
        name: "Centro Sur"
      }
      {
        abbreviation: ""
        name: "Kie-Ntem"
      }
      {
        abbreviation: ""
        name: "Litoral"
      }
      {
        abbreviation: ""
        name: "Wele-Nzas"
      }
    ]
  }
  {
    name: "Eritrea"
    abbreviation: "ER"
    states: [
      {
        abbreviation: ""
        name: "A-nseba Region"
      }
      {
        abbreviation: ""
        name: "Debub Region"
      }
      {
        abbreviation: ""
        name: "Debubawi- Keyih Bahri- Region"
      }
      {
        abbreviation: ""
        name: "Gash Barka Region"
      }
      {
        abbreviation: ""
        name: "Ma'a-kel Region"
      }
      {
        abbreviation: ""
        name: "Seme-nawi- Keyih Bahri- Region"
      }
    ]
  }
  {
    name: "Estonia"
    abbreviation: "EE"
    states: [
      {
        abbreviation: ""
        name: "Kohtla-Jarve County"
      }
      {
        abbreviation: ""
        name: "Harju County"
      }
      {
        abbreviation: ""
        name: "Hiiu County"
      }
      {
        abbreviation: ""
        name: "Ida-Viru County"
      }
      {
        abbreviation: ""
        name: "Jarva County"
      }
      {
        abbreviation: ""
        name: "Jogeva County"
      }
      {
        abbreviation: ""
        name: "Laane County"
      }
      {
        abbreviation: ""
        name: "Laane-Viru County"
      }
      {
        abbreviation: ""
        name: "Parnu County"
      }
      {
        abbreviation: ""
        name: "Polva County"
      }
      {
        abbreviation: ""
        name: "Rapla County"
      }
      {
        abbreviation: ""
        name: "Saare County"
      }
      {
        abbreviation: ""
        name: "Tartu County"
      }
      {
        abbreviation: ""
        name: "Valga County"
      }
      {
        abbreviation: ""
        name: "Viljandi County"
      }
      {
        abbreviation: ""
        name: "Voru County"
      }
    ]
  }
  {
    name: "Ethiopia"
    abbreviation: "ET"
    states: [
      {
        abbreviation: ""
        name: "Addis Ababa"
      }
      {
        abbreviation: ""
        name: "Afar"
      }
      {
        abbreviation: ""
        name: "Amhara"
      }
      {
        abbreviation: ""
        name: "Benshangul Gumaz"
      }
      {
        abbreviation: ""
        name: "Dire Dawa"
      }
      {
        abbreviation: ""
        name: "Gambela Peoples"
      }
      {
        abbreviation: ""
        name: "Harari People"
      }
      {
        abbreviation: ""
        name: "Oromia"
      }
      {
        abbreviation: ""
        name: "Somali"
      }
      {
        abbreviation: ""
        name: "Tigray"
      }
      {
        abbreviation: ""
        name: "Southern Nations, Nationalities and Peoples"
      }
    ]
  }
  {
    name: "Fiji"
    abbreviation: "FJ"
    states: [
      {
        abbreviation: ""
        name: "Central"
      }
      {
        abbreviation: ""
        name: "Eastern"
      }
      {
        abbreviation: ""
        name: "Northern"
      }
      {
        abbreviation: ""
        name: "Rotuma"
      }
      {
        abbreviation: ""
        name: "Western"
      }
    ]
  }
  {
    name: "Finland"
    abbreviation: "FI"
    states: [
      {
        abbreviation: ""
        name: "Lapland"
      }
      {
        abbreviation: ""
        name: "Oulu"
      }
      {
        abbreviation: ""
        name: "Southern Finland"
      }
      {
        abbreviation: ""
        name: "Eastern Finland"
      }
      {
        abbreviation: ""
        name: "Western Finland"
      }
    ]
  }
  {
    name: "France"
    abbreviation: "FR"
    states: [
      {
        abbreviation: ""
        name: "Jura"
      }
      {
        abbreviation: ""
        name: "Aquitaine Region"
      }
      {
        abbreviation: ""
        name: "Basse-Normandie Region"
      }
      {
        abbreviation: ""
        name: "Bourgogne Region"
      }
      {
        abbreviation: ""
        name: "Bretagne Region"
      }
      {
        abbreviation: ""
        name: "Centre Region"
      }
      {
        abbreviation: ""
        name: "Champagne-Ardenne Region"
      }
      {
        abbreviation: ""
        name: "Corse Region"
      }
      {
        abbreviation: ""
        name: "Franche-Comte Region"
      }
      {
        abbreviation: ""
        name: "Haute-Normandie Region"
      }
      {
        abbreviation: ""
        name: "Ile-de-France Region"
      }
      {
        abbreviation: ""
        name: "Languedoc-Roussillon Region"
      }
      {
        abbreviation: ""
        name: "Limousin Region"
      }
      {
        abbreviation: ""
        name: "Lorraine Region"
      }
      {
        abbreviation: ""
        name: "Midi-Pyrenees Region"
      }
      {
        abbreviation: ""
        name: "Nord-Pas-de-Calais Region"
      }
      {
        abbreviation: ""
        name: "Pays de la Loire Region"
      }
      {
        abbreviation: ""
        name: "Picardie Region"
      }
      {
        abbreviation: ""
        name: "Poitou-Charentes Region"
      }
      {
        abbreviation: ""
        name: "Provence-Alpes-Cote d'Azur Region"
      }
      {
        abbreviation: ""
        name: "Rhone-Alpes Region"
      }
      {
        abbreviation: ""
        name: "Alsace Region"
      }
      {
        abbreviation: ""
        name: "Nord"
      }
    ]
  }
  {
    name: "Gabon"
    abbreviation: "GA"
    states: [
      {
        abbreviation: ""
        name: "Estuaire"
      }
      {
        abbreviation: ""
        name: "Haut-Ogooue"
      }
      {
        abbreviation: ""
        name: "Moyen-Ogooue"
      }
      {
        abbreviation: ""
        name: "Ngounie"
      }
      {
        abbreviation: ""
        name: "Nyanga"
      }
      {
        abbreviation: ""
        name: "Ogooue-Ivindo"
      }
      {
        abbreviation: ""
        name: "Ogooue-Lolo"
      }
      {
        abbreviation: ""
        name: "Ogooue-Maritime"
      }
      {
        abbreviation: ""
        name: "Woleu-Ntem"
      }
    ]
  }
  {
    name: "Gambia"
    abbreviation: "GM"
    states: [
      {
        abbreviation: ""
        name: "Banjul"
      }
      {
        abbreviation: ""
        name: "Lower River"
      }
      {
        abbreviation: ""
        name: "MacCarthy Island"
      }
      {
        abbreviation: ""
        name: "Upper River"
      }
      {
        abbreviation: ""
        name: "Western"
      }
      {
        abbreviation: ""
        name: "North Bank"
      }
    ]
  }
  {
    name: "Georgia"
    abbreviation: "GE"
    states: [
      {
        abbreviation: ""
        name: "Abkhazia"
      }
      {
        abbreviation: ""
        name: "Ajaria"
      }
      {
        abbreviation: ""
        name: "Tbilisi"
      }
    ]
  }
  {
    name: "Germany"
    abbreviation: "DE"
    states: [
      {
        abbreviation: ""
        name: "Baden-WÃ¼rttemberg State"
      }
      {
        abbreviation: ""
        name: "Free State of Bavaria"
      }
      {
        abbreviation: ""
        name: "Bremen"
      }
      {
        abbreviation: ""
        name: "Hamburg"
      }
      {
        abbreviation: ""
        name: "Hesse"
      }
      {
        abbreviation: ""
        name: "Lower Saxony State"
      }
      {
        abbreviation: ""
        name: "North Rhine-Westphalia State"
      }
      {
        abbreviation: ""
        name: "Rhineland-Palatinate State"
      }
      {
        abbreviation: ""
        name: "Saarland"
      }
      {
        abbreviation: ""
        name: "Schleswig-Holstein State"
      }
      {
        abbreviation: ""
        name: "Brandenburg"
      }
      {
        abbreviation: ""
        name: "Mecklenburg-West Pomerania State"
      }
      {
        abbreviation: ""
        name: "Free State of Saxony"
      }
      {
        abbreviation: ""
        name: "Sachsen-Anhalt State"
      }
      {
        abbreviation: ""
        name: "Free State of ThÃ¼ringia"
      }
      {
        abbreviation: ""
        name: "Berlin State"
      }
    ]
  }
  {
    name: "Ghana"
    abbreviation: "GH"
    states: [
      {
        abbreviation: ""
        name: "Greater Accra Region"
      }
      {
        abbreviation: ""
        name: "Ashanti Region"
      }
      {
        abbreviation: ""
        name: "Brong-Ahafo Region"
      }
      {
        abbreviation: ""
        name: "Central Region"
      }
      {
        abbreviation: ""
        name: "Eastern Region"
      }
      {
        abbreviation: ""
        name: "Northern Region"
      }
      {
        abbreviation: ""
        name: "Volta Region"
      }
      {
        abbreviation: ""
        name: "Western Region"
      }
      {
        abbreviation: ""
        name: "Upper East Region"
      }
      {
        abbreviation: ""
        name: "Upper West Region"
      }
    ]
  }
  {
    name: "Greece"
    abbreviation: "GR"
    states: [
      {
        abbreviation: ""
        name: "Peiraiws Prefecture"
      }
      {
        abbreviation: ""
        name: "Evros Prefecture"
      }
      {
        abbreviation: ""
        name: "Rodopi Prefecture"
      }
      {
        abbreviation: ""
        name: "Xanthi Prefecture"
      }
      {
        abbreviation: ""
        name: "Drama Prefecture"
      }
      {
        abbreviation: ""
        name: "Serrai Prefecture"
      }
      {
        abbreviation: ""
        name: "Kilkis Prefecture"
      }
      {
        abbreviation: ""
        name: "Pella Prefecture"
      }
      {
        abbreviation: ""
        name: "Florina Prefecture"
      }
      {
        abbreviation: ""
        name: "Kastoria Prefecture"
      }
      {
        abbreviation: ""
        name: "Grevena Prefecture"
      }
      {
        abbreviation: ""
        name: "Kozani Prefecture"
      }
      {
        abbreviation: ""
        name: "Imathia Prefecture"
      }
      {
        abbreviation: ""
        name: "Thessaloniki Prefecture"
      }
      {
        abbreviation: ""
        name: "Kavala Prefecture"
      }
      {
        abbreviation: ""
        name: "Chalkidiki Prefecture"
      }
      {
        abbreviation: ""
        name: "Pieria Prefecture"
      }
      {
        abbreviation: ""
        name: "Ioannina Prefecture"
      }
      {
        abbreviation: ""
        name: "Thesprotia Prefecture"
      }
      {
        abbreviation: ""
        name: "Preveza Prefecture"
      }
      {
        abbreviation: ""
        name: "Arta Prefecture"
      }
      {
        abbreviation: ""
        name: "Larisa Prefecture"
      }
      {
        abbreviation: ""
        name: "Trikala Prefecture"
      }
      {
        abbreviation: ""
        name: "Karditsa Prefecture"
      }
      {
        abbreviation: ""
        name: "Magnisia Prefecture"
      }
      {
        abbreviation: ""
        name: "Kerkyra Prefecture"
      }
      {
        abbreviation: ""
        name: "Lefkas Prefecture"
      }
      {
        abbreviation: ""
        name: "Kefallinia Prefecture"
      }
      {
        abbreviation: ""
        name: "Zakynthos Prefecture"
      }
      {
        abbreviation: ""
        name: "Fthiotis Prefecture"
      }
      {
        abbreviation: ""
        name: "Evrytania Prefecture"
      }
      {
        abbreviation: ""
        name: "Aitolia kai Akarnania Prefecture"
      }
      {
        abbreviation: ""
        name: "Fokidos Prefecture"
      }
      {
        abbreviation: ""
        name: "Voiotia Prefecture"
      }
      {
        abbreviation: ""
        name: "Evvoia Prefecture"
      }
      {
        abbreviation: ""
        name: "Attika Prefecture"
      }
      {
        abbreviation: ""
        name: "Argolis Prefecture"
      }
      {
        abbreviation: ""
        name: "Korinthia Prefecture"
      }
      {
        abbreviation: ""
        name: "Achai'a Prefecture"
      }
      {
        abbreviation: ""
        name: "Ileia Prefecture"
      }
      {
        abbreviation: ""
        name: "Messinia Prefecture"
      }
      {
        abbreviation: ""
        name: "Arkadia Prefecture"
      }
      {
        abbreviation: ""
        name: "Lakonia Prefecture"
      }
      {
        abbreviation: ""
        name: "Hania Prefecture"
      }
      {
        abbreviation: ""
        name: "Rethimnis Prefecture"
      }
      {
        abbreviation: ""
        name: "Irakleion Prefecture"
      }
      {
        abbreviation: ""
        name: "Lasithi Prefecture"
      }
      {
        abbreviation: ""
        name: "Dodekanisos Prefecture"
      }
      {
        abbreviation: ""
        name: "Samos Prefecture"
      }
      {
        abbreviation: ""
        name: "Kyklades Prefecture"
      }
      {
        abbreviation: ""
        name: "Chios Prefecture"
      }
      {
        abbreviation: ""
        name: "Lesvos Prefecture"
      }
    ]
  }
  {
    name: "Grenada"
    abbreviation: "GD"
    states: [
      {
        abbreviation: ""
        name: "Saint Andrew Parish"
      }
      {
        abbreviation: ""
        name: "Saint David Parish"
      }
      {
        abbreviation: ""
        name: "Saint George Parish"
      }
      {
        abbreviation: ""
        name: "Saint John Parish"
      }
      {
        abbreviation: ""
        name: "Saint Mark Parish"
      }
      {
        abbreviation: ""
        name: "Saint Patrick Parish"
      }
    ]
  }
  {
    name: "Guatemala"
    abbreviation: "GT"
    states: [
      {
        abbreviation: ""
        name: "Alta Verapaz Department"
      }
      {
        abbreviation: ""
        name: "Baja Verapaz Department"
      }
      {
        abbreviation: ""
        name: "Chimaltenango Department"
      }
      {
        abbreviation: ""
        name: "Chiquimula Department"
      }
      {
        abbreviation: ""
        name: "El Progreso Department"
      }
      {
        abbreviation: ""
        name: "Escuintla Department"
      }
      {
        abbreviation: ""
        name: "Guatemala Department"
      }
      {
        abbreviation: ""
        name: "Huehuetenango Department"
      }
      {
        abbreviation: ""
        name: "Izabal Department"
      }
      {
        abbreviation: ""
        name: "Jalapa Department"
      }
      {
        abbreviation: ""
        name: "Jutiapa Department"
      }
      {
        abbreviation: ""
        name: "Peten Department"
      }
      {
        abbreviation: ""
        name: "Quetzaltenango Department"
      }
      {
        abbreviation: ""
        name: "Quiche Department"
      }
      {
        abbreviation: ""
        name: "Retalhuleu Department"
      }
      {
        abbreviation: ""
        name: "Sacatepequez Department"
      }
      {
        abbreviation: ""
        name: "San Marcos Department"
      }
      {
        abbreviation: ""
        name: "Santa Rosa Department"
      }
      {
        abbreviation: ""
        name: "Solola Department"
      }
      {
        abbreviation: ""
        name: "Suchitepequez Department"
      }
      {
        abbreviation: ""
        name: "Totonicapan Department"
      }
      {
        abbreviation: ""
        name: "Zacapa Department"
      }
    ]
  }
  {
    name: "Guinea"
    abbreviation: "GN"
    states: [
      {
        abbreviation: ""
        name: "Beyla Prefecture"
      }
      {
        abbreviation: ""
        name: "Boffa Prefecture"
      }
      {
        abbreviation: ""
        name: "Boke Prefecture"
      }
      {
        abbreviation: ""
        name: "Conakry Special Zone"
      }
      {
        abbreviation: ""
        name: "Dabola Prefecture"
      }
      {
        abbreviation: ""
        name: "Dalaba Prefecture"
      }
      {
        abbreviation: ""
        name: "Dinguiraye Prefecture"
      }
      {
        abbreviation: ""
        name: "Faranah Prefecture"
      }
      {
        abbreviation: ""
        name: "Forecariah Prefecture"
      }
      {
        abbreviation: ""
        name: "Fria Prefecture"
      }
      {
        abbreviation: ""
        name: "Gaoual Prefecture"
      }
      {
        abbreviation: ""
        name: "Guekedou Prefecture"
      }
      {
        abbreviation: ""
        name: "Kerouane Prefecture"
      }
      {
        abbreviation: ""
        name: "Kindia Prefecture"
      }
      {
        abbreviation: ""
        name: "Kissidougou Prefecture"
      }
      {
        abbreviation: ""
        name: "Koundara Prefecture"
      }
      {
        abbreviation: ""
        name: "Kouroussa Prefecture"
      }
      {
        abbreviation: ""
        name: "Macenta Prefecture"
      }
      {
        abbreviation: ""
        name: "Mali Prefecture"
      }
      {
        abbreviation: ""
        name: "Mamou Prefecture"
      }
      {
        abbreviation: ""
        name: "Pita Prefecture"
      }
      {
        abbreviation: ""
        name: "Telimele Prefecture"
      }
      {
        abbreviation: ""
        name: "Tougue Prefecture"
      }
      {
        abbreviation: ""
        name: "Yomou Prefecture"
      }
      {
        abbreviation: ""
        name: "Coyah Prefecture"
      }
      {
        abbreviation: ""
        name: "Dubreka Prefecture"
      }
      {
        abbreviation: ""
        name: "Kankan Prefecture"
      }
      {
        abbreviation: ""
        name: "Koubia Prefecture"
      }
      {
        abbreviation: ""
        name: "Labe Prefecture"
      }
      {
        abbreviation: ""
        name: "Lelouma Prefecture"
      }
      {
        abbreviation: ""
        name: "Lola Prefecture"
      }
      {
        abbreviation: ""
        name: "Mandiana Prefecture"
      }
      {
        abbreviation: ""
        name: "Nzerekore Prefecture"
      }
      {
        abbreviation: ""
        name: "Siguiri Prefecture"
      }
    ]
  }
  {
    name: "Guinea-Bissau"
    abbreviation: "GW"
    states: [
      {
        abbreviation: ""
        name: "Bafata Region"
      }
      {
        abbreviation: ""
        name: "Quinara Region"
      }
      {
        abbreviation: ""
        name: "Oio Region"
      }
      {
        abbreviation: ""
        name: "Bolama Region"
      }
      {
        abbreviation: ""
        name: "Cacheu Region"
      }
      {
        abbreviation: ""
        name: "Tombali Region"
      }
      {
        abbreviation: ""
        name: "Gabu Region"
      }
      {
        abbreviation: ""
        name: "Bissau Region"
      }
      {
        abbreviation: ""
        name: "Biombo Region"
      }
    ]
  }
  {
    name: "Guyana"
    abbreviation: "GY"
    states: [
      {
        abbreviation: ""
        name: "Barima-Waini Region"
      }
      {
        abbreviation: ""
        name: "Cuyuni-Mazaruni Region"
      }
      {
        abbreviation: ""
        name: "Demerara-Mahaica Region"
      }
      {
        abbreviation: ""
        name: "East Berbice-Corentyne Region"
      }
      {
        abbreviation: ""
        name: "Essequibo Islands-West Demerara Region"
      }
      {
        abbreviation: ""
        name: "Mahaica-Berbice Region"
      }
      {
        abbreviation: ""
        name: "Pomeroon-Supenaam Region"
      }
      {
        abbreviation: ""
        name: "Potaro-Siparuni Region"
      }
      {
        abbreviation: ""
        name: "Upper Demerara-Berbice Region"
      }
      {
        abbreviation: ""
        name: "Upper Takutu-Upper Essequibo Region"
      }
    ]
  }
  {
    name: "Haiti"
    abbreviation: "HT"
    states: [
      {
        abbreviation: ""
        name: "Nord-Ouest Department"
      }
      {
        abbreviation: ""
        name: "L' Artibonite Department"
      }
      {
        abbreviation: ""
        name: "Centre Department"
      }
      {
        abbreviation: ""
        name: "Nord Department"
      }
      {
        abbreviation: ""
        name: "Nord-Est Department"
      }
      {
        abbreviation: ""
        name: "Ouest Department"
      }
      {
        abbreviation: ""
        name: "Sud Department"
      }
      {
        abbreviation: ""
        name: "Sud-Est Department"
      }
      {
        abbreviation: ""
        name: "Grand'Anse Department"
      }
      {
        abbreviation: ""
        name: "Nippes Department"
      }
    ]
  }
  {
    name: "Honduras"
    abbreviation: "HN"
    states: [
      {
        abbreviation: ""
        name: "Atlantida Department"
      }
      {
        abbreviation: ""
        name: "Choluteca Department"
      }
      {
        abbreviation: ""
        name: "Colon Department"
      }
      {
        abbreviation: ""
        name: "Comayagua Department"
      }
      {
        abbreviation: ""
        name: "Copan Department"
      }
      {
        abbreviation: ""
        name: "Cortes Department"
      }
      {
        abbreviation: ""
        name: "El Paraiso Department"
      }
      {
        abbreviation: ""
        name: "Francisco Morazan Department"
      }
      {
        abbreviation: ""
        name: "Gracias a Dios Department"
      }
      {
        abbreviation: ""
        name: "Intibuca Department"
      }
      {
        abbreviation: ""
        name: "Islas de la Bahia Department"
      }
      {
        abbreviation: ""
        name: "La Paz department"
      }
      {
        abbreviation: ""
        name: "Lempira Department"
      }
      {
        abbreviation: ""
        name: "Ocotepeque Department"
      }
      {
        abbreviation: ""
        name: "Olancho Department"
      }
      {
        abbreviation: ""
        name: "Santa Barbara Department"
      }
      {
        abbreviation: ""
        name: "Valle Department"
      }
      {
        abbreviation: ""
        name: "Yoro Department"
      }
    ]
  }
  {
    name: "Hungary"
    abbreviation: "HU"
    states: [
      {
        abbreviation: ""
        name: "Bacs-Kiskun County"
      }
      {
        abbreviation: ""
        name: "Baranya County"
      }
      {
        abbreviation: ""
        name: "Bekes County"
      }
      {
        abbreviation: ""
        name: "Borsod-Abauj-Zemplen County"
      }
      {
        abbreviation: ""
        name: "Budapest Capital City"
      }
      {
        abbreviation: ""
        name: "Csongrad County"
      }
      {
        abbreviation: ""
        name: "Debrecen"
      }
      {
        abbreviation: ""
        name: "Fejer County"
      }
      {
        abbreviation: ""
        name: "Gyo'r-Moson-Sopron County"
      }
      {
        abbreviation: ""
        name: "Hajdu-Bihar County"
      }
      {
        abbreviation: ""
        name: "Heves County"
      }
      {
        abbreviation: ""
        name: "Komarom-Esztergom County"
      }
      {
        abbreviation: ""
        name: "Miskolc Urban County"
      }
      {
        abbreviation: ""
        name: "Nograd County"
      }
      {
        abbreviation: ""
        name: "Pecs Urban County"
      }
      {
        abbreviation: ""
        name: "Pest County"
      }
      {
        abbreviation: ""
        name: "Somogy County"
      }
      {
        abbreviation: ""
        name: "Szabolcs-Szatmar-Bereg County"
      }
      {
        abbreviation: ""
        name: "Szeged Urban County"
      }
      {
        abbreviation: ""
        name: "Jasz-Nagykun-Szolnok County"
      }
      {
        abbreviation: ""
        name: "Tolna County"
      }
      {
        abbreviation: ""
        name: "Vas County"
      }
      {
        abbreviation: ""
        name: "Veszprem County"
      }
      {
        abbreviation: ""
        name: "Zala County"
      }
      {
        abbreviation: ""
        name: "Gyo'r Urban County"
      }
      {
        abbreviation: ""
        name: "Bekescsaba"
      }
      {
        abbreviation: ""
        name: "Dunaujvaros"
      }
      {
        abbreviation: ""
        name: "Eger"
      }
      {
        abbreviation: ""
        name: "HÃ³dmezovÃ¡sÃ¡rhely"
      }
      {
        abbreviation: ""
        name: "Kaposvar"
      }
      {
        abbreviation: ""
        name: "Kecskemet"
      }
      {
        abbreviation: ""
        name: "Nagykanizsa"
      }
      {
        abbreviation: ""
        name: "Nyiregyhaza"
      }
      {
        abbreviation: ""
        name: "Sopron"
      }
      {
        abbreviation: ""
        name: "Szekesfehervar"
      }
      {
        abbreviation: ""
        name: "Szolnok"
      }
      {
        abbreviation: ""
        name: "Szombathely"
      }
      {
        abbreviation: ""
        name: "Tatabanya"
      }
      {
        abbreviation: ""
        name: "Veszprem"
      }
      {
        abbreviation: ""
        name: "Zalaegerszeg"
      }
      {
        abbreviation: ""
        name: "Salgotarjan"
      }
      {
        abbreviation: ""
        name: "Szekszard"
      }
      {
        abbreviation: ""
        name: "Erd"
      }
      {
        abbreviation: ""
        name: "Erd"
      }
    ]
  }
  {
    name: "Iceland"
    abbreviation: "IS"
    states: [
      {
        abbreviation: ""
        name: "East"
      }
      {
        abbreviation: ""
        name: "Capital Region"
      }
      {
        abbreviation: ""
        name: "Northeast"
      }
      {
        abbreviation: ""
        name: "Northwest"
      }
      {
        abbreviation: ""
        name: "South"
      }
      {
        abbreviation: ""
        name: "Southern Peninsula"
      }
      {
        abbreviation: ""
        name: "West Fjords"
      }
      {
        abbreviation: ""
        name: "West"
      }
      {
        abbreviation: ""
        name: "Reykjavik"
      }
    ]
  }
  {
    name: "India"
    abbreviation: "IN"
    states: [
      {
        abbreviation: ""
        name: "Andaman and Nicobar Islands Union Territory"
      }
      {
        abbreviation: ""
        name: "Andhra Pradesh State"
      }
      {
        abbreviation: ""
        name: "Assam State"
      }
      {
        abbreviation: ""
        name: "Chandigarh Union Territory"
      }
      {
        abbreviation: ""
        name: "Dadra and Nagar Haveli Union Territory"
      }
      {
        abbreviation: ""
        name: "Delhi Union Territory"
      }
      {
        abbreviation: ""
        name: "Gujarat State"
      }
      {
        abbreviation: ""
        name: "Haryana State"
      }
      {
        abbreviation: ""
        name: "Himachal Pradesh State"
      }
      {
        abbreviation: ""
        name: "Jammu and Kashmir State"
      }
      {
        abbreviation: ""
        name: "Kerala State"
      }
      {
        abbreviation: ""
        name: "Lakshadweep Union Territory"
      }
      {
        abbreviation: ""
        name: "Maharashtra State"
      }
      {
        abbreviation: ""
        name: "Manipur State"
      }
      {
        abbreviation: ""
        name: "Meghalaya State"
      }
      {
        abbreviation: ""
        name: "Karnataka State"
      }
      {
        abbreviation: ""
        name: "Nagaland State"
      }
      {
        abbreviation: ""
        name: "Orissa State"
      }
      {
        abbreviation: ""
        name: "Puducherry Union Territory"
      }
      {
        abbreviation: ""
        name: "Punjab State"
      }
      {
        abbreviation: ""
        name: "Rajasthan State"
      }
      {
        abbreviation: ""
        name: "Tamil Nadu State"
      }
      {
        abbreviation: ""
        name: "Tripura State"
      }
      {
        abbreviation: ""
        name: "West Bengal State"
      }
      {
        abbreviation: ""
        name: "Sikkim State"
      }
      {
        abbreviation: ""
        name: "Arunachal Pradesh State"
      }
      {
        abbreviation: ""
        name: "Mizoram State"
      }
      {
        abbreviation: ""
        name: "Daman and Diu Union Territory"
      }
      {
        abbreviation: ""
        name: "Goa State"
      }
      {
        abbreviation: ""
        name: "Bihar State"
      }
      {
        abbreviation: ""
        name: "Madhya Pradesh State"
      }
      {
        abbreviation: ""
        name: "Uttar Pradesh State"
      }
      {
        abbreviation: ""
        name: "Chhattisgarh State"
      }
      {
        abbreviation: ""
        name: "Jharkhand State"
      }
      {
        abbreviation: ""
        name: "Uttarakhand State"
      }
      {
        abbreviation: ""
        name: "Uttarakhand State"
      }
    ]
  }
  {
    name: "Indonesia"
    abbreviation: "ID"
    states: [
      {
        abbreviation: ""
        name: "Aceh Special Region"
      }
      {
        abbreviation: ""
        name: "Bali"
      }
      {
        abbreviation: ""
        name: "Bengkulu"
      }
      {
        abbreviation: ""
        name: "Jakarta Raya Special Capital City District"
      }
      {
        abbreviation: ""
        name: "Jambi"
      }
      {
        abbreviation: ""
        name: "Jawa Tengah"
      }
      {
        abbreviation: ""
        name: "Jawa Timur"
      }
      {
        abbreviation: ""
        name: "Yogyakarta Special Region"
      }
      {
        abbreviation: ""
        name: "Kalimantan Barat"
      }
      {
        abbreviation: ""
        name: "Kalimantan Selatan"
      }
      {
        abbreviation: ""
        name: "Kalimantan Tengah"
      }
      {
        abbreviation: ""
        name: "Kalimantan Timur"
      }
      {
        abbreviation: ""
        name: "Lampung"
      }
      {
        abbreviation: ""
        name: "Maluku"
      }
      {
        abbreviation: ""
        name: "Nusa Tenggara Barat"
      }
      {
        abbreviation: ""
        name: "Nusa Tenggara Timur"
      }
      {
        abbreviation: ""
        name: "Sulawesi Tengah"
      }
      {
        abbreviation: ""
        name: "Sulawesi Tenggara"
      }
      {
        abbreviation: ""
        name: "Sumatera Barat"
      }
      {
        abbreviation: ""
        name: "Sumatera Utara"
      }
      {
        abbreviation: ""
        name: "Maluku Utara"
      }
      {
        abbreviation: ""
        name: "Jawa Barat"
      }
      {
        abbreviation: ""
        name: "Sulawesi Utara"
      }
      {
        abbreviation: ""
        name: "Sumatera Selatan"
      }
      {
        abbreviation: ""
        name: "Banten"
      }
      {
        abbreviation: ""
        name: "Gorontalo"
      }
      {
        abbreviation: ""
        name: "Papua"
      }
      {
        abbreviation: ""
        name: "Riau"
      }
      {
        abbreviation: ""
        name: "Sulawesi Selatan"
      }
      {
        abbreviation: ""
        name: "Irian Jaya Barat"
      }
      {
        abbreviation: ""
        name: "Irian Jaya Barat"
      }
      {
        abbreviation: ""
        name: "Kepulauan Riau"
      }
      {
        abbreviation: ""
        name: "Sulawesi Barat"
      }
      {
        abbreviation: ""
        name: "Kepulauan Bangka Belitung"
      }
    ]
  }
  {
    name: "Iran"
    abbreviation: "IR"
    states: [
      {
        abbreviation: ""
        name: "Osta-n-e A-z?arba-yja-n-e Gharbi-"
      }
      {
        abbreviation: ""
        name: "Osta-n-e A-z?arba-yja-n-e Sharqi-"
      }
      {
        abbreviation: ""
        name: "Chaha-r Maha-ll va Bakhti-a-r"
      }
      {
        abbreviation: ""
        name: "Si-sta-n va Balu-chesta-n"
      }
      {
        abbreviation: ""
        name: "Osta-n-e Kohgi-lu-yeh va Bu-yer Ahmad"
      }
      {
        abbreviation: ""
        name: "Fa-rs"
      }
      {
        abbreviation: ""
        name: "Gi-la-n"
      }
      {
        abbreviation: ""
        name: "Hamada-n"
      }
      {
        abbreviation: ""
        name: "I-la-m"
      }
      {
        abbreviation: ""
        name: "Hormozga-n"
      }
      {
        abbreviation: ""
        name: "Osta-n-e Kerma-nsha-h"
      }
      {
        abbreviation: ""
        name: "Khu-zesta-n"
      }
      {
        abbreviation: ""
        name: "Kordesta-n"
      }
      {
        abbreviation: ""
        name: "Semna-n"
      }
      {
        abbreviation: ""
        name: "Osta-n-e Bu-shehr"
      }
      {
        abbreviation: ""
        name: "Loresta-n"
      }
      {
        abbreviation: ""
        name: "Tehra-n"
      }
      {
        abbreviation: ""
        name: "Esfaha-n"
      }
      {
        abbreviation: ""
        name: "Kerma-n"
      }
      {
        abbreviation: ""
        name: "Khorasan"
      }
      {
        abbreviation: ""
        name: "Ardabi-l"
      }
      {
        abbreviation: ""
        name: "Markazi"
      }
      {
        abbreviation: ""
        name: "Ma-zandara-n"
      }
      {
        abbreviation: ""
        name: "Zanjan"
      }
      {
        abbreviation: ""
        name: "Golesta-n"
      }
      {
        abbreviation: ""
        name: "Qazvi-n"
      }
      {
        abbreviation: ""
        name: "Qom"
      }
      {
        abbreviation: ""
        name: "Yazd"
      }
      {
        abbreviation: ""
        name: "Khora-sa-n-e Janu-bi-"
      }
      {
        abbreviation: ""
        name: "Khora-sa-n-e Razavi-"
      }
      {
        abbreviation: ""
        name: "Khora-sa-n-e Shema-li-"
      }
    ]
  }
  {
    name: "Iraq"
    abbreviation: "IQ"
    states: [
      {
        abbreviation: ""
        name: "Al Anbar Governorate"
      }
      {
        abbreviation: ""
        name: "Al Basrah"
      }
      {
        abbreviation: ""
        name: "Al Muthanna Governorate"
      }
      {
        abbreviation: ""
        name: "Al Qadisiyah Governorate"
      }
      {
        abbreviation: ""
        name: "As Sulaymaniyah Governorate"
      }
      {
        abbreviation: ""
        name: "Babil Governorate"
      }
      {
        abbreviation: ""
        name: "Baghdad Governorate"
      }
      {
        abbreviation: ""
        name: "Dahuk Governorate"
      }
      {
        abbreviation: ""
        name: "Dhi Qar Governorate"
      }
      {
        abbreviation: ""
        name: "Diyala Governorate"
      }
      {
        abbreviation: ""
        name: "Arbil Governorate"
      }
      {
        abbreviation: ""
        name: "Karbala-' Governorate"
      }
      {
        abbreviation: ""
        name: "At Ta'mi-m Governorate"
      }
      {
        abbreviation: ""
        name: "Maysan Governorate"
      }
      {
        abbreviation: ""
        name: "Ninawa Governorate"
      }
      {
        abbreviation: ""
        name: "Wasit"
      }
      {
        abbreviation: ""
        name: "An Najaf Governorate"
      }
      {
        abbreviation: ""
        name: "Salah ad Din"
      }
    ]
  }
  {
    name: "Ireland"
    abbreviation: "IE"
    states: [
      {
        abbreviation: ""
        name: "County Carlow"
      }
      {
        abbreviation: ""
        name: "County Cavan"
      }
      {
        abbreviation: ""
        name: "County Clare"
      }
      {
        abbreviation: ""
        name: "County Cork"
      }
      {
        abbreviation: ""
        name: "County Cork"
      }
      {
        abbreviation: ""
        name: "County Donegal"
      }
      {
        abbreviation: ""
        name: "County Dublin"
      }
      {
        abbreviation: ""
        name: "County Galway"
      }
      {
        abbreviation: ""
        name: "County Kerry"
      }
      {
        abbreviation: ""
        name: "County Kildare"
      }
      {
        abbreviation: ""
        name: "County Kilkenny"
      }
      {
        abbreviation: ""
        name: "County Leitrim"
      }
      {
        abbreviation: ""
        name: "County Laois"
      }
      {
        abbreviation: ""
        name: "County Limerick"
      }
      {
        abbreviation: ""
        name: "County Longford"
      }
      {
        abbreviation: ""
        name: "County Louth"
      }
      {
        abbreviation: ""
        name: "County Mayo"
      }
      {
        abbreviation: ""
        name: "County Meath"
      }
      {
        abbreviation: ""
        name: "County Monaghan"
      }
      {
        abbreviation: ""
        name: "County Offaly"
      }
      {
        abbreviation: ""
        name: "County Roscommon"
      }
      {
        abbreviation: ""
        name: "County Sligo"
      }
      {
        abbreviation: ""
        name: "County Tipperary"
      }
      {
        abbreviation: ""
        name: "County Waterford"
      }
      {
        abbreviation: ""
        name: "County Westmeath"
      }
      {
        abbreviation: ""
        name: "County Wexford"
      }
      {
        abbreviation: ""
        name: "County Wicklow"
      }
    ]
  }
  {
    name: "Israel"
    abbreviation: "IL"
    states: [
      {
        abbreviation: ""
        name: "HaDarom District"
      }
      {
        abbreviation: ""
        name: "HaMerkaz District"
      }
      {
        abbreviation: ""
        name: "HaTzafon District"
      }
      {
        abbreviation: ""
        name: "Hefa District"
      }
      {
        abbreviation: ""
        name: "Tel Aviv District"
      }
      {
        abbreviation: ""
        name: "Yerushalayim District"
      }
    ]
  }
  {
    name: "Italy"
    abbreviation: "IT"
    states: [
      {
        abbreviation: ""
        name: "Abruzzo Region"
      }
      {
        abbreviation: ""
        name: "Basilicata Region"
      }
      {
        abbreviation: ""
        name: "Calabria Region"
      }
      {
        abbreviation: ""
        name: "Campania Region"
      }
      {
        abbreviation: ""
        name: "Emilia-Romagna Region"
      }
      {
        abbreviation: ""
        name: "Friuli-Venezia Giulia Autonomous Region"
      }
      {
        abbreviation: ""
        name: "Lazio Region"
      }
      {
        abbreviation: ""
        name: "Liguria Region"
      }
      {
        abbreviation: ""
        name: "Lombardia Region"
      }
      {
        abbreviation: ""
        name: "Marche Region"
      }
      {
        abbreviation: ""
        name: "Molise Region"
      }
      {
        abbreviation: ""
        name: "Piemonte Region"
      }
      {
        abbreviation: ""
        name: "Puglia Region"
      }
      {
        abbreviation: ""
        name: "Sardegna Autonomous Region"
      }
      {
        abbreviation: ""
        name: "Sicilia Autonomous Region"
      }
      {
        abbreviation: ""
        name: "Toscana Region"
      }
      {
        abbreviation: ""
        name: "Trentino-Alto Adige Autonomous Region"
      }
      {
        abbreviation: ""
        name: "Umbria Region"
      }
      {
        abbreviation: ""
        name: "Valle d'Aosta Autonomous Region"
      }
      {
        abbreviation: ""
        name: "Veneto Region"
      }
      {
        abbreviation: ""
        name: "Bari"
      }
    ]
  }
  {
    name: "Jamaica"
    abbreviation: "JM"
    states: [
      {
        abbreviation: ""
        name: "Clarendon Parish"
      }
      {
        abbreviation: ""
        name: "Hanover Parish"
      }
      {
        abbreviation: ""
        name: "Manchester Parish"
      }
      {
        abbreviation: ""
        name: "Portland Parish"
      }
      {
        abbreviation: ""
        name: "Saint Andrew Parish"
      }
      {
        abbreviation: ""
        name: "Saint Ann Parish"
      }
      {
        abbreviation: ""
        name: "Saint Catherine Parish"
      }
      {
        abbreviation: ""
        name: "Saint Elizabeth Parish"
      }
      {
        abbreviation: ""
        name: "Saint James Parish"
      }
      {
        abbreviation: ""
        name: "Saint Mary Parish"
      }
      {
        abbreviation: ""
        name: "Saint Thomas Parish"
      }
      {
        abbreviation: ""
        name: "Trelawny Parish"
      }
      {
        abbreviation: ""
        name: "Westmoreland Parish"
      }
      {
        abbreviation: ""
        name: "Kingston Parish"
      }
    ]
  }
  {
    name: "Japan"
    abbreviation: "JP"
    states: [
      {
        abbreviation: ""
        name: "Aichi Prefecture"
      }
      {
        abbreviation: ""
        name: "Akita Prefecture"
      }
      {
        abbreviation: ""
        name: "Aomori Prefecture"
      }
      {
        abbreviation: ""
        name: "Chiba Prefecture"
      }
      {
        abbreviation: ""
        name: "Ehime Prefecture"
      }
      {
        abbreviation: ""
        name: "Fukui Prefecture"
      }
      {
        abbreviation: ""
        name: "Fukuoka Prefecture"
      }
      {
        abbreviation: ""
        name: "Fukushima Prefecture"
      }
      {
        abbreviation: ""
        name: "Gifu Prefecture"
      }
      {
        abbreviation: ""
        name: "Gunma Prefecture"
      }
      {
        abbreviation: ""
        name: "Hiroshima Prefecture"
      }
      {
        abbreviation: ""
        name: "Hokkaido- Prefecture"
      }
      {
        abbreviation: ""
        name: "Hyo-go Prefecture"
      }
      {
        abbreviation: ""
        name: "Ibaraki Prefecture"
      }
      {
        abbreviation: ""
        name: "Ishikawa Prefecture"
      }
      {
        abbreviation: ""
        name: "Iwate Prefecture"
      }
      {
        abbreviation: ""
        name: "Kagawa Prefecture"
      }
      {
        abbreviation: ""
        name: "Kagoshima Prefecture"
      }
      {
        abbreviation: ""
        name: "Kanagawa Prefecture"
      }
      {
        abbreviation: ""
        name: "Ko-chi Prefecture"
      }
      {
        abbreviation: ""
        name: "Kumamoto Prefecture"
      }
      {
        abbreviation: ""
        name: "Kyo-to Prefecture"
      }
      {
        abbreviation: ""
        name: "Mie Prefecture"
      }
      {
        abbreviation: ""
        name: "Miyagi Prefecture"
      }
      {
        abbreviation: ""
        name: "Miyazaki Prefecture"
      }
      {
        abbreviation: ""
        name: "Nagano Prefecture"
      }
      {
        abbreviation: ""
        name: "Nagasaki Prefecture"
      }
      {
        abbreviation: ""
        name: "Nara Prefecture"
      }
      {
        abbreviation: ""
        name: "Niigata Prefecture"
      }
      {
        abbreviation: ""
        name: "O-ita Prefecture"
      }
      {
        abbreviation: ""
        name: "Okayama Prefecture"
      }
      {
        abbreviation: ""
        name: "O-saka Prefecture"
      }
      {
        abbreviation: ""
        name: "Saga Prefecture"
      }
      {
        abbreviation: ""
        name: "Saitama Prefecture"
      }
      {
        abbreviation: ""
        name: "Shiga Prefecture"
      }
      {
        abbreviation: ""
        name: "Shimane Prefecture"
      }
      {
        abbreviation: ""
        name: "Shizuoka Prefecture"
      }
      {
        abbreviation: ""
        name: "Tochigi Prefecture"
      }
      {
        abbreviation: ""
        name: "Tokushima Prefecture"
      }
      {
        abbreviation: ""
        name: "To-kyo- Prefecture"
      }
      {
        abbreviation: ""
        name: "Tottori Prefecture"
      }
      {
        abbreviation: ""
        name: "Toyama Prefecture"
      }
      {
        abbreviation: ""
        name: "Wakayama Prefecture"
      }
      {
        abbreviation: ""
        name: "Yamagata Prefecture"
      }
      {
        abbreviation: ""
        name: "Yamaguchi Prefecture"
      }
      {
        abbreviation: ""
        name: "Yamanashi Prefecture"
      }
      {
        abbreviation: ""
        name: "Okinawa Prefecture"
      }
    ]
  }
  {
    name: "Jordan"
    abbreviation: "JO"
    states: [
      {
        abbreviation: ""
        name: "Balqa Governorate"
      }
      {
        abbreviation: ""
        name: "Karak Governorate"
      }
      {
        abbreviation: ""
        name: "Al Mafraq"
      }
      {
        abbreviation: ""
        name: "Tafilah Governorate"
      }
      {
        abbreviation: ""
        name: "Az Zarqa"
      }
      {
        abbreviation: ""
        name: "Irbid"
      }
      {
        abbreviation: ""
        name: "Amman Governorate"
      }
      {
        abbreviation: ""
        name: "Aqaba Governorate"
      }
    ]
  }
  {
    name: "Kazakhstan"
    abbreviation: "KZ"
    states: [
      {
        abbreviation: ""
        name: "Astana"
      }
      {
        abbreviation: ""
        name: "Qyzylorda"
      }
      {
        abbreviation: ""
        name: "Zhambyl"
      }
    ]
  }
  {
    name: "Kenya"
    abbreviation: "KE"
    states: [
      {
        abbreviation: ""
        name: "Central"
      }
      {
        abbreviation: ""
        name: "Coast"
      }
      {
        abbreviation: ""
        name: "Eastern"
      }
      {
        abbreviation: ""
        name: "Nairobi Area"
      }
      {
        abbreviation: ""
        name: "North Eastern"
      }
      {
        abbreviation: ""
        name: "Nyanza"
      }
      {
        abbreviation: ""
        name: "Rift Valley"
      }
      {
        abbreviation: ""
        name: "Western"
      }
      {
        abbreviation: ""
        name: "Western"
      }
    ]
  }
  {
    name: "Kiribati"
    abbreviation: "KI"
    states: [
      {
        abbreviation: ""
        name: "Line Islands"
      }
      {
        abbreviation: ""
        name: "Phoenix Islands"
      }
      {
        abbreviation: ""
        name: "Gilbert Islands"
      }
    ]
  }
  {
    name: "Korea, Democratic People's Republic Of"
    abbreviation: "KP"
    states: [
      {
        abbreviation: ""
        name: "Chagang"
      }
      {
        abbreviation: ""
        name: "Chagang"
      }
      {
        abbreviation: ""
        name: "Hamgyong"
      }
      {
        abbreviation: ""
        name: "Hamgyong"
      }
      {
        abbreviation: ""
        name: "Hwanghae -namdo"
      }
      {
        abbreviation: ""
        name: "Hwanghae -namdo"
      }
      {
        abbreviation: ""
        name: "Hwanghae -bukto"
      }
      {
        abbreviation: ""
        name: "Hwanghae -bukto"
      }
      {
        abbreviation: ""
        name: "Kaesong Municipalite"
      }
      {
        abbreviation: ""
        name: "P'yongan -bukto"
      }
      {
        abbreviation: ""
        name: "P'yongan -bukto"
      }
      {
        abbreviation: ""
        name: "P'yongyang Municipalite"
      }
      {
        abbreviation: ""
        name: "P'yongyang Municipalite"
      }
      {
        abbreviation: ""
        name: "Yanggang"
      }
      {
        abbreviation: ""
        name: "Yanggang"
      }
      {
        abbreviation: ""
        name: "Namp'o Municipalite"
      }
      {
        abbreviation: ""
        name: "P'yongan -namdo"
      }
      {
        abbreviation: ""
        name: "P'yongan -namdo"
      }
      {
        abbreviation: ""
        name: "Hamgyong"
      }
      {
        abbreviation: ""
        name: "Hamgyong"
      }
      {
        abbreviation: ""
        name: "Najin Sonbong-si Municipalite"
      }
    ]
  }
  {
    name: "Korea, Republic of"
    abbreviation: "KR"
    states: [
      {
        abbreviation: ""
        name: "Kangwon"
      }
      {
        abbreviation: ""
        name: "Ulsan-gwangyoksi"
      }
    ]
  }
  {
    name: "Kuwait"
    abbreviation: "KW"
    states: [
      {
        abbreviation: ""
        name: "Al Wafrah Muha-fazat"
      }
      {
        abbreviation: ""
        name: "Al `A-simah Governorate"
      }
      {
        abbreviation: ""
        name: "Al Ahmadi- Governorate"
      }
      {
        abbreviation: ""
        name: "Al Jahra-' Governorate"
      }
      {
        abbreviation: ""
        name: "Hawalli"
      }
    ]
  }
  {
    name: "Kyrgyzstan"
    abbreviation: "KG"
    states: [
      {
        abbreviation: ""
        name: "Bishkek"
      }
      {
        abbreviation: ""
        name: "Jalal-Abad"
      }
      {
        abbreviation: ""
        name: "Naryn"
      }
      {
        abbreviation: ""
        name: "Talas"
      }
      {
        abbreviation: ""
        name: "Ysyk-Kol"
      }
      {
        abbreviation: ""
        name: "Osh"
      }
      {
        abbreviation: ""
        name: "Batken"
      }
    ]
  }
  {
    name: "Laos"
    abbreviation: "LA"
    states: [
      {
        abbreviation: ""
        name: "Attapu"
      }
      {
        abbreviation: ""
        name: "Champasak"
      }
      {
        abbreviation: ""
        name: "Houaphan"
      }
      {
        abbreviation: ""
        name: "Oudomxai"
      }
      {
        abbreviation: ""
        name: "Xaignabouli"
      }
      {
        abbreviation: ""
        name: "Xiangkhoang"
      }
      {
        abbreviation: ""
        name: "Khammouan"
      }
      {
        abbreviation: ""
        name: "Louang Namtha"
      }
      {
        abbreviation: ""
        name: "Louangphrabang"
      }
      {
        abbreviation: ""
        name: "Phongsali"
      }
      {
        abbreviation: ""
        name: "Salavan"
      }
      {
        abbreviation: ""
        name: "Savannakhet"
      }
      {
        abbreviation: ""
        name: "Viangchan"
      }
      {
        abbreviation: ""
        name: "Bokeo"
      }
      {
        abbreviation: ""
        name: "Bolikhamxai"
      }
      {
        abbreviation: ""
        name: "Viangchan Municipality"
      }
      {
        abbreviation: ""
        name: "Xaisomboun Special Zone"
      }
      {
        abbreviation: ""
        name: "Xekong"
      }
    ]
  }
  {
    name: "Latvia"
    abbreviation: "LV"
    states: [
      {
        abbreviation: ""
        name: "Dobeles County"
      }
      {
        abbreviation: ""
        name: "Aizkraukles County"
      }
      {
        abbreviation: ""
        name: "Alu-ksnes County"
      }
      {
        abbreviation: ""
        name: "Balvu County"
      }
      {
        abbreviation: ""
        name: "Bauskas County"
      }
      {
        abbreviation: ""
        name: "Ce-su County"
      }
      {
        abbreviation: ""
        name: "Daugavpils Municipality"
      }
      {
        abbreviation: ""
        name: "Daugavpils County"
      }
      {
        abbreviation: ""
        name: "Gulbenes County"
      }
      {
        abbreviation: ""
        name: "Je-kabpils County"
      }
      {
        abbreviation: ""
        name: "Kra-slavas County"
      }
      {
        abbreviation: ""
        name: "Kuldi-gas County"
      }
      {
        abbreviation: ""
        name: "Liepa-ja Municipality"
      }
      {
        abbreviation: ""
        name: "Liepa-jas County"
      }
      {
        abbreviation: ""
        name: "Limbazu County"
      }
      {
        abbreviation: ""
        name: "Ludzas County"
      }
      {
        abbreviation: ""
        name: "Madonas County"
      }
      {
        abbreviation: ""
        name: "Ogres County"
      }
      {
        abbreviation: ""
        name: "Preil"
      }
      {
        abbreviation: ""
        name: "Re-zekne Municipality"
      }
      {
        abbreviation: ""
        name: "Re-zeknes County"
      }
      {
        abbreviation: ""
        name: "Ri-ga Municipality"
      }
      {
        abbreviation: ""
        name: "Ri-gas County"
      }
      {
        abbreviation: ""
        name: "Saldus County"
      }
      {
        abbreviation: ""
        name: "Talsu County"
      }
      {
        abbreviation: ""
        name: "Tukuma County"
      }
      {
        abbreviation: ""
        name: "Valkas County"
      }
      {
        abbreviation: ""
        name: "Valmieras County"
      }
      {
        abbreviation: ""
        name: "Ventspils Municipality"
      }
      {
        abbreviation: ""
        name: "Ventspils County"
      }
      {
        abbreviation: ""
        name: "Jelgava"
      }
      {
        abbreviation: ""
        name: "Jelgavas"
      }
      {
        abbreviation: ""
        name: "Jurmala"
      }
    ]
  }
  {
    name: "Lebanon"
    abbreviation: "LB"
    states: [
      {
        abbreviation: ""
        name: "Beqaa Governorate"
      }
      {
        abbreviation: ""
        name: "North Governorate"
      }
      {
        abbreviation: ""
        name: "Beirut Governorate"
      }
      {
        abbreviation: ""
        name: "Mount Lebanon Governorate"
      }
      {
        abbreviation: ""
        name: "South Governorate"
      }
    ]
  }
  {
    name: "Lesotho"
    abbreviation: "LS"
    states: [
      {
        abbreviation: ""
        name: "Berea District"
      }
      {
        abbreviation: ""
        name: "Butha-Buthe District"
      }
      {
        abbreviation: ""
        name: "Leribe District"
      }
      {
        abbreviation: ""
        name: "Mafeteng District"
      }
      {
        abbreviation: ""
        name: "Maseru District"
      }
      {
        abbreviation: ""
        name: "Mohale's Hoek District"
      }
      {
        abbreviation: ""
        name: "Mokhotlong District"
      }
      {
        abbreviation: ""
        name: "Qacha's Nek District"
      }
      {
        abbreviation: ""
        name: "Quthing District"
      }
      {
        abbreviation: ""
        name: "Thaba-Tseka District"
      }
    ]
  }
  {
    name: "Liberia"
    abbreviation: "LR"
    states: [
      {
        abbreviation: ""
        name: "Bong County"
      }
      {
        abbreviation: ""
        name: "Nimba County"
      }
      {
        abbreviation: ""
        name: "Sinoe County"
      }
      {
        abbreviation: ""
        name: "Grand Bassa County"
      }
      {
        abbreviation: ""
        name: "Grand Cape Mount County"
      }
      {
        abbreviation: ""
        name: "Maryland"
      }
      {
        abbreviation: ""
        name: "Montserrado County"
      }
      {
        abbreviation: ""
        name: "Bomi County"
      }
      {
        abbreviation: ""
        name: "Grand Kru County"
      }
      {
        abbreviation: ""
        name: "Margibi County"
      }
      {
        abbreviation: ""
        name: "River Cess County"
      }
      {
        abbreviation: ""
        name: "Grand Gedeh County"
      }
      {
        abbreviation: ""
        name: "Lofa County"
      }
      {
        abbreviation: ""
        name: "Gbarpolu County"
      }
      {
        abbreviation: ""
        name: "River Gee County"
      }
    ]
  }
  {
    name: "Liechtenstein"
    abbreviation: "LI"
    states: [
      {
        abbreviation: ""
        name: "Balzers Commune"
      }
      {
        abbreviation: ""
        name: "Eschen Commune"
      }
      {
        abbreviation: ""
        name: "Gamprin Commune"
      }
      {
        abbreviation: ""
        name: "Mauren Commune"
      }
      {
        abbreviation: ""
        name: "Planken Commune"
      }
      {
        abbreviation: ""
        name: "Ruggell Commune"
      }
      {
        abbreviation: ""
        name: "Schaan Commune"
      }
      {
        abbreviation: ""
        name: "Schellenberg Commune"
      }
      {
        abbreviation: ""
        name: "Triesen Commune"
      }
      {
        abbreviation: ""
        name: "Triesenberg Commune"
      }
      {
        abbreviation: ""
        name: "Vaduz Commune"
      }
    ]
  }
  {
    name: "Lithuania"
    abbreviation: "LT"
    states: [
      {
        abbreviation: ""
        name: "Alytus County"
      }
      {
        abbreviation: ""
        name: "Kaunas County"
      }
      {
        abbreviation: ""
        name: "Klaipe.da County"
      }
      {
        abbreviation: ""
        name: "Marijampoles County"
      }
      {
        abbreviation: ""
        name: "Panevezio County"
      }
      {
        abbreviation: ""
        name: "Siauliai County"
      }
      {
        abbreviation: ""
        name: "Taurage. County"
      }
      {
        abbreviation: ""
        name: "Telsiu County"
      }
      {
        abbreviation: ""
        name: "Utena County"
      }
      {
        abbreviation: ""
        name: "Vilnius County"
      }
    ]
  }
  {
    name: "Luxembourg"
    abbreviation: "LU"
    states: [
      {
        abbreviation: ""
        name: "Diekirch District"
      }
      {
        abbreviation: ""
        name: "Grevenmacher District"
      }
      {
        abbreviation: ""
        name: "Luxembourg District"
      }
    ]
  }
  {
    name: "Madagascar"
    abbreviation: "MG"
    states: [
      {
        abbreviation: ""
        name: "Antsiranana"
      }
      {
        abbreviation: ""
        name: "Fianarantsoa"
      }
      {
        abbreviation: ""
        name: "Mahajanga"
      }
      {
        abbreviation: ""
        name: "Toamasina"
      }
      {
        abbreviation: ""
        name: "Antananarivo"
      }
      {
        abbreviation: ""
        name: "Toliara"
      }
    ]
  }
  {
    name: "Malawi"
    abbreviation: "MW"
    states: [
      {
        abbreviation: ""
        name: "Chikwawa District"
      }
      {
        abbreviation: ""
        name: "Chiradzulu District"
      }
      {
        abbreviation: ""
        name: "Chitipa District"
      }
      {
        abbreviation: ""
        name: "Thyolo District"
      }
      {
        abbreviation: ""
        name: "Dedza District"
      }
      {
        abbreviation: ""
        name: "Dowa District"
      }
      {
        abbreviation: ""
        name: "Karonga District"
      }
      {
        abbreviation: ""
        name: "Kasungu District"
      }
      {
        abbreviation: ""
        name: "Lilongwe District"
      }
      {
        abbreviation: ""
        name: "Mangochi District"
      }
      {
        abbreviation: ""
        name: "Mchinji District"
      }
      {
        abbreviation: ""
        name: "Mzimba District"
      }
      {
        abbreviation: ""
        name: "Ntcheu District"
      }
      {
        abbreviation: ""
        name: "Nkhata Bay District"
      }
      {
        abbreviation: ""
        name: "Nkhotakota District"
      }
      {
        abbreviation: ""
        name: "Nsanje District"
      }
      {
        abbreviation: ""
        name: "Ntchisi District"
      }
      {
        abbreviation: ""
        name: "Rumphi District"
      }
      {
        abbreviation: ""
        name: "Salima District"
      }
      {
        abbreviation: ""
        name: "Zomba District"
      }
      {
        abbreviation: ""
        name: "Blantyre District"
      }
      {
        abbreviation: ""
        name: "Mwanza District"
      }
      {
        abbreviation: ""
        name: "Balaka District"
      }
      {
        abbreviation: ""
        name: "Likoma District"
      }
      {
        abbreviation: ""
        name: "Machinga District"
      }
      {
        abbreviation: ""
        name: "Mulanje District"
      }
      {
        abbreviation: ""
        name: "Phalombe District"
      }
    ]
  }
  {
    name: "Malaysia"
    abbreviation: "MY"
    states: [
      {
        abbreviation: ""
        name: "Johor State"
      }
      {
        abbreviation: ""
        name: "Kedah State"
      }
      {
        abbreviation: ""
        name: "Kelantan State"
      }
      {
        abbreviation: ""
        name: "Melaka State"
      }
      {
        abbreviation: ""
        name: "Negeri Sembilan State"
      }
      {
        abbreviation: ""
        name: "Pahang State"
      }
      {
        abbreviation: ""
        name: "Perak State"
      }
      {
        abbreviation: ""
        name: "Perlis State"
      }
      {
        abbreviation: ""
        name: "Pulau Pinang State"
      }
      {
        abbreviation: ""
        name: "Sarawak State"
      }
      {
        abbreviation: ""
        name: "Selangor State"
      }
      {
        abbreviation: ""
        name: "Terengganu State"
      }
      {
        abbreviation: ""
        name: "Kuala Lumpur"
      }
      {
        abbreviation: ""
        name: "Labuan"
      }
      {
        abbreviation: ""
        name: "Sabah State"
      }
      {
        abbreviation: ""
        name: "Putrajaya State"
      }
    ]
  }
  {
    name: "Maldives"
    abbreviation: "MV"
    states: [
      {
        abbreviation: ""
        name: "Seenu Atoll"
      }
      {
        abbreviation: ""
        name: "Laamu Atoll"
      }
      {
        abbreviation: ""
        name: "Alifu"
      }
      {
        abbreviation: ""
        name: "Baa Atoll"
      }
      {
        abbreviation: ""
        name: "Dhaalu Atoll"
      }
      {
        abbreviation: ""
        name: "Faafu Atoll"
      }
      {
        abbreviation: ""
        name: "Gaafu Alifu Atoll"
      }
      {
        abbreviation: ""
        name: "Gaafu Dhaalu Atoll"
      }
      {
        abbreviation: ""
        name: "Haa Alifu Atoll"
      }
      {
        abbreviation: ""
        name: "Haa Dhaalu Atoll"
      }
      {
        abbreviation: ""
        name: "Kaafu Atoll"
      }
      {
        abbreviation: ""
        name: "Lhaviyani Atoll"
      }
      {
        abbreviation: ""
        name: "Maale"
      }
      {
        abbreviation: ""
        name: "Meemu Atoll"
      }
      {
        abbreviation: ""
        name: "Gnaviyani Atoll"
      }
      {
        abbreviation: ""
        name: "Noonu Atoll"
      }
      {
        abbreviation: ""
        name: "Raa Atoll"
      }
      {
        abbreviation: ""
        name: "Shaviyani Atoll"
      }
      {
        abbreviation: ""
        name: "Thaa Atoll"
      }
      {
        abbreviation: ""
        name: "Vaavu Atoll"
      }
    ]
  }
  {
    name: "Mali"
    abbreviation: "ML"
    states: [
      {
        abbreviation: ""
        name: "Bamako"
      }
      {
        abbreviation: ""
        name: "Kayes Region"
      }
      {
        abbreviation: ""
        name: "Mopti Region"
      }
      {
        abbreviation: ""
        name: "Segou Region"
      }
      {
        abbreviation: ""
        name: "Sikasso Region"
      }
      {
        abbreviation: ""
        name: "Koulikoro Region"
      }
      {
        abbreviation: ""
        name: "Tombouctou Region"
      }
      {
        abbreviation: ""
        name: "Gao Region"
      }
      {
        abbreviation: ""
        name: "Kidal Region"
      }
    ]
  }
  {
    name: "Mauritania"
    abbreviation: "MR"
    states: [
      {
        abbreviation: ""
        name: "Adrar Region"
      }
      {
        abbreviation: ""
        name: "Assaba Region"
      }
      {
        abbreviation: ""
        name: "Trarza Region"
      }
    ]
  }
  {
    name: "Mauritius"
    abbreviation: "MU"
    states: [
      {
        abbreviation: ""
        name: "Black River District"
      }
      {
        abbreviation: ""
        name: "Flacq District"
      }
      {
        abbreviation: ""
        name: "Grand Port District"
      }
      {
        abbreviation: ""
        name: "Moka District"
      }
      {
        abbreviation: ""
        name: "Pamplemousses District"
      }
      {
        abbreviation: ""
        name: "Plaines Wilhems District"
      }
      {
        abbreviation: ""
        name: "Port Louis District"
      }
      {
        abbreviation: ""
        name: "RiviÃ¨re du Rempart District"
      }
      {
        abbreviation: ""
        name: "Savanne District"
      }
      {
        abbreviation: ""
        name: "Rodrigues Dependency"
      }
    ]
  }
  {
    name: "Mexico"
    abbreviation: "MX"
    states: [
      {
        abbreviation: ""
        name: "Aguascalientes State"
      }
      {
        abbreviation: ""
        name: "Baja California State"
      }
      {
        abbreviation: ""
        name: "Baja California Sur State"
      }
      {
        abbreviation: ""
        name: "Campeche State"
      }
      {
        abbreviation: ""
        name: "Chiapas State"
      }
      {
        abbreviation: ""
        name: "Chihuahua State"
      }
      {
        abbreviation: ""
        name: "Coahuila de Zaragoza State"
      }
      {
        abbreviation: ""
        name: "Colima State"
      }
      {
        abbreviation: ""
        name: "Distrito Federal Federal District"
      }
      {
        abbreviation: ""
        name: "Durango State"
      }
      {
        abbreviation: ""
        name: "Guanajuato State"
      }
      {
        abbreviation: ""
        name: "Guerrero State"
      }
      {
        abbreviation: ""
        name: "Hidalgo State"
      }
      {
        abbreviation: ""
        name: "Jalisco State"
      }
      {
        abbreviation: ""
        name: "Mexico State"
      }
      {
        abbreviation: ""
        name: "Michoacan de Ocampo State"
      }
      {
        abbreviation: ""
        name: "Morelos State"
      }
      {
        abbreviation: ""
        name: "Nayarit State"
      }
      {
        abbreviation: ""
        name: "Nuevo Leon State"
      }
      {
        abbreviation: ""
        name: "Oaxaca State"
      }
      {
        abbreviation: ""
        name: "Puebla State"
      }
      {
        abbreviation: ""
        name: "Queretaro de Arteaga State"
      }
      {
        abbreviation: ""
        name: "Quintana Roo State"
      }
      {
        abbreviation: ""
        name: "San Luis Potosi State"
      }
      {
        abbreviation: ""
        name: "Sinaloa State"
      }
      {
        abbreviation: ""
        name: "Sonora State"
      }
      {
        abbreviation: ""
        name: "Tabasco State"
      }
      {
        abbreviation: ""
        name: "Tamaulipas State"
      }
      {
        abbreviation: ""
        name: "Tlaxcala State"
      }
      {
        abbreviation: ""
        name: "Veracruz-Llave State"
      }
      {
        abbreviation: ""
        name: "Yucatan State"
      }
      {
        abbreviation: ""
        name: "Zacatecas State"
      }
    ]
  }
  {
    name: "Micronesia"
    abbreviation: "FM"
    states: [
      {
        abbreviation: ""
        name: "Kosrae State"
      }
      {
        abbreviation: ""
        name: "Pohnpei State"
      }
      {
        abbreviation: ""
        name: "Chuuk State"
      }
      {
        abbreviation: ""
        name: "Yap State"
      }
    ]
  }
  {
    name: "Moldova"
    abbreviation: "MD"
    states: [
      {
        abbreviation: ""
        name: "Anenii Noi County"
      }
      {
        abbreviation: ""
        name: "Balti"
      }
      {
        abbreviation: ""
        name: "Basarabeasca County"
      }
      {
        abbreviation: ""
        name: "Briceni County"
      }
      {
        abbreviation: ""
        name: "Cahul County"
      }
      {
        abbreviation: ""
        name: "Cimislia"
      }
      {
        abbreviation: ""
        name: "Criuleni County"
      }
      {
        abbreviation: ""
        name: "Drochia County"
      }
      {
        abbreviation: ""
        name: "Edinet"
      }
      {
        abbreviation: ""
        name: "Falesti County"
      }
      {
        abbreviation: ""
        name: "Floresti County"
      }
      {
        abbreviation: ""
        name: "Glodeni County"
      }
      {
        abbreviation: ""
        name: "Hincesti"
      }
      {
        abbreviation: ""
        name: "Ialoveni County"
      }
      {
        abbreviation: ""
        name: "Leova County"
      }
      {
        abbreviation: ""
        name: "Nisporeni County"
      }
      {
        abbreviation: ""
        name: "Ocnita"
      }
      {
        abbreviation: ""
        name: "Orhei County"
      }
    ]
  }
  {
    name: "Mongolia"
    abbreviation: "MN"
    states: [
      {
        abbreviation: ""
        name: "Darkhan City"
      }
      {
        abbreviation: ""
        name: "Arkhangai"
      }
      {
        abbreviation: ""
        name: "Bayankhongor"
      }
      {
        abbreviation: ""
        name: "Bayan-Olgii"
      }
      {
        abbreviation: ""
        name: "Dornod"
      }
      {
        abbreviation: ""
        name: "Dornogovi"
      }
      {
        abbreviation: ""
        name: "Dundgovi"
      }
      {
        abbreviation: ""
        name: "Zavkhan"
      }
      {
        abbreviation: ""
        name: "Govi-Altai"
      }
      {
        abbreviation: ""
        name: "Khentii"
      }
      {
        abbreviation: ""
        name: "Khovd"
      }
      {
        abbreviation: ""
        name: "Khovsgol"
      }
      {
        abbreviation: ""
        name: "Omnogovi"
      }
      {
        abbreviation: ""
        name: "Ovorkhangai"
      }
      {
        abbreviation: ""
        name: "Selenge"
      }
      {
        abbreviation: ""
        name: "Sukhbaatar"
      }
      {
        abbreviation: ""
        name: "Tov"
      }
      {
        abbreviation: ""
        name: "Uvs"
      }
      {
        abbreviation: ""
        name: "Ulan Bator Municipality"
      }
      {
        abbreviation: ""
        name: "Bulgan"
      }
      {
        abbreviation: ""
        name: "Darkhan-Uul"
      }
      {
        abbreviation: ""
        name: "Govisumber"
      }
    ]
  }
  {
    name: "Morocco"
    abbreviation: "MA"
    states: [
      {
        abbreviation: ""
        name: "Chaouen Region"
      }
      {
        abbreviation: ""
        name: "Guelmim Region"
      }
      {
        abbreviation: ""
        name: "Grand Casablanca Region"
      }
      {
        abbreviation: ""
        name: "Fes-Boulemane Region"
      }
      {
        abbreviation: ""
        name: "Marrakech-Tensift-Al Haouz Region"
      }
      {
        abbreviation: ""
        name: "Meknes-Tafilalet Region"
      }
      {
        abbreviation: ""
        name: "Rabat-Sale-Zemmour-Zaer Region"
      }
      {
        abbreviation: ""
        name: "Chaouia-Ouardigha Region"
      }
      {
        abbreviation: ""
        name: "Rabat-SalÃ©-Zemmour-Zaer"
      }
      {
        abbreviation: ""
        name: "Doukkala-Abda Region"
      }
      {
        abbreviation: ""
        name: "Gharb-Chrarda-Beni Hssen Region"
      }
      {
        abbreviation: ""
        name: "Guelmim-Es Smara Region"
      }
      {
        abbreviation: ""
        name: "Oriental Region"
      }
      {
        abbreviation: ""
        name: "Souss-Massa-Draa Region"
      }
      {
        abbreviation: ""
        name: "Tadla-Azilal Region"
      }
      {
        abbreviation: ""
        name: "Tanger-Tetouan Region"
      }
      {
        abbreviation: ""
        name: "Taza-Al Hoceima-Taounate Region"
      }
      {
        abbreviation: ""
        name: "Laayoune-Boujdour-Sakia El Hamra"
      }
      {
        abbreviation: ""
        name: "Laayoune-Boujdour-Sakia El Hamra"
      }
    ]
  }
  {
    name: "Mozambique"
    abbreviation: "MZ"
    states: [
      {
        abbreviation: ""
        name: "Cabo Delgado"
      }
      {
        abbreviation: ""
        name: "Gaza"
      }
      {
        abbreviation: ""
        name: "Inhambane"
      }
      {
        abbreviation: ""
        name: "Maputo"
      }
      {
        abbreviation: ""
        name: "Sofala"
      }
      {
        abbreviation: ""
        name: "Nampula"
      }
      {
        abbreviation: ""
        name: "Niassa"
      }
      {
        abbreviation: ""
        name: "Tete"
      }
      {
        abbreviation: ""
        name: "Zambezia"
      }
      {
        abbreviation: ""
        name: "Manica"
      }
      {
        abbreviation: ""
        name: "Capital City of Maputo"
      }
    ]
  }
  {
    name: "Myanmar"
    abbreviation: "MM"
    states: [
      {
        abbreviation: ""
        name: "Rakhine State"
      }
      {
        abbreviation: ""
        name: "Chin State"
      }
      {
        abbreviation: ""
        name: "Ayeyarwady Division"
      }
      {
        abbreviation: ""
        name: "Kachin State"
      }
      {
        abbreviation: ""
        name: "Kayin State"
      }
      {
        abbreviation: ""
        name: "Kayah State"
      }
      {
        abbreviation: ""
        name: "Mandalay Division"
      }
      {
        abbreviation: ""
        name: "Sagaing Division"
      }
      {
        abbreviation: ""
        name: "Shan State"
      }
      {
        abbreviation: ""
        name: "Tanintharyi Division"
      }
      {
        abbreviation: ""
        name: "Mon State"
      }
      {
        abbreviation: ""
        name: "Magway Division"
      }
      {
        abbreviation: ""
        name: "Bago Division"
      }
      {
        abbreviation: ""
        name: "Yangon Division"
      }
    ]
  }
  {
    name: "Netherlands"
    abbreviation: "NL"
    states: [
      {
        abbreviation: ""
        name: "Drenthe"
      }
      {
        abbreviation: ""
        name: "Friesland"
      }
      {
        abbreviation: ""
        name: "Gelderland"
      }
      {
        abbreviation: ""
        name: "Groningen"
      }
      {
        abbreviation: ""
        name: "Limburg"
      }
      {
        abbreviation: ""
        name: "Noord-Brabant"
      }
      {
        abbreviation: ""
        name: "Noord-Holland"
      }
      {
        abbreviation: ""
        name: "Utrecht"
      }
      {
        abbreviation: ""
        name: "Zeeland"
      }
      {
        abbreviation: ""
        name: "Zuid-Holland"
      }
      {
        abbreviation: ""
        name: "Overijssel"
      }
      {
        abbreviation: ""
        name: "Flevoland"
      }
    ]
  }
  {
    name: "New Zealand"
    abbreviation: "NZ"
    states: [
      {
        abbreviation: ""
        name: "Chatham Islands"
      }
      {
        abbreviation: ""
        name: "Chatham Islands"
      }
      {
        abbreviation: ""
        name: "Auckland"
      }
      {
        abbreviation: ""
        name: "Bay of Plenty"
      }
      {
        abbreviation: ""
        name: "Canterbury"
      }
      {
        abbreviation: ""
        name: "Gisborne"
      }
      {
        abbreviation: ""
        name: "Hawke's Bay"
      }
      {
        abbreviation: ""
        name: "Manawatu-Wanganui"
      }
      {
        abbreviation: ""
        name: "Marlborough"
      }
      {
        abbreviation: ""
        name: "Nelson"
      }
      {
        abbreviation: ""
        name: "Northland"
      }
      {
        abbreviation: ""
        name: "Otago"
      }
      {
        abbreviation: ""
        name: "Southland"
      }
      {
        abbreviation: ""
        name: "Taranaki"
      }
      {
        abbreviation: ""
        name: "Waikato"
      }
      {
        abbreviation: ""
        name: "Wellington"
      }
      {
        abbreviation: ""
        name: "West Coast"
      }
    ]
  }
  {
    name: "Nicaragua"
    abbreviation: "NI"
    states: [
      {
        abbreviation: ""
        name: "Boaco Department"
      }
      {
        abbreviation: ""
        name: "Carazo Department"
      }
      {
        abbreviation: ""
        name: "Chinandega Department"
      }
      {
        abbreviation: ""
        name: "Chontales Department"
      }
      {
        abbreviation: ""
        name: "Esteli Department"
      }
      {
        abbreviation: ""
        name: "Granada Department"
      }
      {
        abbreviation: ""
        name: "Jinotega Department"
      }
      {
        abbreviation: ""
        name: "Leon Department"
      }
      {
        abbreviation: ""
        name: "Madriz Department"
      }
      {
        abbreviation: ""
        name: "Managua Department"
      }
      {
        abbreviation: ""
        name: "Masaya Department"
      }
      {
        abbreviation: ""
        name: "Matagalpa Department"
      }
      {
        abbreviation: ""
        name: "Nueva Segovia Department"
      }
      {
        abbreviation: ""
        name: "Rio San Juan Department"
      }
      {
        abbreviation: ""
        name: "Rivas Department"
      }
      {
        abbreviation: ""
        name: "Region Autonoma Atlantico Norte"
      }
      {
        abbreviation: ""
        name: "Region Autonoma Atlantico Sur"
      }
    ]
  }
  {
    name: "Niger"
    abbreviation: "NE"
    states: [
      {
        abbreviation: ""
        name: "Agadez Department"
      }
      {
        abbreviation: ""
        name: "Diffa Department"
      }
      {
        abbreviation: ""
        name: "Dosso Department"
      }
      {
        abbreviation: ""
        name: "Maradi Department"
      }
      {
        abbreviation: ""
        name: "Niamey Capital District"
      }
      {
        abbreviation: ""
        name: "Tahoua Department"
      }
      {
        abbreviation: ""
        name: "Zinder Department"
      }
      {
        abbreviation: ""
        name: "Niamey Capital District"
      }
      {
        abbreviation: ""
        name: "Tillaberi Department"
      }
    ]
  }
  {
    name: "Nigeria"
    abbreviation: "NG"
    states: [
      {
        abbreviation: ""
        name: "Lagos State"
      }
      {
        abbreviation: ""
        name: "Federal Capital Territory"
      }
      {
        abbreviation: ""
        name: "Ogun State"
      }
      {
        abbreviation: ""
        name: "Akwa Ibom State"
      }
      {
        abbreviation: ""
        name: "Cross River State"
      }
      {
        abbreviation: ""
        name: "Kaduna State"
      }
      {
        abbreviation: ""
        name: "Katsina State"
      }
      {
        abbreviation: ""
        name: "Anambra State"
      }
      {
        abbreviation: ""
        name: "Benue State"
      }
      {
        abbreviation: ""
        name: "Borno State"
      }
      {
        abbreviation: ""
        name: "Imo State"
      }
      {
        abbreviation: ""
        name: "Kano State"
      }
      {
        abbreviation: ""
        name: "Kwara State"
      }
      {
        abbreviation: ""
        name: "Niger State"
      }
      {
        abbreviation: ""
        name: "Oyo State"
      }
      {
        abbreviation: ""
        name: "Adamawa State"
      }
      {
        abbreviation: ""
        name: "Delta State"
      }
      {
        abbreviation: ""
        name: "Edo State"
      }
      {
        abbreviation: ""
        name: "Jigawa State"
      }
      {
        abbreviation: ""
        name: "Kebbi State"
      }
      {
        abbreviation: ""
        name: "Kogi State"
      }
      {
        abbreviation: ""
        name: "Osun State"
      }
      {
        abbreviation: ""
        name: "Taraba State"
      }
      {
        abbreviation: ""
        name: "Yobe State"
      }
      {
        abbreviation: ""
        name: "Abia State"
      }
      {
        abbreviation: ""
        name: "Bauchi State"
      }
      {
        abbreviation: ""
        name: "Enugu State"
      }
      {
        abbreviation: ""
        name: "Ondo State"
      }
      {
        abbreviation: ""
        name: "Plateau State"
      }
      {
        abbreviation: ""
        name: "Rivers State"
      }
      {
        abbreviation: ""
        name: "Sokoto State"
      }
      {
        abbreviation: ""
        name: "Bayelsa State"
      }
      {
        abbreviation: ""
        name: "Ebonyi State"
      }
      {
        abbreviation: ""
        name: "Ekiti State"
      }
      {
        abbreviation: ""
        name: "Gombe State"
      }
      {
        abbreviation: ""
        name: "Nasarawa State"
      }
      {
        abbreviation: ""
        name: "Zamfara State"
      }
    ]
  }
  {
    name: "Norway"
    abbreviation: "NO"
    states: [
      {
        abbreviation: ""
        name: "Akershus County"
      }
      {
        abbreviation: ""
        name: "Aust-Agder County"
      }
      {
        abbreviation: ""
        name: "Buskerud County"
      }
      {
        abbreviation: ""
        name: "Finnmark County"
      }
      {
        abbreviation: ""
        name: "Hedmark County"
      }
      {
        abbreviation: ""
        name: "Hordaland County"
      }
      {
        abbreviation: ""
        name: "More og Romsdal County"
      }
      {
        abbreviation: ""
        name: "Nordland County"
      }
      {
        abbreviation: ""
        name: "Nord-Trondelag County"
      }
      {
        abbreviation: ""
        name: "Oppland County"
      }
      {
        abbreviation: ""
        name: "Oslo County"
      }
      {
        abbreviation: ""
        name: "Ostfold County"
      }
      {
        abbreviation: ""
        name: "Rogaland County"
      }
      {
        abbreviation: ""
        name: "Sogn og Fjordane County"
      }
      {
        abbreviation: ""
        name: "Sor-Trondelag County"
      }
      {
        abbreviation: ""
        name: "Telemark County"
      }
      {
        abbreviation: ""
        name: "Troms County"
      }
      {
        abbreviation: ""
        name: "Vest-Agder County"
      }
      {
        abbreviation: ""
        name: "Vestfold County"
      }
    ]
  }
  {
    name: "Oman"
    abbreviation: "OM"
    states: [
      {
        abbreviation: ""
        name: "Al Ba-tinah"
      }
      {
        abbreviation: ""
        name: "Ash Sharqi-yah Region"
      }
      {
        abbreviation: ""
        name: "Mintaqat az Za-hirah"
      }
      {
        abbreviation: ""
        name: "Masqat"
      }
      {
        abbreviation: ""
        name: "Musandam Governorate"
      }
      {
        abbreviation: ""
        name: "Zufa-r Governorate"
      }
    ]
  }
  {
    name: "Pakistan"
    abbreviation: "PK"
    states: [
      {
        abbreviation: ""
        name: "Federally Administered Tribal Areas Territory"
      }
      {
        abbreviation: ""
        name: "Balochista-n"
      }
      {
        abbreviation: ""
        name: "North-West Frontier"
      }
      {
        abbreviation: ""
        name: ""
      }
      {
        abbreviation: ""
        name: "Punjab"
      }
      {
        abbreviation: ""
        name: "Sindh"
      }
      {
        abbreviation: ""
        name: "Isla-ma-ba-d"
      }
    ]
  }
  {
    name: "Panama"
    abbreviation: "PA"
    states: [
      {
        abbreviation: ""
        name: "Bocas del Toro"
      }
      {
        abbreviation: ""
        name: "Chiriqui"
      }
      {
        abbreviation: ""
        name: "Cocle"
      }
      {
        abbreviation: ""
        name: "Colon"
      }
      {
        abbreviation: ""
        name: "Darien"
      }
      {
        abbreviation: ""
        name: "Herrera"
      }
      {
        abbreviation: ""
        name: "Los Santos"
      }
      {
        abbreviation: ""
        name: "Panama"
      }
      {
        abbreviation: ""
        name: "San Blas Territory"
      }
      {
        abbreviation: ""
        name: "Veraguas"
      }
    ]
  }
  {
    name: "Paraguay"
    abbreviation: "PY"
    states: [
      {
        abbreviation: ""
        name: "Alto Parana Department"
      }
      {
        abbreviation: ""
        name: "Amambay Department"
      }
      {
        abbreviation: ""
        name: "Caaguazu Department"
      }
      {
        abbreviation: ""
        name: "Caazapa Department"
      }
      {
        abbreviation: ""
        name: "Central Department"
      }
      {
        abbreviation: ""
        name: "Concepcion Department"
      }
      {
        abbreviation: ""
        name: "Cordillera Department"
      }
      {
        abbreviation: ""
        name: "Guaira Department"
      }
      {
        abbreviation: ""
        name: "Itapua Department"
      }
      {
        abbreviation: ""
        name: "Misiones Department"
      }
      {
        abbreviation: ""
        name: "Neembucu Department"
      }
      {
        abbreviation: ""
        name: "Paraguari Department"
      }
      {
        abbreviation: ""
        name: "Presidente Hayes Department"
      }
      {
        abbreviation: ""
        name: "San Pedro Department"
      }
      {
        abbreviation: ""
        name: "Canindeyu Department"
      }
      {
        abbreviation: ""
        name: "Alto Paraguay Department"
      }
      {
        abbreviation: ""
        name: "Boqueron Department"
      }
    ]
  }
  {
    name: "Peru"
    abbreviation: "PE"
    states: [
      {
        abbreviation: ""
        name: "Amazonas Region"
      }
      {
        abbreviation: ""
        name: "Ancash Region"
      }
      {
        abbreviation: ""
        name: "Apurimac Region"
      }
      {
        abbreviation: ""
        name: "Arequipa Region"
      }
      {
        abbreviation: ""
        name: "Ayacucho Region"
      }
      {
        abbreviation: ""
        name: "Cajamarca Region"
      }
      {
        abbreviation: ""
        name: "Callao Region"
      }
      {
        abbreviation: ""
        name: "Cusco Region"
      }
      {
        abbreviation: ""
        name: "Huancavelica Region"
      }
      {
        abbreviation: ""
        name: "Huanuco Region"
      }
      {
        abbreviation: ""
        name: "Ica Region"
      }
      {
        abbreviation: ""
        name: "Junin Region"
      }
      {
        abbreviation: ""
        name: "La Libertad Region"
      }
      {
        abbreviation: ""
        name: "Lambayeque Region"
      }
      {
        abbreviation: ""
        name: "Lima Region"
      }
      {
        abbreviation: ""
        name: "Loreto Region"
      }
      {
        abbreviation: ""
        name: "Madre de Dios Region"
      }
      {
        abbreviation: ""
        name: "Moquegua Region"
      }
      {
        abbreviation: ""
        name: "Pasco Region"
      }
      {
        abbreviation: ""
        name: "Piura Region"
      }
      {
        abbreviation: ""
        name: "Puno Region"
      }
      {
        abbreviation: ""
        name: "San Martin Region"
      }
      {
        abbreviation: ""
        name: "Tacna Region"
      }
      {
        abbreviation: ""
        name: "Tumbes Region"
      }
      {
        abbreviation: ""
        name: "Ucayali Region"
      }
    ]
  }
  {
    name: "Philippines"
    abbreviation: "PH"
    states: [
      {
        abbreviation: ""
        name: "Agusan del Norte"
      }
      {
        abbreviation: ""
        name: "Agusan del Sur"
      }
      {
        abbreviation: ""
        name: "Aklan"
      }
      {
        abbreviation: ""
        name: "Albay"
      }
      {
        abbreviation: ""
        name: "Antique"
      }
      {
        abbreviation: ""
        name: "Bataan"
      }
      {
        abbreviation: ""
        name: "Batanes"
      }
      {
        abbreviation: ""
        name: "Batangas"
      }
      {
        abbreviation: ""
        name: "Benguet"
      }
      {
        abbreviation: ""
        name: "Bohol"
      }
      {
        abbreviation: ""
        name: "Bukidnon"
      }
      {
        abbreviation: ""
        name: "Bulacan"
      }
      {
        abbreviation: ""
        name: "Cagayan"
      }
      {
        abbreviation: ""
        name: "Camarines Norte"
      }
      {
        abbreviation: ""
        name: "Camarines Sur"
      }
      {
        abbreviation: ""
        name: "Camiguin"
      }
      {
        abbreviation: ""
        name: "Capiz"
      }
      {
        abbreviation: ""
        name: "Catanduanes"
      }
      {
        abbreviation: ""
        name: "Cavite"
      }
      {
        abbreviation: ""
        name: "Cebu"
      }
      {
        abbreviation: ""
        name: "Basilan"
      }
      {
        abbreviation: ""
        name: "Eastern Samar"
      }
      {
        abbreviation: ""
        name: "Davao del Norte"
      }
      {
        abbreviation: ""
        name: "Davao del Sur"
      }
      {
        abbreviation: ""
        name: "Davao Oriental"
      }
      {
        abbreviation: ""
        name: "Ifugao"
      }
      {
        abbreviation: ""
        name: "Ilocos Norte"
      }
      {
        abbreviation: ""
        name: "Ilocos Sur"
      }
      {
        abbreviation: ""
        name: "Iloilo"
      }
      {
        abbreviation: ""
        name: "Isabela"
      }
      {
        abbreviation: ""
        name: "Kalinga-Apayao"
      }
      {
        abbreviation: ""
        name: "Laguna"
      }
      {
        abbreviation: ""
        name: "Lanao del Norte"
      }
      {
        abbreviation: ""
        name: "Lanao del Sur"
      }
      {
        abbreviation: ""
        name: "La Union"
      }
      {
        abbreviation: ""
        name: "Leyte"
      }
      {
        abbreviation: ""
        name: "Marinduque"
      }
      {
        abbreviation: ""
        name: "Masbate"
      }
      {
        abbreviation: ""
        name: "Misamis Occidental"
      }
      {
        abbreviation: ""
        name: "Misamis Oriental"
      }
      {
        abbreviation: ""
        name: "Mountain"
      }
      {
        abbreviation: ""
        name: "Negros Oriental"
      }
      {
        abbreviation: ""
        name: "Nueva Ecija"
      }
      {
        abbreviation: ""
        name: "Nueva Vizcaya"
      }
      {
        abbreviation: ""
        name: "Palawan"
      }
      {
        abbreviation: ""
        name: "Pampanga"
      }
      {
        abbreviation: ""
        name: "Pangasinan"
      }
      {
        abbreviation: ""
        name: "Rizal"
      }
      {
        abbreviation: ""
        name: "Romblon"
      }
      {
        abbreviation: ""
        name: "Samar"
      }
      {
        abbreviation: ""
        name: "(North) Cotabato"
      }
      {
        abbreviation: ""
        name: "Sorsogon"
      }
      {
        abbreviation: ""
        name: "Southern Leyte"
      }
      {
        abbreviation: ""
        name: "Sulu"
      }
      {
        abbreviation: ""
        name: "Surigao del Norte"
      }
      {
        abbreviation: ""
        name: "Surigao del Sur"
      }
      {
        abbreviation: ""
        name: "Tarlac"
      }
      {
        abbreviation: ""
        name: "Zambales"
      }
      {
        abbreviation: ""
        name: "Zamboanga del Norte"
      }
      {
        abbreviation: ""
        name: "Zamboanga del Sur"
      }
      {
        abbreviation: ""
        name: "Northern Samar"
      }
      {
        abbreviation: ""
        name: "Quirino"
      }
      {
        abbreviation: ""
        name: "Siquijor"
      }
      {
        abbreviation: ""
        name: "South Cotabato"
      }
      {
        abbreviation: ""
        name: "Sultan Kudarat"
      }
      {
        abbreviation: ""
        name: "Tawi-Tawi"
      }
      {
        abbreviation: ""
        name: "Angeles Chartered City"
      }
      {
        abbreviation: ""
        name: "Bacolod Chartered City"
      }
      {
        abbreviation: ""
        name: "Bago Chartered City"
      }
      {
        abbreviation: ""
        name: "Baguio Chartered City"
      }
      {
        abbreviation: ""
        name: "Batangas City"
      }
      {
        abbreviation: ""
        name: "Butuan Chartered City"
      }
      {
        abbreviation: ""
        name: "Cabanatuan Chartered City"
      }
      {
        abbreviation: ""
        name: "Cadiz Chartered City"
      }
      {
        abbreviation: ""
        name: "Cagayan de Oro Chartered City"
      }
      {
        abbreviation: ""
        name: "Calbayog Chartered City"
      }
      {
        abbreviation: ""
        name: "Caloocan Chartered City"
      }
      {
        abbreviation: ""
        name: "Canlaon Chartered City"
      }
      {
        abbreviation: ""
        name: "Cavite City"
      }
      {
        abbreviation: ""
        name: "Cebu City"
      }
      {
        abbreviation: ""
        name: "Cotabato Chartered City"
      }
      {
        abbreviation: ""
        name: "Dagupan Chartered City"
      }
      {
        abbreviation: ""
        name: "Danao Chartered City"
      }
      {
        abbreviation: ""
        name: "Dapitan Chartered City"
      }
      {
        abbreviation: ""
        name: "Davao Chartered City"
      }
      {
        abbreviation: ""
        name: "Dipolog Chartered City"
      }
      {
        abbreviation: ""
        name: "Dumaguete Chartered City"
      }
      {
        abbreviation: ""
        name: "General Santos Chartered City"
      }
      {
        abbreviation: ""
        name: "Gingoog Chartered City"
      }
      {
        abbreviation: ""
        name: "Iligan Chartered City"
      }
      {
        abbreviation: ""
        name: "Iloilo"
      }
      {
        abbreviation: ""
        name: "Iriga Chartered City"
      }
      {
        abbreviation: ""
        name: "La Carlota Chartered City"
      }
      {
        abbreviation: ""
        name: "Laoag Chartered City"
      }
      {
        abbreviation: ""
        name: "Lapu-Lapu Chartered City"
      }
      {
        abbreviation: ""
        name: "Legaspi Chartered City"
      }
      {
        abbreviation: ""
        name: "Lipa Chartered City"
      }
      {
        abbreviation: ""
        name: "Lucena Chartered City"
      }
      {
        abbreviation: ""
        name: "Mandaue Chartered City"
      }
      {
        abbreviation: ""
        name: "Metropolitan Manila"
      }
      {
        abbreviation: ""
        name: "Marawi Chartered City"
      }
      {
        abbreviation: ""
        name: "Naga Chartered City"
      }
      {
        abbreviation: ""
        name: "Olongapo Chartered City"
      }
      {
        abbreviation: ""
        name: "Ormoc Chartered City"
      }
      {
        abbreviation: ""
        name: "Oroquieta Chartered City"
      }
      {
        abbreviation: ""
        name: "Ozamis Chartered City"
      }
      {
        abbreviation: ""
        name: "Pagadian Chartered City"
      }
      {
        abbreviation: ""
        name: "Palayan Chartered City"
      }
      {
        abbreviation: ""
        name: "Pasay Chartered City"
      }
      {
        abbreviation: ""
        name: "Puerto Princesa Chartered City"
      }
      {
        abbreviation: ""
        name: "Quezon  or City?"
      }
      {
        abbreviation: ""
        name: "Roxas Chartered City"
      }
      {
        abbreviation: ""
        name: "San Carlos Chartered City"
      }
      {
        abbreviation: ""
        name: "San Pablo Chartered City"
      }
      {
        abbreviation: ""
        name: "Silay Chartered City"
      }
      {
        abbreviation: ""
        name: "Surigao Chartered City"
      }
      {
        abbreviation: ""
        name: "Tacloban Chartered City"
      }
      {
        abbreviation: ""
        name: "Tagaytay Chartered City"
      }
      {
        abbreviation: ""
        name: "Tagbilaran Chartered City"
      }
      {
        abbreviation: ""
        name: "Tangub Chartered City"
      }
      {
        abbreviation: ""
        name: "Toledo Chartered City"
      }
      {
        abbreviation: ""
        name: "Trece Martires Chartered City"
      }
      {
        abbreviation: ""
        name: "Aurora"
      }
      {
        abbreviation: ""
        name: "Quezon  or City?"
      }
      {
        abbreviation: ""
        name: "Negros Occidental"
      }
    ]
  }
  {
    name: "Poland"
    abbreviation: "PL"
    states: [
      {
        abbreviation: ""
        name: "Bialystok"
      }
      {
        abbreviation: ""
        name: "Dolnoslaskie"
      }
      {
        abbreviation: ""
        name: "Kujawsko-Pomorskie"
      }
      {
        abbreviation: ""
        name: "Lodzkie"
      }
      {
        abbreviation: ""
        name: "Lubelskie"
      }
      {
        abbreviation: ""
        name: "Lubuskie"
      }
      {
        abbreviation: ""
        name: "Malopolskie"
      }
      {
        abbreviation: ""
        name: "Mazowieckie"
      }
      {
        abbreviation: ""
        name: "Opolskie"
      }
      {
        abbreviation: ""
        name: "Podkarpackie"
      }
      {
        abbreviation: ""
        name: "Podlaskie"
      }
      {
        abbreviation: ""
        name: "Pomorskie"
      }
      {
        abbreviation: ""
        name: "Slaskie"
      }
      {
        abbreviation: ""
        name: "Swietokrzyskie"
      }
      {
        abbreviation: ""
        name: "Warminsko-Mazurskie"
      }
      {
        abbreviation: ""
        name: "Wielkopolskie"
      }
      {
        abbreviation: ""
        name: "Koszalin"
      }
    ]
  }
  {
    name: "Portugal"
    abbreviation: "PT"
    states: [
      {
        abbreviation: ""
        name: "Aveiro District"
      }
      {
        abbreviation: ""
        name: "Beja District"
      }
      {
        abbreviation: ""
        name: "Braga District"
      }
      {
        abbreviation: ""
        name: "Braganca District"
      }
      {
        abbreviation: ""
        name: "Castelo Branco District"
      }
      {
        abbreviation: ""
        name: "Coimbra District"
      }
      {
        abbreviation: ""
        name: "Evora District"
      }
      {
        abbreviation: ""
        name: "Faro District"
      }
      {
        abbreviation: ""
        name: "Madeira Autonomous Region"
      }
      {
        abbreviation: ""
        name: "Guarda District"
      }
      {
        abbreviation: ""
        name: "Leiria District"
      }
      {
        abbreviation: ""
        name: "Lisboa District"
      }
      {
        abbreviation: ""
        name: "Portalegre District"
      }
      {
        abbreviation: ""
        name: "Porto District"
      }
      {
        abbreviation: ""
        name: "Santarem District"
      }
      {
        abbreviation: ""
        name: "Setubal District"
      }
      {
        abbreviation: ""
        name: "Viana do Castelo District"
      }
      {
        abbreviation: ""
        name: "Vila Real District"
      }
      {
        abbreviation: ""
        name: "Viseu District"
      }
      {
        abbreviation: ""
        name: "Acores Autonomous Region"
      }
    ]
  }
  {
    name: "Qatar"
    abbreviation: "QA"
    states: [
      {
        abbreviation: ""
        name: "Ad Dawh"
      }
      {
        abbreviation: ""
        name: "Al Jumayli-yah Municipality"
      }
      {
        abbreviation: ""
        name: "Al Khawr Municipality"
      }
      {
        abbreviation: ""
        name: "Al Wakrah Municipality"
      }
      {
        abbreviation: ""
        name: "Madi-nat ash Shama-l Municipality"
      }
      {
        abbreviation: ""
        name: "Umm Sala-l Municipality"
      }
    ]
  }
  {
    name: "Romania"
    abbreviation: "RO"
    states: [
      {
        abbreviation: ""
        name: "Alba County"
      }
      {
        abbreviation: ""
        name: "Arad County"
      }
      {
        abbreviation: ""
        name: "Arges"
      }
      {
        abbreviation: ""
        name: "Bacau County"
      }
      {
        abbreviation: ""
        name: "Bihor County"
      }
      {
        abbreviation: ""
        name: "Bistrit"
      }
      {
        abbreviation: ""
        name: "Botos ani"
      }
      {
        abbreviation: ""
        name: "Braila County"
      }
      {
        abbreviation: ""
        name: "Bras ov"
      }
      {
        abbreviation: ""
        name: "Bucures"
      }
      {
        abbreviation: ""
        name: "Buzau County"
      }
      {
        abbreviation: ""
        name: "Caras"
      }
      {
        abbreviation: ""
        name: "Cluj County"
      }
      {
        abbreviation: ""
        name: "Constanta"
      }
      {
        abbreviation: ""
        name: "Covasna County"
      }
      {
        abbreviation: ""
        name: "Dambovita"
      }
      {
        abbreviation: ""
        name: "Dolj County"
      }
      {
        abbreviation: ""
        name: "Galati"
      }
      {
        abbreviation: ""
        name: "Gorj County"
      }
      {
        abbreviation: ""
        name: "Harghita County"
      }
      {
        abbreviation: ""
        name: "Hunedoara County"
      }
      {
        abbreviation: ""
        name: "Ialomita"
      }
      {
        abbreviation: ""
        name: "Iasi"
      }
      {
        abbreviation: ""
        name: "Maramures"
      }
      {
        abbreviation: ""
        name: "Mehedint"
      }
      {
        abbreviation: ""
        name: "Mures"
      }
      {
        abbreviation: ""
        name: "Neamt"
      }
      {
        abbreviation: ""
        name: "Olt County"
      }
      {
        abbreviation: ""
        name: "Prahova County"
      }
      {
        abbreviation: ""
        name: "Salaj County"
      }
      {
        abbreviation: ""
        name: "Satu Mare County"
      }
      {
        abbreviation: ""
        name: "Sibiu County"
      }
      {
        abbreviation: ""
        name: "Suceava County"
      }
      {
        abbreviation: ""
        name: "Teleorman County"
      }
      {
        abbreviation: ""
        name: "Timis"
      }
      {
        abbreviation: ""
        name: "Tulcea County"
      }
      {
        abbreviation: ""
        name: "Vaslui County"
      }
      {
        abbreviation: ""
        name: "Valcea County"
      }
      {
        abbreviation: ""
        name: "Vrancea County"
      }
      {
        abbreviation: ""
        name: "Calarasi"
      }
      {
        abbreviation: ""
        name: "Giurgiu County"
      }
      {
        abbreviation: ""
        name: "Ilfov County"
      }
    ]
  }
  {
    name: "Russian Federation"
    abbreviation: "RU"
    states: [
      {
        abbreviation: ""
        name: "Adygeya Republic"
      }
      {
        abbreviation: ""
        name: "Aginskiy Buryatskiy Avtonomnyy Okrug"
      }
      {
        abbreviation: ""
        name: "Altay Republic"
      }
      {
        abbreviation: ""
        name: "Altayskiy Kray"
      }
      {
        abbreviation: ""
        name: "Amurskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Arkhangel'skaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Astrakhanskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Bashkortostan Republic"
      }
      {
        abbreviation: ""
        name: "Belgorodskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Bryanskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Buryatiya Republic"
      }
      {
        abbreviation: ""
        name: "Chelyabinskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Chitinskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Chukotskiy Avtonomnyy Okrug"
      }
      {
        abbreviation: ""
        name: "Chuvashiya Republic"
      }
      {
        abbreviation: ""
        name: "Dagestan Republic"
      }
      {
        abbreviation: ""
        name: "Evenkiyskiy Avtonomnyy Okrug"
      }
      {
        abbreviation: ""
        name: "Irkutskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Ivanovskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Kabardino-Balkariya Republic"
      }
      {
        abbreviation: ""
        name: "Kaliningradskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Kalmykiya Republic"
      }
      {
        abbreviation: ""
        name: "Kaluzhskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Kamchatskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Karachayevo-Cherkesiya Republic"
      }
      {
        abbreviation: ""
        name: "Kareliya Republic"
      }
      {
        abbreviation: ""
        name: "Kemerovskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Khabarovskiy Kray"
      }
      {
        abbreviation: ""
        name: "Khakasiya Republic"
      }
      {
        abbreviation: ""
        name: "Khanty-Mansiyskiy Avtonomnyy Okrug"
      }
      {
        abbreviation: ""
        name: "Kirovskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Komi Republic"
      }
      {
        abbreviation: ""
        name: "Koryakskiy Avtonomnyy Okrug"
      }
      {
        abbreviation: ""
        name: "Kostromskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Krasnodarskiy Kray"
      }
      {
        abbreviation: ""
        name: "Krasnoyarskiy Kray"
      }
      {
        abbreviation: ""
        name: "Kurganskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Kurskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Leningradskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Lipetskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Magadanskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Mariy-El Republic"
      }
      {
        abbreviation: ""
        name: "Mordoviya Republic"
      }
      {
        abbreviation: ""
        name: "Moskovskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Moskva Federal City"
      }
      {
        abbreviation: ""
        name: "Murmanskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Nenetskiy Avtonomnyy Okrug"
      }
      {
        abbreviation: ""
        name: "Nizhegorodskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Novgorodskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Novosibirskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Omskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Orenburgskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Orlovskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Penzenskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Primorskiy Kray"
      }
      {
        abbreviation: ""
        name: "Pskovskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Rostovskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Ryazanskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Sakha (Yakutiya) Republic"
      }
      {
        abbreviation: ""
        name: "Sakhalinskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Samarskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Sankt-Peterburg Federal City"
      }
      {
        abbreviation: ""
        name: "Saratovskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Severnaya Osetiya-Alaniya Respublika"
      }
      {
        abbreviation: ""
        name: "Smolenskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Stavropol'skiy Kray"
      }
      {
        abbreviation: ""
        name: "Sverdlovskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Tambovskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Tatarstan Republic"
      }
      {
        abbreviation: ""
        name: "Taymyrskiy (Dolgano-Nenetskiy) Avtonomnyy Okrug"
      }
      {
        abbreviation: ""
        name: "Tomskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Tul'skaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Tverskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Tyumenskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Tyva Republic"
      }
      {
        abbreviation: ""
        name: "Udmurtiya Republic"
      }
      {
        abbreviation: ""
        name: "Ul'yanovskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Ust'-Ordynskiy Buryatskiy Avtonomnyy Okrug"
      }
      {
        abbreviation: ""
        name: "Vladimirskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Volgogradskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Vologodskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Voronezhskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Yamalo-Nenetskiy Avtonomnyy Okrug"
      }
      {
        abbreviation: ""
        name: "Yaroslavskaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Yevreyskaya Avtonomnaya Oblast'"
      }
      {
        abbreviation: ""
        name: "Permskiy Kray"
      }
      {
        abbreviation: ""
        name: "Chechnya Republic"
      }
    ]
  }
  {
    name: "Rwanda"
    abbreviation: "RW"
    states: [
      {
        abbreviation: ""
        name: "East"
      }
      {
        abbreviation: ""
        name: "Kigali"
      }
      {
        abbreviation: ""
        name: "North"
      }
      {
        abbreviation: ""
        name: "West"
      }
      {
        abbreviation: ""
        name: "South"
      }
    ]
  }
  {
    name: "Saint Kitts And Nevis"
    abbreviation: "KN"
    states: [
      {
        abbreviation: ""
        name: "Christ Church Nichola Town Parish"
      }
      {
        abbreviation: ""
        name: "Saint Anne Sandy Point Parish"
      }
      {
        abbreviation: ""
        name: "Saint George Basseterre Parish"
      }
      {
        abbreviation: ""
        name: "Saint George Gingerland Parish"
      }
      {
        abbreviation: ""
        name: "Saint James Windward Parish"
      }
      {
        abbreviation: ""
        name: "Saint John Capesterre Parish"
      }
      {
        abbreviation: ""
        name: "Saint John Figtree Parish"
      }
      {
        abbreviation: ""
        name: "Saint Mary Cayon Parish"
      }
      {
        abbreviation: ""
        name: "Saint Paul Capesterre Parish"
      }
      {
        abbreviation: ""
        name: "Saint Paul Charlestown Parish"
      }
      {
        abbreviation: ""
        name: "Saint Peter Basseterre Parish"
      }
      {
        abbreviation: ""
        name: "Saint Thomas Lowland Parish"
      }
      {
        abbreviation: ""
        name: "Saint Thomas Middle Island Parish"
      }
      {
        abbreviation: ""
        name: "Trinity Palmetto Point Parish"
      }
    ]
  }
  {
    name: "Saint Lucia"
    abbreviation: "LC"
    states: [
      {
        abbreviation: ""
        name: "Anse-la-Raye Quarter"
      }
      {
        abbreviation: ""
        name: "Dauphin Quarter"
      }
      {
        abbreviation: ""
        name: "Castries Quarter"
      }
      {
        abbreviation: ""
        name: "Choiseul Quarter"
      }
      {
        abbreviation: ""
        name: "Dennery Quarter"
      }
      {
        abbreviation: ""
        name: "Gros-Islet Quarter"
      }
      {
        abbreviation: ""
        name: "Laborie Quarter"
      }
      {
        abbreviation: ""
        name: "Micoud Quarter"
      }
      {
        abbreviation: ""
        name: "Soufriere Quarter"
      }
      {
        abbreviation: ""
        name: "Vieux-Fort Quarter"
      }
      {
        abbreviation: ""
        name: "Praslin Quarter"
      }
    ]
  }
  {
    name: "San Marino"
    abbreviation: "SM"
    states: [
      {
        abbreviation: ""
        name: "Acquaviva Municipality"
      }
      {
        abbreviation: ""
        name: "Chiesanuova Municipality"
      }
      {
        abbreviation: ""
        name: "San Marino CityMunicipality"
      }
      {
        abbreviation: ""
        name: "Serravalle Municipality"
      }
    ]
  }
  {
    name: "Saudi Arabia"
    abbreviation: "SA"
    states: [
      {
        abbreviation: ""
        name: "Al Ba-h"
      }
      {
        abbreviation: ""
        name: "Al Madi-nah"
      }
      {
        abbreviation: ""
        name: "Ash Sharqi-yah"
      }
      {
        abbreviation: ""
        name: "Al Qasi-m"
      }
      {
        abbreviation: ""
        name: "Ar Riya-d"
      }
      {
        abbreviation: ""
        name: "Ha-'il"
      }
      {
        abbreviation: ""
        name: "Makkah"
      }
      {
        abbreviation: ""
        name: "Al Hudu-d ash Shama-li-yah"
      }
      {
        abbreviation: ""
        name: "Ji-za-n"
      }
      {
        abbreviation: ""
        name: "Tabu-k"
      }
      {
        abbreviation: ""
        name: "Al Jawf"
      }
    ]
  }
  {
    name: "Senegal"
    abbreviation: "SN"
    states: [
      {
        abbreviation: ""
        name: "Dakar Region"
      }
      {
        abbreviation: ""
        name: "Diourbel Region"
      }
      {
        abbreviation: ""
        name: "Tambacounda Region"
      }
      {
        abbreviation: ""
        name: "Thies Region"
      }
      {
        abbreviation: ""
        name: "Fatick Region"
      }
      {
        abbreviation: ""
        name: "Kaolack Region"
      }
      {
        abbreviation: ""
        name: "Kolda Region"
      }
      {
        abbreviation: ""
        name: "Ziguinchor Region"
      }
      {
        abbreviation: ""
        name: "Louga Region"
      }
      {
        abbreviation: ""
        name: "Saint-Louis Region"
      }
      {
        abbreviation: ""
        name: "Matam Region"
      }
    ]
  }
  {
    name: "Seychelles"
    abbreviation: "SC"
    states: [
      {
        abbreviation: ""
        name: "Anse Etoile"
      }
      {
        abbreviation: ""
        name: "Grand Anse Mahe"
      }
      {
        abbreviation: ""
        name: "Inner Islands"
      }
      {
        abbreviation: ""
        name: "English River"
      }
      {
        abbreviation: ""
        name: "Port Glaud"
      }
      {
        abbreviation: ""
        name: "Au Cap"
      }
    ]
  }
  {
    name: "Sierra Leone"
    abbreviation: "SL"
    states: [
      {
        abbreviation: ""
        name: "Eastern"
      }
      {
        abbreviation: ""
        name: "Northern"
      }
      {
        abbreviation: ""
        name: "Southern"
      }
      {
        abbreviation: ""
        name: "Western Area"
      }
    ]
  }
  {
    name: "Singapore"
    abbreviation: "SG"
    states: [
      {
        abbreviation: ""
        name: "Central"
      }
      {
        abbreviation: ""
        name: "East"
      }
      {
        abbreviation: ""
        name: "North"
      }
      {
        abbreviation: ""
        name: "North-East"
      }
      {
        abbreviation: ""
        name: "West"
      }
    ]
  }
  {
    name: "Solomon Islands"
    abbreviation: "SB"
    states: [
      {
        abbreviation: ""
        name: "Central"
      }
      {
        abbreviation: ""
        name: "Western"
      }
      {
        abbreviation: ""
        name: "Choiseul"
      }
      {
        abbreviation: ""
        name: "Rennell and Bellona"
      }
    ]
  }
  {
    name: "Somalia"
    abbreviation: "SO"
    states: [
      {
        abbreviation: ""
        name: "Bakool Region"
      }
      {
        abbreviation: ""
        name: "Banaadir Region"
      }
      {
        abbreviation: ""
        name: "Bari Region"
      }
      {
        abbreviation: ""
        name: "Bay Region"
      }
      {
        abbreviation: ""
        name: "Galguduud Region"
      }
      {
        abbreviation: ""
        name: "Gedo Region"
      }
      {
        abbreviation: ""
        name: "Hiiraan Region"
      }
      {
        abbreviation: ""
        name: "Jubbada Dhexe Region"
      }
      {
        abbreviation: ""
        name: "Jubbada Hoose Region"
      }
      {
        abbreviation: ""
        name: "Mudug Region"
      }
      {
        abbreviation: ""
        name: "Sanaag Region"
      }
      {
        abbreviation: ""
        name: "Shabeellaha Dhexe Region"
      }
      {
        abbreviation: ""
        name: "Shabeellaha Hoose Region"
      }
      {
        abbreviation: ""
        name: "Nugaal Region"
      }
      {
        abbreviation: ""
        name: "Togdheer Region"
      }
      {
        abbreviation: ""
        name: "Woqooyi Galbeed Region"
      }
      {
        abbreviation: ""
        name: "Awdal Region"
      }
      {
        abbreviation: ""
        name: "Sool Region"
      }
    ]
  }
  {
    name: "South Africa"
    abbreviation: "ZA"
    states: [
      {
        abbreviation: ""
        name: "KwaZulu-Natal"
      }
      {
        abbreviation: ""
        name: "Free State"
      }
      {
        abbreviation: ""
        name: "Eastern Cape"
      }
      {
        abbreviation: ""
        name: "Gauteng"
      }
      {
        abbreviation: ""
        name: "Mpumalanga"
      }
      {
        abbreviation: ""
        name: "Northern Cape"
      }
      {
        abbreviation: ""
        name: "Limpopo"
      }
      {
        abbreviation: ""
        name: "North-West"
      }
      {
        abbreviation: ""
        name: "Western Cape"
      }
    ]
  }
  {
    name: "Spain"
    abbreviation: "ES"
    states: [
      {
        abbreviation: ""
        name: "Toledo"
      }
      {
        abbreviation: ""
        name: "Nueva Segovia"
      }
      {
        abbreviation: ""
        name: "Nueva Vizcaya"
      }
      {
        abbreviation: ""
        name: "Islas Baleares Comunidad Autonoma"
      }
      {
        abbreviation: ""
        name: "La Rioja Autonomous Community"
      }
      {
        abbreviation: ""
        name: "Madrid Autonomous Community"
      }
      {
        abbreviation: ""
        name: "Murcia Autonomous Community"
      }
      {
        abbreviation: ""
        name: "Navarra Autonomous Community"
      }
      {
        abbreviation: ""
        name: ""
      }
      {
        abbreviation: ""
        name: "Cantabria Autonomous Community"
      }
      {
        abbreviation: ""
        name: "Andalucia Autonomous Community"
      }
      {
        abbreviation: ""
        name: "Aragon Autonomous Community"
      }
      {
        abbreviation: ""
        name: "Canarias Autonomous Community"
      }
      {
        abbreviation: ""
        name: "Castilla-La Mancha Autonomous Community"
      }
      {
        abbreviation: ""
        name: "Castilla y Leon Autonomous Community"
      }
      {
        abbreviation: ""
        name: "Cataluna Autonomous Community"
      }
      {
        abbreviation: ""
        name: "Extremadura Autonomous Community"
      }
      {
        abbreviation: ""
        name: "Galicia Autonomous Community"
      }
      {
        abbreviation: ""
        name: "Pais Vasco Autonomous Community"
      }
    ]
  }
  {
    name: "Sri Lanka"
    abbreviation: "LK"
    states: [
      {
        abbreviation: ""
        name: ""
      }
      {
        abbreviation: ""
        name: ""
      }
      {
        abbreviation: ""
        name: "Ampara"
      }
      {
        abbreviation: ""
        name: "Anuradhapura"
      }
      {
        abbreviation: ""
        name: "Badulla"
      }
      {
        abbreviation: ""
        name: "Batticaloa"
      }
      {
        abbreviation: ""
        name: "Galle"
      }
      {
        abbreviation: ""
        name: "Hambantota"
      }
      {
        abbreviation: ""
        name: "Kalutara"
      }
      {
        abbreviation: ""
        name: "Kandy"
      }
      {
        abbreviation: ""
        name: "Kegalla"
      }
      {
        abbreviation: ""
        name: "Kurunegala"
      }
      {
        abbreviation: ""
        name: "Matale"
      }
      {
        abbreviation: ""
        name: "Matara"
      }
      {
        abbreviation: ""
        name: "Nuwara Eliya"
      }
      {
        abbreviation: ""
        name: "Polonnaruwa"
      }
      {
        abbreviation: ""
        name: "Puttalam"
      }
      {
        abbreviation: ""
        name: "Ratnapura"
      }
      {
        abbreviation: ""
        name: "Trincomalee"
      }
      {
        abbreviation: ""
        name: "Colombo"
      }
      {
        abbreviation: ""
        name: "Gampaha"
      }
      {
        abbreviation: ""
        name: "Jaffna"
      }
      {
        abbreviation: ""
        name: "Mannar"
      }
      {
        abbreviation: ""
        name: "Mullaittivu"
      }
      {
        abbreviation: ""
        name: "Vavuniya"
      }
    ]
  }
  {
    name: "Sudan"
    abbreviation: "SD"
    states: [
      {
        abbreviation: ""
        name: "Al Khartu-m State"
      }
      {
        abbreviation: ""
        name: "Al Khartu-m State"
      }
      {
        abbreviation: ""
        name: "Bahr al Ghaza-l"
      }
      {
        abbreviation: ""
        name: "Da-rfu-r"
      }
      {
        abbreviation: ""
        name: "Kurdufa-n"
      }
      {
        abbreviation: ""
        name: "A`a-li- an Ni-l State"
      }
      {
        abbreviation: ""
        name: "Al Bahr al Ahmar State"
      }
      {
        abbreviation: ""
        name: "Al Bahr al Ahmar State"
      }
      {
        abbreviation: ""
        name: "Al Buhayra-t State"
      }
      {
        abbreviation: ""
        name: "Al Jazi-rah State"
      }
      {
        abbreviation: ""
        name: "Al Jazi-rah State"
      }
      {
        abbreviation: ""
        name: "Al Qada-rif State"
      }
      {
        abbreviation: ""
        name: "Al Qada-rif State"
      }
      {
        abbreviation: ""
        name: "Al Wahdah State"
      }
      {
        abbreviation: ""
        name: "An Ni-l al Abyad State"
      }
      {
        abbreviation: ""
        name: "An Ni-l al Abyad State"
      }
      {
        abbreviation: ""
        name: "An Ni-l al Azraq State"
      }
      {
        abbreviation: ""
        name: "An Ni-l al Azraq State"
      }
      {
        abbreviation: ""
        name: "Ash Shama-li-yah State"
      }
      {
        abbreviation: ""
        name: "Ash Shama-li-yah State"
      }
      {
        abbreviation: ""
        name: "Bahr al Jabal State"
      }
      {
        abbreviation: ""
        name: "Gharb al Istiwa-'i-yah State"
      }
      {
        abbreviation: ""
        name: "Gharb Bahr al Ghaza-l State"
      }
      {
        abbreviation: ""
        name: "Gharb Da-rfu-r State"
      }
      {
        abbreviation: ""
        name: "Gharb Da-rfu-r State"
      }
      {
        abbreviation: ""
        name: "Gharb Kurdufa-n State"
      }
      {
        abbreviation: ""
        name: "Janu-b Da-rfu-r State"
      }
      {
        abbreviation: ""
        name: "Janu-b Da-rfu-r State"
      }
      {
        abbreviation: ""
        name: "Janu-b Kurdufa-n State"
      }
      {
        abbreviation: ""
        name: "Janu-b Kurdufa-n State"
      }
      {
        abbreviation: ""
        name: "Junqali- State"
      }
      {
        abbreviation: ""
        name: "Kassala- State"
      }
      {
        abbreviation: ""
        name: "Kassala- State"
      }
      {
        abbreviation: ""
        name: "Nahr an Ni-l State"
      }
      {
        abbreviation: ""
        name: "Nahr an Ni-l State"
      }
      {
        abbreviation: ""
        name: "Shama-l Bahr al Ghaza-l State"
      }
      {
        abbreviation: ""
        name: "Shama-l Bahr al Ghaza-l State"
      }
      {
        abbreviation: ""
        name: "Shama-l Da-rfu-r State"
      }
      {
        abbreviation: ""
        name: "Shama-l Da-rfu-r State"
      }
      {
        abbreviation: ""
        name: "Shama-l Kurdufa-n State"
      }
      {
        abbreviation: ""
        name: "Sharq al Istiwa-'i-yah State"
      }
      {
        abbreviation: ""
        name: "Sinna-r State"
      }
      {
        abbreviation: ""
        name: "Sinna-r State"
      }
      {
        abbreviation: ""
        name: "Warab State"
      }
    ]
  }
  {
    name: "Suriname"
    abbreviation: "SR"
    states: [
      {
        abbreviation: ""
        name: "Brokopondo District"
      }
      {
        abbreviation: ""
        name: "Commewijne District"
      }
      {
        abbreviation: ""
        name: "Coronie District"
      }
      {
        abbreviation: ""
        name: "Marowijne District"
      }
      {
        abbreviation: ""
        name: "Nickerie District"
      }
      {
        abbreviation: ""
        name: "Para District"
      }
      {
        abbreviation: ""
        name: "Paramaribo District"
      }
      {
        abbreviation: ""
        name: "Saramacca District"
      }
      {
        abbreviation: ""
        name: "Sipaliwini District"
      }
      {
        abbreviation: ""
        name: "Wanica District"
      }
    ]
  }
  {
    name: "Sweden"
    abbreviation: "SE"
    states: [
      {
        abbreviation: ""
        name: "Blekinge County"
      }
      {
        abbreviation: ""
        name: "Gavleborg County"
      }
      {
        abbreviation: ""
        name: "Gotland County"
      }
      {
        abbreviation: ""
        name: "Halland County"
      }
      {
        abbreviation: ""
        name: "Jamtland County"
      }
      {
        abbreviation: ""
        name: "Jonkoping County"
      }
      {
        abbreviation: ""
        name: "Kalmar County"
      }
      {
        abbreviation: ""
        name: "Dalarna County"
      }
      {
        abbreviation: ""
        name: "Kronoberg County"
      }
      {
        abbreviation: ""
        name: "Norrbotten County"
      }
      {
        abbreviation: ""
        name: "Orebro County"
      }
      {
        abbreviation: ""
        name: "Ostergotland County"
      }
      {
        abbreviation: ""
        name: "Sodermanland County"
      }
      {
        abbreviation: ""
        name: "Uppsala County"
      }
      {
        abbreviation: ""
        name: "Varmland County"
      }
      {
        abbreviation: ""
        name: "Vasterbotten County"
      }
      {
        abbreviation: ""
        name: "Vasternorrland County"
      }
      {
        abbreviation: ""
        name: "Vastmanland County"
      }
      {
        abbreviation: ""
        name: "Stockholm County"
      }
      {
        abbreviation: ""
        name: "Skane County"
      }
      {
        abbreviation: ""
        name: "Vastra Gotaland County"
      }
    ]
  }
  {
    name: "Switzerland"
    abbreviation: "CH"
    states: [
      {
        abbreviation: ""
        name: "Aargau"
      }
      {
        abbreviation: ""
        name: "Appenzell Ausserrhoden"
      }
      {
        abbreviation: ""
        name: "Basel-Landschaft"
      }
      {
        abbreviation: ""
        name: "Basel-Stadt"
      }
      {
        abbreviation: ""
        name: "Bern"
      }
      {
        abbreviation: ""
        name: "Fribourg"
      }
      {
        abbreviation: ""
        name: "Geneva"
      }
      {
        abbreviation: ""
        name: "Glarus"
      }
      {
        abbreviation: ""
        name: "Graubunden"
      }
      {
        abbreviation: ""
        name: "Appenzell Innerrhoden"
      }
      {
        abbreviation: ""
        name: "Lucerne"
      }
      {
        abbreviation: ""
        name: "Neuchatel"
      }
      {
        abbreviation: ""
        name: "Nidwalden"
      }
      {
        abbreviation: ""
        name: "Obwalden"
      }
      {
        abbreviation: ""
        name: "St. Gallen"
      }
      {
        abbreviation: ""
        name: "Schaffhausen"
      }
      {
        abbreviation: ""
        name: "Schwyz"
      }
      {
        abbreviation: ""
        name: "Solothurn"
      }
      {
        abbreviation: ""
        name: "Thurgau"
      }
      {
        abbreviation: ""
        name: "Ticino"
      }
      {
        abbreviation: ""
        name: "Uri"
      }
      {
        abbreviation: ""
        name: "Valais"
      }
      {
        abbreviation: ""
        name: "Vaud"
      }
      {
        abbreviation: ""
        name: "Zug"
      }
      {
        abbreviation: ""
        name: "Zurich"
      }
      {
        abbreviation: ""
        name: "Jura"
      }
    ]
  }
  {
    name: "Syria"
    abbreviation: "SY"
    states: [
      {
        abbreviation: ""
        name: "Al Hasakah"
      }
      {
        abbreviation: ""
        name: "Al La-dhiqi-yah"
      }
      {
        abbreviation: ""
        name: "Al Qunaytirah"
      }
      {
        abbreviation: ""
        name: "Ar Raqqah"
      }
      {
        abbreviation: ""
        name: "As Suwayda-'"
      }
      {
        abbreviation: ""
        name: "Dar`a-"
      }
      {
        abbreviation: ""
        name: "Deir ez-Zor"
      }
      {
        abbreviation: ""
        name: "Ri-f Dimashq"
      }
      {
        abbreviation: ""
        name: "H alab"
      }
      {
        abbreviation: ""
        name: "Hama-h"
      }
      {
        abbreviation: ""
        name: "Hims"
      }
      {
        abbreviation: ""
        name: "Idlib"
      }
      {
        abbreviation: ""
        name: "Dimashq"
      }
      {
        abbreviation: ""
        name: "Tartus"
      }
    ]
  }
  {
    name: "Taiwan"
    abbreviation: "TW"
    states: [
      {
        abbreviation: ""
        name: "Kao-hsiung"
      }
      {
        abbreviation: ""
        name: "T'ai-pei"
      }
      {
        abbreviation: ""
        name: "T'ai-wan"
      }
    ]
  }
  {
    name: "Tajikistan"
    abbreviation: "TJ"
    states: [
      {
        abbreviation: ""
        name: "Kuhistoni Badakhshon Viloyati Mukhtor"
      }
      {
        abbreviation: ""
        name: "Khatlon"
      }
      {
        abbreviation: ""
        name: "Sughd"
      }
    ]
  }
  {
    name: "Tanzania"
    abbreviation: "TZ"
    states: [
      {
        abbreviation: ""
        name: "Pwani Region"
      }
      {
        abbreviation: ""
        name: "Dodoma Region"
      }
      {
        abbreviation: ""
        name: "Iringa Region"
      }
      {
        abbreviation: ""
        name: "Kigoma Region"
      }
      {
        abbreviation: ""
        name: "Kilimanjaro Region"
      }
      {
        abbreviation: ""
        name: "Lindi Region"
      }
      {
        abbreviation: ""
        name: "Mara Region"
      }
      {
        abbreviation: ""
        name: "Mbeya Region"
      }
      {
        abbreviation: ""
        name: "Morogoro Region"
      }
      {
        abbreviation: ""
        name: "Mtwara Region"
      }
      {
        abbreviation: ""
        name: "Mwanza Region"
      }
      {
        abbreviation: ""
        name: "Pemba North Region **"
      }
      {
        abbreviation: ""
        name: "Ruvuma Region"
      }
    ]
  }
  {
    name: "Thailand"
    abbreviation: "TH"
    states: [
      {
        abbreviation: ""
        name: "Mae Hong Son"
      }
      {
        abbreviation: ""
        name: "Chiang Mai"
      }
      {
        abbreviation: ""
        name: "Chiang Rai"
      }
      {
        abbreviation: ""
        name: "Nan"
      }
      {
        abbreviation: ""
        name: "Lamphun"
      }
      {
        abbreviation: ""
        name: "Lampang"
      }
      {
        abbreviation: ""
        name: "Phrae"
      }
      {
        abbreviation: ""
        name: "Tak"
      }
      {
        abbreviation: ""
        name: "Sukhothai"
      }
      {
        abbreviation: ""
        name: "Uttaradit"
      }
      {
        abbreviation: ""
        name: "Kamphaeng Phet"
      }
      {
        abbreviation: ""
        name: "Phitsanulok"
      }
      {
        abbreviation: ""
        name: "Phichit"
      }
      {
        abbreviation: ""
        name: "Phetchabun"
      }
      {
        abbreviation: ""
        name: "Uthai Thani"
      }
      {
        abbreviation: ""
        name: "Nakhon Sawan"
      }
      {
        abbreviation: ""
        name: "Nong Khai"
      }
      {
        abbreviation: ""
        name: "Loei"
      }
      {
        abbreviation: ""
        name: "Sakon Nakhon"
      }
      {
        abbreviation: ""
        name: "Khon Kaen"
      }
      {
        abbreviation: ""
        name: "Kalasin"
      }
      {
        abbreviation: ""
        name: "Maha Sarakham"
      }
      {
        abbreviation: ""
        name: "Roi Et"
      }
      {
        abbreviation: ""
        name: "Chaiyaphum"
      }
      {
        abbreviation: ""
        name: "Nakhon Ratchasima"
      }
      {
        abbreviation: ""
        name: "Buriram"
      }
      {
        abbreviation: ""
        name: "Surin"
      }
      {
        abbreviation: ""
        name: "Sisaket"
      }
      {
        abbreviation: ""
        name: "Narathiwat"
      }
      {
        abbreviation: ""
        name: "Chai Nat"
      }
      {
        abbreviation: ""
        name: "Sing Buri"
      }
      {
        abbreviation: ""
        name: "Lop Buri"
      }
      {
        abbreviation: ""
        name: "Ang Thong"
      }
      {
        abbreviation: ""
        name: "Phra Nakhon Si Ayutthaya"
      }
      {
        abbreviation: ""
        name: "Sara Buri"
      }
      {
        abbreviation: ""
        name: "Nonthaburi"
      }
      {
        abbreviation: ""
        name: "Pathum Thani"
      }
      {
        abbreviation: ""
        name: "Krung Thep Mahanakhon"
      }
      {
        abbreviation: ""
        name: "Phayao"
      }
      {
        abbreviation: ""
        name: "Samut Prakan"
      }
      {
        abbreviation: ""
        name: "Nakhon Nayok"
      }
      {
        abbreviation: ""
        name: "Chachoengsao"
      }
      {
        abbreviation: ""
        name: "Prachin Buri"
      }
      {
        abbreviation: ""
        name: "Chon Buri"
      }
      {
        abbreviation: ""
        name: "Rayong"
      }
      {
        abbreviation: ""
        name: "Chanthaburi"
      }
      {
        abbreviation: ""
        name: "Trat"
      }
      {
        abbreviation: ""
        name: "Kanchanaburi"
      }
      {
        abbreviation: ""
        name: "Suphan Buri"
      }
      {
        abbreviation: ""
        name: "Ratchaburi"
      }
      {
        abbreviation: ""
        name: "Nakhon Pathom"
      }
      {
        abbreviation: ""
        name: "Samut Songkhram"
      }
      {
        abbreviation: ""
        name: "Samut Sakhon"
      }
      {
        abbreviation: ""
        name: "Phetchaburi"
      }
      {
        abbreviation: ""
        name: "Prachuap Khiri Khan"
      }
      {
        abbreviation: ""
        name: "Chumphon"
      }
      {
        abbreviation: ""
        name: "Ranong"
      }
      {
        abbreviation: ""
        name: "Surat Thani"
      }
      {
        abbreviation: ""
        name: "Phangnga"
      }
      {
        abbreviation: ""
        name: "Phuket"
      }
      {
        abbreviation: ""
        name: "Krabi"
      }
      {
        abbreviation: ""
        name: "Nakhon Si Thammarat"
      }
      {
        abbreviation: ""
        name: "Trang"
      }
      {
        abbreviation: ""
        name: "Phatthalung"
      }
      {
        abbreviation: ""
        name: "Satun"
      }
      {
        abbreviation: ""
        name: "Songkhla"
      }
      {
        abbreviation: ""
        name: "Pattani"
      }
      {
        abbreviation: ""
        name: "Yala"
      }
      {
        abbreviation: ""
        name: "Ubon Ratchathani"
      }
      {
        abbreviation: ""
        name: "Yasothon"
      }
      {
        abbreviation: ""
        name: "Nakhon Phanom"
      }
      {
        abbreviation: ""
        name: "Udon Thani"
      }
      {
        abbreviation: ""
        name: "Amnat Charoen"
      }
      {
        abbreviation: ""
        name: "Mukdahan"
      }
      {
        abbreviation: ""
        name: "Nong Bua Lamphu"
      }
      {
        abbreviation: ""
        name: "Sa Kaeo"
      }
    ]
  }
  {
    name: "Togo"
    abbreviation: "TG"
    states: [
      {
        abbreviation: ""
        name: "Centrale Region"
      }
      {
        abbreviation: ""
        name: "Kara Region"
      }
      {
        abbreviation: ""
        name: "Maritime Region"
      }
      {
        abbreviation: ""
        name: "Plateaux Region"
      }
      {
        abbreviation: ""
        name: "Savanes Region"
      }
    ]
  }
  {
    name: "Turkey"
    abbreviation: "TR"
    states: [
      {
        abbreviation: ""
        name: ""
      }
      {
        abbreviation: ""
        name: ""
      }
      {
        abbreviation: ""
        name: ""
      }
      {
        abbreviation: ""
        name: ""
      }
      {
        abbreviation: ""
        name: ""
      }
      {
        abbreviation: ""
        name: ""
      }
      {
        abbreviation: ""
        name: ""
      }
    ]
  }
  {
    name: "Turkmenistan"
    abbreviation: "TM"
    states: [
      {
        abbreviation: ""
        name: "Ahal"
      }
      {
        abbreviation: ""
        name: "Balkan"
      }
      {
        abbreviation: ""
        name: "Das"
      }
      {
        abbreviation: ""
        name: "Lebap"
      }
      {
        abbreviation: ""
        name: "Mary"
      }
    ]
  }
  {
    name: "Ukraine"
    abbreviation: "UA"
    states: [
      {
        abbreviation: ""
        name: "Cherkasy"
      }
      {
        abbreviation: ""
        name: "Chernihiv"
      }
      {
        abbreviation: ""
        name: "Chernivtsi"
      }
      {
        abbreviation: ""
        name: "Dnipropetrovs'k"
      }
      {
        abbreviation: ""
        name: "Donets'k"
      }
      {
        abbreviation: ""
        name: "Ivano-Frankivs'ka"
      }
      {
        abbreviation: ""
        name: "Kharkiv"
      }
      {
        abbreviation: ""
        name: "Kherson"
      }
      {
        abbreviation: ""
        name: "Khmel'nyts'kyy"
      }
      {
        abbreviation: ""
        name: "Kirovohrad"
      }
      {
        abbreviation: ""
        name: "Crimea"
      }
      {
        abbreviation: ""
        name: "Kiev City"
      }
      {
        abbreviation: ""
        name: "Kiev"
      }
      {
        abbreviation: ""
        name: "Luhans'k"
      }
      {
        abbreviation: ""
        name: "L'viv"
      }
      {
        abbreviation: ""
        name: "Mykolayiv"
      }
      {
        abbreviation: ""
        name: "Odessa"
      }
      {
        abbreviation: ""
        name: "Poltava"
      }
      {
        abbreviation: ""
        name: "Rivne"
      }
      {
        abbreviation: ""
        name: "Sevastopol' City"
      }
      {
        abbreviation: ""
        name: "Sumy"
      }
      {
        abbreviation: ""
        name: "Ternopil"
      }
      {
        abbreviation: ""
        name: "Vinnytsya"
      }
      {
        abbreviation: ""
        name: "Volyn"
      }
      {
        abbreviation: ""
        name: "Transcarpathia"
      }
      {
        abbreviation: ""
        name: "Zaporiz'ka"
      }
      {
        abbreviation: ""
        name: "Zhytomyr"
      }
    ]
  }
  {
    name: "United Arab Emirates"
    abbreviation: "AE"
    states: [
      {
        abbreviation: ""
        name: "Abu Dhabi"
      }
      {
        abbreviation: ""
        name: "Ajman"
      }
      {
        abbreviation: ""
        name: "Dubai"
      }
      {
        abbreviation: ""
        name: "Fujairah"
      }
      {
        abbreviation: ""
        name: "Sharjah"
      }
      {
        abbreviation: ""
        name: "Umm Al Quwain"
      }
    ]
  }
  {
    name: "United Kingdom"
    abbreviation: "GB"
    states: [
      {
        abbreviation: "AB"
        name: "Aberdeen"
      }
      {
        abbreviation: "AL"
        name: "St Albans"
      }
      {
        abbreviation: "B"
        name: "Birmingham"
      }
      {
        abbreviation: "BA"
        name: "Bath"
      }
      {
        abbreviation: "BB"
        name: "Blackburn"
      }
      {
        abbreviation: "BD"
        name: "Bradford"
      }
      {
        abbreviation: "BH"
        name: "Bournemouth"
      }
      {
        abbreviation: "BL"
        name: "Bolton"
      }
      {
        abbreviation: "BN"
        name: "Brighton"
      }
      {
        abbreviation: "BR"
        name: "Bromley"
      }
      {
        abbreviation: "BS"
        name: "Bristol"
      }
      {
        abbreviation: "BT"
        name: "Northern Ireland"
      }
      {
        abbreviation: "CA"
        name: "Carlisle"
      }
      {
        abbreviation: "CB"
        name: "Cambridge"
      }
      {
        abbreviation: "CF"
        name: "Cardiff"
      }
      {
        abbreviation: "CH"
        name: "Chester"
      }
      {
        abbreviation: "CM"
        name: "Chelmsford"
      }
      {
        abbreviation: "CO"
        name: "Colchester"
      }
      {
        abbreviation: "CR"
        name: "Croydon"
      }
      {
        abbreviation: "CT"
        name: "Canterbury"
      }
      {
        abbreviation: "CV"
        name: "Coventry"
      }
      {
        abbreviation: "CW"
        name: "Crewe"
      }
      {
        abbreviation: "DA"
        name: "Dartford"
      }
      {
        abbreviation: "DD"
        name: "Dundee"
      }
      {
        abbreviation: "DE"
        name: "Derby"
      }
      {
        abbreviation: "DG"
        name: "Dumfries"
      }
      {
        abbreviation: "DH"
        name: "Durham"
      }
      {
        abbreviation: "DL"
        name: "Darlington"
      }
      {
        abbreviation: "DN"
        name: "Doncaster"
      }
      {
        abbreviation: "DT"
        name: "Dorchester"
      }
      {
        abbreviation: "DY"
        name: "Dudley"
      }
      {
        abbreviation: "E"
        name: "London"
      }
      {
        abbreviation: "EC"
        name: "London"
      }
      {
        abbreviation: "EH"
        name: "Edinburgh"
      }
      {
        abbreviation: "EN"
        name: "Enfield"
      }
      {
        abbreviation: "EX"
        name: "Exeter"
      }
      {
        abbreviation: "FK"
        name: "Falkirk"
      }
      {
        abbreviation: "FY"
        name: "Blackpool"
      }
      {
        abbreviation: "G"
        name: "Glasgow"
      }
      {
        abbreviation: "GL"
        name: "Gloucester"
      }
      {
        abbreviation: "GU"
        name: "Guildford"
      }
      {
        abbreviation: "HA"
        name: "Harrow"
      }
      {
        abbreviation: "HD"
        name: "Huddersfield"
      }
      {
        abbreviation: "HG"
        name: "Harrogate"
      }
      {
        abbreviation: "HP"
        name: "Hemel Hempstead"
      }
      {
        abbreviation: "HR"
        name: "Hereford"
      }
      {
        abbreviation: "HS"
        name: "Outer Hebrides"
      }
      {
        abbreviation: "HU"
        name: "Hull"
      }
      {
        abbreviation: "HX"
        name: "Halifax"
      }
      {
        abbreviation: "IG"
        name: "Ilford"
      }
      {
        abbreviation: "IP"
        name: "Ipswich"
      }
      {
        abbreviation: "IV"
        name: "Inverness"
      }
      {
        abbreviation: "KA"
        name: "Kilmarnock"
      }
      {
        abbreviation: "KT"
        name: "Kingston upon Thames"
      }
      {
        abbreviation: "KW"
        name: "Kirkwall"
      }
      {
        abbreviation: "KY"
        name: "Kirkcaldy"
      }
      {
        abbreviation: "L"
        name: "Liverpool"
      }
      {
        abbreviation: "LA"
        name: "Lancaster"
      }
      {
        abbreviation: "LD"
        name: "Llandrindod Wells"
      }
      {
        abbreviation: "LE"
        name: "Leicester"
      }
      {
        abbreviation: "LL"
        name: "Llandudno"
      }
      {
        abbreviation: "LN"
        name: "Lincoln"
      }
      {
        abbreviation: "LS"
        name: "Leeds"
      }
      {
        abbreviation: "LU"
        name: "Luton"
      }
      {
        abbreviation: "M"
        name: "Manchester"
      }
      {
        abbreviation: "ME"
        name: "Rochester"
      }
      {
        abbreviation: "MK"
        name: "Milton Keynes"
      }
      {
        abbreviation: "ML"
        name: "Motherwell"
      }
      {
        abbreviation: "N"
        name: "London"
      }
      {
        abbreviation: "NE"
        name: "Newcastle upon Tyne"
      }
      {
        abbreviation: "NG"
        name: "Nottingham"
      }
      {
        abbreviation: "NN"
        name: "Northampton"
      }
      {
        abbreviation: "NP"
        name: "Newport"
      }
      {
        abbreviation: "NR"
        name: "Norwich"
      }
      {
        abbreviation: "NW"
        name: "London"
      }
      {
        abbreviation: "OL"
        name: "Oldham"
      }
      {
        abbreviation: "OX"
        name: "Oxford"
      }
      {
        abbreviation: "PA"
        name: "Paisley"
      }
      {
        abbreviation: "PE"
        name: "Peterborough"
      }
      {
        abbreviation: "PH"
        name: "Perth"
      }
      {
        abbreviation: "PL"
        name: "Plymouth"
      }
      {
        abbreviation: "PO"
        name: "Portsmouth"
      }
      {
        abbreviation: "PR"
        name: "Preston"
      }
      {
        abbreviation: "RG"
        name: "Reading"
      }
      {
        abbreviation: "RH"
        name: "Redhill"
      }
      {
        abbreviation: "RM"
        name: "Romford"
      }
      {
        abbreviation: "S"
        name: "Sheffield"
      }
      {
        abbreviation: "SA"
        name: "Swansea"
      }
      {
        abbreviation: "SE"
        name: "London"
      }
      {
        abbreviation: "SG"
        name: "Stevenage"
      }
      {
        abbreviation: "SK"
        name: "Stockport"
      }
      {
        abbreviation: "SL"
        name: "Slough"
      }
      {
        abbreviation: "SM"
        name: "Sutton"
      }
      {
        abbreviation: "SN"
        name: "Swindon"
      }
      {
        abbreviation: "SO"
        name: "Southampton"
      }
      {
        abbreviation: "SP"
        name: "Salisbury"
      }
      {
        abbreviation: "SR"
        name: "Sunderland"
      }
      {
        abbreviation: "SS"
        name: "Southend-on-Sea"
      }
      {
        abbreviation: "ST"
        name: "Stoke-on-Trent"
      }
      {
        abbreviation: "SW"
        name: "London"
      }
      {
        abbreviation: "SY"
        name: "Shrewsbury"
      }
      {
        abbreviation: "TA"
        name: "Taunton"
      }
      {
        abbreviation: "TD"
        name: "Galashiels"
      }
      {
        abbreviation: "TF"
        name: "Telford"
      }
      {
        abbreviation: "TN"
        name: "Tonbridge"
      }
      {
        abbreviation: "TQ"
        name: "Torquay"
      }
      {
        abbreviation: "TR"
        name: "Truro"
      }
      {
        abbreviation: "TS"
        name: "Cleveland"
      }
      {
        abbreviation: "TW"
        name: "Twickenham"
      }
      {
        abbreviation: "UB"
        name: "Southall"
      }
      {
        abbreviation: "W"
        name: "London"
      }
      {
        abbreviation: "WA"
        name: "Warrington"
      }
      {
        abbreviation: "WC"
        name: "London"
      }
      {
        abbreviation: "WD"
        name: "Watford"
      }
      {
        abbreviation: "WF"
        name: "Wakefield"
      }
      {
        abbreviation: "WN"
        name: "Wigan"
      }
      {
        abbreviation: "WR"
        name: "Worcester"
      }
      {
        abbreviation: "WS"
        name: "Walsall"
      }
      {
        abbreviation: "WV"
        name: "Wolverhampton"
      }
      {
        abbreviation: "YO"
        name: "York"
      }
      {
        abbreviation: "ZE"
        name: "Lerwick"
      }
    ]
  }
  {
    name: "United States"
    abbreviation: "US"
    states: [
      {
        abbreviation: "AL"
        name: "Alabama"
      }
      {
        abbreviation: "AK"
        name: "Alaska"
      }
      {
        abbreviation: "AZ"
        name: "Arizona"
      }
      {
        abbreviation: "AR"
        name: "Arkansas"
      }
      {
        abbreviation: "CA"
        name: "California"
      }
      {
        abbreviation: "CO"
        name: "Colorado"
      }
      {
        abbreviation: "CT"
        name: "Connecticut"
      }
      {
        abbreviation: "DE"
        name: "Delaware"
      }
      {
        abbreviation: "DC"
        name: "District of Columbia"
      }
      {
        abbreviation: "FL"
        name: "Florida"
      }
      {
        abbreviation: "GA"
        name: "Georgia"
      }
      {
        abbreviation: "HI"
        name: "Hawaii"
      }
      {
        abbreviation: "ID"
        name: "Idaho"
      }
      {
        abbreviation: "IL"
        name: "Illinois"
      }
      {
        abbreviation: "IN"
        name: "Indiana"
      }
      {
        abbreviation: "IA"
        name: "Iowa"
      }
      {
        abbreviation: "KS"
        name: "Kansas"
      }
      {
        abbreviation: "KY"
        name: "Kentucky"
      }
      {
        abbreviation: "LA"
        name: "Louisiana"
      }
      {
        abbreviation: "ME"
        name: "Maine"
      }
      {
        abbreviation: "MD"
        name: "Maryland"
      }
      {
        abbreviation: "MA"
        name: "Massachusetts"
      }
      {
        abbreviation: "MI"
        name: "Michigan"
      }
      {
        abbreviation: "MN"
        name: "Minnesota"
      }
      {
        abbreviation: "MS"
        name: "Mississippi"
      }
      {
        abbreviation: "MO"
        name: "Missouri"
      }
      {
        abbreviation: "MT"
        name: "Montana"
      }
      {
        abbreviation: "NE"
        name: "Nebraska"
      }
      {
        abbreviation: "NV"
        name: "Nevada"
      }
      {
        abbreviation: "NH"
        name: "New Hampshire"
      }
      {
        abbreviation: "NJ"
        name: "New Jersey"
      }
      {
        abbreviation: "NM"
        name: "New Mexico"
      }
      {
        abbreviation: "NY"
        name: "New York"
      }
      {
        abbreviation: "NC"
        name: "North Carolina"
      }
      {
        abbreviation: "ND"
        name: "North Dakota"
      }
      {
        abbreviation: "OH"
        name: "Ohio"
      }
      {
        abbreviation: "OK"
        name: "Oklahoma"
      }
      {
        abbreviation: "OR"
        name: "Oregon"
      }
      {
        abbreviation: "PA"
        name: "Pennsylvania"
      }
      {
        abbreviation: "RI"
        name: "Rhode Island"
      }
      {
        abbreviation: "SC"
        name: "South Carolina"
      }
      {
        abbreviation: "SD"
        name: "South Dakota"
      }
      {
        abbreviation: "TN"
        name: "Tennessee"
      }
      {
        abbreviation: "TX"
        name: "Texas"
      }
      {
        abbreviation: "UT"
        name: "Utah"
      }
      {
        abbreviation: "VT"
        name: "Vermont"
      }
      {
        abbreviation: "VA"
        name: "Virginia"
      }
      {
        abbreviation: "WA"
        name: "Washington"
      }
      {
        abbreviation: "WV"
        name: "West Virginia"
      }
      {
        abbreviation: "WI"
        name: "Wisconsin"
      }
      {
        abbreviation: "WY"
        name: "Wyoming"
      }
      {
        abbreviation: "AS"
        name: "American Samoa Territory"
      }
      {
        abbreviation: "FM"
        name: "Federated States of Micronesia Territory"
      }
      {
        abbreviation: "GU"
        name: "Guam Territory"
      }
      {
        abbreviation: "MH"
        name: "Marshall Islands Territory"
      }
      {
        abbreviation: "MP"
        name: "Northern Mariana Islands"
      }
      {
        abbreviation: ""
        name: "PW"
      }
      {
        abbreviation: "PR"
        name: "Puerto Rico"
      }
      {
        abbreviation: "VI"
        name: "Virgin Islands of the U.S."
      }
    ]
  }
  {
    name: "United States Minor Outlying Islands"
    abbreviation: "UM"
    states: [
      {
        abbreviation: ""
        name: "Johnston Atoll"
      }
      {
        abbreviation: ""
        name: "Kingman Reef"
      }
      {
        abbreviation: ""
        name: "Midway Islands"
      }
      {
        abbreviation: ""
        name: "Navassa Island"
      }
      {
        abbreviation: ""
        name: "Wake Island"
      }
      {
        abbreviation: ""
        name: "Baker Island"
      }
      {
        abbreviation: ""
        name: "Howland Island"
      }
      {
        abbreviation: ""
        name: "Jarvis Island"
      }
      {
        abbreviation: ""
        name: "Palmyra Atoll"
      }
    ]
  }
  {
    name: "Uruguay"
    abbreviation: "UY"
    states: [
      {
        abbreviation: ""
        name: "Artigas"
      }
      {
        abbreviation: ""
        name: "Canelones"
      }
      {
        abbreviation: ""
        name: "Cerro Largo"
      }
      {
        abbreviation: ""
        name: "Colonia"
      }
      {
        abbreviation: ""
        name: "Durazno"
      }
      {
        abbreviation: ""
        name: "Flores"
      }
      {
        abbreviation: ""
        name: "Florida"
      }
      {
        abbreviation: ""
        name: "Lavalleja"
      }
      {
        abbreviation: ""
        name: "Maldonado"
      }
      {
        abbreviation: ""
        name: "Montevideo"
      }
      {
        abbreviation: ""
        name: "PaysandÃº"
      }
      {
        abbreviation: ""
        name: "RÃ­o Negro"
      }
      {
        abbreviation: ""
        name: "Rivera"
      }
      {
        abbreviation: ""
        name: "Rocha"
      }
      {
        abbreviation: ""
        name: "Salto"
      }
      {
        abbreviation: ""
        name: "San JosÃ©"
      }
      {
        abbreviation: ""
        name: "Soriano"
      }
      {
        abbreviation: ""
        name: "TacuarembÃ³"
      }
      {
        abbreviation: ""
        name: "Treinta y Tres"
      }
    ]
  }
  {
    name: "Uzbekistan"
    abbreviation: "UZ"
    states: [
      {
        abbreviation: ""
        name: "Andijon"
      }
      {
        abbreviation: ""
        name: "Buxoro"
      }
      {
        abbreviation: ""
        name: "Fargona"
      }
      {
        abbreviation: ""
        name: "Xorazm"
      }
      {
        abbreviation: ""
        name: "Namangan"
      }
      {
        abbreviation: ""
        name: "Navoi"
      }
      {
        abbreviation: ""
        name: "Qashqadaryo"
      }
      {
        abbreviation: ""
        name: "Qoraqalpakstan"
      }
      {
        abbreviation: ""
        name: "Samarkand"
      }
      {
        abbreviation: ""
        name: "Surxondaryo"
      }
      {
        abbreviation: ""
        name: "Tashkent City"
      }
      {
        abbreviation: ""
        name: "Tashkent"
      }
      {
        abbreviation: ""
        name: "Jizzax"
      }
      {
        abbreviation: ""
        name: "Sirdaryo"
      }
    ]
  }
  {
    name: "Vanuatu"
    abbreviation: "VU"
    states: [
      {
        abbreviation: ""
        name: "Torba"
      }
      {
        abbreviation: ""
        name: "Sanma"
      }
      {
        abbreviation: ""
        name: "Tafea"
      }
      {
        abbreviation: ""
        name: "Malampa"
      }
      {
        abbreviation: ""
        name: "Penama"
      }
      {
        abbreviation: ""
        name: "Shefa"
      }
    ]
  }
  {
    name: "Venezuela"
    abbreviation: "VE"
    states: [
      {
        abbreviation: ""
        name: "Amazonas"
      }
      {
        abbreviation: ""
        name: "Anzoategui"
      }
      {
        abbreviation: ""
        name: "Apure"
      }
      {
        abbreviation: ""
        name: "Aragua"
      }
      {
        abbreviation: ""
        name: "Barinas"
      }
      {
        abbreviation: ""
        name: "BolÃ­var"
      }
      {
        abbreviation: ""
        name: "Carabobo"
      }
      {
        abbreviation: ""
        name: "Cojedes"
      }
      {
        abbreviation: ""
        name: "Delta Amacuro"
      }
      {
        abbreviation: ""
        name: "FalcÃ³n"
      }
      {
        abbreviation: ""
        name: "GuÃ¡rico"
      }
      {
        abbreviation: ""
        name: "Lara"
      }
      {
        abbreviation: ""
        name: "MÃ©rida"
      }
      {
        abbreviation: ""
        name: "Miranda"
      }
      {
        abbreviation: ""
        name: "Monagas"
      }
      {
        abbreviation: ""
        name: "Nueva Esparta"
      }
      {
        abbreviation: ""
        name: "Portuguesa"
      }
      {
        abbreviation: ""
        name: "Sucre"
      }
      {
        abbreviation: ""
        name: "TÃ¡chira"
      }
      {
        abbreviation: ""
        name: "Trujillo"
      }
      {
        abbreviation: ""
        name: "Yaracuy"
      }
      {
        abbreviation: ""
        name: "Zulia"
      }
      {
        abbreviation: ""
        name: "Dependencias Federales"
      }
      {
        abbreviation: ""
        name: "Distrito Capital"
      }
      {
        abbreviation: ""
        name: "Vargas"
      }
    ]
  }
  {
    name: "Viet Nam"
    abbreviation: "VN"
    states: [
      {
        abbreviation: ""
        name: "An Giang"
      }
      {
        abbreviation: ""
        name: "Ben Tre"
      }
      {
        abbreviation: ""
        name: "Cao Bang"
      }
      {
        abbreviation: ""
        name: "Dong Thap"
      }
      {
        abbreviation: ""
        name: "Hai Phong"
      }
      {
        abbreviation: ""
        name: "Ho Chi Minh"
      }
      {
        abbreviation: ""
        name: "Kien Giang"
      }
      {
        abbreviation: ""
        name: "Lam Dong"
      }
      {
        abbreviation: ""
        name: "Long An"
      }
      {
        abbreviation: ""
        name: "Quang Ninh"
      }
      {
        abbreviation: ""
        name: "Son La"
      }
      {
        abbreviation: ""
        name: "Tay Ninh"
      }
      {
        abbreviation: ""
        name: "Thanh Hoa"
      }
      {
        abbreviation: ""
        name: "Thai Binh"
      }
      {
        abbreviation: ""
        name: "Tien Giang"
      }
      {
        abbreviation: ""
        name: "Lang Son"
      }
      {
        abbreviation: ""
        name: "Dong Nai"
      }
      {
        abbreviation: ""
        name: "Ha Noi"
      }
      {
        abbreviation: ""
        name: "Ba Ria-Vung Tau"
      }
      {
        abbreviation: ""
        name: "Binh Dinh"
      }
      {
        abbreviation: ""
        name: "Binh Thuan"
      }
      {
        abbreviation: ""
        name: "Gia Lai"
      }
      {
        abbreviation: ""
        name: "Ha Giang"
      }
      {
        abbreviation: ""
        name: "Ha Tay"
      }
      {
        abbreviation: ""
        name: "Ha Tinh"
      }
      {
        abbreviation: ""
        name: "Hoa Binh"
      }
      {
        abbreviation: ""
        name: "Khanh Hoa"
      }
      {
        abbreviation: ""
        name: "Kon Tum"
      }
      {
        abbreviation: ""
        name: "Nghe An"
      }
      {
        abbreviation: ""
        name: "Ninh Binh"
      }
      {
        abbreviation: ""
        name: "Ninh Thuan"
      }
      {
        abbreviation: ""
        name: "Phu Yen"
      }
      {
        abbreviation: ""
        name: "Quang Binh"
      }
      {
        abbreviation: ""
        name: "Quang Ngai"
      }
      {
        abbreviation: ""
        name: "Quang Tri"
      }
      {
        abbreviation: ""
        name: "Soc Trang"
      }
      {
        abbreviation: ""
        name: "Thua Thien-Hue"
      }
      {
        abbreviation: ""
        name: "Tra Vinh"
      }
      {
        abbreviation: ""
        name: "Tuyen Quang"
      }
      {
        abbreviation: ""
        name: "Vinh Long"
      }
      {
        abbreviation: ""
        name: "Yen Bai"
      }
      {
        abbreviation: ""
        name: "Bac Giang"
      }
      {
        abbreviation: ""
        name: "Bac Can"
      }
      {
        abbreviation: ""
        name: "Bac Lieu"
      }
      {
        abbreviation: ""
        name: "Bac Ninh"
      }
      {
        abbreviation: ""
        name: "Binh Duong"
      }
      {
        abbreviation: ""
        name: "Binh Phuoc"
      }
      {
        abbreviation: ""
        name: "Ca Mau"
      }
      {
        abbreviation: ""
        name: "Da Nang"
      }
      {
        abbreviation: ""
        name: "Hai Duong"
      }
      {
        abbreviation: ""
        name: "Ha Nam"
      }
      {
        abbreviation: ""
        name: "Hung Yen"
      }
      {
        abbreviation: ""
        name: "Nam Dinh"
      }
      {
        abbreviation: ""
        name: "Phu Tho"
      }
      {
        abbreviation: ""
        name: "Quang Nam"
      }
      {
        abbreviation: ""
        name: "Thai Nguyen"
      }
      {
        abbreviation: ""
        name: "Vinh Phuc"
      }
      {
        abbreviation: ""
        name: "Can Tho"
      }
      {
        abbreviation: ""
        name: "Can Tho"
      }
      {
        abbreviation: ""
        name: "Dac Lac"
      }
      {
        abbreviation: ""
        name: "Lai Chau"
      }
      {
        abbreviation: ""
        name: "Lao Cai"
      }
      {
        abbreviation: ""
        name: "Dak Nong"
      }
      {
        abbreviation: ""
        name: "Dien Bien"
      }
      {
        abbreviation: ""
        name: "Hau Giang"
      }
    ]
  }
  {
    name: "Zambia"
    abbreviation: "ZM"
    states: [
      {
        abbreviation: ""
        name: "Western"
      }
      {
        abbreviation: ""
        name: "Central"
      }
      {
        abbreviation: ""
        name: "Eastern"
      }
      {
        abbreviation: ""
        name: "Luapala"
      }
      {
        abbreviation: ""
        name: "Northern"
      }
      {
        abbreviation: ""
        name: "North-Western"
      }
      {
        abbreviation: ""
        name: "Southern"
      }
      {
        abbreviation: ""
        name: "Copperbelt"
      }
      {
        abbreviation: ""
        name: "Lusaka"
      }
    ]
  }
  {
    name: "Zimbabwe"
    abbreviation: "ZW"
    states: [
      {
        abbreviation: ""
        name: "Manicaland"
      }
      {
        abbreviation: ""
        name: "Midlands"
      }
      {
        abbreviation: ""
        name: "Mashonaland Central"
      }
      {
        abbreviation: ""
        name: "Mashonaland East"
      }
      {
        abbreviation: ""
        name: "Mashonaland West"
      }
      {
        abbreviation: ""
        name: "Matabeleland North"
      }
      {
        abbreviation: ""
        name: "Matabeleland South"
      }
      {
        abbreviation: ""
        name: "Masvingo"
      }
      {
        abbreviation: ""
        name: "Bulawayo"
      }
      {
        abbreviation: ""
        name: "Harare"
      }
    ]
  }
]