module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div class="flextainer flexcenter" style="width: 100%; padding: 10px 0;">\n  <div style="width: 330px;">\n    <strong>Money needs to be returned.  Would you like to:</strong><br/>\n    <br/>\n    <div class="btn-group" data-toggle="buttons-radio">\n      <button id="returnAsRefundButton" type="button" class="btn active">Refund Now</button>\n      <button id="returnAsCreditButton" type="button" class="btn">Keep as Credit</button>\n    </div>\n  </div>\n</div>\n<div class="flextainer flexcenter" style="width: 100%; padding: 10px 0 20px 0;">\n  <div id="returnTypeTooltip" style="width: 330px;">\n\n  </div>\n</div>\n', __filename = "js/views/order/return_money.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div class="flextainer flexcenter" style="width: 100%; padding: 10px 0;">\n  <div style="width: 330px;">\n    <strong>Money needs to be returned.  Would you like to:</strong><br/>\n    <br/>\n    <div class="btn-group" data-toggle="buttons-radio">\n      <button id="returnAsRefundButton" type="button" class="btn active">Refund Now</button>\n      <button id="returnAsCreditButton" type="button" class="btn">Keep as Credit</button>\n    </div>\n  </div>\n</div>\n<div class="flextainer flexcenter" style="width: 100%; padding: 10px 0 20px 0;">\n  <div id="returnTypeTooltip" style="width: 330px;">\n\n  </div>\n</div>\n');
            __line = 16;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}