template = require('./uploads_table.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

module.exports = App.Views.Inventory.UploadsTable = App.Views.Base.BaseView.extend

  template: template

  dataTableOptions:
    bJQueryUI: true
    sDom: '<"">t<"F"fp>'
    bScrollInfinite: true
    bScrollCollapse: false
    iDisplayLength: 1000
    sScrollY: '700px'
    aaSorting:[
        [ 0, 'desc']   # Date
    ]
    aoColumns:[
      { sType: 'date' }
      null
      null
      null
      null
    ]
  initialize: ( options ) ->
    @required_args [ "collection", "$container", "filterView" ], options
    @render()

  render: ->
    data = uploads: @collection.toPresenterJSON()
    @$el.html(@template(data))
    @$container.html(@$el)

    @endlessDataTable = new App.Utils.EndlessDataTable
      collection:       @collection
      filterView:       @filterView
      dataTableOptions: @dataTableOptions
    @$el

  # VIEW EVENTS /////////////////////////////////////////////////////////////
  events: {}
  #////////////////////////////////////////////////////////////////////////////
