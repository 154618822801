// DEATHROW
(function() {
  Backbone.Validator = {
    validates: function(attributes, validationObject) {
      var that = this;
      attributes = _.isArray(attributes) ? attributes : [attributes];
      var validate = function(validationObject, matcher) {
        var matcherObject = matcher || validatorMatchers;
        var validationKey = _.reject(_.keys(validationObject), function(key){
          return key === 'message';
        });

        var validationValue = validationObject[validationKey];
        var message = validationObject.message;

        _.each(attributes, function(attribute){
          if (_.isObject(validationValue) && !_.isArray(validationValue)) {
            validate(validationValue, matcherObject[validationKey]);
          } else if (validationValue === true) {
            that[matcherObject[validationKey].isTrue](attribute, message);
          } else {
            that[matcherObject[validationKey]](attribute, validationValue);
          }
        });
      };
      validate(validationObject);
    },
    validatesCurrency: function(attributes, testValue) {
      var that = this;
      var valid_currency = /^\$?[0-9,]+(\.[0-9]+)?$/;
      attributes = _.isArray(attributes) ? attributes : [attributes];

      _.each(attributes, function(attr) {
        if (that.get(attr) && !valid_currency.test(that.get(attr))) {
          that.errors.push(attr.humanize() + ' must be valid US currency');
        }
      });
    },
    validatesInclusion: function(attributes, testValue) {
      var that = this;
      attributes = _.isArray(attributes) ? attributes : [attributes];

      _.each(attributes, function(attr) {
        if (that.get(attr) && !_.contains(testValue, that.get(attr))) {
          that.errors.push('"' + that.get(attr) + '" is not a valid ' + attr.humanize().toLowerCase() + ' value');
        }
      });
    },
    validatesLengthMax: function(attributes, testValue) {
      var that = this;
      attributes = _.isArray(attributes) ? attributes : [attributes];

      _.each(attributes, function(attr) {
        if (that.get(attr) && that.get(attr).length > testValue) {
          that.errors.push(attr.humanize() + ' must be ' + testValue + ' characters or less');
        }
      });
    },
    validatesNumericality: function(attributes) {
      var that = this;
      attributes = _.isArray(attributes) ? attributes : [attributes];

      _.each(attributes, function(attr) {
        if (that.get(attr) && !_.isFinite(parseFloat(that.get(attr)))) {
          that.errors.push(attr.humanize() + ' must be numerical');
        }
      });
    },
    validatesNumericalityMax: function(attributes, testValue) {
      var that = this;
      attributes = _.isArray(attributes) ? attributes : [attributes];

      _.each(attributes, function(attr) {
        that.validatesNumericality(attr);

        if (that.get(attr) > testValue) {
          that.errors.push(attr.humanize() + ' must be ' + testValue + ' or less');
        }
      });
    },
    validatesNumericalityMin: function(attributes, testValue) {
      var that = this;
      attributes = _.isArray(attributes) ? attributes : [attributes];

      _.each(attributes, function(attr) {
        that.validatesNumericality(attr);

        if (that.get(attr) < testValue) {
          that.errors.push(attr.humanize() + ' must be ' + testValue + ' or more');
        }
      });
    },
    validatesPresence: function(attributes, message) {
      var that = this;
      attributes = _.isArray(attributes) ? attributes : [attributes];

      _.each(attributes, function(attr) {
        if (!that.get(attr) && that.get(attr) !== 0) {
          if(message){
            that.errors.push(message);
          }else{
            that.errors.push(attr.humanize() + " can't be blank");
          }
        }
      });
    }
  };
  _.extend(Backbone.Model.prototype, Backbone.Validator);

  var validatorMatchers = {
    currency: { isTrue: 'validatesCurrency' },
    inclusion: 'validatesInclusion',
    length: {
      max: 'validatesLengthMax'
    },
    numericality: {
      isTrue: 'validatesNumericality',
      max: 'validatesNumericalityMax',
      min: 'validatesNumericalityMin'
    },
    presence: { isTrue: 'validatesPresence' }
  };
})();
