App.Collections.Orders = require('../../collections/orders_collection')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.SalesCollectionWrapper = App.Models.Base.V3BaseModel.extend

  urlRoot: '/api_direct/v9/reports/sales?aggregates=true'

  defaults:
    collection: new App.Collections.Orders(null, {
      parentWrapper: @
      isSalesReport: true
    })

  initialize: (attributes, options = {}) ->
    parent.initialize.call(@, attributes, options)

  fetch: (options = {}) ->
    parent.fetch.call(@, options)

  parse: (json, options) ->
    parent.parse.call(@, json, options)
    collection = new App.Collections.Orders(json.sales, {
      parentWrapper: @
      isSalesReport: true
    })
    @set('collection', collection)
    attrs = json.aggregates
    return attrs

