App.Views.Base.Filter = require('../base/filter_view.coffee')

parent = App.Views.Base.Filter.prototype
module.exports = App.Views.Events.Filter = App.Views.Base.Filter.extend

  initialize: (options = {}) ->
    @controller = App.Controllers.eventsController
    parent.initialize.call(@, options)

  startLoad: () ->
    @loadingButton.startLoad()