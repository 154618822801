module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<select name="<%= name %>" id="payment_type">\n\n  <% _.each(dropdownOptions, function(val, key) {\n    var selected = \'\';\n    if (defaultPaymentType && defaultPaymentType == key) {\n      selected = "selected=\'true\'";\n    }\n  %>\n    <option value="<%= key %>" <%= selected %>>\n      <%= val %>\n    </option>\n  <% }); %>\n\n  <% _.each(blockedOptions, function(val, key) { %>\n  <option value="<%= key %>" disabled="true">\n    <%= val %> (Premium Only)\n  </option>\n  <% }); %>\n\n</select>\n', __filename = "js/views/payments/dropdown.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<select name="');
            __append(escapeFn(name));
            __append('" id="payment_type">\n\n  ');
            __line = 3;
            _.each(dropdownOptions, function(val, key) {
                var selected = "";
                if (defaultPaymentType && defaultPaymentType == key) {
                    selected = "selected='true'";
                }
                __line = 8;
                __append('\n    <option value="');
                __line = 9;
                __append(escapeFn(key));
                __append('" ');
                __append(escapeFn(selected));
                __append(">\n      ");
                __line = 10;
                __append(escapeFn(val));
                __append("\n    </option>\n  ");
                __line = 12;
            });
            __append("\n\n  ");
            __line = 14;
            _.each(blockedOptions, function(val, key) {
                __append('\n  <option value="');
                __line = 15;
                __append(escapeFn(key));
                __append('" disabled="true">\n    ');
                __line = 16;
                __append(escapeFn(val));
                __append(" (Premium Only)\n  </option>\n  ");
                __line = 18;
            });
            __append("\n\n</select>\n");
            __line = 21;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}