App.Collections.Base.PaginatedBase = require('./base/paginated_base_collection.coffee')
App.Models.InventoryUpload = require('../models/inventory_upload_model.coffee')

module.exports = App.Collections.InventoryUploads = App.Collections.Base.PaginatedBase.extend

  model: App.Models.InventoryUpload

  url:  "/api_direct/v9/ticket_groups/upload_history"

  initialize: (models, options = {}) ->
    App.Collections.Base.PaginatedBase.prototype.initialize.call(@, models, options)

  parse: (response, options) ->
    if (response.upload_history)
      return response.upload_history
    else
      return response
