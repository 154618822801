module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<form>\n    <div class="modal-header">\n        <span class="fa-solid fa-money-bill"></span>\n        <button class="close" data-dismiss="modal" aria-hidden="true"> x</button>\n        <h3 class="modal-title">\n            EvoPay -&nbsp;\n            <%= title %>\n        </h3>\n    </div>\n    <div class="modal-body" style="max-width: 600px;">\n        <div id="form_body"></div>\n        <hr />\n        <div id="balance"></div>\n    </div>\n    <div class="buttons modal-footer">\n        <button class="btn btn-danger" data-dismiss="modal" style="float:left">Cancel</button>\n        <button id="evopayTransactionButton" class="btn btn-primary save" data-loading-text="Processing"><%= button %></button>\n    </div>\n</form>\n', __filename = "js/views/evopay/evopay_modal_abstract.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<form>\n    <div class="modal-header">\n        <span class="fa-solid fa-money-bill"></span>\n        <button class="close" data-dismiss="modal" aria-hidden="true"> x</button>\n        <h3 class="modal-title">\n            EvoPay -&nbsp;\n            ');
            __line = 7;
            __append(escapeFn(title));
            __append('\n        </h3>\n    </div>\n    <div class="modal-body" style="max-width: 600px;">\n        <div id="form_body"></div>\n        <hr />\n        <div id="balance"></div>\n    </div>\n    <div class="buttons modal-footer">\n        <button class="btn btn-danger" data-dismiss="modal" style="float:left">Cancel</button>\n        <button id="evopayTransactionButton" class="btn btn-primary save" data-loading-text="Processing">');
            __line = 17;
            __append(escapeFn(button));
            __append("</button>\n    </div>\n</form>\n");
            __line = 20;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}