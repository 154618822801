App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V3.Exchange = require('../../models/v3/exchange_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V3.Exchanges = App.Collections.Base.BaseCollection.extend

  model: App.Models.V3.Exchange

  url: '/api_direct/v9/exchanges'

  initialize: (models, options = {}) ->
    parent.initialize.call(@, models, options)

  parse: (response, options) ->
    response.exchanges
