module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<select class="serviceType" id="serviceType" name="serviceType">\n  <%\n  _.each(rateOptions, function(text, id) { %>\n  %>\n  <option value="<%= id %>">\n    <%= text %>\n  </option>\n  <%\n  });\n  %>\n</select>\n', __filename = "js/views/shipping/rate_options.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<select class="serviceType" id="serviceType" name="serviceType">\n  ');
            __line = 2;
            _.each(rateOptions, function(text, id) {
                __line = 3;
                __append("\n  ");
                __line = 4;
                __append('\n  <option value="');
                __line = 5;
                __append(escapeFn(id));
                __append('">\n    ');
                __line = 6;
                __append(escapeFn(text));
                __append("\n  </option>\n  ");
                __line = 8;
            });
            __line = 10;
            __append("\n</select>\n");
            __line = 12;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}