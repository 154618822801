module.exports = poController = {}

_$container = null
poView = null
poFilterView = null

poController.start = (options) ->
  _$container = options.$container
  poView = new App.Views.PO.New(
    options
  )
  poFilterView = new App.Views.PO.Filter({
    $container: poView.$('#filter_container')
  })

poController.filterEvents = (filters) ->
  poView.applyFilters(filters)

poController.doneLoading = () ->
  poFilterView.doneLoading()

poController.displayLimitError = (limit) ->
  poFilterView.doneLoading()
  poView.showLimitPrompt(limit)