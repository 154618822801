template = require('./wasted_view.ejs')
module.exports = App.Views.TicketGroups.WastedView = Backbone.View.extend

  className: "wasted-view-dialog dialog modal fade hide"

  template: template

  events:
    'click a.confirm' : 'onWasteButtonClick'

  initialize: (options) ->
    {
      @callback
      @ticketGroupModel
    } = options
    @state = 'show'

  render: () ->
    @$el.empty()

    $('body').append(
      @$el.html(
        @template({
          state: @state
        })
      )
    )

    # STANDARDIZED MODAL
    @$el.modal('show').on('hidden', () =>
      @remove()
    )

  onWasteButtonClick: () ->
    reason = @$("#wasted_reason").val()
    @state = 'loading'
    @render()
    #$('.modal-backdrop').hide()
    loadingView = new App.Views.Shared.LoadingView()
    loadingView.render()
    @ticketGroupModel.waste(reason, (error, model) =>
      @$el.modal('hide')
      loadingView.remove()
      @callback(error, model)
    )
