module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<td><%= (payment.isRefund || payment.isCredit) ? \'Refund\' : \'Payment\' %></td>\n<td><%= payment.type %></td>\n<td><%= App.Utils.valueToCurrency(payment.amount) %></td>\n<td><%= payment.state %></td>\n\n<% if (payment.type == C.Options.Order.PaymentTypes.credit_card) { %>\n\n<td id="paymentDetails">\n  Name: <%= payment.creditCard.name %><br />\n  Type: <%= payment.creditCard.cardCompany %><br />\n  Last Digits: <%= payment.creditCard.lastDigits %><br />\n  Expiration: <%= payment.creditCard.expiration %><br />\n  CVV: <%= payment.CVVResponse %><br />\n  AVS:\n  <div style="margin-left: 20px;">\n    Street Response: <%= payment.AVSStreetAddressResponse %><br />\n    Postal Response: <%= payment.AVSPostalCodeResponse %><br />\n  </div>\n</td>\n\n<% } else if (payment.type == C.Options.Order.PaymentTypes.check) { %>\n\n<td id="paymentDetails">\n  Check Number: <%= payment.checkNumber %>\n</td>\n\n<% } else if (payment.type == C.Options.Order.PaymentTypes.credit_memo) { %>\n\n<td id="paymentDetails">\n  Credit Memo:\n  <a href="/credit_memo/<%= payment.creditMemoId %>">\n    <%= payment.creditMemoId %>\n  </a>\n</td>\n\n<% } else { %>\n\n<td id="paymentDetails">\n</td>\n\n<% } %>\n\n<td>\n  <%= App.Utils.makeTimestampHuman(payment.createdAt, C.DateFormats.LongNoDay) %>\n</td>\n\n<td>\n  <%\nif (payment.type == C.Options.Order.PaymentTypes.credit_card) {\n  if (payment.state == C.Options.Payment.States.authorized) {\n%>\n  <!-- HIDE UNTIL WORKING\n  <a id="voidCreditCardPaymentButton" class=\'btn btn-danger\'><i class="fa-solid fa-trash"></i> Remove</a>\n  -->\n  <%\n  }\n} else {\n  if (payment.state == C.Options.Payment.States.pending) {\n%>\n  <button class=\'applyButton btn btn-primary\'><i class="fa-solid fa-floppy-disk"></i> Apply</button>\n  <button class=\'removeButton btn btn-danger\'><i class="fa-solid fa-trash"></i> Cancel</button>\n  <%\n  }\n}\n%>\n</td>\n', __filename = "js/views/credit_memos/inline_payment.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append("<td>");
            __append(escapeFn(payment.isRefund || payment.isCredit ? "Refund" : "Payment"));
            __append("</td>\n<td>");
            __line = 2;
            __append(escapeFn(payment.type));
            __append("</td>\n<td>");
            __line = 3;
            __append(escapeFn(App.Utils.valueToCurrency(payment.amount)));
            __append("</td>\n<td>");
            __line = 4;
            __append(escapeFn(payment.state));
            __append("</td>\n\n");
            __line = 6;
            if (payment.type == C.Options.Order.PaymentTypes.credit_card) {
                __append('\n\n<td id="paymentDetails">\n  Name: ');
                __line = 9;
                __append(escapeFn(payment.creditCard.name));
                __append("<br />\n  Type: ");
                __line = 10;
                __append(escapeFn(payment.creditCard.cardCompany));
                __append("<br />\n  Last Digits: ");
                __line = 11;
                __append(escapeFn(payment.creditCard.lastDigits));
                __append("<br />\n  Expiration: ");
                __line = 12;
                __append(escapeFn(payment.creditCard.expiration));
                __append("<br />\n  CVV: ");
                __line = 13;
                __append(escapeFn(payment.CVVResponse));
                __append('<br />\n  AVS:\n  <div style="margin-left: 20px;">\n    Street Response: ');
                __line = 16;
                __append(escapeFn(payment.AVSStreetAddressResponse));
                __append("<br />\n    Postal Response: ");
                __line = 17;
                __append(escapeFn(payment.AVSPostalCodeResponse));
                __append("<br />\n  </div>\n</td>\n\n");
                __line = 21;
            } else if (payment.type == C.Options.Order.PaymentTypes.check) {
                __append('\n\n<td id="paymentDetails">\n  Check Number: ');
                __line = 24;
                __append(escapeFn(payment.checkNumber));
                __append("\n</td>\n\n");
                __line = 27;
            } else if (payment.type == C.Options.Order.PaymentTypes.credit_memo) {
                __append('\n\n<td id="paymentDetails">\n  Credit Memo:\n  <a href="/credit_memo/');
                __line = 31;
                __append(escapeFn(payment.creditMemoId));
                __append('">\n    ');
                __line = 32;
                __append(escapeFn(payment.creditMemoId));
                __append("\n  </a>\n</td>\n\n");
                __line = 36;
            } else {
                __append('\n\n<td id="paymentDetails">\n</td>\n\n');
                __line = 41;
            }
            __append("\n\n<td>\n  ");
            __line = 44;
            __append(escapeFn(App.Utils.makeTimestampHuman(payment.createdAt, C.DateFormats.LongNoDay)));
            __append("\n</td>\n\n<td>\n  ");
            __line = 48;
            if (payment.type == C.Options.Order.PaymentTypes.credit_card) {
                if (payment.state == C.Options.Payment.States.authorized) {
                    __line = 51;
                    __append('\n  <!-- HIDE UNTIL WORKING\n  <a id="voidCreditCardPaymentButton" class=\'btn btn-danger\'><i class="fa-solid fa-trash"></i> Remove</a>\n  -->\n  ');
                    __line = 55;
                }
            } else {
                if (payment.state == C.Options.Payment.States.pending) {
                    __line = 59;
                    __append("\n  <button class='applyButton btn btn-primary'><i class=\"fa-solid fa-floppy-disk\"></i> Apply</button>\n  <button class='removeButton btn btn-danger'><i class=\"fa-solid fa-trash\"></i> Cancel</button>\n  ");
                    __line = 62;
                }
            }
            __line = 65;
            __append("\n</td>\n");
            __line = 67;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}