template = require('./client_cards.ejs');

module.exports = App.Views.CreditCards.ClientCards = Backbone.View.extend({

  existingCardTemplate: template,

  initialize: function(opts) {
    var that = this;
    var model = this.model = opts.order;
    this.client = opts.patron;
    this.clientId = opts.order.get('client_id') || opts.order.get('client').id;

    if (!(model.has("client") && model.has("clientCreditCards"))) {
      this.fetchClientCards();
    }

    that.listenTo(App.Vent, 'reRenderClientCreditCards', _.bind(that.render, that));
  },

  render: function() {
    if (this.model.has('client') && this.model.has('clientCreditCards')) {
      this.$el.html(this.existingCardTemplate({credit_cards: this.model.get('clientCreditCards').models}))
    }
    return this.$el;
  },

  // VIEW EVENTS /////////////////////////////////////////////////////////////
  events: {
    'change #creditCards' : 'addNewCard'
  },

  addNewCard: function(e) {
    var new_card = $(e.currentTarget).find('option:selected').attr('id');

    if (new_card == 'addNewCard') {
      var newCardView = new App.Views.CreditCards.NewModal({
        officeId: SESSION.office_id,
        patronType: this.model.get('patron_type'),
        order: this.model
      });

      newCardView.render();
    }
  },
  ////////////////////////////////////////////////////////////////////////////

  fetchClientCards: function() {
    var that = this;

    this.collection = new App.Collections.CreditCards(null, {
      ownerType: C.Client,
      clientId: this.client.get('id')
    });

    $.when(this.collection.fetch({async:false})).then(function(response) {
      that.model.set('clientCreditCards', new App.Collections.CreditCards(response));
    });
  }

});
