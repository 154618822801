App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V3.OrderItem = require('../../models/v3/order_item_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V3.OrderItems = App.Collections.Base.BaseCollection.extend

  url: () ->
    if (@purpose == C.Purposes.OrderItems)
      return "/api_direct/v9/orders/#{ @orderId }/items"
    else if (@purpose == C.Purposes.TicketCosts)
      return "/api_direct/v9/orders/#{ @orderId }/get_ticket_costs?active_only=true"
    else
      return '/api_direct/v9/orders/orders_by_event'

  model: App.Models.V3.OrderItem

  initialize: (models, options = {}) ->
    {
      @orderId
      @purpose
    } = options
    parent.initialize.call(@, models, options)

  parse: (response, options) ->
    if (response.items)
      return response.items
    else
      return response.order_items

  getByOrderItemId: (orderItemId) ->
    results = @where({ _id: orderItemId })
    if (results.length)
      return results[0]
    else
      return null