App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.ShipmentSnapshot = App.Models.Base.V3BaseModel.extend

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.Order.ShipmentSnapshot)
      addressAttrs = _.pick(json,
        'country_code',
        'extended_address',
        'po_box',
        'label',
        'locality',
        'postal_code',
        'region',
        'street_address'
      )
      attrs._address = new App.Models.V3.Address(addressAttrs, {
        mapping: C.Mappings.Direct.Order.ShipmentSnapshotAddress
      })
      attrs._company = json.company
      attrs._emailAddress = json.email_address
      attrs._id = json.id
      attrs._name = json.name
      attrs._phoneNumber = json.phone_number
      attrs._type = json.type
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    presented = _.extend(@toJSON(), {

      address: @get('_address')?.toPresenterJSON()
      emailAddress: @get('_emailAddress') || ''
      phoneNumber: @get('_phoneNumber') || 'No phone number'

    })
    return presented

  multiLineAddress: () ->
    anything = @get('_streetAddress') ||
        @get('_extendedAddress') ||
        @get('_locality') ||
        @get('_region') ||
        @get('_postalCode') ||
        @get('_countryCode')
    if (anything)
      html = []
      html.push(@get('_streetAddress') || 'No Street')
      html.push('<br/>')
      if (@get('_extendedAddress'))
        html.push(@get('_extendedAddress') || '')
        html.push('<br/>')
      html.push(@get('_locality') || 'No City')
      html.push(', ')
      html.push(@get('_region') || 'No State')
      html.push(' ')
      html.push(@get('_postalCode') || 'No Zip')
      html.push(' ')
      html.push(@get('_countryCode') || '')
      return html.join('')
    else
      return 'No Address'
  #///////////////////////////////////////////////////////////////////////////
