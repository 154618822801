App.Collections.Base.BaseCollection = require('./base/base_collection.coffee')
App.Models.Email = require('../models/email_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.EmailAddresses = App.Collections.Base.BaseCollection.extend

  model: App.Models.Email

  url: -> "/clients/#{@holder_id}/email_addresses"

  initialize: (models, options = {}) ->
    {
      @holder_id
    } = options
    parent.initialize.call(@, models, options)

  toList: () ->
    emails = []
    @models.forEach((emailModel) ->
      emails.push(emailModel.get('address'))
    )
    return emails.join(', ')

