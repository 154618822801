module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div id="content-header">\n  <h1>\n    <%\nif (searchTerm) {\n%>\n    Customers Matching Search "<%= decodeURIComponent(searchTerm) %>"\n    <%\n} else {\n%>\n    Customers\n    <%\n}\n%>\n  </h1>\n</div>\n<div class="flextainer" style="justify-content: space-between; padding: 5px 0; background: #e5e5e5; border-bottom: 1px solid #d6d6d6; border-top: 1px solid #d6d6d6;">\n  <div style="padding-left: 20px;">\n    <input style="margin-bottom: 0px;" placeholder="Customers Search" id="client_search_text" type="text" value="">\n    <input class="btn btn-primary" id="client_search_button" type="Submit" value="Search">\n    <input class="btn" id="reset_search_button" type="Submit" value="Clear Results">\n  </div>\n  <div>\n    <input class="btn btn-primary" id="load_all_button" type="Submit" value="Show All Customers" />\n  </div>\n  <div style="padding-right: 20px;">\n    <a id="add_client_button" class="btn btn-primary">Add New Customer</a>\n  </div>\n</div>\n\n<div id="formErrors"></div>\n\n<div class="container-fluid">\n  <div class="row-fluid">\n    <div class="span12">\n      <div class="widget-box">\n        <div class="widget-title">\n          <span class="icon"><i class="fa-solid fa-user"></i></span>\n          <h5>Customers</h5>\n          <div class="pull-right" style="width: auto; margin-top: -12px; margin-right: 3px; padding-left: 3px; padding-top: 15px;">\n            <div id="printButton" class="btn"><i class="fa-solid fa-print"></i> Print</div>\n            <div id="exportButton" class="btn"><i class="fa-solid fa-table-cells"></i> CSV</div>\n          </div>\n        </div>\n\n        <div id="client_table_container" class="widget-content nopadding">\n          <!-- payment_table rendered here -->\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n', __filename = "js/views/clients/clients_list.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div id="content-header">\n  <h1>\n    ');
            __line = 3;
            if (searchTerm) {
                __line = 5;
                __append('\n    Customers Matching Search "');
                __line = 6;
                __append(escapeFn(decodeURIComponent(searchTerm)));
                __append('"\n    ');
                __line = 7;
            } else {
                __line = 9;
                __append("\n    Customers\n    ");
                __line = 11;
            }
            __line = 13;
            __append('\n  </h1>\n</div>\n<div class="flextainer" style="justify-content: space-between; padding: 5px 0; background: #e5e5e5; border-bottom: 1px solid #d6d6d6; border-top: 1px solid #d6d6d6;">\n  <div style="padding-left: 20px;">\n    <input style="margin-bottom: 0px;" placeholder="Customers Search" id="client_search_text" type="text" value="">\n    <input class="btn btn-primary" id="client_search_button" type="Submit" value="Search">\n    <input class="btn" id="reset_search_button" type="Submit" value="Clear Results">\n  </div>\n  <div>\n    <input class="btn btn-primary" id="load_all_button" type="Submit" value="Show All Customers" />\n  </div>\n  <div style="padding-right: 20px;">\n    <a id="add_client_button" class="btn btn-primary">Add New Customer</a>\n  </div>\n</div>\n\n<div id="formErrors"></div>\n\n<div class="container-fluid">\n  <div class="row-fluid">\n    <div class="span12">\n      <div class="widget-box">\n        <div class="widget-title">\n          <span class="icon"><i class="fa-solid fa-user"></i></span>\n          <h5>Customers</h5>\n          <div class="pull-right" style="width: auto; margin-top: -12px; margin-right: 3px; padding-left: 3px; padding-top: 15px;">\n            <div id="printButton" class="btn"><i class="fa-solid fa-print"></i> Print</div>\n            <div id="exportButton" class="btn"><i class="fa-solid fa-table-cells"></i> CSV</div>\n          </div>\n        </div>\n\n        <div id="client_table_container" class="widget-content nopadding">\n          <!-- payment_table rendered here -->\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n');
            __line = 52;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}