template = require('./management.ejs')
eventsTableTemplate = require('./management_events.ejs')
App.Views.Base.FilteredTable = require('../base/filtered_table_view.coffee')

module.exports = App.Views.Events.Management = App.Views.Base.FilteredTable.extend

  template: template

  eventsTableTemplate: eventsTableTemplate

  dataTableOptions:
    bJQueryUI: true
    sDom: '<"">t'
    bScrollInfinite: true
    bScrollCollapse: false
    iDisplayLength: 1000
    aaSorting: [
      [ 0, 'desc']
    ],
    aoColumns: [
      { sType: 'date' }
      null
      null
      null
      null
      null
      null
      null
    ]
    oLanguage: {
      sSearch: 'Filter Results: '
      sEmptyTable: 'No Events Found'
    }

  initialize: (options) ->
    {
      @$container
      @orderItemCollection
    } = options
    @render()

  renderTable: () ->
    @itemsGroupedByEvent = @groupOrderItemsByEvent()
    eventCollection = new App.Collections.V3.Events(null, {
      mapping: C.Mappings.Direct.OrderItemsByEvent.Event
    })
    for own eventId, items of @itemsGroupedByEvent
      event = items[0].get('_event')

      orderIds = _.map items, (item) ->
        return item.get('_orderId')
      orderCount = _.uniq(orderIds).length

      event.set('_orderCount', orderCount)
      unfilled = 0
      sold = 0
      for item in items
        quantity = parseInt(item.get('_quantity'))
        sold += quantity
        #if (item.get('_isSpec') || item.get('_needsSub'))
        #  unfilled += quantity
        unfilled += parseInt(item.unfilledCount())
      event.set('_unfilledCount', unfilled)
      event.set('_soldCount', sold)
      #event.set('_availableCount', ???????)
      eventCollection.add(event)
    events = eventCollection.toPresenterJSON()
    data = {
      events
    }

    height = ~~(($(window).height() - 370) / 2)
    @dataTableOptions.sScrollY = "#{ height }px"

    $container = @$('#tablesContainer')
    $container.html(
      @eventsTableTemplate(data)
    )

    # Set top datatable to be height of top panel.
    #@dataTableOptions.sScrollY = (@$('#event_container').height() - 30) + 'px'
    @$dataTable = @$('table').dataTable(@dataTableOptions)
    @loadingBox = new App.Utils.LoadingBox(@$('#eventContainer'))

    @buttonLoader.stopLoad()
    @loadingBox.stopLoad()

  render: () ->

    @$container.html(
      @$el.html(
        @template()
      )
    )

    @buttonLoader = new App.Utils.LoadingButton(@$('#apply_filter_button'))
    @renderTable()


    $content = @$('#filter_content')
    $collapseButton = @$('#filter_collapse_button')
    isHorizontalSplit = true
    onChange = () =>
      #@$dataTable.fnDraw()
      @renderTable()
    options = {
      $content
      $collapseButton
      isHorizontalSplit
      onChange
    }
    @collapsibleFilters = new App.Utils.CollapsibleWidget(options)

    App.Utils.dateRangePicker(@$('#occurs_at'), @onDateRangeChanged, @, C.DatePickerConfig.Future)
    @$performerAutocomplete = App.Utils.performerAutocomplete(@$("#performer_name_input"), true)
    @$venueAutocomplete = App.Utils.venueAutocomplete(@$("#venue_id"), true)

    $country = @$('#country_select')
    $state = @$('#venue_region')
    @$localityAutocomplete = new App.Utils.LocalityAutocomplete({
      $country
      $state
      defaultCountry: 'US'
    })

    options = C.Options.Order.SpecFilled
    options = _.map(options, (val, key) ->
      return {
        id: key
        text: val
      }
    )
    @$('#unfilled').select2({
      data: options
      initSelection: (element, callback) ->
        data = {
          id: element.val()
          text: C.Options.Order.SpecFilled[element.val()]
        }
        callback(data)
    })
    @$('#unfilled').select2('val', 'all')

    options = C.Options.Order.EticketsMissing
    options = _.map(options, (val, key) ->
      return {
        id: key
        text: val
      }
    )
    @$('#needs_eticket').select2({
      data: options
      initSelection: (element, callback) ->
        data = {
          id: element.val()
          text: C.Options.Order.EticketsMissing[element.val()]
        }
        callback(data)
    })
    @$('#needs_eticket').select2('val', 'all')

    @delegateEvents()

  # VIEW EVENTS ////////////////////////////////////////////////////////////////
  events:
    'click .event_row': 'onEventRowClicked'
    'click #apply_filter_button': 'onApplyFilterButtonClick',
    'click #reset_filter_button': 'onResetFilterButtonClick'

  onEventRowClicked: (e) ->
    $row = $(e.currentTarget)
    @highlightRow($row)
    data = $row.data()
    eventId = data.eventId

    orderItemsForEvent = @itemsGroupedByEvent[eventId]
    orderItemsCollection = new App.Collections.V3.OrderItems(orderItemsForEvent, {
      mapping: C.Mappings.Direct.OrderItemsByEvent.OrderItems
    })

    $container = @$('#order_container')
    App.Controllers.eventManagementController.fillOrdersPane({
      $container
      orderItemsCollection
    })

  onResetFilterButtonClick: () ->
    App.Controllers.eventManagementController.reset()

  onApplyFilterButtonClick: () ->
    @buttonLoader.startLoad()
    @loadingBox.startLoad()
    App.Controllers.eventManagementController.filter()
  #/////////////////////////////////////////////////////////////////////////////

  highlightRow: ($row) ->
    @$('table tr').removeClass('highlighted')
    $row.addClass('highlighted')

  onDateRangeChanged: (startDate, endDate) ->
    @dateRangeFilter = {
      start: startDate
      end: endDate
    }

  filterParamMappings: () ->
    mappings = {
      event_name:
        type: C.FilterTypes.Text
        el: '#event_name'
      venue_id:
        type: C.FilterTypes.Select2
        el: '#venue_id'
        sendsId: true
      venue_locality:
        type: C.FilterTypes.Text
        el: '#venue_locality'
      venue_region:
        type: C.FilterTypes.Select2
        el: '#venue_region'
      occurs_at:
        type: C.FilterTypes.DateRange
        el: '#occurs_at'
        val: @dateRangeFilter
      unfilled:
        type: C.FilterTypes.Select2Boolean
        el: '#unfilled'
        trueVal: 'not_filled'
        falseVal: 'filled'
        elseFalse: false
      needs_eticket:
        type: C.FilterTypes.Select2Boolean
        el: '#needs_eticket'
        trueVal: 'missing'
        falseVal: 'not_missing'
        elseFalse: false
      active_only:
        type: C.FilterTypes.Default
        val: true
      #performer_id:
      #  type: C.FilterTypes.Select2
      #  el: '#performer_name_input'
      #  sendsId: true
    }
    return mappings

  groupOrderItemsByEvent: () ->
    groupedByEvent = @orderItemCollection.groupBy (orderItemModel) ->
      return orderItemModel.get('_event').get('_id')
    return groupedByEvent

  startLoad: () ->
    @loadingBox.startLoad()