template = require('./topnav_queued_orders.ejs')
App.Views.TopNavBaseView = require('./topnav_base_view.coffee')

parent = App.Views.TopNavBaseView.prototype
module.exports = App.Views.TopNavQueuedOrders = App.Views.TopNavBaseView.extend

  template: template

  id: 'queued_orders'

  className: 'dropdown'

  update: (data) ->
    @queuedOrderCollection = new App.Collections.V3.QueuedOrders(data.queued_orders)

  render: () ->
    indicatorCount = 0
    indicatorClass = 'label-important'

    if (@queuedOrderCollection)
      filteredOrders = @queuedOrderCollection.sort()
      completed = @queuedOrderCollection.filter((queuedOrder) ->
        return queuedOrder.get('state') == C.Completed
      )
      completed = completed.length
      indicatorCount = @queuedOrderCollection.length - completed
      if (@queuedOrderCollection.length && indicatorCount == 0)
        # Turn it how many succeeded.
        indicatorClass = 'label-info'
        indicatorCount = @queuedOrderCollection.length
      data = {
        indicatorCount
        indicatorClass
        queuedOrders: filteredOrders.toPresenterJSON()
      }
    else
      data = {
        indicatorCount
        indicatorClass
      }

    @$container.append(
      @$el.html(
        @template(data)
      )
    )
    @$('a').tooltip()
    return @$el
