App.Models.Base.BaseModel = require('../base/base_model.coffee')

CLIENT = 1
OFFICE = 2
parent = App.Models.Base.BaseModel.prototype
module.exports = App.Models.V2.Patron = App.Models.Base.BaseModel.extend({

  has_many: ['addresses', 'email_addresses', 'phone_numbers']

  childModel: null
  type: null

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)
    if (attributes && attributes.type == 'Office')
      @childModel = new App.Models.V2.Office(attributes, options)
      @type = OFFICE
    else
      @childModel = new App.Models.V2.Client(attributes, options)
      @type = CLIENT

  parse: (response, options) ->
    parent.parse.call(@, response, options)
    return response

  # WARNING! OVERRIDING GET
  get: (attribute) ->
    childAttr = @childModel?.get(attribute)
    if (childAttr?)
      return childAttr
    return parent.get.call(@, attribute)

  updateStandardAttributes: () ->
    json = @attributes
    attrs = {}
    # FIND WHICH MAPPING THESE APPLY TO
    attrs.addresses = new App.Collections.V2.Addresses(json?.addresses?.models || json?.addresses, {
      holder_id: @id
      mapping: C.Mappings.OrderEndpoint.PatronAddress
    })
    attrs.email_addresses = new App.Collections.EmailAddresses(json?.email_addresses?.models || json?.email_addresses, {
      holder_id: @id
      mapping: C.Mappings.OrderEndpoint.PatronEmail
    })
    attrs.phone_numbers = new App.Collections.PhoneNumbers(json?.phone_numbers?.models || json?.phone_numbers, {
      holder_id: @id
      mapping: C.Mappings.OrderEndpoint.PatronPhoneNumber
    })
    @set(attrs)

  toJSON: (options) ->
    json = parent.toJSON.call(@, options)
    json.addresses = json.addresses?.toJSON()
    json.email_addresses = json.email_addresses?.toJSON()
    json.phone_numbers = json.phone_numbers?.toJSON()
    return json

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    childJSON = @childModel.toPresenterJSON()
    if (@type == OFFICE)
      presented = _.extend(childJSON, {
        email:      childJSON.brokerage.email
        emailAddressOptions: childJSON.emailAddressOptions
        name:       childJSON.brokerage.abbreviation
        fullName:   childJSON.brokerage.name
        phoneNumber:  @phoneNumber(childJSON)
        phoneNumberOptions: childJSON.phoneNumberOptions
        prettyName: "#{childJSON.brokerage.name} - #{childJSON.location}"
        type:     C.Office
      })
    if (@type == CLIENT)
      presented = _.extend(childJSON, {
        name:         childJSON.name
        email:        @emailAddress(childJSON)
        emailAddressOptions: childJSON.emailAddressOptions
        fullName:     childJSON.name
        id:           childJSON.id
        prettyName:   childJSON.name
        phoneNumber:  @phoneNumber(childJSON)
        phoneNumberOptions: childJSON.phoneNumberOptions
        preparedFor:  childJSON.preparedFor || ''
        type:         C.Client
        address:      @address()?.toPresenterJSON()
      })
    return presented

  emailAddress: (childJSON) ->
    primary = childJSON.primary_email_address?.address
    if (primary)
      return primary
    else
      first = childJSON.email_addresses.length && childJSON.email_addresses[0].address
      return first || ''

  phoneNumber: (childJSON) ->
    # OFFICE
    if (childJSON.phone)
      return childJSON.phone
    # CLIENT
    primary = childJSON.phone_number?.pretty
    if (primary)
      return primary
    else
      first = childJSON.phone_numbers.length && childJSON.phone_numbers[0].pretty
      return first || ''

  address: -> @addresses().first()

  addresses: -> (@get('addresses') or new App.Collections.V3.Addresses())

  email_address: (index) -> @_get_nested_attribute(@get("email_addresses"), index, "address")

  email_addresses: ->
    emails = []
    @get('email_addresses').each (email) -> emails.push(id:email.get('id'), address: email.get('address'))
    emails

  name: -> @ship_to_name()

  phone_number: (index) -> @_get_nested_attribute(@get("phone_numbers"), index, "number")

  phone_numbers: ->
    #returns an array of just the number in phone numbers object
    @get('phone_numbers').pluck (number) -> number.get("number")

  ship_to_company_name: ->
    @get("company") and (@get("company").company and @get("company").company.name or @get("company").name)

  ship_to_name: -> @get("name")
  #///////////////////////////////////////////////////////////////////////////

}, {
  # STATICS
  defaultMaping: C.Mappings.OrderEndpoint.Patron

  isPatron: (patron) ->
    if (
      patron instanceof App.Models.V2.Patron ||
      patron instanceof App.Models.V3.Patron ||
      patron instanceof App.Models.Client ||
      patron instanceof App.Models.V2.Client ||
      patron instanceof App.Models.V3.Client ||
      patron instanceof App.Models.V2.Office ||
      patron instanceof App.Models.V3.Office
    )
      return true
    else
      return false

  modelize: (patron) ->
    return null unless patron
    return patron if App.Models.V2.Patron.isPatron patron
    if patron.type is "Client" then new App.Models.V2.Client patron else new App.Models.V2.Office patron
})
