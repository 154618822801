module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div class="modal reject-order-dialog">\n  <div class="modal-header">\n    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n    <h3>Reject Order</h3>\n  </div>\n\n  <div class="modal-body">\n    <div class="reject-errors alert alert-error" style="display:none"></div>\n    <div class="formErrors"></div>\n    <label>Seller Rejection Reason *</label>\n    <select class=\'rejection-reason\'>\n      <option></option>\n      <option>Tickets No Longer Available</option>\n      <option>Tickets Priced Incorrectly</option>\n      <option>Duplicate Order</option>\n      <option>Fraudulent Order</option>\n      <option>Test Order</option>\n      <option>Other</option>\n    </select>\n\n    <label>Seller Rejection Notes</label>\n    <textarea rows="5" class="rejection-notes"></textarea>\n  </div>\n  <div class="modal-footer">\n    <button class="btn btn-danger reject">Reject</button>\n  </div>\n</div>\n\n', __filename = "js/views/order/reject_order_modal.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div class="modal reject-order-dialog">\n  <div class="modal-header">\n    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n    <h3>Reject Order</h3>\n  </div>\n\n  <div class="modal-body">\n    <div class="reject-errors alert alert-error" style="display:none"></div>\n    <div class="formErrors"></div>\n    <label>Seller Rejection Reason *</label>\n    <select class=\'rejection-reason\'>\n      <option></option>\n      <option>Tickets No Longer Available</option>\n      <option>Tickets Priced Incorrectly</option>\n      <option>Duplicate Order</option>\n      <option>Fraudulent Order</option>\n      <option>Test Order</option>\n      <option>Other</option>\n    </select>\n\n    <label>Seller Rejection Notes</label>\n    <textarea rows="5" class="rejection-notes"></textarea>\n  </div>\n  <div class="modal-footer">\n    <button class="btn btn-danger reject">Reject</button>\n  </div>\n</div>\n\n');
            __line = 29;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}