template = require('./edit.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.TicketHolds.Edit = App.Views.Base.BaseView.extend

  template: template

  className: 'modal hide fade sell-dialog dialog'

  render: () ->
    @$el.html(
      @template()
    )
    # STANDARDIZED MODAL
    @$el.modal('show').on('hidden', () =>
      @remove()
    )

    @formErrors = new App.Utils.FormErrorManager(@$('.formErrors'))

    holdUntil = @model.get('hold_until')
    holdUntil = moment(holdUntil)
    datePickerConfig = C.DatePickerConfig.TimePicker
    datePickerConfig.startDate = holdUntil

    App.Utils.dateRangePicker(@$('#holdUntil'), @onDateTimeChanged, this, datePickerConfig)

    @$('.hold-until-minutes').on('change', () =>
      @$('#holdUntil').find('#dateTime').text('')
    )


  # VIEW EVENTS //////////////////////////////////////////////////////////////
  events:
    "click a.update" : "updateHold"

  updateHold: (e) ->
    loading = new App.Views.Shared.LoadingView()
    loading.render()
    @listenTo(App.Vent, 'ticket-hold:updated', _.bind(() =>
      loading.remove()
      @$el.modal('hide')
      @trigger("ticket-hold:updated")
    , @))
    holdUntil = @getHoldUntil()
    if(holdUntil)
      @model.updateHoldUntil(holdUntil)
    else
      loading.remove()
  #///////////////////////////////////////////////////////////////////////////

  getHoldUntil: () ->
    holdUntilMinutes = @$('.hold-until-minutes').val()
    format = 'YYYY-MM-DDTHH:mm:ss'
    if (holdUntilMinutes == 'Other')
      if (@dateTime)
        the_moment = @dateTime
        holdUntil = the_moment.utc().format(format).toString() + "Z"
      else
        @formErrors.show({
          title : 'No hold time specified'
          errors : 'You must specify a new hold duration'
        })
    else
      holdUntil = moment.utc().add('minutes', holdUntilMinutes).format(format).toString() + "Z"

    return holdUntil

  onDateTimeChanged: (startDate, endDate) ->
    @dateTime = startDate
    @$('.hold-until-minutes').val('Other')

    if (_.isString(startDate))
      @dateRangeFilter = {
        start: startDate
        end: endDate
      }
    else
      @dateRangeFilter = {
        start: startDate.toDate().toISOString()
        end: endDate.toDate().toISOString()
      }