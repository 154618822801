template = require('./overview.ejs')
notesTemplate = require('../buy_sell/notes.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Order.Overview = App.Views.Base.BaseView.extend

  template: template

  notesTemplate: notesTemplate

  initialize: (options = {}) ->
    {
      @$container
      @v3OrderModel
    } = options

    @fixTicketGroupSeats()

    App.Controllers.uploadsController.setOrderModel(@v3OrderModel)
    @render()
    @loadSoldItems()

  fixTicketGroupSeats: () ->
    #FIXTHEAPI
    # order.items[0].ticket_group.seats is the original seats array of the TG.
    # order.items[0].seats is the actual seat array for this item.
    # We have to manually correct this.
    orderItemCollection = @v3OrderModel.get('_orderItems')
    orderItemCollection.forEach((orderItemModel) =>
      ticketGroup = orderItemModel.get('_ticketGroup')
      ticketGroup.set('_seats', orderItemModel.get('_seats'))
    )

  getOrderPermissions: () ->
    firstOrderItem = @v3OrderModel.get('_orderItems').first()
    ticketGroupModel = firstOrderItem.get('_ticketGroup')

    shipment = @v3OrderModel.get('_shipments').first()
    buyerState = @v3OrderModel.get('_buyerState')
    child_orders = @v3OrderModel.get('child_orders')
    hasSeatNumbers = ticketGroupModel.hasSeatNumbers()
    isConsignment = @v3OrderModel.get('_isConsignment')
    isPO = @v3OrderModel.isPO()
    isTevoOrder = @v3OrderModel.isTevoOrder()
    orderType = @v3OrderModel.get('_orderType')
    sellerState = @v3OrderModel.get('_sellerState')
    shipmentHasAirbill = shipment.hasAirbill()
    shipmentState = shipment.get('_state')
    shipmentType = shipment.get('_type')
    userIsPOS = SESSION.office.get('_isPOS')

    options = {
      buyerState
      child_orders
      isConsignment
      isPO
      isTevoOrder
      hasSeatNumbers
      orderType
      sellerState
      shipmentHasAirbill
      shipmentState
      shipmentType
      userIsPOS
      shipment
      ticketGroupModel
    }

    return {
      canAddTransaction:                App.OrderPermissions.canAddTransaction(options)
      canDeliverBarcodes:               App.OrderPermissions.canDeliverBarcodes(options)
      canChooseSeats:                   App.OrderPermissions.canEnterSeatNumbers(options)
      canEditCosts:                     App.OrderPermissions.canEditCosts(options)
      canGenerateAirbill:               App.OrderPermissions.canGenerateAirbill(options)
      canOrderBeAccepted:               App.OrderPermissions.canOrderBeAccepted(options)
      canOrderBeCompleted:              App.OrderPermissions.canOrderBeCompleted(options)
      canOrderBeCancelled:              App.OrderPermissions.canOrderBeCancelled(options)
      canOrderBeConsignmentAccepted:    App.OrderPermissions.canOrderBeConsignmentAccepted(options)
      canOrderBeRejected:               App.OrderPermissions.canOrderBeRejected(options)
      canPrintAirbill:                  App.OrderPermissions.canPrintAirbill(options)
      canUploadAirbill:                 App.OrderPermissions.canUploadAirbill(options)
      canRequestAirbill:                App.OrderPermissions.canRequestAirbill(options)
      canSeeKount:                      App.OrderPermissions.canSeeKount(options)
      canShipmentBeCancelledAndCreated: App.OrderPermissions.canShipmentBeCancelledAndCreated(options)
      canShipmentBeEdited:              App.OrderPermissions.canShipmentBeEdited(options)
      canShipmentBeMarkedAsDelivered:   App.OrderPermissions.canShipmentBeMarkedAsDelivered(options)
      canShipmentBePended:              App.OrderPermissions.canShipmentBePended(options)
      canRequestProofOfTransfer:        App.OrderPermissions.canRequestProofOfTransfer(options)
      canViewPOs:                       App.OrderPermissions.canViewPOs(options)
      canViewSoldTickets:               App.OrderPermissions.canViewSoldTickets(options)
      label:                            App.OrderPermissions.label(options)
      sellerStateIsPending:             _.contains([C.Pending, C.PendingConsignment, C.PendingSubstitution], @v3OrderModel.get('_sellerState'))
      canRequestSellerInfo:             App.OrderPermissions.canRequestSellerInfo(options)
      canRequestBuyerInfo:              App.OrderPermissions.canRequestBuyerInfo(options)
      buyerShipmentDetailsProvided:     App.OrderPermissions.buyerShipmentDetailsProvided(options)
      canUpdateShipmentType:            App.OrderPermissions.canUpdateShipmentType(options)
      canSubmitTMMobileLink:            App.OrderPermissions.canSubmitTMMobileLink(options)
    }

  render: () ->

    # Determine what kind of messaging to display.
    hasIncompleteLocalPickupInfo = false
#    shipment = @v3OrderModel.get('_shipments').first()
#    if (shipment.get('_type') == C.LocalPickup)
#      isAddressValid = shipment.get('_shipmentSnapshot').get('_address').isValid(true)
#      if (!isAddressValid)
#        hasIncompleteLocalPickupInfo = true

    firstOrderItem = @v3OrderModel.get('_orderItems').first()
    firstShipment = @v3OrderModel.get('_shipments').first()

    hasSeatNumbers = false
    ticketGroupModel = firstOrderItem.get('_ticketGroup')
    if (ticketGroupModel.hasSeatNumbers())
      hasSeatNumbers = true

    renderFlags = @getOrderPermissions()

    orderPresenterJSON = @v3OrderModel.toPresenterJSON()
    orderItemPresenterJSON = firstOrderItem.toPresenterJSON()
    shipmentPresenterJSON = firstShipment.toPresenterJSON()

    data = {
      firstShipment: shipmentPresenterJSON
      order: orderPresenterJSON
      showReturnToEventsButton: !!App.router.generateBackToEventsUrl()

      # Order permissions.
      canAddTransaction: renderFlags.canAddTransaction
      canChooseSeats: renderFlags.canChooseSeats
      canGenerateAirbill: renderFlags.canGenerateAirbill
      canOrderBeAccepted: renderFlags.canOrderBeAccepted
      canOrderBeCompleted: renderFlags.canOrderBeCompleted
      canOrderBeRejected: renderFlags.canOrderBeRejected
      canOrderBeCancelled: renderFlags.canOrderBeCancelled
      canOrderBeConsignmentAccepted: renderFlags.canOrderBeConsignmentAccepted
      canPrintAirbill: renderFlags.canPrintAirbill
      canUploadAirbill: renderFlags.canUploadAirbill
      canRequestAirbill: renderFlags.canRequestAirbill
      canSeeKount: renderFlags.canSeeKount
      canViewSoldTickets: renderFlags.canViewSoldTickets
      label: renderFlags.label

      # Order state.
      eticketActionButton: App.OrderPermissions.eticketActionButton({ # Requires PresenterJSON.
        isPO: orderPresenterJSON.isPO
        orderId: orderPresenterJSON.id
        orderItem: orderItemPresenterJSON
        orderType: orderPresenterJSON.orderType
        sellerState: orderPresenterJSON.sellerState
        shipmentState: shipmentPresenterJSON.state
        shipmentType: shipmentPresenterJSON.type
      })
      hasIncompleteLocalPickupInfo
      hasSeatNumbers
      sellerStateIsPending: renderFlags.sellerStateIsPending
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )

    @renderFraud()
    @renderShipment()
    @renderTicketGroupsTab()
    @renderTotals()
    @renderTransactions()
    @renderPOsPromise()

    @acceptLoadingButton = new App.Utils.LoadingButton(@$('#acceptOrderButton'))
    @completeLoadingButton = new App.Utils.LoadingButton(@$('#completeOrderButton'))
    @rejectLoadingButton = new App.Utils.LoadingButton(@$('#rejectOrderButton'))
    @loadingBox = new App.Utils.LoadingBox(@$container)
    @noteLoadingBox = new App.Utils.LoadingBox(@$('#addNoteContainer'))
    @emailInvoiceLoadingButton = new App.Utils.LoadingButton(@$('#emailInvoiceButton'))
    @formErrors = new App.Utils.FormErrorManager(@$('#formErrors'))
    @$('span.tip-top').tooltip({ placement: 'top' })

    @seatSelectionViews = {}

    # Notes popovers.
    @v3OrderModel.get('_orderItems').forEach((orderItemModel) =>
      ticketGroupModel = orderItemModel.get('_ticketGroup')
      $tr = @$("tr[data-ticket-group-id=#{ ticketGroupModel.id }]")

      # Notes popover.
      $el = $tr.find('.popover_notes')
      App.Utils.notesPopover($el, ticketGroupModel)

      # Seats inputs.
      if (!ticketGroupModel.hasSeatNumbers())
        $container = $tr.find('td.ticketGroupSeatSelection')
        @seatSelectionViews[ticketGroupModel.id] = new App.Views.BuySell.SeatSelection({
          $container
          canChooseSeats: App.OrderPermissions.canEnterSeatNumbers({
            hasSeatNumbers: ticketGroupModel.hasSeatNumbers()
            isPO: @v3OrderModel.isPO()
            orderType: @v3OrderModel.get('_orderType')
            sellerState: @v3OrderModel.get('_sellerState')
            userIsPOS: SESSION.office.get('_isPOS')
          })
          ticketGroupModel
          shipmentType: shipmentPresenterJSON.type
        })
    )

    $content = @$('#notesContent')
    $collapseButton = @$('#notesCollapseButton')
    options = {
      $content
      $collapseButton
      isCollapsed: true
    }
    @collapsibleNotes = new App.Utils.CollapsibleWidget(options)
    if !!@v3OrderModel.get('_notes').length
      $('#notesCollapseButton').click()

    @delegateEvents()

  refresh: () ->
    deferred = Q.defer()
    @loadingBox.startLoad()
    @v3OrderModel.fetchWithOrderItemsPromise()
    .then (model) =>
      @render()
      @loadSoldItems()
      deferred.resolve(true)
    .fail (errors) =>
      @formErrors.show({
        title: 'Could not refresh order info.'
        errors
      })
      @loadingBox.stopLoad()
      deferred.reject(errors)
    .done()
    return deferred.promise

  renderTicketGroupsTab: () ->
    $container = @$('#ticketGroupsContainer')
    view = new App.Views.Order.TicketGroupsTab({
      $container
      overviewView: @
      @v3OrderModel
    })

  renderFraud: () ->
    $content = @$('#fraudContent')
    $collapseButton = @$('#fraudCollapseButton')
    options = {
      $content
      $collapseButton
    }
    @collapsibleFraud = new App.Utils.CollapsibleWidget(options)

    $container = @$('#fraudContainer')
    fraudView = new App.Views.Order.Fraud({
      $container
      @v3OrderModel
    })

  renderTransactions: () ->
    $container = @$('#transactionsContainer')
    @transactionsView = new App.Views.Order.Transactions({
      $container
      overviewView: @
      @v3OrderModel
    })

  renderTotals: () ->
    $container = @$('#totalsContainer')
    totalsView = new App.Views.Order.Totals({
      $container
      @v3OrderModel
    })

  refreshShipment: () ->
    @loadingBoxShipments.startLoad()
    @v3OrderModel.fetchWithOrderItemsPromise()
    .then (model) =>
      @renderShipment()
    .fail (errors) =>
      @formErrors.show({
        title: 'Could not refresh shipment.'
        errors
      })
      @loadingBoxShipments.stopLoad()
    .done()

  renderShipment: () ->
    $shipmentContainer = @$('#shipmentContainer')
    @loadingBoxShipments = new App.Utils.LoadingBox($shipmentContainer)
    @loadingBoxShipments.startLoad()
    shipmentTypes = new App.Collections.V3.ShipmentTypes(null, {
      isOfflineAllowed: true
      isTBDAllowed: true
      mapping: C.Mappings.Direct.ShipmentTypes.ShipmentTypes
    })

    orderPermissions = @getOrderPermissions()

    shipmentTypes.fetchPromise()
    .then (collection) =>
      @loadingBoxShipments.stopLoad()
      @shipmentView = new App.Views.Shipping.Shipment({
        $container: $shipmentContainer
        destinationPatron: @v3OrderModel.get('_buyer')
        isOrderOverview: true
        originPatron: @v3OrderModel.get('_seller')
        overviewView: @
        shipmentTypes
        @v3OrderModel
      })
    .fail (errors) =>
      @formErrors.show({
        title: 'Unable to render shipment.'
        errors
      })
      @loadingBoxShipments.stopLoad()
    .done()

  events:
    'click #addTransactionButton':            'onAddTransactionButtonClick'
    'click #emailInvoiceButton':              'onEmailInvoiceButtonClick'
    'click .acceptOrderButton':               'onAcceptOrderButtonClick'
    'click .completeOrderButton':             'onCompleteOrderButtonClick'
    'click #cancelOrderButton':               'onCancelOrderButtonClick'
    'click .consignmentAcceptOrderButton':    'onConsignmentAcceptOrderButtonClick'
    'click .rejectOrderButton':               'onRejectOrderButtonClick'
    'click .eticketButton':                   'onEticketButtonClick'
    'click #saveNoteButton':                  'onSaveNoteButtonClick'
    'click #generateAirbillButtonOverview':   'onGenerateAirbillButtonClick'
    'click #uploadAirbillButtonOverview':     'onUploadAirbillButtonClick'
    'click #requestAirbillButtonOverview':    'onRequestAirbillButtonClick'
    'click #returnToEventsButton':            'onReturnToEventsButtonClick'
    'click #viewSoldTicketsButton':           'onViewSoldTicketsButtonClick'
    'click #viewTicketGroupsButton':          'onViewTicketGroupsButtonClick'
    'click #overviewPrintAirbillButton':      'onPrintAirbillButtonClick'

  onPrintAirbillButtonClick: (e) ->
    id = $(e.target).data('shipmentId')

    shipment = new App.Models.V3.Shipment({id: id}, {
      mapping: C.Mappings.Direct.Shipments.Shipment
    })

    shipment.downloadAirbill(@formErrors)

  onAddTransactionButtonClick: (e) ->
    @transactionsView.renderAddFormStep1()

  onGenerateAirbillButtonClick: (e) ->
    # This is just a shortcut to the generateAirbillButton in @shipmentView.
    @shipmentView?.onGenerateAirbillButtonClick(e)

  onUploadAirbillButtonClick: (e) ->
    # This is just a shortcut to the uploadAirbillButton in @shipmentView.
    @shipmentView?.onUploadAirbillButtonClick(e)

  onRequestAirbillButtonClick: (e) ->
    # This is just a shortcut to the requestAirbillButton in @shipmentView.
    @shipmentView?.onRequestAirbillButtonClick(e)

  onEmailInvoiceButtonClick: (e) ->
    @emailInvoiceLoadingButton.startLoad()
    @$('#emailInput').attr('disabled', true)
    emailAddresses = @$('#emailInput').val()

    title = 'Invoice Emailed Successfully'
    message = "The order invoice was sent to #{ emailAddresses }."
    failTitle = 'Email Invoice Failed'

    if (
      @v3OrderModel.get('_orderType') == C.OrderTypes.Purchase ||
      (@v3OrderModel.get('_orderType') == C.OrderTypes.Direct && @v3OrderModel.isPO())
    )
      title = 'PO Emailed Successfully'
      message = "The PO was sent to #{ emailAddresses }."
      failTitle = 'Email PO Failed'

    App.Controllers.orderShowController.emailInvoicePromise(@v3OrderModel, emailAddresses)
    .then (model) =>
      @formErrors.show({
        type: 'success'
        title
        message
      })
      @$('#emailInput').removeAttr('disabled')
      @emailInvoiceLoadingButton.stopLoad()
    .fail (errors) =>
      @formErrors.show({
        failTitle
        errors
      })
      @$('#emailInput').removeAttr('disabled')
      @emailInvoiceLoadingButton.stopLoad()
    .done()

  onRejectOrderButtonClick: (e) ->
    view = new App.Views.Order.RejectOrderModal({
      @v3OrderModel
      overviewView: @
    })
    view.render()

  onAcceptOrderButtonClick: (e) ->

    missingSeats = false
    hasSeats = false
    canChooseSeats = @getOrderPermissions().canChooseSeats
    shipmentType = @v3OrderModel.get('_shipments').first().get('_type')

    if (canChooseSeats || shipmentType == C.Barcode)
      @v3OrderModel.get('_orderItems').forEach((orderItemModel) =>
        ticketGroupModel = orderItemModel.get('_ticketGroup')

        # Replace ticketGroups from the seatSelectionViews in case some other control refreshed the v3OrderModel
        #  (and thus reset its orderItems and their ticketGroups).
        seatSelectionView = @seatSelectionViews[ticketGroupModel.id]
        if (seatSelectionView)
          ticketGroupModel = seatSelectionView.getTicketGroupModel()
          orderItemModel.set('_ticketGroup', ticketGroupModel)

        if (!ticketGroupModel.hasSeatNumbers())
          # Was the seatSelectionView filled out?
          if (
            ticketGroupModel.get('_seatOrder') &&
            !isNaN(ticketGroupModel.get('_lowSeat')) &&
            ticketGroupModel.get('_seats')?.length && !isNaN(ticketGroupModel.get('_seats')[0])
          )
            $.noop()
          else
            missingSeats = true
        else
          filtered_seats = ticketGroupModel.get('_seats').filter((seat) => !isNaN(seat));
          if (filtered_seats.length > 0)
            hasSeats = true
      )

    if (shipmentType == C.Barcode && !hasSeats)
      @formErrors.show({
        title: 'Cannot accept an order with missing seats.'
        message: 'Please provide seat numbers to accept order.'
      })
      $(window).scrollTop(0)
    else
      @loadingBox.startLoad()
      @acceptLoadingButton.startLoad()

      @shipmentView.getShipmentPromise()
      .then (shipmentModel) =>

        # Updates shipment when accepting LocalPickup order
        if (@v3OrderModel.isTevoOrder() && shipmentModel.get('_type') == C.LocalPickup)
          shipmentModel.editForOrderPromise({
            order: @v3OrderModel
          })

        @v3OrderModel.acceptPromise()
        .then (model) =>
          @render()

          @formErrors.show({
            type: 'success'
            title: 'Order accepted.'
            message: 'The order was accepted successfully.'
          })
        .fail (errors) =>
          @formErrors.show({
            title: 'Error accepting order.'
            errors
          })
          $(window).scrollTop(0)
          @acceptLoadingButton.stopLoad()
          @loadingBox.stopLoad()

        .done()

      .fail (errors) =>
        @formErrors.show({
          title:   'Cannot accept an order with incomplete shipment info.'
          message: 'Please correct the shipment errors below.'
        })
        $(window).scrollTop(0)
        @acceptLoadingButton.stopLoad()
        @loadingBox.stopLoad()
      .done()

  onCompleteOrderButtonClick: (e) ->
    shipmentType = @v3OrderModel.get('_shipments').first().get('_type')

    if ( shipmentType == C.FlashSeats ||  shipmentType == C.TMMobile  )
      if ( !@v3OrderModel.get('_shipments').first().get('_transferSource') )
        @formErrors.show({
          title:   'Cannot complete an order without a transfer source.'
          message: 'Please correct the shipment errors below.'
        })
      else
        @confirmModal = new App.Views.Shared.BasicModal({
            isConfirmation: true
            header: 'Complete Order Warning'
            message: "By confirming the transfer of this ticket group, you are acknowledging you have sent the specified tickets to the buyer's email address on this order. This will also complete the order in our system."
            onYes: @completeOrder.bind(@)
            icon: 'fa-solid fa-triangle-exclamation'
          })
    else
      @completeOrder()


  onConsignmentAcceptOrderButtonClick: (e) ->
    modal = new App.Views.Order.ConsignmentAcceptModal({
      overviewView: @
      @v3OrderModel
    })

  onEticketButtonClick: (e) ->
    $button = $(e.currentTarget)
    data = $button.data()
    orderItemId = data.itemId
    $uploadRow = @$("tr.uploadRow[data-item-id=#{ orderItemId }]")
    $substituteRow = @$("tr.substitute_row[data-item-id=#{ orderItemId }]")
    App.Controllers.uploadsController.invokeClickAction({
      orderItemId
      $uploadRow
      $substituteRow
    })

  onReturnToEventsButtonClick: (e) ->
    url = App.router.generateBackToEventsUrl()
    App.router.navigate(url, { trigger: true })

  onViewSoldTicketsButtonClick: (e) ->

    if (@$(e.currentTarget).hasClass('disabledTab'))
      return

    canEditCosts = @getOrderPermissions().canEditCosts

    @$('#viewTicketGroupsButton').removeClass('activeTab')
    @$('#viewSoldTicketsButton').addClass('activeTab')
    $container = @$('#ticketGroupsContainer')
    view = new App.Views.Order.SoldTicketsTab({
      canEditCosts
      $container
      overviewView: @
      @v3OrderModel
    })

  onViewTicketGroupsButtonClick: (e) ->
    @$('#viewSoldTicketsButton').removeClass('activeTab')
    @$('#viewTicketGroupsButton').addClass('activeTab')
    @renderTicketGroupsTab()

  onSaveNoteButtonClick: (e) ->
    @noteLoadingBox.startLoad()
    content = @$('#newNoteContent').val().trim()
    category = 'external'
    if (@$('#newNotePrivate').hasClass('active'))
      category = 'internal'
    @v3OrderModel.saveNotePromise({
      category
      content
    })
    .then (model) =>
      @render()
      @formErrors.show({
        type: 'success'
        title: 'Note saved successfully.'
        message: 'Your note was added to this order.'
      })
      @noteLoadingBox.stopLoad()
      $('#notesCollapseButton').click()
    .fail (errors) =>
      $(window).scrollTop(0)
      @formErrors.show({
        title: 'Could not save note.'
        errors
      })
      @noteLoadingBox.stopLoad()
    .done()

  onCancelOrderButtonClick: (e) ->
    view = new App.Views.Order.CancelOrderModal({
      @v3OrderModel
      overviewView: @
    })
    view.render()

  getDeliveryInfoDetails: () ->
    barcodeGroup = document.querySelectorAll('.barcode-seat-group')
    delivery = document.querySelector('#selectDeliveryValue').value.trim()
    accountEmail = document.querySelector('#account-email').value.trim()
    tickets = []

    barcodeGroup.forEach((group) =>
      seat = group.querySelector('.seat-input').value.trim()
      ticketValue = group.querySelector('.ticket-value-input').value.trim()

      if (seat && ticketValue)
        ticket = {
          seat: seat,
        }

        if (accountEmail)
          ticket.seller_email = accountEmail

        if (delivery)
          ticket[delivery] = ticketValue

        tickets.push(ticket)
    )
    return tickets

  completeOrder: () ->
    @confirmModal?.remove()
    @loadingBox.startLoad()
    @completeLoadingButton.startLoad()
    shipment = @v3OrderModel.get('_shipments').first()
    canDeliverBarcodes = @getOrderPermissions().canDeliverBarcodes

    requestPayload = {transfer_source: shipment.get('_transferSource')}
    if (canDeliverBarcodes)
      requestPayload.tickets = @getDeliveryInfoDetails()

      if (requestPayload.tickets.length == 0)
        formErrors = new App.Utils.FormErrorManager(@$('#shipmentDetailsFormErrors'))
        formErrors.show({
          message: "Seat and ticket values are required"
        })
        @completeLoadingButton.stopLoad()
        @loadingBox.stopLoad()
        return

      formErrorsType = 'success'
      formErrorsTitle = 'Completing Order'
      formErrorsMessage = 'We are attempting to deliver your tickets and complete the order. Check back in a few minutes to confirm.'

    shipment.completePromise(requestPayload)
      .then (model) =>
        @refresh().then () =>
          @formErrors.show({
            type: if formErrorsType then formErrorsType else 'success'
            title: if formErrorsTitle then formErrorsTitle else 'Order completed.'
            message: if formErrorsMessage then formErrorsMessage else 'The order was completed successfully.'
          })
      .fail (errors) =>
        @formErrors.show({
          title: 'Error completing order.'
          errors
        })
        $(window).scrollTop(0)
        @completeLoadingButton.stopLoad()
        @loadingBox.stopLoad()
      .done()

  loadSoldItems: () ->
    if (@v3OrderModel.isPO())
      @v3OrderModel.fetchSoldItemsPromise()
      .then (soldItemsCollection) =>

        @v3OrderModel.set('_soldOrderItems', soldItemsCollection)
        @$('#viewSoldTicketsButton')
        .removeClass('disabledTab')
        .removeClass('enabledTab')
        .removeClass('activeTab')

        text = 'No Sales'
        if (soldItemsCollection.length)
          text = "<i class='fa-solid fa-money-bill'></i> #{ soldItemsCollection.length } Sale#{ if (soldItemsCollection.length > 1) then 's' else '' }"
          @$('#viewSoldTicketsButton')
          .addClass('enabledTab')
          .html("<h5>#{ text }</h5>")
        else
          @$('#viewSoldTicketsButton')
          .addClass('disabledTab')
          .html("<h5>#{ text }</h5>")
          @hidePurchaseOrdersBox()

      .fail (errors) =>
        $(window).scrollTop(0)
        @formErrors.show({
          title: 'Could not fetch sold items.'
          errors
        })
      .done()

  getSoldAsInOrder: () ->
    # Reset from prior render:
    @v3OrderModel.get('_orderItems').forEach((orderItemModel) ->
      soldAs = orderItemModel.get('_ticketGroup').get('_sold_as')
      if (soldAs)
        soldAs.orderItems = null
    )

    soldAsById = {}
    @soldAsInOrder = []
    orderItemCollection = @v3OrderModel.get('_orderItems')
    orderItemCollection.forEach((orderItemModel) =>

      soldAs = orderItemModel.get('_ticketGroup').get('_sold_as')

      if (soldAs)
        soldAsById[soldAs.id] = soldAsById[soldAs.id] || soldAs
        soldAsById[soldAs.id].orderItems = soldAsById[soldAs.id].orderItems || []
        soldAsById[soldAs.id].orderItems.push(orderItemModel)
    )

    @soldAsInOrder = _.values(soldAsById)

    soldAsSortFunction = (a, b) ->
      return a.get('_occursAt') - b.get('_occursAt')

    @soldAsInOrder = @soldAsInOrder.sort(soldAsSortFunction)
    return @soldAsInOrder
  getEventsInOrder: () ->
    # Reset from prior render:
    @v3OrderModel.get('_orderItems').forEach((orderItemModel) ->
      event = orderItemModel.get('_ticketGroup').get('_event')
      event.orderItems = null
    )

    eventsById = {}
    @eventsInOrder = []
    orderItemCollection = @v3OrderModel.get('_orderItems')
    orderItemCollection.forEach((orderItemModel) =>

      event = orderItemModel.get('_ticketGroup').get('_event')

      eventsById[event.id] = eventsById[event.id] || event
      eventsById[event.id].orderItems = eventsById[event.id].orderItems || []

      soldInOrderItems = orderItemModel.get('_soldInOrderItems')
      if (soldInOrderItems)
        eventsById[event.id].hasSoldItems = true

      @associateAnySubstitutionsWithOrderItem(orderItemModel)
      eventsById[event.id].orderItems.push(orderItemModel)
    )

    @eventsInOrder = _.values(eventsById)

    eventSortFunction = (a, b) ->
      return a.get('_occursAt') - b.get('_occursAt')

    @eventsInOrder = @eventsInOrder.sort(eventSortFunction)
    return @eventsInOrder

  associateAnySubstitutionsWithOrderItem: (orderItemModel) ->
    orderItemId = orderItemModel.get('_orderItemId')
    subsCollection = @v3OrderModel.get('_substitutions')
    subsCollection.forEach((substitutionModel) ->
      subOrderItemId = substitutionModel.get('_orderItemId')
      if (subOrderItemId == orderItemId)
        orderItemModel.originalSubbedTicketGroup = substitutionModel.get('_original').get('_ticketGroup').toPresenterJSON()
    )

  hidePurchaseOrdersBox: () ->
    @$('#purchaseOrdersBox').remove()

#  showPOIdsInTGTab: () ->
#    @$('.tgTabPurchaseOrderId').show()

  renderPOsPromise: (options = {}) ->
    {
      refetchChildIds
    } = options

    if (!@getOrderPermissions().canViewPOs)
      @hidePurchaseOrdersBox()
      return Q(true)

    deferred = Q.defer()
    $container = @$('#purchaseOrdersContainer')
    formErrors = new App.Utils.FormErrorManager($container.find('.formErrors'))
    loadingBox = new App.Utils.LoadingBox($container)
    loadingBox.startLoad()

    step1Promise = Q(true)
    if (refetchChildIds)
      step1Promise = @v3OrderModel.fetchPromise()

    step1Promise
    .then (model) =>

      promises = []
      purchaseOrderCollection = new App.Collections.V3.Orders(@v3OrderModel.get('_childOrderIds').map((id) ->
        return { id }
      ), {
        mapping: C.Mappings.Direct.Order.Order
      })
      purchaseOrderCollection.forEach((poModel) ->
        promises.push(poModel.fetchPromise())
      )
      Q.all(promises)
      .then (models) =>
        if (!purchaseOrderCollection.length)
          @hidePurchaseOrdersBox()
          #@showPOIdsInTGTab()
        else
          topSellerState = @v3OrderModel.get('_sellerState')
          purchaseOrdersView = new App.Views.Order.PurchaseOrders({
            $container
            purchaseOrderCollection
            overviewView: @
            topSellerState
          })
        deferred.resolve(true)
      .fail (errors) =>
        formErrors.show({
          title: 'Could not fetch POs.'
          errors
        })
        loadingBox.stopLoad()
        deferred.reject(errors)

    .fail (errors) =>
      formErrors.show({
        title: 'Could not refetch child order IDs.'
        errors
      })
      loadingBox.stopLoad()
      deferred.reject(errors)
    .done()
    return deferred.promise

  showFeedback: (options) ->
    @formErrors.show(options)
