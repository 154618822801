module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<%\nif (isError) {\n%>\n<div class="alert alert-danger" style="margin-top: 10px;">\n  <button type="button" class="close" data-dismiss="alert">×</button>\n  <h5><%= errorMessage %></h5>\n</div>\n<%\n} else {\n%>\n<div class="alert alert-success" style="margin-top: 10px;">\n  <button type="button" class="close" data-dismiss="alert">×</button>\n  <h5>Successfully extracted tickets from:</h5>\n  <h4><%= file.name %></h4>\n  <br/>\n  <%= file.size %><br/>\n  <%= file.type %><br/>\n</div>\n<%\n}\n%>', __filename = "js/views/order/file_upload_outcome.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            if (isError) {
                __line = 3;
                __append('\n<div class="alert alert-danger" style="margin-top: 10px;">\n  <button type="button" class="close" data-dismiss="alert">×</button>\n  <h5>');
                __line = 6;
                __append(escapeFn(errorMessage));
                __append("</h5>\n</div>\n");
                __line = 8;
            } else {
                __line = 10;
                __append('\n<div class="alert alert-success" style="margin-top: 10px;">\n  <button type="button" class="close" data-dismiss="alert">×</button>\n  <h5>Successfully extracted tickets from:</h5>\n  <h4>');
                __line = 14;
                __append(escapeFn(file.name));
                __append("</h4>\n  <br/>\n  ");
                __line = 16;
                __append(escapeFn(file.size));
                __append("<br/>\n  ");
                __line = 17;
                __append(escapeFn(file.type));
                __append("<br/>\n</div>\n");
                __line = 19;
            }
            __line = 21;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}