template = require('./reject_order_modal.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Order.RejectOrderModal = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    {
      # Old OrderShow usage.
      @order
      @orderShowView

      # New OrderOverview usage.
      @v3OrderModel
      @overviewView
    } = options

  render: () ->
    @$el.html(@template())
    @$el.modal('show')
    @loadingBox = new App.Utils.LoadingBox(@$el.find('.modal-body'))
    @formErrors = new App.Utils.FormErrorManager(@$('.formErrors'))

  events:
    'click .reject' : 'rejectOrder'

  rejectOrder: () ->

    # New OrderOverview version.
    if (@v3OrderModel)
      @formErrors.reset()
      rejectionReason = @$el.find('.rejection-reason').val()
      if (!rejectionReason.length)
        @formErrors.show({
          message: 'A rejection reason is required.'
        })
      else
        options = {
          reason: @$el.find('.rejection-reason').val()
          rejectionNotes: @$el.find('.rejection-notes').val()
        }
        @loadingBox.startLoad()
        @v3OrderModel.rejectPromise(options)
        .then (model) =>
          @loadingBox.stopLoad()
          @$el.modal('hide')
          @overviewView.render()
          message = ''
          if (SESSION.is_pos)
            message = 'The tickets from this order are now available in your system but have been UNBROADCASTED.'
          @overviewView.formErrors.show({
            type: 'success'
            title: 'Order rejected successfully.'
            message
          })
        .fail (errors) =>
          @loadingBox.stopLoad()
          @formErrors.show({
            title: 'Error rejecting order.'
            errors
          })
        .done()

    # Old OrderShow version.
    else
      loading = new App.Views.Shared.LoadingView()
      rejectionReason = @$el.find('.rejection-reason').val()
      if (!rejectionReason.length)
        @$el.find('.reject-errors').html('A rejection reason is required').show()
      else
        @$el.find('.reject-errors').empty().hide()
        @order.set('rejection_reason', @$el.find('.rejection-reason').val())
        @order.set('rejection_notes', @$el.find('.rejection-notes').val())
        loading.render()
        @order.rejectOrder().done((data) =>
          loading.remove()
          @$el.modal('hide')
          @orderShowView.fullRefresh()
          .then (v3OrderModel) =>
            @orderShowView.showFeedback({
              type: 'success'
              title: 'The order has been sucessfully rejected.'
              message: 'The tickets from this order are now available in your system but have been UNBROADCASTED.'
            })
          .done()
        ).fail((statusText) =>
          loading.remove()
        )