template = require('./shipment_table.ejs')

module.exports = App.Views.Shipping.ShipmentTable = Backbone.View.extend

  template: template

  dataTableOptions: {
    bJQueryUI: true
    sDom: '<"">t<"F"fp>'
    bScrollInfinite: true
    bScrollCollapse: false
    iDisplayLength: 1000
    sScrollY: '700px'
    aaSorting: [
      [ 3, 'desc']  # Date
    ]
    aoColumns: [
      null
      null
      null
      { sType: 'date' }
      null
      null
      null
    ]
  }

  initialize: (options = {}) ->
    {
    @collection
    @$container
    @filterView
    } = options
    @render()

  render: () ->

    data = {
      shipments: @collection.toPresenterJSON()
    }
    @$el.html(@template(data))
    @$container.html(@$el)
    @formErrors = new App.Utils.FormErrorManager($('#formErrors'))
    @delegateEvents()

    @endlessDataTable = new App.Utils.EndlessDataTable({
      @collection
      @filterView
      @dataTableOptions
    })
    @initializeContextMenu()
    return @$el

# VIEW EVENTS //////////////////////////////////////////////////////////////
  events:
# CONTEXT MENU ITEMS
    'click .dropdown-menu .airbill' : 'onAirbillClick'
    'click .dropdown-menu .generate': 'onGenerateClick'
    'click .dropdown-menu .cancel'  : 'onCancelClick'


  onAirbillClick: (e) ->
    data = @getRowData(e)
    id = data.id
    id = encodeURIComponent(id)

    shipment = new App.Models.V3.Shipment({id: id}, {
      mapping: C.Mappings.Direct.Shipments.Shipment
    })

    shipment.downloadAirbill(@formErrors)

  onGenerateClick: (e) ->
    data = @getRowData(e)
    id = data.id
    id = encodeURIComponent(id)

    url = "/api_direct/v9/shipments/#{id}/airbill"

    App.ErrorManager.ignoreNext(422)

    $.ajax({
      type: 'POST'
      url
      success: (data, status, xhr) =>
        @collection.fetchPromise()
        .then () =>
          @render()
          @formErrors.show({
            type: 'success'
            message: 'Airbill has been generated'
          })
        .fail () =>
          @formErrors.show({
            type: 'failed'
            message: "Could not fetch shipment collection..."
          })
        .done()

        App.ErrorManager.resetTolerance(422)
      error: (data) =>
        if (data.responseJSON)
          @formErrors.show({
            type: 'failed'
            message: "Airbill could not be generated. Error: #{data.responseJSON.error}"
          })
        else
          @formErrors.show({
            type: 'failed'
            message: "Airbill could not be generated."
          })
        App.ErrorManager.resetTolerance(422)
    })


  onCancelClick: (e) ->
    data = @getRowData(e)
    id = data.id
    id = encodeURIComponent(id)

    url = "/api_direct/v9/shipments/#{id}/cancel"

    $.ajax({
      type: 'PUT'
      url
      success: (data, status, xhr) =>

        @collection.fetchPromise()
        .then () =>
          @render()
          @formErrors.show({
            type: 'success'
            message: 'Shipment has been canceled'
          })
        .fail () =>
          @formErrors.show({
            type: 'failed'
            message: "Could not fetch shipment collection..."
          })
        .done()

      error: (error) =>
        @formErrors.show({
          type: 'failed'
          message: "Shipment could not be canceled."
        })
    })

  getRowData: (e) ->
    $tr = @$(e.target).closest('tr.shipment')
    trData = $tr.data()
    state = trData.state
    trackingNumber = trData.trackingNumber
    id = trData.id
    data = {
      state
      trackingNumber
      id
    }
    return data


  initializeContextMenu: () ->
    @$el.on 'click', 'button.dropdown-toggle', (e) =>
      $tr = @$(e.target).closest('tr.shipment')
      trData = $tr.data()
      state = trData.state
      trackingNumber = trData.trackingNumber
      hasAirbill = trData.hasAirbill

      if (!(state == C.Options.Shipment.States['canceled'] || state == C.Options.Shipment.States['returned']))
        html = ['<ul class="dropdown-menu" style="left:0">']

        if (state == C.Options.Shipment.States['pending'])
          if(trackingNumber && hasAirbill)
            html.push('<li><a class="airbill">Get Airbill</a></li>')
            html.push('<li><a class="cancel">Cancel</a></li>')
          else
            html.push('<li><a class="generate">Generate Airbill</a></li>')
            html.push('<li><a class="cancel">Cancel</a></li>')

        if (state == C.Options.Shipment.States['in_transit'] ||
            state == C.Options.Shipment.States['delivered'] ||
            state == C.Options.Shipment.States['in_exception'])
          html.push('<li><a class="airbill">Get Airbill</a></li>')

        html.push('</ul>')
        $tr.find('.btn-group').append(html.join(''))

  getDataTable: () ->
    return @endlessDataTable