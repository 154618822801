poller = {}
poller.INTERVAL = 60000 # 60 seconds

 # http://stackoverflow.com/questions/1060008/is-there-a-way-to-detect-if-a-browser-window-is-not-currently-active
init = () ->
  hidden = "hidden"
  if (hidden in document)
    document.addEventListener("visibilitychange", onSiteVisibilityChange)
  else if ((hidden = "mozHidden") in document)
    document.addEventListener("mozvisibilitychange", onSiteVisibilityChange)
  else if ((hidden = "webkitHidden") in document)
    document.addEventListener("webkitvisibilitychange", onSiteVisibilityChange)
  else if ((hidden = "msHidden") in document)
    document.addEventListener("msvisibilitychange", onSiteVisibilityChange)
  else if ('onfocusin' in document)
    document.onfocusin = document.onfocusout = onSiteVisibilityChange
  else
    window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onSiteVisibilityChange

onSiteVisibilityChange = (evt) ->
  v = 'visible'
  h = 'hidden'
  evtMap = {
    focus: v
    focusin: v
    pageshow: v
    blur: h
    focusout: h
    pagehide: h
  }
  evt = evt || window.event
  if (evtMap[evt.type] == v)
    onSiteVisible()
  else if (evtMap[evt.type] == h)
    onSiteHidden()

onSiteVisible = () ->
  startPolling()

onSiteHidden = () ->
  stopPolling()

startPolling = () ->
  # Don't poll in DEV, it's too annoying.
  if (
    window.location.href.indexOf('pos.lvh.me') == -1 &&
    window.location.href.indexOf('localhost') == -1
  )
    if (typeof(SESSION) != 'undefined' && SESSION.office_id)
      if (!poller.intervalFunc)
        poller.intervalFunc = setInterval(poll, poller.INTERVAL)

stopPolling = () ->
  if (poller.intervalFunc)
    clearInterval(poller.intervalFunc)
    poller.intervalFunc = null

callback = (data) ->
  options = {
    action: 'updateQueuedOrders'
    data
  }
  App.router.updateTopNav(options)

poll = () ->
  $.get('/api_direct/v9/queued_orders/recent', callback, 'json')

# Go.
init()
startPolling()
