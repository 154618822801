module.exports = App.Collections.Base.BaseCollection = Backbone.Collection.extend

  initialize: (models, options) ->
    options ||= {}
    @mapping = options.mapping

  super: (f, options) -> @constructor.__super__[f].apply(@, options)

  getMapping: () ->
    return @mapping

  toPresenterJSON: (options) ->
    return _.map(@models, (model) ->
      return model.toPresenterJSON(options)
    )

  isValid: (options) ->
    isValid = true
    @errors = {}
    @forEach((model) =>
      if (!model.isValid(options))
        isValid = false
        @errors[model.cid] = model.errors
    )
    #console.log('base collection', @errors)
    return isValid

  savePromise: (attributes, options) ->
    promises = []
    @forEach((model) ->
      if (_.size(model.changed) || model.isNew())
        promises.push(model.savePromise(attributes, options))
    )
    return Q.all(promises)

  fetchPromise: (options = {}) ->
    deferred = Q.defer()
    origSuccessCallback = options.success
    origErrorCallback = options.error
    newOptions = _.extend(options, {
      success: (collection, response, options) ->
        origSuccessCallback?(collection, response, options)
        deferred.resolve(collection)
      error: (collection, xhr, options) ->
        origErrorCallback?(collection, xhr, options)
        deferred.reject(xhr)
    })
    deferred.promise.xhr = Backbone.Collection.prototype.fetch.call(@, newOptions)
    return deferred.promise

  createPromise: (model, options = {}) ->
    deferred = Q.defer()
    collection = @

    options = _.clone(options)
    origSuccessCallback = options.success
    origErrorCallback = options.error

    newOptions = _.extend(options, {
      success: (model, response, options) ->
        if (options.wait)
          collection.add(model, options)
        origSuccessCallback?(model, response, options)
        deferred.resolve(model)
      error: (model, xhr, options) ->
        origErrorCallback?(model, xhr, options)
        deferred.reject(xhr)
    })

    if (!(model = @_prepareModel(model, options)))
      deferred.reject(new Error('Could not prepare model.'))
    if (!options.wait)
      @add(model, options)

    model.savePromise(model.attributes, newOptions)
    return deferred.promise