App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.Commission = App.Models.Base.V3BaseModel.extend

  url: () ->
    return "/api_direct/v9/commission_rule"

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {
      _amount: C.DefaultCommission
    }

    if (@getMapping() == C.Mappings.Direct.Commissions.Commission)
      attrs._amount = json.commission_percentage
      attrs._createdAt = Date.parse(json.created_at)
      attrs._id = json.id
      attrs._startDate = Date.parse(json.start_date)
      attrs._updatedAt = Date.parse(json.updated_at)
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)