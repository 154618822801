App.Views.Base.BaseView = require('../base/base_view.coffee')
template = require('./evopay_modal_abstract.ejs')
balanceTemplate = require('./balances.ejs')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.EvoPay.ModalAbstract = App.Views.Base.BaseView.extend

  className: 'evopay_modal modal hide fade'

  template: template

  balance_template: balanceTemplate

  render_instructions: () ->
    $.noop()

  afterRender: () ->
    $.noop()

  childSerialize: (formData) ->
    return formData

  super_initialize: (options = {}) ->
    {
      @model
    } = options

  render: () ->
    @$el.html(
      @template({
        title: @title || "New Transaction"
        button: @button || "Submit"
      })
    )
    @$("#form_body").html(
      @form_template({
        instructions: @instructions
      })
    )

    balance = @model.get('balance') || 0
    pending_sent = @model.get('pending_sent') || 0
    restricted = @model.get('restricted') || 0
    spendable = balance - pending_sent - restricted

    @$("#balance").html(
      @balance_template({
        balance:       App.Utils.valueToCurrency(balance)
        pending_sent:  App.Utils.valueToCurrency(pending_sent)
        restricted:    App.Utils.valueToCurrency(restricted)
        spendable:     App.Utils.valueToCurrency(spendable)
      })
    )

    # STANDARDIZED MODAL
    @$el.modal('show').on 'hidden', () =>
      @remove()

    @afterRender()

  events:
    'click #evopayTransactionButton': 'onEvopayTransactionButtonClick'

  onEvopayTransactionButtonClick: (e) ->
    @handleE(e)
    if (@validate_form_and_mark_errors())
      @$("#transact").button("loading")
      formData = @serializeForm()
      formData.type = @type
      if (!formData.transactions)
        transactions = [formData]
        formData = { transactions }

      @model.createTransaction(formData, { async:false })
      @$el.modal('hide')

  validate_form_and_mark_errors: () ->
    has_errors = false
    @$('.error').each () ->
      $(@).removeClass('error')
    required_inputs = @$('form').find('.required')
    required_inputs.each (index, input) ->
      $input = $(input)
      type   = $input.attr("type") || $input.get(0).tagName
      switch type
        when "text"
          bad_input = !$input.val()
        when "number"
          bad_input = !($input.val())
      if (bad_input)
        $input.addClass('error')
        has_errors = true

    return !has_errors

  serializeForm: () ->
    formData = {}
    @$('form [name]').each (i, input) ->
      $input = $(input)
      formData[$input.attr('name')] = $input.val()
    @childSerialize(formData)
    return formData

  handleE: (e) ->
    if (e)
      e.preventDefault()
      e.stopImmediatePropagation()
    if ($(e.target).attr("disabled"))
      return false
    else
      return true
