template = require('./row.ejs');
App.Views.Base.BaseView = require('../base/base_view.coffee');

module.exports = App.Views.Events.Row = Backbone.View.extend({

  template: template,

  className: 'eventListRow clearfix',

  events: {
    "click" : "toggleSelection"
  },

  initialize: function(options) {
    var that = this;
    if(!this.model.has("selected")){
      this.model.set("selected", false);
    }
    _.bindAll(this, "select", "deselect");
    this.model.on("change:selected", function(){
      if(that.model.get("selected")){
        that.select();
      }else{
        that.deselect();
      }
    });
  },

  render: function() {
    var that = this;
    this.$el.html(
      this.template({ event: this.model.toPresenterJSON() })
    );
    if(this.model.get("selected")){
      this.$(".eventContainer").addClass("selectedEvent");
    }
    return this.el;
  },

  toggleSelection: function(e){
    e.preventDefault();
    this.model.set("selected", !this.model.get("selected") );
  },

  select: function(){
    this.$(".eventContainer").addClass('selectedEvent');
  },

  deselect: function(){
    this.$(".eventContainer").removeClass('selectedEvent');
  }

});
