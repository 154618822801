template = require('./credit_memo_table.ejs');

module.exports = App.Views.CreditMemo.CreditMemoTable = Backbone.View.extend({

  template: template,

  dataTableOptions: {
    bJQueryUI: true,
    sDom: '<"">t<"F"fp>',
    bScrollInfinite: true,
    bScrollCollapse: false,
    iDisplayLength: 1000,
    sScrollY: '700px',
    aaSorting: [
      [ 0, 'desc']
    ],
    aoColumns: [
        { sType: 'num' },
         null,
         null,
         null,
         null,
         null,
         null
     ]
  },

  initialize: function (options) {
    var options = options || {};
    this.collection = options.collection;
    this.$container = options.$container;
    this.filterView = options.filterView;
    this.render();
  },

  render: function () {
    var that = this;
    var data = {
      credit_memos: that.collection.toPresenterJSON()
    };
    that.$el.html(that.template(data));
    that.$container.html(that.$el);
    that.delegateEvents();

    that.endlessDataTable = new App.Utils.EndlessDataTable({
      collection: that.collection,
      filterView: that.filterView,
      dataTableOptions: that.dataTableOptions
    });
    return that.$el;
  }

});