module.exports = App.Presenter = function (presentee) {

  this.presentee = presentee;

  this.get = function (attribute) {
    if (this.presentee) {
      return this.presentee.get(attribute) || '';
    }
    return '';
  };

};
