template = require('./topnav_shopping_cart.ejs')
App.Views.TopNavBaseView = require('./topnav_base_view.coffee')

parent = App.Views.TopNavBaseView.prototype
module.exports = App.Views.TopNavShoppingCart = App.Views.TopNavBaseView.extend

  template: template

  update: (numInCart) ->
    @numInCart = numInCart

  render: () ->
    @numInCart ||= 0
    data = {
      @numInCart
    }
    @$container.append(
      @$el.html(
        @template(data)
      )
    )
    @$('a').tooltip()
    return @$el