App.Models.Base.V3BaseModel     = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.EvoQuote = App.Models.Base.V3BaseModel.extend

  urlRoot:  '/api_direct/v9/quotes'

  initialize: (attributes = {}, options = {}) ->
    parent.initialize.call(@, attributes, options)

  validation: () ->
    if (@purpose == C.Purposes.CreateEvoQuote)
      return {
        _email:
          required: true
          msg: 'A client email is required'
        _name:
          required: true
          msg: 'A client name is required'
        _items: () ->
          ticketGroups = @get('_items')
          for tg in ticketGroups
            if(! (parseFloat(tg.price) >= 0))
              return 'Price must be a number greater or equal to 0.'
              break
            if(! (parseFloat(tg.quantity) > 0 && parseFloat(tg.quantity) % 1 == 0))
              return 'Quantity must be a positive integer.'
              break
      }

  labels: # Humanization.
    _clientID: 'EvoQuote  recipient identification'
    _email: 'EvoQuote recipient email'
    _name: 'EvoQuote recipient name'

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.EvoQuotes.EvoQuote)
      attrs._createdAt = Date.parse(json.created_at)
      attrs._event = new App.Models.V3.Event(json.event, {
        mapping: C.Mappings.Direct.EvoQuotes.Event
      })
      attrs._id = json.id
      attrs._items = new App.Collections.V3.TicketGroups(json.items, {
        mapping: C.Mappings.Direct.EvoQuotes.TicketGroup
      })
      attrs._notes = json.notes
      attrs._recipient = new App.Models.V3.Patron(json.recipient, {
        mapping: C.Mappings.Direct.EvoQuotes.Recepient
      })
      attrs._state = json.state
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._user = new App.Models.V3.Patron(json.user, {
        mapping: C.Mappings.Direct.EvoQuotes.User
      })
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  toJSON: () ->
    if (@purpose == C.Purposes.CreateEvoQuote)
      json = {
        quotes : [
          event_id : @get('_eventId')
          client_id : @get('_clientId')
          email : @get('_email')
          name : @get('_name')
          prepared_for : @get('_preparedFor')
          cc_user: @get('_ccUser')
          notes : @get('_notes')
          seat_views : @get('_seatViews')
          items :  @get('_items')
        ]
      }
      return json
    else
      json = parent.toJSON.call(@)
      return json

  parse: (response) ->
    if (@purpose == C.Purposes.CreateEvoQuote && response.quotes && response.quotes.length)
      return response.quotes[0]
    return response

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      createdAt:              @get('_createdAt')
      createdOrCompletedDate: @createdOrCompletedDate()
      event:                  @get('_event')?.toPresenterJSON()
      id:                     @get('_id')
      items:                  @get('_items')?.toPresenterJSON()
      name:                   @get('_name')
      notes:                  @get('_notes')
      state:                  C.Options.EvoQuote.States[@get('_state')]
      recipient:              @get('_recipient')?.toPresenterJSON()
      user:                   @get('_user')?.toPresenterJSON()

    })
    return presented

  createdOrCompletedDate: () ->
    if (@get('_state') == C.Completed)
      return @get('_updatedAt')
    else
      return @get('_createdAt')
  #/////////////////////////////////////////////////////////////////////////////

  resendQuote: () ->
    deferred = Q.defer()
    $.ajax({
      type: 'GET'
      url: "/api_direct/v9/quotes/#{ @get('id') }/resend"
      success: (data, textStatus, jqXHR) =>
        deferred.resolve(data)
      error: (jqXHR, textStatus, errorThrown) =>
        deferred.reject(jqXHR)
    })
    return deferred.promise

  update: (state) ->
    deferred = Q.defer()
    $.ajax({
      type: 'PUT'
      url: "/api_direct/v9/quotes/#{ @get('id') }"
      data: JSON.stringify({ state })
      success: (data, textStatus, jqXHR) =>
        deferred.resolve(data)
      error: (jqXHR, textStatus, errorThrown) =>
        deferred.reject(jqXHR)
    })
    return deferred.promise

  toDataTableRowArray: () ->
    item = @toPresenterJSON()
    row = [
      item.id
      App.Utils.makeTimestampHuman(item.createdOrCompletedDate, C.DateFormats.TableDateWithTime)
      item.recipient.name
      item.recipient.preparedFor
      item.user.name
      item.event.fullDescription
      item.state
    ]
    return row
