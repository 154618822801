template = require('./mass_index.ejs')
notesModalTemplate = require('./notes_modal.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

TRANSLATE = {
  broadcast: 'Is Broadcast'
  broker_notes: 'Broker Notes'
  eticket: 'Is Eticket'
  excluded_exchanges: 'Excluded Exchanges'
  external_notes: 'Public Notes'
  format: 'Format'
  in_hand: 'Is In Hand'
  in_hand_on: 'In Hand On'
  in_hand_days: 'In Hand Days Prior To Event'
  internal_notes: 'Private Notes'
  office: 'Office'
  price: 'Wholesale'
  price_flat: 'Wholesale Flat'
  price_percentage: 'Wholesale Percentage'
  retail_price_override: 'Retail Override'
  row: 'Row'
  section: 'Section'
  split_override: 'Split Overrides'
  type: 'Type'
  view_type: 'View Type'
  wheelchair: 'Is Wheelchair'
}

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Inventory.MassIndex = App.Views.Base.BaseView.extend

  template: template

  notesModalTemplate: notesModalTemplate

  initialize: (options = {}) ->
    {
      @collection
      @$container
      @isReceiveTicketsView
    } = options
    @listenTo(@collection, 'reset', @onCollectionReset)
    @render()

  render: () ->
    data = {
      @isReceiveTicketsView
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )
    @renderEditView()
    @delegateEvents()
    App.Utils.dateRangePicker(@$('#f_date_range'), @onDateRangeChanged, @, C.DatePickerConfig.Future, true)

    $content = @$('#filter_content')
    $collapseButton = @$('#filter_collapse_button')
    isHorizontalSplit = true
    onChange = () =>
      $.noop()
    options = {
      $content
      $collapseButton
      isHorizontalSplit
      onChange
    }
    @collapsibleFilters = new App.Utils.CollapsibleWidget(options)

    $content = @$('#edit_content')
    $collapseButton = @$('#edit_collapse_button')
    options = {
      $content
      $collapseButton
    }
    @collapsibleEditFilters = new App.Utils.CollapsibleWidget(options)

    @buttonLoader = new App.Utils.LoadingButton(@$('#apply_filter_button'))
    @editButtonLoader = new App.Utils.LoadingButton(@$('#edit_button'))

    @initializeAutocomplete()
    @initializeDropdowns()
    @renderTable(true)
    @startLoad()

    # https://github.com/twitter/bootstrap/issues/713
    @$('.alert .close').on('click', () ->
      $(@).parent().hide()
    )

    $retailContainer = @$('#retailContainer')
    retailInput = new App.Utils.RetailInput({
      $container: $retailContainer
      inputName: 'e_retail_text'
      inputId: 'e_retail_text'
    })

    return @$el

  renderTable: (isInitialRender) ->
    if (@massIndexTable)
      @massIndexTable.remove()

    @massIndexTable = new App.Views.Inventory.MassIndexTable({
      filterView: @
      $container: @$('#table_container')
      @collection
    })

    @tableLoader = new App.Utils.LoadingBox(@massIndexTable.$el)

    if (!isInitialRender)
      @stopLoad()

  renderNotesModal: (ticketGroupModel) ->
    data = ticketGroupModel.toJSON()
    $('#notes_modal').remove()
    @$el.append(
      @notesModalTemplate(data)
    )

    # PSEUDO STANDARDIZED MODAL
    $modal = $('#notes_modal').modal('show').on('hidden', () ->
      $modal.remove()
    )

  # VIEW EVENTS //////////////////////////////////////////////////////////////
  events:
    'click #apply_filter_button': 'onApplyFilterButtonClick'
    'click #reset_filter_button': 'onResetFilterButtonClick'
    'click #edit_button': 'onEditButtonClick'
    'click .notes_link': 'onNotesLinkClick'
    'click #selectAllButton': 'onSelectAllButtonClick'
    'click #deSelectAllButton': 'onDeSelectAllButtonClick'
    'click #markInHandButton': 'onMarkInHandButtonClick'

  onResetFilterButtonClick: () ->
    App.Controllers.inventoryController.resetMassIndex()

  onApplyFilterButtonClick: () ->
    @buttonLoader.startLoad()
    @tableLoader.startLoad()
    App.Controllers.inventoryController.filterMassIndex()

  onEditButtonClick: () ->
    $checkedEls = @massIndexTable.$('.mass_edit_checkbox:checked')
    selectedIds = _.map($checkedEls, (el) ->
      return $(el).data('ticket-group-id')
    )
    # Stupid duplicate TGs.
    selectedIds = _.uniq(selectedIds)

    if (@isReceiveTicketsView)
      attrs = {
        in_hand: true
      }
    else
      attrs = @getEditParams()

    if (!selectedIds.length)
      @$('#none_selected_error').show()
      return null
    else
      @$('#none_selected_error').hide()

    if (!_.size(attrs))
      @$('#no_edits_error').show()
      return null
    else
      @$('#no_edits_error').hide()

    if (attrs['in_hand'] == false && !(attrs['in_hand_on'] || attrs['in_hand_days']))
      @$('#in_hand_error').show()
      return null
    else
      @$('#in_hand_error').hide()

    submitEdits = () =>
      confirmModal.remove()
      @editButtonLoader.startLoad()
      @tableLoader.startLoad()
      onEditSuccess = (data) =>
        @editButtonLoader.stopLoad()
        App.Controllers.inventoryController.confirmEditsMassIndex()
      postData = {
        ids: selectedIds
        attrs
      }
      $.ajax({
        type: 'POST'
        url: 'api_direct/v9/ticket_groups/mass_update'
        data: JSON.stringify(postData)
        success: onEditSuccess
        dataType: 'json'
      })

    console.log(attrs)
    summaryString = []
    for own attrName, attrVal of attrs
      summaryString.push("#{ TRANSLATE[attrName] } = #{ attrVal }<br/>")
    summaryString = summaryString.join('')
    #edits = JSON.stringify(attrs).replace(/:/g, ' = ')
    #edits = edits.replace(/,/g, '<br/>')
    #edits = edits.replace(/[{}"]/g, '')
    message = "You are about to modify <span style='font-weight: 700; font-size: 16px;'>#{ selectedIds.length }</span> ticket groups as follows:
      <br/><br/>
      <div style='color: #888;'>
      #{ summaryString }
      </div>"
    confirmModal = new App.Views.Shared.BasicModal({
      isConfirmation: true
      header: 'Warning: Applying Updates'
      message
      onYes: submitEdits
      icon: 'fa-solid fa-triangle-exclamation'
    })

  onNotesLinkClick: (e) ->
    id = $(e.currentTarget).data('ticket-group-id')
    model = @collection.get(id)
    @renderNotesModal(model)

  onDateRangeChanged: (startDate, endDate) ->
    @dateRangeFilter = {
      start: startDate
      end: endDate
    }

  onHandDateChanged: () ->
    @$('#e_in_hand_days').val(null)

  onSelectAllButtonClick: (e) ->
    @massIndexTable?.$('.mass_edit_checkbox').attr('checked', true)

  onDeSelectAllButtonClick: (e) ->
    @massIndexTable?.$('.mass_edit_checkbox').removeAttr('checked')

  onMarkInHandButtonClick: (e) ->
    @onEditButtonClick()
  #///////////////////////////////////////////////////////////////////////////

  # COLLECTION EVENTS ////////////////////////////////////////////////////////
  onCollectionReset: () ->
    @renderTable()

  renderTable: (isInitialRender) ->
    if (@massIndexTable)
      @massIndexTable.remove()

    @massIndexTable = new App.Views.Inventory.MassIndexTable({
      filterView: @
      $container: @$('#table_container')
      @collection
    })

    @tableLoader = new App.Utils.LoadingBox(@massIndexTable.$el)
    if (!isInitialRender)
      @stopLoad()
  #///////////////////////////////////////////////////////////////////////////

  renderEditView: () ->
    if (!@isReceiveTicketsView)
      if (@editView)
        @editView.remove()

      @editView = new App.Views.Inventory.MassIndexEditView({
        $container: @$('#edit_view_container')
      })
      $content = @$('#edit_content')
      $collapseButton = @$('#edit_collapse_button')
      options = {
        $content
        $collapseButton
      }
      @collapsibleEditFilters = new App.Utils.CollapsibleWidget(options)
      @editButtonLoader = new App.Utils.LoadingButton(@$('#edit_button'))
      @initializeEditDropdowns()

  renderEditSuccessMessage: () ->
    if (@messageView)
      @messageView.remove()

    options = {
      title: 'Edit Successful'
      type: C.MessageTypes.Success
      message: 'Ticket groups were updated successfully.'
      $container: @$('#message_container')
    }
    @messageView = new App.Views.Shared.Message(options)

  clearMessage: () ->
    if (@messageView)
      @messageView.remove()

  getEditParams: () ->
    attrs = {}

    p = @exchangeExcluder.val()
    if (p)
      attrs['excluded_exchanges'] = p

    p = @$('#e_type_select').val()
    if (p)
      attrs['type'] = p

    p = @$('#e_format_select').val()
    if (p)
      if (p == 'Eticket')
        attrs['eticket'] = true
        attrs['format'] = C.TicketFormats.Eticket
      else
        attrs['eticket'] = false
        if (p == 'Physical')
          attrs['format'] = C.TicketFormats.Physical
        if (p == 'FlashSeats')
          attrs['format'] = C.TicketFormats.FlashSeats
        if (p == 'Paperless')
          attrs['format'] = C.TicketFormats.Paperless
        if (p == 'GuestList')
          attrs['format'] = C.TicketFormats.GuestList
        if (p == 'TMMobile')
          attrs['format'] = C.TicketFormats.TMMobile

    p = @$('#e_office_select').val()
    if (p)
      attrs['office'] = p

    p = @$('#e_view_select').val()
    if (p)
      attrs['view_type'] = p

    p = @$('#e_section_text').val()
    if (p)
      attrs['section'] = p

    p = @$('#e_wheelchair_select').val()
    if (p)
      val = (p == 'Wheelchair') ? true : false
      attrs['wheelchair'] = val

    p = @$('#e_row_text').val()
    if (p)
      attrs['row'] = p

    p = @$('#e_public_notes').val()
    if (p)
      attrs['external_notes'] = p

    p = @$('#e_private_notes').val()
    if (p)
      attrs['internal_notes'] = p

    p = @$('#e_broker_notes').val()
    if (p)
      attrs['broker_notes'] = p

    p = @$('#e_override_text').val()
    if (p)
      attrs['split_override'] = p

    p = @$('#e_in_hand_select').val()
    if (p)
      val = (p == 'inHand') ? true : false
      attrs['in_hand'] = val

    p = @$('#e_in_hand_date').find('#date').text()
    if (p)
      attrs['in_hand_on'] = (new Date(p)).toISOString()

    p = @$('#e_in_hand_days').val()
    if (p)
      attrs['in_hand_days'] = p

    p = @$('#e_wholesale_text').val()
    if (p)
      o = @$('#e_wholesale_select').val()
      priceField = {
        'Set To': 'price',
        'Flat': 'price_flat',
        'Percentage': 'price_percentage'
      }
      field = priceField[o]
      attrs[field] = p

    p = @$('#e_retail_text').val()
    if (p)
      o = @$('#e_retail_select').val()
      priceField = {
        'Set To': 'retail_price_override',
        'Flat': 'retail_price_override_flat',
        'Percentage': 'retail_price_override_percentage'
      }
      field = priceField[o]
      attrs[field] = p

    p = @$('#e_reset_retail_override').is(':checked')
    if (p)
      attrs['reset_retail_override'] = true

    p = @$('#e_broadcast_select').val()
    if (p)
      val = !!(p == 'on')
      attrs['broadcast'] = val

    return attrs

  getFilterParams: () ->
    # Don't forget some fields must specify '.eq' if numbers.
    params = {}

    p = @dateRangeFilter
    if (p && p.start && p.end)
      # Remove the Z.  Absolute event times.
      params['event_occurs_at.gte'] = p.start.toJSON().replace(/[Zz]/g, '')
      params['event_occurs_at.lte'] = p.end.toJSON().replace(/[Zz]/g, '')

    p = @$('#f_venue_autocomplete').val()
    if (p)
      params['venue_name'] = p

    p = @$('#f_broadcast_select').val()
    if (p && p != 'all')
      val = !!(p == 'on')
      params['ticket_group_broadcast'] = val

    p = @$('#f_spec_select').val()
    if (p && p != 'all')
      val = !!(p == 'spec')
      params['ticket_group_speculative'] = val

    p = @$('#f_consignment_select').val()
    if (p && p != 'all')
      val = !!(p == 'consignment')
      params['ticket_group_consignment'] = val

    p = @$('#f_in_hand_select').val()
    if (p && p != 'all')
      val = !!(p == 'inHand')
      params['ticket_group_in_hand'] = val

    p = @$('#f_section_text').val()
    if (p)
      params['ticket_group_section'] = p

    p = @$('#f_quantity_text').val()
    if (p)
      params['ticket_group_tickets_count.eq'] = p

    p = @$('#f_row_text').val()
    if (p)
      params['ticket_group_row'] = p

    p = @$('#f_performer_autocomplete').val()
    if (p)
      params['performer_id'] = p

    p = @$('#f_retail_override_select').val()
    if (p && p != 'all')
      val = !!(p == 'yes')
      params['has_retail_override'] = val

    #p = @$('#f_performer_text').val()
    #if (p)
    #  params['performers_search'] = p

    p = @$('#f_event_text').val()
    if (p)
      params['events_search'] = p

    @priorFilterParams = params
    return params

  startLoad: () ->
    @tableLoader.startLoad()
    @buttonLoader.startLoad()

  stopLoad: () ->
    @tableLoader.stopLoad()
    @buttonLoader.stopLoad()

  initializeAutocomplete: () ->
    App.Utils.venueAutocomplete(@$('#f_venue_autocomplete'))
    App.Utils.performerAutocomplete(@$('#f_performer_autocomplete'), true)

  initializeDropdowns: () ->

    options = C.Options.TicketGroup.Spec
    options = _.map(options, (val, key) ->
      return { id: key, text: val }
    )
    @$('#f_spec_select').select2({
      placeholder: 'All'
      data: options
    })

    options = C.Options.Order.Consignment
    options = _.map(options, (val, key) ->
      return { id: key, text: val }
    )
    @$('#f_consignment_select').select2({
      placeholder: 'All'
      data: options
    })

    options = C.Options.TicketGroup.BroadcastOptionsAlt
    options = _.map(options, (val, key) ->
      return { id: key, text: val }
    )
    @$('#f_broadcast_select').select2({
      placeholder: 'All'
      data: options
    })

    options = C.Options.TicketGroup.InHandStates
    options = _.map(options, (val, key) ->
      return { id: key, text: val }
    )
    @$('#f_in_hand_select').select2({
      placeholder: 'All'
      data: options
    })

    options = C.Options.TicketGroup.RetailOverrideStates
    options = _.map(options, (val, key) ->
      return { id: key, text: val }
    )
    @$('#f_retail_override_select').select2({
      placeholder: 'All'
      data: options
    })

  initializeEditDropdowns: () ->
    @exchangeExcluder = new App.Utils.ExchangeDropdown({
      $el: @$('#excludedExchanges')
    })
    view = @

    options = C.Options.TicketGroup.InHandStatesNoAll
    options = _.map(options, (val, key) ->
      return { id: key, text: val }
    )
    @$('#e_in_hand_select').select2({
      placeholder: 'Select'
      data: options
    }).on('change', () ->
      if ($(@).val() == 'notInHand')
        view.$('#e_in_hand_date').show()
        view.$('#e_in_hand_days_select').show()
        view.$('#in_hand_label').show()
      else
        view.$('#e_in_hand_date').hide()
        view.$('#e_in_hand_days_select').hide()
        view.$('#in_hand_label').hide()
    )

    @$('#e_in_hand_days_select').on('change', (e) ->
        if (e.currentTarget.value != '')
          view.$('#e_in_hand_date').find('#date').text('')
    )

    App.Utils.dateRangePicker(@$('#e_in_hand_date'), @onHandDateChanged, this, C.DatePickerConfig.SingleDatePicker)

    options = C.Options.TicketGroup.Types
    options = _.map(options, (val, key) ->
      return { id: key, text: val }
    )
    @$('#e_type_select').select2({
      placeholder: 'Select'
      data: options
    })

    options = C.Options.TicketGroup.Formats
    options = _.map(options, (val, key) ->
      return { id: key, text: key }
    )
    @$('#e_format_select').select2({
      placeholder: 'Select'
      data: options
    })

    options = C.Options.TicketGroup.ViewTypesNoAll
    options = _.map(options, (val, key) ->
      return { id: key, text: key }
    )
    @$('#e_view_select').select2({
      placeholder: 'Select'
      data: options
    })

    options = C.Options.TicketGroup.WheelchairOptions
    options = _.map(options, (val, key) ->
      return { id: key, text: key }
    )
    @$('#e_wheelchair_select').select2({
      placeholder: 'Select'
      data: options
    })

    options = C.Options.TicketGroup.PriceModOptions
    options = _.map(options, (val, key) ->
      return { id: key, text: key }
    )
    @$('#e_wholesale_select, #e_retail_select').select2({
      data: options
      initSelection: (element, callback) ->
        data = {
          id: element.val()
          text: element.val()
        }
        callback(data)
    })
    @$('#e_wholesale_select, #e_retail_select').select2('val', 'Set To')
    @$('#e_wholesale_select').change((e) =>
      val = $(e.currentTarget).val()
      units = '$'
      if (val == 'Percentage')
        units = '%'
      @$('#e_wholesale_text').parent().find('span.add-on').html(units)
    )
    @$('#e_retail_select').change((e) =>
      val = $(e.currentTarget).val()
      units = '$'
      if (val == 'Percentage')
        units = '%'
      @$('#e_retail_text').parent().find('span.add-on').html(units)
    )

    $.ajax({
      dataType: "json"
      url: '/api_direct/v9/offices'
      data: { brokerage_id: SESSION.brokerage_id }
      success: (data) =>
        selectOptions = _.map(data, (el) ->
          return {
            id: el.id
            text: el.name
          }
        )
        @$("#e_office_select").select2({
          placeholder: "Office"
          data: selectOptions
        })
    })

    options = C.Options.TicketGroup.BroadcastOptions
    options = _.map(options, (val, key) ->
      return { id: key, text: val }
    )
    @$('#e_broadcast_select').select2({
      placeholder: 'Select'
      data: options
    })
