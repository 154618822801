template = require('./cart_view.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Orders.CartView = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    {
      @$container
      @cartModel
    } = options
    @render()

  render: () ->
    eventsById = {}
    cartItemsByEventId = {}
    cartItemsGroupedByEventInDateOrder = []

    if (@cartModel)
      @cartModel.restoreEventsFromCache()
      holdCollection = @cartModel.get('_holds')
      ticketGroupCollection = @cartModel.get('_ticketGroups')

      if (holdCollection && holdCollection.length)

        for hold in holdCollection.models
          holdId = hold.id
          ticketCollection = hold.get('_tickets')
          tgId = ticketCollection.first().get('_ticketGroupId')
          ticketGroup = ticketGroupCollection.get(tgId)

          event = ticketGroup.get('_event').toPresenterJSON()
          eventId = event.id
          quantity = ticketCollection.length
          section = ticketGroup.get('_section')
          row = ticketGroup.get('_row')
          seats = ticketCollection.pluck('_seat')
          priceRetail = ticketGroup.get('_priceRetail')
          priceWholesale = ticketGroup.get('_priceWholesale')
          price = hold.get('_price')
          subtotal = Math.round(price * quantity)

          cartItem = {
            holdId
            event
            eventId
            quantity
            section
            row
            seats
            priceRetail
            priceWholesale
            price
            subtotal
          }

          eventsById[eventId] = event

          if (!cartItemsByEventId[eventId])
            cartItemsByEventId[eventId] = []
          cartItemsByEventId[eventId].push(cartItem)

        cartItemsGroupedByEventInDateOrder = _.sortBy cartItemsByEventId, (cartItem, eventId) ->
          event = eventsById[eventId]
          return 0 - event.occursAt

    data = {
      cartItemsGroupedByEventInDateOrder
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )
    @delegateEvents()

  events:
    'click .removeButton' : 'onCartRemoveButtonClick'
    'click #checkoutButton' : 'onCheckoutButtonClick'
    'click #destroyButton': 'onCartDestroyButtonClick'
    'change .cartItemPrice': 'onCartItemPriceChange'

  onCartItemPriceChange: (e) ->
    target = e.currentTarget
    @updateTotals(target)

  onCartRemoveButtonClick: (e) ->
    $tr = $(e.currentTarget).closest('tr.cartItem')
    holdId = $tr.data().cartHoldId
    loadingBox = new App.Utils.LoadingBox($tr.closest('table'))
    loadingBox.startLoad()

    @cartModel.remove({
      holdId
    })
    .then (cartModel) =>
      loadingBox.stopLoad()
      @render()
    .fail (error) =>
      errorModal = new App.Views.Shared.BasicModal({
        message: error.responseText
      })
      loadingBox.stopLoad()
      @render()
    .done()

  onCheckoutButtonClick: (e) ->
    loadingBox = new App.Utils.LoadingBox(@$el)
    loadingBox.startLoad()

    $priceInputs = @$('input.cartItemPrice')
    $priceInputs.each (index, input) =>
      $input = $(input)
      holdId = $input.data().cartHoldId
      price = parseFloat($input.val())
      price = Math.max(0, price)
      holdModel = @cartModel.get('_holds').get(holdId)
      holdModel.set('_price', price.toFixed(2))

    App.Controllers.buySellController.sellCart({
      @cartModel
    })

  onCartDestroyButtonClick: (e) ->
    if (@cartModel)
      loadingBox = new App.Utils.LoadingBox(@$el)
      loadingBox.startLoad()
      @cartModel.destroyPromise({ wait: true })
        .then (cartModel) =>
          loadingBox.stopLoad()
          @cartModel = null
          options = {
            action: 'updateCart'
            numInCart: 0
          }
          App.router.updateTopNav(options)
          @render()
        .fail (error) =>
          loadingBox.stopLoad()
        .done()

  # TODO Need to redo this whole view to update totals from the model, not from the DOM
  updateTotals: (target) ->
    #step 1: update the tg total
    newSalePrice = target.value
    tgRow = @$(target.closest('tr'))
    tgQuantity = parseInt(tgRow.find('#tgQuantity').html())
    tgTotal = newSalePrice * tgQuantity
    tgRow.find('#tgSubtotal').html(App.Utils.valueToCurrency(tgTotal))
    #step 2: update the event total
    tbody = target.closest('tbody')
    allSubtotals = tbody.querySelectorAll('#tgSubtotal')
    eventSubtotal = 0
    allSubtotals.forEach((subtotal) =>
      eventSubtotal += App.Utils.currencyToValue(subtotal.innerHTML)
    )
    @$(tbody).find('#eventTotal').html(App.Utils.valueToCurrency(eventSubtotal))
    #step 3: update the mega total
    megaTotal = 0
    document.querySelectorAll('#eventTotal').forEach((subtotal) =>
      megaTotal += App.Utils.currencyToValue(subtotal.innerHTML)
    )
    @$('#megaTotal').html(App.Utils.valueToCurrency(megaTotal))