module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div class="widget-box" style="margin-top: 0px;">\n  <div class="widget-title">\n    <span class="icon"><i class="fa-solid fa-calendar-days"></i></span>\n    <h5>Events</h5>\n  </div>\n  <div class="widget-content nopadding" id="eventContainer" style="min-height: 150px;">\n    <table class="table table-bordered table-striped table-hover data-table hover_point">\n      <thead>\n        <tr>\n          <th>Occurs At</th>\n          <th>Event</th>\n          <th>Venue</th>\n          <th>City</th>\n          <th>State</th>\n          <th>Orders</th>\n          <th>Unfilled</th>\n          <th>Sold</th>\n        </tr>\n      </thead>\n      <tbody>\n        <%  if (events && events.length) {\n        events.forEach(function(event) { %>\n        <tr class="event_row" data-event-id="<%= event.id %>">\n          <td><%= App.Utils.makeTimestampHuman(event.occursAt, C.DateFormats.TableDateWithTime) %></td>\n          <td><%= event.name %></td>\n          <td><%= event.venue.name %></td>\n          <td><%= event.venue.address.locality %></td>\n          <td><%= event.venue.address.region %></td>\n          <td><%= event.orderCount %></td>\n          <td><%= event.unfilledCount %></td>\n          <td><%= event.soldCount %></td>\n        </tr>\n        <%    });\n      } %>\n      </tbody>\n    </table>\n  </div>\n</div>\n\n<div id="order_container" style="overflow: hidden; margin-bottom: 20px; min-height: 50px;">\n  <div style="width: 100%; text-align: center;">\n    <div style="width: 500px; margin: 30px auto; text-align: center; background: #fff; border: 1px solid #666; padding: 50px;">\n      <h4>Select an Event to view its Orders.</h4>\n    </div>\n  </div>\n  <!-- ManagementOrders goes here -->\n</div>\n', __filename = "js/views/events/management_events.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div class="widget-box" style="margin-top: 0px;">\n  <div class="widget-title">\n    <span class="icon"><i class="fa-solid fa-calendar-days"></i></span>\n    <h5>Events</h5>\n  </div>\n  <div class="widget-content nopadding" id="eventContainer" style="min-height: 150px;">\n    <table class="table table-bordered table-striped table-hover data-table hover_point">\n      <thead>\n        <tr>\n          <th>Occurs At</th>\n          <th>Event</th>\n          <th>Venue</th>\n          <th>City</th>\n          <th>State</th>\n          <th>Orders</th>\n          <th>Unfilled</th>\n          <th>Sold</th>\n        </tr>\n      </thead>\n      <tbody>\n        ');
            __line = 21;
            if (events && events.length) {
                events.forEach(function(event) {
                    __line = 22;
                    __append('\n        <tr class="event_row" data-event-id="');
                    __line = 23;
                    __append(escapeFn(event.id));
                    __append('">\n          <td>');
                    __line = 24;
                    __append(escapeFn(App.Utils.makeTimestampHuman(event.occursAt, C.DateFormats.TableDateWithTime)));
                    __append("</td>\n          <td>");
                    __line = 25;
                    __append(escapeFn(event.name));
                    __append("</td>\n          <td>");
                    __line = 26;
                    __append(escapeFn(event.venue.name));
                    __append("</td>\n          <td>");
                    __line = 27;
                    __append(escapeFn(event.venue.address.locality));
                    __append("</td>\n          <td>");
                    __line = 28;
                    __append(escapeFn(event.venue.address.region));
                    __append("</td>\n          <td>");
                    __line = 29;
                    __append(escapeFn(event.orderCount));
                    __append("</td>\n          <td>");
                    __line = 30;
                    __append(escapeFn(event.unfilledCount));
                    __append("</td>\n          <td>");
                    __line = 31;
                    __append(escapeFn(event.soldCount));
                    __append("</td>\n        </tr>\n        ");
                    __line = 33;
                });
            }
            __line = 34;
            __append('\n      </tbody>\n    </table>\n  </div>\n</div>\n\n<div id="order_container" style="overflow: hidden; margin-bottom: 20px; min-height: 50px;">\n  <div style="width: 100%; text-align: center;">\n    <div style="width: 500px; margin: 30px auto; text-align: center; background: #fff; border: 1px solid #666; padding: 50px;">\n      <h4>Select an Event to view its Orders.</h4>\n    </div>\n  </div>\n  <!-- ManagementOrders goes here -->\n</div>\n');
            __line = 48;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}