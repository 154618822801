App.Controllers.Base.BaseController = require('./base/base_controller.coffee')

module.exports = class App.Controllers.DeliveryController extends App.Controllers.Base.BaseController

  _deliveryCollection = null
  _deliveryView = null
  _$container = null
  _xhr = null

  routeIndex: ($container, encodedFilterParams) ->
    @displayDeliveryIndex({
      $container
      shouldReset: true
      encodedFilterParams
    })

  resetDeliveryIndex: () ->
    App.router.navigate('/deliveries'); # Don't trigger.
    @displayDeliveryIndex({
      $container: _$container
      shouldReset: true
    })

  filterDeliveryIndex: () ->
    encodedFilterParams = _deliveryView.getEncodedFilterParams()
    App.router.navigate('/deliveries/filter/' + encodedFilterParams)
    @displayDeliveryIndex({
      $container: _$container
      shouldReset: false
      encodedFilterParams
    })

  displayDeliveryIndex: (options) ->
    {
      $container
      shouldReset
      encodedFilterParams
    } = options

    isGoingToFetch = !!encodedFilterParams # bool cast
    _$container = $container

    if (_xhr)
      _xhr.abort()

    if (!_deliveryCollection || shouldReset)
      _deliveryCollection = new App.Collections.V3.Shipments(null, {
          mapping: C.Mappings.Direct.ShipmentsStatus.Shipments
      })

    if (!_deliveryView || shouldReset)
      _deliveryView = new App.Views.Delivery.List({
        $container: _$container
        collection: _deliveryCollection
      })

    if (isGoingToFetch)
      params = App.Utils.decodeFilterParams(encodedFilterParams)
      _deliveryView.reflectFilterParams(params)
      params = _deliveryView.getFilterParams()
      _xhr = _deliveryCollection.fetch({
        data: params
        reset: true
      })
      App.Utils.requestKiller.add(_xhr)
    else
      _deliveryCollection.reset()
