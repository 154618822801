template = require('./list.ejs')

App.Views.Base.FilteredTable = require('../base/filtered_table_view.coffee')

module.exports = App.Views.Email.List = App.Views.Base.FilteredTable.extend

  template: template

  initialize: (options = {}) ->
    {
      @collection
      @$container
    } = options

    @listenTo(@collection, 'reset', @onCollectionReset)
    @render()

  render: () ->
    @$container.html(
      @$el.html(
        @template()
      )
    )
    @delegateEvents()
    @initializeDropdowns()

    $content = @$('#filter_content')
    $collapseButton = @$('#filter_collapse_button')
    isHorizontalSplit = true
    options = {
      $content
      $collapseButton
      isHorizontalSplit
    }
    @collapsibleFilters = new App.Utils.CollapsibleWidget(options)

    App.Utils.dateRangePicker(@$('#sent_at_date_range'), @onSentAtDateRangeChanged, @, C.DatePickerConfig.Past)
    @buttonLoader = new App.Utils.LoadingButton(@$('#apply_filter_button'))

    @renderTable(true)
    @startLoad()
    return @$el


  renderTable: (isInitialRender = false) ->
    @table?.remove()
    @table = new App.Views.Email.EmailTable({
      filterView: @
      $container: @$('#email_table_container')
      @collection
    })
    @tableLoader = new App.Utils.LoadingBox(@table.$el)
    if (!isInitialRender)
      @stopLoad()

# VIEW EVENTS ////////////////////////////////////////////////////////////////
  events:
    'click #apply_filter_button':   'onApplyFilterButtonClick'
    'click #reset_filter_button':   'onResetFilterButtonClick'

  onResetFilterButtonClick: (e) ->
    App.Controllers.emailController.resetEmailsIndex()

  onApplyFilterButtonClick: (e) ->
    @buttonLoader.startLoad()
    @tableLoader.startLoad()
    App.Controllers.emailController.filterEmailsIndex()

  startLoad: () ->
    @tableLoader.startLoad()
    @buttonLoader.startLoad()

  stopLoad: () ->
    @tableLoader.stopLoad()
    @buttonLoader.stopLoad()

  onSentAtDateRangeChanged: (startDate, endDate) ->
    @sentAtDateRange = {
      start: startDate
      end: endDate
    }

  filterParamMappings: () ->
    return {
      sent_at:
        type: C.FilterTypes.DateRange
        el: '#sent_at_date_range'
        val: @sentAtDateRange
      order_id:
        type: C.FilterTypes.Text
        el: '#order_text'
      recipient:
        type: C.FilterTypes.Text
        el: '#recipient_text'
      subject:
        type: C.FilterTypes.Text
        el: '#subject_text'
      status:
        type: C.FilterTypes.Select2
        el: '#status_select'
    }

  initializeDropdowns: () ->

    stateTypes = C.Options.Emails.States
    stateTypes = _.map stateTypes, (key, val) ->
      return { id: val, text: key }

    @$('#status_select').select2({
      placeholder: 'All'
      data: stateTypes
      initSelection: (element, callback) ->
        data = {
          id: element.val()
          text: C.Options.Emails.States[element.val()]
        }
        callback(data)
    })

# MODEL EVENTS /////////////////////////////////////////////////////////////
  onCollectionReset: () ->
    @renderTable()
#///////////////////////////////////////////////////////////////////////////