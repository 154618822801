(function (Backbone) {
  var Store = Backbone.Store = function () {
    var that = this;

    /* Finds all objects in the store.
     * Similar to #getStore, except that it
     * assigns a attaches the cid to each object
     */
    this.all = function () {
      var allObjects = _.collect(getStore(), function (object, key) {
        object.cid = key;
        return object;
      });

      return allObjects;
    };

    // Finds any random object in the store
    this.any = function () {
      var index = (Math.floor(Math.random() * (this.getSize())));

      return this.find(this.all()[index].cid);
    };

    // Clears all objects form the store, but keeps the store around
    this.clear = function () {
      localStorage.setItem(this.name, JSON.stringify({}));
    };

    // Returns the number of objects in the store
    this.count = function () {
      return _.toArray(getStore()).length;
    };

    // Finds an object based on cid
    this.find = function (cid) {
      var foundObject = _.find(this.all(), function (object) {
        return object.cid === cid;
      });

      if (foundObject) { foundObject.cid = cid; }

      return foundObject;
    };

    // Counts the objects in the store
    this.getSize = function () {
      return this.all().length;
    };

    // Deletes the specified object from the store
    this.remove = function (cid) {
      openStore(function (store) {
        delete store[cid];
      });
    };

    // Inserts the specified object into the store
    this.set = function (object) {
      openStore(function (store) {
        delete object.attributes.cid;
        store[object.cid] = object.attributes;
      });
    };

    // Replaces the store with the specified object
    this.setStore = function (object) {
      localStorage[this.name] = JSON.stringify(object);
      return this;
    };

    // Private: Finds an object based on the given index and value
    var findByIndex = function (index, value) {
      var foundObjects = _.select(that.all(), function (object) {
        return object[index] === value;
      });

      return foundObjects;
    };

    // Private: Returns a JSON object representing the state of the store when the function was called
    var getStore = function () {
      return JSON.parse(localStorage[that.name]);
    };

    // Private: Creates the store (if it doesn't exist) and builds findByIndex functions
    var init = function () {
      if (!(localStorage[that.name])) { localStorage.setItem(that.name, JSON.stringify({})); }

      _.each(that.indexes, function (index) {
        that['findBy' + index.capitalize()] = function (value) {
          return findByIndex(index, value);
        };
      });
    };

    // Private: Easy way to open store, make changes, save it at the end
    var openStore = function (callback) {
      var store = getStore();
      callback(store);
      that.setStore(store);
    };

    init();
  };

  Store.extend = Backbone.View.extend;

  _.extend(Backbone.Collection.prototype, {
    /* Creates Backbone models from store objects, and optionally
     * deletes or rebuilds them.
     */
    buildModelsFromStore: function (options) {
      var that = this;
      var opts = options || {};

      _.each(this.store.all(), function (object, cid) {
        that.add(object);
      });

      if (opts.deleteAll) {
        that.store.clear();
      }

      if (opts.rebuildAll) {
        this.store.clear();

        _.each(this.models, function (model) {
          that.store.set(model);
        });
      }
    }
  });
})(Backbone);
