template = require('./phone_number.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.BuySell.PhoneNumber = App.Views.Base.BaseView.extend
  template: template

  initialize: (options = {}) ->
    {
      @$container
      @isEditingTevoPickup
      @existingShipment # If an existing shipment - OrderOverview shipment view in edit mode.
      @patron
      @canSkipPhoneSubmission
    } = options
    @render()

  # VIEW EVENTS ////////////////////////////////////////////////////////////////
  events:
    'click .addPhoneNumberButton':        'onAddPhoneNumberButtonClick'
    'click .cancelAddPhoneNumberButton':  'onCancelAddPhoneNumberButtonClick'

  onAddPhoneNumberButtonClick: (e) ->
    @useNewPhoneNumber = true
    @$('.existingPhoneNumberContainer').hide()
    @$('.newPhoneNumberContainer').show()

  onCancelAddPhoneNumberButtonClick: (e) ->
    @useNewPhoneNumber = false
    @$('.existingPhoneNumberContainer').show()
    @$('.newPhoneNumberContainer').hide()
  #/////////////////////////////////////////////////////////////////////////////

  render: () ->
    data = {
      @isEditingTevoPickup
      existingShipment: @existingShipment?.toPresenterJSON()
      patron: @patron?.toPresenterJSON()
      @canSkipPhoneSubmission
      @useNewPhoneNumber
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )
    @delegateEvents()
    @loadingBox = new App.Utils.LoadingBox(@$container)
    @formErrors = new App.Utils.FormErrorManager(@$('.formErrors'))

    if (!(@patron && _.size(@patron.phoneNumberOptions())))
      @onAddPhoneNumberButtonClick()

    return @$el

  getPhoneNumberPromise: () ->

    deferred = Q.defer()
    @formErrors.reset()
    formData = @$el.serializeObject()
    if (@useNewPhoneNumber)
      savePhoneNumber = !!(formData.savePhoneNumber)
      phoneNumber = new App.Models.V3.PhoneNumber(null, {
        clientId: @patron?.id
        mapping: C.Mappings.Direct.Clients.PhoneNumbers
      })
      phoneAttrs = {
        _extension: formData.extension
        _label:     formData.label
        _number:    formData.number
      }
      phoneNumber.set(phoneAttrs)
      if (phoneNumber.isValid(true))
        if (savePhoneNumber)
          @formErrors.reset()
          @loadingBox.startLoad()

          phoneNumber.savePromise()
          .then (model) =>
            @loadingBox.stopLoad()
            deferred.resolve(phoneNumber)
          .fail (errors) =>
            @loadingBox.stopLoad()
            @formErrors.show({
              title:  "Error saving phone number."
              errors
            })
            deferred.reject(errors)
          .done()

        else
          deferred.resolve(phoneNumber)

      else
        errors = phoneNumber.errors
        @formErrors.show({
          title:  "Phone Number Error."
          errors
        })
        deferred.reject(errors)

    else
      selectedPhoneNumber = formData.selectedPhoneNumber
      if (selectedPhoneNumber == C.ExistingPhoneNumber)
        if (@isEditingTevoPickup)
          phoneNumber = @existingShipment.get('_shipFromPhoneNumber')
        else
          phoneNumber = @existingShipment.get('_phoneNumber')
      else
        phoneNumber = @patron.phoneNumbers().where({ _number: selectedPhoneNumber })[0]
      deferred.resolve(phoneNumber)

    return deferred.promise