template = require('./buy_sell_wizard.ejs')
fixedPatronTemplate = require('./fixed_patron.ejs')
fixedPatronDetailsTemplate = require('./fixed_patron_details.ejs')
fixedPaymentTemplate = require('./fixed_payment.ejs')
noPaymentTemplate = require('./no_payment.ejs')
selectablePatronTemplate = require('./selectable_patron.ejs')
selectablePaymentTemplate = require('./selectable_payment.ejs')
ticketGroupsTemplate = require('./ticket_groups.ejs')
notesTemplate = require('./notes.ejs')
totalsTemplate = require('./totals.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.BuySell.BuySellWizard = App.Views.Base.BaseView.extend

  id: 'buySellWizard'

  template:                   template

  fixedPatronTemplate:        fixedPatronTemplate
  fixedPatronDetailsTemplate: fixedPatronDetailsTemplate
  fixedPaymentTemplate:       fixedPaymentTemplate

  noPaymentTemplate:          noPaymentTemplate

  selectablePatronTemplate:   selectablePatronTemplate
  selectablePaymentTemplate:  selectablePaymentTemplate

  ticketGroupsTemplate:       ticketGroupsTemplate

  notesTemplate:              notesTemplate

  totalsTemplate:             totalsTemplate

  initialize: (options = {}) ->
    {
      @$container
      @action
      @buyOrder
      @eventCollection
      @filters
      @fixedPatron
      @isCart
      @selectablePatron
      @sellOrder
      @you
    } = options
    @render()

  render: () ->
    data = {
      @action
      eventCollection: @eventCollection.toPresenterJSON()
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )
    @validShipment = false
    @confirmedTerms = SESSION.role.cannot(App.Access.Resources.Orders.BuySell.NeedsToConfirmTerms)
    @formErrors = new App.Utils.FormErrorManager(@$('#formErrors'))
    @loadingBox = new App.Utils.LoadingBox(@$container)
    @loadingButton = new App.Utils.LoadingButton(@$('#submitOrderButton'))
    @loadingButton2 = new App.Utils.LoadingButton(@$('#submitOrderSendPOButton'))

    @$shipmentContainer = @$('#shipmentContainer')
    @loadingBoxShipments = new App.Utils.LoadingBox(@$shipmentContainer)

    @$ticketGroupsContainer = @$('#ticketGroupsContainer')
    @loadingBoxTicketGroups = new App.Utils.LoadingBox(@$ticketGroupsContainer)
    @loadingBoxTicketGroups.startLoad()

    @placeFixedAndSelectablePatron()

    @$('.actLikeCurrency').formatCurrency()

    $content = @$('#buyNotesContent')
    $collapseButton = @$('#buyNotesCollapseButton')
    options = {
      $content
      $collapseButton
      isCollapsed: true
    }
    buyNotesCollapsible = new App.Utils.CollapsibleWidget(options)

    $content = @$('#sellNotesContent')
    $collapseButton = @$('#sellNotesCollapseButton')
    options = {
      $content
      $collapseButton
      isCollapsed: true
    }
    sellNotesCollapsible = new App.Utils.CollapsibleWidget(options)

#    @$('#scrollyArea').css({
#      height: "#{ $(window).height() - 450  }px"
#    })

    @delegateEvents()
    return @$el

  hideRows: () ->
    $buyRow =               @$('#buyRow')
    $sellRow =              @$('#sellRow')
    $buyRow.hide()
    $sellRow.hide()

  showBuyRow: () ->
    $buyRow =               @$('#buyRow')
    $buyPatronContainer =   @$('#buyPatronContainer')
    $buyPaymentContainer =  @$('#buyPaymentContainer')
    $buyNotesContainer =    @$('#buyNotesContainer')
    $buyTotalsContainer =   @$('#buyTotalsContainer')
    $buyRow.show()
    $buyPatronContainer.html(@fixedPatronTemplate())
    $buyPaymentContainer.html(@fixedPaymentTemplate())
    $buyNotesContainer.html(@notesTemplate({ namespace: 'buy' }))
    $buyTotalsContainer.html(@totalsTemplate({
      showFeesAndDiscounts: false,
      showBuyServiceFee: true
    }))

  hideBuyPayment: () ->
    $buyPaymentBox = @$('#buyPaymentBox')
    $buyPaymentBox.hide()

  hideBuyNotes: () ->
    $buyNotesBox = @$('#buyNotesBox')
    $buyNotesBox.hide()

  hideSellNotes: () ->
    $sellNotesBox = @$('#sellNotesBox')
    $sellNotesBox.hide()

  showSellRow: () ->
    $sellRow =               @$('#sellRow')
    $sellPatronContainer =   @$('#sellPatronContainer')
    $sellPaymentContainer =  @$('#sellPaymentContainer')
    $sellNotesContainer =    @$('#sellNotesContainer')
    $sellTotalsContainer =   @$('#sellTotalsContainer')
    $sellRow.show()
    $sellPatronContainer.html(@selectablePatronTemplate())
    $sellPaymentContainer.html(@selectablePaymentTemplate({ @action }))
    $sellNotesContainer.html(@notesTemplate({ namespace: 'sell' }))
    $sellTotalsContainer.html(@totalsTemplate({
      showFeesAndDiscounts: true,
      showBuyServiceFee: false
    }))

  showPORow: () ->
    $buyRow =               @$('#buyRow')
    $buyPatronContainer =   @$('#buyPatronContainer')
    $buyPaymentContainer =  @$('#buyPaymentContainer')
    $buyNotesContainer =    @$('#buyNotesContainer')
    $buyTotalsContainer =   @$('#buyTotalsContainer')
    $buyRow.show()
    $buyPatronContainer.html(@selectablePatronTemplate())
    if (@action == C.BuySellActions.Consignment)
      $buyPaymentContainer.html(@noPaymentTemplate)
    else
      $buyPaymentContainer.html(@selectablePaymentTemplate({ @action }))
    $buyNotesContainer.html(@notesTemplate({ namespace: 'buy' }))
    $buyTotalsContainer.html(@totalsTemplate({
      showFeesAndDiscounts: true,
      showBuyServiceFee: false
    }))

  placeFixedAndSelectablePatron: () ->
    #               +------------+------------+
    #               | BUYER      | SELLER     |
    # +-------------+------------+------------+
    # | BUY         | YOU        | FIXED      |
    # | SELL        | SELECTABLE | YOU        |
    # | BUYSELL     | SELECTABLE | FIXED      |
    # | PO          | YOU        | SELECTABLE |
    # | CONSIGNMENT | YOU        | SELECTABLE |
    # +-------------+------------+------------+

    if (@action == C.BuySellActions.Buy)
      @hideRows()
      @showBuyRow()

    if (@action == C.BuySellActions.Sell)
      @hideRows()
      @showSellRow()

    if (@action == C.BuySellActions.BuySell || @action == C.BuySellActions.AffiliateSell)
      @hideRows()
      @showBuyRow()
      @showSellRow()

    if (@action == C.BuySellActions.AffiliateSell)
      @hideBuyPayment()
      @hideBuyNotes()
      @hideSellNotes()
      # Layout hackery.
      @$('#sellCol3').addClass('flextainer').prepend(@$('#buyTotalsBox').css({ marginRight: '20px' }));
      @$('#sellCol1').append(@$('#sellPaymentBox'))
      # Commissions.
      @fetchCommission()
      @renderInfoPopover()


    if (@action == C.BuySellActions.PO || @action == C.BuySellActions.Consignment)
      @hideRows()
      @showPORow()

    @isYouFetched = false
    @renderFixedPatron()
    @renderSelectablePatron()

    if (@selectablePatron)
      @onSelectablePatronChange(@selectablePatron)
      $('.patronAutocomplete').val(@selectablePatron.toPresenterJSON().name)

  renderFixedPatron: () ->
    if (@fixedPatron)
      $fixedPaymentContainer = @$('#fixedPaymentContainer')
      loadingBoxPatronDetails = new App.Utils.LoadingBox(@$('#fixedPatronDetailsContainer'), { isInline: true })
      loadingBoxPayments = new App.Utils.LoadingBox($fixedPaymentContainer)
      loadingBoxPayments.startLoad()
      loadingBoxPatronDetails.startLoad()
      @loadingBoxShipments.startLoad()
      promises = [ @fixedPatron.fetchPromise() ]

      if (!@isYouFetched)
        promises.push(@you.fetchPromise())
        @isYouFetched = true

      Q.all(promises)
      .then (results) =>

        # Ticket groups.
        if (@action == C.BuySellActions.Buy || @action == C.BuySellActions.BuySell || @action == C.BuySellActions.AffiliateSell)
          @renderTicketGroups()

        # Seller details.
        @$('#fixedPatronDetailsContainer').html(
          @fixedPatronDetailsTemplate({
            patron: @fixedPatron.toPresenterJSON()
          })
        )

        if (@action != C.BuySellActions.AffiliateSell)
          # Payment.
          # This payment is always for Buy or the Buy of a BuySell.
          @fixedPaymentView = new App.Views.BuySell.Payment({
            action: C.BuySellActions.Buy
            $container: $fixedPaymentContainer
            order: @buyOrder
            payingPatron: @you
          })
          @listenTo(@fixedPaymentView, C.Events.PaymentTypeChanged, @onBuyPaymentTypeChanged.bind(@))
          paymentType = @$('.paymentType').val()
          @onBuyPaymentTypeChanged(paymentType)

        # Shipping.
        if (@action == C.BuySellActions.Buy)
          @renderShipment({
            originPatron: @fixedPatron
            destinationPatron: @you
          })

        @loadingBoxShipments.stopLoad()
        loadingBoxPayments.stopLoad()
        loadingBoxPatronDetails.stopLoad()
      .done()

  renderTicketGroups: () ->
    data = {
      @action
      @filters
      eventCollection: @eventCollection.toPresenterJSON()
      seller: @fixedPatron?.toPresenterJSON()
    }
    @$ticketGroupsContainer.html(
      @ticketGroupsTemplate(data)
    )

    @loadingBoxTicketGroups.stopLoad()
    @$('select:not(#discountType)').select2()
    @$('.ticketGroupSalePrice').formatCurrency()
    @recalculateTotals()
    @onBuyPaymentTypeChanged(@$('.paymentType').val())
    @$('span.tip-top').tooltip({ placement: 'top' })
    @delegateEvents()

  onBuyPaymentTypeChanged: (type) ->
    @paymentType = type
    if (type == C.CreditCard)
      # There is a CC payment for the Buy side, need to show service fee and recalculate totals
      @buyServiceFee = @calculateBuyServiceFee()
    else
      @buyServiceFee = null

    @updateBuyOrderServiceFee()

    if (@action != C.BuySellActions.PO)
      @fetchTaxQuote()

  renderSelectablePatron: () ->
    if (
      @action == C.BuySellActions.Sell ||
      @action == C.BuySellActions.PO ||
      @action == C.BuySellActions.Consignment
    )
      @renderTicketGroups()
    selectablePatronAutocomplete = new App.Views.Clients.PatronAutocomplete({
      $container: @$("#selectablePatron")
      clientsOnly: SESSION.role.cannot(App.Access.Resources.Orders.BuySell.CanSellToOffices)
    })
    @listenTo(selectablePatronAutocomplete, C.Events.PatronAutocompleteChange, _.bind(@onSelectablePatronChange, @))

  onSelectablePatronChange: (patron) ->
    @toggleTicketGroupsRetailOrWholesale(patron)
    @toggleHoldForFraud(patron)
    @renderSelectablePayment(patron)

  renderSelectablePayment: (patron) ->
    $selectablePaymentContainer = @$('#selectablePaymentContainer')
    loadingBoxPayments = new App.Utils.LoadingBox($selectablePaymentContainer)
    loadingBoxPayments.startLoad()
    @loadingBoxShipments.startLoad()
    promises = [  patron.fetchPromise() ]

    if (!@isYouFetched)
      promises.push(@you.fetchPromise())
      @isYouFetched = true

    Q.all(promises)
    .then (results) =>

      @selectablePatron = patron
      loadingBoxPayments.stopLoad()

      if (@action == C.BuySellActions.Sell || @action == C.BuySellActions.BuySell || @action == C.BuySellActions.AffiliateSell)
        # This payment is for a Sell or the Sell portion of BuySell.
        @selectablePaymentView = new App.Views.BuySell.Payment({
          action: C.BuySellActions.Sell
          $container: $selectablePaymentContainer
          order: @sellOrder
          payingPatron: @selectablePatron
        })

        if (@action == C.BuySellActions.Sell)
          @renderShipment({
            originPatron: @you
            destinationPatron: @selectablePatron
          })
        if (@action == C.BuySellActions.BuySell || @action == C.BuySellActions.AffiliateSell)
          @renderShipment({
            originPatron: @fixedPatron
            destinationPatron: @selectablePatron
          })

      if (@action == C.BuySellActions.PO)
        # This payment is for the buy order of a PO.
        @selectablePaymentView = new App.Views.BuySell.Payment({
          action: C.BuySellActions.Sell
          $container: $selectablePaymentContainer
          order: @buyOrder
          payingPatron: @you
        })

      if (@action == C.BuySellActions.PO || @action == C.BuySellActions.Consignment)
        @renderShipment({
          originPatron: @selectablePatron
          destinationPatron: @you
        })

    .done()

  renderInfoPopover: ->
    App.Utils.infoPopover(
      @$('#shiping-cost-info'),
      {
        content: "Your shipping cost will be calculated once all delivery information (name, address) has been provided.",
        title: "Shipping Cost"
      }
    )

  renderShipment: (options = {}) ->
    {
      originPatron
      destinationPatron
    } = options
    isOfflineAllowed = true
    if (@action == C.BuySellActions.Sell)
      if (destinationPatron.type == C.Office)
        isOfflineAllowed = false

    isPhysicalOnly = false
    isEticketOnly = false
    isFlashSeatOnly = false
    isTMMobileOnly = false
    isGuestlistOnly = false
    isPaperlessOnly = false
    firstTg = null
    isPickupOnly = false
    if (
      @action == C.BuySellActions.Buy ||
      @action == C.BuySellActions.BuySell ||
      @action == C.BuySellActions.AffiliateSell ||
      @action == C.BuySellActions.Sell
    )
      # Assume buy is only 1 TG.
      firstEvent = @eventCollection.first()

      firstEventOccursAt = firstEvent.get('_occursAtRaw')
      next18Hours = moment().add(18, 'hour')
      if (moment(firstEventOccursAt).isBefore(next18Hours))
        isPickupOnly = true

      firstTg = firstEvent.get('_ticketGroups').first()
      tgFormat = firstTg.get('_format')

      switch tgFormat
        when C.TicketFormats.Physical then isPhysicalOnly = true
        when C.TicketFormats.Eticket then isEticketOnly = true
        when C.TicketFormats.FlashSeats then isFlashSeatOnly = true
        when C.TicketFormats.Paperless then isPaperlessOnly = true
        when C.TicketFormats.GuestList then isGuestlistOnly = true
        when C.TicketFormats.TMMobile then isTMMobileOnly = true

    shipmentTypes = new App.Collections.V3.ShipmentTypes(null, {
      isPickupOnly
      isPhysicalOnly
      isEticketOnly
      isFlashSeatOnly
      isTMMobileOnly
      isGuestlistOnly
      isPaperlessOnly
      isOfflineAllowed
      isTBDAllowed: true
      action: @action
      mapping: C.Mappings.Direct.ShipmentTypes.ShipmentTypes
    })
    shipmentTypes.fetchPromise()
    .then (collection) =>
      @loadingBoxShipments.stopLoad()
      @shipmentView = new App.Views.Shipping.Shipment({
        @action
        $container: @$shipmentContainer
        destinationPatron
        originPatron
        shipmentTypes
        toggleValidShipment: _.bind(@toggleValidShipment, @)
        firstTg
      })
      @listenTo(@shipmentView, C.Events.ShippingCostChange, _.bind(@onShippingCostChange, @))

  fetchCommission: () ->
    @commission = new App.Models.V3.Commission(null, {
      mapping: C.Mappings.Direct.Commissions.Commission
    })
    App.ErrorManager.ignoreNext(404)
    @commission.fetchPromise()
    .then (model) =>
      App.ErrorManager.resetTolerance(404)
      @populateCommission(@sellOrder)
    .fail (errors) =>
      App.ErrorManager.resetTolerance(404)
      #@updateTotals()
      #@formErrors.show({
      #  title: "Unable to fetch commission info."
      #  errors
      #})
    .done()

  fetchTaxQuote: () ->
    @$('#buySellTicketGroupsTable tbody tr').each((index, tr) =>
      ticketGroupModel = @getTicketGroupModelForRow($(tr))
      @fetchTaxQuoteForModel(ticketGroupModel)
    )

  fetchTaxQuoteForModel: (ticketGroupModel) ->
    ticketGroupId = ticketGroupModel.get('_id')
    quantity = parseInt(ticketGroupModel.get('_quantity'))

    if (quantity > 0)
      requestAttrs = { ticketGroupId, quantity, paymentType: @paymentType }
      if (@action == C.BuySellActions.Sell || @action == C.BuySellActions.BuySell || @action == C.BuySellActions.AffiliateSell)
        requestAttrs.retail = @retailTaxFields(ticketGroupModel)
      options = { mapping: C.Mappings.Direct.TaxQuote.TaxQuote }
      @taxQuote = new App.Models.V3.TaxQuote(requestAttrs, options)
      @executeTaxQuoteCall()

  retailTaxFields: (ticketGroupModel) ->
    return {
      price: ticketGroupModel.get('_priceSale') || 0,
      serviceFee: $('#sellTotalsContainer #serviceFee').val() || 0,
      shipping: $('#sellTotalsContainer #shipping').val() || 0,
      discount: $('#sellTotalsContainer #discountAmount').val() || 0,
      additionalExpense: $('#sellTotalsContainer #extraCost').val() || 0,
    }

  executeTaxQuoteCall: () ->
    App.ErrorManager.ignoreNext(422)
    App.ErrorManager.ignoreNext(500)
    @taxQuote.savePromise()
    .then (model) =>
      @updateTotals(model.toPresenterJSON())
      App.ErrorManager.resetTolerance(422)
      App.ErrorManager.resetTolerance(500)
    .catch (errors) =>
      console.error(errors)
      App.ErrorManager.resetTolerance(422)
      App.ErrorManager.resetTolerance(500)
      @updateTotals({wholesaleTax: 0, retailTax: 0})
      @taxQuote = undefined



  # VIEW EVENTS //////////////////////////////////////////////////////////////
  events:
    # TicketGroups
    'change .ticketGroupQuantity':      'onTicketGroupChange'
    'keyup .ticketGroupSalePrice':      'onTicketGroupChange'
    'blur .ticketGroupSalePrice':       'onLeaveSalePrice'
    'keyup #extraCost':                 'updateTotals'
    'keyup #serviceFee':                'updateTotals'
    'keyup #discountAmount':            'updateTotals'
    'change #discountType':             'updateTaxAndTotals'
    'blur #extraCost':                  'updateTaxAndTotals'
    'blur #serviceFee':                 'updateTaxAndTotals'
    'blur #discountAmount':             'updateTaxAndTotals'
    'click #submitOrderButton':         'onSubmitOrderButtonClick'
    'click #submitOrderSendPOButton':   'onSubmitOrderSendPOButtonClick'
    'click #cancelButton':              'onCancelButtonClick'
    'change #termsConfirmation':         'onTermsConfirmationChange'


  onTicketGroupChange: (e) ->
    @recalculateTotals()
    @onBuyPaymentTypeChanged(@$('.paymentType').val())

  onLeaveSalePrice: (e) ->
    # if new price is NaN, set it to purchase price
    if (e != undefined && !parseFloat(e.currentTarget.value))
      ticketGroupContainer = $(e.currentTarget).closest('tr')
      ticketGroupData = ticketGroupContainer.data()
      eventId = ticketGroupData.eventId
      tgID = ticketGroupData.ticketGroupId
      ticketGroupModel = @eventCollection.get(eventId).get('_ticketGroups').get(tgID)
      wholesalePrice = ticketGroupModel.get('_priceWholesale')
      e.currentTarget.value = App.Utils.valueToCurrencyNo$(wholesalePrice)
      @recalculateTotals()

  onSubmitOrderSendPOButtonClick: (e) ->
    autoPend = true
    @submitOrder(autoPend)

  onSubmitOrderButtonClick: (e) ->
    if (@isAutomatedTicketGroup() && SESSION.features.order_integration_enabled)
      App.Controllers.buySellController.showProcessingPurchaseModal()
    @submitOrder()

  submitOrder: (autoPend = false) ->
    @formErrors.reset()
    @disableCancelButton()

    promises = [ @shipmentView.getShipmentPromise() ]

    if (@fixedPaymentView)
      paymentAttrs = {
        _amount: @buyOrder.get('_total')
      }
      promises.push(@fixedPaymentView.getPaymentPromise(paymentAttrs))

    else
      promises.push(Q(null))

    if (@selectablePaymentView)
      amount = @sellOrder.get('_total')
      if (@action == C.BuySellActions.PO || @action == C.BuySellActions.Consignment)
        amount = @buyOrder.get('_total')
      paymentAttrs = {
        _amount: amount
      }
      promises.push(@selectablePaymentView.getPaymentPromise(paymentAttrs))
    else
      promises.push(Q(null))

    return Q.all(promises).spread (shipment, fixedPayment, selectablePayment) =>

      if (@action == C.BuySellActions.Buy || @action == C.BuySellActions.PO || @action == C.BuySellActions.Consignment)
        @populateOrderWithNotes(@buyOrder, '#buyNotesContainer')
        @buyOrder.set('_reference', @$('#buyPatronContainer #reference').val())

      if (@action == C.BuySellActions.Sell)
        @populateOrderWithNotes(@sellOrder, '#sellNotesContainer')
        @sellOrder.set('_reference', @$('#sellPatronContainer #reference').val())

      if (@action == C.BuySellActions.BuySell || @action == C.BuySellActions.AffiliateSell)
        @populateOrderWithNotes(@sellOrder, '#sellNotesContainer')
        @populateOrderWithNotes(@buyOrder, '#buyNotesContainer')
        @buyOrder.set('_reference', @$('#buyPatronContainer #reference').val())
        @sellOrder.set('_reference', @$('#sellPatronContainer #reference').val())

      holdForFraud = @$('#holdForFraud').is(':checked')

      orderOptions = {
        @action
        autoPend
        @buyOrder
        @eventCollection
        @fixedPatron
        fixedPayment
        holdForFraud
        @isCart
        @selectablePatron
        selectablePayment
        @sellOrder
        shipment
        @you
        @taxQuote
      }
      return App.Controllers.buySellController.submitOrderPromise(orderOptions)
      .then (model) =>
        window.dataLayer.push({
          event: 'ticket-group-buy-submit',
        })
        App.Controllers.buySellController.hideProcessingPurchaseModal()
        @enableCancelButton()
      .fail (errors) =>
        App.Controllers.buySellController.hideProcessingPurchaseModal()
        # Errors from submitOrderPromise()
        if ( errors.status == 503 || errors.status == 504 )
          @formErrors.show({
            title: "Sorry, there was an error processing your order."
            message: "Request timeout. The request took longer than expected."
          })
        else
          @formErrors.show({
            title: "Sorry, there was an error processing your order."
            errors
          })
        @enableCancelButton()

    .fail (errors) =>
      App.Controllers.buySellController.hideProcessingPurchaseModal()
      @enableCancelButton()
      # Errors from getPaymentPromise & getShipmentPromise
      # They'll display details inline on the payment or shipment containers.
      @formErrors.show({
        title: "Invalid order. Cannot be submitted."
        errors
      })
    .done()
  #///////////////////////////////////////////////////////////////////////////

  onShippingCostChange: (cost) ->
    @updateTotals({
      shipping: cost
    })

  toggleHoldForFraud: (patron) ->
    if (patron.type == C.Client)
      @$('#holdForFraud').attr('checked', true)
    else
      @$('#holdForFraud').removeAttr('checked')

  toggleTicketGroupsRetailOrWholesale: (patron) ->
    if (@action == C.BuySellActions.Sell)
      if (patron.type == C.Client)
        # Flip all retails to wholesale.
        @$('#buySellTicketGroupsTable tbody tr').each((index, tr) =>
          $tr = $(tr)
          ticketGroupModel = @getTicketGroupModelForRow($tr)
          currentPrice = parseFloat($tr.find('.ticketGroupSalePrice').val())
          if (currentPrice == ticketGroupModel.get('_priceWholesale'))
            $tr.find('.ticketGroupSalePrice').val(
              App.Utils.valueToCurrencyNo$(ticketGroupModel.get('_priceRetail'))
            )
        )
      else
        # Flip all wholesales to retail.
        @$('#buySellTicketGroupsTable tbody tr').each((index, tr) =>
          $tr = $(tr)
          ticketGroupModel = @getTicketGroupModelForRow($tr)
          currentPrice = parseFloat($tr.find('.ticketGroupSalePrice').val())
          if (currentPrice == ticketGroupModel.get('_priceRetail'))
            $tr.find('.ticketGroupSalePrice').val(
              App.Utils.valueToCurrencyNo$(ticketGroupModel.get('_priceWholesale'))
            )
        )

  getTicketGroupModelForRow: ($tr) ->
    data = $tr.data()
    ticketGroupId = data.ticketGroupId || data.ticketGroupCid
    eventId = data.eventId
    eventModel = @eventCollection.get(eventId)
    ticketGroupCollection = eventModel.get('_ticketGroups')
    ticketGroupModel = ticketGroupCollection.get(ticketGroupId)
    return ticketGroupModel

  recalculateTotals: () ->

    totalCost = 0
    totalSale = 0
    totalWholesale = 0

    @$('#buySellTicketGroupsTable tbody tr').each((index, tr) =>
      $tr = @$(tr)
      ticketGroupModel = @getTicketGroupModelForRow($tr)

      # Notes popovers.
      if (!$tr.data().isNotesInitialized)
        $el = $tr.find('.popover_notes')
        App.Utils.notesPopover($el, ticketGroupModel)
        $tr.data({ isNotesInitialized: true })

      quantity = parseInt(ticketGroupModel.get('_quantity'))

      if (@action == C.BuySellActions.PO || @action == C.BuySellActions.Consignment)
        cost = ticketGroupModel.get('_cost')
        groupCost = cost * quantity
        totalCost += groupCost
      else if (ticketGroupModel.get('_heldSeats'))
        # Quantity is locked when selling a hold.
        $.noop()
      else
        # Quantity.
        quantity = parseInt($tr.find('.ticketGroupQuantity').select2('val'))
        ticketGroupModel.set('_quantity', quantity)

      # Wholesale.
      if (
        @action == C.BuySellActions.AffiliateSell ||
        @action == C.BuySellActions.Buy ||
        @action == C.BuySellActions.BuySell ||
        @action == C.BuySellActions.PO ||
        @action == C.BuySellActions.Consignment
      )
        wholesalePrice = ticketGroupModel.get('_priceWholesale')
        groupWholesale = wholesalePrice * quantity
        $tr.find('.ticketGroupTotalWholesale').text(
          App.Utils.valueToCurrency(groupWholesale)
        ) if SESSION.role.cannot(App.Access.Resources.Orders.BuySell.NoTotals)
        totalWholesale += groupWholesale

      # Sale Price.
      if (@action == C.BuySellActions.Sell || @action == C.BuySellActions.BuySell || @action == C.BuySellActions.AffiliateSell)
        salePrice = parseFloat($tr.find('.ticketGroupSalePrice').val())
        ticketGroupModel.set('_priceSale', salePrice)
        groupSale = salePrice * quantity
        $tr.find('.ticketGroupTotalSale').text(
          App.Utils.valueToCurrency(groupSale)
        )
        totalSale += groupSale
    )
    @updateTotals({
      totalCost
      totalSale
      totalWholesale
    })

  populateOrderWithTaxSignature: (order, notesContainer) ->
    order.set('_notesPrivate', @$("#{ notesContainer } .notesPrivate").val())
    order.set('_notesPublic', @$("#{ notesContainer } .notesPublic").val())
    order.set('_instructions', @$("#{ notesContainer } .instructions").val())

  populateOrderWithNotes: (order, notesContainer) ->
    order.set('_notesPrivate', @$("#{ notesContainer } .notesPrivate").val())
    order.set('_notesPublic', @$("#{ notesContainer } .notesPublic").val())
    order.set('_instructions', @$("#{ notesContainer } .instructions").val())

  populateOrderWithTotals: (order, totalsContainer, shipping, includeFees = false) ->
    if (includeFees)
      extraCost =   App.Utils.roundCurrency(@$("#{ totalsContainer } #extraCost").val())
      serviceFee =  App.Utils.roundCurrency(@$("#{ totalsContainer } #serviceFee").val())
      discountAmount = App.Utils.roundCurrency(@$("#{ totalsContainer } #discountAmount").val())
    else
      extraCost = 0
      serviceFee = 0
      discountAmount = 0

    tax = App.Utils.roundCurrency(@$("#{ totalsContainer } #tax").val())
    discountType = @$("#{ totalsContainer } #discountType").val()
    discount = App.Utils.calculateDiscount(order.get('_subtotal'), discountType, discountAmount)

    # Check to see if a service fee is needed, then recalculate in case subtotal changed.
    if (@buyServiceFee && order == @buyOrder)
      @buyServiceFee = @calculateBuyServiceFee()
      serviceFee = @buyServiceFee
      @$("#buyServiceFee").val(App.Utils.valueToCurrencyNo$(serviceFee))
      @$("#buyServiceFeeWarning").html(App.Utils.valueToCurrency(serviceFee))

    orderAttrs = {
      _discount: discount
      _fee: extraCost
      _serviceFee: serviceFee
      _tax: tax
    }
    order.set(orderAttrs)

    if (!_.isUndefined(shipping)) # 0 or "0.00" would be false.
      shipping = App.Utils.roundCurrency(shipping)
      @$("#{ totalsContainer } #shipping").val(App.Utils.valueToCurrencyNo$(shipping))
      order.set('_shipping', shipping)

    @$("#{ totalsContainer } #total").val(App.Utils.valueToCurrencyNo$(order.total()))

  populateCommission: (order) ->
    if (@action == C.BuySellActions.AffiliateSell)
      commissionAmount = (order.get('_subtotal') * @commission.get('_amount') ) + order.get('_serviceFee') - order.get('_discount')
      commissionAmount = 0 if commissionAmount < 0
      @$('#affiliateCommission').val(App.Utils.valueToCurrencyNo$(commissionAmount))

  updateTaxAndTotals: (options) ->
    @updateTotals(options)

    # Not fetching tax quote on PO because the ticket group ID hasn't been created yet
    if (@action != C.BuySellActions.PO)
      @fetchTaxQuote()

  updateTax: (options) ->
    { wholesaleTax, retailTax } = options

    if ((@action == C.BuySellActions.Buy || @action == C.BuySellActions.BuySell || @action == C.BuySellActions.AffiliateSell) && _.isNumber(wholesaleTax) && !isNaN(wholesaleTax))
      @buyOrder.set('_tax', wholesaleTax) # needed?
      @$('#buyTotalsContainer #tax').val(App.Utils.valueToCurrencyNo$(wholesaleTax))

    if ((@action == C.BuySellActions.Sell || @action == C.BuySellActions.BuySell || @action == C.BuySellActions.AffiliateSell) && _.isNumber(retailTax) && !isNaN(retailTax))
      @sellOrder.set('_tax', retailTax)
      @$('#sellTotalsContainer #tax').val(App.Utils.valueToCurrencyNo$(retailTax))

  updateTotals: (options) ->
    {
      shipping
      totalCost
      totalSale
      totalWholesale
      wholesaleTax
      retailTax
    } = options

    if ((@action == C.BuySellActions.PO || @action == C.BuySellActions.Consignment) && _.isNumber(totalCost) && !isNaN(totalCost))
      @buyOrder.set('_subtotal', totalCost)
      @$('#buyTotalsContainer #subtotal').val(App.Utils.valueToCurrencyNo$(totalCost))

    if ((@action == C.BuySellActions.Sell || @action == C.BuySellActions.BuySell || @action == C.BuySellActions.AffiliateSell) && _.isNumber(totalSale) && !isNaN(totalSale))
      @sellOrder.set('_subtotal', totalSale)
      @$('#sellTotalsContainer #subtotal').val(App.Utils.valueToCurrencyNo$(totalSale))

    if ((@action == C.BuySellActions.Buy || @action == C.BuySellActions.BuySell || @action == C.BuySellActions.AffiliateSell) && _.isNumber(totalWholesale) && !isNaN(totalWholesale))
      @buyOrder.set('_subtotal', totalWholesale)
      @$('#buyTotalsContainer #subtotal').val(App.Utils.valueToCurrencyNo$(totalWholesale))

    @updateTax(options)

    if (@action == C.BuySellActions.Sell || @action == C.BuySellActions.BuySell || @action == C.BuySellActions.AffiliateSell)
      sellShipping = shipping
      buyShipping = null
    if (@action == C.BuySellActions.Buy || @action == C.BuySellActions.PO || @action == C.BuySellActions.Consignment)
      sellShipping = null
      buyShipping = shipping

    if (@action == C.BuySellActions.PO || @action == C.BuySellActions.Consignment)
      includeFees = true
      @populateOrderWithTotals(@buyOrder, '#buyTotalsContainer', buyShipping, includeFees)
    else
      includeFees = true
      @populateOrderWithTotals(@sellOrder, '#sellTotalsContainer', sellShipping, includeFees)
      @populateCommission(@sellOrder)
      includeFees = false
      @populateOrderWithTotals(@buyOrder, '#buyTotalsContainer', buyShipping, includeFees)

  onCancelButtonClick: (e) ->
    firstEvent = @eventCollection.first()
    if (@action == C.BuySellActions.Sell)
      App.router.navigate("/tickets/events/my/#{ firstEvent.id }/my", { trigger: true })
    else
      App.router.navigate("/tickets/events/all/#{ firstEvent.id }/all", { trigger: true })


  onTermsConfirmationChange: () ->
    @confirmedTerms = @$('#termsConfirmation').is(':checked')
    @toggleSubmitOrderButton()

  toggleValidShipment: (valid = false) ->
    @validShipment = valid
    @toggleSubmitOrderButton()

  toggleSubmitOrderButton: () ->
    if (@validShipment && @confirmedTerms )
      @$('#submitOrderButton').removeAttr('disabled')
      @$('#submitOrderSendPOButton').removeAttr('disabled')
    else
      @$('#submitOrderButton').attr('disabled', true)
      @$('#submitOrderSendPOButton').attr('disabled', true)

  disableCancelButton: () ->
    @$('#cancelButton').attr('disabled', true)

  enableCancelButton: () ->
    @$('#cancelButton').removeAttr('disabled')

  isAutomatedTicketGroup: () ->
    $tr = $(@$('#buySellTicketGroupsTable tr.ticketGroupRow')[0])
    ticketGroupModel = @getTicketGroupModelForRow($tr)
    return ticketGroupModel.get('_isAutomated')

  startLoad: () ->
    if (!@isAutomatedTicketGroup() || !SESSION.features.order_integration_enabled)
      @loadingBox.startLoad()
    @loadingButton.startLoad()
    @loadingButton2.startLoad()

  stopLoad: () ->
    @loadingBox.stopLoad()
    @loadingButton.stopLoad()
    @loadingButton2.stopLoad()

  calculateBuyServiceFee: () ->
    subtotal = @buyOrder.get('_subtotal')
    return App.Utils.twoDecimalPlaces(subtotal * C.SERVICE_FEE_PRECENTAGE)

  updateBuyOrderServiceFee: () ->
    serviceFee = @buyServiceFee || 0
    @$("#buyServiceFee").val(App.Utils.valueToCurrencyNo$(serviceFee))
    @buyOrder.set({_serviceFee: serviceFee})
    @$("#buyTotalsContainer #total").val(App.Utils.valueToCurrencyNo$(@buyOrder.total()))
    @$("#buyServiceFeeWarning").html(App.Utils.valueToCurrency(serviceFee))
