template = require('./payment_table.ejs');

module.exports = App.Views.Payments.PaymentTable = Backbone.View.extend({

  template: template,

  dataTableOptions: {
    bJQueryUI: true,
    sDom: '<"">t<"F"fp>',
    bScrollInfinite: true,
    bScrollCollapse: false,
    iDisplayLength: 1000,
    sScrollY: '700px',
    aaSorting: [[ 4, 'desc']],
    aoColumns: [
      null,
      null,
      null,
      null,
      { sType: 'currency' },
      { sType: 'date' },
      { sType: 'currency' },
      null
    ]
  },

  initialize: function(options) {
    var options = options || {};
    this.collection = options.collection;
    this.$container = options.$container;
    this.filterView = options.filterView;
    this.render();
  },

  render: function () {
    var that = this;

    var data = {
      payments: this.collection.toPresenterJSON()
    };
    that.$el.html(that.template(data));
    that.$container.html(that.$el);
    that.endlessDataTable = new App.Utils.EndlessDataTable({
      collection: that.collection,
      filterView: that.filterView,
      dataTableOptions: that.dataTableOptions
    });

    return that.$el;
  },

  // VIEW EVENTS /////////////////////////////////////////////////////////////
  events: {
  }
  ////////////////////////////////////////////////////////////////////////////

});