App.Collections.V2.Events = require('../../collections/v2/events_collection.coffee')
App.Models.Base.BaseModel = require('../base/base_model.coffee')
App.Models.Payment        = require('../payment_model')

parent = App.Models.Base.BaseModel.prototype
module.exports = App.Models.V2.Order = App.Models.Base.BaseModel.extend

  has_many: ['orderItems', 'shipments']

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)
    @set '_customer', @customer()
    @standaloneOrderItems = new App.Collections.V2.OrderItems(null, {
      mapping: C.Mappings.OrderEndpoint.OrderItem
    })

  parse: (json, options) ->
    parent.parse.call(@, json, options)
    #this is not the best way, but it is fine for now.  Maybe have a collection for the models later
    json.returns    = _.filter json.payments, (p) -> p.type is   'return' || p.type is 'penalty'
    json.payments   = _.filter json.payments, (p) -> p.type isnt 'return' && p.type != 'penalty'
    json.returns    = _.map    json.returns,  (r) -> new App.Models.Return(r)
    json.payments   = _.map    json.payments, (p) -> new App.Models.Payment(p)
    json.orderItems = json.items

    # This feels wrong.  Sorry.  Still working out the best way here.
    json.shipments = new App.Collections.V2.Shipments(json?.shipments?.models || json?.shipments, {
      holder_id: @id
      mapping: C.Mappings.OrderEndpoint.OrderShipment
    })

    json

#  ****************************
#  STANDARD SETUP FOR A V2 MODEL
#  ****************************
  urlRoot: '/v2/orders'
  v1Url: -> @url().replace /v2\//, ''

  fetch: (options) ->
    @mapping = C.Mappings.V2OrderEndpoint.Order
    parent.fetch.call(@, options)

  printUrl:   -> "#{@v1Url()}/print#{if @get('isPO') then '?purchase_order=true' else '' }"

  defaults:
    notes:   []
    returns: []

#  ****************************
#  DESCRIPTIVE METHODS
#  ****************************
  cannot_view_children: -> @get('isPO') and ( @get('buyer').id is SESSION.office_id )

  isSpec:    -> @get('spec')
  isSub:     -> @get('substitute_order_link_id')
  isEticket: -> !!(@get('ticketGroup') and @get('ticketGroup').get('eticket'))
  # Rich isPO is rarely existent.  Usually returning undefined.
  #isPO:      -> @get('isPO')


#  ****************************
#  REMOVE ME WHEN YOU GET A CHANCE
#  ****************************
  getBuyer:  -> @get('_buyer')
  getSeller: -> @get('_seller')

  toJSON: (options) ->
    json = parent.toJSON.call(@, options)
    json.orderItems = json.orderItems?.toJSON()
    json.shipments = json.shipments?.toJSON()
    return json

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    # FIND WHICH MAPPING THESE APPLY TO
    attrs.orderItems = new App.Collections.V2.OrderItems(json?.orderItems?.models || json?.orderItems, {
      holder_id: @id
      mapping: C.Mappings.OrderEndpoint.OrderItem
    })
    attrs.shipments = new App.Collections.V2.Shipments(json?.shipments?.models || json?.shipments, {
      holder_id: @id
      mapping: C.Mappings.OrderEndpoint.OrderShipment
    })

    if (@getMapping() == C.Mappings.OrderEndpoint.Order)
      attrs._additionalExpense = json.additonal_expense
      attrs._balance = json.balance
      attrs._buyer = new App.Models.V2.Patron(json.buyer, {
        mapping: C.Mappings.OrderEndpoint.Patron
      })
      attrs._buyerState = json.buyer_state
      attrs._buyerType = json.buyer_type
      attrs._createdAt = json.created_at
      attrs._discount = json.discount
      attrs._fee = json.fee
      attrs._hasEticketsMissing = do () ->
        if (json.items)
          for item in json.items
            if (item.needs_eticket)
              return true
          return false
      attrs._id = json.id  # link id
      attrs._isConsignment = json.consignment
      attrs._isSpec = json.spec
      attrs._isSpecFulfilled = json.spec_fulfilled
      if json.items
        attrs._items = new App.Collections.V2.OrderItems(json.items, {
          mapping: C.Mappings.OrderEndpoint.OrderItem
        })
      attrs._minfraudRiskScore = json.minfraud_risk_score
      attrs._orderId = json.oid
      #payments: [,…]
      attrs._placer = json.placer
      attrs._reference = json.reference
      attrs._seller = new App.Models.V2.Patron(json.seller, {
        mapping: C.Mappings.OrderEndpoint.Patron
      })
      attrs._sellerState = json.seller_state
      attrs._sellerType = json.seller_type
      attrs._serviceFee = json.service_fee
      #shipment_snapshot: [{postal_code:89178, email_address:null, type:FedEx, country_code:US,…}]
      #shipments: [{,…}]
      attrs._shipping = json.shipping
      attrs._state = json.state
      attrs._subtotal = json.subtotal
      attrs._tax = json.tax
      attrs._total = json.total
      attrs._updatedAt = Date.parse(json.updated_at)
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.V2OrderEndpoint.Order)
      attrs._additionalExpense = json.additonal_expense
      attrs._balance = json.balance
      attrs._billingAddress = json.billing_address # Should be a model.
      attrs._buyer = new App.Models.V2.Patron(json.buyer, {
        mapping: C.Mappings.V2OrderEndpoint.Patron
      })
      attrs._buyerCancellationNotes = json.buyer_cancellation_notes
      attrs._buyerCancellationReason = json.buyer_cancellation_reason
      attrs._buyerState = json.buyer_state
      attrs._childOrders = json.child_orders # Should be a model.
      attrs._client = json.client # Should be a model.
      attrs._createdAt = Date.parse(json.created_at)
      attrs._createdBy= json.created_by
      attrs._createdByIpAddress = json.created_by_ip_address
      attrs._discount = json.discount
      attrs._distinctEvents = new App.Collections.V2.Events(json.distinct_events, {
        mapping: C.Mappings.V2OrderEndpoint.Event
      })
      attrs._fee = json.fee
      fraudAttrs = {
        notifications: json.kount_notifications
        score:         json.kount_score
        status:        json.kount_status
        transactions:  json.kount_transactions
        fraudCheckStatus: json.fraud_check_status
      }
      attrs._fraud = new App.Models.V3.Fraud(fraudAttrs, {
        mapping: C.Mappings.V2OrderEndpoint.Fraud
      })
      attrs._id = json.id
      attrs._instructions = json.instructions
      attrs._isConsignment = json.consignment
      attrs._isPartner = json.partner
      attrs._isPO = json.isPO
      attrs._isSellerPOS = json.seller_is_pos
      attrs._isSpec = json.spec
      attrs._isSpecFulfilled = json.spec_fulfilled
      attrs._items = new App.Collections.V2.OrderItems(json.items, {
        mapping: C.Mappings.V2OrderEndpoint.OrderItem
      })
      attrs._minfraudResponse = json.minfraud_response
      attrs._notes = json.notes
      attrs._orderId = json.oid
      attrs._patronType = json.patron_type
      attrs._payments = json.payments
      attrs._penaltiesTotal = json.penalties_total
      attrs._placer = json.placer # Should be a model.
      attrs._reference = json.reference
      attrs._refunded = json.refunded
      attrs._returnsTotal = json.returns_total
      attrs._seller = new App.Models.V2.Patron(json.seller, {
        mapping: C.Mappings.V2OrderEndpoint.Patron
      })
      attrs._sellerCancellationNotes = json.seller_cancellation_notes
      attrs._sellerCancellationReason = json.seller_cancellation_reason
      attrs._sellerState = json.seller_state
      attrs._serviceFee = json.service_fee
      attrs._shipments = json.shipments
      attrs._shipping = json.shipping
      attrs._shippingAddress = json.shipping_address
      attrs._state = json.state
      attrs._statusCode = json.status_code
      attrs._substituteOrderLinkId = json.substitute_order_link_id
      attrs._substitutions = json.substitutions
      attrs._subtotal = json.subtotal
      attrs._tax = json.tax
      attrs._total = json.total
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # NOT COMPLETE - TONS OF NESTED MODELS.

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    @super('toPresenterJSON')
    presented = _.extend(@toJSON(), {

      balance:               App.Utils.valueToCurrency(@get('_balance'))
      buyer:                 @get('_buyer')?.toPresenterJSON()
      buyerState:            C.Options.Order.States[@get('buyer_state')]
      createdAt:             @get('_createdAt')
      customerName:          @customer().toPresenterJSON().prettyName
      flags:                 @flags()
      hasEticketsMissing:    @hasEticketsMissingDisplay()
      hasChildOrders:        not @isPO() and @get('child_orders')?.length
      id:                    @get('_id')
      isPartner:             @get('_isPartner')
      isTevoOrder:           @isTevoOrder()
      orderId:               @get('_orderId')
      orderState:            @orderState()
      orderType:             @type()
      placer: {
        brokerage:           (n = @get('placer')?.brokerage?.name) and "(#{n})"
        name:                (n = @get('placer')?.name)
        createdBy:           @createdBy()
      }
      printUrl:              @printUrl()
      reference:             @get('_reference')
      seller:                @get('_seller').toPresenterJSON()
      sellerState:           C.Options.Order.States[@get('seller_state')]
      total:                 App.Utils.valueToCurrency(@get('_total'))
      viewLink:              @viewLink()
    })
    return presented

  customer: () -> @get("_#{@patronType()}")

  createdBy: () ->
    isMaster = @get('_buyer')?.get('_brokerage')?.get('_isMaster')
    if (isMaster)
      return 'Ticket Evolution'
    if @get("_placer")?.name
      @get('_placer').name
    else if @get("_placer")?.brokerage
      @get('_placer').brokerage?.name
    else
      @get("created_by")

  type: -> "#{if @get('consignment') then 'Consignment ' else '' } #{if @isPO() then 'Purchase ' else '' }Order"

  flags: () ->
    flags = ''
    if (@get('_isSpec'))
      flags += C.FlagBadges.Spec
    # We have no Eticket or Notes fields as of now.
    #if (@get('_isEticket'))
    #  flags += C.FlagBadges.Eticket()
    #if (@get('_notesBroker') || @get('_notesPrivate') || @get('_notesPublic'))
    #  flags += C.FlagBadges.Notes
    return flags

  hasEticketsMissingDisplay: () ->
    if (@get('_hasEticketsMissing'))
      return '<span class="label label-important">Missing</span>'
    return ''

  isPO: () ->
    buyer = @get('_buyer')
    buyerId = buyer?.get('_id')
    officeId = SESSION.office_id
    if (buyer && buyerId && officeId && buyerId == officeId)
      return true
    return false

  isTevoOrder: () ->
    buyer = @get('_buyer')
    buyerId = buyer?.get('_id')
    if (buyerId)
      return buyerId == C.TicketEvolutionOfficeId
    return false

  orderState: () -> return C.Options.Order.States[@get("_#{@userType()}State")]

  viewLink: () ->
    return "
      <a href='/order/#{@get('id')}' class='view_order_button btn btn-small' data-order-id='#{@get('id')}'>
        <i class='icon fa-solid fa-magnifying-glass'></i> View
      </a>"

  setHasOutstandingChildren: () ->
    outstandingStates = [C.Pending, 'open']
    states       = @child_orders && @child_orders.collect (co) -> co.get('seller_state')
    intersection = _.intersection(states, outstandingStates)
    @set('hasOutstandingChildren', ( intersection.length != 0 ))

  hasOpenChildOrders: () ->
    if (@child_orders)
      openChildOrders = @child_orders.filter((childOrderModel) ->
        return (childOrderModel.get('seller_state') == 'open')
      )
      return !!(openChildOrders.length)
    else
      return false

  #/////////////////////////////////////////////////////////////////////////////

  # Will call callback if provided, else will just return a promise.
  fetchAllItems: (callback) ->
    deferred = @standaloneOrderItems.fetch(
      data:
        order_id: @get('id')
      success: (collection, response, options) =>
        if (callback)
          callback(@standaloneOrderItems)
      error: (collection, response, options) =>
        console.log('Unhandled Error at OrderModel.fetchAllItems')
    )
    return deferred

  events: ->
    items = @standaloneOrderItems.models
    if (items.length > 0)
      events  = {}
      offices = {}

      items.forEach (item) =>
        tg = item.get('ticket_group')
        unless events[tg.event.id]
          event = $.extend true, {offices: {}}, tg.event
          events[event.id] = event
          office_id = if tg.office then tg.office.id else tg.office_id
        unless events[tg.event.id].offices[office_id]
          office = $.extend true, {ticket_groups: []}, tg.office
          events[tg.event.id].offices[office_id] = office
        ticketGroup = $.extend true, {}, tg
        delete ticketGroup.event
        delete ticketGroup.office
        events[tg.event.id].offices[office_id].ticket_groups.push(ticketGroup)
      sorted   = _.values(events).sort (a,b) -> a.occurs_at > b.occurs_at
      response = _.map sorted, (event) -> new App.Models.V3.Event(event)
      response
    else
      []


  toDataTableRowArray: () ->
    item = @toPresenterJSON()
    row = [
      item.oid
      item.customerName
      App.Utils.makeTimestampHuman(item.createdAt, C.DateFormats.TableDateWithTime)
      item.buyerState
      item.sellerState
      item.total
      item.balance
      item.reference
      item.hasEticketsMissing
      item.flags
      item.viewLink
    ]
    return row

  pendToSeller: (pendData, paymentType)  ->
    post = $.post(
      "#{@url()}/pend_to_seller",
      {
        order_item_links: pendData
        pos: true
        'payments[]': paymentType
        seller_type: 'Office'
      }
    )
    post.done (response)  =>
      @attributes = @parse(response)
    post

  acceptOrder: (move_consigned_tickets)  ->
    params = if move_consigned_tickets then {move_consigned_tickets: move_consigned_tickets} else {}
    post   = $.post(this.v1Url() + '/accept', params)
    post.done (response) =>
      @attributes = @parse(response)
      @updateStandardAttributes()
    post

  rejectOrder: ()  ->
    rejection_reason = this.get('rejection_reason')
    rejection_notes = this.get('rejection_notes') or false
    rejection_reason = if rejection_reason then "rejection_reason=" + rejection_reason + "" else ""
    rejection_notes = if rejection_notes then "&rejection_notes=" + rejection_notes + "" else ""
    post = $.post(this.v1Url() + '/reject?' + rejection_reason + rejection_notes)
    post.done (response)  =>
      json = @parse(response)
      @updateStandardAttributes(json)
    post

  cancelOrder: ()  ->
    cancellation_reason = this.get('cancellation_reason')
    cancellation_notes = this.get('cancellation_notes') or false
    cancellation_reason = if cancellation_reason then "cancellation_reason=" + cancellation_reason + "" else ""
    cancellation_notes = if cancellation_notes then "&cancellation_notes=" + cancellation_notes + "" else ""
    post = $.post(this.v1Url() + '/cancel?' + cancellation_reason + cancellation_notes)
    post.done (response)  =>
      json = @parse(response)
      @updateStandardAttributes(json)
    post

#  ****************************
#  SHOULD BE CONTROLLER STUFF POSSIBLY
#  ****************************
  sendEmails: (emails, opts) ->
    if (opts?.shipment)
      return opts.shipment.emailAirbill(emails)

    args = { recipients: emails }
    if (@isPO())
      sender = @get('buyer')
      args.purchase_order = true
    else
      sender = @get('seller')

    if (sender.type == 'Client')
      if (sender.email_addresses.length)
        args.from_address = sender.email_addresses[0].address
      args.from_name = sender.name
    else
      args.from_address = sender.email_address
      args.from_name = sender.brokerage.name

    $.post("#{@v1Url()}/email", args)

  childOrders: (reload) ->
    delete @child_orders if reload
    if @child_orders
      @child_orders
    else
      @child_orders = new App.Collections.Orders _.collect @get('child_orders'), (cid) =>
        if @id isnt cid
          new App.Models.Order(id: cid)
      @child_orders

  fetchChildOrders: ->
    deferred = $.Deferred()
    if (@cannot_view_children())
      setTimeout(() ->
        deferred.resolve()
      , 0)
    else
      promiseArray = []
      childOrderCollection = @childOrders()
      childOrderCollection.forEach((childOrderModel) =>
        promiseArray.push(childOrderModel.fetch())
      )
      # Never do this again.  Ever.
      # Start using Q or ES6 Promises.
      $.when.apply($, promiseArray).then((results) =>
        @setHasOutstandingChildren() #hacky I know but getting the job done for now
        App.Vent.trigger('order:childOrdersFetched')
        deferred.resolve()
      , (error) ->
        deferred.reject(error)
      )
    return deferred

  fetchTicketCosts:  ->
    #$.get("#{@v1Url()}/ticket_costs")
    $.get("/api_direct/v9/orders/#{ @id }/get_ticket_costs?active_only=true")

  fetchShipments: (callbacks) ->
    fetchedCount        = 0
    fetchedAllShipments = $.Deferred()

    if @get('shipment_snapshot') and @get('shipment_snapshot').length

      ids = _.pluck @get("shipment_snapshot"), 'id'
      shipments = $.map ids, (id) -> new App.Models.Shipment {id}

      $.each shipments, (index, shipment) ->
        shipment.fetch success: -> (++fetchedCount is shipments.length) and fetchedAllShipments.resolve()

      $.when(fetchedAllShipments).then =>
        shipments = new App.Collections.ShipmentSnapshots(shipments)
        shipments.each (shipment, index) =>
          id             = shipment.get('id')
          snapshot       = @get("shipment_snapshot")[index]
          ids_match      = id is snapshot.id
          attrs_to_merge = ['name', 'company','street_address','extended_address', 'locality', 'region', 'postal_code', 'country_code', 'phone_number', 'email_address']
          $.each attrs_to_merge, (index, attr) -> shipment.set(attr, snapshot[attr]) if ids_match

        @set('shipment_snapshot_collection', shipments)
        callbacks.success()

  addNote: (note, callback) ->
    notes = [ note ]
    $.ajax({
      type: 'POST'
      url:  "#{@v1Url()}/notes"
      data: JSON.stringify({ notes })
      success: (data, status, xhr) =>
        #This is stupid please update order to use update method later
        @set('notes', ( data.notes or [] ))
        callback()
    })

  updateSoldTicketCosts: (tickets, order_item_id) ->
    # pass in tickets [{id:n, cost:n},{id:n, cost:n}]
    # OPTIONAL pass in order_time and it will filter only the order_item for response
    model = @
    oid   = order_item_id
    url   = @v1Url() + "/update_ticket_costs"
    data  = JSON.stringify({ tickets: tickets })
    done  = (result) ->
      model.order_item = _.filter(result.order_items, (oi) ->
        return oi.id == oid
      )[0]
    error = (result) ->
      console.error('ERROR', result)

    post = $.ajax({
      type: "post"
      url: url
      data: data
      dataType: 'json'
      contentType: 'application/json'
    }).done(done).error(error)
    return post

#  ****************************
#  Payments
#  ****************************

  onlyCardPayments: ->
    payments = @get('payments')
    invalidStates = ['refunded', 'voided']

    if payments
      to_pass = payments
      to_pass = payments.models if payments.models
      return _.filter to_pass, (payment) ->
        payment.get('type') == 'credit_card' && !_.contains(invalidStates, payment.get('state')) && payment.get('is_refund') == false

#  ****************************
#PATRON STUFF
#  ****************************

  patronType:      -> if @isPO() then 'seller' else 'buyer'



  patron:     -> @get(@patronType())


  userOrderState: () ->
    return @get("_#{ @userType() }State")

  userType: () ->
    if (@isPO())
      return 'buyer'
    else
      return 'seller'

  user: () ->
    return @get("_#{ @userType() }")
