App.Controllers.Base.BaseController = require('./base/base_controller.coffee')

module.exports = class App.Controllers.InventoryUploadsController extends App.Controllers.Base.BaseController

  _view = null
  _$container = null
  _collection = null
  _xhr = null

  routeIndex: ($container, encodedFilterParams) ->
    @displayIndex({
      $container
      shouldReset: true
      encodedFilterParams
    })

  displayIndex: (options) ->
    {
      $container
      shouldReset
      encodedFilterParams
    } = options
    shouldReset ||= false
    isGoingToFetch = !!encodedFilterParams
    _$container = $container

    if (_xhr)
      _xhr.abort()

    if (not _collection or shouldReset)
      _collection = new App.Collections.InventoryUploads()

    if (not _view or shouldReset)
      _view = new App.Views.Inventory.UploadsIndex({
        collection: _collection
        $container: _$container
      })

    if (isGoingToFetch)
      params = App.Utils.decodeFilterParams(encodedFilterParams)
      _view.reflectFilterParams(params)
      params = _view.getFilterParams()
      # View is bound to collection reset event.
      _xhr = _collection.fetch({
        data: params
        reset: true
      })
      App.Utils.requestKiller.add(_xhr)
    else
      _collection.reset()

  filterIndex: () ->
    encodedFilterParams = _view.getEncodedFilterParams()
    App.router.navigate("/uploads/filter/#{encodedFilterParams}") # Don't trigger.
    @displayIndex({
      $container: _$container
      encodedFilterParams
      shouldReset: false
    })

  upload: (fileModel, callback) ->
    fileModel.set('purpose', C.FilePurposes.InventoryUpload)
    fileModel.save(null, {
      success: (model, response, options) ->
        callback(null, response)
      error: (model, xhr, options) ->
        console.error('Upload Error', model, xhr, options)
        callback(new Error('Error uploading CSV'))
    })

  resetIndex: () ->
    App.router.navigate('/uploads') #dont trigger
    @displayIndex({
      $container: _$container
      shouldReset: true
    })
