App.Models.Base.BaseModel = require('../base/base_model.coffee')

# READ ONLY MODEL
parent = App.Models.Base.BaseModel.prototype
module.exports = App.Models.V2.EticketThumbnail = App.Models.Base.BaseModel.extend({

  defaults:
    aspectRatio: null
    _isChecked: false

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)
    json = attributes
    attrs = {}
    # STANDARDIZED ATTRIBUTES
    if (@getMapping() == C.Mappings.OrderEndpoint.EticketThumbnail)
      attrs.page = json.page
      attrs.url = json.url
      # COMPLETE - KEEP THIS COMMENT
    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {
      aspectRatio:  @get('aspectRatio')
      cid:          @cid
      isChecked:    @get('_isChecked')
      page:         @get('page')
      url:          @get('url')
    })
    return presented
  #///////////////////////////////////////////////////////////////////////////

  prefetchAndCalculateDimensions: () ->

    # If already set
    if (@get('aspectRatio'))
      return Q(true)
    else
      # https://github.com/kriskowal/q/wiki/API-Reference
      deferred = Q.defer()
      img = new Image()

      img.onload = () =>
        aspectRatio = img.width / img.height
        @set('aspectRatio', aspectRatio)
        @set('width', img.width)
        @set('height', img.height)
        deferred.resolve()

      img.onerror = (error) =>
        deferred.reject(new Error("Unable to load the eticket preview at #{ @get('url') }"))

      img.crossOrigin = 'anonymous'
      img.src = @get('url')

      return deferred.promise

}, {
# STATICS
  defaultMapping: C.Mappings.OrderEndpoint.EticketThumbnail
})