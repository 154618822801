App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V3.TicketGroup = require('../../models/v3/ticket_group_model.coffee')

TYPES = {
  PARKING: 1
  TICKETS: 2
  MY: 4
  ALL: 8
  SOLD: 16
  AVAILABLE: 32
  #WASTED: 64
}

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V3.TicketGroups = App.Collections.Base.BaseCollection.extend

  model: App.Models.V3.TicketGroup

  url: () ->
    if (@getMapping() == C.Mappings.MassIndexEndpoint.TicketGroup)
      return '/api_direct/v9/ticket_groups/mass_index'

    if (@getMapping() == C.Mappings.TicketGroupEndpoint.TicketGroup)
      params = {
        event_id: @eventId,
      }

      url = "/api_direct/v9/listings?"

      if (@type & TYPES['MY'])
        params.owned = true

      if (@type & TYPES['PARKING'])
        params.type = 'parking'

      if (@type & TYPES['TICKETS'])
        params.type = 'event'

      if (@quantity)
        params.quantity = @quantity

      if (@electronicFormatsOnly)
        params.ticket_format = 'Eticket'

      return url + $.param(params)

  parse: (response, options) ->
    if (response.ticket_groups)
      return @filterTicketGroups(response.ticket_groups)
    else
      return response

  initialize: (models, options = {}) ->
    {
      @electronicFormatsOnly
      @excludeOwned
      @quantity
    } = options
    @eventId = options.eventId
    @type = 0
    if (options.isParking)
      @type |= TYPES['PARKING']
    if (options.isTickets)
      @type |= TYPES['TICKETS']
    if (options.isMy)
      @type |= TYPES['MY']
    if (options.isAll)
      @type |= TYPES['ALL']
    if (options.isSold)
      @type |= TYPES['SOLD']
    if (options.isAvailable)
      @type |= TYPES['AVAILABLE']
    parent.initialize.call(@, models, options)

  filterTicketGroups: (ticketGroupsResponse) ->
    ticket_groups = ticketGroupsResponse
    if(@excludeOwned)
      ticket_groups = ticket_groups.filter((model) => model.office.id != SESSION.office_id)
    if(@quantity)
      ticket_groups = ticket_groups.filter((model) => model.splits.includes(@quantity))
    return ticket_groups

  fetch: (options) ->
    if (@getMapping() == C.Mappings.TicketGroupEndpoint.TicketGroup)
      options ||= {}
      realCallback = options.success || $.noop

      # Available comes from EVENTS endpoint.
      if (@type & TYPES['AVAILABLE'])
        options.success = (collection, response, options) =>
          # We could just do options.success = realCallback.  But this is a more clear.

          @giveTGsEventIds(collection)

          realCallback(collection, response, options)
        parent.fetch.call(@, options)

      if (@type & TYPES['SOLD'])
        # 1. Get SOLD Orders for peripheral data.
        #  1.2 Invert SOLD Orders into TicketGroups.
        # 2. Fetch ALL TicketGroups (including all unavailable types like sold & wasted).
        #  2.1 Filter out results leaving only WASTED & SOLD tickets.
        #  2.2 Attach the Order data from Step #1 to the corresponding SOLD TicketGroups.
        soldOrdersCallback = (soldTicketGroupsCollection, response, options) =>

          #@type |= TYPES['WASTED'] # Make sure parentFetch uses url for all unavailable types.
          options.success = (collection, response, options) =>

            @giveTGsEventIds(collection)

            #  2.1 Filter out results leaving only WASTED & SOLD tickets.
            @remove(
              @filter((ticketGroupModel) ->
                states = ticketGroupModel.get('ticket_states')
                if (_.has(states, 'wasted'))
                  ticketGroupModel.wasted = true # Mark as WASTED.
                  return false # Don't delete WASTED.
                else if (_.has(states, 'sold'))

                  # SANITY CHECK - COULD PROB BE DELETED DOWN THE ROAD.
                  correspondingOrderTG = soldTicketGroupsCollection.get(ticketGroupModel.id)
                  if (!correspondingOrderTG)
                    console.log("WARNING: A SOLD TicketGroup from the TicketGroup endpoint had
                                            no corresponding Order on the Order endpoint.  It will be deleted and not displayed.
                                            TicketGroupModel = #{ticketGroupModel}")
                    return true # Let's delete these weird orphaned tickets if they have no corresponding Order.

                  return false # Don't delete SOLD.
                else
                  return true # Delete all others
              )
            )

            #  2.2 Attach the Order data from Step #1 to the corresponding SOLD TicketGroups.
            soldTicketGroupsCollection.forEach((soldTicketGroup) =>
              correspondingTG = @get(soldTicketGroup.id)
              if (correspondingTG)
                correspondingTG.set('_order', soldTicketGroup.get('_order'))
              else
                console.log("WARNING: A SOLD TicketGroup from the Order endpoint had
                                    no corresponding TicketGroup on the TicketGroup endpoint.  It will be displayed anyway.
                                    TicketGroup from OrderModel = #{soldTicketGroup}")
                @add(soldTicketGroup)
            )
            realCallback(soldTicketGroupsCollection, response, options)

          # 2. Fetch ALL TicketGroups (including all unavailable types like sold & wasted).
          parent.fetch.call(@, options)

        # 1. Get SOLD Orders for peripheral data.
        @fetchSoldOrders(soldOrdersCallback)

    else
      parent.fetch.call(@, options)

  # Get All SOLD Orders
  fetchSoldOrders: (soldOrdersCallback) ->

    # We make a fresh collection for these SOLD Orders based TicketGroups.
    soldTicketGroupsCollection = new App.Collections.V3.TicketGroups()
    soldTicketGroupsCollection.eventId = @eventId
    soldTicketGroupsCollection.type = @type

    orders = new App.Collections.V2.Orders(null, {
      mapping: C.Mappings.OrderEndpoint.Order
      ordersOrPOs: 'orders'
    })
    orders.fetch({
      data: {
        orders: {
          sold: true
          event_id: @eventId
        }
      }
      success: (soldOrdersCollection, response, options) =>
        if (orders.length)
          orders.each((orderModel) =>

            orderItems = orderModel.get('_items')
            orderItems.each((orderItemModel) =>

              ticketGroupModel = orderItemModel.get('ticketGroup')
              addToSoldParking = (ticketGroupModel.get('_format') == 'parking') && (@type & TYPES['PARKING'])
              addToSoldTickets =  (ticketGroupModel.get('_format') != 'parking') && (@type & TYPES['TICKETS'])
              if (addToSoldParking || addToSoldTickets)
                # Inversion - pull TG from order, set order & orderItem on TG
                ticketGroupModel.set('_order', orderModel)
                ticketGroupModel.updateStandardAttributes()
                soldTicketGroupsCollection.add(ticketGroupModel)
            )
          )
        soldOrdersCallback(soldTicketGroupsCollection, response, options)
    })

  giveTGsEventIds: (collection) ->
    collection.forEach((ticketGroupModel) =>
      ticketGroupModel.set('eventId', @eventId)
      ticketGroupModel.set('_eventId', @eventId)
    )

  jsonGroupedByEventId: () ->
    groups = {}
    _.each(this.models, (model) ->
      eventId = model.get('event_id')
      group = groups[eventId] || []
      group.push(model.toPresenterJSON())
      groups[eventId] = group
    )
    for own eventId, eventArray of groups
      eventArray.sort((a, b) ->
        return b._cost - a._cost
      )
    return groups

  uniqueGroups: (options = {}) ->
    {
      groupBy
    } = options
    formattedGroups = _.map(@pluck(groupBy), (group) =>
      @formatGroupByKey(groupBy, group)
    )
    return _.compact(_.uniq(formattedGroups))

  formatGroupByKey: (groupBy, value) ->
    if (groupBy == 'event_occurs_at' || groupBy == 'ticket_group_created_at')
      return moment(value).startOf('day').format(C.DateFormats.Sortable)
    else
      return value

  eventsById: (options = {}, events = {}) ->
    {
      state
      format
    } = options
    _.each(@models, (model) ->
      eventId = model.get('event_id')
      events[eventId] ||= []
      output = model
      if (format == 'json')
        output = model.toPresenterJSON({
          state
        })
      events[eventId].push(output)
    )
    return events

  validateNewTicketGroupForPO: (newTicketGroup) ->
    existingSections = _.collect(@models, (ticketGroup) ->
      return ticketGroup.get('_section')
    )
    if (_.contains(existingSections, newTicketGroup.get('_section')))

      sameSectionAndRow = _.select(@models, (ticketGroup) ->
        if (_.isEqual(
          [ticketGroup.get('_section'), ticketGroup.get('_row')],
          [newTicketGroup.get('_section'), newTicketGroup.get('_row')]
        ))
          return ticketGroup
      )

      sectionAndRowSeats = _.flatten(_.collect(sameSectionAndRow, (ticketGroup) ->
        return ticketGroup.calculateSeatsArray()
      ))

      overlaps = _.select(newTicketGroup.calculateSeatsArray(), (seat) ->
        return _.contains(sectionAndRowSeats, seat)
      )

      if (overlaps.length)
        newTicketGroup.errors ||= []
        newTicketGroup.errors.push('Seats in this ticket group overlap with seats in the same event.')
        return false

    return true
