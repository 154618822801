App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.RateOption = App.Models.Base.V3BaseModel.extend

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Indirect.RateOptions.RateOption)
      # Important! Using service_type as id.
      attrs.id = attrs._serviceType = json.service_type || json.type

      attrs._id = json.id
      attrs._name = json.name
      attrs._price = json.price || json.rate
      attrs._provider = json.provider || 'FedEx'
      attrs._serviceType = json.service_type || json.type
      # NOT COMPLETE - WE DON'T NEED REMAINING FIELDS

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      singleLine: @singleLine()

    })
    return presented

  singleLine: () ->
    return "#{ @get('_name') }"