App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V3.Cart = require('../../models/v3/cart_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V3.Carts = App.Collections.Base.BaseCollection.extend

  url: '/api_direct/v9/carts'

  model: App.Models.V3.Cart

  parse: (json) ->
    return json.carts

  reset: (json, options) ->
    options = {
      action: 'updateCart'
      numInCart: 0
    }
    App.router.updateTopNav(options)
    carts = (json && json.carts) || null
    parent.reset.call(@, carts, options)

