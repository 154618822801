template = require('./sold_tickets_tab.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Order.SoldTicketsTab = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    {
      @canEditCosts
      @$container
      @overviewView
      @v3OrderModel
    } = options
    @render()

  render: () ->
    #orderItems.forEach(function (orderItem, index) {

    #_orderItems
    #console.log( @v3OrderModel.get('_soldOrderItems'))
    #soldItemsCollection = @v3OrderModel.get('_soldOrderItems')

    data = {
      @canEditCosts
      eventsInOrder: @overviewView.getEventsInOrder()
      order: @v3OrderModel.toPresenterJSON()
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )
    @loadingBox = new App.Utils.LoadingBox(@$container)
    @formErrors = new App.Utils.FormErrorManager(@$('.formErrors'))
    @delegateEvents()

  events:
    'click .consignmentOrderItemCheckbox':  'onConsignmentOrderItemCheckboxClick'
    'click .consignmentTicketCheckbox':     'onConsignmentTicketCheckboxClick'
    'click .saveCostEditsButton':           'onSaveCostEditsButtonClick'
    'click .cancelCostEditsButton':         'onCancelCostEditsButton'
    'keyup .bulkConsignmentCostInput':      'onBulkConsignmentCostInputKeyup'
    'click tr.soldTicketRow':               'onSoldTicketRowClick'

  onSoldTicketRowClick: (e) ->
    if (!@$(e.target).is('input') && !@$(e.target).is('.costCol'))
      @$(e.currentTarget).find('.consignmentTicketCheckbox').click()

  onSaveCostEditsButtonClick: (e) ->
    @loadingBox.startLoad()
    changedTickets = []
    @v3OrderModel.get('_orderItems')?.forEach((orderItem) =>
      orderItem.get('_soldInOrderItems')?.forEach((soldOrderItem) =>
        soldOrderItem.get('_soldTickets')?.forEach((ticket) =>
          $costInput = @$(".consignmentCostInput[data-ticket-id=#{ ticket.id }]")
          inputCost = App.Utils.roundCurrency(parseFloat($costInput.val()))
          if (inputCost != ticket.get('_cost'))
            changedTickets.push({
              id: ticket.id
              cost: inputCost
            })
        )
      )
    )
    if (changedTickets.length)
      $.ajax({
        type: 'POST'
        data: JSON.stringify({
          tickets: changedTickets
        })
        url: "/api_direct/v9/orders/#{ @v3OrderModel.id }/update_ticket_costs"
        success: (data, textStatus, jqXHR) =>
          window.location.reload(false);
          @formErrors.show({
            type: 'success'
            title: 'Costs successfully updated.'
          })
        error: (jqXHR, textStatus, errorThrown) =>
          @formErrors.show(errorThrown)
          @loadingBox.stopLoad()
      })
    else
      @loadingBox.startLoad()

  onCancelCostEditsButton: (e) ->
    soldOrderItem = @getSoldOrderItemFromCheckbox(e)
    @$(".consignmentOrderItemCheckbox[data-sold-order-item-cid=#{ soldOrderItem.cid }]").prop('checked', false)
    @resetAllTicketCosts(soldOrderItem)

  onBulkConsignmentCostInputKeyup: (e) ->
    soldOrderItem = @getSoldOrderItemFromCheckbox(e)
    val = @getBulkCostInput(soldOrderItem.cid)
    tickets = soldOrderItem.get('_soldTickets')
    tickets.forEach((ticketModel) =>
      $checkbox = @$(".consignmentTicketCheckbox[data-ticket-id=#{ ticketModel.id }]")
      if ($checkbox.is(':checked'))
        @setTicketCost(soldOrderItem, ticketModel.id)
      else
        @resetTicketCost(soldOrderItem, ticketModel.id)
    )

  getSoldOrderItemFromCheckbox: (e) ->
    data = @$(e.target).data()
    soldOrderItemCid = data.soldOrderItemCid
    orderItemId = data.orderItemId
    orderItem = @v3OrderModel.get('_orderItems').get(orderItemId)
    soldOrderItem = orderItem.get('_soldInOrderItems').get(soldOrderItemCid)

  onConsignmentOrderItemCheckboxClick: (e) ->
    soldOrderItem = @getSoldOrderItemFromCheckbox(e)
    checkbox = @$(e.target)
    isChecked = checkbox.is(':checked')
    if (isChecked)
      @setAllTicketCosts(soldOrderItem)
    else
      @resetAllTicketCosts(soldOrderItem)

  getBulkCostInput: (soldOrderItemCid) ->
    return App.Utils.roundCurrency(
      parseFloat(
        @$(".bulkConsignmentCostInput[data-sold-order-item-cid=#{ soldOrderItemCid }]").val()
      )
    ).toFixed(2)

  onConsignmentTicketCheckboxClick: (e) ->
    soldOrderItem = @getSoldOrderItemFromCheckbox(e)
    $checkbox = @$(e.target)
    data = $checkbox.data()
    ticketId = data.ticketId
    if ($checkbox.is(':checked'))
      @setTicketCost(soldOrderItem, ticketId)
    else
      @resetTicketCost(soldOrderItem, ticketId)

  setTicketCost: (soldOrderItem, ticketId) ->
    val = @getBulkCostInput(soldOrderItem.cid)
    if (!isNaN(val))
      $costInput = @$(".consignmentCostInput[data-ticket-id=#{ ticketId }]")
      $costInput.val(val)

  setAllTicketCosts: (soldOrderItem) ->
    tickets = soldOrderItem.get('_soldTickets')
    tickets.forEach((ticketModel) =>
      $ticketCheckbox = @$(".consignmentTicketCheckbox[data-ticket-id=#{ ticketModel.id }]")
      $ticketCheckbox.prop('checked', true)
      @setTicketCost(soldOrderItem, ticketModel.id)
    )

  resetTicketCost: (soldOrderItem, ticketId) ->
    $costInput = @$(".consignmentCostInput[data-ticket-id=#{ ticketId }]")
    ticket = soldOrderItem.get('_soldTickets').get(ticketId)
    $costInput.val(parseFloat(ticket.get('_cost')).toFixed(2))

  resetAllTicketCosts: (soldOrderItem) ->
    tickets = soldOrderItem.get('_soldTickets')
    tickets.forEach((ticketModel) =>
      $ticketCheckbox = @$(".consignmentTicketCheckbox[data-ticket-id=#{ ticketModel.id }]")
      $ticketCheckbox.prop('checked', false)
      @resetTicketCost(soldOrderItem, ticketModel.id)
    )



