template = require('./user_nav.ejs')
App.Views.TopNavBaseView = require('./topnav_base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.UserNav = App.Views.Base.BaseView.extend

  template: template

  tagName: 'li'

  className: 'pull-right dropdown'

  initialize: (options = {}) ->
    {
      @$container
    } = options
    @render()

  render: () ->
    @$container.append(
        @$el.html(
            @template()
        )
    )
    return @$el
