template = require('./filter.ejs')
require('../../utils/date_range_picker')

App.Views.Base.BaseView = require('./base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Base.Filter = App.Views.Base.BaseView.extend

  template: template

  initialize: (options) ->
    @$container = options.$container
    @filters = options.filters || {}
    @render()

  render: () ->
    @$container.html(
      @$el.html(
        @template({filters : @filters})
      )
    )
    @loadingButton = new App.Utils.LoadingButton(@$('#filter_button'))
    @startLoad()

    @$performerAutocomplete = App.Utils.performerAutocomplete(@$("#performer_name_input"), true)
    @$venueAutocomplete = App.Utils.venueAutocomplete(@$("#venue_name_input"), true)
    @$categoryAutocomplete = @$('#category_input').select2()
    App.Utils.dateRangePicker(@$('#occurs_at_range'), @onDateRangeChanged, this, C.DatePickerConfig.Future)

    if (SESSION.role.can(App.Access.Resources.Inventory.AllTickets.SearchByCity))
      # Just a blank initialization
      @$cityAutocomplete = @$('#city_input').select2({
        data: []
      })
      $.ajax({
        type: 'GET'
        url: "/dist/json/cities.json"
        dataType: 'json'
        success: (data, status, xhr) =>
          cities = _.map(data, (item) ->
            return {
              id: item.iata
              text: item.city + ", " + item.state + ", " + item.country
              lat: item.lat
              lon: item.lon
            }
          )
          currentVal = @$cityAutocomplete.select2('data')
          @$cityAutocomplete = @$('#city_input').select2({
            minimumInputLength: 2
            data: cities
            initSelection: (element, callback) ->
              console.log(element)
              callback({ id: element.val() })
          })
          if (currentVal)
            @$cityAutocomplete.select2('data', currentVal)
        error: (xhr, status, error) =>
          console.error('Could not load city database.')
      })

  #// VIEW EVENTS ////////////////////////////////////////////////////////////
  events: {
    'click #filter_button': 'onFilterButtonClick',
    'click #clear_button': 'onClearButtonClick',
    'click #affiliate_link_button': 'onGenerateLinkButtonClick',
  }

  getFilters: () ->
    filters = {}
    
    performer = @$("#performer_name_input").select2('data')
    if (performer)
      filters['performer_id'] = performer.id
      filters['performer_name'] = performer.text

    venue = @$('#venue_name_input').select2('data')
    if (venue)
      filters['venue_id'] = venue.id
      filters['venue_name'] = venue.text

    dateRangeFilter = @dateRangeFilter
    if (dateRangeFilter && dateRangeFilter.start && dateRangeFilter.end)
      filters['occurs_at.gte'] = dateRangeFilter.start
      filters['occurs_at.lte'] = dateRangeFilter.end

    if (SESSION.role.can(App.Access.Resources.Inventory.AllTickets.SearchByCity))
      city = @$('#city_input').select2('data')
      if (city)
        filters['airport_code'] = city.id
        filters['lat'] = city.lat
        filters['lon'] = city.lon
        filters['within'] = 30
        filters['__city_display_name'] = city.text

      category = @$('#category_input').select2('data')
      if (category)
        filters['category_id'] = category.id
        filters['category_text'] = category.text
        filters['category_tree'] = true

    return filters

  onFilterButtonClick: () ->
    @loadingButton.startLoad()
    @controller.filterEvents(@getFilters())

  onClearButtonClick: () ->
    filters = {}
    @reflectFilters(filters)
    @dateRangeFilter = null
    @$('#affiliate_link_button').hide()

  onGenerateLinkButtonClick: () ->
    legend = new App.Views.AffiliateLink(@filters)

  #///////////////////////////////////////////////////////////////////////////

  reflectFilters: (filters) ->
    if (filters['occurs_at.lte'] && filters['occurs_at.gte'])
      startDate = filters['occurs_at.gte']
      endDate = filters['occurs_at.lte']
      $el = @$('#occurs_at_range')
      $el[0].setDateRange(startDate, endDate)
    else
      App.Utils.dateRangePicker(@$('#occurs_at_range'), @onDateRangeChanged, @, C.DatePickerConfig.Future)

    if (@$cityAutocomplete && filters.airport_code && filters.lat && filters.lon)
      data = {
        id: filters.airport_code
        lat: filters.lat
        lon: filters.lon
        text: filters.__city_display_name
      }

      @$cityAutocomplete.select2('data', data)
    else
      if (@$cityAutocomplete)
        @$cityAutocomplete.select2('data', null)

    if (filters.venue_id && filters.venue_name)
      data = {
        id: filters.venue_id
        text: filters.venue_name
      }
      @$venueAutocomplete.select2('data', data)
    else
      @$venueAutocomplete.select2('data', null)

    if (filters.category_id && filters.category_text)
      data = {
        id: filters.category_id
        text: filters.category_text
      }
      @$categoryAutocomplete.select2('data', data)
    else
      @$categoryAutocomplete.select2('data', null)

    if (filters.performer_id && filters.performer_name)
      data = {
        id: filters.performer_id
        text: filters.performer_name
      }
      @$performerAutocomplete.select2('data', data)
    else
      @$performerAutocomplete.select2('data', null)

  onDateRangeChanged: (startDate, endDate) ->
    if(startDate && endDate)
      if (_.isString(startDate))
        @dateRangeFilter = {
          start: startDate
          end: endDate
        }
      else
        # Event stuff should not be in users local time.
        @dateRangeFilter = {
          start: startDate.utc().startOf('day').toISOString()
          end: endDate.utc().endOf('day').toISOString()
        }
    else
      @dateRangeFilter = null

  doneLoading: () ->
    @loadingButton.stopLoad()