# SINGLETON
module.exports = orderShowController = {}

(() ->

  @xhr        = {}
  @views      = {}
  @$container = {}
  @loadingBox = {}

  @emailInvoicePromise = (v3OrderModel, emailAddresses) ->
    deferred = Q.defer()
    sender = v3OrderModel.you().toPresenterJSON()
    data = {
      recipients: emailAddresses
      from_address: sender.emailAddress
      from_name: sender.name
    }
    if (v3OrderModel.isPO())
      data.purchase_order = true
    $.ajax({
      type: 'POST'
      url: "/api_direct/v9/orders/#{ v3OrderModel.id }/email"
      data: JSON.stringify(data)
      success: (data, status, xhr) =>
        deferred.resolve(data)
      error: (xhr, status, error) =>
        deferred.reject(xhr)
    })
    return deferred.promise

  @clearView = (namespace) ->
    @views[namespace]?.close?()
    @loadingBox[namespace]?.stopLoad()

  @resetController = ->
    @views      = {}
    @$container = {}
    @loadingBox = {}

  @startLoad = (namespace) ->
    @loadingBox[namespace] ||= new App.Utils.LoadingBox(@$container[namespace])
    @loadingBox[namespace].startLoad()

  @routeOrderShow = ($container, id) ->
    if (@baseView)
      @baseView.close()

    @resetController()
    @v3OrderModel = new App.Models.V3.Order({
      id
    }, {
      mapping: C.Mappings.Direct.Order.Order
    })
    @currentOrderItemPage = 1
    @fetch(() =>
      #placerBrokerageId = @v3OrderModel.get('_placer')?.get('brokerage')?.id
      createdByBrokerageId = @v3OrderModel.get('_createdBy')?.brokerage_id
      @overviewView = new App.Views.Order.Overview({
        $container
        @v3OrderModel
      })
    )

  @renderAddNote = (options) ->
    @clearView 'newNote'
    @views.newNote = new App.Views.Orders.NewNoteModal { orderModel: @v2OrderModel }

  @renderDeliveries = () ->
    @overviewView.refresh()

  @fetch = (callback) ->
    loadingOverlay = new App.Views.Shared.LoadingView()
    loadingOverlay.render()
    v3Promise = @v3OrderModel.fetchWithOrderItemsPromise()
    # https://github.com/kriskowal/q/wiki/Coming-from-jQuery
    Q.all([v3Promise])
    .spread (v3Model) ->
      loadingOverlay.remove()
      callback?()
    .fail (error) ->
      loadingOverlay.remove()
      console.error(error.stack)
      errorModal = new App.Views.Shared.BasicModal({
        header: 'Error fetching order.'
        message: error.message
      })
    .done()

  @fetchShipmentTypes  = (options) ->
    deferred = Q.defer()
    isOfflineAllowed = true
    buyer = @v2OrderModel.get('_buyer')
    buyerType = buyer.get('_type').toLowerCase()
    seller = @v2OrderModel.get('_seller')
    sellerType = seller.get('_type').toLowerCase()
    if (seller.id == SESSION.office_id && buyerType == C.Office)
      isOfflineAllowed = false
    else if (buyer.id == SESSION.office_id && sellerType == C.Office)
      isOfflineAllowed = false

    @shipmentTypes = new App.Collections.V3.ShipmentTypes(null, {
      isOfflineAllowed
      isTBDAllowed: false
      mapping: C.Mappings.Direct.ShipmentTypes.ShipmentTypes
    })
    @shipmentTypes.fetchPromise()
    .then (collection) =>
      @shipmentTypes.sort()
      deferred.resolve(@shipmentTypes)
    .done()
    return deferred.promise

  @fetchDeliveries = (options) ->
    {
      ignoreFetch
    } = options
    @shipmentsCollection = @v2OrderModel.get('shipments')
    if (ignoreFetch)
      return Q(true)
    else
      return Q(@shipmentsCollection.reset().fetch())

  @fetchTicketCosts = (callback) ->
    @xhr.orderItemTab?.abort?()
    @xhr.orderItemTab = @v2OrderModel.fetchTicketCosts().done (ticket_costs) =>
      @xhr.orderItemTab = null
      callback(ticket_costs?.order_items)

).call(orderShowController)
