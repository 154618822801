App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V3.CreditCard = require('../../models/v3/credit_card_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V3.CreditCards = App.Collections.Base.BaseCollection.extend

  model: App.Models.V3.CreditCard

  url: () ->
    if (@cardOwner.type == C.Client)
      return "/api_direct/v9/clients/#{ @cardOwner.id }/credit_cards"
    else if (@cardOwner.type == C.Office)
      #if (@cardOwner.id == SESSION.office_id)
      #  return "/api_direct/v9/offices/#{ SESSION.office_id }/credit_cards"
      #else
      return "/api_direct/v9/offices/#{ SESSION.office_id }/credit_cards?stored_for_id=#{ @cardOwner.id }"
    else
      console.error("CreditCards collection not given a v3Patron as @cardOwner (no @cardOwner.type).")

  initialize: (attributes, options) ->
    {
      @cardOwner
    } = options
    parent.initialize.call(@, attributes, options)

  parse: (response, options) ->
    if (response.credit_cards)
      return response.credit_cards
    else
      return response



