template = require('./patron_autocomplete.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Clients.PatronAutocomplete = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    {
      @$container
      @clientsOnly
      @patron
    } = options
    @render()

  # VIEW EVENTS ////////////////////////////////////////////////////////////////
  events:
    "click .addClientButton": "onAddClientButtonClick"

  onAddClientButtonClick: (e) ->
    @form = new App.Views.Clients.Show({
      $container: @$clientFormContainer
      isBuySellWizard: true
      onSave: _.bind(@onNewClientSaved, @)
    })
  #/////////////////////////////////////////////////////////////////////////////

  onAutocompleteSelect: (e, ui) ->
    type = C.Office
    mapping = C.Mappings.Direct.Offices.Office
    if (ui.item.category == 'Client')
      type = C.Client
      mapping = C.Mappings.Direct.Clients.Client
    @patron = new App.Models.V3.Patron({
      id: ui.item.id
    }, {
      type
      mapping
    })
    @trigger(C.Events.PatronAutocompleteChange, @patron)

  render: () ->
    data = {
      name: @patron?.toPresenterJSON().prettyName || ''
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )
    types = ['clients', 'offices']
    if (@clientsOnly)
      types = ['clients']
    App.Utils.autocomplete({
      selector: '.patronAutocomplete'
      select:   _.bind(@onAutocompleteSelect, @)
      types
    })

    @$clientFormContainer = @$('.clientFormContainer')
    @delegateEvents()
    return @$el

  onNewClientSaved: (client) ->
    if (client)
      @patron = client.toPatron()
      @trigger(C.Events.PatronAutocompleteChange, @patron)
    @render()
    if (client)
      @$('.patronAutocomplete').val(client.get('_name'))
