App.Models.Base.BaseModel = require('../base/base_model.coffee')

parent = App.Models.Base.BaseModel.prototype
module.exports = App.Models.V2.Venue = App.Models.Base.BaseModel.extend({

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: () ->
    json = @attributes
    attrs = {}

    # COMMON ATTRIBUTES
    attrs._name = json.name

    # STANDARDIZED ATTRIBUTES
    if (@getMapping() == C.Mappings.TicketGroupEndpoint.Venue)
      attrs._id = json.id
      attrs._location = json.location
      attrs._url = json.url

    if (@getMapping() == C.Mappings.OrderEndpoint.Venue)
      attrs._address = new App.Models.V2.Address(json.address, {
        mapping: C.Mappings.OrderEndpoint.Address
      })

    if (@getMapping() == C.Mappings.EventEndpoint.Venue)
      attrs._id = json.id
      attrs._location = json.location
      attrs._url = json.url

    if (@getMapping() == C.Mappings.MassIndexEndpoint.Venue)
      addressAttrs = _.pick(json,
        'venue_address_company'
        'venue_address_country_code'
        'venue_address_extended_address'
        'venue_address_id'
        'venue_address_label'
        'venue_address_locality'
        'venue_address_name'
        'venue_address_postal_code'
        'venue_address_region'
        'venue_address_street_address'
      )
      attrs._address = new App.Models.V2.Address(addressAttrs, {
        mapping: C.Mappings.MassIndexEndpoint.Address
      })
      attrs._id = json.venue_id
      attrs._name = json.venue_name
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      address:  @get('_address')?.toPresenterJSON()
      id:       @get('_id')
      name:     @get('_name')
      location: @get('_location')
      url:      @get('_url')

    })
    return presented
  #///////////////////////////////////////////////////////////////////////////

}, {
  # STATICS
  defaultMapping: C.Mappings.TicketGroupEndpoint.Venue
})
