App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V2.Address = require('../../models/v2/address_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V2.Addresses = App.Collections.Base.BaseCollection.extend

  model: App.Models.V2.Address

  url:   -> "/clients/#{@holder_id}/addresses"

  initialize: (models, options = {}) ->
    {
      @holder_id
    } = options
    parent.initialize.call(@, models, options)