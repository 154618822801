template = require('./ticket_holds_list.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.TicketHolds.TicketHoldsList = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    parent.initialize.call(@, options)
    {
      @$container
      @collection
      @isTaken
    } = options
    @render()
    @startLoad()
    @collection.fetchPromise()
    .then () =>
      @render()
      @stopLoad()
    .fail (error) =>
      @stopLoad()
      console.error(error.stack)
      errorModal = new App.Views.Shared.BasicModal({
        header: 'Error fetching held tickets.'
        message: error.message
      })
    .done()

  render: () ->
    data = {
      holds: @collection.toPresenterJSON()
      @isTaken
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )
    @delegateEvents()
    @tableLoader = new App.Utils.LoadingBox(@$('#held_tickets_table'))
    @collection.forEach (model) =>
      if (model.get('notes'))
        holdId = model.get('id')
        $el = @$("tr[data-hold-id=#{ holdId }] .popover_notes")
        $el.popover({
          placement: 'left'
          container: $el
          offset: 10
          title: 'Notes'
          content: model.get('notes')
          trigger: 'hover'
          html: true
        })
    @initializeContextMenu()
    return @$el

  # VIEW EVENTS //////////////////////////////////////////////////////////////
  events:
    # CONTEXT MENU ITEMS
    'click .dropdown-menu .edit': 'onContextEditClick'
    'click .dropdown-menu .sell': 'onContextSellClick'
    'click .dropdown-menu .take': 'onContextTakeClick'
    'click .dropdown-menu .release': 'onContextReleaseClick'

  getRowData: (e) ->
    $tr = @$(e.target).closest('tr.ticket-hold')
    trData = $tr.data()
    holdId = trData.holdId
    ticketGroupId = trData.ticketGroupId
    holdModel = @collection.get(holdId)
    data = {
      id: ticketGroupId
      model: holdModel
    }
    return data

  onContextEditClick: (e) ->
    data = @getRowData(e)
    editView = new App.Views.TicketHolds.Edit(data)
    editView.on "ticket-hold:updated", () =>
      @render()
    editView.render()

  onContextSellClick: (e) ->
    data = @getRowData(e)
    options = {
      heldForType:    data.model.get('_heldForType')
      heldForId:      data.model.get('_heldForId')
      heldPrice:      data.model.get('_price')
      heldSeats:      data.model.get('_seats')
      quantity:       data.model.get('_quantity')
      ticketGroupId:  data.id
    }
    App.Controllers.buySellController.sellHoldOrTake(options)

  onContextTakeClick: (e) ->
    data = @getRowData(e)

    holdModel = data.model
    ticketGroupId = data.id
    ticketGroupModel = new App.Models.V3.TicketGroup({
      id: ticketGroupId
    })

    takeData = {
      lowSeat: holdModel.get('seats')[holdModel.get('quantity') - 1 ]
      quantity: holdModel.get('quantity')
      heldForId: holdModel.get('held_for_id')
      heldForType: holdModel.get('held_for_type')
      holdUntil: moment(new Date()).utc().format('YYYY-MM-DD HH:mm:ss UTC').toString()
      price: holdModel.get('price')
    }
    ticketGroupModel.fetchPromise()
    .then(() =>
      ticketGroupModel.takePromise(takeData)
      .then((ticketGroup, response, data) =>
        @collection.remove(holdModel)
        @render()
        @tableLoader.stopLoad()
      )
    )


  onContextReleaseClick: (e, callback) ->
    @tableLoader.startLoad()
    data = @getRowData(e)
    holdModel = data.model
    opts = {
      ticketGroupId: data.id
    }
    @listenTo(App.Vent, 'ticket-hold:released', _.bind(() =>
      if (callback)
        callback(data)
      else
        @collection.remove(holdModel)
        @render()
        @tableLoader.stopLoad()
    , @))
    holdModel.release(opts)

  startLoad: () ->
    @tableLoader.startLoad()

  stopLoad: () ->
    @tableLoader.stopLoad()

  initializeContextMenu: () ->
    @$el.on 'click', 'button.dropdown-toggle', (e) =>
      $tr = @$(e.target).closest('tr.ticket-hold')
      data = $tr.data()
      hasMenu = data.hasMenu
      if (!hasMenu)
        $tr.data('has-menu', 1)
        html = ['<ul class="dropdown-menu" style="left:0">']
        if (@isTaken)
          html.push('<li><a class="sell">Sell</a></li>')
          html.push('<li><a class="release">Release</a></li>')
        else
          html.push('<li><a class="edit">Edit</a></li>')
          html.push('<li><a class="sell">Sell</a></li>')
          html.push('<li><a class="take">Take</a></li>')
          html.push('<li><a class="release">Release</a></li>')
        html.push('</ul>')
        $tr.find('.btn-group').append(html.join(''))
