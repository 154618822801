template = require('./eticket_management.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

SYNC_TIMEOUT = 300

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.TicketGroups.EticketManagment = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    {
      @$container
      @v3TicketGroupModel
      @v3EventModel
    } = options
    @ticketType = 'ticketmaster'
    @seatMappings = {}
    @syncOtherElsPositionTimeout = null
    @syncOtherElsZoomTimeout = null
    @render()

  resetFileInput: () ->
    $inputEl = @$('#fileToUpload')
    $inputEl.val('')
    if (!@uploader)
      fileChosenCallback = (error, fileModel) =>
        if (error)
          @formErrors.show({
            title: 'File not allowed.'
            errors: error
          })
          @loadingBox.stopLoad()
          @resetFileInput()
        else
          @loadingBox.startLoad()
          App.Controllers.eticketManagement.uploadEticket(fileModel)
          .then (model) =>
            @formErrors.show({
              type: 'success'
              title: 'Etickets PDF conversion successful.'
              message: 'Please configure your etickets below.'
            })
            @loadingBox.stopLoad()
            @resetFileInput()
            App.Controllers.eticketManagement.onUploadComplete(fileModel)
          .fail (error) =>
            @formErrors.show({
              title: 'Error converting PDF.'
              errors: error
            })
            @loadingBox.stopLoad()
            @resetFileInput()
          .done()
      @uploader = new App.Utils.FileUploader({
        $fileInputEl: $inputEl
        callback: fileChosenCallback
        $dragDropEl: @$el
        fileTypeFilter: C.FileFilters.PDF
        fileSizeLimit: 30000000 #~30MB
      })

  render: () ->
    backToEventsUrl = App.router.generateBackToEventsUrl()
    if (!backToEventsUrl)
      backToEventsUrl = "/tickets/events/my/#{ @v3EventModel.id }/my"

    data = {
      backToEventsUrl
      ticketGroup: @v3TicketGroupModel.toPresenterJSON()
      event: @v3EventModel.toPresenterJSON()
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )

    $content = @$('#filter_content')
    $collapseButton = @$('#filter_collapse_button')
    options = {
      $content
      $collapseButton
    }
    @collapsibleFilters = new App.Utils.CollapsibleWidget(options)

    @loadingBox = new App.Utils.LoadingBox(@$('#filter_content'))
    @loadingBoxBottom = new App.Utils.LoadingBox(@$('#eticketsContent'))
    @formErrors = new App.Utils.FormErrorManager($('#formErrors'))
    @resetFileInput()

  # VIEW EVENTS //////////////////////////////////////////////////////////////
  events:
    '.click #eticketUploadArea': 'onEticketUploadAreaClick'
    'click #autoSeatLowHighButton': 'onAutoSeatLowHighButtonClick'
    'click #autoSeatHighLowButton': 'onAutoSeatHighLowButtonClick'
    'click #saveButton': 'onSaveButtonClick'
    'click #deleteAllEticketsButton': 'onDeleteAllEticketsButtonClick'
    'click #downloadEticketsButton' : 'onDownloadEticketsButtonClick'

  onDownloadEticketsButtonClick: (e) ->
    @loadingButton = new App.Utils.LoadingButton(@$('#downloadEticketsButton'))
    @loadingButton.startLoad()

    name = @v3EventModel.get('_name')
    date = App.Utils.makeTimestampHumanForEvent(@v3EventModel.get('_occursAt'))
    seats = App.Utils.seatsToSeatRange(@v3TicketGroupModel.get('_seats'))
    filename = encodeURIComponent("#{ name } #{ date } #{ seats }")

    tgId = encodeURIComponent(@v3TicketGroupModel.id)

    url = "/api_direct/v9/ticket_groups/#{ tgId }/retrieve_etickets.pdf?filename=#{ filename }"
    win = window.open(url, '_blank')
    win.document.body.innerHTML = "<h3>Please wait while your etickets are fetched...</h3>This window will close automatically."
    setTimeout(() =>
      @loadingButton.stopLoad()
    , 5000)

  onEticketUploadAreaClick: (e) ->
    @$('#fileToUpload').click()
    e.stopPropagation()
    e.preventDefault()

  onAutoSeatLowHighButtonClick: (e) ->
   @autoSeatSelect()

  onAutoSeatHighLowButtonClick: (e) ->
    asc = true
    @autoSeatSelect(asc)

  onSaveButtonClick: (e) ->
    @loadingBoxBottom.startLoad()
    @loadingButton = new App.Utils.LoadingButton(@$('#saveButton'))
    @loadingButton.startLoad()
    attrs = []
    $selectedSeatButtons = @$allSeatButtons.filter(() ->
      $(@).data('isOn') == 1
    )

    if (!$selectedSeatButtons.length)
      @formErrors.show({
        title: 'You must assign seat numbers.'
        message: 'Please select the seat numbers that correspond to each eticket.'
      })
      @loadingButton.stopLoad()
      return null

    $selectedSeatButtons.each((index, el) =>
      $button = $(el)
      {
        eticketId
        seat
        ticketId
        isOn
      } = $button.data()
      barcode = $button.closest('tr').find('.barcodeInput').val()
      attrs.push({
        _ticketId: ticketId
        _id: eticketId
        _barcode: barcode
      })
    )
    App.Controllers.eticketManagement.saveEtickets(attrs)
    .then (collection) =>
      @formErrors.show({
        type: 'success'
        title: 'Etickets saved successfully.'
        message: ""
      })
      @loadingBoxBottom.stopLoad()
      @loadingButton.stopLoad()
      $('html,body').scrollTop(0);
    .fail (error) =>
      @formErrors.show({
        title: 'Error saving etickets.'
        errors: error
      })
      @loadingBoxBottom.stopLoad()
      @loadingButton.stopLoad()
      $('html,body').scrollTop(0);
    .done()

  onDeleteAllEticketsButtonClick: () ->
    @loadingBoxBottom.startLoad()
    @loadingButton = new App.Utils.LoadingButton(@$('#deleteAllEticketsButton'))
    @loadingButton.startLoad()
    App.Controllers.eticketManagement.deleteAllEtickets()
  #///////////////////////////////////////////////////////////////////////////

  zoomSeat: (e, zoomOut = false, settings = {}) ->
    selector = '.seatPreview'
    previews = @seatPreviews
    @zoom(e, selector, previews, zoomOut, settings)

  zoomBarcode: (e, zoomOut = false, settings = {}) ->
    selector = '.barcodePreview'
    previews = @barcodePreviews
    @zoom(e, selector, previews, zoomOut, settings)

  zoom: (e, selector, previews, zoomOut = false, settings = {}) ->

    $elsToSync = @$(selector)
    $el = $(e.currentTarget).parent().siblings(selector)

    imgHeight = $el.height()
    imgWidth = $el.width()

    halfContainerWidth = @HALF_CONTAINER[selector].width
    halfContainerHeight = @HALF_CONTAINER[selector].height

    marginTop = $el.css('margin-top')
    marginLeft = $el.css('margin-left')
    x = Number(marginLeft.replace(/[^0-9-]/g, ''))
    y = Number(marginTop.replace(/[^0-9-]/g, ''))

    centerX = x - halfContainerWidth - (settings.extraX || 0)
    centerY = y - halfContainerHeight - (settings.extraY || 0)

    zoomRatio = settings.zoomRatio || (3 / 2)
    if (zoomOut)
      zoomRatio = 1 / zoomRatio

    # ~~ = Math.floor
    imgHeight = ~~(imgHeight * zoomRatio)
    imgWidth = ~~(imgWidth * zoomRatio)
    centerX = ~~(centerX * zoomRatio)
    centerY = ~~(centerY * zoomRatio)
    x = centerX + halfContainerWidth
    y = centerY + halfContainerHeight

    $el.css({
      width: "#{ imgWidth }px"
      height: "#{ imgHeight }px"
      marginLeft: "#{ x }px"
      marginTop: "#{ y }px"
    })

    clearTimeout(@syncOtherElsZoomTimeout)
    @syncOtherElsZoomTimeout = setTimeout(() ->
      $elsToSync.css({
        width: "#{ imgWidth }px"
        height: "#{ imgHeight }px"
        marginLeft: "#{ x }px"
        marginTop: "#{ y }px"
      })
    , SYNC_TIMEOUT)

    previews.setMarginPosition(x, y)

  createSyncedPreviewWindows: () ->
    $elsToSync = @$container.find('.barcodePreview')
    @barcodePreviews = @makeDraggablePreviews({
      $elsToSync
    })
    $elsToSync = @$container.find('.seatPreview')
    @seatPreviews = @makeDraggablePreviews({
      $elsToSync
    })

  makeDraggablePreviews: (options) ->
    {
      $elsToSync
    } = options

    Preview = ($elsToSync) ->
      @posX = 0
      @posY = 0

      isDown = false
      lastX = null
      lastY = null

      $elsToSync.css({
        marginLeft: "#{ @posX }px"
        marginTop: "#{ @posY }px"
      })

      $elsToSync.on('mousedown', () =>
        isDown = true
      )
      $elsToSync.on('mouseup', () =>
        isDown = false
        lastX = null
        lastY = null
      )
      $elsToSync.on('mouseout', () =>
        isDown = false
        lastX = null
        lastY = null
      )
      onMouseMove = (e) =>
        if (isDown)
          if (lastX && lastY)
            deltaX = lastX - e.clientX
            deltaY = lastY - e.clientY
            @posX -= deltaX
            @posY -= deltaY

            $(e.currentTarget).css({
              marginLeft: "#{ @posX }px"
              marginTop: "#{ @posY }px"
            })

            clearTimeout(@syncOtherElsPositionTimeout)
            @syncOtherElsPositionTimeout = setTimeout(() =>
              $elsToSync.css({
                marginLeft: "#{ @posX }px"
                marginTop: "#{ @posY }px"
              })
            , SYNC_TIMEOUT)

          lastX = e.clientX
          lastY = e.clientY

      # Throttle limits execution to once every 100ms = performance.
      $elsToSync.on('mousemove', _.throttle(onMouseMove, 10))

      return @

    Preview.prototype.setMarginPosition = (x, y) ->
      @posX = x
      @posY = y

    preview = new Preview($elsToSync)
    return preview

  autoSeatSelect: (asc) ->
    tickets = @v3TicketGroupModel.get('_ticketList')?.sort()
    seatsInOrder = tickets.pluck('_seat')
    if (asc)
      seatsInOrder = seatsInOrder.reverse()

    targetSeatNumIndex = 0
    targetSeatNum = seatsInOrder[targetSeatNumIndex]
    targetRow = 0

    for seats, rowNumber in @seatButtons
      for own seatNum, $seatButton of seats
        seatNum = +seatNum
        if (rowNumber == targetRow && seatNum == targetSeatNum)
          $seatButton.addClass('btn-success')
          $seatButton.addClass('active')
          $seatButton.data('isOn', 1)
          targetRow++
          targetSeatNum = seatsInOrder[++targetSeatNumIndex]
        else
          $seatButton.removeClass('btn-success')
          $seatButton.removeClass('active')
          $seatButton.data('isOn', 0)

  onSeatButtonClick: (e) ->
    $button = $(e.currentTarget)
    data = $button.data()
    {
      eticketId
      seat
      ticketId
      rowNumber
      isOn
    } = data
    if (isOn)
      # Unselect
      $button.removeClass('btn-success')
      $button.removeClass('active')
      $button.data('isOn', 0)
    else
      @unselectRow(rowNumber)
      @unselectSeatNumber(seat)
      $button.addClass('btn-success')
      $button.addClass('active')
      $button.data('isOn', 1)

  unselectRow: (targetRow) ->
    seats = @seatButtons[targetRow]
    for own seatNumber, $seatButton of seats
      $seatButton.removeClass('btn-success')
      $seatButton.removeClass('active')
      $seatButton.data('isOn', 0)

  unselectSeatNumber: (targetSeatNumber) ->
    for seats, rowNumber in @seatButtons
      for own seatNum, $seatButton of seats
        seatNum = +seatNum
        if (seatNum == targetSeatNumber)
          $seatButton.removeClass('btn-success')
          $seatButton.removeClass('active')
          $seatButton.data('isOn', 0)

  cacheSeatButtons: () ->
    @$allSeatButtons = @$('.seatButton')
    @seatButtons = []
    $rows = @$('#eticketsContainer tr.eticketRow')
    rowIndex = 0
    for row in $rows
      $row = $(row)
      @seatButtons[rowIndex] = {}
      $seatButtons = $row.find('button.seatButton')
      for button in $seatButtons
        $button = $(button)
        data = $button.data()
        seat = data.seat
        @seatButtons[rowIndex][seat] = $button
      rowIndex++

    @HALF_CONTAINER = {
      '.seatPreview': {
        width: ~~($('.seatPreviewContainer').width() / 2)
        height: ~~($('.seatPreviewContainer').height() / 2)
      }
      '.barcodePreview': {
        width: ~~($('.barcodePreviewContainer').width() / 2)
        height: ~~($('.barcodePreviewContainer').height() / 2)
      }
    }

    $('.barcodeInput').eq(0).focus()