module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = "<%\nif (orderItems.length == 1) {\n  var orderItem = orderItems[0];\n  var ticketsText = 'tickets';\n  if (orderItem.quantity == 1) {\n    ticketsText = 'ticket';\n  }\n%>\n  <%= orderItem.event.name %>\n  &nbsp;-&nbsp;\n  <%= orderItem.event.venue.name %>\n  (<%= orderItem.event.venue.address.cityState %>)\n  &nbsp;-&nbsp;\n  <%= App.Utils.makeTimestampHumanForEvent(orderItem.event.occursAtRaw, C.DateFormats.Long) %>\n  &nbsp;-&nbsp;\n  <%= orderItem.quantity %>\n  <%= ticketsText %> in Section\n  <%= orderItem.section %>\n  Row\n  <%= orderItem.row %>\n  Seats\n  <%= App.Utils.seatsToSeatRange(orderItem.seats) %>\n  &nbsp;-&nbsp;\n  <%= App.Utils.valueToCurrency(orderItem.price) %>\n<%\n} else {\n%>\n  <span style=\"font-style: italic;\">This Order has more than 1 set of tickets, please view the Order for full details.</span>\n<%\n}\n%>", __filename = "js/views/orders/order_table_row_details.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            if (orderItems.length == 1) {
                var orderItem = orderItems[0];
                var ticketsText = "tickets";
                if (orderItem.quantity == 1) {
                    ticketsText = "ticket";
                }
                __line = 8;
                __append("\n  ");
                __line = 9;
                __append(escapeFn(orderItem.event.name));
                __append("\n  &nbsp;-&nbsp;\n  ");
                __line = 11;
                __append(escapeFn(orderItem.event.venue.name));
                __append("\n  (");
                __line = 12;
                __append(escapeFn(orderItem.event.venue.address.cityState));
                __append(")\n  &nbsp;-&nbsp;\n  ");
                __line = 14;
                __append(escapeFn(App.Utils.makeTimestampHumanForEvent(orderItem.event.occursAtRaw, C.DateFormats.Long)));
                __append("\n  &nbsp;-&nbsp;\n  ");
                __line = 16;
                __append(escapeFn(orderItem.quantity));
                __append("\n  ");
                __line = 17;
                __append(escapeFn(ticketsText));
                __append(" in Section\n  ");
                __line = 18;
                __append(escapeFn(orderItem.section));
                __append("\n  Row\n  ");
                __line = 20;
                __append(escapeFn(orderItem.row));
                __append("\n  Seats\n  ");
                __line = 22;
                __append(escapeFn(App.Utils.seatsToSeatRange(orderItem.seats)));
                __append("\n  &nbsp;-&nbsp;\n  ");
                __line = 24;
                __append(escapeFn(App.Utils.valueToCurrency(orderItem.price)));
                __append("\n");
                __line = 25;
            } else {
                __line = 27;
                __append('\n  <span style="font-style: italic;">This Order has more than 1 set of tickets, please view the Order for full details.</span>\n');
                __line = 29;
            }
            __line = 31;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}