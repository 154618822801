module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div class="flextainer">\n\n  <div class="flex1 flextainer seatingChartContainer" style="height: 600px">\n    <!-- InteractiveSeatingChartView goes here. -->\n  </div>\n\n  <div class="flex1" style="max-height: 600px; overflow: auto;">\n    <table class="table table-hover">\n      <thead>\n      <tr>\n        <th>Qty.</th>\n        <th>Section</th>\n        <th>Row</th>\n        <th>Seats</th>\n        <th>Wholesale</th>\n        <th>Retail</th>\n        <% if (SESSION.role.can(App.Access.Resources.Inventory.AllTickets.ViewDetails.Broker)) { %>\n        <th>Broker</th>\n        <% } %>\n        <th></th>\n        <th></th>\n      </tr>\n      </thead>\n      <tbody>\n\n      <% ticketGroups.forEach(function(ticketGroup, index) { %>\n      <tr class="ticketGroup" data-ticket-group-id="<%= ticketGroup.id %>">\n        <td><%= ticketGroup.quantitySeparated %></td>\n        <td><%= ticketGroup.section %></td>\n        <td><%= ticketGroup.row %></td>\n        <td><%= ticketGroup.availableSeatsString %></td>\n        <td><%= App.Utils.valueToCurrency(ticketGroup.wholesalePrice) %></td>\n        <td><%= App.Utils.valueToCurrency(ticketGroup.retailPrice) %></td>\n        <% if (SESSION.role.can(App.Access.Resources.Inventory.AllTickets.ViewDetails.Broker)) { %>\n        <td>\n          <a style="cursor: pointer;" class="broker" data-office-id="<%= ticketGroup.office.id %>" data-broker-id="<%= ticketGroup.office.brokerage.id %>" title="<%= ticketGroup.office.brokerage.abbreviation %>">\n            <%= ticketGroup.office.brokerage.abbreviation %>\n          </a>\n        </td>\n        <% } %>\n        <td style="white-space: nowrap;"><%- ticketGroup.flags %></td>\n        <td>\n          <button class="btn subButton" data-ticket-group-id="<%= ticketGroup.id %>">Purchase</button>\n        </td>\n      </tr>\n\n      <% }); %>\n      </tbody>\n    </table>\n  </div>\n</div>\n', __filename = "js/views/order/sub_order_item_tickets.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div class="flextainer">\n\n  <div class="flex1 flextainer seatingChartContainer" style="height: 600px">\n    <!-- InteractiveSeatingChartView goes here. -->\n  </div>\n\n  <div class="flex1" style="max-height: 600px; overflow: auto;">\n    <table class="table table-hover">\n      <thead>\n      <tr>\n        <th>Qty.</th>\n        <th>Section</th>\n        <th>Row</th>\n        <th>Seats</th>\n        <th>Wholesale</th>\n        <th>Retail</th>\n        ');
            __line = 17;
            if (SESSION.role.can(App.Access.Resources.Inventory.AllTickets.ViewDetails.Broker)) {
                __append("\n        <th>Broker</th>\n        ");
                __line = 19;
            }
            __append("\n        <th></th>\n        <th></th>\n      </tr>\n      </thead>\n      <tbody>\n\n      ");
            __line = 26;
            ticketGroups.forEach(function(ticketGroup, index) {
                __append('\n      <tr class="ticketGroup" data-ticket-group-id="');
                __line = 27;
                __append(escapeFn(ticketGroup.id));
                __append('">\n        <td>');
                __line = 28;
                __append(escapeFn(ticketGroup.quantitySeparated));
                __append("</td>\n        <td>");
                __line = 29;
                __append(escapeFn(ticketGroup.section));
                __append("</td>\n        <td>");
                __line = 30;
                __append(escapeFn(ticketGroup.row));
                __append("</td>\n        <td>");
                __line = 31;
                __append(escapeFn(ticketGroup.availableSeatsString));
                __append("</td>\n        <td>");
                __line = 32;
                __append(escapeFn(App.Utils.valueToCurrency(ticketGroup.wholesalePrice)));
                __append("</td>\n        <td>");
                __line = 33;
                __append(escapeFn(App.Utils.valueToCurrency(ticketGroup.retailPrice)));
                __append("</td>\n        ");
                __line = 34;
                if (SESSION.role.can(App.Access.Resources.Inventory.AllTickets.ViewDetails.Broker)) {
                    __append('\n        <td>\n          <a style="cursor: pointer;" class="broker" data-office-id="');
                    __line = 36;
                    __append(escapeFn(ticketGroup.office.id));
                    __append('" data-broker-id="');
                    __append(escapeFn(ticketGroup.office.brokerage.id));
                    __append('" title="');
                    __append(escapeFn(ticketGroup.office.brokerage.abbreviation));
                    __append('">\n            ');
                    __line = 37;
                    __append(escapeFn(ticketGroup.office.brokerage.abbreviation));
                    __append("\n          </a>\n        </td>\n        ");
                    __line = 40;
                }
                __append('\n        <td style="white-space: nowrap;">');
                __line = 41;
                __append(ticketGroup.flags);
                __append('</td>\n        <td>\n          <button class="btn subButton" data-ticket-group-id="');
                __line = 43;
                __append(escapeFn(ticketGroup.id));
                __append('">Purchase</button>\n        </td>\n      </tr>\n\n      ');
                __line = 47;
            });
            __append("\n      </tbody>\n    </table>\n  </div>\n</div>\n");
            __line = 52;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}