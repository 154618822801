template = require('./new_payment.ejs')

module.exports = App.Views.CreditMemo.NewPayment = Backbone.View.extend

  template: template

  tagName: 'tr'

  className: 'newPayment'

  initialize: (options = {}) ->
    {
      @creditMemo
      @creditMemoShowView
    } = options

  render: () ->
    view = @$el.html(
      @template()
    )
    @buttonLoader = new App.Utils.LoadingButton(@$('.createButton'))
    @$('.amount').formatCurrency()

    # Render Payments, and then update the details with the item that is selected by default.
    supportedPaymentTypes = ['cash', 'check', 'credit_card', 'money_order', 'pay_pal']
    if (@creditMemo.get('_holder').type == C.Office)
      supportedPaymentTypes.push('evopay')

    @paymentDropdown = new App.Views.Payments.Dropdown({
      $container: @$('.payment_dropdown_wrapper')
      supportedPaymentTypes: supportedPaymentTypes
      onChange:  _.bind(@updateDetails, @)
    })

    @formErrors = new App.Utils.FormErrorManager($('#creditMemoFormErrors'))
    @delegateEvents()
    return view

  # VIEW EVENTS ////////////////////////////////////////////////////////////////
  events:
    'click .createButton': 'onCreateButtonClick'
    'click .cancelButton': 'onCancelButtonClick'

  onCreateButtonClick: (e) ->
    payment = new App.Models.V3.Payment(null, {
      mapping: C.Mappings.Direct.CreditMemo.Payments
      creditMemoId: @creditMemo.id
    })

    attrs = {
      _amount:             App.Utils.currencyToValue(@$('.amount').val())
      _billingAddressId:   @$('#creditCards :selected').data('credit-card-billing-id')
      _checkNumber:        @$('.transactionNumber').val()
      _creditCardId:       @$('#creditCards :selected').data('credit-card-id') || @$('select.transactions :selected').data('credit-card-id')
      _isCompleted:        !!(@$('.paymentState').val() == 'Completed')
      _state:              @getState()
      _paymentType:        @$('#payment_type').val()
    }
    payment.set(attrs)
    payment.purpose = C.Purposes.CreateCreditMemoPayment

    @disableActions()
    payment.savePromise()
    .then (model) =>
      @formErrors.show({
        type: 'success'
        title: 'Payment created successfully.'
      })
      @reRender(payment)
    .fail (errors) =>
      @formErrors.show({
        title: 'Error creating payment.'
        errors
      })
      @enableActions()
    .done()

  getState: () ->
    if (@$('.paymentState').val() == 'Completed')
      return "completed"
    else
      return "pending"

  onCancelButtonClick: (e) ->
    @$el.remove()
  #/////////////////////////////////////////////////////////////////////////////

  enableActions: () ->
    @buttonLoader.stopLoad()
    @$('.createButton').removeClass('disabled')
    @$('.cancelButton').removeClass('disabled')
    @$('input').removeAttr('disabled')
    @$('select').removeAttr('disabled')

  disableActions: () ->
    @buttonLoader.startLoad()
    @$('.createButton').addClass('disabled')
    @$('.cancelButton').addClass('disabled')
    @$('input').attr('disabled', 'disabled')
    @$('select').attr('disabled', 'disabled')

  reRender: (payment) ->
    @enableActions()
    @onCancelButtonClick()
    @creditMemoShowView.updatePayments(payment)

  updateDetails: (paymentType) ->
    $details = @$el.find('.paymentDetails .details')
    $label = @$el.find('.paymentDetails label')
    $transactionDetails = @$el.find('.transactionDetails')
    transType = @$el.find('#transactionType :selected').val()

    @$('.amount').removeAttr('disabled')
    if (@$el.find('.paymentDetails').length > 0)
      @$el.find('select.paymentState').attr('disabled', false)
      $label.empty()
      @$el.find('.paymentDetails .card_details, .billing_address').remove()

    switch (paymentType)

      when 'cash'
        $details.empty().html('N/A')

      when 'credit_card'
        @$el.find('select.paymentState').attr('disabled', true)
        @creditMemo.fetchCardsPromise()
        .then (response) =>
          if (response?.cards?.length)
            ccView = new App.Views.CreditCards.CreditMemoCards({
              cards: response.cards
            })
            $details.html(ccView.render())
          else
            @$('.amount').attr('disabled', 'disabled')
            $details.html('No Cards Used on This Order')
        .fail (error) =>
          $.noop()

      when 'evopay'
        $details.empty().html('N/A')

      when 'check'
        $label.html('Check Number:')
        $details.empty().html("<input class='transactionNumber' type='text'>")

      when 'money_order'
        $label.html('Transaction Number:')
        $details.empty().html("<input class='transactionNumber' type='text'>")

      when 'pay_pal'
        $label.html('Transaction Number:')
        $details.empty().html("<input class='transactionNumber' type='text'>")

      when 'credit_memo'
        $details.empty().html('N/A')