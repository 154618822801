App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

module.exports = App.Models.V3.OrderCollectionWrapper = App.Models.Base.BaseModel.extend

  urlRoot: () ->
    urls = {
      orders: '/api_direct/v9/orders.json?type=Order&lightweight=true'
      pos: '/api_direct/v9/orders?type=PurchaseOrder&lightweight=true'
    }
    return urls[@ordersOrPOs] + "&order_by=orders.created_at DESC&with_tickets=true&per_page=100"

  defaults: () -> {
    orders: new App.Collections.V3.Orders(null, {
      mapping: C.Mappings.Direct.OrdersLightweight.Orders
      @ordersOrPOs
      parentWrapper: @ })}

  initialize: (attributes, options) ->
    options ||= {}
    @ordersOrPOs = options.ordersOrPOs

  fetchAll: (options) ->
    return @get('orders').fetchAll(options)

  parse: (json, options) ->
    orderCollection = new App.Collections.V3.Orders(json.orders, {
      mapping: C.Mappings.Direct.OrdersLightweight.Orders
      ordersOrPOs: @ordersOrPOs
      parentWrapper: @
    })
    @set('orders', orderCollection)
    delete json.orders
    return json

