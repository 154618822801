App.Models.Base.V3BaseModel     = require('../base/v3_base_model.coffee')

EVENT_CACHE = {}

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.Cart = App.Models.Base.V3BaseModel.extend

  urlRoot: '/api_direct/v9/carts'

  initialize: (attributes = {}, options = {}) ->
    parent.initialize.call(@, attributes, options)

  # Handles circular reference craziness of _event has _ticketGroups has _event has _ticketGroups
  restoreEventsFromCache: () ->
    holds = @get('_holds').models
    for hold in holds
      ticketGroupId = hold.get('_tickets').first().get('_ticketGroupId')
      ticketGroupModel = @get('_ticketGroups').get(ticketGroupId)
      if (ticketGroupModel.get('_eventId'))
        eventModel = EVENT_CACHE[ticketGroupModel.get('_eventId')]
        if (eventModel)
          eventModel.unset('_ticketGroups')
          ticketGroupModel.set({
            _event: EVENT_CACHE[ticketGroupModel.get('_eventId')]
          })

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.Carts.Carts)
      attrs._holds = new App.Collections.V3.TicketHolds(json.holds, {
        mapping: C.Mappings.Direct.Carts.TicketHolds
      })
      attrs._id = json.id
      attrs._name = json.name
      attrs._ticketGroups = new App.Collections.V3.TicketGroups(json.ticket_groups, {
        mapping: C.Mappings.Direct.Carts.TicketGroups
      })

    @set(attrs)

  add: (options = {}) ->
    {
      ticket_ids
      low_seat
      price
      seat_order
    } = options
    attrs = {
      ticket_ids
      low_seat
      price
      seat_order
      update_type: 'add'
    }
    @set(attrs)
    @purpose = C.Purposes.AddToCart
    return @savePromise()

  remove: (options = {}) ->
    deferred = Q.defer()
    {
      holdId
    } = options
    attrs = {
      hold_id: holdId
      update_type: 'remove'
    }
    @set(attrs)
    @savePromise()
    .then (model) =>
      @updateTitleBar()
      deferred.resolve(@)
    .fail (error) =>
      console.error(error)
      errorModal = new App.Views.Shared.BasicModal({
        message: 'Error removing item to cart.'
      })
      deferred.reject(error)
    .done()
    return deferred.promise

  updateTitleBar: () ->
    numInCart = @get('_holds')?.length || 0
    options = {
      action: 'updateCart'
      numInCart
    }
    App.router.updateTopNav(options)

  exportForCheckout: () ->
    eventCollection = new App.Collections.V3.Events()
    holds = @get('_holds').models
    for hold in holds
      ticketGroupId = hold.get('_tickets').first().get('_ticketGroupId')
      ticketGroupModel = @get('_ticketGroups').get(ticketGroupId)

      price = hold.get('_price')
      quantity = hold.get('_tickets').length
      seats = hold.get('_tickets').pluck('_seat').sort((a, b) ->
        return a - b
      )

      sortedSeats = seats.sort((a, b) -> return a - b)
      seatOrder = C.SeatOrder.Consecutive
      if (App.Utils.isSeatArrayOddEven(sortedSeats))
        seatOrder = C.SeatOrder.OddEven

      ticketGroupModel.set({
        _lowSeat: sortedSeats[0]
        _heldPrice: price
        _heldSeats: seats
        _quantity:  quantity
        _seatOrder: seatOrder
        _seats:     seats
      })

      eventModel = ticketGroupModel.get('_event')
      if (eventCollection.get(eventModel.id))
        eventModel = eventCollection.get(eventModel.id)
        ticketGroupCollection = eventModel.get('_ticketGroups')
      else
        eventCollection.add(eventModel)
        ticketGroupCollection = new App.Collections.V3.TicketGroups()
        eventModel.set('_ticketGroups', ticketGroupCollection)

      # This grossness resolves the flow of taking a cart to checkout, then going back to cart view.
      ticketGroupModel.set('_eventId', eventModel.id)
      EVENT_CACHE[eventModel.id] = eventModel

      ticketGroupModel.unset('event')   # Undo circular reference tg -> event -> tg (breaks toJSON())
      ticketGroupModel.unset('_event')  # Undo circular reference tg -> event -> tg

      ticketGroupCollection.add(ticketGroupModel)

    return eventCollection

  toJSON: (options = {}) ->
    if (@purpose == C.Purposes.AddToCart)
      @purpose = null
      return {
        low_seat:     @get('low_seat')
        name:         'default'
        price:        @get('price')
        seat_order:   @get('seat_order')
        ticket_ids:   @get('ticket_ids')
        update_type:  'add'
      }
    else
      return parent.toJSON.call(@, options)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

    })
    return presented
  #///////////////////////////////////////////////////////////////////////////
