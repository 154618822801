App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V3.Ticket = require('../../models/v3/ticket_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V3.Tickets = App.Collections.Base.BaseCollection.extend

  model: App.Models.V3.Ticket

  url: () ->
    return "/api_direct/v9/tickets?ticket_group_id=#{ @ticketGroupId }"

  initialize: (attributes, options = {}) ->
    {
      @ticketGroupId
    } = options
    parent.initialize.call(@, attributes, options)

  comparator: (ticketModel) ->
    return 0 + parseInt(ticketModel.get('_seat'))

  parse: (response, options) ->
    if (response.tickets)
      return response.tickets
    return response