App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.Payment = App.Models.Base.V3BaseModel.extend

  url: ()->
    if (@purpose == C.Purposes.CreateOrderPayment)
      return '/api_direct/v9/payments'
    else if (@purpose == C.Purposes.CreateOrderReturn)
      return "/api_direct/v9/orders/#{ @get('_orderId') }/return"
    else if (@purpose == C.Purposes.CreateRefund)
      return "/api_direct/v9/payments/#{ @get('_refundPaymentId') }/refund"
    else if (@purpose == C.Purposes.CreateCreditMemoPayment)
      return "/api_direct/v9/credit_memos/#{ @creditMemoId }/credit_memo_payments"
    else if (@purpose == C.Purposes.ApplyCreditMemoPayment)
      return "/api_direct/v9/credit_memos/#{ @creditMemoId }/credit_memo_payments/#{ @id }/apply"
    else if (@purpose == C.Purposes.CancelCreditMemoPayment)
      return "/api_direct/v9/credit_memos/#{ @creditMemoId }/credit_memo_payments/#{ @id }/cancel"
    else
      return parent.url.call(@)

  urlRoot: '/api_direct/v9/payments'

  initialize: (attributes, options = {}) ->
    {
      @creditMemoId
    } = options
    parent.initialize.call(@, attributes, options)

  parse: (response, options) ->
    ###
    # FIXTHEAPI
    Api response for create looks like:
    {
      credit_memo_payments: Array[1]
      current_page: null
      per_page: null
      total_entries: null
    }
    ###
    if (
      response.credit_memo_payments &&
      response.credit_memo_payments.length &&
      response.credit_memo_payments.length == 1
    )
      return response.credit_memo_payments[0]
    else
      return response

  # http://thedersen.com/projects/backbone-validation/
  validation: () ->

    if (@purpose == C.Purposes.CreateCreditMemoPayment)
      return {}

    attrs = @attributes
    type = attrs._type
    base = {
      _amount:
        required: true
        range:  [0, 1000000]
      _type:
        required: true
    }
    switch type
      when C.Cash
        return _.extend({}, base, {
          _isComplete:
            required: true
        })
      when C.Check
        return _.extend({}, base, {
          #_checkNumber:
          #  required: true
          _isComplete:
            required: true
        })
      when C.CreditCard
        return base
      when C.CreditMemo
        return _.extend({}, base, {
          _isComplete:
            required: true
        })
      when C.EvoPay
        return _.extend({}, base, {
          _isComplete:
            required: true
        })
      when C.ExternalCreditCard
        # Included for completeness, this should not be used when creating new
        # payments, this is a payment type caused by external transactions
        # performed in admin
        return base
      when C.MoneyOrder
        return _.extend({}, base, {
          #_checkNumber:
          #  required: true
          _isComplete:
            required: true
        })
      when C.NoPayment
        return base
      when C.PayPal
        return _.extend({}, base, {
          #_transactionId:
          #  required: true
          _isComplete:
            required: true
        })
      when C.TBDPayment
        return base
      else
        return base
  labels:
    _amount: 'Amount'
    _checkNumber: 'Check Number'
    _creditCard: 'Credit Card'
    _isComplete: 'Is Complete'
    _transactionId: 'Transaction ID'
    _type: 'Type'

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (
      @getMapping() == C.Mappings.Direct.Orders.Payments ||
      @getMapping() == C.Mappings.Direct.Order.Payments
    )
      attrs._amount = json.amount
      attrs._AVSPostalCodeResponse = json.avs_postal_code_response
      attrs._AVSPostalCodeResponseCode = json.avs_postal_code_response_code
      attrs._AVSResponse = json.avs_response
      attrs._AVSResponseCode = json.avs_response_code
      attrs._AVSStreetAddressResponse = json.avs_street_address_response
      attrs._AVSStreetAddressResponseCode = json.avs_street_address_response_code
      attrs._checkNumber = json.check_number
      attrs._createdAt = Date.parse(json.created_at)
      attrs._creditCard = new App.Models.V3.CreditCard(json.credit_card, {
        mapping: C.Mappings.Direct.Order.PaymentsCreditCard
      })
      attrs._creditMemoId = json.credit_memo_id
      attrs._CVVResponse = json.cvv_response
      attrs._CVVResponseCode = json.cvv_response_code
      attrs._id = json.id
      attrs._isRefund = json.is_refund
      attrs._orderLinkId = json.order_link_id
      attrs._paymentForCreditMemo = json.payment_for_credit_memo_id
      attrs._performedBy = json.performed_by
      attrs._refundableAmount = json.refundable_amount
      attrs._refundedFromId = json.refunded_from_id
      attrs._state = json.state
      attrs._type = json.type
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.CreditMemo.Payments)
      attrs._amount = json.amount
      attrs._AVSPostalCodeResponse = json.avs_postal_code_response
      attrs._AVSPostalCodeResponseCode = json.avs_postal_code_response_code
      attrs._AVSResponse = json.avs_response
      attrs._AVSResponseCode = json.avs_response_code
      attrs._AVSStreetAddressResponse = json.avs_street_address_response
      attrs._AVSStreetAddressResponseCode = json.avs_street_address_response_code
      attrs._checkNumber = json.check_number
      attrs._createdAt = Date.parse(json.created_at)
      attrs._creditCard = new App.Models.V3.CreditCard(json.credit_card, {
        mapping: C.Mappings.Direct.CreditMemos.CreditCard
      })
      attrs._creditMemoId = json.credit_memo_id
      attrs._CVVResponse = json.cvv_response
      attrs._CVVResponseCode = json.cvv_response_code
      attrs._id = json.id
      attrs._isRefund = json.is_refund
      attrs._orderLinkId = json.order_link_id
      attrs._paymentForCreditMemo = json.payment_for_credit_memo_id
      attrs._performedBy = json.performed_by
      attrs._refundedFromId = json.refunded_from_id
      attrs._state = json.state
      attrs._type = json.type
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url

    if (@getMapping() == C.Mappings.Direct.CreditMemo.Transactions)
      attrs._amount = json.amount
      attrs._creditMemoId = @collection?.creditMemoId || null
      attrs._id = json.id
      attrs._isCredit = json.is_credit
      attrs._orders = json.orders
      attrs._state = C.Completed
      attrs._type = C.CreditMemo

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      amount:                   @get('_amount')
      AVSPostalCodeResponse:    @get('_AVSPostalCodeResponse')
      AVSStreetAddressResponse: @get('_AVSStreetAddressResponse')
      checkNumber:              @get('_checkNumber')
      createdAt:                @get('_createdAt')
      creditCard:               @get('_creditCard')?.toPresenterJSON?()
      creditMemoId:             @get('_creditMemoId')
      CVVResponse:              @get('_CVVResponse')
      details:                  @details()
      id:                       @get('_id')
      isCredit:                 @get('_isCredit')
      isRefund:                 @get('_isRefund')
      state:                    C.Options.Payment.States[@get('_state')] || @get('_state')
      transactionType:          @transactionType()
      type:                     C.Options.Order.PaymentTypes[@get('_type')] || @get('_type')
      updatedAt:                @get('_updatedAt')

    })
    return presented

  details: () ->
    if (@get('_type') == C.CreditCard)
      card = @get('_creditCard')?.toPresenterJSON?()
      details = []
      details.push(card.name)
      details.push("#{ card.cardCompany } x#{card.lastDigits}")
      details.push("Exp: #{ card.expiration }")
      details.push("AVS Postal Code: #{ @get('_AVSPostalCodeResponse') }")
      details.push("AVS Street Address: #{ @get('_AVSStreetAddressResponse') }")
      details.push("CVV: #{ @get('_CVVResponse') }")
      return details.join('<br/>')
    if (@get('_type') == C.CreditMemo)
      creditMemoId = @get('_creditMemoId')
      return "<a href='/credit_memo/#{ creditMemoId }'>Credit Memo ##{ creditMemoId }</a>"
    return ''

  transactionType: () ->
    if (@get('_isRefund'))
      return 'Refund'
    return 'Payment'
  #///////////////////////////////////////////////////////////////////////////

  toJSON: () ->
    attrs = @attributes

    if (@purpose == C.Purposes.CreateRefund)
      return {
        amount:       attrs._amount
      }

    if (@purpose == C.Purposes.CreateOrderReturn)
      return {
        order_id:     attrs._orderId
        amount:       attrs._amount
        performed_by: attrs._performedBy
        skip_memo:    attrs._skipMemo
      }

    if (@purpose == C.Purposes.CreateOrderPayment)
      type = attrs._type
      json = {
        is_refund:    attrs._isRefund
        order_id:     attrs._orderId
        amount:       attrs._amount
        type:         attrs._type
        is_completed: attrs._isComplete
      }
      if (type == C.Cash)
        $.noop()
      if (type == C.Check)
        json.check_number = attrs._checkNumber
      if (type == C.CreditCard)
        json.payment_method_nonce = attrs._payment_method_nonce
      if (type == C.MoneyOrder)
        json.check_number = attrs._checkNumber
      if (type == C.PayPal)
        json.transaction_id = attrs._transactionId

      return {
        payments: [ json ]
      }

    if (@purpose == C.Purposes.CreateCreditMemoPayment)
      return {
        credit_memo_payments: [{
          amount:             attrs._amount
          check_number:       attrs._checkNumber
          is_completed:       attrs._isCompleted
          state:              attrs._state
          transaction_id:     attrs._creditCardId
          type:               attrs._paymentType
        }]
      }

    else if (@purpose == C.Purposes.ApplyCreditMemoPayment)
      json = {}
      if (attrs._checkNumber)
        json.check_number = attrs._checkNumber
      return json

    else if (@purpose == C.Purposes.CancelCreditMemoPayment)
      return {}

    else
      type = attrs._type

      json = {
        amount: App.Utils.roundCurrency(attrs._amount)
        type
      }

      if (type == C.Cash)
        json.is_completed = attrs._isComplete

      if (type == C.Check)
        json.check_number = attrs._checkNumber
        json.is_completed = attrs._isComplete

      if (type == C.CreditCard)
        json.payment_method_nonce = attrs._payment_method_nonce

      if (type == C.CreditMemo)
        json.is_completed = attrs._isComplete

      if (type == C.EvoPay)
        json.is_completed = attrs._isComplete

      if (type == C.ExternalCreditCard)
        json.credit_card_id = 'external'

      if (type == C.MoneyOrder)
        json.check_number = attrs._checkNumber
        json.is_completed = attrs._isComplete

      if (type == C.NoPayment)
        $.noop()

      if (type == C.PayPal)
        json.is_completed =   attrs._isComplete
        json.transaction_id = attrs._transactionId

      if (type == C.TBDPayment)
        # Send nothing in case of TBD.
        # If this blows up, set it back to null, but that breaks toPresenterJSON.
        # You can't _.extend(null).
        json = {}

      return json

  applyPromise: () ->
    deferred = Q.defer()
#    Does anyone still use this ?
#    if (@get('_type') == C.Check || @get('_type') == C.MoneyOrder)
#      if (!@get('_checkNumber'))
#        checkNumber = prompt('Please supply a check number to apply this payment:')
#        @set('_checkNumber', checkNumber)
#
#    checkNumber = @get('_checkNumber')
#    data = {}
#    if (checkNumber)
#      data.check_number = checkNumber

    $.ajax({
      type: 'GET'
      url: "/api_direct/v9/payments/#{ @id }/apply"
#      data: JSON.stringify(data)
      success: (data, status, xhr) =>
        @fetchPromise()
        .then (model) =>
          deferred.resolve(data)
        .fail (errors) =>
          deferred.reject(errors)
        .done()
      error: (xhr, status, error) =>
        deferred.reject(xhr)
    })
    return deferred.promise

  cancelPromise: () ->
    deferred = Q.defer()
    $.ajax({
      type: 'GET'
      url: "/api_direct/v9/payments/#{ @id }/cancel"
      success: (data, status, xhr) =>
        @fetchPromise()
        .then (model) =>
          deferred.resolve(data)
        .fail (errors) =>
          deferred.reject(errors)
        .done()
      error: (xhr, status, error) =>
        deferred.reject(xhr)
    })
    return deferred.promise




