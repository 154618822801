module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div class="container-fluid no-indent-rows">\n  <div class="span9" id="top_name_label" style="font-size:35px;min-height:45px;line-height:45px;margin-top:25px"><%= client.name || \'New\' %></div>\n  <div class="row-fluid">\n    <div class="span12">\n      <div class="widget-box">\n        <div class="widget-title">\n          <ul class="nav nav-tabs" id="top_tabs">\n\n            <li class="<%= (tab == null) ? \'active\' : \'\' %>">\n              <a id="info" data-toggle="tab" href="#">\n                <i class="fa-solid fa-info"></i>\n                Information\n              </a>\n            </li>\n\n            <li id="invoicesTab" class="<%= (tab == \'invoices\') ? \'active\' : \'\' %>">\n              <a id="invoices" data-toggle="tab" href="/client/<%= client.id %>/invoices">\n                <i class="fa-solid fa-right-to-bracket"></i>\n                Invoices\n              </a>\n            </li>\n\n            <% if (SESSION.role.can(App.Access.Resources.Orders.POs)) { %>\n            <li id="ordersTab" class="<%= (tab == \'orders\') ? \'active\' : \'\' %>">\n              <a id="pos" data-toggle="tab" href="/client/<%= client.id %>/orders">\n                <i class="fa-solid fa-right-from-bracket"></i>\n                Purchase Orders\n              </a>\n            </li>\n            <% } %>\n\n            <li id="cardsTab" class="<%= (tab == \'creditCards\') ? \'active\' : \'\' %>">\n              <a id="cards" data-toggle="tab" href="/client/<%= client.id %>/credit_cards">\n                <i class="fa-solid fa-credit-card"></i>\n                Credit Cards\n              </a>\n            </li>\n\n          </ul>\n        </div>\n        <div class="tab-content widget-content" id="content_here" style="position:relative">\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n', __filename = "js/views/clients/show_layout.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div class="container-fluid no-indent-rows">\n  <div class="span9" id="top_name_label" style="font-size:35px;min-height:45px;line-height:45px;margin-top:25px">');
            __line = 2;
            __append(escapeFn(client.name || "New"));
            __append('</div>\n  <div class="row-fluid">\n    <div class="span12">\n      <div class="widget-box">\n        <div class="widget-title">\n          <ul class="nav nav-tabs" id="top_tabs">\n\n            <li class="');
            __line = 9;
            __append(escapeFn(tab == null ? "active" : ""));
            __append('">\n              <a id="info" data-toggle="tab" href="#">\n                <i class="fa-solid fa-info"></i>\n                Information\n              </a>\n            </li>\n\n            <li id="invoicesTab" class="');
            __line = 16;
            __append(escapeFn(tab == "invoices" ? "active" : ""));
            __append('">\n              <a id="invoices" data-toggle="tab" href="/client/');
            __line = 17;
            __append(escapeFn(client.id));
            __append('/invoices">\n                <i class="fa-solid fa-right-to-bracket"></i>\n                Invoices\n              </a>\n            </li>\n\n            ');
            __line = 23;
            if (SESSION.role.can(App.Access.Resources.Orders.POs)) {
                __append('\n            <li id="ordersTab" class="');
                __line = 24;
                __append(escapeFn(tab == "orders" ? "active" : ""));
                __append('">\n              <a id="pos" data-toggle="tab" href="/client/');
                __line = 25;
                __append(escapeFn(client.id));
                __append('/orders">\n                <i class="fa-solid fa-right-from-bracket"></i>\n                Purchase Orders\n              </a>\n            </li>\n            ');
                __line = 30;
            }
            __append('\n\n            <li id="cardsTab" class="');
            __line = 32;
            __append(escapeFn(tab == "creditCards" ? "active" : ""));
            __append('">\n              <a id="cards" data-toggle="tab" href="/client/');
            __line = 33;
            __append(escapeFn(client.id));
            __append('/credit_cards">\n                <i class="fa-solid fa-credit-card"></i>\n                Credit Cards\n              </a>\n            </li>\n\n          </ul>\n        </div>\n        <div class="tab-content widget-content" id="content_here" style="position:relative">\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n');
            __line = 47;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}