module.exports = App.Access
do (A = App.Access) ->

  # ACL SYSTEM:
  #
  # The A.Resources object is converted into a tree of ResourceNodes.
  # Each Role gets is own copy of this tree.

  # You can then perform whitelist/blacklist operations on that role - targeting specific Resources.
  # Permissions are a tree structure - if no rule is provided,
  # the resource inherits its permission rules from its parent. We go up the tree until we find a rule.

  # Blacklist goes down the tree - we block the resources and all child resources.
  # Whitelist goes up the tree - we allow the resources, and unlock all resources above it
  #  (since these would inherently be required to utilize the specified resource).
  # WhitelistDown is a special case to unlock a whole portion of the tree without specifying all the child
  #   resources 1 at a time.
  #
  # So each Role is really just a pointer to a rootNode of a ResourceTree.
  # The structure of the ResourceTree matches the A.Resources object exactly.
  # Each node has isAllowed & isDenied fields.
  # We determine permissions by finding the appropriate ResourceNode and checking isAllowed / isDenied.
  # If no permissions are set we traverse back up the tree until we find a parent with permissions.

  # TODO: Cache rule lookups?









  #// RESOURCES ////////////////////////////////////////////////////////////////
  # Please give leaf nodes a unique value.
  A.Resources = {

    # Access to Customers screen.
    Customers: {
      CanSeeBrokerageScope: {} # Customers from entire brokerage, not just their office.
    }

    # Access to Dashbaord.
    Dashboard: {}

    Inventory: {

      # Access to TicketGroupsListView / "Homepage".
      AllTickets: {

        AllTicketsOneClick: {}

        # Actions in TG ListView (homepage) context menu for All Tickets.
        AllTicketsActions: {
          AffiliateSell: {}
          PartnerEvopaySell: {}
          BulkBuy: {}
          BuySell: {}
          Buy: {}
          AddToQuote: {}
          Bid: {}
        }

        Filter: {
          CreateAffiliateLink: {}
        }

        # Actions in TG ListView (homepage) context menu for My Tickets.
        MyTicketsActions: {
          AddToCart: {}
          AddToQuote: {}
          DestroySpec: {}
          Edit: {
            Price: {}
          }
          Hold: {}
          ManageEtickets: {}
          Sell: {}
          Take: {}
          Unbroadcast: {}
          Waste: {}
        }

        NonElectronicFormats: {}

        # Some roles cannot own inventory.  Will they own tickets?
        OwnTickets: {}

        SearchByCity: {}

        # Some roles cannot view inventory details such as Broker or Cost.
        ViewDetails: {
          Broker: {}
          Cost: {}
          OriginatingPO: {}
          RetailPrice: {}
          PurchasePrice: {}
          WholesalePrice: {}
        }

        ViewTGFiltersByDefault: {}

        ViewSold: {}

        QuickSearch: {}
      }

      ViewReportingData: {}
      ConsignmentPO: {}
      EvoQuotes: {}
      Held: {}
      MassEdit: {}
      NewPO: {}
      ReceiveTickets: {}
      Spec: {}
      Taken: {}
      Uploads: {
        UploadInventory: {}
      }

    }

    Orders: {
      BuySell: {
        # Whether or not the sale price input is disabled
        CanEditSalePrice: {}
        # Access to the selector for seats.
        CanEnterSeats: {}
        CanEnterCustomerCompany: {}
        CanEnterCustomerReference: {}
        # Show "Submit & Send PO" when making orders.
        CanSendPOWithOrder: {}
        # Show option to hold for fraud review
        CanFraudReview: {}

        CanSellToOffices: {}

        ShouldAutoAccept: {}

        # Travel agents aren't interested in Purchase Totals, Total Cost, or
        # Purchase Price
        NoTotals: {}

        CanSeeFieldInfo: {}

        # Travel agents need to confirm the terms and conditions in order to submit an order
        NeedsToConfirmTerms: {}
      }
      # Access to Orders screen.
      OrdersListView: {
        BuyerState: {}
        OrdersOneClick: {}
        UnpaidOrders: {}
        TevoOrders: {}
        SimpleFilters: {}
      }
      # Access to POs screen.
      POs: {}
      # Access to Event Management screen.
      EventManagement: {}
      # Access to Deliveries screen.
      Deliveries: {}
      # Access to Payments screen.
      Payments: {}
      # Access to EvoPay screen.
      EvoPay: {
        # Do they have ACH setup?  Allows extra features in EvoPay screen.
        ManageACHFunds: {}
      }
      ManageEvopayFunding: {}
      # Access to view Emails
      Emails: {}
      # Access to Credit Memos screen.
      CreditMemos: {}
      # Allows adding emails during Order checkout
      ManageDeliveryEmail: {}

      # Actions available on the Order Show screen (OrderOverview).
      OrderShow: {
        Accept: {}
        AddReturn: {}
        AddTransaction: {}
        Cancel: {}
        EmailAndPrintInvoice: {}
        Reject: {}
        ViewDetails : {
          BuyingFromBroker : {}
        }
        ViewKount: {}
        ViewPOs: {}
        ViewPONumber: {}
        ViewSoldTickets: {}
        ViewTaxAndExtraCost: {}
        CanConvertEtickets: {}
      }

    }

    NoBrokerJargon: {}

    # Allowed to use non-chrome browsers.
    NonChromeBrowsers: {}

    PaymentTypes: {
      # Allowed to use the below payment types.
      # Affected by braintree & evopay setups.
      Cash: {}
      Check: {}
      CreditCard: {}
      BuyWithCreditCard: {}
      CreditMemo: {}
      EvoPay: {}
      MoneyOrder: {}
      NoPayment: {}
      PayPal: {}
      TBD: {}
    }

    Reports: {
      # Access Sales Report screen.
      Sales: {}
    }

    Shipping: {
      ShipWithFedEx: {}
      CanEditShippingCost: {}
      CanSeeTravelSpecificNotes: {}
    }

    # Link to settings in user dropdown.
    Settings: {}

    VerboseIconDescriptions: {}

  }









  # Traits are like optional features that can be added to any role.
  A.Traits = {
    # Is BrainTree setup?  If yes they can do CreditCard payments.
    has_braintree_credentials: [
      A.Resources.PaymentTypes.CreditCard
    ]
    # Is EvoPay setup.  If yes they can make payments and view the EvoPay screen.
    allow_evopay: [
      A.Resources.Orders.EvoPay       # The EvoPay screen.
      A.Resources.PaymentTypes.EvoPay # The payment type.
    ]
    # Is ACH setup.  If yes they can transfer money in & out of EvoPay to their bank account.
    allow_evopay_ach: [
      A.Resources.Orders.EvoPay.ManageACHFunds
    ]
    manage_evopay_funding: [
      A.Resources.Orders.ManageEvopayFunding
    ]
    # Does the brokerage have FedEx credentials setup?  Rate options will fail without these.
    has_fedex_credentials: [
      A.Resources.Shipping.ShipWithFedEx
    ]

    reporting_access: [
      A.Resources.Inventory.ViewReportingData
    ]

    manage_delivery_email: [
      A.Resources.Orders.ManageDeliveryEmail
    ]

    can_buy_with_cc: [
      A.Resources.PaymentTypes.BuyWithCreditCard
    ]

    use_partner_evopay_funds: [
      A.Resources.Inventory.AllTickets.AllTicketsActions.PartnerEvopaySell
    ]

    early_evopay_clearance: [

    ]
  }









  # ROLE DEFINITIONS ///////////////////////////////////////////////////////////
  A.initRoles = () -> # Will be run once ACL system is initialized.
    A.Roles = {}

    # POS //////////////////////////////////////////////////////////////////////
    A.Roles.POS = new Role()
    A.Roles.POS.whitelistDown(A.Resources) # Allows everything.
    A.Roles.POS.blacklist(A.Resources.Shipping.CanSeeTravelSpecificNotes)
    A.Roles.POS.blacklist(A.Resources.Inventory.Uploads)
    A.Roles.POS.blacklist(A.Resources.Inventory.Uploads.UploadInventory)
    A.Roles.POS.blacklist(A.Resources.Inventory.AllTickets.AllTicketsActions.AffiliateSell)
    A.Roles.POS.blacklist(A.Resources.Inventory.AllTickets.AllTicketsOneClick)
    A.Roles.POS.blacklist(A.Resources.Inventory.AllTickets.SearchByCity)
    A.Roles.POS.blacklist(A.Resources.Inventory.AllTickets.ViewDetails.PurchasePrice)
    A.Roles.POS.blacklist(A.Resources.Inventory.AllTickets.Filter.CreateAffiliateLink)
    A.Roles.POS.whitelist(A.Resources.Inventory.AllTickets.QuickSearch)
    A.Roles.POS.blacklist(A.Resources.Orders.BuySell.ShouldAutoAccept)
    A.Roles.POS.blacklist(A.Resources.Orders.OrdersListView.OrdersOneClick)
    A.Roles.POS.blacklist(A.Resources.Orders.OrdersListView.SimpleFilters)
    A.Roles.POS.blacklist(A.Resources.NoBrokerJargon)
    A.Roles.POS.blacklist(A.Resources.Orders.BuySell.CanSeeFieldInfo)
    A.Roles.POS.blacklist(A.Resources.VerboseIconDescriptions)
    A.Roles.POS.blacklist(A.Resources.Orders.BuySell.NeedsToConfirmTerms)
    A.Roles.POS.blacklist(A.Resources.Inventory.AllTickets.AllTicketsActions.Bid)
    A.Roles.POS.whitelist(A.Resources.Orders.OrderShow.ViewKount)
    A.Roles.POS.blacklist(A.Resources.Orders.BuySell.NoTotals)
    A.Roles.POS.whitelist(A.Resources.Inventory.AllTickets.AllTicketsActions.BulkBuy)






    # POSAdmin /////////////////////////////////////////////////////////////////
    A.Roles.POSAdmin = new Role()
    A.Roles.POSAdmin.whitelist(A.Resources.Customers)
    A.Roles.POSAdmin.whitelist(A.Resources.Customers.CanSeeBrokerageScope)
    A.Roles.POSAdmin.whitelist(A.Resources.Dashboard)
    A.Roles.POSAdmin.whitelist(A.Resources.Inventory.AllTickets.AllTicketsActions.Buy)
    A.Roles.POSAdmin.whitelist(A.Resources.Inventory.AllTickets.AllTicketsActions.BuySell)
    A.Roles.POSAdmin.whitelist(A.Resources.Inventory.AllTickets.Filter)
    A.Roles.POSAdmin.whitelist(A.Resources.Inventory.AllTickets.MyTicketsActions.DestroySpec)
    A.Roles.POSAdmin.whitelist(A.Resources.Inventory.AllTickets.NonElectronicFormats)
    A.Roles.POSAdmin.whitelist(A.Resources.Inventory.AllTickets.ViewDetails.RetailPrice)
    A.Roles.POSAdmin.whitelist(A.Resources.Inventory.AllTickets.ViewDetails.WholesalePrice)
    A.Roles.POSAdmin.whitelist(A.Resources.Inventory.AllTickets.ViewTGFiltersByDefault)
    A.Roles.POSAdmin.whitelist(A.Resources.Inventory.AllTickets.QuickSearch)
    A.Roles.POSAdmin.whitelist(A.Resources.Inventory.AllTickets.OwnTickets)
    A.Roles.POSAdmin.whitelist(A.Resources.Inventory.AllTickets.AllTicketsActions.Bid)
    A.Roles.POSAdmin.whitelist(A.Resources.Inventory.AllTickets.SearchByCity)
    A.Roles.POSAdmin.whitelist(A.Resources.NonChromeBrowsers)
    A.Roles.POSAdmin.whitelist(A.Resources.Orders.BuySell.CanEnterCustomerCompany)
    A.Roles.POSAdmin.whitelist(A.Resources.Orders.BuySell.CanEnterCustomerReference)
    A.Roles.POSAdmin.whitelist(A.Resources.Orders.OrdersListView)
    A.Roles.POSAdmin.whitelist(A.Resources.Orders.OrdersListView.BuyerState)
    A.Roles.POSAdmin.whitelist(A.Resources.Orders.OrdersListView.UnpaidOrders)
    A.Roles.POSAdmin.whitelist(A.Resources.Orders.OrdersListView.TevoOrders)
    A.Roles.POSAdmin.whitelist(A.Resources.Orders.OrderShow.EmailAndPrintInvoice)
    A.Roles.POSAdmin.whitelist(A.Resources.Orders.OrderShow.ViewKount)
    A.Roles.POSAdmin.whitelist(A.Resources.Orders.OrderShow.ViewPOs)
    A.Roles.POSAdmin.whitelist(A.Resources.Orders.POs)
    A.Roles.POSAdmin.whitelist(A.Resources.Shipping.CanEditShippingCost)
    A.Roles.POSAdmin.blacklist(A.Resources.Inventory.AllTickets.QuickSearch)
    A.Roles.POSAdmin.whitelist(A.Resources.Inventory.AllTickets.AllTicketsActions.BulkBuy)






    # POSLite //////////////////////////////////////////////////////////////////
    A.Roles.POSLite = new Role()
    A.Roles.POSLite.whitelist(A.Resources.Dashboard)
    A.Roles.POSLite.whitelist(A.Resources.Customers.CanSeeBrokerageScope)
    A.Roles.POSLite.whitelist(A.Resources.Inventory.AllTickets.AllTicketsActions.AddToQuote)
    A.Roles.POSLite.whitelist(A.Resources.Inventory.AllTickets.AllTicketsActions.Buy)
    A.Roles.POSLite.whitelist(A.Resources.Inventory.AllTickets.AllTicketsActions.Bid)

    A.Roles.POSLite.whitelist(A.Resources.Inventory.AllTickets.Filter)
    A.Roles.POSLite.whitelist(A.Resources.Inventory.AllTickets.MyTicketsActions.AddToQuote)
    A.Roles.POSLite.whitelist(A.Resources.Inventory.AllTickets.NonElectronicFormats)
    A.Roles.POSLite.whitelist(A.Resources.Inventory.AllTickets.OwnTickets)
    A.Roles.POSLite.whitelist(A.Resources.Inventory.AllTickets.ViewDetails.Broker)
    A.Roles.POSLite.whitelist(A.Resources.Inventory.AllTickets.ViewDetails.RetailPrice)
    A.Roles.POSLite.whitelist(A.Resources.Inventory.AllTickets.ViewDetails.WholesalePrice)
    A.Roles.POSLite.whitelist(A.Resources.Inventory.AllTickets.ViewSold)
    A.Roles.POSLite.whitelist(A.Resources.Inventory.AllTickets.ViewTGFiltersByDefault)
    A.Roles.POSLite.whitelist(A.Resources.Inventory.EvoQuotes)
    A.Roles.POSLite.whitelist(A.Resources.Inventory.Uploads)
    A.Roles.POSLite.whitelist(A.Resources.Inventory.Uploads.UploadInventory)
    A.Roles.POSLite.whitelist(A.Resources.Inventory.AllTickets.QuickSearch)
    A.Roles.POSLite.whitelist(A.Resources.NonChromeBrowsers)
    A.Roles.POSLite.whitelist(A.Resources.Orders.BuySell.CanEnterCustomerCompany)
    A.Roles.POSLite.whitelist(A.Resources.Orders.BuySell.CanEnterCustomerReference)
    A.Roles.POSLite.whitelist(A.Resources.Orders.BuySell.CanEnterSeats)
    A.Roles.POSLite.whitelist(A.Resources.Orders.BuySell.CanFraudReview)
    A.Roles.POSLite.whitelist(A.Resources.Orders.BuySell.CanSellToOffices)
    A.Roles.POSLite.whitelist(A.Resources.Orders.BuySell.CanSendPOWithOrder)
    A.Roles.POSLite.whitelist(A.Resources.Orders.OrdersListView)
    A.Roles.POSLite.whitelist(A.Resources.Orders.OrdersListView.BuyerState)
    A.Roles.POSLite.whitelist(A.Resources.Orders.OrdersListView.UnpaidOrders)
    A.Roles.POSLite.whitelist(A.Resources.Orders.OrdersListView.TevoOrders)
    A.Roles.POSLite.whitelist(A.Resources.Orders.POs)
    A.Roles.POSLite.whitelist(A.Resources.Orders.CreditMemos)
    A.Roles.POSLite.whitelist(A.Resources.Orders.OrderShow.Accept)
    A.Roles.POSLite.whitelist(A.Resources.Orders.OrderShow.CanConvertEtickets)
    A.Roles.POSLite.whitelist(A.Resources.Orders.OrderShow.Cancel)
    A.Roles.POSLite.whitelist(A.Resources.Orders.OrderShow.EmailAndPrintInvoice)
    A.Roles.POSLite.whitelist(A.Resources.Orders.OrderShow.Reject)
    A.Roles.POSLite.whitelist(A.Resources.Orders.OrderShow.ViewKount)
    A.Roles.POSLite.whitelist(A.Resources.Orders.OrderShow.ViewPOs)
    A.Roles.POSLite.whitelist(A.Resources.Orders.OrderShow.ViewDetails.BuyingFromBroker)
    A.Roles.POSLite.whitelist(A.Resources.Orders.OrderShow.ViewTaxAndExtraCost)
    A.Roles.POSLite.whitelist(A.Resources.PaymentTypes.EvoPay)
    A.Roles.POSLite.whitelist(A.Resources.Settings)
    A.Roles.POSLite.whitelist(A.Resources.Shipping.CanEditShippingCost)
    A.Roles.POSLite.whitelist(A.Resources.Inventory.AllTickets.AllTicketsActions.BulkBuy)





    # POSPartner ///////////////////////////////////////////////////////////////
    A.Roles.POSPartner = new Role()
    A.Roles.POSPartner.whitelist(A.Resources.Customers)
    A.Roles.POSPartner.whitelist(A.Resources.Customers.CanSeeBrokerageScope)
    A.Roles.POSPartner.whitelist(A.Resources.Dashboard)
    A.Roles.POSPartner.whitelist(A.Resources.Inventory.AllTickets.AllTicketsActions.Buy)
    A.Roles.POSPartner.whitelist(A.Resources.Inventory.AllTickets.AllTicketsActions.BuySell)
    A.Roles.POSPartner.whitelist(A.Resources.Inventory.AllTickets.Filter)
    A.Roles.POSPartner.whitelist(A.Resources.Inventory.AllTickets.MyTicketsActions.DestroySpec)
    A.Roles.POSPartner.whitelist(A.Resources.Inventory.AllTickets.NonElectronicFormats)
    A.Roles.POSPartner.whitelist(A.Resources.Inventory.AllTickets.ViewDetails.RetailPrice)
    A.Roles.POSPartner.whitelist(A.Resources.Inventory.AllTickets.ViewDetails.WholesalePrice)
    A.Roles.POSPartner.whitelist(A.Resources.Inventory.AllTickets.ViewSold)
    A.Roles.POSPartner.whitelist(A.Resources.Inventory.AllTickets.ViewTGFiltersByDefault)
    A.Roles.POSPartner.whitelist(A.Resources.Inventory.AllTickets.QuickSearch)
    A.Roles.POSPartner.whitelist(A.Resources.NonChromeBrowsers)
    A.Roles.POSPartner.whitelist(A.Resources.Orders.BuySell.CanEnterCustomerCompany)
    A.Roles.POSPartner.whitelist(A.Resources.Orders.BuySell.CanEnterCustomerReference)
    A.Roles.POSPartner.whitelist(A.Resources.Orders.OrdersListView)
    A.Roles.POSPartner.whitelist(A.Resources.Orders.OrdersListView.BuyerState)
    A.Roles.POSPartner.whitelist(A.Resources.Orders.OrdersListView.UnpaidOrders)
    A.Roles.POSPartner.whitelist(A.Resources.Orders.OrdersListView.TevoOrders)
    A.Roles.POSPartner.whitelist(A.Resources.Orders.OrderShow.EmailAndPrintInvoice)
    A.Roles.POSPartner.whitelist(A.Resources.Orders.OrderShow.ViewKount)
    A.Roles.POSPartner.whitelist(A.Resources.Orders.OrderShow.ViewPOs)
    A.Roles.POSPartner.whitelist(A.Resources.Orders.POs)
    A.Roles.POSPartner.whitelist(A.Resources.Settings)
    A.Roles.POSPartner.whitelist(A.Resources.Shipping.CanEditShippingCost)
    A.Roles.POSPartner.blacklist(A.Resources.Inventory.AllTickets.AllTicketsActions.Bid)
    A.Roles.POSPartner.whitelist(A.Resources.Inventory.AllTickets.AllTicketsActions.BulkBuy)




    # TravelAgent //////////////////////////////////////////////////////////////
    A.Roles.TravelAgent = new Role()
    A.Roles.TravelAgent.blacklist(A.Resources.Orders.BuySell.CanEnterCustomerCompany)
    A.Roles.TravelAgent.blacklist(A.Resources.Orders.BuySell.CanEnterCustomerReference)
    A.Roles.TravelAgent.blacklist(A.Resources.Orders.BuySell.CanEditSalePrice)
    A.Roles.TravelAgent.whitelist(A.Resources.Orders.BuySell.NeedsToConfirmTerms)
    A.Roles.TravelAgent.whitelist(A.Resources.Orders.BuySell.NoTotals)
    A.Roles.TravelAgent.whitelist(A.Resources.Orders.BuySell.CanSeeFieldInfo)
    A.Roles.TravelAgent.whitelist(A.Resources.Orders.OrdersListView)
    A.Roles.TravelAgent.whitelist(A.Resources.Orders.OrdersListView.OrdersOneClick)
    A.Roles.TravelAgent.whitelist(A.Resources.Orders.OrdersListView.SimpleFilters)
    A.Roles.TravelAgent.whitelist(A.Resources.Orders.OrderShow.ViewKount)
    A.Roles.TravelAgent.whitelist(A.Resources.Customers)
    A.Roles.TravelAgent.whitelist(A.Resources.Inventory.AllTickets.AllTicketsActions.AffiliateSell)
    A.Roles.TravelAgent.whitelist(A.Resources.Inventory.AllTickets.AllTicketsOneClick)
    A.Roles.TravelAgent.whitelist(A.Resources.Inventory.AllTickets.NonElectronicFormats)
    A.Roles.TravelAgent.whitelist(A.Resources.Inventory.AllTickets.SearchByCity)
    A.Roles.TravelAgent.whitelist(A.Resources.Inventory.AllTickets.ViewDetails.PurchasePrice)
    A.Roles.TravelAgent.whitelist(A.Resources.Inventory.AllTickets.Filter.CreateAffiliateLink)
    A.Roles.TravelAgent.blacklist(A.Resources.Inventory.AllTickets.QuickSearch)
    A.Roles.TravelAgent.whitelist(A.Resources.NoBrokerJargon)
    A.Roles.TravelAgent.whitelist(A.Resources.NonChromeBrowsers)
    A.Roles.TravelAgent.whitelist(A.Resources.Shipping.CanSeeTravelSpecificNotes)
    A.Roles.TravelAgent.whitelist(A.Resources.VerboseIconDescriptions)
    A.Roles.TravelAgent.blacklist(A.Resources.Inventory.AllTickets.AllTicketsActions.Bid)
    A.Roles.TravelAgent.blacklist(A.Resources.Inventory.AllTickets.AllTicketsActions.BulkBuy)

    # Affiliate ////////////////////////////////////////////////////////////////

    A.Roles.Affiliate = new Role()
    A.Roles.Affiliate.whitelist(A.Resources.Orders.BuySell.CanEnterCustomerCompany)
    A.Roles.Affiliate.whitelist(A.Resources.Orders.BuySell.CanEnterCustomerReference)
    A.Roles.Affiliate.whitelist(A.Resources.Orders.OrdersListView.SimpleFilters)
    A.Roles.Affiliate.whitelist(A.Resources.Orders.OrderShow.ViewKount)
    A.Roles.Affiliate.whitelist(A.Resources.NoBrokerJargon)
    A.Roles.Affiliate.whitelist(A.Resources.NonChromeBrowsers)
    A.Roles.Affiliate.whitelist(A.Resources.Inventory.AllTickets.QuickSearch)
    A.Roles.Affiliate.blacklist(A.Resources.Inventory.AllTickets.AllTicketsActions.Bid)
    A.Roles.Affiliate.whitelist(A.Resources.Inventory.AllTickets.AllTicketsActions.BulkBuy)



    # TRAITS ///////////////////////////////////////////////////////////////////
    # Blacklist all resources that should be assigned from addTraits.
    # Traits are more like individual permission flags, indicating if certain optional features are enabled.
    for own key, role of A.Roles
      role.blacklist(A.Resources.Inventory.AllTickets.AllTicketsActions.PartnerEvopaySell)            # Requires SESSION.use_partner_evopay_funds
      role.blacklist(A.Resources.Inventory.ViewReportingData)                                         # Requires SESSION.reporting_access
      role.blacklist(A.Resources.Orders.EvoPay.ManageACHFunds)                                        # Requires SESSION.allow_evopay_ach
      role.blacklist(A.Resources.Orders.EvoPay)                                                       # Requires SESSION.allow_evopay
      role.blacklist(A.Resources.PaymentTypes.BuyWithCreditCard)                                      # Requires SESSION.can_buy_with_cc
      role.blacklist(A.Resources.PaymentTypes.CreditCard)                                             # Requires SESSION.has_braintree_credentials
      role.blacklist(A.Resources.PaymentTypes.EvoPay)                                                 # Requires SESSION.allow_evopay
      role.blacklist(A.Resources.Shipping.ShipWithFedEx)                                              # Requires SESSION.has_fedex_credentials
      role.blacklist(A.Resources.Orders.ManageEvopayFunding)                                          # Requires SESSION.manage_evopay_funding
      role.blacklist(A.Resources.Orders.ManageDeliveryEmail)                                          # Requires SESSION.manage_delivery_email
    return

  A.findTrait = (trait) ->
    return A.Traits[trait]

  #<CoreType id: 1, name: "Core POS">,
  #<CoreType id: 2, name: "Core Lite">,
  #<CoreType id: 3, name: "Core Admin">,
  #<CoreType id: 4, name: "Core Partner">]
  A.assignRole = (role = "Core Lite") ->
    A.assignedRole = role
    switch (role)
      when "Core POS" then A.Roles.POS
      when "Core Lite" then A.Roles.POSLite
      when "Core Admin" then A.Roles.POSAdmin
      when "Core Partner" then A.Roles.POSPartner
      when "Travel Agent" then A.Roles.TravelAgent
      when "Affiliate" then A.Roles.Affiliate
      else A.Roles.POSLite

  #// RESOURCE TREE ////////////////////////////////////////////////////////////
  # Root node of Resource Tree.
  A.ResourceTree = null

  ResourceNode = (resource, resourceKey, parentNode) ->
    @resource = resource
    @key = resourceKey
    @parent = parentNode
    @children = []
    @isAllowed = false
    @isDenied = false
    return @

  ResourceNode.prototype.print = (indent) ->
    #console.log(@key, 'isAllowed = ', @isAllowed, 'isDenied = ', @isDenied, 'resource = ', @resource,
    # 'parent = ', @parent, 'children = ', @children)
    col1 = 30
    leftPad = (new Array(indent)).join(' ')
    rightPad = col1 - indent - (@key.length)
    rightPad = (new Array(rightPad)).join(' ')
    allowedDisplay = ''
    if @isAllowed
      allowedDisplay = '✔'
    if @isDenied
      allowedDisplay = '     ✖'
    console.log(leftPad, @key, rightPad, allowedDisplay)

  buildResourceTree = (parentNode) ->
    parentResource = parentNode.resource
    for own childKey, childResource of parentResource
      rNode = new ResourceNode(childResource, childKey, parentNode)
      parentNode.children.push(rNode)
      buildResourceTree(rNode)
  #// ROLE CLASS ///////////////////////////////////////////////////////////////
  Role = () ->
    #@permissionTree = _.clone(A.ResourceTree)
    root = A.Resources
    rootParent = null
    @permissionTree = new ResourceNode(root, 'Resources', rootParent)
    buildResourceTree(@permissionTree)

    # Give Role no permissions by default.
    @blacklist(A.Resources)
    return @

  Role.prototype.print = () ->
    node = @permissionTree
    gen = 1
    @printRecursive(node, gen)
  Role.prototype.printRecursive = (node, gen) ->
    node.print(gen)
    for childNode in node.children
      @printRecursive(childNode, gen + 1)

  Role.prototype.blacklist = (resource) ->
    node = @findNodeForResource(resource)
    @blacklistDownRecursive(node)
  Role.prototype.blacklistDownRecursive = (node) ->
    node.isAllowed = false
    node.isDenied = true
    for childNode in node.children
      @blacklistDownRecursive(childNode)

  Role.prototype.whitelistDown = (resource) ->
    node = @findNodeForResource(resource)
    @whitelistDownRecursive(node)
  Role.prototype.whitelistDownRecursive = (node) ->
    node.isAllowed = true
    node.isDenied = false
    for childNode in node.children
      @whitelistDownRecursive(childNode)

  Role.prototype.whitelist = (resource) ->
    node = @findNodeForResource(resource)
    @whitelistUpRecursive(node)
  Role.prototype.whitelistUpRecursive = (node) ->
    node.isAllowed = true
    node.isDenied = false
    parent = node.parent
    if (parent)
      @whitelistUpRecursive(parent)

  Role.prototype.addTraits = (traits = []) ->
    for trait in traits
      resources = A.findTrait(trait)
     # If the response does not include the trait, skip processing it.
      if(resources != undefined)
        for resource in resources
          @whitelist(resource)

  ###
  TODO
  can & cannot could inherit permissions from parents.  I currently see no need for that.
  Every node will have rules.
  ###
  Role.prototype.can = (resource) ->
    node = @findNodeForResource(resource)
    return node.isAllowed

  Role.prototype.cannot = (resource) ->
    node = @findNodeForResource(resource)
    return node.isDenied

  Role.prototype.findNodeForResource = (resource) ->
    root = @permissionTree
    @findNodeForResourceRecursive(resource, root)
  Role.prototype.findNodeForResourceRecursive = (resource, node) ->
    if (node.resource == resource)
      return node
    else
      for childNode in node.children
        result = @findNodeForResourceRecursive(resource, childNode)
        if (result)
          return result
    return false

  cloneRole = (role) ->
    clone = new Role()
    root = A.Resources
    rootParent = null
    clone.permissionTree = clonePermissionNode(role.permissionTree)
    return clone

  clonePermissionNode = (node, parentNode = null) ->
    cloneNode = new ResourceNode(node.resource, node.key, parentNode)
    cloneNode.children = []
    cloneNode.isAllowed = node.isAllowed
    cloneNode.isDenied = node.isDenied
    for child in node.children
      cloneNode.children.push(clonePermissionNode(child, cloneNode))
    return cloneNode

  #// SETUP ////////////////////////////////////////////////////////////////////
  init = do () -> # Initialize ACL system.
    A.initRoles()
