App.Controllers.Base.BaseController = require('./base/base_controller.coffee')

module.exports = class App.Controllers.ReportController extends App.Controllers.Base.BaseController

  _model = null
  _view = null
  _$container = null
  _xhr = null

  # SALES //////////////////////////////////////////////////////////////////////
  routeSalesIndex: ($container, encodedFilterParams) ->
    @displaySalesIndex({
      $container
      shouldReset: true
      encodedFilterParams
    })

  resetSalesIndex: () ->
    App.router.navigate('/reports/sales') # Don't trigger.
    @displaySalesIndex({
      $container: _$container
      shouldReset: true
    })

  filterSalesIndex: () ->
    encodedFilterParams = _view.getEncodedFilterParams()
    App.router.navigate('/reports/sales/filter/' + encodedFilterParams)  # Don't trigger.
    @displaySalesIndex({
      $container: _$container
      shouldReset: false
      encodedFilterParams
    })

  displaySalesIndex: (options) ->
    {
      $container
      shouldReset
      encodedFilterParams
    } = options
    isGoingToFetch = !!encodedFilterParams # bool cast
    _$container = $container

    if (_xhr)
      _xhr.abort()

    if (!_model || shouldReset)
      _model = new App.Models.V3.SalesCollectionWrapper()

    if (!_view || shouldReset)
      if (_view)
        _view.remove()
      _view = new App.Views.Reports.Sales({
        $container: _$container
        model: _model
      })

    if (isGoingToFetch)
      params = App.Utils.decodeFilterParams(encodedFilterParams)
      _view.reflectFilterParams(params)
      params = _view.getFilterParams()

      _xhr = _model.fetch({
        data: params
        reset: true
      })
      App.Utils.requestKiller.add(_xhr)

    else
      _model.get('collection').reset()
