App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
# Use backbone-validation.
_.extend(parent, Backbone.Validation.mixin)
module.exports = App.Models.V3.PhoneNumber = App.Models.Base.V3BaseModel.extend

  urlRoot: () ->
    if (!@clientId)
      @clientId = @collection.clientId
    return "/api_direct/v9/clients/#{ @clientId }/phone_numbers"

  defaults:
    _isPrimary: false

  initialize: (attributes, options) ->
    {
      @clientId
    } = options
    parent.initialize.call(@, attributes, options)

  validation:
    _number:
      required: true
      rangeLength: [1, 20]
  labels:
    _number: 'Number'

  parse: (response, options) ->
    # We're hackily saving a single model though the collection endpoint.
    if (response.phone_numbers && response.phone_numbers.length)
      return response.phone_numbers[0]
    else
      return response

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.User.Phone)
      attrs._extension = json.extension
      attrs._number = json.number

    if (@getMapping() == C.Mappings.Direct.CreditMemos.HolderPhoneNumbers)
      attrs._countryCode = json.country_code
      attrs._extension = json.extension
      attrs._id = json.id
      attrs._label = json.label
      attrs._number = json.number
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url

    if (@getMapping() == C.Mappings.Direct.CreditMemo.HolderPhoneNumbers)
      attrs._countryCode = json.country_code
      attrs._extension = json.extension
      attrs._id = json.id
      attrs._label = json.label
      attrs._number = json.number
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url

    if (@getMapping() == C.Mappings.Direct.Order.ShipmentPhoneNumber)
      attrs._countryCode = json.country_code
      attrs._extension = json.extension
      attrs._id = json.id
      attrs._label = json.label
      attrs._number = json.number
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Clients.PhoneNumbers)
      attrs._countryCode = json.country_code
      attrs._extension = json.extension
      attrs._id = json.id
      attrs._isPrimary = json.primary || false
      attrs._label = json.label
      attrs._number = json.number
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Order.PhoneNumbers)
      attrs._countryCode = json.country_code
      attrs._extension = json.extension
      attrs._id = json.id
      attrs._label = json.label
      attrs._number = json.number
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # TODO: We need _isPrimary from the api (api_display method) for order show.
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  toJSON: (options = {}) ->
    attrs = @attributes
    json = {}
    json.country_code = attrs._countryCode
    json.extension =    attrs._extension
    json.id =           attrs._id
    json.label =        attrs._label
    json.number =       attrs._number
    json.is_primary =   attrs._isPrimary
    if (
      !options.preventWrapping &&
      @isNew()
    )
      return { phone_numbers: [json] }
    else
      return json

  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      humanized: @humanized()
      cid: @cid
      countryCode: @get('_countryCode') || ''
      extension: @get('_extension') || ''
      id: @get('_id')
      isPrimary: !!@get('_isPrimary')
      label: @get('_label') || ''
      number: @get('_number') || ''

    })
    return presented

  humanized: () ->
    humanized = []

    if (@get('_countryCode'))
      humanized.push('+')
      humanized.push(@get('_countryCode'))
      humanized.push(' ')

    num = @get('_number')
    if (num)
      if (num.length == 10)
        humanized.push(num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'))
      else if (num.length == 7)
        humanized.push(num.replace(/(\d{3})(\d{4})/, '$1-$2'))
      else
        humanized.push(num)

    if (@get('_extension'))
      humanized.push(' x')
      humanized.push(@get('_extension'))

    return humanized.join('')