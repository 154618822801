template = require('./uploads_new.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

module.exports = App.Views.Inventory.UploadsNew = App.Views.Base.BaseView.extend

  template: template

  className: 'drag_drop_upload_area'

  initialize: (options = {}) ->
    { @$container } = options
    @model      = new App.Models.InventoryUpload()
    @loadingBox = new App.Utils.LoadingBox(@$el)
    @render()

  render: () ->

    data = { model: @model.toPresenterJSON() }

    @$container.html(
      @$el.html(
        @template(data)))

    fileChosenCallback = (error, fileModel) =>
      if (error)
        @renderUploadError(error.message)
      else
        @loadingBox.startLoad()
        App.Controllers.inventoryUploadsController.upload(fileModel, (error, response) =>
          if (error)
            @renderUploadError(error.message)
          else
            @renderUploadOutcome(fileModel)
            App.Controllers.inventoryUploadsController.filterIndex()
        )

    @uploader = new App.Utils.FileUploader({
      $fileInputEl: @$('#fileToUpload')
      callback: fileChosenCallback
      $dragDropEl: @$el
      fileTypeFilter: C.FileFilters.CSVorTXT
      fileSizeLimit: 30000000 #~30MB
    })

  renderUploadOutcome: (fileModel) ->
    outcomeView = new App.Views.Order.FileUploadOutcome({
      fileModel
      $container: @$('#fileContainer')
      @loadingBox
    })

  renderUploadError: (errorMessage) ->
    @loadingBox.stopLoad()
    outcomeView = new App.Views.Order.FileUploadOutcome({
      $container: @$('#fileContainer')
      errorMessage
    })
