module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div class="modal-header">\n  <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n  <span class="icon"><i class="fa-solid fa-clock"></i></span>\n  <h3 class="modal-title">Hold Expires</h3>\n</div>\n\n<div class="formErrors" />\n\n<div class="modal-body">\n  <div class="row-fluid">\n    <div class="half">\n      <div class="select">\n        <select class="hold-until-minutes">\n          <option value="5">5 min.</option>\n          <option value="10">10 min.</option>\n          <option value="15">15 min.</option>\n          <option value="30">30 min.</option>\n          <option value="45">45 min.</option>\n          <option value="60">60 min.</option>\n          <option value="90">90 min.</option>\n          <option value="Other" selected="selected">Other</option>\n        </select>\n      </div>\n    </div>\n    <div class="half">\n      <!-- old timepicker <input class="hold-until-picker" type="text"/> -->\n      <div id="holdUntil" style="background: #fff; cursor: pointer; padding: 5px 10px; border: 1px solid #ccc; width: 198px;">\n        <span style="color: #999;" id="dateTime">When</span>\n        <b class="caret" style="color: #999; margin-top: 8px"></b>\n      </div>\n    </div>\n  </div>\n  <div class="clear"></div>\n</div>\n<div class="bottom-buttons modal-footer">\n  <div class=\'filler\'></div>\n  <a href="#" class="btn" data-dismiss="modal">Cancel</a>\n  <a href="#" class="update btn btn-primary">Update</a>\n</div>\n', __filename = "js/views/ticket_holds/edit.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div class="modal-header">\n  <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n  <span class="icon"><i class="fa-solid fa-clock"></i></span>\n  <h3 class="modal-title">Hold Expires</h3>\n</div>\n\n<div class="formErrors" />\n\n<div class="modal-body">\n  <div class="row-fluid">\n    <div class="half">\n      <div class="select">\n        <select class="hold-until-minutes">\n          <option value="5">5 min.</option>\n          <option value="10">10 min.</option>\n          <option value="15">15 min.</option>\n          <option value="30">30 min.</option>\n          <option value="45">45 min.</option>\n          <option value="60">60 min.</option>\n          <option value="90">90 min.</option>\n          <option value="Other" selected="selected">Other</option>\n        </select>\n      </div>\n    </div>\n    <div class="half">\n      <!-- old timepicker <input class="hold-until-picker" type="text"/> -->\n      <div id="holdUntil" style="background: #fff; cursor: pointer; padding: 5px 10px; border: 1px solid #ccc; width: 198px;">\n        <span style="color: #999;" id="dateTime">When</span>\n        <b class="caret" style="color: #999; margin-top: 8px"></b>\n      </div>\n    </div>\n  </div>\n  <div class="clear"></div>\n</div>\n<div class="bottom-buttons modal-footer">\n  <div class=\'filler\'></div>\n  <a href="#" class="btn" data-dismiss="modal">Cancel</a>\n  <a href="#" class="update btn btn-primary">Update</a>\n</div>\n');
            __line = 40;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}