module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<% if (!_.size(uploads)) { %>\n  <h5 style="width: 100%; text-align:center; line-height: 150px;">Please use the filter to find uploads.</h5>\n<% } else { %>\n  <table id="upload_table" class="table table-bordered table-striped table-hover data-table">\n    <thead>\n    <tr>\n      <th>Date     </th>\n      <th>Exchange </th>\n      <th>File     </th>\n      <th>Status   </th>\n      <th>Result   </th>\n    </tr>\n    </thead>\n    <tbody>\n    <%\n    _.each(uploads, function(item, i){\n    %>\n    <tr>\n      <td><%= item.created_at    %></td>\n      <td><%= item.exchange      %></td>\n      <td><%= item.uploaded_file %></td>\n      <td><%- item.status        %></td>\n      <td><%= item.result        %></td>\n    </tr>\n    <%\n    });\n    %>\n  </table>\n<% } %>\n', __filename = "js/views/inventory/uploads_table.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            if (!_.size(uploads)) {
                __append('\n  <h5 style="width: 100%; text-align:center; line-height: 150px;">Please use the filter to find uploads.</h5>\n');
                __line = 3;
            } else {
                __append('\n  <table id="upload_table" class="table table-bordered table-striped table-hover data-table">\n    <thead>\n    <tr>\n      <th>Date     </th>\n      <th>Exchange </th>\n      <th>File     </th>\n      <th>Status   </th>\n      <th>Result   </th>\n    </tr>\n    </thead>\n    <tbody>\n    ');
                __line = 15;
                _.each(uploads, function(item, i) {
                    __line = 17;
                    __append("\n    <tr>\n      <td>");
                    __line = 19;
                    __append(escapeFn(item.created_at));
                    __append("</td>\n      <td>");
                    __line = 20;
                    __append(escapeFn(item.exchange));
                    __append("</td>\n      <td>");
                    __line = 21;
                    __append(escapeFn(item.uploaded_file));
                    __append("</td>\n      <td>");
                    __line = 22;
                    __append(item.status);
                    __append("</td>\n      <td>");
                    __line = 23;
                    __append(escapeFn(item.result));
                    __append("</td>\n    </tr>\n    ");
                    __line = 25;
                });
                __line = 27;
                __append("\n  </table>\n");
                __line = 29;
            }
            __append("\n");
            __line = 30;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}