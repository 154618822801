App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V3.QueuedOrder = require('../../models/v3/queued_order_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V3.QueuedOrders = App.Collections.Base.BaseCollection.extend

  model: App.Models.V3.QueuedOrder

  parse: (response, options) ->
    return response.queued_orders

  comparator: (a, b) ->
    return b.queuedOrderId() - a.queuedOrderId()