_ = require('underscore')
App.Models.Base.PatronBaseModel = require('../base/patron_base_model.coffee')

parent = App.Models.Base.PatronBaseModel.prototype
# Use backbone-validation.
_.extend(parent, Backbone.Validation.mixin)
module.exports = App.Models.V3.Client = App.Models.Base.PatronBaseModel.extend

  urlRoot: "/api_direct/v9/clients"

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  validation:
    _name:
      required: true
    _addresses:
      fn: () ->
        isValid = @get('_addresses').isValid(true)
        if (!isValid)
          return @get('_addresses').errors
    _emailAddresses:
      fn: () ->
        isValid = @get('_emailAddresses').isValid(true)
        if (!isValid)
          console.log(@get('_emailAddresses').errors, @errors)
          return @get('_emailAddresses').errors
    _phoneNumbers:
      fn: () ->
        isValid = @get('_phoneNumbers').isValid(true)
        if (!isValid)
          return @get('_phoneNumbers').errors
  labels: # Humanization.
    _name: 'Client name'

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.EvoQuotes.User)
      attrs._id = json.id
      attrs._name = json.name
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.EvoQuotes.Recepient)
      attrs._email = json.email
      attrs._preparedFor = json.prepared_for
      attrs._name = json.name
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.CreditMemos.Holder)
      attrs._addresses = new App.Collections.V3.Addresses(json.addresses, {
        mapping:  C.Mappings.Direct.CreditMemos.HolderAddress
        clientId: @id
      })
      attrs._company = json.company
      attrs._emailAddresses = new App.Collections.V3.EmailAddresses(json.email_addresses, {
        mapping: C.Mappings.Direct.CreditMemos.HolderEmailAddresses
      })
      attrs._id = json.id
      attrs._name = json.name
      attrs._office = json.office  #Could not find an non-null office for a client
      attrs._phoneNumbers = new App.Collections.V3.PhoneNumbers(json.phone_numbers, {
        mapping: C.Mappings.Direct.CreditMemos.HolderPhoneNumbers
        clientId: @id
      })
      attrs._pnrId = json.pnr_id
      attrs._primaryCreditCard = json.primary_credit_card
      attrs._primaryShippingAddress = new App.Models.V3.Address(json.primary_shipping_address, {
        mapping: C.Mappings.Direct.CreditMemos.HolderAddress
      })
      attrs._tags = json.tags
      attrs._type = json.type
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url

    if (@getMapping() == C.Mappings.Direct.CreditMemo.Holder)
      attrs._addresses = new App.Collections.V3.Addresses(json.addresses, {
        mapping:  C.Mappings.Direct.CreditMemo.HolderAddress
        clientId: @id
      })
      attrs._company = json.company
      attrs._emailAddresses = new App.Collections.V3.EmailAddresses(json.email_addresses, {
        mapping: C.Mappings.Direct.CreditMemo.HolderEmailAddresses
      })
      attrs._id = json.id
      attrs._name = json.name
      attrs._office = json.office  #Could not find an non-null office for a client
      attrs._phoneNumbers = new App.Collections.V3.PhoneNumbers(json.phone_numbers, {
        mapping: C.Mappings.Direct.CreditMemo.HolderPhoneNumbers
        clientId: @id
      })
      attrs._pnrId = json.pnr_id
      attrs._primaryCreditCard = json.primary_credit_card
      attrs._primaryShippingAddress = new App.Models.V3.Address(json.primary_shipping_address, {
        mapping: C.Mappings.Direct.CreditMemo.HolderAddress
      })
      attrs._tags = json.tags
      attrs._type = json.type
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url

    if (@getMapping() == C.Mappings.Direct.Order.Patron)
      attrs._addresses = new App.Collections.V3.Addresses(json.addresses, {
        mapping:  C.Mappings.Direct.Order.Addresses
        clientId: @id
      })
      attrs._company = json.company
      attrs._emailAddresses = new App.Collections.V3.EmailAddresses(json.email_addresses, {
        mapping: C.Mappings.Direct.Order.EmailAddresses
      })
      attrs._id = json.id
      attrs._name = json.name
      attrs._office = new App.Models.V3.Office(json.office, {
        mapping: C.Mappings.Direct.Order.Office
      })
      attrs._phoneNumbers = new App.Collections.V3.PhoneNumbers(json.phone_numbers, {
        mapping: C.Mappings.Direct.Order.PhoneNumbers
        clientId: @id
      })
      attrs._primaryCreditCard = json.primary_credit_card
      attrs._primaryShippingAddress = json.primary_shipping_address
      attrs._tags = json.tags
      attrs._type = json.type
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.OrdersLightweight.Patron)
      attrs._emailAddresses = new App.Collections.V3.EmailAddresses(json.email_addresses, {
        mapping: C.Mappings.Direct.OrdersLightweight.PatronEmailAddresses
      })
      attrs._id = json.id
      attrs._name = json.name
      attrs._phoneNumbers = new App.Collections.V3.PhoneNumbers(json.phone_numbers, {
        mapping: C.Mappings.Direct.OrdersLightweight.PatronPhoneNumbers
        clientId: @id
      })
      attrs._type = json.type
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Clients.Client)
      attrs._addresses = new App.Collections.V3.Addresses(json.addresses, {
        mapping: C.Mappings.Direct.Clients.Addresses
        clientId: @id
      })
      attrs._balance = json.balance
      attrs._company = json.company
      attrs._emailAddresses = new App.Collections.V3.EmailAddresses(json.email_addresses, {
        mapping: C.Mappings.Direct.Clients.EmailAddresses
        clientId: @id
      })
      attrs._id = json.id
      attrs._name = json.name
      attrs._notes = json.notes
      attrs._office = new App.Models.V3.Office(json.office, {
        mapping: C.Mappings.Direct.Clients.Office
      })
      attrs._phoneNumbers = new App.Collections.V3.PhoneNumbers(json.phone_numbers, {
        mapping: C.Mappings.Direct.Clients.PhoneNumbers
        clientId: @id
      })
      attrs._tags = json.tags
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

#        if (json.primary_billing_address)
#          primary = attrs._addresses.get(json.primary_billing_address.id)
#          if (primary)
#            primary.set('_isPrimaryBilling', true)
#          else
#            primary = new App.Models.V3.Address(json.primary_billing_address, {
#              mapping: C.Mappings.Direct.Clients.Addresses
#              clientId: @id
#            })
#            primary.set('_isPrimaryBilling', true)
#            attrs._addresses.add(primary)
#
#        if (json.primary_email_address)
#          primary = attrs._emailAddresses.get(json.primary_email_address.id)
#          if (primary)
#            primary.set('_isPrimary', true)
#          else
#            primary = new App.Models.V3.EmailAddress(json.primary_email_address, {
#              mapping: C.Mappings.Direct.Clients.EmailAddresses
#              clientId: @id
#            })
#            primary.set('_isPrimary', true)
#            attrs._emailAddresses.add(primary)
#
#        if (json.primary_phone_number)
#          primary = attrs._phoneNumbers.get(json.primary_phone_number.id)
#          if (primary)
#            primary.set('_isPrimary', true)
#          else
#            primary = new App.Models.V3.PhoneNumber(json.primary_phone_number, {
#              mapping: C.Mappings.Direct.Clients.PhoneNumbers
#              clientId: @id
#            })
#            primary.set('_isPrimary', true)
#            attrs._phoneNumbers.add(primary)
#
#        if (json.primary_shipping_address)
#          primary = attrs._addresses.get(json.primary_shipping_address.id)
#          if (primary)
#            primary.set('_isPrimaryShipping', true)
#          else
#            primary = new App.Models.V3.Address(json.primary_shipping_address, {
#              mapping: C.Mappings.Direct.Clients.Addresses
#              clientId: @id
#            })
#            primary.set('_isPrimaryShipping', true)
#            attrs._addresses.add(primary)
#
#         attrs._ = json.primary_credit_card: null


    if (@getMapping() == C.Mappings.Direct.TicketCosts.Patron)
      attrs._addresses = new App.Collections.V3.Addresses(json.addresses, {
        mapping: C.Mappings.Direct.TicketCosts.PatronAddresses
      })
      attrs._company = json.company
      attrs._emailAddresses = new App.Collections.EmailAddresses(json.email_addresses, {
        mapping: C.Mappings.Direct.TicketCosts.PatronEmailAddresses
      })
      attrs._id = json.id
      attrs._name = json.name
      attrs._office = json.office
      attrs._phoneNumbers = new App.Collections.V3.PhoneNumbers(json.phone_numbers, {
        mapping: C.Mappings.Direct.TicketCosts.PatronPhoneNumbers
        clientId: @id
      })
      attrs._primaryCreditCard = json.primary_credit_card
      attrs._primaryShippingAddress = json.primary_shipping_address
      attrs._tags = json.tags
      attrs._type = json.type
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # TOTAL GUESS... FIX THESE FIELDS

    if (@getMapping() == C.Mappings.Direct.OrderShowLite.Patron)
      attrs._addresses = new App.Collections.V3.Addresses(json.addresses, {
        mapping: C.Mappings.Direct.OrderShowLite.PatronAddresses
      })
      attrs._company = json.company
      attrs._emailAddresses = new App.Collections.EmailAddresses(json.email_addresses, {
        mapping: C.Mappings.Direct.OrderShowLite.PatronEmailAddresses
      })
      attrs._id = json.id
      attrs._name = json.name
      attrs._office = json.office
      attrs._phoneNumbers = new App.Collections.V3.PhoneNumbers(json.phone_numbers, {
        mapping: C.Mappings.Direct.OrderShowLite.PatronPhoneNumbers
        clientId: @id
      })
      attrs._primaryCreditCard = json.primary_credit_card
      attrs._primaryShippingAddress = json.primary_shipping_address
      attrs._tags = json.tags
      attrs._type = json.type
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.OrderEndpoint.Patron)
      attrs._emailAddresses = json.email_addresses
      attrs._id = json.id
      attrs._name = json.name
      attrs._phoneNumbers = json.phone_numbers
      attrs._type = json.type
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.CreditMemos.CreditMemo.Holder)
      attrs._addresses = new App.Collections.V3.Addresses(json.addresses, {
        mapping: C.Mappings.CreditMemos.CreditMemo.Address
      })
      attrs._company = json.company
      attrs._emailAddresses = new App.Collections.EmailAddresses(json.email_addresses, {
        mapping: C.Mappings.CreditMemos.CreditMemo.EmailAddresses
      })
      attrs._id = json.id
      attrs._name = json.name
      attrs._office = new App.Models.V3.Office(json.office, {
        mapping: C.Mappings.CreditMemos.CreditMemo.Holder
      })
      attrs._phoneNumbers = new App.Collections.V3.PhoneNumbers(json.phone_numbers, {
        mapping: C.Mappings.CreditMemos.CreditMemo.PhoneNumbers
        clientId: @id
      })
      attrs._primaryCreditCard = json.primary_credit_card
      attrs._primaryShippingAddress = json.primary_shipping_address
      attrs._tags = json.tags
      attrs._type = json.type
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  parse: (response, options) ->
    if (response.clients && response.clients.length)
      return response.clients[0]
    return response

  toJSON: () ->
    json = {
      #addresses: @get('_addresses').toJSON()
      company: @get('_company')
      company_id: @get('_companyId')
      #email_addresses: @get('_emailAddresses').toJSON()
      id: @get('_id')
      name: @get('_name')
      notes: @get('_notes')
      office_id: @get('_officeId')
      #phone_numbers: @get('_phoneNumbers').toJSON()
    }
    if (@isNew())
      return { clients: [json] }
    else
      return json

  savePromise: (attributes, options) ->
    # Crazy override - we gotta save all the child models manually.
    copyThatWillLoseChildChanges = @.clone()
    return parent.savePromise.call(copyThatWillLoseChildChanges, attributes, options)
    .then (model) =>
      clientId = copyThatWillLoseChildChanges.id
      @set('id', clientId)
      @set('_id', clientId)
      @get('_addresses').clientId = clientId
      @get('_emailAddresses').clientId = clientId
      @get('_phoneNumbers').clientId = clientId
      promises = []
      promises.push(@get('_addresses').savePromise(attributes, options))
      promises.push(@get('_emailAddresses').savePromise(attributes, options))
      promises.push(@get('_phoneNumbers').savePromise(attributes, options))
      return Q.all(promises)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    addressJSON = @address()?.toPresenterJSON()
    presented = _.extend(@toJSON(), {

      address: addressJSON
      addresses: @get('_addresses')?.toPresenterJSON()
      cityState: addressJSON?.cityState || ''
      company: @get('_company')
      companyName: @get('_company')?.name || ''
      emailAddress: @emailAddress()
      emailAddresses: @get('_emailAddresses')?.toPresenterJSON()
      notes: @get('_notes')
      phoneNumber: @phoneNumber()
      phoneNumbers: @get('_phoneNumbers')?.toPresenterJSON()
      name: @get('_name') || ''
      notes: @get('_notes') || ''
      streetAddress: addressJSON?.streetAddress || ''
      preparedFor: @get('_preparedFor') || ''

    })
    return presented

  address: () ->
    addresses = @get('_addresses')
    if (addresses && addresses.length)
      primaryAddress = addresses.first()
      addresses.forEach((addressModel) ->
        if (addressModel.get('_isPrimary') || addressModel.get('_isPrimaryBilling'))
          primaryAddress = addressModel
      )
      return primaryAddress
    return null

  addresses: () ->
    addresses = @get('_addresses')
    return addresses || new App.Collections.V3.Addresses()

  emailAddresses: () ->
    emails = @get('_emailAddresses')
    return emails || new App.Collections.V3.EmailAddresses()

  emailAddress: () ->
    emails = @get('_emailAddresses')
    if (emails && emails.length)
      # First look for primary
      primary = null
      emails.each((emailModel) ->
        if (emailModel.get('_isPrimary'))
          primary = emailModel.get('_address')
      )
      if (primary)
        return primary
      else
        return emails.first().get('_address')
    else if (@get('_email'))
      return @get('_email')
    else
      return null

  # Fix this once phoneNumbers are v3.
  phoneNumber: () ->
    phoneNumbers = @get('_phoneNumbers')
    if (phoneNumbers?.length)
      if (phoneNumbers instanceof App.Collections.V3.PhoneNumbers)
        primaryPhoneNumber = phoneNumbers.first()
        phoneNumbers.forEach((phoneNumberModel) ->
          if (phoneNumberModel.get('_isPrimary'))
            primaryPhoneNumber = phoneNumberModel
        )
        return primaryPhoneNumber.toPresenterJSON().humanized
      if (phoneNumbers instanceof App.Collections.PhoneNumbers)
        return phoneNumbers.models[0].to_s()
    return null

  phoneNumbers: () ->
    phoneNumbers = @get('_phoneNumbers')
    if (phoneNumbers)
      if (phoneNumbers instanceof App.Collections.V3.PhoneNumbers)
        return phoneNumbers
      else
        console.error("v3 Client phoneNumbers() used when _phoneNumbers is not a v3 PhoneNumber collection.")
        return null
    return null
  #///////////////////////////////////////////////////////////////////////////

  toDataTableRowArray: () ->
    item = @toPresenterJSON()
    notes = ''
    if (item.notes)
      notes = '<span data-toggle="tooltip" data-original-title="' + item.notes + '" class="popover_notes badge badge-default"><i class="fa-solid fa-file-lines"/></span>'
    editLink = '<a class="edit_client_button btn btn-small" style="white-space: nowrap;" data-client-id="' + item.id + '"><i class="fa-solid fa-pen-to-square"></i> View / Edit</a>'
    row = [
      item.name
      item.companyName
      item.streetAddress
      item.cityState
      item.phoneNumber
      item.emailAddress
      notes
      editLink
    ]
    return row

  toPatron: () ->
    mapping = @getMapping()
    patron = new App.Models.V3.Patron(@attributes, {
      type: C.Client
      mapping
    })
    return patron
