template = require('./topnav_evoquote.ejs')
App.Views.TopNavBaseView = require('./topnav_base_view.coffee')

parent = App.Views.TopNavBaseView.prototype
module.exports = App.Views.TopNavEvoquote = App.Views.TopNavBaseView.extend

  template: template

  tagName: 'li'

  update: () ->
    # We have evoquote count globally in App.EvoQuote.count()
    $.noop()

