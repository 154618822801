module.exports = App.Models.TicketGroup = Backbone.Model.extend({
  url: function () {
    if (this.purpose == C.Purposes.EditTicketGroup) {
      // This is used for GET
      return '/api_direct/v9/listings/' + this.get('id');
    } else if (this.purpose == C.Purposes.UpdateTicketGroup) {
      // This is used for PUT
      return '/api_direct/v9/ticket_groups/' + this.get('id');
    } else if (this.get('id') && this.get('eventId')) {
      return '/api_direct/v9/listings/' + this.get('id');
    } else if (!this.get('eventId') && !this.get('id')) {
      throw 'TicketGroup must have an id and an eventId';
    } else if (this.get('eventId')) {
      throw 'TicketGroup must have an id';
    } else if (this.get('id')) {
      return '/api_direct/v9/listings/' + this.get('id');
    }
  },

  initialize: function (attributes, options) {
    // this.set('seat_range', this.getSeatRange());
    this.set('seat_range', this.getSeatRangeStrict());
    this.set('eticket', this.get('eticket'));
    var options = options || {};
    this.purpose = options.purpose;
  },

  flags: function (fields) {
    fields = fields || this.attributes;
    var flags = '';

    // SPEC
    if (fields.has_spec_tickets || fields.ticket_group_speculative == 't') {
      flags += C.FlagBadges.Spec;
    }

    // ETICKET
    if (fields.eticket || fields.ticket_group_eticket) {
      flags += C.FlagBadges.Eticket();
    }

    // NOTES
    if (
      fields.ticket_group_broker_notes ||
      fields.ticket_group_external_notes ||
      fields.ticket_group_internal_notes ||
      fields.exchange_notes ||
      fields.private_notes ||
      fields.public_notes
    ) {
      flags += C.FlagBadges.Notes;
    }

    // CONSIGNMENT
    if (fields.ticket_group_consignment) {
      flags += C.FlagBadges.Consignment;
    }

    return flags;
  },

  toPresenterJSON: function () {
    var that = this;
    var presented = _.extend(this.toJSON(), {
      // FIELDS FOR INVENTORY REPORT /////////////////////////////////////////

      availableSeatDetails: (function () {
        return that.availableSeatDetails();
      })(),

      type: (function () {
        var f = that.get('ticket_group_type');
        return (f && C.Options.TicketGroup.Types[f]) || '';
      })(),

      quantity: (function () {
        var a = that.get('ticket_group_tickets_count');
        var b = that.get('quantity');
        return a || b || '';
      })(),

      section: (function () {
        var a = that.get('ticket_group_section');
        var b = that.get('section');
        return a || b || '';
      })(),

      row: (function () {
        var a = that.get('ticket_group_row');
        var b = that.get('row');
        return a || b || '';
      })(),

      displaySeats: (function () {
        var a = that.get('ticket_group_ticket_seats');
        if (a) {
          return App.Utils.seatsToSeatRange(a);
        }
        var b = that.get('seats');
        if (b) {
          return App.Utils.seatsToSeatRange(b);
        }
        return '';
      })(),

      cost: (function () {
        var f = that.get('ticket_group_cost');
        return (f && App.Utils.valueToCurrency(f)) || '';
      })(),

      wholesale: (function () {
        var f = that.get('ticket_group_price');
        return (f && App.Utils.valueToCurrency(f)) || '';
      })(),

      retail: (function () {
        var f = that.get('ticket_group_retail_price_override');
        return (f && App.Utils.valueToCurrency(f)) || '';
      })(),

      flags: that.flags(),

      format: that.get('ticket_group_format') || '',

      view: that.get('ticket_group_view_type') || '',

      isConsignment: (function () {
        if (that.get('ticket_group_consignment')) {
          return '<span class="label label-info">Yes</span>';
        }
        return '';
      })(),

      eventDate: (function () {
        var f = that.get('event_occurs_at');
        if (f) {
          return App.Utils.makeTimestampHumanForEvent(
            f,
            C.DateFormats.TableDateWithTime
          );
        }
        return '';
      })(),

      createdDate: (function () {
        var f = that.get('ticket_group_created_at');
        if (f) {
          return App.Utils.makeTimestampHuman(
            f,
            C.DateFormats.TableDateWithTime
          );
        }
        return '';
      })(),

      venue: that.get('venue_name') || '',

      performer: that.get('performer_name') || '',

      // Consistency now enforced in inventory_table.js
      state: (function () {
        var states = that.get('ticket_group_ticket_states');
        if (states && states.length) {
          var firstState = states[0];
          return C.Options.TicketGroup.States[firstState];
        }
        return '';
      })(),

      priceRetail: this.get('retail_price'),
      priceWholesale: this.get('wholesale_price'),

      //          if (states) {
      //            if (states.length == 0) {
      //              return '';
      //            } else {
      //              var firstState = states[0];
      //              var consistent = _.every(states, function(state) {
      //                return (state == firstState);
      //              });
      //              if (consistent) {
      //                return C.Options.TicketGroup.States[firstState];
      //              }
      //            }
      //          }
      //          return '';
      //        })()
    });
    return presented;
  },

  availableSeatDetails: function () {
    var that = this;
    var seats = that.get('ticket_group_ticket_seats');
    var states = that.get('ticket_group_ticket_states');
    var ticketIds = that.get('ticket_group_ticket_ids');
    var availableSeats = [];
    if (seats && states && ticketIds) {
      for (var i = 0; i < seats.length; i++) {
        seat = seats[i];
        state = states[i];
        ticketId = ticketIds[i];
        if (state == 'available') {
          availableSeats.push({
            seat: seat,
            state: state,
            ticketId: ticketId,
          });
        }
      }
    }
    availableSeats = availableSeats.sort(function (a, b) {
      return parseInt(a.seat) - parseInt(b.seat);
    });
    return availableSeats;
  },

  fetch: function () {
    var deferred = $.Deferred(),
      that = this;

    $.when(Backbone.Model.prototype.fetch.apply(this, arguments)).then(
      function () {
        that.buildTickets();
        deferred.resolve();
      }
    );

    return deferred;
  },

  // displayFields could be done via presenter (next function down).
  parse: function (json) {
    var attrs = _.clone(json); // WARNING: Shallow copy.

    //if (!Models.TicketGroup.Types[json.ticket_group_type]) { console.log('WARNING: Models.TicketGroup.Types missing ', json.ticket_group_type, json); }
    //if (!Models.TicketGroup.Formats[json.ticket_group_format]) { console.log('WARNING: Models.TicketGroup.Formats missing ', json.ticket_group_format, json); }
    //if (!Models.TicketGroup.ViewTypes[json.ticket_group_view_type]) { console.log('WARNING: Models.TicketGroup.ViewTypes missing ', json.ticket_group_view_type, json); }

    attrs.id = json.id || json.ticket_group_id; // We can't use idAttribute in this instance so manually set id.
    attrs.displayQuantity = json.ticket_group_tickets_count;
    attrs.displaySection = json.ticket_group_section;
    attrs.displayRow = json.ticket_group_row;
    attrs.automated = !!json.automated;

    //      attrs.displaySeats = json.ticket_group_ticket_seats && (function() {
    //        var seats = json.ticket_group_ticket_seats;
    //        if (seats.length == 0) {
    //          return '';
    //        } else if (seats.length == 1) {
    //          return seats[0];
    //        } else {
    //          seats = seats.sort();
    //          return _.first(seats) + ' - ' + _.last(seats);
    //        }
    //      })();
    attrs.displaySeats =
      json.ticket_group_ticket_seats &&
      App.Utils.seatsToSeatRange(json.ticket_group_ticket_seats);

    attrs.displayFaceValue = App.Utils.valueToCurrency(json.face_value);
    attrs.displayWholesale = App.Utils.valueToCurrency(
      json.wholesale_price || json.ticket_group_price
    );
    attrs.displayCost = App.Utils.valueToCurrency(json.ticket_group_cost);
    attrs.displayRetail = App.Utils.valueToCurrency(
      json.retail_price || json.ticket_group_retail_price_override
    );
    attrs.displayBroadcast = json.ticket_group_broadcast
      ? '<i class="fa-solid fa-rss fa-lg" style="color: #62c462"></i> On'
      : 'Off';
    attrs.displayNotes =
      json.ticket_group_broker_notes ||
      json.ticket_group_external_notes ||
      json.ticket_group_internal_notes
        ? '<a class="notes_link" data-ticket-group-id="' +
          attrs.ticket_group_id +
          '" href="#"><i class="fa-solid fa-file-alt fa-lg"> </i></a>'
        : '';
    attrs.displayName = json.event && json.event.name;
    attrs.displayOccursAt =
      json.event &&
      json.event.occurs_at &&
      App.Utils.makeTimestampHumanForEvent(
        json.event.occurs_at,
        C.DateFormats.Long
      );
    attrs.displayInHand =
      typeof json.in_hand != 'undefined' && (json.in_hand ? 'Yes' : 'No');

    if (json.event_occurs_at) {
      var summary = [];
      summary.push(json.event_name);
      summary.push(' on ');
      summary.push(
        App.Utils.makeTimestampHumanForEvent(
          json.event_occurs_at,
          C.DateFormats.Long
        )
      );
      summary.push(' at ');
      summary.push(json.venue_name);
      summary.push(' (');
      summary.push(json.venue_address_locality);
      summary.push(', ');
      summary.push(json.venue_address_region);
      summary.push(')');
      attrs.displaySummary = summary.join('');
    }

    attrs.displayFlags = this.flags(json);

    return attrs;
  },

  /*
  toPresenterJSON: function() {
    var that = this;
    var presented = _.extend(this.toJSON(), {

    });
    return presented;
  },
  */

  availability: function () {
    if (this.get('in_hand')) {
      return 'In-Hand';
    } else {
      if (this.get('in_hand_on')) {
        var inHandOn = 'In-Hand On ',
          time = moment(this.get('in_hand_on')).format('MMMM Do, YYYY');
        return inHandOn + time;
      } else {
        return 'Not In-Hand';
      }
    }
  },

  buildTickets: function () {
    this.set(
      'tickets',
      _.collect(this.get('tickets'), function (ticket) {
        return new App.Models.V2.Ticket({
          seat: ticket[1],
          state: ticket[0],
        });
      })
    );
  },

  cost: function () {
    if (this.get('ticket_costs')[0] === 'NULL') {
      return 'N/A';
    } else {
      return App.Utils.valueToCurrency(this.get('ticket_costs')[0]);
    }
  },

  faceValue: function () {
    if (this.get('face_value')) {
      return App.Utils.valueToCurrency(this.get('face_value'));
    } else {
      return '';
    }
  },

  format: function () {
    if (this.get('eticket') || this.get('ticket_group_eticket')) {
      return C.TicketFormats.Eticket;
    } else {
      return C.TicketFormats.Physical;
    }
  },

  fetchHolds: function () {
    var deferred = $.Deferred(),
      that = this,
      collection = new App.Collections.V2.TicketHolds(null, {
        eventId: this.get('eventId'),
        ticketGroupId: this.get('id'),
        mapping: C.Mappings.HeldTicketEndpoint.TicketHolds,
      });

    $.when(collection.fetch()).then(function () {
      that.set('ticketHolds', collection);
      deferred.resolve();
    });

    return deferred;
  },

  purchaseOrderNumber: function () {
    if (this.get('ticket_purchase_order_ids')[0] === 'NULL') {
      return '';
    } else {
      return this.get('ticket_purchase_order_ids')[0];
    }
  },

  validate: function () {
    var self = this;
    var errors = (this.errors = []);

    var requiredFields = [
      'quantity',
      'section',
      'row',
      'low_seat',
      'seat_order',
      'wholesale_price',
    ];

    var numericalFields = ['quantity', 'low_seat'];

    var dollarAmountFields = ['face_value', 'wholesale_price', 'retail_price'];

    if (!this.get('isSpec')) {
      requiredFields.push('cost');
      dollarAmountFields.push('cost');

      if (this.get('cost') < 0.0) {
        self.errors.push('Cost must be 0.00 or more');
      }
    }

    this.validates(numericalFields, { numericality: true });
    this.validates(requiredFields, { presence: true });
    this.validates(dollarAmountFields, { currency: true });

    _.each(['wholesale_price', 'retail_price'], function (attr) {
      if (self.get(attr) && App.Utils.currencyToValue(self.get(attr)) < 0.0) {
        self.errors.push(attr.humanize() + ' must be $0.00 or greater');
      }
    });

    if (!this.get('in_hand')) {
      this.validates('in_hand_on', { presence: true });
    }
  },

  validateEditOne: function () {
    var self = this;
    var errors = (this.errors = []);

    var requiredFields = [
      'type',
      'view_type',
      'office_id',
      'section',
      'row',
      'price',
    ];

    var dollarAmountFields = [
      'price',
      'face_value',
      'cost',
      'wholesale_price',
      'retail_price',
    ];

    if (this.get('retail_price_override')) {
      dollarAmountFields.push('retail_price_override');
    }

    this.validates(requiredFields, { presence: true });
    this.validates(dollarAmountFields, { currency: true });

    _.each(['price', 'retail_price'], function (attr) {
      if (self.get(attr) && App.Utils.currencyToValue(self.get(attr)) < 0.0) {
        self.errors.push(attr.humanize() + ' must be $0.00 or greater');
      }
    });

    if (!this.get('in_hand')) {
      this.validates('in_hand_on', { presence: true });
    }
    if (!!this.get('customSplitsCheckbox')) {
      this.validates('split_override', { presence: true });
    }
  },

  isValid: function () {
    this.validate();
    return _.isEmpty(this.errors);
  },

  isValidEditOne: function () {
    this.validateEditOne();
    return _.isEmpty(this.errors);
  },

  highSeat: function () {
    var multiplier = this.get('seat_order') === C.SeatOrder.Consecutive ? 1 : 2;
    var quantity = +this.get('quantity');
    var lowSeat = +this.get('low_seat');

    return lowSeat + (quantity - 1) * multiplier;
  },

  seatsInState: function (state) {
    return _.chain(this.get('tickets'))
      .select(function (ticket) {
        if (state) {
          if (ticket.get('state') === state) {
            return ticket;
          }
        } else {
          return ticket;
        }
      })
      .collect(function (ticket) {
        return +ticket.get('seat');
      })
      .sortBy(function (seat) {
        return seat;
      })
      .value();
  },

  canWaste: function () {
    return (
      this.get('ticket_states').available || this.get('ticket_states').consigned
    );
  },

  owned: function () {
    if (SESSION.office_id === this.get('office').id) {
      return true;
    } else {
      return false;
    }
  },

  isBroadcast: function () {
    return this.get('broadcast') === 't' || this.get('broadcast') === true;
  },

  getSeatRange: function () {
    if (
      (this.get('seats') && this.get('seats').length) ||
      this.get('low_seat')
    ) {
      var low_seat, multiplier;

      if (this.get('seat_order')) {
        multiplier = this.get('seat_order') === C.SeatOrder.Consecutive ? 1 : 2;
      } else {
        multiplier = 1;
      }

      if (_.isFinite(+this.get('low_seat'))) {
        low_seat = +this.get('low_seat');
      } else {
        low_seat = +_.sortBy(this.get('seats'), function (seat) {
          return +seat;
        })[0];
      }

      var quantity = +this.get('quantity');
      quantity = quantity === 0 ? this.get('seats').length : quantity;
      var high_seat = low_seat + (quantity - 1) * multiplier;
      return low_seat + ' - ' + high_seat;
    } else {
      return 'N/A';
    }
  },

  //The strict kicks out non-available tickets from the range
  ticketIsAvailable: function (ticket) {
    var not_available, no_seat, hold_date, on_hold, current_date;

    (current_date = new Date()), (not_available = ticket.state !== 'available');
    no_seat = ticket.seat === 0;
    hold_date = new Date(ticket.hold_until + 'Z');
    on_hold = ticket.hold_id !== 0 && hold_date >= current_date;

    return not_available || no_seat || on_hold ? false : true;
  },

  getSeatRangeStrict: function () {
    var available_seats, groups, current_group, current_number, condenseGroup;
    //Gate the function
    if (!this.get('ticket_list')) {
      return this.getSeatRange();
    }
    //used later
    //filter ticket list, grab seats, and sort
    available_seats = _.pluck(
      _.filter(this.get('ticket_list'), this.ticketIsAvailable),
      'seat'
    );
    available_seats = available_seats.sort(function (a, b) {
      return a - b;
    });
    //loop through and create array with each index as a group of consecutive tickets
    return App.Utils.seatsToSeatRange(available_seats);
  },

  states: function () {
    var states = _.map(this.get('ticket_states'), function (value, key) {
      var ticket_s = value === 1 ? 'ticket' : 'tickets';

      return value + ' ' + ticket_s + ' ' + key;
    });

    return states;
  },

  take: function (options) {
    var url =
      '/api_direct/v9/events/' +
      this.get('event').id +
      '/ticket_groups/' +
      this.get('id') +
      '/take';
    return this.holdOrTake(url, options);
  },

  hold: function (options) {
    var url =
      '/api_direct/v9/events/' +
      this.get('event').id +
      '/ticket_groups/' +
      this.get('id') +
      '/hold';
    return this.holdOrTake(url, options);
  },

  holdOrTake: function (url, options) {
    var data = {};
    data.low_seat = options.lowSeat;
    data.quantity = options.quantity;
    data.held_for_id = options.heldForId;
    data.held_for_type = options.heldForType;
    data.hold_until = options.holdUntil;
    data.price = options.price;
    data.notes = options.notes;

    return $.post(url, data);
  },

  wholesalePriceDisplay: function () {
    return App.Utils.valueToCurrency(this.get('wholesale_price'));
  },

  waste: function (wasted_reason) {
    return $.ajax({
      type: 'POST',
      url: this.url() + '/waste',
      dataType: 'html',
      data: JSON.stringify({ wasted_reason: wasted_reason }),
    });
  },

  broadcast: function (toggle) {
    return $.ajax({
      type: 'POST',
      url: this.url() + '/broadcast',
      data: JSON.stringify({
        broadcast: toggle,
      }),
    });
  },

  quantitySeparated: function () {
    var quantity = this.get('quantity'),
      held,
      reserved,
      heldReserved,
      sold;

    held = this.get('ticket_states') ? this.get('ticket_states').held : 0;
    held = held ? held : 0;
    reserved = this.get('ticket_states')
      ? this.get('ticket_states').reserved
      : 0;
    reserved = reserved ? reserved : 0;
    heldReserved = held + reserved;
    sold = this.get('ticket_states') ? this.get('ticket_states').sold : 0;
    sold = sold ? sold : 0;
    quantity = quantity - heldReserved - sold;

    if (held) {
      return quantity + ' (' + held + ')';
    } else {
      return quantity;
    }
  },

  brokerage_abbreviation: function () {
    return this.get('office').brokerage.abbreviation.toLowerCase();
  },

  toJSON: function (options) {
    if (this.purpose == C.Purposes.EditTicketGroup) {
      this.purpose = null;
      var json = Backbone.Model.prototype.toJSON.call(this, options);
      delete json.eventId;
      delete json.event;
      delete json.featured;
      delete json.signature;
      delete json.office;
      delete json.status_code;
      delete json.server_message;
      delete json.displayFaceValue;
      delete json.displayWholesale;
      delete json.displayCost;
      delete json.displayRetail;
      delete json.displayBroadcast;
      delete json.displayNotes;
      delete json.displayName;
      delete json.displayOccursAt;
      delete json.displayInHand;
      delete json.displayFlags;
      delete json.formatDropdown;
      delete json.customSplitsCheckbox;
      delete json.displayQuantity;
      delete json.displayRow;
      delete json.displaySeats;
      delete json.displaySection;
      return json;
    } else {
      return Backbone.Model.prototype.toJSON.call(this, options);
    }
  },
});
