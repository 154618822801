template = require('./dashboard.ejs')
App.Views.Base.BaseView = require('./base/base_view.coffee')

DEFAULT_UPCOMING_EVENTS = 5

module.exports = App.Views.Dashboard = App.Views.Base.BaseView.extend

  template: template

  initialize: (options) ->
    {
      @$container
      @eventsCollection
      @evoPayTransactions
    } = options

    promises = []

    promise = @eventsCollection.fetchPromise()
    App.Utils.requestKiller.add(promise.xhr)
    promises.push(promise)

    promises.push(@fetchPinsPromise())

    if (SESSION && SESSION.office)
      @officeModel = SESSION.office
    else if (SESSION && SESSION.office_id)
      @officeModel = new App.Models.V3.Office({
        id: SESSION.office_id
      }, {
        mapping: C.Mappings.Direct.Offices.Office
      })

    promise = @officeModel.fetchPromise()
    App.Utils.requestKiller.add(promise.xhr)
    promises.push(promise)

    Q.all(promises)
    .then (result) =>
      @render()
      @upcomingEventsLoadingBox.stopLoad()
      @pinnedEventsLoadingBox.stopLoad()
      @fetchEvopayTransactions()
    .fail (errors) =>
      console.log(errors)
      @upcomingEventsLoadingBox.stopLoad()
      @pinnedEventsLoadingBox.stopLoad()
    .done()

    @upcomingEventsNumber = DEFAULT_UPCOMING_EVENTS

    @render()

    @upcomingEventsLoadingBox = new App.Utils.LoadingBox(@$('#upcomingEvents'))
    @upcomingEventsLoadingBox.startLoad()
    @pinnedEventsLoadingBox = new App.Utils.LoadingBox(@$('#pinnedEvents'))
    @pinnedEventsLoadingBox.startLoad()

    @formErrors = new App.Utils.FormErrorManager(@$('.formErrors'))
    return @$el

  fetchPinsPromise: () ->
    @pinsCollection = new App.Collections.V3.EventPins(null, {
      mapping: C.Mappings.Direct.EventPins.EventPin
    })
    promise = @pinsCollection.fetchPromise()
    xhr = promise.xhr
    App.Utils.requestKiller.add(xhr)
    return promise

  fetchEvopayTransactions: () ->
    promise = @evoPayTransactions.fetch({
      data: {
        state: "completed"
      }
    })
    App.Utils.requestKiller.add(promise)

    promise
    .then (result) =>
      @$('#lastEvopayTransaction').text(@getLastEvoPayTransaction())
      @$('#evopayBalance').text(App.Utils.valueToCurrency(@evoPayTransactions.get('balance')))
    .fail (errors) =>
      console.log(errors)
    .done()

  render: () ->
    data = {
      events: @eventsCollection.toPresenterJSON()
      @evoPayTransactions
      lastEvoPayTransaction: @getLastEvoPayTransaction()
      pins: @pinsCollection.toPresenterJSON()
      office: @officeModel.toPresenterJSON()
    }
    @$container.html(
      @$el.html(
        @template(
          data
        )
      )
    )
    @$('#upcomingEventsNumber').val(@upcomingEventsNumber)

    @delegateEvents()

  # VIEW EVENTS //////////////////////////////////////////////////////////////
  events:
    'change #upcomingEventsNumber': 'onEventsNumberChanged'
    'click .pinButton' : 'onPinButtonClicked'

  onPinButtonClicked: (e) ->
    $pin = $(e.currentTarget)
    $i = $pin.find('i')
    $i.addClass('fa-rotate fa-spin')

    pinId = $pin.data().pinId
    eventId = $pin.data().eventId
    pinModel = @pinsCollection.get(pinId)

    if (pinModel) # isPinned
      pinModel.destroyPromise()
      .then (model) =>
        $i.removeClass('fa-rotate fa-spin').removeClass('fa-solid fa-star').addClass('fa-regular fa-star')
      .fail (errors) =>
        @formErrors.show({
          title: 'Error removing event pin.'
          errors
        })
        $i.removeClass('fa-rotate fa-spin')
      .done()
    else
      pinAttrs = {
        _eventId: eventId
      }
      @pinsCollection.createPromise(pinAttrs)
      .then (model) =>
        $pin.data().pinId = model.id
        $i.removeClass('fa-rotate fa-spin').removeClass('fa-regular fa-star').addClass('fa-solid fa-star')
      .fail (errors) =>
        @formErrors.show({
          title: 'Error pinning event.'
          errors
        })
        $i.removeClass('fa-solid fa-rotate fa-spin')
      .done()

  onEventsNumberChanged: (e) ->
    @upcomingEventsNumber = $('#upcomingEventsNumber').val()
    @render()

  getLastEvoPayTransaction: () ->
    lastEvoPayTransactionModel = _.first(@evoPayTransactions.get('transactions').models)
    if (lastEvoPayTransactionModel)

      return lastEvoPayTransactionModel.get('displayDate')
    else
      return 'No EvoPay Transactions.'
