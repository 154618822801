template = require('./show.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

NEW_COMPANY_ID = -1

MODAL_WRAP_TOP = "<div class='modal-header'>
<button type='button' class='close' data-dismiss='modal' aria-hidden='true'>&times;</button>
<span class='icon'><i class='fa-solid fa-user'></i></span>
<h3 class='modal-title'>New Client</h3>
</div>
<div class='modal-body' style='background: #fff;'>"

MODAL_WRAP_BOTTOM = "</div>
<div class='modal-footer'>
<a id='saveClientButton' class='btn pull-right btn-large btn-primary'>Save Client</a>
</div>"

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Clients.Show = App.Views.Base.BaseView.extend

  template:   template

  initialize: (options = {}) ->
    {
      @client
      @$container
      @isModal
      @isBuySellWizard
      @onSave
    } = options

    if (@isModal || @isBuySellWizard)
      @client = new App.Models.V3.Client(null, {
        mapping: C.Mappings.Direct.Clients.Client
      })

    @render()

  render: (isRerender = false) ->

    clientFormHtml = @template(
      _.extend(@client.toPresenterJSON(), {
        @isModal
        @isBuySellWizard
      })
    )

    if (@isModal)

      if (isRerender)
        @$el.find('.modal-body').html(clientFormHtml)
      else
        @className = 'modal hide fade'

        $('body').append(
          @$el.html(
            MODAL_WRAP_TOP +
            clientFormHtml +
            MODAL_WRAP_BOTTOM
          )
        )

        windowWidth = $(window).width()
        windowHeight = $(window).height()
        HPAD = 20
        VPAD = 160
        height = windowHeight - VPAD;
        width = windowWidth - HPAD;
        isFullScreen = true
        options = {
          width
          maxHeight: height
          isFullScreen
        }

        # STANDARDIZED MODAL
        @$el.modal(options, 'show').on('hidden', () =>
          @remove()
        )

    else

      @$container.html(
        @$el.html(
          clientFormHtml
        )
      )

      if (@isBuySellWizard && !isRerender)
        @onAddButtonClick(null, 'emailAddress')

    $el = @$('#companyNameAutocomplete')
    val = @client.get('_company') && @client.get('_company').id
    App.Utils.companyAutocomplete({
      $el
      val
      isRerender
    })

    @localityAutocompletes = {}
    $countrySelects = @$('.countrySelect')
    $stateSelects = @$('.regionSelect')
    for i in [0...$countrySelects.length]
      $country = $countrySelects.eq(i)
      $state = $stateSelects.eq(i)
      $tr = $country.closest('tr')
      cid = $tr.data().cid
      address = @client.get('_addresses').get(cid)
      countryVal = address.get('_countryCode')
      stateVal = address.get('_region')
      defaultCountry = 'US'
      @localityAutocompletes[cid] = new App.Utils.LocalityAutocomplete({
        $country
        $state
        defaultCountry
        countryVal
        stateVal
      })

    @resizeNotes()

    if (@isNewClient())
      @$("[name=name]").focus()

    @formErrors = new App.Utils.FormErrorManager(@$('#formErrors'))
    @loadingBox = new App.Utils.LoadingBox(@$('.tab-pane'))
    @loadingButton = new App.Utils.LoadingButton(@$('#saveClientButton'))
    @loadingButtonModal = new App.Utils.LoadingButton($('#saveClientButton'))
    @renderInfoPopover()
    @delegateEvents()

  renderInfoPopover: () ->
    App.Utils.infoPopover(
      @$('#email-address-info'),
      {
        content: "Please provide your email address or your client's email address Please note that we will send out emails with updates about your orders to this email address.",
        title: "Email Address"
        placement: "right"
      }
    )

  isNewClient: () ->
    return !@client.id

  events:
    'click .addButton': 'onAddButtonClick'
    'click .removeButton': 'onRemoveButtonClick'
    'click #saveClientButton': 'onSaveClientButtonClick'
    'click #cancelButton': 'onCancelButtonClick'
    'keyup #notes': 'resizeNotes'

  onAddButtonClick: (e, model = null) ->
    @saveFormToClientModel()

    if (!model)
      $el = $(e.currentTarget)
      model = $el.data().model

    clientId = @client.id
    if (model == 'phoneNumber')
      @client.get('_phoneNumbers').add(new App.Models.V3.PhoneNumber(null, {
        mapping: C.Mappings.Direct.Clients.PhoneNumbers
        clientId
      }))
    else if (model == 'emailAddress')
      @client.get('_emailAddresses').add(new App.Models.V3.EmailAddress(null, {
        mapping: C.Mappings.Direct.Clients.EmailAddresses
        clientId
      }))
    else if (model == 'address')
      @client.get('_addresses').add(new App.Models.V3.Address(null, {
        mapping: C.Mappings.Direct.Clients.Addresses
        clientId
      }))
    isRerender = true
    @render(isRerender)

  onCancelButtonClick: (e) ->
    @onSave?(null)

  onRemoveButtonClick: (e) ->
    $el = $(e.currentTarget)
    data = $el.data()
    model = data.model
    cid = data.cid

    confirm = new App.Views.Shared.BasicModal({
      isConfirmation: true
      header: "Are you sure?"
      message: "Remove this field from the client?"
      onYes: () =>
        confirm.$el.modal('hide')
        if (model == 'phoneNumber')
          coll = @client.get('_phoneNumbers')
        else if (model == 'emailAddress')
          coll = @client.get('_emailAddresses')
        else if (model == 'address')
          coll = @client.get('_addresses')
        model = coll.get(cid)
        @destroyNestedModel(model)
    })

  saveFormToClientModel: () ->
    @$('tr').removeClass('alert').removeClass('alert-error')
    @client.set('_name', @$('#name').val())

    # Company
    $input = $('#companyNameAutocomplete')
    companyData = $input.select2('data')
    if (companyData)
      id = companyData.id
      text = companyData.text
      company = {}
      # Decide if we're creating a new company or sending the id of an existing one.
      if (id == NEW_COMPANY_ID)
        company.name = text
        # Force refresh of companyOptions
        App.Utils.companyAutocomplete.companyOptions = null
      else
        company.id = id
      @client.set('_company', company)
    else
      @client.set('_company', null)

    processRows = ($rows, coll, isAddress = false) =>

      $rows.each((index, tr) =>
        $tr = $(tr)
        data = $tr.data()
        cid = data.cid
        if (cid)
          model = coll.get(cid)
          attrs = $tr.serializeObject(false)

          if (model instanceof App.Models.V3.Address)
            attrs._isPrimaryShipping = !!(attrs._isPrimaryShipping)
            attrs._isPrimaryBilling = !!(attrs._isPrimaryBilling)
          else # phone or email
            attrs._isPrimary = !!(attrs._isPrimaryPhoneNumber || attrs._isPrimaryEmailAddress)

          if (isAddress)
            attrs._region = @localityAutocompletes[cid].getState()
            attrs._countryCode = @localityAutocompletes[cid].getCountry()

          model.set(attrs)
      )

    # Phone Numbers
    coll = @client.get('_phoneNumbers')
    $rows = @$('#phoneNumbers tbody tr')
    processRows($rows, coll)

    # Email Addresses
    coll = @client.get('_emailAddresses')
    $rows = @$('#emailAddresses tbody tr')
    processRows($rows, coll)

    # Addresses
    coll = @client.get('_addresses')
    $rows = @$('#addresses tbody tr')
    isAddress = true
    processRows($rows, coll, isAddress)

    # Notes
    @client.set('_notes', @$('#notes').val())

    # Office ID.
    @client.set({
      _officeId: SESSION.office_id
    })

  onSaveClientButtonClick: (e) ->
    @saveClient()

  saveClient: () ->
    @startLoad()
    @saveFormToClientModel()

    @$('.validationError').remove()

    if (@client.isValid(true))
      @client.savePromise()
      .then (model) =>

        @client.fetchPromise()
        .then (model) =>

          if (@isModal)
            @$el.modal('hide')
          else
            isRerender = true
            @render(isRerender)
            if (!@isModal && !@isBuySellWizard)
              App.Controllers.clientShowController.afterSave(@client)
            @showMessage({
              type: 'success'
              title: 'Client saved successfully.'
            })
            @onSave?(@client)

          @trigger('clientFormComplete', @client)

      .fail (error) =>
        console.error(error)
        @showMessage({
          title: 'Error saving client.'
          errors: error
        })
      .done()
    else

      console.log('client errors', @client.errors)
      topLevelErrros = _.pick(@client.errors, '_name', '_company')
      @showMessage({
        title: 'Cannot save an invalid client.'
        errors: topLevelErrros
      })

      phoneErrs = @client.errors._phoneNumbers
      if (phoneErrs)
        selector = '#phoneNumbers'
        for own cid, errorObj of phoneErrs
          @highlightErrors(selector, cid, errorObj)
      emailErrs = @client.errors._emailAddresses
      if (emailErrs)
        selector = '#emailAddresses'
        for own cid, errorObj of emailErrs
          @highlightErrors(selector, cid, errorObj)
      addressErrs = @client.errors._addresses
      if (addressErrs)
        selector = '#addresses'
        for own cid, errorObj of addressErrs
          @highlightErrors(selector, cid, errorObj)

  highlightErrors: (selector, cid, errorObj) ->
    errorHtml = []
    $table = @$(selector)
    colspan = $table.find('tbody tr:first td').length
    errorHtml.push("<tr class='validationError alert alert-error'><td colspan='#{ colspan }'><ul>")
    for own attr, error of errorObj
      errorHtml.push("<li>#{ error }</li>")
    errorHtml.push('</ul></td></tr>')
    $tr = $table.find("tr[data-cid=#{ cid }]").addClass('alert alert-error')
    $tr.after(errorHtml.join(''))

  destroyNestedModel: (model) ->
    @startLoad()
    model.destroyPromise()
    .then (model) =>
      isRerender = true
      @render(isRerender)
      @showMessage({
        type: 'success'
        title: 'Client saved successfully.'
      })
    .fail (error) =>
      console.error(error)
      @showMessage({
        title: 'Error saving client.'
        errors: error
      })
    .done()

  resizeNotes: () ->
    if (!@isBuySellWizard)
      $el = @$('#notes')
      scrollHeight = $el[0].scrollHeight
      clientHeight = $el[0].clientHeight
      (scrollHeight > clientHeight) && $el.height("#{ scrollHeight }px")

  startLoad: () ->
    @loadingBox.startLoad()
    @loadingButton.startLoad()
    @loadingButtonModal.startLoad()

  stopLoad: () ->
    @loadingBox.stopLoad()
    @loadingButton.stopLoad()
    @loadingButtonModal.stopLoad()

  showMessage: (messageOptions) ->
    @formErrors.show(messageOptions)
    $(window).scrollTop(0)
    @stopLoad()
