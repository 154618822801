App.Collections.Base.PaginatedBase = require('./base/paginated_base_collection.coffee');
App.Models.EvoPayTransaction = require('../models/evopay_transaction_model');

module.exports = App.Collections.EvoPayTransactions = App.Collections.Base.PaginatedBase.extend({

  model: App.Models.EvoPayTransaction,

  url: function(){
    return '/api_direct/v9/accounts/'+ SESSION.office_id + '/transactions'
  },

  initialize: function (models, options) {
    var options = options || {};
    App.Collections.Base.PaginatedBase.prototype.initialize.call(this, models, options);
  },

  parse: function (response, options) {
    if (response.transactions) {
      return response.transactions
    } else {
      return response
    }
  }
});