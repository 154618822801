module.exports = App.Models.ListSummary = Backbone.Model.extend({

  initialize: function(attributes) {
    attributes = attributes || {};
    this.set('count1', attributes.count1 || 0);
    this.set('count2', attributes.count2 || 0);
    this.set('total', attributes.total || 0);
  },

  addTo: function(attributes) {
    var count1 = App.Utils.currencyToValue(attributes.count1);
    var count2 = App.Utils.currencyToValue(attributes.count2);
    var total = App.Utils.currencyToValue(attributes.total);
    this.set('total', (this.get('total') || 0) + total);
    this.set('count1', (this.get('count1') || 0) + count1);
    this.set('count2', (this.get('count2') || 0) + count2);
  }

});
