template = require('./credit_memo_show.ejs')
totalsTemplate = require('./totals.ejs')

App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.CreditMemo.Show = App.Views.Base.BaseView.extend

  template:                     template

  totalsTemplate:              totalsTemplate

  initialize: (options = {}) ->
    {
      @creditMemo
    } = options

  updatePayments: (payment) ->
    @paymentLoader.startLoad()
    @totalLoader.startLoad()

    @creditMemo = new App.Models.V3.CreditMemo({
      id: (payment.creditMemoId || payment.collection.creditMemoId)
    }, {
      mapping: C.Mappings.Direct.CreditMemo.CreditMemo
    })

    @creditMemo.fetchPromise()
    .then (model) =>
      @renderPayments()
      @renderTotals()
      @paymentLoader.stopLoad()
      @totalLoader.stopLoad()
    .fail (errors) =>
      @paymentLoader.stopLoad()
      @totalLoader.stopLoad()
    .done()

  render: () ->
    @$el.html(
      @template({
        creditMemo: @creditMemo.toPresenterJSON()
      })
    )
    @renderPayments()
    @renderTotals()
    @paymentLoader = new App.Utils.LoadingBox(@$('#paymentsContainer .widget-content'))
    @totalLoader   = new App.Utils.LoadingBox(@$('#creditMemoTotals .widget-content'))
    @delegateEvents()
    return @$el

  renderPayments: () ->
    view = new App.Views.CreditMemo.InlinePayments({
      @creditMemo
      creditMemoShowView: @
    })
    @$('#paymentsContainer').html(view.render())

  renderTotals: () ->
    @$("#creditMemoTotals").html(
      @totalsTemplate(
        @creditMemo.toPresenterJSON()
      )
    )