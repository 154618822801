template = require('./uploads_index.ejs')
App.Views.Base.FilteredTable = require('../base/filtered_table_view.coffee')

module.exports = App.Views.Inventory.UploadsIndex = App.Views.Base.FilteredTable.extend

  template: template

  initialize: (args) ->
    @collection = args.collection
    @$container = args.$container
    @listenTo(@collection, 'reset', @onCollectionReset)
    @render()

  filterParamMappings: ->
    created_at:
      type: C.FilterTypes.DateRange
      el: '#date_created_range'
      val: @dateRange
    exchange_id:
      type: C.FilterTypes.Select2
      el: '#exchange_select'
      ignoreAll: true
      sendsId: true
    status:
      type: C.FilterTypes.Select2
      el: '#status_select'
      sendsId: true
      ignoreAll: true

  onCollectionReset: -> @renderTable()

  render: ->
    @$el.html(@template())
    @$container.html(@$el)
    @delegateEvents()
    @buttonLoader = new App.Utils.LoadingButton(@$('#apply_filter_button'))
    App.Utils.dateRangePicker(@$('#date_created_range'), @onDateRangeChanged, @, C.DatePickerConfig.Past)
    @initializeDropdowns()
    @renderNewUpload()

    @renderTable(true)

  renderTable: (isInitialRender) ->
    @table.remove() if @table

    @table = new App.Views.Inventory.UploadsTable
      filterView: @
      $container: @$('#table_container')
      collection: @collection

    @tableLoader = new App.Utils.LoadingBox(@table.$el)

    # Shows empty loading table during initial fetch.
    @stopLoad() unless isInitialRender

  renderNewUpload: -> @newForm = new App.Views.Inventory.UploadsNew({$container: @$("#newFormContainer")})

  events:
    'click #apply_filter_button'   : 'onApplyFilterButtonClick'
    'click #reset_filter_button'   : 'onResetFilterButtonClick'
    'click #refresh_uploads_button': 'onRefreshUploadsClick'

  onResetFilterButtonClick: -> App.Controllers.inventoryUploadsController.resetIndex()

  onApplyFilterButtonClick: ->
    @buttonLoader.startLoad()
    @tableLoader.startLoad()
    App.Controllers.inventoryUploadsController.filterIndex()
  onDateRangeChanged: (startDate, endDate) -> @dateRange = start: startDate, end: endDate

  onRefreshUploadsClick: ->
    @startLoad()
    App.Controllers.inventoryUploadsController.filterIndex()

  initializeDropdowns: ->
    resultTypes = _.map C.Options.Inventory.Exchanges, (key, val) -> id: val, text: key
    @$('#exchange_select').select2
      placeholder: 'Any'
      data: resultTypes
      initSelection: (element, callback) ->
        data =
          id: element.val()
          text: element.val()
        callback(data)

    resultTypes = _.map C.Options.Inventory.UploadStatus, (key, val) -> id: val, text: key
    @$('#status_select').select2
      placeholder: 'All'
      data: resultTypes
      initSelection: (element, callback) ->
        data =
          id: element.val()
          text: element.val()
        callback(data)

  startLoad: ->
    @tableLoader.startLoad()
    @buttonLoader.startLoad()

  stopLoad: ->
    @tableLoader.stopLoad()
    @buttonLoader.stopLoad()
