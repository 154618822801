module.exports = App.Models.Return = Backbone.Model.extend({

  initialize: function(opts) {
  },

  toJSON: function () {
    var attrs = _.clone(this.attributes);
    // The order contains a reference to the payment, which causes a stack overflow on JSON.stringify.
    delete attrs.order;
    return attrs;
  },

  createReturn: function() {
    var d = $.Deferred(),
        that = this,
        amount = this.get('amount'),
        orderId = this.get('order').id;

    this.url = "/returns";

    return $.ajax({
      type: 'post',
      url: '/returns?amount=' + amount + '&order_id=' + orderId + "&performed_by=" + SESSION.user_name,
      success: function(res) {
        that.set(res);
      }
    });
  },
  parse: function() {
  },
  validate: function() {
    this.validates(['amount'], {
      presence: true
    });

  },
  isValid: function() {
    this.validate();
    return _.isEmpty(this.errors);
  },
  buildDetails: function() {
   return "Details";
  }

});

