module.exports = dashboardController = {}

dashboardController.routeDashboardIndex = ($normalContent) ->
  $container = $normalContent
  App.router.resetCurrentState()

  eventsCollection = new App.Collections.V3.Events(null, {
    mapping: C.Mappings.Direct.Events.Event
    myOrAllEvents: 'my'
  })

  evoPayTransactions = new App.Models.EvoPayTransactionsAndBalances()

  dashboardView = new App.Views.Dashboard({
    $container
    eventsCollection
    evoPayTransactions
  })
