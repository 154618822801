module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div class="modal-header">\n  <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n  <span class="icon"><i class="fa-solid fa-rotate-left"></i></span>\n  <h3 class="modal-title">Existing Draft</h3>\n</div>\n<div class="modal-body">\n  <p>\n    It appears you have an unfinished order. Would you like to continue working on it?\n  </p>\n  <p>\n    <strong>\n      Clicking "No" will permanently delete the draft.\n    </strong>\n  </p>\n</div>\n<div class="modal-footer">\n  <a class="btn btn-primary" data-choice="yes">Yes</a>\n  <a class="btn" data-choice="no">No</a>\n</div>\n', __filename = "js/views/po/existing_draft.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div class="modal-header">\n  <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n  <span class="icon"><i class="fa-solid fa-rotate-left"></i></span>\n  <h3 class="modal-title">Existing Draft</h3>\n</div>\n<div class="modal-body">\n  <p>\n    It appears you have an unfinished order. Would you like to continue working on it?\n  </p>\n  <p>\n    <strong>\n      Clicking "No" will permanently delete the draft.\n    </strong>\n  </p>\n</div>\n<div class="modal-footer">\n  <a class="btn btn-primary" data-choice="yes">Yes</a>\n  <a class="btn" data-choice="no">No</a>\n</div>\n');
            __line = 20;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}