App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.Event = App.Models.Base.V3BaseModel.extend

  urlRoot: '/api_direct/v9/events'

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() ==  C.Mappings.Direct.FilteredTickets.Event)
      attrs._name = json.name
      attrs._occursAt = Date.parse(json.occurs_at)
      attrs._venue = new App.Models.V3.Venue(json.venue, {
        mapping: C.Mappings.Direct.FilteredTickets.Venue
      })
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.EvoQuotes.Event)
      attrs._id = json.id
      attrs._name = json.name
      attrs._occursAt = Date.parse(json.occurs_at)
      attrs._occursAtRaw = json.occurs_at
      attrs._venue = new App.Models.V3.Venue(json.venue, {
        mapping: C.Mappings.Direct.EvoQuotes.Venue
      })
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() ==  C.Mappings.Direct.OrderItems.Event)
      attrs._id = json.id
      attrs._name = json.name
      attrs._occursAt = Date.parse(json.occurs_at)
      attrs._occursAtRaw = json.occurs_at
      attrs._url = json.url
      attrs._venue = new App.Models.V3.Venue(json.venue, {
        mapping: C.Mappings.Direct.OrderItems.Venue
      })
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() ==  C.Mappings.Direct.EventPins.Event)
      attrs._id = json.id
      attrs._name = json.name
      attrs._occursAt = Date.parse(json.occurs_at)
      attrs._occursAtRaw = json.occurs_at
      attrs._url = json.url
      attrs._venue = new App.Models.V3.Venue(json.venue, {
        mapping: C.Mappings.Direct.EventPins.Venue
      })
    # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Order.SubstitutionEvent)
      attrs._id = json.id
      attrs._name = json.name
      attrs._occursAt = Date.parse(json.occurs_at)
      attrs._occursAtRaw = json.occurs_at
      attrs._venue = new App.Models.V3.Venue(json.venue, {
        mapping: C.Mappings.Direct.Order.SubstitutionVenue
      })
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Order.Event)
      attrs._id = json.id
      attrs._name = json.name
      attrs._occursAt = Date.parse(json.occurs_at)
      attrs._occursAtRaw = json.occurs_at
      attrs._url = json.url
      attrs._venue = new App.Models.V3.Venue(json.venue, {
        mapping: C.Mappings.Direct.Order.Venue
      })
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.OrdersLightweight.Event)
      attrs._id = json.id
      attrs._name = json.name
      attrs._occursAt = Date.parse(json.occurs_at)
      attrs._occursAtRaw = json.occurs_at
      attrs._venue = new App.Models.V3.Venue(json.venue, {
        mapping: C.Mappings.Direct.Order.Venue
      })
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Carts.Event)
      attrs._id = json.id
      attrs._name = json.name
      attrs._occursAt = Date.parse(json.occurs_at)
      attrs._occursAtRaw = json.occurs_at
      attrs._venue = new App.Models.V3.Venue(json.venue, {
        mapping: C.Mappings.Direct.Carts.Venue
      })

    if (@getMapping() == C.Mappings.Direct.Orders.Event)
      attrs._id = json.id
      attrs._name = json.name
      # WARNING! occurs_at IS FALSELY REPRESENTING ITSELF AS ISO8601
      attrs._occursAt = Date.parse(json.occurs_at)
      attrs._occursAtRaw = json.occurs_at
      attrs._url = json.url
      attrs._venue = new App.Models.V3.Venue(json.venue, {
        mapping: C.Mappings.Direct.Orders.Venue
      })
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.TicketGroups.Event)
      attrs._id = json.id
      attrs._name = json.name
      # WARNING! occurs_at IS FALSELY REPRESENTING ITSELF AS ISO8601
      attrs._occursAt = Date.parse(json.occurs_at)
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Events.Event)
      attrs._category = new App.Models.V3.Category(json.category, {
        mapping: C.Mappings.Direct.Events.Category
      })
      attrs._configuration = new App.Models.V3.Configuration(json.configuration, {
        mapping: C.Mappings.Direct.Events.Configuration
      })
      attrs._id = json.id
      attrs._longTermPopularityScore = json.long_term_popularity_score
      attrs._name = json.name
      attrs._notes = json.notes
      # WARNING! occurs_at IS FALSELY REPRESENTING ITSELF AS ISO8601
      attrs._occursAt = Date.parse(json.occurs_at)
      attrs._occursAtRaw = json.occurs_at
      attrs._ownedByOffice = json.owned_by_office
      performers = []
      if (json.performances)
        for el, index in json.performances
          performer = el.performer
          performer._isPrimary = el.primary
          performers.push(performer)
        attrs._performers = new App.Collections.V3.Performers(performers, {
          mapping: C.Mappings.Direct.Events.Performers
        })
      attrs._popularityScore = json.popularity_score
      attrs._productsCount = json.products_count
      attrs._productsEticketCount = json.products_eticket_count
      attrs._state = json.state
      attrs._stubhubId = json.stubhub_id

      # When resuming an existing PO from localStorage, we may have the _ticketGroups attribute.
      attrs._ticketGroups = new App.Collections.V3.TicketGroups(json._ticketGroups)

      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      attrs._venue = new App.Models.V3.Venue(json.venue, {
        mapping: C.Mappings.Direct.Events.Venue
      })
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.EventEndpoint.Event)
      attrs._category = new App.Models.V3.Category(json.category, {
        mapping: C.Mappings.EventEndpoint.Category
      })
      attrs._configuration = new App.Models.V3.Configuration(json.configuration, {
        mapping: C.Mappings.EventEndpoint.Configuration
      })
      attrs._id = json.id
      attrs._longTermPopularityScore = json.long_term_popularity_score
      attrs._name = json.name
      attrs._notes = json.notes
      # WARNING! occurs_at IS FALSELY REPRESENTING ITSELF AS ISO8601
      attrs._occursAt = Date.parse(json.occurs_at)
      attrs._occursAtRaw = json.occurs_at
      attrs._ownedByOffice = json.owned_by_office

      performers = []
      if (json.performances)
        for el, index in json.performances
          performer = el.performer
          performer._isPrimary = el.primary
          performers.push(performer)
        attrs._performers = new App.Collections.V3.Performers(performers, {
          mapping: C.Mappings.Direct.Events.Performers
        })
      attrs._popularityScore = json.popularity_score
      attrs._productsCount = json.products_count
      attrs._productsEticketCount = json.products_eticket_count
      attrs._state = json.state
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      attrs._venue = new App.Models.V3.Venue(json.venue, {
        mapping: C.Mappings.EventEndpoint.Venue
      })
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.OrderItemsByEvent.Event)
      attrs._id = json.id
      attrs._name = json.name
      attrs._notes = json.notes
      # WARNING! occurs_at IS FALSELY REPRESENTING ITSELF AS ISO8601
      attrs._occursAt = Date.parse(json.occurs_at)
      attrs._occursAtRaw = json.occurs_at
      attrs._venue = new App.Models.V3.Venue(json.venue, {
        mapping: C.Mappings.Direct.OrderItemsByEvent.Venue
      })
      # THESE FIELDS ARE SET AT RUNTIME FOR management_events TABLE
      #_orderCount
      #_unfilledCount
      #_availableCount
      #_soldCount
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      availableCount: @get('_availableCount')
      awayPerformer: @awayPerformer()?.toPresenterJSON?()
      category: @get('_category')?.toPresenterJSON?()
      configuration: @get('_configuration')?.toPresenterJSON()
      fullDescription: @fullDescription()
      homePerformer: @homePerformer()?.toPresenterJSON?()
      id: @get('_id')
      name: @get('_name')
      notes: @get('_notes')
      isOwnedByOffice:  @get('_isOwnedByOffice')
      occursAt: @get('_occursAt')
      occursAtRaw: @get('_occursAtRaw')
      orderCount: @get('_orderCount')
      ownedByOffice: @get('_ownedByOffice')
      soldCount: @get('_soldCount')
      ticketGroups: @get('_ticketGroups')?.toPresenterJSON()
      unfilledCount: @get('_unfilledCount')
      productsCount: @get('_productsCount')
      venue: @get('_venue')?.toPresenterJSON()

    })
    return presented

  fullDescription: () ->
    venue = @get('_venue')
    address = venue?.get('_address')
    if (venue && address)
      return [
        @get('_name')
        venue.get('_name')
        address.get('_locality')
        address.get('_region')
        App.Utils.makeTimestampHumanForEvent(@get('_occursAtRaw'), C.DateFormats.Long)
      ].join(' - ')
    else
      return ''

  homePerformer: () ->
    performers = @get('_performers')
    if (performers)
      for performer in performers.models
        if (performer.get('_isPrimary'))
          return performer
    return null

  awayPerformer: () ->
    performers = @get('_performers')
    if (performers)
      for performer in performers.models
        if (!performer.get('_isPrimary'))
          return performer
    return null
  #///////////////////////////////////////////////////////////////////////////
