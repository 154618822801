App.Views.MasterLayout = require('./views/master_layout_view.coffee')
App.Collections.V3.Carts = require('./collections/v3/carts_collection.coffee')
App.Controllers.OrderController = require('./controllers/order_controller.coffee')

privacyPolicyTemplate = require('./privacy_policy.ejs')
termsAndConditionsTemplate = require('./terms_and_conditions.ejs')

_currentState = null
_myOrAllEvents = null
_lastEventViewSettings = null

PREREQS = {}

PREREQS.eventList = (options) ->
  {
    $container
    selectedEventId
    isEventsFiltered
    filterCode
  } = options

  if (isEventsFiltered)
    targetState = "eventSearch#{ _myOrAllEvents }#{ filterCode }"
    if (_currentState == targetState)
      return Q(true)
    else
      deferred = Q.defer()
      filters = App.Utils.decodeFilterParams(filterCode)
      App.Controllers.eventsController.search({
        $container
        deferred
        filters
      })
      _currentState = targetState
      return deferred.promise
  else
    targetState = "eventList#{ _myOrAllEvents }"
    if (_currentState == targetState)
      return Q(true)
    else
      deferred = Q.defer()
      App.Controllers.eventsController.index({
        $container
        deferred
      })
      _currentState = targetState
      return deferred.promise

# Instantiated at bottom of page.
module.exports = App.Router = Backbone.Router.extend

  saveEventViewSettings: (eventViewSettings) ->
    _lastEventViewSettings = eventViewSettings

  generateBackToEventsUrl: () ->
    if (!_lastEventViewSettings)
      return null
    else
      {
        eventId
        filterCode
        myOrAllTickets
      } = _lastEventViewSettings
      if (filterCode)
        url = "/tickets/events/#{ App.router.getMyOrAllEvents() }/filter/#{ filterCode }/#{ eventId }/#{ myOrAllTickets }"
      else
        url = "/tickets/events/#{ App.router.getMyOrAllEvents() }/#{ eventId }/#{ myOrAllTickets }"
      return url

  resetCurrentState: () ->
    _currentState = null

  completePreReq: (prereq, options = {}) ->
    options = _.extend(options, {
      $container: @$normalContent
    })
    return prereq(options)

  setNavItem: (category, item) ->
    $.noop()

  getMyOrAllEvents: () ->
    return _myOrAllEvents

  setMyOrAllEvents: (myOrAllEvents) ->
    _myOrAllEvents = myOrAllEvents

  initLayout: () ->
    @masterLayoutView = new App.Views.MasterLayout({
      $container: $('body')
      router: @
    })

  updateTopNav: (options) ->
    @masterLayoutView.updateTopNav(options)

  getContentContainer: () ->
    return @$normalContent

  initialize: () ->

    @routesHit = 0
    Backbone.history.on 'route', (->
      @routesHit++
      return
    ), this

    @search = {}
    @prevBodyContent = $('body').html()
    @initLayout()
    @$normalContent = $('#content')
    @myOrAllTickets = 'my'
    if (SESSION.user_id) # If logged in.
      App.Utils.initializeSearchBar()

    if (SESSION.office_id)
      # Load shopping carts.
      carts = new App.Collections.V3.Carts(null, {
        mapping: C.Mappings.Direct.Carts.Carts
      })
      carts.fetchPromise()
      .then (v3CartsCollection) =>
        SESSION.carts = v3CartsCollection

        numInCart = 0
        if (SESSION.carts?.length)
          cart = SESSION.carts.first()
          numInCart = cart.get('_holds')?.length || 0
        if (numInCart)
          options = {
            action: 'updateCart'
            numInCart
          }
          @updateTopNav(options)

      .fail (error) ->
        console.error(error)
        errorModal = new App.Views.Shared.BasicModal({
          header: 'Error fetching cart.',
          message: error.message
        })
      .done()

    setTimeout(() ->
      if (!Backbone.History.started)
        Backbone.history.start({ pushState: true })
    , 0)

  routes:
    '':                                     'entryPoint'

    'account':                              'accountShow'

    'buy/event/:eventId/tickets/:ticketGroupId':            'buy'
    'affiliatesell/event/:eventId/tickets/:ticketGroupId':  'affiliateSell'
    'buysell/event/:eventId/tickets/:ticketGroupId':        'buySell'

    'cart':                                 'cartIndex'
    'client/:id':                           'clientShow'
    'client/:id/credit_cards':              'clientShowCreditCards'
    'client/:id/invoices':                  'clientShowInvoices'
    'client/:id/orders':                    'clientShowOrders'
    'clients':                              'clientIndex'
    'clients#all':                          'clientIndexAll'
    'clients/search/':                      'clientSearchBlank'
    'clients/search/:searchTerm':           'clientSearch'
    'consignment/new':                      'purchaseOrderConsignment'
    'credit_memo/:id':                      'creditMemoShow'
    'credit_memos':                         'creditMemoIndex'
    'credit_memos/filter/*filterCode':      'creditMemoIndex'
    'customer/new':                         'clientNew'

    'dashboard':                            'dashboardIndex'
    'deliveries':                           'deliveryIndex'
    'deliveries/filter/*filterCode':        'deliveryIndex'

    'emails':                               'emailsIndex'
    'emails/filter/*filterCode':            'emailsIndex'
    'email/:id':                            'emailShow'
    'etickets/:eventId/:ticketGroupId':     'eticketManagementIndex'
    'eventmgmt':                            'eventManagement'
    'eventmgmt/filter/*filterCode':         'eventManagement'
    'events':                               'eventsIndex'
    'events/taken':                         'eventsTaken'
    'events/held':                          'eventsHeld'
    'events/:myOrAllEvents':                'eventsIndex'
    'evopay':                               'evoPayTransactionsIndex'
    'evopay/filter/*filterCode':            'evoPayTransactionsIndex'
    'evoquotes':                            'evoQuoteIndex'
    'evoquotes/filter/*filterCode':         'evoQuoteIndex'
    'evoquote/new':                         'evoQuoteNew'
    'evoquote/:id':                         'evoQuoteShow'

    'inventory':                            'massIndex'

    'restricted_evopay_orders':             'restrictedEvopayOrdersIndex'

    'order':                                'orderIndex'
    'order/filter/*filterCode':             'orderIndex'
    'order/:id':                            'orderShow'

    'payment':                              'paymentIndex'
    'payment/filter/*filterCode':           'paymentIndex'
    'performers/:id':                       'performersShow'
    'pos':                                  'purchaseOrderIndex'
    'pos/filter/*filterCode':               'purchaseOrderIndex'
    'po/new':                               'purchaseOrder'

    'privacy':                              'privacyPolicy'

    'receive':                              'receiveTickets'
    'reports/sales':                        'salesReportIndex'
    'reports/sales/filter/*filterCode':     'salesReportIndex'

    'search/:category/:id/:term':           'search'

    'sell/event/:eventId/tickets/:ticketGroupId': 'sell'

    'sessions':                             'preserveBody'
    'sessions/new':                         'preserveBody'

    'shipment':                             'shipmentsIndex'
    'shipment/filter/*filterCode':          'shipmentsIndex'
    'shipment/new':                         'shipmentsNew'

    'spec':                                 'purchaseOrderSpec'

    'terms':                                'termsAndConditions'

    'tickets/events/:myOrAllEvents/filter/:filterCode':                           'eventsFiltered'
    'tickets/events/:myOrAllEvents/filter/:filterCode/:eventId/:myOrAllTickets':  'eventsFiltered'
    'tickets/events/:myOrAllEvents/:eventId/:myOrAllTickets':                     'ticketGroupsIndex'

    'uploads':                              'uploads'
    'uploads/filter/*filterCode':           'uploads'

    'venues/:id':                           'venuesShow'

    '*catchall':                            'catchAll'

  # Useful for debugging.
  navigate: (fragment, options) ->
    eventData = {
      route: fragment
    }
    Backbone.Router.prototype.navigate(fragment, options)

    App.Utils.requestKiller.kill()
    return

  #// ACTUAL ROUTES //////////////////////////////////////////////////////////

  catchAll: () ->
    $.noop()

  accountShow: () ->
    @setNavItem('My Account')
    userModel    = new App.Models.V3.User({
      id : SESSION.user_id
    }, {
      mapping: C.Mappings.Direct.User.User
    })
    accountView       = new App.Views.User.Account({
      userModel
      $container : @$normalContent
    })
    loadingOverlay = new App.Views.Shared.LoadingView()
    loadingOverlay.render()
    userModel.fetchPromise()
    .then () =>
      loadingOverlay.remove()
      accountView.render()
    .fail () =>
      loadingOverlay.remove()
    .done()


  buy: (eventId, ticketGroupId) ->
    event = new App.Models.V3.Event({
      id: eventId
    }, {
      mapping: C.Mappings.Direct.Events.Event
    })
    ticketGroup = new App.Models.V3.TicketGroup({
      id: ticketGroupId
    }, {
      mapping: C.Mappings.Direct.TicketGroups.TicketGroup
    })
    Q.all([
      event.fetchPromise()
      ticketGroup.fetchPromise()
    ])
    .spread (eventModel, ticketGroupModel) ->
      App.Controllers.buySellController.buy({
        eventModel
        ticketGroupModel
      })
    .done()

  affiliateSell: (eventId, ticketGroupId) ->
    isAffiliateSell = true
    @buySell(eventId, ticketGroupId, isAffiliateSell)

  buySell: (eventId, ticketGroupId, isAffiliateSell = false) ->
    event = new App.Models.V3.Event({
      id: eventId
    }, {
      mapping: C.Mappings.Direct.Events.Event
    })
    ticketGroup = new App.Models.V3.TicketGroup({
      id: ticketGroupId
    }, {
      mapping: C.Mappings.Direct.TicketGroups.TicketGroup
    })
    Q.all([
      event.fetchPromise()
      ticketGroup.fetchPromise()
    ])
    .spread (eventModel, ticketGroupModel) ->
      if (isAffiliateSell)
        App.Controllers.buySellController.affiliateSell({
          eventModel
          ticketGroupModel
        })
      else
        App.Controllers.buySellController.buySell({
          eventModel
          ticketGroupModel
        })
    .done()

  cartIndex: () ->
    @setNavItem(null)
    App.Controllers.cartController.routeIndex(@$normalContent)

  clientIndex: () ->
    @setNavItem('Customers')
    App.Controllers.clientController.routeIndex(@$normalContent)

  clientIndexAll: () ->
    @setNavItem('Customers')
    App.Controllers.clientController.routeAll(@$normalContent)

  creditMemoShow: (id) ->
    @setNavItem('Orders', 'Credit Memos')
    creditMemo    = new App.Models.V3.CreditMemo({
      id
    }, {
      mapping: C.Mappings.Direct.CreditMemo.CreditMemo
    })
    showView       = new App.Views.CreditMemo.Show({
      creditMemo
    })
    loadingOverlay = new App.Views.Shared.LoadingView()
    loadingOverlay.render()
    creditMemo.fetch().done(() =>
      loadingOverlay.remove()
      @$normalContent.html(showView.render())
    ).error(() =>
      loadingOverlay.remove()
    )

  creditMemoIndex: (encodedFilterParams) ->
    @setNavItem('Orders', 'Credit Memos')
    App.Controllers.creditMemoController.routeIndex(@$normalContent, encodedFilterParams)

  clientSearchBlank: () ->
    @navigate('/clients', { trigger: true })

  clientNew: (id) ->
    @setNavItem('Customers')
    App.Controllers.clientShowController.go(@$normalContent)

  clientShow: (id) ->
    @setNavItem('Customers')
    App.Controllers.clientShowController.go(@$normalContent, id)

  clientShowCreditCards: (id) ->
    @setNavItem('Customers')
    options = { tab: 'creditCards' }
    App.Controllers.clientShowController.go(@$normalContent, id, options)

  clientShowInvoices: (id) ->
    @setNavItem('Customers')
    options = { tab: 'invoices' }
    App.Controllers.clientShowController.go(@$normalContent, id, options)

  clientShowOrders: (id) ->
    @setNavItem('Customers')
    options = { tab: 'orders' }
    App.Controllers.clientShowController.go(@$normalContent, id, options)

  clientSearch: (searchTerm) ->
    @setNavItem('Customers')
    App.Controllers.clientController.routeSearch(@$normalContent, searchTerm)

  dashboardIndex: () ->
    if (SESSION.role.can(App.Access.Resources.Dashboard))
      @setNavItem('Dashboard')
      App.Controllers.dashboardController.routeDashboardIndex(@$normalContent)
    else
      @navigate('/', { trigger: true, replace: true })

  deliveryIndex: (encodedFilterParams) ->
    @setNavItem('Orders', 'Deliveries')
    App.Controllers.deliveryController.routeIndex(@$normalContent, encodedFilterParams)

  emailsIndex: (encodedFilterParams) ->
    @setNavItem('Orders', 'Emails')
    App.Controllers.emailController.routeIndex(@$normalContent, encodedFilterParams)

  emailShow: (id) ->
    @setNavItem('Orders', 'Emails')
    App.Controllers.emailController.routeEmailShow(@$normalContent, id)

  entryPoint: () ->
   if (SESSION.role.can(App.Access.Resources.Inventory.AllTickets))
     @eventsIndex()
   else
    @orderIndex()
# EVENTS_INDEX
  # This Event stuff should really run through a controller.
  # Having the collections in the views is poor design.
  eventsIndex: (myOrAllEvents = 'all') ->

    if (SESSION.role.cannot(App.Access.Resources.Inventory.AllTickets.OwnTickets))
      myOrAllEvents = 'all'

    @setNavItem('Inventory', 'All Tickets')
    @setMyOrAllEvents(myOrAllEvents)
    App.Controllers.eventsController.index({
      $container: @$normalContent
    })
    _currentState = "eventList#{ _myOrAllEvents }"

  eticketManagementIndex: (eventId, ticketGroupId, options) ->
    @setNavItem(null)
    App.Controllers.eticketManagement.routeIndex(@$normalContent, eventId, ticketGroupId, options)

  eventsTaken: (id) ->
    @setNavItem('Inventory', 'Taken')
    App.Controllers.ticketGroupController.routeTaken(@$normalContent)

  eventsHeld: (id) ->
    @setNavItem('Inventory', 'Held')
    App.Controllers.ticketGroupController.routeHeld(@$normalContent)

  # tickets/events/:myOrAllEvents/filter/:filterCode/:eventId/:myOrAllTickets':  'eventsFiltered'
  eventsFiltered: (myOrAllEvents, filterCode, selectedEventId, myOrAllTickets) ->
    @setNavItem('Inventory', 'All Tickets')
    @setMyOrAllEvents(myOrAllEvents)
    options = {
      isEventsFiltered: true
      filterCode
      selectedEventId
      myOrAllTickets
    }
    @ticketGroupsIndexAction(options)

  ticketGroupsIndex: (myOrAllEvents, selectedEventId, myOrAllTickets) ->
    @setNavItem('Inventory', 'All Tickets')
    @setMyOrAllEvents(myOrAllEvents)
    options = {
      selectedEventId
      myOrAllTickets
    }
    @ticketGroupsIndexAction(options)

  # TICKET_GROUPS_INDEX
  ticketGroupsIndexAction: (options) ->
    {
      isEventsFiltered
      filterCode
      selectedEventId
      myOrAllTickets
    } = options
    @myOrAllTickets = myOrAllTickets
    @completePreReq(PREREQS.eventList, options)
    .then () =>
      App.Vent.trigger('edit-ticket-groups:close')
      App.Controllers.eventsController.setCurrentUrl(filterCode)
      App.Controllers.eventsController.highlightEvent(selectedEventId)
      .then (selectedEventView) =>
        if (selectedEventView)
          App.Controllers.ticketGroupController.routeIndex({
            filterCode
            eventModel: selectedEventView.eventModel
            selectedEventId
            myOrAllTickets
          })
    .fail (error) ->
      console.log(error)
    .done()

  eventManagement: (encodedFilterParams) ->
    @setNavItem('Orders', 'Event Management')
    App.Controllers.eventManagementController.routeIndex(@$normalContent, encodedFilterParams)

  evoPayTransactionsIndex: (encodedFilterParams) ->
    @setNavItem('Orders', 'EvoPay Management')
    App.Controllers.evoPayController.routeIndex(@$normalContent, encodedFilterParams)

  evoQuoteIndex: (encodedFilterParams) ->
    @setNavItem('Inventory', 'EvoQuotes')
    App.Controllers.evoQuoteController.routeIndex(@$normalContent, encodedFilterParams)

  evoQuoteNew: () ->
    @setNavItem('Inventory', 'EvoQuotes')
    App.Controllers.evoQuoteController.routeEvoQuoteNew(@$normalContent)

  evoQuoteShow: (id) ->
    @setNavItem('Inventory', 'EvoQuotes')
    App.Controllers.evoQuoteController.routeEvoQuoteShow(@$normalContent, id)

  massIndex: () ->
    @setNavItem('Inventory', 'Mass Edit')
    App.Controllers.inventoryController.routeMassIndex({
      $container: @$normalContent
    })

  restrictedEvopayOrdersIndex: () ->
    @setNavItem('Evopayments', 'Orders with Restricted EvoPay')
    App.Controllers.restrictedEvopayOrdersController.routeIndex(@$normalContent)

  orderIndex: (encodedFilterParams) ->
    @setNavItem('Orders', 'All Orders')
    ordersOrPOs = 'orders'
    App.Controllers.OrderController.routeIndex(@$normalContent, ordersOrPOs, encodedFilterParams)

  orderShow: (id) ->
    @setNavItem(null)
    App.Controllers.orderShowController.routeOrderShow(@$normalContent, id)

  paymentIndex: (encodedFilterParams) ->
    @setNavItem('Orders', 'Payments')
    App.Controllers.paymentController.routeIndex(@$normalContent, encodedFilterParams)

  performersShow: (id) ->
    @setNavItem(null)
    collection = new App.Collections.V3.Events()
    filters = {
      performer_id: id
      performer_name: @search.label || "-"
    }
    view = new App.Views.Events.List({
      $container: @$normalContent
      collection
      filters
    })

  preserveBody: () ->
    $('body').html(@prevBodyContent)

  privacyPolicy: () ->
    @$normalContent.html(
      privacyPolicyTemplate()
    )

  purchaseOrderIndex: (encodedFilterParams) ->
    @setNavItem('Orders', 'Purchase Orders')
    ordersOrPOs = 'pos'
    App.Controllers.OrderController.routeIndex(@$normalContent, ordersOrPOs, encodedFilterParams)

  purchaseOrder: () ->
    @setNavItem('Inventory', 'New PO')
    App.Controllers.poController.start({
      $container: @$normalContent
      isSpec: false
    })

  purchaseOrderSpec: () ->
    @setNavItem('Inventory', 'Category Seats')
    App.Controllers.poController.start({
      $container: @$normalContent
      isSpec: true
    })

  purchaseOrderConsignment: () ->
    @setNavItem('Inventory', 'Consignment PO')
    App.Controllers.poController.start({
      $container: @$normalContent
      isConsignment: true
    })

  salesReportIndex: (encodedFilterParams) ->
    @setNavItem('Canned Reports', 'Sales')
    App.Controllers.reportController.routeSalesIndex(@$normalContent, encodedFilterParams)

  receiveTickets: () ->
    @setNavItem('Inventory', 'Receive Tickets')
    App.Controllers.inventoryController.routeMassIndex({
      $container: @$normalContent
      isReceiveTicketsView: true
    })

  search: (category, id, term) ->
    @setNavItem(null)
    switch (category)

      #// VENUES ///////////////////////////////////////////////////////////
      when 'venues'
        filters = {
          venue_id: id
          venue_name: decodeURIComponent(term)
        }
        filterCode = App.Utils.encodeFilterParams(filters)
        @navigate("/tickets/events/#{ _myOrAllEvents }/filter/#{ filterCode }", { trigger: true })

      # PERFORMERS /////////////////////////////////////////////////////////
      when 'performers'
        filters = {
          performer_id: id
          performer_name: decodeURIComponent(term)
        }
        filterCode = App.Utils.encodeFilterParams(filters)
        @navigate("/tickets/events/#{ _myOrAllEvents }/filter/#{ filterCode }", { trigger: true })

      #// CLIENTS //////////////////////////////////////////////////////////
      when 'clients'
        @navigate('/clients/search/' + term, { trigger: true })

      #// OFFICES //////////////////////////////////////////////////////////
      when 'offices'
        officeId = id
        brokerDetailsView = new App.Views.TicketGroups.BrokerDetails({
          officeId
        })
        window.history.back()

      # See search.js for how ORDERS search is handled.

      else
        $.noop()

  sell: (eventId, ticketGroupId) ->
    event = new App.Models.V3.Event({
      id: eventId
    }, {
      mapping: C.Mappings.Direct.Events.Event
    })
    ticketGroup = new App.Models.V3.TicketGroup({
      id: ticketGroupId
    }, {
      mapping: C.Mappings.Direct.TicketGroups.TicketGroup
    })
    Q.all([
      event.fetchPromise()
      ticketGroup.fetchPromise()
    ])
    .spread (eventModel, ticketGroupModel) ->
      App.Controllers.buySellController.sell({
        eventModel
        ticketGroupModel
      })
    .done()

  shipmentsIndex: (encodedFilterParams) ->
    @setNavItem('Orders', 'Shipments')
    App.Controllers.shipmentController.routeIndex(@$normalContent, encodedFilterParams)

  shipmentsNew: () ->
    @setNavItem('Orders', 'Shipments')
    App.Controllers.shipmentController.routeShipmentNew(@$normalContent)

  termsAndConditions: () ->
    @$normalContent.html(
      termsAndConditionsTemplate()
    )

  uploads: (encodedFilterParams) ->
    @setNavItem('Inventory', 'Uploads')
    App.Controllers.inventoryUploadsController.routeIndex(@$normalContent, encodedFilterParams)

  venuesShow: (id) ->
    @setNavItem(null)
    collection = new App.Collections.V3.Events()
    filters = {
      venue_id: id
      venue_name: @search.label || "-"
    }
    view = new App.Views.Events.List({
      $container: @$normalContent
      collection: collection
      filters: filters
    })

#/////////////////////////////////////////////////////////////////////////////



