module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = "<strong>\n  <%= patron.prettyName %>\n</strong>\n<br/>\n<%- patron.address.multiLine %><br/>\n<%- patron.emailAddressLink %><br/>\n<%= patron.phoneNumber %>", __filename = "js/views/buy_sell/fixed_patron_details.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append("<strong>\n  ");
            __line = 2;
            __append(escapeFn(patron.prettyName));
            __append("\n</strong>\n<br/>\n");
            __line = 5;
            __append(patron.address.multiLine);
            __append("<br/>\n");
            __line = 6;
            __append(patron.emailAddressLink);
            __append("<br/>\n");
            __line = 7;
            __append(escapeFn(patron.phoneNumber));
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}