App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.ShipmentType = App.Models.Base.V3BaseModel.extend

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.ShipmentTypes.ShipmentTypes)
      attrs._id = json.id
      attrs._name = json.name
      attrs._position = json.position
      attrs._price = json.price
      attrs._provider = json.shipping_option.provider
      attrs._shippingOption = json.shipping_option
      attrs._description = json.description
      attrs._updatedAt = Date.parse(json.updated_at)

    @set(attrs)

  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      provider: @provider()

    })
    return presented

  toSelectOption: () ->
    id = @get('_provider')
    position = @get('_position')
    if (id == C.FedEx)
      text = C.FedEx
    else
      text = @get('_name')
    return {
      id
      text
      position
    }

  provider: () ->
    return @get('_shippingOption').provider

#
#    to_hash:  ->
#      hash = {}
#      hash[@get("id")] = @displayJSON()
#      return hash
#
#    displayJSON: ->
#      str = if @is("custom") then "Custom - #{@get("shipping_option").name}" else @get("name")
#      price  =  "(#{App.Utils.valueToCurrency @get("price")})"
#      "#{str} #{price}"
#
#    is: (type) -> @get('shipping_option').provider.toLowerCase() is type.toLowerCase()
#
#    provider_key: ->
#      return C.FedEx if @is("fedex")
#      @get("id")
