App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.Substitution = App.Models.Base.V3BaseModel.extend

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.Order.Substitution)
      attrs._from = new App.Models.V3.OrderItem(json.from, {
        mapping: C.Mappings.Direct.Order.SubstitutionOrderItem
      })
      attrs._orderItemId = json.order_item_id
      attrs._original = new App.Models.V3.OrderItem(json.from, {
        mapping: C.Mappings.Direct.Order.SubstitutionOrderItem
      })
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

    })
    return presented
  #///////////////////////////////////////////////////////////////////////////
