App.Models.Base.BaseModel = require('../base/base_model.coffee')

parent = App.Models.Base.BaseModel.prototype
module.exports = App.Models.V2.Client = App.Models.Base.BaseModel.extend({

  has_one:
    primary_email_address: "Email"
    primary_address: "Address"
  has_many: ["email_addresses", "addresses", "phone_numbers"]

  urlRoot: "/clients"

  validates:
    name:
      required: true

  defaults:
    company: ''
    tags: []
    name: ''
    notes: ''

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)
    @set('tags', []) unless @get('tags')

  parse: (response, options) ->
    parent.parse.call(@, response, options)
    return response

  toJSON: (options) ->
    json = parent.toJSON.call(@, options)
    json.primary_email_address = json.primary_email_address?.toJSON()
    json.primary_address = json.primary_address?.toJSON()
    json.addresses = json.addresses?.toJSON()
    json.email_addresses = json.email_addresses?.toJSON()
    json.phone_numbers = json.phone_numbers?.toJSON()
    return json

  updateStandardAttributes: () ->
    json = @attributes
    attrs = {}

    # FIND WHICH MAPPINGS THESE APPLY TO.
    attrs.primary_email_address = new App.Models.Email(json?.primary_email_address?.attributes || json?.primary_email_address, {
      holder_id: @id
      mapping: C.Mappings.Unknown
    })
    attrs.primary_address = new App.Models.V2.Address(json?.primary_address?.attributes || json?.primary_address, {
      holder_id: @id
      mapping: C.Mappings.Unknown
    })
    attrs.addresses = new App.Collections.V2.Addresses(json?.addresses?.models || json?.addresses, {
      holder_id: @id
      mapping: C.Mappings.Unknown
    })
    attrs.email_addresses = new App.Collections.EmailAddresses(json?.email_addresses?.models || json?.email_addresses, {
      holder_id: @id
      mapping: C.Mappings.Unknown
    })
    attrs.phone_numbers = new App.Collections.PhoneNumbers(json?.phone_numbers?.models || json?.phone_numbers, {
      holder_id: @id
      mapping: C.Mappings.Unknown
    })

    if (@getMapping() == C.Mappings.OrderEndpoint.Patron)
      attrs.x = 12

    if (@getMapping() == C.Mappings.V2OrderEndpoint.Patron)
      attrs._addresses = json.addresses # Should be a collection.
      attrs._company = json.company
      attrs._emailAddresses = json.email_addresses # Should be a collection.
      attrs._id = json.id
      attrs._name = json.name
      attrs._office = json.office # Should be a model
      attrs._phoneNumbers = json.phone_numbers # Should be a collection.
      attrs._primaryCreditCard = json.primary_credit_card
      attrs._primaryShippingAddress = json.primary_shipping_address
      attrs._tags = json.tags
      attrs._type = json.type
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # NOT COMPLETE - HANDLE NESTED - KEEP THIS COMMENT

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {
      company:  @get('company')?.name
      email:    @get('_email')
      emailAddressOptions: @emailAddressOptions()
      id:       @get('_id')
      tags:     @get('tags')?.join(',')
      name:     @get('name')
      notes:    @get('notes')
      preparedFor:    @get('_preparedFor')
      address:        @address()?.toPresenterJSON()
      phone_numbers:  @get('phone_numbers')?.toPresenterJSON()
      phone_number:   @phone_number()?.toPresenterJSON()
      phoneNumberOptions: @phoneNumberOptions()
      addresses:      @get('addresses')?.toPresenterJSON()
    })
    return presented

  address: -> @get('addresses').first()

  emailAddressOptions: () ->
    opts = []
    emailCollection = @get('_emailAddresses')
    if (emailCollection)
      for emailModel in emailCollection.models
        opts.push(emailModel.get('address'))
    return opts

  phone_number: -> @get('phone_numbers').first()

  phoneNumberOptions: () ->
    if (@get('phone_numbers')?.length)
      return @get('phone_numbers').toPresenterJSON()
    return []
  #///////////////////////////////////////////////////////////////////////////



}, {
  # STATICS
  defaultMapping: C.Mappings.OrderEndpoint.Patron

  coalesce: (client) ->
    if client then new App.Models.V2.Client(client.attributes) else new App.Models.V2.Client()
})
