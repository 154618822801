template = require('./transaction_table.ejs')

module.exports = App.Views.EvoPay.TransactionTable = Backbone.View.extend

  template: template

  dataTableOptions: {
    bJQueryUI: true
    sDom: '<"">t<"F"fp>'
    bScrollInfinite: true
    bScrollCollapse: false
    iDisplayLength: 1000
    sScrollY: '700px'
    aaSorting: [
      [ 0, 'asc']
      [ 2, 'desc']  # Date
    ]
    aoColumns: [
      { bVisible: false } # Hidden stateSortPriority column to sort on.
      null
      { sType: 'date' }
      null
      null
      null
      { sType: 'currency' }
      { sType: 'currency' }
      null
      null
      null
    ]
  }

  initialize: (options = {}) ->
    {
      @collection
      @$container
      @filterView
    } = options
    @render()

  render: () ->
    data = {
      transactions: @collection.toJSON()
    }
    @$el.html(@template(data))
    @$container.html(@$el)
    @endlessDataTable = new App.Utils.EndlessDataTable({
      @collection
      @filterView
      @dataTableOptions
    })
    return @$el

  getDataTable: () ->
    return @endlessDataTable