module.exports = ticketGroupController = {}

_ticketGroupsIndexView = null
_ticketTakesListView = null

ticketGroupController.getTicketGroupsListView = () ->
  return _ticketGroupsIndexView

ticketGroupController.routeIndex = (options) ->
  {
    filterCode
    eventModel
    selectedEventId
    myOrAllTickets
  } = options
  listviewOptions = _.extend(options, {
    $container: $('#ticketGroupsListContainer')
  })
  if (_ticketGroupsIndexView)
    _ticketGroupsIndexView.remove()
  _ticketGroupsIndexView = new App.Views.TicketGroups.ListView(listviewOptions)

ticketGroupController.routeHeld = ($container) ->
  collection = new App.Collections.V3.TicketHolds(null, {
    mapping: C.Mappings.Direct.FilteredTickets.HeldTickets
  })
  view = new App.Views.TicketHolds.TicketHoldsList({
    collection
    $container
  })

ticketGroupController.routeTaken = ($container) ->
  collection = new App.Collections.V3.TicketHolds(null, {
    mapping: C.Mappings.Direct.FilteredTickets.TakenTickets
  })
  view = new App.Views.TicketHolds.TicketHoldsList({
    collection
    $container
    isTaken: true
  })

ticketGroupController.initiateEdit = (options) ->
  {
    ticketGroupModel # v3
    eventModel
    formErrors
  } = options
  loadingView = new App.Views.Shared.LoadingView()
  loadingView.render()
  tg = new App.Models.TicketGroup({ # v1
    id: ticketGroupModel.id
    eventId: eventModel.id
  }, {
    purpose: C.Purposes.EditTicketGroup
  })
  tg.fetch().done(() =>
    availableOffices = new App.Collections.V3.Offices()
    availableOffices.fetch({data: {brokerage_id: SESSION.brokerage_id}}).done(() =>
      editView = new App.Views.TicketGroups.EditOne({
        eventModel
        model: tg
        ticketGroupModel
        availableOffices: availableOffices
      })
      editView.render()
      loadingView.remove()
    ).fail(() ->
      formErrors.show({
        errors: "Failed to fetch availableOffices."
      })
    )
  ).fail(() ->
    formErrors.show({
      errors: "Failed to fetch ticketGroup."
    })
  )

ticketGroupController.addToEvoQuote = (options = {}) ->
  {
    ticketGroupModel
    eventModel
  } = options
  oldCount = App.EvoQuote.count()
  App.EvoQuote.set(ticketGroupModel)
  if (App.EvoQuote.count() == oldCount + 1)
    $.gritter.add({
      title:  'Successful'
      text:   'Ticket Group was sucessfully added to your EvoQuote.'
    })
  else
    $.gritter.add({
      title:  'Failed'
      text:   'Could not add tickets to EvoQuote.'
    })
  options = {
    action: 'updateEvoquote'
  }
  App.router.updateTopNav(options)