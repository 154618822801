module.exports = cartController = {}

_$container = null
_view = null

cartController.routeIndex = ($container) ->

  callback = () =>
    _$container = $container
    _view = new App.Views.Orders.CartView({
      $container
      cartModel
    })

  if (SESSION.carts?.length)
    cartModel = SESSION.carts.first()
    callback()
  else
    SESSION.carts = new App.Collections.V3.Carts(null, {
      mapping: C.Mappings.Direct.Carts.Carts
    })
    SESSION.carts.fetchPromise()
    .then (v3CartsCollection) ->
      if (SESSION.carts?.length)
        cartModel = SESSION.carts.first()
      callback()

