App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V3.TicketHold = require('../../models/v3/ticket_hold_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V3.TicketHolds = App.Collections.Base.BaseCollection.extend

  model: App.Models.V3.TicketHold

  url: () ->
    if (@getMapping() == C.Mappings.Direct.FilteredTickets.HeldTickets)
      return "/api_direct/v9/filtered_tickets/held"
    else if (@getMapping() == C.Mappings.Direct.FilteredTickets.TakenTickets)
      return "/api_direct/v9/filtered_tickets/taken"
    else
      return "/api_direct/v9/ticket_groups/#{ @ticketGroup.id }/ticket_holds"

  initialize: (models, options = {}) ->
    parent.initialize.call(@, models, options)
    {
      @eventId
      @ticketGroup
    } = options

  fetchPromise: (options = {}) ->
    origSuccessCallback = options.success
    newOptions = _.extend(options, {
      success: (collection, response, options) =>
        origSuccessCallback?(collection, response, options)
        @ticketGroup?.set('_ticketHolds', collection)
    })
    return parent.fetchPromise.call(@, options)

  parse: (response, options = {}) ->
    if (response.filtered_tickets)
      return response.filtered_tickets
    else if (response.ticket_holds)
      return response.ticket_holds
    else
      return response