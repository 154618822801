template = require('./begin_hold_take_cart.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.TicketGroups.BeginHoldTakeCart = App.Views.Base.BaseView.extend

  template: template

  className: 'modal hide fade'

  initialize: (options = {}) ->
    {
      @ticketGroupModel
      @isCart
      @isHold
      @isTake
    } = options
    @client = null
    parent.initialize.call(@, options)
    @render()

    if (@isHold)
      App.Utils.dateRangePicker(@$('#holdUntil'), @onDateTimeChanged, this, C.DatePickerConfig.TimePicker)

    @$('#holdDuration').on('change', () =>
      @$('#holdUntil').find('#dateTime').text('')
    )

  render: () ->
    @$el.html(
      @template({
        ticketGroup: @ticketGroupModel.toPresenterJSON()
        @isCart
        @isHold
        @isTake
      })
    )
    # STANDARDIZED MODAL
    @$el.modal({ backdrop: 'static' }).modal('show').on('hidden', () =>
      @remove()
    )

    @onSeatsHighOrLowChange()
    @onQuantityChange()
    @onPriceChange()

    @clientField = new App.Views.Clients.ClientField({
      el: @$("#client")
      type: 'Buyer'
      types: ['clients', 'offices']
      fullDisable: @isCart
    })

    @listenTo(@clientField, C.Events.ClientFieldIdChange, @onClientIdChanged)
    @clientField.render()

    @formErrors = new App.Utils.FormErrorManager(@$('.formErrors'))
    @loadingBox = new App.Utils.LoadingBox(@$('.modal-body'))
    @delegateEvents()

  remove: () ->
    @$el.modal('hide')

  events:
    "change #quantity":         "onQuantityChange"
    "change #price":            "onPriceChange"
    "change #seatsHighOrLow":   "onSeatsHighOrLowChange"
    "click #canOverrideSplits": "onCanOverrideSplitsChange"
    "click #holdButton":        "onHoldButtonClick"
    "click #takeButton":        "onTakeButtonClick"
    "click #addToCartButton":   "onAddToCartButtonClick"

  onQuantityChange: (e) ->
    quantity = @$('#quantity').val()
    @$('#quantityCell').text(quantity)
    @$('#quantity').select2()
    setTimeout(() =>
      @updateSeatRange()
      @onPriceChange()
    , 0)

  onPriceChange: (e) ->
    price = App.Utils.currencyToValue(@$('#price').val())
    quantity = @$('#quantity').val()
    total = App.Utils.valueToCurrency(price * quantity)
    @$("#totalCell").text(App.Utils.valueToCurrency(total))

  onSeatsHighOrLowChange: (e) ->
    @updateSeatRange()

  onCanOverrideSplitsChange: (e) ->
    $select = @$("#quantity").empty()
    $select2 = @$("#quantity").parent().find('.select2-container').remove()
    if ($(e.currentTarget).is(':checked'))
      tickets = @ticketGroupModel.get('_tickets')
      splits = _.map(App.Utils.showAvailableSeats(tickets), (v, i) ->
        return i + 1
      )
    else
      splits = @ticketGroupModel.get('_splits')
    _.each(splits, (split) ->
      $select.append("<option value='#{ split }'>#{ split }</option>")
    )
    @onQuantityChange()

  onHoldButtonClick: (e) ->
    @doHoldOrTake()

  onTakeButtonClick: (e) ->
    isTake = true
    @doHoldOrTake(isTake)

  extractFormData: () ->
    data = {}
    data.lowSeat = @getLowSeat('available')
    data.quantity = @$('#quantity').val()
    data.heldForId = @client?.heldForId
    data.heldForType = @client?.heldForType
    data.holdUntil = @getHoldUntil()
    data.price = @$('#price').val()
    data.notes = @$('#notes').val()
    return data

  doHoldOrTake: (isTake = false) ->
    action = @ticketGroupModel.holdPromise.bind(@ticketGroupModel)
    if (isTake)
      action = @ticketGroupModel.takePromise.bind(@ticketGroupModel)

    errors = @validate()
    if (errors.length)
      title = 'Invalid Hold'
      if (@isTake)
        title = 'Invalid Take'
      @formErrors.show({
        title
        errors
      })
      return

    @loadingBox.startLoad()
    data = @extractFormData()
    action(data)
    .then (model) =>
      @loadingBox.stopLoad()
      successText = if @isHold then 'Hold was successful.' else 'Take was successful.'
      $.gritter.add({
        title          : 'Successful'
        text           : successText
      })
      @$el.modal('hide')
      App.Controllers.ticketGroupController.getTicketGroupsListView()?.logicMediator('REFRESH_BUTTON')
    .fail (errors) =>
      @formErrors.show({
        title: 'Error performing hold.'
        errors
      })
      @loadingBox.stopLoad()
    .done()

  onAddToCartButtonClick: (e) ->
    @loadingBox.startLoad()
    formData = @extractFormData()

    seatOrder = C.SeatOrder.Consecutive

    if (App.Utils.isSeatArrayOddEven(@ticketGroupModel.get('_seats')))
      seatOrder = C.SeatOrder.OddEven

    # Sort by seat #.
    @ticketGroupModel.get('_ticketList').sort()
    # Remove unavailable tickets.
    tickets = @ticketGroupModel.get('_ticketList').filter((ticketModel) ->
      currentDate  = new Date()
      holdDate = new Date(ticketModel.get('_holdUntil') + 'Z')
      onHold = (ticketModel.get('_holdId') != 0) && (holdDate >= currentDate)
      return (ticketModel.get('_state') == 'available' && !onHold)
    )

    sliceN = formData.quantity

    if (sliceN > tickets.length)
      @loadingBox.stopLoad()
      @formErrors.show({
        title: "You are trying to add more tickets than are available to the cart."
      })
      return null

    startingHigh = (@$('#seatsHighOrLow').val() == 'high')
    if (startingHigh)
      tickets = tickets.slice(0 - sliceN)
    else
      tickets = tickets.slice(0, sliceN)

    ticketIds = tickets.map((ticketModel) ->
      return ticketModel.id
    )

    successCallback = (cart) =>
      cart.updateTitleBar()
      $.gritter.add({
        title          : 'Added To Cart'
        text           : 'Ticket Group was successfully added to your cart.'
      })
      finallyCallback()

    failCallback = (error) =>
      $.gritter.add({
        title          : 'Failed'
        text           : 'Ticket Group could not be placed in cart.'
      })
      finallyCallback()

    finallyCallback = () =>
      @loadingBox.stopLoad()
      @$el.modal('hide')
      App.Controllers.ticketGroupController.getTicketGroupsListView()?.logicMediator('REFRESH_BUTTON')

    # If existing cart
    if (SESSION.carts.length)
      cart = SESSION.carts.first()
      cart.add({
        ticket_ids: ticketIds
        low_seat:   formData.lowSeat
        price:      formData.price
        seat_order: seatOrder
      })
      .then(successCallback)
      .fail(failCallback)
      return

    # Else create new cart named 'default'
    else
      SESSION.carts.createPromise({
        name:       C.Cart.DefaultCart
        ticket_ids: ticketIds
        low_seat:   formData.lowSeat
        price:      formData.price
        seat_order: seatOrder
      }, { wait: true })
      .then(successCallback)
      .fail(failCallback)
      return

  onClientIdChanged: (id, category) ->
    if (category == 'offices')
      @client = {
        heldForType: 'Office'
        heldForId: id
      }
    else
      @client = {
        heldForType: 'Client'
        heldForId: id
      }

  updateSeatRange: () ->
    quantity = @$('#quantity').val()
    startingHigh = (@$('#seatsHighOrLow').val() == 'high')
    range = @ticketGroupModel.availableSeatsString(quantity, startingHigh)
    @$('#seatsCell').text(range)

  getLowSeat: (type) ->
    seats = @ticketGroupModel.seatsInState(type)
    lowOrHigh = @$('#seatsHighOrLow').val()
    quantity = @$("#quantity").val()
    lowSeat = if (lowOrHigh == 'low') then seats[0] else seats[seats.length - quantity]
    return lowSeat

  getHoldUntil: () ->
    if (@$('#holdDuration').length)
      holdUntilMinutes = @$('#holdDuration :selected').val()
      if (holdUntilMinutes == 'Other')
        holdUntil = @holdUntilDateTime.toISOString()
      else
        holdUntil = moment().add(holdUntilMinutes, 'minutes').toISOString()
      return holdUntil
    return null

  validate: () ->
    errors = []

    if (@isHold || @isTake)
      if (@$('#holdDuration').val() == 'Other')
        if (!@holdUntilDateTime)
          errors.push('A hold duration or expiration is required.')

    if (@isHold || @isTake)
      if (!@client || !@client.heldForId || !@client.heldForType)
        errors.push('Buyer is required.')

    price = @$('#price').val()
    if (price == '')
      errors.push('Price is required.')

    return errors

  onDateTimeChanged: (startDate, endDate) ->
    @holdUntilDateTime = startDate
    @$('#holdDuration').val('Other')