# TODO: Remove all handling for v2 order models.
module.exports = uploadsController = {}

_orderItemModels = {} # by orderItem id
_uploadEticketsViews = {} # by orderItem id
_orderModel = null

uploadsController.setOrderModel = (orderModel) ->
  _orderModel = orderModel

uploadsController.getOrderItemModel = (orderItemId) ->
  # Only case that should exist.  Others should be killed off with v2OrderItems.
  if (_orderModel instanceof App.Models.V3.Order)
    itemToUploadFor = _orderModel.get('_orderItems').getByOrderItemId(orderItemId)
    return itemToUploadFor
  else if (_orderItemModels[orderItemId])
    orderItemModel = _orderItemModels[orderItemId]
  else
    # On first usage, orderItem will come out of order.
    # Order comes from Controllers.OrderController.routeShow()
    items = _orderModel.get('items')
    itemToUploadFor = _.find(items, (item) =>
      return item.id == orderItemId
    )
    itemToUploadFor = new App.Models.V3.OrderItem(itemToUploadFor, {
      mapping: C.Mappings.Direct.Order.OrderItems
    })
    # Hack adjustments since it's really data from a v2 orderItem.
    # All getting deleted soon.
    itemToUploadFor.set('_orderId', _orderModel.id)
    itemToUploadFor.set('_orderItemLinkId', itemToUploadFor.id)
    return itemToUploadFor
    #uploadsController.buildOrderItemFromJSON(itemToUploadFor)

uploadsController.invokeClickAction = (options = {}) ->
  {
    orderItemId
    $uploadRow
    $substituteRow
  } = options
  orderItemModel = uploadsController.getOrderItemModel(orderItemId)
  uploadsController.showUploadView({ orderItemModel, $uploadRow })

uploadsController.showUploadView = (options = {}) ->
  {
    orderItemModel
    $uploadRow
  } = options
  $container = $uploadRow.find('td').eq(0)
  _uploadEticketsViews[orderItemModel.id] = new App.Views.Order.UploadEtickets({
    $container
    orderItemModel
    orderModel: _orderModel
  })

uploadsController.updateActionButton = (options) ->
  {
    orderItemModel
  } = options
  $button = $(".eticketButton[data-item-id=#{ orderItemModel.id }]")
  $button.closest('.eticketButtonContainer').replaceWith(
    uploadsController.eticketActionButton({ orderItem: orderItemModel })
  )

uploadsController.refreshViews = (orderItemModel) ->
  uploadView = _uploadEticketsViews[orderItemModel.id]
  uploadView.render()

uploadsController.uploadEticketPromise = (fileModel, orderItemModel) ->
  fileModel.set('_orderItem', orderItemModel)
  fileModel.set('purpose', C.FilePurposes.OrderItemEticket)
  return fileModel.savePromise()

uploadsController.uploadAirbillPromise = (fileModel, shipmentId, trackingNumber, carrier) ->
  fileModel.set('_shipmentId', shipmentId)
  fileModel.set('_trackingNumber', trackingNumber)
  fileModel.set('_carrier', carrier)
  fileModel.set('purpose', C.FilePurposes.UploadAirbill)
  return fileModel.savePromise()

uploadsController.eTicketActionCase = (orderItem) -> # Can be a V1 order_item object, or a V2 orderItem model.
  if (orderItem instanceof App.Models.V3.OrderItem || orderItem instanceof App.Models.V2.OrderItem)
    isE = orderItem.get('_isEticket')
    finalized = orderItem.get('_isEticketFinalized')
    pages = orderItem.get('_eTicketPDFPages')
  else # Sometimes it's json (directly called from templates).
    isE = orderItem.eticket_delivery || orderItem.isEticket
    finalized = orderItem.eticket_finalized || orderItem.isEticketFinalized
    pages = orderItem.eticket_pdf_pages || orderItem.eTicketPDFPages

  if (isE)
    if (_orderModel.isPO())
      if (finalized)
        # CASE = DOWNLOAD - You're on the consuming end of the order & etickets are ready for download.
        return C.EticketActions.Buy.Download
      else
        # CASE = WAITING - You're on the consuming end of the order & no etickets available yet.
        return C.EticketActions.Buy.Waiting
    else
      if (finalized)
        # CASE = VIEW - You've uploaded and finalized the etickets.
        return C.EticketActions.Sell.View
      else
        if (pages)
          # CASE = MANAGE - You added some PDF's but never finalized.
          return C.EticketActions.Sell.Manage
        else
          # CASE = UPLOAD - You haven't added anything yet but need to.
          return C.EticketActions.Sell.Upload
  else
    return C.EticketActions.Sell.Convert

  #console.error('eTicketActionCase could not determine an appropriate case.', orderModel, orderItem, isE, finalized, pages)
  return null

uploadsController.eticketActionButton = (options = {}) ->
  {
    orderItem # Can be a V1 order_item object, or a V2 orderItem model.
    orderType
  } = options
  orderItemLinkId = orderItem.id
  orderItemId = orderItem.order_item_id || orderItem.get('_orderItemId')

  c = uploadsController.eTicketActionCase(orderItem)
  html = "<span class='eticketButtonContainer'>"
  switch c
    when C.EticketActions.Buy.Download
      html += "<div class='btn btn-success eticketButton' data-item-id='#{ orderItemLinkId }'><i class='fa-solid fa-magnifying-glass-plus'></i> Preview Etickets</div>"
      filename = App.Utils.fileNameFromOrderItem(orderItem)
      html += "<a style='margin-left: 10px;' class='btn btn-primary' data-bypass='1' href='/orders/#{ _orderModel.id }/print_etickets/#{ orderItemId }?filename=#{ encodeURIComponent(filename) }'><i class='fa-solid fa-download'></i> Download Etickets</a>"
    when C.EticketActions.Buy.Waiting
      html += "Waiting for Seller to Upload Etickets"
    when C.EticketActions.Sell.View
      html += "<div class='btn btn-success eticketButton' data-item-id='#{ orderItemLinkId }'><i class='fa-solid fa-magnifying-glass-plus'></i> View Etickets</div>"
      filename = App.Utils.fileNameFromOrderItem(orderItem)
      html += "<a style='margin-left: 10px;' class='btn btn-primary' data-bypass='1' href='/orders/#{ _orderModel.id }/print_etickets/#{ orderItemId }?filename=#{ encodeURIComponent(filename) }'><i class='fa-solid fa-download'></i> Download Etickets</a>"
    when C.EticketActions.Sell.Manage
      html += "<div class='btn btn-info eticketButton' data-item-id='#{ orderItemLinkId }'><i class='fa-solid fa-qrcode'></i> Manage Etickets</div>"
    when C.EticketActions.Sell.Upload
      if (_.contains([C.Accepted, C.Completed], _orderModel.get('_sellerState')))
        html += "<div class='btn btn-primary eticketButton' data-item-id='#{ orderItemLinkId }'><i class='fa-solid fa-upload'></i> Upload Etickets</div>"
    when C.EticketActions.Sell.Convert
      if (orderType && (orderType != C.OrderTypes.Sale || orderType != C.OrderTypes.Sell)) || (SESSION.role.cannot(App.Access.Resources.Orders.OrderShow.CanConvertEtickets))
        $.noop()
      else
        if (_orderModel.get('_buyerState') == C.Accepted && _orderModel.get('_sellerState') == C.Accepted)
          html += "<div data-toggle='tooltip' title='Warning: Only use this button if you know the Seller has the ability to upload Etickets for this Order.' class='btn btn-warning eticketButton' data-item-id='#{ orderItemLinkId }'>Etickets can be Uploaded</div>"
        else
          $.noop()
  html += "</span>"
  return html
