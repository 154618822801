App.Models.Base.BaseModel = require('../base/base_model.coffee')

parent = App.Models.Base.BaseModel.prototype
module.exports = App.Models.V2.Office = App.Models.Base.BaseModel.extend({

  urlRoot: '/offices'

  has_one: ['address']

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  parse: (response, options) ->
    parent.parse.call(@, response, options)
    return response

  fetch: (options) ->
    @mapping = C.Mappings.OfficeEndpoint.Office
    parent.fetch.call(@, options)

  toJSON: (options) ->
    json = parent.toJSON.call(@, options)
    json.address = json.address?.toJSON()
    return json

  updateStandardAttributes: () ->
    json = @attributes
    attrs = {}

    # FIND WHICH MAPPING THIS APPLIES TO
    attrs.address = new App.Models.V2.Address(json?.address?.attributes || json?.address, {
      holder_id: @id
      mapping: C.Mappings.Unknown
    })

    if (@getMapping() == C.Mappings.OfficeEndpoint.Office)
      attrs._address = new App.Models.V2.Address(json.address, {
        mapping: C.Mappings.OfficeEndpoint.Address
      })
      attrs._brokerage = new App.Models.V2.Brokerage(json.brokerage, {
        mapping: C.Mappings.OfficeEndpoint.Brokerage
      })
      attrs._emails = json.email
      attrs._evopayDiscount =json.evopay_discount
      attrs._fax = json.fax
      attrs._hours = json.hours
      attrs._id = json.id
      attrs._isMain = json.main
      attrs._name = json.name
      attrs._phone = json.phone
      attrs._isPOS = json.pos
      attrs._timeZone = json.time_zone
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.TicketGroupEndpoint.Office)
      attrs._brokerage = new App.Models.V2.Brokerage(json.brokerage, {
        mapping: C.Mappings.TicketGroupEndpoint.Brokerage
      })
      attrs._id = json.id
      attrs._name = json.name
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.OrderEndpoint.Patron)
      attrs._brokerage = new App.Models.V2.Brokerage(json.brokerage, {
        mapping: C.Mappings.OrderEndpoint.Brokerage
      })
      attrs._id = json.id
      attrs._location = json.location
      attrs._name = json.name
      attrs._type = json.type
      attrs._url = json.url

    if (@getMapping() == C.Mappings.V2OrderEndpoint.Patron)
      attrs._address = new App.Models.V2.Address(json.address, {
        mapping: C.Mappings.V2OrderEndpoint.Address
      })
      attrs._brokerage = new App.Models.V2.Brokerage(json.brokerage, {
        mapping: C.Mappings.V2OrderEndpoint.Brokerage
      })
      attrs._emailAddress = json.email_address
      attrs._id = json.id
      attrs._isEvoPay = json.evopay
      attrs._name = json.name
      attrs._phoneNumber = json.phone
      attrs._type = json.type
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.SingleTicketGroupEndpoint.Office)
      attrs._brokerage = new App.Models.V2.Brokerage(json.brokerage, {
        mapping: C.Mappings.SingleTicketGroupEndpoint.Brokerage
      })
      attrs._id = json.id
      attrs._name = json.name
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {
      brokerage:     @get('_brokerage').toPresenterJSON()
      id:            @get('_id')
      isPOS:         @get('_isPOS')
      location:      @get('_location')
      name:          @get('_name')
      type:          @get('_type')
      url:           @get('_url')
      address:       @address()?.toPresenterJSON()
      phone_number:  @get('phone')
      email_address: ""
      emailAddressOptions: @emailAddressOptions()
      phone_numbers: @phone_numbers()
      phoneNumberOptions: @phoneNumberOptions()
      addresses: @addresses().toPresenterJSON()
    })
    return presented

  #normalized methods
  address: -> @addresses().first()

  addresses: ->
    if address = @get('address')
      unless address instanceof App.Models.V2.Address
        address = new App.Models.V2.Address(address)
      address = new App.Collections.V3.Addresses(address)
    address or new App.Collections.V3.Addresses()

  email_address: -> @email_addresses[0]

  email_addresses: ->
    emails = []
    if @get('brokerage') and @get('brokerage').email
      emails.push({id:0, address: @get("brokerage").email})
    emails.push( id:0, address: @get('email'))
    emails

  name: -> @ship_to_name()

  phone_number: ->
    nums = @phone_numbers()
    if nums.length > 0 then nums[0] else ""

  emailAddressOptions: () ->
    return [ @get('_emailAddress') ]

  phoneNumberOptions: () ->
    numbers = []
    if (@get('phone'))
      number = @get("phone")
      numbers.push({
        number
      })
    return numbers

  phone_numbers: () ->
    numbers = []
    if (@get('phone'))
      numbers.push(@get("phone"))
#      if (@get('_phoneNumber'))
#        numbers.push(@get('_phoneNumber'))
#      numbers = _.uniq(numbers)
    return numbers

  ship_to_company_name: -> ""

  ship_to_name: -> "#{@get("brokerage")?name} - #{@get("name")}"
  #///////////////////////////////////////////////////////////////////////////

}, {
  # STATICS
  defaultMapping: C.Mappings.TicketGroupEndpoint.Office
})
