module.exports = App.Models.Base.BaseModel = Backbone.Model.extend

  #fix to let models use urlRoot because of stupid rails backbone sync
  url: -> Backbone.Model.prototype.url.call @

  has_one: {}
  has_many: []

  collections: -> if _.isArray(@has_many) then @has_many else _.keys(@has_many)
  models:      -> if _.isArray(@has_one)  then @has_one  else _.keys(@has_one)
  required:    -> _.filter _.keys(@validates), (key) => @validates[key].required

  #@super('initialize', [attributes, options])
  super: (f, options) -> @constructor.__super__[f].apply(@, options)

  #    super: @constructor.__super__
  # fancy syntax for: super = App.Models.Base.BaseModel.prototype

  initialize: (attributes, options) ->
    options ||= {}
    @mapping = options.mapping
    @setup_nested && @setup_nested()
    @updateStandardAttributes?()

  setup_nested:  (json) ->
    @initialize_required_attrs(json)

  initialize_required_attrs: (json) ->
    _.each @required(), (attr) =>
      if json
        json[attr] ||= null
      else if not @get(attr)
        @set(attr, null)

  getMapping: () ->
    if (@mapping)
      return @mapping
    else if (mappingThatsNotNull = @collection?.getMapping?())
      return mappingThatsNotNull
    else
      #console.warn('WARNING: Using default mapping.', @constructor.defaultMapping)
      return @constructor.defaultMapping

  toPresenterJSON: () ->
    @updateStandardAttributes && @updateStandardAttributes()
    presented = _.extend(@toJSON(), {
    # No additional fields
    })
    return presented

  parse: (json) ->
    @setup_nested(json)
    json

  validate: () ->
    errors     = []
    errors = errors.concat @validate_non_nested_attributes() || []
    #may need to figure out how to do this better if its needed.  Right now it isnt
    #errors = errors.concat @validate_nested_attributes()     || []
    unless errors.length then false else errors

  validate_non_nested_attributes: ->
    errors = []
    _.each _.keys(@non_nested_attributes()), (attr) =>
      if attr_validator_props = @validates[attr]
        if attr_validator_props.required and not @get(attr)
          errors.push({attr:attr, errors:["#{attr.humanize()} is required"]})
    unless errors.length then false else errors

  non_nested_attributes: ->
    nested = @collections().concat(@models())
    _.omit(@attributes, nested)


