module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<table id="quote_table" class="table table-bordered table-striped table-hover data-table">\n  <thead>\n  <tr>\n    <th>ID</th>\n    <th style="min-width: 100px;">Date</th>\n    <th>Name</th>\n    <th>Prepared For</th>\n    <th>Prepared By</th>\n    <th>Event</th>\n    <th>State</th>\n  </tr>\n  </thead>\n  <tbody>\n  <%\n  _.each(quotes, function(item, i){\n  %>\n  <tr>\n    <td><a href="/evoquote/<%= item.id %>"><%= item.id %></a></td>\n    <td><%= App.Utils.makeTimestampHuman(item.createdOrCompletedDate, C.DateFormats.TableDateWithTime) %></td>\n    <td><%= item.recipient.name %></td>\n    <td><%= item.recipient.preparedFor %></td>\n    <td><%= item.user.name %></td>\n    <td><%- item.event.fullDescription %></td>\n    <td><%= item.state %></td>\n  </tr>\n  <%\n  });\n  %>\n</table>', __filename = "js/views/evoquote/quote_table.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<table id="quote_table" class="table table-bordered table-striped table-hover data-table">\n  <thead>\n  <tr>\n    <th>ID</th>\n    <th style="min-width: 100px;">Date</th>\n    <th>Name</th>\n    <th>Prepared For</th>\n    <th>Prepared By</th>\n    <th>Event</th>\n    <th>State</th>\n  </tr>\n  </thead>\n  <tbody>\n  ');
            __line = 14;
            _.each(quotes, function(item, i) {
                __line = 16;
                __append('\n  <tr>\n    <td><a href="/evoquote/');
                __line = 18;
                __append(escapeFn(item.id));
                __append('">');
                __append(escapeFn(item.id));
                __append("</a></td>\n    <td>");
                __line = 19;
                __append(escapeFn(App.Utils.makeTimestampHuman(item.createdOrCompletedDate, C.DateFormats.TableDateWithTime)));
                __append("</td>\n    <td>");
                __line = 20;
                __append(escapeFn(item.recipient.name));
                __append("</td>\n    <td>");
                __line = 21;
                __append(escapeFn(item.recipient.preparedFor));
                __append("</td>\n    <td>");
                __line = 22;
                __append(escapeFn(item.user.name));
                __append("</td>\n    <td>");
                __line = 23;
                __append(item.event.fullDescription);
                __append("</td>\n    <td>");
                __line = 24;
                __append(escapeFn(item.state));
                __append("</td>\n  </tr>\n  ");
                __line = 26;
            });
            __line = 28;
            __append("\n</table>");
            __line = 29;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}