module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div class="modal-header">\n  <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n  <h3 class="modal-title">Proof Of Transfer</h3>\n</div>\n\n<div class="modal-body">\n  <table class="table table-bordered">\n    <thead>\n      <tr>\n        <th>Upload Date</th>\n        <th>Type</th>\n        <th>Download Link(s)</th>\n      </tr>\n    </thead>\n    <tbody>\n      <%\n        transferVerifications.forEach(function(transferVerification) {\n      %>\n      <tr>\n        <td><%= App.Utils.makeTimestampHumanEST(transferVerification.updated_at) %></td>\n        <td><%= transferVerification.type %></td>\n        <td>\n          <ul>\n            <%\n              transferVerification.uploads.forEach(function(upload) {\n            %>\n            <li>\n              <a href=<%=upload.url%>>\n                <%= upload.file_name %>\n              </a>\n            </li>\n            <% \n              }); // END transferVerification.uploads.forEach()\n            %>\n          </ul>\n        </td>\n      </tr>\n      <%\n        }); // END transferVerifications.forEach()\n      %>\n    </tbody>\n  </table>\n</div>\n', __filename = "js/views/shipping/modal_proof_of_transfer_uploads.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div class="modal-header">\n  <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n  <h3 class="modal-title">Proof Of Transfer</h3>\n</div>\n\n<div class="modal-body">\n  <table class="table table-bordered">\n    <thead>\n      <tr>\n        <th>Upload Date</th>\n        <th>Type</th>\n        <th>Download Link(s)</th>\n      </tr>\n    </thead>\n    <tbody>\n      ');
            __line = 16;
            transferVerifications.forEach(function(transferVerification) {
                __line = 18;
                __append("\n      <tr>\n        <td>");
                __line = 20;
                __append(escapeFn(App.Utils.makeTimestampHumanEST(transferVerification.updated_at)));
                __append("</td>\n        <td>");
                __line = 21;
                __append(escapeFn(transferVerification.type));
                __append("</td>\n        <td>\n          <ul>\n            ");
                __line = 24;
                transferVerification.uploads.forEach(function(upload) {
                    __line = 26;
                    __append("\n            <li>\n              <a href=");
                    __line = 28;
                    __append(escapeFn(upload.url));
                    __append(">\n                ");
                    __line = 29;
                    __append(escapeFn(upload.file_name));
                    __append("\n              </a>\n            </li>\n            ");
                    __line = 32;
                });
                __line = 34;
                __append("\n          </ul>\n        </td>\n      </tr>\n      ");
                __line = 38;
            });
            __line = 40;
            __append("\n    </tbody>\n  </table>\n</div>\n");
            __line = 44;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}