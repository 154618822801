template = require('./invalid_model.ejs');

module.exports = App.Views.Shared.InvalidModel = Backbone.View.extend({

  className: 'modal hide fade',

  id: 'invalid-model-modal',

  template: template,

  // options can include:
  //  errorTitle: modal title
  //  errors: array of errors
  //  model: backbone model with errors
  //  response: backbone ajax sync error: (model, response, options)
  //  jqXHR: jQuery ajax error: (jqXHR, textStatus, errorThrown)
  initialize: function(options) {
    var that = this;
    var displayErrors = [];

    this.errorTitle = options.errorTitle || '';
    this.showDetails = options.showDetails || false;

    if (options.errors) {
      displayErrors = displayErrors.concat(options.errors);
    }

    if (options.model) {
      displayErrors = displayErrors.concat(options.model.errors);
    }

    if (options.response || options.jqXHR) {
      var xhr = options.response || options.jqXHR;
      if (xhr.responseText) {
        try {
          json = JSON.parse(xhr.responseText);
          for (var key in json) {
            displayErrors.push(key + ': ' + json[key]);
          }
        } catch (e) {
          displayErrors.push(xhr.responseText);
        }
      } else {
        displayErrors.push(xhr);
      }
    }

    this.displayErrors = displayErrors;
  },

  render: function() {
    var that = this;

    var data = {
      errors: this.displayErrors,
      errorTitle: this.errorTitle,
      showDetails: this.showDetails
    };

    this.$el.html(this.template(data));

    // STANDARDIZED MODAL
    this.$el.modal('show').on('hidden', function() {
      that.remove();
    });
  }

});