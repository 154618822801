module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<%\nif (!_.size(shipments)) {\n%>\n<h5 style="width: 100%; text-align:center; line-height: 150px;">Please use the filter to find shipments.</h5>\n<%\n} else {\n%>\n<div id="formErrors"></div>\n<table id="shipment_table" class="table table-bordered table-striped table-hover data-table">\n  <thead>\n    <tr>\n      <th>\n        <!-- Actions -->\n      </th>\n      <th>ID</th>\n      <th>Status</th>\n      <th>Date Created</th>\n      <th>Recipient Name</th>\n      <th>Service Type</th>\n      <th>Tracking Number</th>\n    </tr>\n  </thead>\n  <tbody>\n    <%\n  _.each(shipments, function(shipment, i){\n  %>\n    <tr class="shipment" data-id="<%= shipment.id%>" data-state="<%= shipment.state %>" data-tracking-number="<%= shipment.trackingNumber %>" data-has-airbill="<%= shipment.hasAirbill %>">\n      <td>\n        <div class="btn-group" <% if (shipment.state == C.Options.Shipment.States[\'canceled\'] || shipment.state == C.Options.Shipment.States[\'returned\']) { %> style="display: none;" <% } %>>\n          <button class="btn btn-small dropdown-toggle" data-toggle="dropdown" tabindex="-1">\n            <i class="fa-solid fa-plus" />\n          </button>\n        </div>\n      </td>\n      <td><%= shipment.id %></td>\n      <td><%= shipment.state %></td>\n      <td><%= (shipment.shipmentCreatedAt) ? App.Utils.makeTimestampHuman(shipment.shipmentCreatedAt, C.TableDateWithTime) : \'\' %></td>\n      <td><%= shipment.shipToName %></td>\n      <td><%= shipment.serviceTypeDisplay %></td>\n      <td><%= shipment.trackingNumber %></td>\n    </tr>\n    <%\n  });\n  %>\n</table>\n<%\n}\n%>\n', __filename = "js/views/shipping/shipment_table.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            if (!_.size(shipments)) {
                __line = 3;
                __append('\n<h5 style="width: 100%; text-align:center; line-height: 150px;">Please use the filter to find shipments.</h5>\n');
                __line = 5;
            } else {
                __line = 7;
                __append('\n<div id="formErrors"></div>\n<table id="shipment_table" class="table table-bordered table-striped table-hover data-table">\n  <thead>\n    <tr>\n      <th>\n        <!-- Actions -->\n      </th>\n      <th>ID</th>\n      <th>Status</th>\n      <th>Date Created</th>\n      <th>Recipient Name</th>\n      <th>Service Type</th>\n      <th>Tracking Number</th>\n    </tr>\n  </thead>\n  <tbody>\n    ');
                __line = 24;
                _.each(shipments, function(shipment, i) {
                    __line = 26;
                    __append('\n    <tr class="shipment" data-id="');
                    __line = 27;
                    __append(escapeFn(shipment.id));
                    __append('" data-state="');
                    __append(escapeFn(shipment.state));
                    __append('" data-tracking-number="');
                    __append(escapeFn(shipment.trackingNumber));
                    __append('" data-has-airbill="');
                    __append(escapeFn(shipment.hasAirbill));
                    __append('">\n      <td>\n        <div class="btn-group" ');
                    __line = 29;
                    if (shipment.state == C.Options.Shipment.States["canceled"] || shipment.state == C.Options.Shipment.States["returned"]) {
                        __append(' style="display: none;" ');
                    }
                    __append('>\n          <button class="btn btn-small dropdown-toggle" data-toggle="dropdown" tabindex="-1">\n            <i class="fa-solid fa-plus" />\n          </button>\n        </div>\n      </td>\n      <td>');
                    __line = 35;
                    __append(escapeFn(shipment.id));
                    __append("</td>\n      <td>");
                    __line = 36;
                    __append(escapeFn(shipment.state));
                    __append("</td>\n      <td>");
                    __line = 37;
                    __append(escapeFn(shipment.shipmentCreatedAt ? App.Utils.makeTimestampHuman(shipment.shipmentCreatedAt, C.TableDateWithTime) : ""));
                    __append("</td>\n      <td>");
                    __line = 38;
                    __append(escapeFn(shipment.shipToName));
                    __append("</td>\n      <td>");
                    __line = 39;
                    __append(escapeFn(shipment.serviceTypeDisplay));
                    __append("</td>\n      <td>");
                    __line = 40;
                    __append(escapeFn(shipment.trackingNumber));
                    __append("</td>\n    </tr>\n    ");
                    __line = 42;
                });
                __line = 44;
                __append("\n</table>\n");
                __line = 46;
            }
            __line = 48;
            __append("\n");
            __line = 49;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}