template = require('./dropdown.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

ALL_PAYMENT_TYPES = {
  all: 'All'
  cash: 'Cash'
  check: 'Check'
  credit_card: 'Credit Card'
  credit_memo: 'Credit Memo'
  evopay: 'EvoPay'
  money_order: 'Money Order'
  no_payment: 'No Payment'
  offline: 'Offline'
  pay_pal: 'PayPal'
  penalty: 'Penalty'
  'return': 'Return'
  tbd: 'TBD'
}

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Payments.Dropdown = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    {
      @$container
      @supportedPaymentTypes
      @defaultPaymentType
      @name
      @onChange
      action
    } = options

    ALLOWED_PAYMENT_TYPES = {}
    if (SESSION.role.can(App.Access.Resources.PaymentTypes.Cash))
      ALLOWED_PAYMENT_TYPES.cash = 'Cash'
    if (SESSION.role.can(App.Access.Resources.PaymentTypes.Check))
      ALLOWED_PAYMENT_TYPES.check = 'Check'
    if (SESSION.role.can(App.Access.Resources.PaymentTypes.CreditCard))
      ALLOWED_PAYMENT_TYPES.credit_card = 'Credit Card'
    if (SESSION.role.can(App.Access.Resources.PaymentTypes.CreditMemo))
      ALLOWED_PAYMENT_TYPES.credit_memo = 'Credit Memo'
    if (SESSION.role.can(App.Access.Resources.PaymentTypes.EvoPay))
      ALLOWED_PAYMENT_TYPES.evopay = 'EvoPay'
    if (SESSION.role.can(App.Access.Resources.PaymentTypes.MoneyOrder))
      ALLOWED_PAYMENT_TYPES.money_order = 'Money Order'
    if (SESSION.role.can(App.Access.Resources.PaymentTypes.NoPayment))
      ALLOWED_PAYMENT_TYPES.no_payment = 'No Payment'
    if (SESSION.role.can(App.Access.Resources.PaymentTypes.PayPal))
      ALLOWED_PAYMENT_TYPES.pay_pal = 'PayPal'
    if (SESSION.role.can(App.Access.Resources.PaymentTypes.TBD))
      ALLOWED_PAYMENT_TYPES.tbd = 'TBD'

    @name ||= 'payment_type'

    # Do we have to rename TBD (careful to only add it if it's allowed.
    if (SESSION.role.can(App.Access.Resources.PaymentTypes.TBD))
      if (action == C.BuySellActions.Buy)
        ALLOWED_PAYMENT_TYPES.tbd = 'TBD-Accounts Payable'
      if (action == C.BuySellActions.Sell)
        ALLOWED_PAYMENT_TYPES.tbd = 'TBD-Accounts Receivable'

    @dropdownOptions = {}

    # Allowed and supported.
    for own key, val of ALLOWED_PAYMENT_TYPES
      if (_.contains(@supportedPaymentTypes, key))
        @dropdownOptions[key] = ALL_PAYMENT_TYPES[key]

    # Supported but not allowed ("premium only").
    @blockedOptions = {}
    for key in @supportedPaymentTypes
      if (!ALLOWED_PAYMENT_TYPES[key])
        @blockedOptions[key] = ALL_PAYMENT_TYPES[key]

    @render()

  render: ->
    @$container.html(
      @$el.html(
        @template({
          @blockedOptions
          @defaultPaymentType
          @dropdownOptions
          @name
        })
      )
    )
    @onTypeChange()

  # VIEW EVENTS ////////////////////////////////////////////////////////////////
  events:
    'change #payment_type' : 'onTypeChange'

  onTypeChange: ->
    @currentPaymentType = @$("#payment_type").val()
    @onChange?(@currentPaymentType)
  #/////////////////////////////////////////////////////////////////////////////