App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.File = App.Models.Base.V3BaseModel.extend

  url: () ->
    switch @get('purpose')
      when C.FilePurposes.OrderItemEticket
        orderId = @get('_orderItem').get('_orderId')
        orderItemLinkId = @get('_orderItem').id
        return "/orders/#{ orderId }/items/#{ orderItemLinkId }/upload_eticket"
      when C.FilePurposes.InventoryUpload
        return "/api_direct/v9/ticket_groups/import"
      when C.FilePurposes.TicketEticket
        return "/api_direct/v9/etickets/split_pdf"
      when C.FilePurposes.UploadAirbill
        shipmentId = @get('_shipmentId')
        return "/api_direct/v9/shipments/#{ shipmentId }/upload_airbill"

  toJSON: () ->
    if (@get('purpose') == C.FilePurposes.TicketEticket)
      json = { files: [@get('base64')] }
      return json
    else if (@get('purpose') == C.FilePurposes.OrderItemEticket)
      json = parent.toJSON.call(@)
      json.order_item = @get('_orderItem').toJSON()
      return json
    else if (@get('purpose') == C.FilePurposes.UploadAirbill)
      json = {
        file: @get('base64')
        tracking_number: @get('_trackingNumber')
        carrier: @get('_carrier')
      }
      return json
    else
      json = parent.toJSON.call(@)
      return json

  updateStandardAttributes: (json = @attributes) ->
    $.noop()

  # PRESENTATION ///////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    presented = _.extend(@toJSON(), {
      name: @get('name')
      size: @get('size')
      type: @get('type')
    })
    return presented
  #/////////////////////////////////////////////////////////////////////////////