template = require('./client_field.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Clients.ClientField = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    @required_args ["id", "name", "$selector"], options
    @optional_args ["addOnly", "fullDisable", "pos", "partner", "type", "types", "selector"], options
    @type     ||=  'Client'
    @types    ||=  ['clients']
    @selector ||=  ".client-input input.client"

  # VIEW EVENTS ////////////////////////////////////////////////////////////////
  events:
    "click .new_client": "addClient"

  addClient: (e) ->
    @handleE(e)
    @form = new App.Views.Clients.Show({
      isModal: true
    })
    @listenTo(@form, 'clientFormComplete', @onComplete)
  #/////////////////////////////////////////////////////////////////////////////

  select: (e, ui) ->
    @id = ui.item.id
    @name = ui.item.label
    @category = ui.item.category
    @trigger(C.Events.ClientFieldIdChange, @id, @category)
    @trigger(C.Events.ClientFieldNameChange, @name)

  render: () ->
    @$el.html(
      @template({
        addOnly: !!(@addOnly)
        @type
        @name
        fullDisable: !!(@fullDisable)
      })
    )

    if (!@addOnly)
      App.Utils.autocomplete({
        @selector
        @$selector
        @types
        @pos
        @partner
        select: _.bind(@select, @)
      })

    return @$el

  getId: () ->
    return @id

  getName: () ->
    return @name

  onComplete: (client) ->
    @id = client.get("id")
    @name = client.get("name")
    @trigger(C.Events.ClientFieldIdChange, @id, @category)
    @trigger(C.Events.ClientFieldNameChange, @name)
    @$("input.client").val(@name)

