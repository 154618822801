App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V3.EmailAddress = require('../../models/v3/email_address_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V3.EmailAddresses = App.Collections.Base.BaseCollection.extend

  model: App.Models.V3.EmailAddress

  toList: () ->
    emails = []
    @models.forEach((emailModel) ->
      emails.push(emailModel.get('_address'))
    )
    return emails.join(', ')
