App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V3.Office = require('../../models/v3/office_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V3.Offices = App.Collections.Base.BaseCollection.extend

  model: App.Models.V3.Office

  url: '/api_direct/v9/offices'

  initialize: (models, options) ->
    parent.initialize.call(@, models, options)

  parse: (response, options) ->
    if (response.offices)
      return response.offices
    else
      return response