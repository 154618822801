App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V2.OrderItems = require('../../models/v2/order_item_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V2.OrderItems = App.Collections.Base.BaseCollection.extend

  url: '/api_direct/v9/order_items'

  model: App.Models.V2.OrderItem

  initialize: (models, options) ->
    parent.initialize.call(@, models, options)
    @currentPage = 0

  findOriginalSpecGroups: (options) ->
    {subs}         = options
    itemsByOrderId = {}
    _.each @models, (orderItem) =>
      itemsByOrderId[orderItem.get('order_item_id')] = orderItem
    #do some stuff with the subs
    _.each(subs, (sub) =>
      itemIdSubWasFor = sub.order_item_id
      item = itemsByOrderId[itemIdSubWasFor]
      item.originalSpecTicketGroup = sub.original
    )
    itemsByOrderId

  #EVENT FUNCTIONS THAT NEED A GOOD REFACTOR
  events: ->
    @uniqueEvents = {}
    @each (item) =>
      if item.get('ticket_group')
        event     = item.event()
        office_id = item.ticketGroupOfficeId()
        #set unique keys
        event   = @uniqueEvents[event.id]  ||= event
        offices = event.offices[office_id] ||= item.office()
        #add light ticket group
        offices.ticket_groups.push(item.ticketGroup())
    sorted   = _.values(@uniqueEvents).sort (a,b) -> a.occurs_at > b.occurs_at
    _.map sorted, (event) -> new App.Models.V3.Event(event)

  getEvents: ->
    events = @map    (item) -> item.get('event')
    events = _.groupBy events, (item) -> item.id
    events = _.map _.toArray(events), (arr) -> arr[0]
    events.sort (a,b) -> a.get('occurs_at') > b.get('occurs_at')
