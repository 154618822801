module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<%\nif (!_.size(emails)) {\n%>\n<h5 style="width: 100%; text-align:center; line-height: 150px;">Please use the filter to find emails.</h5>\n<%\n} else {\n%>\n<div id="formErrors"></div>\n<table id="email_table" class="table table-bordered table-striped table-hover data-table">\n  <thead>\n    <tr>\n      <th>\n        <!-- Actions -->\n      </th>\n      <th>Recipients</th>\n      <th>Order ID</th>\n      <th>Status</th>\n      <th>Sent At</th>\n      <th>Subject</th>\n    </tr>\n  </thead>\n  <tbody>\n    <%\n  _.each(emails, function(email, i){\n  %>\n    <tr class="email" data-email-id="<%= email.id%>">\n      <td>\n        <div class="btn-group">\n          <button class="btn btn-small dropdown-toggle" data-toggle="dropdown" tabindex="-1">\n            <i class="fa-solid fa-plus" />\n          </button>\n        </div>\n      </td>\n      <td><%= email.recipients %></td>\n      <td><%= email.orderID%></td>\n      <td><%= email.status%></td>\n      <td><%= (email.sentAt) ? App.Utils.makeTimestampHuman(email.sentAt, C.TableDateWithTime) : \'\'%></td>\n      <td><a href="/email/<%=email.id%>"><%= email.subject %></a></td>\n    </tr>\n    <%\n  });\n  %>\n</table>\n<%\n}\n%>\n', __filename = "js/views/emails/email_table.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            if (!_.size(emails)) {
                __line = 3;
                __append('\n<h5 style="width: 100%; text-align:center; line-height: 150px;">Please use the filter to find emails.</h5>\n');
                __line = 5;
            } else {
                __line = 7;
                __append('\n<div id="formErrors"></div>\n<table id="email_table" class="table table-bordered table-striped table-hover data-table">\n  <thead>\n    <tr>\n      <th>\n        <!-- Actions -->\n      </th>\n      <th>Recipients</th>\n      <th>Order ID</th>\n      <th>Status</th>\n      <th>Sent At</th>\n      <th>Subject</th>\n    </tr>\n  </thead>\n  <tbody>\n    ');
                __line = 23;
                _.each(emails, function(email, i) {
                    __line = 25;
                    __append('\n    <tr class="email" data-email-id="');
                    __line = 26;
                    __append(escapeFn(email.id));
                    __append('">\n      <td>\n        <div class="btn-group">\n          <button class="btn btn-small dropdown-toggle" data-toggle="dropdown" tabindex="-1">\n            <i class="fa-solid fa-plus" />\n          </button>\n        </div>\n      </td>\n      <td>');
                    __line = 34;
                    __append(escapeFn(email.recipients));
                    __append("</td>\n      <td>");
                    __line = 35;
                    __append(escapeFn(email.orderID));
                    __append("</td>\n      <td>");
                    __line = 36;
                    __append(escapeFn(email.status));
                    __append("</td>\n      <td>");
                    __line = 37;
                    __append(escapeFn(email.sentAt ? App.Utils.makeTimestampHuman(email.sentAt, C.TableDateWithTime) : ""));
                    __append('</td>\n      <td><a href="/email/');
                    __line = 38;
                    __append(escapeFn(email.id));
                    __append('">');
                    __append(escapeFn(email.subject));
                    __append("</a></td>\n    </tr>\n    ");
                    __line = 40;
                });
                __line = 42;
                __append("\n</table>\n");
                __line = 44;
            }
            __line = 46;
            __append("\n");
            __line = 47;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}