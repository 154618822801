App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.Order = App.Models.Base.V3BaseModel.extend

  url: () ->
    if (@getMapping() == C.Mappings.Direct.Order.Order)
      if (@purpose == C.Purposes.OrderAccept || @purpose == C.Purposes.OrderAcceptConsignmentMoveUnsold)
        return "/api_direct/v9/orders/#{ @id }/accept"
      else if (@purpose == C.Purposes.OrderCancel)
        return "/api_direct/v9/orders/#{ @id }/cancel"
      else if (@purpose == C.Purposes.OrderSendPO)
        return "/api_direct/v9/orders/#{ @id }/pend_to_seller"
      else if (@purpose == C.Purposes.OrderReject)
        return "/api_direct/v9/orders/#{ @id }/reject"
      else if (@purpose == C.Purposes.SubmitPO)
        return "/api_direct/v9/orders/background"
      else if (@purpose == C.Purposes.SubOrder)
        return "/api_direct/v9/orders"
      else if (@isNew())
        return "/api_direct/v9/orders"
      else
        return "/api_direct/v9/orders/#{ @id }"
    if (@getMapping() == C.Mappings.Direct.OrderShowLite.Order)
      return "/api_direct/v9/orders/show_lite?id=#{ @id }"
    if (@getMapping() == C.Mappings.V2OrderEndpoint.Order)
      return "/v2/orders/#{ @id }"
    return null

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  validation: () ->
    if (@purpose == C.Purposes.SubmitOrder)
      buyer = @get('_buyer')
      evoPayBalance = buyer.toPresenterJSON().spendable
      isInvoicedBuyer = buyer.toPresenterJSON().invoiced_buyer
      payment = @get('_payment')
      paymentType = payment.get('_type')
      if (paymentType == C.EvoPay && !isInvoicedBuyer)
        return {
          _payment: () ->
            if (payment.get('_amount') > evoPayBalance)
              return 'EvoPay account has insufficient funds.'
        }
      # Verify that the user accepted the buy side service charge
      if(@action == C.BuySellActions.BuySell)
        buyOrder = @get('_buyOrder').toJSON().orders[0]
        payments = buyOrder.payments
        if (payments.length && payments[0].type == C.CreditCard)
          agreement = $('#buyServiceFeeAgreement').is(':checked')
          if (!agreement)
            return {
              _payment: () ->
                return 'You must agree to the credit card terms and conditions and the service fee.'
            }
      if(@action == C.BuySellActions.Buy)
        payment = @get('_payment')
        if (payment.get('_type') == C.CreditCard)
          agreement = $('#buyServiceFeeAgreement').is(':checked')
          if (!agreement)
            return {
              _payment: () ->
                return 'You must agree to the credit card terms and conditions and the service fee.'
            }
      if(@action == C.BuySellActions.AffiliateSell)
        buyOrder = @get('_buyOrder')
        buySubtotal = buyOrder.get('_subtotal')
        saleSubtotal =  @get('_subtotal')
        buyTotal = buyOrder.get('_total')
        saleTotal =  @get('_total')
        return {
          _payment: () ->
            if (buySubtotal > saleSubtotal)
              return 'Sale Price cannot be lower than Purchase Price.'
            if (saleSubtotal > (buySubtotal * 1.25))
              return 'Sale Price cannot be greater than 25% of Purchase Price.'
            if (buyTotal > saleTotal)
              return 'Sale Totals cannot be lower than Purchase totals'
        }


    return {}

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.OrdersLightweight.Orders)
      attrs._balance = json.balance
      attrs._commission = json.commission
      attrs._buyer = new App.Models.V3.Patron(json.buyer, {
        mapping: C.Mappings.Direct.OrdersLightweight.Patron
      })
      attrs._buyerState = json.buyer_state
      attrs._buyerType = json.buyer_type
      attrs._createdAt = Date.parse(json.created_at)
      attrs._fee = json.fee
      attrs._id = json.id
      attrs._isConsignment = json.consignment
      attrs._isSpec = json.spec
      attrs._notes = json.notes
      attrs._orderItems = new App.Collections.V3.OrderItems(json.items, {
        mapping: C.Mappings.Direct.OrdersLightweight.OrderItems
      })
      attrs._orderIdWithGroup = json.oid
      attrs._reference = json.reference
      attrs._seller = new App.Models.V3.Patron(json.seller, {
        mapping: C.Mappings.Direct.OrdersLightweight.Patron
      })
      attrs._sellerState = json.seller_state
      attrs._sellerType = json.seller_type
      attrs._state = json.state
      attrs._total = json.total
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._wasAutoAccepted = !!json.was_auto_accepted
      attrs._wasAutoCanceled = !!json.was_auto_canceled
      attrs._wasAutoPended = !!json.was_auto_pended
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Order.Order)
      attrs._additionalExpense = json.additional_expense
      attrs._balance = json.balance
      attrs._billingAddress = new App.Models.V3.Address(json.billing_address, {
        mapping: C.Mappings.Direct.Order.Address
      })
      attrs._buyer = new App.Models.V3.Patron(json.buyer, {
        mapping: C.Mappings.Direct.Order.Patron
      })
      attrs._buyerCancellationNotes = json.buyer_cancellation_notes
      attrs._buyerCancellationReason = json.buyer_cancellation_reason
      attrs._buyerState = json.buyer_state
      attrs._childOrderIds = json.child_orders
      # Can't create an infinite loop of order -> order -> order...
#      attrs._childOrders = new App.Collections.V3.Orders(json.child_orders, {
#        mapping: C.Mappings.Direct.Order.ChildOrder
#      })
      attrs._client = new App.Models.V3.Client(json.client, {
        mapping: C.Mappings.Direct.Order.Patron
      })
      attrs._completedNonRefundPaymentsTotal = json.completed_non_refund_payments_total
      attrs._createdAt = Date.parse(json.created_at)
      attrs._createdBy = json.created_by
      attrs._createdByIpAddress = json.created_by_ip_address
      attrs._discount = json.discount
      attrs._fee = json.fee
      attrs._id = json.id
      attrs._instructions = json.instructions
      attrs._isConsignment = json.consignment
      attrs._isPartner = json.partner
      attrs._isPO = json.isPO
      attrs._isSellerPOS = json.seller_is_pos
      attrs._isSpec = json.spec
      attrs._isSpecFulfilled = json.spec_fulfilled
      attrs._orderItems = new App.Collections.V3.OrderItems(json.items, {
        mapping: C.Mappings.Direct.Order.OrderItems
      })
      fraudAttrs = {
        notifications: json.kount_notifications
        score:         json.kount_score
        status:        json.kount_status
        transactions:  json.kount_transactions
        fraudCheckStatus: json.fraud_check_status
      }
      attrs._fraud = new App.Models.V3.Fraud(fraudAttrs, {
        mapping: C.Mappings.Direct.Order.Fraud
      })
      attrs._minfraudResponse = json.minfraud_response
      attrs._notes = json.notes
      attrs._orderIdWithGroup = json.oid
      attrs._orderType = json.order_type
      attrs._patronType = json.patron_type
      attrs._payments = new App.Collections.V3.Payments(json.payments, {
        mapping: C.Mappings.Direct.Order.Payments
      })
      attrs._penaltiesTotal = json.penalties_total
      attrs._pendingBalance = json.pending_balance
      attrs._pendingNonRefundPaymentsTotal = json.pending_non_refund_payments_total
      attrs._placer = new App.Models.V3.Patron(json.placer, {
        mapping: C.Mappings.Direct.Order.Patron
      })
      attrs._reference = json.reference
      attrs._refunded = json.refunded
      attrs._returnsTotal = json.returns_total
      attrs._seller = new App.Models.V3.Patron(json.seller, {
        mapping: C.Mappings.Direct.Order.Patron
      })
      attrs._sellerCancellationNotes = json.seller_cancellation_notes
      attrs._sellerCancellationReason = json.seller_cancellation_reason
      attrs._sellerRejectionNotes = json.seller_rejection_notes
      attrs._sellerRejectionReason = json.seller_rejection_reason
      attrs._sellerState = json.seller_state
      attrs._serviceFee = json.service_fee
      attrs._shipments = new App.Collections.V3.Shipments(json.shipments, {
        mapping: C.Mappings.Direct.Order.Shipments
      })
      attrs._shipping = json.shipping
      attrs._shippingAddress = new App.Models.V3.Address(json.shipping_address, {
        mapping: C.Mappings.Direct.Order.Address
      })
      attrs._state = json.state
      attrs._substituteOrderLinkId = json.substitute_order_link_id
      attrs._substitutions = new App.Collections.V3.Substitutions(json.substitutions, {
        mapping: C.Mappings.Direct.Order.Substitution
      })
      attrs._subtotal = json.subtotal
      attrs._tax = json.tax
      attrs._total = json.total
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      attrs._wasAutoAccepted = !!json.was_auto_accepted
      attrs._wasAutoCanceled = !!json.was_auto_canceled
      attrs._wasAutoPended = !!json.was_auto_pended
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Orders.Orders)
      attrs._additionalExpense = json.additional_expense
      attrs._balance = json.balance
      attrs._buyer = new App.Models.V3.Patron(json.buyer, {
        mapping: C.Mappings.Direct.OrderShowLite.Patron
      })
      attrs._buyerState = json.buyer_state
      attrs._buyerType = json.buyer_type
      attrs._createdAt = Date.parse(json.created_at)
      attrs._discount = json.discount
      attrs._fee = json.fee
      attrs._id = json.id
      attrs._isConsignment = json.consignment
      attrs._isSpec = json.spec
      attrs._isSpecFulfilled = json.spec_fulfilled
      attrs._orderItems = new App.Collections.V3.OrderItems(json.items, {
        mapping: C.Mappings.Direct.Orders.OrderItems
      })
      attrs._minfraudRiskScore = json.minfraud_risk_score
      attrs._orderIdWithGroup = json.oid
      attrs._payments = new App.Collections.V3.Payments(json.payments, {
        mapping: C.Mappings.Direct.Orders.Payments
      })
      attrs._placer = json.placer
      attrs._reference = json.reference
      attrs._seller = new App.Models.V3.Patron(json.seller, {
        mapping: C.Mappings.Direct.OrderShowLite.Patron
      })
      attrs._sellerState = json.seller_state
      attrs._sellerType = json.seller_type
      attrs._serviceFee = json.service_fee
      # should be a v3 collection
      attrs._shipmentSnapshot = json.shipment_snapshot
      # should be a v3 collection
      attrs._shipments = json.shipments
      attrs._shipping = json.shipping
      attrs._state = json.state
      attrs._subtotal = json.subtotal
      attrs._tax = json.tax
      attrs._total = json.total
      attrs._updatedAt = Date.parse(json.updated_at)
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.OrderShowLite.Order)
      attrs._additionalExpense = json.additional_expense
      attrs._balance = json.balance
      attrs._billingAddress = new App.Models.V3.Address(json.billing_address, {
        mapping: C.Mappings.Direct.OrderShowLite.BillingAddress
      })
      attrs._buyer = new App.Models.V3.Patron(json.buyer, {
        mapping: C.Mappings.Direct.OrderShowLite.Patron
      })
      attrs._buyerCancellationNotes = json.buyer_cancellation_notes
      attrs._buyerCancellationReason = json.buyer_cancellation_reason
      attrs._buyerState = json.buyer_state
      attrs._childOrderIds = json.child_orders
      # Can't create an infinite loop of order -> order -> order...
#      attrs._childOrders = new App.Collections.V3.Orders(json.child_orders, {
#        mapping: C.Mappings.Direct.OrderShowLite.ChildOrder
#      })
      attrs._client = new App.Models.V3.Client(json.client, {
        mapping: C.Mappings.Direct.OrderShowLite.Patron
      })
      attrs._createdAt = Date.parse(json.created_at)
      attrs._createdByIpAddress = json.created_by_ip_address
      attrs._discount = json.discount
      attrs._fee = json.fee
      attrs._id = json.id
      attrs._instructions = json.instructions
      attrs._isConsignment = json.consignment
      attrs._isPO = json.isPO
      attrs._isSpec = json.spec
      attrs._isSpecFulfilled = json.spec_fulfilled
      attrs._orderItems = new App.Collections.V3.OrderItems(json.items, {
        mapping: C.Mappings.Direct.OrderShowLite.OrderItems
      })
      attrs._minfraudResponse = json.minfraud_response
      attrs._notes = json.notes
      attrs._orderIdWithGroup = json.oid
      attrs._partner = json.partner
      attrs._patronType = json.patron_type
      attrs._payments = json.payments
      attrs._penaltiesTotal = json.penalties_total
      attrs._placer = new App.Models.V3.Patron(json.placer, {
        mapping: C.Mappings.Direct.OrderShowLite.Placer
      })
      attrs._reference = json.reference
      attrs._refunded = json.refunded
      attrs._returnsTotal = json.returns_total
      attrs._seller = new App.Models.V3.Patron(json.seller, {
        mapping: C.Mappings.Direct.OrderShowLite.Patron
      })
      attrs._sellerCancellationNotes = json.seller_cancellation_notes
      attrs._sellerCancellationReason = json.seller_cancellation_reason
      attrs._sellerIsPOS = json.seller_is_pos
      attrs._sellerState = json.seller_state
      attrs._serviceFee = json.service_fee
      attrs._shipmentSnapshot = json.shipment_snapshot
      attrs._shipping = json.shipping
      attrs._shippingAddress = new App.Models.V3.Address(json.shipping_address, {
        mapping: C.Mappings.Direct.OrderShowLite.ShippingAddress
      })
      attrs._state = json.state
      attrs._substituteOrderLinkId = json.substitute_order_link_id
      attrs._substitutions = json.substitutions
      attrs._subtotal = json.subtotal
      attrs._tax = json.tax
      attrs._total = json.total
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    # Multiple
    if (@getMapping() == C.Mappings.OrderEndpoint.Order)
      attrs._balance = json.balance
      attrs._buyer = new App.Models.V3.Patron(json.buyer, {
        mapping: C.Mappings.OrderEndpoint.Patron
      })
      attrs._buyerState = json.buyer_state
      attrs._buyerType = json.buyer_type
      attrs._createdAt = json.created_at
      attrs._fee = json.fee
      attrs._id = json.id  # link id
      attrs._isConsignment = json.consignment
      attrs._isSpec = json.spec
      if json.items
        attrs._items = new App.Collections.V3.OrderItems(json.items, {
          mapping: C.Mappings.OrderEndpoint.OrderItem
        })
      attrs._orderIdWithGroup = json.oid
      attrs._reference = json.reference
      attrs._seller = new App.Models.V3.Patron(json.seller, {
        mapping: C.Mappings.OrderEndpoint.Patron
      })
      attrs._sellerState = json.seller_state
      attrs._sellerType = json.seller_type
      attrs._state = json.state
      attrs._total = json.total
      attrs._updatedAt = Date.parse(json.updated_at)
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      additionalExpense: @get('_additionalExpense')
      balance: @get('_balance')
      commission: @get('_commission')
      buyer: @get('_buyer')?.toPresenterJSON()
      buyerState: C.Options.Order.States[@get('_buyerState')]
      completedNonRefundPaymentsTotal: @get('_completedNonRefundPaymentsTotal')
      createdAt: @get('_createdAt')
      createdBy: @createdBy()
      createdByIpAddress: @get('_createdByIpAddress')
      customerEmailAddresses: @customerEmailAddresses()
      displayCustomerEmail: @displayCustomerEmail()
      customerName: @customer().toPresenterJSON().prettyName
      discount: @get('_discount')
      fee: @get('_fee')
      firstEventDateRaw: @firstEventDateRaw()
      flags: @flags()
      hasMissingEticketsDisplay: @hasMissingEticketsDisplay()
      id: @get('_id')
      isConsignment: @get('_isConsignment')
      isPO: @isPO()
      isSubstitutionAllowed: @isSubstitutionAllowed()
      isTevoOrder: @isTevoOrder()
      netTotal: @netTotal()
      notes: @get('_notes')
      orderIdWithGroup: @get('_orderIdWithGroup')
      orderItems: @get('_orderItems')?.toPresenterJSON()
      orderType: @get('_orderType')
      payments: @get('_payments')?.toPresenterJSON?()
      paymentsTotal: @paymentsTotal()
      penaltiesTotal: @get('_penaltiesTotal')
      pendingBalance: @get('_pendingBalance')
      pendingNonRefundPaymentsTotal: @get('_pendingNonRefundPaymentsTotal')
      placer: @get('_placer')
      reference: @get('_reference') || ''
      refundsTotal: @refundsTotal()
      rejectionReason: @get('_sellerCancellationReason') || @get('_sellerRejectionReason')
      rejectionNotes: @get('_sellerCancellationNotes') || @get('_sellerRejectionNotes')
      returnsTotal: @get('_returnsTotal')
      seller: @get('_seller')?.toPresenterJSON()
      sellerState: C.Options.Order.States[@get('_sellerState')]
      serviceFee: @get('_serviceFee')
      shipments: @get('_shipments')?.toPresenterJSON?()
      shipping: @get('_shipping')
      state: C.Options.Order.States[@get('_state')]
      subtotal: @get('_subtotal')
      tax: @get('_tax')
      total: @get('_total')
      wasAutoAccepted: @get('_wasAutoAccepted')

    })
    return presented

  createdBy: () ->
    createdBy = @get('_createdBy')
    placer = @get('_placer')
    if (createdBy)
      if (createdBy.brokerage_id == SESSION.brokerage_id)
        if (@attributes.placer && placer instanceof App.Models.V3.Patron)
          return placer.toPresenterJSON().prettyName
        else
          return createdBy.label
    if (_.isString(placer))
      return placer
    return 'Ticket Evolution'

  customer: () ->
    counterParty = '_buyer'
    if (@isPO())
      counterParty = '_seller'
    @get(counterParty)

  customerEmailAddresses: () ->
    customer = @customer()
    emailAddressCollection = customer.emailAddresses()
    list = emailAddressCollection.toList()
    return list

  displayCustomerEmail: () ->
    customer = @customer()
    emailAddressCollection = customer.emailAddresses()
    primaryAddress = _.first(emailAddressCollection.where({_isPrimary: true})) || emailAddressCollection.first()
    displayAddress = if primaryAddress then primaryAddress.get('_address') else C.SupportEmail
    return displayAddress

  customerOrderState: () ->
    counterParty = '_buyer'
    if (@isPO())
      counterParty = '_seller'
    @get("#{ counterParty }State")

  you: () ->
    you = '_seller'
    if (@isPO())
      you = '_buyer'
    @get(you)

  firstEventDateRaw: () ->
    dates = []
    # Two iterations through the items isn't optimal but keeps code simple.
    @get('_orderItems').each((orderItemModel) ->
      # Why aren't the model attributes standardized?!
      occursAt = orderItemModel.eventOccursAt()
      dates.push(occursAt)
    )
    if (dates.length)
      minDate = new Date(Math.min.apply(null, dates))
    if (minDate)
      firstOrderItem = null
      @get('_orderItems').each((orderItemModel) ->
        occursAt = orderItemModel.eventOccursAt()
        if (occursAt == +minDate)
          firstOrderItem = orderItemModel
      )
      if (firstOrderItem)
        occursAt = firstOrderItem.eventOccursAt({ raw: true })
        return occursAt
    return ''

  flags: () ->
    flags = ''
    if (@get('_isSpec'))
      flags += C.FlagBadges.Spec
    if (@get('_isConsignment'))
      flags += C.FlagBadges.Consignment
    if (@get('_notes') && @get('_notes').length)
      flags += C.FlagBadges.Notes
    if (@get('_wasAutoAccepted'))
      flags += C.FlagBadges.AutoAccepted
    # We have no Eticket or Notes fields as of now.
    #if (@get('_isEticket'))
    #  flags += C.FlagBadges.Eticket()
    #if (@get('_notesBroker') || @get('_notesPrivate') || @get('_notesPublic'))
    #  flags += C.FlagBadges.Notes
    return flags

  hasMissingEticketsDisplay: () ->
    if (@hasMissingEtickets())
      return '<span class="label label-important">Missing</span>'
    return ''

  hasMissingEtickets: () ->
    items = @get('_items')
    if (items?.models)
      for item in items.models
        if (item.get('_needsEticket'))
          return true
    return false

  isPO: () ->
    buyer = @get('_buyer').toPresenterJSON()
    buyerId = buyer?.id
    officeId = SESSION.office_id
    if (buyer && buyerId && officeId && buyerId == officeId)
      return true
    return false

  isSubstitutionAllowed: () ->
    if (@get('_substituteOrderLinkId'))
      return false
    if (!_.contains(
      [C.Rejected, C.Canceled, C.CanceledOpen, C.CanceledPostAcceptance],
      @get('_sellerState')
    ))
      return false
    return true

  netTotal: () ->
    return @get('_total') - @get('_fee')

  paymentsTotal: () ->
    total = 0
    @get('_payments')?.forEach((paymentModel) ->
      # Isn't _type usually 'cash' or 'credit_card' ?
      if (
        !paymentModel.get('_isRefund') &&
        !(paymentModel.get('_type') == 'penalty') &&
        !(paymentModel.get('_type') == 'return') &&
        !(paymentModel.get('_state') == 'canceled') &&
        !(paymentModel.get('_state') == 'pending')
      )
        total += parseFloat(paymentModel.get?('_amount'))
    )
    return total

  refundsTotal: () ->
    total = 0
    @get('_payments')?.forEach((paymentModel) ->
      if (paymentModel.get('_isRefund'))
        total += parseFloat(paymentModel.get?('_amount'))
    )
    return total

  toDataTableRowArray: () ->

    # Orders ListView
    if (C.Mappings.Direct.OrderShowLite.Order)
      item = @toPresenterJSON()

      if (SESSION.role.can(App.Access.Resources.Orders.OrdersListView.BuyerState))
        row = [
          "<a data-order-id='#{ item.id }' href='/order/#{ item.id }'>#{ item.orderIdWithGroup }</a>"
          item.customerName
          App.Utils.makeTimestampHuman(item.createdAt, C.DateFormats.TableDateWithTime)
          item.buyerState
          item.sellerState
          App.Utils.valueToCurrency(item.total)
          App.Utils.valueToCurrency(item.balance)
          App.Utils.makeTimestampHumanForEvent(item.firstEventDateRaw, C.DateFormats.TableDateWithTime)
          item.displayCustomerEmail
          item.reference
          item.hasMissingEticketsDisplay
          item.flags
        ]
      else
        row = [
          "<a data-order-id='#{ item.id }' href='/order/#{ item.id }'>#{ item.orderIdWithGroup }</a>"
          item.customerName
          App.Utils.makeTimestampHuman(item.createdAt, C.DateFormats.TableDateWithTime)
          item.sellerState
          App.Utils.valueToCurrency(item.total)
          App.Utils.valueToCurrency(item.balance)
          App.Utils.makeTimestampHumanForEvent(item.firstEventDateRaw, C.DateFormats.TableDateWithTime)
          item.displayCustomerEmail
          item.reference
          item.hasMissingEticketsDisplay
          item.flags
        ]
      return row

    else
      # Client POs ?
      # We cheat and return multiple rows (a 2d array) - an array of rows for each orderItem.
      rows = []
      orderItems = @get('_orderItems')
      for orderItem in orderItems
        p = orderItem.toPresenterJSON()
        rows.push([
          "<a data-order-id='#{ p.id }' href='/order/#{ p.id }'#{ p.id }</a>"
          p.ticketGroup.event.name
          p.ticketGroup.event.venue.name
          App.Utils.makeTimestampHumanForEvent(p.ticketGroup.event.occursAtRaw, C.DateFormats.TableDateWithTime)
          p.ticketGroup.quantity
          p.ticketGroup.section
          p.ticketGroup.row
          p.ticketGroup.seats
          App.Utils.valueToCurrency(p.price)
          App.Utils.makeTimestampHuman(@get('_createdAt'), C.DateFormats.TableDateWithTime)
          @get('_placer')
          C.Options.Order.States[@get('_state')]
        ])
      return rows

  isTevoOrder: () ->
    buyerId = @get('_buyer')?.id
    if (buyerId)
      return buyerId == C.TicketEvolutionOfficeId
    return false
  #///////////////////////////////////////////////////////////////////////////

  total: () ->
    total = 0.0
    total += App.Utils.twoDecimalPlaces(@get('_subtotal') || '0')
    total += App.Utils.twoDecimalPlaces(@get('_tax') || '0')
    total += App.Utils.twoDecimalPlaces(@get('_fee') || '0')
    total += App.Utils.twoDecimalPlaces(@get('_serviceFee') || '0')
    total += App.Utils.twoDecimalPlaces(@get('_shipping') || '0')
    total -= App.Utils.twoDecimalPlaces(@get('_discount') || '0')
    # These all round DOWN by dropping all decimal points after 2 deep, but sometimes the addition of
    # rounded float values still messes up here. So we round to 2 decimals mathematically,
    # just in case float arithmetic gives us something like 15.529999999999 when it should be 15.53
    total = Math.round(total * 100) / 100
    @set('_total', total)
    return total

  toJSON: (options = {}) ->
    if (@purpose == C.Purposes.SubOrder)
      @purpose = null
      attrs = @attributes
      json = {
        orders: [{
          additional_expense: App.Utils.roundCurrency(attrs._additionalExpense)
          buyer_id: attrs._buyerId
          seller_id: attrs._sellerId
          service_fee: App.Utils.roundCurrency(attrs._serviceFee)
          shipped_items: attrs._shippedItems
          shipping: App.Utils.roundCurrency(attrs._shipping)
          substitute_order_link_id: attrs._substituteOrderLinkId
          tax: App.Utils.roundCurrency(attrs._tax)
        }]
      }
      return json

    if (@purpose == C.Purposes.OrderSendPO)
      @purpose = null
      json = {
        id: @id
        order_item_links: @get('_orderItemLinks')
        note: @get('_additionalNote')
        pos: true
        seller_type: 'Office'
      }
      return json

    if (@purpose == C.Purposes.OrderCancel)
      @purpose = null
      json = {
        id: @id
        notes: @get('_cancellationNotes')
        reason: @get('_cancellationReason')
        reviewer_id: SESSION.user_id
      }
      return json

    if (@purpose == C.Purposes.OrderSaveNote)
      @purpose = null
      newNote = @get('_newNote')
      note = {
        category: newNote.category
        content: newNote.content
        office_id: SESSION.office_id
      }
      json = {
        order: {
          id: @id
          notes: [ note ]
        }
      }
      return json

    if (@purpose == C.Purposes.OrderAccept || @purpose == C.Purposes.OrderAcceptConsignmentMoveUnsold)
      json = {
        id: @id
        reviewer_id: @get('_reviewerId')
      }

      if (@purpose == C.Purposes.OrderAcceptConsignmentMoveUnsold)
        json.move_consigned_tickets = true

      @purpose = null

      # If the first orderItem was missing seat numbers...
      seats = null
      firstTicketGroup = @get('_orderItems')?.first().get('_ticketGroup')
      if (firstTicketGroup.hasSeatNumbers())
        seats = firstTicketGroup.get('_seats')
      if (seats && seats.length)
        # Don't POST seats if they're all null.
        seats = seats.filter((seat) =>
          !_.isNull(seat) && !_.isNaN(seat) && !_.isUndefined(seat)
        )

        if (seats.length)
          json.seats = seats

      return json

    else if (@purpose == C.Purposes.OrderReject)
      @purpose = null
      json = {
        reason: @get('_reason')
        rejection_notes: @get('_rejectionNotes')
        reviewer_id: @get('_reviewerId')
      }
      return json

    else if (@purpose == C.Purposes.SubmitOrder || @purpose == C.Purposes.SubmitPO)
      attrs = @attributes
      payment = attrs._payment
      seller = attrs._seller
      sellerType = 'Office'
      if (seller.type == C.Client)
        sellerType = 'Client'
      shipment = attrs._shipment
      items = attrs._items

      shippedItems = null
      shipping = null
      if (shipment)
        shippedItems = shipment.toJSON()
        shippedItems.items = items
        shippedItems = [ shippedItems ] # Wrap in array.
        shipping = shipment.get('_cost')

      # Only Sell and sell-side of BuySell have payments.
      payments = null
      if (@action == C.BuySellActions.Buy) # Buy.
        payments = []

        if (payment.get('_type') == C.CreditCard)
          buy_attributes = { payments: [ payment.toJSON() ] }
          attrs._buyAttributes = buy_attributes

      else
        if (payment)
          if (payment.get('_type') == C.TBDPayment)
            payments = []
          else
            payments = [ payment.toJSON() ]

      order = {
        additional_expense:       App.Utils.roundCurrency(attrs._fee)
        discount:                 App.Utils.roundCurrency(attrs._discount)
        payments:                 payments
        seller_id:                seller.id
        seller_reference_number:  attrs._sellerReference
        reference:                attrs._buyerReference
        seller_type:              sellerType
        service_fee:              App.Utils.roundCurrency(attrs._serviceFee)
        shipped_items:            shippedItems
        shipping:                 App.Utils.roundCurrency(shipping)
        tax:                      App.Utils.roundCurrency(attrs._tax)
        source:                   "POS"
      }

      if(attrs._buyAttributes)
        order.buy_attributes = attrs._buyAttributes

      if (attrs._notesPublic)
        order.external_notes = attrs._notesPublic

      if (attrs._instructions)
        order.instructions = attrs._instructions

      if (attrs._notesPrivate)
        order.internal_notes = attrs._notesPrivate

      buyer = attrs._buyer
      if (buyer.type == C.Office)
        order.buyer_id = buyer.id
      else
        order.client_id = buyer.id

      if (attrs._isCart && SESSION.carts.length)
        order.cart_id = SESSION.carts.first().id

      if (@action == C.BuySellActions.BuySell)
        buyOrder = @get('_buyOrder').toJSON().orders[0]
        payments = buyOrder.payments
        if (payments.length && payments[0].type == C.CreditCard)
          service_fee = buyOrder.service_fee
          order.buy_attributes = { payments, service_fee}

      if (@action == C.BuySellActions.PO || @action == C.BuySellActions.Consignment)
        order.is_purchase = true

        if (attrs._isConsignment)
          order.payments = []
          order.consignment = true

      if (attrs._taxSignature)
        order.tax_signature = attrs._taxSignature

      json = {
        orders: [ order ]
      }
      return json
    else
      return parent.toJSON.call(@, options)

    ###
    # Consider ommitting some keys from json as model the POST/PUT is getting huge.
    json = _.omit(json,
    '_additionalExpense',
    '_balance',
    '_billingAddress',
    '_buyer',
    '_buyerCancellationNotes',
    '_buyerCancellationReason',
    '_buyerState',
    '_buyerType',
    '_childOrderIds',
    '_client',
    '_completedNonRefundPaymentsTotal',
    '_createdAt',
    '_createdBy',
    '_createdByIpAddress',
    '_discount',
    '_fee',
    '_fraud',
    '_id',
    '_instructions',
    '_isConsignment',
    '_isPartner',
    '_isPO',
    '_isSellerPOS',
    '_isSpec',
    '_isSpecFulfilled',
    '_items',
    '_minfraudResponse',
    '_minfraudRiskScore',
    '_notes',
    '_orderId',
    '_orderItems',
    '_orderIdWithGroup',
    '_partner',
    '_patronType',
    '_payments',
    '_penaltiesTotal',
    '_pendingBalance',
    '_pendingNonRefundPaymentsTotal',
    '_placer',
    '_reference',
    '_refunded',
    '_returnsTotal',
    '_seller',
    '_sellerCancellationNotes',
    '_sellerCancellationReason',
    '_sellerIsPOS',
    '_sellerState',
    '_sellerType',
    '_serviceFee',
    '_shipmentSnapshot',
    '_shipments',
    '_shipping',
    '_shippingAddress',
    '_state',
    '_substituteOrderLinkId',
    '_substitutions',
    '_subtotal',
    '_tax',
    '_total',
    '_updatedAt',
    '_url',
    '_wasAutoAccepted',
    '_wasAutoCanceled',
    '_wasAutoPended'
    )
    ###

  parse: (response, options) ->
    if (response.orders && response.orders.length)
      return response.orders[0]
    else
      return response

  acceptPromise: (options = {}) ->
    @purpose = C.Purposes.OrderAccept
    if (options.moveUnsoldTickets)
      @purpose = C.Purposes.OrderAcceptConsignmentMoveUnsold
    attrs = {
      _reviewerId: SESSION.user_id
    }
    @set(attrs)
    return @savePromise()

  cancelPromise: (options = {}) ->
    @purpose = C.Purposes.OrderCancel
    return @savePromise()

  sendPOPromise: (options = {}) ->
    @purpose = C.Purposes.OrderSendPO
    return @savePromise()

  rejectPromise: (options = {}) ->
    {
      reason
      rejectionNotes
    } = options
    attrs = {
      _reason: reason
      _rejectionNotes: rejectionNotes
      _reviewerId: SESSION.user_id
    }
    @set(attrs)
    @purpose = C.Purposes.OrderReject
    return @savePromise()

  saveNotePromise: (options = {}) ->
    {
      category
      content
    } = options
    attrs = {
      _newNote: {
        category
        content
      }
    }
    @set(attrs)
    @purpose = C.Purposes.OrderSaveNote
    return @savePromise()

  fetchOrderItemsPromise: () ->
    orderItemsCollection = new App.Collections.V3.OrderItems(null, {
      mapping: C.Mappings.Direct.OrderItems.OrderItem
    })
    orderItemsCollection.orderId = @id
    orderItemsCollection.purpose = C.Purposes.OrderItems
    return orderItemsCollection.fetchPromise()

  fetchWithOrderItemsPromise: () ->
    deferred = Q.defer()
    orderPromise = @fetchPromise()
    orderPromise
    .then (v3OrrModel) =>
      deferred.resolve(@)
    .fail (error) ->
      deferred.reject(error)
    .done()
    return deferred.promise

  fetchSoldItemsPromise: () ->

    orderItemsById = {}
    @get('_orderItems').forEach((orderItem) ->
      orderItemsById[orderItem.get('_orderItemId')] = orderItem
    )

    soldOrderItemsCollection = new App.Collections.V3.OrderItems(null, {
      mapping: C.Mappings.Direct.TicketCosts.SoldOrderItem
      orderId: @id
    })

    orderItemsCollection = new App.Collections.V3.OrderItems(null, {
      mapping: C.Mappings.Direct.TicketCosts.OrderItem
      orderId: @id
      purpose: C.Purposes.TicketCosts
    })

    return orderItemsCollection.fetchPromise()
    .then (collection) =>
      orderItemsCollection.forEach((orderItemModel) =>
        soldInOrderItems = orderItemModel.get('_soldInOrderItems')
        if (soldInOrderItems)
          orderItemsById[orderItemModel.id].set('_soldInOrderItems', soldInOrderItems)
          soldOrderItemsCollection.add(soldInOrderItems.models)
      )
      return soldOrderItemsCollection
