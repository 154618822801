App.Controllers.Base.BaseController = require('./base/base_controller.coffee')

module.exports = class App.Controllers.EvoPayController extends App.Controllers.Base.BaseController

  _evoPayTransactions = null
  _evoPayTransactionsView = null
  _$container = null

  routeIndex: ($container, encodedFilterParams) ->
    @displayTransactionIndex({
      $container
      shouldReset: true
      encodedFilterParams
    })

  resetTransactionIndex: () ->
    App.router.navigate('/evopay') # Don't trigger.
    @displayTransactionIndex({
      $container: _$container
      shouldReset: true
    })

  filterTransactionIndex: () ->
    encodedFilterParams = _evoPayTransactionsView.getEncodedFilterParams()
    App.router.navigate('/evopay/filter/' + encodedFilterParams) # Don't trigger.
    @displayTransactionIndex({
      $container: _$container
      shouldReset: false
      encodedFilterParams
    })

  displayTransactionIndex: (options) ->
    {
      $container
      shouldReset
      encodedFilterParams
    } = options

    isGoingToFetch = !!encodedFilterParams
    _$container = $container

    if (!_evoPayTransactions || shouldReset)
      _evoPayTransactions = new App.Models.EvoPayTransactionsAndBalances()

    if (!_evoPayTransactionsView || shouldReset)
      _evoPayTransactionsView = new App.Views.EvoPay.List({
        $container: _$container
        model: _evoPayTransactions
      })

    if (isGoingToFetch)
      params = App.Utils.decodeFilterParams(encodedFilterParams)
      _evoPayTransactionsView.reflectFilterParams(params)
      params = _evoPayTransactionsView.getFilterParams()
      # View is bound to collection reset event.
      _evoPayTransactions.fetch({
        data: params
        reset: true
      })
    else
      coll = _evoPayTransactions.get('transactions');
      coll.reset();
      _evoPayTransactions.fetchOfficeTotals()
