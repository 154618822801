App.Views.Events.Filter = require('../views/events/filter_view.coffee')

# SINGLETON
module.exports = eventsController = {}

_currentUrl = null
_eventsListView = null
_eventsFilterView = null
_eventsCollection = null

eventsController.index = (options) ->
  {
    deferred
  } = options
  _eventsCollection = new App.Collections.V3.Events(null, {
    mapping: C.Mappings.Direct.Events.Event
    myOrAllEvents: App.router.getMyOrAllEvents()
  })
  _eventsListView = new App.Views.Events.List({
    $container: options.$container
    eventsCollection: _eventsCollection
    deferred
  })
  _eventsFilterView = new App.Views.Events.Filter({
    $container: _eventsListView.$('#filter_container')
  })
  filters = {}
  _eventsListView.applyFilters(filters)

eventsController.search = (options = {}) ->
  {
    $container
    deferred
    filters
  } = options
  _eventsCollection = new App.Collections.V3.Events(null, {
    mapping: C.Mappings.Direct.Events.Event
    myOrAllEvents: App.router.getMyOrAllEvents()
  })
  _eventsListView = new App.Views.Events.List({
    $container
    eventsCollection: _eventsCollection
    filters
    deferred
  })
  _eventsFilterView = new App.Views.Events.Filter({
    $container: _eventsListView.$('#filter_container')
    filters
  })
  _eventsFilterView.reflectFilters(filters)
  _eventsListView.applyFilters(filters)

eventsController.highlightEvent = (eventId) ->
  return _eventsListView.highlightEvent(eventId)

eventsController.setCurrentUrl = (filterCode) ->
  _currentUrl = "/tickets/events/#{ App.router.getMyOrAllEvents() }/filter/#{ filterCode }"

eventsController.filterEvents = (filters) ->
  filterCode = App.Utils.encodeFilterParams(filters)
  newUrl = "/tickets/events/#{ App.router.getMyOrAllEvents() }/filter/#{ filterCode }"
  if (_currentUrl == newUrl)
    eventsController.doneLoading()
  App.router.navigate(newUrl, { trigger: true })

eventsController.doneLoading = () ->
  _eventsFilterView.doneLoading()

eventsController.getEventsFilterCode = () ->
  filters = _eventsFilterView.getFilters()
  filterCode = App.Utils.encodeFilterParams(filters)
  return filterCode

eventsController.collapseEventsView = () ->
  _eventsListView.collapse()
  App.Controllers.ticketGroupController.getTicketGroupsListView()?.logicMediator('FIT_CHART_TO_WINDOW')
  App.Controllers.ticketGroupController.getTicketGroupsListView()?.logicMediator('FIT_TABLE_TO_WINDOW')

eventsController.expandEventsView = () ->
  _eventsListView.expand()
  App.Controllers.ticketGroupController.getTicketGroupsListView()?.logicMediator('FIT_CHART_TO_WINDOW')
  App.Controllers.ticketGroupController.getTicketGroupsListView()?.logicMediator('FIT_TABLE_TO_WINDOW')