template = require('./eticket_select_pages.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

module.exports = App.Views.Order.EticketSelectPages = Backbone.View.extend

  template: template

  initialize: (options = {}) ->
    {
      @$container
      @orderModel
      @orderItemModel
    } = options
    @loadingBox = new App.Utils.LoadingBox(@$container)
    @render()

  render: () ->
    @loadingBox.startLoad()
    thumbnails = @orderItemModel.get('_eTicketThumbnails').models

    # Find all aspect ratios & dimensions of images.
    # This also fetches all images from server.
    arrayOfPromises = []
    for thumbnailModel in thumbnails
      arrayOfPromises.push(thumbnailModel.prefetchAndCalculateDimensions())
    # https://github.com/kriskowal/q/wiki/API-Reference
    allDone = Q.all(arrayOfPromises)
    allDone.then () =>

      # Then display them.
      data = {
        orderItem: @orderItemModel.toPresenterJSON()
        viewCase: App.Controllers.uploadsController.eTicketActionCase(@orderItemModel)
        orderModel: @orderModel.toPresenterJSON()
      }
      @$container.html(
        @$el.html(
          @template(data)
        )
      )

      @$summaryCountText = @$('#count_summary_text')
      @$ignoreCountCheckbox = @$('#ignore_page_count_checkbox')
      @$confirmButton = @$('#commitEticketsButton')
      @formErrors = new App.Utils.FormErrorManager(@$('.formErrorContainer'))

      @isSubmittableCheck()
      @loadingBox.stopLoad()
      @delegateEvents()
      return

    .fail (error) =>
      console.error(error)
      @$container.html(
        @$el.html(
          "<h5>Error Loading Etickets</h5>
           <div>
            #{ error }
           </div>"
        )
      )
      throw error

    .done()
    return

  #// VIEW EVENTS //////////////////////////////////////////////////////////////
  events:
    'click .eticketThumbnail':            'onEticketThumbnailClick'
    'click .previewButton':               'onPreviewButtonClick'
    'click #abort_button':                'onAbortButtonClick'
    'click #commitEticketsButton':        'onCommitEticketsButtonClick'
    'click #emailEticketsButton':         'onEmailEticketsButtonClick'
    'change #ignore_page_count_checkbox': 'isSubmittableCheck'

  getModelAndAttrsFromTarget: (e) ->
    data = $(e.currentTarget).data()
    cid = data.thumbnailCid
    eTicketThumbnailModel = @orderItemModel.get('_eTicketThumbnails').get(cid)
    attrs = eTicketThumbnailModel.toPresenterJSON()
    return {
      eTicketThumbnailModel
      attrs
    }

  onPreviewButtonClick: (e) ->
    {
      eTicketThumbnailModel
      attrs
    } = @getModelAndAttrsFromTarget(e)
    previewModal = new App.Views.Shared.BasicModal({
      header: "Page #{attrs.page}"
      icon: "fa-magnifying-glass-plus"
      message: "<div class='zoomable' style='
        width: #{attrs.width}px;
        height: #{attrs.height}px;
        cursor: move;
        background: #fff url(#{attrs.url});
        background-size: 100%;
        border: 1px solid #aaa;
        -moz-box-shadow: 0 0 10px #888;
        -webkit-box-shadow: 0 0 10px #888;
        box-shadow: 0 0 10px #888;
      ' />"
      isFullScreen: true
      isZoomable: true
      #width: attrs.width
      #height: attrs.height
    })
    e.stopPropagation()
    e.preventDefault()
    return false

  onEticketThumbnailClick: (e) ->
    {
      eTicketThumbnailModel
      attrs
    } = @getModelAndAttrsFromTarget(e)

    e.stopPropagation()
    e.preventDefault()

    wasChecked = attrs._isChecked

    # Page count enforcement
    checkedCount = @getCheckedThumbnails().length
    quantity = @orderItemModel.get('quantity')
    if (!wasChecked && quantity == checkedCount && !@$ignoreCountCheckbox.is(':checked'))
      return

    eTicketThumbnailModel.set('_isChecked', !wasChecked)
    $div = @$('div.eticketThumbnail[data-thumbnail-cid=' + attrs.cid + ']')
    if (wasChecked)
      $div.removeClass('active')
      $div.find('.thumbnailCheckbox').prop('checked', false)
    else
      $div.addClass('active')
      $div.find('.thumbnailCheckbox').prop('checked', true)

    @isSubmittableCheck()
    return false

  onAbortButtonClick: () ->
    @loadingBox.startLoad()
    @orderItemModel.deleteAllEticketsPromise()
    .then (model) =>
      App.Controllers.uploadsController.refreshViews(@orderItemModel)
    .fail (errors) =>
      @formErrors.show({
        title: 'Unable to remove etickets.'
        errors
      })
      @loadingBox.stopLoad()
    .done()

  onCommitEticketsButtonClick: () ->
    if (@isSubmittableCheck())
      @loadingBox.startLoad()
      pagesToKeep = @getCheckedThumbnails()
      pagesToKeep = _.map(pagesToKeep, (thumbnail) ->
        return thumbnail.get('page')
      )
      isIgnorePageRulesChecked =  @$ignoreCountCheckbox.is(':checked')
      @orderItemModel.finalizeEticketsPromise({
        pagesToKeep
        isIgnorePageRulesChecked
      })
      .then (model) =>
        App.Controllers.orderShowController.renderDeliveries()
        App.Controllers.uploadsController.refreshViews(@orderItemModel)
      .fail (errors) =>
        @formErrors.show({
          title: 'Unable to finalize etickets.'
          errors
        })
        @loadingBox.stopLoad()
      .done()

  onEmailEticketsButtonClick: () ->
    recipients = @$('#email_etickets_text').val()
    if (recipients.length)
      recipients = recipients.split(',')
      @loadingBox.startLoad()
      @orderItemModel.manuallySendEticketEmailPromise({
        recipients
      })
      .then (model) =>
        @formErrors.show({
          type: 'success'
          title: 'Email sent successfully.'
          message: "The Etickets have been emailed to the buyer."
        })
        @loadingBox.stopLoad()
      .fail (errors) =>
        @formErrors.show({
          title: 'Unable to email etickets.'
          errors
        })
        @loadingBox.stopLoad()
      .done()
    else
      @formErrors.show({
        errors: "Please provide the buyer's email address."
      })

  #/////////////////////////////////////////////////////////////////////////////

  getCheckedThumbnails: () ->
    return _.filter(@orderItemModel.get('_eTicketThumbnails').models, (thumbnail) ->
      return thumbnail.get('_isChecked')
    )

  isSubmittableCheck: () ->
    checkedCount = @getCheckedThumbnails().length
    quantity = @orderItemModel.get('quantity')
    @$summaryCountText.text("Selected #{checkedCount} of #{quantity}")
    if (quantity == checkedCount || @$ignoreCountCheckbox.is(':checked'))
      @$confirmButton.removeAttr('disabled')
      return true
    else
      @$confirmButton.attr('disabled', 'disabled')
      return false
