App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V3.Event = require('../../models/v3/event_model.coffee')
partition = require('lodash/partition')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V3.Events = App.Collections.Base.BaseCollection.extend

  model: App.Models.V3.Event

  url: () ->
    if (@purpose == C.Purposes.NewPO)
      return '/api_direct/v9/events'
    else
      url = '/api_direct/v9/events'
      params = {
        office_id: SESSION.office_id,
        only_with_available_tickets: true
      }

      if (@myOrAllEvents == 'all')
        params =
          if SESSION.role.can(App.Access.Resources.NoBrokerJargon)
          then { 'occurs_at.gte': new moment().utc().toISOString() }
          else {};
      if (@filters)
        delete @filters.__city_display_name
        delete @filters.airport_code
        delete @filters.category_text
        params = Object.assign(params, @filters)
      return url + "?" + $.param(params)

  initialize: (models, options = {}) ->
    parent.initialize.call(@, models, options)
    {
      @purpose
      @myOrAllEvents
    } = options

  parse: (response, options) ->
    if (response.events)
      return response.events
    else
      return response

  setFilters: (filters) ->
    @filters = filters

  toOrderItems: (options = {}) ->
    {
      price
      isPO
      isSpec
    } = options
    items = []
    @each((eventModel) ->
      ticketGroupCollection = eventModel.get('_ticketGroups')
      ticketGroupCollection.forEach((ticketGroupModel) ->
        ticketGroupModel.purpose = C.Purposes.OrderItem
        tgJSON = ticketGroupModel.toJSON()
        if (price == 'wholesale')
          tgJSON.price = ticketGroupModel.get('_priceWholesale')
        else if (price == 'sale')
          # This is used during Sell.
          tgJSON.price = ticketGroupModel.get('_priceSale')
          # This is used during BuySell.
          tgJSON.retail_price = ticketGroupModel.get('_priceSale')
        else if (price == 'cost')
          # This is used during PO & Consignment.
          tgJSON.price = ticketGroupModel.get('_cost')
        else
          console.error('You must specify which price field to use in EventsCollection.toOrderItems()')

        if (isPO)
          ticketGroupModel.calculateSeatsArray()
          tgJSON.broadcast =      ticketGroupModel.get('_isBroadcast')
          tgJSON.eticket =        ticketGroupModel.get('_isEticket')
          tgJSON.event_id =       eventModel.id
          tgJSON.broker_notes =   ticketGroupModel.get('_notesBroker')
          tgJSON.excluded_exchanges = ticketGroupModel.get('_excludedExchanges')
          tgJSON.external_notes = ticketGroupModel.get('_notesPublic')
          tgJSON.format =         ticketGroupModel.get('_format')
          tgJSON.face_value =     ticketGroupModel.get('_priceFace')
          tgJSON.in_hand =        !!(ticketGroupModel.get('_isInHand'))
          tgJSON.in_hand_on =     ticketGroupModel.get('_inHandOn')
          tgJSON.intended_for_instant_delivery = ticketGroupModel.get('_isInstantDelivery')
          tgJSON.internal_notes = ticketGroupModel.get('_notesPrivate')
          tgJSON.price =          ticketGroupModel.get('_cost')
          tgJSON.retail_price_override = ticketGroupModel.get('_priceRetail')
          tgJSON.row =            ticketGroupModel.get('_row')
          tgJSON.section =        ticketGroupModel.get('_section')
          tgJSON.stubhub_traits = ticketGroupModel.get('_stubhubTraits')
          tgJSON.type =           ticketGroupModel.get('_type')
          tgJSON.view_type = ticketGroupModel.get('_viewType')

          delete tgJSON.ticket_group_id

          if (isSpec)
            delete tgJSON.face_value

          ticketAttrs = []
          ticketGroupModel.get('_seats').forEach((seatNumber, index) ->
            ticket = {
              seat:  seatNumber + ''
              index: index + 1 # No idea.  Just do it.
              cost:  ticketGroupModel.get('_cost')
            }
            ticketAttrs.push(ticket)
          )
          tgJSON.tickets_attributes = ticketAttrs

          poFormattedJSON = {
            price:        ticketGroupModel.get('_cost')
            quantity:     ticketGroupModel.get('_quantity')
            ticket_group: tgJSON
          }
          items.push(poFormattedJSON)
        else
          items.push(tgJSON)
      )
    )
    return items

