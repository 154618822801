template = require('./address.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.BuySell.Address = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    {
      @$container
      @isAdHocShipmentOrigin
      @existingShipment # If an existing shipment - OrderOverview shipment view in edit mode.
      @defaultToNewAddress
      @disableAddNew
      @disableExisting
      @disableSaving
      @isForFedEx
      @isForLocalPickup
      @patron
    } = options
    @render()

  # VIEW EVENTS ////////////////////////////////////////////////////////////////
  events:
    'change .selectedAddressId':      'onSelectedAddressIdChange'
    'click .addAddressButton':        'onAddAddressButtonClick'
    'click .addVenueAddressButton':   'onAddVenueAddressButtonClick'
    'click .cancelAddAddressButton':  'onCancelAddAddressButtonClick'
    'click .saveAddressButton':       'onSaveAddressButtonClick'

  onSelectedAddressIdChange: (e) ->
    @trigger(C.Events.AddressViewChange)

  onAddAddressButtonClick: (e) ->
    @useNewAddress = true
    @useVenueAddress = false
    @$('.existingAddressContainer').hide()
    @$('.venueAddressContainer').hide()
    @$('.newAddressContainer').show()
    $country = @$('.countrySelect')
    $state = @$('.regionSelect')
    defaultCountry = 'US'

    countryVal = null
    stateVal = null
    if (@existingShipment)
      countryVal = @existingShipment.get('_shipmentSnapshot').get('_address').get('_countryCode')
      stateVal = @existingShipment.get('_shipmentSnapshot').get('_address').get('_region')

    @localityAutocomplete = new App.Utils.LocalityAutocomplete({
      $country
      $state
      defaultCountry
      countryVal
      stateVal
    })

  onAddVenueAddressButtonClick: (e) ->
    @useNewAddress = false
    @useVenueAddress = true
    @$('.existingAddressContainer').hide()
    @$('.newAddressContainer').hide()
    @$('.venueAddressContainer').show()
    @trigger(C.Events.AddressViewChange)


  onCancelAddAddressButtonClick: (e) ->
    @useNewAddress = false
    @useVenueAddress = false
    @$('.existingAddressContainer').show()
    @$('.venueAddressContainer').hide()
    @$('.newAddressContainer').hide()
    @trigger(C.Events.AddressViewChange)

  onSaveAddressButtonClick: (e) ->
    @trigger(C.Events.AddressViewChange)
  #/////////////////////////////////////////////////////////////////////////////

  render: () ->
    data = {
      @isAdHocShipmentOrigin
      @disableAddNew
      @disableExisting
      @disableSaving
      existingShipment: @existingShipment?.toPresenterJSON()
      patron: @patron?.toPresenterJSON()
      @isForFedEx
      @isForLocalPickup
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )
    @delegateEvents()
    @loadingBox = new App.Utils.LoadingBox(@$container)
    @formErrors = new App.Utils.FormErrorManager(@$('.formErrors'))

    if (!@patron || !_.size(@patron.addressOptions()) || @defaultToNewAddress)
      @onAddAddressButtonClick()

    return @$el

  getAddressPromise: () ->
    deferred = Q.defer()

    @formErrors.reset()
    formData = @$el.serializeObject()

    if (@useNewAddress)

      saveAddress = !!(formData.saveAddress)
      address = new App.Models.V3.Address(null, {
        clientId: @patron?.id
        mapping: C.Mappings.Direct.CreditCards.Address
      })
      addressAttrs = {
        _countryCode: @localityAutocomplete.getCountry()
        _extendedAddress: formData.extendedAddress
        _crossStreet: formData.crossStreet
        _locality: formData.locality
        _postalCode: formData.postalCode
        _region: @localityAutocomplete.getState()
        _streetAddress: formData.streetAddress
      }
      address.set(addressAttrs)

      if (address.isValid(true))
        @formErrors.reset()
        if (saveAddress)
          @loadingBox.startLoad()

          address.savePromise()
          .then (model) =>
            @patron.fetchPromise()
          .then (model) =>
            @render()
            @useNewAddress = false
            @$('.selectedAddressId').val(address.id)
            @loadingBox.stopLoad()
            deferred.resolve(address)
          .fail (errors) =>
            @loadingBox.stopLoad()
            @formErrors.show({
              title:  "Error saving address."
              errors
            })
            deferred.reject(errors)
          .done()

        else
          deferred.resolve(address)

      else
        errors = address.errors
        @formErrors.show({
          title:  "Invalid address."
          errors
        })
        deferred.reject(errors)

    else if (@useVenueAddress)
      deferred.resolve({useVenueAddress: true})
    else
      selectedAddressId = formData.selectedAddressId
      address = @patron.addresses().get(selectedAddressId)
      if (address)
        deferred.resolve(address)
      else
        deferred.resolve(@existingShipment.get('_shipFromAddress'))

    return deferred.promise