module.exports = clientController = {}

(() ->

  @routeIndex = ($container, searchTerm, showAll) ->
    _$container = $container

    if (!_clientCollection)
      officeId = SESSION.office_id
      if (SESSION.role.can(App.Access.Resources.Customers.CanSeeBrokerageScope))
        officeId = null
      _clientCollection = new App.Collections.V3.Clients(null, {
        mapping: C.Mappings.Direct.Clients.Client
        officeId
        clientsPerPage: 100
      })

    if (_clientView)
      _clientView.remove()

    _clientView = new App.Views.Clients.List({
      $container: _$container
      collection: _clientCollection
      searchTerm
    })

    if (searchTerm)
      _clientView.clearTable()
      _clientCollection.searchTerm = searchTerm
      _clientCollection.fetch({
        reset: true
      })
    else if (showAll)
      _clientView.clearTable()
      _clientCollection.searchTerm = null
      _clientCollection.fetch({
        reset: true
      })
     else
      _clientCollection.reset()

  @routeSearch = ($container, searchTerm)  ->
    _$container = $container
    @routeIndex(_$container, searchTerm)

  @routeAll = ($container)  ->
    _$container = $container
    @routeIndex(_$container, null, true)

).call(clientController)
