App.Utils.Countries = require('./locality.coffee')
momentTimeZone = require('moment-timezone')

module.exports = App.Utils = App.Utils || {}

`(function() {

  // LOADING INDICATORS ////////////////////////////////////////////////////////

  // Must be used with the 'new' keyword:
  // this.buttonLoader = new App.Utils.LoadingButton($('#delivery_table'));
  App.Utils.LoadingButton = function($button) {

    var loadingText = $button.data('loading-text') || 'Loading...';

    this.$button = $button;
    this.isLoading =  false;
    this.originalHtml = $button.html();
    this.loadingHtml = '<i class="fa-solid fa-rotate fa-spin"></i> ' + loadingText;

    this.startLoad = function() {
      if (!this.isLoading) {
        this.$button.attr('disabled', true);
        this.$button.html(this.loadingHtml);
        this.isLoading = true;
      }
    };

    this.stopLoad = function() {
      if (this.isLoading) {
        this.$button.attr('disabled', false);
        this.$button.html(this.originalHtml);
        this.isLoading = false;
      }
    };

    return this;

  };

  App.Utils.LOADING_BOX_HTML = [
    '<div class="loading">',
    '  <div class="content">',
    '    <h1>LOADING</h1>',
    '   <div id="floatingBarsG">',
    '     <div class="blockG" id="rotateG_01"/>',
    '     <div class="blockG" id="rotateG_02"/>',
    '     <div class="blockG" id="rotateG_03"/>',
    '     <div class="blockG" id="rotateG_04"/>',
    '     <div class="blockG" id="rotateG_05"/>',
    '     <div class="blockG" id="rotateG_06"/>',
    '     <div class="blockG" id="rotateG_07"/>',
    '     <div class="blockG" id="rotateG_08"/>',
    '   </div>',
    '  </div>',
    '</div>'
  ].join('');

  App.Utils.LOADING_BOX_INLINE_HTML = [
    '<div class="loadingInline">',
    '  <div class="content" style="display: table;">',
    '   <div id="floatingBarsG" style="display: table-cell;">',
    '     <div class="blockG" id="rotateG_01"/>',
    '     <div class="blockG" id="rotateG_02"/>',
    '     <div class="blockG" id="rotateG_03"/>',
    '     <div class="blockG" id="rotateG_04"/>',
    '     <div class="blockG" id="rotateG_05"/>',
    '     <div class="blockG" id="rotateG_06"/>',
    '     <div class="blockG" id="rotateG_07"/>',
    '     <div class="blockG" id="rotateG_08"/>',
    '   </div>',
    '   <h1 style="display: table-cell; padding: 18px 0 0 20px">LOADING</h1>',
    '  </div>',
    '</div>'
  ].join('');

  // Must be used with the 'new' keyword:
  // this.tableLoader = new App.Helpers.LoadingTable($('#delivery_table'));
  App.Utils.LoadingBox = function($container, options) {

    this.$container = $container;
    this.options = options || {};

    if (this.$container.is('table')) {
      var $sizeGuide = this.$container.closest('.widget-content');
      this.$container = $sizeGuide;
    }
    this.isLoading = false;

    var html = App.Utils.LOADING_BOX_HTML;
    if (this.options.isInline) {
      html = App.Utils.LOADING_BOX_INLINE_HTML;
    }

    this.startLoad = function() {
      if (!this.isLoading) {
        var w = this.$container.width();
        var h = this.$container.height();
        var cssProps = {
          width: w + 'px',
          height: h + 'px'
        };

        if (this.options.isInline) {
          cssProps = {};
        }

        this.$loadingOverlay = $(html)
          .css(cssProps)
          .prependTo(this.$container);
        this.isLoading = true;
      }
    };

    this.stopLoad = function() {
      if (this.isLoading) {
        this.$loadingOverlay.remove();
        this.isLoading = false;
      }
    };

    return this;

  };

  // FORMATTING ////////////////////////////////////////////////////////////////

  App.Utils.Address = {
    fullAddress: function(address) {
      return address.street_address + ", " + address.region + ", " + address.locality + " " + address.postal_code;
    },
    localityRegion: function(address){
      if (address){
        return address.locality + ", " + address.region;
      } else {
        return "";
      }
    }
  };

  // AUTOCOMPLETES /////////////////////////////////////////////////////////////

  var AUTOCOMPLETE_LIMIT = 30;

  App.Utils.performerAutocomplete = function($el, shouldReturnId) {
    $el.select2({
      placeholder: "Performer Name",
      minimumInputLength: 2,
      ajax: {
        url: '/api_direct/v9/searches',
        dataType: 'json',
        data: function (term, page) {
          return {
            q: term.trim(),
            per_page: AUTOCOMPLETE_LIMIT,
            types: ['performers']
          };
        },
        results: function (jsonData, page) {
          // Select2 wants an array of objects with id & text.
          const results = jsonData.results.map(item => ({
            text: item.name,
            id: (shouldReturnId) ? item.id : item.name
          }));

          return { results };
        }
      },
      initSelection: function (element, callback) {
        var data = {
          id: element.val(),
          text: element.val()
        };
        callback(data);
      }
    });
    return $el
  };

  App.Utils.venueAutocomplete = function($el, shouldReturnId) {
    $el.select2({
      placeholder: "Venue Name",
      minimumInputLength: 2,
      ajax: {
        url: '/api_direct/v9/searches',
        dataType: 'json',
        data: function (term, page) {
          return {
            q: term,
            per_page: AUTOCOMPLETE_LIMIT,
            types: ['venues']
          };
        },
        results: function (jsonData, page) {
          // Select2 wants an array of objects with id & text.
          var results = [];
          _.each(jsonData.results, function(item) {
            results.push({
              text: item.name,
              id: (shouldReturnId) ? item.id : item.name
            });
          });
          return {
            results: results
          };
        }
      },
      initSelection: function (element, callback) {
        var data = {
          id: element.val(),
          text: element.val()
        };
        callback(data);
      }
    });
    return $el;
  };

  App.Utils.eventAutocomplete = function($el, shouldReturnId) {
    $el.select2({
      placeholder: "Event Name",
      minimumInputLength: 2,
      ajax: {
        url: "/api_direct/v9/searches",
        dataType: 'json',
        data: function (term, page) {
          return {
            q: term,
            types: ['events'],
            limit: AUTOCOMPLETE_LIMIT
          };
        },
        results: function (jsonData, page) {
          // Select2 wants an array of objects with id & text.
          var results = [];
          _.each(jsonData.results, function(item) {
            results.push({
              text: item.name,
              id: (shouldReturnId) ? item.id : item.name
            });
          });
          return {
            results: results
          };
        }
      },
      initSelection: function (element, callback) {
        var data = {
          id: element.val(),
          text: element.val()
        };
        callback(data);
      }
    });

  };


  // TODO: Support more than 100 users.
  // Keep re-fetching until no more.
  App.Utils.createdByBrokerageUsersAutocomplete = function($el, shouldReturnId) {

    var setupCreatedBy = function(dataSource) {
      var data = $el.select2('data'); // returns the $el if no data for whatever reason.
      $el.select2({
        placeholder: 'Name',
        data: dataSource,
        initSelection: function(element, callback) {
          var data = {
            id: element.val(),
            text: element.val()
          };
          callback(data);
        }
      });
      if (data != $el) {
        $el.select2('data', data);
      }
    };

    // We look for collection globally - only fetch it once.
    var collection = App.Collections.V2.brokerageUsers;
    if (collection) {
      setupCreatedBy(App.Collections.V2.brokerageUsers.selectOptions);
    } else {
      setupCreatedBy({});
    }

    if (!collection) {
      var options = { brokerageId: SESSION.brokerage_id };
      collection = new App.Collections.V2.BrokerageUsers(null, options);
      collection.fetch({
        data: {
          brokerage_id: SESSION.brokerage_id
        },
        success: function(collection, response, options) {
          var selectOptions = _.map(collection.models, function(model) {
            return {
              id: (shouldReturnId) ? model.get('id') : model.get('name'),
              text: model.get('name')
            };
          });
          collection.selectOptions = selectOptions;
          App.Collections.V2.brokerageUsers = collection;
          setupCreatedBy(App.Collections.V2.brokerageUsers.selectOptions);
        },
        error: function() {
          $el.select2({
            placeholder: 'Error Loading',
            data: [{ id: 0, text: 'Could not load brokerage users' }]
          });
        }
      });
    }

     /*
     $.ajax({
     dataType: "json",
     url: '/api/users',
     data: { brokerage_id: SESSION.brokerage_id },
     success: function(data) {

     var selectOptions = _.map(data, function(el) {
     return {
     id: el.id,
     text: el.name
     };
     });

     that.$("#created_by_autocomplete").select2({
     placeholder: "Name",
     data: selectOptions
     });

     }
     });


     */
  };


  // SEATING ///////////////////////////////////////////////////////////////////

  App.Utils.isSeatArrayOddEven = function(seatsArray) {
    var groups = App.Utils.seatsToSeatRange(seatsArray);
    if (groups.length) {
      var firstGroup = groups[0] + ''; // String cast.
      if (firstGroup.indexOf('O/E') > -1) {
        return true;
      }
    }
    return false;
  };

  App.Utils.calculateLowSeat = function(seatsArray) {
    var seatsArray = _.clone(seatsArray);
    seatsArray = seatsArray.sort(function(a, b) {
      return a - b;
    })
    return seatsArray[0];
  };

  App.Utils.displaySeatArray = function(seatsArray) {
    if (!seatsArray.length) {
      return 'No Seats';
    }
    var first, last;
    first = _.first(seatsArray);
    last  = _.last(seatsArray);
    if (+first == +last) {
      return first;
    } else {
      return first + '-' + last;
    }
  };

  // Overkill logic?
  App.Utils.seatsToSeatRange = function(seatsArray){

    if (!seatsArray) {
      return '';
    }

    seatsArray = _.uniq(seatsArray);

    var currentNumber = null;
    var oddEven = '';
    var groups = [];
    var seatsArray = _.clone(seatsArray);
    seatsArray = seatsArray.sort(function(a, b) {
      return a - b;
    });
    var currentGroup = seatsArray.length ? [seatsArray.shift()] : null;

    var isOddEven = false;
    var isConsectutive = false;
    while (seatsArray.length) {
      currentNumber = seatsArray.shift();
      if (+_.last(currentGroup) == +currentNumber - 1 && !isOddEven) {
        currentGroup.push(currentNumber);
        isConsectutive = true;
      } else if (+_.last(currentGroup) == +currentNumber - 2 && !isConsectutive) {
        oddEven = ' (O/E)';
        currentGroup.push(currentNumber);
        isOddEven = true;
      } else {
        groups.push({
          seats: currentGroup,
          oddEven: oddEven
        });
        oddEven = '';
        currentGroup = [currentNumber];
      }
    }

    var condenseGroup = function(group){
      var first, last;
      first = _.first(group.seats);
      last  = _.last(group.seats);
      if (+first === +last) {
        return first;
      } else {
        return first + ' - ' + last + (group.oddEven || '');
      }
    };

    // Add the last current group into the groups array.
    currentGroup && groups.push({
      seats: currentGroup,
      oddEven: oddEven
    });

    // Format each of the group ranges.
    groups = _.map(groups, condenseGroup);
    return groups

  };

  App.Utils.showAvailableSeats = function(seatsCollection) {
    var availColl, availArray = [];

    if (seatsCollection instanceof App.Collections.V3.Tickets) {
      seatsCollection = seatsCollection.models;
      availColl = _.filter(seatsCollection, function(tick) { return tick.get('_state') === "available"; });
      _.each(availColl, function(t) {return availArray.push(t.get('_seat')) });
    } else {
      availColl = _.filter(seatsCollection, function(tick) { return tick.get('state') === "available"; });
      _.each(availColl, function(t) {return availArray.push(t.get('seat')) });
    }

    return availArray;
  };

  // FILTER PARAM ENCODING /////////////////////////////////////////////////////

  App.Utils.encodeFilterParams = function(params) {
    return btoa(unescape(encodeURIComponent(JSON.stringify(params))));
  }

  App.Utils.decodeFilterParams = function(encodedFilterParams) {
    return JSON.parse(decodeURIComponent(escape(atob(encodedFilterParams))))
  };

  // CURRENCY //////////////////////////////////////////////////////////////////



  App.Utils.currencyToValue = function(currency) {
    if(currency) {
      result = parseFloat(currency.toString().replace(/[^0-9.]/g, ''));
      return (isNaN(result) ? 0 : result);
    } else {
      return 0;
    }
  };


})();`

#// COFFEESCRIPT ///////////////////////////////////////////////////////////////
#///////////////////////////////////////////////////////////////////////////////
#///////////////////////////////////////////////////////////////////////////////
#///////////////////////////////////////////////////////////////////////////////
#///////////////////////////////////////////////////////////////////////////////
#///////////////////////////////////////////////////////////////////////////////
#///////////////////////////////////////////////////////////////////////////////
#///////////////////////////////////////////////////////////////////////////////
#///////////////////////////////////////////////////////////////////////////////
#///////////////////////////////////////////////////////////////////////////////

# RETAIL INPUT /////////////////////////////////////////////////////////////////
# category seats
# new po
# consignment po

RETAIL_INPUT_PLACEHOLDER = 'Via Markup'

App.Utils.RetailInput = do () ->

  retailInputCounter = 0

  return (options) ->
    {
      $container
      inputName
      inputId
      retailPrice
      retailPriceOverride
      isPriceEditTable
      onChange
    } = options

    # Prevent double inits.
    if ($container.hasRetailInput)
      return

    defaults = {
      inputId: "retailInput#{ retailInputCounter }"
      inputName: "retailInput#{ retailInputCounter }"
    }
    options = _.extend(defaults, options)
    @onChange = options.onChange
    @retailPriceOverride = options.retailPriceOverride
    @retailPrice = options.retailPrice
    $container.hasRetailInput = true
    @isOverridden = !!@retailPriceOverride

    disabled = "disabled='true'"
    if (@isOverridden)
      disabled = ''

    checked = ''
    if (@isOverridden)
      checked = "checked='true'"

    value = ''
    if (@isOverridden && @retailPriceOverride)
      value = "value = #{ @retailPriceOverride }"
    if (!@isOverridden && @retailPrice)
      value = "value = #{ @retailPrice }"

    width = ''
    inputWidth = '60%'
    if (isPriceEditTable)
      width = 'width: 130px;'
      inputWidth = '85px'

    if (isPriceEditTable)
      @$html = $("
      <div style='background: #fff;
      margin-top: 5px;
      display: table;
      border-radius: 3px;
      -moz-box-shadow:    inset 0 0 3px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.75);
      box-shadow:         inset 0 0 3px rgba(0, 0, 0, 0.75);
      #{ width }
      '>
        <div class='input-prepend' style='width: auto; display: table-cell;'>
          <span class='add-on' style=''>$</span>
          <input tabindex='-1' type='text' name='#{ inputName }' id='#{ options.inputId }' placeholder='0.00' style='display: table-cell; width: #{ inputWidth };' #{ disabled } #{ value } />
        </div>
        <div style='display: table-cell; padding: 4px 5px 0px 5px;'>
          <label>
            <input tabindex='-1' type='checkbox' id='retailInputCheckbox#{ retailInputCounter }' style='margin: 0;' #{ checked }/>
            <a style='white-space: nowrap;'>?</a>
          </label>
      </div>")
    else
      @$html = $("
        <div style='background: #fff;
  border-radius: 3px;
  padding: 5px 5px 3px 5px;
  -moz-box-shadow:    inset 0 0 3px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.75);
  box-shadow:         inset 0 0 3px rgba(0, 0, 0, 0.75);
  #{ width }
  '>
          <div class='input-prepend' style='width: auto; display: table;'>
            <span class='add-on' style=''>$</span>
            <input tabindex='-1' type='text' name='#{ inputName }' id='#{ options.inputId }' placeholder='0.00' style='display: table-cell; width: #{ inputWidth };' #{ disabled } #{ value } />
          </div>
          <label style='height: 20px; padding-top: 5px; white-space: nowrap;'>
            <input tabindex='-1' type='checkbox' id='retailInputCheckbox#{ retailInputCounter }' style='margin-bottom: 7px; width: 24px;' #{ checked }/>
            <a style='white-space: nowrap;'>Retail Override</a>
          </label>
        </div>")

    $container.html(@$html)
    @$checkbox = @$html.find('input[type=checkbox]')
    @$input = @$html.find('input[type=text]')
    @$input.formatCurrency()
    @$html.find('a').tooltip({
      placement: 'bottom'
      html: true
      title: '<span style=\'font-size: 14px;\'>Retail price is determined by your markup rules unless you enable this manual override.</span>'
    })
    @$html.on('click', 'label', _.bind(@toggleOverride, @))
    @$html.on('click', 'input[type=checkbox]', _.bind(@toggleOverride, @))
    @$html.on('change', 'input', _.bind(@changePrice, @))
    retailInputCounter++
    return @

App.Utils.RetailInput.prototype.toggleOverride = (e) ->
#  e.preventDefault()
#  e.stopPropagation()
  setTimeout(() => # Let the browser check the box... then we'll run our code to ensure proper state.
    @isOverridden = @$checkbox.is(':checked')
    if (@isOverridden)
      @$input.removeAttr('disabled')
      @$input.val(@retailPriceOverride || '')
    else
      @$input.attr('disabled', true)
      @$input.val(@retailPrice || RETAIL_INPUT_PLACEHOLDER)
    @onChange?(@)
  , 0)
#  return false

App.Utils.RetailInput.prototype.enableOverride = () ->
  @isOverridden = true
  @$checkbox.attr('checked', true)
  @$input.removeAttr('disabled')

App.Utils.RetailInput.prototype.disableOverride = () ->
  @isOverridden = false
  @$checkbox.removeAttr('checked')
  @$input.attr('disabled', true)

App.Utils.RetailInput.prototype.changePrice = (e) ->
  if (@isOverridden)
    @retailPriceOverride = @$input.val()
  @onChange?(@)

App.Utils.RetailInput.prototype.getVal = () ->
  if (@isOverridden)
    return @retailPriceOverride
  else
    return null

# FORM ERROR MANAGER ///////////////////////////////////////////////////////////
App.Utils.FormErrorManager = ($container) ->
  @$container = $container
  @isVisible = false

App.Utils.FormErrorManager.prototype.reset = (options) ->
  @errors = []
  @title = null
  @type = null
  @isVisible = false
  @$container.html('')

App.Utils.FormErrorManager.prototype.show = (options) ->
  {
    title # Big header for alert message. None by default.

    type # Controls color. Red ('error') by default.
    # error = red, succcess = green, info = blue, block = yellow

    errors # String or array or object of errors.
    message # String to display (generally for success).  Alias for errors.

    append # Append additional 'shows' to the existing message.
  } = options

  @title = title || ''
  @type = type || 'error'

  errors = errors || message
  cleanedErrors = []

  if (errors)

    # If a jqXHR (error callback of jQuery.ajax).
    if (errors.responseText)
      try
        json = JSON.parse(errors.responseText)
        for own key, val of json
          cleanedErrors.push("#{ key }: #{ val }")
      catch e
        cleanedErrors.push(errors.responseText)

    # A single Error object.
    else if (errors instanceof Error)
      cleanedErrors.push(errors.message)

    # An array of Error objects or strings.
    else if (_.isArray(errors))
      for error in errors
        if (error.message) # Error message.
          cleanedErrors.push(error.message)
        else # String.
          cleanedErrors.push(error)

    # Single string.
    else if (_.isString(errors))
      cleanedErrors.push(errors)

    # Generally an object of validation errors.
    else if (_.isObject(errors))
      for own key, val of errors
        if (_.isObject(val)) # Support nested objects.
          for own key2, val2 of val
            cleanedErrors.push(val2)
        else
          cleanedErrors.push(val)

  if (@isVisible && append)
    @errors = @errors.concat(cleanedErrors)
  else
    @errors = [].concat(cleanedErrors)

  titleHtml = ''
  if (@title)
    titleHtml = "<h4>#{ @title }</h4><br/>"

  errorHtml = []
  for error in @errors
    errorHtml.push("<p>#{ error }</p>")
  errorHtml = errorHtml.join('')

  $html = $("
    <div class='alert alert-#{ @type }'>
      <button type='button' class='close' data-dismiss='alert'>×</button>
      #{ titleHtml }
      #{ errorHtml }
    </div>")
  $html.bind 'closed', () =>
    @isVisible = false

  @$container.html($html)
  @isVisible = true
  if (@$container.length)
    @$container[0].scrollIntoView()

# AUTOCOMPLETES ////////////////////////////////////////////////////////////////
App.Utils.clientOfficeAutocomplete = (options) ->
  {
    $el
    shouldReturnId
    callback
    types
  } = options
  App.Utils.autocomplete({
    selector: "##{ $el.attr('id') }"
    types: (types || ['clients', 'offices'])
    select: (event, ui) ->
      item = ui.item
      returnVal = if (shouldReturnId) then item.id else item.label
      if (shouldReturnId && !callback)
        console.log('WARNING: App.Utils.clientOfficeAutocomplete needs a callback to return the item ID.')
      if (callback)
        callback(returnVal)
  })
  if ($el.length)
    $el[0].setClientOrOffice = (data) ->
      # data = { id: 123, text: 'xxx' };
      $el.val(data.text)
      if (shouldReturnId)
        callback(data.id)
      else
        callback(data.text)

App.Utils.companyAutocomplete = (options) ->
  {
    $el
    val
    isRerender
  } = options
  NEW_COMPANY_ID = -1

  processOptions = () ->
    $el.select2({
      placeholder: 'Company Name'
      allowClear: true,
      data: App.Utils.companyAutocomplete.companyOptions
      initSelection: (element, callback) ->
        data = {
          id: +(element.val())
          text: _.where(App.Utils.companyAutocomplete.companyOptions, { id: parseInt(element.val()) })[0].text
        }
        callback(data)
      createSearchChoice: (term, data) ->
        return {
          id: NEW_COMPANY_ID
          text: term
        }
    })
    if (val)
      $el.select2('val', val)

  if (!isRerender || !App.Utils.companyAutocomplete.companyOptions)
    companies = []
    $el.html('<div style="padding: 5px 10px;"><i class="fa-solid fa-rotate fa-spin"></i> Loading Companies</div>')
    $.get('/api_direct/v9/companies?paginate=false', (data) =>
      if (data.companies)
        companies = data.companies
      companies = companies.sort((a, b) ->
        return a.name.localeCompare(b.name)
      )
      App.Utils.companyAutocomplete.companyOptions = _.map(companies, (item) ->
        return {
          id: item.id
          text: item.name
        }
      )
      $el.html('')
      processOptions()
    )
  else
    processOptions()


# REQUEST KILLER ///////////////////////////////////////////////////////////////

App.Utils.requestKiller = do () ->

  xhrs = []
  reqKiller = {}
  reqKiller.add = (xhr) ->
    key = Backbone.history.fragment
    entry = {
      key
      xhr
    }
    xhrs.push(entry)

  reqKiller.kill = () ->
    if (xhrs.length)
      key = Backbone.history.fragment
      survivors = []

      while entry = xhrs.pop()
        if (entry.key == key)
          survivors.push(entry)
        else
          entry.xhr.abort()

      xhrs = survivors

  return reqKiller

# GENERIC UTILS ////////////////////////////////////////////////////////////////
App.Utils.createAttrObject = (name, value, nested_name) ->
  obj = {}
  if (nested_name)
    obj[name] = {}
    obj[name][nested_name] = value
  else
    obj[name] = value
  return obj

App.Utils.falsyToEmptyString = (value) ->
  if (!!value == false)
    return ''
  return value

# LOCALITY AUTOCOMPLETE ////////////////////////////////////////////////////////

App.Utils.LocalityAutocomplete = do () ->

  # Memomize pattern.
  countriesByAbbrev = {}
  countryOptions = []
  statesByCountry = {}

  # Preload countries data into the memo data.
  for country in App.Utils.Countries
    countriesByAbbrev[country.abbreviation] = country
    text = if country.abbreviation then "#{ country.abbreviation } - #{ country.name }" else country.name
    countryOptions.push({
      id: country.abbreviation
      text
    })

  # App.Utils.LocalityAutocomplete becomes this function.
  # The above only runs once.
  constructor = (options) ->
    {
      @$country
      @$state
      defaultCountry
      countryVal
      stateVal
    } = options
    stateValInitialSetOccured = false
    defaultCountry ||= 'US'

    onCountryChange = () =>
      states = countriesByAbbrev[@selectedCountry.toUpperCase()].states
      statesByCountry[@selectedCountry] = {}
      stateOptions = []
      for state in states
        statesByCountry[@selectedCountry][state.abbreviation] = state
        text = if state.abbreviation then "#{ state.abbreviation } - #{ state.name }" else state.name
        stateOptions.push({
          id: state.abbreviation || state.name
          text
        })

      @stateSelect = @$state.select2({
        placeholder: 'State'
        data: stateOptions
        initSelection: (element, callback) =>
          stateName = element.val()
          nameIfHasAbbreviation = statesByCountry[@selectedCountry]?[element.val()]?.name
          if (nameIfHasAbbreviation)
            stateName = nameIfHasAbbreviation
          data = {
            id: element.val()
            text: stateName
          }
          callback(data)
      })

      if (!stateValInitialSetOccured && stateVal)
        @stateSelect.select2('val', stateVal)
        stateValInitialSetOccured = true
      else
        @stateSelect.select2('val', '')

    # IF COUNTRY ///////////////////////////////////////////////////////////////
    if (@$country)
      @countrySelect = @$country.select2({
        placeholder: 'Country'
        data: countryOptions
        initSelection: (element, callback) =>
          data = {
            id: element.val()
            text: countriesByAbbrev[element.val().toUpperCase()].name
          }
          callback(data)
      })

      @countrySelect.on 'change', (e) =>
        @selectedCountry = e.val
        onCountryChange()

    if (defaultCountry || countryVal)
      selectedCountry = countryVal || defaultCountry
      @countrySelect?.select2('val', selectedCountry)
      @selectedCountry = selectedCountry
      onCountryChange()

    @getCountry = () ->
      return @countrySelect?.select2('val') || null

    @getState = () ->
      return @stateSelect.select2('val') || null

    return @

  return constructor

#///////////////////////////////////////////////////////////////////////////////

# CollapsibleWidget ////////////////////////////////////////////////////////////
App.Utils.CollapsibleWidget = (options = {}) ->
  {
    @$content
    @$collapseButton
    @isHorizontalSplit
    @onChange
    @isCollapsed
    @name
  } = options
  @$icon = @$collapseButton.find('i')
  if (@isHorizontalSplit)
    @$collapseButton.on('click', (e) =>
      if (@isCollapsed)
        @expand()
      else
        @collapse()
    )
  else
    # Vertical Split
    @$content.on 'hide', () =>
      @collapse()
    @$content.on 'show', () =>
      @expand()
  return @

App.Utils.CollapsibleWidget.prototype.expand = () ->
  if (@isCollapsed)
    @isCollapsed = false
    if (@isHorizontalSplit)
      @$icon.removeClass('fa-chevron-left').addClass('fa-chevron-right')
      @$content.show()
      @$content.closest('.horizontalFilter').removeClass('collapsed')
      @onChange?()
    else
      # Vertical
      @$icon.attr('class', 'fa-solid fa-chevron-up')

App.Utils.CollapsibleWidget.prototype.collapse = () ->
  if (!@isCollapsed)
    @isCollapsed = true
    if (@isHorizontalSplit)
      @$icon.removeClass('fa-chevron-right').addClass('fa-chevron-left')
      @$content.hide()
      @$content.closest('.horizontalFilter').addClass('collapsed')
      @onChange?()
    else
      # Vertical
      @$icon.attr('class', 'fa-solid fa-chevron-down')


#// DATE FORMATTING ////////////////////////////////////////////////////////////

App.Utils.makeTimestampHumanForEvent = (timestamp, format = C.DateFormats.TableDateWithTime) ->

  if (!timestamp)
    return ''

  if (_.isString(timestamp))
    timestamp = timestamp.replace(/[Zz]/g, '')

    timecode = timestamp.slice(-8)
    if (timecode == "00:00:00") # TBD
      isTBD = true
      format = format.replace(/[hmsa:]/g, '').trim()

  date = moment(timestamp)
  if (date.isValid())
    result = date.format(format)
    if (isTBD)
      result += " TBD"
  else
    result = ''

  return result

App.Utils.makeTimestampHuman = (timestamp, format = C.DateFormats.TableDateWithTime) ->
  date = moment(timestamp)
  return date.format(format)

App.Utils.makeTimestampHumanEST = (timestamp, format = C.DateFormats.TableDateWithTimeAndZone) ->
  date = momentTimeZone(timestamp).tz('America/New_York')
  return date.format(format)

#// SHOW BASIC ERROR ///////////////////////////////////////////////////////////

App.Utils.fail = (error) ->
  console.error(error, error.stack)
  errorModal = new App.Views.Shared.BasicModal({
    header: 'Error'
    message: error?.message
  })

#// FILES //////////////////////////////////////////////////////////////////////

App.Utils.FileUploader = (options = {}) ->
  { $fileInputEl
  $dragDropEl
  @fileTypeFilter
  @fileSizeLimit
  @callback } = options
  @fileReader = new FileReader()

  # User hovers over $dragDropEl with a file
  @fileDragHover = (e) =>
    e.preventDefault()
    e.stopPropagation()

    if (e.type == 'dragover' && !@active)
      @active = true
      $dragDropEl.addClass('dragdrop_active')
    if (e.type == 'dragleave' && @active)
      @active = false
      $dragDropEl.removeClass('dragdrop_active')
    return false

  # User drops a file into $dragDropEl
  @fileDrop = (e) =>
    @fileDragHover(e)
    file = e.originalEvent.dataTransfer.files[0]
    @prepFile(file)

  @prettySize = (size) ->
    if (!size)
      fileSize = 0
    if (size > 1024 * 1024)
      fileSize = (Math.round(size * 100 / (1024 * 1024)) / 100).toString() + 'MB'
    else
      fileSize = (Math.round(size * 100 / 1024) / 100).toString() + 'KB'
    return fileSize

  # User drops or selects a file
  @fileIsCSV = (file) -> file.type == 'text/csv' && (/\.(csv)$/i).test(file.name)
  @fileIsTXT = (file) -> @fileTypeIsTXT(file.type) && (/\.(txt)$/i).test(file.name)
  @fileTypeIsTXT = (type) ->
    txtMimes = ['ext/plain', 'application/txt', 'browser/internal', 'text/anytext', 'widetext/plain', 'widetext/paragraph']
    _.contains(txtMimes, type)

  @prepFile = (file) ->
    if ($dragDropEl)
      $dragDropEl.removeClass('dragdrop_active')

    if (!file)
      return

    @fileName = file.name
    @fileSize = @prettySize(file.size)
    @fileType = file.type

    if (@fileTypeFilter? && @fileTypeFilter == C.FileFilters.PDF)
      if !(file.type == 'application/pdf' && (/\.(pdf)$/i).test(file.name))
        @callback(new Error('File is not a PDF.'))
        return null
    if (@fileTypeFilter? && @fileTypeFilter == C.FileFilters.CSV)
      unless @fileIsCSV(file)
        @callback(new Error('File is not a CSV.'))
        return null

    if (@fileTypeFilter? && @fileTypeFilter == C.FileFilters.CSVorTXT)
      unless @fileIsCSV(file) || @fileIsTxt(file)
        @callback(new Error('File is not a CSV or TXT.'))
        return null

    if (@fileSizeLimit)
      if (file.size > @fileSizeLimit)
        @callback(new Error('File is bigger than limit of ' + @prettySize(@fileSizeLimit) + '.'))
        return null

    try
      @fileReader.readAsDataURL(file)
    catch error
      @callback(new Error('Error parsing PDF file.  Contact support if the problem persists.'))

  # FileReader finishes reading a file (readAsDataURL)
  @fileReader.onload = (e) =>
    fileURLData = e.target.result
    parts = fileURLData.split(',')
    fileBase64 = parts[1]
    @fileModel = new App.Models.V3.File({
      name: @fileName
      size: @fileSize
      type: @fileType
      base64: fileBase64
    })
    # Execute user specified callback - pass it a FileModel
    @callback(null, @fileModel)

  # On File Select change
  $fileInputEl.on('change', (e) =>
    file = e.target.files[0]
    @prepFile(file)
  )
  if ($dragDropEl)
    $dragDropEl.on('dragover', @fileDragHover)
    $dragDropEl.on('dragleave', @fileDragHover)
    $dragDropEl.on('drop', @fileDrop)

App.Utils.scrollableOffPage = ($el) ->
  SCROLLPAD   = 20
  $window     = $(window)
  elOffset      = $el.offset()
  elBottom = elOffset.top + $el.height()
  maxHeight = ( $window.height() + $window.scrollTop() ) - elOffset.top - SCROLLPAD
  if (elBottom > $window.height())
    $el.css({
      height: maxHeight
    })
  else
    bottomOfLastItem =  $el.children().last().offset().top + $el.children().last().height()
    contentHeight = bottomOfLastItem - elOffset.top
    if (contentHeight < maxHeight)
      $el.css({
        height: contentHeight
      })
    else
      $el.css({
        height: maxHeight
      })

ALPHA_NUMERIC_MIX = /(\d+)|(\D+)/g
NUMERIC_RX = /\d+/
App.Utils.alphaNumericSort = (as, bs) ->
  a = String(as).toLowerCase().match(ALPHA_NUMERIC_MIX)
  b = String(bs).toLowerCase().match(ALPHA_NUMERIC_MIX)

  if (a == null)
    return 1
  if (b == null)
    return -1
  while (a && b && a.length && b.length)
    a1 = a.shift()
    b1 = b.shift()
    if (NUMERIC_RX.test(a1) || NUMERIC_RX.test(b1))
      if (!NUMERIC_RX.test(a1))
        return 1
      if (!NUMERIC_RX.test(b1))
        return -1
      if (a1 != b1)
        return a1 - b1
    else if (a1 != b1)
      if (a1 > b1)
        return 1
      else
        return -1
  return a.length - b.length

# NOTES POPOVER ////////////////////////////////////////////////////////////////
MAX_NOTE_SIZE = 200
PRIVATE_LIMIT = 3

App.Utils.notesPopover = ($el, model) ->
  popoverHtml = []
  hasNotes = false

  # orderModel
  if (model.get('ticket_group'))
    external = model.get('ticket_group').external_notes
    if (external)
      popoverHtml.push('<h5>Public</h5>')
      popoverHtml.push('<p>', external, '</p>')
      hasNotes = true

  else if (model.get('_notes'))
    # v3OrderItemModel
    if (_.isArray(model.get('_notes')))
      priv = []
      pub = []
      privCount = 0

      notes = model.get('_notes')
      for note in notes
        if (note.category == 'external')
          pub.push(note.content)
        if (note.category == 'internal')
          priv.push(note.content)

      if (pub.length)
        popoverHtml.push('<h5>Public</h5>')
        for note in pub
          content = note.substr(0, MAX_NOTE_SIZE)
          if (note.length > MAX_NOTE_SIZE)
            content += "..."
          popoverHtml.push('<p class="popover_content">', content, '</p>')

      if (priv.length)
        popoverHtml.push('<h5>Private</h5>')
        for note in priv
          privCount++
          if (privCount <= PRIVATE_LIMIT)
            content = note.substr(0, MAX_NOTE_SIZE)
            if (note.length > MAX_NOTE_SIZE)
              content += "..."
            popoverHtml.push('<p class="popover_content">', content, '</p>')

      hasNotes = (pub.length || priv.length)

    # ticketHoldModel
    else
      popoverHtml.push('<h5>Hold Notes</h5>')
      popoverHtml.push('<p>', model.get('_notes'), '</p>')
      hasNotes = true

  # ticketGroupModel
  broker = model.get('_notesBroker') || model.get('ticket_group_broker_notes')
  priv = model.get('_notesPrivate') || model.get('ticket_group_internal_notes')
  pub = model.get('_notesPublic') || model.get('ticket_group_external_notes') || model.get('external_notes') # ticketGroup inside an OrderItem (order show viewByTickets area).

  if (pub)
    popoverHtml.push('<h5>Public</h5>')
    popoverHtml.push('<p>', pub, '</p>')
    hasNotes = true

  if (priv)
    popoverHtml.push('<h5>Private</h5>')
    popoverHtml.push('<p>', priv, '</p>')
    hasNotes = true

  if (broker)
    popoverHtml.push('<h5>Broker</h5>')
    popoverHtml.push('<p>', broker, '</p>')
    hasNotes = true

  popoverHtml = popoverHtml.join('')
  if (hasNotes)
    $el.popover({
        placement: 'left'
        title: 'Notes'
        content: popoverHtml
        container: $el
        trigger: 'hover'
        html: true
      })

# EXCHANGE DROPDOWN ////////////////////////////////////////////////////////////
# http://www.erichynds.com/blog/jquery-ui-multiselect-widget

App.Utils.infoPopover = ($container, options = {}) ->
  if($container.length)
    {
      title = 'Info',
      content = '',
      placement = 'left'
    } = options
    $container.html("<i class='fa-solid fa-circle-info'></i>")
    $el =  $container.find(".fa-circle-info")
    $el.popover({
      placement,
      title,
      content: "<p class=\"popover_content\">#{content}</p>"
      container: $el
      trigger: 'hover'
      html: true
    })

App.Utils.ExchangeDropdown = (options = {}) ->
  {
    @$el
  } = options
  collection = App.Collections.V3.exchanges
  if (collection)
    @render()
    return @
  else
    collection = new App.Collections.V3.Exchanges()
    collection.fetchPromise().then (collection) =>
      App.Collections.V3.exchanges = collection
      @render()
      return @
    .fail (error) ->
      console.error(error.stack)
      errorModal = new App.Views.Shared.BasicModal({
        header: 'Error fetching exchanges.',
        message: JSON.parse(error.responseText)
      })
    .done()

EXCHANGE_PRIORITY = [
  'Stubhub'
  'Ticket Network'
  'Event Inventory'
  'Vivid'
]

App.Utils.ExchangeDropdown.prototype.render = (valsToSet) ->
  checked = {}
  if (valsToSet)
    for key in valsToSet
      checked[key] = true

  collection = App.Collections.V3.exchanges
  options = collection.map((v3ExchangeModel) =>
    return {
      id: v3ExchangeModel.id
      text: v3ExchangeModel.get('name')
    }
  )

  optionSort = (a, b) ->
    aVal = EXCHANGE_PRIORITY.indexOf(a.text)
    bVal = EXCHANGE_PRIORITY.indexOf(b.text)
    if (aVal > -1 && bVal > -1)
      # If both in EXCHANGE_PRIORITY, sort based on their indexes.
      return aVal - bVal
    else
      # Put everything not in EXCHANGE_PRIORITY after anything that is.
      return bVal - aVal

  options = options.sort(optionSort)

  options.unshift({
    id:   C.IncludeAllExchanges
    text: C.IncludeAllExchanges
  })

  html = ["<select multiple='multiple'>"]
  for option in options
    if (checked[option.id])
      html.push("<option selected='true' value='#{ option.id }'>#{ option.text }</option>")
    else
      html.push("<option value='#{ option.id }'>#{ option.text }</option>")
  html.push("</select>")
  $html = $(html.join(''))
  @$el.html($html)
  multiselect = $html.multiselect({
    selectedList: 2
    height: 400
    checkAllText: 'Exclude All'
    uncheckAllText: 'Include All'
    minWidth: 200
    click: (event, ui) ->
      if (ui.checked)
        if (ui.value == C.IncludeAllExchanges)
          $html.multiselect('uncheckAll')
          $html.val([C.IncludeAllExchanges])
        else
          $html.find("option[value='#{ ui.value }']").attr('selected', true)
          $html.find("option[value='#{ C.IncludeAllExchanges }']").removeAttr('selected')
        $html.multiselect('refresh')
    checkAll: () ->
      $html.find("option").attr('selected', true)
      $html.find("option[value='#{ C.IncludeAllExchanges }']").removeAttr('selected')
      $html.multiselect('refresh')
    uncheckAll: () ->
      $html.find("option").removeAttr('selected')
      $html.find("option[value='#{ C.IncludeAllExchanges }']").attr('selected', true)
      $html.multiselect('refresh')
  })

  $('button.ui-multiselect').css({ width: '100%' })

# Gets or sets val like jQuery val() function.
App.Utils.ExchangeDropdown.prototype.val = (valsToSet) ->
  if (valsToSet)
    retry = () =>
      if (App.Collections.V3.exchanges)
        @render(valsToSet)
      else
        setTimeout(retry, 1000)
    retry()
  else
    val = @$el.find('select').val()
    if (_.contains(val, C.IncludeAllExchanges))
      val = C.IncludeAllExchanges
    return val

# API TOKEN DROPDOWN ///////////////////////////////////////////////////////////

App.Utils.apiTokenDropdown = ($el) ->
  API_CREDENTIAL_TYPE = 'ApiCredential'

  setupApiDropdown = (dataSource) ->
    data = $el.select2('data') # returns the $el if no data for whatever reason.
    $el.select2({
      multiple: true
      placeholder: 'Select'
      data: dataSource
    })
    if (data != $el)
      $el.select2('data', data)

  # We look for collection globally - only fetch it once.
  collection = App.Collections.V3.apiTokens
  if (collection)
    setupApiDropdown(App.Collections.V3.apiTokens.selectOptions)
  else
    setupApiDropdown({})

  if (!collection)
    officeTokenCollection = new App.Collections.V3.ApiTokens(null, {
      officeOnly: true
    })
    brokerageTokenCollection = new App.Collections.V3.ApiTokens()

    successCallback = (officeResult, brokerageResult) ->

      brokerageResult.each((tokenModel) ->
        if (tokenModel.get('type') == API_CREDENTIAL_TYPE)
          officeTokenCollection.add(tokenModel)
      )
      officeTokenCollection.sort()
      selectOptions = _.map(officeTokenCollection.models, (model) ->
        return {
          id: model.id
          text: (model.get('pretty_label') || model.get('label'))
        }
      )
      selectOptions.unshift({
        id: 'other'
        text: 'Other'
      })
      officeTokenCollection.selectOptions = selectOptions
      App.Collections.V3.apiTokens = officeTokenCollection
      setupApiDropdown(App.Collections.V3.apiTokens.selectOptions)

    errorCallback = (error) ->
      $el.select2({
        placeholder: 'Error Loading'
        data: [{
          id: 0
          text: 'Could not load API tokens'
        }]
      })

    Q.all([
      officeTokenCollection.fetchPromise()
      brokerageTokenCollection.fetchPromise()
    ])
    .spread (officeResult, brokerageResult) ->
      successCallback(officeResult, brokerageResult)
    .fail (error) ->
      errorCallback(error)
    .done()

# STUBHUB TRAITS DROPDOWN //////////////////////////////////////////////////////
App.Utils.StubhubTraitsDropdown = (options = {}) ->
  {
    @$el
    @selected
    @stubhubEventId
  } = options
  url = "https://api.stubhub.com/catalog/events/v1/#{ @stubhubEventId }/metadata.json"
  window.App.ErrorManager.ignoreNext(400)
  window.App.ErrorManager.ignoreNext(401)
  $.ajax(url, {
    type: 'GET'
    dataType: 'json'
    beforeSend: (request) =>
      request.setRequestHeader("Accept", "application/json")
      request.setRequestHeader("Authorization", "Bearer 8bba5e1bc919fc1d4865b78928e046")
      request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded")
    success: (data, textStatus, jqXHR) =>
      @render(null, data)
      window.App.ErrorManager.decreaseTolerance(400)
      window.App.ErrorManager.decreaseTolerance(401)
    error: (jqXHR, textStatus, errorThrown) =>
      try
        error = JSON.parse(jqXHR.responseText)
        errorMessage = error?.event?.error?.message
      catch
        errorMessage = "StubHub is currently unavailable."
      errorMessage ||= "StubHub API Error."
      @render(errorMessage, null)
      window.App.ErrorManager.decreaseTolerance(400)
      window.App.ErrorManager.decreaseTolerance(401)
      # If there's an error, the ErrorManager will have already deducted 1 ignore tolerance from the HTTP error status code.
      # So between that the and the two decreases above, 1 tolerance is deducted 1 val too many.  Compensate:
      window.App.ErrorManager.ignoreNext(jqXHR.status)
  })
  return @

TRAITS_PRIORITY = [
  'Ticket Feature'
  'Listing Disclosure'
  'Seller Comments'
]

App.Utils.StubhubTraitsDropdown.prototype.render = (error, data) ->

  traits = data?.event?.ticketTraits
  if (error || !traits)
    @$el.html("<div class='alert alert-error'>
      <button type='button' class='close' data-dismiss='alert'>×</button>
      Error fetching StubHub ticket traits for this event.
      <br/><br/>#{ error }
    </div>")
  else
    traitGroups = {}

    selectedTraits = {}
    if (@selected)
      for traitId in @selected
        selectedTraits[traitId] = true

    for trait in traits
      group = traitGroups[trait.type] || []
      group.push(trait)
      traitGroups[trait.type] = group

    orderedTraitGroups = TRAITS_PRIORITY
    for own groupName, group of traitGroups
      # If it's not in orderedTraitGroups, add it
      if (TRAITS_PRIORITY.indexOf(groupName) < 0)
        orderedTraitGroups.push(groupName)

    html = ["<select multiple='multiple'>"]
    for groupName in orderedTraitGroups
      group = traitGroups[groupName]
      html.push("<optgroup label='#{ groupName }'>")
      for trait in group
        if (selectedTraits[trait.id])
          html.push("<option selected='true' value='#{ trait.id }'>#{ trait.name }</option>")
        else
          html.push("<option value='#{ trait.id }'>#{ trait.name }</option>")
      html.push("</optgroup>")
    html.push("</select>")
    $html = $(html.join(''))
    @$el.html($html)
    $html.multiselect({
      selectedList: 1
      height: 400
      header: true
      classes: 'stubhubTraitsMultiselect'
    }).multiselectfilter()
    $('.ui-multiselect-menu').css({ width: '300px' })
    $('button.ui-multiselect').css({ width: '100%' })

App.Utils.StubhubTraitsDropdown.prototype.remove = () ->
  @$el.empty()

App.Utils.StubhubTraitsDropdown.prototype.val = () ->
  return @$el.find('select').val()

# PLURALIZE ////////////////////////////////////////////////////////////////////
App.Utils.pluralize = (amount, noun) ->
  if (amount == 1 || amount == -1)
    return "#{ amount } #{ noun.singularize() }"
  else
    return "#{ amount } #{ noun.pluralize() }"

# VALUE TO CURRENCY ////////////////////////////////////////////////////////////
App.Utils.valueToCurrency = (value) ->

  if (value)
    if (_.isString(value))
      if (value.toLowerCase() == 'null')
        return 'N/A'
      else
        value = parseFloat(value.replace(/\$|,/g, ''))

    value = App.Utils.twoDecimalPlaces(value)
    return "#{'$' + value.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}"
  else
    return '$0.00'

App.Utils.valueToCurrencyNo$ = (value) ->
  value = App.Utils.valueToCurrency(value)
  return value.replace(/\$/g, '')

# SHOW HIDE IS PAYMENT COMPLETED ///////////////////////////////////////////////
App.Utils.showHideIsPaymentCompleted = ($el, paymentType) ->
  if (
    paymentType == 'cash' ||
    paymentType == 'check' ||
    paymentType == 'money_order' ||
    paymentType == 'pay_pal'
  )
    $el.show()
  else
    $el.hide()
    $el.find('input[type=checkbox]').removeAttr('checked')

# MONTH OPTIONS ////////////////////////////////////////////////////////////////
App.Utils.monthOptions = () ->
  html = []
  for el, index in C.Months
    html.push("<option value='#{ index + 1 }'>#{ index + 1 } - #{ el }</option>")
  return html.join('')

# YEAR OPTIONS /////////////////////////////////////////////////////////////////
App.Utils.yearOptions = () ->
  html = []
  for i in [2014...2100]
    html.push("<option value='#{ i }'>#{ i }</option>")
  return html.join('')

# CALCULATE DISCOUNT ///////////////////////////////////////////////////////////
App.Utils.calculateDiscount = (subTotal, discountType, discountAmount) ->
  if (!discountAmount)
    return 0
  # FLAT
  if (discountType == 'dollar')
    return discountAmount
  # PERCENTAGE
  else
    newSubTotal = subTotal * (1 - (discountAmount / 100))
    newSubTotal = Math.round(newSubTotal * 100) / 100 # Round 2 decimal places.
    disc = subTotal - newSubTotal
    disc = Math.round(disc * 100) / 100
    return disc

# FLATTEN OBJECT ///////////////////////////////////////////////////////////////
App.Utils.flattenObject = (data) ->
  result = {}
  recurse = (curr, prop) ->
    if (Object(curr) != curr)
      result[prop] = curr
    else if (Array.isArray(curr))
      if (!curr.length)
        result[prop] = []
      else
        for el, index in curr
          recurse(el, prop + '[' + index + ']')
    else
      isEmpty = true
      for own key, val of curr
        isEmpty = false
        recurse(val, if prop then prop + '.' + key else key)
      if (isEmpty && prop)
        result[prop] = {}
  recurse(data, '')
  return result

# ROUND CURRENCY ///////////////////////////////////////////////////////////////
App.Utils.roundCurrency = (amount) ->
  amount = App.Utils.currencyToValue(amount)
  amount = App.Utils.twoDecimalPlaces(amount)
  return amount

# ROUND ///////////////////////////////////////////////////////////////////////
App.Utils.twoDecimalPlaces = (value) ->
  return Number(value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0])

# GREP /////////////////////////////////////////////////////////////////////////
Array.prototype.grep = (regex) ->
  @filter((elem) -> elem.match(regex))

# GREP KEYS ////////////////////////////////////////////////////////////////////
Object.grepKeys = (object, regex) ->
  return Object.keys(object).grep(regex).reduce(
    (memo, val) ->
      memo[val] = object[val]
      return memo
  , {})

# READ BARCODE PROMISE /////////////////////////////////////////////////////////
App.Utils.readBarcodePromise = (options = {}) ->
  {
    url
  } = options
  deferred = Q.defer()
  img = new Image()
  img.onload = () =>
      canvas = document.createElement('canvas')
      context = canvas.getContext('2d')
      width = img.width
      height = img.height
      canvas.width = width
      canvas.height = height
      context.drawImage(img, 0, 0)
      pixelData = context.getImageData(0, 0, width, height).data
      length = pixelData.length
      barcodeWorker = new Worker("/dist/barcode_reader#{ window.LATEST_BUILD_HASH }.js");
      barcodeWorker.addEventListener('message', (message) ->
        if (message.data.error)
          deferred.reject(message.data.error)
        else
          deferred.resolve(message.data.result)
      , false)
      barcodeWorker.postMessage({
        width
        height
        pixelData
      })
  img.crossOrigin = 'anonymous'
  img.src = url
  return deferred.promise

# Make seating zones human readable
App.Utils.formatTicketZone = (zone) ->
  zoneParts = zone.split('-');
  zoneParts = zoneParts.map((part) ->
    return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
  );

  return zoneParts.join(' ');

App.Utils.buildChartTg = (ticketGroupModel) ->
  return {
    section: ticketGroupModel.get('_section')
    row: ticketGroupModel.get('_row')
    price: ticketGroupModel.get('_priceWholesale')
    quantity: ticketGroupModel.get('_quantity')
  }

App.Utils.buildMapTg = (ticketGroupModel) ->
  return {
    tevo_section_name: ticketGroupModel.get('tevo_section_name')
    retail_price: ticketGroupModel.get('_priceWholesale')
  }

App.Utils.fileNameFromOrderItem = (orderItem) ->
  fileName = ''
  if (orderItem.get)
    date = orderItem.get('_ticketGroup')?.get('_event')?.get('_occursAt')
    name = orderItem.get('_ticketGroup')?.get('_event')?.get('_name')
    seats = orderItem.get('_seats')
  else
    date = orderItem.ticket_group.event.occurs_at
    name = orderItem.ticket_group.event.name
    seats = orderItem.seats

  if (date)
    fileName += moment(date).format(C.DateFormats.SortableUnderscore)
  if (name)
    escapedName = name.replace(/[^0-9a-z ]/i, '')
    fileName += '_' + escapedName.replace(/\s+/g,'_')
  if (seats)
    fileName += '_seats_' + seats.join('_')
  return fileName + '.pdf'

App.Utils.getEnvironment = () ->
  url = window.location.host

  if (url.match(/pos.lvh.me/i))
    return "Dev"
  else if (url.match(/localhost/i))
    return "Dev"
  else if (url.match(/staging/i))
    return "Staging"
  else if (url.match(/sandbox/i))
    return "Sandbox"
  else
    return "Production"


App.Utils.baseAffiliateLink = () ->
  env = App.Utils.getEnvironment().toLowerCase()
  brokerageConfig = C.AFFILIATE_SUB_DOMAINS[env][SESSION.brokerage_id]
  subdomain = 'shop'
  if (brokerageConfig && brokerageConfig.subdomain)
    subdomain = brokerageConfig.subdomain

  domain = if env != 'production' then "#{env}.events365.com" else 'events365.com'
  if (brokerageConfig && brokerageConfig.domain)
    domain = brokerageConfig.domain

  return "#{subdomain}.#{domain}/"

App.Utils.baseEvents365UrlLink = () ->
  env = App.Utils.getEnvironment().toLowerCase()

  return C.URLS[env]['events365_url']

App.Utils.affiliateLinkIds = () -> SESSION.brokerage_id + '-' + SESSION.office_id
App.Utils.affiliateLinkIdsHash = () -> encodeURIComponent(window.btoa(App.Utils.affiliateLinkIds()))

App.Utils.affiliateLinkIncludingUserIds = () -> SESSION.brokerage_id + '-' + SESSION.office_id + '-' + SESSION.user_id
App.Utils.affiliateLinkIncludingUserIdsHash = () -> window.btoa(App.Utils.affiliateLinkIncludingUserIds())


