App.Models.Base.V3BaseModel     = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.CreditMemo = App.Models.Base.V3BaseModel.extend

  urlRoot:  '/api_direct/v9/credit_memos'

  initialize: (attributes = {}, options = {}) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.CreditMemos.CreditMemo)
      attrs._balance = json.balance
      attrs._createdAt = Date.parse(json.created_at)
      attrs._createdBy = json.created_by
      attrs._creatingOrderId = json.creating_order_id
      attrs._holder = new App.Models.V3.Patron(json.holder, {
        mapping: C.Mappings.Direct.CreditMemos.Holder
      })
      attrs._id = json.id
      attrs._originalValue = json.original_value
      attrs._owner = new App.Models.V3.Patron(json.owner, {
        mapping: C.Mappings.Direct.CreditMemos.Owner
      })
      attrs._payments = json.payments
      attrs._pendingBalance = json.pending_balance
      attrs._remoteId = json.remote_id
      attrs._transactions = json.transactions
      attrs._updatedAt = Date.parse(json.updated_at)

    if (@getMapping() == C.Mappings.Direct.CreditMemo.CreditMemo)
      attrs._balance = json.balance
      attrs._createdAt = Date.parse(json.created_at)
      attrs._createdBy = json.created_by
      attrs._creatingOrderId = json.creating_order_id
      attrs._creatingOrderOId = json.creating_order_oid
      attrs._holder = new App.Models.V3.Patron(json.holder, {
        mapping: C.Mappings.Direct.CreditMemo.Holder
      })
      attrs._id = json.id
      attrs._originalValue = json.original_value
      attrs._owner = new App.Models.V3.Patron(json.owner, {
        mapping: C.Mappings.Direct.CreditMemo.Holder
      })
      attrs._payments = new App.Collections.V3.Payments(json.payments, {
        creditMemoId: json.id
        mapping: C.Mappings.Direct.CreditMemo.Payments
      })
      attrs._pendingBalance = json.pending_balance
      attrs._remoteId = json.remote_id
      attrs._transactions = new App.Collections.V3.Payments(json.transactions, {
        creditMemoId: json.id
        mapping: C.Mappings.Direct.CreditMemo.Transactions
      })
      attrs._updatedAt = Date.parse(json.updated_at)

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      balance: @get('_balance')
      createdAt: @get('_createdAt')
      createdBy: @get('_createdBy')
      creatingOrderId: @get('_creatingOrderId')
      creatingOrderOId: @get('_creatingOrderOId')
      holder: @get('_holder')?.toPresenterJSON()
      id: @get('_id')
      originalValue: @get('_originalValue')
      owner: @get('_owner')?.toPresenterJSON()
      payments: @get('_payments')
      pendingBalance: @get('_pendingBalance')
      remoteId: @get('_remoteId')
      transactions: @get('_transactions')
      updatedAt: @get('_updatedAt')

    })
    return presented
  #/////////////////////////////////////////////////////////////////////////////

  fetchCardsPromise: () ->
    deferred = Q.defer()
    $.ajax({
      type: 'GET'
      url:  "#{ @url() }/cards"
      success: (data, textStatus, jqXHR) =>
        deferred.resolve(data)
      error: (jqXHR, textStatus, errorThrown) =>
        deferred.reject(jqXHR)
    })
    return deferred.promise