App.Controllers.Base.BaseController = require('./base/base_controller.coffee')

module.exports = class App.Controllers.PaymentController extends App.Controllers.Base.BaseController

  _paymentOverviewCollection = null
  _paymentView = null
  _$container = null
  _xhr = null

  routeIndex: ($container, encodedFilterParams) ->
    @displayPaymentIndex({
      $container
      shouldReset: true
      encodedFilterParams
    })

  resetPaymentIndex: () ->
    App.router.navigate('/payment')
    @displayPaymentIndex({
      $container: _$container
      shouldReset: true
    })

  filterPaymentIndex: () ->
    encodedFilterParams = _paymentView.getEncodedFilterParams()
    App.router.navigate('/payment/filter/' + encodedFilterParams) # Don't trigger.
    @displayPaymentIndex({
      $container: _$container
      shouldReset: false
      encodedFilterParams
    })

  displayPaymentIndex: (options) ->
    {
      $container
      shouldReset
      encodedFilterParams
    } = options
    isGoingToFetch = !!encodedFilterParams # bool cast
    _$container = $container

    if (_xhr)
      _xhr.abort()

    if (!_paymentOverviewCollection || shouldReset)
      _paymentOverviewCollection = new App.Collections.V3.PaymentOverviews(null, {
        mapping: C.Mappings.Direct.PaymentOverviews
      })

    if (!_paymentView || shouldReset)
      _paymentView = new App.Views.Payments.List({
        $container: _$container
        collection: _paymentOverviewCollection
      })

    if (isGoingToFetch)
      params = App.Utils.decodeFilterParams(encodedFilterParams)
      _paymentView.reflectFilterParams(params)
      params = _paymentView.getFilterParams()
      _xhr = _paymentOverviewCollection.fetch({
        data: params
        reset: true
      })
      App.Utils.requestKiller.add(_xhr)
    else
      _paymentOverviewCollection.reset()
