template = require('./sub_order_item.ejs')
ticketsTemplate = require('./sub_order_item_tickets.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Order.SubOrderItem = App.Views.Base.BaseView.extend

  template: template

  ticketsTemplate: ticketsTemplate

  initialize: (options = {}) ->
    {
      @$container
      @orderItemModel
      @overviewView
      @poModel
    } = options
    @render()

  render: () ->
    @$container.html(
      @$el.html(
        @template()
      )
    )
    @formErrors = new App.Utils.FormErrorManager(@$('.formErrors'))
    @loadingBox = new App.Utils.LoadingBox(@$el)
    @delegateEvents()
    @fetchTickets()

  fetchTickets: () ->
    @loadingBox.startLoad()
    ticketGroup = @orderItemModel.get('_ticketGroup')
    eventId = ticketGroup.get('_event').id

    @v3TicketGroupsCollection = new App.Collections.V3.TicketGroups(null, {
      mapping: C.Mappings.TicketGroupEndpoint.TicketGroup
      eventId
      isTickets: true
      isAll: true
      isAvailable: true
      excludeOwned: true
      quantity: @orderItemModel.get('_quantity')
    })

    ticketGroup.get('_event').mapping = C.Mappings.Direct.Events.Event
    promises = [
      @v3TicketGroupsCollection.fetchPromise()
      ticketGroup.get('_event').fetchPromise()
    ]

    Q.all(promises)
    .then (collection) =>
      @renderTickets()
      @renderSeatingChart()
      @loadingBox.stopLoad()
    .fail (error) =>
      @loadingBox.stopLoad()
      @formErrors.show({
        title: 'Error fetching tickets.'
        errors: error
      })
    .done()

  renderTickets: () ->
    data = {
      ticketGroups: @v3TicketGroupsCollection.toPresenterJSON()
    }
    @$('#subTicketsContainer').html(
      @ticketsTemplate(data)
    )
    @$('span.tip-top').tooltip({ placement: 'top' })
    @v3TicketGroupsCollection.forEach((ticketGroupModel) ->
      $el = @$("tr[data-ticket-group-id=#{ ticketGroupModel.id  }] .popover_notes")
      App.Utils.notesPopover($el, ticketGroupModel)
    )
    @delegateEvents()

  renderSeatingChart: () ->
    @seatMap = new App.Views.Events.SeatMap({
      $container: @$('.seatingChartContainer')
      collection: @v3TicketGroupsCollection
      eventModel: @orderItemModel.get('_ticketGroup').get('_event')
      onSectionSelection: (selectedSections) =>
        @$('tr.ticketGroup').hide()
        ticketIds = @v3TicketGroupsCollection
          .filter((ticketGroup) => selectedSections.includes(ticketGroup.get('tevo_section_name')))
          .map((ticketGroup) => ticketGroup.id)
        if (ticketIds.length)
          ticketIds.forEach((ticketId) ->
            $tr = @$('tr.ticketGroup[data-ticket-group-id=' + ticketId + ']')
            $tr.show()
          )
        else
          @$('tr.ticketGroup').show()
    })

  events:
    'click .broker':            'showBrokerInfo'
    'mouseover tr.ticketGroup': 'onTicketGroupRowMouseover'
    'mouseout tr.ticketGroup':  'onTicketGroupRowMouseout'
    'click .subButton':         'onSubButtonClick'

  showBrokerInfo: (e) ->
    data = $(e.currentTarget).data()
    dialog = new App.Views.TicketGroups.BrokerDetails({
      officeId: data.officeId
    })
    e.preventDefault()
    e.stopPropagation()
    return false

  onTicketGroupRowMouseover: (e) ->
    $tr = $(e.currentTarget)
    id = $tr.data().ticketGroupId
    ticketGroup = @v3TicketGroupsCollection.get(id)
    @seatMap?.highlightSection(ticketGroup.get('tevo_section_name'))

  onTicketGroupRowMouseout: (e) ->
    $tr = $(e.currentTarget)
    id = $tr.data().ticketGroupId
    ticketGroup = @v3TicketGroupsCollection.get(id)
    @seatMap?.unhighlightSection(ticketGroup.get('tevo_section_name'))

  onSubButtonClick: (e) ->
    @loadingBox.startLoad()
    data = $(e.currentTarget).data()
    ticketGroup = @v3TicketGroupsCollection.get(data.ticketGroupId)
    quantity = @orderItemModel.get('_quantity')
    tgItem = {
      price: ticketGroup.get('_priceWholesale')
      quantity
      ticket_group_id: ticketGroup.id
    }
    subOrder = new App.Models.V3.Order(null, {
      mapping: C.Mappings.Direct.Order.Order
    })
    subOrder.purpose = C.Purposes.SubOrder
    attrs = {
      _additionalExpense: 0
      _buyerId: SESSION.office_id
      _sellerId: ticketGroup.get('_office').id
      _serviceFee:  0
      _shippedItems: [{
        items: [ tgItem ]
      }]
      _shipping: 0
      _substituteOrderLinkId: @poModel.id
      _tax: 0
    }
    subOrder.set(attrs)
    subOrder.savePromise()
    .then (model) =>
      @loadingBox.stopLoad()
      @overviewView.renderPOsPromise({ refetchChildIds: true })
      .then () =>
        @overviewView.showFeedback({
          type: 'success'
          title: 'Substitute order created successfully.'
        })
    .fail (error) =>
      @loadingBox.stopLoad()
      @formErrors.show({
        title: 'Error creating substitute order.'
        errors: error
      })
    .done()
