module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<%\nif (!_.size(credit_memos)) {\n%>\n<h5 style="width: 100%; text-align:center; line-height: 150px;">Please use the filter to find credit memos.</h5>\n<%\n} else {\n%>\n<table id="credit_memo_table" class="table table-bordered table-striped table-hover data-table">\n  <thead>\n    <tr>\n      <th>ID</th>\n      <th>Date</th>\n      <th>State</th>\n      <th>Customer</th>\n      <th>Total</th>\n      <th>Balance</th>\n      <th>Order #</th>\n    </tr>\n  </thead>\n  <tbody>\n<%\n  _.each(credit_memos, function(item, i) {\n%>\n    <tr>\n      <td><a href="/credit_memo/<%=item.id%>"><%= item.id %></a></td>\n      <td><%= App.Utils.makeTimestampHuman(item.createdAt, C.DateFormats.TableDateWithTime) %></td>\n      <td><%= (item.balance == 0) ? \'Closed\' : \'Open\'%></td>\n      <td><%= item.holder.name %></td>\n      <td><%= App.Utils.valueToCurrency(item.originalValue) %></td>\n      <td><%= App.Utils.valueToCurrency(item.balance) %></td>\n      <td><a href="/order/<%= item.creatingOrderId %>"><%= item.creatingOrderId %></a></td>\n    </tr>\n<%\n  });\n%>\n  </tbody>\n</table>\n<%\n}\n%>', __filename = "js/views/credit_memos/credit_memo_table.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            if (!_.size(credit_memos)) {
                __line = 3;
                __append('\n<h5 style="width: 100%; text-align:center; line-height: 150px;">Please use the filter to find credit memos.</h5>\n');
                __line = 5;
            } else {
                __line = 7;
                __append('\n<table id="credit_memo_table" class="table table-bordered table-striped table-hover data-table">\n  <thead>\n    <tr>\n      <th>ID</th>\n      <th>Date</th>\n      <th>State</th>\n      <th>Customer</th>\n      <th>Total</th>\n      <th>Balance</th>\n      <th>Order #</th>\n    </tr>\n  </thead>\n  <tbody>\n');
                __line = 21;
                _.each(credit_memos, function(item, i) {
                    __line = 23;
                    __append('\n    <tr>\n      <td><a href="/credit_memo/');
                    __line = 25;
                    __append(escapeFn(item.id));
                    __append('">');
                    __append(escapeFn(item.id));
                    __append("</a></td>\n      <td>");
                    __line = 26;
                    __append(escapeFn(App.Utils.makeTimestampHuman(item.createdAt, C.DateFormats.TableDateWithTime)));
                    __append("</td>\n      <td>");
                    __line = 27;
                    __append(escapeFn(item.balance == 0 ? "Closed" : "Open"));
                    __append("</td>\n      <td>");
                    __line = 28;
                    __append(escapeFn(item.holder.name));
                    __append("</td>\n      <td>");
                    __line = 29;
                    __append(escapeFn(App.Utils.valueToCurrency(item.originalValue)));
                    __append("</td>\n      <td>");
                    __line = 30;
                    __append(escapeFn(App.Utils.valueToCurrency(item.balance)));
                    __append('</td>\n      <td><a href="/order/');
                    __line = 31;
                    __append(escapeFn(item.creatingOrderId));
                    __append('">');
                    __append(escapeFn(item.creatingOrderId));
                    __append("</a></td>\n    </tr>\n");
                    __line = 33;
                });
                __line = 35;
                __append("\n  </tbody>\n</table>\n");
                __line = 38;
            }
            __line = 40;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}