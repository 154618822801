module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<td> Payment </td>\n<td class="payment_dropdown_wrapper">\n</td>\n<td><input class="amount" type="text"></td>\n<td>\n  <select class="paymentState">\n    <option>Pending</option>\n    <option>Completed</option>\n  </select>\n</td>\n<td class="paymentDetails credit_card">\n  <label></label>\n  <div class="details">\n    <div class="card_details"></div>\n    <div class="billing_address">\n    </div>\n  </div>\n</td>\n<td class="paymentDate"><%= App.Utils.makeTimestampHuman(new Date(), C.TableDateWithTime) %></td>\n<td>\n  <button class=\'createButton btn btn-primary\'><i class="fa-solid fa-floppy-disk"></i> Create</button>\n  <button class=\'cancelButton btn\'> Cancel</button>\n</td>\n', __filename = "js/views/credit_memos/new_payment.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<td> Payment </td>\n<td class="payment_dropdown_wrapper">\n</td>\n<td><input class="amount" type="text"></td>\n<td>\n  <select class="paymentState">\n    <option>Pending</option>\n    <option>Completed</option>\n  </select>\n</td>\n<td class="paymentDetails credit_card">\n  <label></label>\n  <div class="details">\n    <div class="card_details"></div>\n    <div class="billing_address">\n    </div>\n  </div>\n</td>\n<td class="paymentDate">');
            __line = 19;
            __append(escapeFn(App.Utils.makeTimestampHuman(new Date(), C.TableDateWithTime)));
            __append("</td>\n<td>\n  <button class='createButton btn btn-primary'><i class=\"fa-solid fa-floppy-disk\"></i> Create</button>\n  <button class='cancelButton btn'> Cancel</button>\n</td>\n");
            __line = 24;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}