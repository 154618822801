template = require('./list.ejs');
App.Views.Base.FilteredTable = require('../base/filtered_table_view.coffee');

module.exports = App.Views.EvoQuote.List = App.Views.Base.FilteredTable.extend({

  template: template,

  AUTOCOMPLETE_LIMIT: 30,

  initialize: function(options) {
    var options = options || {};
    this.collection = options.collection;
    this.$container = options.$container;
    this.listenTo(this.collection, 'reset', this.onCollectionReset);
    this.render();
  },

  render: function() {

    this.$el.html(this.template());
    this.$container.html(this.$el);
    this.delegateEvents();

    var $content = this.$('#filter_content');
    var $collapseButton = this.$('#filter_collapse_button');
    var options = { $content: $content, $collapseButton: $collapseButton };
    this.collapsibleFilters = new App.Utils.CollapsibleWidget(options);

    App.Utils.dateRangePicker(this.$('#quote_date_range'), this.onQuoteDateRangeChanged, this, C.DatePickerConfig.Past);
    App.Utils.dateRangePicker(this.$('#event_date_range'), this.onEventDateRangeChanged, this, C.DatePickerConfig.Past, true);
    this.buttonLoader = new App.Utils.LoadingButton(this.$('#apply_filter_button'));
    this.initializeAutocomplete();
    this.renderTable(true);
    this.startLoad();

    return this.$el;
  },

  renderTable: function(isInitialRender) {
    var that = this;

    if (this.table) {
      this.table.remove();
    }

    this.table = new App.Views.EvoQuote.QuoteTable({
      filterView: that,
      $container: this.$('#quote_table_container'),
      collection: this.collection
    });

    this.tableLoader = new App.Utils.LoadingBox(this.table.$el);
    if (!isInitialRender) {
      this.stopLoad();
    }
  },


  // VIEW EVENTS /////////////////////////////////////////////////////////////
  events: {
    'click #apply_filter_button': 'onApplyFilterButtonClick',
    'click #reset_filter_button': 'onResetFilterButtonClick',
    'click .context-button'     : 'showEvoQuote'
  },

  onResetFilterButtonClick: function() {
    App.Controllers.evoQuoteController.resetIndex();
  },

  onApplyFilterButtonClick: function() {
    this.buttonLoader.startLoad();
    this.tableLoader.startLoad();
    App.Controllers.evoQuoteController.filterIndex();
  },

  showEvoQuote: function(e) {
    var that = this,
        evoQuoteId = $(e.currentTarget).data('id');

    App.router.navigate('/evoquote/'+evoQuoteId, {trigger:true});
  },

  onQuoteDateRangeChanged: function(startDate, endDate) {
    this.quoteDateRange = { start: startDate, end: endDate };
  },

  onEventDateRangeChanged: function(startDate, endDate) {
    this.eventDateRange = { start: startDate, end: endDate };
  },
  ////////////////////////////////////////////////////////////////////////////

  // COLLECTION EVENTS ///////////////////////////////////////////////////////
  onCollectionReset: function() {
    this.renderTable();
  },
  ////////////////////////////////////////////////////////////////////////////

  filterParamMappings: function() {
    var that = this;
    return {
      created_at: {
        type: C.FilterTypes.DateRange,
        el: '#quote_date_range',
        val: this.quoteDateRange
      },
      occurs_at: {
        type: C.FilterTypes.DateRange,
        el: '#event_date_range',
        val: this.eventDateRange
      },
      event_name: {
        type: C.FilterTypes.Select2,
        el: '#event_autocomplete'
      },
      venue_name: {
        type: C.FilterTypes.Select2,
        el: '#venue_autocomplete'
      },
      prepared_for: {
        type: C.FilterTypes.Select2,
        el: '#prepared_for_autocomplete'
      },
      user_id: {
        type: C.FilterTypes.Select2,
        el: '#prepared_by_autocomplete',
        sendsId: true
      },
      client_id: {
        type: C.FilterTypes.Autocomplete,
        el: '#name_autocomplete',
        val: this.clientId,
        sendsId: true
      },
      id: {
        type: C.FilterTypes.Text,
        el: '#quote_id_text'
      },
      state: {
        type: C.FilterTypes.Select2,
        el: '#state_select',
        ignoreAll: true
      },
      email: {
        type: C.FilterTypes.Text,
        el: '#email_text'
      }
    };
  },

  startLoad: function() {
    this.tableLoader.startLoad();
    this.buttonLoader.startLoad();
  },

  stopLoad: function() {
    this.tableLoader.stopLoad();
    this.buttonLoader.stopLoad();
  },

  initializeAutocomplete: function() {
    var that = this;
    var callback = function(itemId) {
      that.clientId = itemId;
    };
    var $el = this.$('#name_autocomplete');
    var shouldReturnId = true;
    var options = {
      $el: $el,
      shouldReturnId: shouldReturnId,
      callback: callback
    };
    App.Utils.clientOfficeAutocomplete(options);
    App.Utils.eventAutocomplete(this.$('#event_autocomplete'));

    this.$("#venue_autocomplete").select2({
      placeholder: "Venue Name",
      minimumInputLength: 2,
      ajax: {
        url: "/api_direct/v9/evoquotes/autocomplete",
        dataType: 'json',
        data: function (term, page) {
          return {
            q: term,
            search_field: 'venues',
            limit: this.AUTOCOMPLETE_LIMIT
          };
        },
        results: function (jsonData, page) {
          // Select2 wants an array of objects with id & text.
          var results = [];
          _.each(jsonData, function(item) {
            results.push({
              text: item.value,
              id: item.value
            });
          });
          return {
            results: results
          };
        }
      },
      initSelection: function (element, callback) {
        var data = {
          id: element.val(),
          text: element.val()
        };
        callback(data);
      }
    });

    this.$("#prepared_for_autocomplete").select2({
      placeholder: "Name",
      minimumInputLength: 2,
      ajax: {
        url: "/api_direct/v9/evoquotes/autocomplete",
        dataType: 'json',
        data: function (term, page) {
          return {
            q: term,
            search_field: 'Name',
            limit: this.AUTOCOMPLETE_LIMIT
          };
        },
        results: function (jsonData, page) {
          // Select2 wants an array of objects with id & text.
          var results = [];
          _.each(jsonData, function(item) {
            results.push({
              text: item.value,
              id: item.value
            });
          });
          return {
            results: results
          };
        }
      },
      initSelection: function (element, callback) {
        var data = {
          id: element.val(),
          text: element.val()
        };
        callback(data);
      }
    });

    var setupPreparedBy = function(options) {

      $el = $("#prepared_by_autocomplete");
      var data = $el.select2('data'); // returns the $el if no data for whatever reason.
      $el.select2({
        placeholder: "Name",
        data: options
      });
      if (data != $el) {
         $el.select2('data', data);
      }

    };
    setupPreparedBy({});

    $.ajax({
      dataType: "json",
      url: '/api_direct/v9/users',
      data: { brokerage_id: SESSION.brokerage_id },
      success: function(data) {

        var selectOptions = _.map(data.users, function(el) {
          return {
            id: el.id,
            text: el.name
          };
        });
        setupPreparedBy(selectOptions);

      },
    });

    var states = C.Options.EvoQuote.States;
    states = _.map(states, function(key, val) {
      return { id: val, text: key };
    });
    this.$('#state_select').select2({
      data: states,
      initSelection: function (element, callback) {
        var data = {
          id: element.val(),
          text: C.Options.EvoQuote.States[element.val()]
        };
        callback(data);
      }
    });
    this.$('#state_select').select2('val', C.Pending);

  }

  /* Now driven by FilteredTable.
  Leave this here - it's a good reference.
  getFilterParams: function() {

    // Default params.
    var params = {
      state: 'pending'
    };

    var p = this.quoteDateRange;
    if (p && p.start && p.end) {
      params['created_at.gte'] = p.start.toDate().toISOString();
      params['created_at.lte'] = p.end.toDate().toISOString();
    }

    var p = this.eventDateRange;
    if (p && p.start && p.end) {
      params['occurs_at.gte'] = p.start.toDate().toISOString();
      params['occurs_at.lte'] = p.end.toDate().toISOString();
    }

    var p = this.$('#event_autocomplete').val();
    if (p) {
      params['event_name'] = p;
    }

    var p = this.$('#venue_autocomplete').val();
    if (p) {
      params['venue_name'] = p;
    }

    var p = this.$('#prepared_for_autocomplete').val();
    if (p) {
      params['prepared_for'] = p;
    }

    var p = this.$('#prepared_by_autocomplete').val();
    if (p) {
      params['user_id'] = p;
    }

    var p = this.$('#name_autocomplete').val();
    if (p && this.clientId) {
      params['client_id'] = this.clientId;
    }

    var p = this.$('#quote_id_text').val();
    if (p) {
      params['id'] = p;
    }

    var p = this.$('#state_select').val();
    if (p) {
      if (p == 'all') {
        delete params.state;
      } else {
        params['state'] = p;
      }
    }

    var p = this.$('#email_text').val();
    if (p) {
      params['email'] = p;
    }

    this.priorFilterParams = params;
    return params;
  },*/

});