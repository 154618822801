module.exports = App.Views.Base.BaseView = Backbone.View.extend

  handleE: (e) ->
    if (e)
      e.preventDefault()
      e.stopImmediatePropagation()
      if ($(e.target).attr("disabled"))
        return false
      else
        return true

    # http://lostechies.com/derickbailey/2011/09/15/zombies-run-managing-page-transitions-in-backbone-apps/
  # really close
  #   => prevents event mismash
  #   => enables garbage collection (prevents leaks)
  #   *** ControllerExample = ->
  #         @view.close() if @view
  #         @view = new App.View()
  close: ->
    vent.unbind() for vent in @globalEvents || []
    @remove()
    @unbind()
    #  write custom cleanup in your view
    @onClose() if @onClose

  renderValidations: (model, errors)->
    errors ||= model.validationError
    _.each errors, (error) =>
      $el      = @find_error_input(error)
      cid      = model.cid || -1
      content  = "<div class='#{cid}-validation alert alert-danger validation-error'><span class='fa-solid fa-triangle-exclamation' style='margin-right:10px'></span>#{error.errors.join(', ')}</div>"
      content = @link_validations($el, content)
      $el.addClass("invalid")
      $el.closest(".errors-parent").find(".errors").append(content) #append error to dom

  clearValidations: (model) ->
    @$(".#{model.cid}-validation").remove()
    @$(".invalid").removeClass("invalid")

  find_error_input: (error) ->
    $el = @$el

    if error.cid and error.nested_attr
      selector = "[name=#{error.attr}][data-nested-name=#{error.nested_attr}]"
      $el      = @$("[data-cid=#{error.cid}]") if @$("[data-cid=#{error.cid}]").length
    else if error.cid
      selector = "[name=#{error.attr}]"
      $el      = @$("[data-cid=#{error.cid}]") if @$("[data-cid=#{error.cid}]").length
    else
      selector = "[name=#{error.attr}]" or "[data-nested-name=#{error.attr}]"

    #standard finders
    (result = $el.find(selector)).length or (result = $el.find("[name=#{error.attr}]")).length or (result = $el.find("[data-nested-name=#{error.attr}]"))
    #select2 switcharoo
    result = $el.find("#s2id_#{error.attr}") if result.is("select") and $el.find("#s2id_#{error.attr}").length

    result

  link_validations: ($el, content) ->
    id = new Date().getTime()
    $el.attr("validation-id-target", id)
    $(content).attr("validation-id-ref",    id).click( -> $el.focus())[0]

  #
  #dom convention helpers
  #

  #
  #argument stuff
  #

  required_args: (attributes, args) ->
    return_code = 1
    _.each(attributes, (attr) =>
      if args && args[attr]
        this[attr] = args[attr]
      else
        console.log("Warning Function Requires parameter #{attr} and no value was provided as an argument")
        return_code = 0
    )
    return return_code

  optional_args: (attributes, args) ->
    _.each attributes, (attr) =>
      if args && args[attr]
        this[attr] = args[attr]

  describe_args: (attributes, args) ->
    return_code = 1
    _.each(attributes, (attr) =>
      if (args && args[attr])
        this[attr] = args[attr]
      else
        console.log("Warning Function Requires parameter #{attr} and no value was provided as an argument")
        return_code = 0
    )
    return return_code

  #
  #  misc
  #

  is_premium: ->
    SESSION.role.can(App.Access.Resources.Orders.Payments)

  download: (options) ->
    {fileUrl, callback} = options
    el = $("<iframe id='proxy' style='display:none'></iframe>")
    .appendTo("body")
    #    .onload ->
    #      _.bind(callback, @)
    #      el.remove()
    .attr('src', fileUrl)

  super: (f, options) -> @constructor.__super__[f].apply(@, options)
