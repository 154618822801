App.Controllers.Base.BaseController = require('./base/base_controller.coffee')

module.exports = class App.Controllers.OrderController extends App.Controllers.Base.BaseController

  # STATIC VARS ////////////////////////////////////////////////////////////////

  _$container = null
  _model = null
  _view = null
  _xhr = null

  # STATICS ////////////////////////////////////////////////////////////////////

  @initiateWasted: (ticketGroupModel, callback) ->
    wastedForm = new App.Views.TicketGroups.WastedView({
      ticketGroupModel,
      callback
    })
    wastedForm.render()

  @displayIndex: (options) ->
    {
      $container
      shouldReset
      ordersOrPOs
      encodedFilterParams
    } = options
    shouldReset ||= false
    isGoingToFetch = !!encodedFilterParams # bool cast
    _$container = $container

    if (_xhr)
      _xhr.abort()

    if (!_model || shouldReset)
      _model = new App.Models.V3.OrderCollectionWrapper(null, {
        ordersOrPOs
      })

    if (!_view || shouldReset)
      if (_view)
        _view.remove()

      _view = new App.Views.Orders.OrdersList({
        $container: _$container
        model: _model
        ordersOrPOs
        isGoingToFetch
      })

    if (isGoingToFetch)
      params = App.Utils.decodeFilterParams(encodedFilterParams)
      _view.reflectFilterParams(params)
      params = _view.getFilterParams()
      _xhr = _model.fetch({
        data: params
      })
      App.Utils.requestKiller.add(_xhr)

  @routeIndex: ($container, ordersOrPOs, encodedFilterParams) ->
    App.Controllers.OrderController.displayIndex({
      $container
      shouldReset: true
      ordersOrPOs
      encodedFilterParams
    })

  @resetIndex: (ordersOrPOs) ->
    route = if (ordersOrPOs == 'orders') then '/order' else '/pos'
    App.router.navigate(route) # Don't trigger.
    App.Controllers.OrderController.displayIndex({
      $container: _$container
      shouldReset: true
      ordersOrPOs
    })

  @filterIndex: (ordersOrPOs) ->
    encodedFilterParams = _view.getEncodedFilterParams()
    route = if (ordersOrPOs == 'orders') then '/order' else '/pos'
    route += '/filter/' + encodedFilterParams
    App.router.navigate(route) # Don't trigger.
    App.Controllers.OrderController.displayIndex({
      $container: _$container
      shouldReset: false
      ordersOrPOs
      encodedFilterParams
    })

  # END STATICS ////////////////////////////////////////////////////////////////

  constructor: () ->
    super() # Inherit Backbone.Events functionality from BaseController.

    # PUBLIC VARS //////////////////////////////////////////////////////////////
    @loadingOverlay = new App.Views.Shared.LoadingView()