App.Collections.Base.BaseCollection = require('../base/paginated_base_collection.coffee')
App.Models.V3.CreditMemo = require('../../models/v3/credit_memo_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V3.CreditMemos = App.Collections.Base.BaseCollection.extend

  model: App.Models.V3.CreditMemo

  url: '/api_direct/v9/credit_memos'

  initialize: (models, options = {}) ->
    {
      @parentWrapper
    } = options
    parent.initialize.call(@, models, options)

  parse: (response, options) ->
    modelFields = _.pick(response, 'balance_sum', 'current_page', 'per_page', 'total_entries', 'total_sum')
    @parentWrapper.set(modelFields)
    json = response.credit_memos
    return json