App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V3.Payment = require('../../models/v3/payment_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V3.Payments = App.Collections.Base.BaseCollection.extend

  model: App.Models.V3.Payment

  initialize: (models, options) ->
    {
      @creditMemoId
    } = options
    parent.initialize.call(@, models, options)