template = require('./totals.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Order.Totals = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    {
      @$container
      @v3OrderModel
    } = options
    @render()

  render: () ->
    data = {
      order: @v3OrderModel.toPresenterJSON()
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )
    @delegateEvents()