App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.Venue = App.Models.Base.V3BaseModel.extend

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if(@getMapping() == C.Mappings.Direct.EventPins.Venue)
      attrs._countryCode = json.country_code
      attrs._id = json.id
      attrs._location = json.location
      attrs._name = json.name
      attrs._streetAddress = json.street_address
      attrs._url = json.url

    if(@getMapping() == C.Mappings.Direct.FilteredTickets.Venue)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping: C.Mappings.Direct.FilteredTickets.Address
      })
      attrs._name = json.name
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.EvoQuotes.Venue)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping: C.Mappings.Direct.EvoQuotes.Address
      })
      attrs._id = json.id
      attrs._name = json.name
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.OrderItems.Venue)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping: C.Mappings.Direct.OrderItems.Address
      })
      attrs._name = json.name
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Order.SubstitutionVenue)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping: C.Mappings.Direct.Order.SubstitutionVenueAddress
      })
      attrs._id = json.id
      attrs._name = json.name
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Order.Venue)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping: C.Mappings.Direct.Order.VenueAddress
      })
      attrs._id = json.id
      attrs._name = json.name
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.OrdersLightweight.Venue)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping: C.Mappings.Direct.OrdersLightweight.VenueAddress
      })
      attrs._id = json.id
      attrs._name = json.name
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Carts.Venue)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping: C.Mappings.Direct.Carts.Address
      })
      attrs._id = json.id
      attrs._name = json.name

    if (@getMapping() == C.Mappings.Direct.Orders.Venue)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping: C.Mappings.Direct.Orders.VenueAddress
      })
      attrs._name = json.name
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Events.Venue)
      attrs._id = json.id
      attrs._location = json.location
      attrs._name = json.name
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.EventEndpoint.Venue)
      attrs._id = json.id
      attrs._location = json.location
      attrs._name = json.name
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.OrderItemsByEvent.Venue)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping: C.Mappings.Direct.OrderItemsByEvent.Address
      })
      attrs._name = json.name
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      address: @get('_address')?.toPresenterJSON?()
      name: @get('_name')
      location: @location()

    })
    return presented

  location: () ->
    if (@get('_location'))
      return @get('_location')
    else if (@get('_address'))
      return @get('_address').cityState()
    return 'Location Unknown'
  #///////////////////////////////////////////////////////////////////////////