App.Controllers.Base.BaseController = require('./base/base_controller.coffee')

module.exports = class App.Controllers.ShipmentController extends App.Controllers.Base.BaseController

  _shipmentsCollection = null
  _shipmentsListView = null
  _$container = null

  routeIndex: ($container, encodedFilterParams) ->
    @displayIndex({
      $container
      shouldReset: true
      encodedFilterParams
    })

  displayIndex: (options) ->
    {
    $container
    shouldReset
    encodedFilterParams
    } = options
    _$container = $container

    if (_xhr)
      _xhr.abort()

    if (!_shipmentsCollection || shouldReset)
      _shipmentsCollection = new App.Collections.V3.Shipments(null, {
        mapping : C.Mappings.Direct.ShipmentsStatus.ShipmentsInfo
        params  :
          type      : 'Delivery::FedEx'
          info_only : 'true'
        purpose : C.Purposes.FetchOnlyShipmentInfo
      })

    if (!_shipmentsListView || shouldReset)
      _shipmentsListView = new App.Views.Shipping.List({
        $container: _$container
        collection: _shipmentsCollection
      })

    if (encodedFilterParams)
      params = App.Utils.decodeFilterParams(encodedFilterParams)
      _shipmentsListView.reflectFilterParams(params)

    params = _shipmentsListView.getFilterParams()

    # View is bound to collection reset event.
    _xhr = _shipmentsCollection.fetch({
      data: params
      reset: true
    })
    App.Utils.requestKiller.add(_xhr)

  resetIndex: () ->
    App.router.navigate('/shipment') # Don't trigger.
    @displayIndex({
      $container: _$container
      shouldReset: true
    })

  filterIndex: () ->
    encodedFilterParams = _shipmentsListView.getEncodedFilterParams()
    App.router.navigate('/shipment/filter/' + encodedFilterParams) # Don't trigger
    @displayIndex({
      $container: _$container
      shouldReset: false
      encodedFilterParams
    })

  routeShipmentNew: ($container) ->
    App.router.navigate('/shipment/new') # Don't trigger.
    if (!SESSION.office)
      senderOfficeModel = new App.Models.V3.Office({
        id: SESSION.office_id
      }, {
        mapping: C.Mappings.Direct.Offices.Office
      })
    else
      senderOfficeModel = SESSION.office
      newView = new App.Views.Shipping.New ({
        $container
        senderOfficeModel
      })

    senderOfficeModel.fetch()
      .then (model) ->
        newView = new App.Views.Shipping.New ({
          $container
          senderOfficeModel
        })
      .done()
