App.Models.Base.BaseModel = require('../base/base_model.coffee')

parent = App.Models.Base.BaseModel.prototype
module.exports = App.Models.V2.Brokerage = App.Models.Base.BaseModel.extend({

  updateStandardAttributes: () ->
    json = @attributes
    attrs = {}

    if (@getMapping() == C.Mappings.SingleTicketGroupEndpoint.Brokerage)
      attrs._abbreviation = json.abbreviation
      attrs._id = json.id
      attrs._name = json.name
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.OrderEndpoint.Brokerage)
      attrs._abbreviation = json.abbreviation
      attrs._email = json.email
      attrs._id = json.id
      attrs._name = json.name
      attrs._url = json.url

    if (@getMapping() == C.Mappings.V2OrderEndpoint.Brokerage)
      attrs._abbreviation = json.abbreviation
      attrs._createdAt = Date.parse(json.created_at)
      attrs._email = json.email
      attrs._id = json.id
      attrs._isMaster = json.master
      attrs._isNATBMember = json.natb_member
      attrs._name = json.name
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.TicketGroupEndpoint.Brokerage)
      attrs._abbreviation = json.abbreviation
      attrs._id = json.id
      attrs._name = json.name
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      abbreviation: @get('_abbreviation')
      email:        @get('_email')
      id:           @get('_id')
      isMaster:     @get('_isMaster')
      name:         @get('_name') || @get('name') # Hack fix for BuySell not showing brokerage.
      url:          @get('_url')

    })
    return presented
  #///////////////////////////////////////////////////////////////////////////

}, {
# STATICS
  defaultMapping: C.Mappings.TicketGroupEndpoint.Brokerage
})