template = require('./delivery_table.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Delivery.DeliveryTable = App.Views.Base.BaseView.extend

  template: template

  dataTableOptions: {
    bJQueryUI: true
    sDom: '<"">t<"F"fp>'
    bScrollInfinite: true
    bScrollCollapse: false
    iDisplayLength: 1000
    sScrollY: '700px'
    aaSorting: [
      [ 5, 'asc']
    ]
    aoColumns: [
      null
      null
      null
      null
      null
      { sType: 'date' }
      null
      { sType: 'date' }
    ]
  }

  initialize: (options = {}) ->
    {
      @collection
      @$container
      @filterView
    } = options
    @render()

  render: () ->
    data = {
      deliveries: @collection.toPresenterJSON()
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )
    @endlessDataTable = new App.Utils.EndlessDataTable({
      @collection
      @filterView
      @dataTableOptions
    })
    return @$el
