template = require('./loading.ejs');

module.exports = App.Views.Shared.LoadingView = Backbone.View.extend({

  className: 'loading',

  template: template,

  render: function() {
    this.$el.html(this.template());

    var $body = $('body');
    var w = $body.width();
    var hBody = $body.height();
    var hWindow = $(window).height();
    var h = Math.max(hBody, hWindow);

    this.$el.css({
      top: '0px',
      left: '0px',
      width: w + 'px',
      height: h + 'px'
    })

    $('body').append(this.$el);
  }

});