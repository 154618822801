template = require('./edit_one.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.TicketGroups.EditOne = App.Views.Base.BaseView.extend

  template: template

  className: "modal edit_ticket_group_dialog"

  initialize: (options = {}) ->
    @listenTo(App.Vent, 'edit-ticket-groups:close', _.bind(@remove, @))
    {
      @eventModel
      @model       # v1 - to be removed.
      @ticketGroupModel # v3
    } = options
    @availableOffices = @filterOffices(options.availableOffices);

  events:
    "click input#customSplitsCheckbox": "onCustomSplitsCheckboxClick"
    "click #in_hand":                   "toggleInHandOn"
    "click #saveTicketEditsButton":     "onSaveTicketEditsButtonClick"
    'change #format':           'onFormatDropdownChange'
    'change #ticketGroupSeatOrder':     'onTicketGroupSeatsChange'
    'keyup  #ticketGroupLowSeat':       'onTicketGroupSeatsChange'
    'change #in_hand_days':             'onInHandDaysChange'

  onTicketGroupSeatsChange: (e) ->
    seatOrder = @$('#ticketGroupSeatOrder').select2('val')
    lowSeat = parseInt(@$('#ticketGroupLowSeat').val())
    if (!isNaN(lowSeat))
      quantity = @model.get('quantity')
      multiplier = 1
      if (seatOrder == C.SeatOrder.OddEven)
        multiplier = 2
      highSeat = lowSeat + (quantity - 1) * multiplier
      @$('#ticketGroupHighSeat').val(highSeat)

  render: () ->
    template_params = {
      ticketGroup: @model
      availableOffices: @availableOffices
    }
    @$el.html(
      @template(template_params)
    )
    @$("select").select2()
    @$(".currency").formatCurrency()
    @$('.actLikeCurrency').formatCurrency()
    @$el.modal('show')
    @$('.modal-body').css({ maxHeight: 'none' })
    @$('.notes').tabbedTextareas()

    datePickerConfig = C.DatePickerConfig.SingleDatePicker
    startDate = ''

    if (@model.get('in_hand_on'))
      datePickerConfig.startDate = moment(@model.get('in_hand_on'))
      startDate = moment(@model.get('in_hand_on')).format('MM/DD/YYYY')
      @$('#in_hand_days').select2('val', null)


    App.Utils.dateRangePicker(@$('#in_hand_on'), @onDateChanged, this, datePickerConfig)
    @$('#in_hand_on').find('#date').text(startDate)

    @retailInput = new App.Utils.RetailInput({
      $container: @$('#retailContainer')
      inputName: "retail_price_override"
      retailPriceOverride: @model.get("retail_price_override")
      retailPrice: @model.get("retail_price")
    })

    @exchangeExcluder = new App.Utils.ExchangeDropdown({
      $el: @$('#excludedExchanges')
    })
    @exchangeExcluder.val(@model.get('excluded_exchanges'))
    @stubhubDropdown = new App.Utils.StubhubTraitsDropdown({
      $el: @$('#stubhubTraitsDropdown')
      selected: @model.get('ticket_traits')
      stubhubEventId: @eventModel.get('_stubhubId')
    })

    @onFormatDropdownChange()

  onSaveTicketEditsButtonClick: (e) ->
    skipEmpty = false
    inHandDateIsBeforeEventDt     = true
    formData                      = @$('form').serializeObject(skipEmpty)
    model                         = @model
    wheelchair                    = formData.wheelchair || 0
    wholesale                     = formData.wholesale_price || 0
    wholesale_has_dollar_sign     = wholesale && wholesale.indexOf("$") != -1

    formData.retail_price_override = @retailInput.getVal()

    # Changed purpose so the edit update goes to /ticket_groups instead of /listings
    # See client/js/models/ticket_group_model.js for more info
    @model.purpose = C.Purposes.UpdateTicketGroup

    # Manually keeping these fields in sync is super dumb.
    # #FIXTHEAPI!
    formData.eticket = !!(@$('#format').val() == C.TicketFormats.Eticket)

    if (formData.intended_for_instant_delivery)
      intended_for_instant_delivery = 't'
    else
      intended_for_instant_delivery = 'f'


    in_hand = if @$('#in_hand').is(':checked') then 1 else 0

    if (!formData.customSplitsCheckbox)
      delete formData.split_override

    if (wheelchair)
      wheelchair = 1

    if (in_hand)
      delete formData.in_hand_on
    else
      if (@$('#in_hand_days').select2('val') == null && @inHandDate)
        formData.in_hand_on = @inHandDate.format('YYYY-MM-DD')
        eventOccursAt = moment(model.get('event').occurs_at).utc()
        inHandOnDt = @inHandDate.utc().startOf("day")
        if (inHandOnDt > eventOccursAt)
          inHandDateIsBeforeEventDt = false

      else if (@$('#in_hand_days').select2('val') != null)
        formData.in_hand_on = moment(model.get('event').occurs_at).subtract(@$('#in_hand_days').select2('val'), 'day').format('YYYY-MM-DD')

    if (wholesale_has_dollar_sign)
      wholesale = wholesale.replace("$", '')
    if (wholesale)
       formData.wholesale_price = wholesale

    formData.in_hand = in_hand
    formData.wheelchair = wheelchair
    formData.intended_for_instant_delivery = intended_for_instant_delivery

    formData.excluded_exchanges = @exchangeExcluder.val() || []

    formData.stubhub_traits = @stubhubDropdown?.val() || []

    model.set(formData)
    ready_to_save = (model.changed && model.isValidEditOne())
    if (!inHandDateIsBeforeEventDt)
      model.errors.push("The in hand date cannot be after the event date.")
      ready_to_save = false
    if (ready_to_save)
      @loadingView = new App.Views.Shared.LoadingView()
      @loadingView.render()
      @$el.addClass('saving')
      model.save({}, {
        success: _.bind(@saveSuccess, @)
        error: _.bind(@saveError, @)
      })

    else if (model.changed)
      errors_element = @$('.errors')
      errors_element.html(model.errors.join('. '))

  saveError: () ->
    @loadingView.remove()
    @$el.removeClass('saving')
    @$el.addClass('error')
    @$el.removeClass('changed')

  saveSuccess: () ->
    @loadingView.remove()
    @$('.errors').html('')
    @model.changed = false
    @$el.modal('hide')
    App.Vent.trigger('ticket-edit:completed', @model)

  onCustomSplitsCheckboxClick: (e) ->
    $customSplitsCheckbox = @$('#customSplitsCheckbox')
    $splitOverride = @$('#split_override')
    if ($customSplitsCheckbox.is(':checked'))
      $splitOverride.show()
    else
      $splitOverride.hide()

  toggleInHandOn: (e) ->
    $inHand = @$('#in_hand')
    $inHandOn = @$('#in_hand_on')
    $inHandDays = @$('#in_hand_days_select')
    if ($inHand.is(':checked'))
      $inHandOn.hide()
      $inHandDays.hide()
    else
      $inHandOn.show()
      $inHandDays.show()

  filterOffices: (offices) ->
    if (!offices || !offices.length)
      return []
    filtered = offices.filter((office) =>
      return +(office.id) == +(SESSION.office_id)
    )
    return filtered

  onFormatDropdownChange: (e) ->
    val = @$('#format').val()
    if (val == C.TicketFormats.Eticket)
      @$('#instantDeliveryRow').show()
    else
      @$('#instantDeliveryRow').hide()


  onDateChanged: (startDate, endDate) ->
    @inHandDate = startDate
    @$('#in_hand_days').select2('val', null)

    if (_.isString(startDate))
      @dateRangeFilter = {
        start: startDate
        end: endDate
      }
    else
      @dateRangeFilter = {
        start: startDate.toDate().toISOString()
        end: endDate.toDate().toISOString()
      }

  onInHandDaysChange: (e) ->
    if (e.currentTarget.value != '')
      @$('#in_hand_on').find('#date').text('')