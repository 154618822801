App.Models.Base.BaseModel = require('./base/base_model.coffee')

module.exports = App.Models.InventoryUpload = App.Models.Base.BaseModel.extend

  urlRoot: "/api_direct/v9/ticket_groups/upload_history"

  toPresenterJSON: ->
    created_at = App.Utils.makeTimestampHuman(@get('created_at'), C.DateFormats.TableDateWithTime)
    status     = C.Options.Inventory.UploadStatus[@get 'status']
    _.extend @super('toPresenterJSON'), {created_at, status}
