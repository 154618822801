template = require('./seating_chart.ejs')

module.exports = App.Views.TicketGroups.SeatingChart = Backbone.View.extend

  template: template

  initialize: (options = {}) ->
    {
      @event
    } = options
    @render()

  render: () ->
    data = {
      event: @event.toPresenterJSON()
    }
    @$el.html(
      @template(data)
    )
    @$el.dialog
      width: 600
      title: "Seating for #{ @event.get('_venue').get('_name') }"
      closeOnEscape: true
      close: -> $(@).dialog('destroy')
    @$el.parent().addClass('specialDialog')