module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<tr class="custom-row" data-custom-count="<%= ($(\'.custom-row\').size() + 1) %>">\n  <td><%= brokerage.get(\'name\') %></td>\n  <td><input style="width: 90%;" id="custom-section" type="text"></td>\n  <td><input style="width: 90%;" id="custom-row" type="text"></td>\n  <td>N/A</td>\n  <td>N/A</td>\n  <td>\n    <div style="width: 90%;" class="input-prepend">\n      <span class="add-on">$</span>\n      <input id="individual-price" type="text" value="5.00">\n    </div>\n  <td><input style="width: 90%;" id="quantity" type="text"></td>\n  <td><input style="width: 90%;" type="checkbox" class="showNotesCheckbox" type="text"></td>\n  <td>\n    <a class="btn btn-danger" id="remove-item"><i class="fa-solid fa-trash"></i></a>\n  </td>\n</tr>\n', __filename = "js/views/evoquote/custom_ticket_group.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<tr class="custom-row" data-custom-count="');
            __append(escapeFn($(".custom-row").size() + 1));
            __append('">\n  <td>');
            __line = 2;
            __append(escapeFn(brokerage.get("name")));
            __append('</td>\n  <td><input style="width: 90%;" id="custom-section" type="text"></td>\n  <td><input style="width: 90%;" id="custom-row" type="text"></td>\n  <td>N/A</td>\n  <td>N/A</td>\n  <td>\n    <div style="width: 90%;" class="input-prepend">\n      <span class="add-on">$</span>\n      <input id="individual-price" type="text" value="5.00">\n    </div>\n  <td><input style="width: 90%;" id="quantity" type="text"></td>\n  <td><input style="width: 90%;" type="checkbox" class="showNotesCheckbox" type="text"></td>\n  <td>\n    <a class="btn btn-danger" id="remove-item"><i class="fa-solid fa-trash"></i></a>\n  </td>\n</tr>\n');
            __line = 18;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}