template = require('./orders.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Clients.Orders = App.Views.Base.BaseView.extend

  template: template

  dataTableOptions: {
    bJQueryUI: true
    sDom: '<"F"fp>t'
    bScrollInfinite: true,
    bScrollCollapse: false
    iDisplayLength: 1000
    sScrollY: '700px'
    aaSorting: [
      [ 0, 'desc']
    ]
    oLanguage: {
      sSearch: 'Filter Results: ',
      sEmptyTable: 'Please select any of the available filter criteria in order to view your orders'
    }
    aoColumns: [
      null
      null
      null
      { sType: 'date' }
      null
      null
      null
      null
      null
      { sType: 'date' }
      null
      null
    ]
  }

  initialize: (options) ->
    {
      @$container
      @type
      @v3OrdersCollection
    } = options
    @render(true)

  render: (isInitialRender = false) ->
    data = {
      orders: @v3OrdersCollection.toPresenterJSON()
      totals: @calcTotals()
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )

    @endlessDataTable = new App.Utils.EndlessDataTable({
      filterView: @
      collection: @v3OrdersCollection
      @dataTableOptions
    })

    if (isInitialRender)
      @loadingBox = new App.Utils.LoadingBox(@$el)
      @loadingBox.startLoad()
    else
      @loadingBox.stopLoad()

    @delegateEvents()

  calcTotals: () ->
    count30 = 0
    count365 = 0
    total30 = 0
    total365 = 0
    cutoff30 = moment().add('days', -30).toDate()
    cutoff365 = moment().add('days', -365).toDate()

    @v3OrdersCollection.sort()
    for orderModel in @v3OrdersCollection.models
      orderDate = orderModel.get('_createdAt')
      orderTotal = parseFloat(orderModel.get('_total'))
      if (orderDate > cutoff365)
        count365++
        total365 += orderTotal
        if (orderDate > cutoff30)
          count30++
          total30 += orderTotal

    return {
      count30
      count365
      total30
      total365
    }
