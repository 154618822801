module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<a class="dropdown-toggle" data-target="#menu-dropdown-user" data-toggle="dropdown">\n  <span id="user_name"><%= SESSION.user_name %></span>\n  <i class="fa-solid fa-user"></i>\n  <i class="fa-solid fa-caret-down"></i>\n</a>\n<ul class="dropdown-menu" id="user_menu" role="menu">\n\n  <% if (SESSION.role.can(App.Access.Resources.Settings)) { %>\n  <li>\n    <a href="http://settings.ticketevolution.com/" target="_blank">\n      <i class="fa-solid fa-cog"></i>\n      <span>Settings</span>\n    </a>\n  </li>\n  <% } %>\n\n  <li>\n    <a data-bypass="1" href="/account">\n      <i class="fa-solid fa-user"></i>\n      <span>My Account</span>\n    </a>\n  </li>\n  <li>\n    <a data-bypass="1" href="/logout">\n      <i class="fa-solid fa-reply"></i>\n      <span>Log Out</span>\n    </a>\n  </li>\n</ul>\n', __filename = "js/views/topnav/user_nav.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<a class="dropdown-toggle" data-target="#menu-dropdown-user" data-toggle="dropdown">\n  <span id="user_name">');
            __line = 2;
            __append(escapeFn(SESSION.user_name));
            __append('</span>\n  <i class="fa-solid fa-user"></i>\n  <i class="fa-solid fa-caret-down"></i>\n</a>\n<ul class="dropdown-menu" id="user_menu" role="menu">\n\n  ');
            __line = 8;
            if (SESSION.role.can(App.Access.Resources.Settings)) {
                __append('\n  <li>\n    <a href="http://settings.ticketevolution.com/" target="_blank">\n      <i class="fa-solid fa-cog"></i>\n      <span>Settings</span>\n    </a>\n  </li>\n  ');
                __line = 15;
            }
            __append('\n\n  <li>\n    <a data-bypass="1" href="/account">\n      <i class="fa-solid fa-user"></i>\n      <span>My Account</span>\n    </a>\n  </li>\n  <li>\n    <a data-bypass="1" href="/logout">\n      <i class="fa-solid fa-reply"></i>\n      <span>Log Out</span>\n    </a>\n  </li>\n</ul>\n');
            __line = 30;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}