template = require('./credit_memo_cards.ejs');
App.Views.Base.BaseView = require('../base/base_view.coffee');

module.exports = App.Views.CreditCards.CreditMemoCards = App.Views.Base.BaseView.extend({

  existingCardTemplate: template,

  initialize: function(opts) {
    this.required_args(['cards'], opts);
    var that          = this;
  },

  render: function() {
    this.$el.html(this.existingCardTemplate({credit_cards: this.cards}))
    return this.$el;
  }
});

