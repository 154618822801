module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<select id="creditCards"> \n    <% _.each(credit_cards, function(credit_card) { %>\n      <option data-credit-card-id="<%=credit_card.transaction.credit_card_transaction.id %>"><%= credit_card.card_company %> x<%= credit_card.last_digits %> (<%= credit_card.expiration_month%>/<%= credit_card.expiration_year %>)</option>\n    <% }); %>\n</select>\n', __filename = "js/views/credit_cards/credit_memo_cards.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<select id="creditCards"> \n    ');
            __line = 2;
            _.each(credit_cards, function(credit_card) {
                __append('\n      <option data-credit-card-id="');
                __line = 3;
                __append(escapeFn(credit_card.transaction.credit_card_transaction.id));
                __append('">');
                __append(escapeFn(credit_card.card_company));
                __append(" x");
                __append(escapeFn(credit_card.last_digits));
                __append(" (");
                __append(escapeFn(credit_card.expiration_month));
                __append("/");
                __append(escapeFn(credit_card.expiration_year));
                __append(")</option>\n    ");
                __line = 4;
            });
            __append("\n</select>\n");
            __line = 6;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}