template = require('./new_po.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

PAGE = 100
SELECT_AN_EVENT = 1
NO_EVENTS_FOUND = 2
HIT_LIMIT = 3
RESULT_LIMIT = 500

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.PO.New = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    {
      @$container
      @isSpec
      @isConsignment
    } = options

    @existingViewsByEventId = {}
    @_eventsBeingPurchased = null
    @eventSearchResultsViews = []
    @resultCount = 0

    @eventSearchResultsCollection = new App.Collections.V3.Events(null, {
      purpose: C.Purposes.NewPO
      mapping: C.Mappings.Direct.Events.Event
    })
    @_eventsBeingPurchased = new App.Collections.V3.Events(null, {
      mapping: C.Mappings.Direct.Events.Event
    })

    if (@isConsignment)
      @_eventsBeingPurchased.store = new App.Stores.ConsignEvents()
    else if (@isSpec)
      @_eventsBeingPurchased.store = new App.Stores.SpecEvents()
    else
      @_eventsBeingPurchased.store = new App.Stores.Events()

    @render()

  render: () ->
    @renderPOTemplate()
    @resetTicketDetailsForm()
    @$('.notes').tabbedTextareas()
    @$("#tags").tagsInput({
      autocomplete_url: "/tags?taggable_type=TicketGroup"
      height: '50px'
    })

    App.Utils.dateRangePicker(@$('#in_hand_on'), @onDateChanged, this, C.DatePickerConfig.SingleDatePicker)

    if (@_eventsBeingPurchased.store.count())
      view = new App.Views.PO.ExistingDraft()
      @listenTo(view, 'destroy-draft', @destroyDraft)
      @listenTo(view, 'retrieve-draft', @retrieveDraft)
      view.render()
    else
      @summarizeTicketGroups()
    $('.actLikeCurrency').formatCurrency()

    @addEndlessScroll()
    @loading = new App.Utils.LoadingBox(@$('.events'))

    @$('div.events').css({
      height: $(window).height() - 360 + 'px'
    })

    @exchangeExcluder = new App.Utils.ExchangeDropdown({
      $el: @$('#excludedExchanges')
    })
    @formErrors = new App.Utils.FormErrorManager(@$('#formErrors'))

  # VIEW EVENTS //////////////////////////////////////////////////////////////
  events:
    'change #seat_order':             'updateHighSeat'
    'click a#add_ticket_group':       'onAddTicketGroupClick'
    'click a.continue':               'continueToCompletion'
    'click #overrideSplits':          'onOverrideSplitsClick'
    'click #in_hand':                 'toggleInHandOn'
    'keyup #low_seat':                'updateHighSeat'
    'keyup #quantity':                'updateHighSeat'
    'click .events' :                 'updateSelectedNumberOfEvents'
    'click a.close' :                 'clearSelectedNumberOfEvents'
    'click a.select.none':            'clearSelection'
    'click a.select.all':             'selectAll'
    'change #formatDropdown':         'onFormatDropdownChange'
    'change #in_hand_days':           'onInHandDaysChange'
  #///////////////////////////////////////////////////////////////////////////

  applyFilters: (filters) ->
    @resultCount = 0
    if(_.isEmpty(filters))
      @clearEvents()
    else
      @$('.events').html('')
      @eventSearchResultsCollection.filterOptions = _.clone(filters)
      @eventSearchResultsCollection.reset()
      @updateSelectedNumberOfEvents()
      @allLoaded(false)
      @loading.startLoad()
      @eventSearchResultsCollection.fetch({
        data: filters
        success: _.bind(@displayEvents, @)
      })

  displayEvents: (events, response, options) ->
    if (_.isEmpty(events.models))
      @$('.list .events').html('')
      @showPrompt(NO_EVENTS_FOUND)
      App.Controllers.poController.doneLoading()
    else
      @loading.stopLoad()
      # add only if this is the next
      if (@$(".events .event").length == 0)
        @$('.list .events').html('')
        @eventSearchResultsViews = []

      if (response?.events?.length)
        @showMoreEvents(events)

        # call back the fetch with new page
        if (typeof(events.filterOptions) != 'undefined')
          events.filterOptions.page = (events.filterOptions.page || 1) + 1

        @resultCount += events.length
        if (@resultCount > RESULT_LIMIT)
          App.Controllers.poController.displayLimitError(RESULT_LIMIT)
        else
          # We repull until a blank response.
          events.fetch({
            data: events.filterOptions
            update: true # http://stackoverflow.com/questions/13877391/backbone-collection-0-9-9-add-event-not-work
            remove: false
            success: _.bind(@displayEvents, @)
            error: () =>
              @formErrors.show({
                errors: "A communication error occured when loading the events"
              })
          })

      else
        # everything is loaded
        @allLoaded(true)
        App.Controllers.poController.doneLoading()

  allLoaded: (areAllLoaded) ->
    @allEventsAreLoaded = areAllLoaded
    if (areAllLoaded)
      @$(".select.none, .select.all").show()
    else
      @$(".select.none, .select.all").hide()

  showPrompt: (promptCode, limit) ->
    if (promptCode == SELECT_AN_EVENT)
      title = 'Select an Event'
      message = 'Please use filters to find an event.'
    else if (promptCode == NO_EVENTS_FOUND)
      title = 'No Events Found'
      message = 'Please try a different filter.'
    else if (promptCode == HIT_LIMIT)
      title = 'Too Many Results To Display'
      message = 'Displaying the first ' + limit + ' results for the current filter.'

    $promptContainer = $('<div style="margin: 20px"/>')
    prompt = new App.Views.Shared.Message({
      title
      type: C.MessageTypes.Info
      message
      $container: $promptContainer
    })
    @$('.events').prepend($promptContainer)

  showLimitPrompt: (limit) ->
    @showPrompt(HIT_LIMIT, limit)

  clearEvents: () ->
    @eventSearchResultsViews = []
    @$('.events').html('')
    @eventSearchResultsCollection.filterOptions = {}
    @eventSearchResultsCollection.reset()
    @updateSelectedNumberOfEvents()
    @allLoaded(false)
    @loading.stopLoad()
    App.Controllers.poController.doneLoading()
    @showPrompt(SELECT_AN_EVENT)

  destroyDraft: () ->
    @_eventsBeingPurchased.store.clear()

  renderAllTicketGroups: () ->
    _.each(@_eventsBeingPurchased.models, (eventModel) =>
      @renderEventWithTicketGroup(eventModel)
    )

  renderPOTemplate: () ->
    template = @template
    data = {
      @isConsignment
      @isSpec
    }
    @$container.html(
      @$el.html(
        template(data)
      )
    )
    @delegateEvents()
    @$el.addClass('po')
    @showPrompt(SELECT_AN_EVENT)
    $('.selectable').selectable() # TODO: this should be moved to a global location
    @ticketGroupSummary = new App.Views.Shared.ListSummary({
      viewing: 'ticket group'
      counting: 'ticket'
      container: '.fixedFooter .summary'
      @isSpec
    })

  retrieveDraft: () ->
    #@_eventsBeingPurchased.mapping = C.Mappings.LocalStorage.Events
    @_eventsBeingPurchased.buildModelsFromStore({
      rebuildAll: true
    })
    @renderAllTicketGroups()
    @summarizeTicketGroups()

  updateHighSeat: (e) ->
    seatOrder     = @$('#seat_order').val()
    quantity      = +@$('input#quantity').val()
    lowSeatNumber = +@$('#low_seat').val()
    multiplier    = if (seatOrder == C.SeatOrder.Consecutive) then 1 else 2
    highSeatNumber = lowSeatNumber + (quantity - 1) * multiplier
    highSeatValue = if (lowSeatNumber < 1 || quantity < 1) then '' else highSeatNumber
    @$('#high_seat_number').val(highSeatValue)

  toggleInHandOn: (e) ->
    $inHand = @$('#in_hand')
    $inHandOn = @$('#in_hand_on')
    $inHandDays = @$('#in_hand_days_select')
    if ($inHand.is(':checked'))
      $inHandOn.hide()
      $inHandDays.hide()
    else
      $inHandOn.show()
      $inHandDays.show()

  addTicketGroupToSummary: (ticketGroup) ->
    tickets = App.Utils.currencyToValue(ticketGroup.get('_quantity'))
    total = tickets * App.Utils.currencyToValue(ticketGroup.get('_cost'))
    @ticketGroupSummary.model.addTo({
      count1: 1
      count2: tickets
      total
    })

  onOverrideSplitsClick: (e) ->
    @enableSplitOverride = !@enableSplitOverride
    if (@enableSplitOverride)
      @$('#split_override').removeAttr('disabled')
    else
      @$('#split_override').attr('disabled', true)

  showMoreEvents: (events) ->
    parent = @$('.events')
    # load next hundred
    toShow = events.models.slice(@eventSearchResultsViews.length, @eventSearchResultsViews.length + PAGE)
    _.each(toShow, (event) ->
      eventView = new App.Views.Events.Row({
        model: event
      })
      parent.append(eventView.render())
      @eventSearchResultsViews.push(eventView)
    , @)

  addEndlessScroll: () ->
    $e = @$(".events")
    $e.endlessScroll({
      fireDelay: 10
      callback: (index) =>
        @showMoreEvents(@eventSearchResultsCollection)
    })

  clearSelection: (e) ->
    @eventSearchResultsCollection.forEach((ev) ->
      ev.set("selected", false)
    )
    @updateSelectedNumberOfEvents()

  selectAll: (e) ->
    @eventSearchResultsCollection.forEach((ev) ->
      ev.set("selected", true)
    )
    @updateSelectedNumberOfEvents()

  onFormatDropdownChange: (e) ->
    val = @$('#formatDropdown').val()
    if (val == C.TicketFormats.Eticket)
      @$('#instantDeliveryRow').show()
    else
      @$('#instantDeliveryRow').hide()

  clearSelectedNumberOfEvents: (e) ->
    @eventSearchResultsViews = []
    @updateSelectedNumberOfEvents()

  updateSelectedNumberOfEvents: (e) ->
    selectedEvents = @eventSearchResultsCollection.select((ev) ->
      return ev.get("selected")
    )
    selectedCount = selectedEvents.length
    if (selectedCount > 0)
      text = if (selectedCount > 0) then 'Events Selected: ' + selectedCount else ''
      @$('#selectedNumberOfEvents').text(text).show()
      firstEvent = selectedEvents[0]
      @stubhubDropdown = new App.Utils.StubhubTraitsDropdown({
        $el: @$('#stubhubTraitsDropdown')
        stubhubEventId: firstEvent.get('stubhub_id')
      })
    else
      @$('#selectedNumberOfEvents').hide()
      @stubhubDropdown?.remove()

  remove: () ->
    @$el.removeClass('po')
    @$el.html('')

  continueToCompletion: () ->
    if (@isSpec)
      # Spec Create process:
      if (@_eventsBeingPurchased.models.length)
        # TODO - Validate eventsBeingPurchased
        loadingButton = App.Utils.LoadingButton(@$('#create_spec_button'))
        loadingButton.startLoad()
        loadingView = new App.Views.Shared.LoadingView()
        loadingView.render()
        postData = @_eventsBeingPurchased.toOrderItems({
          price: 'wholesale'
          isPO: true
          @isSpec
        })
        ajax = $.ajax({
          data: JSON.stringify({
            ticket_groups: postData
          })
          dataType: 'json'
          type: 'POST'
          url: '/api_direct/v9/ticket_groups'
          success: (data, textStatus, jqXHR) =>
            loadingView.remove()
            @_eventsBeingPurchased.store.clear()
            successModal = new App.Views.Shared.BasicModal({
              header: 'Tickets Created Successfully'
              message: 'The speculative tickets were created successfully.'
              icon: 'fa-circle-check'
            })
            @listenTo(successModal, 'remove', App.router.navigate('/', { trigger:true }))
          error: (jqXHR, textStatus, errorThrown) =>
            loadingView.remove()
            @formErrors.show({
              title: 'Invalid Purchase Order'
              errors: jqXHR
            })
            App.ErrorManager.resetTolerance(401)
        })
    else
      # Normal PO Case
      if (@_eventsBeingPurchased.models.length)
        App.Controllers.buySellController.buyPO({
          eventsBeingPurchased: @_eventsBeingPurchased
          @isConsignment
        })

  showError: (ticketGroup) ->
    @formErrors.show({
      title: 'Invalid Ticket Group'
      errors: ticketGroup.errors
    })

  onAddTicketGroupClick: (e) ->
    @formErrors.reset()

    selectedEvents = @eventSearchResultsCollection.select((ev) ->
      return ev.get("selected")
    )
    if (selectedEvents.length == 0)
      @formErrors.show({
        title: 'Select Events'
        message: 'Please select events to add tickets to.'
      })
      return false

    eventIdsBeingPurchased = _.map(@_eventsBeingPurchased.models, (event) ->
      return event.get('id')
    )

    ticketGroup = @buildTicketGroup()
    if (!ticketGroup.isValid(true))
      @showError(ticketGroup)
      return false

    _.each(selectedEvents, (selectedEvent) =>

      if (ticketGroup.get('_inHandOnDays'))
        ticketGroup.set('_inHandOn', moment(selectedEvent.get('_occursAt')).subtract(ticketGroup.get('_inHandOnDays'), 'day').format('MM/DD/YYYY'))

      if (!ticketGroup.get("_isInHand") && ticketGroup.get('_inHandOn'))
        eventOccursAt = moment(selectedEvent.get('_occursAt')).utc()
        inHandOn = moment(ticketGroup.get('_inHandOn'),"MM/DD/YYYY").utc().startOf("day")
        if (inHandOn > eventOccursAt)
          @formErrors.show({
            title: 'In Hand Date is Invalid'
            message: 'The in hand date needs to be before the event date'
          })
          return false

      if (_.contains(eventIdsBeingPurchased, selectedEvent.get('id')))

        existingEvent = _.find(@_eventsBeingPurchased.models, (eventModel) ->
          return eventModel.id == selectedEvent.id
        )

        if (existingEvent.get('_ticketGroups').validateNewTicketGroupForPO(ticketGroup))
          existingEvent.get('_ticketGroups').add(ticketGroup)
        else
          @showError(ticketGroup)

      else
        selectedEvent.set('_ticketGroups', new App.Collections.V3.TicketGroups())
        selectedEvent.get('_ticketGroups').add(ticketGroup)
        @_eventsBeingPurchased.add(selectedEvent)

      @addEventToLocalStorage(selectedEvent)
      @renderEventWithTicketGroup(selectedEvent)
      @addTicketGroupToSummary(ticketGroup)

    )

    if (!@formErrors.errors.length)
      @resetTicketDetailsForm()

  addEventToLocalStorage: (event) ->
    @_eventsBeingPurchased.store.set(event)

  removeEventFromLocalStorage: (event) ->
    @_eventsBeingPurchased.store.remove(event.cid)

  resetTicketDetailsForm: () ->
    $el = @$el
    if ($el.find('#doNotClear').is(':checked'))
      return true
    else

      @$('#quantity').val('')
      @enableSplitOverride = false
      @$('#overrideSplits').removeAttr('checked')
      @$('#split_override').attr('disabled', true)
      @$('#split_override').val('')
      @$('#section').val('')
      @$('#row').val('')
      @$('#low_seat').val('')
      @updateHighSeat()
      @$('#seat_order').val('consecutive')

      @$('#face_value').val('0.00')
      @$('#cost').val('0.00')
      @$('#wholesale_price').val('0.00')
      $retailContainer = @$('#retailContainer')
      @retailInput = new App.Utils.RetailInput({
        $container: $retailContainer
        inputName: 'retail_price'
        inputId: 'retail'
      })

      @$('#type').val('event')
      @$('#formatDropdown').val(C.TicketFormats.Physical)
      @$('#instantDeliveryRow').hide()
      @$('#intended_for_instant_delivery').prop('checked', true)
      #@$('#instantDelivery').attr('checked', true)
      @$('#in_hand').prop('checked', true)
      @$('#in_hand_on').hide()
      @$('#in_hand_days_select').hide()
      @$('#in_hand_on').find('#date').text('')
      @toggleInHandOn()
      @$('#view_type').val('Full')
      @$('#broadcast').prop('checked', true)
      @$('#wheelchair').removeAttr('checked')
      @$('#intended_for_instant_delivery').prop('checked', true)
      @$('#notesBroker').val('')
      @$('#notesPrivate').val('')
      @$('#notesPublic').val('')
      @$("#excludedExchanges").select2('val', null)

  buildTicketGroup: () ->
    formAttrs = $('.new_po form').serializeObject()

    ticketGroupAttributes = {
      _canOverrideSplits: !!formAttrs.isOverrideSplitsEnabled
      _cost: App.Utils.currencyToValue(formAttrs.cost)
      _format: formAttrs.format
      _lowSeat: formAttrs.low_seat
      _isBroadcast: !!formAttrs.broadcast
      _isEticket: (formAttrs.format == C.TicketFormats.Eticket)
      _isInHand: formAttrs.in_hand
      _isInstantDelivery: !!formAttrs.intended_for_instant_delivery
      _isWheelchair: formAttrs.wheelchair
      _notesBroker: formAttrs.notesBroker
      _notesPrivate: formAttrs.notesPrivate
      _notesPublic: formAttrs.notesPublic
      _priceFace: App.Utils.currencyToValue(formAttrs.face_value)
      _priceRetail: @retailInput.getVal()
      _priceWholesale: App.Utils.currencyToValue(formAttrs.wholesale_price)
      _quantity: formAttrs.quantity
      _row: formAttrs.row
      _seatOrder: formAttrs.seat_order
      _section: formAttrs.section
      _splits: formAttrs.split_override
      _type: formAttrs.type
      _viewType: formAttrs.view_type
    }

    if (!formAttrs.in_hand)
      if (@$('#in_hand_days').val() != null)
        ticketGroupAttributes._inHandOnDays = @$('#in_hand_days').val()
      else if (@$('#in_hand_on').find('#date').text() != '')
        ticketGroupAttributes._inHandOn = @$('#in_hand_on').find('#date').text()

    if (ticketGroupAttributes._format == C.TicketFormats.Physical)
      ticketGroupAttributes._isInstantDelivery = false

    if (@isSpec)
      ticketGroupAttributes._isSpec = @isSpec
      ticketGroupAttributes._lowSeat = 1
      ticketGroupAttributes._seatOrder = C.SeatOrder.Consecutive

    ticketGroupAttributes._excludedExchanges = @exchangeExcluder.val()
    ticketGroupAttributes._stubhubTraits = @stubhubDropdown?.val() || []

    ticketGroupModel = new App.Models.V3.TicketGroup(ticketGroupAttributes)
    return ticketGroupModel

  renderEventWithTicketGroup: (eventModel) ->
    existingView = @existingViewsByEventId[eventModel.id]
    if (existingView)
      existingView.updateTicketGroups(eventModel)
    else
      eventWithTicketGroupView = new App.Views.Events.WithTicketGroup({
        eventsBeingPurchased: @_eventsBeingPurchased
        eventModel
        newPOView: @
        parentSelector: ".ticket_groups"
      })
      eventWithTicketGroupView.render()
      @existingViewsByEventId[eventModel.id] = eventWithTicketGroupView

  summarizeTicketGroups: () ->
    @ticketGroupSummary = new App.Views.Shared.ListSummary({
      viewing: 'ticket group'
      counting: 'ticket'
      container: '.summary'
      @isSpec
    })
    @_eventsBeingPurchased.forEach((eventModel) =>
      _.each(eventModel.get('_ticketGroups').models, (ticketGroupModel) =>
        tickets = App.Utils.currencyToValue(ticketGroupModel.get('_quantity'))
        total = tickets * App.Utils.currencyToValue(ticketGroupModel.get('_cost'))
        @ticketGroupSummary.model.addTo({
          count1: 1
          count2: tickets
          total
        })
      )
    )

  removeEmptyEvent: (eventId) ->
    delete @existingViewsByEventId[eventId]

  onDateChanged: (startDate, endDate) ->
    @$('#in_hand_days').val(null)

    if (_.isString(startDate))
      @dateRangeFilter = {
        start: startDate
        end: endDate
      }
    else
      @dateRangeFilter = {
        start: startDate.toDate().toISOString()
        end: endDate.toDate().toISOString()
      }

  onInHandDaysChange: (e) ->
    if (e.currentTarget.value != '')
      @$('#in_hand_on').find('#date').text('')
