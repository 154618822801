module.exports = clientShowController = {}

(() ->

  @views = {}
  @go = ($container, id = null, options = {}) ->
    {
      tab
    } = options
    @current_tab = null
    @$container = $container

    @client = new App.Models.V3.Client({
      id
    }, {
      mapping: C.Mappings.Direct.Clients.Client
    })

    switch tab
      when 'creditCards'
        callback = _.bind(@renderCards, @)
      when 'invoices'
        callback = _.bind(@renderInvoices, @)
      when 'orders'
        callback = _.bind(@renderPOS, @)
      else
        callback = _.bind(@renderInfo, @)

    @fetch () =>
      @views.layout = new App.Views.Clients.ShowLayout {
        @client
        @$container
        callback
        tab
      }

  @renderInfo = ->
    #FIXME NEED TO KILL TAB WHEN OTHER IS CLICKED
    if (@current_tab != 'info')
      @current_tab = 'info'
      @views.info   = new App.Views.Clients.Show {
        @client
        $container: @$container.find('#content_here')
      }

  @renderCards = ->
    if (@current_tab != 'cards')
      @current_tab = 'cards'

      patron = @client.toPatron()

      creditCardCollection = new App.Collections.V3.CreditCards(null, {
        cardOwner: patron
        mapping: C.Mappings.Direct.CreditCards.CreditCard
      })
      creditCardsView = new App.Views.Clients.CreditCards {
        patron
        $container: @$container.find('#content_here')
        creditCardCollection
      }

      creditCardCollection.fetchPromise()
      .then (collection) =>
        creditCardsView.render()
      .fail (error) =>
        console.error(error.stack)
        errorModal = new App.Views.Shared.BasicModal({
          header: 'Error fetching order items.'
          message: error.message
        })
      .done()



  @renderInvoicesOrPOs = (params) ->

    $container = @$container.find("#content_here")

    v3OrdersCollection = new App.Collections.V3.Orders(null, {
      mapping: C.Mappings.Direct.Orders.Orders
      params
    })

    clientOrdersView = new App.Views.Clients.Orders({
      $container
      type: 'orders'
      v3OrdersCollection
    })

    v3OrdersCollection.fetchPromise({
      reset: true
    })
    .then (collection) =>
      clientOrdersView.render()
    .fail (error) =>
      console.error(error.stack)
      errorModal = new App.Views.Shared.BasicModal({
        header: 'Error fetching order items.'
        message: error.message
      })
    .done()

  @renderInvoices = () ->
    if (@current_tab != 'invoices')
      @current_tab = 'invoices'
      params = {
        buyer_id: @client.id
        type: 'Order'
      }
      @renderInvoicesOrPOs(params)

  @renderPOS = ->
    if (@current_tab != 'pos')
      @current_tab = 'pos'
      params = {
        seller_id: @client.id
        type: 'PurchaseOrder'
      }
      @renderInvoicesOrPOs(params)

  @fetch = (callback) ->
    loadingOverlay = new App.Views.Shared.LoadingView()
    if @client.get('id')
      loadingOverlay.render()
      @client.fetch
        success: () =>
          loadingOverlay.remove()
          callback()
    else
      callback()

  @afterSave = (client)->
    @client = client
    App.router.navigate("/client/#{@client.get("id")}")
    @$container.find('#top_name_label').html("Client - #{@client.get('_name')}")

).call(clientShowController)
