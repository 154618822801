template = require('./management_orders.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

module.exports = App.Views.Events.ManagementOrders = App.Views.Base.BaseView.extend

  template: template

  dataTableOptions:
    bJQueryUI: true
    sDom: '<"">t<"F"fp>',
    bScrollInfinite: true
    bScrollCollapse: false
    iDisplayLength: 1000
    aaSorting: [
      [ 0, 'desc']
    ],
    aoColumns: [
      null
      { sType: 'date' }
      null
      null
      { sType: 'currency' }
      null
      null
      null
      null
      null
      null
    ]
    oLanguage: {
      sSearch: 'Filter Results: '
      sEmptyTable: 'Please select any of the available filter criteria in order to view your orders'
    }

  initialize: (options) ->
    {
      @$container
      @orderItemsCollection
    } = options
    @displayedCollection = @orderItemsCollection
    @render()

  render: () ->
    orderItems = @displayedCollection.toPresenterJSON()
    data = {
      orderItems
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )
    @loadingBox = new App.Utils.LoadingBox(@$container)

    # Make datatable fill container.
    height = ~~(($(window).height() - 370) / 2)
    @dataTableOptions.sScrollY = "#{ height }px"

    @$('table').dataTable(@dataTableOptions)

    # This craziness just drives the createdBy filter.
    options = _.map @orderItemsCollection.models, (model, index) ->
      return model.get('_createdBy')
    options = _.uniq(options)
    options = options.sort()
    options = _.map options, (el, index) ->
      return {
        id: el
        text: el
      }
    options.unshift({ id: 'all', text: 'All' })
    @$('#created_by_filter').select2({
      placeholder: 'Created By'
      data: options
    }).on 'change', (e) =>
      if (e.val == 'all')
        @displayedCollection = @orderItemsCollection
      else

        displayedModels = @orderItemsCollection.filter (orderItemModel) ->
          return orderItemModel.get('_createdBy') == e.val

        @displayedCollection = new App.Collections.OrderItems(displayedModels, {
          mapping: C.Mappings.Direct.OrderItemsByEvent.OrderItems
        })

      @render()

    # Notes popovers.
    @displayedCollection.forEach((orderItemModel) ->
      $el = $("tr[data-order-item-id=#{ orderItemModel.id  }] .popover_notes")
      App.Utils.notesPopover($el, orderItemModel)
    )
    # End notes popovers.

    @delegateEvents()

  # VIEW EVENTS ////////////////////////////////////////////////////////////////
  events:
    'click .order_row': 'onOrderRowClicked'

  onOrderRowClicked: (e) ->
    $row = $(e.currentTarget)
    data = $row.data()
    orderId = data.orderId
  #/////////////////////////////////////////////////////////////////////////////
