module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<%\nif (!_.size(clients)) { // If no cients.\n%>\n<h5 style="width: 100%; text-align:center; line-height: 150px;">Please use search to find customers.</h5>\n<%\n} else {\n%>\n<table id="client_table" style="min-height: 150px;" class="table table-bordered table-striped table-hover data-table">\n  <thead>\n    <tr>\n      <th>Client</th>\n      <th>Company</th>\n      <th>Street Address</th>\n      <th>City, ST</th>\n      <th>Phone</th>\n      <th>Email</th>\n      <th>Notes</th>\n      <th></th>\n    </tr>\n  </thead>\n  <tbody>\n    <%\n  _.each(clients, function(item, i) {\n%>\n    <tr>\n      <td><%= item.name %></td>\n      <td><%= item.companyName %></td>\n      <td><%= item.streetAddress %></td>\n      <td><%= item.cityState %></td>\n      <td><%= item.phoneNumber %></td>\n      <td><%= item.emailAddress %></td>\n      <td>\n        <% if(item.notes) {%>\n        <span data-toggle="tooltip" data-original-title="<%= item.notes %>" class="popover_notes badge badge-default"><i class="fa-solid fa-file-lines" /></span>\n        <% } %>\n      </td>\n      <td><a class="edit_client_button btn btn-small" style="white-space: nowrap;" data-client-id="<%= item.id %>"><i class="fa-solid fa-pen-to-square"></i> View / Edit</a></td>\n    </tr>\n    <%\n  });\n%>\n</table>\n<%\n}\n%>\n', __filename = "js/views/clients/client_table.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            if (!_.size(clients)) {
                __line = 3;
                __append('\n<h5 style="width: 100%; text-align:center; line-height: 150px;">Please use search to find customers.</h5>\n');
                __line = 5;
            } else {
                __line = 7;
                __append('\n<table id="client_table" style="min-height: 150px;" class="table table-bordered table-striped table-hover data-table">\n  <thead>\n    <tr>\n      <th>Client</th>\n      <th>Company</th>\n      <th>Street Address</th>\n      <th>City, ST</th>\n      <th>Phone</th>\n      <th>Email</th>\n      <th>Notes</th>\n      <th></th>\n    </tr>\n  </thead>\n  <tbody>\n    ');
                __line = 22;
                _.each(clients, function(item, i) {
                    __line = 24;
                    __append("\n    <tr>\n      <td>");
                    __line = 26;
                    __append(escapeFn(item.name));
                    __append("</td>\n      <td>");
                    __line = 27;
                    __append(escapeFn(item.companyName));
                    __append("</td>\n      <td>");
                    __line = 28;
                    __append(escapeFn(item.streetAddress));
                    __append("</td>\n      <td>");
                    __line = 29;
                    __append(escapeFn(item.cityState));
                    __append("</td>\n      <td>");
                    __line = 30;
                    __append(escapeFn(item.phoneNumber));
                    __append("</td>\n      <td>");
                    __line = 31;
                    __append(escapeFn(item.emailAddress));
                    __append("</td>\n      <td>\n        ");
                    __line = 33;
                    if (item.notes) {
                        __append('\n        <span data-toggle="tooltip" data-original-title="');
                        __line = 34;
                        __append(escapeFn(item.notes));
                        __append('" class="popover_notes badge badge-default"><i class="fa-solid fa-file-lines" /></span>\n        ');
                        __line = 35;
                    }
                    __append('\n      </td>\n      <td><a class="edit_client_button btn btn-small" style="white-space: nowrap;" data-client-id="');
                    __line = 37;
                    __append(escapeFn(item.id));
                    __append('"><i class="fa-solid fa-pen-to-square"></i> View / Edit</a></td>\n    </tr>\n    ');
                    __line = 39;
                });
                __line = 41;
                __append("\n</table>\n");
                __line = 43;
            }
            __line = 45;
            __append("\n");
            __line = 46;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}