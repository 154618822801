App.Models.Base.BaseModel = require('../base/base_model.coffee')

parent = App.Models.Base.BaseModel.prototype
module.exports = App.Models.V2.Address = App.Models.Base.BaseModel.extend({

  initialize: (attributes, options = {}) ->
    {
      @holder_id
    } = options
    parent.initialize.call(@, attributes, options)
    @set 'country_code', 'US' unless @get('country_code')

  updateStandardAttributes: () ->
    json = @attributes
    attrs = {}
    attrs.id = json.id

    if (@getMapping() == C.Mappings.OrderEndpoint.Address)
      attrs._region = json.region
      attrs._locality = json.locality
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.OrderShowLite.PatronAddresses ||
        @getMapping() == C.Mappings.Direct.TicketCosts.PatronAddresses ||
        @getMapping() == C.Mappings.V2OrderEndpoint.Address ||
        @getMapping() == C.Mappings.Unknown)
      attrs._countryCode = json.country_code
      attrs._extended = json.extended_address
      attrs._id = json.id
      attrs._isPoBox = json.po_box
      attrs._isPrimary = json.primary
      attrs._label = json.label
      attrs._latitude = json.latitude
      attrs._locality = json.locality
      attrs._longitude = json.longitude
      attrs._postalCode = json.postal_code
      attrs._region = json.region
      attrs._streetAddress = json.street_address
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.MassIndexEndpoint.Address)
      attrs._company = json.venue_address_company
      attrs._countryCode = json.venue_address_country_code
      attrs._extended = json.venue_address_extended_address
      attrs._id = json.venue_address_id
      attrs._label = json.venue_address_label
      attrs._locality = json.venue_address_locality
      attrs._name = json.venue_address_name
      attrs._postalCode = json.venue_address_postal_code
      attrs._region = json.venue_address_region
      attrs._streetAddress = json.venue_address_street_address
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  validates:
    street_address:
      required: true
    locality:
      required: true
    country_code:
      required: true
    region:
      required: true
    postal_code:
      required: true

  defaults:
    country_code: ''
    extended_address: ''
    locality: ''
    postal_code: ''
    region: ''
    street_address: ''

  # PRESENTATION /////////////////////////////////////////////////////////////

  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {
      locality: @get('_locality') || @get('locality')
      region:   @get('_region')   || @get('region')
      shouldPresentAddress: @shouldPresentAddress()
      pretty: @to_s()
    })
    return presented

  shouldPresentAddress: -> @get('locality') and @get('region') and @get('street_address')

  to_s: () ->
    if @shouldPresentAddress
      result = "#{@get('street_address') or 'Missing Street'}, "
      result +="#{ (@get('extended_address') && (@get('extended_address') + ', ')) || '' }"
      result +="#{@get('locality') or 'Missing Locality'} "
      result +="#{@get('region') or 'Missing Region'}, "
      result +="#{@get('postal_code') or 'Missing Postal Code'}, "
      "#{result}#{@get('country_code') or 'No Country Code'}"
    else
      "No Address"

  #///////////////////////////////////////////////////////////////////////////

}, {
  # STATICS
  defaultMapping: C.Mappings.OrderEndpoint.Address
})
