App.Controllers.Base.BaseController = require('./base/base_controller.coffee')

module.exports = class App.Controllers.InventoryController extends App.Controllers.Base.BaseController

  _ticketGroupsCollection = null
  _massIndexView = null
  _isReceiveTicketsView = null
  _$container = null
  _xhr = null

  routeMassIndex: (options = {}) ->
    {
      $container
      isReceiveTicketsView
    } = options
    _isReceiveTicketsView = isReceiveTicketsView || false
    @displayMassIndex({
      $container
      shouldReset: true
      doNotFetch: true
    })

  resetMassIndex: () ->
    @displayMassIndex({
      $container: _$container
      shouldReset: true
      doNotFetch: true
    })

  filterMassIndex: () ->
    @displayMassIndex({
      $container: _$container
    })

  confirmEditsMassIndex: (message) ->
    _massIndexView.renderEditView()
    _massIndexView.renderEditSuccessMessage()
    _ticketGroupsCollection.fetch({
      data: _massIndexView.getFilterParams()
      reset: true
    })

  # TODO: This usage of v2TicketGroupCollection prevents the deletion of most v2 stuff.
  displayMassIndex: (options = {}) ->
    {
      $container
      shouldReset
      doNotFetch
    } = options

    _$container = $container
    if (!_ticketGroupsCollection || shouldReset)
      _ticketGroupsCollection = new App.Collections.V3.TicketGroups(null, {
        mapping: C.Mappings.MassIndexEndpoint.TicketGroup
      })
    if (!_massIndexView || shouldReset)
      _massIndexView = new App.Views.Inventory.MassIndex({
        $container: _$container
        collection: _ticketGroupsCollection
        isReceiveTicketsView: _isReceiveTicketsView
      })

    if (_xhr)
      _xhr.abort()

    _massIndexView.clearMessage()

    params = _massIndexView.getFilterParams()
    params.by_event_occurs_at = 'desc'

    # We don't fetch on initial page view.  Wait for user to pick some filters.
    if (doNotFetch)
      _ticketGroupsCollection.reset()
    else
      # View is bound to collection reset event.
      # TicketGroup.fetch is all screwed up: function (mine, type, wasted, showPast, fetchOptions)
      _xhr = _ticketGroupsCollection.fetch({
        data: params
        success: (collection, response, options) ->
          $.noop()
        reset: true
      })
      App.Utils.requestKiller.add(_xhr)
