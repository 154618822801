App.Models.Base.BaseModel = require('../base/base_model.coffee')

parent = App.Models.Base.BaseModel.prototype
module.exports = App.Models.V2.TicketHold = App.Models.Base.BaseModel.extend({

  updateStandardAttributes: () ->
    json = @attributes
    attrs = {}

    if (@getMapping() == C.Mappings.FilteredTicketsEndpoint.HeldTickets ||
        @getMapping() == C.Mappings.FilteredTicketsEndpoint.TakenTickets ||
        @getMapping() == C.Mappings.HeldTicketEndpoint.TicketHolds)
      attrs._createdAt = Date.parse(json.created_at)
      attrs._createdBy = json.created_by
      attrs._heldFor = json.held_for
      attrs._heldForBrokerage = json.held_for_brokerage
      attrs._heldForBrokerageId = json.held_for_brokerage_id
      attrs._heldForId = json.held_for_id
      attrs._heldForType = json.held_for_type
      attrs._holdUntil = Date.parse(json.hold_until) || null
      attrs._id = json.id
      attrs._notes = json.notes
      attrs._price = json.price
      attrs._quantity = json.quantity
      attrs._seats = json.seats
      attrs._ticketGroupIds = json.ticket_group_ids
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      createdAt:      @get('_createdAt')
      createdBy:      @get('_createdBy')
      flags:          @flags()
      heldFor:        @heldFor()
      heldForType:    @get('_heldForType')
      holdUntil:      @get('_holdUntil')
      id:             @get('_id')
      price:          App.Utils.valueToCurrency(@get('_price'))
      quantity:       @get('_quantity')
      seats:          @seats()
      ticketGroupId:  @ticketGroupId()

    })
    return presented

  flags: () ->
    if (@get('_notes'))
      return C.FlagBadges.Notes
    return ''

  heldFor: () ->
    type = @get('_heldForType')
    if (type == 'Client')
      return @get('_heldFor')
    else if (type == 'Office')
      return @get('_heldForBrokerage')
    else if (type == 'Cart')
      return '<strong>Cart</strong>'
    return ''

  seats: () ->
    return App.Utils.seatsToSeatRange(@get('_seats'))

  ticketGroupId: () ->
    return _.first(@get('_ticketGroupIds'))
  #///////////////////////////////////////////////////////////////////////////

  release: (options) ->
    ticketGroupId = options.ticketGroupId
    path = if @has("hold_until") then '/release_hold/' else '/release_take/'
    url = "/api_direct/v9/ticket_groups/#{ ticketGroupId }#{ path }#{ @get('id') }"
    $.ajax({
      type: 'POST',
      url: url,
      success: (data, status, xhr) =>
        @set(data)
        App.Vent.trigger('ticket-hold:released', @)
        options.callback && options.callback(null, data)
      error: (xhr, status, error) =>
        callback(new Error('Error releasing ticket group.'))
    })

  updateHoldUntil: (date) ->
    ticketGroupId = @get('ticket_group_ids')[0]
    url = "/api_direct/v9/ticket_groups/#{ ticketGroupId }/update_hold/#{ @get('id') }"
    $.when($.post(url, {
      hold_until: date
    })).then((data) =>
      @set('hold_until', date)
      App.Vent.trigger('ticket-hold:updated', @)
    )

}, {
  # STATICS
  defaultMapping: C.Mappings.FilteredTicketsEndpoint.HeldTicket
})