module.exports = App.Models.Airbill = Backbone.Model.extend

  parse: (options) ->
    return {
      url: options.airbill
      shipment_id: options.id
    }

  has_airbill: () ->
    url = @get('url')
    if (url)
      isMissing = url.match(/missing.png/)
      return !isMissing
    return false

  url: () ->
    return "/api_direct/v9/shipments/#{ @get('shipment_id') }/airbill"

  show: () ->
    return "/api_direct/v9/shipments/#{ @get('shipment_id') }/get_airbill"