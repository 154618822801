template = require('./events_list.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')
App.Views.Events.Event = require('./event_view.coffee')

parent = App.Views.Base.BaseView
module.exports = App.Views.Events.List = App.Views.Base.BaseView.extend

  template: template

  INSERT_AFTER_SELECTOR: 'div.events div.eventListRow:last'

  initialize: (options = {}) ->
    {
      @$container
      @filters
      @deferred
    } = options

    @currentPage = 1
    @collection = options.eventsCollection

    @pinsCollection = new App.Collections.V3.EventPins(null, {
      mapping: C.Mappings.Direct.EventPins.EventPin
    })
    @pinsByEventId = {}

    @render()

  render: () ->
    data = {
      myOrAllEvents: App.router.getMyOrAllEvents()
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )
    @$('div.events').css({
      height: $(window).height() - 315 + 'px'
    })
    return @$el

  # VIEW EVENTS //////////////////////////////////////////////////////////////
  events:
    'click button#myEvents': 'onMyOrAllEventsClick',
    'click button#allEvents': 'onMyOrAllEventsClick'
    'click #expandEventsListButton': 'onExpandEventsListButtonClick'
    'click #collapseEventsListButton': 'onCollapseEventsListButtonClick'

  onMyOrAllEventsClick: (e) ->
    id = $(e.currentTarget).attr('id')
    myOrAllEvents = 'my'
    if (id == 'allEvents')
      myOrAllEvents = 'all'
    filterCode = App.Controllers.eventsController.getEventsFilterCode()
    if (filterCode == App.Utils.encodeFilterParams({})) # If no filters
      App.router.navigate("/events/#{ myOrAllEvents }", { trigger: true })
    else
      App.router.navigate("/tickets/events/#{ myOrAllEvents }/filter/#{ filterCode }", { trigger: true })

  onExpandEventsListButtonClick: (e) ->
    App.Controllers.eventsController.expandEventsView()

  onCollapseEventsListButtonClick: (e) ->
    App.Controllers.eventsController.collapseEventsView()

  fetchCollectionAndPins: () ->
    @pinsByEventId = {}
    @$('.events').empty()
    @filters = @filters || {}
    @collection.setFilters(@filters)
    @loadingBox = new App.Utils.LoadingBox(@$('.events'))
    @loadingBox.startLoad()

    Q.all([@collection.fetchPromise(), @pinsCollection.fetchPromise()])
    .spread (collectionResult, pinsResult) =>
      @pinsCollection.forEach((pinModel) =>
        @pinsByEventId[pinModel.get('_event').id] = pinModel
      )
      @loadingBox.stopLoad()
      @renderEvents(null, true)
      @deferred?.resolve()
    .fail (errors) =>
      @loadingBox.stopLoad()
      @deferred?.reject(errors)
    .done()
  #///////////////////////////////////////////////////////////////////////////

  endlessScroll: () ->
    blockFetching = false
    shouldEndlessScrollBeBlocked = () ->
      return blockFetching
    LOADING_HTML = '<div class="loading_div" style="background: #f00; opacity: 0.7; height: 100px; font-size: 30px; line-height: 100px; text-align: center; color: #fff;"><i class="fa-solid fa-rotate fa-spin fa-lg"></i>&nbsp&nbsp&nbspLOADING</div>'
    DONE_HTML = '<div class="loading_div" style="background: #62c462; opacity: 0.7; height: 100px; font-size: 30px; line-height: 100px; text-align: center; color: #fff;">END OF RESULTS</div>'
    ERROR_HTML = '<div class="loading_div" style="background: #f00; opacity: 0.7; height: 100px; font-size: 30px; line-height: 100px; text-align: center; color: #fff;"><i class="fa-solid fa-triangle-exclamation fa-lg"></i>&nbsp&nbsp&nbspERROR</div>'
    $loader = null
    removeLoader = () =>
      @$('.loading_div').remove()
    $self = @$el.find('.events')
    $self.data('all-fetched', false)
    endlessScrollCallback = (index) =>
      removeLoader() # Ensure only one loader message.
      $loader = $(LOADING_HTML).insertAfter(@$(@INSERT_AFTER_SELECTOR))
      @currentPage += 1
      blockFetching = true

      @filters = @filters || {}
      @collection.setFilters(@filters)
      url = @collection.url()

      $.ajax({
        url: url
        dataType: 'json'
        data:
          page: @currentPage
        success: (json) =>
          removeLoader()
          if (!json.events || !json.events.length)
            #$(@INSERT_AFTER_SELECTOR).append(DONE_HTML)
            $(DONE_HTML).insertAfter(@$(@INSERT_AFTER_SELECTOR))
          else
            collection = new App.Collections.V3.Events(json.events, {
              mapping: C.Mappings.EventEndpoint.Event
            })
            models = collection

            models.each((model) =>
              pinModel = @pinsByEventId[model.id]
              view = new App.Views.Events.Event({
                eventModel: model
                @filters
                pinModel
                @pinsCollection
              })
              (view.render()).insertAfter(@$(@INSERT_AFTER_SELECTOR))
              model.view = view
            )

            blockFetching = false
            @collection.add(collection.models)

        error: () =>
          removeLoader()
          $(ERROR_HTML).insertAfter(@$(@INSERT_AFTER_SELECTOR))

      })

    $self.endlessScroll({
      fireDelay: 200
      fireOnce: true
      # We'll handle loader on our own since the endlessScroll plugin removes it before fetch is done.
      # We'll wait for fetch to complete and manage it ourselves.
      loader: '<div style="display: none"/>' # LOADING_HTML(colSpanCount),
      insertAfter: @INSERT_AFTER_SELECTOR
      ceaseFire: shouldEndlessScrollBeBlocked
      bottomPixels: 50
      callback: endlessScrollCallback
    })

  applyFilters: (filters) ->
    @filters = filters
    @fetchCollectionAndPins()

  eventsFetched: () ->
    @renderEvents()

  highlightEvent: (eventId) ->

    if (!eventId)
      return Q(null)

    model = @collection.get(eventId)
    if (model)
      view = model.view
      view.highlightEvent()
      return Q(view)
    else
      deferred = Q.defer()

      v3EventModel = new App.Models.V3.Event({
        id: eventId
      }, {
        mapping: C.Mappings.Direct.Events.Event
      })

      Q.all([v3EventModel.fetchPromise(), @pinsCollection.fetchPromise()])
      .spread (v3EventModel, pinResult) =>
        #isPinned = !!(pinResult.id)
        view = @renderOneEvent(v3EventModel)
        view.highlightEvent()
        deferred.resolve(view)
      .fail (error) ->
        console.log(error)
      .done()
      return deferred.promise

  renderEvents: (collection, empty, ignoreEmpty) ->
    models = collection || @collection
    emptyMessage = 'No more events'
    if (App.router.getMyOrAllEvents() == 'my')
      emptyMessage = 'You have no more events with tickets available'
    if (empty)
      @$('.events').empty().append('<div class="loading-message">Loading...')
    if (!models.length && !ignoreEmpty)
      error_code = '<div class="error-message alert alert-error" style="padding:5px">'+ emptyMessage + '</div>'
      @$('.events').append($(error_code))
      App.Controllers.eventsController.doneLoading()
      return

    @endlessScroll()

    models.each((v3EventModel) =>
      @renderOneEvent(v3EventModel)
    )
    App.Controllers.eventsController.doneLoading()

  renderOneEvent: (v3EventModel) ->
    pinModel = @pinsByEventId[v3EventModel.id]
    view = new App.Views.Events.Event({
      eventModel: v3EventModel
      @filters
      pinModel
      @pinsCollection
    })
    # CAREFUL HERE!
    # So the endless scroll plugin takes all existing events and wraps them with a endless_scroll_inner_wrap div.
    # That div is used to calculate scroll heights and when to trigger endless scroll loads.
    # In short... we CANNOT append to $('.events') once the endlessScroll plugin is initialized...
    #  or all the measuring is off.
    # The final markup should be something like:
    # <div class="events">
    #  <div class="endless_scroll_inner_wrap">
    #   <div class="eventListRow"> <!-- one event -->
    #   <div class="eventListRow">
    #   <div class="eventListRow">
    #   <div class="eventListRow">

    $lastEvent = @$(@INSERT_AFTER_SELECTOR)
    if ($lastEvent.length) # If there's any events, insert after last event.
      (view.render()).insertAfter(@$(@INSERT_AFTER_SELECTOR))
    else # initial load safe to append into '.events'
      @$('.events').append(view.render())
    v3EventModel.view = view
    return view

  collapse: () ->
    @$('#expandedEventsList').hide()
    @$('#collapsedEventsList').show()

  expand: () ->
    @$('#collapsedEventsList').hide()
    @$('#expandedEventsList').show()
