module.exports = App.Models.CreditCard = Backbone.Model.extend({

  validate: function () {
    var that = this;
    var errors = this.errors = [];

    var requiredFields = [
      'name',
      'number',
      'expiration_month',
      'expiration_year'
      //'cvv'
    ];
    this.validates(requiredFields, { presence: true });


    if(!this.has("address_id")){
      var address = new App.Models.V3.Address(this.get('address_attributes'));
      address.validate();
      _.each(address.errors, function (error) {
        that.errors.push("Address " + error.toLowerCase());
      });
    }
  },

  displayString: function (noExpiration) {
    if (noExpiration) {
      return [this.get('card_company'),
        "x" + this.get('last_digits')].join(' ');
    } else {
      return [this.get('card_company'),
        "x" + this.get('last_digits'),
        "(" + this.expirationString() + ")"].join(' ');
    }
  },

  expirationString: function () {
    if (this.get('expiration_month') && this.get('expiration_year')) {
      return this.get('expiration_month') + '/' + this.get('expiration_year');
    } else if (this.get('expiration')) {
      var year = this.get('expiration').split('-')[0],
          month = this.get('expiration').split('-')[1];

      return year + '/' + month;
    }
  },

  isValid: function () {
    this.validate();
    return _.isEmpty(this.errors);
  }

});

