const template = require('./list_summary.ejs')

module.exports = App.Views.Shared.ListSummary = Backbone.View.extend({

  template: template,

  initialize: function(options) {
    this.isSpec = options.isSpec;
    this.model = new App.Models.ListSummary();
    var attrs = _.extend({count1: 0, count2: 0, total: 0}, options);
    this.model.addTo(attrs);
    this.viewing = options.viewing;
    this.counting = options.counting;
    this.$el = $(options.container);
    this.listenTo(this.model, 'change', this.render);
    this.render();
  },

  render: function() {
    this.$el.html(this.template(this.templateOptions()));
  },

  templateOptions: function() {
    return {
      viewing: this.viewing,
      counting: this.counting,
      count1: this.model.get('count1'),
      count2: this.model.get('count2'),
      total: this.model.get('total'),
      isSpec: this.isSpec
    };
  }

});