template = require('./sales.ejs')
App.Views.Base.FilteredTable = require('../base/filtered_table_view.coffee')

parent = App.Views.Base.FilteredTable.prototype
module.exports = App.Views.Reports.Sales = App.Views.Base.FilteredTable.extend

  template: template

  initialize: (options = {}) ->
    {
      @model
      @$container
    } = options
    @listenTo(@model, 'change:collection', @onCollectionChange)
    @listenTo(@model, 'change', @onModelChange)
    coll = @model.get('collection')
    @listenTo(coll, 'reset', @onCollectionReset)
    @render()

  render: () ->
    @$container.html(
      @$el.html(
        @template()
      )
    )

    $dateRangeEl = @$('#date_range')
    App.Utils.dateRangePicker($dateRangeEl, @onDateRangeChanged, @, C.DatePickerConfig.Past)
    # Default to last 7 days.
    $dateRangeEl[0].setDateRange(C.Times.Begin7, C.Times.TodayEnd)

    $content = @$('#filter_content')
    $collapseButton = @$('#filter_collapse_button')
    isHorizontalSplit = true
    onChange = () =>
      @renderTable()
    options = {
      $content
      $collapseButton
      isHorizontalSplit
      onChange
    }
    @collapsibleFilters = new App.Utils.CollapsibleWidget(options)

    @buttonLoader = new App.Utils.LoadingButton(@$('#apply_filter_button'))
    @initializeAutocomplete()
    @initializeDropdowns()
    @renderTable(true)
    @startLoad()
    @delegateEvents()
    return @$el

  renderTable: (isInitialRender) ->
    if (@table)
      @table.remove()
    @table = new App.Views.Reports.SalesTable({
      filterView: @
      $container: @$('#table_container')
      collection: @model.get('collection')
      @model
    })
    @tableLoader = new App.Utils.LoadingBox(@table.$el)
    if (!isInitialRender)
      @stopLoad()

  # VIEW EVENTS //////////////////////////////////////////////////////////////
  events:
    'click #apply_filter_button': 'onApplyFilterButtonClick'
    'click #reset_filter_button': 'onResetFilterButtonClick'
    'click #printButton': 'onPrintButtonClick'
    'click #exportButton': 'onExportButtonClick'

  onResetFilterButtonClick: () ->
    App.Controllers.reportController.resetSalesIndex()

  onApplyFilterButtonClick: () ->
    @startLoad()
    App.Controllers.reportController.filterSalesIndex()

  onDateRangeChanged: (startDate, endDate) ->
    @dateRangeFilter = {
      start: startDate
      end: endDate
    }

  onPrintButtonClick: (e) ->
    header = "Sales Report"
    options = {
      header
    }
    App.Exporter.printGroupedTable(@table, options)

  onExportButtonClick: (e) ->
    filePrefix = "sales"
    filename = [filePrefix, '_', App.Utils.makeTimestampHuman(Date.now(), C.DateFormats.File)].join('')
    options = {
      filename
    }
    App.Exporter.csvGroupedTable(@table, options)
  #///////////////////////////////////////////////////////////////////////////

  # COLLECTION EVENTS ////////////////////////////////////////////////////////
  onCollectionChange: () ->
    @renderTable()
    coll = @model.get('collection')
    @listenTo(coll, 'reset', @onCollectionReset)

  onCollectionReset: () ->
    @renderTable()

  onModelChange: () ->
    # Lazy man binding.  Not good practice.
    m = @model
    @$('#orders_display').text(m.get('order_count'))
    @$('#tickets_display').text(m.get('ticket_count'))
    @$('#cost_display').text(App.Utils.valueToCurrency(m.get('cost_sum')))
    @$('#revenue_display').text(App.Utils.valueToCurrency(m.get('revenue_sum')))
    @$('#profit_display').text(App.Utils.valueToCurrency(m.get('profit_sum')))
    @$('#fees_display').text(App.Utils.valueToCurrency(m.get('service_fee_sum')))
  #///////////////////////////////////////////////////////////////////////////

  filterParamMappings: () ->
    return {
      order_created_at_timestamptz:
        type: C.FilterTypes.DateRange
        el: '#date_range'
        val: @dateRangeFilter
      order_placer_id:
        type: C.FilterTypes.Select2
        el: '#csr'
        sendsId: true
      'order_price_total.gte':
        type: C.FilterTypes.Text
        el: '#amount_min'
      by_order_placer:
        type: C.FilterTypes.Select2Boolean
        el: '#group_by'
        trueVal: 'order_placer_id'
        elseFalse: false
      buyer_id:
        type: C.FilterTypes.Autocomplete
        el: '#customer'
        val: @filterCustomerId
        sendsId: true
      order_promo_code:
        type: C.FilterTypes.Text
        el: '#promo'
      'order_price_total.lte':
        type: C.FilterTypes.Text
        el: '#amount_max'
      order_credential_id:
        type: C.FilterTypes.Select2
        el: '#api_token'
        sendsId: true
        isMultiple: true
    }

  startLoad: () ->
    @tableLoader.startLoad()
    @buttonLoader.startLoad()

  stopLoad: () ->
    @tableLoader.stopLoad()
    @buttonLoader.stopLoad()

  initializeAutocomplete: () ->
    callback = (itemId) =>
      @filterCustomerId = itemId
    $el = @$('#customer')
    shouldReturnId = true
    options = {
      $el
      shouldReturnId
      callback
    }
    App.Utils.clientOfficeAutocomplete(options)

  initializeDropdowns: () ->
    App.Utils.createdByBrokerageUsersAutocomplete(@$('#csr'), true)
    App.Utils.apiTokenDropdown(@$('#api_token'))
    options = C.Options.Reports.SalesGrouping
    options = _.map(options, (val, key) ->
      return {
        id: key
        text: val
      }
    )
    @$('#group_by').select2({
      data: options
      initSelection: (element, callback) ->
        data = {
          id: element.val()
          text: C.Options.Reports.SalesGrouping[element.val()]
        }
        callback(data)
    })
    @$('#group_by').select2('val', 'order_created_at_date')

  getGroupBy: () ->
    return @$('#group_by').val()
