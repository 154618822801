module.exports = App.Utils.EndlessDataTable = (options) ->
  {
    @collection
    @filterView
    @dataTableOptions
  } = options

  blockFetching = false
  endlessScrollReady = false
  newRowArrayBuffer = []
  addTimeout = null
  WAIT_FOR_ANOTHER_ADD_DELAY = 500
  colSpanCount = 1
  INSERT_AFTER_SELECTOR = 'table.data-table tr:last'

  LOADING_HTML = () ->
    return "<tr>
      <td class='loading_div' colspan='#{ colSpanCount }' style='background: #f00; opacity: 0.7; height: 100px; font-size: 30px; line-height: 100px; text-align: center; color: #fff;'>
        <i class='fa-solid fa-rotate fa-spin fa-lg'></i>
        &nbsp&nbsp&nbsp
        LOADING
      </td>
    </tr>"

  DONE_HTML = () ->
    return "<tr>
      <td colspan='#{ colSpanCount }' style='background: #62c462; opacity: 0.7; height: 100px; font-size: 30px; line-height: 100px; text-align: center; color: #fff;'>
        END OF RESULTS
      </td>
    </tr>"

  if (!options.collection)
    console.log('Error: EndlessDataTable requires a collection option.')

  shouldEndlessScrollBeBlocked = () ->
    return blockFetching

  endlessScrollCallback = () =>
    @$loader = $(INSERT_AFTER_SELECTOR).after(LOADING_HTML())
    endlessScrollReady = true
    blockFetching = true
    if (@collection.fetchNextPage)
      @collection.fetchNextPage({
        data: (@filterView && @filterView.getFilterParamsOfCurrentResults && @filterView.getFilterParamsOfCurrentResults()) || {}
        success: (collection, response, options) =>
          collectionArray = null
          # Normal collection array.
          if (_.isArray(response))
            collectionArray = response
          else
            # Some responses may be collections wrapped with aggregates.
            collectionArray = _.first(
                _.filter(response, (item) ->
                  return _.isArray(item)
                )
            )
          if (!collectionArray.length)
            $('.loading_div').remove()
            $(INSERT_AFTER_SELECTOR).after(DONE_HTML())
      })
    else
      console.warn("Warning: The collection for your EndlessDataTable has no fetchNextPage function.  Consider extending App.Collections.Base.PaginatedBase.")

  onCollectionAddCallback = (model, collection) =>
    if (!model.toDataTableRowArray)
      console.log('Model must have toDataTableRowArray() method to work within EndlessDataTable.')

    if (endlessScrollReady && model.toDataTableRowArray)
      newRowArrayBuffer.push(model.toDataTableRowArray())
      # Instead of calling fnAddData for every model... we build up an array of models (rows)
      # and then once the 'add' events stop firing we send them all to the dataTable.
      if (addTimeout)
        clearTimeout(addTimeout)
      addTimeout = setTimeout(() =>
        @$dataTable.fnAddData(newRowArrayBuffer)
        newRowArrayBuffer = []
        blockFetching = false

        # BUG FIX - Combining EndlessScroll + DataTable gets hairy. This fixes it.
        # I think DataTable tries to adjust the height of it's container or something.
        @filterView.$('.endless_scroll_inner_wrap').css({ height: 'auto', width: 'auto '})

        if (@$loader)
          @$loader.remove()

      , WAIT_FOR_ANOTHER_ADD_DELAY)

  @initEndlessScroll = () =>
    # Setup endlessScroll.
    @$endless = @filterView.$('.dataTables_scrollBody')
    # Higher scoped var.
    colSpanCount = @filterView.$('table.data-table tr').eq(10).find('td').length

    @$endless.endlessScroll({
      fireDelay: 200
      fireOnce: true
      # We'll handle loader on our own since the endlessScroll plugin removes it before fetch is done.
      # We'll wait for fetch to complete and manage it ourselves.
      loader: '<div style="display: none"/>' # LOADING_HTML(colSpanCount),
      insertAfter: INSERT_AFTER_SELECTOR
      ceaseFire: shouldEndlessScrollBeBlocked
      bottomPixels: 50
      callback: endlessScrollCallback
    })

  # INITIALIZE
  # Setup dataTable.
  @$dataTable = @filterView.$('.data-table').dataTable(@dataTableOptions)
  # Collection add callback.
  @collection.on('add', onCollectionAddCallback)
  # Setup endless scroll.
  @initEndlessScroll()
  return @$dataTable
