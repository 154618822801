App.Collections.Base.PaginatedBase = require('../base/paginated_base_collection.coffee')
App.Models.V3.Order = require('../../models/v3/order_model.coffee')

parent = App.Collections.Base.PaginatedBase.prototype
module.exports = App.Collections.V3.Orders = App.Collections.Base.PaginatedBase.extend

  model: App.Models.V3.Order

  parentWrapper: null

  ordersOrPOs: null

  url: () ->
    if (@parentWrapper)
      return @parentWrapper.url()
    if (@params)
      params = []
      for own key, val of @params
        params.push("#{ key }=#{ val }")
      url = "/api_direct/v9/orders?#{ params.join('&') }"
      return url
    return "/api_direct/v9/orders"

  initialize: (models, options) ->
    parent.initialize.call(@, models, options)
    @totalEntries = null
    {
      @params
      @parentWrapper
    } = options

  parse: (response, options) ->
    @totalEntries = response.total_entries

    if (@parentWrapper)
      jsonCopyWithoutOrders = _.pick(response, 'total_entries', 'total_sum', 'balance_sum','commissions_sum')
      @parentWrapper.set(jsonCopyWithoutOrders)
      return response.orders

    return response.orders

  comparator: (orderModel) ->
    return 0 - orderModel.get('_createdAt')