template = require('./with_ticket_groups.ejs')
ticketGroupTableTemplate = require('./with_ticket_groups_table_only.ejs')

module.exports = App.Views.Events.WithTicketGroup = Backbone.View.extend

  template: template

  ticketGroupTableTemplate: ticketGroupTableTemplate

  className: 'ticket_group'

  initialize: (options = {}) ->
    {
      @eventsBeingPurchased
      @eventModel
      @newPOView
      parentSelector
    } = options
    @parent = $(parentSelector)

  events:
    'click .deleteButton': 'onDeleteTicketGroupClick'

  render: () ->
    row = @template({
      event: @eventModel.toPresenterJSON()
      ticketGroups: @eventModel.get('_ticketGroups').toPresenterJSON()
    })
    @$el.append(row)
    @$el.attr('data-event-id', @eventModel.get('id'))
    @parent.append(@$el)

  removeAll: (id) ->
    $(".ticket_groups [data-event-id='#{ id }']").remove()

  onDeleteTicketGroupClick: (e) ->
    ticketGroupCid = @$el.find(e.target).parents('tr').attr('data-ticket-group-cid')
    targetRow = $(".ticket_groups [data-ticket-group-cid='#{ ticketGroupCid }']")
    eventId = parseInt(@$el.attr('data-event-id'))
    eventModel = _.find(@eventsBeingPurchased.models, (eventModel) ->
      return eventModel.get('id') == eventId
    )
    ticketGroups = eventModel.get('_ticketGroups')
    ticketGroup = ticketGroups.get(ticketGroupCid)
    ticketGroups.remove(ticketGroup)
    @eventsBeingPurchased.store.set(eventModel)
    targetRow.remove()

    if (!ticketGroups.length)
      @eventsBeingPurchased.remove(eventModel)
      @eventsBeingPurchased.store.remove(eventModel.cid)
      @removeAll(eventModel.id)
      @newPOView.removeEmptyEvent(eventModel.id)

    @newPOView.summarizeTicketGroups()

  updateTicketGroups: () ->
    ticketGroups = @eventModel.get('_ticketGroups')
    template = @ticketGroupTableTemplate
    @$("table").replaceWith(template({
      event: @eventModel.toPresenterJSON()
      ticketGroups: @eventModel.get('_ticketGroups').toPresenterJSON()
    }))
