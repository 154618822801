App.Controllers.Base.BaseController = require('./base/base_controller.coffee')

module.exports = class App.Controllers.CreditMemoController extends App.Controllers.Base.BaseController

  _model = null
  _creditMemoView = null
  _$container = null
  _xhr = null

  routeIndex: ($container, encodedFilterParams) ->
    @displayCreditMemoIndex({
      $container
      shouldReset: true
      encodedFilterParams
    })

  resetCreditMemoIndex: () ->
    App.router.navigate('/credit_memos') # Don't trigger.
    @displayCreditMemoIndex({
      $container: _$container
      shouldReset: true
    })

  filterCreditMemoIndex: () ->
    encodedFilterParams = _creditMemoView.getEncodedFilterParams()
    App.router.navigate('/credit_memos/filter/' + encodedFilterParams)
    @displayCreditMemoIndex({
      $container: _$container
      shouldReset: false
      encodedFilterParams
    })

  displayCreditMemoIndex: (options) ->
    {
    $container
    shouldReset
    encodedFilterParams
    } = options
    isGoingToFetch = !!encodedFilterParams # bool cast
    _$container = $container

    if (_xhr)
      _xhr.abort()

    if (!_model || shouldReset)
      _model = new App.Models.V3.CreditMemoCollectionWrapper(null, {
        mapping: C.Mappings.Direct.CreditMemos.CreditMemoCollectionWrapper
      })

    if (!_creditMemoView || shouldReset)
      if (_creditMemoView)
        _creditMemoView.remove()

      _creditMemoView = new App.Views.CreditMemo.List({
        $container: _$container
        model: _model
      })

    if (isGoingToFetch)
      params = App.Utils.decodeFilterParams(encodedFilterParams)
      _creditMemoView.reflectFilterParams(params)
      params = _creditMemoView.getFilterParams()
      _xhr = _model.fetch({
        data: params
        reset: true
      })
      App.Utils.requestKiller.add(_xhr)
    else
      _model.get('collection').reset()
