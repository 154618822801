module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<% if (SESSION.role.can(App.Access.Resources.Inventory.AllTickets.MyTicketsActions.AddToQuote)) { %>\n\n<a href="/evoquote/new" data-toggle="tooltip" data-placement="bottom" title="EvoQuote" style="display: block;">\n  <span class="fa-stack">\n    <i class="fa-solid fa-stack-2x fa-comment"></i>\n    <i class="fa-solid fa-stack-1x fa-inverse fa-quote-right"></i>\n  </span>\n  <% if (App.EvoQuote.count()) { %>\n  <span class="label label-important" style="margin-top: 4px; margin-left: 7px;">\n    <%= App.EvoQuote.count() %>\n  </span>\n  <% } %>\n</a>\n\n<% } %>\n', __filename = "js/views/topnav/topnav_evoquote.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            if (SESSION.role.can(App.Access.Resources.Inventory.AllTickets.MyTicketsActions.AddToQuote)) {
                __append('\n\n<a href="/evoquote/new" data-toggle="tooltip" data-placement="bottom" title="EvoQuote" style="display: block;">\n  <span class="fa-stack">\n    <i class="fa-solid fa-stack-2x fa-comment"></i>\n    <i class="fa-solid fa-stack-1x fa-inverse fa-quote-right"></i>\n  </span>\n  ');
                __line = 8;
                if (App.EvoQuote.count()) {
                    __append('\n  <span class="label label-important" style="margin-top: 4px; margin-left: 7px;">\n    ');
                    __line = 10;
                    __append(escapeFn(App.EvoQuote.count()));
                    __append("\n  </span>\n  ");
                    __line = 12;
                }
                __append("\n</a>\n\n");
                __line = 15;
            }
            __append("\n");
            __line = 16;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}