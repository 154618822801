moment = require('moment')
module.exports = window.C = {}

# CONFIG FLAGS /////////////////////////////////////////////////////////////////
C.TakeForCart = false
C.TicketEvolutionOfficeId = 6
C.ForceNewOrderShow = true
C.EnableNewSeatingCharts = true
C.LatestBuildCheckInterval = 300000 # 5 mins

C.IncludeAllExchanges = 'INCLUDE ALL EXCHANGES'

C.DefaultCommission = 0.065

# EVENT CATEGORIES /////////////////////////////////////////////////////////////
C.EventCategories = {}
C.EventCategories.Sports = 1
C.EventCategories.Concerts = 54
C.EventCategories.Theatre = 68
C.EventCategories.SpecialEvents = 73

# ORDER TYPES //////////////////////////////////////////////////////////////////
C.OrderTypes = {}
C.OrderTypes.Direct = 'direct'
C.OrderTypes.Purchase = 'purchase'
C.OrderTypes.Sale = 'sale'
C.OrderTypes.Sell = 'sell'

# PATRON TYPES /////////////////////////////////////////////////////////////////
C.Client = 'client'
C.Office = 'office'

# DELIVERY TYPES ///////////////////////////////////////////////////////////////
C.Barcode               = 'Barcode'
C.Courier               = 'Courier'
C.Custom                = 'Custom'
C.Eticket               = 'Eticket'
C.FedEx                 = 'FedEx'
C.TMMobile              = 'TMMobile'
C.TMMobileLink          = 'TMMobileLink'
C.FlashSeats            = 'FlashSeats'
C.GuestList             = 'GuestList'
C.Paperless             = 'Paperless'
C.FedExFromClient       = 'FedExFromClient'
C.InstantDelivery       = 'InstantDelivery'
C.LocalPickup           = 'LocalPickup'
C.Offline               = 'Offline'
C.PickupAtOffice        = 'PickupAtOffice'
C.ProvidedAirbill       = 'ProvidedAirbill'
C.TBDShipment           = 'TBD'
C.UPS                   = 'UPS'
C.WillCall              = 'WillCall'

# ORDER OVERVIEW / BUYSELL ///////////////////////////////////////////////////
C.ExistingPhoneNumber = 'ExistingPhoneNumber'

# PAYMENT TYPES //////////////////////////////////////////////////////////////
C.Cash        = 'cash'
C.Check       = 'check'
C.CreditCard  = 'credit_card'
C.CreditMemo  = 'credit_memo'
C.EvoPay      = 'evopay'
C.ExternalCreditCard = 'external_credit_card'
C.MoneyOrder  = 'money_order'
C.NoPayment   = 'no_payment'
C.PayPal      = 'pay_pal'
C.TBDPayment  = 'tbd'

# TICKET FORMATS /////////////////////////////////////////////////////////////
C.TicketFormats = {}
C.TicketFormats.Physical    = 'Physical'
C.TicketFormats.Eticket     = 'Eticket'
C.TicketFormats.FlashSeats  = 'Flash_seats'
C.TicketFormats.Paperless   = 'Paperless'
C.TicketFormats.GuestList   = 'Guest_list'
C.TicketFormats.TMMobile    = 'TM_mobile'

# ORDER STATES /////////////////////////////////////////////////////////////////
C.Accepted =                'accepted'
C.CancelProposed =          'cancel_proposed'
C.Canceled =                'canceled'
C.CanceledOpen =            'canceled_open'
C.CanceledPostAcceptance =  'canceled_post_acceptance'
C.Completed =               'completed'
C.Open =                    'open'
C.Pending =                 'pending'
C.PendingConsignment =      'pending_consignment'
C.PendingSubstitution =     'pending_substitution'
C.Rejected =                'rejected'
C.UserCanceled =            'user_cancelled'

# TRIGGERABLE BACKBONE EVENTS ////////////////////////////////////////////////
# For use with trigger & listenTo.
C.Events = {}
C.Events.AddressCreated =             'AddressCreated'
C.Events.AddressViewChange =          'AddressViewChange'
C.Events.ClientFieldIdChange =        'ClientFieldIdChange'
C.Events.ClientFieldNameChange =      'ClientFieldNameChange'
C.Events.EmailFieldChange =           'EmailFieldChange'
C.Events.PatronAutocompleteChange =   'PatronAutocompleteChange'
C.Events.PaymentTypeChanged =         'PaymentTypeChanged'
C.Events.ShippingCostChange =         'ShippingPriceChange'
C.Events.SubtituteViewDoneLoading =   'SubtituteViewDoneLoading'
C.Events.SubtituteViewDoneSubbing =   'SubtituteViewDoneSubbing'

# MODEL PURPOSES (DYNAMICALLY CHANGE URL) ////////////////////////////////////
C.Purposes = {}
C.Purposes.AddToCart =                'AddToCart'
C.Purposes.ApplyCreditMemoPayment =   'ApplyCreditMemoPayment'
C.Purposes.CancelCreditMemoPayment =  'CancelCreditMemoPayment'
C.Purposes.CreateCreditMemoPayment =  'CreateCreditMemoPayment'
C.Purposes.CreateEvoQuote =           'CreateEvoQuote'
C.Purposes.CreateAdHocShipment =      'CreateAdHocShipment'
C.Purposes.CreateOrderPayment =       'CreateOrderPayment'
C.Purposes.CreateOrderReturn =        'CreateOrderReturn'
C.Purposes.CreateRefund =             'CreateRefund'
C.Purposes.DeleteEticket =            'DeleteEticket'
C.Purposes.DestroyEventPin =          'DeletePin'
C.Purposes.DestroyTicketGroup =       'DestroyTicketGroup'
C.Purposes.EditTicketGroup =          'EditTicketGroup'
C.Purposes.UpdateTicketGroup =        'UpdateTicketGroup'
C.Purposes.FetchBrokerageCards =      'FetchBrokerageCards'
C.Purposes.FetchEvoPayBalance =       'FetchEvoPayBalance'
C.Purposes.FetchOnlyShipmentInfo =    'FetchShipmentInfo'
C.Purposes.OrderAccept =              'OrderAccept'
C.Purposes.OrderAcceptConsignmentMoveUnsold = 'OrderAcceptConsignmentMoveUnsold'
C.Purposes.OrderCancel =              'OrderCancel'
C.Purposes.OrderSaveNote =            'OrderSaveNote'
C.Purposes.OrderSendPO =              'OrderSendPO'
C.Purposes.OrderReject =              'OrderReject'
C.Purposes.OrderItem =                'OrderItem'
C.Purposes.OrderItems =               'OrderItems'
C.Purposes.NewPO =                    'NewPO'
C.Purposes.SaveEtickets =             'SaveEtickets'
C.Purposes.SavePriceEdits =           'SavePriceEdits'
C.Purposes.SubOrder =                 'SubOrder'
C.Purposes.SubmitOrder =              'SubmitOrder'
C.Purposes.SubmitPO =                 'SubmitPO'
C.Purposes.TicketCosts =              'TicketCosts'
C.Purposes.UpdateCreditCard =         'UpdateCreditCard'

# BUYSELL PROCESS ////////////////////////////////////////////////////////////
C.BuySellActions = {}
C.BuySellActions.AffiliateSell =  'affiliatesell'
C.BuySellActions.Buy =            'buy'
C.BuySellActions.Sell =           'sell'
C.BuySellActions.BuySell =        'buySell'
C.BuySellActions.PO =             'po'
C.BuySellActions.Consignment =    'consignment'

C.Cart = {}
C.Cart.DefaultCart = 'default'

# E-TICKET ACTION CASES //////////////////////////////////////////////////////
C.EticketActions              = {}
C.EticketActions.Buy          = {}
C.EticketActions.Buy.Waiting  = 1  # buy side, no eticket yet
C.EticketActions.Buy.Download = 2  # buy side, eticket available
C.EticketActions.Sell         = {} #
C.EticketActions.Sell.Upload  = 3  # sell side, no pdfs
C.EticketActions.Sell.Manage  = 4  # sell side, pdfs uploaded, no pages finalized
C.EticketActions.Sell.View    = 5  # sell side, pages finalized & made available for buyer to download
C.EticketActions.Sell.Convert = 6  # sell side, no etickets

# DISPLAY BADGES FOR TYPE FLAGS //////////////////////////////////////////////

EticketBadgeText = () ->
  return if SESSION.role.can(App.Access.Resources.VerboseIconDescriptions)
  then 'E-tickets will be emailed as a PDF and must be printed out unless otherwise stated in the ticket group notes. Delivery fees may apply.'
  else 'PDF File (Print-at-Home or QR Codes)'

C.FlagBadges =
  AutoAccepted:       '<span class="tip-top badge" style="background: #0091ff;" data-toggle="tooltip" title="Auto Confirmed"><i class="fa-solid fa-gavel"></i></span>'
  Consignment:        '<span class="tip-top badge badge-warning" data-toggle="tooltip" title="Consignment">C</span>'
  Paperless:          '<span class="tip-top badge" style="background: #cc00ff;" data-toggle="tooltip" title="Paperless"><i class="fa-solid fa-credit-card"></i></span>'
  Notes:              '<span class="popover_notes badge badge-default"><i class="fa-solid fa-file-lines"/></span>'
  Spec:               '<span class="tip-top badge" style="background: #fff; color: #444;" data-toggle="tooltip" title="Speculative">S</span>'
  NotInHand:          '<span class="tip-top badge badge-important" data-toggle="tooltip" title="NOT In Hand"><i class="fa-solid fa-thumbs-down"></i></span>'
  No:                 '<span class="badge badge-warning">No</span>'
  Yes:                '<span class="badge badge-success">Yes</span>'
  InstantConfirm:     '<span class="badge badge-success instant-confirm" data-toggle="tooltip" title="Instant Confirm"><i class="fa-solid fa-arrow-right-arrow-left"></i></span>'
  Accessible:         '<span class="badge badge-success accessible" style="background: blue;" data-toggle="tooltip" title="Accessible"><i class="fa-solid fa-wheelchair-move"></i></span>'
  Physical: () ->
    text = if (SESSION.role.can(App.Access.Resources.VerboseIconDescriptions))
    then 'Physical tickets will be shipped via FedEx. Shipping/handling fees may apply. If tickets are purchased within a few days of the event date, please feel free to contact us to confirm delivery options. Events outside of North America are often delivered locally.'
    else 'Physical'

    return '<span class="tip-top badge badge-success" style="background: blue;" data-toggle="tooltip" title="' + text + '"><i class="fa-solid fa-ticket"></i></span>'
  InstantDelivery: () ->
    text = if (SESSION.role.can(App.Access.Resources.VerboseIconDescriptions))
    then 'Once your order has been accepted, tickets will be delivered within a few minutes. Delivery fees may apply.'
    else 'Instant Delivery'

    return '<span class="tip-top badge" style="background: #1CDB4A;" data-toggle="tooltip" title="' + text + '"><i class="fa-solid fa-bolt"></i></span>'
  FlashSeats: () ->
    text = if (SESSION.role.can(App.Access.Resources.VerboseIconDescriptions))
    then 'Please download the Flash Seats app and create an account to retrieve these tickets. Attendee(s) must display the tickets from the Flash Seats app when entering the venue. Delivery fees may apply.'
    else 'AXS'

    return '<span class="tip-top badge" style="background: #ff6; color: #444;" data-toggle="tooltip" title="' + text + '"><i class="fa-solid fa-bolt"></i></span>'
  GuestList: () ->
    text = if (SESSION.role.can(App.Access.Resources.VerboseIconDescriptions))
    then 'Attendees will gain entry to the event by presenting a government-issued ID that matches the attendee information provided at checkout. Handling fees may apply.'
    else 'Guest List'

    return '<span class="tip-top badge" style="background: #663300;" data-toggle="tooltip" title="' + text + '"><i class="fa-solid fa-list"></i></span>'
  TMMobile: () ->
    text = if (SESSION.role.can(App.Access.Resources.VerboseIconDescriptions))
    then 'These are mobile transfer tickets. The tickets will be transfered via a third-party mobile app and the recipient will receive email instructions on how to create their account and retrieve their tickets. These tickets cannot be printed out and must be displayed on a mobile phone to enter the event. Delivery Fees may apply.'
    else 'Mobile Transfer'

    return '<span class="tip-top badge" style="background: #ff0000;" data-toggle="tooltip" title="' + text + '"><i class="fa-solid fa-mobile-screen-button"></i></span>'
  InHand: () ->
    text = if (SESSION.role.can(App.Access.Resources.VerboseIconDescriptions))
    then 'Tickets will be ready for delivery once your order has been accepted.'
    else 'In Hand'

    return '<span class="tip-top badge" style="background: #0091ff;" data-toggle="tooltip" title="' + text + '"><i class="fa-solid fa-thumbs-up"></i></span>'
  InHandOn: (date) ->
    date = App.Utils.makeTimestampHuman(date, C.DateFormats.LongNoTime)

    text = if (SESSION.role.can(App.Access.Resources.VerboseIconDescriptions))
    then 'Tickets are expected to be ready for delivery by ' + date + '.'
    else 'Expected in hand on ' + date

    return '<span class="tip-top badge badge-important" data-toggle="tooltip" title="' + text + '"><i class="fa-solid fa-thumbs-down"></i></span>'
  Eticket: () -> '<span class="tip-top badge badge-success" data-toggle="tooltip" title="' + EticketBadgeText() + '">E</span>'
  EticketUploadedAll: () -> '<span class="tip-top badge badge-success" data-toggle="tooltip" title="' + EticketBadgeText() + '">E</span>'
  EticketUploadedIncomplete: () -> '<span class="tip-top badge badge-success" style="background: #1CDB4A;" data-toggle="tooltip" title="' + EticketBadgeText() + '">E</span>'


# FILE FILTERS FOR UPLOADS ///////////////////////////////////////////////////
C.FileFilters     = {}
C.FileFilters.PDF = 1
C.FileFilters.CSV = 2

# UPLOADABLE FILE TYPES //////////////////////////////////////////////////////
C.FilePurposes                  = {}
C.FilePurposes.OrderItemEticket = 1
C.FilePurposes.InventoryUpload  = 2
C.FilePurposes.TicketEticket    = 3
C.FilePurposes.UploadAirbill    = 4

# FILTER MAPPINGS #############################/
C.FilterTypes                      = {}
C.FilterTypes.DateRange            = 1
C.FilterTypes.Select2              = 2
C.FilterTypes.Select2Boolean       = 3
C.FilterTypes.Text                 = 4
C.FilterTypes.Autocomplete         = 5
C.FilterTypes.Grouping             = 6
C.FilterTypes.FixedValForSelection = 7
C.FilterTypes.Default              = 8

# FRESHNESS //////////////////////////////////////////////////////////////////
C.Freshness = {}
C.Freshness['POS'] = 1
C.Freshness['< 30 mins'] = 2
C.Freshness['< 60 mins'] = 3
C.Freshness['< 120 mins'] = 4
C.Freshness['< 180 mins'] = 5
C.Freshness['> 240 mins'] = C.Freshness.Max = 6

# DATE FORMATS ###############################
# Moment.js syntax
C.DateFormats = {}
C.DateFormats.DayOnly            = 'D MMMM YYYY'
C.DateFormats.DateSlashes        = 'MM/DD/YYYY'
C.DateFormats.Sortable           = 'YYYY-MM-DD'
C.DateFormats.SortableUnderscore = 'YYYY_MM_DD'
C.DateFormats.TableDate          = 'MM-DD-YYYY'
C.DateFormats.TableDateWithTime  = 'MM-DD-YYYY hh:mm a'
C.DateFormats.TableDateWithTimeAndZone  = 'MM-DD-YYYY hh:mm a z'
C.DateFormats.Dense              = 'ddd, MMM Do YYYY, h:mm a'
C.DateFormats.Long               = 'dddd, MMMM Do YYYY, h:mm a'
C.DateFormats.LongNoTime          = 'dddd, MMMM Do YYYY'
C.DateFormats.LongNoDay          = 'MMMM D, YYYY h:mm a'
C.DateFormats.File               = 'YYYY_MM_DD_HH_mm_ss'

# V2 MODEL ATTRIBUTE MAPPINGS #######################/
C.Mappings                                      = {}

# Unknown or doesn't matter.
C.Mappings.Unknown                              = -999

# When manually setting values from a form.
C.Mappings.Manual                               = -1000

#/api_direct/_____
# Please differentiate between the singular (show) endpoints, and the plural (index/list) endpoints.
# I.E. Event gives different fields than Events.
C.Mappings.Direct = {}

C.Mappings.Direct.Commissions = {}
C.Mappings.Direct.Commissions.Commission = 1370 # /api_direct/v9/commission_rules

C.Mappings.Direct.CreditCards = {}
C.Mappings.Direct.CreditCards.CreditCard = 1210 # /api_direct/v9/clients/123/credit_cards or
                                                 # /api_direct/v9/offices/1685/credit_cards?stored_for_id=123"
C.Mappings.Direct.CreditCards.Address = 1211

C.Mappings.Direct.Brokerages = {}
C.Mappings.Direct.Brokerages.Brokerage = 1170 # /api_direct/v9/brokerages/123

# Multiple carts.
C.Mappings.Direct.Carts = {}
C.Mappings.Direct.Carts.Address = 1000
C.Mappings.Direct.Carts.Carts = 1001  # /api_direct/v9/carts
C.Mappings.Direct.Carts.Event = 1002
C.Mappings.Direct.Carts.TicketGroups = 1003
C.Mappings.Direct.Carts.TicketGroupsTickets = 1004
C.Mappings.Direct.Carts.TicketHolds = 1005
C.Mappings.Direct.Carts.TicketHoldTickets = 1006
C.Mappings.Direct.Carts.Venue = 1007

# One single client.
C.Mappings.Direct.Clients = {}
C.Mappings.Direct.Clients.Client = 1010 # /api_direct/v9/clients/123
C.Mappings.Direct.Clients.Addresses = 1011
C.Mappings.Direct.Clients.EmailAddresses = 1012
C.Mappings.Direct.Clients.Office = 1013
C.Mappings.Direct.Clients.PhoneNumbers = 1014

# Multiple credit memos.
C.Mappings.Direct.CreditMemos = {}
C.Mappings.Direct.CreditMemos.CreditCard = 1290
C.Mappings.Direct.CreditMemos.CreditCardAddress = 1291
C.Mappings.Direct.CreditMemos.CreditMemo = 1292  #/api_direct/v9/credit_memos
C.Mappings.Direct.CreditMemos.CreditMemoCollectionWrapper = 1293
C.Mappings.Direct.CreditMemos.Holder = 1294
C.Mappings.Direct.CreditMemos.HolderAddress = 1295
C.Mappings.Direct.CreditMemos.HolderEmailAddresses = 1296
C.Mappings.Direct.CreditMemos.HolderPhoneNumbers = 1297
C.Mappings.Direct.CreditMemos.HolderBrokerage = 1298
C.Mappings.Direct.CreditMemos.Owner = 1299
C.Mappings.Direct.CreditMemos.OwnerAddress = 1300
C.Mappings.Direct.CreditMemos.OwnerBrokerage = 1301

# One single credit memo.
C.Mappings.Direct.CreditMemo = {}
C.Mappings.Direct.CreditMemo.CreditMemo = 1310  #/api_direct/v9/credit_memos/1234
C.Mappings.Direct.CreditMemo.Holder = 1311
C.Mappings.Direct.CreditMemo.HolderAddress = 1312
C.Mappings.Direct.CreditMemo.HolderEmailAddresses = 1313
C.Mappings.Direct.CreditMemo.HolderPhoneNumbers = 1314
C.Mappings.Direct.CreditMemo.HolderBrokerage = 1315
C.Mappings.Direct.CreditMemo.Owner = 1316
C.Mappings.Direct.CreditMemo.OwnerAddress = 1317
C.Mappings.Direct.CreditMemo.OwnerBrokerage = 1318
C.Mappings.Direct.CreditMemo.Payments = 1319
C.Mappings.Direct.CreditMemo.Transactions = 1320

# Multiple emails
C.Mappings.Direct.Emails = {}
C.Mappings.Direct.Emails.Email = 1330

#One single email
C.Mappings.Direct.Email = {}
C.Mappings.Direct.Email.Email = 1331

# Multiple events.
C.Mappings.Direct.Events = {} # WARNING: This is not a true direct endpoint.  It's actually /api/events.
C.Mappings.Direct.Events.Event = 1020 #show
C.Mappings.Direct.Events.Category = 1022
C.Mappings.Direct.Events.Configuration = 1023
C.Mappings.Direct.Events.Performers = 1024

C.Mappings.Direct.EventPins = {}
C.Mappings.Direct.EventPins.EventPin = 1350
C.Mappings.Direct.EventPins.Event = 1351
C.Mappings.Direct.EventPins.User = 1352
C.Mappings.Direct.EventPins.Venue = 1353

C.Mappings.Direct.PaymentOverviews = 1025 # /api_direct/v9/payments/status/

C.Mappings.Direct.EvoPayTransactions = {}
C.Mappings.Direct.EvoPayTransactions.EvoPayTransaction = 1230 # /api_direct/v9/transactions

C.Mappings.Direct.EvoQuotes                       = {}
C.Mappings.Direct.EvoQuotes.EvoQuote              = 1320
C.Mappings.Direct.EvoQuotes.Address               = 1321
C.Mappings.Direct.EvoQuotes.Event                 = 1322
C.Mappings.Direct.EvoQuotes.TicketGroup           = 1323
C.Mappings.Direct.EvoQuotes.Venue                 = 1324
C.Mappings.Direct.EvoQuotes.User                  = 1325
C.Mappings.Direct.EvoQuotes.Recepient             = 1326

# List of non-withdrwawable evopayments
C.Mappings.Direct.RestrictedEvopayOrders = {}
C.Mappings.Direct.RestrictedEvopayOrders.Payments = 1360

# One single office.
C.Mappings.Direct.Offices = {}
C.Mappings.Direct.Offices.Office = 1160 # /api_direct/v9/offices
C.Mappings.Direct.Offices.Address = 1161
C.Mappings.Direct.Offices.Brokerage = 1162
C.Mappings.Direct.Offices.BrokerageCreditCards = 1163
C.Mappings.Direct.Offices.EmailAddresses = 1164

C.Mappings.Direct.OrderShowLite = {}
C.Mappings.Direct.OrderShowLite.Order = 1030 # /api_direct/v9/orders/show_lite?id=74525
C.Mappings.Direct.OrderShowLite.BillingAddress = 1031
C.Mappings.Direct.OrderShowLite.Patron = 1032
C.Mappings.Direct.OrderShowLite.PatronAddress = 1033
C.Mappings.Direct.OrderShowLite.PatronAddresses = 1034
C.Mappings.Direct.OrderShowLite.PatronEmailAddresses = 1035
C.Mappings.Direct.OrderShowLite.PatronPhoneNumbers = 1036
C.Mappings.Direct.OrderShowLite.ChildOrder = 1037
C.Mappings.Direct.OrderShowLite.OrderItems = 1038
C.Mappings.Direct.OrderShowLite.Placer = 1039
C.Mappings.Direct.OrderShowLite.ShippingAddress = 1040
C.Mappings.Direct.OrderShowLite.Brokerage = 1041

# One single order.
C.Mappings.Direct.Order = {}
C.Mappings.Direct.Order.Order = 1250 # /api_direct/v9/orders/1234
C.Mappings.Direct.Order.Address = 1251
C.Mappings.Direct.Order.Addresses = 1252
C.Mappings.Direct.Order.Brokerage = 1253
C.Mappings.Direct.Order.ChildOrder = 1254
C.Mappings.Direct.Order.EmailAddresses = 1255
C.Mappings.Direct.Order.Event = 1256
C.Mappings.Direct.Order.Fraud = 1257
C.Mappings.Direct.Order.Office = 1258
C.Mappings.Direct.Order.OrderItems = 1259
C.Mappings.Direct.Order.Patron = 1260
C.Mappings.Direct.Order.Payments = 1261
C.Mappings.Direct.Order.PaymentsCreditCard = 1277
C.Mappings.Direct.Order.PaymentsCreditCardAddress = 1278
C.Mappings.Direct.Order.PhoneNumbers = 1262
C.Mappings.Direct.Order.ShipmentPhoneNumber = 1263
C.Mappings.Direct.Order.ShipmentSnapshot = 1264
C.Mappings.Direct.Order.ShipmentSnapshotAddress = 1265
C.Mappings.Direct.Order.Shipments = 1266
C.Mappings.Direct.Order.Substitution = 1267
C.Mappings.Direct.Order.SubstitutionEvent = 1268
C.Mappings.Direct.Order.SubstitutionOrderItem = 1269
C.Mappings.Direct.Order.SubstitutionOrderItemTicketGroup = 1270
C.Mappings.Direct.Order.SubstitutionVenue = 1271
C.Mappings.Direct.Order.SubstitutionVenueAddress = 1272
C.Mappings.Direct.Order.TicketGroup = 1273
C.Mappings.Direct.Order.Tickets = 1274
C.Mappings.Direct.Order.Venue = 1275
C.Mappings.Direct.Order.VenueAddress = 1276

# Multiple orders.
C.Mappings.Direct.Orders = {}
C.Mappings.Direct.Orders.Event = 1050 # /api_direct/v9/orders
C.Mappings.Direct.Orders.Orders = 1051
C.Mappings.Direct.Orders.OrderItems = 1052
C.Mappings.Direct.Orders.Payments = 1053
C.Mappings.Direct.Orders.TicketGroup = 1054
C.Mappings.Direct.Orders.Tickets = 1055
C.Mappings.Direct.Orders.Venue = 1056
C.Mappings.Direct.Orders.VenueAddress = 1057

C.Mappings.Direct.OrderItems = {}
C.Mappings.Direct.OrderItems.Address = 1286
C.Mappings.Direct.OrderItems.EticketThumbnail = 1281
C.Mappings.Direct.OrderItems.Event = 1283
C.Mappings.Direct.OrderItems.OrderItem = 1280 # /api_direct/v9/orders/123/items
C.Mappings.Direct.OrderItems.TicketGroup = 1282
C.Mappings.Direct.OrderItems.Tickets = 1284
C.Mappings.Direct.OrderItems.Venue = 1285

C.Mappings.Direct.OrdersLightweight = {}
C.Mappings.Direct.OrdersLightweight.Orders = 1060
C.Mappings.Direct.OrdersLightweight.Patron = 1061
C.Mappings.Direct.OrdersLightweight.OrderItems = 1062
C.Mappings.Direct.OrdersLightweight.Brokerage = 1063
C.Mappings.Direct.OrdersLightweight.Event = 1064
C.Mappings.Direct.OrdersLightweight.Venue = 1065
C.Mappings.Direct.OrdersLightweight.VenueAddress = 1066

C.Mappings.Direct.OrderItemsByEvent = {} # /api_direct/v9/orders/orders_by_event
C.Mappings.Direct.OrderItemsByEvent.OrderItems = 1070
C.Mappings.Direct.OrderItemsByEvent.Event = 1071
C.Mappings.Direct.OrderItemsByEvent.Venue = 1072
C.Mappings.Direct.OrderItemsByEvent.Address = 1073

C.Mappings.Direct.Shipments = {}
C.Mappings.Direct.Shipments.Address = 1240
C.Mappings.Direct.Shipments.Order = 1241
C.Mappings.Direct.Shipments.OrderItems = 1242
C.Mappings.Direct.Shipments.Shipment = 1243 # /api_direct/v9/shipments/12345

C.Mappings.Direct.ShipmentTypes = {}
C.Mappings.Direct.ShipmentTypes.ShipmentTypes = 1200 # /api_direct/v9/settings/shipping/

C.Mappings.Direct.ShipmentsStatus = {}
C.Mappings.Direct.ShipmentsStatus.Shipments = 1090 # /api_direct/v9/shipments/status
# This mapping targets shipments/index rather than shipments/status.
# It omits some customer and order attributes, but returns additional shipment information.
C.Mappings.Direct.ShipmentsStatus.ShipmentsInfo = 1091 # /api_direct/v9/shipments?info_only=true

C.Mappings.Direct.TicketCosts = {}
C.Mappings.Direct.TicketCosts.OrderItem = 1100 # /api_direct/v9/orders/12345/get_ticket_costs
C.Mappings.Direct.TicketCosts.Patron = 1101
C.Mappings.Direct.TicketCosts.PatronAddresses = 1102
C.Mappings.Direct.TicketCosts.PatronEmailAddresses = 1103
C.Mappings.Direct.TicketCosts.PatronPhoneNumbers = 1104
C.Mappings.Direct.TicketCosts.SoldOrderItem = 1105 # The order items in array sold_in_order_items within a C.Mappings.Direct.TicketCosts.OrderItem
C.Mappings.Direct.TicketCosts.SoldTickets = 1106

C.Mappings.Direct.Tickets = {}
C.Mappings.Direct.Tickets.Tickets = 1120
C.Mappings.Direct.Tickets.Eticket = 1121

C.Mappings.Direct.TicketGroups = {}
C.Mappings.Direct.TicketGroups.TicketGroup = 1130 # /api_direct/v9/ticket_groups/12345
C.Mappings.Direct.TicketGroups.Event = 1131
C.Mappings.Direct.TicketGroups.Office = 1132
C.Mappings.Direct.TicketGroups.Tickets = 1133
C.Mappings.Direct.TicketGroups.TicketList = 1134
C.Mappings.Direct.TicketGroups.Brokerage = 1135

C.Mappings.Direct.User = {}
C.Mappings.Direct.User.User = 1340
C.Mappings.Direct.User.Office = 1341
C.Mappings.Direct.User.Brokerage = 1342
C.Mappings.Direct.User.Phone = 1343

C.Mappings.Direct.TaxQuote = {}
C.Mappings.Direct.TaxQuote.TaxQuote = 1470

C.Mappings.Direct.Etickets = {}
C.Mappings.Direct.Etickets.SplitPdf = 1140 # /api_direct/v9/etickets/split_pdf

C.Mappings.Direct.FilteredTickets                = {}
C.Mappings.Direct.FilteredTickets.HeldTickets    = 1180
C.Mappings.Direct.FilteredTickets.TakenTickets   = 1181
C.Mappings.Direct.FilteredTickets.TicketGroup    = 1182
C.Mappings.Direct.FilteredTickets.Event          = 1183
C.Mappings.Direct.FilteredTickets.Venue          = 1184
C.Mappings.Direct.FilteredTickets.Address        = 1185

# Intentionally indirect
C.Mappings.Indirect = {}
C.Mappings.Indirect.RateOptions = {}
C.Mappings.Indirect.RateOptions.RateOption = 1220

# Old mappings.  Constant numbers not in 1000's.
C.Mappings.CreditMemos = {} # /api/credit_memos/
C.Mappings.CreditMemos.CreditMemo = {} # /api/credit_memos/123
C.Mappings.CreditMemos.CreditMemo.Holder          = 1
C.Mappings.CreditMemos.CreditMemo.Address         = 2
C.Mappings.CreditMemos.CreditMemo.Brokerage       = 3
C.Mappings.CreditMemos.CreditMemo.PhoneNumbers    = 4
C.Mappings.CreditMemos.CreditMemo.EmailAddresses  = 5

C.Mappings.EventEndpoint                          = {} # /api/events/my
C.Mappings.EventEndpoint.Category                 = 10
C.Mappings.EventEndpoint.Configuration            = 11
C.Mappings.EventEndpoint.Event                    = 12
C.Mappings.EventEndpoint.Performer                = 13
C.Mappings.EventEndpoint.Venue                    = 14

C.Mappings.EvoPayTransactions                     = {} # /api/transactions
C.Mappings.EvoPayTransactions.Patron              = 20
C.Mappings.EvoPayTransactions.Address             = 21
C.Mappings.EvoPayTransactions.Brokerage           = 22

C.Mappings.FilteredTicketsEndpoint                = {}
C.Mappings.FilteredTicketsEndpoint.HeldTickets    = 40
C.Mappings.FilteredTicketsEndpoint.TakenTickets   = 41

C.Mappings.TicketGroupEndpoint                    = {}
C.Mappings.TicketGroupEndpoint.TicketGroup        = 50
C.Mappings.TicketGroupEndpoint.Office             = 51
C.Mappings.TicketGroupEndpoint.Brokerage          = 52
C.Mappings.TicketGroupEndpoint.Event              = 53
C.Mappings.TicketGroupEndpoint.Venue              = 54
C.Mappings.TicketGroupEndpoint.Tickets            = 55
C.Mappings.TicketGroupEndpoint.TicketList         = 56

C.Mappings.TicketHolds                            = {}
C.Mappings.TicketHolds.TicketHolds                = 140

C.Mappings.OfficeEndpoint                         = {}
C.Mappings.OfficeEndpoint.Address                 = 60
C.Mappings.OfficeEndpoint.Brokerage               = 61
C.Mappings.OfficeEndpoint.Office                  = 62

C.Mappings.OrderEndpoint                          = {} # /orders
C.Mappings.OrderEndpoint.Address                  = 70
C.Mappings.OrderEndpoint.Brokerage                = 71
C.Mappings.OrderEndpoint.Event                    = 72
C.Mappings.OrderEndpoint.Order                    = 73
C.Mappings.OrderEndpoint.OrderItem                = 74
C.Mappings.OrderEndpoint.Patron                   = 75 # orders -> buyer
C.Mappings.OrderEndpoint.PatronAddress            = 76
C.Mappings.OrderEndpoint.PatronEmail              = 77
C.Mappings.OrderEndpoint.PatronPhoneNumber        = 78
C.Mappings.OrderEndpoint.OrderShipment            = 79
C.Mappings.OrderEndpoint.TicketGroup              = 80 # orders -> items -> ticket_group
C.Mappings.OrderEndpoint.Tickets                  = 81
C.Mappings.OrderEndpoint.EticketThumbnail         = 82
C.Mappings.OrderEndpoint.Venue                    = 83

C.Mappings.V2OrderEndpoint                        = {}
C.Mappings.V2OrderEndpoint.Order                  = 90
C.Mappings.V2OrderEndpoint.OrderItem              = 91
C.Mappings.V2OrderEndpoint.Event                  = 92
C.Mappings.V2OrderEndpoint.Patron                 = 93
C.Mappings.V2OrderEndpoint.Brokerage              = 94
C.Mappings.V2OrderEndpoint.Address                = 95
C.Mappings.V2OrderEndpoint.Fraud                  = 96

C.Mappings.MassIndexEndpoint                      = {}
C.Mappings.MassIndexEndpoint.Event                = 100
C.Mappings.MassIndexEndpoint.TicketGroup          = 101
C.Mappings.MassIndexEndpoint.Venue                = 102
C.Mappings.MassIndexEndpoint.Address              = 103

C.Mappings.HeldTicketEndpoint                     = {}  #  /api/events/382019/ticket_groups/36277907/ticket_holds
C.Mappings.HeldTicketEndpoint.TicketHolds         = 110

C.Mappings.SingleEventEndpoint                    = {}
C.Mappings.SingleEventEndpoint.Event              = 120

C.Mappings.SingleTicketGroupEndpoint              = {}
C.Mappings.SingleTicketGroupEndpoint.TicketGroup  = 130
C.Mappings.SingleTicketGroupEndpoint.Office       = 131
C.Mappings.SingleTicketGroupEndpoint.Brokerage    = 132
C.Mappings.SingleTicketGroupEndpoint.Event        = 133
C.Mappings.SingleTicketGroupEndpoint.Ticket       = 134

# INLINE FEEDBACK MESSAGE TYPES ######################/
C.MessageTypes         = {}
C.MessageTypes.Error   = 'error'
C.MessageTypes.Info    = 'info'
C.MessageTypes.Success = 'success'

# OPTIONS #################################/
C.Options                  = {}
C.Options.CreditMemo       = {}

C.Options.CreditMemo.Types =
  all:    'All'
  client: 'Client'
  office: 'Office'

C.Options.CreditMemo.States =
  all:    'All'
  open:   'Open'
  closed: 'Closed'

C.Options.Delivery       = {}
C.Options.Delivery.Types =
  'all'                      :  'All'
  'Delivery::Courier'        :  'Courier'
  'Delivery::Custom'         :  'Custom'
  'Delivery::Eticket'        :  'Eticket'
  'Delivery::FedEx'          :  'FedEx'
  'Delivery::LocalPickup'    :  'Local Pick Up'
  'Delivery::Offline'        :  'Offline'
  'Delivery::PickupAtOffice' :  'Office Pick Up'
  'Delivery::TBD'            :  'TBD'
  'Delivery::UPS'            :  'UPS'
  'Delivery::WillCall'       :  'Will Call'

C.Options.Carrier         = {}
C.Options.Carrier.Types =
  'fedex'  : 'FedEx'
  'ups'    : 'UPS'
  'usps'   : 'USPS'
  'dhl'    : 'DHL'
  'other'  : 'Other'

C.Options.Shipment = {}
C.Options.Shipment.States =
  'all'          :  'All'
  'pending'      :  'Pending'
  'in_transit'   :  'In Transit'
  'in_exception' :  'In Exception'
  'delivered'    :  'Delivered'
  'not_delivered':  'Not Delivered'
  'returned'     :  'Returned'
  'canceled'     :  'Canceled'

C.Options.Shipment.ServiceTypes =
  'LEAST_EXPENSIVE'                         : 'Least Expensive'
  'FEDEX_EXPRESS_SAVER'                     : 'FedEx Express Saver'
  'FEDEX_2_DAY'                             : 'FedEx 2 Day'
  'STANDARD_OVERNIGHT'                      : 'Standard Overnight'
  'PRIORITY_OVERNIGHT'                      : 'Priority Overnight'
  'FIRST_OVERNIGHT'                         : 'First Overnight'
  'FEDEX_2_DAY_SATURDAY_DELIVERY'           : 'FedEx 2 Day Saturday Delivery'
  'PRIORITY_OVERNIGHT_SATURDAY_DELIVERY'    : 'Priority Overnight Saturday Delivery'
  'INTERNATIONAL_ECONOMY'                   : 'International Economy'
  'INTERNATIONAL_PRIORITY'                  : 'International Priority'
  'INTERNATIONAL_FIRST'                     : 'International First'
  'INTERNATIONAL_PRIORITY_SATURDAY_DELIVERY': 'International Priority Saturday Delivery'

C.Options.Emails        = {}
C.Options.Emails.States =
  'all'             : 'All'
  'sentToProvider'  : 'Sent to provider'
  'processed'       : 'Processed'
  'delivered'       : 'Delivered'
  'bounced'         : 'Bounced'
  'clicked'         : 'Clicked'
  'ppened'          : 'Opened'
  'unsubscribed'    : 'Unsubscribed'
  'markedAsSpam'    : 'Marked as spam'
  'dropped'         : 'Dropped'
  'failed'          : 'Failed'
  'deferred'        : 'Deferred'
  'pending'         : 'Pending'

C.Options.EvoPay = {}
C.Options.EvoPay.States =
  all       : 'All'
  pending   : 'Pending'
  completed : 'Completed'
  exported  : 'Exported'
  canceled  : 'Canceled'

C.Options.EvoPay.Types =
  deposit    : 'Deposit'
  withdrawal : 'Withdrawal'
  transfer   : 'Transfer'
  fee        : 'Fee'
  all        : 'All'

C.Options.EvoPay.CreditType =
  credit    : 'Credits - Receiving'
  debit     : 'Debits - Paying'
  all       :  'All'

C.Options.EvoPay.BrokerType =
  broker    : 'Broker'
  tevo      : 'TEvo'
  all       : 'All'

C.Options.EvoQuote = {}
C.Options.EvoQuote.States =
  all       : 'All'
  expired   : 'Expired'
  closed    : 'Closed'
  converted : 'Converted'
  pending   : 'Pending'

C.Options.Order = {}
C.Options.Order.States =
  pending                  : 'Pending'
  accepted                 : 'Accepted'
  completed                : 'Completed'
  rejected                 : 'Rejected'
  expired                  : 'Expired'
  open                     : 'Open'
  canceled                 : 'Canceled'
  canceled_open            : 'Canceled (From Open)'
  cancel_proposed          : 'Cancel Proposed'
  canceled_post_acceptance : 'Canceled Post Acceptance'
  pending_substitution     : 'Pending Substitution'
  pending_consignment      : 'Pending Consignment'

C.Options.Order.Balance =
  all     : 'All'
  with    : 'With a Balance'
  without : 'Without a Balance'

C.Options.Order.Consignment =
  all             : 'All'
  consignment     : 'Consignment'
  not_consignment : 'Not Consignment'

C.Options.Order.Delivery =
  'all'             :  'All'
  'courier'         :  'Courier'
  'custom'          :  'Custom'
  'eticket'         :  'Eticket'
  'fed_ex'          :  'FedEx'
  'local_pickup'    :  'Local Pick Up'
  'offline'         :  'Offline'
  'pickup_at_office':  'Office Pick Up'
  'tbd'             :  'TBD'
  'ups'             :  'UPS'
  'will_call'       :  'Will Call'

# See order_helpers.rb line 156
# :type => obj.transaction.class.name.tableize.gsub('_transactions', ''),
C.Options.Order.PaymentTypes =
  'all':         'All'
  'evopay':      'EvoPay'
  'credit_card': 'Credit Card'
  'tbd':         'TBD'
  'cash':        'Cash'
  'check':       'Check'
  'credit_memo': 'Credit Memo'
  'money_order': 'Money Order'
  'offline':     'Offline'
  'pay_pal':     'PayPal'
  'penalty':     'Penalty'
  'return':      'Return'
  [C.ExternalCreditCard]: 'External Credit Card'

C.Options.Order.SpecFilled =
  all        : 'All'
  filled     : 'Filled'
  not_filled : 'Not Filled'

C.Options.Order.NeedsSub =
  all       : 'All'
  yes       : 'Yes'
  no        : 'No'

C.Options.Order.EticketsMissing =
  all         : 'All'
  missing     : 'Yes (Needs E-Eticket)'
  not_missing : 'No (E-Tickets Uploaded)'

C.Options.Order.EticketsNotDownloaded =
  all     : 'All'
  yes     : 'Not Downloaded Yet'

C.Options.Payment = {}
C.Options.Payment.PaymentTypes =
  'all'                   : 'All'
  'EvopayTransaction'     : 'EvoPay'
  'CreditCardTransaction' : 'Credit Card'
  'TBDTransaction'        : 'TBD'
  'CashTransaction'       : 'Cash'
  'CheckTransaction'      : 'Check'
  'CreditMemoTransaction' : 'Credit Memo'
  'MoneyOrderTransaction' : 'Money Order'
  'OfflineTransaction'    : 'Offline'
  'PayPalTransaction'     : 'PayPal'
  'PenaltyTransaction'    : 'Penalty'
  'ReturnTransaction'     : 'Return'

C.Options.Payment.OrderTypes =
#'all'           : 'All'
  order          : 'Order'
  purchase_order : 'PO'

C.Options.Payment.States =
  'all'                 : 'All'
  'authorized'          : 'Authorized'
  'canceled'            : 'Canceled'
  'captured'            : 'Captured'
  'completed'           : 'Completed'
  'failed_to_authorize' : 'Failed to Authorize'
  'failed_to_capture'   : 'Failed to Capture'
  'failed_to_void'      : 'Failed to Void'
  'pending'             : 'Pending'
  'processed_offline'   : 'Processed Offline'
  'refunded'            : 'Refunded'
  'voided'              : 'Voided'

C.Options.Payment.TransactionTypes =
  'all'     : 'All'
  'payment' : 'Payment'
  'return'  : 'Return'
  'refund'  : 'Refund'

C.Options.Reports = {}

C.Options.Reports.SalesGrouping =
  order_created_at_date : 'Date'
  order_placer_id       : 'CSR'

C.Options.TicketGroup = {}
C.Options.TicketGroup.BroadcastOptions =
  all:  'All'
  on:   'Broadcast'
  off:  'Unbroadcast'
C.Options.TicketGroup.BroadcastOptionsAlt =
  all:  'All'
  on:   'Broadcasted'
  off:  'Unbroadcasted'

C.Options.TicketGroup.InHandStates =
  all       : 'All'
  inHand    : 'In Hand'
  notInHand : 'Not In Hand'

C.Options.TicketGroup.InHandStatesNoAll =
  inHand    : 'In Hand'
  notInHand : 'Not In Hand'

C.Options.TicketGroup.Spec =
  all:      'All'
  spec:     'Only Spec'
  notSpec:  'Only Real'

C.Options.TicketGroup.RetailOverrideStates =
  all       : 'All'
  yes       : 'Has Override'
  no        : 'Does Not Have Override'

C.Options.TicketGroup.States =
  available : 'Available'
  reserved  : 'Reserved'
  sold      : 'Sold'
  wasted    : 'Wasted'

C.Options.TransferSource =
  AXS: "AXS Resale"
  AM: "Account Manager"
  BA: "Ballpark App"
  DICE: "Dice.fm"
  MPV: "MyProVenue"
  PAC: "Paciolan"
  SG: "SeatGeek"
  SH: "StubHub"
  RDP: "Tickets.com"
  TM: "Ticketmaster.com"
  VL: "Victory Live"

C.TransferSource = {}
C.TransferSource.AXS = 'AXS'
C.TransferSource.AM = 'AM'
C.TransferSource.BA = 'BA'
C.TransferSource.DICE = 'DICE'
C.TransferSource.MPV = 'MPV'
C.TransferSource.PAC = 'PAC'
C.TransferSource.RDP = 'RDP'
C.TransferSource.SG = 'SG'
C.TransferSource.SH = 'SH'
C.TransferSource.TM = 'TM'

C.Options.TicketGroup.Types =
#all     : 'All'
  event   : 'Event'
  parking : 'Parking'

C.Options.TicketGroup.Formats =
  Physical: 1
  Eticket:  2
  FlashSeats: 3
  Paperless: 4
  GuestList: 5


C.Options.TicketGroup.ViewTypes =
  All                    : 1
  Full                   : 1
  Obstructed             : 1
  'Possibly Obstructed'  : 1
  'Partially Obstructed' : 1

C.Options.TicketGroup.ViewTypesNoAll =
  'Full'                 : 1
  'Obstructed'           : 1
  'Possibly Obstructed'  : 1
  'Partially Obstructed' : 1

C.Options.TicketGroup.PriceModOptions =
  'Set To'     : 1
  'Flat'       : 1
  'Percentage' : 1

C.Options.TicketGroup.WheelchairOptions =
  'Wheelchair'     : 1
  'Not Wheelchair' : 1

C.Options.Inventory = {}
C.Options.Inventory.Exchanges =
  11 :  "Event Inventory"
  5  :  "Vivid"
  1  :  "Ticket Evolution"
  6  :  "Stubhub"
  2  :  "Razorgator"
  15 :  "NATB"
  14 :  "ListEasy"
  12 :  "Ticket City"
  3  :  "Ticket Technology"
  8  :  "Fanxchange"
  10 :  "SeatGeek"
  16 :  "Scorebig"
  17 :  "Viagogo Export"
  18 :  "Seatwave"
  4  :  "Viagogo"
  21 :  "Tickpick"
  7  :  "SeatQuest"
  19 :  "Tickets on the Fly"
  13 :  "Rocket Poster"
  20 :  "Zigabid"

C.Options.Inventory.UploadStatus =
  'Done'        : "<i class='fa-solid fa-circle-check' style='font-size: 18pxcolor: green'></i></span> <span style='margin-left: 5px'>Complete</span>"
  'Ignored'     : "<i class='fa-solid fa-circle-xmark' style='font-size: 18pxcolor: red'></i> <span style='margin-left: 5px'>Ignored</span>"
  'Pending'     : "<i class='fa-solid fa-pause' style='font-size: 18pxcolor: #C2C920'></i> <span style='margin-left: 5px'>Pending</span>"
  'Downloading' : "<i class='fa-solid fa-download' style='font-size: 18pxcolor: #4E82F0'></i> <span style='margin-left: 5px'>In Progress</span>"
  'Queued'      : "<i class='fa-solid fa-calendar-days' style='font-size: 18pxcolor: #f0ad4e'></i> <span style='margin-left: 5px'>Queued</span>"
  'Not Queued'  : "<span class='fa-stack'><i class='fa-solid fa-stack-2x fa-calendar-days' style='font-size: 18pxcolor: #f0ad4e'></i><i class='fa-ban fa-stack-1x text-error'></i></span> <span style='margin-left: 5px'>Not Queued</span>"
  'Unknown'     : "<i class='fa-solid fa-circle-question' style='font-size: 18pxcolor: #AB41CC'></i> <span style='margin-left: 5px'>Status Unknown</span>"

# SEATING #################################/
C.SeatOrder             = {}
C.SeatOrder.OddEven     = 'odd_even'
C.SeatOrder.Consecutive = 'consecutive'

# URLS ###################################
C.URLS                   = {}
C.URLS.Reports           = {}
C.URLS.Reports.Sales     = '/reports/sales'

# DATEPICKER ################################
C.Times = {}
C.Times.TodayBegin = moment().startOf('day')
C.Times.TodayEnd = moment().endOf('day')
C.Times.YesterdayBegin = moment().subtract(1, 'days').startOf('day')
C.Times.End7 = moment().add(7, 'days').endOf('day')
C.Times.End14 = moment().add(14, 'days').endOf('day')
C.Times.End30 = moment().add(30, 'days').endOf('day')
C.Times.End60 = moment().add(60, 'days').endOf('day')
C.Times.End90 = moment().add(90, 'days').endOf('day')
C.Times.Begin7 = moment().subtract(7, 'days').startOf('day')
C.Times.Begin30 = moment().subtract(30, 'days').startOf('day')
C.Times.Begin60 = moment().subtract(60, 'days').startOf('day')
C.Times.Begin90 = moment().subtract(90, 'days').startOf('day')

C.Dates           = {}
C.Dates.Today     = [C.Times.TodayBegin, C.Times.TodayEnd]
C.Dates.Tomorrow  = [moment().add(1, 'days').startOf('day'), moment().add(1, 'days').endOf('day')]
C.Dates.Next7     = [C.Times.TodayBegin, C.Times.End7]
C.Dates.Next14    = [C.Times.TodayBegin, C.Times.End14]
C.Dates.Next30    = [C.Times.TodayBegin, C.Times.End30]
C.Dates.Next60    = [C.Times.TodayBegin, C.Times.End60]
C.Dates.Next90    = [C.Times.TodayBegin, C.Times.End90]
C.Dates.Yesterday = [C.Times.YesterdayBegin, C.Times.TodayEnd]
C.Dates.Last7     = [C.Times.Begin7, C.Times.TodayEnd]
C.Dates.Last30    = [C.Times.Begin30, C.Times.TodayEnd]
C.Dates.Last60    = [C.Times.Begin60, C.Times.TodayEnd]
C.Dates.Last90    = [C.Times.Begin90, C.Times.TodayEnd]
C.Dates.ThisMonth = [moment().startOf('month'), moment().endOf('month')]
C.Dates.ThisYear  = [C.Times.TodayBegin, moment().endOf('year')]
C.Dates.LastMonth = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]

C.DatePickerConfig = {}

C.DatePickerConfig.Past =
  startDate: moment().subtract(30, 'days'),
  ranges:
    'Today': C.Dates.Today
    'Last 7 Days': C.Dates.Last7
    'Last 30 Days': C.Dates.Last30
    'Last 60 Days': C.Dates.Last60

C.DatePickerConfig.Future =
  endDate: moment().add(30, 'days'),
  ranges:
    'Today': C.Dates.Today
    'Next 7 Days': C.Dates.Next7
    'Next 30 Days': C.Dates.Next30
    'Next 60 Days': C.Dates.Next60

C.DatePickerConfig.TimePicker =
  singleDatePicker: true,
  timePicker: true

C.DatePickerConfig.SingleDatePicker =
  singleDatePicker: true

C.Months = [
  "January"
  "February"
  "March"
  "April"
  "May"
  "June"
  "July"
  "August"
  "September"
  "October"
  "November"
  "December"
]

C.SERVICE_FEE_PRECENTAGE = 0.03

C.AFFILIATE_SUB_DOMAINS = {
  dev: {},
  staging: {},
  sandbox: {},
  production: {
    3020: { subdomain: 'departurelounge'},
    2979: { subdomain: 'shoppetravel'},
    2988: { subdomain: 'travelbygagnon'},
    2984: { subdomain: 'travelsmiths'},
    3033: { subdomain: 'lnmtravel'},
    2967: { subdomain: 'aariusinternational'},
    2964: { subdomain: 'yourtravelution'},
    2761: { subdomain: 'inteletravel'},
    2765: { subdomain: 'frosch'},
    3031: { subdomain: 'jetsetworldtravel'},
    3001: { subdomain: 'showtimetravel'},
    3029: { subdomain: 'jbltravelgroup'},
    3003: { subdomain: 'risenflytravel'},
    2977: { subdomain: 'travelstore'},
    3048: { subdomain: 'destinytravelandtoursllc'},
    3077: { subdomain: 'giggetaways'},
    3076: { subdomain: 'ticketsavants'},
    3194: { subdomain: 'huffmantravel'},
    3582: { subdomain: 'duchesstravel'},
    4570: { subdomain: 'bookheregivehere'},
    5560: { subdomain: 'tickets', domain: 'aspirelifestyles.com'},
    5019: { subdomain: 'events', domain: 'largaytravel.com'},
  },
}

C.URLS = {
  dev: {
    ims_splitter : 'https://beta.ims.1ticket.com/1tmd-splitter',
    events365_url: 'https://staging.events365.com'
  },
  staging: {
    ims_splitter : 'https://beta.ims.1ticket.com/1tmd-splitter',
    events365_url: 'https://staging.events365.com'
  },
  sandbox: {
    ims_splitter : 'https://beta.ims.1ticket.com/1tmd-splitter',
    events365_url: 'https://sandbox.events365.com'
  },
  production: {
    ims_splitter : 'https://ims.1ticket.com/1tmd-splitter',
    events365_url: 'https://events365.com'
  }
}

C.EXTERNAL_URLS = {
  ims_itmd_domain   : 'https://downloadmyseats.com',
  access_to_tickets : [
    'https://downloadmyseats.com',
    'https://secure.tickets',
    'https://taciyon.com',
    'https://tm.amosa.app',
    'https://verified-ticket.com'
  ]
}

C.SupportEmail = 'sellersupport@ticketevolution.com'
