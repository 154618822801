App.Models.CreditCard = require('../models/credit_card_model');

module.exports = App.Collections.CreditCards = Backbone.Collection.extend({

  model: App.Models.CreditCard,

  initialize: function(models, opts) {
    options = opts || {};
    this.ownerType = options.ownerType || C.Client;
    this.ownerId = options.clientId || options.ownerId;
    this.storedForId = options.storedForId;
    if (this.ownerType == C.Client) {
      this.url = '/' + this.ownerType.pluralize() + '/' + this.ownerId + '/credit_cards';
    } else {
      this.url = '/' + this.ownerType.pluralize() + '/' + this.ownerId + '/credit_cards?stored_for_id=' + this.storedForId + '';
    }
  }

});

