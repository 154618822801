module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div style="margin-top: 20px;">\n  <strong>\n    <%= shipNameLabel %>\n  </strong>\n</div>\n<div><%= shipment.shipToName %></div>\n\n<div style="margin-top: 20px;">\n  <strong>\n    <%= shipCompanyLabel %>\n  </strong>\n</div>\n<div><%= shipment.shipToCompanyName %></div>\n\n<div style="margin-top: 20px;">\n  <strong>\n    Phone Number\n  </strong>\n</div>\n<div><%= shipment.phoneNumber.humanized %></div>\n\n<div style="margin-top: 20px;">\n  <strong>\n    Address\n  </strong>\n</div>\n<div><%- address.multiLine %></div>\n\n<% if (isTo) { %>\n\n<div style="margin-top: 20px;">\n  <strong>\n    Rate Options\n  </strong>\n</div>\n<div><%= shipment.serviceTypeDisplay %></div>\n\n<div style="margin-top: 20px;">\n  <strong>\n    Residential?\n  </strong>\n</div>\n<div><%= shipment.residential ? \'Yes\' : \'No\' %></div>\n\n<div style="margin-top: 20px;">\n  <strong>\n    Signature Type\n  </strong>\n</div>\n<div><%= shipment.signatureType %></div>\n\n<% } %>', __filename = "js/views/shipping/shipment_new_preview.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div style="margin-top: 20px;">\n  <strong>\n    ');
            __line = 3;
            __append(escapeFn(shipNameLabel));
            __append("\n  </strong>\n</div>\n<div>");
            __line = 6;
            __append(escapeFn(shipment.shipToName));
            __append('</div>\n\n<div style="margin-top: 20px;">\n  <strong>\n    ');
            __line = 10;
            __append(escapeFn(shipCompanyLabel));
            __append("\n  </strong>\n</div>\n<div>");
            __line = 13;
            __append(escapeFn(shipment.shipToCompanyName));
            __append('</div>\n\n<div style="margin-top: 20px;">\n  <strong>\n    Phone Number\n  </strong>\n</div>\n<div>');
            __line = 20;
            __append(escapeFn(shipment.phoneNumber.humanized));
            __append('</div>\n\n<div style="margin-top: 20px;">\n  <strong>\n    Address\n  </strong>\n</div>\n<div>');
            __line = 27;
            __append(address.multiLine);
            __append("</div>\n\n");
            __line = 29;
            if (isTo) {
                __append('\n\n<div style="margin-top: 20px;">\n  <strong>\n    Rate Options\n  </strong>\n</div>\n<div>');
                __line = 36;
                __append(escapeFn(shipment.serviceTypeDisplay));
                __append('</div>\n\n<div style="margin-top: 20px;">\n  <strong>\n    Residential?\n  </strong>\n</div>\n<div>');
                __line = 43;
                __append(escapeFn(shipment.residential ? "Yes" : "No"));
                __append('</div>\n\n<div style="margin-top: 20px;">\n  <strong>\n    Signature Type\n  </strong>\n</div>\n<div>');
                __line = 50;
                __append(escapeFn(shipment.signatureType));
                __append("</div>\n\n");
                __line = 52;
            }
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}