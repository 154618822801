App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.Ticket = App.Models.Base.V3BaseModel.extend

  url: () ->
    if (@purpose == C.Purposes.DeleteEticket)
      return "/api_direct/v9/tickets/#{ @id }"

  toJSON: (options) ->
    if (@purpose == C.Purposes.DeleteEticket)
      json = {
        eticket: ''
      }
    else
      json = parent.toJSON.call(@, options)
    return json

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.OrderItems.Tickets)
      attrs._purchaseOrderId = json.purchase_order_id
      attrs._seat = json.seat
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Tickets.Tickets)
      attrs._barcode = json.barcode
      if (json.eticket)
        eticketAttrs = _.extend(json.eticket, {
          barcode: json.barcode
          seat: json.seat
          ticketId: json.id
        })
        attrs._eticket = new App.Models.V3.Eticket(eticketAttrs, {
          mapping: C.Mappings.Direct.Tickets.Eticket
        })
      attrs._eticketId = json.eticket_id
      attrs._id = json.id
      attrs._seat = json.seat

    if (@getMapping() == C.Mappings.Direct.Order.Tickets)
      attrs._purchaseOrderId = json.purchase_order_id
      attrs._seat = json.seat
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.OrderEndpoint.Tickets)
      attrs._purchaseOrderId = json.purchase_order_id
      attrs._seat = json.seat

    if (@getMapping() == C.Mappings.Direct.Carts.TicketHoldTickets)
      attrs._id = json.id
      attrs._seat = json.seat
      attrs._ticketGroupId = json.ticket_group_id

    if (@getMapping() == C.Mappings.Direct.TicketGroups.TicketList)
      attrs._cost = json.cost
      attrs._eticketId = json.eticket_id
      attrs._holdId = json.hold_id
      attrs._holdUntil = json.hold_until
      attrs._id = json.id
      attrs._isConsignment = !!json.consignment
      attrs._isSpec = !!json.spec
      attrs._seat = json.seat
      attrs._state = json.state

    if (@getMapping() == C.Mappings.TicketGroupEndpoint.TicketList)
      attrs._cost = json.cost
      attrs._eticketId = json.eticket_id
      attrs._holdId = json.hold_id
      attrs._holdUntil = json.hold_until
      attrs._id = json.id
      attrs._seat = json.seat
      attrs._state = json.state

    if (@getMapping() == C.Mappings.Direct.TicketGroups.Tickets)
      attrs._seat = json[1]
      attrs._state = json[0]

    if (@getMapping() == C.Mappings.TicketGroupEndpoint.Tickets)
      attrs._seat = json[1]
      attrs._state = json[0]
      if (attrs._seat == 'NULL')
        attrs._seat = null

    if (@getMapping() == C.Mappings.Direct.TicketCosts.SoldTickets)
      attrs._cost = json.cost
      attrs._faceValue = json.face_value
      attrs._id = json.id
      attrs._price = json.price
      attrs._salePrice = json.sale_price
      attrs._seat = json.seat
      attrs._state = json.state
      attrs._ticketGroupId = json.ticket_group_id
      #attrs._consignmentCost = json.consignment_cost
      #attrs._createdAt = Date.parse(json.created_at)
      #attrs._eTicketContentType = json.eticket_content_type
      #attrs._eTicketFileName = json.eticket_file_name
      #attrs._eTicketFileSize = json.eticket_file_size
      #attrs._eTicketUpdatedAt = Date.parse(json.eticket_updated_at)
      #attrs._isConsignment = json.consignment
      #attrs._isGeneralAdmission = json.general_admission
      #attrs._isSpec = json.spec
      #attrs._holdId = json.hold_id
      #attrs._index = json.index
      #attrs._originalPOId = json.original_purchase_order_id
      #attrs._overs = json.overs
      #attrs._POId = json.purchase_order_id
      #attrs._takenId = json.taken_id
      #attrs._totalCost = json.total_cost
      #attrs._updatedAt = Date.parse(json.updated_at)
      #attrs._wastedReason = json.wasted_reason
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.SingleTicketGroupEndpoint.Ticket)
      attrs._state = json[0]
      attrs._seat = json[1]

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      id: @get('_id')
      eticketId: @get('_eticketId')
      cost: @get('_cost')
      faceValue: @get('_faceValue')
      purchaseOrderId: @get('_purchaseOrderId')
      salePrice: @get('_salePrice')
      seat: @get('_seat')

    })
    return presented
  #///////////////////////////////////////////////////////////////////////////

  deleteEticket: () ->
    @purpose = C.Purposes.DeleteEticket
    return @savePromise()
