App.Presenter = require('./presenter');

module.exports = App.Presenters.TicketGroupPresenter = (function (_super) {

  function TicketGroupPresenter () {
    return _super.prototype.constructor.apply(this, arguments);
  }

  _.extend(TicketGroupPresenter.prototype, {

    availability: function () {
      if (this.get('in_hand')) {
        return 'In-Hand';
      } else {
        if (this.get('in_hand_on')) {
          var inHandOn = 'In-Hand On ',
              time = moment(this.get('in_hand_on')).format('MMMM Do, YYYY');
          return time;
        } else {
          return 'Not In-Hand';
        }
      }
    },

    cost: function () {
      if (this.get('ticket_costs')[0] === 'NULL') {
        return 'N/A';
      } else {
        return App.Utils.valueToCurrency(this.get('ticket_costs')[0]);
      }
    },

    owned_bool: function() {
      if (this.get('office').id === SESSION.office_id) {
        return true;
      } else {
        return false;
      }
    },

    owned_css: function() {
      if (this.get('office').id === SESSION.office_id) {
        return 'success';
      } else {
        return '';
      }
    },

    faceValue: function () {
      if (this.get('face_value')) {
        return App.Utils.valueToCurrency(this.get('face_value'));
      } else {
        return '';
      }
    },

    format: function () {
      if (this.get('eticket')) {
        return C.TicketFormats.Eticket;
      } else {
        return C.TicketFormats.Physical;
      }
    },

    notes: function (type) {
      return this.get(type + '_notes') || '';
    },

    purchaseOrderNumber: function () {
      if (this.get('ticket_purchase_order_ids')[0] === 'NULL') {
        return '';
      } else {
        return this.get('ticket_purchase_order_ids')[0];
      }
    }

  });

  return TicketGroupPresenter;

})(App.Presenter);