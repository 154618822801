template = require('./inline_payment.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.CreditMemo.InlinePayment = App.Views.Base.BaseView.extend

  template: template

  tagName: 'tr'

  initialize: (options = {}) ->
    {
      @creditMemoShowView
      @payment
      @loadingView
    } = options

  render: () ->
    @$el.html(
      @template({
        payment: @payment.toPresenterJSON()
      })
    )
    @$applyButton = @$('.applyButton')
    @$removeButton = @$('.removeButton')
    @formErrors = new App.Utils.FormErrorManager($('#creditMemoFormErrors'))

  # VIEW EVENTS ////////////////////////////////////////////////////////////////
  events:
    'click .applyButton':   'onApplyButtonClick'
    'click .removeButton':  'onRemoveButtonClick'

  onApplyButtonClick: () ->
    @buttonLoader = new App.Utils.LoadingButton(@$applyButton)
    @buttonLoader.startLoad()
    @$removeButton.attr('disabled', true)

    if (
      (@payment.get('_type') == C.Check || @payment.get('_type') == C.MoneyOrder) &&
      !@payment.get('_checkNumber')
    )
      checkNumber = prompt('Please supply a check number to apply this payment:')
      @payment.set('_checkNumber', checkNumber)

    @payment.purpose = C.Purposes.ApplyCreditMemoPayment
    @payment.savePromise()
    .then (model) =>
      @buttonLoader.stopLoad()
      @$removeButton.removeAttr('disabled')
      @creditMemoShowView.updatePayments(@payment)
    .fail (errors) =>
      @formErrors.show({
        title: 'Error applying payment.'
        errors
      })
      @buttonLoader.stopLoad()
      @$removeButton.removeAttr('disabled')
    .done()

  onRemoveButtonClick: () ->
    @buttonLoader = new App.Utils.LoadingButton(@$removeButton)
    @buttonLoader.startLoad()
    @$applyButton.attr('disabled', true)
    @payment.purpose = C.Purposes.CancelCreditMemoPayment
    @payment.savePromise()
    .then (model) =>
      @buttonLoader.stopLoad()
      @$applyButton.removeAttr('disabled')
      @creditMemoShowView.updatePayments(@payment)
    .fail (errors) =>
      @formErrors.show({
        title: 'Error removing payment.'
        errors
      })
      @buttonLoader.stopLoad()
      @$applyButton.removeAttr('disabled')
    .done()
  #/////////////////////////////////////////////////////////////////////////////