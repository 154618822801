module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div class="modal-header">\n    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n    <h5>Waste</h5>\n</div>\n<div class="modal-body">\n    <form>\n        <div class="field">\n            <label for= "wasted_reason">Reason</label>\n            <div class="select">\n                <select id="wasted_reason" name="wasted_reason" >\n                    <option value= "Did not sell" selected="selected"> Did not sell</option>\n                    <option value= "Lost by shipper">Lost by shipper</option>\n                    <option value= "Lost before shipment"> Lost before shipment</option></select>\n            </div>\n        </div>\n    </form>\n</div>\n<div class="bottom-buttons modal-footer">\n    <div class="filler"></div>\n    <a class="btn btn-primary confirm" href="#">Waste</a>\n</div>\n', __filename = "js/views/ticket_groups/wasted_view.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div class="modal-header">\n    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n    <h5>Waste</h5>\n</div>\n<div class="modal-body">\n    <form>\n        <div class="field">\n            <label for= "wasted_reason">Reason</label>\n            <div class="select">\n                <select id="wasted_reason" name="wasted_reason" >\n                    <option value= "Did not sell" selected="selected"> Did not sell</option>\n                    <option value= "Lost by shipper">Lost by shipper</option>\n                    <option value= "Lost before shipment"> Lost before shipment</option></select>\n            </div>\n        </div>\n    </form>\n</div>\n<div class="bottom-buttons modal-footer">\n    <div class="filler"></div>\n    <a class="btn btn-primary confirm" href="#">Waste</a>\n</div>\n');
            __line = 22;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}