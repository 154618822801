App.Controllers.Base.BaseController = require('./base/base_controller.coffee')

module.exports = class App.Controllers.EventManagementController extends App.Controllers.Base.BaseController

  _managementView = null
  _managementOrdersView = null
  _$container = null
  _orderItemCollection = null
  _xhr = null

  routeIndex: ($normalContent, encodedFilterParams) ->
    _$container = $normalContent
    @display({
      encodedFilterParams
      shouldReset: true
    })

  filter: () ->
    encodedFilterParams = _managementView.getEncodedFilterParams()
    route = "/eventmgmt/filter/#{ encodedFilterParams }"
    App.router.navigate(route)
    @display({
      encodedFilterParams
      shouldReset: false
    })

  reset: () ->
    App.router.navigate('/eventmgmt') # Don't trigger.
    @display({
      shouldReset: true
    })

  display: (options) ->
    {
      encodedFilterParams
      shouldReset
    } = options
    isGoingToFetch = !!encodedFilterParams

    if (_xhr)
      _xhr.abort()

    if (!_orderItemCollection || shouldReset)
      _orderItemCollection = new App.Collections.V3.OrderItems(null, {
        mapping: C.Mappings.Direct.OrderItemsByEvent.OrderItems
      })

    if (!_managementView || shouldReset)
      _managementView = new App.Views.Events.Management({
        $container: _$container
        orderItemCollection: _orderItemCollection
      })

    if (encodedFilterParams)
      params = App.Utils.decodeFilterParams(encodedFilterParams)
      _managementView.reflectFilterParams(params)
      _managementView.startLoad()
      params = _managementView.getFilterParams()
      promise = _orderItemCollection.fetchPromise({
        data: params
        reset: true
      })
      _xhr = promise.xhr
      App.Utils.requestKiller.add(_xhr)
      promise.then (collection) =>
        _managementView.renderTable()
      .fail (error) =>
        console.error(error.stack)
      .done()

  fillOrdersPane: (options) ->
    {
      $container
      orderItemsCollection
    } = options
    _managementOrdersView = new App.Views.Events.ManagementOrders({
      $container
      orderItemsCollection
    })
