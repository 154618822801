template = require('./message.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

module.exports = App.Views.Shared.Message = App.Views.Base.BaseView.extend

  template: template

  initialize: (options) ->
    options = options || {}
    @title = options.title || null
    @type = options.type || 'info'
    @message = options.message || ''
    @$container = options.$container
    @render()

  render: () ->
    data = {
      @title
      @type
      @message
    }
    @$container.html(
      @$el.html(
        @template(
          data
        )
      )
    )
    return @$el

  #// VIEW EVENTS ////////////////////////////////////////////////////////////
  events: {
    'click button.close': 'onCloseButtonClick'
  }

  onCloseButtonClick: () ->
    @remove()
  #///////////////////////////////////////////////////////////////////////////