App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

STATUSES = {
  A: 'Accepted'
  R: 'Review'
  D: 'Declined'
  C: 'Changed'
}

FRAUD_CHECK_STATUSES = {
  submitted: 'Submitted'
  declined: 'Declined'
  approved: 'Approved'
}

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.Fraud = App.Models.Base.V3BaseModel.extend

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.Order.Fraud)
      attrs._notifications = json.notifications
      attrs._score = json.score
      attrs._status = json.status
      attrs._transactions = json.transactions
      attrs._fraudCheckStatus = json.fraudCheckStatus
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.V2OrderEndpoint.Fraud)
      attrs._notifications = json.notifications
      attrs._score = json.score
      attrs._status = json.status
      attrs._transactions = json.transactions
      attrs._fraudCheckStatus = json.fraudCheckStatus
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {
      autoStatus: @autoStatus()
      isEnabled: @isEnabled()
      notifications: @get('_notifications')
      score: @get('_score')
      status: @status()
      transaction: @transaction()
      fraudCheckStatus: @fraudCheckStatus()
    })
    return presented

  autoStatus: () ->
    transaction = @transaction()
    return STATUSES[transaction.AUTO]

  transaction: () ->
    transactions = _.reject(@get('_transactions'), (transaction) ->
      return (transaction.MODE == 'U')
    )
    return (transactions.length && transactions[0]) || {}

  status: () ->
    status = @get('_status')
    return STATUSES[status]

  fraudCheckStatus: () ->
    fraudCheckStatus = @get('_fraudCheckStatus')
    if (FRAUD_CHECK_STATUSES[fraudCheckStatus])
      return FRAUD_CHECK_STATUSES[fraudCheckStatus]
    else
      return fraudCheckStatus

  isEnabled: () ->
    return (@get('_score') && @get('_status')) || @get('_fraudCheckStatus')
  #///////////////////////////////////////////////////////////////////////////
