App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.CreditCard = App.Models.Base.V3BaseModel.extend

  url: () ->
    if (@purpose == C.Purposes.UpdateCreditCard)
      return "/api_direct/v9/clients/#{ @cardOwner.id }/credit_cards/#{ @id }/relabel"
    else
      return parent.url.call(@)

  urlRoot: () ->
    if (@cardOwner?.type == C.Client)
      return "/api_direct/v9/clients/#{ @cardOwner.id }/credit_cards"
    else if (@cardOwner?.type == C.Office)
      #if (@cardOwner.id == SESSION.office_id)
      #  return "/api_direct/v9/offices/#{ SESSION.office_id }/credit_cards"
      #else
      return "/api_direct/v9/offices/#{ SESSION.office_id }/credit_cards?stored_for_id=#{ @cardOwner.id }"
    else
      console.error("CreditCards collection not given a v3Patron as @cardOwner (no @cardOwner.type).")

  initialize: (attributes, options = {}) ->
    {
      @cardOwner
    } = options
    parent.initialize.call(@, attributes, options)

  parse: (response, options) ->
    # We're hackily saving a single model though the collection endpoint.
    if (response.credit_cards && response.credit_cards.length)
      return response.credit_cards[0]
    else
      return response

  validation:
    _number: (val) ->
      if (@isNew())
        if (!$.payment.validateCardNumber(val))
          return 'Credit card number is not valid.'
    _expirationMonth: (val) ->
      if (@purpose != C.Purposes.UpdateCreditCard)
        if (!$.payment.validateCardExpiry(val, @get('_expirationYear')))
          return 'Credit card expiration is not valid.'
    _ccv: (val) ->
      if (@isNew())
        if (val) # Only validate it if present.
          if (!$.payment.validateCardCVC(val))
            return 'Credit card CCV code is not valid.'
    _name:
      required: true
      msg: 'Credit card name is required.'

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.Order.PaymentsCreditCard)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping: C.Mappings.Direct.Order.PaymentsCreditCardAddress
      })
      attrs._cardCompany = json.card_company
      attrs._expirationMonth = json.expiration_month
      attrs._expirationYear = json.expiration_year
      attrs._id = json.id
      attrs._lastDigits = json.last_digits
      attrs._name = json.name
      attrs._phoneNumber = json.phone_number
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.CreditMemos.CreditCard)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping: C.Mappings.Direct.CreditMemos.CreditCardAddress
      })
      attrs._cardCompany = json.card_company
      attrs._expirationMonth = json.expiration_month
      attrs._expirationYear = json.expiration_year
      attrs._id = json.id
      attrs._lastDigits = json.last_digits
      attrs._name = json.name
      attrs._phoneNumber = json.phone_number
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Offices.BrokerageCreditCards)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping: C.Mappings.Direct.CreditCards.Address
      })
      attrs._association = json.association
      attrs._cardCompany = json.card_company
      attrs._expirationMonth = json.expiration_month
      attrs._expirationYear = json.expiration_year
      attrs._id = json.id
      attrs._isPrimary = json.primary
      attrs._lastDigits = json.last_digits
      attrs._name = json.name
      attrs._phoneNumber = json.phone_number
      attrs._url = json.url
      # INCOMPLETE.

    if (@getMapping() == C.Mappings.Direct.CreditCards.CreditCard)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping: C.Mappings.Direct.CreditCards.Address
      })
      attrs._association = json.association
      attrs._cardCompany = json.card_company
      attrs._expirationMonth = json.expiration_month
      attrs._expirationYear = json.expiration_year
      attrs._id = json.id
      attrs._isPrimary = json.primary
      attrs._lastDigits = json.last_digits
      attrs._name = json.name
      attrs._phoneNumber = json.phone_number
      attrs._url = json.url
      attrs._label = json.label
      # INCOMPLETE.

    @set(attrs)

  toJSON: (options) ->
    json = {}
    if (@get('_address').isNew())
      json.address_attributes = @get('_address').toJSON({
        preventWrapping: true
      })
    else
      json.address_id = @get('_address').id
    json.cvv = @get('_ccv') # Careful. cvv vs. ccv
    json.expiration_month = @get('_expirationMonth')
    json.expiration_year = @get('_expirationYear')
    json.name = @get('_name')
    json.number = @get('_number')
    if (@cardOwner && @cardOwner.type == C.Office)
      json.stored_for_id = @cardOwner.id

    if (@purpose == C.Purposes.UpdateCreditCard)
      json.label = @get('_label')
      json.primary = @get('_isPrimary')
      return json
    else
      body = {
        credit_cards: [ json ]
      }
      if (@get('_useTevoGateway'))
        body.use_tevo_gateway = true;
      return body

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {
      id:           @get('_id')
      address:      @get('_address')?.toPresenterJSON?()
      cardCompany:  @get('_cardCompany')
      expiration:   "#{ @get('_expirationMonth') } / #{ @get('_expirationYear') }"
      lastDigits:   @get('_lastDigits')
      name:         @get('_name')
      singleLine:   @singleLine()
      isPrimary:    @get('_isPrimary')
      label:        @get('_label')


    })
    return presented

  singleLine: () ->
    line = "#{ @get('_cardCompany') } #{ @get('_name') } *#{ @get('_lastDigits') } Expires #{ @get('_expirationMonth') }/#{ @get('_expirationYear') }"
    if (!_.isUndefined(@get('_refundableAmount')))
      line += " (Max Refundable: #{ App.Utils.valueToCurrency(@get('_refundableAmount')) })"
    return line
#///////////////////////////////////////////////////////////////////////////