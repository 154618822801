template = require('./deliveries_list.ejs')
App.Views.Base.FilteredTable = require('../base/filtered_table_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Delivery.List = App.Views.Base.FilteredTable.extend

  template: template

  initialize: (options = {}) ->
    @collection = options.collection
    @$container = options.$container
    @listenTo(@collection, 'reset', @onCollectionReset)
    @render()

  render: () ->
    @$container.html(
        @$el.html(
            @template()
        )
    )
    @delegateEvents()
    #@$('input[type=checkbox],input[type=radio],input[type=file]').uniform()
    App.Utils.dateRangePicker(@$('#first_event_date_range'), @onFirstEventDateRangeChanged, @, C.DatePickerConfig.Future, true)
    App.Utils.dateRangePicker(@$('#order_link_created_at_date_range'), @onOrderLinkCreatedAtDateRangeChanged, @, C.DatePickerConfig.Past, true)

    $content = @$('#filter_content')
    $collapseButton = @$('#filter_collapse_button')
    isHorizontalSplit = true

    onChange = () =>
      @renderDeliveryTable()

    options = {
      $content
      $collapseButton
      isHorizontalSplit
      onChange
    }
    @collapsibleFilters = new App.Utils.CollapsibleWidget(options)

    @buttonLoader = new App.Utils.LoadingButton(@$('#apply_filter_button'))
    @initializeDropdowns()
    @initializeAutocomplete()
    @renderDeliveryTable(true)
    @startLoad()
    return @$el

  renderDeliveryTable: (isInitialRender) ->
    if (@deliveryTable)
      @deliveryTable.remove()

    @deliveryTable = new App.Views.Delivery.DeliveryTable({
      filterView: @
      $container: @$('#delivery_table_container')
      collection: @collection
    })
    @tableLoader = new App.Utils.LoadingBox(@deliveryTable.$el)
    if (!isInitialRender)
      @stopLoad() # Shows empty loading table during initial fetch.

# VIEW EVENTS //////////////////////////////////////////////////////////////
  events:
    'click #apply_filter_button': 'onApplyFilterButtonClick'
    'click #reset_filter_button': 'onResetFilterButtonClick'

  onResetFilterButtonClick: (e) ->
    App.Controllers.deliveryController.resetDeliveryIndex()

  onApplyFilterButtonClick: (e) ->
    @buttonLoader.startLoad()
    @tableLoader.startLoad()
    App.Controllers.deliveryController.filterDeliveryIndex()

  onFirstEventDateRangeChanged: (startDate, endDate) ->
    @firstEventDateRange = { start: startDate, end: endDate }

  onOrderLinkCreatedAtDateRangeChanged: (startDate, endDate) ->
    @orderLinkCreatedAtDateRange = { start: startDate, end: endDate }
#///////////////////////////////////////////////////////////////////////////

# COLLECTION EVENTS ////////////////////////////////////////////////////////
  onCollectionReset: () ->
    @renderDeliveryTable()
#///////////////////////////////////////////////////////////////////////////

  filterParamMappings: () ->
# Yes transaction_type & payment_type have their labels flipped.
# This is not a mistake.
    return {
    first_event_occurs_at:
      type: C.FilterTypes.DateRange
      el: '#first_event_date_range'
      val: @firstEventDateRange
    ship_to_name:
      type: C.FilterTypes.Text
      el: '#recipient'
    customer_id:
      type: C.FilterTypes.Autocomplete
      el: '#customer_autocomplete'
      sendsId: true
      val: @customerIdFilter
    type:
      type: C.FilterTypes.Select2
      el: '#delivery_type_select'
      ignoreAll: true
    order_link_id:
      type: C.FilterTypes.Text
      el: '#order_text'
    order_link_created_at:
      type: C.FilterTypes.DateRange
      el: '#order_link_created_at_date_range'
      val: @orderLinkCreatedAtDateRange
    tracking_number:
      type: C.FilterTypes.Text
      el: '#tracking_text'
    state:
      type: C.FilterTypes.Select2
      el: '#delivery_state_select'
      ignoreAll: true
    order_by:
      type: C.FilterTypes.Default
      val: 'first_event_occurs_at'
    order_direction:
      type: C.FilterTypes.Default
      val: 'asc'
    }

  startLoad: () ->
    @tableLoader.startLoad()
    @buttonLoader.startLoad()

  stopLoad: () ->
    @tableLoader.stopLoad()
    @buttonLoader.stopLoad()

  initializeAutocomplete: () ->
    callback = (itemId) =>
      @customerIdFilter = itemId
    $el = @$('#customer_autocomplete')
    shouldReturnId = true
    options = {
      $el
      shouldReturnId
      callback
    }
    App.Utils.clientOfficeAutocomplete(options)

  initializeDropdowns: () ->
    deliveryTypes = C.Options.Delivery.Types
    deliveryTypes = _.map(deliveryTypes, (key, val) =>
      return { id: val, text: key }
    )
    @$('#delivery_type_select').select2({
      placeholder: 'All'
      data: deliveryTypes
      initSelection: (element, callback) =>
        data = {
          id: element.val()
          text: C.Options.Delivery.Types[element.val()]
        }
        callback(data)
    })

    deliveryStates = C.Options.Shipment.States
    deliveryStates = _.map(deliveryStates, (key, val) ->
      return { id: val, text: key }
    )
    @$('#delivery_state_select').select2({
      data: deliveryStates
      initSelection: (element, callback) =>
        data = {
          id: element.val(),
          text: C.Options.Shipment.States[element.val()]
        }
        callback(data)
    })
    @$('#delivery_state_select').select2('val', C.Pending)