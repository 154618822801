App.Models.Base.V3BaseModel = require('./v3_base_model.coffee')

# Defines an interface for Patron - i.e. Client & Office must implement these methods.
parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.Base.PatronBaseModel = App.Models.Base.V3BaseModel.extend

  address: () ->
    alert('address() must be implemented.')

  addresses: () ->
    alert('addresses() must be implemented.')

  emailAddress: () ->
    alert('emailAddress() must be implemented.')

  emailAddresses: () ->
    alert('emailAddresses() must be implemented.')

  phoneNumbers: () ->
    alert('phoneNumbers() must be implemented.')

  toPatron: () ->
    alert('toPatron() must be implemented.')