App.Collections.Base.BaseCollection = require('./base/base_collection.coffee')
App.Models.PhoneNumber = require('../models/phone_number_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.PhoneNumbers = App.Collections.Base.BaseCollection.extend

  model: App.Models.PhoneNumber

  url: -> "/clients/#{@holder_id}/phone_numbers"

  initialize: (models, options = {}) ->
    {
      @holder_id
    } = options
    parent.initialize.call(@, models, options)
