template = require('./mass_index_edit.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Inventory.MassIndexEditView = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    {
      @$container
    } = options
    @count = 0
    @render()

  render: () ->
    @$container.html(
      @$el.html(
        @template()
      )
    )
    @$fieldContainer = @$('#fieldContainer')
    @delegateEvents()
    return @$el

  # VIEW EVENTS //////////////////////////////////////////////////////////////
  events:
    'click .fieldToggleButton':       'onFieldToggleButtonClick'

  onFieldToggleButtonClick: (e) ->
    e.preventDefault()
    e.stopPropagation()

    $button = $(e.currentTarget)
    field = $button.data().field
    $fieldDiv = @$fieldContainer.find("div[data-field=#{ field }]")
    if ($button.hasClass('active'))
      $button.removeClass('active')
      $button.removeClass('btn-info')
      $fieldDiv.hide()
    else
      $button.addClass('active')
      $button.addClass('btn-info')
      $fieldDiv.css({
        order: "#{ @count++ }"
      }).show()