module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div class="span12 center" style="text-align: center;">\n  <ul class="stat-boxes" style="margin: 0px;">\n    <li style="margin-bottom: 0px;">\n      <div class="right" style="width: auto; padding-left: 7px;">\n        <strong><%= count || \'...\' %></strong>\n        Credit Memos\n      </div>\n    </li>\n    <li style="margin-bottom: 0px;">\n      <div class="right" style="width: auto; padding-left: 7px;">\n        <strong><%= isNaN(total) ? total || \'...\' : App.Utils.valueToCurrency(total) %></strong>\n        Total\n      </div>\n    </li>\n    <li style="margin-bottom: 0px;">\n      <div class="right" style="width: auto; padding-left: 7px;">\n        <strong><%= isNaN(balance) ? balance || \'...\' : App.Utils.valueToCurrency(balance) %></strong>\n       Balance\n      </div>\n    </li>\n  </ul>\n</div>\n', __filename = "js/views/credit_memos/credit_memo_aggregates.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div class="span12 center" style="text-align: center;">\n  <ul class="stat-boxes" style="margin: 0px;">\n    <li style="margin-bottom: 0px;">\n      <div class="right" style="width: auto; padding-left: 7px;">\n        <strong>');
            __line = 5;
            __append(escapeFn(count || "..."));
            __append('</strong>\n        Credit Memos\n      </div>\n    </li>\n    <li style="margin-bottom: 0px;">\n      <div class="right" style="width: auto; padding-left: 7px;">\n        <strong>');
            __line = 11;
            __append(escapeFn(isNaN(total) ? total || "..." : App.Utils.valueToCurrency(total)));
            __append('</strong>\n        Total\n      </div>\n    </li>\n    <li style="margin-bottom: 0px;">\n      <div class="right" style="width: auto; padding-left: 7px;">\n        <strong>');
            __line = 17;
            __append(escapeFn(isNaN(balance) ? balance || "..." : App.Utils.valueToCurrency(balance)));
            __append("</strong>\n       Balance\n      </div>\n    </li>\n  </ul>\n</div>\n");
            __line = 23;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}