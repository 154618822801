module.exports = App.Models.Payment = Backbone.Model.extend({

  initialize: function(opts) {
    var options = opts || {};

    if (!this.has("type")) {
      this.set('type', 'cash');
    }

    if ((this.get('credit_card')) && !(this.get('credit_card') instanceof App.Models.CreditCard)) {
      this.set('credit_card', new App.Models.CreditCard(this.get('credit_card')));
    }


  },

  url: function () {
    if (!this.get('order') || !this.get('order').id) {
      throw new Error('Payment must have an order with an ID.');
    } else if (!this.id) {
      throw new Error('Payment must have an ID.');
    } else {
      return '/orders/' + this.get('order').id + '/payments/' + this.id;
    }
  },

  disableApply: function () {
    return _.include([C.Canceled, C.Completed], this.get('state')) || this.get('type') === 'credit_card';
  },

  disableCancel: function () {
    return this.get('state') === C.Canceled || this.get('type') === 'credit_card';
  },

  create: function () {
    var d = $.Deferred(),
        that = this,
        orderId = that.get('order').id,
        full_refund = that.get('full_refund'),
        amount = that.get('amount'),
        check_number = that.get('check_number'),
        // This won't be hardcoded later on
        type = that.get('payment_type'),
        isCompleted = that.get('is_completed'),
        credit_card = that.get('credit_card'),
        credit_card_id = that.get('credit_card_id'),
        is_refund = that.get('is_refund'),
        transaction_id = that.get('transactionId');

    this.url = (full_refund || (is_refund && type === 'credit_card')) ? '/payments/' + transaction_id + '/refund' : '/payments';

    if (full_refund || (is_refund && type === 'credit_card')) {
      var postData = {};
      // since this a refund it doesn't need an amount
      if (is_refund && amount) {
        postData.amount = amount;
      }
      var post = $.post(this.url, postData);
    } else {
      var post = $.post(
        this.url, {
          "payments": {
            order_id: orderId,
            amount: amount,
            type: type,
            is_refund: is_refund,
            is_completed: isCompleted,
            check_number: check_number,
            credit_card_id: credit_card_id
          },
        }
      );
    }
    post.done(function(response) {
      that.attributes = that.parse(response);
    });

    return post;
  },

  apply: function () {
    var that = this;
    var deferred  = $.Deferred();

    var doApply = function() {
      var checkNumber = that.get('check_number');
      var data = null;
      if (checkNumber) {
        data = {
          check_number: checkNumber
        };
      }
      return $.ajax({
        type: 'post',
        url: '/payments/' + that.id + '/apply',
        data: data,
        success: function (res) {
          that.set(res);
        }
      });
    };

    if (
      _.include(['check', 'money_order'], that.get('type')) &&
      !that.get('check_number')
    ) {
      var checkNumber = prompt('Please supply a check number to apply this payment:');
      that.set('check_number', checkNumber);
      return doApply();
    } else {
      return doApply();
    }
  },

  cancel: function () {
    var d = $.Deferred(),
        that = this;

    return $.ajax({
      type: 'post',
      url: '/payments/' + that.id + '/cancel',
      success: function (res) {
        that.set(res);
      }
    });
  },

//    refund: function() {
//      var deferred = $.Deferred(),
//          that = this;
//
//      return $.ajax({
//        type: 'post',
//        url: '/payments/' + that.id + '/refund',
//        success: function (res) {
//          that.set(res);
//        },
//        error: function () {
//          window.alert('There was an error refunding the payment.');
//        }
//      });
//    },

  parse: function(orderData) {

    // Need to parse out the details coming back from the payment
    // to consolidate them into a normalized structure available
    // to all payment types

    function modelizeAttribute(attributeKey, ModelConstructor) {
      var value = orderData[attributeKey];

      if (value) {
        orderData[attributeKey] = new ModelConstructor(value);
      }else{
        delete orderData[attributeKey];
      }
    }

    //modelize payments
    //Need to split up payments and returns based on the type
    //

    _.each(orderData.payments, function(payment) {
      if (payment.get('type') == 'return' || payment.get('type') == 'penalty') {
        orderData.payment = new App.Models.Return(payment);
      } else {
        orderData.payment = new App.Models.Payment(payment);
      }
    });

    return orderData;
  },

  toJSON: function () {
    var attrs = _.clone(this.attributes);
    // The order contains a reference to the payment, which causes a stack overflow on JSON.stringify.
    delete attrs.order;
    return attrs;
  },

  validate: function() {
    var errors = this.errors = [], that = this,
        payment_types = ['cash', 'check', 'credit_card', 'money_order', 'pay_pal', 'evopay', 'credit_memo', 'tbd'];

    this.validates(['type'], {
      presence: true
    });

    this.validates(['amount'], {
      numericality: { min: 0.00 }
    });

    // NEED TO ADD A VALIDATION THAT WILL MAKE SURE THERE IS NO $ ON AMOUNT
    //

    this.validates('type', {
      inclusion: payment_types
    });

    if(this.has("credit_card")){
      var cc = new App.Models.CreditCard(this.get("credit_card"));
      cc.validate();
      _.each(cc.errors, function (error) {
        that.errors.push("Credit Card " + error.toLowerCase());
      });
    }else if(this.get("type") === "credit_card"){
      this.validates(['credit_card_id'], {
        presence: true
      });
    }

    if (this.get('is_completed')) {
      if (this.get('type') === 'check' || this.get('type') === 'money_order') {
        this.validates(['check_number'], {
          presence: true
        });
      } else if (this.get('type') === 'pay_pal' ) {
        this.validates(['transaction_id'], {
          presence: true
        });
      } else if (this.get('type') === 'credit_card' ) {
        this.validates(['credit_card_id'], {
          presence: true
        });
      }
    }
  },
  isValid: function() {
    this.validate();
    return _.isEmpty(this.errors);
  },
  buildDetails: function(payment) {
    var transaction_array = ["check", "money_order", "pay_pal"],
        credit_card = payment.get('credit_card');


    if ( _.contains(transaction_array, payment.get('type'))) {
      payment.details = "Transaction ID: " + (payment.get('check_number') || 'None');
    } else if (payment.get('type') === 'credit_card') {

      payment.cc_details = {
        card_name: credit_card.get('name'),
        card_company: credit_card.get('card_company'),
        last_digits: credit_card.get('last_digits'),
        exp_date: credit_card.get('expiration_month') + '/' + credit_card.get('expiration_year'),
        cvv: payment.get('cvv_response'),
        avs_postal: payment.get('avs_postal_code_response'),
        avs_street: payment.get('avs_street_address_response')
      }
    } else if (payment.get('type') === 'evopay') {
      payment.details = "EvoPay Details"
    } else if (payment.get('type') === 'credit_memo') {
      var cmid = payment.attributes.credit_memo_id;
      payment.details = "<a href='/credit_memo/" + cmid + "'> Credit Memo: " + cmid + "</a>";
    }

    return payment;
  }
});