template = require('./eticket_management_instructions_row.ejs')
App.Controllers.Base.BaseController = require('./base/base_controller.coffee')

module.exports = class App.Controllers.EticketManagmement extends App.Controllers.Base.BaseController

  view = null
  $container = null

  v3TicketGroupModel = null
  v3TicketCollection = null

  v3EventModel = null

  # Orphans.
  v3EticketCollection = null

  isSkeletonMode = null
  eticketsRendered = null
  rowNumber = null

  instructionsTemplate = template

  routeIndex: (@$normalContent, eventId, ticketGroupId, options) ->
    App.router?.resetCurrentState()

    $container = @$normalContent

    # Reset all vars. They're scoped to the controller, so re-used across ticket groups.
    v3EticketCollection = new App.Collections.V3.Etickets(null, {
      mapping: C.Mappings.Direct.Etickets.SplitPdf
    })
    isSkeletonMode = true
    rowNumber = 0

    # First page visited.
    if (eventId && ticketGroupId)

      v3TicketGroupModel = new App.Models.V3.TicketGroup({
        id: ticketGroupId
      }, {
        mapping: C.Mappings.Direct.TicketGroups.TicketGroup
      })

      v3TicketCollection = new App.Collections.V3.Tickets(null, {
        mapping: C.Mappings.Direct.Tickets.Tickets
        ticketGroupId
      })
      v3TicketGroupModel.set('_tickets', v3TicketCollection)

      v3EventModel= new App.Models.V3.Event({
        id: eventId
      }, {
        mapping: C.Mappings.Direct.Events.Event
      })

      Q.all([
        v3TicketGroupModel.fetchPromise()
        v3TicketCollection.fetchPromise()
        v3EventModel.fetchPromise()
      ])
      .spread (ticketGroupModel, ticketCollection, eventModel) =>
        @renderView()
      .fail(App.Utils.fail)
      .done()

    # Coming from TG listview.
    # We already have v3TicketGroupModel & v3EventModel
    else
      {
        v3TicketGroupModel
        v3EventModel
      } = options

      App.router.navigate("etickets/#{ v3EventModel.id }/#{ v3TicketGroupModel.id }")

      v3TicketCollection = new App.Collections.V3.Tickets(null, {
        mapping: C.Mappings.Direct.Tickets.Tickets
        ticketGroupId: v3TicketGroupModel.id
      })
      v3TicketGroupModel.set('_tickets', v3TicketCollection)

      v3TicketCollection.fetchPromise()
      .then (ticketCollection) =>
        @renderView()
      .fail(App.Utils.fail)
      .done()

      @renderView()

  renderView: () ->
    eticketsRendered = false

    view = new App.Views.TicketGroups.EticketManagment({
      $container
      v3TicketGroupModel
      v3EventModel
    })

    existingEtickets = false
    v3TicketCollection.forEach((v3TicketModel) =>
      eticket = v3TicketModel.get('_eticket')
      if (eticket)
        existingEtickets = true
        v3EticketCollection.add(eticket, { merge: true })
    )

    if (existingEtickets)
      @renderEtickets()
    else
      @renderSkeletonView()

  renderEtickets: () ->
    isSkeletonMode = false
    $collectionContainer = view.$el.find('#eticketsContainer')

    if (!eticketsRendered)
      rowNumber = 0
      $collectionContainer.empty()

    renderQueue = []
    eticketRowViews = []

    v3EticketCollection.forEach((v3EticketModel) =>
      # This iterates the whole collection everytime there's upload success.
      if (!v3EticketModel.isRendered)
        v3EticketModel.isRendered = true
        eticketRowView = new App.Views.TicketGroups.EticketManagementRow({
          $container: $collectionContainer
          parentView: view
          v3EticketModel
          v3TicketGroupModel
          rowNumber
        })
        eticketRowViews.push(eticketRowView)
        renderQueue.push(eticketRowView.renderPromise())
        rowNumber++
    )

    Q.all(renderQueue)
    .then () =>

      eticketsRendered = true

      view.$el.find('.btn').removeClass('disabled').removeAttr('disabled')
      view.createSyncedPreviewWindows()
      view.cacheSeatButtons()

      # Zoom everything out a little.
#      out = true
#      settings = {
#        zoomRatio: 7
#        extraX: 1700
#        extraY: 500
#      }
#      view.zoomBarcode(out, settings)
#      settings = {
#        zoomRatio: 7
#        extraX: 800
#        extraY: 500
#      }
#      view.zoomSeat(out, settings)

      readBarcodeJobs = []

      # Try to read one barcode - then do the rest in parallel if it works.
      firstRowView = eticketRowViews.shift()
      firstRowView.readBarcodePromise()
      .then (barcode) =>

        console.log("Read one barcode successfully.  Now reading all in parallel.")
        for rowView in eticketRowViews
          readBarcodeJobs.push(rowView.readBarcodePromise())

        Q.all(readBarcodeJobs)
        .then (jobResults) ->
          console.log("Done reading all barcodes.")
        .fail (jobErrors) ->
          # We don't really care if any of them fail.
          $.noop()
        .done()

      .fail () =>
        console.log("Couldn't read barcode of ticket 1. Not trying the others.")
        for rowView in eticketRowViews
          readBarcodeJobs.push(rowView.hideBarcodeHint())

      .done()

  renderSkeletonView: () ->
    isSkeletonMode = true
    $collectionContainer = view.$el.find('#eticketsContainer')
    $collectionContainer.empty()
    $collectionContainer.append(
      instructionsTemplate()
    )
    rowNumber = 0

    v3TicketCollection.forEach((v3TicketModel) ->
      eticketSkeletonView = new App.Views.TicketGroups.EticketManagementRow({
        isSkeletonMode: isSkeletonMode
        $container: $collectionContainer
        parentView: view
        v3TicketGroupModel
        v3TicketModel
        rowNumber
      })
      eticketSkeletonView.renderPromise()
      rowNumber++
    )

    view.cacheSeatButtons()
    view.$el.find('.btn').addClass('disabled').attr('disabled', true)
    view.$el.find('#saveButton').removeClass('disabled').removeAttr('disabled')
    view.$el.find('#returnToListingsButton').removeClass('disabled').removeAttr('disabled')

  uploadEticket: (fileModel) ->
    fileModel.set('_ticketGroup', v3TicketGroupModel)
    fileModel.set('purpose', C.FilePurposes.TicketEticket)
    return fileModel.savePromise()

  onUploadComplete: (fileModel) ->
    etickets = fileModel.get('etickets')
    v3EticketCollection.add(etickets, { merge: true })
    @renderEtickets()

  saveEtickets: (mappings) ->
    for mapping in mappings
      attrs = mapping
      if (isSkeletonMode)
        _ticketId = mapping._ticketId
        models = v3EticketCollection.where({ _ticketId })
        if (models.length)
          v3EticketModel = models[0]
          v3EticketModel.set(attrs)
        else
          v3EticketModel = new App.Models.V3.Eticket(attrs, {
            mapping: C.Mappings.Direct.Etickets.SplitPdf
          })
        v3EticketCollection.add(v3EticketModel, { merge: true })
      else
        v3EticketModel = v3EticketCollection.get(attrs._id)
        v3EticketModel.set(attrs)

    packIds = []
    v3EticketCollection.forEach((v3EticketModel) ->
      packIds.push(v3EticketModel.get('_packId'))
    )
    packIds = _.uniq(packIds)


    view.$el.find('#deleteAllEticketsButton').show()

    v3TicketGroupModel.set('_etickets', v3EticketCollection)
    v3TicketGroupModel.set('_eticketPackIds', packIds)
    v3TicketGroupModel.purpose = C.Purposes.SaveEtickets
    return v3TicketGroupModel.savePromise()

  deleteAllEtickets: () ->
    deleteJobPromises = []
    v3TicketCollection.forEach((v3TicketModel) =>
      if (v3TicketModel.get('eticket'))
        deleteJobPromises.push(v3TicketModel.deleteEticket())
    )
    Q.all(deleteJobPromises)
    .then (results) =>
      v3EticketCollection.reset()
      v3TicketCollection.fetchPromise()
      .then (fetchResults) =>
        @renderView()
        view.formErrors.show({
          type: 'success'
          title: 'Etickets removed successfully.'
          message: 'Any etickets associated with this ticket group were destroyed.'
        })
    .fail (error) =>
      view.formErrors.show({
        title: 'Error removing etickets.'
        errors: error
      })
      view.loadingBoxBottom.stopLoad()
      view.loadingButton.stopLoad()
      $('html,body').scrollTop(0);
    .done()