App.Views.EvoPay.ModalAbstract = require('./evopay_modal_abstract_view.coffee')
template = require('./withdraw_or_deposit_funds.ejs')

module.exports = App.Views.EvoPay.WithdrawFunds = App.Views.EvoPay.ModalAbstract.extend

  form_template: template

  initialize: (options) ->
    @title        = "Withdraw funds from EvoPay"
    @type         = "withdrawal"
    @button       = "Withdraw"
    @instructions = 'Submitting this form will transfer via ACH the specified amount back to your bank account on record with Ticket Evolution. The funds should show up in your bank account within 24 business hours if submitted before 5 PM EST Monday through Friday and excluding bank holidays.'
    @super_initialize(options||{})
