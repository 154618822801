template = require('./ticket_groups_tab.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Order.TicketGroupsTab = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    {
      @$container
      @overviewView
      @v3OrderModel
    } = options
    @render()

  render: () ->
    isAcceptedOrCompleted = _.contains([C.Accepted, C.Completed], @v3OrderModel.get('_sellerState'))
    shouldShowPurchaseOrderId = (
      (@v3OrderModel.get('_orderType') == C.OrderTypes.Direct && !@v3OrderModel.isPO()) ||
      @v3OrderModel.get('_orderType') == C.OrderTypes.Sale ||
      @v3OrderModel.get('_orderType') == C.OrderTypes.Sell
    ) && (SESSION.role.can(App.Access.Resources.Orders.OrderShow.ViewPONumber))
    #shouldShowPurchaseOrderId = _.contains([C.Accepted, C.Completed, C.Pending], @v3OrderModel.get('_sellerState'))

    data = {
      eventsInOrder: @overviewView.getEventsInOrder()
      soldAsInOrder: @overviewView.getSoldAsInOrder()
      isAcceptedOrCompleted
      order: @v3OrderModel.toPresenterJSON()
      orderType: @v3OrderModel.get('_orderType')
      shouldShowPurchaseOrderId
    }

    @$container.html(
      @$el.html(
        @template(data)
      )
    )
    @delegateEvents()
