template = require('./new_modal.ejs')

module.exports = App.Views.CreditCards.NewModal = Backbone.View.extend({

  className: 'modal hide fade new-credit-card',

  template: template,

  initialize: function(opts) {
    var that = this;
    this.officeId = opts.officeId;
    this.storedForId = opts.storedForId;
    this.patronType = opts.patronType;
    this.order = opts.order;
  },

  events: {
    'click #save_card_button': 'save'
  },

  render: function () {

    this.$el.html(this.template({type: this.patronType}));

    var that = this;

    // STANDARDIZED MODAL
    this.$el.modal('show').on('hidden', function() {
      that.remove();
    });

    var $country = this.$('#countryCode');
    var $state = this.$('#region');
    var defaultCountry = 'US';
    this.localityAutocomplete = new App.Utils.LocalityAutocomplete({
      $country: $country,
      $state: $state,
      defaultCountry: defaultCountry
    });

    this.formErrors = new App.Utils.FormErrorManager($('#newCreditCardFormErrors'));

  },

  saveClientCard: function() {
    this.model.url = "/clients/" + this.order.get('client').id + "/credit_cards";
  },

  saveOfficeCard: function() {
    //This managementId is the office id of the brokerage that is managing
    // the buyers cards
    if (this.storedForId && (!(this.order.buy)) && (!this.order.isPO()) ) {
      this.model.url = "/offices/" + SESSION.office_id + "/credit_cards?stored_for_id=" + this.storedForId  + "";
      this.model.set('stored_for_id', this.storedForId.toString());
    } else {
      this.model.url = "/offices/" + this.officeId + "/credit_cards";
    }
  },

  save: function () {
    var that = this,
        office_id,
        buyer_id;

    this.loadingButton = new App.Utils.LoadingButton(this.$('#save_card_button'));
    this.loadingButton.startLoad();
    this.loadingBox = new App.Utils.LoadingBox(this.$el.find('.modal-body'));
    this.loadingBox.startLoad();

    this.model = new App.Models.CreditCard();

    if (this.order && this.order.get('client')) {
      this.addressModel = new App.Models.V3.Address();
      var addressAttrs = this.$('form#creditCardForm .address-attributes').serializeObject().address_attributes || {};
      addressAttrs.country_code = this.localityAutocomplete.getCountry();
      addressAttrs.region = this.localityAutocomplete.getState();
      var v3Attrs = {
        _countryCode:     addressAttrs.country_code,
        _locality:        addressAttrs.locality,
        _postalCode:      addressAttrs.postal_code,
        _region:          addressAttrs.region,
        _streetAddress:   addressAttrs.street_address,
        _extendedAddress: addressAttrs.extended_address
      };
      this.addressModel.set(v3Attrs);
      this.addressModel.set('client_id', this.order.get('client').id);
      this.addressModel.url = "/clients/" + this.order.get('client').id + "/addresses"

      $.when(this.addressModel.save()).then(function(response) {
        var address_id = response.id;
        that.model.set('address_id', address_id);
        that.model.set(that.$('form#creditCardForm .credit-card-attributes').serializeObject());

        that.saveClientCard();
        that.saveCard();
      });

    } else {
      office_id = (!this.order || this.order.isPO()) ? SESSION.office_id : (this.order.get("buyer_id") || this.order.get('buyer').id);
      this.officeModel = new App.Models.V3.Office({id: office_id});
      this.officeModel.url = '/offices/' + office_id

      $.when(this.officeModel.fetch()).then(function(data) {
        address_id = data.address.id;
        that.model.set('address_id', address_id);
        that.model.set(that.$('form#creditCardForm .credit-card-attributes').serializeObject());
        that.saveOfficeCard();
        that.saveCard();
      });

    }

  },

  saveCard: function() {
    var that = this;

    if (this.model.isValid(true)) {
      window.App.ErrorManager.ignoreNext(422);
      this.model.save(null, {
        success: function (data) {
          if (that.officeModel) {
            that.refetchOfficeCards(data);
          } else if (that.addressModel){
            that.refetchClientCards(data);
          }
          window.App.ErrorManager.resetTolerance(422);
        },
        error: function(model, response, options) {
          that.formErrors.show({
            title: 'Error Saving Credit Card',
            errors: response
          });
          that.loadingButton.stopLoad();
          that.loadingBox.stopLoad();
          window.App.ErrorManager.resetTolerance(422);
        }
      });
    } else {
      that.formErrors.show({
        title: 'Invalid Credit Card',
        errors: that.model.errors
      });
      that.loadingButton.stopLoad();
      that.loadingBox.stopLoad();
    }
  },

  refetchClientCards: function(data) {
    var clientId = this.order.get('client').id,
        that = this;

    this.clientCollection = new App.Collections.CreditCards(null, {
      ownerType: C.Client,
      clientId: clientId
    });

    $.when(this.clientCollection.fetch()).then(function(response) {
      that.$el.modal('hide');
      that.order.set('clientCreditCards', that.clientCollection);
      that.loadingButton.stopLoad();
      that.loadingBox.stopLoad();
      App.Vent.trigger('reRenderClientCreditCards', that.order);
    });

  },

  refetchOfficeCards: function(data) {
    var storedForId = data.get('stored_for_id'),
        that = this;

    this.officeCollection = new App.Collections.CreditCards(null, {
      ownerType: "office",
      ownerId: SESSION.office_id,
      storedForId: that.storedForId
    });
    if (!(this.storedForId) || (this.order.has('buy') || this.order.isPO())){
      this.officeCollection.url = "/offices/" + SESSION.office_id + "/credit_cards"
    }

    $.when(this.officeCollection.fetch()).then(function(response) {
      that.$el.modal('hide');
      that.loadingButton.stopLoad();
      that.loadingBox.stopLoad();
      App.Vent.trigger('reRenderOfficeCreditCards', that.officeCollection);
      App.Vent.trigger('reRenderBilling');
    });

  }

});
