# REFERENCE:
# https://ticketevolution.atlassian.net/wiki/pages/viewpage.action?pageId=51970050
# https://ticketevolution.atlassian.net/browse/POS-1685
module.exports = App.OrderPermissions = {}

stateIsPending = (state) ->
  # PresenterJSON case.
  if (
    state == C.Options.Order.States.pending ||
    state == C.Options.Order.States.pending_consignment ||
    state == C.Options.Order.States.pending_substitution
  )
    return true

  # Normal case.
  result =  _.contains([C.Pending, C.PendingConsignment, C.PendingSubstitution], state)
  return result

sellerShipmentDetailsProvided = (shipment) ->
  if (shipment.get('_shipFromName') && shipment.get('_shipFromPhoneNumber') && shipment.get('_pickupTimeRange'))
    return true
  return false


stateIsAcceptedOrCompleted = (state) ->
  # PresenterJSON case.
  if (state == C.Options.Order.States.accepted || state == C.Options.Order.States.completed)
    return true

  # Normal case.
  result = _.contains([C.Accepted, C.Completed], state)
  return result

App.OrderPermissions.canAddTransaction = (options) ->
  {
    buyerState
    orderType
    sellerState
  } = options
  if (
    orderType == C.OrderTypes.Purchase ||
    orderType == C.OrderTypes.Sale ||
    orderType == C.OrderTypes.Sell
  )
    if (sellerState != C.Pending && buyerState != C.PendingConsignment)
      return SESSION.role.can(App.Access.Resources.Orders.OrderShow.AddTransaction)
  return false

# CALLED FROM EJS - PRESENTER JSON.
App.OrderPermissions.canApplyPayment = (options) ->
  {
    orderType
    paymentState
    paymentType
  } = options
  if (
    orderType == C.OrderTypes.Purchase ||
    orderType == C.OrderTypes.Sale ||
    orderType == C.OrderTypes.Sell
  )
    if (
      paymentType == C.Options.Order.PaymentTypes.cash ||
      paymentType == C.Options.Order.PaymentTypes.check ||
      paymentType == C.Options.Order.PaymentTypes.money_order ||
      paymentType == C.Options.Order.PaymentTypes.pay_pal ||
      paymentType == C.Options.Order.PaymentTypes.offline
    )
     if (paymentState == C.Options.Payment.States.pending)
      return true
  return false

App.OrderPermissions.buyerShipmentDetailsProvided = (options) ->
  {
    shipmentType
    shipment
  } = options
  if (shipmentType == C.LocalPickup && shipment.get('_shipToName') && shipment.get('_phoneNumber'))
    return true
  # This is for MLB orders which don't need to verify shipment details on Core
  if (shipmentType == C.Barcode && shipment.get('_transferSource') == C.TransferSource.BA)
    return true
  # This is FlashSeat and TMMobile
  if (shipmentType != C.LocalPickup && shipment.get('_emailAddress').get('address'))
    return true
  return false

App.OrderPermissions.canCancelPayment = App.OrderPermissions.canApplyPayment

App.OrderPermissions.canDeliverBarcodes = (options) ->
  {
    sellerState
    shipmentType
    shipment
  } = options
  return shipmentType == C.Barcode && sellerState == C.Accepted && shipment.get('_transferSource') == C.TransferSource.BA

App.OrderPermissions.canEditCosts = (options) ->
  {
    buyerState
    orderType
  } = options
  if (orderType == C.OrderTypes.Purchase)
    if (buyerState == C.PendingConsignment)
      return true
  return false

App.OrderPermissions.canEnterChildsSeatNumbers = (options) ->
  {
    hasSeatNumbers
    isPO
    orderType
    sellerState
    userIsPOS
  } = options
  if (
    orderType == C.OrderTypes.Sale ||
    orderType == C.OrderTypes.Sell ||
    (orderType == C.OrderTypes.Direct && !isPO)
  )
    if (!userIsPOS)
      if (!hasSeatNumbers)
        if (sellerState == C.Open)
          if (SESSION.role.can(App.Access.Resources.Orders.BuySell.CanEnterSeats))
            return true
  return false

App.OrderPermissions.canEnterSeatNumbers = (options) ->
  {
    hasSeatNumbers
    isPO
    orderType
    sellerState
  } = options
  if (orderType == C.OrderTypes.Direct && !isPO)
    if (!hasSeatNumbers)
      if (sellerState == C.Pending)
        if (SESSION.role.can(App.Access.Resources.Orders.BuySell.CanEnterSeats))
          return true
  return false

App.OrderPermissions.canGenerateAirbill = (options) ->
  {
    isPO
    orderType
    sellerState
    shipmentType
    shipmentHasAirbill
  } = options
  if (
    (orderType == C.OrderTypes.Direct && !isPO) ||
    orderType == C.OrderTypes.Purchase ||
    orderType == C.OrderTypes.Sale ||
    orderType == C.OrderTypes.Sell
  )
    if (stateIsAcceptedOrCompleted(sellerState))
      if (shipmentType == C.FedEx)
        if (!shipmentHasAirbill)
          return true
  return false

App.OrderPermissions.canViewShippingCost = (options) ->
  {
    isPO
    orderType
  } = options
  if (orderType == C.OrderTypes.Direct && isPO)
    return false
  return true

App.OrderPermissions.canOrderBeAccepted = (options) ->
  {
    isPO
    orderType
    sellerState
  } = options
  if (
    orderType == C.OrderTypes.Sale ||
    orderType == C.OrderTypes.Sell ||
    (orderType == C.OrderTypes.Direct && !isPO)
  )
    if (_.contains([C.Pending, C.PendingSubstitution], sellerState))
      return SESSION.role.can(App.Access.Resources.Orders.OrderShow.Accept)
  return false

App.OrderPermissions.canOrderBeCompleted = (options) ->
  {
    isPO
    orderType
    sellerState
    shipmentType
    shipment
  } = options
  if (
    (shipmentType == C.FlashSeats || shipmentType == C.TMMobile || shipmentType == C.Barcode) &&
      orderType == C.OrderTypes.Direct &&
      !isPO && C.Accepted == sellerState &&
      App.OrderPermissions.buyerShipmentDetailsProvided(options)
  )
    return true
  return false

App.OrderPermissions.canSubmitTMMobileLink = (options) ->
  {
    isPO
    orderType
    sellerState
    shipmentType
    shipment
  } = options
  if (
    (shipmentType == C.TMMobileLink ) && !isPO &&
      orderType == C.OrderTypes.Direct &&
       C.Accepted == sellerState &&
      !shipment.get('tm_mobile_link')
  )
    return true
  return false

App.OrderPermissions.canUpdateShipmentType = (options) ->
  {
    child_orders
    sellerState
    isPO
    orderType
  } = options
  if (
    !isPO && sellerState == C.Accepted &&
    orderType == C.OrderTypes.Direct &&
    child_orders.length == 0  # Order is a Bottom Order Link
  )
    return true
  return false


App.OrderPermissions.canOrderBeCancelled = (options) ->
  {
    orderType
    sellerState
  } = options
  if (
    orderType == C.OrderTypes.Purchase ||
    orderType == C.OrderTypes.Sale ||
    orderType == C.OrderTypes.Sell
  )
    if (stateIsAcceptedOrCompleted(sellerState))
      return SESSION.role.can(App.Access.Resources.Orders.OrderShow.Cancel)
  return false

App.OrderPermissions.canOrderBeConsignmentAccepted = (options) ->
  {
    buyerState
    isConsignment
    orderType
  } = options
  if (isConsignment)
    if (orderType == C.OrderTypes.Purchase)
      if (buyerState == C.PendingConsignment)
        return true
  return false

App.OrderPermissions.canOrderBeRejected = (options) ->
  {
    isPO
    orderType
    sellerState
  } = options
  if (
    orderType == C.OrderTypes.Sale ||
    orderType == C.OrderTypes.Sell ||
    (orderType == C.OrderTypes.Direct && !isPO)
  )
    if (_.contains([C.Pending, C.PendingSubstitution], sellerState))
      return SESSION.role.can(App.Access.Resources.Orders.OrderShow.Reject)
  return false

App.OrderPermissions.canPrintAirbill = (options) ->
  {
    orderType
    sellerState
    shipmentType
    shipmentHasAirbill
  } = options
  if (
    orderType == C.OrderTypes.Direct ||
    orderType == C.OrderTypes.Purchase ||
    orderType == C.OrderTypes.Sale ||
    orderType == C.OrderTypes.Sell
  )
    if (stateIsAcceptedOrCompleted(sellerState))
      if (shipmentType == C.FedEx || shipmentType == C.ProvidedAirbill)
        if (shipmentHasAirbill)
          return true
  return false

App.OrderPermissions.canUploadAirbill = (options) ->
  {
  isPO
  orderType
  sellerState
  shipmentType
  shipmentHasAirbill
  } = options
  if (
    (orderType == C.OrderTypes.Direct && isPO) ||
    orderType == C.OrderTypes.Purchase ||
    orderType == C.OrderTypes.Sale ||
    orderType == C.OrderTypes.Sell
  )
    if (stateIsAcceptedOrCompleted(sellerState) || stateIsPending(sellerState))
      if (shipmentType == C.ProvidedAirbill)
        if (!shipmentHasAirbill)
          return true
  return false

App.OrderPermissions.canRequestAirbill = (options) ->
  {
  isPO
  orderType
  sellerState
  shipmentType
  shipmentHasAirbill
  } = options
  if (
    (orderType == C.OrderTypes.Direct && !isPO)
  )
    if (stateIsAcceptedOrCompleted(sellerState))
      if (shipmentType == C.ProvidedAirbill)
        if (!shipmentHasAirbill)
          return true
  return false

App.OrderPermissions.canSeeKount = (options) ->
  {
    orderType
  } = options
  if (orderType == C.OrderTypes.Sale || orderType == C.OrderTypes.Sell)
    if (SESSION.role.can(App.Access.Resources.Orders.OrderShow.ViewKount))
      return true
  return false

App.OrderPermissions.canShipmentBeCancelledAndCreated = (options) ->
  {
    orderType
    shipmentState
  } = options
  if (
    orderType == C.OrderTypes.Purchase ||
    orderType == C.OrderTypes.Sell
  )
    if (shipmentState == 'pending')
      return true
  return false

App.OrderPermissions.canShipmentBeEdited = (options) ->
  {
    isPO
    orderType
    sellerState
    shipmentState
    shipmentType
    shipment
    ticketGroupModel
  } = options
  if (
    shipmentType == C.LocalPickup &&
      (((orderType == C.OrderTypes.Sale || orderType == C.OrderTypes.Sell || isPO) && !App.OrderPermissions.buyerShipmentDetailsProvided(options)) ||
        (orderType == C.OrderTypes.Direct && !isPO && !sellerShipmentDetailsProvided(shipment)))
  )
    return true
  else if (
    (shipmentType == C.FlashSeats || shipmentType == C.TMMobile || shipmentType == C.TMMobileLink) &&
      (orderType == C.OrderTypes.Sale || orderType == C.OrderTypes.Sell || isPO) && !App.OrderPermissions.buyerShipmentDetailsProvided(options) &&
      shipmentState == 'pending' && (!shipment.get('_buyerEditCount') || parseInt(shipment.get('_buyerEditCount')) < 1)
  )
    return true
  else if (
    shipmentType != C.LocalPickup &&
      shipmentType != C.FlashSeats &&
      shipmentType != C.TMMobile &&
      shipmentType != C.TMMobileLink &&
      (ticketGroupModel.format() != "TM Mobile" && shipmentType != C.TBDShipment) &&
      (orderType == C.OrderTypes.Purchase ||
        orderType == C.OrderTypes.Sale ||
        orderType == C.OrderTypes.Sell ||
        (orderType == C.OrderTypes.Direct && isPO))
  )
    if ((stateIsAcceptedOrCompleted(sellerState)) || sellerState == C.Pending)
      if (shipmentType != C.ProvidedAirbill)
        if (shipmentState == 'pending')
          return true
  return false

App.OrderPermissions.canShipmentBeMarkedAsDelivered = (options) ->
  {
    isPO
    orderType
    sellerState
    shipmentType
    shipmentState
  } = options
  if (shipmentState == 'pending')
    if (shipmentType == C.FlashSeats || shipmentType == C.TMMobile)
      if (orderType == C.OrderTypes.Direct && !isPO && sellerState == C.Options.Order.States.completed)
        return true
    else if (
      shipmentType != C.FedEx &&
      (shipmentType == C.LocalPickup && isPO) &&
        stateIsAcceptedOrCompleted(sellerState) &&
        (orderType == C.OrderTypes.Purchase ||
          orderType == C.OrderTypes.Sale ||
          orderType == C.OrderTypes.Sell)
    )
      return true
  return false

App.OrderPermissions.canShipmentBePended = (options) ->
  {
    orderType
    sellerState
    shipmentType
    shipmentState
  } = options
  if (
    orderType == C.OrderTypes.Purchase ||
    orderType == C.OrderTypes.Sale ||
    orderType == C.OrderTypes.Sell
  )
    if (shipmentType != C.FedEx)
      if (shipmentState == 'delivered')
        if (stateIsAcceptedOrCompleted(sellerState))
          return true
  return false

App.OrderPermissions.canRequestProofOfTransfer = (options) ->
  {
    isPO
    orderType
    shipmentState
    shipment
  } = options
  if (
    isPO &&
      (orderType == C.OrderTypes.Direct ||
       orderType == C.OrderTypes.Sale)
  )
    if (shipmentState == 'delivered')
      if (shipment.attributes.transfer_verifications.length == 0)
        return true
  return false

App.OrderPermissions.canViewPOs = (options) ->
  {
    isPO
    orderType
  } = options
  if (
    (orderType == C.OrderTypes.Direct && !isPO) ||
    orderType == C.OrderTypes.Sale ||
    orderType == C.OrderTypes.Sell
  )
    if (SESSION.role.can(App.Access.Resources.Orders.OrderShow.ViewPOs))
      return true
  return false

App.OrderPermissions.canRequestSellerInfo = (options) ->
  {
    shipment
    shipmentType
    shipmentState
    isTevoOrder
  } = options
  if (
    !isTevoOrder &&
    shipmentState == 'pending' &&
      shipmentType == C.LocalPickup &&
      !(sellerShipmentDetailsProvided(shipment))
  )
    return true
  return false

App.OrderPermissions.canRequestBuyerInfo = (options) ->
  {
    shipment
    shipmentType
    shipmentState
    isTevoOrder
  } = options
  if (
    isTevoOrder &&
      shipmentState == 'pending' &&
      shipmentType == C.LocalPickup &&
      !(App.OrderPermissions.buyerShipmentDetailsProvided(options))
  )
    return true
  return false

App.OrderPermissions.canViewSoldTickets = (options) ->
  {
    isPO
    orderType
  } = options
  if (
    orderType == C.OrderTypes.Purchase ||
    (orderType == C.OrderTypes.Direct && isPO)
  )
    if (SESSION.role.can(App.Access.Resources.Orders.OrderShow.ViewSoldTickets))
      return true
  return false

# CALLED FROM EJS - PRESENTER JSON.
App.OrderPermissions.eticketActionButton = (options) ->
  {
    isPO
    orderId
    orderItem
    orderType
    sellerState
    shipmentState
    shipmentType
  } = options

  #hasPages = orderItem.eTicketPDFPages
  #isE = orderItem.isEticket
  #isFinalized = orderItem.isEticketFinalized

  # C.EticketActions.Sell.Upload
  # CASE = UPLOAD - You haven't added anything yet but need to.
  if ((orderType == C.OrderTypes.Direct && !isPO) || orderType == C.OrderTypes.Sale || orderType == C.OrderTypes.Sell)
    if (stateIsAcceptedOrCompleted(sellerState))
      if (!orderItem.eTicketPDFPages && (shipmentType == C.InstantDelivery || shipmentType == C.Eticket))
        if (orderItem.needs_eticket)
          return {
            case: C.EticketActions.Sell.Upload
            html: "<span class='eticketButtonContainer'>
                    <div
                    class='btn btn-primary eticketButton'
                    data-item-id='#{ orderItem.id }'>
                      <i class='fa-solid fa-upload'></i> Upload Etickets
                    </div>
                  </span>"
          }

  # C.EticketActions.Sell.Manage
  # CASE = MANAGE - You added some PDF's but never finalized.
  if ((orderType == C.OrderTypes.Direct && !isPO) || orderType == C.OrderTypes.Sale || orderType == C.OrderTypes.Sell)
    if (stateIsAcceptedOrCompleted(sellerState))
      if (!orderItem.isEticketFinalized && orderItem.eTicketPDFPages > 0)
        return {
          case: C.EticketActions.Sell.Manage
          html: "<span class='eticketButtonContainer'>
                  <div
                  class='btn btn-info eticketButton'
                  data-item-id='#{ orderItem.id }'>
                  <i class='fa-solid fa-qrcode'></i> Manage Etickets
                </div>
              </span>"
        }

  # C.EticketActions.Buy.Download
  # CASE = DOWNLOAD - You're on the consuming end of the order & etickets are ready for download.
  # AND
  # C.EticketActions.Sell.View
  # CASE = VIEW - You've uploaded and finalized the etickets.
  if (orderType == C.OrderTypes.Direct || orderType == C.OrderTypes.Sale || orderType == C.OrderTypes.Sell)
    if (stateIsAcceptedOrCompleted(sellerState))
      if (orderItem.isEticketFinalized)
        filename = App.Utils.fileNameFromOrderItem(orderItem)
        return {
          case: C.EticketActions.Buy.Download
          html: "<span class='eticketButtonContainer'>
                  <div
                  class='btn btn-success eticketButton'
                  data-item-id='#{ orderItem.id }'>
                    <i class='fa-solid fa-magnifying-glass-plus'></i> View Etickets
                  </div>
                  <a
                  style='margin-left: 10px;'
                  class='btn btn-primary'
                  data-bypass='1'
                  href='/orders/#{ orderId }/print_etickets/#{ orderItem.order_item_id }?filename=#{ encodeURIComponent(filename) }'>
                    <i class='fa-solid fa-download'></i> Download Etickets
                  </a>
                </span>"
        }

  # C.EticketActions.Sell.Convert
  if (SESSION.role.can(App.Access.Resources.Orders.OrderShow.CanConvertEtickets))
    if (orderType == C.OrderTypes.Sale || orderType == C.OrderTypes.Sell)
      if (sellerState == C.Options.Order.States.accepted)
        if (!orderItem.needsEticket)
          if (shipmentState != C.Options.Shipment.States.delivered)
            return {
              case: C.EticketActions.Sell.Convert
              html: "<span class='eticketButtonContainer'>
                        <div
                        data-toggle='tooltip'
                        title='Warning: Only use this button if you know the Seller has the ability to upload Etickets for this Order.'
                        class='btn btn-warning eticketButton'
                        data-item-id='#{ orderItem.id }'>
                        Etickets can be Uploaded
                      </div>
                    </span>"
            }

  # C.EticketActions.Buy.Waiting
  # CASE = WAITING - You're on the consuming end of the order & no etickets available yet.
  if (orderType == C.OrderTypes.Direct && isPO)
    if (sellerState == C.Options.Order.States.accepted)
      if (orderItem.needsEticket && !orderItem.eTicketPDFPages)
        return {
          case: C.EticketActions.Buy.Waiting
          html: "<span class='eticketButtonContainer'>
                  Waiting for Seller to Upload Etickets
                </span>"
        }

  return {
    case: null
    html: ''
  }

ORDER_ALIASES = {
  USER_IS_BUYER: {}
  USER_IS_SELLER: {}
}

ORDER_ALIASES.USER_IS_BUYER[C.Accepted] = {}
ORDER_ALIASES.USER_IS_BUYER[C.Accepted][C.Accepted] = 'Confirmed Purchase Pending Delivery'
ORDER_ALIASES.USER_IS_BUYER[C.Accepted][C.CancelProposed] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_BUYER[C.Accepted][C.Canceled] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Accepted][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_BUYER[C.Accepted][C.CanceledPostAcceptance] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Accepted][C.Completed] = 'Confirmed Purchase Pending Delivery'
ORDER_ALIASES.USER_IS_BUYER[C.Accepted][C.Open] = ''
ORDER_ALIASES.USER_IS_BUYER[C.Accepted][C.Pending] = 'Confirmed Purchase Pending Delivery'
ORDER_ALIASES.USER_IS_BUYER[C.Accepted][C.PendingConsignment] = 'Open Consignment Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Accepted][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_BUYER[C.Accepted][C.Rejected] = 'Rejected Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Accepted][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_BUYER[C.CancelProposed] = {}
ORDER_ALIASES.USER_IS_BUYER[C.CancelProposed][C.Accepted] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_BUYER[C.CancelProposed][C.CancelProposed] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_BUYER[C.CancelProposed][C.Canceled] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_BUYER[C.CancelProposed][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_BUYER[C.CancelProposed][C.CanceledPostAcceptance] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_BUYER[C.CancelProposed][C.Completed] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_BUYER[C.CancelProposed][C.Open] = ''
ORDER_ALIASES.USER_IS_BUYER[C.CancelProposed][C.Pending] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_BUYER[C.CancelProposed][C.PendingConsignment] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_BUYER[C.CancelProposed][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_BUYER[C.CancelProposed][C.Rejected] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_BUYER[C.CancelProposed][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_BUYER[C.Canceled] = {}
ORDER_ALIASES.USER_IS_BUYER[C.Canceled][C.Accepted] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Canceled][C.CancelProposed] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_BUYER[C.Canceled][C.Canceled] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Canceled][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_BUYER[C.Canceled][C.CanceledPostAcceptance] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Canceled][C.Completed] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Canceled][C.Open] = ''
ORDER_ALIASES.USER_IS_BUYER[C.Canceled][C.Pending] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Canceled][C.PendingConsignment] = 'Open Consignment Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Canceled][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_BUYER[C.Canceled][C.Rejected] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Canceled][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_BUYER[C.CanceledOpen] = {}
ORDER_ALIASES.USER_IS_BUYER[C.CanceledOpen][C.Accepted] = 'Unused Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.CanceledOpen][C.CancelProposed] = 'Unused Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.CanceledOpen][C.Canceled] = 'Unused Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.CanceledOpen][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_BUYER[C.CanceledOpen][C.CanceledPostAcceptance] = 'Unused Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.CanceledOpen][C.Completed] = 'Unused Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.CanceledOpen][C.Open] = ''
ORDER_ALIASES.USER_IS_BUYER[C.CanceledOpen][C.Pending] = 'Unused Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.CanceledOpen][C.PendingConsignment] = 'Unused Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.CanceledOpen][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_BUYER[C.CanceledOpen][C.Rejected] = 'Unused Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.CanceledOpen][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_BUYER[C.CanceledPostAcceptance] = {}
ORDER_ALIASES.USER_IS_BUYER[C.CanceledPostAcceptance][C.Accepted] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.CanceledPostAcceptance][C.CancelProposed] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_BUYER[C.CanceledPostAcceptance][C.Canceled] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.CanceledPostAcceptance][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_BUYER[C.CanceledPostAcceptance][C.CanceledPostAcceptance] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.CanceledPostAcceptance][C.Completed] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.CanceledPostAcceptance][C.Open] = ''
ORDER_ALIASES.USER_IS_BUYER[C.CanceledPostAcceptance][C.Pending] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.CanceledPostAcceptance][C.PendingConsignment] = 'Open Consignment Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.CanceledPostAcceptance][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_BUYER[C.CanceledPostAcceptance][C.Rejected] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.CanceledPostAcceptance][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_BUYER[C.Completed] = {}
ORDER_ALIASES.USER_IS_BUYER[C.Completed][C.Accepted] = 'Confirmed & Delivered Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Completed][C.CancelProposed] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_BUYER[C.Completed][C.Canceled] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Completed][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_BUYER[C.Completed][C.CanceledPostAcceptance] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Completed][C.Completed] = 'Confirmed & Delivered Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Completed][C.Open] = ''
ORDER_ALIASES.USER_IS_BUYER[C.Completed][C.Pending] = 'Confirmed & Delivered Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Completed][C.PendingConsignment] = 'Open Consignment Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Completed][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_BUYER[C.Completed][C.Rejected] = 'Rejected Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Completed][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_BUYER[C.Open] = {}
ORDER_ALIASES.USER_IS_BUYER[C.Open][C.Accepted] = 'Unsent Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Open][C.CancelProposed] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_BUYER[C.Open][C.Canceled] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Open][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_BUYER[C.Open][C.CanceledPostAcceptance] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Open][C.Completed] = 'Unsent Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Open][C.Open] = ''
ORDER_ALIASES.USER_IS_BUYER[C.Open][C.Pending] = 'Unsent Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Open][C.PendingConsignment] = 'Open Consignment Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Open][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_BUYER[C.Open][C.Rejected] = 'Rejected Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Open][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_BUYER[C.Pending] = {}
ORDER_ALIASES.USER_IS_BUYER[C.Pending][C.Accepted] = 'Unconfirmed Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Pending][C.CancelProposed] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_BUYER[C.Pending][C.Canceled] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Pending][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_BUYER[C.Pending][C.CanceledPostAcceptance] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Pending][C.Completed] = 'Unconfirmed Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Pending][C.Open] = ''
ORDER_ALIASES.USER_IS_BUYER[C.Pending][C.Pending] = 'Unconfirmed Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Pending][C.PendingConsignment] = 'Open Consignment Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Pending][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_BUYER[C.Pending][C.Rejected] = 'Rejected Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Pending][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_BUYER[C.PendingConsignment] = {}
ORDER_ALIASES.USER_IS_BUYER[C.PendingConsignment][C.Accepted] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingConsignment][C.CancelProposed] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingConsignment][C.Canceled] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingConsignment][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingConsignment][C.CanceledPostAcceptance] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingConsignment][C.Completed] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingConsignment][C.Open] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingConsignment][C.Pending] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingConsignment][C.PendingConsignment] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingConsignment][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingConsignment][C.Rejected] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingConsignment][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_BUYER[C.PendingSubstitution] = {}
ORDER_ALIASES.USER_IS_BUYER[C.PendingSubstitution][C.Accepted] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingSubstitution][C.CancelProposed] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingSubstitution][C.Canceled] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingSubstitution][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingSubstitution][C.CanceledPostAcceptance] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingSubstitution][C.Completed] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingSubstitution][C.Open] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingSubstitution][C.Pending] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingSubstitution][C.PendingConsignment] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingSubstitution][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingSubstitution][C.Rejected] = ''
ORDER_ALIASES.USER_IS_BUYER[C.PendingSubstitution][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_BUYER[C.Rejected] = {}
ORDER_ALIASES.USER_IS_BUYER[C.Rejected][C.Accepted] = 'Rejected Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Rejected][C.CancelProposed] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_BUYER[C.Rejected][C.Canceled] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Rejected][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_BUYER[C.Rejected][C.CanceledPostAcceptance] = 'Canceled Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Rejected][C.Completed] = 'Rejected Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Rejected][C.Open] = ''
ORDER_ALIASES.USER_IS_BUYER[C.Rejected][C.Pending] = 'Rejected Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Rejected][C.PendingConsignment] = 'Open Consignment Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Rejected][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_BUYER[C.Rejected][C.Rejected] = 'Rejected Purchase'
ORDER_ALIASES.USER_IS_BUYER[C.Rejected][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_BUYER[C.UserCanceled] = {}
ORDER_ALIASES.USER_IS_BUYER[C.UserCanceled][C.Accepted] = ''
ORDER_ALIASES.USER_IS_BUYER[C.UserCanceled][C.CancelProposed] = ''
ORDER_ALIASES.USER_IS_BUYER[C.UserCanceled][C.Canceled] = ''
ORDER_ALIASES.USER_IS_BUYER[C.UserCanceled][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_BUYER[C.UserCanceled][C.CanceledPostAcceptance] = ''
ORDER_ALIASES.USER_IS_BUYER[C.UserCanceled][C.Completed] = ''
ORDER_ALIASES.USER_IS_BUYER[C.UserCanceled][C.Open] = ''
ORDER_ALIASES.USER_IS_BUYER[C.UserCanceled][C.Pending] = ''
ORDER_ALIASES.USER_IS_BUYER[C.UserCanceled][C.PendingConsignment] = ''
ORDER_ALIASES.USER_IS_BUYER[C.UserCanceled][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_BUYER[C.UserCanceled][C.Rejected] = ''
ORDER_ALIASES.USER_IS_BUYER[C.UserCanceled][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_SELLER[C.Accepted] = {}
ORDER_ALIASES.USER_IS_SELLER[C.Accepted][C.Accepted] = 'Confirmed Order Pending Delivery'
ORDER_ALIASES.USER_IS_SELLER[C.Accepted][C.CancelProposed] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_SELLER[C.Accepted][C.Canceled] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.Accepted][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Accepted][C.CanceledPostAcceptance] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.Accepted][C.Completed] = 'Confirmed Order Pending Delivery'
ORDER_ALIASES.USER_IS_SELLER[C.Accepted][C.Open] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Accepted][C.Pending] = 'Confirmed Order Pending Delivery'
ORDER_ALIASES.USER_IS_SELLER[C.Accepted][C.PendingConsignment] = 'Confirmed Order Pending Delivery'
ORDER_ALIASES.USER_IS_SELLER[C.Accepted][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Accepted][C.Rejected] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.Accepted][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_SELLER[C.CancelProposed] = {}
ORDER_ALIASES.USER_IS_SELLER[C.CancelProposed][C.Accepted] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_SELLER[C.CancelProposed][C.CancelProposed] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_SELLER[C.CancelProposed][C.Canceled] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_SELLER[C.CancelProposed][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_SELLER[C.CancelProposed][C.CanceledPostAcceptance] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_SELLER[C.CancelProposed][C.Completed] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_SELLER[C.CancelProposed][C.Open] = ''
ORDER_ALIASES.USER_IS_SELLER[C.CancelProposed][C.Pending] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_SELLER[C.CancelProposed][C.PendingConsignment] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_SELLER[C.CancelProposed][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_SELLER[C.CancelProposed][C.Rejected] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_SELLER[C.CancelProposed][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_SELLER[C.Canceled] = {}
ORDER_ALIASES.USER_IS_SELLER[C.Canceled][C.Accepted] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.Canceled][C.CancelProposed] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_SELLER[C.Canceled][C.Canceled] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.Canceled][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Canceled][C.CanceledPostAcceptance] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.Canceled][C.Completed] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.Canceled][C.Open] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Canceled][C.Pending] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.Canceled][C.PendingConsignment] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.Canceled][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Canceled][C.Rejected] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.Canceled][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_SELLER[C.CanceledOpen] = {}
ORDER_ALIASES.USER_IS_SELLER[C.CanceledOpen][C.Accepted] = ''
ORDER_ALIASES.USER_IS_SELLER[C.CanceledOpen][C.CancelProposed] = ''
ORDER_ALIASES.USER_IS_SELLER[C.CanceledOpen][C.Canceled] = ''
ORDER_ALIASES.USER_IS_SELLER[C.CanceledOpen][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_SELLER[C.CanceledOpen][C.CanceledPostAcceptance] = ''
ORDER_ALIASES.USER_IS_SELLER[C.CanceledOpen][C.Completed] = ''
ORDER_ALIASES.USER_IS_SELLER[C.CanceledOpen][C.Open] = ''
ORDER_ALIASES.USER_IS_SELLER[C.CanceledOpen][C.Pending] = ''
ORDER_ALIASES.USER_IS_SELLER[C.CanceledOpen][C.PendingConsignment] = ''
ORDER_ALIASES.USER_IS_SELLER[C.CanceledOpen][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_SELLER[C.CanceledOpen][C.Rejected] = ''
ORDER_ALIASES.USER_IS_SELLER[C.CanceledOpen][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_SELLER[C.CanceledPostAcceptance] = {}
ORDER_ALIASES.USER_IS_SELLER[C.CanceledPostAcceptance][C.Accepted] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.CanceledPostAcceptance][C.CancelProposed] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_SELLER[C.CanceledPostAcceptance][C.Canceled] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.CanceledPostAcceptance][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_SELLER[C.CanceledPostAcceptance][C.CanceledPostAcceptance] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.CanceledPostAcceptance][C.Completed] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.CanceledPostAcceptance][C.Open] = ''
ORDER_ALIASES.USER_IS_SELLER[C.CanceledPostAcceptance][C.Pending] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.CanceledPostAcceptance][C.PendingConsignment] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.CanceledPostAcceptance][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_SELLER[C.CanceledPostAcceptance][C.Rejected] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.CanceledPostAcceptance][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_SELLER[C.Completed] = {}
ORDER_ALIASES.USER_IS_SELLER[C.Completed][C.Accepted] = 'Confirmed & Delivered Order'
ORDER_ALIASES.USER_IS_SELLER[C.Completed][C.CancelProposed] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_SELLER[C.Completed][C.Canceled] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.Completed][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Completed][C.CanceledPostAcceptance] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.Completed][C.Completed] = 'Confirmed & Delivered Order'
ORDER_ALIASES.USER_IS_SELLER[C.Completed][C.Open] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Completed][C.Pending] = 'Confirmed & Delivered Order'
ORDER_ALIASES.USER_IS_SELLER[C.Completed][C.PendingConsignment] = 'Confirmed & Delivered Order'
ORDER_ALIASES.USER_IS_SELLER[C.Completed][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Completed][C.Rejected] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.Completed][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_SELLER[C.Open] = {}
ORDER_ALIASES.USER_IS_SELLER[C.Open][C.Accepted] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Open][C.CancelProposed] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Open][C.Canceled] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Open][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Open][C.CanceledPostAcceptance] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Open][C.Completed] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Open][C.Open] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Open][C.Pending] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Open][C.PendingConsignment] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Open][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Open][C.Rejected] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Open][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_SELLER[C.Pending] = {}
ORDER_ALIASES.USER_IS_SELLER[C.Pending][C.Accepted] = 'Unconfirmed Order'
ORDER_ALIASES.USER_IS_SELLER[C.Pending][C.CancelProposed] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_SELLER[C.Pending][C.Canceled] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.Pending][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Pending][C.CanceledPostAcceptance] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.Pending][C.Completed] = 'Unconfirmed Order'
ORDER_ALIASES.USER_IS_SELLER[C.Pending][C.Open] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Pending][C.Pending] = 'Unconfirmed Order'
ORDER_ALIASES.USER_IS_SELLER[C.Pending][C.PendingConsignment] = 'Unconfirmed Order'
ORDER_ALIASES.USER_IS_SELLER[C.Pending][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Pending][C.Rejected] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.Pending][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_SELLER[C.PendingConsignment] = {}
ORDER_ALIASES.USER_IS_SELLER[C.PendingConsignment][C.Accepted] = ''
ORDER_ALIASES.USER_IS_SELLER[C.PendingConsignment][C.CancelProposed] = ''
ORDER_ALIASES.USER_IS_SELLER[C.PendingConsignment][C.Canceled] = ''
ORDER_ALIASES.USER_IS_SELLER[C.PendingConsignment][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_SELLER[C.PendingConsignment][C.CanceledPostAcceptance] = ''
ORDER_ALIASES.USER_IS_SELLER[C.PendingConsignment][C.Completed] = ''
ORDER_ALIASES.USER_IS_SELLER[C.PendingConsignment][C.Open] = ''
ORDER_ALIASES.USER_IS_SELLER[C.PendingConsignment][C.Pending] = ''
ORDER_ALIASES.USER_IS_SELLER[C.PendingConsignment][C.PendingConsignment] = ''
ORDER_ALIASES.USER_IS_SELLER[C.PendingConsignment][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_SELLER[C.PendingConsignment][C.Rejected] = ''
ORDER_ALIASES.USER_IS_SELLER[C.PendingConsignment][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_SELLER[C.PendingSubstitution] = {}
ORDER_ALIASES.USER_IS_SELLER[C.PendingSubstitution][C.Accepted] = 'Confirmed Order Pending Subs'
ORDER_ALIASES.USER_IS_SELLER[C.PendingSubstitution][C.CancelProposed] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_SELLER[C.PendingSubstitution][C.Canceled] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.PendingSubstitution][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_SELLER[C.PendingSubstitution][C.CanceledPostAcceptance] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.PendingSubstitution][C.Completed] = 'Confirmed Order Pending Subs'
ORDER_ALIASES.USER_IS_SELLER[C.PendingSubstitution][C.Open] = ''
ORDER_ALIASES.USER_IS_SELLER[C.PendingSubstitution][C.Pending] = 'Confirmed Order Pending Subs'
ORDER_ALIASES.USER_IS_SELLER[C.PendingSubstitution][C.PendingConsignment] = 'Confirmed Order Pending Subs'
ORDER_ALIASES.USER_IS_SELLER[C.PendingSubstitution][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_SELLER[C.PendingSubstitution][C.Rejected] = 'Canceled Order'
ORDER_ALIASES.USER_IS_SELLER[C.PendingSubstitution][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_SELLER[C.Rejected] = {}
ORDER_ALIASES.USER_IS_SELLER[C.Rejected][C.Accepted] = 'Rejected Order'
ORDER_ALIASES.USER_IS_SELLER[C.Rejected][C.CancelProposed] = 'Cancellation Initiated'
ORDER_ALIASES.USER_IS_SELLER[C.Rejected][C.Canceled] = 'Rejected Order'
ORDER_ALIASES.USER_IS_SELLER[C.Rejected][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Rejected][C.CanceledPostAcceptance] = 'Rejected Order'
ORDER_ALIASES.USER_IS_SELLER[C.Rejected][C.Completed] = 'Rejected Order'
ORDER_ALIASES.USER_IS_SELLER[C.Rejected][C.Open] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Rejected][C.Pending] = 'Rejected Order'
ORDER_ALIASES.USER_IS_SELLER[C.Rejected][C.PendingConsignment] = 'Rejected Order'
ORDER_ALIASES.USER_IS_SELLER[C.Rejected][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_SELLER[C.Rejected][C.Rejected] = 'Rejected Order'
ORDER_ALIASES.USER_IS_SELLER[C.Rejected][C.UserCanceled] = ''

ORDER_ALIASES.USER_IS_SELLER[C.UserCanceled] = {}
ORDER_ALIASES.USER_IS_SELLER[C.UserCanceled][C.Accepted] = ''
ORDER_ALIASES.USER_IS_SELLER[C.UserCanceled][C.CancelProposed] = ''
ORDER_ALIASES.USER_IS_SELLER[C.UserCanceled][C.Canceled] = ''
ORDER_ALIASES.USER_IS_SELLER[C.UserCanceled][C.CanceledOpen] = ''
ORDER_ALIASES.USER_IS_SELLER[C.UserCanceled][C.CanceledPostAcceptance] = ''
ORDER_ALIASES.USER_IS_SELLER[C.UserCanceled][C.Completed] = ''
ORDER_ALIASES.USER_IS_SELLER[C.UserCanceled][C.Open] = ''
ORDER_ALIASES.USER_IS_SELLER[C.UserCanceled][C.Pending] = ''
ORDER_ALIASES.USER_IS_SELLER[C.UserCanceled][C.PendingConsignment] = ''
ORDER_ALIASES.USER_IS_SELLER[C.UserCanceled][C.PendingSubstitution] = ''
ORDER_ALIASES.USER_IS_SELLER[C.UserCanceled][C.Rejected] = ''
ORDER_ALIASES.USER_IS_SELLER[C.UserCanceled][C.UserCanceled] = ''

App.OrderPermissions.label = (options) ->
  {
    buyerState
    isPO
    sellerState
  } = options
  buyerOrSeller = 'USER_IS_SELLER'
  if (isPO)
    buyerOrSeller = 'USER_IS_BUYER'
  return ORDER_ALIASES[buyerOrSeller][sellerState][buyerState]
