App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V3.ApiToken = require('../../models/v3/api_token_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V3.ApiTokens = App.Collections.Base.BaseCollection.extend

  model: App.Models.V3.ApiToken

  url: () ->
    if (@officeOnly)
      return '/api_direct/v9/credentials?filter_by_office=true'
    else
      return '/api_direct/v9/credentials'

  parse: (response, options) ->
    response.credentials

  comparator: (apiTokenModel) ->
    return apiTokenModel.get('pretty_label') || apiTokenModel.get('label')

  initialize: (models, options = {}) ->
    {
      @officeOnly
    } = options
    parent.initialize.call(@, models, options)