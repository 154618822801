template = require('./shipment_new.ejs')
previewTemplate = require('./shipment_new_preview.ejs')

App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Shipping.New = App.Views.Base.BaseView.extend

  template: template

  previewTemplate: previewTemplate

  initialize: (options = {}) ->
    parent.initialize.call(@, options)
    {
      @$container
      @senderOfficeModel
    } = options
    @lastSelectedFromPatron = null
    @lastSelectedToPatron = null
    @render()

  render: () ->
    data = {}
    @$container.html(
      @$el.html(
        @template(data)
      )
    )

    @$fromContainer = @$('#fromShipmentContainer')
    @$fromPatronContainer = @$("#fromPatronContainer")
    @$toContainer = @$('#toShipmentContainer')
    @$toPatronContainer = @$("#toPatronContainer")

    @fromLoadingBox = new App.Utils.LoadingBox(@$fromContainer)
    @toLoadingBox = new App.Utils.LoadingBox(@$toContainer)

    @fromFormErrors = new App.Utils.FormErrorManager(@$('#fromFormErrors'))
    @toFormErrors = new App.Utils.FormErrorManager(@$('#toFormErrors'))
    @createFormErrors = new App.Utils.FormErrorManager(@$('#createFormErrors'))

    @shipmentTypes = new App.Collections.V3.ShipmentTypes(null, {
      mapping: C.Mappings.Direct.ShipmentTypes.ShipmentTypes
    })

    @fromLoadingBox.startLoad()
    @shipmentTypes.fetchPromise()
    .then (collection) =>
      fedexShipmentTypeModel = @shipmentTypes.findByType(C.FedEx)
      if (fedexShipmentTypeModel)
        fromPatron = @senderOfficeModel.toPatron()
        @renderExistingFrom(fromPatron)
      else
        @fromFormErrors.show({
          title: 'FedEx not supported.'
          message: 'Contact support to setup a FedEx account credential.'
        })
        @fromLoadingBox.stopLoad()
    .fail (errors) =>
      @fromFormErrors.show({
        title: 'Could not fetch shipment types.'
        errors
      })
    .done()
    @delegateEvents()

  renderExistingFrom: (fromPatron) ->
    @fromShipmentView = new App.Views.Shipping.Shipment({
      $container: @$fromContainer
      isAdHocShipmentOrigin: true
      originPatron: fromPatron
      @shipmentTypes
    })
    fromPatronAutocomplete = new App.Views.Clients.PatronAutocomplete({
      $container: @$fromPatronContainer
      patron: fromPatron
    })
    @listenTo(fromPatronAutocomplete, C.Events.PatronAutocompleteChange, _.bind(@onFromPatronChange, @))
    @fromLoadingBox.stopLoad()

  renderNewFrom: () ->
    @fromShipmentView = new App.Views.Shipping.Shipment({
      $container: @$fromContainer
      isAdHocShipmentOrigin: true
      @shipmentTypes
    })
    @$fromPatronContainer.html('<div style="padding-top: 8px;">New Address</div>')
    @fromLoadingBox.stopLoad()

  onFromPatronChange: (fromPatron) ->
    @fromLoadingBox.startLoad()
    fromPatron.fetchPromise()
    .then (model) =>
      @lastSelectedFromPatron = fromPatron
      @renderExistingFrom(fromPatron)
    .fail (errors) =>
      @fromFormErrors.show({
        title: 'Could not fetch client/office info.'
      })
      @fromLoadingBox.stopLoad()
    .done()

  renderExistingTo: (toPatron) ->
    @toShipmentView = new App.Views.Shipping.Shipment({
      $container: @$toContainer
      isAdHocShipmentDestination: true
      destinationPatron: toPatron
      originPatron: @fromPatron
      @shipmentTypes
    })
    toPatronAutocomplete = new App.Views.Clients.PatronAutocomplete({
      $container: @$toPatronContainer
      patron: toPatron
    })
    @listenTo(toPatronAutocomplete, C.Events.PatronAutocompleteChange, _.bind(@onToPatronChange, @))

  renderNewTo: () ->
    @toShipmentView = new App.Views.Shipping.Shipment({
      $container: @$toContainer
      isAdHocShipmentDestination: true
      @shipmentTypes
    })
    @$toPatronContainer.html('<div style="padding-top: 8px;">New Address</div>')
    @toLoadingBox.stopLoad()

  onToPatronChange: (toPatron) ->
    @toLoadingBox.startLoad()
    toPatron.fetchPromise()
    .then (model) =>
      @lastSelectedToPatron = toPatron
      @renderExistingTo(toPatron)
    .fail (errors) =>
      @toFormErrors.show({
        title: 'Could not fetch client/office info.'
      })
      @toLoadingBox.stopLoad()
    .done()


  # VIEW EVENTS ////////////////////////////////////////////////////////////////
  events:
    'click #newFromAddressButton': 'onNewFromAddressButtonClick'
    'click #newToAddressButton': 'onNewToAddressButtonClick'

    'click #existingFromAddressButton': 'onExistingFromAddressButtonClick'
    'click #existingToAddressButton': 'onExistingToAddressButtonClick'

    'click #completeToButton': 'onCompleteToButtonClick'
    'click #completeFromButton': 'onCompleteFromButtonClick'

    'click #createShipmentButton': 'onCreateShipmentButtonClick'

  onNewFromAddressButtonClick: (e) ->
    @$('#fromAddressButtons .btn').removeClass('active')
    @$('#newFromAddressButton').addClass('active')
    @renderNewFrom()

  onNewToAddressButtonClick: (e) ->
    @$('#toAddressButtons .btn').removeClass('active')
    @$('#newToAddressButton').addClass('active')
    @renderNewTo()

  onExistingFromAddressButtonClick: (e) ->
    @$('#fromAddressButtons .btn').removeClass('active')
    @$('#existingFromAddressButton').addClass('active')
    fromPatron = @lastSelectedFromPatron || @senderOfficeModel.toPatron()
    @renderExistingFrom(fromPatron)

  onExistingToAddressButtonClick: (e) ->
    @$('#toAddressButtons .btn').removeClass('active')
    @$('#existingToAddressButton').addClass('active')
    toPatron = @lastSelectedToPatron
    @renderExistingTo(toPatron)

  onCompleteFromButtonClick: (e) ->
    @fromShipmentView.getShipmentPromise()
    .then (fromShipment) =>
      @fromShipment = fromShipment
      data = {
        address: fromShipment.get('_address').toPresenterJSON()
        isTo: false
        shipment: fromShipment.toPresenterJSON()
        shipNameLabel: 'Ship from Name'
        shipCompanyLabel: 'Ship from Company'
      }
      @$('#fromPreview').html(
        @previewTemplate(data)
      )
      @$('#completeToButton').show()
      # Fake patron.  Let's us use shipmentView without friction.
      @fromPatron = new App.Models.V3.Patron(null, {
        mapping: C.Mappings.Direct.Offices.Office
        type: C.Office
      })
      @fromPatron.childModel.set({
        _address: fromShipment.get('_address')
        _phoneNumber: fromShipment.get('_phoneNumber')
      })
      toPatron = null
      @renderExistingTo(toPatron)
    .fail (errors) =>
      @fromFormErrors.show({
        title: 'Problem with shipment.'
        errors
      })
    .done()

  onCompleteToButtonClick: (e) ->
    @toLoadingBox.startLoad()
    @toShipmentView.getShipmentPromise()
    .then (toShipment) =>
      @toShipment = toShipment
      data = {
        address: toShipment.get('_address').toPresenterJSON()
        isTo: true
        shipment: toShipment.toPresenterJSON()
        shipNameLabel: 'Ship to Name'
        shipCompanyLabel: 'Ship to Company'
      }

      @$('#toPreview').html(
        @previewTemplate(data)
      )
      @$('#createShipmentButton').show()
      @toLoadingBox.stopLoad()
    .fail (errors) =>
      @toLoadingBox.stopLoad()
      @fromFormErrors.show({
        title: 'Problem with shipment.'
        errors
      })
    .done()

  onCreateShipmentButtonClick: (e) ->
    @toLoadingBox.startLoad()
    @fromLoadingBox.startLoad()
    fromAttrs = @fromShipment.attributes
    @toShipment.set({
      _shipFromName: fromAttrs._shipToName
      _shipFromCompanyName: fromAttrs._shipToCompanyName
      _shipFromAddress: fromAttrs._address
      _shipFromPhoneNumber: fromAttrs._phoneNumber
    })
    @toShipment.purpose = C.Purposes.CreateAdHocShipment
    @toShipment.savePromise()
    .then (model) =>

      @toLoadingBox.stopLoad()
      @fromLoadingBox.stopLoad()

      setTimeout(() =>
        $.gritter.add({
          title:  'Shipment created.'
          text: 'Airbill available from shipments list.'
        })
      , 0)

      App.router.navigate('/shipment', { trigger: true })

    .fail (errors) =>
      @toLoadingBox.stopLoad()
      @fromLoadingBox.stopLoad()
      @createFormErrors.show({
        title: 'Problem creating shipment.'
        errors
      })
    .done()
  #/////////////////////////////////////////////////////////////////////////////
