template = require('./upload_etickets.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

module.exports = App.Views.Order.UploadEtickets = Backbone.View.extend

  template: template

  className: 'drag_drop_upload_area'

  initialize: (options = {}) ->
    {
      @$container
      @orderItemModel
      @orderModel
    } = options
    @render()

  render: () ->
    data = {
      orderItem: @orderItemModel.toPresenterJSON()
      viewCase: App.Controllers.uploadsController.eTicketActionCase(@orderItemModel)
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )
    @formErrors = new App.Utils.FormErrorManager(@$('.formErrors'))
    @loadingBox = new App.Utils.LoadingBox(@$container)

    fileChosenCallback = (error, fileModel) =>
      if (error)
        @renderUploadError(error.message)
      else
        @loadingBox.startLoad()
        App.Controllers.uploadsController.uploadEticketPromise(fileModel, @orderItemModel)
        .then (model) =>
          @orderItemModel.fetchPromise()
          .then (model) =>
            App.Controllers.uploadsController.updateActionButton({ @orderItemModel })
            @renderUploadOutcome(fileModel)
            @refreshPages()
            @loadingBox.stopLoad()
          .fail (errors) =>
            @formErrors.show({
              title: 'Error refetching order item.'
              errors
            })
            @loadingBox.stopLoad()
          .done()
        .fail (error) =>
          @renderUploadError(error.message)
        .done()

    @uploader = new App.Utils.FileUploader({
      $fileInputEl: @$('#fileToUpload')
      callback: fileChosenCallback
      $dragDropEl: @$el
      fileTypeFilter: C.FileFilters.PDF
      fileSizeLimit: 30000000 #~30MB
    })

    App.Controllers.uploadsController.updateActionButton({ @orderItemModel })
    @refreshPages()

  refreshPages: () ->
    eTicketSelectPages = new App.Views.Order.EticketSelectPages({
      @orderItemModel
      $container: @$('#pageContainer')
      @orderModel
    })

  renderUploadOutcome: (fileModel) ->
    outcomeView = new App.Views.Order.FileUploadOutcome({
      fileModel
      $container: @$('#fileContainer')
      @loadingBox
    })

  renderUploadError: (errorMessage) ->
    outcomeView = new App.Views.Order.FileUploadOutcome({
      $container: @$('#fileContainer')
      errorMessage
    })

