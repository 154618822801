module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = "<% if (presenter.notes('exchange')) { %>\n<div class=\"popover_section_title\">Exchange</div>\n<div class=\"popover_content\"><%= presenter.notes('exchange') %></div>\n  <% if (presenter.notes('exchange') && (presenter.notes('public') || presenter.notes('private'))) { %>\n  <hr />\n  <% } %>\n<% } %>\n\n<% if (presenter.notes('public')) { %>\n<div class=\"popover_section_title\">Public</div>\n<div class=\"popover_content\"><%= presenter.notes('public') %></div>\n  <% if (presenter.notes('public') && presenter.notes('private')) { %>\n  <hr />\n  <% } %>\n<% } %>\n\n<% if (presenter.notes('private')) { %>\n<div class=\"popover_section_title\">Private</div>\n<div class=\"popover_content\"><%= presenter.notes('private') %></div>\n<% } %>", __filename = "js/views/ticket_groups/notes_popover.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            if (presenter.notes("exchange")) {
                __append('\n<div class="popover_section_title">Exchange</div>\n<div class="popover_content">');
                __line = 3;
                __append(escapeFn(presenter.notes("exchange")));
                __append("</div>\n  ");
                __line = 4;
                if (presenter.notes("exchange") && (presenter.notes("public") || presenter.notes("private"))) {
                    __append("\n  <hr />\n  ");
                    __line = 6;
                }
                __append("\n");
                __line = 7;
            }
            __append("\n\n");
            __line = 9;
            if (presenter.notes("public")) {
                __append('\n<div class="popover_section_title">Public</div>\n<div class="popover_content">');
                __line = 11;
                __append(escapeFn(presenter.notes("public")));
                __append("</div>\n  ");
                __line = 12;
                if (presenter.notes("public") && presenter.notes("private")) {
                    __append("\n  <hr />\n  ");
                    __line = 14;
                }
                __append("\n");
                __line = 15;
            }
            __append("\n\n");
            __line = 17;
            if (presenter.notes("private")) {
                __append('\n<div class="popover_section_title">Private</div>\n<div class="popover_content">');
                __line = 19;
                __append(escapeFn(presenter.notes("private")));
                __append("</div>\n");
                __line = 20;
            }
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}