template = require('./inline_payments.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView
module.exports = App.Views.CreditMemo.InlinePayments = App.Views.Base.BaseView.extend

  template: template

  className: 'widget-box'

  initialize: (options = {}) ->
    {
      @creditMemo
      @creditMemoShowView
    } = options

    @creditMemo.get('_transactions').forEach((transaction) =>
      transaction.set('_createdAt', @creditMemo.get('_createdAt'))
    )

    @payments = @creditMemo.get('_payments')
    @payments.add(@creditMemo.get('_transactions').models)

  render: ()  ->
    @$el.html(@template())
    @renderPayments()
    return @$el

  renderPayments: () ->
    @payments.each((payment) =>
      partial = new App.Views.CreditMemo.InlinePayment({
        @creditMemoShowView
        payment
      })
      partial.render()
      @$el.find('.paymentForm').append(partial.$el)
    )

  # VIEW EVENTS ////////////////////////////////////////////////////////////////
  events:
    'click #addPaymentButton' : 'onAddPaymentButtonClick'

  onAddPaymentButtonClick: () ->
    view = new App.Views.CreditMemo.NewPayment({
      @creditMemo
      @creditMemoShowView
    })
    if (!@$('.paymentForm tr.newPayment').length)
      @$('.paymentForm').append(view.render())
  #/////////////////////////////////////////////////////////////////////////////