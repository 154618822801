module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<table class="table table-condensed table-striped" style="background-color: white;">\n  <thead>\n    <tr>\n      <th>Qty</th>\n      <th>Section</th>\n      <th>Row</th>\n      <th>Seats</th>\n      <th>Cost</th>\n      <th>Wholesale/Retail</th>\n      <th></th>\n    </tr>\n  </thead>\n  <tbody>\n    <% ticketGroups.forEach(function(ticketGroup) { %>\n    <tr data-ticket-group-cid="<%= ticketGroup.cid %>">\n      <td><%= ticketGroup.quantity %></td>\n      <td><%= ticketGroup.section %></td>\n      <td><%= ticketGroup.row %></td>\n      <td><%= ticketGroup.seatRange %></td>\n      <td><%= ticketGroup.cost %></td>\n      <td><%= App.Utils.valueToCurrency(ticketGroup.priceWholesale) %> / <%= App.Utils.valueToCurrency(ticketGroup.priceRetail) %></td>\n      <td>\n        <a class="btn btn-mini btn-danger deleteButton" data-event-id="<%= event.id %>">\n          <i class="fa-solid fa-xmark"></i>\n        </a>\n      </td>\n    </tr>\n    <% }); %>\n  </tbody>\n</table>\n', __filename = "js/views/events/with_ticket_groups_table_only.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<table class="table table-condensed table-striped" style="background-color: white;">\n  <thead>\n    <tr>\n      <th>Qty</th>\n      <th>Section</th>\n      <th>Row</th>\n      <th>Seats</th>\n      <th>Cost</th>\n      <th>Wholesale/Retail</th>\n      <th></th>\n    </tr>\n  </thead>\n  <tbody>\n    ');
            __line = 14;
            ticketGroups.forEach(function(ticketGroup) {
                __append('\n    <tr data-ticket-group-cid="');
                __line = 15;
                __append(escapeFn(ticketGroup.cid));
                __append('">\n      <td>');
                __line = 16;
                __append(escapeFn(ticketGroup.quantity));
                __append("</td>\n      <td>");
                __line = 17;
                __append(escapeFn(ticketGroup.section));
                __append("</td>\n      <td>");
                __line = 18;
                __append(escapeFn(ticketGroup.row));
                __append("</td>\n      <td>");
                __line = 19;
                __append(escapeFn(ticketGroup.seatRange));
                __append("</td>\n      <td>");
                __line = 20;
                __append(escapeFn(ticketGroup.cost));
                __append("</td>\n      <td>");
                __line = 21;
                __append(escapeFn(App.Utils.valueToCurrency(ticketGroup.priceWholesale)));
                __append(" / ");
                __append(escapeFn(App.Utils.valueToCurrency(ticketGroup.priceRetail)));
                __append('</td>\n      <td>\n        <a class="btn btn-mini btn-danger deleteButton" data-event-id="');
                __line = 23;
                __append(escapeFn(event.id));
                __append('">\n          <i class="fa-solid fa-xmark"></i>\n        </a>\n      </td>\n    </tr>\n    ');
                __line = 28;
            });
            __append("\n  </tbody>\n</table>\n");
            __line = 31;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}