module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div class="widget-title">\n  <span class="icon"><i class="fa-solid fa-credit-card"></i></span>\n  <h5>Payments</h5>\n  <a id="addPaymentButton" class="pull-right btn btn-primary" style="margin-top: 3px;">\n    <i class="fa-solid fa-plus"></i> Add Payment\n  </a>\n</div>\n\n<div class="widget-content tab-content nopadding" style="width: 100%;">\n  <table class="table" style="width: 100%;">\n    <thead>\n      <tr>\n        <th>Type</th>\n        <th>Payment Type</th>\n        <th>Amount</th>\n        <th>State</th>\n        <th>Details</th>\n        <th>Date</th>\n        <th> Actions </th>\n      </tr>\n    </thead>\n    <tbody class="paymentForm">\n    </tbody>\n  </table>\n</div>\n', __filename = "js/views/credit_memos/inline_payments.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div class="widget-title">\n  <span class="icon"><i class="fa-solid fa-credit-card"></i></span>\n  <h5>Payments</h5>\n  <a id="addPaymentButton" class="pull-right btn btn-primary" style="margin-top: 3px;">\n    <i class="fa-solid fa-plus"></i> Add Payment\n  </a>\n</div>\n\n<div class="widget-content tab-content nopadding" style="width: 100%;">\n  <table class="table" style="width: 100%;">\n    <thead>\n      <tr>\n        <th>Type</th>\n        <th>Payment Type</th>\n        <th>Amount</th>\n        <th>State</th>\n        <th>Details</th>\n        <th>Date</th>\n        <th> Actions </th>\n      </tr>\n    </thead>\n    <tbody class="paymentForm">\n    </tbody>\n  </table>\n</div>\n');
            __line = 26;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}