template = require('./purchase_orders.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Order.PurchaseOrders = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    {
      @$container
      @purchaseOrderCollection
      @overviewView
      @topSellerState
    } = options
    @seatSelectionViewsByOrderItemId = {}
    @render()

  getPurchaseOrdersWithEventsPresenterJSON: () ->
    pos = []
    @purchaseOrderCollection.forEach((orderModel) ->

      eventsById = {}
      orderItems = orderModel.get('_orderItems')
      poPresenterJSON = orderModel.toPresenterJSON()

      orderItems.forEach((orderItemModel) ->

        event = orderItemModel.get('_ticketGroup').get('_event')
        orderItemPresenterJSON = orderItemModel.toPresenterJSON()
        eventPresenterJSON = event.toPresenterJSON()

        eventsById[event.id] = eventsById[event.id] || eventPresenterJSON
        eventsById[event.id].orderItems = eventsById[event.id].orderItems || []
        eventsById[event.id].orderItems.push(orderItemPresenterJSON)

      )
      eventsInOrder = _.values(eventsById)
      eventSortFunction = (a, b) ->
        return a.occursAtRaw - b.occursAtRaw
      eventsInOrder = eventsInOrder.sort(eventSortFunction)
      poPresenterJSON.eventsInOrder = eventsInOrder
      pos.push(poPresenterJSON)
    )
    return pos

  isTopOrderSubstitutionAllowed: () ->
    if (_.contains(
      [C.Pending, C.PendingSubstitution, C.Accepted, C.Completed],
      @topSellerState
    ))
      return true
    else
      return false

  render: () ->
    data = {
      purchaseOrders: @getPurchaseOrdersWithEventsPresenterJSON()
      topOrderSubstitutionAllowed: @isTopOrderSubstitutionAllowed()
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )

    @formErrors = new App.Utils.FormErrorManager(@$('.formErrors'))
    @loadingBox = new App.Utils.LoadingBox(@$el)

    # Badges and notes tooltips.
    @$('span.tip-top').tooltip({ placement: 'top' })
    @purchaseOrderCollection.forEach((orderModel) ->
      orderModel.get('_orderItems').forEach((orderItemModel) ->
        ticketGroupModel = orderItemModel.get('_ticketGroup')
        $el = @$("tr[data-ticket-group-id=#{ ticketGroupModel.id  }] .popover_notes")
        App.Utils.notesPopover($el, ticketGroupModel)
      )
    )

    @renderSeatSelectionViews()
    @delegateEvents()

  renderSeatSelectionViews: () ->
    @purchaseOrderCollection.forEach((orderModel) =>

      seller = orderModel.get('_seller')
      orderItems = orderModel.get('_orderItems')
      orderItems.forEach((orderItemModel) =>

        ticketGroupModel = orderItemModel.get('_ticketGroup')
        if (!ticketGroupModel.hasSeatNumbers())

          $row = @$("tr[data-order-item-id=#{ orderItemModel.id }]")
          $container = $row.find('.seats')
          view = new App.Views.BuySell.SeatSelection({
            action: C.BuySellActions.Buy
            $container
            canChooseSeats: App.OrderPermissions.canEnterChildsSeatNumbers({
              hasSeatNumbers: ticketGroupModel.hasSeatNumbers()
              isPO: orderModel.isPO()
              orderType: orderModel.get('_orderType')
              sellerState: orderModel.get('_sellerState')
            })
            seller
            ticketGroupModel
          })
          @seatSelectionViewsByOrderItemId[orderItemModel.id] = view

      )
    )

  events:
    'click .emailInvoiceButton':  'onEmailInvoiceButtonClick'
    'click .sendPOButton':        'onSendPOButtonClick'
    'click .cancelPOButton':      'onCancelPOButtonClick'
    'click .substituteOrderButton': 'onSubstituteOrderButtonClick'

  onEmailInvoiceButtonClick: (e) ->
    poModel = @getPOFromDOMEvent(e)
    $button = @$(e.currentTarget)
    loadingButton = new App.Utils.LoadingButton($button)
    loadingButton.startLoad()
    $emailInput = $button.parent().find('.emailInput')
    $emailInput.attr('disabled', true)
    emailAddresses = $emailInput.val()
    App.Controllers.orderShowController.emailInvoicePromise(poModel, emailAddresses)
    .then (model) =>
      @formErrors.show({
        type: 'success'
        title: 'PO Emailed Successfully'
        message: "The PO was sent to #{ emailAddresses }."
      })
      $emailInput.removeAttr('disabled')
      loadingButton.stopLoad()
    .fail (errors) =>
      @formErrors.show({
        title: 'Email PO Failed'
        errors
      })
      $emailInput.removeAttr('disabled')
      loadingButton.stopLoad()
    .done()

  onSendPOButtonClick: (e) ->
    poModel = @getPOFromDOMEvent(e)
    @loadingBox.startLoad()

    # Determine if we need a payment.
    if (poModel.get('_sellerState') == 'open' && !poModel.get('_payments').length)
      paymentModel = new App.Models.V3.Payment()
      paymentModel.set({
        _type: C.EvoPay
        _amount: poModel.get('_balance') || 0
        _isComplete: false
      })
      poModel.set({
        _payment: paymentModel
      })

    additionalNode = @$(".additionalNoteInput[data-po-id=#{ poModel.id }]").val()
    poModel.set('_additionalNote', additionalNode)
    poModel.set('_orderItemLinks', @getOrderItemLinks(poModel))

    App.ErrorManager.ignoreNext(422)
    poModel.sendPOPromise()
    .then (model) =>
      App.ErrorManager.resetTolerance(422)
      @loadingBox.stopLoad()
      @overviewView.renderPOsPromise()
      .then () =>
        @overviewView.showFeedback({
          type: 'success'
          title: 'PO sent successfully.'
        })
    .fail (errors) =>
      App.ErrorManager.resetTolerance(422)
      @loadingBox.stopLoad()
      @formErrors.show({
        title:  "Error sending PO."
        errors
      })
    .done()

  onCancelPOButtonClick: (e) ->
    poModel = @getPOFromDOMEvent(e)
    view = new App.Views.Order.CancelOrderModal({
      isPOView: true
      v3OrderModel: poModel
      @overviewView
    })
    view.render()

  getPOFromDOMEvent: (e) ->
    $button = @$(e.currentTarget)
    poId = $button.data().poId
    poModel = @purchaseOrderCollection.get(poId)
    return poModel

  onSubstituteOrderButtonClick: (e) ->
    poModel = @getPOFromDOMEvent(e)
    $button = @$(e.currentTarget)
    orderItemId = $button.data().orderItemId
    orderItemModel = poModel.get('_orderItems').get(orderItemId)
    $container = @$("td.subItemRow[data-order-item-id=#{ orderItemModel.id }]")
    subOrderView = new App.Views.Order.SubOrderItem({
      $container
      orderItemModel
      @overviewView
      poModel
    })

  getOrderItemLinks: (poModel) ->
    orderItems = {}
    poModel.get('_orderItems').forEach((orderItem) =>

      ticketGroupModel = orderItem.get('_ticketGroup')
      seatSelectionView = @seatSelectionViewsByOrderItemId[orderItem.id]
      if (seatSelectionView)
        lowSeat = seatSelectionView.getLowSeat() # Can be left undefined - still allowed to send PO.
        seatOrder = seatSelectionView.getSeatOrder()
      else
        lowSeat = ticketGroupModel.nthHighestSeat(orderItem.get('_quantity'))
        seatOrder = ticketGroupModel.seatOrderFromSeats()

      json = {
        id: orderItem.id
        order: seatOrder
        cost: orderItem.get('_price')
      }

      if (lowSeat)
        json.low_seat = lowSeat

      orderItems[orderItem.id] = json
    )
    return orderItems