App.Models.V2.BrokerageUser = require('../../models/v2/brokerage_user_model.coffee')
App.Collections.Base.PaginatedBase = require('../base/paginated_base_collection.coffee')

parent = App.Collections.Base.PaginatedBase.prototype
module.exports = App.Collections.V2.BrokerageUsers = App.Collections.Base.PaginatedBase.extend

  model: App.Models.V2.BrokerageUser

  url:  '/api_direct/v9/users'

  initialize: (models, options) ->
    parent.initialize.call(@, models, options)
    {
      @brokerageId
    } = options

  parse: (response, options) ->
    if (response.users)
      return response.users
    else
      return response
