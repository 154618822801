# TEMPLATES
template = require('./master_layout.ejs')
noSessionTemplate = require('./master_layout_no_session.ejs')

App.Views.TopNavEvoquote      = require('./topnav/topnav_evoquote_view.coffee')
App.Views.TopNavQueuedOrders  = require('./topnav/topnav_queued_orders_view.coffee')
App.Views.TopNavShoppingCart  = require('./topnav/topnav_shopping_cart_view.coffee')
App.Views.UserNav             = require('./topnav/user_nav_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.MasterLayout = App.Views.Base.BaseView.extend

  template: template

  noSessionTemplate: noSessionTemplate

  initialize: (options = {}) ->
    {
      @$container
    } = options
    @render()

  render: () ->

    if (SESSION.user_id) # If logged in.
      @$container.html(
        @$el.html(
          @template()
        )
      )
      @$topNavcontainer = @$('#topNavContainer')
      @queuedOrders = new App.Views.TopNavQueuedOrders({
        $container: @$topNavcontainer
      })
      @shoppingCart = new App.Views.TopNavShoppingCart({
        $container: @$topNavcontainer
      })
      @evoquote = new App.Views.TopNavEvoquote({
        $container: @$topNavcontainer
      })
      @renderTopNav()
      @renderUserNav()
    else
      $('body').attr('id', 'sessionless')
      @$container.html(
        @$el.html(
          @noSessionTemplate()
        )
      )
    return @$el

  events: {
    'click #copyButton': 'onCopyButtonClick'
  }

  onCopyButtonClick: (e) ->
    urlRegex = /(https?:\/\/[^ ]*)/;
    input = document.querySelector('#shopUrlField')
    url = input.textContent.match(urlRegex)[1];
    linkTextArea = document.createElement('input');
    linkTextArea.value = url;
    document.body.appendChild(linkTextArea);
    linkTextArea.select()
    document.execCommand('copy')
    e.currentTarget.style["background"] = "green"
    document.body.removeChild(linkTextArea);

  renderTopNav: () ->
    @$topNavcontainer.empty()
    @queuedOrders.render()
    @shoppingCart.render()
    @evoquote.render()
    @renderAffiliateLinkNav()

  renderAffiliateLinkNav: () ->
    App.Utils.infoPopover(
      @$('#affiliate-base-link-info'),
      {
        title: 'Shareable Link'
        placement: 'bottom'
        content: 'Share this URL with customers to ensure your commission is captured for orders placed.'
      }
    )

  renderUserNav: () ->
    $container = @$('#userNavContainer')
    usernav = new App.Views.UserNav({
      $container
    })

  updateTopNav: (options) ->
    if (options.action == 'updateQueuedOrders')
      @queuedOrders.update(options.data)
    if (options.action == 'updateCart')
      @shoppingCart.update(options.numInCart)
    if (options.action == 'updateEvoquote')
      @evoquote.update()
    @renderTopNav()
