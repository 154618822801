App.Collections.Base.PaginatedBase = require('../base/paginated_base_collection.coffee')
App.Models.V3.Shipment = require('../../models/v3/shipment_model.coffee')

parent = App.Collections.Base.PaginatedBase.prototype
module.exports = App.Collections.V3.Shipments = App.Collections.Base.PaginatedBase.extend

  model: App.Models.V3.Shipment

  url: () ->
    if (@purpose == C.Purposes.FetchOnlyShipmentInfo)
      url = "/api_direct/v9/shipments_without_orders"
    else
      url = "/api_direct/v9/shipments/status"

    if (@params)
      params = []
      for own key, val of @params
        params.push("#{ key }=#{ encodeURIComponent(val) }")
      url += "?#{ params.join('&') }"

    return url

  initialize: (models, options) ->
    parent.initialize.call(@, models, options)
    {
      @params
      @purpose
    } = options

  parse: (response) ->
    if (response.shipments)
      return response.shipments
    return response