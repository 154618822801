PourOver = require('./pourover');
template = require('./ticket_groups_list.ejs')
filtersTemplate = require('./ticket_group_filter.ejs')
bulkBuyTemplate = require('./bulk_buy_button.ejs')
notesPopoverTemplate = require('./notes_popover.ejs')
App.Views.Events.SeatMap = require('../events/seatmaps_view.coffee')
App.Views.Base.BaseView = require('../base/base_view.coffee')

DIGITS_PLUS_GARBAGE_REGEX = /[0-9]+.*/i

FILTERABLE_ATTRS = [
  '_age'
  '_brokerageAbbreviation'
  '_id'
  '_isInHand'
  '_isInstantDelivery'
  '_isWheelchair'
  '_priceWholesale'
  '_row'
  '_section'
  '_sectionId'
  '_splits'
  '_unifiedFormat'
]

AVAILABLE_OR_SOLD = {
  AVAILABLE: 1
  SOLD: 2
}

TICKETS_OR_PARKING = {
  TICKETS: 1
  PARKING: 2
}

MY_OR_ALL_TICKETS = { # match up with @myOrAll strings
  MY: 'my'
  ALL: 'all'
}

resizeTimeout = null
RESIZE_TIMEOUT = 1000

ACTIONS = {
  AVAILABLE_OR_SOLD_CHANGE: 'AVAILABLE_OR_SOLD_CHANGE'
  ENTER_EDIT_MODE: 'ENTER_EDIT_MODE'
  EXIT_EDIT_MODE: 'EXIT_EDIT_MODE'
  FILTERS_CHANGED: 'FILTERS_CHANGED'
  FIT_CHART_TO_WINDOW: 'FIT_CHART_TO_WINDOW'
  FIT_TABLE_TO_WINDOW: 'FIT_TABLE_TO_WINDOW'
  INITIAL_LOAD: 'INITIAL_LOAD'
  LIST_VIEW_BUTTON: 'LIST_VIEW_BUTTON'
  MY_OR_ALL_CHANGE: 'MY_OR_ALL_CHANGE'
  PRICES_UPDATED: 'PRICES_UPDATED'
  REFRESH_BUTTON: 'REFRESH_BUTTON'
  RESET_FILTERS_BUTTON: 'RESET_FILTERS_BUTTON'
  SEATING_CHART_BUTTON: 'SEATING_CHART_BUTTON'
  TICKETS_OR_PARKING_CHANGE: 'TICKETS_OR_PARKING_CHANGE'
}

VIEWS = {
  LIST_VIEW: 'LIST_VIEW'
  SEATING_CHART: 'SEATING_CHART'
}

BULK_BUY_LISTINGS = []
CHECKOUT_THRESHOLD = window.__TEVO_CORE__.env.CHECKOUT_THRESHOLD || 100;

FORMAT_SELECTOR = 'input[type="checkbox"][name="format"]'

loadingOverlay = new App.Views.Shared.LoadingView()

window.addEventListener 'unload', ->
  loadingOverlay.remove()

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.TicketGroups.ListView = App.Views.Base.BaseView.extend

  template: template

  bulkBuyTemplate: bulkBuyTemplate
  filtersTemplate: filtersTemplate
  notesPopoverTemplate: notesPopoverTemplate

  currentAction: null

  initialize: (options = {}) ->
    {
      @$container
      @filterCode
      @eventModel
      @selectedEventId
      @myOrAllTickets
    } = options
    @eventModalsStore = new App.Stores.EventModals()
    @listenTo(App.Vent, 'ticket-edit:completed', () =>
      @logicMediator(ACTIONS.REFRESH_BUTTON)
    )
    @isPriceEditTable = false
    @viewMode = VIEWS.LIST_VIEW
    @render()

  render: () ->
    @$container.html(
      @$el.html(
        @template({
          event: @eventModel.toPresenterJSON()
          @myOrAllTickets
        })
      )
    )

    @delegateEvents()
    @$('.tip-bottom').tooltip()
    @setupEventNotesPopover()
    @loader = new App.Utils.LoadingBox(@$("#ticketTableContainer"))
    @refreshButton = new App.Utils.LoadingButton(@$('#refreshTicketsButton'))
    @formErrors = new App.Utils.FormErrorManager($('#formErrors'))

    @renderFilters()

    @renderBulkBuyButton()

    resizeFn = _.bind(@onWindowResize, @)
    $(window).on('resize', _.throttle(resizeFn, 50))

    @logicMediator(ACTIONS.INITIAL_LOAD)
    return @$el

  fetchZoneCallback: (zones) ->
    @seatingZones = zones

    keys = Object.keys(zones)

    @$("#_zone").select2({
      multiple: true
      placeholder: 'All'
      data: keys.map((zone) ->
        return {
          id: zone
          text: App.Utils.formatTicketZone(zone)
        }
      )
      initSelection: (element, callback) =>
        data = {
          id: element.val()
          text: App.Utils.formatTicketZone(element.val())
        }
        callback(data)
    })

  makeZoneFilter: (name,values,attr) ->
    ZoneFilter = PourOver.Filter.extend({
      cacheResults: (items) ->
        #cache
        possibilities = @possibilities
        zones = @zones
        items.forEach((item) ->
          itemSection = item._section
          Object.keys(possibilities).forEach((possibility) ->
            zoneSections = zones[possibility]
            if (zoneSections.indexOf(itemSection) > -1)
              p = possibilities[possibility]
              p.matching_cids = PourOver.insert_sorted(p.matching_cids,item.cid)
          )
        )
      addCacheResults: (new_items) ->
        #add cahche
        this.cacheResults.call(this,new_items)
      getFn: (query) ->
        finalSet = null
        possibilities = @possibilities
        makeQueryMatchSet = @makeQueryMatchSet.bind(this)

        query.forEach((seletedZone) ->
          matching_possibility = possibilities[seletedZone]
          matchSet = makeQueryMatchSet(matching_possibility.matching_cids,seletedZone)
          if (finalSet)
            finalSet = finalSet.or(matchSet)
          else
            finalSet = matchSet
        )
        return finalSet
    })

    values = _(values).map((i) -> return {value:i})
    opts = { zones: @seatingZones }
    filter = new ZoneFilter(name,values,opts);
    return filter;

  setupEventNotesPopover: () ->
    eventNotes = @eventModel.get('_notes')
    if (eventNotes)
      # NOTE: commented out because Dan would prefer to always show the modal
      # NOTE: To only show one time per user per event enable the logic below
      # stored = @eventModalsStore.find('' + @eventModel.id)
      # if (stored == undefined || !stored.shown)
      @onEventNotesClick()

  onWindowResize: () ->
    if (!@isPriceEditTable)
      if (resizeTimeout)
        clearTimeout(resizeTimeout)
      resizeTimeout = setTimeout(() =>
        @logicMediator(ACTIONS.FIT_CHART_TO_WINDOW)
        @logicMediator(ACTIONS.FIT_TABLE_TO_WINDOW)
      , RESIZE_TIMEOUT)

  stopLoad: () ->
    @loader.stopLoad()
    @refreshButton.stopLoad()
    @$('#seatingChartButton').removeAttr('disabled')

  getToggleSelections: () ->
    @$('#ticketGroupFilter').show()
    @$('#myOrAllButtonGroup').show()
    @$('#ticketOrParkingButtonGroup').show()
    if (!@isPriceEditTable)
      @$('#editPricesButton').show()
      @$('#bulkBuyContainer').show()

    selections = {
      AVAILABLE_OR_SOLD: AVAILABLE_OR_SOLD.AVAILABLE
      MY_OR_ALL_TICKETS: MY_OR_ALL_TICKETS.MY
      TICKETS_OR_PARKING: TICKETS_OR_PARKING.TICKETS
    }

    if (@$('#soldButton').hasClass('active'))
      selections.AVAILABLE_OR_SOLD = AVAILABLE_OR_SOLD.SOLD
      @$('#ticketGroupFilter').hide()
      @$('#myOrAllButtonGroup').hide()
      @$('#ticketOrParkingButtonGroup').hide()
      @$('#editPricesButton').hide()
      @$('#bulkBuyContainer').hide()

    if (@$('#allTicketsButton').hasClass('active')) || (SESSION.role.cannot(App.Access.Resources.Inventory.AllTickets.OwnTickets))
      selections.MY_OR_ALL_TICKETS = MY_OR_ALL_TICKETS.ALL

    if (@$('#parkingTypeButton').hasClass('active'))
      selections.TICKETS_OR_PARKING = TICKETS_OR_PARKING.PARKING

    return selections

  getEventViewSettings: () ->
    return {
      eventId: @eventModel.id
      @filterCode
      myOrAllTickets: @getToggleSelections().MY_OR_ALL_TICKETS
    }

  forceAvailableMode: () ->
    @$('#availableButton').addClass('active')
    @$('#soldButton').removeClass('active')

  refetchWhilePreservingFiltersPromise: () ->
    deferred = Q.defer()
    @fetchTicketsPromise()
    .then (collection) =>
      toggleSelections = @getToggleSelections()
      if (toggleSelections.AVAILABLE_OR_SOLD == AVAILABLE_OR_SOLD.AVAILABLE)
        @removeOneFilter('_section')
        @seatMap?.deselectSection()
        preserveFilters = true
        @buildFiltersForCollection(@availableCollection, preserveFilters)
        collection = @applyCurrentFiltersToCollection(@availableCollection)
        deferred.resolve(collection)
      @populateTableWithCollection(collection)
    .fail (errors) =>
      # Errors displayed by fetchTicketsPromise().
      deferred.reject(errors)
    .done()
    return deferred.promise

  # Rather than chain together function calls, call them sequentially here so code stays simple.
  logicMediator: (action, options = {}) ->
    @currentAction = action
    @clearBulkBuySelection()

    # AVAILABLE_OR_SOLD_CHANGE /////////////////////////////////////////////////
    if (action == ACTIONS.AVAILABLE_OR_SOLD_CHANGE)
      @refetchWhilePreservingFiltersPromise()

    # ENTER_EDIT_MODE //////////////////////////////////////////////////////////
    if (action == ACTIONS.ENTER_EDIT_MODE)
      @loader.startLoad()
      @forceAvailableMode()
      @isPriceEditTable = true
      @$('#editPricesBox').show()
      @$('#editPricesButton').hide()
      collection = @applyCurrentFiltersToCollection(@availableCollection)
      @populateTableWithCollection(collection)

    # EXIT_EDIT_MODE ///////////////////////////////////////////////////////////
    if (action == ACTIONS.EXIT_EDIT_MODE)
      @loader.startLoad()
      @forceAvailableMode()
      @isPriceEditTable = false
      @$('#editPricesBox').hide()
      @$('#editPricesButton').show()
      collection = @applyCurrentFiltersToCollection(@availableCollection)
      @populateTableWithCollection(collection)

    # FILTERS_CHANGED //////////////////////////////////////////////////////////
    else if (action == ACTIONS.FILTERS_CHANGED)
      collection = @applyCurrentFiltersToCollection(@availableCollection)
      @populateTableWithCollection(collection)
      if (!options.triggeredBySeatingChart && @viewMode == VIEWS.SEATING_CHART)
        @updateTicketsOnSeatingChart(collection)

    # FIT_TABLE_TO_WINDOW //////////////////////////////////////////////////////
    else if (action == ACTIONS.FIT_TABLE_TO_WINDOW)
      setTimeout(() =>
        @table?.render()
      , 0)

    # INITIAL_LOAD /////////////////////////////////////////////////////////////
    else if (action == ACTIONS.INITIAL_LOAD)
      @currentFilters = {}
      @fetchTicketsPromise()
      .then (collection) =>
        toggleSelections = @getToggleSelections()
        if (toggleSelections.AVAILABLE_OR_SOLD == AVAILABLE_OR_SOLD.AVAILABLE)
          preserveFilters = false
          @buildFiltersForCollection(@availableCollection, preserveFilters)
          collection = @applyCurrentFiltersToCollection(@availableCollection)
        @populateTableWithCollection(collection)
        if (SESSION.role.cannot(App.Access.Resources.Inventory.AllTickets.ViewTGFiltersByDefault))
          @logicMediator(ACTIONS.SEATING_CHART_BUTTON, {dontCollapseEvents: true})
          @$('#listViewButton').removeClass('active')
          @$('#seatingChartButton').addClass('active')
          @table?.render()
      .fail (errors) =>
        # Errors displayed by fetchTicketsPromise().
        console.error('Failed to fetch tickets %o', errors)
        $.noop()
      .done()

    # LIST_VIEW_BUTTON /////////////////////////////////////////////////////////
    else if (action == ACTIONS.LIST_VIEW_BUTTON)
      @viewMode = VIEWS.LIST_VIEW
      App.Controllers.eventsController.expandEventsView()
      @seatMap?.remove()
      @removeOneFilter('_sectionId')
      toggleSelections = @getToggleSelections()
      if (toggleSelections.AVAILABLE_OR_SOLD == AVAILABLE_OR_SOLD.AVAILABLE)
        collection = @applyCurrentFiltersToCollection(@availableCollection)
      else
        collection = @soldCollection
      @populateTableWithCollection(collection)

    # MY_OR_ALL_CHANGE /////////////////////////////////////////////////////////
    else if (action == ACTIONS.MY_OR_ALL_CHANGE)
      @refetchWhilePreservingFiltersPromise()
      .then (collection) =>
        if (@viewMode == VIEWS.SEATING_CHART)
          @updateTicketsOnSeatingChart(collection)
      .fail((error) => console.error(error))
      .done()

    # PRICES_UPDATED ///////////////////////////////////////////////////////////
    else if (action == ACTIONS.PRICES_UPDATED)
      collection = @applyCurrentFiltersToCollection(@availableCollection)
      @populateTableWithCollection(collection)
      if (@viewMode == VIEWS.SEATING_CHART)
        @updateTicketsOnSeatingChart(collection)

    # REFRESH_BUTTON ///////////////////////////////////////////////////////////
    else if (action == ACTIONS.REFRESH_BUTTON)
      @refreshButton.startLoad()
      # Same as INITIAL_LOAD but no buildFiltersForCollection()
      @fetchTicketsPromise()
      .then (collection) =>
        toggleSelections = @getToggleSelections()
        if (toggleSelections.AVAILABLE_OR_SOLD == AVAILABLE_OR_SOLD.AVAILABLE)
          collection = @applyCurrentFiltersToCollection(@availableCollection)
        @populateTableWithCollection(collection)
      .fail (errors) =>
        # Error display by fetchTicketsPromise().
        $.noop()
      .done()

    # RESET_FILTERS_BUTTON /////////////////////////////////////////////////////
    else if (action == ACTIONS.RESET_FILTERS_BUTTON)
      @removeAllFilters()
      @resetFilterUi()

    # SEATING_CHART_BUTTON /////////////////////////////////////////////////////
    else if (action == ACTIONS.SEATING_CHART_BUTTON)
      if (@$('#seatingChartButton').attr('disabled'))
        return
      @viewMode = VIEWS.SEATING_CHART
      if (!options.dontCollapseEvents)
        App.Controllers.eventsController.collapseEventsView()
      $container = @$('#seatingChartContainer')
      collection = @applyCurrentFiltersToCollection(@availableCollection)
      @populateTableWithCollection(collection)
      @seatMap = new App.Views.Events.SeatMap({
        $container
        collection
        @eventModel
        isListView: true
        onSectionSelection: ((sections) ->
          uniqueSections = @availableCollection
            .filter((model) => sections.includes(model.get('tevo_section_name')))
            .map((model) => model.get('section'))
          filterOptions = Array.from(new Set(uniqueSections))
            .map((section) => ({
              id: section
              text: section
            }))
          # Apply filter to listview.
          @addAndApplyOneFilter('_section', filterOptions, true)
        ).bind(@)
      })
      @createSectionIdFilter()

    # TICKETS_OR_PARKING_CHANGE ////////////////////////////////////////////////
    else if (action == ACTIONS.TICKETS_OR_PARKING_CHANGE)
      @refetchWhilePreservingFiltersPromise()

  #/////////////////////////////////////////////////////////////////////////////
  # TICKETS
  #/////////////////////////////////////////////////////////////////////////////
  populateTableWithCollection: (availableTicketsOrSoldItemsCollection) ->
    $container = @$('#ticketTableContainer')
    if (@isPriceEditTable)
      @editTable = new App.Views.TicketGroups.TicketGroupsTable({
        @isPriceEditTable
        filterView: @
        $container
        showLessColumns: false
        toggleSelections: @getToggleSelections()
        collection: availableTicketsOrSoldItemsCollection
      })
      @setupMarkupDropdowns()
    else
      @table = new App.Views.TicketGroups.TicketGroupsTable({
        filterView: @
        $container
        showLessColumns: (@viewMode == VIEWS.SEATING_CHART)
        toggleSelections: @getToggleSelections()
        collection: availableTicketsOrSoldItemsCollection
      })
    @renderAggregates(availableTicketsOrSoldItemsCollection)
    @setupNotesPopovers()
    @stopLoad()

    if (
      @currentAction == ACTIONS.MY_OR_ALL_CHANGE ||
      @currentAction == ACTIONS.AVAILABLE_OR_SOLD_CHANGE || 
      @currentAction == ACTIONS.TICKETS_OR_PARKING_CHANGE
    )
      @handleFormatVisibility()


  renderAggregates: (collection) ->
    $container = @$('#aggregatesContainer')
    listings = collection.reduce((memo, model) ->
      if (model.get('_quantity') && model.get('_availableQuantity'))
        return memo + 1
      else
        return memo
    , 0)
    tickets = collection.reduce((memo, model) ->
      available = model.get('_availableQuantity')
      return memo + available
    , 0)
    html = "<span>#{ listings } Listings</span>
        <span style='margin-left: 30px;'>#{ tickets } Tickets</span>"
    $container.html(html)

  setupNotesPopovers: () ->
    @$el.on('mouseover', '.popover_notes', (e) =>
      $tr = @$(e.target).closest('tr.ticket-group')
      hasPopover = $tr.data('has-popover')
      if (!hasPopover)
        $tr.data('has-popover', 1)
        ticketGroupId = $tr.data('ticket-group-id')
        ticketGroup = @availableCollection.get(ticketGroupId)
        presenter = new App.Presenters.TicketGroupPresenter(ticketGroup)
        $el = $tr.find('.popover_notes')
        $el.popover({
          placement: 'left'
          offset: 10
          container: $el
          content: @notesPopoverTemplate({
            presenter: presenter
          })
          trigger: 'hover'
          html: true
          title: 'Notes'
        }).popover('show')
    )

  showBrokerInfo: (e) ->
    data = $(e.currentTarget).data()
    dialog = new App.Views.TicketGroups.BrokerDetails({
      officeId: data.officeId
    })
    e.preventDefault()
    e.stopPropagation()
    return false

  hideContextMenus: (e) ->
    @$('.ticketDropdownMenu').remove()
    @$('button.dropdown-toggle.active').removeClass('active')

  getTicketGroupFromEvent: (e) ->
    data = e.target.dataset
    if(data && data.ticketGroupId)  # data-ticket-group-id is in target element
      id = data.ticketGroupId
    else  # data-ticket-group-id is in parent element
      ul = e.target.closest('ul.ticketDropdownMenu')
      data = ul.dataset
      id = data.ticketGroupId
    ticketGroupModel = @availableCollection.get(id)
    return ticketGroupModel

  # http://datatables.net/examples/api/row_details.html
  toggleTicketGroupDetails: ($tr, id) ->
    dataTable = @table.getDataTable()
    row = dataTable.row($tr)
    if (row.child.isShown())
      row.child.hide()
      $tr.removeClass('shown')
    else
      ticketGroup = @availableCollection.get(id)
      detailsView = new App.Views.TicketGroups.ExpandedRowDetails({
        @eventModel
        row
        ticketGroup
        ticketGroupListView: @
      })
      $tr.addClass('shown')

  #/////////////////////////////////////////////////////////////////////////////
  # FILTERS
  #/////////////////////////////////////////////////////////////////////////////

  renderFilters: () ->
    # Sorry this is a really dumb way of inserting html,
    # but I see no other simple to keep flexbox layout working.
    $container = @$('#ticketFilterContainer')
    isCollapsed = SESSION.role.cannot(App.Access.Resources.Inventory.AllTickets.ViewTGFiltersByDefault)
    filterOptions = {
      filterIcon: "fa-solid fa-chevron-right"
      collapsed: ""
      hide: ""
    }

    if isCollapsed
      filterOptions = {
        filterIcon: "fa-solid fa-chevron-left"
        collapsed: "collapsed"
        hide: "display: none;"
      }

    $container.html(
      @filtersTemplate(filterOptions)
    )
    @collapsibleFilters = new App.Utils.CollapsibleWidget({
      $content: @$('#filterContent')
      $collapseButton: @$('#filterButton')
      isHorizontalSplit: true
      onChange: () => @table?.render()
      isCollapsed,
      name: "Ticket Groups List View Filters"
    })
    @collapsibleFilters.collapse()

  buildFiltersForCollection: (v3TicketGroupsCollection, preserveFilters = false) ->
    @uniqueFilterOptions = {}

    tgObjs = v3TicketGroupsCollection.map((v3TicketGroupModel) ->
      return _.pick(v3TicketGroupModel.attributes, FILTERABLE_ATTRS)
    )
    @pourover = new PourOver.Collection(tgObjs)

    for attr in FILTERABLE_ATTRS
      @uniqueFilterOptions[attr] = _.uniq(_.pluck(tgObjs, attr))

    # Condense splits
    uniqueSplits = _.uniq(_.flatten(@uniqueFilterOptions._splits))
    @uniqueFilterOptions._splits = uniqueSplits

    for attr in FILTERABLE_ATTRS
      @uniqueFilterOptions[attr] = @uniqueFilterOptions[attr].sort(App.Utils.alphaNumericSort)

    # Condense sections
    condensedSections = []
    sections = @uniqueFilterOptions._section
    for section in sections
      sanitizedSection = section.match(DIGITS_PLUS_GARBAGE_REGEX)
      if (!sanitizedSection)
        # It's text only with no numbers like "Parking Pass".
        sanitizedSection = section
      condensedSections.push(sanitizedSection)
    @uniqueFilterOptions._section = condensedSections

    @makeFilters()
    @populateFilterDropdowns()

    if (preserveFilters)
      for own attr, val of @currentFilters
        @addOneFilter(attr, val)
    else
      @resetFilterUi()

  makeFilters: () ->
    filters = []
    for own attr, options of @uniqueFilterOptions

      if (
        attr == '_splits'
      )
        filters.push(
          PourOver.makeInclusionFilter(attr, @uniqueFilterOptions[attr])
        )

      if (
        attr == '_id' ||
        attr == '_row' ||
        attr == '_section' ||
        attr == '_brokerageAbbreviation' ||
        attr == '_unifiedFormat'
      )
        filters.push(
          PourOver.makeExactFilter(attr, @uniqueFilterOptions[attr])
        )

      if (
        attr == '_isInHand' ||
        attr == '_isWheelchair' ||        
        attr == '_isInstantDelivery'
      )
        filters.push(
          # Support filtering by true, false, or both
          PourOver.makeExactFilter(attr, [true, false, [true, false]])
        )

    @pourover.addFilters(filters)

  populateFilterDropdowns: () ->
    for own attr, options of @uniqueFilterOptions
      if (options.length && typeof options[0] != "boolean" && typeof options[0] != "undefined")
        options = _.map(options, (el, index) =>
          if (el)
            return { id: el, text: el.toString() }
          else
            return false
        )
        options = _.compact(options)
        @$("##{ attr }").select2({
          multiple: true
          placeholder: 'All'
          data: options
          initSelection: (element, callback) =>
            data = {
              id: element.val()
              text: element.val()
            }
            callback(data)
        }).on('change', (e) =>
          attr = e.target.id
          val = $(e.target).select2('data')
          @addAndApplyOneFilter(attr, val)
        )
    #@makeAgeDropdown()

  resetFilterUi: () ->
    @$('#_id').select2('val', '')
    @$('#_age').select2('val', '')
    @$('#_row').select2('val', '')
    @$('#_zone').select2('val', '')
    @$('#_splits').select2('val', '')
    @$('#_section').select2('val', '')
    @$('#_brokerageAbbreviation').select2('val', '')
    @$('#priceMin').val('')
    @$('#priceMax').val('')
    @$('#_isInHand').prop('checked', false)
    @$('#_isWheelchair').prop('checked', false)
    @$('#_isInstantDelivery').prop('checked', false)
    @handleFormatVisibility()

  applyCurrentFiltersToCollection: (collection) ->
    matchSets = []
    for own filterName, filter of @pourover.filters
      matchSet = filter.current_query
      if (matchSet)
        matchSets.push(matchSet)
    currentSet = matchSets.pop()
    while (nextSet = matchSets.pop())
      currentSet = currentSet.and(nextSet)

    if (currentSet)
      results = @pourover.get(currentSet.cids)
    else
      # No filters
      results = @pourover.items
    models = []
    for result in results
      models.push(collection.get(result._id))

    toggleSelections = @getToggleSelections()
    filteredTicketGroupsCollection = new App.Collections.V3.TicketGroups(models, {
      mapping: C.Mappings.TicketGroupEndpoint.TicketGroup
      electronicFormatsOnly: SESSION.role.cannot(App.Access.Resources.Inventory.AllTickets.NonElectronicFormats)
      eventId: @eventModel.id
      isParking: (toggleSelections.TICKETS_OR_PARKING == TICKETS_OR_PARKING.PARKING)
      isTickets: (toggleSelections.TICKETS_OR_PARKING == TICKETS_OR_PARKING.TICKETS)
      isMy: (toggleSelections.MY_OR_ALL_TICKETS == MY_OR_ALL_TICKETS.MY)
      isAll: (toggleSelections.MY_OR_ALL_TICKETS == MY_OR_ALL_TICKETS.ALL)
      isSold: (toggleSelections.AVAILABLE_OR_SOLD == AVAILABLE_OR_SOLD.SOLD)
      isAvailable: (toggleSelections.AVAILABLE_OR_SOLD == AVAILABLE_OR_SOLD.AVAILABLE)
    })
    return filteredTicketGroupsCollection

  createSectionIdFilter: () ->
    sectionIds = @availableCollection.pluck('_section')
    sectionIdFilter = PourOver.makeExactFilter('_section', sectionIds)
    # http://nytimes.github.io/pourover/examples/examples_build/basic_pourover_ing.html#section-9
    preserveFilters = true
    @buildFiltersForCollection(@availableCollection, preserveFilters)
    @pourover.addFilters(sectionIdFilter)

  handleFormatVisibility: () ->
    options = @uniqueFilterOptions
    formats = @$(FORMAT_SELECTOR)
    _.each formats, (format) ->
      id = '#' + format.id
      if (options["_unifiedFormat"].includes(format.id))
        @$(id).prop('disabled', false)
        @$(id).prop('checked', true)
      else
        @$(id).prop('checked', false)
        @$(id).prop('disabled', true)

  # Does not apply the change.
  # Use this to add multiple filters then apply all at once.
  addOneFilter: (attr, val) ->
    if (@pourover.filters[attr])
      @currentFilters[attr] = val

      if (_.isArray(val))
        # Make a copy, so original isn't modified by .pop()
        val = _.clone(val)

      if (_.isArray(val)) # If multiple options selected, union all of them.
        if (val.length)
          firstVal = val.pop()
          @pourover.filters[attr].query(firstVal.id)
          while (nextVal = val.pop())
            @pourover.filters[attr].unionQuery(nextVal.id)
        else
          @removeOneFilter(attr)
      else
        if (attr == '_age')
          val = [val.id, C.Freshness.Max]
        @pourover.filters[attr].query(val)

  addAndApplyOneFilter: (attr, val, triggeredBySeatingChart = false) ->
    @loader.startLoad()
    @addOneFilter(attr, val, triggeredBySeatingChart)
    @logicMediator(ACTIONS.FILTERS_CHANGED, {
      triggeredBySeatingChart
    })

  # Does not apply the change.
  # Use this to remove multiple filters then apply all at once.
  removeOneFilter: (attr) ->
    delete @currentFilters[attr]
    @pourover.filters[attr]?.clearQuery()

  removeAndApplyOneFilter: (attr) ->
    @removeOneFilter(attr)
    @logicMediator(ACTIONS.FILTERS_CHANGED)

  removeAllFilters: () ->
    for own attr, val of @currentFilters
      @removeOneFilter(attr)
    @logicMediator(ACTIONS.FILTERS_CHANGED)
    #filters = @pourover.filters
    #for own key, filter of filters
    #  filter.clearQuery()

  #/////////////////////////////////////////////////////////////////////////////
  # SEATING CHART
  #/////////////////////////////////////////////////////////////////////////////
  updateTicketsOnSeatingChart: (collection) ->
    @seatMap?.updateTickets(collection)

  #/////////////////////////////////////////////////////////////////////////////
  # FETCHING
  #/////////////////////////////////////////////////////////////////////////////
  fetchTicketsPromise: () ->
    @$('#seatingChartButton').attr('disabled', true)
    @loader.startLoad()
    toggleSelections = @getToggleSelections()
    if (toggleSelections.AVAILABLE_OR_SOLD == AVAILABLE_OR_SOLD.SOLD)
      return @fetchSoldTicketsPromise()
        .then (collection) =>
          @soldCollection = collection
          return collection
    return @fetchAvailableTicketsPromise(toggleSelections)
      .then (collection) =>
        @availableCollection = collection
        # If no 'my' tickets, go to 'all'
        if (collection.length == 0)
          @reRouteForZeroResults(collection)
        return collection

  fetchSoldTicketsPromise: () ->
    deferred = Q.defer()

    # Sold
    v3OrderItemsCollection = new App.Collections.V3.OrderItems(null, {
      mapping: C.Mappings.Direct.OrderItemsByEvent.OrderItems
    })

    v3OrderItemsCollection.fetchPromise({
      data: {
        event_id: @eventModel.id
        active_only: true
      }
    })
    .then (soldTickets) =>
      deferred.resolve(v3OrderItemsCollection)
    .fail (error) =>
      @formErrors.show({
        title: 'Error fetching sold tickets.'
        errors: error
      })
      deferred.reject(error)
    .done();

    return deferred.promise

  fetchAvailableTicketsPromise: (toggleSelections) ->
    deferred = Q.defer()
    v3TicketGroupsCollection = new App.Collections.V3.TicketGroups(null, {
      mapping: C.Mappings.TicketGroupEndpoint.TicketGroup
      electronicFormatsOnly: SESSION.role.cannot(App.Access.Resources.Inventory.AllTickets.NonElectronicFormats)
      eventId: @eventModel.id
      isParking: (toggleSelections.TICKETS_OR_PARKING == TICKETS_OR_PARKING.PARKING)
      isTickets: (toggleSelections.TICKETS_OR_PARKING == TICKETS_OR_PARKING.TICKETS)
      isMy: (toggleSelections.MY_OR_ALL_TICKETS == MY_OR_ALL_TICKETS.MY)
      isAll: (toggleSelections.MY_OR_ALL_TICKETS == MY_OR_ALL_TICKETS.ALL)
      isSold: (toggleSelections.AVAILABLE_OR_SOLD == AVAILABLE_OR_SOLD.SOLD)
      isAvailable: (toggleSelections.AVAILABLE_OR_SOLD == AVAILABLE_OR_SOLD.AVAILABLE)
    })
    v3TicketGroupsCollection.fetchPromise()
    .then (collection) =>
      deferred.resolve(v3TicketGroupsCollection)
    .fail (error) =>
      @formErrors.show({
        title: 'Error fetching tickets.'
        errors: error
      })
      deferred.reject(error)
    .done()
    return deferred.promise

  reRouteForZeroResults: (collection) ->
    toggleSelections = @getToggleSelections()
    if (
      toggleSelections.AVAILABLE_OR_SOLD == AVAILABLE_OR_SOLD.AVAILABLE &&
      toggleSelections.MY_OR_ALL_TICKETS == MY_OR_ALL_TICKETS.MY &&
      toggleSelections.TICKETS_OR_PARKING == TICKETS_OR_PARKING.TICKETS
    )
      if (@filterCode)
        App.router.navigate("/tickets/events/#{ App.router.getMyOrAllEvents() }/filter/#{ @filterCode }/#{ @selectedEventId }/#{ MY_OR_ALL_TICKETS.ALL }", { trigger: true })
      else
        App.router.navigate("/tickets/events/#{ App.router.getMyOrAllEvents() }/#{ @selectedEventId }/#{ MY_OR_ALL_TICKETS.ALL }", { trigger: true })

  #/////////////////////////////////////////////////////////////////////////////
  # PRICE EDITING
  #/////////////////////////////////////////////////////////////////////////////
  saveInputsToModels: () ->

    # Wholesale.
    $wholesaleInputs = @$('.editWholesaleInput')
    $wholesaleInputs.each((index, inputEl) =>
      $input = $(inputEl)
      val = parseFloat($input.val())
      ticketGroupId = $input.data().ticketGroupId
      v3TicketGroupModel = @availableCollection.get(ticketGroupId)
      if (v3TicketGroupModel.get('_priceWholesale') != val)
        v3TicketGroupModel.set('_priceWholesaleEdited', val)
    )

    # Retail.
    @availableCollection.forEach((v3TicketGroupModel) =>
      retailInput = v3TicketGroupModel.retailInput
      if (retailInput)
        val = parseFloat(retailInput.getVal())
        if (
          retailInput.isOverridden &&
          _.isNumber(val) &&
          val != v3TicketGroupModel.get('_priceRetailOverride')
        )
          val = parseFloat(val)
          v3TicketGroupModel.set('_priceRetailOverrideEdited', val)
    )

  setupMarkupDropdowns: () ->
    hasSelect2 = @$('#wholesaleMarkupSelect').data('select2')
    if (!hasSelect2)
      options = C.Options.TicketGroup.PriceModOptions
      options = _.map(options, (val, key) ->
        return {
        id: key
        text: key
        }
      )
      @$('#wholesaleMarkupSelect, #retailMarkupSelect').select2({
        data: options
        initSelection: (element, callback) ->
          data = {
            id: element.val()
            text: element.val()
          }
          callback(data)
      })
      @$('#wholesaleMarkupSelect, #retailMarkupSelect').select2('val', 'Set To')
      that = @
      @$('#wholesaleMarkupSelect').change(() ->
        val = $(@).val()
        units = '$'
        if (val == 'Percentage')
          units = '%'
        that.$('#wholesaleMarkupText').parent().find('span.add-on').html(units)
      )
      @$('#retailMarkupSelect').change(() ->
        val = $(@).val()
        units = '$'
        if (val == 'Percentage')
          units = '%'
        that.$('#retailMarkupText').parent().find('span.add-on').html(units)
      )

  #/////////////////////////////////////////////////////////////////////////////
  # VIEW EVENTS
  #/////////////////////////////////////////////////////////////////////////////
  events:

    'click #availableButton':         'onToggleSelectionsChange'
    'click #soldButton':              'onToggleSelectionsChange'
    'click #myTicketsButton':         'onToggleSelectionsChange'
    'click #allTicketsButton':        'onToggleSelectionsChange'
    'click #ticketsTypeButton':       'onToggleSelectionsChange'
    'click #parkingTypeButton':       'onToggleSelectionsChange'
    'click #showLegendButton':        'onShowLegendButtonClick'
    'click #affiliate_link_button':   'onGenerateLinkButtonClick'
    'click #eventNotes':              'onEventNotesClick'

    'click tr.ticket-group':                        'onTicketGroupRowClick'
    'click tr.ticket-group .ticketGroupSelection':  'onTicketGroupCheckboxClick'
    'click #bulkSelectCheckbox' :                   'onBulkSelectCheckboxClick'
    'click tr.ticket-group 
      button.dropdown-toggle:not(.active)':         'onContextButtonClick'
    'click tr.ticket-group 
      button.dropdown-toggle.active':               'onActiveContextButtonClick'
    'click .broker':                                'showBrokerInfo'
    'click #seatingChartButton':                    'onSeatingChartButtonClick'
    'click #listViewButton':                        'onListViewButtonClick'
    'click #editPricesButton':                      'onEditPricesButtonClick'
    'click #cancelPricesButton':                    'onCancelPricesButtonClick'
    'click #savePricesButton':                      'onSavePricesButtonClick'
    'click #updatePricesButton':                    'onUpdatePricesButtonClick'
    'click #refreshTicketsButton':                  'onRefreshTicketsButtonClick'
    'click #bulkBuyButton':                         'onBulkBuyButtonClick'

    # CONTEXT MENU ITEMS /////////////////////////////////////////////////////
    'click .ticketDropdownMenu li:not(.disabled) .edit':  'onContextEditClick'
    'click .ticketDropdownMenu .sell':                    'onContextSellClick'
    'click .ticketDropdownMenu .hold':                    'onContextHoldClick'
    'click .ticketDropdownMenu .take':                    'onContextTakeClick'
    'click .ticketDropdownMenu .manageEtickets':          'onContextManageEtickets'
    'click .ticketDropdownMenu .addToCart':               'onContextAddToCartClick'
    'click .ticketDropdownMenu .addToQuote':              'onContextAddToQuoteClick'
    'click .ticketDropdownMenu .waste':                   'onContextWasteClick'
    'click .ticketDropdownMenu .broadcast':               'onContextBroadcastClick'
    'click .ticketDropdownMenu .destroySpec':             'onContextDestroySpecClick'
    'click .ticketDropdownMenu .buySell':                 'onContextBuySellClick'
    'click .ticketDropdownMenu .buySellNonPartnerEvopay': 'onContextBuySellNonPartnerEvopayClick'
    'click .ticketDropdownMenu .buy':                     'onContextBuyClick'
    'click .ticketDropdownMenu .bid':                     'onContextBidClick'
    'click .affiliateOrderButton':                        'onContextAffiliateSellClick'

    # FILTER EVENTS //////////////////////////////////////////////////////////
    'keyup #priceMin':              'onPriceFilterChange'
    'keyup #priceMax':              'onPriceFilterChange'
    'change #_isInHand':            'onCheckboxFilterChange'
    'change #_isInstantDelivery':   'onCheckboxFilterChange'
    'change #_isWheelchair':        'onCheckboxFilterChange'
    'click #_isWheelchair':         'onCheckboxFilterChange'
    'click #resetFilterButton':     'onResetFilterButtonClick'
    'change input[name="format"]':  'onFormatChange'

    # INTERACTIVE SEATING //////////////////////////////////////////////////////
    'mouseover tr.ticket-group': 'onTicketGroupRowMouseover'
    'mouseout tr.ticket-group':  'onTicketGroupRowMouseout'

    # EXPANDED ROW /////////////////////////////////////////////////////////////
    'click .expandedTicketRowDetails .buy': 'onContextBuyClick'

  onToggleSelectionsChange: (e) ->
    $button = $(e.currentTarget)
    if ($button.hasClass('active'))
      e.stopPropagation()
      e.preventDefault()
      return false

    id = $button.attr('id')

    if (id == 'availableButton' || id == 'soldButton')
      @$('#availableButton').removeClass('active')
      @$('#soldButton').removeClass('active')
      action = ACTIONS.AVAILABLE_OR_SOLD_CHANGE

    if (id == 'myTicketsButton' || id == 'allTicketsButton')
      @$('#myTicketsButton').removeClass('active')
      @$('#allTicketsButton').removeClass('active')
      action = ACTIONS.MY_OR_ALL_CHANGE

    if (id == 'ticketsTypeButton' || id == 'parkingTypeButton')
      @$('#ticketsTypeButton').removeClass('active')
      @$('#parkingTypeButton').removeClass('active')
      action = ACTIONS.TICKETS_OR_PARKING_CHANGE

    $button.addClass('active')

    @logicMediator(action)

  onShowLegendButtonClick: (e) ->
    legend = new App.Views.TicketGroups.Legend()

  onGenerateLinkButtonClick: () ->
    legend = new App.Views.AffiliateLink({event_id: @eventModel.id})

  onEventNotesClick: () ->
    legend = new App.Views.TicketGroups.ModalNotes({
      eventNotes: @eventModel.get('_notes')
    })
    @eventModalsStore.set({
      cid: '' + @eventModel.id,
      attributes: {
        shown: true
      }
    })

  onTicketGroupRowClick: (e) ->
    $target = $(e.target)
    if (
      $target.is('i') ||
      $target.is('button')
    )
      return
    $tr = $(e.currentTarget)
    id = $tr.data('ticket-group-id')
    @toggleTicketGroupDetails($tr, id)
  
  onTicketGroupCheckboxClick: (e) ->
    e.stopPropagation()
    checked = e.target.checked

    if (checked && CHECKOUT_THRESHOLD <= BULK_BUY_LISTINGS.length)
      return

    $tr = $(e.currentTarget)
    id = $tr.data('ticket-group-id')

    if (BULK_BUY_LISTINGS.includes(id))
      BULK_BUY_LISTINGS = BULK_BUY_LISTINGS.filter((item) => item != id)
    else
      BULK_BUY_LISTINGS.push(id)

    @handleRowHighlight(id, checked)
    @disableRowsForBulkBuy()
    @renderBulkBuyButton()

  handleRowHighlight: (id, checked) ->
    $tr = @$('.ticket-group[data-ticket-group-id=' + id + ']')
    $tr.toggleClass('selected', checked)

  onBulkSelectCheckboxClick: (e) ->
    $checkboxes = @$('.ticket-group .ticketGroupSelection')
    checked = e.target.checked

    BULK_BUY_LISTINGS = []
    @clearBulkBuySelection(true)

    $checkboxes.each((index, checkbox) =>

      if (CHECKOUT_THRESHOLD <= BULK_BUY_LISTINGS.length)
        return

      $checkbox = $(checkbox)
      $checkbox.prop('checked', checked)

      id = $checkbox.closest('tr').data('ticket-group-id')
      
      if (checked)
        BULK_BUY_LISTINGS.push(id)
      
      @handleRowHighlight(id, checked)
    )
    
    @disableRowsForBulkBuy()
    @renderBulkBuyButton()

  clearBulkBuySelection : (skipSelectAllCheckbox = false) ->
    $checkAll = @$('#bulkSelectCheckbox')

    if (!skipSelectAllCheckbox)
      $checkAll.prop('checked', false)

    $checkboxes = @$('.ticket-group input[type=checkbox]:checked.ticketGroupSelection')
    $checkboxes.prop('checked', false)

    BULK_BUY_LISTINGS = []

    $checkboxes.each((index, checkbox) =>
      $checkbox = $(checkbox)
      id = $checkbox.closest('tr').data('ticket-group-id')
      @handleRowHighlight(id, false)
    )

    @renderBulkBuyButton()

  disableRowsForBulkBuy : () ->
    $checkboxes = @$('.ticket-group .ticketGroupSelection')

    $checkboxes.each((index, checkbox) =>
      $checkbox = $(checkbox)
      $checkbox.prop(
        'disabled', 
        CHECKOUT_THRESHOLD <= BULK_BUY_LISTINGS.length && !$checkbox.prop('checked')
      )
    )

  onBulkBuyButtonClick: () ->
    loadingOverlay.render()
    $.ajax({
      type: 'GET'
      url: "/encrypted-user"
      success: (data, status, xhr) =>
        @getCheckoutUrl(data.encryptedUser)
      error: (xhr, status, error) =>
        loadingOverlay.remove()
        errorModal = new App.Views.Shared.BasicModal({
          header: 'Error getting user info.',
          message: error
        })
    })

  getCheckoutUrl: (user) ->
    { CHECKOUT_API_URL } = window.__TEVO_CORE__.env;
    ALLOWED_LISTINGS = BULK_BUY_LISTINGS.slice(0, CHECKOUT_THRESHOLD)

    $.ajax({
      type: 'POST'
      dataType: 'json'
      url: CHECKOUT_API_URL + "/api/auth/forced-sign-in"
      xhrFields: { 
        withCredentials: true 
      }
      data: JSON.stringify({
        user: user,
        returnUrl: window.location.href,
        action: {
          type: 'listing-bulk-buy',
          payload: {
            "eventId": @eventModel.id,
            "listingIds": ALLOWED_LISTINGS
          }
        }
      })
      success: (data, status, xhr) =>
        @clearBulkBuySelection()
        window.location.href = data.redirectUrl
      error: (xhr, status, error) =>
        loadingOverlay.remove()
        errorModal = new App.Views.Shared.BasicModal({
          header: 'Error generating checkout URL.',
          message: error
        })
    })

  renderBulkBuyButton: () ->
    $container = @$('#bulkBuyContainer')
    options = {
      bulkBuyListings: BULK_BUY_LISTINGS
    }
    $container.html(
      @bulkBuyTemplate(options)
    )

  onRefreshTicketsButtonClick: (e) ->
    @logicMediator(ACTIONS.REFRESH_BUTTON)

  onSeatingChartButtonClick: (e) ->
    if (C.EnableNewSeatingCharts)
      @logicMediator(ACTIONS.SEATING_CHART_BUTTON)
    else
      seatingChart = new App.Views.TicketGroups.SeatingChart({
        event: @eventModel
      })

  onListViewButtonClick: (e) ->
    @logicMediator(ACTIONS.LIST_VIEW_BUTTON)

  onActiveContextButtonClick: () ->
    @hideContextMenus()

  onContextButtonClick: (e) ->
    @hideContextMenus()
    $button = @$(e.currentTarget)
    $button.addClass('active')
    $tr = $button.closest('tr.ticket-group')
    data = $tr.data()
    ticketGroupId = data.ticketGroupId
    v3TicketGroupModel = @availableCollection.get(ticketGroupId)
    availableTix = v3TicketGroupModel.get('_availableQuantity')
    canSell = !!(availableTix)
    isSpec = v3TicketGroupModel.get('_hasSpecTickets')
    html = ['<ul style="display: block;" data-ticket-group-id="' + ticketGroupId + '" class="ticketDropdownMenu dropdown-menu context-menu">']
    eventId = @eventModel.id
    isMlbEvent = @eventModel?.attributes?.category?.id == "3"
    if (v3TicketGroupModel.isOwned())
      myTicketGroups = App.Access.Resources.Inventory.AllTickets.MyTicketsActions
      if (SESSION.role.can(myTicketGroups.Edit))
        disableButton = if @is_premium() then "" else "disabled"
        is_premium    = if @is_premium() then "" else "is_premium"
        html.push('<li class="' +disableButton+ '"> <a class="edit ' + is_premium+ '"> Edit </a> </li>')
      if (
        SESSION.role == App.Access.Roles.POSLite ||
        SESSION.role == App.Access.Roles.POSPartner ||
        SESSION.role == App.Access.Roles.POS
      )
        if (canSell && SESSION.role.can(myTicketGroups.Sell))
          html.push('<li><a class="sell">Sell</a></li>')
        if (SESSION.role.can(myTicketGroups.Hold))
          html.push('<li class="divider"></li>')
          html.push('<li><a class="hold">Hold</a></li>')
        if (SESSION.role.can(myTicketGroups.Take))
          html.push('<li><a class="take">Take</a></li>')
        html.push('<li class="divider"></li>')
        if (!isSpec && SESSION.role.can(myTicketGroups.ManageEtickets))
          if (v3TicketGroupModel.get('_format') == C.TicketFormats.Eticket)
            if (v3TicketGroupModel.hasSomeEticketsUploaded())
              html.push('<li><a class="manageEtickets">Manage Etickets</a></li>')
            else
              html.push('<li><a class="manageEtickets">Upload Etickets</a></li>')
          else
            html.push('<li><a class="manageEtickets">Upload Barcodes</a></li>')
          html.push('<li class="divider"></li>')
        if (SESSION.role.can(myTicketGroups.AddToCart))
          html.push('<li><a class="addToCart">Add To Cart</a></li>')
        if (SESSION.role.can(myTicketGroups.AddToQuote))
          html.push('<li><a class="addToQuote">Add To Quote</a></li>')
          html.push('<li class="divider"></li>')
        if (v3TicketGroupModel.canWaste())
          if (!isSpec && SESSION.role.can(myTicketGroups.Waste))
            html.push('<li><a class="waste">Waste</a></li>')
        if (SESSION.role.can(myTicketGroups.Unbroadcast))
          if (v3TicketGroupModel.get('_isBroadcast'))
            html.push('<li><a class="broadcast">Unbroadcast</a></li>')
          else
            html.push('<li><a class="broadcast">Broadcast</a></li>')
        if (isSpec && SESSION.role.can(myTicketGroups.DestroySpec))
          html.push('<li class="divider"></li>')
          html.push('<li><a class="destroySpec">Destroy</a></li>')
    else
      allTicketGroups = App.Access.Resources.Inventory.AllTickets.AllTicketsActions
      if (SESSION.role.can(allTicketGroups.BuySell))
        if (SESSION.role.can(allTicketGroups.PartnerEvopaySell))
          html.push('<li><a class="buySell">Buy / Sell</a></li>')
        else
          html.push('<li><a class="buySellNonPartnerEvopay">Buy / Sell</a></li>')
      if (SESSION.role.can(allTicketGroups.Buy))
        html.push('<li><a class="buy">Buy</a></li>')
      if (SESSION.role.can(allTicketGroups.Bid) && !isMlbEvent)
        html.push('<li class="divider"></li>')
        html.push('<li><a class="bid">Bid</a></li>')
      if (SESSION.role.can(allTicketGroups.AddToQuote))
        html.push('<li class="divider"></li>')
        html.push('<li><a class="addToQuote">Add To Quote</a></li>')
    html.push('</ul>')
    $menu = $(html.join(''))

    # Craziness to make this escape the dataTable scroll area.
    $menu.css({
      top:  "#{ $tr.offset().top + 36 }px"
      left: "#{ $tr.offset().left + 8 }px"
    })
    # This click would make dropdown menu immediately close.
    e.preventDefault()
    e.stopPropagation()

    @$el.append($menu)
    $button.addClass('active')

    # Make it go away when blurred.
    $('body').on('click', ':not(.ticketDropdownMenu)', () =>
      $button.removeClass('active')
      $menu.remove()
      $('body').off('click', ':not(.ticketDropdownMenu)')
    )

  onTicketGroupRowMouseover: (e) ->
    $tr = $(e.currentTarget)
    id = $tr.data('ticket-group-id')
    ticketGroup = @availableCollection.get(id)
    @seatMap?.highlightSection(ticketGroup.get('tevo_section_name'))

  onTicketGroupRowMouseout: (e) ->
    $tr = $(e.currentTarget)
    id = $tr.data('ticket-group-id')
    ticketGroup = @availableCollection.get(id)
    @seatMap?.unhighlightSection(ticketGroup.get('tevo_section_name'))

  onContextEditClick: (e) ->
    v3TicketGroupModel = @getTicketGroupFromEvent(e)
    App.Controllers.ticketGroupController.initiateEdit({
      ticketGroupModel: v3TicketGroupModel
      @eventModel
      @formErrors
    })

  onContextSellClick: (e) ->
    ticketGroupModel = @getTicketGroupFromEvent(e)
    App.router.saveEventViewSettings(@getEventViewSettings())
    App.router.navigate("/sell/event/#{ @eventModel.id }/tickets/#{ ticketGroupModel.id }")
    App.Controllers.buySellController.sell({
      @eventModel
      ticketGroupModel
      filters: @pourover.filters
    })

  onContextHoldClick: (e) ->
    @doHoldTake({
      e
      isHold: true
    })

  onContextTakeClick: (e) ->
    @doHoldTake({
      e
      isTake: true
    })

  doHoldTake: (options = {}) ->
    {
      e
      isHold
      isTake
    } = options
    ticketGroupModel = @getTicketGroupFromEvent(e)
    $tr = @$(e.target).closest('tr.ticket-group')
    view = new App.Views.TicketGroups.BeginHoldTakeCart({
      isHold
      isTake
      ticketGroupModel
    })

  onContextManageEtickets: (e) ->
    v3TicketGroupModel = @getTicketGroupFromEvent(e)
    App.router.saveEventViewSettings(@getEventViewSettings())
    App.router.eticketManagementIndex(null, null, {
      v3TicketGroupModel
      v3EventModel: @eventModel
    })

  onContextAddToCartClick: (e) ->
    ticketGroupModel = @getTicketGroupFromEvent(e)
    isCart = true
    view = new App.Views.TicketGroups.BeginHoldTakeCart({
      isCart
      ticketGroupModel
    })

  onContextAddToQuoteClick: (e) ->
    ticketGroupModel = @getTicketGroupFromEvent(e)
    ticketGroupModel.set('_event', @eventModel)
    ticketGroups = []
    if (App.EvoQuote.count())
      _.each(App.EvoQuote.all(), (ticketGroup) =>
        ticketGroups.push(new App.Models.TicketGroup(ticketGroup))
      )
      groupedEvents = _.groupBy(ticketGroups, (ticketGroup) =>
        return ticketGroup.get('_event').id
      )
      if (!groupedEvents[@eventModel.id])
        $.gritter.add({
          title: 'Failed'
          text: 'You already have a quote in progress for another event.'
        })
      else
        App.Controllers.ticketGroupController.addToEvoQuote({
          ticketGroupModel
          @eventModel
        })
    else
      App.Controllers.ticketGroupController.addToEvoQuote({
        ticketGroupModel
        @eventModel
      })

  onContextWasteClick: (e) ->
    ticketGroupModel = @getTicketGroupFromEvent(e)
    App.Controllers.OrderController.initiateWasted(ticketGroupModel, (error, model) =>
      if (error)
        $.gritter.add({
          title          : 'Failed'
          text           : error.message
        })
      else
        $.gritter.add({
          title          : 'Successful'
          text           : 'Tickets were wasted.'
        })
      @logicMediator(ACTIONS.REFRESH_BUTTON)
    )

  onContextBroadcastClick: (e) ->
    v3TicketGroupModel = @getTicketGroupFromEvent(e)
    loadingView = new App.Views.Shared.LoadingView()
    loadingView.render()
    v3TicketGroupModel.toggleBroadcast((error, model) =>
      if (error)
        $.gritter.add({
          title          : 'Failed'
          text           : error.message
        })
      else
        $.gritter.add({
          title          : 'Successful'
          text           : 'Broadcast settings changed.'
        })
      @logicMediator(ACTIONS.REFRESH_BUTTON)
      loadingView.remove()
    )

  onContextDestroySpecClick: (e) ->
    v3TicketGroupModel = @getTicketGroupFromEvent(e)
    onYes = () =>
      loadingButton = new App.Utils.LoadingButton(confirmModal.$('#yesButton'))
      loadingButton.startLoad()
      tgid = v3TicketGroupModel.id
      v3TicketGroupModel.purpose = C.Purposes.DestroyTicketGroup # Tell it to use direct url.
      v3TicketGroupModel.destroyPromise()
      .then((model) =>
        @$('tr[data-ticket-group-id=' + tgid + ']').remove()
        confirmModal.remove()
        $.gritter.add({
          title          : 'Successful'
          text           : 'Tickets destroyed.'
        })
      )
      .fail((error) =>
        confirmModal.remove()
        console.error(error.stack)
        errorModal = new App.Views.Shared.BasicModal({
          header: 'Error fetching order items.'
          message: error.message
        })
      )

    confirmModal = new App.Views.Shared.BasicModal({
      isConfirmation: true
      header: 'Are you sure?'
      message: 'Destroy these category seats?'
      onYes: onYes
      icon: 'fa-solid fa-trash'
    })

  onContextAffiliateSellClick: (e) ->
    data = e.target.dataset
    if(data && data.ticketGroupId)  # data-ticket-group-id is in target element
      tgId = data.ticketGroupId
    else  # data-ticket-group-id is in parent element
      closestTr = e.currentTarget.closest('tr')
      tgId = closestTr.dataset.ticketGroupId
    ticketGroupModel = @availableCollection.get(tgId)
    App.router.saveEventViewSettings(@getEventViewSettings())
    App.router.navigate("/affiliatesell/event/#{ @eventModel.id }/tickets/#{ ticketGroupModel.id }")
    App.Controllers.buySellController.affiliateSell({
      @eventModel
      ticketGroupModel
      filters: @pourover.filters
    })

  onContextBuySellClick: (e) ->
    ticketGroupModel = @getTicketGroupFromEvent(e)
    App.router.saveEventViewSettings(@getEventViewSettings())
    App.router.navigate("/buysell/event/#{ @eventModel.id }/tickets/#{ ticketGroupModel.id }")
    App.Controllers.buySellController.buySell({
      @eventModel
      ticketGroupModel
      filters: @pourover.filters
    })

  onContextBuySellNonPartnerEvopayClick: (e) ->
    ticketGroupModel = @getTicketGroupFromEvent(e)
    App.router.saveEventViewSettings(@getEventViewSettings())
    App.router.navigate("/affiliatesell/event/#{ @eventModel.id }/tickets/#{ ticketGroupModel.id }")
    App.Controllers.buySellController.affiliateSell({
      @eventModel
      ticketGroupModel
      filters: @pourover.filters
    })

  onContextBuyClick: (e) ->
    ticketGroupModel = @getTicketGroupFromEvent(e)
    window.dataLayer.push({
      event: 'ticket-group-buy',
      ticketGroupId: ticketGroupModel.id,
    })
    App.router.saveEventViewSettings(@getEventViewSettings())
    App.router.navigate("/buy/event/#{ @eventModel.id }/tickets/#{ ticketGroupModel.id }")
    App.Controllers.buySellController.buy({
      @eventModel
      ticketGroupModel
      filters: @pourover.filters
    })

  onContextBidClick: (e) ->
    ticketGroupModel = @getTicketGroupFromEvent(e)
    window.dataLayer.push({
      event: 'ticket-group-place-bid',
      ticketGroupId: ticketGroupModel.id,
    })
    window.location.href = "/bids/place/#{ ticketGroupModel.id }"

  # FILTERS ////////////////////////////////////////////////////////////////////
  onPriceFilterChange: (e) ->
    min = parseFloat(@$('#priceMin').val())
    min ||= 0
    max = parseFloat(@$('#priceMax').val())
    max ||= Number.MAX_SAFE_INTEGER
    range = [min, max]
    filter = PourOver.makeRangeFilter('_priceWholesale', [range])
    @pourover.addFilters(filter)
    @pourover.filters['_priceWholesale'].query(range)
    @logicMediator(ACTIONS.FILTERS_CHANGED)

  onResetFilterButtonClick: (e) ->
    @logicMediator(ACTIONS.RESET_FILTERS_BUTTON)

  onCheckboxFilterChange: (e) ->
    checkbox = @$(e.currentTarget)
    attr = checkbox.attr('id')
    val = !!(checkbox.is(':checked'))
    runFilter = val

    if (runFilter)
      @addAndApplyOneFilter(attr, val)
    else
      @removeAndApplyOneFilter(attr)

  onFormatChange: (e) ->
    selection = []
    formats = @$(FORMAT_SELECTOR)
    _.each formats, (format) ->
      if (format.checked == true)
        selection.push({
          id: format.id
        })
    @addAndApplyOneFilter('_unifiedFormat', selection)

  # PRICE EDITS //////////////////////////////////////////////////////////////
  onEditPricesButtonClick: () ->
    @logicMediator(ACTIONS.ENTER_EDIT_MODE)

  onCancelPricesButtonClick: () ->
    @$('#editPricesBox').hide()
    @$('#editPricesButton').show()
    @isPriceEditTable = false
    @logicMediator(ACTIONS.REFRESH_BUTTON)

  onSavePricesButtonClick: () ->
    @loader.startLoad()
    @saveInputsToModels()
    changedTicketGroups = @availableCollection.select((ticketGroupModel) ->
      if (
        _.isNumber(ticketGroupModel.get('_priceRetailOverrideEdited')) ||
        _.isNumber(ticketGroupModel.get('_priceWholesaleEdited'))
      )
        if (
          ticketGroupModel.get('_priceWholesaleEdited') != ticketGroupModel.get('_priceWholesale') ||
          ticketGroupModel.get('_priceRetailOverrideEdited') !=  ticketGroupModel.get('_priceRetailOverride')
        )
          return ticketGroupModel
    )
    if (!changedTicketGroups.length)
      @formErrors.show({
        type: 'info'
        title: 'No changes to save'
        message: 'No prices were edited.'
      })
      @stopLoad()
    else
      count = changedTicketGroups.length
      runningCount = 0

      saveNextTicketGroup = (groupsToSave) =>
        if (!groupsToSave.length)
          @formErrors.reset()
          $.gritter.add({
            title          : 'All prices saved'
            text           : "#{ count } ticket groups were edited."
          })
          @onCancelPricesButtonClick()
          @stopLoad()
          return null

        v3TicketGroupModel = groupsToSave.pop()
        runningCount++
        @formErrors.show({
          type: 'info'
          title: "Saving price edits"
          message: "Saving ticket group #{ runningCount } of #{ count }"
        })
        v3TicketGroupModel.set('_priceWholesale',       v3TicketGroupModel.get('_priceWholesaleEdited'))
        v3TicketGroupModel.set('_priceRetailOverride',  v3TicketGroupModel.get('_priceRetailOverrideEdited'))
        v3TicketGroupModel.purpose = C.Purposes.SavePriceEdits
        v3TicketGroupModel.savePromise()
        .then (model) =>
          setTimeout(() =>
            saveNextTicketGroup(groupsToSave)
            #arguments.callee(groupsToSave) # = saveNextTicketGroup(groupsToSave) [coffeescrpt shortcoming]
          , 0)
        .fail (error) =>
          @formErrors.show({
            title: "Error saving price edits on ticket group #{ runningCount } of #{ count }"
            error
          })
          return null

      saveNextTicketGroup(changedTicketGroups)

  onUpdatePricesButtonClick: () ->
    markups = {
      retail: {
        amount: @$('#retailMarkupText').val()
        type: @$('#retailMarkupSelect').select2('val')
      }
      wholesale: {
        amount: @$('#wholesaleMarkupText').val()
        type: @$('#wholesaleMarkupSelect').select2('val')
      }
    }
    for own field, markupObj of markups
      {
        amount
        type
      } = markupObj
      amount = parseFloat(amount, 10)
      if  _.isFinite(amount)

        @availableCollection.forEach((ticketGroupModel) =>
          if (ticketGroupModel.isOwned())

            if (field == 'retail')
              baseVal = ticketGroupModel.get('_priceRetail')
              editAttr = '_priceRetailOverrideEdited'
              if (_.isNumber(ticketGroupModel.get(editAttr)))
                baseVal = ticketGroupModel.get(editAttr)

            if (field == 'wholesale')
              baseVal = ticketGroupModel.get('_priceWholesale')
              editAttr = '_priceWholesaleEdited'
              if (_.isNumber(ticketGroupModel.get(editAttr)))
                baseVal = ticketGroupModel.get(editAttr)

            if (type == 'Percentage')
              newVal = baseVal + (baseVal * (amount / 100))
            if (type == 'Flat')
              newVal = baseVal + amount
            if (type == 'Set To')
              newVal = amount
            newVal = Math.max(newVal, 0)

            if (field == 'retail')
              if (_.isNumber(amount))
                ticketGroupModel.retailInput.enableOverride()
              ticketGroupModel.set(editAttr, newVal)
            else
              ticketGroupModel.set(editAttr, newVal)
        )

    @isPriceEditTable = true
    @logicMediator(ACTIONS.PRICES_UPDATED)
