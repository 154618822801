App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V2.TicketGroup = require('../../models/v2/ticket_group_model.coffee')

TYPES_IN_ORDER = [
  'PARKING'
  'TICKETS'
  'MY'
  'ALL'
  'SOLD'
  'AVAILABLE'
  'WASTED'
]

TYPES = { # Bit Flags
  PARKING: 1    # 0000001
  TICKETS: 2    # 0000010
  MY: 4         # 0000100
  ALL: 8        # 0001000
  SOLD: 16      # 0010000
  AVAILABLE: 32 # 0100000
  WASTED: 64    # 1000000
}

# The way TYPES works is as a bit flag system.
# A type of MY SOLD TICKETS = MY + SOLD + TICKETS = 4 + 16 + 2 = 22
# 22 in binary is # 10110
#
# You could see if the type is SOLD by checking if (type & SOLD == SOLD), or just if (type & SOLD != 0)
# type   10110
# SOLD & 10000
# -------------
#        10000 = SOLD

URL_PARTS = {
  PARKING: 'parking'
  TICKETS: 'tickets'
  MY: '/my.json'
  ALL: '.json'
  SOLD: ''
  AVAILABLE : ''
  WASTED: '?include_unavailable=true&show_past=true'
}

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V2.TicketGroups = App.Collections.Base.BaseCollection.extend

  model: App.Models.V2.TicketGroup

  url: () ->
    if (@getMapping() == C.Mappings.MassIndexEndpoint.TicketGroup)
      return '/api_direct/v9/ticket_groups/mass_index'

    if (@getMapping() == C.Mappings.TicketGroupEndpoint.TicketGroup)
      url = "/api_direct/v9/events/#{@eventId}/"
      TYPES_IN_ORDER.forEach((type, index, array) =>
        if (@type & TYPES[type])
          url += URL_PARTS[type]
      )
      return url

  initialize: (models, options) ->
    options ||= {}
    @eventId = options.eventId
    @event = options.event
    @type = 0
    if (options.isParking)
      @type |= TYPES['PARKING']
    if (options.isTickets)
      @type |= TYPES['TICKETS']
    if (options.isMy)
      @type |= TYPES['MY']
    if (options.isAll)
      @type |= TYPES['ALL']
    if (options.isSold)
      @type |= TYPES['SOLD']
    if (options.isAvailable)
      @type |= TYPES['AVAILABLE']
    if (options.isWasted)
      @type |= TYPES['WASTED']
    parent.initialize.call(@, models, options)

  fetch: (options) ->
    if (@getMapping() == C.Mappings.TicketGroupEndpoint.TicketGroup)
      options ||= {}
      realCallback = options.success || () ->

        # Available comes from EVENTS endpoint.
      if (@type & TYPES['AVAILABLE'])
        options.success = (collection, response, options) =>
          # We could just do options.success = realCallback.  But this is a more clear.

          @giveTGsEvents(collection)

          realCallback(collection, response, options)
        parent.fetch.call(@, options)

      if (@type & TYPES['SOLD'])
        # 1. Get SOLD Orders for peripheral data.
        #  1.2 Invert SOLD Orders into TicketGroups.
        # 2. Fetch ALL TicketGroups (including all unavailable types like sold & wasted).
        #  2.1 Filter out results leaving only WASTED & SOLD tickets.
        #  2.2 Attach the Order data from Step #1 to the corresponding SOLD TicketGroups.
        soldOrdersCallback = (soldTicketGroupsCollection, response, options) =>

          @type |= TYPES['WASTED'] # Make sure parentFetch uses url for all unavailable types.
          options.success = (collection, response, options) =>

            @giveTGsEvents(collection)

            #  2.1 Filter out results leaving only WASTED & SOLD tickets.
            @remove(
              @filter((ticketGroupModel) ->
                states = ticketGroupModel.get('ticket_states')
                if (_.has(states, 'wasted'))
                  ticketGroupModel.wasted = true # Mark as WASTED.
                  return false # Don't delete WASTED.
                else if (_.has(states, 'sold'))

                  # SANITY CHECK - COULD PROB BE DELETED DOWN THE ROAD.
                  correspondingOrderTG = soldTicketGroupsCollection.get(ticketGroupModel.id)
                  if (!correspondingOrderTG)
                    console.log("WARNING: A SOLD TicketGroup from the TicketGroup endpoint had
                      no corresponding Order on the Order endpoint.  It will be deleted and not displayed.
                      TicketGroupModel = #{ticketGroupModel}")
                    return true # Let's delete these weird orphaned tickets if they have no corresponding Order.

                  return false # Don't delete SOLD.
                else
                  return true # Delete all others
              )
            )

            #  2.2 Attach the Order data from Step #1 to the corresponding SOLD TicketGroups.
            soldTicketGroupsCollection.forEach((soldTicketGroup) =>
              correspondingTG = @get(soldTicketGroup.id)
              if (correspondingTG)
                #correspondingTG.order = soldTicketGroup.get('_order')
                correspondingTG.set('order', soldTicketGroup.get('_order')) # Can remove this once TG is v3.
                correspondingTG.set('_order', soldTicketGroup.get('_order'))
              else
                console.log("WARNING: A SOLD TicketGroup from the Order endpoint had
                  no corresponding TicketGroup on the TicketGroup endpoint.  It will be displayed anyway.
                  TicketGroup from OrderModel = #{soldTicketGroup}")
                @add(soldTicketGroup)
            )
            realCallback(@, response, options)

          # 2. Fetch ALL TicketGroups (including all unavailable types like sold & wasted).
          parent.fetch.call(@, options)

        # 1. Get SOLD Orders for peripheral data.
        @fetchSoldOrders(soldOrdersCallback)
    else
      parent.fetch.call(@, options)

  # Get All SOLD Orders
  fetchSoldOrders: (soldOrdersCallback) ->

    # We make a fresh collection for these SOLD Orders based TicketGroups.
    soldTicketGroupsCollection = new App.Collections.V2.TicketGroups()
    soldTicketGroupsCollection.eventId = @eventId
    soldTicketGroupsCollection.type = @type

    orders = new App.Collections.V2.Orders(null, {
      mapping: C.Mappings.OrderEndpoint.Order
      ordersOrPOs: 'orders'
    })
    orders.fetch({
      data: {
        orders: {
          sold: true
          event_id: @eventId
        }
      }
      success: (soldOrdersCollection, response, options) =>
        if (orders.length)
          orders.each((orderModel) =>

            orderItems = orderModel.get('_items')
            orderItems.each((orderItemModel) =>

              ticketGroupModel = orderItemModel.get('ticketGroup')
              #addToSoldParking = (ticketGroupModel.get('_format') == 'parking') && (@type & TYPES['PARKING'])
              #addToSoldTickets = (ticketGroupModel.get('_format') != 'parking') && (@type & TYPES['TICKETS'])
              addToSoldParking = @type & TYPES['PARKING']
              addToSoldTickets = @type & TYPES['TICKETS']
              if (addToSoldParking || addToSoldTickets)
                # Inversion - pull TG from order, set order & orderItem on TG
                ticketGroupModel.set('order', orderModel) # Can remove this once TG is v3
                ticketGroupModel.set('_order', orderModel)
                ticketGroupModel.updateStandardAttributes()
                soldTicketGroupsCollection.add(ticketGroupModel)
            )
          )
        soldOrdersCallback(soldTicketGroupsCollection, response, options)
    })

  giveTGsEvents: (collection) ->
    collection.forEach((ticketGroupModel) =>
      ticketGroupModel.set('eventId', @eventId)
      # Manually injecting event since lightweight is no longer providing event.
      ticketGroupModel.set('event', @event) # Can remove this once TG is v3
      ticketGroupModel.set('_event', @event)
    )

  eventsByIdJSON: () ->
    events = {}
    _.each(@models, (model) ->
      eventId = model.get('event_id')
      events[eventId] ||= []
      events[eventId].push(model.toPresenterJSON())
    )
    for own eventId, eventArray of events
      eventArray.sort((a, b) ->
        return b._cost - a._cost
      )
    return events

  jsonGroupedByEventId: () ->
    eventsById   = @eventsByIdJSON()
    _.sortBy eventsById, (event) -> event[0].event_occurs_at
