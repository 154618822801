moment = require('moment');

(function() {

  var defaultConfig = {
    ranges: C.DatePickerConfig.Past,
    opens: 'right',
    format: 'MM-DD-YYYY',
    separator: ' to ',
    endDate: new Date(),
    minDate: '01-01-2000', // Must be in 'format' specified above.
    maxDate: '01-01-2100',
    locale: {
      applyLabel: 'Submit',
      "cancelLabel": "Clear",
      fromLabel: 'From',
      toLabel: 'To',
      customRangeLabel: 'Custom Range',
      singleDateLabel: 'Single Date',
      daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      firstDay: 0
    },
    showWeekNumbers: false,
    buttonClasses: ['btn-danger'],
    dateLimit: false
  };

  var anyLabel = '<span color="#999">Any</span>';

  var displayCallback = function($el, start, end) {
    var html;    
    if (start && end) {
      html = moment(start).format(defaultConfig.format) +
      ' to ' +
      moment(end).format(defaultConfig.format);
      //.toString('MMM d, yyyy') + ' - ' + end.toString('MMM d, yyyy');
    } else {
      html = anyLabel;
    }
    $el.find('span').css({ color: '#444' }).html(html);
  };

  var displayDateTimeCallback = function($el, start) {
    var html;
    if (start) {
      html = moment(start).format('MM/DD/YYYY HH:mm a')
    } else {
      html = anyLabel;
    }
    $el.find('span').css({ color: '#444' }).html(html);
  };

  var displaySingleDateCallback = function($el, start) {
    var html;
    if (start) {
      html = moment(start).format('MM/DD/YYYY')
    } else {
      html = '';
    }
    $el.find('span').css({ color: '#444' }).html(html);
  };


  App.Utils.dateRangePicker = function($el, callback, contextOfCallback, customConfig, notLocal) {

    var customConfig = customConfig || {};
    var config = _.extend({}, defaultConfig, customConfig);
    //var offset = new Date();
    //offset = offset.getTimezoneOffset() * 60;

    var realCallback = function(start, end) {

      // This is impossible.
      //if (notLocal) { // For event dates.
        //start = moment([start.year(), start.month(), start.date()]);
        //end = moment([end.year(), end.month(), end.date()]).add('days', 1);
        //start = moment.unix(start.unix() - offset);
        //end = moment.unix(end.unix() - offset);
      ///}// else {
        //start = moment(start).startOf('day');
        //end = moment(end).endOf('day');
      //}
      if (customConfig.singleDatePicker && customConfig.timePicker)
        displayDateTimeCallback($el, start);
      else if (customConfig.singleDatePicker)
        displaySingleDateCallback($el, start);
      else
        displayCallback($el, start, end);
      callback.call(contextOfCallback, start, end);
    };

    $el.daterangepicker(config, realCallback);

    if (customConfig.startDate && customConfig.endDate) {
      realCallback(customConfig.startDate, customConfig.endDate);

    } else {
      //$el.find('span').html(initialRange);
      // Set the initial state of the picker label.
      if (customConfig.singleDatePicker)
        $el.find('span').html('');
      else
        $el.find('span').html(anyLabel);
    }

    $el.on('cancel.daterangepicker', function(ev, picker) {
      $el[0].setDateRange('', '');
    });

    $el[0].setDateRange = function(start, end) {
      $el.daterangepicker(config, realCallback);

      $el.on('cancel.daterangepicker', function(ev, picker) {
        $el[0].setDateRange('', '');
      });

      realCallback(start, end);
    };

    return $el;

  };

})();
