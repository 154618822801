module.exports = App.Models.Order = Backbone.Model.extend({
  urlRoot: '/orders',

  defaults: {
    seat_selection: 'Low',
    shipping: 0,
    tax: 0,
    additional_expense: 0,
    service_fee: 0,
    shipment_snapshot_collection: null,
    has_pending_shipments: false,
    can_add_to_shipments: false,
  },

  initialize: function (attributes, options) {
    var that = this;
    options = options || {};
    this.v1TicketGroupModel = options.v1TicketGroupModel;
    this.v3TicketGroupModel = options.v3TicketGroupModel;

    var tg = this.get('ticketGroup');

    if (!options.addToCart) {
      if (!tg && this.get('items') && this.get('items').length > 0) {
        tg = new App.Models.TicketGroup(this.get('items')[0].ticket_group);
      }

      if (tg instanceof App.Models.TicketGroup && !this.isSub()) {
        if (tg.get('quantity')) {
          this.set('quantity', tg.get('quantity'));
        } else if (!this.get('quantity')) {
          var smallestSplit = _.first(tg.get('splits') || []);
          this.set('quantity', smallestSplit);
        }

        if (!this.get('individual_price')) {
          this.set('individual_price', tg.get('wholesale_price'));
        }
      }

      this.set(
        'shipment_snapshot_collection',
        new App.Collections.ShipmentSnapshots()
      );
      this.on('change:client_id', this.onClientIdChange, this);
      that.on(
        'change:shipment_snapshot_collection',
        that.onShipmentSnapshotCollectionChange
      );
    }
  },

  isSpec: function () {
    var that = this;
    return this.get('spec');
  },

  isSub: function () {
    var that = this;
    return this.get('substitute_order_link_id');
  },

  isEticket: function () {
    var that = this;
    return !!(
      this.get('ticketGroup') && this.get('ticketGroup').get('eticket')
    );
  },

  toPresenterJSON: function () {
    var that = this;
    var presented = _.extend(this.toJSON(), {
      // FIELDS FOR ORDERS SCREEN /////////////////////////////////////////

      customer: (function () {
        var isPO = that.isPO();
        var customer, type;
        if (isPO) {
          customer = that.get('seller');
          type = that.get('seller_type');
        } else {
          customer = that.get('buyer');
          type = that.get('buyer_type');
        }
        if (type == 'Office' && customer && customer.brokerage) {
          return customer.brokerage.name + ' - ' + customer.location;
        }
        if (type == 'Client' && customer) {
          return customer.name;
        }
        return '';
      })(),

      date:
        that.get('created_at') &&
        App.Utils.makeTimestampHuman(
          that.get('created_at'),
          C.DateFormats.TableDateWithTime
        ),

      state:
        C.Options.Order.States[
          that.isPO() ? that.get('buyer_state') : that.get('seller_state')
        ],

      total: (function () {
        if (that.get('order_price_total')) {
          return App.Utils.valueToCurrency(that.get('order_price_total'));
        }
        if (that.get('total')) {
          return App.Utils.valueToCurrency(that.get('total'));
        }
        return '';
      })(),

      balance: App.Utils.valueToCurrency(that.get('balance')),

      reference: that.get('reference') || '',

      viewLink: (function () {
        return [
          '<a href="/order/',
          that.get('id'),
          '" class="view_order_button btn btn-small" data-order-id="',
          that.get('id'),
          '"><i class="icon fa-solid fa-magnifying-glass"></i> View</a>',
        ].join('');
      })(),

      isConsignment: (function () {
        if (that.get('consignment')) {
          return '<span class="label label-info">Yes</span>';
        }
        return '';
      })(),

      // FIELDS FOR SALES REPORT ///////////////////////////////////////////

      additionalFee: App.Utils.valueToCurrency(
        that.get('order_additional_expense')
      ),

      cost: App.Utils.valueToCurrency(that.get('order_cost_total')),

      orderCustomer: that.get('buyer_name'),

      orderLink: (function () {
        if (that.get('order_id')) {
          return (
            '<a href="/order/' +
            that.get('order_link_id') +
            '">' +
            that.get('order_id') +
            '</a>'
          );
        }
        return '';
      })(),

      orderNumber: that.get('order_id') || '',

      orderDate:
        that.get('order_created_at') &&
        App.Utils.makeTimestampHuman(
          that.get('order_created_at'),
          C.DateFormats.TableDateWithTime
        ),

      subtotal: App.Utils.valueToCurrency(that.get('order_price_sub_total')),

      shipping: App.Utils.valueToCurrency(that.get('order_shipping')),

      serviceFee: App.Utils.valueToCurrency(that.get('order_service_fee')),

      tax: App.Utils.valueToCurrency(that.get('order_tax')),

      discount: App.Utils.valueToCurrency(that.get('order_discount')),

      orderDay:
        that.get('order_created_at') &&
        App.Utils.makeTimestampHuman(
          that.get('order_created_at'),
          C.DateFormats.LongNoTime
        ),

      flags: that.flags(),
    });
    return presented;
  },

  flags: function (fields) {
    var that = this;
    fields = fields || this.attributes;
    var flags = '';
    if (fields.spec) {
      flags += C.FlagBadges.Spec;
    }
    if (fields.consignment) {
      flags += C.FlagBadges.Consignment;
    }
    return flags;
  },

  toDataTableRowArray: function () {
    var that = this;
    var item = this.toPresenterJSON();
    var isPO = this.isPO();
    var row = [
      item.oid,
      item.customer,
      item.date,
      item.state,
      item.total,
      item.balance,
      item.reference,
      item.flags,
      item.viewLink,
    ];
    return row;
  },

  toJSON: function () {
    var that = this;
    var notes,
      json = {};

    var that = this;
    _.each(
      [
        'id',
        'buyer_id',
        'seller_id',
        'client_id',
        'tax',
        'parent_order_id',
        'billing_address_id',
        'additional_expense',
        'service_fee',
        'shipping',
        'consignment',
        'move_consigned_tickets',
        'oid',
        'order_additional_expense',
        'order_cost_total',
        'order_created_at_date',
        'order_placer_id',
        'order_price_sub_total',
        'order_shipping',
        'order_fee',
        'order_service_fee',
        'order_tax',
        'order_discount',
        'order_price_total',
        'discount',
        'ticket_hold_id',
      ],
      function (key) {
        json[key] = that.get(key);
      }
    );

    json.seller_reference_number = that.get('reference');

    notes = this.get('notes');
    if (notes) {
      if (notes.internal_notes) {
        json.internal_notes = notes.internal_notes;
      }
      if (notes.external_notes) {
        json.instructions = notes.external_notes;
      }
    }

    // If this is a buySell it needs to set the buy_data flag
    var buyData = this.get('buy_data') ? true : false;
    var buySellQuantity = this.get('buySellQuantity')
      ? this.get('buySellQuantity')
      : null;

    if (this.get('ticketGroup')) {
      json.items = [
        {
          price: this.get('individual_price'),
          retail_price: this.get('retail_price'),
          quantity: buySellQuantity ? buySellQuantity : this.get('quantity'),
          ticket_group_id: this.get('ticketGroup').get('id'),
          low_seat: buyData
            ? this.get('buy_data').shipped_items[0].items[0].low_seat
            : this.get('low_seat'),
          seat_order: buyData
            ? this.get('buy_data').shipped_items[0].items[0].seat_order
            : null,
        },
      ];
    } else if (this.get('items')) {
      json.items = [];
      _.each(this.get('items'), function (item) {
        var item = {
          price: item.individual_price,
          quantity: '' + item.quantity, // Other orders submit this as a string.  Just to be safe let's copy.
          ticket_group_id: item.ticketGroup && item.ticketGroup.id,
          low_seat: item.low_seat,
          seat_order: item.seat_order,
        };
        json.items.push(item);
      });
    }

    if (this.get('shipment')) {
      this.get('shipment').items = json.items;
    }

    // Subbed orders don't have any shipped items, so we need to
    // send over an empty array
    if (this.get('substitute_order_link_id')) {
      json.shipped_items = [{ items: json.items }];
    } else {
      json.shipped_items = [this.get('shipment')];
    }
    delete json.items;
    var billingAddress = this.get('billing_address');
    if (billingAddress) {
      json.billing_address = billingAddress;
    }

    var substitute_order_link_id = this.get('substitute_order_link_id');
    if (substitute_order_link_id) {
      json.substitute_order_link_id = substitute_order_link_id;
    }

    if (this.get('buy_data')) {
      json.buy_attributes = this.get('buy_data');
    }

    json.payments = this.get('payments');
    if (json.payments) {
      payments = json.payments.models ? json.payments.models : json.payments;
      _.each(payments, function (payment) {
        payment.amount = that.total();
      });
    }

    if (this.get('credit_card_id')) {
      json.payments[0].credit_card_id = this.get('credit_card_id');
    }

    if (this.cartId) {
      json.cart_id = this.cartId;
    }

    return json;
  },

  parse: function (orderData) {
    var that = this;
    var modelizeAttribute = function (attributeKey, ModelConstructor) {
      var value = orderData[attributeKey];
      orderData.new_payments = [];
      orderData.returns = [];
      if (value) {
        orderData[attributeKey] = new ModelConstructor(value);
      } else {
        delete orderData[attributeKey];
      }
    };
    modelizeAttribute('client', App.Models.V3.Client);
    modelizeAttribute('billing_address', App.Models.V3.Address);
    modelizeAttribute('shipping_address', App.Models.V3.Address);
    _.collect(orderData.payments, function (payment) {
      if (payment.type == 'return' || payment.type == 'penalty') {
        return orderData.returns.push(new App.Models.Return(payment));
      } else {
        return orderData.new_payments.push(new App.Models.Payment(payment));
      }
    });
    orderData.payments = orderData.new_payments;
    return orderData;
  },

  fetchShipments: function (callbacks) {
    var that = this;
    var shipments, ids, that, fetchedAllShipments, fetchedCount;
    that = this;
    fetchedCount = 0;
    fetchedAllShipments = $.Deferred();

    if (this.get('shipment_snapshot') && this.get('shipment_snapshot').length) {
      ids = _.pluck(this.get('shipment_snapshot'), 'id');

      shipments = $.map(ids, function (id) {
        return new App.Models.Shipment({ id: id });
      });
      $.each(shipments, function (index, shipment) {
        shipment.fetch({
          success: function () {
            ++fetchedCount === shipments.length &&
              fetchedAllShipments.resolve();
          },
        });
      });
      $.when(fetchedAllShipments).then(function () {
        shipments = new App.Collections.ShipmentSnapshots(shipments);
        shipments.each(function (shipment, index) {
          //vars
          var ids_match, id, snapshot_id, snapshot, attr_to_merge;
          id = shipment.get('id');
          snapshot = that.get('shipment_snapshot')[index];
          ids_match = id === snapshot.id;
          attrs_to_merge = [
            'name',
            'company',
            'street_address',
            'extended_address',
            'locality',
            'region',
            'postal_code',
            'country_code',
            'phone_number',
            'email_address',
          ];
          //logic
          if (ids_match) {
            $.each(attrs_to_merge, function (index, attr) {
              shipment.set(attr, snapshot[attr]);
            });
          }
        });
        that.set('shipment_snapshot_collection', shipments);
        callbacks.success();
      });
    }
  },

  validate: function () {
    //////////////////////////////////////////////////////////////////////////
    // NEW VALIDATION
    // MAKE SURE TO PORT ME TO V3 WHEN YOU DESTROY THIS V1 MODEL.
    //////////////////////////////////////////////////////////////////////////

    var that = this;
    that.errors = [];
    var shipment = that.get('shipment');

    var addressId = shipment && shipment.address_id;
    var address = shipment && shipment.address_attributes;
    var email = shipment && shipment.email_address_attributes;
    var localName = shipment && shipment.ship_to_company_name;
    var name = shipment && shipment.ship_to_name;
    var phone = shipment && shipment.phone_number_attributes;
    var rateOption = shipment && shipment.service_type;
    var shipmentType = shipment && shipment.type;
    var signatureType = shipment && shipment.signature_type;
    var trackingNumber = shipment && shipment.tracking_number;
    var existingAirbill = shipment && shipment.existing_airbill;
    var quantity = that.get('quantity');

    var attrs = {
      _addressId: addressId,
      _addressAttributes: address,
      _emailAddressAttributes: email,
      _shipToCompanyName: localName,
      _shipToName: name,
      _phoneNumberAttributes: phone,
      _serviceType: rateOption,
      _type: shipmentType,
      _signatureType: signatureType,
      _trackingNumber: trackingNumber,
      _existingAirbill: existingAirbill,
    };
    var v3ShipmentModel = new App.Models.V3.Shipment(attrs, {
      mapping: C.Mappings.Manual,
    });
    v3ShipmentModel.validateOld();
    that.errors = that.errors.concat(v3ShipmentModel.errors);

    if (that.get('items') && !that.get('items').length) {
      if (!quantity) {
        that.errors.push('You must select a valid quantity.');
      }
    }

    if (!that.get('client_id') && !that.get('buyer_id')) {
      if (that.get('isHold') || that.get('isTaken')) {
        that.errors.push('Order requires a customer.');
      } else {
        that.errors.push('Order requires a buyer.');
      }
    }
  },

  isValid: function () {
    var that = this;
    this.validate();
    return _.isEmpty(this.errors);
  },

  isPO: function () {
    var that = this;
    var buyer = this.get('buyer') || null;

    if (buyer) {
      var buyer_id = buyer.id || null;
      var office_id = SESSION.office_id || null;
      if (buyer_id !== null && office_id !== null && office_id === buyer_id) {
        return true;
      }
    }
    return false;
  },
  //bs_order = getBSOrder()
  //.done(function(order){return order;})
  //.error(function(){ return false;});
  getBSOrder: function () {
    var that = this;
    var order, order_id, def;
    def = $.Deferred();
    if (this.get('child_orders')) {
      order_id = this.get('child_orders')[0];
      order = new App.Models.Order({ id: order_id });
      order
        .fetch()
        .success(function () {
          def.resolve(order);
        })
        .error(function () {
          def.resolve();
        });
      return def;
    }
    return false;
  },
  //getters
  getBuyer: function () {
    var that = this;
    var buyer = this.get('buyer');
    if (buyer) {
      if (buyer.type === 'Office') {
        return new App.Models.V3.Office(buyer);
      }
      return new App.Models.Client(buyer);
    } else {
      //if no order its stuck on the client
      return this.get('client');
    }
  },
  getSeller: function () {
    var that = this;
    var seller = this.get('seller');
    if (seller.type === 'Office') {
      return new App.Models.V3.Office(seller);
    }
    return new App.Models.Client(seller);
  },
  customer: function () {
    var that = this;
    // defer this V2 method to the old method until we transition completely off V1
    this.getPatron();
  },
  getPatron: function () {
    var that = this;
    var patron = this.isPO() ? this.get('seller') : this.get('buyer');

    if (this.get('patron_type') === 'Client') {
      patron = new App.Models.Client(patron);
      patron.isClient = true;
      patron.isOffice = false;
    } else {
      patron = new App.Models.V3.Office(patron);
      patron.isClient = false;
      patron.isOffice = true;
    }
    return patron;
  },
  getAddress: function () {
    var that = this;
    var billing_address = this.get('billing_address');
    return billing_address.id ? billing_address : this.getPatron().address();
  },
  events: function () {
    var that = this;
    if (this.get('items') && this.get('items').length !== 0) {
      var events = {};
      var offices = {},
        office_id;

      this.get('items').forEach(function (item) {
        if (!events[item.ticket_group.event.id]) {
          var event = $.extend(true, { offices: {} }, item.ticket_group.event);
          events[event.id] = event;
        }
        office_id = item.ticket_group.office
          ? item.ticket_group.office.id
          : item.ticket_group.office_id;
        if (!events[item.ticket_group.event.id].offices[office_id]) {
          var office = $.extend(
            true,
            { ticket_groups: [] },
            item.ticket_group.office
          );
          events[item.ticket_group.event.id].offices[office_id] = office;
        }

        var ticketGroup = $.extend(true, {}, item.ticket_group);
        delete ticketGroup.event;
        delete ticketGroup.office;
        events[item.ticket_group.event.id].offices[
          office_id
        ].ticket_groups.push(ticketGroup);
      });

      sorted = _.values(events).sort(function (a, b) {
        return a.occurs_at > b.occurs_at;
      });
      var response = _.map(sorted, function (event) {
        return new App.Models.V3.Event(event);
      });
      return response;
    } else {
      return [];
    }
  },
  headline: function () {
    var that = this;
    return (
      '#' +
      this.id +
      ' ' +
      this.recipientName() +
      ' ' +
      moment(this.get('created_at').slice(0, -1))
        .add('s', SESSION.office_tz)
        .calendar()
    );
  },
  shipments: function (options) {
    var that = this;
    var shipments = new App.Collections.Shipments();
    shipments.order = this;

    shipments.fetch({
      success: function (shipments) {
        options.success(shipments);
      },
    });
  },

  payments: function (options) {
    var that = this;
    var payments = new App.Collections.Payments(this.get('payments'), {
      order: this,
    });
    payments.order = this;

    payments.fetch({
      success: function (payments) {
        options.success(payments);
      },
    });
  },

  allPayments: function () {
    var that = this;
    var allPaymentsTotal = this.get('payments');
    if (payments) {
      payments = _.inject(
        payments,
        function (sum, payment) {
          return sum + parseFloat(payment.get('amount'));
        },
        0
      );
    }

    return allPaymentsTotal;
  },
  poBalance: function () {
    var that = this;
    if (this.poSubTotal()) {
      return this.poSubTotal() - this.get('completed_payments_total');
    }
  },
  poCost: function () {
    var that = this;
    var items = this.get('items');
    var cost = _.inject(
      items,
      function (sum, item) {
        return sum + parseFloat(item.cost);
      },
      0
    );
    return cost;
  },
  poSubTotal: function () {
    var that = this;
    this.get('subtotal');
  },

  onlyCardPayments: function () {
    var that = this;
    var payments = this.get('payments'),
      invalidStates = ['refunded', 'voided'];

    return _.filter(payments.models, function (payment) {
      return (
        payment.get('type') === 'credit_card' &&
        !_.contains(invalidStates, payment.get('state')) &&
        payment.get('is_refund') === false
      );
    });
  },

  subTotal: function () {
    var that = this;
    var subTotal;
    if (this.get('items')) {
      var items = this.get('items');
      subTotal = _.inject(
        items,
        function (sum, item) {
          if (item.individual_price) {
            return sum + item.individual_price.replace(',', '') * item.quantity;
          }
        },
        0
      );
    } else if (this.get('buy_data')) {
      var shipped_items_item =
        this.get('buy_data').shipped_items &&
        this.get('buy_data').shipped_items[0] &&
        this.get('buy_data').shipped_items[0].items &&
        this.get('buy_data').shipped_items[0].items[0] &&
        this.get('buy_data').shipped_items[0].items[0]; //this is for buy sell changed price
      subTotal =
        +shipped_items_item.retail_price * +this.get('quantity') || null;
    } else {
      subTotal = +this.get('individual_price') * +this.get('quantity') || null;
    }

    return subTotal;
  },

  tax: function () {
    var that = this;
    return this.get('tax') || null;
  },
  additionalExpenses: function () {
    var that = this;
    return this.get('additional_expense') || null;
  },
  serviceFee: function () {
    var that = this;
    return this.get('service_fee') || null;
  },
  shipping: function () {
    var that = this;
    return this.get('shipping') || null;
  },

  discount: function () {
    var that = this;
    var flat = this.get('discount_flat');
    var discount = 0;
    if (flat) {
      discount = flat;
    } else {
      var subTotal = this.subTotal();
      if (subTotal) {
        var perc = this.get('discount_percent');
        if (perc) {
          var newSubTotal = subTotal * (1 - perc / 100);
          newSubTotal = Math.round(newSubTotal * 100) / 100; // round 2 decimal places.
          var disc = subTotal - newSubTotal;
          disc = Math.round(disc * 100) / 100;
          discount = disc;
        }
      }
    }

    this.set('discount', discount);

    return parseFloat(discount);
  },

  total: function () {
    var that = this;
    if (this.subTotal()) {
      var total =
        this.subTotal() +
        parseFloat(this.tax() || 0) +
        parseFloat(this.additionalExpenses() || 0) +
        parseFloat(this.serviceFee() || 0) +
        parseFloat(this.shipping() || 0) -
        this.discount();

      total = Math.round(total * 100) / 100;

      return total;
    } else {
      return null;
    }
  },

  poTotal: function () {
    var that = this;
    if (this.poSubTotal()) {
      return (
        this.poSubTotal() +
        parseFloat(this.get('tax') || 0) +
        parseFloat(this.get('additional_expense') || 0) +
        parseFloat(this.get('service_fee') || 0) +
        parseFloat(this.get('shipping') || 0)
      );
    } else {
      return null;
    }
  },
  priceDisplay: function () {
    var that = this;
    individual_price = this.get('individual_price');
    if (individual_price && individual_price.substring) {
      individual_price = parseFloat(
        individual_price.replace(',', '').replace('$', '')
      );
    }

    return App.Utils.valueToCurrency(individual_price * +this.get('quantity'));
  },
  recipientName: function () {
    var that = this;
    var buyer = this.get('buyer');
    var client = this.get('client');
    if (buyer) {
      return buyer.name;
    } else if (client) {
      return client.name();
    } else {
      return '';
    }
  },

  showSeats: function () {
    var that = this;
    var lowSeat = parseInt(this.get('low_seat')),
      quantity = parseInt(this.get('quantity'));

    return +lowSeat + ' - ' + (lowSeat + (quantity - 1));
  },

  /*
  seats: function() {
    if(this.has("seats")){
      return this.get("seats");
    }
    var selectionStrategy = {
      Low: function(order) {
        return _.first(this.seats(order), order.get('quantity'));
      },
      High: function(order) {
        return _.last(this.seats(order), order.get('quantity'));
      },
      seats: function(order) {

        var tg = order.get('ticketGroup');
        if (tg instanceof App.Models.TicketGroup) {
          var seats = tg.get('tickets');
        } else {
          var seats = tg.get('_tickets');
        }

        seats = App.Utils.showAvailableSeats(seats);
        return seats.sort(function (a,b) {
          return +a - +b;
        });

      }
    };

    return selectionStrategy[this.get('seat_selection')](this);
  },
  */

  seatsDisplay: function () {
    var that = this;
    // Get Low vs. High selection preference.
    var selectionPreference = this.get('seat_selection');
    // Get seats from the TG.
    var tg = this.get('ticketGroup');
    if (tg instanceof App.Models.TicketGroup) {
      var seats = tg.get('tickets');
    } else {
      var seats = tg.get('_tickets');
    }
    // Filter down to available only, and sort by seat number.
    seats = App.Utils.showAvailableSeats(seats);
    seats.sort(function (a, b) {
      return +a - +b;
    });
    // Grab quantity seats.
    if (selectionPreference == 'Low') {
      seats = seats.slice(0, this.get('quantity')); // Grab first n.
    } else {
      // Default to high.
      seats = seats.slice(0 - this.get('quantity')); // Grab last n.
    }
    // Format for display.
    var seatRanges = App.Utils.seatsToSeatRange(seats);
    return seatRanges.length ? seatRanges.join(', ') : '';
  },

  getEvents: function () {
    var that = this;
    var evenTs;
    //pluck out evenTs and make them models
    if (this.get('items')) {
      evenTs = _.map(this.get('items'), function (i) {
        return i.ticket_group && new App.Models.V3.Event(i.ticket_group.event);
      });
      //grab only unique children
      evenTs = _.groupBy(evenTs, function (evenT) {
        return evenT.id;
      });
      evenTs = _.map(_.toArray(evenTs), function (arr) {
        return arr[0];
      });
      evenTs.sort(function (a, b) {
        return a.get('occurs_at') > b.get('occurs_at');
      });
    }
    return evenTs;
  },
  //Server Comms

  onShipmentSnapshotCollectionChange: function () {
    var that = this;
    var shipmentColl = that.get('shipment_snapshot_collection');
    var pendingVals = shipmentColl && shipmentColl.pluck('state');
    that.set('has_pending_shipments', _.contains(pendingVals, C.Pending));
    that.set(
      'can_add_to_shipments',
      _.contains(pendingVals, 'cancelled') ||
        _.contains(pendingVals, 'returned')
    );
  },
  onClientIdChange: function () {
    var that = this;
    this.fetchClient();
  },

  fetchClient: function () {
    var that = this;
    var clientId = this.get('client_id');
    if (clientId) {
      var client = new App.Models.V3.Client(
        {
          id: clientId,
        },
        {
          mapping: C.Mappings.Direct.Clients.Client,
        }
      );

      client
        .fetchPromise()
        .then(function (model) {
          that.set('client', client);
        })
        .fail(function (error) {})
        .done();

      var clientCreditCards = new App.Collections.CreditCards(null, {
        clientId: clientId,
      });
      clientCreditCards.fetch({
        success: function () {
          that.set('clientCreditCards', clientCreditCards);
        },
      });
    }
  },

  fetchPayments: function () {
    var that = this;
    var payments = new App.Collections.Payments(null, { order: this });

    payments.on(
      'reset',
      _.bind(function () {
        this.set('payments', payments);

        _.each(payments.models, function (payment) {
          payment.set('order', that);
        });
      }, this)
    );

    return payments.fetch();
  },
  summary: function () {
    var that = this;
    if (!this.id) {
      return '';
    }

    var msg =
      'Order ID: ' +
      this.id +
      '\nCustomer Name: ' +
      this.get('client').get('name') +
      '\nTotal: ' +
      this.get('total');
    var firstPayment = this.get('payments')[0];

    if (firstPayment.type === 'credit_card') {
      msg +=
        '\nAVS response: ' +
        firstPayment.avs_response +
        '\nCVV response: ' +
        firstPayment.cvv_response;
    }

    return msg;
  },
  avsString: function () {
    var that = this;
    return _.compact(
      that.get('payments').map(function (p) {
        if (p.get('avs_response')) {
          return (
            p.get('avs_response') + ' (' + p.get('avs_response_code') + ')'
          );
        }
      })
    );
  },
  cvvString: function () {
    var that = this;
    return _.compact(
      this.get('payments').map(function (p) {
        if (p.get('cvv_response')) {
          return (
            p.get('cvv_response') + ' (' + p.get('cvv_response_code') + ')'
          );
        }
      })
    );
  },
  addNote: function (note) {
    var that = this;
    var notes = [];
    notes.push(note);
    return $.post(this.url() + '/notes', { notes: notes });
  },
  printUrl: function () {
    var that = this;
    return this.url() + '/print';
  },
  printUrlPO: function () {
    var that = this;
    return this.url() + '/print?purchase_order=true';
  },
  sendEmails: function (emails, opts) {
    var that = this;
    var args;
    if (opts && opts.shipment) {
      return opts.shipment.emailAirbill(emails);
    }

    args = this.isPO()
      ? { recipients: emails, purchase_order: true }
      : { recipients: emails };
    return $.post(this.url() + '/email', args);
  },

  pendToSeller: function (pendData, paymentType, note) {
    var that = this;
    var post = $.post(this.url() + '/pend_to_seller', {
      order_item_links: pendData,
      pos: true,
      'payments[]': paymentType,
      seller_type: 'Office',
      note: note,
    });

    post.done(function (response) {
      that.attributes = that.parse(response);
    });

    return post;
  },
  acceptOrder: function (move_consigned_tickets) {
    var that = this;
    var params, post;
    params = move_consigned_tickets
      ? { move_consigned_tickets: move_consigned_tickets }
      : {};
    post = $.post(this.url() + '/accept', params);
    that = this;
    post.done(function (response) {
      that.attributes = that.parse(response);
    });

    return post;
  },
  rejectOrder: function () {
    var that = this;
    var rejection_reason = this.get('rejection_reason'),
      rejection_notes = this.get('rejection_notes') || false,
      that = this;

    rejection_reason = rejection_reason
      ? 'rejection_reason=' + rejection_reason + ''
      : '';
    rejection_notes = rejection_notes
      ? '&rejection_notes=' + rejection_notes + ''
      : '';

    (post = $.post(
      this.url() + '/reject?' + rejection_reason + rejection_notes
    )),
      post.done(function (response) {
        that.attributes = that.parse(response);
      });

    return post;
  },
  cancelOrder: function () {
    var that = this;
    var cancellation_reason = this.get('cancellation_reason'),
      cancellation_notes = this.get('cancellation_notes') || false,
      that = this;

    cancellation_reason = cancellation_reason
      ? 'cancellation_reason=' + cancellation_reason + ''
      : '';
    cancellation_notes = cancellation_notes
      ? '&cancellation_notes=' + cancellation_notes + ''
      : '';

    post = $.post(
      this.url() + '/cancel?' + cancellation_reason + cancellation_notes
    );

    post.done(function (response) {
      that.attribtues = that.parse(response);
    });

    return post;
  },
  childOrders: function (reload) {
    var that = this;
    if (reload) {
      delete this.child_orders;
    }

    if (this.child_orders) {
      return this.child_orders;
    } else {
      this.child_orders = new App.Collections.Orders(
        _.collect(this.get('child_orders'), function (cid) {
          if (that.id !== cid) {
            return new App.Models.Order({ id: cid });
          }
        })
      );

      return this.child_orders;
    }
  },
  fetchChildOrders: function () {
    var that = this;
    var deferred, childOrderFetches;

    deferred = $.Deferred();

    if (!this.cannot_view_children()) {
      childOrderFetches = _.collect(this.childOrders().models, function (
        childOrder
      ) {
        return childOrder.fetch();
      });
    }

    $.when.apply(null, childOrderFetches).done(function () {
      deferred.resolve();
    });

    return deferred;
  },

  cannot_view_children: function () {
    var that = this;
    return this.isPO() && this.get('buyer').id === SESSION.office_id;
  },

  updateSoldTicketCosts: function (tickets, order_item_id) {
    //pass in tickets [{id:n, cost:n},{id:n, cost:n}]
    //OPTIONAL pass in order_time and it will filter only the order_item for response
    var that = this;
    var model, oid, url, data, done, error;
    model = this;
    oid = order_item_id;
    url = this.url() + '/update_ticket_costs';
    data = JSON.stringify({ tickets: tickets });
    done = function (result) {
      model.order_item = _.filter(result.order_items, function (oi) {
        return oi.id === oid;
      })[0];
    };
    error = function (result) {
      //         //TODO handle this error
    };
    post = $.ajax({
      type: 'post',
      url: url,
      data: data,
      dataType: 'json',
      contentType: 'application/json',
    })
      .done(done)
      .error(error);
    return post;
  },

  canBeSubstituted: function () {
    var that = this;
    var can = true;
    if (this.get('substitute_order_link_id')) {
      can = false;
    }
    if (
      !(
        this.get('seller_state') == C.Rejected ||
        this.get('seller_state') == C.Canceled ||
        this.get('seller_state') == C.CanceledOpen ||
        this.get('seller_state') == C.CanceledPostAcceptance
      )
    ) {
      can = false;
    }
    return can;
  },
});
