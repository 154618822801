module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div class="clientFormContainer">\n  <input placeholder="Search by Customer Name" class="patronAutocomplete" type="text" name="patronAutocomplete" value="<%= name %>" />\n  <button class="addClientButton btn btn-success" style="background: #5FB75D; color: #FFF;" title="New Client">\n    <i class="fa-solid fa-plus" style="margin-right: 10px;"></i>\n    Add New Customer\n  </button>\n  <div>\n', __filename = "js/views/buy_sell/patron_autocomplete.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div class="clientFormContainer">\n  <input placeholder="Search by Customer Name" class="patronAutocomplete" type="text" name="patronAutocomplete" value="');
            __line = 2;
            __append(escapeFn(name));
            __append('" />\n  <button class="addClientButton btn btn-success" style="background: #5FB75D; color: #FFF;" title="New Client">\n    <i class="fa-solid fa-plus" style="margin-right: 10px;"></i>\n    Add New Customer\n  </button>\n  <div>\n');
            __line = 8;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}