App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V2.TicketHold = require('../../models/v2/ticket_hold_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V2.TicketHolds = App.Collections.Base.BaseCollection.extend

  model: App.Models.V2.TicketHold

  url: () ->
    return "/api_direct/v9/events/#{ @eventId }/ticket_groups/#{ @ticketGroupId }/ticket_holds"

  initialize: (models, options) ->
    parent.initialize.call(@, models, options)
    {
      @eventId
      @ticketGroupId
    } = options