App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V3.Email = require('../../models/v3/email_model.coffee')

parent = App.Collections.Base.PaginatedBase.prototype
module.exports = App.Collections.V3.Emails = App.Collections.Base.PaginatedBase.extend

  model: App.Models.V3.Email

  url: '/api_direct/v9/emails'

  parse: (response, options) ->
    if (response.emails)
      return response.emails
    else
      return response