template = require('./cancel_order_modal.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Order.CancelOrderModal = App.Views.Base.BaseView.extend

  template: template

  className: 'modal hide fade'

  initialize: (options = {}) ->
    {
      @isPOView
      @v3OrderModel
      @overviewView
    } = options
    @render()

  render: () ->

    if (
      @v3OrderModel.get('_sellerState') == C.CancelProposed ||
      @v3OrderModel.get('_buyerState') == C.CancelProposed
    )
      isConfirmationOnly = true

    cancellationNotes = @v3OrderModel.get('_buyerCancellationNotes')
    cancellationReason = @v3OrderModel.get('_buyerCancellationReason')
    if (@v3OrderModel.isPO())
      cancellationNotes = @v3OrderModel.get('_sellerCancellationNotes')
      cancellationReason = @v3OrderModel.get('_sellerCancellationReason')

    data = {
      cancellationNotes
      cancellationReason
      isConfirmationOnly
    }
    @$el.html(
      @template(data)
    )
    # STANDARDIZED MODAL
    @$el.modal('show').on('hidden', () => @remove())
    @loadingBox = new App.Utils.LoadingBox(@$el)
    @formErrors = new App.Utils.FormErrorManager(@$('.formErrors'))

  events:
    'click #proposeCancelButton' :      'onProposeCancelButtonClick'
    'click #confirmCancelOrderButton' : 'onConfirmCancelOrderButtonClick'

  onProposeCancelButtonClick: (e) ->
    cancellationReason = @$el.find('#cancellationReasonInput').val()
    cancellationNotes = @$el.find('#cancellationNotesInput').val()
    if (cancellationReason.length == 0)
      @formErrors.show({
        title: 'A cancellation reason is required.'
      })
    else
      @formErrors.reset()
      @v3OrderModel.set('_cancellationReason', cancellationReason)
      @v3OrderModel.set('_cancellationNotes', cancellationNotes)
      @cancelOrder()

  onConfirmCancelOrderButtonClick: (e) ->
    @v3OrderModel.set('_cancellationReason', @v3OrderModel.get('_buyerCancellationReason'))
    if (@v3OrderModel.isPO())
      @v3OrderModel.set('_cancellationReason', @v3OrderModel.get('_sellerCancellationReason'))
    @cancelOrder()

  cancelOrder: () ->
    App.ErrorManager.ignoreNext(422)
    @loadingBox.startLoad()
    @v3OrderModel.cancelPromise()
    .then (model) =>
      App.ErrorManager.resetTolerance(422)
      @loadingBox.stopLoad()
      @$el.modal('hide')

      if (@isPOView)
        finishPromise = @overviewView.renderPOsPromise()
      else
        finishPromise = @overviewView.refresh()

      finishPromise
      .then () =>
        message = 'The tickets from this order are now available in your system but have been UNBROADCASTED.'
        if (@v3OrderModel.isPO())
          message = 'The Tickets from this Purchase Order have been removed from your system.'
        @overviewView.showFeedback({
          type: 'success'
          title: 'Order canceled successfully.'
          message
        })

    .fail (errors) =>
      App.ErrorManager.resetTolerance(422)
      @loadingBox.stopLoad()
      @formErrors.show({
        title:  "Error cancelling order."
        errors
      })
    .done()

#      @orderShowView.fullRefresh()
#      .then () =>
#
#    .fail (xhr) =>
#      loadingBox.stopLoad()
#      errorText = JSON.parse(xhr.responseText).error
#      @$el.find('.cancel-errors').html(errorText).show()
#      App.ErrorManager.resetTolerance(422)
#    .done()
