// DEATHROW
App.Collections.Base.PaginatedBase = require('./base/paginated_base_collection.coffee');
App.Models.Order = require('../models/order_model');

module.exports = App.Collections.Orders = App.Collections.Base.PaginatedBase.extend({

  model: App.Models.Order,

  parentWrapper: null,

  ordersOrPOs: null,

  isSalesReport: false,

  url: function() {

    // ORDERS screen
    if (this.ordersOrPOs) {
      var urls = {
        orders: '/orders.json?type=Order',
        pos: '/orders?type=PurchaseOrder'
      };
      return urls[this.ordersOrPOs];

    // SALES REPORT
    } else if (this.isSalesReport) {
      return '/api_direct/v9/reports/sales?aggregates=true';

    // DEFAULT ORDER BEHAVIOR
    } else {
      return '/orders';
    }
  },

  initialize: function(models, options) {
    var options = options || {};
    this.parentWrapper = options.parentWrapper;
    //Flag to determine if we want to pull shipments with the order
    this.ignoreShipments = options.ignoreShipments;

    this.ordersOrPOs = options.ordersOrPOs;
    this.isSalesReport = options.isSalesReport;

    App.Collections.Base.PaginatedBase.prototype.initialize.apply(this, models, options);
  },

  toPresenterJSON: function(options) {
    return _.map(this.models, function(model) {
      return model.toPresenterJSON(options);
    });
  },

  parse: function(json, options) {
    var that = this;

    // Set the ignore shipments flag on each order response
    _.each(json.orders, function(order) {
      order.ignoreShipments  = that.ignoreShipments;
    });

    if (this.ordersOrPOs ) {
      var jsonCopyWithoutOrders = _.pick(json, 'total_entries', 'total_sum', 'balance_sum');
      this.parentWrapper.set(jsonCopyWithoutOrders);
      var json = json.orders;
      return json;} // pretend the JSON was originally just an array of orders.
    else if ( this.showList/*just set it manually in the view*/ ){ return json.orders }
    else { return json; }
  },

// PAP BUG
// Why is this get overridden?
// I have no idea how this is designed, just making it work.
  get: function(model, options) {

    if (this.ordersOrPOs || this.isSalesReport || this.ignoreShipments) {
      return Backbone.Collection.prototype.get.call(this, model); // model is really id.

    // WARNING - this default behavior is weird & causes a million network calls when using an OrderCollection.
    } else {

      var id = model.get('id');

      var order = Backbone.Collection.prototype.get.call(this, id);
      options = options || {};
      if (order && options.success) {
        options.success(order);
      } else if (order) {
        return order;
      } else {
        order = new this.model({id: id});
        var that = this;

        order.fetch({
          success: function(order) {
            that.add(order);
            if (options.success) {
              options.success(order);
            }
          },
          error: options.error
        });
      }

    }
  }
});

