App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.EvoPayTransaction = App.Models.Base.V3BaseModel.extend

  urlRoot: () ->
    return "/api_direct/v9/accounts/#{ SESSION.office_id }/transactions"

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.EvoPayTransactions.EvoPayTransaction)
      $.noop()

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      stateSortPriority: @stateSortPriority()

    })
    return presented

  stateSortPriority: () ->
    state = @get('state')
    switch (state)
      when 'canceled'
        return 2
      when 'completed'
        return 2
      when 'exported'
        return 2
      when 'pending'
        return 1
      else
        return 2
  #///////////////////////////////////////////////////////////////////////////

  toJSON: () ->
    attrs = @attributes
    json = {
      amount:         attrs._amount
      credit_card_id: attrs._creditCardId
      type:           attrs._type
    }
    # Wrap it in an array cuz rails is gross.
    json = {
      transactions: [ json ]
    }
    return json