App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.TaxQuote = App.Models.Base.V3BaseModel.extend

  urlRoot: '/api_direct/v9/tax_quotes'

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.TaxQuote.TaxQuote)
      # Request attributes
      attrs._ticketGroupId = json.ticketGroupId
      attrs._quantity = json.quantity
      attrs._paymentType = json.paymentType
      # Response attributes
      attrs._taxSignature = json.tax_signature
      attrs._wholesale = json.wholesale
      attrs._retail = json.retail
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  toJSON: (options = {}) ->
    json = {}
    json.ticket_group_id = @get('_ticketGroupId')
    json.quantity = @get('_quantity')

    if (@get('_paymentType') == C.CreditCard)
      json.payment_type = @get('_paymentType')

    if (_retail = @get('_retail'))
      json.retail = {}
      json.retail.price = _retail.price
      json.retail.additional_expense = _retail.additionalExpense
      json.retail.service_fee = _retail.serviceFee
      json.retail.shipping = _retail.shipping
      json.retail.discount = _retail.discount

    return json

# PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    return {
      taxSignature: @get('_taxSignature')
      wholesaleTax: @get('_wholesale')?.tax
      retailTax:    @get('_retail')?.tax
    }
