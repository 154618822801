availableTemplate = require('./ticket_groups_table.ejs')
soldTemplate = require('./sold_items_table.ejs')
dataTableAdditions = require('vendor/jquery-dataTables-plugins')

App.Views.Base.BaseView = require('../base/base_view.coffee')

AVAILABLE_OR_SOLD = {
  AVAILABLE: 1
  SOLD: 2
}
TICKETS_OR_PARKING = {
  TICKETS: 1
  PARKING: 2
}
MY_OR_ALL_TICKETS = { # match up with @myOrAll strings
  MY: 'my'
  ALL: 'all'
}

AVAILABLE_DATATABLE_OPTIONS = {
  bJQueryUI: true
  sDom: '<"">t<"F"p>'
  bScrollInfinite: true
  bScrollCollapse: false
  iDisplayLength: 500
  sScrollY: '700px'
  autoWidth: false
  aaSorting: [
    [ 0, 'asc'] # Cheapest wholesale.  Use the hidden column so in isEditPriceTable, the order remains the same despite the input boxes.
  ]
  oLanguage: {
    sSearch: 'Filter Results: '
    sEmptyTable: 'No Tickets Found'
  }
  aoColumns: [
    { bVisible: false, sType: 'currency' } # 0 - HIDDEN WHOLESALE column to sort on so price edit mode doesn't screw up sorting (since input boxes move to top rows).
    { width: '7%' }                       # 1 - ACTION BUTTON
    { width: '7%' }                       # 2 - QUANTITY
    { width: '8%', sType: 'natural-ci' }  # 3 - SECTION
    { width: '7%', sType: 'natural-ci' }  # 4 - ROW
    { width: '7%' }                       # 5 - SEATS
    { width: '11%', sType: 'currency' }   # 6 - COST
    { width: '11%', sType: 'currency' }   # 7 - WHOLESALE
    { width: '11%', sType: 'currency' }   # 8 - PURCHASE PRICE
    { width: '11%', sType: 'currency' }   # 9 - RETAIL
    { width: '15%' }                      # 10 - BROKER
    { width: '5%' }                       # 11 - FLAGS
  ]
}

SOLD_DATATABLE_OPTIONS = {
  bJQueryUI: true
  sDom: '<"">t<"F"p>'
  bScrollInfinite: true
  bScrollCollapse: false
  iDisplayLength: 500
  sScrollY: '700px'
  aaSorting: [
    [ 8, 'desc'] # Latest sales.
  ]
  oLanguage: {
    sSearch: 'Filter Results: '
    sEmptyTable: 'No Sold Items Found'
  }
  aoColumns: [
    null
    null
    { sType: 'natural-ci' }
    { sType: 'natural-ci' }
    null
    { sType: 'currency' }
    null
    { sType: 'date' }
    { sType: 'date' }
  ]
}

LESS_COLS_DATATABLE_OPTIONS = {
  aaSorting: [
    [ 0, 'asc'] # Cheapest wholesale.  Use the hidden column so in isEditPriceTable, the order remains the same despite the input boxes.
  ]
  aoColumns: [
    { bVisible: false, sType: 'currency' }  # 0 - HIDDEN WHOLESALE
    null                                    # 1 - ACTION BUTTON
    null                                    # 2 - QUANTITY
    { sType: 'natural-ci' }                 # 3 - SECTION
    { sType: 'natural-ci' }                 # 4 - ROW
    null                                    # 5 - SEATS
    { sType: 'currency' }                   # 6 - WHOLESALE
    { sType: 'currency' }                   # 7 - PURCHASE PRICE
    { sType: 'currency' }                   # 8 - RETAIL
    null                                    # 9 - BROKER
    null                                    # 10 - FLAGS
  ]
}

copyOfAoColumns = (aoColumns) ->
  copy = []
  for col in aoColumns
    if (!col)
      copy.push(null)
    else
      copy.push({
        sType: col.sType
        width: col.width
        bVisible: col.bVisible
      })
  return copy

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.TicketGroups.TicketGroupsTable = App.Views.Base.BaseView.extend

  availableTemplate: availableTemplate

  soldTemplate: soldTemplate

  initialize: (options = {}) ->
    {
      @isPriceEditTable
      @filterView
      @$container
      @showLessColumns
      @toggleSelections
      @collection
    } = options
    @render()

  render: () ->
    if (@toggleSelections.AVAILABLE_OR_SOLD == AVAILABLE_OR_SOLD.AVAILABLE)
      dataTableOptions = _.extend({}, AVAILABLE_DATATABLE_OPTIONS)
      if (@showLessColumns)
        dataTableOptions = _.extend({}, dataTableOptions, LESS_COLS_DATATABLE_OPTIONS)
    else if (@toggleSelections.AVAILABLE_OR_SOLD == AVAILABLE_OR_SOLD.SOLD)
      dataTableOptions = _.extend({}, SOLD_DATATABLE_OPTIONS)

    # Prevent array modifications to originals (from slice()'s below).
    dataTableOptions.aoColumns = copyOfAoColumns(dataTableOptions.aoColumns)

    if (@toggleSelections.AVAILABLE_OR_SOLD == AVAILABLE_OR_SOLD.AVAILABLE)
      data = {
        ticketGroups: @collection.toPresenterJSON({
          @isPriceEditTable
        })
        @isPriceEditTable
        @showLessColumns
      }
      template = @availableTemplate

      # See AVAILABLE_DATATABLE_OPTIONS & LESS_COLS_DATATABLE_OPTIONS above for where these come from.
      brokerIndex = 10
      brokerIndexLessCols = 9

      retailIndex = 9
      retailIndexLessCols = 8

      purchasePriceIndex = 8
      purchasePriceIndexLessCols = 7

      wholesaleIndex = 7
      wholesaleIndexLessCols = 6

      costIndex = 6

      # Careful - must remove from end of the array first.
      if (@showLessColumns)
        if (SESSION.role.cannot(App.Access.Resources.Inventory.AllTickets.ViewDetails.Broker))
          dataTableOptions.aoColumns.splice(brokerIndexLessCols, 1)
        if (SESSION.role.cannot(App.Access.Resources.Inventory.AllTickets.ViewDetails.RetailPrice))
          dataTableOptions.aoColumns.splice(retailIndexLessCols, 1)
        if (SESSION.role.cannot(App.Access.Resources.Inventory.AllTickets.ViewDetails.PurchasePrice))
          dataTableOptions.aoColumns.splice(purchasePriceIndexLessCols, 1)
        if (SESSION.role.cannot(App.Access.Resources.Inventory.AllTickets.ViewDetails.WholesalePrice))
          dataTableOptions.aoColumns.splice(wholesaleIndexLessCols, 1)

      else
        if (SESSION.role.cannot(App.Access.Resources.Inventory.AllTickets.ViewDetails.Broker))
          dataTableOptions.aoColumns.splice(brokerIndex, 1)
        if (SESSION.role.cannot(App.Access.Resources.Inventory.AllTickets.ViewDetails.RetailPrice))
          dataTableOptions.aoColumns.splice(retailIndex, 1)
        if (SESSION.role.cannot(App.Access.Resources.Inventory.AllTickets.ViewDetails.PurchasePrice))
          dataTableOptions.aoColumns.splice(purchasePriceIndex, 1)
        if (SESSION.role.cannot(App.Access.Resources.Inventory.AllTickets.ViewDetails.WholesalePrice))
          dataTableOptions.aoColumns.splice(wholesaleIndex, 1)
        if (SESSION.role.cannot(App.Access.Resources.Inventory.AllTickets.ViewDetails.Cost))
          dataTableOptions.aoColumns.splice(costIndex, 1)

    if (@toggleSelections.AVAILABLE_OR_SOLD == AVAILABLE_OR_SOLD.SOLD)
      data = {
        soldOrderItemsOrWastedTicketGroups: @collection.toPresenterJSON()
        @isPriceEditTable
      }
      template = @soldTemplate

    windowHeight = $(window).height()
    scrollHeight = windowHeight - @$container.offset().top - 90
    dataTableOptions.sScrollY = "#{ scrollHeight }px"
    @$container.html(
      @$el.html(
        template(data)
      )
    )
    # DataTable() returns the DataTable.
    # dataTable() would return the jQuery object.
    @dataTable = @filterView.$('.data-table').DataTable(dataTableOptions)
    dataTableAdditions.addDataTablePlugins()

    if (@isPriceEditTable)
      @collection.forEach((v3TicketGroupModel) =>
        retailPrice = v3TicketGroupModel.get('_priceRetail')
        retailPriceOverride = v3TicketGroupModel.get('_priceRetailOverrideEdited')
        if (!_.isNumber(retailPriceOverride))
          retailPriceOverride = v3TicketGroupModel.get('_priceRetailOverride')
        $container = @$(".retailContainer[data-ticket-group-id=#{ v3TicketGroupModel.id }]")
        retailInput = new App.Utils.RetailInput({
          $container
          retailPrice
          retailPriceOverride
          onChange: @onRetailInputChange
          @isPriceEditTable
        })

        # @collection may be the filteredTicketGroupCollection.
        # Make sure the master v3TicketGroupCollection is also given pointers to the retailInputs
        masterCopyOfTG = @filterView.availableCollection.get(v3TicketGroupModel.id)

        v3TicketGroupModel.retailInput = masterCopyOfTG.retailInput = retailInput
      )

    @$('span.tip-top').tooltip({ placement: 'top' })
    $('.actLikeCurrency').formatCurrency()
    setTimeout(() =>
      @dataTable.draw()
      @$('.dataTables_scrollBody').css({ height: scrollHeight })
    , 0)

  onRetailInputChange: (retailInput)  ->
    $.noop()
#      if  !retailInput.isOverridden
#        @$el.addClass('changed')
#        @$el.removeClass('success')
#        @model.set('retail_price_override', null)
#        @model.save({}, {
#          success: @removeOverrideSuccess
#          error: @saveError
#        })

  getDataTable: () ->
    return @dataTable
