template = require('./list.ejs');
App.Views.Base.FilteredTable = require('../base/filtered_table_view.coffee');

module.exports = App.Views.Payments.List = App.Views.Base.FilteredTable.extend({

  template: template,

  initialize: function(options) {

    var options = options || {};
    this.collection = options.collection;
    this.$container = options.$container;
    this.listenTo(this.collection, 'reset', this.onCollectionReset);
    this.render();
  },

  render: function() {
    var data = {
      payments: this.collection.toJSON()
    };

    var createdOnDatePickerConfig = _.extend({}, C.DatePickerConfig.Past, {
      startDate: moment().startOf('day'),
      endDate: moment().endOf('day')
    });

    this.$el.html(this.template(data));
    this.$container.html(this.$el);
    this.delegateEvents();
    //this.$('input[type=checkbox],input[type=radio],input[type=file]').uniform();

    App.Utils.dateRangePicker(this.$('#created_on_date_range'), this.onCreatedOnDateRangeChanged, this, createdOnDatePickerConfig);
    App.Utils.dateRangePicker(this.$('#completed_on_date_range'), this.onCompletedOnDateRangeChanged, this, C.DatePickerConfig.Past);

    var $content = this.$('#filter_content');
    var $collapseButton = this.$('#filter_collapse_button');
    var options = { $content: $content, $collapseButton: $collapseButton };
    this.collapsibleFilters = new App.Utils.CollapsibleWidget(options);

    this.buttonLoader = new App.Utils.LoadingButton(this.$('#apply_filter_button'));
    this.initializeDropdowns();
    this.initializeAutocomplete();
    this.renderPaymentTable(true);
    this.startLoad();

    return this.$el;
  },

  renderPaymentTable: function(isInitialRender) {
    var that = this;

    if (this.paymentTable) {
      this.paymentTable.remove();
    }
    this.paymentTable = new App.Views.Payments.PaymentTable({
      filterView: that,
      $container: this.$('#payment_table_container'),
      collection: this.collection
    });

    this.tableLoader = new App.Utils.LoadingBox(this.paymentTable.$el);
    if (!isInitialRender) {
      this.stopLoad();
    }
  },


  // VIEW EVENTS /////////////////////////////////////////////////////////////
  events: {
    'click #apply_filter_button': 'onApplyFilterButtonClick',
    'click #reset_filter_button': 'onResetFilterButtonClick'
  },

  onResetFilterButtonClick: function() {
    App.Controllers.paymentController.resetPaymentIndex();
  },

  onApplyFilterButtonClick: function() {
    this.startLoad();
    App.Controllers.paymentController.filterPaymentIndex();
  },

  onCreatedOnDateRangeChanged: function(startDate, endDate) {
    this.createdOnDateRange = { start: startDate, end: endDate };
  },

  onCompletedOnDateRangeChanged: function(startDate, endDate) {
    this.completedOnDateRange = { start: startDate, end: endDate };
  },
  ////////////////////////////////////////////////////////////////////////////

  // COLLECTION EVENTS ///////////////////////////////////////////////////////
  onCollectionReset: function() {
    this.renderPaymentTable();
  },
  ////////////////////////////////////////////////////////////////////////////

  filterParamMappings: function() {
    var that = this;
    // Yes transaction_type & payment_type have their labels flipped.
    // This is not a mistake.
    return {
      created_at: {
        type: C.FilterTypes.DateRange,
        el: '#created_on_date_range',
        val: this.createdOnDateRange
      },
      updated_at: {
        type: C.FilterTypes.DateRange,
        el: '#completed_on_date_range',
        val: this.completedOnDateRange
      },
      transaction_type: {
        type: C.FilterTypes.Select2,
        el: '#payment_type_select',
        ignoreAll: true
      },
      transaction_state: {
        type: C.FilterTypes.Select2,
        el: '#payment_state_select',
        ignoreAll: true
      },
      buyer_name: {
        type: C.FilterTypes.Autocomplete,
        el: '#customer_autocomplete',
      },
      order_link_id: {
        type: C.FilterTypes.Text,
        el: '#order_text'
      },
      transaction_state: {
        type: C.FilterTypes.Select2,
        el: '#payment_state_select',

      },
      transaction_state: {
        type: C.FilterTypes.Select2,
        el: '#payment_state_select',
        ignoreAll: true
      },
      buyer_id: {
        type: C.FilterTypes.FixedValForSelection,
        el: '#order_type_select',
        condition: 'purchase_order',
        val: SESSION.office_id
      },
      seller_id: {
        type: C.FilterTypes.FixedValForSelection,
        el: '#order_type_select',
        condition: 'order',
        val: SESSION.office_id
      },
      payment_type: {
        type: C.FilterTypes.Select2,
        el: '#transaction_type_select',
        ignoreAll: true
      },
      order_by: {
        type: C.FilterTypes.Default,
        val: 'created_at'
      },
      order_direction: {
        type: C.FilterTypes.Default,
        val: 'desc'
      }
    };
  },

  /* Now driven by FilteredTable.
   Leave this here - it's a good reference.
  getFilterParams: function() {

    // Yes transaction_type & payment_type have their labels flipped.
    // This is not a mistake.

    var params = {
      order_by: 'created_at',
      order_direction: 'desc',
      'created_at.gte': Date.today().add({ days: -1 }).toISOString(),
      'created_at.lte': Date.today().add({ days: 1 }).set({ hour: 0, minute: 0, second: 0 }).toISOString(),
      'seller_id': SESSION.office_id
    };

    var p = this.createdOnDateRange;
    if (p && p.start && p.end) {
      params['created_at.gte'] = p.start.toDate().toISOString();
      params['created_at.lte'] = p.end.toDate().toISOString();
    }

    var p = this.completedOnDateRange;
    if (p && p.start && p.end) {
      params['updated_at.gte'] = p.start.toDate().toISOString();
      params['updated_at.lte'] = p.end.toDate().toISOString();
      params['transaction_state'] = 'completed';
    }

    var p = this.$('#payment_type_select').val();
    if (p && p != 'all') {
      params['transaction_type'] = p;
    }

    var p = this.$('#payment_state_select').val();
    if (p && p != 'all') {
      params['transaction_state'] = p;
    }

    var p = this.$('#customer_autocomplete').val();
    if (p) {
      params['buyer_name'] = p;
    }

    var p = this.$('#order_text').val();
    if (p) {
      params['order_link_id'] = p;
    }

    var p = this.$('#order_type_select').val();
    if (p) {
      var isPO = (p == 'purchase_order');
      if (isPO) {
        params['buyer_id'] = SESSION.office_id;
        delete params.seller_id;
      } else {
        params['seller_id'] = SESSION.office_id;
      }
    }

    var p = this.$('#transaction_type_select').val();
    if (p && p != 'all') {
      params['payment_type'] = p;
    }

    this.priorFilterParams = params;
    return params;
  },


  getFilterParamsOfCurrentResults: function() {
    return this.priorFilterParams;
  },
  */

  startLoad: function() {
    this.tableLoader.startLoad();
    this.buttonLoader.startLoad();
  },

  stopLoad: function() {
    this.tableLoader.stopLoad();
    this.buttonLoader.stopLoad();
  },

  initializeAutocomplete: function() {
    var that = this;
    var $el = this.$('#customer_autocomplete');
    var options = {
      $el: $el
    };
    App.Utils.clientOfficeAutocomplete(options);
  },

  initializeDropdowns: function() {

    var paymentTypes = C.Options.Payment.PaymentTypes;
    paymentTypes = _.map(paymentTypes, function(key, val) {
      return { id: val, text: key };
    });
    this.$('#payment_type_select').select2({
      placeholder: 'All',
      data: paymentTypes,
      initSelection: function (element, callback) {
        var data = {
          id: element.val(),
          text: C.Options.Payment.PaymentTypes[element.val()]
        };
        callback(data);
      }
    });

    var orderTypes = C.Options.Payment.OrderTypes;
    orderTypes = _.map(orderTypes, function(key, val) {
      return { id: val, text: key };
    });
    this.$('#order_type_select').select2({
      data: orderTypes,
      // Everything below is required to get a default selection of 'order'
      initSelection: function (element, callback) {
        var data = {
          id: element.val(),
          text: C.Options.Payment.OrderTypes[element.val()]
        };
        callback(data);
      }
    });
    this.$('#order_type_select').select2('val', 'order');

    var paymentStates = C.Options.Payment.States;
    paymentStates = _.map(paymentStates, function(key, val) {
      return { id: val, text: key };
    });
    this.$('#payment_state_select').select2({
      placeholder: 'All',
      data: paymentStates,
      initSelection: function (element, callback) {
        var data = {
          id: element.val(),
          text: C.Options.Payment.States[element.val()]
        };
        callback(data);
      }
    });

    var transTypes = C.Options.Payment.TransactionTypes;
    transTypes = _.map(transTypes, function(key, val) {
      return { id: val, text: key };
    });
    this.$('#transaction_type_select').select2({
      placeholder: 'All',
      data: transTypes,
      initSelection: function (element, callback) {
        var data = {
          id: element.val(),
          text: C.Options.Payment.TransactionTypes[element.val()]
        };
        callback(data);
      }
    });

  }

});