module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<% if (isConfirmationOnly) { %>\n\n  <div class="modal-header">\n    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n    <h3>Confirm Cancel Order</h3>\n  </div>\n\n  <div class="modal-body">\n    <div class="formErrors"></div>\n\n    <p> The other party wants to cancel this order for the following reasons:</p>\n    <div class="alert alert-error">\n      <p>Cancellation Reason: <%= cancellationReason %></p>\n      <p class="cancellation-notes">Cancellation Notes: <%= cancellationNotes %></p>\n    </div>\n\n    <p> Do you want to proceed with the cancel? </p>\n  </div>\n\n  <div class="modal-footer">\n    <a class="btn btn-danger" id="confirmCancelOrderButton">Cancel Order</a>\n  </div>\n\n<% } else { %>\n\n  <div class="modal-header">\n    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n    <h3>Cancel Order</h3>\n  </div>\n\n  <div class="modal-body">\n    <div class="formErrors"></div>\n\n    <label>Seller Cancellation Reason</label>\n    <select id="cancellationReasonInput">\n      <option></option>\n      <option>Tickets No Longer Available</option>\n      <option>Tickets Priced Incorrectly</option>\n      <option>Duplicate Order</option>\n      <option>Fraudulent Order</option>\n      <option>Test Order</option>\n      <option>Other</option>\n    </select>\n\n    <label>Seller Cancellation Notes</label>\n    <textarea rows="5" id="cancellationNotesInput"></textarea>\n  </div>\n\n  <div class="modal-footer">\n    <a class="btn" data-dismiss="modal">Close</a>\n    <a class="btn btn-danger" id="proposeCancelButton">Cancel Order</a>\n  </div>\n\n<% } %>\n\n', __filename = "js/views/order/cancel_order_modal.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            if (isConfirmationOnly) {
                __append('\n\n  <div class="modal-header">\n    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n    <h3>Confirm Cancel Order</h3>\n  </div>\n\n  <div class="modal-body">\n    <div class="formErrors"></div>\n\n    <p> The other party wants to cancel this order for the following reasons:</p>\n    <div class="alert alert-error">\n      <p>Cancellation Reason: ');
                __line = 13;
                __append(escapeFn(cancellationReason));
                __append('</p>\n      <p class="cancellation-notes">Cancellation Notes: ');
                __line = 14;
                __append(escapeFn(cancellationNotes));
                __append('</p>\n    </div>\n\n    <p> Do you want to proceed with the cancel? </p>\n  </div>\n\n  <div class="modal-footer">\n    <a class="btn btn-danger" id="confirmCancelOrderButton">Cancel Order</a>\n  </div>\n\n');
                __line = 24;
            } else {
                __append('\n\n  <div class="modal-header">\n    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n    <h3>Cancel Order</h3>\n  </div>\n\n  <div class="modal-body">\n    <div class="formErrors"></div>\n\n    <label>Seller Cancellation Reason</label>\n    <select id="cancellationReasonInput">\n      <option></option>\n      <option>Tickets No Longer Available</option>\n      <option>Tickets Priced Incorrectly</option>\n      <option>Duplicate Order</option>\n      <option>Fraudulent Order</option>\n      <option>Test Order</option>\n      <option>Other</option>\n    </select>\n\n    <label>Seller Cancellation Notes</label>\n    <textarea rows="5" id="cancellationNotesInput"></textarea>\n  </div>\n\n  <div class="modal-footer">\n    <a class="btn" data-dismiss="modal">Close</a>\n    <a class="btn btn-danger" id="proposeCancelButton">Cancel Order</a>\n  </div>\n\n');
                __line = 54;
            }
            __append("\n\n");
            __line = 56;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}