App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V3.Eticket = require('../../models/v3/eticket_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V3.Etickets = App.Collections.Base.BaseCollection.extend

  model: App.Models.V3.Eticket

  url: () ->
    if (@ticketGroupId)
      return "/api_direct/v9/etickets?ticket_group_id=#{ @ticketGroupId }"
    else
      console.error("Cannot fetch an eticketCollection that doesn't have a ticketGroupId.")

  initialize: (attributes, options) ->
    {
      @ticketGroupId
    } = options
    parent.initialize.call(@, attributes, options)

  parse: (response, options) ->
    return response.etickets