App.Models.Shipment = require('../models/shipment_model');

module.exports = App.Collections.Shipments = Backbone.Collection.extend({

  model: App.Models.Shipment,

  initialize: function (preloads) {
    var deliveries = preloads || [];
    deliveries = _.map(deliveries, function(d) {
      return new App.Models.Shipment(d);
    });
  },

  comparator: function(s){
    return -+s.id;
  },

  url: function() {
    var base = '/shipments?';
    if (this.order.id.to_i > 50000) {
      return base + 'order_link_id=' + this.order.id;
    } else {
      var type;
      if (this.order.get('partner')) {
        type = 'partner_order_id';
      } else if (this.order.get('child_orders')) {
        type = this.order.get('child_orders').length ? 'client_order_id' : 'order_id';
      }
      else
      {
        type = "order_id";
      }
      return base + type + '=' + this.order.id;
    }
  }

});