App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.Email = App.Models.Base.V3BaseModel.extend

  urlRoot: '/api_direct/v9/emails'

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.Email.Email)
      attrs._attachmentContentType = json.attachment_content_type
      attrs._attachmentCustomName = json.attachment_custom_name
      attrs._attachmentFileName = json.attachment_file_name
      attrs._attachmentFileSize = json.attachment_file_size
      attrs._attachmentUpdatedAt = Date.parse(json.attachment_updated_at)
      attrs._bcc = json.bcc
      attrs._cc = json.cc
      attrs._createdAt = Date.parse(json.created_at)
      attrs._htmlBody = json.html_body
      attrs._id = json.id
      attrs._isSuccessful = json.is_successful
      attrs._lockedAt = Date.parse(json.locked_at)
      attrs._orderLinkId = json.order_link_id
      attrs._postmarkBounceResponse = json.postmark_bounce_response
      attrs._postmarkResponse = json.postmark_response
      attrs._provider = json.provider
      attrs._providerResponse = json.provider_response
      attrs._recipients = json.recipients
      attrs._sender = json.sender
      attrs._sentAt = Date.parse(json.sent_at)
      attrs._sentCount = json.sent_count
      attrs._status = json.status
      attrs._subject = json.subject
      attrs._textBody = json.text_body
      attrs._updatedAt = Date.parse(json.updated_at)
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Emails.Email)
      attrs._id = json.id
      attrs._recipients = json.recipients
      attrs._sender = json.sender
      attrs._sentAt = Date.parse(json.sent_at)
      attrs._subject = json.subject
      attrs._orderID = json.order_link_id
      attrs._status = json.status
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  parse: (response, options) ->
    if (response.email)
      return response.email
    else
      return response

# PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    presented = _.extend(@toJSON(), {
      sender:           @get('_sender')
      recipients:       @trimRecipients()
      sentAt:           @get('_sentAt') || ''
      subject:          @get('_subject')
      id:               @get('_id')
      orderID:          @get('_orderID')
      status:           @get('_status')
    })
    return presented

  trimRecipients: () ->
    @get('_recipients').substring(1, @get('_recipients').length-1)

  toDataTableRowArray: () ->
    email = @toPresenterJSON()
    row = [
      "<button class='btn btn-small dropdown-toggle' data-toggle='dropdown' tabindex='-1'><i class='fa-solid fa-plus'/></button>"
      email.recipients
      email.orderID
      email.status
      App.Utils.makeTimestampHuman(email.sentAt, C.DateFormats.TableDate)
      "<a href='/email/#{ email.id }'>#{ email.subject }</a>"
    ]
    return row
