subGroupedTemplate = require ('./sub_grouped_table.ejs')
groupedTemplate = require ('./grouped_table.ejs')
datatableTemplate = require('./datatable.ejs')
module.exports = class App.Exporter

  # http://datatables.net/extras/tabletools/api
  datatableTemplate = datatableTemplate
  groupedTemplate = groupedTemplate
  subGroupedTemplate = subGroupedTemplate

  MULTI_QUOTE_REGEX = /^"*(".*?")"*$/
  HTML_TAG_REGEX = /(<([^>]+)>)/ig
  # Remove anything outside of 0000 -> 00ff
  NON_LATIN1_REGEX = /[\u0100-\uffff]/g
  # http://stackoverflow.com/questions/3143070/javascript-regex-iso-datetime
  ISO8601_REGEX = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/
  UNDERSCORE_REGEX = /_/g

  @extractDataTableInfo: (tableView) ->
    #headings = []
    $table = tableView.getDataTable()

    if ($table instanceof jQuery)
      # DataTable
      data = $table.fnGetData()
      headings = _.map($table.dataTableSettings[0].aoColumns, (aoColumn) ->
        return aoColumn.sTitle
      )

    totals = tableView.exportTotals?()
    filter = @getHumanizedFilters(tableView)
    return {
      headings
      data
      totals
      filter
    }

  @extractGroupedDataTableInfo: (tableView) ->
    headings = tableView.exportHeadings()
    data = tableView.exportData()
    totals = tableView.exportTotals?()
    filter = @getHumanizedFilters(tableView)
    return {
      headings
      data
      totals
      filter
    }

  @extractCollectionInfo: (tableView, collection) ->
    headings = tableView.exportHeadings()
    totals = tableView.exportTotals?()
    filter = @getHumanizedFilters(tableView)
    data = collection.map (model) ->
      return model.toDataTableRowArray()
    return {
      headings
      data
      totals
      filter
    }

  @checkForUnappliedFilter: (tableView) ->
    currentParamsEncoded = tableView?.filterView?.getEncodedFilterParams?()
    appliedParamsEncoded = window.location.href.replace(/.*\/filter\//, '')
    if (currentParamsEncoded == appliedParamsEncoded)
      return true
    else
      warnModal = new App.Views.Shared.BasicModal({
        header: "You have unapplied filters."
        message: "<p>You changed filters but didn't hit apply.</p>
          <p>The exported data would not match the selected filters.</p>
          <p>Please hit <strong>Apply Filter</strong>.</p>"
      })
      return false

  @printDataTable: (tableView, options = {}) ->
    {
      isNotFilteredTable
    } = options
    if (isNotFilteredTable || @checkForUnappliedFilter(tableView))
      tableInfo = @extractDataTableInfo(tableView)
      @print(tableInfo, datatableTemplate, options)

  @printSubGroupedTable: (tableView, options) ->
    if (@checkForUnappliedFilter(tableView))
      tableInfo = @extractGroupedDataTableInfo(tableView)
      @print(tableInfo, subGroupedTemplate, options)

  @printGroupedTable: (tableView, options) ->
    if (@checkForUnappliedFilter(tableView))
      tableInfo = @extractGroupedDataTableInfo(tableView)
      @print(tableInfo, groupedTemplate, options)

  @csvDataTable: (tableView, options) ->
    {
      isNotFilteredTable
    } = options
    if (isNotFilteredTable || @checkForUnappliedFilter(tableView))
      tableInfo = @extractDataTableInfo(tableView)
      @buildCSV(tableInfo, options)

  @csvGroupedTable: (tableView, options) ->
    if (@checkForUnappliedFilter(tableView))
      tableInfo = @extractGroupedDataTableInfo(tableView)
      @buildGroupCSV(tableInfo, options)

  @csvSubGroupedTable: (tableView, options) ->
    if (@checkForUnappliedFilter(tableView))
      tableInfo = @extractGroupedDataTableInfo(tableView)
      @buildSubGroupCSV(tableInfo, options)

  @csvCollection: (tableView, collection, options) ->
    if (@checkForUnappliedFilter(tableView))
      tableInfo = @extractCollectionInfo(tableView, collection)
      @buildCSV(tableInfo, options)

  @buildSubGroupCSV: (tableInfo, options) ->
    tableHeadings = tableInfo.headings
    tableData = tableInfo.data
    # THIS DONT WORK YET
    # iCols = options.ignoreCols
    #if (iCols)
    #  for own colIndex of iCols
    #    tableHeadings.splice(colIndex, 1)
    #  for group in tableData
    #    for data in group.data
    #      for row in tableData.data
    #        for own colIndex of iCols
    #          row.splice(colIndex, 1)
    csv = []
    csv.push(tableHeadings.join(','))
    for key, group of tableData
      for key, subgroup of group.tables
        for row in subgroup.rows
          # Put quotes around everything.
          for cell, index in row
            cell = "\"#{ cell }\""
            row[index] = cell
          cleanRow = row
          cleanRow = cleanRow.join(',')
          cleanRow = cleanRow.replace(HTML_TAG_REGEX, '')
          cleanRow = cleanRow.replace(NON_LATIN1_REGEX, '')
          csv.push(cleanRow)
    csv = csv.join('\r\n')
    @outputCsv(csv, options)

  @buildGroupCSV: (tableInfo, options) ->

    tableHeadings = tableInfo.headings
    tableData = tableInfo.data
    # tableData = [{   Array of objects
    #  title: "group title"
    #  data: [[1, 2, 3], [1,4,6]]
    #  totalRow: "<tr><td>some total display for table</td></tr>"
    # }]

    iCols = options.ignoreCols
    if (iCols)
      for own colIndex of iCols
        tableHeadings.splice(colIndex, 1)
      for group in tableData
        for data in group.data
          for row in tableData.data
            for own colIndex of iCols
              row.splice(colIndex, 1)

    csv = []
    csv.push(tableHeadings.join(','))
    for group in tableData
      for row in group.data

        # Put quotes around everything.
        for cell, index in row
          cell = "\"#{ cell }\""
          row[index] = cell

        cleanRow = row
        cleanRow = cleanRow.join(',')
        cleanRow = cleanRow.replace(HTML_TAG_REGEX, '')
        cleanRow = cleanRow.replace(NON_LATIN1_REGEX, '')

        csv.push(cleanRow)
    csv = csv.join('\r\n')
    @outputCsv(csv, options)

  @buildCSV: (tableInfo, options) ->

    tableHeadings = tableInfo.headings
    tableData = tableInfo.data

    iCols = options.ignoreCols
    if (iCols)
      for own colIndex of iCols
        tableHeadings.splice(colIndex, 1)
      for row in tableData
        for own colIndex of iCols
          row.splice(colIndex, 1)

    csv = []

    cleanHeadings = []
    for heading in tableHeadings
      cleanHeading = heading.trim()
      cleanHeading = cleanHeading.replace(/(\r\n|\n|\r)/gm, '') # line feeds
      cleanHeading = cleanHeading.replace(HTML_TAG_REGEX, '')
      cleanHeading = cleanHeading.replace(NON_LATIN1_REGEX, '')
      cleanHeading = cleanHeading.trim()
      cleanHeadings.push(cleanHeading)

    csv.push(cleanHeadings.join(','))
    for row in tableData

      # Put quotes around everything.
      for cell, index in row
        cell = "\"#{ cell }\""
        row[index] = cell.replace(MULTI_QUOTE_REGEX, '$1')

      cleanRow = row.join(',')
      cleanRow = cleanRow.replace(HTML_TAG_REGEX, '')
      cleanRow = cleanRow.replace(NON_LATIN1_REGEX, '')
      csv.push(cleanRow)
    csv = csv.join('\r\n')
    @outputCsv(csv, options)

  @outputCsv: (csv, options) ->
    $a = $('<a/>')
    $a.attr('href', "data:attachment/csv;base64,#{ btoa(csv) }")
    $a.attr('target', '_blank')
    $a.attr('download', "#{ options.filename }.csv")
    $a.attr('data-bypass', 1)
    $a.appendTo('body')
    $a[0].click()
    setTimeout(() ->
      $a.remove()
    , 100)

  @print: (tableInfo, template, options) ->
    data = {
      tableHeadings: tableInfo.headings
      tableData: tableInfo.data
      tableTotals: tableInfo.totals
      filter: tableInfo.filter
      options
    }
    html = template(data)
    w = window.open(null, '_blank')
    giveUpCount = 20
    tries = 0
    check = () ->
      if (!w?.document)
        if (tries++ < giveUpCount)
          setTimeout(check, 100)
        else
          console.error("Can't get printable page to open. Giving up.")
      else
        w.document.write(html)
        w.print()
    check()
    ###
    $form = $('<form style="display:block;"/>')
    $form.attr('method', 'POST')
    $form.attr('action', '/print')
    $form.attr('target', '_blank')
    $input = $('<input/>')
    $input.attr('type', 'hidden')
    $input.attr('name', 'html')
    $input.val(html)
    $form.append($input)
    $input = $('<input/>')
    $input.attr('type', 'submit')
    $form.append($input)
    $('body').append($form)
    $input[0].click()
    setTimeout(() ->
      $form.remove()
    , 100)
    ###

  # If you have to touch this code, I'm sorry.  Track me down and I'll help you.
  @getHumanizedFilters: (tableView) ->
    mappings = tableView?.filterView?.filterParamMappings?()
    params =tableView?.filterView?.getFilterParams?()
    #params = tableView?.filterView?.getEncodedFilterParams?()

    humanParams = {}
    if (mappings && params)

      for own key, val of params

        # HUMANIZE PARAM KEY (LABEL) ///////////////////////////////////////////
        humanLabel = key
        suffix = ""

        # DATERANGE
        parts = key.split('.') # handle date.gte type keys
        firstPart = parts[0]
        mapping = mappings[firstPart]
        if (mapping)
          key = firstPart
        else if (mappings[key])
          mapping = mappings[key]
        else if (firstPart.split('[').length > 1)
          mapping = mappings[firstPart + "]"]
          #parts[1] = firstPart
          #parts[1] = firstPart

        if (mapping.type == C.FilterTypes.DateRange)
          if (parts.length == 2 && parts[1].match(/gte/))
            suffix = " Is After"
          if (parts.length == 2 && parts[1].match(/lte/))
            suffix = " Is Before"

        # STEAL LABELS FROM FORM
        $el = $(mapping.el)
        if ($el)
          $controlGroup = $el.closest('.control-group')
          humanLabel = $controlGroup.find('label').text() + suffix

        # HUMANIZE PARAM VAL ///////////////////////////////////////////////////
        humanVal = val

        # ARRAY
        if (_.isArray(val))
          humanVal = $el.select2('data').text

        # BOOLEAN
        else if (_.isBoolean(val))
          if (mapping.type == C.FilterTypes.Select2Boolean)
            humanVal = $el.select2('data').text
          else
            if (val)
              humanVal = "Yes"
            else
              humanVal = "No"

        # STRING
        else if (_.isString(val))

          if (val.match(ISO8601_REGEX))
            humanVal = App.Utils.makeTimestampHuman(val, C.DateFormats.Long)

          if (mapping.type == C.FilterTypes.Select2)
            humanVal = $el.select2('data').text

          if (mapping.type == C.FilterTypes.Autocomplete)
            humanVal = $el.val()

        # NUMBER
        else if (_.isNumber(val))

          if (mapping.type == C.FilterTypes.Select2)
            humanVal = $el.select2('data').text


        # SPECIAL CASE NONSENSE ////////////////////////////////////////////////
        if (mapping.type == C.FilterTypes.Grouping)
          humanVal = "#{ key.replace(UNDERSCORE_REGEX, ' ') } (#{ val })"




        # Save humanized param key/val pair.
        humanParams[humanLabel] = humanVal

    return humanParams

