App.Models.Base.BaseModel = require('./base/base_model.coffee')

parent = App.Models.Base.BaseModel.prototype
module.exports = App.Models.Email = App.Models.Base.BaseModel.extend

  initialize: (attributes, options = {}) ->
    {
      @holder_id
    } = options
    parent.initialize.call(@, attributes, options)

  validates_presence_of: ["address"]

  validates:
    'address':
      required: true

  equals: (email) -> email.get('address') is @get('address')

  toPresenterJSON: ->
    address: @get("address")
    label:   @get("label")
    id:      @get("id")
  to_s: -> @get("email_address")

