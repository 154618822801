template = require('./modal_notes.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.TicketGroups.ModalNotes = App.Views.Base.BaseView.extend

  template: template

  className: 'modal hide fade'

  initialize: (options = {}) ->
    parent.initialize.call(@, options)
    { @eventNotes } = options
    @render()

  render: () ->
    data = {
      eventNotes: @eventNotes
    }
    @$el.html(
      @template(data)
    )
    # STANDARDIZED MODAL
    @$el.modal('show').on('hidden', () =>
      @remove()
    )
