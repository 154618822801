App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')
App.Collections.V3.Tickets = require('../../collections/v3/tickets_collection.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.TicketHold = App.Models.Base.V3BaseModel.extend

  initialize: (attributes = {}, options = {}) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}
    
    if (@getMapping() == C.Mappings.Direct.FilteredTickets.TakenTickets)
      attrs._createdAt = Date.parse(json.created_at)
      attrs._createdBy = json.created_by
      attrs._heldFor = json.held_for
      attrs._heldForBrokerage = json.held_for_brokerage
      attrs._heldForBrokerageId = json.held_for_brokerage_id
      attrs._heldForId = json.held_for_id
      attrs._heldForType = json.held_for_type
      attrs._holdUntil = Date.parse(json.hold_until) || null
      attrs._id = json.id
      attrs._notes = json.notes
      attrs._price = json.price
      attrs._quantity = json.quantity
      attrs._seats = json.seats
      attrs._ticketGroupIds = json.ticket_group_ids
      attrs._ticketGroup = new App.Models.V3.TicketGroup(json.ticket_group, {
        mapping: C.Mappings.Direct.FilteredTickets.TicketGroup
      })
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.FilteredTickets.HeldTickets)
      attrs._createdAt = Date.parse(json.created_at)
      attrs._createdBy = json.created_by
      attrs._heldFor = json.held_for
      attrs._heldForBrokerage = json.held_for_brokerage
      attrs._heldForBrokerageId = json.held_for_brokerage_id
      attrs._heldForId = json.held_for_id
      attrs._heldForType = json.held_for_type
      attrs._holdUntil = Date.parse(json.hold_until) || null
      attrs._id = json.id
      attrs._notes = json.notes
      attrs._price = json.price
      attrs._quantity = json.quantity
      attrs._seats = json.seats
      attrs._ticketGroupIds = json.ticket_group_ids
      attrs._ticketGroup = new App.Models.V3.TicketGroup(json.ticket_group, {
        mapping: C.Mappings.Direct.FilteredTickets.TicketGroup
      })
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.TicketHolds.TicketHolds)
      attrs._createdAt = Date.parse(json.created_at)
      attrs._createdBy = json.created_by
      attrs._heldFor = json.held_for
      attrs._heldForBrokerage = json.held_for_brokerage
      attrs._heldForBrokerageId = json.held_for_brokerage_id
      attrs._heldForId = json.held_for_id
      attrs._heldForType = json.held_for_type
      attrs._holdUntil = Date.parse(json.hold_until) || null
      attrs._id = json.id
      attrs._notes = json.notes
      attrs._price = json.price
      attrs._quantity = json.quantity
      attrs._seats = json.seats
      attrs._ticketGroupIds = json.ticket_group_ids
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Carts.TicketHolds)
      attrs._id = json.id
      attrs._lowSeat = json.low_seat
      attrs._price = json.price
      attrs._seatOrder = json.seat_order
      attrs._tickets = new App.Collections.V3.Tickets(json.tickets, {
        mapping: C.Mappings.Direct.Carts.TicketHoldTickets
      })

    @set(attrs)

  fetchPatron: () ->
    if (@get('_heldForType').toLowerCase() == C.Office)
      office = new App.Models.V3.Office({
        id: @get('_heldForId')
      }, {
        mapping: C.Mappings.Direct.Offices.Office
      })
      @set('_heldForOffice', office)
      return office.fetchPromise()
    else
      return Q(null)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      createdAt: @get('_createdAt')
      createdBy: @get('_createdBy')
      heldForType: @get('_heldForType')
      heldFor: @heldFor()
      holdUntil: @get('_holdUntil')
      price: @get('_price')
      quantity: @get('_quantity')
      seats: @get('_seats')
      flags: @flags()
      ticketGroupId: @ticketGroupId()
      ticketGroup: @get('_ticketGroup')?.toPresenterJSON()
    })
    return presented

  flags: () ->
    if (@get('_notes'))
      return C.FlagBadges.Notes
    return ''

  heldFor: () ->
    type = @get('_heldForType')?.toLowerCase()
    if (type == C.Office)
      return @get('_heldForBrokerage')
    else if (type == C.Client)
      return @get('_heldFor')
    else if (type == 'cart')
      return '<strong>Cart</strong>'
    return ''

  ticketGroupId: () ->
    return _.first(@get('_ticketGroupIds'))
  #///////////////////////////////////////////////////////////////////////////

  release: (options) ->
    ticketGroupId = options.ticketGroupId
    path = if @has("_holdUntil") then '/release_hold/' else '/release_take/'
    url = "/api_direct/v9/ticket_groups/#{ ticketGroupId }#{ path }#{ @id }"
    $.ajax({
      type: 'POST'
      url
      success: (data, status, xhr) =>
        @set(data)
        @updateStandardAttributes()
        App.Vent.trigger('ticket-hold:released', @)
        options.callback?(null, data)
      error: (xhr, status, error) =>
        callback(new Error('Error releasing ticket group.'))
    })

  updateHoldUntil: (date) ->
    ticketGroupId = @get('_ticketGroupIds')[0]
    url = "/api_direct/v9/ticket_groups/#{ ticketGroupId }/update_hold/#{ @id }"
    data = JSON.stringify({
      hold_until: date
    })
    $.when($.post(url, data)).then((data) =>
      @set('_holdUntil', Date.parse(date))
      App.Vent.trigger('ticket-hold:updated', @)
    )

