App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.Eticket = App.Models.Base.V3BaseModel.extend

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.Etickets.SplitPdf)
      attrs._id = json.id
      attrs._thumbnail = json.thumbnail
      attrs._width = null  # Set via prefetchImage()
      attrs._height = null # Set via prefetchImage()

    if (@getMapping() == C.Mappings.Direct.Tickets.Eticket)
      attrs._barcode = json.barcode
      attrs._id = json.id
      attrs._packId = json.eticket_pack_id
      attrs._seat = json.seat
      attrs._thumbnail = json.thumbnail
      attrs._ticketId = json.ticketId

    @set(attrs)
    @prefetchImage()

  toJSON: () ->
    attrs = @attributes
    json = {
      barcode: attrs._barcode
      id: attrs._ticketId
    }
    if (attrs._id)
      json.eticket_id = attrs._id
    return json

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {
      barcode: @get('_barcode')
      id: @get('_id')
      seat: @get('_seat')
      ticketId: @get('_ticketId')
      thumbnail: @get('_thumbnail')
      width: @get('_width')
      height: @get('_height')
    })
    return presented
  #///////////////////////////////////////////////////////////////////////////

  prefetchImage: () ->
    url = @get('_thumbnail')
    if (url)
      img = new Image()
      img.onload = () =>
        height = img.height
        width = img.width
        attrs = {
          _width: width
          _height: height
        }
        @set(attrs)
      img.crossOrigin = 'anonymous'
      img.src = url


