if (global.__TEVO_CORE__ && global.__TEVO_CORE__.env)
  {
    SENTRY_DSN,
    SENTRY_DEBUG,
    SENTRY_ENVIRONMENT,
    SENTRY_ENABLED
  } = global.__TEVO_CORE__.env

  require('@sentry/browser').init({
    dsn: SENTRY_DSN,
    debug: SENTRY_DEBUG == 'true',
    environment: SENTRY_ENVIRONMENT,
    enabled: SENTRY_ENABLED == 'true',
    release: global.SENTRY_RELEASE?.id,
  });
else
  console.warn('Core environment variables (global.__TEVO_CORE__.env) not found');

# GLOBALS (NPM LIBRARIES) //////////////////////////////////////////////////////
global._ = require('underscore')
global.jQuery = require('jquery')
global.$ = global.jQuery
global.Backbone = require('backbone')
global.Backbone.$ = global.$
global.templates = {}
global.__TEVO_CORE__ = global.__TEVO_CORE__ || {}

# These "packages" are defined in webpack to resolve to specific files in the
# ./vendor directory. Presumably they are treated differently than the above
# so that webpack will pre-process them.
require('vendor/card')
require('vendor/daterangepicker')
require('vendor/jquery-payment')
require('vendor/jquery-ui')
require('vendor/jquery-ujs')
require('vendor/select2')
# VENDOR LIBRARIES (NON NPM) ///////////////////////////////////////////////////
require('./vendor/backbone.store')
# The good validation library.  Keep using this one.
require('./vendor/backbone-validation')
#TODO: Remove this dependency.  Used by v1 & v2 models.  Look for 'validates' usage.
require('./vendor/backbone.validator')
require('./vendor/bootstrap')

# EXPORT APP ///////////////////////////////////////////////////////////////////
module.exports = global.App = App = {}

# CONSTANTS ////////////////////////////////////////////////////////////////////
global.C = require('./c.coffee')

global.__TEVO_CORE__.configureSalesIQ = () ->
  console.log('configuring salesIQ')
  salesIQ = global.$zoho.salesiq

  salesIQ.visitor.name(SESSION.user_name)
  salesIQ.visitor.id(SESSION.user_id.toString())

  user = new App.Models.V3.User({
    id : SESSION.user_id
  }, {
    mapping: C.Mappings.Direct.User.User
  })

  user.fetchPromise().then (user) =>
    # The salesIQ package will clear the email address field if we set it too early, so we wait for it to finish loading.
    setTimeout ->
      try
        salesIQ.visitor.email(user.attributes.email)
        salesIQ.visitor.contactnumber(user.attributes.phone.number)
        salesIQ.visitor.info({
          officeID: user.attributes.office.id,
          officeName: user.attributes.office.name,
          brokerageID: user.attributes.office.brokerage.id,
          brokerageName: user.attributes.office.brokerage.name,
          brokerageAbbreviation: user.attributes.office.brokerage.abbreviation,
          contactExtension: user.attributes.phone.extension,
        })
        console.log('salesIQ configured')
      catch e
        console.log(e)
    , 200
  .fail (e) =>
    console.log('failed to fetch user during SalesIQ configuration:')
    console.log(e)

# INIT /////////////////////////////////////////////////////////////////////////
# Starts the actual application.
# Router is initialized and routes the current url to a controller.
App.init = () ->

  #configure AJAX
  $.ajaxSetup({
    contentType: "application/json; charset=utf-8"
    dataType: "json"
  });

  # Dumb evoquote client side storage.
  App.EvoQuote = new App.Stores.EvoQuotes()

  # Yell at non chrome users (to be removed eventually).
  $container = $('body')
  chrome = new App.Views.Shared.ChromeOnly({
    $container
  })

  configureGTM()

  # Create event aggregator (pubsub system).
  App.Vent = _.extend({}, Backbone.Events)

  # Error manager, from `error_manager.js`
  App.setupSentry();
  App.setupErrorManager()

  # Intercept links in <a> tags and send them to Backbone Router.
  App.Utils.createLinkInterceptor()

  # Boot the app.
  App.router = new App.Router()

  if App.Access.assignedRole == 'Travel Agent'
    global.__TEVO_CORE__.loadSalesIQ()

# DECLARE NAMESPACES ///////////////////////////////////////////////////////////
App.Access                = {}
App.Collections           = {}
App.Collections.Base      = {}
App.Collections.V2        = {}
App.Collections.V3        = {}
App.Controllers           = {}
App.Controllers.Base      = {}
App.ErrorManager          = {}
App.Helpers               = {}
App.Models                = {}
App.Models.Base           = {}
App.Models.V2             = {}
App.Models.V3             = {}
App.Presenters            = {}
App.Stores                = {}
App.Utils                 = {}

App.Views                            = {}
App.Views.Base                       = {}
App.Views.Shared                     = {}
App.Views.Abstract                   = {}
App.Views.Addresses                  = {}
App.Views.BuySell                    = {}
App.Views.Clients                    = {}
App.Views.CreditCards                = {}
App.Views.CreditMemo                 = {}
App.Views.Delivery                   = {}
App.Views.Email                      = {}
App.Views.Events                     = {}
App.Views.EvoQuote                   = {}
App.Views.EvoPay                     = {}
App.Views.Export                     = {}
App.Views.Inventory                  = {}
App.Views.RestrictedEvopayOrders     = {}
App.Views.Order                      = {} # NEW
App.Views.Orders                     = {} # OLD
App.Views.Payments                   = {}
App.Views.PhoneNumbers               = {}
App.Views.PO                         = {}
App.Views.Shared                     = {}
App.Views.Shipments                  = {} # OLD
App.Views.Shipping                   = {} # NEW
App.Views.Reports                    = {}
App.Views.TakenTickets               = {}
App.Views.TicketGroups               = {}
App.Views.TicketHolds                = {}
App.Views.User                       = {}

# TEVO LIBRARIES ///////////////////////////////////////////////////////////////
App.SeatMap                               = require('ticket-evolution-seatmaps')

# INCLUDE CODE /////////////////////////////////////////////////////////////////
App.Access                                = require('./utils/access.coffee')
App.Utils.Countries                       = require('./utils/locality.coffee')
App.Utils                                 = require('./utils/utils.coffee')
App.Utils.EndlessDataTable                = require('./utils/endless_datatable.coffee')

App.OrderPermissions                      = require('./utils/order_permissions.coffee')

App.Collections.Base.BaseCollection       = require('./collections/base/base_collection.coffee')
App.Collections.Base.PaginatedBase        = require('./collections/base/paginated_base_collection.coffee')

App.Collections.V2.Addresses              = require('./collections/v2/addresses_collection.coffee')
App.Collections.V2.BrokerageUsers         = require('./collections/v2/brokerage_users_collection.coffee')
App.Collections.V2.EticketThumbnails      = require('./collections/v2/eticket_thumbnails_collection.coffee')
App.Collections.V2.Events                 = require('./collections/v2/events_collection.coffee')
App.Collections.V2.HeldTickets            = require('./collections/v2/held_tickets_collection.coffee')
App.Collections.V2.OrderItems             = require('./collections/v2/order_items_collection.coffee')
App.Collections.V2.Orders                 = require('./collections/v2/orders_collection.coffee')
App.Collections.V2.Shipments              = require('./collections/v2/shipments_collection.coffee')
App.Collections.V2.TicketGroups           = require('./collections/v2/ticket_groups_collection.coffee')
App.Collections.V2.TicketHolds            = require('./collections/v2/ticket_holds_collection.coffee')

App.Collections.V3.Addresses                  = require('./collections/v3/addresses_collection.coffee')
App.Collections.V3.ApiTokens                  = require('./collections/v3/api_tokens_collection.coffee')
App.Collections.V3.Carts                      = require('./collections/v3/carts_collection.coffee')
App.Collections.V3.Clients                    = require('./collections/v3/clients_collection.coffee')
App.Collections.V3.CreditCards                = require('./collections/v3/credit_cards_collection.coffee')
App.Collections.V3.CreditMemos                = require('./collections/v3/credit_memos_collection.coffee')
App.Collections.V3.Emails                     = require('./collections/v3/emails_collection.coffee')
App.Collections.V3.EmailAddresses             = require('./collections/v3/email_addresses_collection.coffee')
App.Collections.V3.Etickets                   = require('./collections/v3/etickets_collection.coffee')
App.Collections.V3.Events                     = require('./collections/v3/events_collection.coffee')
App.Collections.V3.EventPins                  = require('./collections/v3/event_pins_collection.coffee')
App.Collections.V3.EvoQuotes                  = require('./collections/v3/evoquotes_collection.coffee')
App.Collections.V3.Exchanges                  = require('./collections/v3/exchanges_collection.coffee')
App.Collections.V3.RestrictedEvopayOrders     = require('./collections/v3/restricted_evopay_orders_collections.coffee')
App.Collections.V3.Offices                    = require('./collections/v3/offices_collection.coffee')
App.Collections.V3.OrderItems                 = require('./collections/v3/order_items_collection.coffee')
App.Collections.V3.Orders                     = require('./collections/v3/orders_collection.coffee')
App.Collections.V3.PaymentOverviews           = require('./collections/v3/payment_overviews_collection.coffee')
App.Collections.V3.Payments                   = require('./collections/v3/payments_collection.coffee')
App.Collections.V3.Performers                 = require('./collections/v3/performers_collection.coffee')
App.Collections.V3.PhoneNumbers               = require('./collections/v3/phone_numbers_collection.coffee')
App.Collections.V3.QueuedOrders               = require('./collections/v3/queued_orders_collection.coffee')
App.Collections.V3.RateOptions                = require('./collections/v3/rate_options_collection.coffee')
App.Collections.V3.ShipmentTypes              = require('./collections/v3/shipment_types_collection.coffee')
App.Collections.V3.Shipments                  = require('./collections/v3/shipments_collection.coffee')
App.Collections.V3.Substitutions              = require('./collections/v3/substitutions_collection.coffee')
App.Collections.V3.TicketGroups               = require('./collections/v3/ticket_groups_collection.coffee')
App.Collections.V3.TicketHolds                = require('./collections/v3/ticket_holds_collection.coffee')
App.Collections.V3.Tickets                    = require('./collections/v3/tickets_collection.coffee')

App.Collections.CreditCards               = require('./collections/credit_cards_collection')
App.Collections.EmailAddresses            = require('./collections/email_addresses_collection.coffee')
App.Collections.EvoPayTransactions        = require('./collections/evopay_transactions_collection')
App.Collections.InventoryUploads          = require('./collections/inventory_uploads_collection.coffee')
App.Collections.OrderItems                = require('./collections/order_items_collection.coffee')
App.Collections.Orders                    = require('./collections/orders_collection')
App.Collections.Payments                  = require('./collections/payments_collection')
App.Collections.PhoneNumber               = require('./collections/phone_numbers_collection.coffee')
App.Collections.ShipmentSnapshots         = require('./collections/shipment_snapshots_collection')
App.Collections.Shipments                 = require('./collections/shipments_collection')
App.Collections.TicketGroups              = require('./collections/ticket_groups_collection')

App.Controllers.Base.BaseController                  = require('./controllers/base/base_controller.coffee')
App.Controllers.BuySellController                    = require('./controllers/buy_sell_controller.coffee')
App.Controllers.CreditMemoController                 = require('./controllers/credit_memo_controller.coffee')
App.Controllers.DeliveryController                   = require('./controllers/delivery_controller.coffee')
App.Controllers.EmailController                      = require('./controllers/email_controller.coffee')
App.Controllers.EticketManagmementController         = require('./controllers/eticket_management_controller.coffee')
App.Controllers.EventManagementController            = require('./controllers/event_management_controller.coffee')
App.Controllers.EvoPayController                     = require('./controllers/evopay_controller.coffee')
App.Controllers.EvoQuoteController                   = require('./controllers/evoquote_controller.coffee')
App.Controllers.InventoryController                  = require('./controllers/inventory_controller.coffee')
App.Controllers.RestrictedEvopayOrdersController     = require('./controllers/restricted_evopay_orders_controller.coffee')
App.Controllers.OrderController                      = require('./controllers/order_controller.coffee')
App.Controllers.PaymentController                    = require('./controllers/payment_controller.coffee')
App.Controllers.ReportController                     = require('./controllers/report_controller.coffee')
App.Controllers.ShipmentController                   = require('./controllers/shipment_controller.coffee')
App.Controllers.InventoryUploadsController           = require('./controllers/inventory_uploads_controller.coffee')

# SINGLETON CONTROLLERS
App.Controllers.buySellController                    = new App.Controllers.BuySellController()
App.Controllers.cartController                       = require('./controllers/cart_controller.coffee')
App.Controllers.clientController                     = require('./controllers/client_controller.coffee')
App.Controllers.clientShowController                 = require('./controllers/client_show_controller.coffee')
App.Controllers.creditMemoController                 = new App.Controllers.CreditMemoController()
App.Controllers.dashboardController                  = require('./controllers/dashboard_controller.coffee')
App.Controllers.deliveryController                   = new App.Controllers.DeliveryController()
App.Controllers.emailController                      = new App.Controllers.EmailController()
App.Controllers.eticketManagement                    = new App.Controllers.EticketManagmementController()
App.Controllers.eventManagementController            = new App.Controllers.EventManagementController()
App.Controllers.eventsController                     = require('./controllers/events_controller.coffee')
App.Controllers.evoPayController                     = new App.Controllers.EvoPayController()
App.Controllers.evoQuoteController                   = new App.Controllers.EvoQuoteController()
App.Controllers.inventoryController                  = new App.Controllers.InventoryController()
App.Controllers.inventoryUploadsController           = new App.Controllers.InventoryUploadsController()
App.Controllers.restrictedEvopayOrdersController     = new App.Controllers.RestrictedEvopayOrdersController()
App.Controllers.orderShowController                  = require('./controllers/order_show_controller.coffee')
App.Controllers.paymentController                    = new App.Controllers.PaymentController()
App.Controllers.poController                         = require('./controllers/po_controller.coffee')
App.Controllers.reportController                     = new App.Controllers.ReportController()
App.Controllers.shipmentController                   = new App.Controllers.ShipmentController()
App.Controllers.ticketGroupController                = require('./controllers/ticket_group_controller.coffee')
App.Controllers.uploadsController                    = require('./controllers/uploads_controller.coffee')

App.Exporter                              = require('./helpers/exporter.coffee')

App.Models.Base.BaseModel                 = require('./models/base/base_model.coffee')
App.Models.Base.PatronBaseModel           = require('./models/base/patron_base_model.coffee')
App.Models.Base.V3BaseModel               = require('./models/base/v3_base_model.coffee')

App.Models.V2.Address                     = require('./models/v2/address_model.coffee')
App.Models.V2.Brokerage                   = require('./models/v2/brokerage_model.coffee')
App.Models.V2.BrokerageUser               = require('./models/v2/brokerage_user_model.coffee')
App.Models.V2.Client                      = require('./models/v2/client_model.coffee')
App.Models.V2.EticketThumbnail            = require('./models/v2/eticket_thumbnail_model.coffee')
App.Models.V2.Event                       = require('./models/v2/event_model.coffee')
App.Models.V2.Office                      = require('./models/v2/office_model.coffee')
App.Models.V2.OrderItem                   = require('./models/v2/order_item_model.coffee')
App.Models.V2.Order                       = require('./models/v2/order_model.coffee')
App.Models.V2.Patron                      = require('./models/v2/patron_model.coffee')
App.Models.V2.Shipment                    = require('./models/v2/shipment_model.coffee')
App.Models.V2.TicketGroup                 = require('./models/v2/ticket_group_model.coffee')
App.Models.V2.TicketHold                  = require('./models/v2/ticket_hold_model.coffee')
App.Models.V2.Ticket                      = require('./models/v2/ticket_model.coffee')
App.Models.V2.Venue                       = require('./models/v2/venue_model.coffee')

App.Models.V3.Address                     = require('./models/v3/address_model.coffee')
App.Models.V3.ApiToken                    = require('./models/v3/api_token_model.coffee')
App.Models.V3.Brokerage                   = require('./models/v3/brokerage_model.coffee')
App.Models.V3.Cart                        = require('./models/v3/cart_model.coffee')
App.Models.V3.Category                    = require('./models/v3/category_model.coffee')
App.Models.V3.Client                      = require('./models/v3/client_model.coffee')
App.Models.V3.Commission                  = require('./models/v3/commission_model.coffee')
App.Models.V3.Configuration               = require('./models/v3/configuration_model.coffee')
App.Models.V3.CreditCard                  = require('./models/v3/credit_card_model.coffee')
App.Models.V3.CreditMemo                  = require('./models/v3/credit_memo_model.coffee')
App.Models.V3.CreditMemoCollectionWrapper = require('./models/v3/credit_memo_collection_wrapper_model.coffee')
App.Models.V3.EmailAddress                = require('./models/v3/email_address_model.coffee')
App.Models.V3.Eticket                     = require('./models/v3/eticket_model.coffee')
App.Models.V3.Event                       = require('./models/v3/event_model.coffee')
App.Models.V3.EventPin                    = require('./models/v3/event_pin_model.coffee')
App.Models.V3.EvoPayTransaction           = require('./models/v3/evopay_transaction_model.coffee')
App.Models.V3.EvoQuote                    = require('./models/v3/evoquote_model.coffee')
App.Models.V3.Exchange                    = require('./models/v3/exchange_model.coffee')
App.Models.V3.File                        = require('./models/v3/file_model.coffee')
App.Models.V3.Fraud                       = require('./models/v3/fraud_model.coffee')
App.Models.V3.RestrictedEvopayOrders      = require('./models/v3/restricted_evopay_orders_model.coffee')
App.Models.V3.Office                      = require('./models/v3/office_model.coffee')
App.Models.V3.OrderCollectionWrapper      = require('./models/v3/order_collection_wrapper_model.coffee')
App.Models.V3.OrderItem                   = require('./models/v3/order_item_model.coffee')
App.Models.V3.Order                       = require('./models/v3/order_model.coffee')
App.Models.V3.Patron                      = require('./models/v3/patron_model.coffee')
App.Models.V3.Payment                     = require('./models/v3/payment_model.coffee')
App.Models.V3.PaymentOverview             = require('./models/v3/payment_overview_model.coffee')
App.Models.V3.Performer                   = require('./models/v3/performer_model.coffee')
App.Models.V3.PhoneNumber                 = require('./models/v3/phone_number_model.coffee')
App.Models.V3.QueuedOrder                 = require('./models/v3/queued_order_model.coffee')
App.Models.V3.RateOption                  = require('./models/v3/rate_option_model.coffee')
App.Models.V3.SalesCollectionWrapper      = require('./models/v3/sales_collection_wrapper_model.coffee')
App.Models.V3.Shipment                    = require('./models/v3/shipment_model.coffee')
App.Models.V3.ShipmentSnapshot            = require('./models/v3/shipment_snapshot_model.coffee')
App.Models.V3.ShipmentType                = require('./models/v3/shipment_type_model.coffee')
App.Models.V3.Substitution                = require('./models/v3/substitution_model.coffee')
App.Models.V3.TaxQuote                    = require('./models/v3/tax_quote_model.coffee')
App.Models.V3.TicketGroup                 = require('./models/v3/ticket_group_model.coffee')
App.Models.V3.TicketHold                  = require('./models/v3/ticket_hold_model.coffee')
App.Models.V3.Ticket                      = require('./models/v3/ticket_model.coffee')
App.Models.V3.User                        = require('./models/v3/user_model.coffee')
App.Models.V3.Venue                       = require('./models/v3/venue_model.coffee')

App.Models.Airbill                        = require('./models/airbill_model.coffee')
App.Models.CreditCard                     = require('./models/credit_card_model')
App.Models.Email                          = require('./models/email_model.coffee')
App.Models.EvoPayTransaction              = require('./models/evopay_transaction_model')
App.Models.EvoPayTransactionsAndBalances  = require('./models/evopay_transactions_and_balances_model.coffee')
App.Models.InventoryExport                = require('./models/inventory_export_model.coffee')
App.Models.InventoryUpload                = require('./models/inventory_upload_model.coffee')
App.Models.ListSummary                    = require('./models/list_summary_model')
App.Models.Order                          = require('./models/order_model')
App.Models.Payment                        = require('./models/payment_model')
App.Models.PhoneNumber                    = require('./models/phone_number_model.coffee')
App.Models.Return                         = require('./models/return_model')
App.Models.Shipment                       = require('./models/shipment_model')
App.Models.ShipmentSnapshot               = require('./models/shipment_snapshot_model')
App.Models.TicketGroup                    = require('./models/ticket_group_model')

App.Presenter                             = require('./presenters/presenter')
App.Presenters.TicketGroupPresenter       = require('./presenters/ticket_group_presenter')

App.Router                                = require('./router.coffee')

App.Stores.ConsignEvents                  = require('./stores/consign_events.coffee')
App.Stores.Events                         = require('./stores/events.coffee')
App.Stores.EventModals                    = require('./stores/event_modals.coffee')
App.Stores.EvoQuotes                      = require('./stores/evoquotes.coffee')
App.Stores.SpecEvents                     = require('./stores/spec_events.coffee')
App.Stores.ProofOfTransferModals          = require('./stores/proof_of_transfer_modal.coffee')
App.Stores.UploadProofOfTransferModals    = require('./stores/upload_proof_of_transfer_modal.coffee')

App.Views.Base.BaseView                   = require('./views/base/base_view.coffee')
App.Views.Base.Filter                     = require('./views/base/filter_view.coffee')
App.Views.Base.FilteredTable              = require('./views/base/filtered_table_view.coffee')

App.Views.Addresses.NewAddressForm        = require('./views/addresses/new_address_form_view.coffee')
App.Views.AffiliateLink                   = require('./views/affiliate_link_view.coffee')
App.Views.BuySell.Address                 = require('./views/buy_sell/address_view.coffee')
App.Views.BuySell.BuySellWizard           = require('./views/buy_sell/buy_sell_wizard_view.coffee')
App.Views.BuySell.EmailAddress            = require('./views/buy_sell/email_address_view.coffee')
App.Views.BuySell.Payment                 = require('./views/buy_sell/payment_view.coffee')
App.Views.BuySell.PhoneNumber             = require('./views/buy_sell/phone_number_view.coffee')
App.Views.BuySell.SeatSelection           = require('./views/buy_sell/seat_selection_view.coffee')
App.Views.BuySell.TransferSource          = require('./views/buy_sell/transfer_source_view.coffee')
App.Views.Clients.PatronAutocomplete      = require('./views/buy_sell/patron_autocomplete_view.coffee')

App.Views.Clients.ClientField             = require('./views/clients/client_field_view.coffee')
App.Views.Clients.ClientTable             = require('./views/clients/client_table_view.coffee')
App.Views.Clients.CreditCards             = require('./views/clients/credit_cards_view.coffee')
App.Views.Clients.List                    = require('./views/clients/clients_list_view.coffee')
App.Views.Clients.Orders                  = require('./views/clients/orders_view.coffee')
App.Views.Clients.ShowLayout              = require('./views/clients/show_layout_view.coffee')
App.Views.Clients.Show                    = require('./views/clients/show_view.coffee')

App.Views.CreditCards.ClientCards         = require('./views/credit_cards/client_cards_view')
App.Views.CreditCards.CreditMemoCards     = require('./views/credit_cards/credit_memo_cards_view')
App.Views.CreditCards.NewModal            = require('./views/credit_cards/new_modal_view')
App.Views.CreditCards.OfficeCards         = require('./views/credit_cards/office_cards_view')

App.Views.CreditMemo.Aggregates           = require('./views/credit_memos/credit_memo_aggregates_view')
App.Views.CreditMemo.CreditMemoTable      = require('./views/credit_memos/credit_memo_table_view')
App.Views.CreditMemo.InlinePayment        = require('./views/credit_memos/inline_payment_view.coffee')
App.Views.CreditMemo.InlinePayments       = require('./views/credit_memos/inline_payments_view.coffee')
App.Views.CreditMemo.List                 = require('./views/credit_memos/list_view.coffee')
App.Views.CreditMemo.NewPayment           = require('./views/credit_memos/new_payment_view.coffee')
App.Views.CreditMemo.Show                 = require('./views/credit_memos/credit_memo_show_view.coffee')

App.Views.Dashboard                       = require('./views/dashboard_view.coffee')

App.Views.Delivery.List                   = require('./views/deliveries/deliveries_list_view.coffee')
App.Views.Delivery.DeliveryTable          = require('./views/deliveries/delivery_table_view.coffee')

App.Views.Email.List                      = require('./views/emails/list_view.coffee')
App.Views.Email.EmailTable                = require('./views/emails/email_table_view.coffee')

App.Views.Events.Event                    = require('./views/events/event_view.coffee')
App.Views.Events.List                     = require('./views/events/events_list_view.coffee')
App.Views.Events.Filter                   = require('./views/events/filter_view.coffee')

App.Views.Events.SeatMap                  = require('./views/events/seatmaps_view.coffee')

App.Views.Events.ManagementOrders         = require('./views/events/management_orders_view.coffee')
App.Views.Events.Management               = require('./views/events/management_view.coffee')
App.Views.Events.Row                      = require('./views/events/row_view')
App.Views.Events.WithTicketGroup          = require('./views/events/with_ticket_groups_view.coffee')

App.Views.EvoQuote.List                   = require('./views/evoquote/list_view')
App.Views.EvoQuote.New                    = require('./views/evoquote/new_view.coffee')
App.Views.EvoQuote.QuoteTable             = require('./views/evoquote/quote_table_view')
App.Views.EvoQuote.Show                   = require('./views/evoquote/show_view.coffee')

App.Views.EvoPay.DepositFunds             = require('./views/evopay/deposit_funds_view.coffee')
App.Views.EvoPay.ModalAbstract            = require('./views/evopay/evopay_modal_abstract_view.coffee')
App.Views.EvoPay.List                     = require('./views/evopay/list_view.coffee')
App.Views.EvoPay.SendPaymentModal         = require('./views/evopay/send_payment_modal_view.coffee')
App.Views.EvoPay.TransactionTable         = require('./views/evopay/transaction_table_view.coffee')
App.Views.EvoPay.WithdrawFunds            = require('./views/evopay/withdraw_funds_view.coffee')

App.Views.Export.Modal                    = require('./views/export_modal_view.coffee')

App.Views.Inventory.MassIndex             = require('./views/inventory/mass_index_view.coffee')
App.Views.Inventory.MassIndexEditView     = require('./views/inventory/mass_index_edit_view.coffee')
App.Views.Inventory.MassIndexTable        = require('./views/inventory/mass_index_table_view.coffee')
App.Views.Inventory.UploadsIndex          = require('./views/inventory/uploads_index_view.coffee')
App.Views.Inventory.UploadsNew            = require('./views/inventory/uploads_new_view.coffee')
App.Views.Inventory.UploadsTable          = require('./views/inventory/uploads_table_view.coffee')

App.Views.RestrictedEvopayOrders.PaymentsTable = require('./views/restricted_evopay_orders/table_view.coffee')

# NEW ORDER SHOW (OrderOverview)
App.Views.Order.CancelOrderModal          = require('./views/order/cancel_order_modal_view.coffee')
App.Views.Order.ConsignmentAcceptModal    = require('./views/order/consignment_accept_modal_view.coffee')
App.Views.Order.Overview                  = require('./views/order/overview_view.coffee')
App.Views.Order.EticketSelectPages        = require('./views/order/eticket_select_pages_view.coffee')
App.Views.Order.FileUploadOutcome         = require('./views/order/file_upload_outcome_view.coffee')
App.Views.Order.Fraud                     = require('./views/order/fraud_view.coffee')
App.Views.Order.PurchaseOrders            = require('./views/order/purchase_orders_view.coffee')
App.Views.Order.RejectOrderModal          = require('./views/order/reject_order_modal_view.coffee')
App.Views.Order.SoldTicketsTab            = require('./views/order/sold_tickets_tab_view.coffee')
App.Views.Order.SubOrderItem              = require('./views/order/sub_order_item_view.coffee')
App.Views.Order.TicketGroupsTab           = require('./views/order/ticket_groups_tab_view.coffee')
App.Views.Order.Totals                    = require('./views/order/totals_view.coffee')
App.Views.Order.Transactions              = require('./views/order/transactions_view.coffee')
App.Views.Order.UploadEtickets            = require('./views/order/upload_etickets_view.coffee')

App.Views.Orders.CartView                 = require('./views/orders/cart_view.coffee')
App.Views.Orders.OrdersList               = require('./views/orders/orders_list_view.coffee')
App.Views.Orders.OrderTable               = require('./views/orders/order_table_view.coffee')

App.Views.Payments.Dropdown               = require('./views/payments/dropdown_view.coffee')
App.Views.Payments.List                   = require('./views/payments/list_view')
App.Views.Payments.PaymentTable           = require('./views/payments/payment_table_view')

App.Views.PO.ExistingDraft                = require('./views/po/existing_draft_view')
App.Views.PO.Filter                       = require('./views/po/filter_view.coffee')
App.Views.PO.New                          = require('./views/po/new_po_view.coffee')

App.Views.Reports.Sales                   = require('./views/reports/sales_view.coffee')
App.Views.Reports.SalesTable              = require('./views/reports/sales_table_view.coffee')

App.Views.Shared.BasicModal               = require('./views/shared/basic_modal_view.coffee')
App.Views.Shared.ChromeOnly               = require('./views/shared/chrome_only_view.coffee')
App.Views.Shared.InvalidModel             = require('./views/shared/invalid_model_view')
App.Views.Shared.ListSummary              = require('./views/shared/list_summary_view')
App.Views.Shared.LoadingView              = require('./views/shared/loading_view')
App.Views.Shared.Message                  = require('./views/shared/message_view.coffee')

App.Views.Shipping.Shipment                       = require('./views/shipping/shipment_view.coffee')
App.Views.Shipping.List                           = require('./views/shipping/shipment_list_view.coffee')
App.Views.Shipping.ShipmentTable                  = require('./views/shipping/shipment_table_view.coffee')
App.Views.Shipping.New                            = require('./views/shipping/shipment_new_view.coffee')
App.Views.Shipping.ModalProofOfTransferUploads    = require('./views/shipping/modal_proof_of_transfer_uploads.coffee')
App.Views.Shipping.ModalUploadProofOfTransfer     = require('./views/shipping/modal_upload_proof_of_transfer.coffee')

App.Views.TicketGroups.BeginHoldTakeCart    = require('./views/ticket_groups/begin_hold_take_cart_view.coffee')
App.Views.TicketGroups.BrokerDetails        = require('./views/ticket_groups/broker_details_view.coffee')
App.Views.TicketGroups.EditOne              = require('./views/ticket_groups/edit_one_view.coffee')
App.Views.TicketGroups.EticketManagementRow = require('./views/ticket_groups/eticket_management_row_view.coffee')
App.Views.TicketGroups.EticketManagement    = require('./views/ticket_groups/eticket_management_view.coffee')
App.Views.TicketGroups.ExpandedRowDetails   = require('./views/ticket_groups/expanded_row_details_view.coffee')
App.Views.TicketGroups.SeatingChart         = require('./views/ticket_groups/seating_chart_view.coffee')
App.Views.TicketGroups.Legend               = require('./views/ticket_groups/legend_view.coffee')
App.Views.TicketGroups.ListView             = require('./views/ticket_groups/ticket_groups_list_view.coffee')
App.Views.TicketGroups.ModalNotes           = require('./views/ticket_groups/modal_notes_view.coffee')
App.Views.TicketGroups.TicketGroupsTable    = require('./views/ticket_groups/ticket_groups_table_view.coffee')
App.Views.TicketGroups.WastedView           = require('./views/ticket_groups/wasted_view.coffee')

App.Views.TicketHolds.Edit                  = require('./views/ticket_holds/edit_view.coffee')
App.Views.TicketHolds.Row                   = require('./views/ticket_holds/ticket_hold_row_view.coffee')
App.Views.TicketHolds.TicketHoldsList       = require('./views/ticket_holds/ticket_holds_list_view.coffee')

App.Views.User.Account                      = require('./views/user/account_view.coffee')

# INCLUDES WITHOUT EXPLICIT module.exports /////////////////////////////////////
require('./error_manager')
require('./utils/autocomplete.coffee')
require('./utils/link_interceptor')
require('./version_manager.coffee')
require('./utils/global_queued_orders.coffee')

# IMAGES ///////////////////////////////////////////////////////////////////////
require('../img/chrome_logo.png')
require('../img/ico_new_client.png')
require('../img/icon_down.png')
require('../img/icon_top.png')
require('../img/notes_icon.png')
require('../img/search_handdrawn_arrow.png')
require('../img/seating_chart_icon.png')
require('../img/select2.png')
require('../img/select2x2.png')
require('../img/spinner.gif')
require('../img/gritter-light.png')
require('../img/gritter.png')
require('../img/line.png')
require('../img/logo.png')
require('../img/logo_travel.png')
require('../img/update.png')

SESSION.office = new App.Models.V3.Office({
  id: SESSION.office_id
}, {
  mapping: C.Mappings.Direct.Offices.Office
})

configureGTM = () ->
  if (SESSION.core_type)
    window.dataLayer.push({
      core_type: SESSION.core_type
    })

  if (SESSION.office_id)
    window.dataLayer.push({
      office_id: SESSION.office_id
    })

  if (SESSION.brokerage_id)
    window.dataLayer.push({
      brokerage_id: SESSION.brokerage_id
    })
