_ = require('underscore')
App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
# Use backbone-validation.
_.extend(parent, Backbone.Validation.mixin)
module.exports = App.Models.V3.EmailAddress = App.Models.Base.V3BaseModel.extend

  urlRoot: () ->
    if (!@clientId)
      @clientId = @collection.clientId
    return "/api_direct/v9/clients/#{ @clientId }/email_addresses"

  defaults:
    _isPrimary: false

  initialize: (attributes, options) ->
    {
      @clientId
    } = options
    parent.initialize.call(@, attributes, options)

  validation:
    _address:
      pattern: 'email'
      required: true
  labels: # Humanization
    _address: 'Email address'

  parse: (response, options) ->
    # We're hackily saving a single model though the collection endpoint.
    if (response.email_addresses && response.email_addresses.length)
      return response.email_addresses[0]
    else
      return response

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.CreditMemos.HolderEmailAddresses)
      attrs._address = json.address
      attrs._id = json.id
      attrs._label = json.label
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url

    if (@getMapping() == C.Mappings.Direct.CreditMemo.HolderEmailAddresses)
      attrs._address = json.address
      attrs._id = json.id
      attrs._label = json.label
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url

    if (@getMapping() == C.Mappings.Direct.Order.EmailAddresses)
      attrs._address = json.address
      attrs._id = json.id
      attrs._label = json.label
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Clients.EmailAddresses)
      attrs._address = json.address
      attrs._id = json.id
      attrs._isPrimary = json.primary || false
      attrs._label = json.label
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.OrdersLightweight.PatronEmailAddresses)
      attrs._address = json.address
      attrs._id = json.id
      attrs._label = json.label
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  toJSON: (options = {}) ->
    json = {}
    json.address = @get('_address')
    json.id = @get('_id')
    json.label = @get('_label')
    json.is_primary = @get('_isPrimary')
    if (
      !options.preventWrapping &&
      @isNew()
    )
      return { email_addresses: [json] }
    else
      return json

  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      address: @get('_address') || ''
      cid: @cid
      id: @get('_id')
      isPrimary: !!@get('_isPrimary')
      label: @get('_label') || ''

    })
    return presented