template = require('./credit_cards.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Clients.CreditCards = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    {
      @patron
      @$container
      @creditCardCollection
    } = options
    @render(true)

  render: (isInitialRender = false) ->
    data = {
      creditCards: @creditCardCollection.toPresenterJSON()
    }

    @$container.html(
      @$el.html(
        @template(data)
      )
    )

    if (isInitialRender)
      @loadingBox = new App.Utils.LoadingBox(@$el)
      @loadingButton = new App.Utils.LoadingButton(@$('#saveClientButton'))
      @loadingButtonModal = new App.Utils.LoadingButton($('#saveClientButton'))
      @loadingBox.startLoad()
    else
      @loadingBox.stopLoad()

    @formErrors = new App.Utils.FormErrorManager($('#formErrors'))
    @delegateEvents()

  events:
    'click .removeButton': 'onRemoveButtonClick'
    'click #saveClientButton': 'onSaveClientButtonClick'

  onRemoveButtonClick: (e)->
    $el = $(e.currentTarget)
    data = $el.data()
    model = data.model
    id = data.id

    confirm = new App.Views.Shared.BasicModal({
      isConfirmation: true
      header: "Are you sure?"
      message: "Remove this credit card from the client?"
      onYes: () =>
        confirm.$el.modal('hide')
        model = @creditCardCollection.get(id)
        model.cardOwner = @patron
        @destroyNestedModel(model)
    })

  onSaveClientButtonClick: (e)->
    @startLoad()
    promises = []
    primaryCardId = parseInt(@$('.isPrimaryCard:checked').val())
    @creditCardCollection.forEach((creditCardModel) =>

      changed = false

      # Are we changing primary?
      if (!creditCardModel.get('_isPrimary') && primaryCardId == creditCardModel.id)
        creditCardModel.set('_isPrimary', true)
        changed = true

      if (creditCardModel.get('_isPrimary') && primaryCardId != creditCardModel.id)
        creditCardModel.set('_isPrimary', false)
        changed = true

      # Did label change....
      inputLabel = @$(".inputLabel[data-card-id=#{ creditCardModel.id }]").val()
      if (creditCardModel.get('_label') != inputLabel)
        creditCardModel.set('_label', inputLabel)
        changed = true

      if (changed)
        creditCardModel.cardOwner = @patron
        creditCardModel.purpose = C.Purposes.UpdateCreditCard
        if (creditCardModel.isValid(true))
          promises.push(creditCardModel.savePromise())
        else
          @formErrors.show({
            title: 'Invalid credit card.'
            errors: creditCardModel.errors
          })

    )
    Q.all(promises)
    .then (results) =>
      @stopLoad()
      @showMessage({
        type: 'success'
        title: 'Client credit cards saved successfully.'
      })
    .fail (errors) =>
      @formErrors.show({
        title: "Unable to save card information."
        errors
      })
      @stopLoad()
    .done()


  destroyNestedModel: (model) ->
    @startLoad()
    model.destroyPromise()
    .then (model) =>
      isRerender = true
      @render(isRerender)
      @showMessage({
        type: 'success'
        title: 'Client credit cards saved successfully.'
      })
    .fail (error) =>
      console.error(error)
      @showMessage({
        title: 'Error saving credit cards.'
        errors: error
      })
    .done()

  startLoad: () ->
    @loadingBox.startLoad()
    @loadingButton.startLoad()
    @loadingButtonModal.startLoad()

  showMessage: (messageOptions) ->
    @formErrors.show(messageOptions)
    $(window).scrollTop(0)
    @stopLoad()

  stopLoad: () ->
    @loadingBox.stopLoad()
    @loadingButton.stopLoad()
    @loadingButtonModal.stopLoad()