template = require('./show_layout.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

module.exports = App.Views.Clients.ShowLayout = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    {
      @$container
      @callback
      @client
      @tab
    } = options

    @render()

  render: ->
    #regular render
    @$container.html(
      @$el.html(
        @template({
          client: @client.toPresenterJSON()
          @tab
        })
      )
    )
    @callback()

  events:
    'click #top_tabs li a' : 'tabClicked'

  tabClicked: (e) ->
    id = $(e.currentTarget).attr('id')
    switch id
      when 'info'
        App.router.navigate("/client/#{ @client.id }")
        App.Controllers.clientShowController.renderInfo()
      when 'invoices'
        @$('li').removeClass('active')
        @$('li#invoicesTab').addClass('active')
        App.router.navigate("/client/#{ @client.id }/invoices")
        App.Controllers.clientShowController.renderInvoices()
      when 'pos'
        @$('li').removeClass('active')
        @$('li#ordersTab').addClass('active')
        App.router.navigate("/client/#{ @client.id }/orders")
        App.Controllers.clientShowController.renderPOS()
      when 'cards'
        @$('li').removeClass('active')
        @$('li#cardsTab').addClass('active')
        App.router.navigate("/client/#{ @client.id }/credit_cards")
        App.Controllers.clientShowController.renderCards()

