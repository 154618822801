module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<%\nif (!_.size(transactions)) {\n%>\n<h5 style="width: 100%; text-align:center; line-height: 150px;">Please use the filter to find transactions.</h5>\n<%\n} else {\n%>\n<table id="transaction_table" class="table table-bordered table-striped table-hover data-table">\n\t<thead>\n\t<tr>\n\t\t<!-- Hidden stateSortPriority column -->\n\t\t<th></th>\n\n\t\t<th>ID</th>\n\t\t<th>Date Created</th>\n\t\t<th>Type</th>\n\t\t<th>State</th>\n\t\t<th>Office</th>\n\t\t<th>Credit</th>\n\t\t<th>Debit</th>\n\t\t<th>Balance</th>\n\t\t<th>Notes</th>\n\t\t<th>Order - PO #</th>\n\t</tr>\n\t</thead>\n\t<tbody>\n<%\n  _.each(transactions, function(item, i){\n%>\n\t<tr>\n\t\t<td><%= item.stateSortPriority %></td>\n\n\t\t<td><%= item.id %></td>\n\t\t<td><%= item.displayDate %></td>\n\t\t<td><%= item.displayType %></td>\n\t\t<td><%= item.state %></td>\n\t\t<td><%= item.displayOffice %></td>\n\t\t<td><%= item.displayCredit %></td>\n\t\t<td><%= item.displayDebit %></td>\n\t\t<td><%- item.displayBalance %></td>\n\t\t<td><%- item.notes %></td>\n    <td><%- item.displayOrders %></td>\n\t</tr>\n<%\n  });\n%>\n</table>\n<%\n}\n%>\n', __filename = "js/views/evopay/transaction_table.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            if (!_.size(transactions)) {
                __line = 3;
                __append('\n<h5 style="width: 100%; text-align:center; line-height: 150px;">Please use the filter to find transactions.</h5>\n');
                __line = 5;
            } else {
                __line = 7;
                __append('\n<table id="transaction_table" class="table table-bordered table-striped table-hover data-table">\n\t<thead>\n\t<tr>\n\t\t<!-- Hidden stateSortPriority column -->\n\t\t<th></th>\n\n\t\t<th>ID</th>\n\t\t<th>Date Created</th>\n\t\t<th>Type</th>\n\t\t<th>State</th>\n\t\t<th>Office</th>\n\t\t<th>Credit</th>\n\t\t<th>Debit</th>\n\t\t<th>Balance</th>\n\t\t<th>Notes</th>\n\t\t<th>Order - PO #</th>\n\t</tr>\n\t</thead>\n\t<tbody>\n');
                __line = 27;
                _.each(transactions, function(item, i) {
                    __line = 29;
                    __append("\n\t<tr>\n\t\t<td>");
                    __line = 31;
                    __append(escapeFn(item.stateSortPriority));
                    __append("</td>\n\n\t\t<td>");
                    __line = 33;
                    __append(escapeFn(item.id));
                    __append("</td>\n\t\t<td>");
                    __line = 34;
                    __append(escapeFn(item.displayDate));
                    __append("</td>\n\t\t<td>");
                    __line = 35;
                    __append(escapeFn(item.displayType));
                    __append("</td>\n\t\t<td>");
                    __line = 36;
                    __append(escapeFn(item.state));
                    __append("</td>\n\t\t<td>");
                    __line = 37;
                    __append(escapeFn(item.displayOffice));
                    __append("</td>\n\t\t<td>");
                    __line = 38;
                    __append(escapeFn(item.displayCredit));
                    __append("</td>\n\t\t<td>");
                    __line = 39;
                    __append(escapeFn(item.displayDebit));
                    __append("</td>\n\t\t<td>");
                    __line = 40;
                    __append(item.displayBalance);
                    __append("</td>\n\t\t<td>");
                    __line = 41;
                    __append(item.notes);
                    __append("</td>\n    <td>");
                    __line = 42;
                    __append(item.displayOrders);
                    __append("</td>\n\t</tr>\n");
                    __line = 44;
                });
                __line = 46;
                __append("\n</table>\n");
                __line = 48;
            }
            __line = 50;
            __append("\n");
            __line = 51;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}