template = require('./new_address_form.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Addresses.NewAddressForm = App.Views.Base.BaseView.extend

  className: 'modal hide fade'

  template: template

  initialize: (options) ->
    {
      @onSave
      @patron
      @type
    } = options
    parent.initialize.call(@, options)
    @render()

  render: () ->

    v3AddressModel = new App.Models.V3.Address(null, {
      mapping: C.Mappings.Direct.Clients.Addresses
      clientId: @patron.id
    })
    allowSaving = false

    if (@patron.type == C.Client)
      if (@type == C.Courier || @type == C.FedEx)
        allowSaving = true

    if (@type == C.LocalPickup)
      allowSaving = false

    data = {
      address: v3AddressModel.toPresenterJSON()
      allowSaving
    }
    @$el.html(
      @template(data)
    )
    # STANDARDIZED MODAL
    @$el.modal('show').on('hidden', () =>
      @remove()
    )
    $country = @$('#countrySelect')
    $state = @$('#regionSelect')
    defaultCountry = 'US'
    @localityAutocomplete = new App.Utils.LocalityAutocomplete({
      $country
      $state
      defaultCountry
    })
    @formErrors = new App.Utils.FormErrorManager($('#newAddressFormErrors'))

  events:
    'click #saveAddressButton': 'onSaveAddressButtonClick'

  onSaveAddressButtonClick: (e) ->
    data = @$('form').serializeObject()
    attrs = {
      country_code: @localityAutocomplete.getCountry()
      extended_address: data.extendedAddress
      locality: data.locality
      postal_code: data.postalCode
      region: @localityAutocomplete.getState()
      street_address: data.streetAddress
    }
    v3AddressModel = new App.Models.V3.Address(attrs, {
      mapping: C.Mappings.Direct.Clients.Addresses
      clientId: @patron.id
    })

    whenDone = () =>
      @onSave?(v3AddressModel)
      @$el.modal('hide')

    saveToServer = @$('#saveAddressCheckbox').is(':checked')
    if (v3AddressModel.isValid(true))
      if (saveToServer)
        loadingBox = new App.Utils.LoadingBox(@$('.modal-body'))
        loadingBox.startLoad()
        v3AddressModel.savePromise()
        .then (model) =>
          loadingBox.stopLoad()
          whenDone()
        .fail (error) =>
          console.error(error)
          loadingBox.stopLoad()
          @formErrors.show({ title: 'Error Saving Address', errors: error });
        .done()
      else
        whenDone()
    else
      errors = v3AddressModel.errors
      @formErrors.show({ title: 'Address Error', errors: errors });

