App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V3.RateOption = require('../../models/v3/rate_option_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V3.RateOptions = App.Collections.Base.BaseCollection.extend

  model: App.Models.V3.RateOption

  url: () ->
    if (@firstTg && @destinationAddress)
      return "/api_direct/v9/shipments/suggestion"
    else
      return '/api_direct/v9/rate_options'
  initialize: (models, options = {}) ->
    {
      @destinationAddress
      @originAddress
      @firstTg
    } = options
    parent.initialize.call(@, models, options)

  toSelectOptions: () ->
    options = {}
    @each((rateOptionModel) ->
      options[ rateOptionModel.get('_serviceType') ] = rateOptionModel.singleLine()
    )
    return options

  parse: (response, options) ->
    if (response.rate_options)
      return response.rate_options.reverse()  # Reversed so that the most expensive doesn't show up as the default
    else
      return response

  fetchOptions: () ->
    if (@destinationAddress.isNew())
      params = {
        address_attributes: @destinationAddress.toJSON({ preventWrapping: true })
      }
    else
      params = {
        address_id: @destinationAddress.id
      }

    wrapParams = false

    if (@firstTg)
      params.ticket_group_id = @firstTg.id
      params.show_all = true
    else
      wrapParams = true
      params.recipient_address_id = params.address_id
      params.recipient_address_attributes = params.address_attributes
      if (@originAddress)
        if (@originAddress.isNew())
          params.shipper = @originAddress.toJSON({ preventWrapping: true })
        else
          params.shipper_address_id = @originAddress.id

    if (wrapParams)
      params = {rate_options: [params]}

    body = {
      type: 'POST'
      data: JSON.stringify(params)
      contentType: "application/json"
    }
    return body