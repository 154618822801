module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div id="content-header" style="font-size: 16px; font-weight: 700;">\n  <div class="flextainer">\n\n    <div style="padding: 0 20px 20px 20px;">\n      <h1 style="padding: 0;">\n        <a href="/client/<%= creditMemo.holder.id %>"><%= creditMemo.holder.prettyName %></a>\n      </h1>\n      <p><%- creditMemo.holder.address.multiLine %></p>\n      <p><%= creditMemo.holder.phoneNumber %></p>\n      <p><%- creditMemo.holder.emailAddressLink %></p>\n    </div>\n\n    <div class="flex1"></div>\n\n    <div style="padding: 0 20px 20px 20px">\n      <h1 style="padding: 0;">\n        Credit Memo: #<a href="/credit_memos/<%= creditMemo.id %>"><%= creditMemo.id %></a>\n      </h1>\n      <p>Created by <a href="/order/<%= creditMemo.creatingOrderId %>">Order <%= creditMemo.creatingOrderId %></a></p>\n      <p>Created <%= App.Utils.makeTimestampHuman(creditMemo.createdAt, C.DateFormats.Long) %></p>\n      <p>Created by <%= creditMemo.createdBy %></p>\n    </div>\n\n  </div>\n</div>\n\n<div>\n  <div id="creditMemoFormErrors"></div>\n\n  <div id="paymentsContainer" style="padding: 0 10px;"></div>\n\n  <div class="flextainer" style="padding: 0 10px;">\n    <div class="flex1"></div>\n    <div id="creditMemoTotals" class="flex1"></div>\n  </div>\n\n  <div class="flextainer flexend" style="background: #f5f5f5; border-top: 1px solid #e5e5e5; padding: 20px;">\n    <a class="btn btn-info btn-large" href="/credit_memos">View All Credit Memos</a>\n  </div>\n\n</div>', __filename = "js/views/credit_memos/credit_memo_show.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div id="content-header" style="font-size: 16px; font-weight: 700;">\n  <div class="flextainer">\n\n    <div style="padding: 0 20px 20px 20px;">\n      <h1 style="padding: 0;">\n        <a href="/client/');
            __line = 6;
            __append(escapeFn(creditMemo.holder.id));
            __append('">');
            __append(escapeFn(creditMemo.holder.prettyName));
            __append("</a>\n      </h1>\n      <p>");
            __line = 8;
            __append(creditMemo.holder.address.multiLine);
            __append("</p>\n      <p>");
            __line = 9;
            __append(escapeFn(creditMemo.holder.phoneNumber));
            __append("</p>\n      <p>");
            __line = 10;
            __append(creditMemo.holder.emailAddressLink);
            __append('</p>\n    </div>\n\n    <div class="flex1"></div>\n\n    <div style="padding: 0 20px 20px 20px">\n      <h1 style="padding: 0;">\n        Credit Memo: #<a href="/credit_memos/');
            __line = 17;
            __append(escapeFn(creditMemo.id));
            __append('">');
            __append(escapeFn(creditMemo.id));
            __append('</a>\n      </h1>\n      <p>Created by <a href="/order/');
            __line = 19;
            __append(escapeFn(creditMemo.creatingOrderId));
            __append('">Order ');
            __append(escapeFn(creditMemo.creatingOrderId));
            __append("</a></p>\n      <p>Created ");
            __line = 20;
            __append(escapeFn(App.Utils.makeTimestampHuman(creditMemo.createdAt, C.DateFormats.Long)));
            __append("</p>\n      <p>Created by ");
            __line = 21;
            __append(escapeFn(creditMemo.createdBy));
            __append('</p>\n    </div>\n\n  </div>\n</div>\n\n<div>\n  <div id="creditMemoFormErrors"></div>\n\n  <div id="paymentsContainer" style="padding: 0 10px;"></div>\n\n  <div class="flextainer" style="padding: 0 10px;">\n    <div class="flex1"></div>\n    <div id="creditMemoTotals" class="flex1"></div>\n  </div>\n\n  <div class="flextainer flexend" style="background: #f5f5f5; border-top: 1px solid #e5e5e5; padding: 20px;">\n    <a class="btn btn-info btn-large" href="/credit_memos">View All Credit Memos</a>\n  </div>\n\n</div>');
            __line = 41;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}