template = require('./transactions.ejs')
addTemplate = require('./add_transaction.ejs')
returnTemplate = require('./return_money.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

TRANSACTION_TYPES = {
  PAYMENT: {}
  REFUND: {}
  RETURN: {}
}

TOOLTIPS = {
  # 3 Transaction types.
  PAYMENT: "Use this option if you want to add another payment to the Order and decrease the Order's Balance."
  REFUND: "Use this option if you're refunding money from a previous payment, but still expect to receive that payment
          in the future. Example is if you charged the wrong credit card and need to refund that card in order to
          charge another."
  RETURN: "Use this option if you need to return money and reduce the total amount of the order without expecting that
          money back. Examples of this are returning an entire order, refunding shipping fees, partial ticket prices,
          etc."

  # Options for creating returns beyond order total.
  REFUND_NOW:     "Use this option if you're returning money at this time in the form of a completed refund."
  KEEP_AS_CREDIT: "Use this option if you're not returning money at this time and instead want to keep Credit to be
                  used on a future Order or return the money in the future."
}

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Order.Transactions = App.Views.Base.BaseView.extend

  template: template

  addTemplate: addTemplate

  returnTemplate: returnTemplate

  initialize: (options = {}) ->
    {
      @$container
      @overviewView
      @v3OrderModel
    } = options
    @render()

  # Draws the transactions table.
  render: () ->
    data = {
      order: @v3OrderModel.toPresenterJSON()
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )
    @formErrors = new App.Utils.FormErrorManager(@$('#transactionFormErrors'))
    @$addTransactionContainer = @$('#addTransactionContainer')
    @delegateEvents()

  # Draws the 3 buttons to choose what transaction you want to create.
  renderAddFormStep1: () ->
    data = {
      order: @v3OrderModel.toPresenterJSON()
    }
    @$addTransactionContainer.html(
      @addTemplate(data)
    )

    @$paymentContainer = @$('#paymentContainer')
    @$returnOptionsContainer = @$('#returnOptionsContainer')
    @$addFormStep1 = @$('#addFormStep1')
    @$transactionTypeTooltip = @$('#transactionTypeTooltip')

  # Called once user picks what type of transaction to create.
  renderAddFormStep2: () ->
    @$returnOptionsContainer.empty()
    @$addFormStep1.show()

    @$transactionAmount = @$('#transactionAmount')
    @$transactionAmount.val('')

    @$newTotal = @$('#newTotal')
    @$newPayments = @$('#newPayments')
    @$newBalance = @$('#newBalance')
    @$newRefunds = @$('#newRefunds')
    @$newReturns = @$('#newReturns')

    @addFormErrors = new App.Utils.FormErrorManager(@$('#addTransactionFormErrors'))
    @addLoadingBox = new App.Utils.LoadingBox(@$addTransactionContainer)

    @addLoadingBox.startLoad()
    @buyer = @v3OrderModel.get('_buyer')
    @buyer.fetchPromise()
    .then (model) =>

      if (@addType == TRANSACTION_TYPES.RETURN)
        @renderReturnLessThanBalance()
      else
        @renderPaymentView()
      @onTransactionAmountChange()
      @addLoadingBox.stopLoad()
      @delegateEvents()

    .fail (errors) =>
      @addFormErrors.show({
        title: 'Could not fetch buyer info.'
        errors
      })
      @addLoadingBox.stopLoad()
    .done()

  # The payment input.  Eventually drawn by all transaction types except return below total.
  renderPaymentView: () ->
    disabledTypes = {}
    disabledTypes[C.TBDPayment] = true
    @paymentView = new App.Views.BuySell.Payment({
      action: C.BuySellActions.Sell
      $container: @$paymentContainer
      disabledTypes
      isRefund: (@addType == TRANSACTION_TYPES.REFUND || @addType == TRANSACTION_TYPES.RETURN)
      order: @v3OrderModel
      payingPatron: @buyer
    })

  # If user enters a return for more than order total, we present the "Refund Now" vs. "Keep as Credit" form.
  renderReturnBeyondTotalForm: () ->
    @$returnOptionsContainer.html(
      @returnTemplate()
    )
    @$returnTypeTooltip = @$('#returnTypeTooltip')
    @$returnTypeTooltip.text(TOOLTIPS.REFUND_NOW)
    if (!@paymentView)
      @renderPaymentView()
    @delegateEvents()

  # VIEW EVENTS ////////////////////////////////////////////////////////////////
  events:
    'click #addPaymentButton':                'onAddPaymentButtonClick'
    'click #returnButton':                    'onReturnButtonClick'
    'click #refundButton':                    'onRefundButtonClick'
    'keyup #transactionAmount':               'onTransactionAmountChange'
    'click #createTransactionButton':         'onCreateTransactionButtonClick'
    'click #cancelTransactionButton':         'onCancelTransactionButtonClick'
    'click #returnAsRefundButton':            'onReturnAsRefundButtonClick'
    'click #returnAsCreditButton':            'onReturnAsCreditButtonClick'
    'click .applyPendingPaymentButton':       'onApplyPendingPaymentButtonClick'
    'click .cancelPendingPaymentButton':      'onCancelPendingPaymentButtonClick'

  onAddPaymentButtonClick: (e) ->
    @addType = TRANSACTION_TYPES.PAYMENT
    @renderAddFormStep2()
    @$transactionTypeTooltip.text(TOOLTIPS.PAYMENT)

  onReturnButtonClick: (e) ->
    @addType = TRANSACTION_TYPES.RETURN
    @renderAddFormStep2()
    @$transactionTypeTooltip.text(TOOLTIPS.RETURN)

  onRefundButtonClick: (e) ->
    @addType = TRANSACTION_TYPES.REFUND
    @renderAddFormStep2()
    @$transactionTypeTooltip.text(TOOLTIPS.REFUND)

  onReturnAsRefundButtonClick: (e) ->
    @$returnTypeTooltip.text(TOOLTIPS.REFUND_NOW)
    @renderPaymentView()

  onReturnAsCreditButtonClick: (e) ->
    @$returnTypeTooltip.text(TOOLTIPS.KEEP_AS_CREDIT)
    @$paymentContainer.empty()

  onTransactionAmountChange: (e) ->
    amount = parseFloat(@$transactionAmount.val())
    if (isNaN(amount))
      amount = 0

    total = parseFloat(@v3OrderModel.get('_total'))
    payments = parseFloat(@v3OrderModel.get('_completedNonRefundPaymentsTotal'))
    pendingPayments = parseFloat(@v3OrderModel.get('_pendingNonRefundPaymentsTotal'))
    balance = parseFloat(@v3OrderModel.get('_balance'))
    pendingBalance = parseFloat(@v3OrderModel.get('_pendingBalance'))
    returns = parseFloat(@v3OrderModel.get('_returnsTotal')) + parseFloat(@v3OrderModel.get('_penaltiesTotal'))
    refunds = parseFloat(@v3OrderModel.refundsTotal())

    if (@addType == TRANSACTION_TYPES.PAYMENT)
      payments = payments + amount
      balance = balance - amount

    if (@addType == TRANSACTION_TYPES.RETURN)
      returns += amount
      # We we return more than the balance, that extra overage becomes a refund.
      if (returns > balance)
        refunds = returns - balance # Amount by which returns exceeds the balance.
        balance = 0
        @renderReturnBeyondTotalForm()
      else
        balance = balance - returns
        @renderReturnLessThanBalance()

    if (@addType == TRANSACTION_TYPES.REFUND)
      refunds += amount
      balance = balance + refunds

    # TO DISPLAY PENDING AMOUNTS:
    #payments = "#{ App.Utils.valueToCurrency(payments) } / #{ App.Utils.valueToCurrency(pendingPayments) }"
    #balance = "#{ App.Utils.valueToCurrency(balance) } / #{ App.Utils.valueToCurrency(pendingBalance) }"

    payments = "#{ App.Utils.valueToCurrency(payments) }"
    balance = "#{ App.Utils.valueToCurrency(balance) }"
    returns = "#{ App.Utils.valueToCurrency(returns) }"
    refunds = "#{ App.Utils.valueToCurrency(refunds) }"

    @$newPayments.text(payments)
    @$newBalance.text(balance)
    @$newReturns.text(returns)
    @$newRefunds.text(refunds)
    @$newTotal.text(App.Utils.valueToCurrency(total))

  renderReturnLessThanBalance: () ->
    @paymentView = null
    @$returnOptionsContainer.empty()
    @$paymentContainer.html("<div style='padding: 30px;'>The order balance will be adjusted to account for the amount being returned.</div>")

  onCreateTransactionButtonClick: (e) ->
    # Properly give feedback msg.
    @addFormErrors.reset()
    pendingBalance = @v3OrderModel.get('_pendingBalance')
    amount = parseFloat(@$('#transactionAmount').val())
    if (isNaN(amount))
      amount = 0

    paymentAttrs = {
      _amount: amount
      _orderId: @v3OrderModel.id
    }

    if (
      @addType == TRANSACTION_TYPES.PAYMENT &&
      amount > pendingBalance
    )
      @addFormErrors.show({
        title: 'Payment cannot exceed the pending balance.'
        message: 'This would result in a negative order balance.'
      })
      return

    if (@addType == TRANSACTION_TYPES.REFUND)
      paymentAttrs._isRefund = true

    if (@paymentView)

      getPaymentPromise = @paymentView.getPaymentPromise(paymentAttrs)
    else
      paymentModel = new App.Models.V3.Payment()
      paymentModel.set({
        _amount: amount
        _orderId: @v3OrderModel.id
      })
      getPaymentPromise = Q(paymentModel)

    getPaymentPromise
    .then (paymentModel) =>
      if (paymentModel.get('_amount') <= 0)
        @addFormErrors.show({
          title: 'Amount must be greater than 0.'
        })
        return

      App.ErrorManager.ignoreNext(422)
      paymentModel.purpose = C.Purposes.CreateOrderPayment

      if (@addType == TRANSACTION_TYPES.RETURN)
        paymentModel.purpose = C.Purposes.CreateOrderReturn
        paymentModel.set('_performedBy', SESSION.user_name) # Blindly trust SESSION! Awesome! /sarcasm.
        if (!@$('#returnAsCreditButton').hasClass('active'))
          paymentModel.set('_skipMemo', true)

      if (@addType == TRANSACTION_TYPES.REFUND && paymentModel.get('_refundPaymentId') && paymentModel.get('_type') == C.CreditCard)
        paymentModel.purpose = C.Purposes.CreateRefund
        if (amount > parseFloat(paymentModel.get('_refundableAmount')))
          @addFormErrors.show({
            title: "Refund amount is greater than the selected card's maximum refundable amount."
            message: "Refunds are usually limited to original charge amount."
          })
          return

      @addLoadingBox.startLoad()
      return paymentModel.savePromise()
      .then (model) =>
        return @v3OrderModel.fetchPromise()
        .then (model) =>
          @overviewView.renderTotals()
          @overviewView.renderTransactions()
          App.ErrorManager.resetTolerance(422)
          @addLoadingBox.stopLoad()
        .fail (errors) =>
          @addFormErrors.show({
            title: 'Unable to refresh order.'
            errors
          })
          App.ErrorManager.resetTolerance(422)
          @addLoadingBox.stopLoad()
        .done()

      .fail (errors) =>
        @addFormErrors.show({
          title: 'Unable to create payment.'
          errors
        })
        App.ErrorManager.resetTolerance(422)
        @addLoadingBox.stopLoad()
      .done()

    .fail (errors) =>
      # These errors will be handled and displayed by paymentView.
      @addLoadingBox.stopLoad()
    .done()

  onCancelTransactionButtonClick: (e) ->
    @$addTransactionContainer.empty()

  getButtonAndPaymentFromClick: (e) ->
    $button = @$(e.currentTarget)
    loadingButton = new App.Utils.LoadingButton($button)
    paymentId = $button.data().paymentId
    paymentCollection = @v3OrderModel.get('_payments')
    paymentModel = paymentCollection.get(paymentId)
    return {
      paymentModel
      loadingButton
    }

  onApplyPendingPaymentButtonClick: (e) ->
    {
      paymentModel
      loadingButton
    } = @getButtonAndPaymentFromClick(e)
    loadingButton.startLoad()
    @formErrors.reset()
    paymentModel.applyPromise()
    .then (model) =>
      @v3OrderModel.fetchPromise()
      .then (model) =>
        @render()
        @overviewView.renderTotals()
        @formErrors.show({
          type: 'success'
          title: 'Payment applied successfully.'
        })
        loadingButton.stopLoad()
      .fail (errors) =>
        @formErrors.show({
          title: 'Payment applied but unable to refresh order.'
          errors
        })
        loadingButton.stopLoad()
    .fail (errors) =>
      @formErrors.show({
        title: 'Unable to apply payment.'
        errors
      })
      loadingButton.stopLoad()
    .done()

  onCancelPendingPaymentButtonClick: (e) ->
    {
      paymentModel
      loadingButton
    } = @getButtonAndPaymentFromClick(e)
    loadingButton.startLoad()
    @formErrors.reset()
    paymentModel.cancelPromise()
    .then (model) =>
      @v3OrderModel.fetchPromise()
      .then (model) =>
        @render()
        @overviewView.renderTotals()
        @formErrors.show({
          type: 'success'
          title: 'Payment cancelled successfully.'
        })
        loadingButton.stopLoad()
      .fail (errors) =>
        @formErrors.show({
          title: 'Payment canceled but unable to refresh order.'
          errors
        })
        loadingButton.stopLoad()
    .fail (errors) =>
      @formErrors.show({
        title: 'Unable to apply payment.'
        errors
      })
      loadingButton.stopLoad()
    .done()
  #/////////////////////////////////////////////////////////////////////////////