App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.EventPin = App.Models.Base.V3BaseModel.extend

  url: () ->
    if (@purpose == C.Purposes.DestroyEventPin)
      return "/api_direct/v9/pins/destroy_by_event_id/#{ @get('_event').id }"
    return parent.url.call(@)

  initialize: (attributes, options = {}) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() ==  C.Mappings.Direct.EventPins.EventPin)
      attrs._event = new App.Models.V3.Event(json.event, {
        mapping: C.Mappings.Direct.EventPins.Event
      })
      attrs._id = json.id
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._updatedAtRaw = json.updated_at
      attrs._url = json.url
      attrs._user = new App.Models.V3.User(json.user, {
        mapping: C.Mappings.Direct.EventPins.User
      })
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  toJSON: () ->
    if (@purpose == C.Purposes.DestroyEventPin)
      @purpose = null
      return {}
    else
      json = {
        pins : [{ event_id : @get('_eventId') }]
      }
      return json

  parse: (json) ->
    if (json.pins?.length)
      return json.pins[0]
    else
      return json

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {
      event: @get('_event')?.toPresenterJSON()
      id: @get('_id')
      updatedAt: @get('_updatedAt')
      updatedAtRaw: @get('_updatedAtRaw')
      url: @get('_url')
      user: @get('_user')?.toPresenterJSON()
    })
    return presented
  #///////////////////////////////////////////////////////////////////////////

  destroyPromise: () ->
    @purpose = C.Purposes.DestroyEventPin
    return parent.destroyPromise.call(@)