App.Models.Base.BaseModel = require('./base/base_model.coffee')

module.exports = App.Models.InventoryExport = App.Models.Base.BaseModel.extend

  urlRoot: "/api_direct/v9/ticket_groups/export"

  defaults:
    marking_rule: {}
    encode:       true #all api calls need this

  exportUrl: -> "#{@urlRoot}?#{$.param(@toJSON())}"
