template = require('./ticket_hold_row.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.TicketHolds.Row = App.Views.Base.BaseView.extend

  className: 'ticket-hold'

  tagName: 'tr'

  template: template

  initialize: (options) ->
    {
      @ticketHoldModel
      @ticketGroup
      @eventId
      @$container
      @ticketGroupListView
    } = options

    @ticketHoldModel.fetchPatron()
    .then (model) =>
      @render()
    .fail (error) ->
      console.error("Couldn't fetch hold patron.", error)
      @render()
    .done()

  render: () ->
    @$container.append(
      @$el.html(
        @template({
          item: @ticketHoldModel.toPresenterJSON()
          ticketGroupId: @ticketGroup.id
        })
      )
    )
    @$el.attr('data-ticket-hold-id', @ticketHoldModel.get('id'))
    @$el.attr('data-ticket-group-id', @ticketHoldModel.get('ticket_group_ids')[0])
    App.Utils.notesPopover(@$('.popover_notes'), @ticketHoldModel)
    @$el.data('hasMenu', false)
    @delegateEvents()
    return @$el

  events:
    'click button.dropdown-toggle':      'onContextButtonClick'
    'click .context-menu .edit':         'onContextEditClick'
    'click .context-menu .sell':         'onContextSellClick'
    'click .context-menu .release_hold': 'onContextReleaseHoldClick'
    'click .context-menu .take':         'onContextTakeClick'

  onContextEditClick: (e) ->
    editView = new App.Views.TicketHolds.Edit({
      model: @ticketHoldModel
    })
    editView.on("ticket-hold:updated", () =>
      @render()
    )
    editView.render()

  onContextReleaseHoldClick: (e) ->
    @ticketHoldModel.release({
      ticketGroupId: @ticketGroup.id
      @eventId
      callback: (err, model) =>
        if (err)
          $.gritter.add({
            title          : 'Failed'
            text           : err.message
          })
        else
          #@ticketGroupListView.fetchTickets()
          App.Controllers.ticketGroupController.getTicketGroupsListView()?.logicMediator('REFRESH_BUTTON')
          $.gritter.add({
            title          : 'Successful'
            text           : 'Ticket group was released.'
          })
    })

  onContextTakeClick: (e) ->
    loading = new App.Views.Shared.LoadingView()
    takenData = {
      lowSeat: @ticketHoldModel.get('seats')[@ticketHoldModel.get('quantity') - 1 ]
      quantity: @ticketHoldModel.get('quantity')
      heldForId: @ticketHoldModel.get('held_for_id')
      heldForType: @ticketHoldModel.get('held_for_type')
      holdUntil: moment(new Date()).utc().format('YYYY-MM-DD HH:mm:ss UTC').toString()
      price: @ticketHoldModel.get('price')
    }
    loading.render()
    ticketGroup = new App.Models.TicketGroup({
      id: @ticketGroup.id
      event: {
        id: @eventId
      }
    })
    $.when(ticketGroup.take(takenData)).then((ticketGroup, response, data) =>
      loading.remove()
      @remove()
    )

  onContextSellClick: (e) ->
    $tr = @$(e.target).closest('tr.ticket-hold')
    data = $tr.data()
    ticketGroupId = data.ticketGroupId
    options = {
      heldForType:  @ticketHoldModel.get('_heldForType')
      heldForId:    @ticketHoldModel.get('_heldForId')
      heldPrice:    @ticketHoldModel.get('_price')
      heldSeats:    @ticketHoldModel.get('_seats')
      quantity:     @ticketHoldModel.get('_quantity')
      ticketGroupId
    }
    App.Controllers.buySellController.sellHoldOrTake(options)

  onContextButtonClick: (e) ->
    $tr = @$(e.target).closest('tr.ticket-hold')
    data = $tr.data()
    hasMenu = data.hasMenu
    if (!hasMenu)
      $tr.data('has-menu', 1)
      html = ['<ul class="dropdown-menu context-menu" style="cursor: pointer">']
      html.push('<li><a class="edit">Edit</a></li>')
      html.push('<li><a class="sell">Sell</a></li>')
      html.push('<li><a class="release_hold">Release Hold</a></li>')
      html.push('<li><a class="take">Take</a></li>')
      html.push('</ul>')
      $tr.find('.btn-group').append(html.join(''))