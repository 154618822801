template = require('./orders_list.ejs')
App.Views.Base.FilteredTable = require('../base/filtered_table_view.coffee')

# These are the url encodings that become the route.
# I.E. /orders/filter/eyJzZWxsZXJfc3RhdGUiOiJwZW5kaW5nIn0%3D
# It's the filter setttings as a json object that's base64 encoded.
# See App.Utils.encodeFilterParams()
#
# Encode with encodeURIComponent(btoa(JSON.stringify(paramsObject)))
# Decode with JSON.parse(atob(decodeURIComponent(encodedFilterParamsString)))
PREFAB_FILTER_ENCODINGS = {
  'All Orders': {
    # encodeURIComponent(btoa(JSON.stringify({})))
    'All':          'e30='
    # encodeURIComponent(btoa(JSON.stringify({ 'created_at.gte': 'TodayBegin', 'created_at.lte': 'TodayEnd' })))
    'Today':        'eyJjcmVhdGVkX2F0Lmd0ZSI6IlRvZGF5QmVnaW4iLCJjcmVhdGVkX2F0Lmx0ZSI6IlRvZGF5RW5kIn0='
    # encodeURIComponent(btoa(JSON.stringify({ 'created_at.gte': 'Begin7', 'created_at.lte': 'TodayEnd' })))
    'Last 7 Days':  'eyJjcmVhdGVkX2F0Lmd0ZSI6IkJlZ2luNyIsImNyZWF0ZWRfYXQubHRlIjoiVG9kYXlFbmQifQ=='
    # encodeURIComponent(btoa(JSON.stringify({ 'created_at.gte': 'Begin30', 'created_at.lte': 'TodayEnd' })))
    'Last 30 Days': 'eyJjcmVhdGVkX2F0Lmd0ZSI6IkJlZ2luMzAiLCJjcmVhdGVkX2F0Lmx0ZSI6IlRvZGF5RW5kIn0='
    # encodeURIComponent(btoa(JSON.stringify({ 'created_at.gte': 'Begin60', 'created_at.lte': 'TodayEnd' })))
    'Last 60 Days': 'eyJjcmVhdGVkX2F0Lmd0ZSI6IkJlZ2luNjAiLCJjcmVhdGVkX2F0Lmx0ZSI6IlRvZGF5RW5kIn0='
  }
  'Pending Orders': {
    # {seller_state: "pending"}
    'Received/Incoming':  'eyJzZWxsZXJfc3RhdGUiOlt7ImlkIjoicGVuZGluZyIsInRleHQiOiJQZW5kaW5nIn1dfQ=='
    # {seller_state: "pending_substitution"}
    'Pending Subs':       'eyJzZWxsZXJfc3RhdGUiOlt7ImlkIjoicGVuZGluZ19zdWJzdGl0dXRpb24iLCJ0ZXh0IjoiUGVuZGluZyBTdWJzdGl0dXRpb24ifV19'
    # {buyer_state: "pending", seller_state: "accepted"}
    'Sent':               'eyJidXllcl9zdGF0ZSI6W3siaWQiOiJwZW5kaW5nIiwidGV4dCI6IlBlbmRpbmcifV0sInNlbGxlcl9zdGF0ZSI6W3siaWQiOiJhY2NlcHRlZCIsInRleHQiOiJBY2NlcHRlZCJ9XX0='
  }
  'Pending Delivery': {
    # {buyer_state: "accepted", seller_state: "accepted"}
    'All':        'eyJidXllcl9zdGF0ZSI6W3siaWQiOiJhY2NlcHRlZCIsInRleHQiOiJBY2NlcHRlZCJ9XSwic2VsbGVyX3N0YXRlIjpbeyJpZCI6ImFjY2VwdGVkIiwidGV4dCI6IkFjY2VwdGVkIn1dfQ=='
    # {buyer_state: "accepted", seller_state: "accepted", delivery_type: "FedEx"}
    'FedEx':      'eyJidXllcl9zdGF0ZSI6W3siaWQiOiJhY2NlcHRlZCIsInRleHQiOiJBY2NlcHRlZCJ9XSwic2VsbGVyX3N0YXRlIjpbeyJpZCI6ImFjY2VwdGVkIiwidGV4dCI6IkFjY2VwdGVkIn1dLCJkZWxpdmVyeV90eXBlIjpbeyJpZCI6ImZlZF9leCIsInRleHQiOiJGZWRFeCJ9XX0='
    'Eticket':    'eyJidXllcl9zdGF0ZSI6W3siaWQiOiJhY2NlcHRlZCIsInRleHQiOiJBY2NlcHRlZCJ9XSwic2VsbGVyX3N0YXRlIjpbeyJpZCI6ImFjY2VwdGVkIiwidGV4dCI6IkFjY2VwdGVkIn1dLCJkZWxpdmVyeV90eXBlIjpbeyJpZCI6ImV0aWNrZXQiLCJ0ZXh0IjoiRXRpY2tldCJ9XX0='
    'Will Call':  'eyJidXllcl9zdGF0ZSI6W3siaWQiOiJhY2NlcHRlZCIsInRleHQiOiJBY2NlcHRlZCJ9XSwic2VsbGVyX3N0YXRlIjpbeyJpZCI6ImFjY2VwdGVkIiwidGV4dCI6IkFjY2VwdGVkIn1dLCJkZWxpdmVyeV90eXBlIjpbeyJpZCI6IndpbGxfY2FsbCIsInRleHQiOiJXaWxsIENhbGwifV19'
  }
  'Unfilled Orders': {
    # encodeURIComponent(btoa(JSON.stringify({ buyer_state: [{ id: 'accepted', text: 'Accepted' }], seller_state: [{ id: 'accepted', text: 'Accepted' }], needs_sub: true })))
    'All':          'eyJidXllcl9zdGF0ZSI6W3siaWQiOiJhY2NlcHRlZCIsInRleHQiOiJBY2NlcHRlZCJ9XSwic2VsbGVyX3N0YXRlIjpbeyJpZCI6ImFjY2VwdGVkIiwidGV4dCI6IkFjY2VwdGVkIn1dLCJuZWVkc19zdWIiOnRydWV9'
    # encodeURIComponent(btoa(JSON.stringify({'event_date.gte': 'TodayBegin', 'event_date.lte': 'End7', needs_sub: true, seller_state: [{ id: 'accepted', text: 'Accepted' }], buyer_state: [{ id: 'accepted', text: 'Accepted' }] })))
    'Next 7 Days':  'eyJldmVudF9kYXRlLmd0ZSI6IlRvZGF5QmVnaW4iLCJldmVudF9kYXRlLmx0ZSI6IkVuZDciLCJuZWVkc19zdWIiOnRydWUsInNlbGxlcl9zdGF0ZSI6W3siaWQiOiJhY2NlcHRlZCIsInRleHQiOiJBY2NlcHRlZCJ9XSwiYnV5ZXJfc3RhdGUiOlt7ImlkIjoiYWNjZXB0ZWQiLCJ0ZXh0IjoiQWNjZXB0ZWQifV19'
    # encodeURIComponent(btoa(JSON.stringify({'event_date.gte': 'TodayBegin', 'event_date.lte': 'End30', needs_sub: true, seller_state: [{ id: 'accepted', text: 'Accepted' }], buyer_state: [{ id: 'accepted', text: 'Accepted' }] })))
    'Next 30 Days': 'eyJldmVudF9kYXRlLmd0ZSI6IlRvZGF5QmVnaW4iLCJldmVudF9kYXRlLmx0ZSI6IkVuZDMwIiwibmVlZHNfc3ViIjp0cnVlLCJzZWxsZXJfc3RhdGUiOlt7ImlkIjoiYWNjZXB0ZWQiLCJ0ZXh0IjoiQWNjZXB0ZWQifV0sImJ1eWVyX3N0YXRlIjpbeyJpZCI6ImFjY2VwdGVkIiwidGV4dCI6IkFjY2VwdGVkIn1dfQ=='
    # encodeURIComponent(btoa(JSON.stringify({'event_date.gte': 'TodayBegin', 'event_date.lte': 'End60', needs_sub: true, seller_state: [{ id: 'accepted', text: 'Accepted' }], buyer_state: [{ id: 'accepted', text: 'Accepted' }] })))
    'Next 60 Days': 'eyJldmVudF9kYXRlLmd0ZSI6IlRvZGF5QmVnaW4iLCJldmVudF9kYXRlLmx0ZSI6IkVuZDYwIiwibmVlZHNfc3ViIjp0cnVlLCJzZWxsZXJfc3RhdGUiOlt7ImlkIjoiYWNjZXB0ZWQiLCJ0ZXh0IjoiQWNjZXB0ZWQifV0sImJ1eWVyX3N0YXRlIjpbeyJpZCI6ImFjY2VwdGVkIiwidGV4dCI6IkFjY2VwdGVkIn1dfQ=='
  }
  'Unpaid Orders': {
    # encodeURIComponent(btoa(JSON.stringify({ with_balance: true, seller_state: [{ id: 'accepted', text: 'Accepted' }, { id: 'completed', text: 'Completed' }] } )))
    'All':         'eyJ3aXRoX2JhbGFuY2UiOnRydWUsInNlbGxlcl9zdGF0ZSI6W3siaWQiOiJhY2NlcHRlZCIsInRleHQiOiJBY2NlcHRlZCJ9LHsiaWQiOiJjb21wbGV0ZWQiLCJ0ZXh0IjoiQ29tcGxldGVkIn1dfQ=='
    # encodeURIComponent(btoa(JSON.stringify({ 'created_at.gte': 'Begin7', 'created_at.lte': 'TodayEnd', with_balance: true, seller_state: [{ id: 'accepted', text: 'Accepted' }, { id: 'completed', text: 'Completed' }] } )))
    'Last 7 Days': 'eyJjcmVhdGVkX2F0Lmd0ZSI6IkJlZ2luNyIsImNyZWF0ZWRfYXQubHRlIjoiVG9kYXlFbmQiLCJ3aXRoX2JhbGFuY2UiOnRydWUsInNlbGxlcl9zdGF0ZSI6W3siaWQiOiJhY2NlcHRlZCIsInRleHQiOiJBY2NlcHRlZCJ9LHsiaWQiOiJjb21wbGV0ZWQiLCJ0ZXh0IjoiQ29tcGxldGVkIn1dfQ=='
    # encodeURIComponent(btoa(JSON.stringify({ 'created_at.gte': 'Begin30', 'created_at.lte': 'TodayEnd', with_balance: true, seller_state: [{ id: 'accepted', text: 'Accepted' }, { id: 'completed', text: 'Completed' }] } )))
    'Last 30 Days': 'eyJjcmVhdGVkX2F0Lmd0ZSI6IkJlZ2luMzAiLCJjcmVhdGVkX2F0Lmx0ZSI6IlRvZGF5RW5kIiwid2l0aF9iYWxhbmNlIjp0cnVlLCJzZWxsZXJfc3RhdGUiOlt7ImlkIjoiYWNjZXB0ZWQiLCJ0ZXh0IjoiQWNjZXB0ZWQifSx7ImlkIjoiY29tcGxldGVkIiwidGV4dCI6IkNvbXBsZXRlZCJ9XX0='
    # encodeURIComponent(btoa(JSON.stringify({ 'created_at.gte': 'Begin60', 'created_at.lte': 'TodayEnd', with_balance: true, seller_state: [{ id: 'accepted', text: 'Accepted' }, { id: 'completed', text: 'Completed' }] } )))
    'Last 60 Days': 'eyJjcmVhdGVkX2F0Lmd0ZSI6IkJlZ2luNjAiLCJjcmVhdGVkX2F0Lmx0ZSI6IlRvZGF5RW5kIiwid2l0aF9iYWxhbmNlIjp0cnVlLCJzZWxsZXJfc3RhdGUiOlt7ImlkIjoiYWNjZXB0ZWQiLCJ0ZXh0IjoiQWNjZXB0ZWQifSx7ImlkIjoiY29tcGxldGVkIiwidGV4dCI6IkNvbXBsZXRlZCJ9XX0='
  }
  'TEvo Orders': {
    # encodeURIComponent(btoa(JSON.stringify({ buyer_id: { id: C.TicketEvolutionOfficeId, text: 'Ticket Evolution - Main Office' }})))
    'All':         'eyJidXllcl9pZCI6eyJpZCI6NiwidGV4dCI6IlRpY2tldCBFdm9sdXRpb24gLSBNYWluIE9mZmljZSJ9fQ=='
    # encodeURIComponent(btoa(JSON.stringify({ buyer_id: { id: C.TicketEvolutionOfficeId, text: 'Ticket Evolution - Main Office' }, 'created_at.gte': 'TodayBegin', 'created_at.lte': 'TodayEnd' })))
    'Today':        'eyJidXllcl9pZCI6eyJpZCI6NiwidGV4dCI6IlRpY2tldCBFdm9sdXRpb24gLSBNYWluIE9mZmljZSJ9LCJjcmVhdGVkX2F0Lmd0ZSI6IlRvZGF5QmVnaW4iLCJjcmVhdGVkX2F0Lmx0ZSI6IlRvZGF5RW5kIn0='
    # encodeURIComponent(btoa(JSON.stringify({ buyer_id: { id: C.TicketEvolutionOfficeId, text: 'Ticket Evolution - Main Office' }, 'created_at.gte': 'Begin7', 'created_at.lte': 'TodayEnd' })))
    'Last 7 Days': 'eyJidXllcl9pZCI6eyJpZCI6NiwidGV4dCI6IlRpY2tldCBFdm9sdXRpb24gLSBNYWluIE9mZmljZSJ9LCJjcmVhdGVkX2F0Lmd0ZSI6IkJlZ2luNyIsImNyZWF0ZWRfYXQubHRlIjoiVG9kYXlFbmQifQ=='
    # encodeURIComponent(btoa(JSON.stringify({ buyer_id: { id: C.TicketEvolutionOfficeId, text: 'Ticket Evolution - Main Office' }, 'created_at.gte': 'Begin30', 'created_at.lte': 'TodayEnd' })))
    'Last 30 Days': 'eyJidXllcl9pZCI6eyJpZCI6NiwidGV4dCI6IlRpY2tldCBFdm9sdXRpb24gLSBNYWluIE9mZmljZSJ9LCJjcmVhdGVkX2F0Lmd0ZSI6IkJlZ2luMzAiLCJjcmVhdGVkX2F0Lmx0ZSI6IlRvZGF5RW5kIn0='
    # encodeURIComponent(btoa(JSON.stringify({ buyer_id: { id: C.TicketEvolutionOfficeId, text: 'Ticket Evolution - Main Office' }, 'created_at.gte': 'Begin60', 'created_at.lte': 'TodayEnd' })))
    'Last 60 Days': 'eyJidXllcl9pZCI6eyJpZCI6NiwidGV4dCI6IlRpY2tldCBFdm9sdXRpb24gLSBNYWluIE9mZmljZSJ9LCJjcmVhdGVkX2F0Lmd0ZSI6IkJlZ2luNjAiLCJjcmVhdGVkX2F0Lmx0ZSI6IlRvZGF5RW5kIn0='
  }
}

parent = App.Views.Base.FilteredTable.prototype
module.exports = App.Views.Orders.OrdersList = App.Views.Base.FilteredTable.extend

  template: template

  initialize: (options = {}) ->
    {
      @model
      @$container
      @ordersOrPOs
    } = options
    @isPO = (options.ordersOrPOs == 'pos')
    @listenTo(@model, 'change:orders', @onOrdersChange)
    @listenTo(@model, 'change', @onModelChange)
    @render(options.isGoingToFetch)

  render: (isGoingToFetch) ->
    data = {
      @isPO
      PREFAB_FILTER_ENCODINGS
    }
    @$container.html(
      @$el.html(
        @template(
          data
        )
      )
    )
    @delegateEvents()

    $content = @$('#filter_content')
    $collapseButton = @$('#filter_collapse_button')
    isHorizontalSplit = true
    onChange = () =>
      @renderTable()
    options = {
      $content
      $collapseButton
      isHorizontalSplit
      onChange
    }
    @collapsibleFilters = new App.Utils.CollapsibleWidget(options)

    @buttonLoader = new App.Utils.LoadingButton(@$('#apply_filter_button'))
    App.Utils.dateRangePicker(@$('#date_range'), @onDateRangeChanged, @, C.DatePickerConfig.Past)
    if(SESSION.role.cannot(App.Access.Resources.Orders.OrdersListView.SimpleFilters))
      App.Utils.dateRangePicker(@$('#event_date_range'), @onEventDateRangeChanged, @, C.DatePickerConfig.Future)
    if(SESSION.role.can(App.Access.Resources.Inventory.AllTickets))
      @initializeAutocomplete()
    @initializeDropdowns()
    @renderTable(true)
    if (isGoingToFetch)
      @startLoad()
    @reflectPrefabFilter()
    # Hide parent filters for travel agents
    if (SESSION.role.can(App.Access.Resources.Orders.OrdersListView.SimpleFilters))
      @highlightPrefabFilter("All Orders")
      @$('#prefabFilterParent').hide()

    return @$el

  # VIEW EVENTS //////////////////////////////////////////////////////////////
  events:
    'click #apply_filter_button':     'onApplyFilterButtonClick'
    'click #reset_filter_button':     'onResetFilterButtonClick'
    'click #printButton':             'onPrintButtonClick'
    'click #exportButton':            'onExportButtonClick'
    'click #prefabFilterParent li':   'onPrefabFilterParentClick'
    'click #extraFiltersToggle':      'onExtraFiltersToggleClick'

  onResetFilterButtonClick: () ->
    App.Controllers.OrderController.resetIndex(@ordersOrPOs)

  onApplyFilterButtonClick: () ->
    @startLoad()
    App.Controllers.OrderController.filterIndex(@ordersOrPOs)

  onDateRangeChanged: (startDate, endDate) ->
    @dateRangeFilter = {
      start: startDate
      end: endDate
    }

  onEventDateRangeChanged: (startDate, endDate) ->
    @eventDateRangeFilter = {
      start: startDate
      end: endDate
    }

  onPrintButtonClick: (e) ->
    header = if @isPO then "Purchase Orders" else "Orders"
    subHeader = ""
    if (@dateRangeFilter && @dateRangeFilter.start && @dateRangeFilter.end)
      subHeader = "#{ App.Utils.makeTimestampHuman(@dateRangeFilter.start, C.DateFormats.LongNoTime) } to #{App.Utils.makeTimestampHuman(@dateRangeFilter.end, C.DateFormats.LongNoTime) }"
    options = {
      header
      subHeader
      ignoreCols: {
        9: true
      }
    }
    App.Exporter.printDataTable(@table, options)

  onExportButtonClick: (e) ->

    generateCSV = () =>
      filePrefix = if @isPO then "pos" else "orders"
      filename = "#{ filePrefix }_#{ App.Utils.makeTimestampHuman(Date.now(), C.DateFormats.File) }"
      options = {
        filename
        ignoreCols: {
          9: true
        }
      }
      App.Exporter.csvDataTable(@table, options)

    startFetch = () =>
      @tableLoader.startLoad()
      options = {
        data: @getFilterParamsOfCurrentResults()
        success: (collection, response, options) =>
          @tableLoader.stopLoad()
          @onOrdersChange()
          generateCSV()
      }
      @model.fetchAll(options)

    WARN_LIMIT = 500
    total = @model.get('total_entries')
    mins = Math.floor(total / 1000)
    if (total > WARN_LIMIT)
      confirm = new App.Views.Shared.BasicModal({
        isConfirmation: true
        header: "This could take a while"
        message: "This will fetch all #{ total } entries from the server (taking up to #{ mins } minutes).<br/>Do you want to proceed?"
        onYes: () ->
          confirm.$el.modal('hide')
          startFetch()
      })
    else
      startFetch()

  onExtraFiltersToggleClick: (e) ->
    @$('#extraFilters').toggle()
    if (@$('#extraFiltersToggle i').hasClass('fa-solid fa-chevron-down'))
      @$('#extraFiltersToggle i').removeClass('fa-solid fa-chevron-down')
      @$('#extraFiltersToggle i').addClass('fa-solid fa-chevron-up')
    else
      @$('#extraFiltersToggle i').removeClass('fa-solid fa-chevron-up')
      @$('#extraFiltersToggle i').addClass('fa-solid fa-chevron-down')

  #///////////////////////////////////////////////////////////////////////////

  # COLLECTION EVENTS ////////////////////////////////////////////////////////
  onOrdersChange: () ->
    @renderTable()

  onModelChange: () ->
    # Lazy man binding.  Not good practice.
    m = @model
    @$('#orders_display').text(m.get('total_entries'))
    @$('#total_display').text(App.Utils.valueToCurrency(m.get('total_sum')))
    @$('#balance_display').text(App.Utils.valueToCurrency(m.get('balance_sum')))
    @$('#commission_display').text(App.Utils.valueToCurrency(m.get('commissions_sum')))

  renderTable: (isInitialRender) ->
    if (@table)
      @table.remove()
    @table = new App.Views.Orders.OrderTable({
      filterView: @
      $container: @$('#table_container')
      collection: @model.get('orders')
      isPO: @isPO
      @model
    })
    @tableLoader = new App.Utils.LoadingBox(@table.$el)
    if (!isInitialRender)
      @stopLoad()
  #///////////////////////////////////////////////////////////////////////////

  filterParamMappings: () ->

    baseMappings = {
      'created_at':
        type: C.FilterTypes.DateRange
        el: '#date_range'
        val: @dateRangeFilter
      'stored_total.gte':
        type: C.FilterTypes.Text
        el: '#amount_min_text'
      'stored_total.lte':
        type: C.FilterTypes.Text
        el: '#amount_max_text'
      'reference':
        type: C.FilterTypes.Text
        el: '#reference_text'
      'id':
        type: C.FilterTypes.Text
        el: '#order_id_text'
      'order_group_id':
        type: C.FilterTypes.Text
        el: '#order_group_id_text'
      'created_by_token':
        type: C.FilterTypes.Select2
        el: '#created_by_autocomplete'
        sendsId: true
        ignoreAll: true
        isMultiple: true
      'needs_eticket':
        type: C.FilterTypes.Select2Boolean
        el: '#eticket_select'
        trueVal: 'missing'
        falseVal: 'not_missing'
        elseFalse: false
      'buyer_state':
        type: C.FilterTypes.Select2
        el: '#buyer_state_select'
        ignoreAll: true
        isMultiple: true
      'seller_state':
        type: C.FilterTypes.Select2
        el: '#seller_state_select'
        ignoreAll: true
        isMultiple: true
      'delivery_type':
        type: C.FilterTypes.Select2
        el: '#delivery_type_select'
        ignoreAll: true
        isMultiple: true
      'event_date':
        type: C.FilterTypes.DateRange
        el: '#event_date_range'
        val: @eventDateRangeFilter
      'needs_sub':
        type: C.FilterTypes.Select2Boolean
        el: '#needs_sub_select'
        trueVal: 'yes'
        falseVal: 'no'
        elseFalse: false
      'venue_id':
        type: C.FilterTypes.Select2
        el: '#venue_autocomplete'
        sendsId: true
      'performer_id':
        type: C.FilterTypes.Select2
        el: '#performer_autocomplete'
        sendsId: true
    }

    if(SESSION.role.cannot(App.Access.Resources.Orders.OrdersListView.SimpleFilters))
      extendedFilterMappings = {
        'with_balance':
          type: C.FilterTypes.Select2Boolean
          el: '#balance_select'
          trueVal: 'with'
          elseFalse: false
        'without_balance':
          type: C.FilterTypes.Select2Boolean
          el: '#balance_select'
          trueVal: 'without'
          elseFalse: false
        # Note that to get unfulfilled spec orders, you will need to pass (:spec => true) and (:spec_fulfilled => false).
        # Fulfilled spec can just be (:spec_fulfilled => true).
        # WARNING: We're using 2 mappings on the same el.
        #  It's a little hacky but works in this instance.
        #  See filtered_table.js for the details.
        'spec_fulfilled':
          type: C.FilterTypes.Select2Boolean
          el: '#spec_select'
          trueVal: 'filled'
          elseFalse: false
          falseVal: 'not_filled'
        'spec':
          type: C.FilterTypes.Select2Boolean
          el: '#spec_select'
          trueVal: 'not_filled'
        'eticket_not_downloaded':
          type: C.FilterTypes.Select2Boolean
          el: '#eticket_downloaded_select'
          trueVal: 'yes' # Reversing the logic a little.
          elseFalse: false
      }
    else
      extendedFilterMappings ={}




    if (@isPO)
      moreMappings = {
        'seller_id':
          type: C.FilterTypes.Autocomplete
          el: '#customer_autocomplete'
          val: @filterCustomerId
          sendsId: true
        'consignment':
          type: C.FilterTypes.Select2Boolean
          el: '#consignment_select'
          trueVal: 'consignment'
          elseFalse: false
          falseVal: 'not_consignment'
      }
    else if (SESSION.role.can(App.Access.Resources.Inventory.AllTickets))
      moreMappings = {
        'buyer_id':
          type: C.FilterTypes.Autocomplete
          el: '#customer_autocomplete'
          val: @filterCustomerId
          sendsId: true
      }

    _.extend(baseMappings,extendedFilterMappings, moreMappings)
    return baseMappings

  startLoad: () ->
    @buttonLoader.startLoad()
    @tableLoader.startLoad()

  stopLoad: () ->
    @buttonLoader.stopLoad()
    @tableLoader.stopLoad()

  initializeAutocomplete: () ->
    callback = (itemId) =>
      @filterCustomerId = itemId
    $el = @$('#customer_autocomplete')
    shouldReturnId = true
    options = {
      $el
      shouldReturnId
      callback
    }
    App.Utils.clientOfficeAutocomplete(options)

  initializeDropdowns: () ->
    options = C.Options.Order.States
    options = _.map options, (val, key) ->
      return { id: key, text: val }
    @$('#buyer_state_select').select2({
      data: options
      multiple: true
      initSelection: (element, callback) ->
        data = {
          id: element.val()
          text: C.Options.Order.States[element.val()]
        }
        callback(data)
    })
    #@$('#buyer_state_select').select2('val', ['all'])

    @$('#seller_state_select').select2({
      data: options
      multiple: true
      initSelection: (element, callback) ->
        data = {
          id: element.val()
          text: C.Options.Order.States[element.val()]
        }
        callback(data)
    })
    #@$('#seller_state_select').select2('val', ['all'])


    options = C.Options.Order.Balance
    options = _.map options, (val, key) ->
      return { id: key, text: val }
    @$('#balance_select').select2({
      data: options
      initSelection: (element, callback) ->
        data = {
          id: element.val()
          text: C.Options.Order.Balance[element.val()]
        }
        callback(data)
    })
    @$('#balance_select').select2('val', 'all')

    options = C.Options.Order.Consignment
    options = _.map options, (val, key) ->
      return { id: key, text: val }
    @$('#consignment_select').select2({
      data: options
      initSelection: (element, callback) ->
        data = {
          id: element.val()
          text: C.Options.Order.Consignment[element.val()]
        }
        callback(data)
    })
    @$('#consignment_select').select2('val', 'all')

    options = C.Options.Order.SpecFilled
    options = _.map options, (val, key) ->
      return { id: key, text: val }
    @$('#spec_select').select2({
      data: options
      initSelection: (element, callback) ->
        data = {
          id: element.val()
          text: C.Options.Order.SpecFilled[element.val()]
        }
        callback(data)
    })
    @$('#spec_select').select2('val', 'all')

    options = C.Options.Order.NeedsSub
    options = _.map options, (val, key) ->
      return { id: key, text: val }
    @$('#needs_sub_select').select2({
      data: options
      initSelection: (element, callback) ->
        data = {
          id: element.val()
          text: C.Options.Order.NeedsSub[element.val()]
        }
        callback(data)
    })
    @$('#needs_sub_select').select2('val', 'all')

    options = C.Options.Order.EticketsMissing
    options = _.map options, (val, key) ->
      return { id: key, text: val }
    @$('#eticket_select').select2({
      data: options
      initSelection: (element, callback) ->
        data = {
          id: element.val(),
          text: C.Options.Order.EticketsMissing[element.val()]
        }
        callback(data)
    })
    @$('#eticket_select').select2('val', 'all')

    options = C.Options.Order.Delivery
    options = _.map options, (val, key) ->
      return { id: key, text: val }
    @$('#delivery_type_select').select2({
      data: options
      multiple: true
      initSelection: (element, callback) ->
        data = {
          id: element.val()
          text: C.Options.Order.Delivery[element.val()]
        }
        callback(data)
    })
    #@$('#delivery_type_select').select2('val', 'All')

    options = C.Options.Order.EticketsNotDownloaded
    options = _.map options, (val, key) ->
      return { id: key, text: val }
    @$('#eticket_downloaded_select').select2({
      data: options
      initSelection: (element, callback) ->
        data = {
          id: element.val()
          text: C.Options.Order.EticketsNotDownloaded[element.val()]
        }
        callback(data)
    })
    @$('#eticket_downloaded_select').select2('val', 'all')
    if (SESSION.role.can(App.Access.Resources.Inventory.AllTickets))
      App.Utils.apiTokenDropdown(@$('#created_by_autocomplete'))
    sendsId = true
    App.Utils.venueAutocomplete(@$('#venue_autocomplete'), sendsId)
    App.Utils.performerAutocomplete(@$('#performer_autocomplete'), sendsId)

  onPrefabFilterParentClick: (e) ->
    $button = $(e.currentTarget)
    parentFilter = $button.data().parentFilter
    @highlightPrefabFilter(parentFilter)

  reflectPrefabFilter: () ->
    url = window.document.location.href
    currentFilter = url.split('/').pop()
    if (currentFilter != 'order')
      for own parentFilter, childFilters of PREFAB_FILTER_ENCODINGS
        for own childFilter, filterEncoding of childFilters
          if (currentFilter == filterEncoding)
            @highlightPrefabFilter(parentFilter, childFilter)


  # Can we do this shit in render?
  highlightPrefabFilter: (parent, child = null) ->
    $parentFilters = @$('#prefabFilterParent li').removeClass('active')
    $childFilters = @$('.prefabFilterChild').hide()
    $parentButton = @$("#prefabFilterParent li[data-parent-filter='#{ parent }']")
    $parentButton.addClass('active')
    $children = @$(".prefabFilterChild[data-children-for-filter='#{ parent }']").show()
    $child = @$(".prefabFilterChild[data-children-for-filter='#{ parent }'] li[data-child-filter='#{ child }']").addClass('active')
