template = require('./eticket_management_row.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.TicketGroups.EticketManagementRow = App.Views.Base.BaseView.extend

  tagName: 'tr'

  className: 'eticketRow'

  template: template

  initialize: (options = {}) ->
    {
      @$container
      @parentView
      @v3EticketModel
      @v3TicketGroupModel
      @v3TicketModel
      @rowNumber
      @isSkeletonMode
    } = options

  renderPromise: () ->
    deferred = Q.defer()

    data = {
      eticket: @v3EticketModel?.toPresenterJSON() || {}
      ticketGroup: @v3TicketGroupModel.toPresenterJSON()
      ticket: @v3TicketModel?.toPresenterJSON()
      @rowNumber
      @isSkeletonMode
    }

    # APPEND NOT .html()
    @$container.append(
      @$el.html(
        @template(data)
      )
    )

    if (!@isSkeletonMode)
      $els = @$('.barcodePreview')
      $els = $els.add(@$('.seatPreview'))
      loadingBoxes = new App.Utils.LoadingBox($els)
      loadingBoxes.startLoad()
      url = @v3EticketModel.get('_thumbnail')
      img = new Image()
      img.onload = () =>
        height = img.height
        width = img.width
        ratio = width / height
        previewHeight = 200
        previewWidth = ratio * previewHeight
        $pdfPreview = $("<img client='#{ url }' width='#{ previewWidth }' height='#{ previewHeight }'/>")
        @$('.pdfContainer').css({
          width: "#{ previewWidth }px"
        }).html($pdfPreview)
        @$('.barcodePreview').css({ width: "#{ width }px", height: "#{ height }px" })
        @$('.seatPreview').css({ width: "#{ width }px", height: "#{ height }px" })
        loadingBoxes.stopLoad()
        deferred.resolve()

      img.crossOrigin = 'anonymous'
      img.src = url

    else
      deferred.resolve()

    return deferred.promise

  showBarcodeHint: (barcode) ->
    @calculatedBarcode = barcode
    $button = @$('.barcodeButton')
    $button
    .removeClass('btn-warning')
    .addClass('btn-success')
    .find('i')
      .removeClass('fa-rotate')
      .removeClass('fa-spin')
      .addClass('fa-arrow-left')
    .end()
    .find('.barcodeButtonLabel')
    # Splits barcode into length 4 chunks.
    .text(@calculatedBarcode.match(/.{1,4}/g).join(' '))

  hideBarcodeHint: () ->
    @calculatedBarcode = null
    $button = @$('.barcodeButton')
    $button.remove().closest('div')
      .removeClass('input-append')

  # VIEW EVENTS //////////////////////////////////////////////////////////////
  events:
    'click .previewButton': 'onPreviewButtonClick'
    'click .seatButton': 'onSeatButtonClick'
    'click .seatPreviewContainer .zoomInButton': 'onSeatZoomInButtonClick'
    'click .seatPreviewContainer .zoomOutButton': 'onSeatZoomOutButtonClick'
    'click .barcodePreviewContainer .zoomInButton': 'onBarcodeZoomInButtonClick'
    'click .barcodePreviewContainer .zoomOutButton': 'onBarcodeZoomOutButtonClick'
    'click .barcodeButton': 'onBarcodeButtonClick'

  onPreviewButtonClick: (e) ->
    attrs = @v3EticketModel.toPresenterJSON()
    previewModal = new App.Views.Shared.BasicModal({
      header: "Eticket Preview"
      icon: "fa-magnifying-glass-plus"
      message: "<div class='zoomable' style='
              height: #{ attrs.height }px;
              width: #{ attrs.width }px;
              cursor: move;
              background: #fff url(#{ attrs.thumbnail });
              background-size: cover;
              border: 1px solid #aaa;
              -moz-box-shadow: 0 0 10px #888;
              -webkit-box-shadow: 0 0 10px #888;
              box-shadow: 0 0 10px #888;
            ' />"
      isFullScreen: true
      isZoomable: true
    })

  onSeatButtonClick: (e) ->
    @parentView.onSeatButtonClick(e)

  onSeatZoomInButtonClick: (e) ->
    @parentView.zoomSeat(e)

  onSeatZoomOutButtonClick: (e) ->
    out = true
    @parentView.zoomSeat(e, out)

  onBarcodeZoomInButtonClick: (e) ->
    @parentView.zoomBarcode(e)

  onBarcodeZoomOutButtonClick: (e) ->
    out = true
    @parentView.zoomBarcode(e, out)

  onBarcodeButtonClick: (e) ->
    if (@calculatedBarcode)
      @$('.barcodeInput').val(@calculatedBarcode)
  #///////////////////////////////////////////////////////////////////////////

  readBarcodePromise: () ->
    url = @v3EticketModel.get('_thumbnail')
    barcode = @v3EticketModel.get('_barcode')
    if (barcode)
      @hideBarcodeHint()
      # Immediately fulfilled promise
      return Q(barcode)
    else
      deferred = Q.defer()
      App.Utils.readBarcodePromise({
        url
      })
      .then (barcode) =>
        @showBarcodeHint(barcode)
        deferred.resolve(barcode)
      .fail (error) =>
        @hideBarcodeHint()
        deferred.reject(error)
      .done()
      return deferred.promise
