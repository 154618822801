App.Models.Payment = require('../models/payment_model');

module.exports = App.Collections.Payments = Backbone.Collection.extend({

  model: App.Models.Payment,

  initialize: function(models, options) {
    this.url = '/orders/' + options.order.id + '/payments';
  }

});
