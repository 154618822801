do () ->

  # groupedautocomplete ////////////////////////////////////////////////////////
  # This is a jQuery plugin that allows search results to be grouped by 'category'
  # Creates $(el).groupedautocomplete
  # Used by App.Utils.autocomplete

  App.Utils ||= {}
  Categories = {
    "Performer": "performers",
    "Venue": "venues",
    "Event": "events",
    "Client": "clients",
    "Office": "offices"
  }

  capitaliseFirstLetter = (s) ->
    return s.charAt(0).toUpperCase() + s.slice(1)

  renderItem = ($ul, item) ->

    # Don't let users select their own office.
    if (item.category == 'Office')
      if (+(item.id) == +(SESSION.office_id))
        return null

    company = (item.company && "<span style='color: #000;'>#{ item.company }</span>") || ""
    location = (item.location &&
      "<div style='font-weight: 500; color: #888; font-size: 11px;'>
        #{ item.location }
      </div>") || ''

    labelAndLocation = (item.location &&
      "<div style='font-weight: 500; color: #888; font-size: 11px;'>
        #{ item.label } -
        #{ item.location }
      </div>") || ''

    if (item.category == 'Client' || item.category == 'Venue')
      html =
        "<li>
           <a>
            <div>#{ item.label }</div>
            #{ location }
          </a>
        </li>"
    else if (item.category == 'Office')
      html =
        "<li>
           <a>
            <div>#{ company }</div>
            #{ labelAndLocation }
          </a>
        </li>"
    else
      html = "<li><a>#{ item.label }</a></li>"

    return $(html)
      .appendTo($ul)
      .data( "ui-autocomplete-item", item)

  $.widget("custom.groupedautocomplete", $.ui.autocomplete, {

    _renderMenu: ($ul, items) ->

      self = @
      categories = {}
      $ul.addClass("groupedautocomplete")

      _.each items, (item) ->
        cat = item.category
        if (!categories.hasOwnProperty(cat))
          categories[cat] = []
        categories[cat].push(item)

      _.each categories, (category) ->

        $ul.append("<li class='ui-autocomplete-category'><div>" + capitaliseFirstLetter(category[0].category) + "</div></li>")

        _.each category, (item) ->
          renderItem($ul, item)

        if (self.options.isGlobalSearch && category.length && category[0].category == 'Client')
          item = {
            label: 'More...'
            name: 'more_clients'
            value: 'More...'
            category: 'clients'
          }
          renderItem($ul, item)
  })






  # App.Utils.autocomplete /////////////////////////////////////////////////////
  # This is our custom autocomplete util.
  # It hits the search endpoint and returns results based on what 'types' you request (clients, offices, venues, performers, etc.)
  # It relies on groupedautocomplete to group the results.
  App.Utils.autocomplete = (options) ->

    autocompleteOptions = {
      disabled: options.disabled || false
      appendTo: options.appendTo || "body"
      position: {
        my: "left top"
        at: "left bottom"
      }
      delay: 300
      select: options.select
      change: options.change
      open:   (event, ui) ->
        App.Utils.scrollableOffPage($(@).groupedautocomplete('widget'))
        $('.ui-autocomplete-category').removeClass('ui-menu-item')
      close: options.close
      isGlobalSearch: options.isGlobalSearch

      source: (request, response) ->

        if (options.originalTermCallback)
          options.originalTermCallback(request.term)
        if (self.xhr)
          self.xhr.abort()

        self.xhr = $.ajax({
          cache: true
          url: options.url || '/api_direct/v9/searches/suggestions'
          data: { # Some are doubled up to support api_direct endpoint expectations.
            q: request.term

            per_page: options.perPage || 10
            limit: options.perPage || 10

            types: options.types
            entities: options.types

            redirect_path: $element.data("redirect-path")
            pos: options.pos
            partner: options.partner

            fuzzy: options.fuzzy || false
            collapse_categories: true
          }
          dataType: "json"
          success: (data, status) ->
            appendTo = options.appendTo || "body"
            if ($(appendTo).is(':visible'))
              response(data.suggestions || data.results)
            else
              response([])
          error: (xhr, status, error) ->
            response([])
        })
    }

    $element = if options.$selector then options.$selector else $(options.selector)
    $element.groupedautocomplete(autocompleteOptions).keypress (event) ->
      if (event.keyCode == 13)
        if (options.onEnterKey)
          options.onEnterKey(event)
        event.preventDefault()
        event.stopPropagation()
        return false






  # Search Bar /////////////////////////////////////////////////////////////////
  # This is the search bar on the top of the POS.
  # It is powerd bu App.Utils.autocomplete

  originalTerm = null

  regIsDigit = (fData) ->
    reg = /^[0-9]+$/
    return reg.test(fData)

  App.Utils.initializeSearchBar = () ->
    searchBar = App.Utils.autocomplete({
      url: '/api_direct/v9/searches/suggestions'
      perPage: 5
      selector: "#search #q"
      types: ['performer', 'venue', 'client'] # do orders manually
      fuzzy: true
      isGlobalSearch: true
      select: (e, ui) =>

        category = ui.item.category
        term = ui.item.label
        id = ui.item.id

        if (id == 'more_clients')
          term = originalTerm || ''

        App.router.navigate('search/' + Categories[category] + '/' + id + '/' + term, { trigger: true })

      close: () ->
        $el = $('#search #q')
        if (!regIsDigit($el.val()))
          $el.val('')

      originalTermCallback: (origTerm) ->
        originalTerm = origTerm

      # For Orders search:
      onEnterKey: (event) ->
        $q = $('#search #q')
        offset = $q.offset()
        top = offset.top + 3
        left = offset.left + 2

        $pleaseHold = $('<div style="position: absolute; display: none; z-index: 5000; font-size: 16px; line-height: 20px; width: 190px; height: 20px; margin-top: -2px; margin-left: -1px;" class="label label-info">Searching Orders &nbsp;&nbsp;<i class="fa-solid fa-rotate fa-spin"></i></div>')
        $noDice = $('<div style="position: absolute; display: none; z-index: 5001; font-size: 16px; line-height: 20px; width: 190px; height: 20px; margin-top: -2px; margin-left: -1px;" class="label label-important">Order Not Found</div>')
        $pleaseHold.css({ top: top + 'px', left: left + 'px' })
        $noDice.css({ top: top + 'px', left: left + 'px' })

        $('body').append($pleaseHold)
        $pleaseHold.fadeIn()

        orderId = $(@selector).val()
        orderModelAttempt = new App.Models.V3.Order({
          id: orderId
        }, {
          mapping: C.Mappings.Direct.Order.Order
        })
        App.ErrorManager.ignoreNext(404)
        orderModelAttempt.fetchPromise()
        .then (model) =>
          $q.val('')
          App.ErrorManager.resetTolerance(404)
          $pleaseHold.delay(3000).remove()
          App.router.navigate('/order/' + orderId, { trigger: true })
          return false
        .fail (errors) =>
          $('body').append($noDice)
          $noDice.fadeIn()
          $pleaseHold.delay(5000).remove()
          setTimeout(() ->
            $noDice.fadeOut()
            $noDice.delay(5000).remove()
          , 2500)
        .done()
    })
