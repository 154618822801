App.Models.Base.PatronBaseModel = require('../base/patron_base_model.coffee')
App.Models.V3.Patron = require('./patron_model.coffee')

parent = App.Models.Base.PatronBaseModel.prototype
module.exports = App.Models.V3.Office = App.Models.Base.PatronBaseModel.extend

  url: () ->
    if (@purpose == C.Purposes.FetchBrokerageCards)
      return '/api_direct/v9/credit_cards'
    else
      return parent.url.call(@)

  urlRoot: () ->
    if (@purpose == C.Purposes.FetchEvoPayBalance)
      return '/api_direct/v9/accounts'
    return '/api_direct/v9/offices'

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  fetchEvoPayBalancePromise: () ->
    deferred = Q.defer()
    @purpose = C.Purposes.FetchEvoPayBalance
    @fetchPromise({data: {"show_restricted": true}})
    .then (model) =>
      @purpose = null
      deferred.resolve(@)
    .done()
    return deferred.promise

  fetchBrokerageCreditCardsPromise: () ->
    deferred = Q.defer()
    @purpose = C.Purposes.FetchBrokerageCards
    @fetchPromise()
    .then (model) =>
      @purpose = null
      deferred.resolve(@)
    .done()
    return deferred.promise

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.User.Office)
      attrs._brokerage = new App.Models.V3.Brokerage(json.brokerage, {
        mapping: C.Mappings.Direct.User.Brokerage
      })
      attrs._id = json.id
      attrs._name = json.name
      attrs._pos = json.pos
      attrs._url = json.url

    if (@getMapping() == C.Mappings.Direct.CreditMemos.Holder)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping:  C.Mappings.Direct.CreditMemos.HolderAddress
        clientId: @id
      })
      attrs._brokerage = new App.Models.V3.Brokerage(json.brokerage, {
        mapping: C.Mappings.Direct.CreditMemos.HolderBrokerage
      })
      attrs._emailAddresses = json.email_address
      attrs._evopay = json.evopay
      attrs._id = json.id
      attrs._name = json.name
      attrs._pccCode = json.pcc_code
      attrs._phone = json.phone
      attrs._type = json.type
      attrs._url = json.url

    if (@getMapping() == C.Mappings.Direct.CreditMemos.Owner)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping:  C.Mappings.Direct.CreditMemos.OwnerAddress
        clientId: @id
      })
      attrs._brokerage = new App.Models.V3.Brokerage(json.brokerage, {
        mapping: C.Mappings.Direct.CreditMemos.OwnerBrokerage
      })
      attrs._emailAddresses = json.email_address
      attrs._evopay = json.evopay
      attrs._id = json.id
      attrs._name = json.name
      attrs._pccCode = json.pcc_code
      attrs._phone = json.phone
      attrs._type = json.type
      attrs._url = json.url

    if (@getMapping() == C.Mappings.Direct.CreditMemo.Holder)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping:  C.Mappings.Direct.CreditMemo.HolderAddress
        clientId: @id
      })
      attrs._brokerage = new App.Models.V3.Brokerage(json.brokerage, {
        mapping: C.Mappings.Direct.CreditMemo.HolderBrokerage
      })
      attrs._emailAddresses = json.email_address
      attrs._evopay = json.evopay
      attrs._id = json.id
      attrs._name = json.name
      attrs._pccCode = json.pcc_code
      attrs._phone = json.phone
      attrs._type = json.type
      attrs._url = json.url

    if (@getMapping() == C.Mappings.Direct.CreditMemo.Owner)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping:  C.Mappings.Direct.CreditMemo.OwnerAddress
        clientId: @id
      })
      attrs._brokerage = new App.Models.V3.Brokerage(json.brokerage, {
        mapping: C.Mappings.Direct.CreditMemo.OwnerBrokerage
      })
      attrs._emailAddresses = json.email_address
      attrs._evopay = json.evopay
      attrs._id = json.id
      attrs._name = json.name
      attrs._pccCode = json.pcc_code
      attrs._phone = json.phone
      attrs._type = json.type
      attrs._url = json.url

    if (@getMapping() == C.Mappings.EvoPayTransactions.Patron)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping: C.Mappings.EvoPayTransactions.Address
      })
      attrs._brokerage = new App.Models.V3.Brokerage(json.brokerage, {
        mapping: C.Mappings.EvoPayTransactions.Brokerage
      })
      attrs._emailAddress = json.email_address
      attrs._id = json.id
      attrs._isEvopay = json.evopay
      attrs._name = json.name
      attrs._phoneNumber = json.phone
      attrs._type = json.type
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Order.Patron)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping: C.Mappings.Direct.Order.Address
      })
      attrs._brokerage = new App.Models.V3.Brokerage(json.brokerage, {
        mapping: C.Mappings.Direct.Order.Brokerage
      })
      attrs._emailAddress = json.email_address
      attrs._id = json.id
      attrs._isEvopay = json.evopay
      attrs._name = json.name
      attrs._phoneNumber = json.phone
      attrs._type = json.type
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Offices.Office)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping: C.Mappings.Direct.Offices.Address
      })
      attrs._brokerage = new App.Models.V3.Brokerage(json.brokerage, {
        mapping: C.Mappings.Direct.Offices.Brokerage
      })
      attrs._emailAddress = json.email && json.email.length && json.email[0] # An array.
      attrs._fraudReviewEmailAddresses = json.fraud_review_email_list
      attrs._evopayDiscount = json.evopay_discount
      attrs._fax = json.fax
      attrs._hours = json.hours # An array.
      attrs._id = json.id
      attrs._isEvopay = json.evopay
      attrs._isMain = json.main
      attrs._name = json.name
      attrs._phoneNumber = json.phone
      attrs._invoiced_buyer = json.invoiced_buyer
      attrs._isPOS = json.pos
      attrs._timeZone = json.time_zone
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

      # BONUS FIELDS
      # FetchEvoPayBalance
      attrs._spendable = json.spendable
      attrs._creditCards = new App.Collections.V3.CreditCards(json.credit_cards, {
        mapping: C.Mappings.Direct.Offices.BrokerageCreditCards
      })

    if (@getMapping() == C.Mappings.Direct.OrdersLightweight.Patron)
      attrs._brokerage = new App.Models.V3.Brokerage(json.brokerage, {
        mapping: C.Mappings.Direct.OrdersLightweight.Brokerage
      })
      attrs._id = json.id
      attrs._location = json.location
      attrs._name = json.name
      attrs._type = json.type
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.TicketGroups.Office)
      attrs._brokerage = new App.Models.V3.Brokerage(json.brokerage, {
        mapping: C.Mappings.Direct.TicketGroups.Brokerage
      })
      attrs._id = json.id
      attrs._name = json.name
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.TicketCosts.Patron)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping: C.Mappings.Direct.TicketCosts.PatronAddress
      })
      attrs._brokerage = new App.Models.V3.Brokerage(json.brokerage, {
        mapping: C.Mappings.Direct.TicketCosts.Brokerage
      })
      attrs._emailAddress = json.email_address
      attrs._id = json.id
      attrs._isEvopay = json.evopay
      attrs._name = json.name
      attrs._phoneNumber = json.phone
      attrs._type = json.type
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.OrderShowLite.Patron)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping: C.Mappings.Direct.OrderShowLite.PatronAddress
      })
      attrs._brokerage = new App.Models.V3.Brokerage(json.brokerage, {
        mapping: C.Mappings.Direct.OrderShowLite.Brokerage
      })
      attrs._emailAddress = json.email_address
      attrs._id = json.id
      attrs._isEvopay = json.evopay
      attrs._name = json.name
      attrs._phoneNumber = json.phone
      attrs._type = json.type
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.OrderEndpoint.Patron)
      attrs._brokerage = new App.Models.V3.Brokerage(json.brokerage, {
        mapping: C.Mappings.OrderEndpoint.Brokerage
      })
      attrs._id = json.id
      attrs._location = json.location
      attrs._name = json.name
      attrs._type = json.type
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.CreditMemos.CreditMemo.Holder)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping: C.Mappings.CreditMemos.CreditMemo.Address
      })
      attrs._brokerage = new App.Models.V3.Brokerage(json.brokerage, {
        mapping: C.Mappings.CreditMemos.CreditMemo.Brokerage
      })
      attrs._emailAddress = json.email_address
      attrs._id = json.id
      attrs._isEvopay = json.evopay
      attrs._name = json.name
      attrs._phoneNumber = json.phone
      attrs._type = json.type
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {
      address: @get('_address')?.toPresenterJSON()
      addresses: @addresses()
      brokerage: @get('_brokerage')?.toPresenterJSON()
      creditCards: @get('_creditCards')?.toPresenterJSON()
      emailAddress: @get('_emailAddress')
      id: @get('_id')
      location: @get('_location')
      name: @get('_name')
      phoneNumber: @get('_phoneNumber')
      spendable: @get('_spendable')
      hoursOfOperation: @hoursOfOperation()
    })
    return presented
  #///////////////////////////////////////////////////////////////////////////

  address: () ->
    return @addresses().first()

  addresses: () ->
    if (@get('_address'))
      addresses = new App.Collections.V3.Addresses(@get('_address'))
    return addresses || new App.Collections.V3.Addresses()

  emailAddress: () ->
    return @get('_emailAddress')

  emailAddresses: () ->
    if (@get('_emailAddress'))
      emailModel = new App.Models.V3.EmailAddress({
        _address: @get('_emailAddress')
      }, {
        mapping: C.Mappings.Direct.Offices.EmailAddresses
      })
      emails = new App.Collections.V3.EmailAddresses([ emailModel ])
    return emails || new App.Collections.V3.EmailAddresses()

  phoneNumbers: () ->
    number = @get('_phoneNumber')
    if (number)
      attrs = {
        _number: number
      }
      phoneNumbers = new App.Collections.V3.PhoneNumbers([attrs])
    return phoneNumbers || new App.Collections.V3.PhoneNumbers()

  toPatron: () ->
    mapping = @getMapping()
    patron = new App.Models.V3.Patron(@attributes, {
      type: C.Office
      mapping
    })
    return patron

  hoursOfOperation: () ->
    if (@get('_hours') && @get('_hours').length == 7)
      hours = @get('_hours')
      #Take care of operating_hour wrapper if it exists
      if (hours[0].operating_hour)
        hours = hours.map((hour) ->
          newHour = {}
          newHour.open = hour.operating_hour.open
          newHour.close = hour.operating_hour.close
          newHour.closed = hour.operating_hour.closed
          return newHour
        )

      format = "h:mm a"
      html = []
      if (hours[0].closed)
        html.push('Mon: Closed.')
      else
        html.push("Mon: #{moment(hours[0].open).utc().format(format)} - #{moment(hours[0].close).utc().format(format)}")
      html.push('<br/>')

      if (hours[1].closed)
        html.push('Tue: Closed.')
      else
        html.push("Tue: #{moment(hours[1].open).utc().format(format)} - #{moment(hours[1].close).utc().format(format)}")
      html.push('<br/>')

      if (hours[2].closed)
        html.push('Wed: Closed.')
      else
        html.push("Wed: #{moment(hours[2].open).utc().format(format)} - #{moment(hours[2].close).utc().format(format)}")
      html.push('<br/>')

      if (hours[3].closed)
        html.push('Thu: Closed.')
      else
        html.push("Thu: #{moment(hours[3].open).utc().format(format)} - #{moment(hours[3].close).utc().format(format)}")
      html.push('<br/>')

      if (hours[4].closed)
        html.push('Fri: Closed.')
      else
        html.push("Fri: #{moment(hours[4].open).utc().format(format)} - #{moment(hours[4].close).utc().format(format)}")
      html.push('<br/>')

      if (hours[5].closed)
        html.push('Sat: Closed.')
      else
        html.push("Sat: #{moment(hours[5].open).utc().format(format)} - #{moment(hours[5].close).utc().format(format)}")
      html.push('<br/>')

      if (hours[6].closed)
        html.push('Sun: Closed.')
      else
        html.push("Sun: #{moment(hours[6].open).utc().format(format)} - #{moment(hours[6].close).utc().format(format)}")

      return html.join('')
    else
      return 'No hours listed.'
