formTemplate = require('./transfer_source_form.ejs')
showTemplate = require('./transfer_source_show.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.BuySell.TransferSource = App.Views.Base.BaseView.extend

  initialize: (options = {}) ->
    {
      @$container,
      @selectedTransferSource
    } = options
    @render()

  render: () ->
    data = {
      transferSourceOptions: C.Options.TransferSource,
      selectedTransferSource: @selectedTransferSource
    }

    template = formTemplate
    if ( @selectedTransferSource )
      template = showTemplate

    @$container.html(
      @$el.html(
        template(data)
      )
    )
    @delegateEvents()
    @formErrors = new App.Utils.FormErrorManager(@$('.formErrors'))

    return @$el

  getTransferSourcePromise: () ->
    deferred = Q.defer()

    @formErrors.reset()
    formData = @$el.serializeObject()
    selectedTransferSource = formData.selectedTransferSource
    if (!selectedTransferSource)
      error = "You must select a transfer source"
      console.log("ERROR", error)
      deferred.reject(error)

    deferred.resolve(selectedTransferSource)

    return deferred.promise
