App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.RestrictedEvopayOrders = App.Models.Base.V3BaseModel.extend

  initialize: (attributes, options = {}) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() ==  C.Mappings.Direct.RestrictedEvopayOrders.Payments)

      attrs._eventWithdrawalFactor = json.event_withdrawal_factor
      attrs._eventId = json.event_id
      attrs._eventName = json.event_name
      attrs._evopayAmount = json.evopay_amount
      attrs._amountAfterFactor = json.amount_after_factor
      attrs._eventWithdrawalRestrictionLevel = json.event_withdrawal_restriction_level
      attrs._eventOccursAt = Date.parse(json.event_occurs_at)
      attrs._orderLinkId = json.order_link_id
      attrs._orderLinkChallenged = json.order_link_challenged
      attrs._sellerState = json.seller_state
    # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

# PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {
      eventWithdrawalFactor : @get('_eventWithdrawalFactor')
      eventId : @get('_eventId')
      eventName : @get('_eventName')
      evopayAmount : @get('_evopayAmount')
      amountAfterFactor : @get('_amountAfterFactor')
      eventWithdrawalRestrictionLevel : @get('_eventWithdrawalRestrictionLevel')
      eventOccursAt : @get('_eventOccursAt')
      orderLinkId : @get('_orderLinkId')
      orderLinkChallenged : if @get('_orderLinkChallenged') == 't' then 'True' else 'False'
      sellerState : @get('_sellerState')
    })
    return presented


  toDataTableRowArray: () ->
    payment = @toPresenterJSON()
    row = [
      payment.eventName
      App.Utils.makeTimestampHuman(payment.eventOccursAt, C.DateFormats.TableDate)
      App.Utils.valueToCurrency(payment.evopayAmount)
      App.Utils.valueToCurrency(payment.amountAfterFactor)
      "<a href='order/#{ payment.orderLinkId }'>#{ payment.orderLinkId }</a>"
      payment.sellerState
    ]
    return row