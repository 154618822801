module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<% if (SESSION.role.can(App.Access.Resources.Inventory.AllTickets.MyTicketsActions.AddToCart)) { %>\n\n<a href="/cart" data-toggle="tooltip" data-placement="bottom" title="Shopping Cart" style="display: block;">\n  <i class="fa-solid fa-cart-shopping"></i>\n  <% if (numInCart) { %>\n  <span class="label label-important" style="margin-top: -12px;">\n    <%= numInCart %>\n  </span>\n  <% } %>\n</a>\n\n<% } %>\n', __filename = "js/views/topnav/topnav_shopping_cart.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            if (SESSION.role.can(App.Access.Resources.Inventory.AllTickets.MyTicketsActions.AddToCart)) {
                __append('\n\n<a href="/cart" data-toggle="tooltip" data-placement="bottom" title="Shopping Cart" style="display: block;">\n  <i class="fa-solid fa-cart-shopping"></i>\n  ');
                __line = 5;
                if (numInCart) {
                    __append('\n  <span class="label label-important" style="margin-top: -12px;">\n    ');
                    __line = 7;
                    __append(escapeFn(numInCart));
                    __append("\n  </span>\n  ");
                    __line = 9;
                }
                __append("\n</a>\n\n");
                __line = 12;
            }
            __append("\n");
            __line = 13;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}