(function() {
  const Sentry = require('@sentry/browser');
  window.App = window.App || {};
  window.App = _.extend(window.App, {
    setupErrorManager: function() {
      var defaultOnErrorHandler = window.onerror;
      var currentErrorView = null;
      var ignoreBuffer     = {};
      var queuedOrderPollFailedOnce = false;

      var HTTP_STATUS_WHITELIST = {
        0: 1 // 0 = Abort - happens a lot with autocompleters.
      };

      window.App.ErrorManager.ignoreNext = function(code) {
        ignoreBuffer[code] = ignoreBuffer[code] ? ++ignoreBuffer[code] : 1;
      };

      window.App.ErrorManager.resetTolerance = function(code) {
        setTimeout(function() {
          ignoreBuffer[code] = 0;
        }, 0);
      };

      window.App.ErrorManager.decreaseTolerance = function(code) {
        setTimeout(function() {
          ignoreBuffer[code]--;
        }, 0);
      };

      // GLOBAL JAVASCRIPT CATCHALL //////////////////////////////////////////////
      var onWindowError = function(errorMessage, url, lineNumber, colNumber, errorObject) {

        // Try to fingerprint and ignore the error caused by dynamic venue maps being down.
        if (errorMessage == 'Script error.' && url == '' && lineNumber == 0) {
          return;
        }

        var options = {
          errorTitle: 'Client Problem.'
        };
        var params = {}
        var errors = [];
        if (errorMessage) {
          errors.push('Message: ' + errorMessage);
          params.errorMessage = errorMessage;
        }
        if (url) {
          errors.push('URL: ' + url);
          params.url = url
        }
        if (lineNumber) {
          errors.push('Line Number: ' + lineNumber);
          params.lineNumber = lineNumber
        }
        if (colNumber){
          errors.push('Column Number: ' + colNumber);
          params.colNumber = colNumber
        }
        if (errorObject){
          errors.push('Error Object: ' + errorObject);
          params.errorObject = errorObject;
        }

        options.errors = errors;
        displayErrorView(options);
        if (defaultOnErrorHandler) {
          defaultOnErrorHandler(errorMessage, url, lineNumber, colNumber, errorObject);
        }
      };

      window.onerror = onWindowError;

      // GLOBAL AJAX FAIL CATCHALL /////////////////////////////////////////////////

      // HTTP ERRORS
      $(document).ajaxError(function(event, jqXHR, ajaxSettings, thrownError) {

        if (HTTP_STATUS_WHITELIST[jqXHR.status]) {
          return;
        }

        if(ignoreBuffer[jqXHR.status] > 0){
          ignoreBuffer[jqXHR.status]--;
          return;
        }

        if (ajaxSettings.url == "/api_direct/v9/queued_orders/recent") {
          if (queuedOrderPollFailedOnce) {
            // Swallow this.
            return;
          } else {
            $.gritter.add({
              title: "Problem talking to server",
              text: "Unable to check queued order status.",
              time: 10000
            });
            queuedOrderPollFailedOnce = true;
            return;
          }
        }

        var options = {
          errorTitle: 'Server Problem.'
        };
        var errors = [];

        //if (event) {
        //  errors.push('Event: ' + event);
        //}

        if (ajaxSettings) {
          errors.push('Request: ' + ajaxSettings.type + ': ' + ajaxSettings.url);
        }
        if (jqXHR) {
          errors.push('Status: ' + jqXHR.status + ': ' + jqXHR.statusText)
        }
//        if (thrownError) {
//          errors.push('Error: ' + thrownError);
//        }

        var remainingArgs = Array.prototype.slice.apply(arguments);
        remainingArgs = remainingArgs.slice(3);
        for (var i = 0; i < remainingArgs.length; i++) {
          errors.push(remainingArgs[i]);
        }
        options.errors = errors;
        displayErrorView(options);
      });


      $.ajaxSetup({
        // HTTP OK But giving us the POS html.
        dataFilter: function (data, type) {
          if (data && data.substr(0, 15) == '<!DOCTYPE html>') {
            var options = {
              errorTitle: 'Endpoint Problem.',
              errors: ['Invalid Endpoint']
            };
            displayErrorView(options);
          }
          return data;
        }
      });

      // DISPLAY UTILITY ///////////////////////////////////////////////////////////
      var displayErrorView = function(options) {
        if (App &&
          App.Views &&
          App.Views.Shared &&
          App.Views.Shared.InvalidModel) {
          // Let's only show 1 error - otherwise they can pile up.
          if (currentErrorView) {
            currentErrorView.remove();
          }
          currentErrorView = new App.Views.Shared.InvalidModel(options);
          currentErrorView.render();
        } else {
          console.log('Error occured before InvalidModel exists.', options);
        }
      };

    },
    setupSentry: function() {
      // Setup Sentry
      const SENTRY_RELEASE_ID = (window.SENTRY_RELEASE || {}).id;
      const {
        ENVIRONMENT,
        SENTRY_DSN,
        SENTRY_DEBUG,
        SENTRY_ENVIRONMENT,
      } = window.__TEVO_CORE__.env;

      Sentry.init({
        dsn: SENTRY_DSN,
        debug: SENTRY_DEBUG === 'true',
        environment: SENTRY_ENVIRONMENT || ENVIRONMENT,
        enabled: true,
        release: SENTRY_RELEASE_ID,
      });
    }
  });

})();
