App.SeatMap = require('ticket-evolution-seatmaps')

template = require('./seatmaps.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Events.SeatMap = App.Views.Base.BaseView.extend

  template: template

  className: 'flex1 flextainer flexcol'

  initialize: (options = {}) ->
    {
      @$container
      @eventModel
      @isListView
      @onSectionSelection
      @collection
    } = options
    parent.initialize.call(@, options)
    @render()

  render: () ->
    @$container.show()
    @$container.html(
      @$el.html(
        @template()
      )
    )

    @map = new App.SeatMap({
      venueId: @eventModel.get('_venue').id
      configurationId: @eventModel.get('_configuration').id
      ticketGroups: @collection.map((ticketGroupModel) => App.Utils.buildMapTg(ticketGroupModel)),
      showLegend: false,
      sectionPercentiles: {
        '1': '#187512',
      },
      onSelection: ((sections) ->
        @onSectionSelection(sections)
        if (!@isListView)
          @trigger('onSelectedSectionsChange', sections)
      ).bind(@)
    }).build('seatmap')

    @delegateEvents()

    return @$el

  remove: () ->
    @$container.hide()
    parent?.remove.call(@)

  updateTickets: (ticketGroupModels = []) ->
    ticketGroups = ticketGroupModels.map((ticketGroupModel) => App.Utils.buildMapTg(ticketGroupModel))
    @map.updateTicketGroups(ticketGroups)

  highlightSection: (section) ->
    @map.highlightSection(section)

  unhighlightSection: (section) ->
    @map.unhighlightSection(section)

  deselectSection: (section) ->
    @map.deselectSection(section)
