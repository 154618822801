App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

MISSING_AIRBILL_REGEX = /missing.png/i

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.Shipment = App.Models.Base.V3BaseModel.extend

  urlRoot: '/api_direct/v9/shipments'

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)
    {
      @purpose
      @isAdHocShipmentOrigin
      @isTevoOrder
    } = options

  parse: (response, options) ->
      # We're hackily saving a single model though the collection endpoint.
    if (response.shipments && response.shipments.length)
      return response.shipments[0]
    else
      return response

  validateNestedModel: (value, attr, computedState) ->
    isValid = value.isValid(true)
    if (!isValid)
      return value.errors

  # http://thedersen.com/projects/backbone-validation/
  validation: () ->
    attrs = @attributes
    type = attrs._type
    base = {
      _type:
        required: true
    }
    switch type
      when C.Courier
        return _.extend({}, base, {
          _address: 'validateNestedModel'
          _courier:
            required: true
          _phoneNumber: 'validateNestedModel'
          _shipToName:
            required: true
          _shipToCompanyName:
            required: true
        })
      when C.Custom
        return base
      when C.FedEx
        if (@isAdHocShipmentOrigin)
          return _.extend({}, base, {
            _address: 'validateNestedModel'
            _phoneNumber: 'validateNestedModel'
            _shipToName:
              required: true
          })
        else
          return _.extend({}, base, {
            _address: 'validateNestedModel'
            _isResidential:
              required: true
            _phoneNumber: 'validateNestedModel'
            _serviceType:
              required: true
            #_signatureType:
            #  required: true
            _shipToName:
              required: true
            #_shipToCompanyName:
            #  required: true
          })
      when C.ProvidedAirbill
        return base
      when C.Eticket
        return _.extend({}, base, {
          _email: 'validateNestedModel'
        })
      when C.LocalPickup
          return base
      when C.Offline
        return base
      when C.PickupAtOffice
        return _.extend({}, base, {
          _address: 'validateNestedModel'
          _phoneNumber: 'validateNestedModel'
          _shipToName:
            required: true
        })
      when C.UPS
        return _.extend({}, base, {
          _trackingNumber:
            required: true
        })
      when C.WillCall
        return _.extend({}, base, {
          _phoneNumber: 'validateNestedModel'
          _shipToName:
            required: true
        })
      else
        return base
  labels:
    _address: 'Address'
    _available: 'Available'
    _courier: 'Courier Name'
    _isResidential: 'Is Residential'
    _locationName: 'Location Name'
    _phoneNumber: 'Phone Number'
    _serviceType: 'Rate Option'
    _shipToCompanyName: 'Ship to Company Name'
    _shipToName: 'Ship to Name'
    _signatureType: 'Signature Type'
    _trackingNumber: 'Tracking Number'
    _type: 'Type'
    _transferSource: 'Transfer Source'
    _notes: 'Requests'
    _shipFromName: 'Contact Name'
    _shipFromPhoneNumber: 'Contact Phone Number'
    _shipFromAddress: 'Location'
    _pickupTimeRange: 'Pickup Time Range'
    _pickupInstructions: 'Pickup Instructions'


  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.Shipments.Shipment)
      attrs._address = new App.Models.V3.Address(json.address, {
        mapping: C.Mappings.Direct.Shipments.Address
      })
      attrs._airbill = json.airbill
      attrs._cost = json.cost
      attrs._courier = json.courier_company
      attrs._createdAt = Date.parse(json.created_at)
      # SHOULD BE A MODEL
      attrs._email = json.email_address
      attrs._id = json.id
      attrs._items = new App.Collections.V3.OrderItems(json.items, {
        mapping: C.Mappings.Direct.Shipments.OrderItems
      })
      attrs._name = json.name
      attrs._notes = json.notes
      attrs._order = new App.Models.V3.Order(json.order, {
        mapping: C.Mappings.Direct.Shipments.Order
      })
      # SHOULD BE A MODEL
      attrs._phoneNumber = json.phone_number
      attrs._isResidential = !!json.residential
      attrs._serviceType = json.service_type
      attrs._serviceTypeDisplay = json.service_type_display
      attrs._shipToCompanyName = json.ship_to_company_name
      attrs._shipToName = json.ship_to_name
      attrs._shipmentSnapshot = json.shipment_snapshot
      attrs._signatureType = json.signature_type
      attrs._state = json.state
      attrs._trackingNumber = json.tracking_number
      attrs._type = json.type
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Order.Shipments)
      attrs._airbill = json.airbill
      attrs._available = json.available
      attrs._carrier = json.carrier
      attrs._courier = json.courier_company
      attrs._id = json.id
      # TODO: Can probably be OrderItemsCollection.
      attrs._items = json.items
      attrs._name = json.name
      attrs._notes = json.notes
      attrs._phoneNumber = new App.Models.V3.PhoneNumber(json.phone_number, {
        mapping: C.Mappings.Direct.Order.ShipmentPhoneNumber
      })
      attrs._emailAddress = new App.Models.V3.EmailAddress(json.email_address, {
        mapping: C.Mappings.Direct.Order.EmailAddresses
      })
      attrs._serviceType = json.service_type
      attrs._serviceTypeDisplay = json.service_type_display
      attrs._shipmentSnapshot = new App.Models.V3.ShipmentSnapshot(json.shipment_snapshot, {
        mapping: C.Mappings.Direct.Order.ShipmentSnapshot
      })
      attrs._shipToCompanyName = json.ship_to_company_name
      attrs._shipToName = json.ship_to_name
      attrs._shipFromName = json.ship_from_name
      attrs._shipFromPhoneNumber = new App.Models.V3.PhoneNumber(json.ship_from_phone_number, {
        mapping: C.Mappings.Direct.Order.ShipmentPhoneNumber
      })
      attrs._shipFromAddress = new App.Models.V3.Address(json.ship_from_address, {
        mapping: C.Mappings.Direct.Order.Address
      })
      attrs._pickupTimeRange = json.pickup_time_range
      attrs._transferSource = json.transfer_source
      attrs._pickupInstructions = json.pickup_instructions
      attrs._signatureType = json.signature_type
      attrs._buyerEditCount = json.buyer_edit_count
      attrs._sellerEditCount = json.seller_edit_count
      attrs._state = json.state
      attrs._trackingNumber = json.tracking_number
      attrs._type = json.type
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.ShipmentsStatus.Shipments)
      attrs._customerId = json.customer_id
      attrs._customerName = json.customer_name
      attrs._customerType = json.customer_type
      attrs._notes = json.notes
      attrs._firstEventOccursAt = Date.parse(json.first_event_occurs_at)
      attrs._id = json.id
      attrs._officeId = json.office_id
      attrs._orderCreatedAt = Date.parse(json.order_link_created_at)
      attrs._orderGroupId = json.order_group_id
      attrs._orderLinkId = json.order_link_id
      attrs._shipFromName = json.ship_from_name
      attrs._shipToName = json.ship_to_name
      attrs._state = json.state
      attrs._trackingNumber = json.tracking_number
      attrs._type = json.type
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.ShipmentsStatus.ShipmentsInfo)
      attrs._airbill = json.airbill
      attrs._cost = json.cost
      attrs._id = json.id
      attrs._officeId = json.office_id
      attrs._shipmentCreatedAt = Date.parse(json.created_at)
      attrs._shipmentUpdatedAt = Date.parse(json.updated_at)
      attrs._isResidential = json.residential
      attrs._notes = json.notes
      attrs._shipFromName = json.ship_from_name
      attrs._shipToName = json.ship_to_name
      attrs._shipToCompanyName = json.ship_to_company_name
      attrs._phoneNumber = new App.Models.V3.PhoneNumber(json.phone_number, {
        mapping: C.Mappings.Direct.Order.ShipmentPhoneNumber
      })
      attrs._serviceType = json.service_type
      attrs._signatureType = json.signature_type
      attrs._state = json.state
      attrs._trackingNumber = json.tracking_number
      attrs._type = json.type
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    presented = _.extend(@toJSON(), {
      available:           @get('_available')
      carrier:             @carrierDisplay()
      courier:             @get('_courier')
      customerName:        @get('_customerName')
      firstEventOccursAt:  @get('_firstEventOccursAt')
      hasAirbill:          @hasAirbill()
      id:                  @get('_id')
      isResidential:       @get('_isResidential')
      orderCreatedAt:      @get('_orderCreatedAt')
      orderLinkId:         @get('_orderLinkId')
      shipmentCreatedAt:   @get('_shipmentCreatedAt')
      shipmentUpdatedAt:   @get('_shipmentUpdatedAt')
      phoneNumber:         @get('_phoneNumber')?.toPresenterJSON()
      trackingNumber:      @get('_trackingNumber')
      type:                @get('_type')
      serviceType:         @get('_serviceType') || ''
      serviceTypeDisplay:  @get('_serviceTypeDisplay') || @serviceTypeDisplay()
      signatureType:       @get('_signatureType') || 'No Signature Type'
      shipToName:          @get('_shipToName') || ''
      shipFromName:        @get('_shipFromName') || ''
      shipFromPhoneNumber: @get('_shipFromPhoneNumber')?.toPresenterJSON()
      shipFromAddress:     @get('_shipFromAddress')?.toPresenterJSON()
      pickupTimeRange:     @get('_pickupTimeRange') || ''
      transferSource:      @get('_transferSource') || ''
      pickupInstructions:  @get('_pickupInstructions') || ''
      shipToCompanyName:   @get('_shipToCompanyName') || ''
      snapshot:            @get('_shipmentSnapshot')?.toPresenterJSON()
      notes:               @get('_notes') || ''
      state:               C.Options.Shipment.States[@get('_state')]

    })
    return presented

  serviceTypeDisplay: () ->
    if(@get('_serviceType'))
      C.Options.Shipment.ServiceTypes[@get('_serviceType')]
    else
      ''

  carrierDisplay: () ->
    if(@get('_serviceType'))
      C.Options.Carrier.Types[@get('_carrier')]
    else
      ''

  hasAirbill: () ->
    return !@get('_airbill')?.match(MISSING_AIRBILL_REGEX)
  #///////////////////////////////////////////////////////////////////////////

  toDataTableRowArray: () ->
    item = @toPresenterJSON()
    row = [
      item.id
      C.Options.Delivery.Types[item.type]
      item.shipToName
      item.customerName
      item.state
      App.Utils.makeTimestampHuman(item.firstEventOccursAt, C.DateFormats.TableDate)
      "<a href='/order/#{ item.orderLinkId }'>#{ item.orderLinkId }</a>"
      App.Utils.makeTimestampHuman(item.orderCreatedAt, C.DateFormats.TableDate)
    ]
    return row

  # Used by older v1Order & v1PurchaseOrderDraft
  validateOld: () ->
    @errors = []
    addressId = @get('_addressId')
    address = @get('_addressAttributes')
    email = @get('_emailAddressAttributes')
    localName = @get('_shipToCompanyName')
    name = @get('_shipToName')
    phone = @get('_phoneNumberAttributes')
    quantity = @get('quantity')
    rateOption = @get('_serviceType')
    shipmentType = @get('_type')
    signatureType = @get('_signatureType')
    trackingNumber = @get('_trackingNumber')

    # VALIDATION FUNCTIONS

    validations = {}
    validations.address = () ->
      valid = true
      if (addressId)
        validId = true
        shouldBeANumber = parseInt(addressId)
        if (_.isNaN(shouldBeANumber) || !_.isNumber(shouldBeANumber))
          validId = false
          @errors.push("Shipment requires a valid address.")
      else
        subErrors = []
        if (address)
          if (!address.street_address)
            valid = false
            subErrors.push('Street Address')
          if (!address.locality)
            valid = false
            subErrors.push('Locality')
          if (!address.region)
            valid = false
            subErrors.push('Region/State')
          if (!address.postal_code)
            valid = false
            subErrors.push('Postal Code')
          if (!address.country_code)
            valid = false
            subErrors.push('Country Code')
        else
          valid = false
        if (!valid)
          @errors.push("Shipment requires a valid address.")
          if (subErrors.length)
            subs = subErrors.join(", ")
            if (subErrors.length > 1)
              subs = subs.substring(0, subs.length - 2) + " are "
            else
              subs += " is "
            @errors.push(subs + " missing from address.")

    validations.email = () ->
      if (!email || !email.address)
        @errors.push("Eticket requires a valid email address.")

    validations.name = () ->
      if (!name)
        @errors.push("Shipment requires a name.")

    validations.phone = () ->
      valid = true
      if (phone)
        if (!phone.number)
          valid = false
        else
          # Strip (, ), -, and space
          num = phone.number.replace(/\(|\)|-|\s/g, "")
          shouldBeANumber = parseInt(num)
          if (_.isNaN(shouldBeANumber) || !_.isNumber(shouldBeANumber))
            valid = false
      else
        valid = false
      if (!valid)
        @errors.push("Shipment requires a valid phone number.")

    validations.localName = () ->
      if (!localName)
        @errors.push("Shipment requires a pickup location name.")

    validations.signatureType = () ->
      if (!signatureType)
        @errors.push("FedEx delivery requires a signature type.")

    validations.trackingNumber = () ->
      if (!trackingNumber)
        @errors.push("FedEx delivery requires a tracking number.")

    validations.rateOption = () ->
      if (!rateOption)
        @errors.push("FedEx delivery requires a rate option.")

    # VALIDATION MAPPINGS

    run = []

    if (shipmentType == C.Courier)
      run.push(validations.address)
      run.push(validations.name)
      run.push(validations.phone)

    if (shipmentType == C.Custom)
      $.noop()

    if (shipmentType == C.Eticket)
      run.push(validations.email)

    if (shipmentType == C.FedEx)
      if(!trackingNumber)
        run.push(validations.address)
        run.push(validations.name)
        run.push(validations.phone)
        run.push(validations.rateOption)
        run.push(validations.signatureType)
      else
        run.push(validations.trackingNumber)

    if (shipmentType == C.LocalPickup)
#      run.push(validations.name)
#      run.push(validations.phone)
#      run.push(validations.localName)
#      run.push(validations.address)
      $.noop()

    if (shipmentType == C.Offline)
      $.noop()

    if (shipmentType == C.PickupAtOffice)
      run.push(validations.address)
      run.push(validations.name)
      run.push(validations.phone)

    if (shipmentType == C.TBDShipment)
      $.noop()

    if (shipmentType == C.UPS)
      $.noop()

    if (shipmentType == C.WillCall)
      run.push(validations.name)
      run.push(validations.phone)

    for validationFn in run
      validationFn.call(@)

    if (_.isEmpty(@errors))
      return null
    else
      return @errors

  addAddressToJSON: (json, attribute = '_address', key = 'address') ->
    address = @get(attribute)
    if (address)
      if (address.isNew())
        json["#{ key }_attributes"] = address.toJSON({ preventWrapping: true })
      else
        json["#{ key }_id"] = address.id
    return json

  addEmailAddressToJSON: (json) ->
    email = @get('_email')
    if (email)
      if (email.isNew())
        json.email_address_attributes = email.toJSON({ preventWrapping: true })
      else
        json.email_address_id = email.id
    return json

  addPhoneNumberToJSON: (json, isEdit = false, attribute = '_phoneNumber', key = 'phone_number') ->
    phone = @get(attribute)
    if (phone)
      if (phone.isNew() || isEdit)
        json["#{ key }_attributes"] = phone.toJSON({ preventWrapping: true })
      else
        json["#{ key }_id"] = phone.id
    return json

  toJSON: () ->
    attrs = @attributes

    if (@purpose == C.Purposes.CreateAdHocShipment)
      shipment = {
        ship_to_name : attrs._shipToName
        ship_to_company_name : attrs._shipToCompanyName
        signature_type: attrs._signatureType
        service_type : attrs._serviceType
        residential : attrs._residential
        ship_from_name : attrs._shipFromName
        ship_from_company_name : attrs._shipFromCompanyName
        notes : attrs._notes
      }

      @addAddressToJSON(shipment)
      # HACK HACK HACK
      # This is just something we have to do. Must provide phone_number_attributes for deliveries even though they exit
      # @addPhoneNumberToJSON(shipment)

      phone_number_attributes = {
        number: @get('_phoneNumber')?.get('_number')
        is_primary: false
      }
      shipment.phone_number_attributes = phone_number_attributes
      #ship_from_address_attributes : attrs._shipFromAddress.toJSON()
      @addAddressToJSON(shipment, '_shipFromAddress', 'ship_from_address')
      #ship_from_phone_number_attributes : attrs._shipFromPhoneNumber.toJSON({preventWrapping : true })
      isEdit = false
      @addPhoneNumberToJSON(shipment, isEdit, '_shipFromPhoneNumber', 'ship_from_phone_number')

      json = {
        shipments : [shipment]
        non_order_delivery : true
      }
      return json

    # These are set in createForOrderPromise & editForOrderPromise
    isCreate = @isCreate
    isEdit = @isEdit
    order = @order

    type = attrs._type

    json = {
      cost: attrs._cost
      type: attrs._type
    }

    json = @addEmailAddressToJSON(json)

    if (type == C.Courier)
      json = @addAddressToJSON(json)
      json = @addPhoneNumberToJSON(json, isEdit)
      json.courier_company =      attrs._courier
      json.ship_to_name =         attrs._shipToName
      json.ship_to_company_name = attrs._shipToCompanyName

    if (type == C.Custom)
      $.noop()

    if (type == C.FedEx)
      json = @addAddressToJSON(json)
      json = @addPhoneNumberToJSON(json, isEdit)
      json.residential =          attrs._isResidential
      json.ship_to_name =         attrs._shipToName
      json.ship_to_company_name = attrs._shipToCompanyName
      json.signature_type =       attrs._signatureType
      json.service_type =         attrs._serviceType

    if (type == C.ProvidedAirbill)
      $.noop()

    if (type == C.Eticket)
      json = @addEmailAddressToJSON(json)

    if (type == C.FlashSeats || type == C.TMMobile || type == C.TMMobileLink )
      json.ship_to_name  = attrs._shipToName
      json = @addEmailAddressToJSON(json)
      json = @addPhoneNumberToJSON(json, isEdit)
      json.tm_mobile_link = attrs.tm_mobile_link
      json.transfer_source = attrs._transferSource

    if (type == C.LocalPickup)
      json = @addAddressToJSON(json)
      json = @addPhoneNumberToJSON(json, isEdit)
      json.ship_to_name =              attrs._shipToName
      json.notes =                     attrs._notes
      json.ship_from_name =            attrs._shipFromName
      json = @addAddressToJSON(json, '_shipFromAddress', 'ship_from_address')
      json = @addPhoneNumberToJSON(json, isEdit, '_shipFromPhoneNumber', 'ship_from_phone_number')
      json.pickup_time_range =         attrs._pickupTimeRange
      json.pickup_instructions =       attrs._pickupInstructions
      json.use_venue_address =         attrs._useVenueAddress

    if (type == C.Offline)
      $.noop()

    if (type == C.PickupAtOffice)
      json = @addAddressToJSON(json)
      json = @addPhoneNumberToJSON(json, isEdit)
      json.ship_to_name =         attrs._shipToName

    if (type == C.UPS)
      json.tracking_number = attrs._trackingNumber

    if (type == C.WillCall)
      json = @addPhoneNumberToJSON(json, isEdit)
      json.ship_to_name =         attrs._shipToName

    if (isCreate)
      json.with_snapshot = true
      json.replace_existing = true
      json.order_id = order.id
      json.items = order.get('_orderItems').toJSON()
      json = {
        shipments: [
          json
        ]
      }

    if (isEdit)
      delete json.id
      json.order_id = order.id
      json.items = order.get('_orderItems').toJSON()
      json = {
        shipments: [
          json
        ]
      }

    json.transfer_verifications = attrs.transfer_verifications

    return json

  cancelPromise: () ->
    return @deliveryPromise('cancel', 'PUT')

  markAsDeliveredPromise: () ->
    return @deliveryPromise('deliver', 'GET')

  requestProofOfTransferPromise: () ->
    return @deliveryPromise('transfer_verifications/request', 'POST')

  pendPromise: () ->
    return @deliveryPromise('pend', 'GET')

  deliveryPromise: (urlAction, method) ->
    deferred = Q.defer()
    $.ajax({
      type: method
      url: "/api_direct/v9/shipments/#{ @id }/#{ urlAction }"
      success: (data, status, xhr) =>
        deferred.resolve(data)
      error: (xhr, status, error) =>
        deferred.reject(xhr)
    })
    return deferred.promise

  createForOrderPromise: (options = {}) ->
    {
      order
    } = options
    @isCreate = true
    @isEdit = false
    @order = order
    return @savePromise()

  editForOrderPromise: (options = {}) ->
    {
      order
    } = options
    @isCreate = false
    @isEdit = true
    @order = order
    return @savePromise()

  completePromise: (data = {}) ->
    url = "/api_direct/v9/shipments/#{ @id }/complete"
    return @doPostPromise(url,data)

  patchPromise: (data = {}) ->
    url = "/api_direct/v9/shipments/#{ @id }"
    return @doPatchPromise(url,data)

  generateAirbillPromise: () ->
    url = "/api_direct/v9/shipments/#{ @id }/airbill"
    return @doPostPromise(url)

  emailAirbillPromise: (recipients) ->
    url = "/api_direct/v9/shipments/#{ @id }/email_airbill"
    data = {
      recipients
    }
    return @doPostPromise(url, data)

  uploadProofOfTransferPromise: (data) ->
    url = "/api_direct/v9/shipments/#{ @id }/transfer_verifications"
    return @doPostPromise(url, data)

  requestAirbillPromise: (shipmentId) ->
    deferred = Q.defer()
    $.ajax({
      type: 'GET'
      url: "/api_direct/v9/shipments/#{ shipmentId }/request_airbill"
      success: (data, textStatus, jqXHR) =>
        deferred.resolve(data)
      error: (jqXHR, textStatus, errorThrown) =>
        deferred.reject(jqXHR)
    })
    return deferred.promise

  requestDetailsPromise: (shipmentId) ->
    deferred = Q.defer()
    $.ajax({
      type: 'GET'
      url: "/api_direct/v9/shipments/#{ shipmentId }/request_details"
      success: (data, textStatus, jqXHR) =>
        deferred.resolve(data)
      error: (jqXHR, textStatus, errorThrown) =>
        deferred.reject(jqXHR)
    })
    return deferred.promise

  doPostPromise: (url, data) ->
    deferred = Q.defer()
    $.ajax({
      type: 'POST'
      data: JSON.stringify(data)
      url
      success: (data, textStatus, jqXHR) =>
        deferred.resolve(data)
      error: (jqXHR, textStatus, errorThrown) =>
        deferred.reject(jqXHR)
    })
    return deferred.promise

  doPatchPromise: (url, data) ->
    deferred = Q.defer()
    $.ajax({
      type: 'PUT'
      data: JSON.stringify(data)
      url
      success: (data, textStatus, jqXHR) =>
        deferred.resolve(data)
      error: (jqXHR, textStatus, errorThrown) =>
        deferred.reject(jqXHR)
    })
    return deferred.promise

  toDataTableRowArray: () ->
    shipment = @toPresenterJSON()
    row = [
      "<button class='btn btn-small dropdown-toggle' data-toggle='dropdown' tabindex='-1'><i class='fa-solid fa-plus'/></button>"
      shipment.id
      shipment.state
      App.Utils.makeTimestampHuman(shipment.shipmentCreatedAt, C.TableDateWithTime)
      shipment.shipToName
      shipment.serviceTypeDisplay
      shipment.trackingNumber
    ]
    return row

  downloadAirbill: (formErrors) ->
    url = "/api_direct/v9/shipments/#{@id}/get_airbill"

    $.ajax({
      type: 'GET'
      url
      success: (data, status, xhr) =>
        @outputPDF(data.file, "airbill-#{@id}")
        #window.open("data:application/pdf;base64," + data.file)
        formErrors.show({
          type: 'success'
          message: 'Airbill has been downloaded'
        })
      error: (error) =>
        formErrors.show({
          type: 'failed'
          message: "Airbill could not be downloaded."
        })
    })

  outputPDF: (data, filename) ->
    $a = $('<a/>')
    $a.attr('href', "data:attachment/pdf;base64,#{ data }")
    $a.attr('target', '_blank')
    $a.attr('download', "#{ filename }.pdf")
    $a.attr('data-bypass', 1)
    $a.appendTo('body')
    $a[0].click()
    setTimeout(() ->
      $a.remove()
    , 100)
