App.Models.Base.BaseModel = require('./base_model.coffee')

# Refer to fields using the 'labels' defined in model.
Backbone.Validation.configure({
  labelFormatter: 'label'
});

parent = App.Models.Base.BaseModel.prototype
module.exports = App.Models.Base.V3BaseModel = App.Models.Base.BaseModel.extend

  # TODO - COULD OVERRIDE GET TO ANNOUNCE MISSING FIELDS SINCE ALL LOGIC SHOULD BE USING _attrs ONLY
  toPresenterJSON: () ->
    # V3 DOES NOT DO THIS!
    # WE CAN'T OVERWRITE _attrs SINCE THEY HAVE FRESHER DATA THAN attrs
    # @updateStandardAttributes && @updateStandardAttributes()
    presented = _.extend(@toJSON(), {
      # No additional fields
    })
    return presented

  fetch: (options = {}) ->
    oldSuccess = options.success || $.noop
    newSuccess = (model, response, options) =>
      @updateStandardAttributes?(response)
      oldSuccess(model, response, options)
    options.success = newSuccess
    parent.fetch.call(@, options)

  fetchPromise: (options = {}) ->
    deferred = Q.defer()
    origSuccessCallback = options.success
    origErrorCallback = options.error
    newOptions = _.extend(options, {
      success: (model, response, options) =>
        @updateStandardAttributes()
        origSuccessCallback?(model, response, options)
        deferred.resolve(model)
      error: (model, xhr, options) ->
        origErrorCallback?(model, xhr, options)
        deferred.reject(xhr)
    })
    deferred.promise.xhr = Backbone.Model.prototype.fetch.call(@, newOptions)
    return deferred.promise

  savePromise: (attrs, options = {}) ->
    deferred = Q.defer()
    origSuccessCallback = options.success
    origErrorCallback = options.error
    newOptions = _.extend(options, {
      success: (model, response, options) =>
        @updateStandardAttributes()
        origSuccessCallback?(model, response, options)
        deferred.resolve(model)
      error: (model, xhr, options) ->
        origErrorCallback?(model, xhr, options)
        deferred.reject(xhr)
    })
    Backbone.Model.prototype.save.call(@, attrs, newOptions)
    return deferred.promise

  destroyPromise: (options = {}) ->
    deferred = Q.defer()
    origSuccessCallback = options.success
    origErrorCallback = options.error
    newOptions = _.extend(options, {
      success: (model, response, options) ->
        origSuccessCallback?(model, response, options)
        deferred.resolve(model)
      error: (model, xhr, options) ->
        origErrorCallback?(model, xhr, options)
        deferred.reject(xhr)
      wait: true
    })
    Backbone.Model.prototype.destroy.call(@, newOptions)
    return deferred.promise

