App.Models.Base.PatronBaseModel = require('../base/patron_base_model.coffee')

# This should probably use a library like StickIt to do multiple inheritence from both client & office.
parent = App.Models.Base.PatronBaseModel.prototype
module.exports = App.Models.V3.Patron = App.Models.Base.PatronBaseModel.extend

  childModel: null
  type: null

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)
    if (options.type == C.Office || (attributes && attributes.type == 'Office'))
      @childModel = new App.Models.V3.Office(attributes, options)
      @type = C.Office
    else
      @childModel = new App.Models.V3.Client(attributes, options)
      @type = C.Client

  fetchPromise: (options) ->
    return @childModel.fetchPromise(options)

  fetchEvoPayBalancePromise: () ->
    return @childModel.fetchEvoPayBalancePromise()

  fetchBrokerageCreditCardsPromise: () ->
    return @childModel.fetchBrokerageCreditCardsPromise()

  updateStandardAttributes: (json = @attributes) ->
    @set(json)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    childJSON = @childModel.toPresenterJSON()
    if (@type == C.Office)
      location = childJSON.address?.cityState || childJSON.location
      presented = _.extend(childJSON, {
        type:               C.Office

        address:              childJSON.address # Unneccessary but nice and explicit.
        addressOptions:       @addressOptions()
        companyName:          @companyName(childJSON)
        creditCardOptions:    @creditCardOptions()
        emailAddress:         childJSON.emailAddress
        emailAddressLink:     @emailAddressLink()
        emailAddressOptions:  @emailAddressOptions()
        isPOS:                @isPOS()
        isTEVO:               @isTEVO()
        name:                 @name(childJSON)
        nameLink:             @nameLink(childJSON)
        phoneNumber:          childJSON.phoneNumber || ''
        phoneNumberOptions:   @phoneNumberOptions()
        prettyName:           @prettyName(childJSON)
        shipToName:           @shipToName(childJSON)
        spendable:            childJSON.spendable

      })
    if (@type == C.Client)
      # WARNING: This was previously:
      # presented = _.extend(@toJSON(), {
      # Probably gonna break some things.
      presented = _.extend(childJSON, {
        type:               C.Client

        address:              childJSON.address # Unneccessary but nice and explicit.
        addressOptions:       @addressOptions()
        companyName:          @companyName(childJSON)
        creditCardOptions:    @creditCardOptions()
        emailAddress:         childJSON.emailAddress
        emailAddressLink:     @emailAddressLink()
        emailAddressOptions:  @emailAddressOptions()
        isPOS:                @isPOS()
        isTEVO:               @isTEVO()
        name:                 childJSON.name
        nameLink:             @nameLink(childJSON)
        phoneNumber:          childJSON.phoneNumber || ''
        phoneNumberOptions:   @phoneNumberOptions()
        prettyName:           @prettyName(childJSON)
        shipToName:           @shipToName(childJSON)
        spendable:            null

      })
    return presented

  addresses: () ->
    return @childModel.addresses()

  addressOptions: () ->
    options = {}
    if (@type == C.Client)
      addresses = @childModel.get('_addresses')
      if (addresses)
        addresses.each((addressModel) ->
          options[addressModel.id] = addressModel.singleLine()
        )
    else
      address = @childModel.get('_address')
      if (address)
        options[address.id] = address.singleLine()

    return options

  setCreditCards: (creditCardCollection) ->
    @childModel.set('_creditCards', creditCardCollection)

  getCreditCards: () ->
    return @childModel.get('_creditCards')

  creditCardOptions: () ->
    options = {}
    creditCardCollection = @childModel.get('_creditCards')
    creditCardCollection?.each((creditCardModel) ->
      options[creditCardModel.id] = creditCardModel.singleLine()
    )
    return options

  companyName: (childJSON) ->
    return (childJSON.company?.name || childJSON.brokerage?.name) || ''

  emailAddressLink: () ->
    emailAddress = @childModel.emailAddress()
    return "<a href='mailto:#{ emailAddress }' data-bypass='1'>#{ emailAddress }</a>"

  emailAddressOptions: () ->
    options = {}
    if (@type == C.Client)
      @childModel.get('_emailAddresses')?.each((emailAddressModel) ->
        options[emailAddressModel.id] = emailAddressModel.get('_address')
      )
    else if (SESSION.role.can(App.Access.Resources.Orders.ManageDeliveryEmail))
        emailAddress = @childModel.get('_emailAddress') # Not a model.  Just a string.
        options[emailAddress] = emailAddress
      else
        options = @childModel.get('_fraudReviewEmailAddresses')

    return options

  isPOS: () ->
    isPOS = false
    if (@childModel.get('_isPOS'))
      isPOS = true
    return isPOS

  isTEVO: () ->
    return @childModel.get('_brokerage')?.get('_isMaster')

  name: (childJSON) ->
    if (childJSON?.brokerage?.abbreviation)
      name = "#{childJSON.brokerage.abbreviation}"
      if (childJSON.location)
        name += " - #{childJSON.location}"
      return name
    return ''

  nameLink: (childJSON) ->
    if (@type == C.Client)
      company = ''
      if (@companyName(childJSON))
        company = " - #{ @companyName(childJSON) }"
      return "<a href='/client/#{ childJSON.id }'>#{ childJSON.name }#{ company }</a>"
    else
      if (childJSON.brokerage)
        return "#{ childJSON.brokerage.abbreviation } - #{ childJSON.name }"
      else
        return childJSON.name

  phoneNumberOptions: () ->
    options = {}
    phoneNumbers = @childModel.phoneNumbers()
    if (phoneNumbers)
      phoneNumbers.each((phoneNumberModel) ->
        options[phoneNumberModel.get('_number')] = phoneNumberModel.humanized()
      )
    return options

  prettyName: (childJSON) ->
    if (@type == C.Office && childJSON.brokerage)
      output = [childJSON.brokerage.name]
      output.push(childJSON.address.cityState) if childJSON.address?.cityState
      return _.compact(output).join(' - ')
    else
      return childJSON.name

  shipToName: (childJSON) ->
    if (@type == C.Office)
      if (childJSON.brokerage)
        return "#{ childJSON.brokerage.name } - #{ childJSON.name }"
      else
        return childJSON.name
    else
      return childJSON.name
  #///////////////////////////////////////////////////////////////////////////

  address: () ->
    return @childModel.address()

  addresses: () ->
    addressCollection = @childModel.addresses()
    return addressCollection

  emailAddress: () ->
    return @childModel.emailAddress()

  emailAddresses: () ->
    return @childModel.emailAddresses()

  phoneNumbers: () ->
    return @childModel.phoneNumbers()
