template = require('./expanded_row_details.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

CLASSNAME = 'expandedTicketRowDetails'

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.TicketGroups.ExpandedRowDetails = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    {
      @eventModel
      @row
      @ticketGroup
      @ticketGroupListView
    } = options
    @render()

  render: () ->

    # Adapted from App.Utils.LoadingBox without the absolute positioning.
    # Need it to scroll inside table.
    html = [
      '<div style="background: #f9f9f9; width: 100%; text-align: center;">'
      ' <div style="width: 62px; margin: 0 auto;">'
      '   <h1 style="font-weight: 900; font-size: 24px; margin-left: -22px;">LOADING</h1>'
      '   <div id="floatingBarsG">'
      '     <div class="blockG" id="rotateG_01"/>'
      '     <div class="blockG" id="rotateG_02"/>'
      '     <div class="blockG" id="rotateG_03"/>'
      '     <div class="blockG" id="rotateG_04"/>'
      '     <div class="blockG" id="rotateG_05"/>'
      '     <div class="blockG" id="rotateG_06"/>'
      '     <div class="blockG" id="rotateG_07"/>'
      '     <div class="blockG" id="rotateG_08"/>'
      '   </div>'
      ' </div>'
      '</div>'
    ].join('')

    @row.child('<div style="min-height: 150px;">' + html + '</div>', CLASSNAME).show()

    promises = []
    promises.push(@ticketGroup.fetchPromise())

    @ticketGroup.createBlankHoldsCollection()
    if (@ticketGroup.isOwned())
      promises.push(@ticketGroup.fetchHolds())

    Q.all(promises)
    .then (promiseResults) =>

      @finishRender()

# Disable this for now so we don't have to fetch PO's.
#      if (@ticketGroup.isOwned())
#        purchaseOrderIds = @ticketGroup.get('_ticketPOIds')
#        purchaseOrderIds = _.filter(purchaseOrderIds, (poId) ->
#          return (poId && poId != 'NULL')
#        )
#        if (purchaseOrderIds && purchaseOrderIds.length)
#          purchaseOrderId = _.first(purchaseOrderIds)
#          purchaseOrder = new App.Models.V3.Order({
#            id: purchaseOrderId
#          }, {
#            mapping: C.Mappings.Direct.OrderShowLite.Order
#          })
#          purchaseOrder.fetchPromise()
#          .then (model) =>
#            @finishRender(purchaseOrder)
#        else
#          @finishRender()
#
#      else
#        @finishRender()

    .fail (promiseErrors) =>
      @row.child("<div class='alert alert-error'>
        Error fetching ticket group details.
      </div>", CLASSNAME).show()
    .done()

  finishRender: (purchaseOrder = null) ->
    data = {
      ticketGroup: @ticketGroup.toPresenterJSON()
      purchaseOrder: null
    }
    if (purchaseOrder)
      data.purchaseOrder = purchaseOrder.toPresenterJSON()
    @$el.html(@template(data))
    @row.child(@$el, CLASSNAME).show()
    @renderHolds()
    @delegateEvents()

  renderHolds: () ->
    $container = @$('#ticketHoldsContainer')
    ticketHolds = @ticketGroup.get('_ticketHolds')
    if (ticketHolds && ticketHolds.length)
      ticketHolds.each((ticketHoldModel) =>
        view = new App.Views.TicketHolds.Row({
          ticketHoldModel
          @ticketGroup
          eventId: @eventModel.id
          $container
          @ticketGroupListView
        })
      )

  # VIEW EVENTS //////////////////////////////////////////////////////////////
  events:
    'click .broker': 'showBrokerInfo'

  showBrokerInfo: (e) ->
    dialog = new App.Views.TicketGroups.BrokerDetails({
      officeId: $(e.currentTarget).data("office-id")
    })
    e.preventDefault()
    e.stopPropagation()
    return false
  #///////////////////////////////////////////////////////////////////////////
