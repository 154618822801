template = require('./office_cards.ejs');

module.exports = App.Views.CreditCards.OfficeCards = Backbone.View.extend({

  events: {
    'change #creditCards' : 'addNewCard'
  },

  existingCardTemplate: template,

  initialize: function(opts) {
    var that = this;
    var model = this.model = opts.order;
    this.officeId = this.model.isPO() ? SESSION.office_id : opts.officeId;

    if ((this.model && ( (this.model.get('is_purchase') || (this.model.isPO()) ))))  {
      this.collection = new App.Collections.CreditCards(null, {
        ownerType: C.Office,
        ownerId: that.officeId
      });
    } else {
      this.collection = new App.Collections.CreditCards(null, {
        ownerType: C.Office,
        ownerId: SESSION.office_id,
        storedForId: that.officeId
      });
    }
    $.when(this.collection.fetch({async:false})).then(function(response) {
      that.model.set('officeCreditCards', new App.Collections.CreditCards(response))
    });

    that.listenTo(App.Vent, 'reRenderOfficeCreditCards', _.bind(that.render, that ));
  },

  render: function(data) {
    if (data.length) { this.model.set('officeCreditCards', data) }
    this.$el.html(this.existingCardTemplate({credit_cards: this.model.get('officeCreditCards').models}))

    return this.$el;
  },

  addNewCard: function(e) {
    var new_card = $(e.currentTarget).find('option:selected').attr('id');

    if (new_card == 'addNewCard') {
      var newCardView = new App.Views.CreditCards.NewModal({
        officeId: SESSION.office_id,
        storedForId: this.officeId,
        patronType: this.model.get('patron_type'),
        order: this.model
      });

      newCardView.render();
    }
  }

});
