App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.User = App.Models.Base.V3BaseModel.extend

  urlRoot: '/api_direct/v9/users'

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.User.User)
      attrs._email = json.email
      attrs._id = json.id
      attrs._name = json.name
      attrs._office = new App.Models.V3.Office(json.office, {
        mapping:  C.Mappings.Direct.User.Office
      })
      attrs._phone = new App.Models.V3.PhoneNumber(json.phone, {
        mapping:  C.Mappings.Direct.User.Phone
      })
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.EventPins.User)
      attrs._id = json.id
      attrs._name = json.name
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)


# PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    presented = _.extend(@toJSON(), {
      email:          @get('_email')
      id:             @get('_id')
      updateAt:       @get('_updatedAt') || ''
      name:           @get('_name')
      office:         @get('_office')?.toPresenterJSON()
      phone:          @get('_phone')?.toPresenterJSON()
    })
    return presented
