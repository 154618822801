template = require('./basic_modal.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Shared.BasicModal = App.Views.Base.BaseView.extend

  className: 'modal hide fade'

  template: template

  initialize: (options = {}) ->
    {
      @header
      @message
      @icon
      @isFullScreen
      @isZoomable
      @isConfirmation
      @onYes
      @onNo
      @hideButtons
      @preventClose
      @withLoading
      @bodyStyles
    } = options
    @header ||= 'Error'
    @message ||= ''
    @bodyStyles ||= ''
    @hideButtons ||= false
    @preventClose ||= false
    @withLoading ||= false
    @icon ||= 'fa-solid fa-triangle-exclamation'
    @render()
    return @

  events:
    'click .fa-magnifying-glass-plus':  'onZoomInClick'
    'click .fa-magnifying-glass-minus': 'onZoomOutClick'
    'click #yesButton':     'onYesButtonClick'
    'click #noButton':      'onNoButtonClick'

  render: () ->
    if (@isZoomable)
      @message = [
        @message
        '<div style="position: absolute; top: 50px; left: 10px;">'
        ' <button><i class="fa-solid fa-xl fa-magnifying-glass-plus"/></button>'
        ' <button><i class="fa-solid fa-xl fa-magnifying-glass-minus"/></button>'
        '</div>'
      ].join('')

    $('body').append(
      @$el.html(
        @template({
          @header
          @message
          @icon
          @isConfirmation
          @hideButtons
          @withLoading
          @bodyStyles
        })
      )
    )

    if( @withLoading )
      @$modalLoadingContainer = @$el.find('#modalLoadingContainer')
      @loadingBoxModal = new App.Utils.LoadingBox(@$modalLoadingContainer)
      @loadingBoxModal.startLoad()

    if ( @preventClose )
      @$el.attr('data-backdrop', 'static')
      @$el.attr('data-keyboard', 'false')

    if (@isConfirmation)
      @$el.find('.modal-body').append([
        '<div class="pull-right" style="margin-top: 50px;">'
          '<div class="btn" id="noButton">No</div>'
          '<div style="margin-left: 20px;" class="btn btn-primary" id="yesButton">Yes</div>'
        '</div>'
      ].join(''))

    if (@isZoomable)
      @$('.zoomable').draggable({
        drag: (e, ui) ->
          $.noop()
      })

    options = {}
    if (@isFullScreen)
      windowWidth = $(window).width()
      windowHeight = $(window).height()
      HPAD = 0
      VPAD = 180
      height = windowHeight - VPAD
      width = windowWidth - HPAD
      options.width = width
      options.maxHeight = height
      options.isFullScreen = true
      @$el.find('modal-body').css({
        overflowX: 'auto'
        maxHeight: height
      })
      @$el.css({
        backgroundColor: '#eee'
      })

    # STANDARDIZED MODAL
    @$el.modal(options, 'show').on('hidden', () =>
      @remove()
    )

  remove: () ->
    @$el.modal('hide')

  onZoomInClick: (e) ->
    $zoom = @$('.zoomable')
    w = $zoom.width()
    h = $zoom.height()
    $zoom.css({
      height: ~~(h * 1.5) + 'px'
      width: ~~(w * 1.5) + 'px'
    })
    #@$el.css({
    #  marginTop: (0 - (h + 165) / 2) + 'px'
    #});

  onZoomOutClick: (e) ->
    $zoom = @$('.zoomable')
    w = $zoom.width()
    h = $zoom.height()
    $zoom.css({
      height: ~~(h / 1.5) + 'px'
      width: ~~(w / 1.5) + 'px'
    })

  onYesButtonClick: (e) ->
    @onYes?(e)

  onNoButtonClick: (e) ->
    @onNo?(e)
    @$el.modal('hide')
