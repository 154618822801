App.Collections.Base.PaginatedBase = require('../base/paginated_base_collection.coffee')
App.Models.V3.Client = require('../../models/v3/client_model.coffee')

parent = App.Collections.Base.PaginatedBase.prototype
module.exports = App.Collections.V3.Clients = App.Collections.Base.PaginatedBase.extend

  url: () ->
    if (@searchTerm)
      return "/api_direct/v9/search?types=clients&q=#{ @searchTerm }"
    if (@officeId)
      return "/api_direct/v9/clients?office_id=#{ @officeId }"
      #return '/api_direct/v9/clients'
    else
      return '/api_direct/v9/clients'

  model: App.Models.V3.Client

  initialize: (models, options = {}) ->
    {
      @officeId
      @clientsPerPage
    } = options
    parent.initialize.call(@, models, options)

  fetch: (options = {}) ->
    if (@clientsPerPage)
      options.data ||= {}
      options.data.per_page = @clientsPerPage
    return Backbone.Collection.prototype.fetch.call(@, options)

  parse: (json) ->
    if (json.total_entries)
      @totalEntries = json.total_entries
    if (json.clients)
      return json.clients
    if (json.results)
      return json.results
    return json
