template = require('./show.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.EvoQuote.Show = App.Views.Base.BaseView.extend

  template: template

  initialize: () ->
    @loadingView = new App.Views.Shared.LoadingView()

  render: (options = {}) ->

    if (options.model)
      @model = options.model

    @$el.html(
      @template({
        evoQuote: @model.toPresenterJSON()
      })
    )

    @$('#note-tip').tooltip()
    @formErrors = new App.Utils.FormErrorManager($('#formErrors'))
    return @$el

  events:
    'click #resend-quote':  'resendQuote'
    'click #convert-quote': 'convertQuote'
    'click #close-quote':   'closeQuote'

  resendQuote: (e) ->
    @loadingView.render()
    @model.resendQuote()
    .then (data) =>
      @render()
      @loadingView.remove()
      @formErrors.show({
        type: 'success'
        message: 'The EvoQuote has been successfully resent'
      })
    .fail (errors) =>
      @loadingView.remove()
      @formErrors.show({
        type: 'failed'
        message: "The EvoQuote could not be sent. Errors: #{errors}"
      })
    .done()

  convertQuote: (e) ->
    @loadingView.render()
    @model.update("convert")
    .then (model) =>
      evoQuote = new App.Models.V3.EvoQuote(model, {
        mapping: C.Mappings.Direct.EvoQuotes.EvoQuote
      })
      @render({model: evoQuote})
      @loadingView.remove()
      @formErrors.show({
        type: 'success'
        message: 'The EvoQuote has been successfully converted'
      })
    .fail (errors) =>
      @loadingView.remove()
      @formErrors.show({
        type: 'failed'
        message: "The EvoQuote could not be converted. Errors: #{errors}"
      })
    .done()

  closeQuote: (e) ->
    @loadingView.render()
    @model.update("close")
    .then (model) =>
      evoQuote = new App.Models.V3.EvoQuote(model, {
        mapping: C.Mappings.Direct.EvoQuotes.EvoQuote
      })
      @render({model : evoQuote})
      @loadingView.remove()
      @formErrors.show({
        type: 'success'
        message: 'The EvoQuote has been closed'
      })
    .fail (errors) =>
      @loadingView.remove()
      @formErrors.show({
        type: 'failed'
        message: "The EvoQuote could not be closed. Errors: #{errors}"
      })
    .done()

