App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.Brokerage = App.Models.Base.V3BaseModel.extend

  urlRoot: '/api_direct/v9/brokerages'

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.User.Brokerage)
      attrs._abbreviation = json.abbreviation
      attrs._id = json.id
      attrs._name = json.name
      attrs._url = json.url

    if (@getMapping() == C.Mappings.Direct.CreditMemos.HolderBrokerage)
      attrs._abbreviation = json.abbreviation
      attrs._createdAt = Date.parse(json.created_at)
      attrs._email = json.email
      attrs._id = json.id
      attrs._isMaster = json.master
      attrs._name = json.name
      attrs._isNATBMember = json.natb_member
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url

    if (@getMapping() == C.Mappings.Direct.CreditMemos.OwnerBrokerage)
      attrs._abbreviation = json.abbreviation
      attrs._createdAt = Date.parse(json.created_at)
      attrs._email = json.email
      attrs._id = json.id
      attrs._isMaster = json.master
      attrs._name = json.name
      attrs._isNATBMember = json.natb_member
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url

    if (@getMapping() == C.Mappings.Direct.CreditMemo.HolderBrokerage)
      attrs._abbreviation = json.abbreviation
      attrs._createdAt = Date.parse(json.created_at)
      attrs._email = json.email
      attrs._id = json.id
      attrs._isMaster = json.master
      attrs._name = json.name
      attrs._isNATBMember = json.natb_member
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url

    if (@getMapping() == C.Mappings.Direct.CreditMemo.OwnerBrokerage)
      attrs._abbreviation = json.abbreviation
      attrs._createdAt = Date.parse(json.created_at)
      attrs._email = json.email
      attrs._id = json.id
      attrs._isMaster = json.master
      attrs._name = json.name
      attrs._isNATBMember = json.natb_member
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url

    if (@getMapping() == C.Mappings.Direct.Order.Brokerage)
      attrs._abbreviation = json.abbreviation
      attrs._createdAt = Date.parse(json.created_at)
      attrs._email = json.email
      attrs._id = json.id
      attrs._isMaster = json.master
      attrs._isNATBMember = json.natb_member
      attrs._name = json.name
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Offices.Brokerage)
      attrs._abbreviation = json.abbreviation
      attrs._id = json.id
      attrs._name = json.name
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.OrdersLightweight.Brokerage)
      attrs._abbreviation = json.abbreviation
      attrs._email = json.email
      attrs._id = json.id
      attrs._name = json.name
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.TicketGroups.Brokerage)
      attrs._abbreviation = json.abbreviation
      attrs._id = json.id
      attrs._name = json.name
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.TicketCosts.Brokerage)
      attrs._abbreviation = json.abbreviation
      attrs._createdAt = Date.parse(json.created_at)
      attrs._email = json.email
      attrs._id = json.id
      attrs._isNATBMember = json.natb_member
      attrs._name = json.name
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.OrderShowLite.Brokerage)
      attrs._abbreviation = json.abbreviation
      attrs._createdAt = Date.parse(json.created_at)
      attrs._email = json.email
      attrs._id = json.id
      attrs._isNATBMember = json.natb_member
      attrs._name = json.name
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.OrderEndpoint.Brokerage)
      attrs._abbreviation = json.abbreviation
      attrs._email = json.email
      attrs._id = json.id
      attrs._name = json.name
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.CreditMemos.CreditMemo.Brokerage)
      attrs._abbreviation = json.abbreviation
      attrs._createdAt = Date.parse(json.created_at)
      attrs._email = json.email
      attrs._id = json.id
      attrs._isMaster = json.master
      attrs._isNATBMember = json.natb_member
      attrs._name = json.name
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      abbreviation: @get('_abbreviation')
      name: @get('_name')

    })
    return presented
  #///////////////////////////////////////////////////////////////////////////