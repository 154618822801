App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.OrderItem = App.Models.Base.V3BaseModel.extend

  urlRoot: ()->
    return "/api_direct/v9/orders/#{ @get('_orderId') }/items/"

  defaults:
    _eTicketThumbnails: new App.Collections.V2.EticketThumbnails(null, {
      mapping: C.Mappings.OrderEndpoint.EticketThumbnail
    })

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.OrderItems.OrderItem)
      attrs._cost = json.cost
      attrs._createdAt = Date.parse(json.created_at)
      attrs._deliveryId = json.delivery_id
      attrs._isEticket = json.eticket_delivery
      attrs._eTicketDownloadedAt = Date.parse(json.eticket_downloaded_at)
      attrs._eTicketDownloadedBy = json.eticket_downloaded_by
      attrs._eTicketFileSize = json.eticket_file_size
      attrs._isEticketFinalized = json.eticket_finalized
      attrs._eTicketFinalizedAt = Date.parse(json.eticket_finalized_at)
      attrs._eTicketPDFPages = json.eticket_pdf_pages
      attrs._eTicketThumbnails = new App.Collections.V2.EticketThumbnails(json.eticket_thumbnails, {
        mapping: C.Mappings.Direct.OrderItems.EticketThumbnail
      })
      attrs._eTicketUpdatedAt = Date.parse(json.eticket_updated_at)
      attrs._eTickets = json.etickets
      attrs._isSpec = json.has_spec_tickets
      attrs._id = json.id
      attrs._needsEticket = json.needs_eticket
      attrs._needsSub = json.needs_sub
      # IDs are handled poorly by API.  #FIXTHEAPI
      attrs._orderId = json.order_id
      attrs._orderItemId = json.order_item_id
      attrs._orderItemLinkId = json.order_item_link_id
      attrs._price = json.price
      attrs._quantity = parseInt(json.quantity)
      attrs._seats = json.seats
      attrs._ticketGroup = new App.Models.V3.TicketGroup(json.ticket_group, {
        mapping: C.Mappings.Direct.OrderItems.TicketGroup
      })
      attrs._totalQuantity = json.total_quantity
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Order.SubstitutionOrderItem)
      attrs._eTicketDownloadedAt = Date.parse(json.eticket_downloaded_at)
      attrs._eTicketDownloadedBy = json.eticket_downloaded_by
      attrs._id = json.id
      attrs._isEticketAvailable = json.eticket_available
      attrs._isEticket = json.eticket_delivery
      attrs._quantity = parseInt(json.quantity)
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._ticketGroup = new App.Models.V3.TicketGroup(json.ticket_group, {
        mapping: C.Mappings.Direct.Order.SubstitutionOrderItemTicketGroup
      })
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Order.OrderItems)
      attrs._cost = json.cost
      attrs._createdAt = Date.parse(json.created_at)
      attrs._eTicketDownloadedAt = Date.parse(json.eticket_downloaded_at)
      attrs._eTicketDownloadedBy = json.eticket_downloaded_by
      attrs._eTicketFinalizedAt = Date.parse(json.eticket_finalized_at)
      attrs._eTicketFileSize = json.eticket_file_size
      attrs._eTicketPDFPages = json.eticket_pdf_pages
      attrs._eTicketThumbnails = new App.Collections.V2.EticketThumbnails(json.eticket_thumbnails, {
        mapping: C.Mappings.OrderEndpoint.EticketThumbnail
      })
      attrs._eTicketUpdatedAt = Date.parse(json.eticket_updated_at)
      attrs._id = json.id
      attrs._isEticket = json.eticket_delivery
      attrs._isEticketAvailable = json.eticket_available
      attrs._isEticketFinalized = json.eticket_finalized
      attrs._isSpec = json.has_spec_tickets
      attrs._needsEticket = json.needs_eticket
      attrs._needsSub = json.needs_sub
      # IDs are handled poorly by API.  #FIXTHEAPI
      attrs._orderId = json.order_id
      attrs._orderItemId = json.order_item_id
      attrs._orderItemLinkId = json.order_item_link_id
      attrs._price = json.price
      attrs._quantity = parseInt(json.quantity)
      attrs._ticketGroup = new App.Models.V3.TicketGroup(json.ticket_group, {
        mapping: C.Mappings.Direct.Order.TicketGroup
      })
      attrs._seats = json.seats
      attrs._updatedAt = Date.parse(json.updated_at)
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.OrdersLightweight.OrderItems)
      attrs._cost = json.cost
      attrs._event = new App.Models.V3.Event(json.event, {
        mapping: C.Mappings.Direct.OrdersLightweight.Event
      })
      attrs._needsEticket = json.needs_eticket
      attrs._price = json.price
      attrs._quantity = json.quantity
      attrs._row = json.row
      attrs._seats = json.seats
      attrs._section = json.section
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Orders.OrderItems)
      attrs._cost = json.cost
      attrs._createdAt = Date.parse(json.created_at)
      attrs._eTicketDownloadedAt = Date.parse(json.eticket_downloaded_at)
      attrs._eTicketDownloadedBy = json.eticket_downloaded_by
      attrs._eTicketFinalizedAt = Date.parse(json.eticket_finalized_at)
      attrs._id = json.order_item_id #json.id
      attrs._isEticket = json.eticket_delivery
      attrs._isEticketAvailable = json.eticket_available
      attrs._isEticketFinalized = json.eticket_finalized
      #attrs._orderItemId = json.order_item_id
      attrs._needsEticket = json.needs_eticket
      attrs._needsSub = json.needs_sub
      attrs._price = json.price
      attrs._quantity = parseInt(json.quantity)
      attrs._ticketGroup = new App.Models.V3.TicketGroup(json.ticket_group, {
        mapping: C.Mappings.Direct.Orders.TicketGroup
      })
      attrs._updatedAt = Date.parse(json.updated_at)
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.TicketCosts.OrderItem)
      attrs._buyer = new App.Models.V3.Patron(json.buyer, {
        mapping: C.Mappings.Direct.TicketCosts.Patron
      })
      attrs._createdAt = Date.parse(json.created_at)
      attrs._eventId = json.event_id
      attrs._id = json.id
      attrs._price = json.price
      attrs._quantity = parseInt(json.quantity)
      attrs._soldInOrderItems = null
      if (json.sold_in_order_items?.length)
        attrs._soldInOrderItems = new App.Collections.V3.OrderItems(json.sold_in_order_items, {
          mapping: C.Mappings.Direct.TicketCosts.SoldOrderItem
        })
      #attrs._soldTickets = new App.Collections.V3.Tickets(json.sold_tickets, {
      #  mapping: C.Mappings.Direct.TicketCosts.SoldTickets
      #})

    if (@getMapping() == C.Mappings.Direct.TicketCosts.SoldOrderItem)
      attrs._buyer = new App.Models.V3.Patron(json.buyer, {
        mapping: C.Mappings.Direct.TicketCosts.Patron
      })
      attrs._createdAt = Date.parse(json.created_at)
      attrs._id = json.id
      attrs._orderId = json.order_id
      attrs._price = json.price
      attrs._quantity = parseInt(json.quantity)
      attrs._soldTickets = new App.Collections.V3.Tickets(json.tickets, {
        mapping: C.Mappings.Direct.TicketCosts.SoldTickets
      })

    if (@getMapping() == C.Mappings.OrderEndpoint.OrderItem)
      attrs._cost = json.cost
      attrs._needsEticket = json.needs_eticket
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.OrderItemsByEvent.OrderItems)
      attrs._buyerId = json.buyer_id
      attrs._buyerState = json.buyer_state
      attrs._customer = json.buyer_name
      attrs._createdAt = Date.parse(json.created_at)
      attrs._createdBy = json.created_by
      attrs._event = new App.Models.V3.Event(json.event, {
        mapping: C.Mappings.Direct.OrderItemsByEvent.Event
      })
      attrs._isConsignment = json.consignment
      attrs._isEticket = json.eticket_delivery
      attrs._isEticketFinalized = json.eticket_finalized
      attrs._id = json.id
      attrs._invoiceNumber = json.invoice_number
      attrs._isOrderSpecFulfilled = json.order_spec_fulfilled
      attrs._isSpec = json.item_has_spec_tickets
      attrs._needsSub = json.item_needs_sub
      attrs._notes = json.notes
      attrs._orderGroupId = json.order_group_id
      attrs._orderId = json.order_id
      attrs._orderIsSpec = json.order_is_spec
      attrs._price = json.price
      attrs._quantity = parseInt(json.quantity)
      attrs._row = json.row
      attrs._seats = json.seats
      attrs._section = json.section
      attrs._sellerId = json.seller_id
      attrs._sellerState = json.seller_state
      attrs._updatedAt = Date.parse(json.updated_at)
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  toJSON: (options = {}) ->
    json = parent.toJSON.call(@, options)
    json = _.extend(json, {
      order_id:           @get('_orderId')
      order_item_id:      @get('_orderItemId')
      order_item_link_id: @get('_orderItemLinkId')
    })
    return json

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      buyer: @get('_buyer')?.toPresenterJSON()
      buyerId: @get('_buyerId')
      cid: @cid
      createdAt: @get('_createdAt')
      createdBy: @get('_createdBy')
      customer: @get('_customer')
      eTicketDownloadedAt: @get('_eTicketDownloadedAt')
      eTicketFinalizedAt: @get('_eTicketFinalizedAt')
      eTicketsMissing: @eTicketsMissing()
      eTicketPDFPages: @get('_eTicketPDFPages')
      eTicketThumbnails: @get('_eTicketThumbnails')?.toPresenterJSON()
      event: @get('_event')?.toPresenterJSON()
      flags: @flags()
      format: @format()
      id: @get('_id')
      isEticket: @get('_isEticket')
      isEticketFinalized: @get('_isEticketFinalized')
      isSpec: @get('_isSpec')
      needsEticket: @get('_needsEticket')
      needsSub: @get('_needsSub')
      orderId: @get('_orderId')
      price: @get('_price')
      quantity: @get('_quantity')
      row: @get('_row')
      seats: @get('_seats')
      section: @get('_section')
      soldTickets: @get('_soldTickets')?.toPresenterJSON()
      ticketGroup: @get('_ticketGroup')?.toPresenterJSON()
      unfilledCount: @unfilledCount()

    })
    return presented

  eTicketsMissing: () ->
    if (@get('ticketGroup')?.get('_format') == C.TicketFormats.Eticket && !@get('_isEticketFinalized'))
      return '<span class="label label-important">Missing</span>'
    return ''

  format: () ->
    if (@get('_ticketGroup'))
      if (@get('_ticketGroup').get('_format') == C.TicketFormats.Eticket)
        return "Eticket"
      if (@get('_ticketGroup').get('_format') == C.TicketFormats.Physical)
        return "Physical"
      if (@get('_ticketGroup').get('_format') == C.TicketFormats.FlashSeats)
        return "AXS"
      if (@get('_ticketGroup').get('_format') == C.TicketFormats.Paperless)
        return "Paperless"
      if (@get('_ticketGroup').get('_format') == C.TicketFormats.GuestList)
        return "Guest List"
      if (@get('_ticketGroup').get('_format') == C.TicketFormats.TMMobile)
        return "Mobile Transfer"
    else
      return "Format unknown"

  flags: () ->
    if (@get('_notes')?.length)
      return C.FlagBadges.Notes
    return ''

  unfilledCount: () ->
    if (@get('_isSpec') && !@get('_isOrderSpecFulfilled'))
      return @get('_quantity')
    if (@get('_needsSub'))
      return @get('_quantity')
    return 0
  #///////////////////////////////////////////////////////////////////////////

  eventOccursAt: (options = {}) ->
    {
      raw
    } = options
    if (@get('_event'))
      if (raw)
        occursAt = @get('_event').get('_occursAtRaw')
      else
        occursAt = @get('_event').get('_occursAt')
    else if (@get('ticket_group'))
      if (raw)
        occursAt = @get('ticket_group').event.occurs_at
      else
        occursAt = Date.parse(@get('ticket_group').event.occurs_at)
    else
      occursAt = null
    return occursAt


  # TODO MON
  # Refetch here.  Undo all the clear initialize shit.
  # Rectify the orderId vs id shit on v2 and v3 orderItemModels.
  # Make uploadsController.refreshViews cleaner.

  deleteAllEticketsPromise: () ->
    deferred = Q.defer()
    $.ajax({
      type: 'GET'
      url: "/api_direct/v9/orders/#{ @get('_orderId') }/items/#{ @id }/remove_etickets"
      success: (response) =>
        @fetchPromise()
        .then (model) =>
          deferred.resolve(@)
        .fail (errors) =>
          deferred.reject(errors)
        .done()
      error: (response) =>
        deferred.reject(new Error('Error deleting all etickets.'))
    })
    return deferred.promise

  finalizeEticketsPromise: (options) ->
    {
      isIgnorePageRulesChecked
      pagesToKeep
    } = options
    deferred = Q.defer()
    params = {
      keep_pages: pagesToKeep
    }
    if (isIgnorePageRulesChecked)
      params['ignore_quantity'] = true
    $.ajax({
      type: 'POST'
      url: "/api_direct/v9/orders/#{ @get('_orderId') }/items/#{ @id }/finalize_etickets"
      data: JSON.stringify(params)
      success: (response) =>
        @fetchPromise()
        .then (model) =>
          deferred.resolve(@)
        .fail (errors) =>
          deferred.reject(errors)
        .done()
      error: (response) =>
        deferred.reject(new Error('Error finalizing Etickets.'))
    })
    return deferred.promise

  manuallySendEticketEmailPromise: (options) ->
    {
      recipients
    } = options
    params = {
      recipients
    }
    deferred = Q.defer()
    $.ajax({
      type: 'POST'
      url: "/api_direct/v9/orders/#{ @get('_orderId') }/items/#{ @id }/eticket_download_link"
      data: JSON.stringify(params)
      success: (response) =>
        @fetchPromise()
        .then (model) =>
          deferred.resolve(@)
        .fail (errors) =>
          deferred.reject(errors)
        .done()
      error: (response) =>
        deferred.reject(new Error('Error emailing etickets.'))
    })
    return deferred.promise
