template = require('./file_upload_outcome.ejs')

module.exports = App.Views.Order.FileUploadOutcome = Backbone.View.extend

  template: template

  initialize: (options = {}) ->
    {
      @fileModel
      @$container
      @loadingBox
      @errorMessage
    } = options
    @render()

  render: () ->
    data = {
      file: @fileModel?.toPresenterJSON()
      isError: !@fileModel
      errorMessage: @errorMessage
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )
    @loadingBox?.stopLoad()