App.Models.Base.BaseModel = require('./base/base_model.coffee')

module.exports = App.Models.PhoneNumber = App.Models.Base.BaseModel.extend

  defaults:
    extension:    ''
    number:       ''

  validates:
    'number':
      required: true

  toPresenterJSON: ->
    _.extend @super('toPresenterJSON'),
      pretty: @to_s()

  to_s: ->
    str = ""
    str = "#{str} #{@get("number")}" if @get('number')
    str = "#{str} ex.#{@get("extension")}" if @get('extension')
    if @get('number') then str else "No Phone Number"
