template = require('./affiliate_link.ejs')
App.Views.Base.BaseView = require('./base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.AffiliateLink = App.Views.Base.BaseView.extend

  template: template

  className: 'modal hide fade'

  initialize: (options = {}) ->
    @filters = options
    parent.initialize.call(@, options)
    @render()

  render: () ->

    linkParams = ["refid=#{App.Utils.affiliateLinkIncludingUserIdsHash()}"]
    linkBase = "#{App.Utils.baseAffiliateLink()}events/event-search/"
    if @filters.event_id
      linkBase += @filters.event_id
    else
      for key, value of @filters
        linkParams.push("#{key}=#{encodeURIComponent(value)}")

    affiliateLink = "#{linkBase}?#{linkParams.join('&')}"

    @$el.html(
      @template({link: affiliateLink})
    )

    # STANDARDIZED MODAL
    @$el.modal('show').on('hidden', () =>
      @remove()
    )

  events: {
    'click #copy_link_button': 'onCopyLinkButtonClick'
  }

  onCopyLinkButtonClick: (e) ->
    linkTextArea = document.querySelector('#affiliate_link_field');
    linkTextArea.select()
    document.execCommand('copy')
    e.currentTarget.style["color"] = "green"

