App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

ONE_MILLION = 1000000

stringSafeBool = (val) =>
  if (_.isString(val))
    if (val == 'true' || val == 't')
      return true
    return false
  return val

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.TicketGroup = App.Models.Base.V3BaseModel.extend

  url: () ->
    if (@purpose == C.Purposes.SaveEtickets)
      return '/api_direct/v9/tickets/mass_update'
    else if (@purpose == C.Purposes.SavePriceEdits)
      return "/api_direct/v9/ticket_groups/#{ @id }"
    else
      return "/api_direct/v9/listings/#{ @id }"

  validation: () ->

    if (@purpose == C.Purposes.SaveEtickets)
      return {}

    if (@purpose == C.Purposes.SavePriceEdits)
      return {}

    base = {
      _canOverrideSplits:
        oneOf: [true, false]
      _cost:
        range: [0, ONE_MILLION]
      _excludedExchanges: (excludedExchanges) =>
        if (excludedExchanges && !_.isArray(excludedExchanges) && excludedExchanges != C.IncludeAllExchanges)
          return 'Invalid Excluded Exchanges.'
      _format: (format) =>
        if (@get('_isEticket'))
          if (format != C.TicketFormats.Eticket)
            return 'Inconsistent ticket format.'
      _inHandOn: (inHandOn) =>
        if (!@get('_isInHand'))
          if (!inHandOn && !@get('_inHandOnDays'))
            return 'In hand on date required if not in hand.'
      _isBroadcast:
        oneOf: [true, false]
      _isEticket:
        oneOf: [true, false]
      _isInHand:
        oneOf: [true, false]
      _isAutomated:
        oneOf: [true, false]
      _isInstantDelivery: (isInstantDelivery) =>
        if (@get('_format') == C.TicketFormats.Physical && isInstantDelivery)
          return 'Physical tickets cannot be instant delivery.'
      _isWheelchair:
        oneOf: [true, false]
      _lowSeat:
        range: [1, ONE_MILLION]
      _notesBroker: null
      _notesPrivate: null
      _notesPublic: null
      _priceWholesale:
        range: [0, ONE_MILLION]
      _quantity:
        min: 1
      _row:
        required: true
      _seatOrder:
        oneOf: [C.SeatOrder.Consecutive,  C.SeatOrder.OddEven]
      _section:
        required: true
      _splits: (splits) =>
        if (@get('_canOverrideSplits') && !splits)
          return 'Splits must be specified if overriding splits.'
      _stubhubTraits: null
      _type:
        oneOf: Object.keys(C.Options.TicketGroup.Types)
      _viewType:
        oneOf: Object.keys(C.Options.TicketGroup.ViewTypes)
    }
    if (@get('_isSpec'))
      # New Spec PO
      return _.extend({}, base, {

      })
    else
      # New PO & New Consignment PO
      return base

  # Appear when validation fails.
  labels:
    _canOverrideSplits: 'Override Splits'
    _cost: 'Cost'
    _excludedExchanges: 'Excluded Exchanges'
    _format: 'Format'
    _lowSeat: 'Low Seat'
    _inHandOn: 'In Hand On'
    _isBroadcast: 'Broadcast'
    _isEticket: 'Eticket'
    _isInHand: 'In Hand'
    _isAutomated: 'Instant Confirm'
    _isInstantDelivery: 'Instant Delivery'
    _isWheelchair: 'Wheelchair Accessible'
    _notesBroker: 'Broker Notes'
    _notesPrivate: 'Private Notes'
    _notesPublic: 'Public Notes'
    _priceFace: 'Face Value'
    _priceRetail: 'Retail Price Override'
    _priceWholesale: 'Wholesale Price'
    _quantity: 'Quantity'
    _row: 'Row'
    _seatOrder: 'Seat Order'
    _section: 'Section'
    _splits: 'Splits'
    _stubhubTraits: 'Ticket Traits'
    _type: 'Type'
    _viewType: 'View Type'

  toJSON: () ->
    if (@purpose == C.Purposes.OrderItem)
      json = {
        low_seat:         @get('_lowSeat')
        # Price is populated manually in EventsCollection.toOrderItems().
        # price:            @get('_price')
        quantity:         @get('_quantity')
        ticket_group_id:  @id
        wholesale_price:  @get('_priceWholesale')
      }
      if (@has('_seatOrder'))
        json.seat_order = @get('_seatOrder')
      return json

    else if (@purpose == C.Purposes.SaveEtickets)
      json = {
        tickets: []
      }
      v3EticketsCollection = @get('_etickets')
      json.tickets = v3EticketsCollection.map((v3EticketModel) ->
        return v3EticketModel.toJSON()
      )
      json.eticket_pack_ids = @get('_eticketPackIds')
      return json
    else if (@purpose == C.Purposes.SavePriceEdits)
      json = {
        retail_price_override: @get('_priceRetailOverride')
        price: @get('_priceWholesale')
      }
      return json
    else
      json = parent.toJSON.call(@)
      return json

  urlRoot: () ->
    if (@mapping == C.Mappings.Direct.TicketGroups.TicketGroup)
      return @url()
    return "/api_direct/v9/ticket_groups/"

  fetch: (options) ->
    @mapping = C.Mappings.SingleTicketGroupEndpoint.TicketGroup
    return parent.fetch.call(@, options)

  fetchPromise: (options) ->
    @mapping = C.Mappings.Direct.TicketGroups.TicketGroup
    return parent.fetchPromise.call(@, options)

  initialize: (attributes, options) ->
    parent.initialize.call(this, attributes, options)
    # OLD FIELDS
    @set('seat_range', @seatRangeStrict())
    @set('eventId', @get('_event')?.get('_id'))

  # PREVENT toPresenterJSON from choking on nulls.
  defaults:
    _ticketStates:
      available: 0
      consigned: 0

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    attrs._isAutomated = !!json.automated

    if (@getMapping() == C.Mappings.Direct.FilteredTickets.TicketGroup)
      attrs._event = new App.Models.V3.Event(json.event, {
        mapping: C.Mappings.Direct.FilteredTickets.Event
      })
      attrs._id = json.id
      attrs._row = json.row
      attrs._section = json.section
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.OrderItems.TicketGroup)
      attrs._brokerageId = json.brokerage_id
      attrs._brokerageName = json.brokerage_name
      attrs._event = new App.Models.V3.Event(json.event, {
        mapping: C.Mappings.Direct.OrderItems.Event
      })
      attrs._notesPublic = json.external_notes
      attrs._format = json.format
      attrs._id = json.id
      attrs._isInHand = stringSafeBool(json.in_hand)
      attrs._inHandOn = json.in_hand_on
      attrs._officeId = json.office_id
      attrs._officeName = json.office_name
      attrs._priceRetail = json.retail_price
      attrs._priceWholesale = json.wholesale_price
      attrs._quantity = json.quantity
      attrs._remoteId = json.remote_id
      attrs._row = json.row
      attrs._seats = @seatsToArray(json.seats)
      attrs._section = json.section
      attrs._tickets = new App.Collections.V3.Tickets(json.tickets, {
        mapping: C.Mappings.Direct.OrderItems.Tickets
      })
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Order.SubstitutionOrderItemTicketGroup)
      attrs._event = new App.Models.V3.Event(json.event, {
        mapping: C.Mappings.Direct.Order.SubstitutionEvent
      })
      attrs._notesPublic = json.external_notes
      attrs._id = json.id
      attrs._officeId = json.office_id
      attrs._quantity = json.quantity
      attrs._remoteId = json.remote_id
      attrs._row = json.row
      attrs._seats = @seatsToArray(json.seats)
      attrs._section = json.section
      attrs._priceWholesale = json.wholesale_price
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() ==C.Mappings.Direct.EvoQuotes.TicketGroup)
      attrs._brokerageName = json.brokerage_name
      attrs._notesPublic = json.external_notes
      attrs._index = json.index
      attrs._price = json.price
      attrs._quantity = json.quantity
      attrs._row = json.row
      attrs._section = json.section
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Order.TicketGroup)
      attrs._brokerageId = json.brokerage_id
      attrs._brokerageName = json.brokerage_name
      if (json.sold_as)
        attrs._sold_as = new App.Models.V3.Event(json.sold_as, {
          mapping: C.Mappings.Direct.Order.Event
        })
      attrs._event = new App.Models.V3.Event(json.event, {
        mapping: C.Mappings.Direct.Order.Event
      })
      attrs._notesPublic = json.external_notes
      attrs._format = json.format
      attrs._id = json.id
      attrs._isInHand = stringSafeBool(json.in_hand)
      attrs._inHandOn = json.in_hand_on
      attrs._isBroadcast = json.broadcast
      attrs._isEticket = json.eticket
      attrs._isFeatured = json.featured
      attrs._isInstantDelivery = json.instant_delivery
      attrs._officeId = json.office_id
      attrs._officeName = json.office_name
      attrs._priceRetail = json.retail_price
      attrs._priceWholesale = json.wholesale_price
      attrs._quantity = json.quantity
      attrs._remoteId = json.remote_id
      attrs._row = json.row
      attrs._seats = @seatsToArray(json.seats)
      attrs._section = json.section
      attrs._tickets = new App.Collections.V3.Tickets(json.tickets, {
        mapping: C.Mappings.Direct.Order.Tickets
      })
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.Carts.TicketGroups)
      attrs._event = new App.Models.V3.Event(json.event, {
        mapping: C.Mappings.Direct.Carts.Event
      })
      attrs._notesPublic = json.external_notes
      attrs._id = json.id
      attrs._officeId = json.office_id
      attrs._priceRetail = json.retail_price
      attrs._priceWholesale = json.wholesale_price
      attrs._quantity = json.quantity
      attrs._remoteId = json.remote_id
      attrs._row = json.row
      attrs._section = json.section
      attrs._tickets = new App.Collections.V3.Tickets(json.ticket, {
        mapping: C.Mappings.Direct.Carts.TicketGroupsTickets
      })

    if (@getMapping() == C.Mappings.Direct.Orders.TicketGroup)
      attrs._brokerageId = json.brokerage_id
      attrs._event = new App.Models.V3.Event(json.event, {
        mapping: C.Mappings.Direct.Orders.Event
      })
      attrs._format = json.format
      attrs._id = json.id
      attrs._officeId = json.office_id
      attrs._notesPublic = json.external_notes
      attrs._priceRetail = json.retail_price
      attrs._priceWholesale = json.wholesale_price
      attrs._quantity = json.quantity
      attrs._remoteId = json.remote_id
      attrs._row = json.row
      attrs._seats = @seatsToArray(json.seats)
      attrs._section = json.section
      attrs._tickets = new App.Collections.V3.Tickets(json.tickets, {
        mapping: C.Mappings.Direct.Orders.Tickets
      })
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.Direct.TicketGroups.TicketGroup)
      attrs._createdAt = Date.parse(json.created_at)
      attrs._errors = json.errors
      attrs._event = new App.Models.V3.Event(json.event, {
        mapping: C.Mappings.Direct.TicketGroups.Event
      })
      attrs._evopayDiscount = json.evopay_discount
      attrs._evopayDiscountPrice = json.evopay_discount_price
      attrs._format = json.format
      attrs._freshness = json.freshness
      attrs._hasSpecTickets = json.has_spec_tickets
      attrs._id = json.id
      attrs._inHandOn = json.in_hand_on
      attrs._isBroadcast = json.broadcast
      attrs._isEticket = json.eticket
      attrs._isFeatured = json.featured
      attrs._isInHand = stringSafeBool(json.in_hand)
      attrs._isWheelchair = json.wheelchair
      attrs._office = new App.Models.V3.Office(json.office, {
        mapping: C.Mappings.Direct.TicketGroups.Office
      })
      attrs._notesBroker = json.exchange_notes
      attrs._notesPublic = json.public_notes
      attrs._notesPrivate = json.private_notes
      attrs._priceFace = json.face_value
      attrs._priceRetail = json.retail_price
      attrs._priceRetailOverride = json.retail_price_override
      attrs._priceWholesale = json.wholesale_price
      attrs._quantity = json.quantity
      attrs._row = json.row
      attrs._seats = @seatsToArray(json.seats)
      attrs._section = json.section
      attrs._signature = json.signature
      attrs._splits = json.splits
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      attrs._viewType = json.view_type
      attrs._ticketCosts = json.ticket_costs
      attrs._ticketHoldIds = json.ticket_hold_ids
      attrs._ticketList = new App.Collections.V3.Tickets(json.ticket_list, {
        mapping: C.Mappings.Direct.TicketGroups.TicketList
      })
      attrs._ticketPOIds = json.ticket_purchase_order_ids
      attrs._ticketStates = json.ticket_states
      attrs._ticketTraits = json.ticket_traits
      attrs._tickets = new App.Collections.V3.Tickets(json.tickets, {
        mapping: C.Mappings.Direct.TicketGroups.Tickets
      })
      attrs._type = json.type
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.SingleTicketGroupEndpoint.TicketGroup)
      attrs._createdAt = Date.parse(json.created_at)
      attrs._errors = json.errors
      attrs._event = new App.Models.V2.Event(json.event, {
        mapping: C.Mappings.SingleTicketGroupEndpoint.Event
      })
      attrs._evopayDiscount = json.evopay_discount
      attrs._evopayDiscountPrice = json.evopay_discount_price
      attrs._isBroadcast = json.broadcast
      attrs._isEticket = json.eticket
      attrs._isFeatured = json.featured
      attrs._format = json.format
      attrs._freshness = json.freshness
      attrs._hasSpecTickets = json.has_spec_tickets
      attrs._id = json.id
      attrs._inHandOn = json.in_hand_on
      attrs._isInHand = stringSafeBool(json.in_hand)
      attrs._isWheelchair = json.wheelchair
      attrs._office = new App.Models.V2.Office(json.office, {
        mapping: C.Mappings.SingleTicketGroupEndpoint.Office
      })
      attrs._notesBroker = json.exchange_notes
      attrs._notesPublic = json.public_notes
      attrs._notesPrivate = json.private_notes
      attrs._priceFace = json.face_value
      attrs._priceRetail = json.retail_price
      attrs._priceRetailOverride = json.retail_price_override
      attrs._priceWholesale = json.wholesale_price
      attrs._quantity = json.quantity
      attrs._row = json.row
      attrs._seats = @seatsToArray(json.seats)
      attrs._section = json.section
      attrs._serverMessage = json.server_message
      attrs._signature = json.signature
      attrs._splits = json.splits
      attrs._statusCode = json.status_code
      attrs._ticketCosts = json.ticket_costs
      attrs._ticketHoldIds = json.ticket_hold_ids
      attrs._ticketPOIds = json.ticket_purchase_order_ids
      attrs._ticketStates = json.ticket_states
      # MAKE THIS A COLLECTION OF TicketModel ?
      attrs._tickets = new App.Collections.V3.Tickets(json.tickets, {
        mapping: C.Mappings.SingleTicketGroupEndpoint.Ticket
      })
      attrs._type = json.type
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      attrs._viewType = json.view_type
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.TicketGroupEndpoint.TicketGroup)
      attrs._age = @age(json.freshness)
      attrs._availableQuantity = json.available_quantity
      attrs._brokerageAbbreviation = json.office?.brokerage?.abbreviation
      attrs._consignment = json.consignment
      attrs._consignmentCosts = json.consignment_cost
      attrs._createdAt = Date.parse(json.created_at)
      attrs._evopayDiscount = json.evopay_discount
      attrs._evopayDiscountPrice = json.evopay_discount_price
      attrs._format = json.format
      attrs._unifiedFormat = if (json.format == C.TicketFormats.FlashSeats) then C.TicketFormats.TMMobile else json.format
      attrs._freshness = json.freshness
      attrs._hasSpecTickets = json.has_spec_tickets
      attrs._id = json.id
      attrs._inHandOn = json.in_hand_on
      attrs._isBroadcast = @isBroadcast()
      attrs._isFeatured = json.featured
      attrs._isInHand = stringSafeBool(json.in_hand)
      attrs._isInstantDelivery = json.instant_delivery
      attrs._isWheelchair = json.wheelchair
      attrs._notesBroker = json.exchange_notes
      attrs._notesPublic = json.public_notes
      attrs._notesPrivate = json.private_notes
      attrs._office = new App.Models.V2.Office(json.office, {
        mapping: C.Mappings.TicketGroupEndpoint.Office
      })
      attrs._priceFace = json.face_value
      attrs._priceRetail = json.retail_price
      attrs._priceRetailOverride = json.retail_price_override
      attrs._priceWholesale = json.wholesale_price
      attrs._quantity = json.quantity
      attrs._row = json.row
      attrs._seats = @seatsToArray(json.seats)
      attrs._section = json.section
      attrs._signature = json.signature
      attrs._splits = json.splits
      attrs._ticketCosts = json.ticket_costs
      attrs._ticketHoldIds = json.ticket_hold_ids
      attrs._ticketList = new App.Collections.V3.Tickets(json.ticket_list, {
        mapping: C.Mappings.TicketGroupEndpoint.TicketList
      })
      attrs._ticketPOIds = json.ticket_purchase_order_ids
      attrs._tickets = new App.Collections.V3.Tickets(json.tickets, {
        mapping: C.Mappings.TicketGroupEndpoint.Tickets
      })
      attrs._ticketStates = json.ticket_states
      attrs._ticketTraits = json.ticket_traits
      attrs._type = json.type
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      attrs._viewType = json.view_type
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.OrderEndpoint.TicketGroup)
      attrs._brokerageId = json.brokerage_id
      attrs._createdAt = @get('_order')?.get('_createdAt')
      attrs._event = new App.Models.V2.Event(json.event, {
        mapping: C.Mappings.OrderEndpoint.Event
      })
      attrs._format = json.format
      attrs._id = json.id
      attrs._notesPublic = json.external_notes
      attrs._officeId = json.office_id
      attrs._priceRetail = json.retail_price
      attrs._priceWholesale = json.wholesale_price
      attrs._quantity = json.quantity
      attrs._remoteId = json.remote_id
      attrs._row = json.row
      attrs._seats = @seatsToArray(json.seats)
      attrs._section = json.section
      attrs._url = json.url
      #tickets: [{purchase_order_id:null, seat:null}, {purchase_order_id:null, seat:null},…]
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.OrderEndpoint.TicketGroup)
      attrs._brokerageId = json.brokerage_id
      attrs._createdAt = @get('_order')?.get('_createdAt')
      attrs._event = new App.Models.V2.Event(json.event, {
        mapping: C.Mappings.OrderEndpoint.Event
      })
      attrs._format = json.format
      attrs._id = json.id
      attrs._notesPublic = json.external_notes
      attrs._officeId = json.office_id
      attrs._priceRetail = json.retail_price
      attrs._priceWholesale = json.wholesale_price
      attrs._quantity = json.quantity
      attrs._remoteId = json.remote_id
      attrs._row = json.row
      attrs._seats = @seatsToArray(json.seats)
      attrs._section = json.section
      attrs._url = json.url
      #tickets: [{purchase_order_id:null, seat:null}, {purchase_order_id:null, seat:null},…]
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.MassIndexEndpoint.TicketGroup)
      eventAttrs = _.pick(json,
        'event_id',
        'event_name',
        'event_notes',
        'event_occurs_at',
        'event_occurs_local',
        'event_popularity_score',
        'event_short_term_popularity_score',
        'event_state',
        'event_tbd'
      )
      attrs._brokerageId = json.ticket_group_brokerage_id
      attrs._canOverrideSplits = json.ticket_group_split_override
      attrs._cost = json.ticket_group_cost
      attrs._createdAt = Date.parse(json.ticket_group_created_at)
      attrs._event = new App.Models.V2.Event(eventAttrs, {
        mapping: C.Mappings.MassIndexEndpoint.Event
      })
      attrs._format = json.ticket_group_format
      attrs._hasClubAccess = json.ticket_group_club_access
      attrs._hasSpecTickets = json.ticket_group_speculative
      attrs._id = json.ticket_group_id
      attrs._isBroadcast = @isBroadcast()
      attrs._isEticket = json.ticket_group_eticket
      attrs._inHandOn = json.ticket_group_in_hand_on
      attrs._isInHand = stringSafeBool(json.ticket_group_in_hand)
      attrs._notesBroker = json.ticket_group_broker_notes
      attrs._notesPublic = json.ticket_group_external_notes
      attrs._notesPrivate = json.ticket_group_internal_notes
      attrs._officeId = json.ticket_group_office_id
      attrs._priceFace = json.ticket_group_face_value
      attrs._priceRetail = json.ticket_group_retail_price_override
      attrs._priceWholesale = json.ticket_group_price
      attrs._quantity = json.ticket_group_tickets_count
      attrs._row = json.ticket_group_row
      attrs._seats = json.ticket_group_ticket_seats
      attrs._section = json.ticket_group_section
      attrs._ticketIds = json.ticket_group_ticket_ids
      attrs._ticketStates = json.ticket_group_ticket_states
      attrs._type = json.ticket_group_type
      attrs._viewType = json.ticket_group_view_type
      venueAttrs = _.pick(json,
        'venue_address_company',
        'venue_address_country_code',
        'venue_address_extended_address',
        'venue_address_id',
        'venue_address_label',
        'venue_address_locality',
        'venue_address_name',
        'venue_address_postal_code',
        'venue_address_region',
        'venue_address_street_address',
        'venue_id',
        'venue_name'
      )
      attrs._venue = new App.Models.V2.Venue(venueAttrs, {
        mapping: C.Mappings.MassIndexEndpoint.Venue
      })

    @set(attrs)

  age: (freshness) ->
    if (C.Freshness[freshness])
      return C.Freshness[freshness]
    else
      return C.Freshness.Max

  mySpec: (hasSpec) ->
    return (hasSpec && SESSION.office_id)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: (options = {}) ->
    {
      isPriceEditTable
    } = options

    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      automated:                !!@get('_isAutomated')
      availability:             @availability()
      availableQuantity:        @get('_availableQuantity')
      availableSeatsString:     @availableSeatsString()
      availableSeatDetails:     @availableSeatDetails()
      broadcastCSS:             @broadcastCSS()
      brokerageName:            @get('_brokerageName')
      buyer:                    @get('_buyer')?.toPresenterJSON()
      canWaste:                 @canWaste()
      cid:                      @cid
      cost:                     @cost()
      costPerTicket:            App.Utils.valueToCurrency(@get('_costPerTicket'))
      createdAt:                @get('_createdAt')
      event:                    @get('_event')?.toPresenterJSON()
      flags:                    @flags()
      format:                   @format()
      freshness:                @get('_freshness')
      hasAllEticketsUploaded:   @hasAllEticketsUploaded()
      hasSomeEticketsUploaded:  @hasSomeEticketsUploaded()
      hasSeatNumbers:           @hasSeatNumbers()
      heldPrice:                @get('_heldPrice')
      heldSeats:                @get('_heldSeats')
      id:                       @get('_id')
      isEticket:                @get('_isEticket')
      isBroadcast:              @isBroadcast()
      isInHand:                 @get('_isInHand')
      isInstantDelivery:        @get('_isInstantDelivery')
      isOwned:                  @isOwned()
      isWheelchair:             @get('_isWheelchair')
      myBrokerageId:            SESSION.brokerage_id
      notesBroker:              @get('_notesBroker')
      notesPublic:              @get('_notesPublic')
      notesPrivate:             @get('_notesPrivate')
      office:                   @get('_office')?.toPresenterJSON()
      order:                    @get('_order')?.toPresenterJSON()
      orderItem:                @get('_orderItem')?.toPresenterJSON()
      price:                    @get('_price')
      priceFace:                @get('_priceFace')
      priceRetail:              @get('_priceRetail')
      priceWholesale:           @get('_priceWholesale')
      purchaseOrderId:          @purchaseOrderId()
      purchasePrice:            @purchasePrice()
      quantity:                 @get('_quantity')
      quantitySeparated:        @quantitySeparated()
      retailPrice:              @get('_priceRetail')
      retailPricePerTicket:     @get('_priceRetailPerTicket')
      row:                      @get('_row')
      salePricePerTicket:       App.Utils.valueToCurrency(@get('_ticketsSalePricePerTicket'))
      seats:                    @seats()
      seatRange:                @seatRangeStrict()
      section:                  @get('_section')
      seller:                   @get('_seller')
      soldQuantity:             @soldQuantity()
      soldSeatRange:            @soldSeatRange()
      splits:                   @get('_splits')
      tickets:                  @get('_ticketList')?.sort().toPresenterJSON()
      ticketStates:             @get('_ticketStates')
      # The `_ticketHolds` collection/property is not set as part of instance
      # creation or the `updateStandardAttributes` method.
      # ticketHolds:              @get('_ticketHolds')?.toPresenterJSON()
      type:                     C.Options.TicketGroup.Types[@get('_type')]
      uniqueStates:             @uniqueTicketStates()
      viewType:                 @get('_viewType') || ''
      wholesalePrice:           App.Utils.valueToCurrency(@get('_priceWholesale'))
      wholesalePricePerTicket:  App.Utils.valueToCurrency(@get('_priceWholesalePerTicket'))
    })

    if (isPriceEditTable)
      presented = _.extend(presented, {
#          editRetailInput: "<div class='input-prepend'>
#              <span class='add-on'>$</span>
#              <input value='#{ @get('_priceRetailEdited') || @get('_priceRetail') }' type='text' class='actLikeCurrency' placeholder='0.00' style='width: 80px;'/>
#            </div>"
        editWholesaleInput: "<div class='input-prepend' style='margin: 5px;'>
            <span class='add-on'>$</span>
            <input data-ticket-group-id='#{ @id }' value='#{ @get('_priceWholesaleEdited') || @get('_priceWholesale') }' type='text' class='editWholesaleInput actLikeCurrency' placeholder='0.00' style='width: 80px;'/>
          </div>"
      })

    return presented

  availability: () ->
    if (@get('_isInHand'))
      return 'In Hand'
    else
      if (@get('_inHandOn'))
        return App.Utils.makeTimestampHuman(@get('_inHandOn'), C.DateFormats.LongNoTime)
    return 'Not In Hand'

  availableSeatsHelper: () ->
    tickets = @get('_tickets')
    if (tickets)
      available = tickets.filter (ticketModel) ->
        return ticketModel.get('_state') == 'available'
      available = available.map (ticketModel) ->
        return ticketModel.get('_seat')
      available = available.sort((a, b) -> return a - b )
      return available
    else
      return []

  availableSeatsString: (forNSeats = null, startingHigh = false) ->
    available = @availableSeatsHelper()

    if (startingHigh)
      available = available.reverse()

    if (available.length)

      first = _.first(available)
      if (first == null) # null => no seat numbers
        return ''

      if (forNSeats && available.length >= forNSeats)
        if (forNSeats == 1)
          return first

        lastSeat = available[forNSeats - 1]
        if (startingHigh)
          return "#{ lastSeat } - #{ first }"
        else
          return "#{ first } - #{ lastSeat }"

      if (available.length > 1)
        return "#{ first } - #{ _.last(available) }"

      else
        return first

    return ''

  availableSeatDetails: () ->
    availableTickets = []
    ticketList = @get('_ticketList')
    if (ticketList)
      @get('_ticketList').each((ticketModel) ->
        state = ticketModel.get('_state')
        if (state == 'available')
          availableTickets.push({
            seat: ticketModel.get('_seat')
            state
            ticketId: ticketModel.id
          })
      )
      availableTickets = availableTickets.sort((a, b) ->
        return parseInt(a.seat) - parseInt(b.seat)
      )
    return availableTickets

  canWaste: () ->
    # Used to check for available or consigned in ticket groups endpoint, but listings api doesn't have consigned
    return @get('_availableQuantity')

  cost: () ->
    cost = null
    ticketCosts = @get('_ticketCosts')
    if (ticketCosts && ticketCosts.length)
      cost = _.first(ticketCosts)
    else
      cost = @get('_cost')
    if (cost)
      return App.Utils.valueToCurrency(cost)
    return ''

  validInstantDeliveryFormat: () ->
    return @get('_format') == C.TicketFormats.Eticket || @get('_format') == C.TicketFormats.FlashSeats || @get('_format') == C.TicketFormats.TMMobile

  showInstantDelivery: () ->
    return @get('_isInstantDelivery') && @validInstantDeliveryFormat() && @get('_isInHand')

  flags: () ->
    flags = ''
    format = @get('_format')

    switch format
      when C.TicketFormats.FlashSeats then flags += C.FlagBadges.FlashSeats()
      when C.TicketFormats.GuestList then flags += C.FlagBadges.GuestList()
      when C.TicketFormats.Paperless then flags += C.FlagBadges.Paperless
      when C.TicketFormats.TMMobile then flags += C.FlagBadges.TMMobile()
      when C.TicketFormats.Physical then flags += C.FlagBadges.Physical()
      when C.TicketFormats.Eticket
        if (@hasAllEticketsUploaded())
          flags += C.FlagBadges.EticketUploadedAll()
        else
          flags += C.FlagBadges.EticketUploadedIncomplete()

    if (@showInstantDelivery())
      flags += C.FlagBadges.InstantDelivery()

    if (@get('_isInHand'))
      flags += C.FlagBadges.InHand()
    else
      if (@get('_inHandOn'))
        flags += C.FlagBadges.InHandOn(@get('_inHandOn'))
      else
        flags += C.FlagBadges.NotInHand

    if (@hasSpec())
      flags += C.FlagBadges.Spec
    if (@get('_notesBroker') || @get('_notesPrivate') || @get('_notesPublic'))
      flags += C.FlagBadges.Notes
    if (@hasConsignment())
      flags += C.FlagBadges.Consignment
    if (@get("_isAutomated") && SESSION.features.order_integration_enabled)
      flags += C.FlagBadges.InstantConfirm
    if (@get("_isWheelchair"))
      flags += C.FlagBadges.Accessible

    return flags

  hasConsignment: () ->
    if (!_.isUndefined(@get('_consignment')))
      if (_.contains(@get('_consignment'), 't'))
        return true
      else
        return false
    if (@get('_ticketList'))
      consignmentTickets = @get('_ticketList').where({ _isConsignment: true })
      if (consignmentTickets.length)
        return true
    return false

  hasAllEticketsUploaded: () ->
    allEticketsUploaded = true
    v3TicketCollection = @get('_ticketList')
    if (v3TicketCollection?.length)
      v3TicketCollection.forEach((v3TicketModel) ->
        if (!v3TicketModel.get('_eticketId'))
          allEticketsUploaded = false
      )
    return allEticketsUploaded

  hasSomeEticketsUploaded: () ->
    someEticketsUploaded = false
    v3TicketCollection = @get('_ticketList')
    if (v3TicketCollection?.length)
      v3TicketCollection.forEach((v3TicketModel) ->
        if (v3TicketModel.get('_eticketId'))
          someEticketsUploaded = true
          return someEticketsUploaded
      )
    return someEticketsUploaded

  hasSeatNumbers: () ->
    seats = @seats()
    if (seats && seats.length)
      return true

    seatNums = []
    ticketCollection = null

    ticketList = @get('_ticketList')
    tickets = @get('_tickets')
    if (ticketList && ticketList.length)
      ticketCollection = ticketList
    else if (tickets && tickets.length)
      ticketCollection = tickets

    if (ticketCollection)
      ticketCollection.forEach((ticketModel) =>
        seat = ticketModel.get('_seat')
        seatNum = parseInt(seat)
        if (!isNaN(seatNum))
          seatNums.push(seatNum)
      )
      if (seatNums.length >= @get('_quantity'))

        # We gotta check for all seats being '0' because the API gives us zeroes instead of null when no seats.
        # FIXTHEAPI
        if (_.without(seatNums, 0).length)
          return true
        else
          return false

    return false

  hasSpec: () ->
    if (!_.isUndefined(@get('_hasSpecTickets')))
      return @get('_hasSpecTickets')
    if (@get('_ticketList'))
      specTickets = @get('_ticketList').where({ _isSpec: true })
      if (specTickets.length)
        return true
    return false

  isBroadcast: () ->
    broadcast = @get('_isBroadcast')
    if (@getMapping() == C.Mappings.TicketGroupEndpoint.TicketGroup)
      broadcast = @get('broadcast')
    if (@getMapping() == C.Mappings.MassIndexEndpoint.TicketGroup)
      broadcast = @get('ticket_group_broadcast')
    return broadcast == 't' || (broadcast != 'f' && broadcast == true)

  isOwned: () ->
    if (@officeId() == SESSION.office_id)
      return true
    else
      return false

  broadcastCSS: () ->
    if (@isOwned())
      if (@isBroadcast())
        return 'success'
      else
        return 'error'
    return ''

  purchaseOrderId: () ->
    purchaseOrderId = ''
    tickets = @get('_tickets')

    if (@get('_ticketPOIds') && @get('_ticketPOIds').length)
      purchaseOrderId = @get('_ticketPOIds')[0]

    if (!purchaseOrderId && tickets?.length)
      purchaseOrderId = tickets.first().get('_purchaseOrderId')

    return purchaseOrderId

  purchasePrice: () ->
    return App.Utils.valueToCurrency(@get('_priceWholesale'))

  quantitySeparated: () ->
    #console.warn('quantitySeparated() should not rely on _ticketStates as it wrongly claims tickets are available.')
    quantity = @get('_quantity')
    held = if @get('_ticketStates') then @get('_ticketStates').held else 0
    held ||= 0
    reserved = if @get('_ticketStates') then @get('_ticketStates').reserved else 0
    reserved ||= 0
    heldReserved = held + reserved
    sold = if @get('_ticketStates') then @get('_ticketStates').sold else 0
    sold ||= 0
    quantity = quantity - heldReserved - sold
    if held
      return quantity + "(#{held})"
    else
      return quantity

  seats: () ->
    return App.Utils.seatsToSeatRange(@get('_seats'))

  seatRangeStrict: () ->
    if (!@get('_ticketList'))
      return @getSeatRange()
    availableSeats = _.pluck(
      _.filter(
        @get('_ticketList').models,
        @ticketIsAvailable
      ),
      'seat'
    )
    availableSeats = availableSeats.sort((a, b) -> return a - b )
    return App.Utils.seatsToSeatRange(availableSeats)

  getSeatRange: () ->
    if (@get('_seats') && @get('_seats').length) || @get('_lowSeat')
      if (@get('_seatOrder'))
        multiplier = if (@get('_seatOrder') == C.SeatOrder.Consecutive) then 1 else 2
      else
        multiplier = 1

      if (_.isFinite(+@get('_lowSeat')))
        lowSeat = +@get('_lowSeat')
      else
        lowSeat = _.sortBy(@get('_seats'), (seat) ->
          return +seat
        )[0]

      quantity = +@get('_quantity')
      quantity = if (quantity == 0) then @get('_seats').length else quantity
      highSeat = parseInt(lowSeat) + (quantity - 1) * multiplier
      if (quantity == 1)
        return lowSeat
      else
        return "#{lowSeat} - #{highSeat}"
    else
      return 'N/A'

  officeId: () ->
    return @get('_officeId') || @get('_office')?.id

  soldQuantity: () ->
    return @soldSeats().length

  soldSeatRange: () ->
    return App.Utils.seatsToSeatRange(@soldSeats()).join(', ')

  soldSeats: () ->
    ticketList = @get('_ticketList')
    soldSeats = []
    if (ticketList)
      for ticketModel in ticketList.models
        if (ticketModel.get('_state') == 'sold')
          soldSeats.push(ticketModel.get('_seat'))
    return soldSeats

  ticketIsAvailable: (ticketModel) ->
    currentDate  = new Date()
    notAvailable = (ticketModel.get('_state') != 'available')
    noSeat = (ticketModel.get('_seat') == 0)
    holdDate = new Date(ticketModel.get('_holdUntil') + 'Z')
    onHold = (ticketModel.get('_holdId') != 0) && (holdDate >= currentDate)
    return !(notAvailable || noSeat || onHold)

  uniqueTicketStates: () ->
    states = @get('_ticketStates')
    states = _.map(states, (state) ->
      return C.Options.TicketGroup.States[state]
    )
    states = _.uniq(states)
    if (states.length == 1)
      return states
    return states.join(', ')
  #///////////////////////////////////////////////////////////////////////////

  calculateSeatsArray: () ->
    multiplier = 1
    if (@get('_seatOrder') == C.SeatOrder.OddEven)
      multiplier = 2
    lowSeat = +@get('_lowSeat')
    quantity = +@get('_quantity')
    seats = []
    for i in [0...quantity]
      seats.push(lowSeat + (i * multiplier))
    @set('_seats', seats)
    return seats

  format: () ->
    if (@get('_format') == C.TicketFormats.Eticket)
      return "Eticket"
    if (@get('_format') == C.TicketFormats.Physical)
      return "Physical"
    if (@get('_format') == C.TicketFormats.FlashSeats)
      return "AXS"
    if (@get('_format') == C.TicketFormats.Paperless)
      return "Paperless"
    if (@get('_format') == C.TicketFormats.GuestList)
      return "Guest List"
    if (@get('_format') == C.TicketFormats.TMMobile)
      return "Mobile Transfer"

  createBlankHoldsCollection: () ->
    @set('_ticketHolds', new App.Collections.V3.TicketHolds(null, {
      eventId: @collection.eventId
      ticketGroup: @
      mapping: C.Mappings.TicketHolds.TicketHolds
    }))

  fetchHolds: () ->
    v3TicketHoldsCollection = new App.Collections.V3.TicketHolds(null, {
      eventId: @collection.eventId
      ticketGroup: @
      mapping: C.Mappings.TicketHolds.TicketHolds
    })
    return v3TicketHoldsCollection.fetchPromise()

  holdPromise: (holdData) ->
    url = "/api_direct/v9/ticket_groups/#{ @id }/hold"
    return @holdOrTakePromise(holdData, url)

  takePromise: (takeData) ->
    url = "/api_direct/v9/ticket_groups/#{ @id }/take"
    return @holdOrTakePromise(takeData, url)

  # STANDARD AJAX
  holdOrTakePromise: (data, url) ->
    deferred = Q.defer()
    # sample data:
    #      low_seat:55
    #      quantity:5
    #      held_for_id:853
    #      held_for_type:Office
    #      hold_until:2014-01-03 16:41:45 UTC
    #      price:10.0
    #      notes:tnotes
    params = {}
    params.ticket_list = true
    params.low_seat =      data.lowSeat
    params.quantity =      data.quantity
    params.held_for_id =   data.heldForId
    params.held_for_type = data.heldForType
    params.hold_until =    data.holdUntil
    params.price =         App.Utils.roundCurrency(data.price)
    params.notes =         data.notes
    $.ajax({
      type: 'POST'
      url: url
      data: JSON.stringify(params)
      success: (data, status, xhr) =>
        @clear()
        @set(data)
        @mapping = C.Mappings.Direct.TicketGroups.TicketGroup
        @updateStandardAttributes()
        deferred.resolve(@)
      error: (xhr, status, error) =>
        deferred.reject(new Error('Error performing hold or take.'))
    })
    return deferred.promise

  firstReservedTicketId: () ->
    tickets = @get('_ticketList')
    reserved = tickets.filter (ticketModel) ->
      return ticketModel.get('_state') == 'reserved'
    reserved = _.map reserved, (ticketModel) ->
      return ticketModel.id
    if (reserved.length)
      return reserved[0]
    return null

  releaseTake: (callback) ->
    url = "api_direct/v9/ticket_groups/#{ @id }/release_take/0"
    $.ajax({
      type: 'POST'
      url: url
      data: @firstReservedTicketId()
      success: (data, status, xhr) =>
        #@clear()
        #@set(data)
        #@updateStandardAttributes()
        callback(null, @)
      error: (xhr, status, error) =>
        console.error(xhr, status, error)
        callback(new Error('Error releasing take.'))
    })

  # STANDARD AJAX
  toggleBroadcast: (callback) ->
    $.ajax({
      type: 'POST'
      url: "/api_direct/v9/ticket_groups/#{ @id }/broadcast"
      data: JSON.stringify({
        ticket_list: true
        broadcast: !@isBroadcast()
      })
      success: (data, status, xhr) =>
        @clear()
        @set(data)
        @updateStandardAttributes()
        callback(null, @)
      error: (xhr, status, error) =>
        console.error(xhr, status, error)
        callback(new Error('Error changing broadcast state.'))
    })

  # STANDARD AJAX
  waste: (reason, callback) ->
    $.ajax({
      type: 'POST'
      url: "/api_direct/v9/ticket_groups/#{ @id }/waste"
      data: JSON.stringify({
        ticket_list: true
        wasted_reason: reason
      })
      success: (data, status, xhr) =>
        @clear()
        @set(data)
        @updateStandardAttributes()
        callback(null, @)
      error: (xhr, status, error) =>
        console.error(xhr, status, error)
        callback(new Error('Error wasting tickets.'))
    })

  seatsInState: (state) ->
    return _.chain(
      @get('_tickets').models
    ).select(
      (ticket) ->
        if (state)
          if (ticket.get('_state') == state)
            return ticket
        else
          return ticket
    ).collect(
      (ticket) ->
        return +ticket.get('_seat')
    ).sortBy(
      (seat) ->
        return seat
    ).value()

  toExportColumns: ->
    json = @toPresenterJSON()
    [
      json.event.name
      App.Utils.makeTimestampHumanForEvent(json.event.occursAt, C.DateFormats.TableDateWithTime)
      App.Utils.makeTimestampHuman(json.createdAt, C.DateFormats.TableDateWithTime)
      json.event.venue_address_name
      json.event.venue_address_locality
      json.event.venue_address_region
      json.type
      json.quantity
      json.uniqueStates
      json.section
      json.row
      json.seats
      json.salePricePerTicket
      json.costPerTicket
      json.wholesalePricePerTicket
      App.Utils.valueToCurrency(json.retailPricePerTicket)
      json.flags
      json.viewType
      #json.isConsignment
    ]

  nthHighestSeat: (n) ->
    lowSeat = null
    seats = @get('_seats')
    sortedSeats = seats.sort((a, b) ->
      return a - b
    )
    if (sortedSeats.length)
      if (sortedSeats.length >= n)
        nHighestSeats = _.last(sortedSeats, n)
        return nHighestSeats[0]
    return null

  seatOrderFromSeats: () ->
    seats = @get('_seats')
    sortedSeats = seats.sort((a, b) ->
      return a - b
    )
    seatOrder = C.SeatOrder.Consecutive
    if (App.Utils.isSeatArrayOddEven(sortedSeats))
      seatOrder = C.SeatOrder.OddEven
    return seatOrder

  seatsToArray: (seats) ->
    if (!seats || Array.isArray(seats))
      return seats
    else
      return seats.split(',')
