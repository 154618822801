App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V3.EventPin = require('../../models/v3/event_pin_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V3.EventPins = App.Collections.Base.BaseCollection.extend

  model: App.Models.V3.EventPin

  url: () ->
    return '/api_direct/v9/pins'

  initialize: (models, options = {}) ->
    parent.initialize.call(@, models, options)

  parse: (json) ->
    return json.pins
