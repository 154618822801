parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.TopNavBaseView = App.Views.Base.BaseView.extend

  tagName: 'li'

  initialize: (options = {}) ->
    {
      @$container
    } = options

  render: () ->
    @$container.append(
        @$el.html(
            @template()
        )
    )
    @$('a').tooltip()
    return @$el