template = require('./clients_list.ejs')
App.Views.Base.FilteredTable = require('../base/filtered_table_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Clients.List = App.Views.Base.FilteredTable.extend

  template: template

  initialize: (options = {}) ->
    {
      @collection
      @$container
      @searchTerm
    } = options
    @listenTo(@collection, 'reset', _.bind(@onCollectionReset, @))
    @render()

  render: () ->
    @$container.html(
      @$el.html(
        @template({
          searchTerm: @searchTerm
        })
      )
    )
    @delegateEvents()
    @renderTable(true)
    @formErrors = new App.Utils.FormErrorManager(@$('#formErrors'))
    return @$el

  renderTable: (isInitialRender) ->
    if (@table)
      @table.remove()

    @table = new App.Views.Clients.ClientTable({
          filterView: @
          $container: @$('#client_table_container')
          @collection
        })

    if (isInitialRender)
      @tableLoader = new App.Utils.LoadingBox(@$('#client_table_container'))

  # VIEW EVENTS /////////////////////////////////////////////////////////////
  events:
    'click #client_search_button':  'onClientSearchClick'
    'click #reset_search_button':   'onClientSearchResetClick'
    'keypress #client_search_text': 'onClientSearchKeyPress'
    'click .edit_client_button':    'onEditButtonClick'
    'click #add_client_button':     'onAddButtonClick'
    'click #load_all_button':       'onLoadAllButtonClick'
    'click #printButton':           'onPrintButtonClick'
    'click #exportButton':          'onExportButtonClick'

  onClientSearchClick: () ->
    q = @$('#client_search_text').val()
    if (q)
      App.router.navigate('/clients/search/' + encodeURIComponent(q), { trigger: true })

  onClientSearchResetClick: () ->
    App.router.navigate('/clients', { trigger: true })

  onClientSearchKeyPress: (event) ->
    if (event.keyCode == 13) # Enter
      @onClientSearchClick()

  onEditButtonClick: (e) ->
    clientId = $(e.currentTarget).data('client-id')
    App.router.navigate('/client/' + clientId, { trigger: true })

  onAddButtonClick: () ->
    App.router.navigate('/customer/new', { trigger: true })

  onLoadAllButtonClick: () ->
    App.router.navigate('/clients#all', { trigger: true })
  #///////////////////////////////////////////////////////////////////////////

  # COLLECTION EVENTS ////////////////////////////////////////////////////////
  onCollectionReset: () ->
    @renderTable()
  #///////////////////////////////////////////////////////////////////////////

  startLoad: () ->
    @tableLoader.startLoad()

  stopLoad: () ->
    @tableLoader.stopLoad()

  clearTable: () ->
    @collection.reset()
    @startLoad()

  refetchCollection: () ->
    @startLoad()
    @collection.fetch({ reset: true })

  filterParamMappings: () ->
    return {}

  saveClient: (view) ->
    $form = $(view.el).find('form:first')
    @loadingOverlay = new App.Views.Shared.LoadingView()
    @loadingOverlay.render()
    $.post(
      $form.attr('action'),
      view.cleanUpFormAndSerialize(),
      () =>
        @refetchCollection()
    ).error((jqXHR, textStatus, errorThrown) =>
      @formErrors.show({
        errors: jqXHR
      })
      @loadingOverlay.remove()
    )

  onPrintButtonClick: (e) ->
    header = "Customers"
    subHeader = ""
    if (@createdAtDateRange && @createdAtDateRange.start && @createdAtDateRange.end)
      subHeader = "#{ App.Utils.makeTimestampHuman(@createdAtDateRange.start, C.DateFormats.LongNoTime) } to #{App.Utils.makeTimestampHuman(@createdAtDateRange.end, C.DateFormats.LongNoTime) }"
    options = {
      header
      isNotFilteredTable: true
      subHeader
    }
    App.Exporter.printDataTable(@table, options)

  onExportButtonClick: (e) ->
    generateCSV = () =>
      filePrefix = "customers"
      filename = "#{ filePrefix }_#{ App.Utils.makeTimestampHuman(Date.now(), C.DateFormats.File) }"
      options = {
        filename
        isNotFilteredTable: true
      }
      App.Exporter.csvDataTable(@table, options)

    url = "/api_direct/v9/clients?export=true"
    if (@searchTerm && @searchTerm.length)
      url += "&name=#{@searchTerm}"
    @tableLoader.startLoad()
    $.ajax({
      type: 'GET'
      url: url
      success: (data, textStatus, jqXHR) =>
        @tableLoader.stopLoad()
        csv = data.file
        csvData = new Blob([csv])
        $a = $('<a download="clients.csv" type="text/csv"></a>')
        $a.attr('href', URL.createObjectURL(csvData))
        $a.appendTo('body')
        $a[0].click()
        setTimeout(() ->
          $a.remove()
        , 100)
    })
