module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<span class="evopaybalance"><%= "Balance: "+balance %></span>\n<span class="evopaybalance"><%= "Pending Sent: "+pending_sent %></span>\n<span class="evopaybalance"><%= "Restricted: "+restricted %></span>\n<span class="evopaybalance"><%= "Spendable: "+spendable %></span>', __filename = "js/views/evopay/balances.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<span class="evopaybalance">');
            __append(escapeFn("Balance: " + balance));
            __append('</span>\n<span class="evopaybalance">');
            __line = 2;
            __append(escapeFn("Pending Sent: " + pending_sent));
            __append('</span>\n<span class="evopaybalance">');
            __line = 3;
            __append(escapeFn("Restricted: " + restricted));
            __append('</span>\n<span class="evopaybalance">');
            __line = 4;
            __append(escapeFn("Spendable: " + spendable));
            __append("</span>");
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}