template = require('./broker_details.ejs')

App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.TicketGroups.BrokerDetails = App.Views.Base.BaseView.extend

  className: 'modal hide fade broker-details-dialog'

  template: template

  initialize: (options = {}) ->
    {
      @officeId
    } = options

    @office = new App.Models.V3.Office({
      id: @officeId
    }, {
      mapping: C.Mappings.Direct.Offices.Office
    })
    @office.fetchPromise()
    .then (model) =>

      brokerageId = @office.get('brokerage').id
      @brokerage = new App.Models.V3.Brokerage({
        id: brokerageId
      }, {
        mapping: C.Mappings.Direct.Brokerages.Brokerage
      })
      @brokerage.fetchPromise()
      .then (model) =>

        @render()

    .fail (error) ->
      console.error(error.stack)
      errorModal = new App.Views.Shared.BasicModal({
        header: 'Error fetching brokerage.'
        message: error.message
      })
    .done()

    @render()

  render: () ->

    @loadingBox?.stopLoad()

    data = {
      @brokerage
      @office
    }

    @$el.html(
      @template(data)
    )

    # STANDARDIZED MODAL
    @$el.modal('show').on('hidden', () =>
      @remove()
    )

    if (
      !@office ||
      !@office.has('name') ||
      !@brokerage ||
      !@brokerage.has('name')
    )
      @loadingBox = App.Utils.LoadingBox(@$('.modal-body'));
      @loadingBox.startLoad();

