module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div class="modal-header">\n  <% if (!hideButtons) { %>\n  <button type="button" class="close header-close" data-dismiss="modal" aria-hidden="true">\n    &times;\n  </button>\n  <% } %>\n  <span class="icon"><i class="fa-solid <%= icon %>"></i></span>\n  <h3 class="modal-title"><%= header %></h3>\n</div>\n<div class="modal-body" <% if (bodyStyles) { %> style="<%- bodyStyles %>" <% } %>> <%- message %> <% if (withLoading) { %>\n  <div id="modalLoadingContainer" style="margin: auto; margin-left: 45%; min-height: 150px;"></div>\n  <% } %>\n</div>\n<% if (!isConfirmation && !hideButtons) { %>\n<div class="modal-footer">\n  <a href="#" class="btn footer-close" data-dismiss="modal">Close</a>\n</div>\n<% } %>\n', __filename = "js/views/shared/basic_modal.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div class="modal-header">\n  ');
            __line = 2;
            if (!hideButtons) {
                __append('\n  <button type="button" class="close header-close" data-dismiss="modal" aria-hidden="true">\n    &times;\n  </button>\n  ');
                __line = 6;
            }
            __append('\n  <span class="icon"><i class="fa-solid ');
            __line = 7;
            __append(escapeFn(icon));
            __append('"></i></span>\n  <h3 class="modal-title">');
            __line = 8;
            __append(escapeFn(header));
            __append('</h3>\n</div>\n<div class="modal-body" ');
            __line = 10;
            if (bodyStyles) {
                __append(' style="');
                __append(bodyStyles);
                __append('" ');
            }
            __append("> ");
            __append(message);
            __append(" ");
            if (withLoading) {
                __append('\n  <div id="modalLoadingContainer" style="margin: auto; margin-left: 45%; min-height: 150px;"></div>\n  ');
                __line = 12;
            }
            __append("\n</div>\n");
            __line = 14;
            if (!isConfirmation && !hideButtons) {
                __append('\n<div class="modal-footer">\n  <a href="#" class="btn footer-close" data-dismiss="modal">Close</a>\n</div>\n');
                __line = 18;
            }
            __append("\n");
            __line = 19;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}