// All <a> tags sent to router UNLESS:
// 1. They have no href: <a class="foo">
// 2. They have an anchor href: <a href="#somewhere">
// 3. They have an external href (includes "://"): <a href="http://google.com">
// 4. They have a data-bypass attribute: <a href="/orders/show" data-bypass="1">

// Just runs and directs all <a> links to the router.
// Doesn't return anything.
App.Utils.createLinkInterceptor = function() {

  // intercept all 'a' clicks that don't have data-bypass="1" attribute, and that don't go to #something
  $('body').on('click', 'a:not([data-bypass]):not([href^=\\#])', function (event) {
    var href = $(this).attr('href');
    // If 'href' contains 'http://', let event leak - external link.
    // Also, <a href="#" seems to make href undefined.
    if (href && href.indexOf('://') == -1) {
      event.preventDefault();
      event.stopPropagation();
      // console.log('LinkKiller sending ', href, ' to router.navigate().  Link element was: ', $(this));
      App.router.navigate(href, { trigger: true });
    } else {
      // console.log('LinkKiller did not kill link: ', $(this));
    }
  });

};
