template = require('./email_address.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.BuySell.EmailAddress = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    {
      @$container
      @patron
      @shipmentType
      @shipmentEmail
    } = options
    if (@shipmentEmail)
      @shipmentEmail = @shipmentEmail.toPresenterJSON()
    @render()

  # VIEW EVENTS ////////////////////////////////////////////////////////////////
  events:
    'click .addEmailButton':        'onAddEmailButtonClick'
    'click .cancelAddEmailButton':  'onCancelAddEmailButtonClick'
    'keyup #addressConfirmation':   'onEmailConfirmationChange'

  onAddEmailButtonClick: (e) ->
    @useNewEmail = true
    @$('.existingEmailContainer').hide()
    @$('.newEmailContainer').show()
    @formErrors.reset()

  onCancelAddEmailButtonClick: (e) ->
    @useNewEmail = false
    @$('.existingEmailContainer').show()
    @$('.newEmailContainer').hide()
    @formErrors.reset()

  onEmailConfirmationChange: (e) ->
    mainEmailVal = @$('#address').val()
    confirmationEmail = @$('#addressConfirmation')
    confirmationEmailVal = confirmationEmail.val()
    if (mainEmailVal != confirmationEmailVal)
      $('#emailValidationWarning').show()
      confirmationEmail.css("border-color", "red")
    else
      $('#emailValidationWarning').hide()
      confirmationEmail.css("border-color", "#ccc")
  #/////////////////////////////////////////////////////////////////////////////

  render: () ->
    data = {
      patron: @patron?.toPresenterJSON()
      @shipmentType
      @shipmentEmail
      @isPhysicalTicket
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )
    @delegateEvents()
    @loadingBox = new App.Utils.LoadingBox(@$container)
    @formErrors = new App.Utils.FormErrorManager(@$('.formErrors'))

    if (!(@patron && _.size(@patron.emailAddressOptions())))
      @onAddEmailButtonClick()

    return @$el

  isPhysicalTicket: () ->
    return @shipmentType == C.FedEx || @shipmentType == C.LocalPickup || @shipmentType == C.PickupAtOffice

  getEmailPromise: () ->
    deferred = Q.defer()

    @formErrors.reset()
    formData = @$el.serializeObject()
    if (@useNewEmail)
      saveEmail = !!(formData.saveEmail)
      email = new App.Models.V3.EmailAddress(null, {
        clientId: @patron?.id
        mapping: C.Mappings.Direct.Clients.EmailAddresses
      })
      emailAttrs = {
        _address: formData.address
        _label:   formData.label
      }
      email.set(emailAttrs)
      if (email.isValid(true))
        if (saveEmail)
          @formErrors.reset()
          @loadingBox.startLoad()

          email.savePromise()
          .then (model) =>
            @loadingBox.stopLoad()
            deferred.resolve(email)
          .fail (errors) =>
            @loadingBox.stopLoad()
            @formErrors.show({
              title:  "Error saving email address."
              errors
            })
            deferred.reject(errors)
          .done()

        else
          deferred.resolve(email)

      else
        errors = email.errors
        @formErrors.show({
          title:  "Invalid email address."
          errors
        })
        deferred.reject(errors)

    else
      selectedEmailId = formData.selectedEmailId
      unless(@isPhysicalTicket() || selectedEmailId)
        error = "You must select an email"
        deferred.reject(error)
      if (@shipmentEmail && (selectedEmailId == @shipmentEmail.id.toString()))
        email = @shipmentEmail
      else if (@patron.type == C.Client)
        # Client has a real emailAddresses collection.
        email = @patron.emailAddresses().get(selectedEmailId)
      else
        # Office just has one email with _address only (no id).
        email = @patron.emailAddresses().first()
        if (email.get('_address') == selectedEmailId)
          return email

      deferred.resolve(email)

    return deferred.promise
