module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<% if (SESSION.role.can(App.Access.Resources.Inventory.NewPO)) { %>\n\n<a class="dropdown-toggle" data-toggle="dropdown" data-target="#menu-queued-pos" data-toggle="tooltip" data-placement="bottom" title="Queued Orders" style="display: block; cursor: pointer;">\n  <i class="fa-solid fa-list"></i>\n  <% if (indicatorCount) { %>\n  <span class="label <%= indicatorClass %>" style="margin-top: -12px;"><%= indicatorCount %></span>\n  <% } %>\n</a>\n\n<% if ((typeof queuedOrders != \'undefined\') && queuedOrders.length) { %>\n<ul class="dropdown-menu right">\n  <% _.each(queuedOrders, function(queuedOrder) { %>\n  <li class="<%= queuedOrder.state %> <%= queuedOrder.state == "completed" ? "dropdown-submenu" : "" %>" data-order-id="<%= queuedOrder.result && queuedOrder.result.id %>" style="margin: 0;">\n\n    <% if (queuedOrder.state == C.Completed) { %>\n    <a href="/order/<%= queuedOrder.queuedOrderId %>" class=" <%= queuedOrder.state %> " id="<%= queuedOrder.id %>" style="height: auto;">\n      <div style="white-space: nowrap; padding: 10px;">\n        PO# <%= queuedOrder.queuedOrderId %>\n        <br />\n        Created by: <%= queuedOrder.created_by %>\n        <br />\n        State: <%= queuedOrder.state %>\n      </div>\n    </a>\n    <% } else { %>\n    <div style="white-space: nowrap; padding: 10px;">\n      PO# <%= queuedOrder.queuedOrderId %>\n      <br />\n      Created by: <%= queuedOrder.created_by %>\n      <br />\n      State: <%= queuedOrder.state %>\n    </div>\n    <% } %>\n\n  </li>\n  <% }) %>\n</ul>\n<% } %>\n\n<% } %>\n', __filename = "js/views/topnav/topnav_queued_orders.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            if (SESSION.role.can(App.Access.Resources.Inventory.NewPO)) {
                __append('\n\n<a class="dropdown-toggle" data-toggle="dropdown" data-target="#menu-queued-pos" data-toggle="tooltip" data-placement="bottom" title="Queued Orders" style="display: block; cursor: pointer;">\n  <i class="fa-solid fa-list"></i>\n  ');
                __line = 5;
                if (indicatorCount) {
                    __append('\n  <span class="label ');
                    __line = 6;
                    __append(escapeFn(indicatorClass));
                    __append('" style="margin-top: -12px;">');
                    __append(escapeFn(indicatorCount));
                    __append("</span>\n  ");
                    __line = 7;
                }
                __append("\n</a>\n\n");
                __line = 10;
                if (typeof queuedOrders != "undefined" && queuedOrders.length) {
                    __append('\n<ul class="dropdown-menu right">\n  ');
                    __line = 12;
                    _.each(queuedOrders, function(queuedOrder) {
                        __append('\n  <li class="');
                        __line = 13;
                        __append(escapeFn(queuedOrder.state));
                        __append(" ");
                        __append(escapeFn(queuedOrder.state == "completed" ? "dropdown-submenu" : ""));
                        __append('" data-order-id="');
                        __append(escapeFn(queuedOrder.result && queuedOrder.result.id));
                        __append('" style="margin: 0;">\n\n    ');
                        __line = 15;
                        if (queuedOrder.state == C.Completed) {
                            __append('\n    <a href="/order/');
                            __line = 16;
                            __append(escapeFn(queuedOrder.queuedOrderId));
                            __append('" class=" ');
                            __append(escapeFn(queuedOrder.state));
                            __append(' " id="');
                            __append(escapeFn(queuedOrder.id));
                            __append('" style="height: auto;">\n      <div style="white-space: nowrap; padding: 10px;">\n        PO# ');
                            __line = 18;
                            __append(escapeFn(queuedOrder.queuedOrderId));
                            __append("\n        <br />\n        Created by: ");
                            __line = 20;
                            __append(escapeFn(queuedOrder.created_by));
                            __append("\n        <br />\n        State: ");
                            __line = 22;
                            __append(escapeFn(queuedOrder.state));
                            __append("\n      </div>\n    </a>\n    ");
                            __line = 25;
                        } else {
                            __append('\n    <div style="white-space: nowrap; padding: 10px;">\n      PO# ');
                            __line = 27;
                            __append(escapeFn(queuedOrder.queuedOrderId));
                            __append("\n      <br />\n      Created by: ");
                            __line = 29;
                            __append(escapeFn(queuedOrder.created_by));
                            __append("\n      <br />\n      State: ");
                            __line = 31;
                            __append(escapeFn(queuedOrder.state));
                            __append("\n    </div>\n    ");
                            __line = 33;
                        }
                        __append("\n\n  </li>\n  ");
                        __line = 36;
                    });
                    __append("\n</ul>\n");
                    __line = 38;
                }
                __append("\n\n");
                __line = 40;
            }
            __append("\n");
            __line = 41;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}