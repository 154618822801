module.exports = App.Models.EvoPayTransactionsAndBalances = Backbone.Model.extend

  url: 'NONE'

  # returns a deferred that says when its ready
  initialize: () ->
    @set("transactions", new App.Collections.EvoPayTransactions())

  # does the add and update balance thing
  # note(this just needs to be an object
  # it will make the App.... nonsense
  # returns a deferred that says when its ready
  createTransaction: (transaction_object, options) ->
    App.ErrorManager.ignoreNext(422)
    @get('transactions').create(transaction_object, _.extend({
      wait: true
      success: () ->
        App.ErrorManager.resetTolerance(422)
        App.Controllers.evoPayController.filterTransactionIndex()
      error: (model, response, options) ->
        errorView = new App.Views.Shared.InvalidModel({
          errorTitle: 'EvoPay transaction error'
          errors: [response.responseJSON.error || "You are attempting to Withdraw or Transfer an amount that exceeds your currently available EvoPay balance. You may <a href='/restricted_evopay_orders'>review your Orders with Restricted EvoPay</a> for more details."]
          showDetails: true
        })
        errorView.render()
    }, options))

  fetchOfficeTotals: () ->
    attrs = ['balance', 'currency', 'pending_sent', 'spendable', 'restricted']
    data = {
      show_restricted: true
    }
    success = (result) =>
      $(attrs).each((i, attr) =>
        @set(attr, result[attr])
      )
      @trigger("evopaybalances:change")

    $.ajax({
      url: "/api_direct/v9/accounts/#{ SESSION.office_id }",
      data: data,
      success: success,
      dataType: "json"
    })

  fetch: (fetchOptions) ->
    @fetchOfficeTotals()
    @get('transactions').fetch(fetchOptions)

  fetchAll: (options) ->
    return @get('transactions').fetchAll(options)
