do () ->

  wasWarned = false

  checkVersion = () ->
    if (window.SESSION && window.SESSION.user_id)
      $.ajax({
        type: 'GET'
        url: '/latest_build_hash'
        success: (data, status, xhr) =>
          if (
            data.latest_build_hash &&
            data.latest_build_hash != window.LATEST_BUILD_HASH &&
            !wasWarned
          )
            warnUser()
            wasWarned = true
        error: (xhr, status, error) =>
          console.error(error)
      })

  warnUser = () ->
    $.gritter.add({
      title: "A new version of Core is available."
      text: "Click here to load the latest version & avoid potential errors.<br/><br/>WARNING: Unsaved changes will be lost."
      image: '/dist/img/update.png'
      sticky: true
      class_name: 'latestBuildWarning'
    });
    $('.latestBuildWarning').on('click', () ->
      reloadPage()
    )

  reloadPage = () ->
    window.location.reload()

  setInterval(checkVersion, C.LatestBuildCheckInterval)