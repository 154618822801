template = require('./event.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.parent
module.exports = App.Views.Events.Event = App.Views.Base.BaseView.extend

  template: template

  className: 'eventListRow clearfix'

  initialize: (options = {}) ->
    {
      @eventModel
      @filters
      @pinModel
      @pinsCollection
    } = options

  render: () ->
    data = {
      event: @eventModel.toPresenterJSON()
      isPinned: !!@pinModel
    }
    @$el.html(
      @template(data)
    )
    @delegateEvents()
    return @$el

  events:
    'click .event' : 'eventClicked'
    'click .pinButton' : 'onPinButtonClicked'

  highlightEvent: () ->
    $("div.eventContainer").removeClass("selectedEvent")
    @$("div.eventContainer").addClass("selectedEvent")

  eventClicked: (e) ->
    if (e && $(e.target).hasClass('edit'))
      return false
    @highlightEvent()
    myOrAllTickets = 'all'

    if (_.size(@filters))
      filterCode = App.Utils.encodeFilterParams(@filters)
      App.router.navigate("/tickets/events/#{ App.router.getMyOrAllEvents() }/filter/#{ filterCode }/#{ @eventModel.get('id') }/#{ myOrAllTickets }", { trigger: true })
    else
      App.router.navigate("/tickets/events/#{ App.router.getMyOrAllEvents() }/#{ @eventModel.get('id') }/#{ myOrAllTickets }", { trigger: true })

  onPinButtonClicked: (e) ->
    $i = @$(e.currentTarget).find('i')
    $i.removeClass('fa-regular').addClass('fa-solid fa-rotate fa-spin')
    if (@pinModel)
      @pinModel.destroyPromise()
      .then (model) =>
        @pinModel = null
        $i.removeClass('fa-solid fa-rotate fa-spin').addClass('fa-regular fa-star')
      .fail (errors) =>
        $.gritter.add({
          title: 'Error removing event pin.'
          text: "Could not remove the event pin. "
        })
        $i.removeClass('fa-rotate fa-spin')
      .done()
    else
      pinAttrs = {
        _eventId: @eventModel.id
      }
      @pinsCollection.createPromise(pinAttrs)
      .then (model) =>
        @pinModel = model
        $i.removeClass('fa-solid fa-rotate fa-spin').addClass('fa-solid fa-star')
      .fail (errors) =>
        $.gritter.add({
          title: 'Error creating event pin.'
          text: "Could not create the event pin. "
        })
        $i.removeClass('fa-rotate fa-spin')
      .done()
