App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.PaymentOverview = App.Models.Base.V3BaseModel.extend

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.PaymentOverviews)
      attrs._buyerId = json.buyer_id
      attrs._buyerName = json.buyer_name
      attrs._buyerType = json.buyer_type
      attrs._cardCompany = json.card_company
      attrs._createdAt = Date.parse(json.created_at)
      attrs._id = json.id
      attrs._isRefund = json.is_refund
      attrs._lastDigits = json.last_digits
      attrs._orderGroupId = json.order_group_id
      attrs._orderLinkId = json.order_link_id
      attrs._paymentAmount = parseFloat(json.payment_amount)
      attrs._sellerId = json.seller_id
      attrs._sellerName = json.seller_name
      attrs._sellerType = json.seller_type
      attrs._transactionAmount = parseFloat(json.transaction_amount)
      attrs._transactionState = json.transaction_state
      attrs._transactionType = json.transaction_type
      attrs._updatedAt = Date.parse(json.updated_at)
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      amount: @amount()
      completedOn: @completedOn()
      createdAt: App.Utils.makeTimestampHuman(@get('_createdAt'), C.DateFormats.TableDate)
      customer: @customer()
      orderLink: @orderLink()
      specialType: @specialType()
      state: C.Options.Payment.States[@get('_transactionState')]
      transactionType: @transactionType()

    })
    return presented

  amount: () ->
    App.Utils.valueToCurrency(@get('_paymentAmount'))

  completedOn: () ->
    if (@get('_transactionState') == C.Completed)
      return App.Utils.makeTimestampHuman(@get('_updatedAt'), C.DateFormats.TableDate)
    return ''

  customer: () ->
    if (@isPO())
      return @get('_sellerName')
    else
      return @get('_buyerName')

  isPO: () ->
    return SESSION.office_id == @get('_buyerId')

  orderLink: () ->
    return "<a href='/order/#{ @get('_orderLinkId') }'>#{ @get('_orderLinkId') }</a>"

  specialType: () ->
    t = 'payment'
    if (@get('_isRefund'))
      t = 'refund'
    else
      transType = @get('_transactionType')
      if (transType == 'ReturnTransaction')
        t = 'return'
      if (transType == 'PenaltyTransaction')
        t = 'penalty'
    return C.Options.Payment.TransactionTypes[t]

  transactionType: () ->
    t = @get('_transactionType')
    if (t == 'CreditCardTransaction')
      return "#{ @get('_cardCompany') } - #{ @get('_lastDigits') }"
    else
      return C.Options.Payment.PaymentTypes[t]

  toDataTableRowArray: () ->
    p = @toPresenterJSON()
    return [
      p.specialType
      p.transactionType
      p.customer
      p.state
      p.amount
      p.createdAt
      p.completedOn
      p.orderLink
    ]
  #///////////////////////////////////////////////////////////////////////////
