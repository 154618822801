module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<!-- SINGLE EVENT ON ALL TICKETS PAGE -->\n<div class="flextainer eventContainer" style="border-bottom: 1px solid #ddd; padding: 8px;">\n\n  <div style="margin-right: 10px; width:95%;" class="<%= event.ownedByOffice ? "owned-tickets" : "" %>" data-event-date="<%= event.occursAtRaw %>">\n    <div id="event-<%= event.id %>" title="<%= event.name %>" data-event-date="<%= event.occursAtRaw %>" class="event">\n      <div style="font-weight: 700; color: #006699; font-size: 16px;"><%= event.name %></div>\n      <div><%= App.Utils.makeTimestampHumanForEvent(event.occursAtRaw, C.DateFormats.Long) %></div>\n      <div style="color: #888;"><%= event.venue.name %> (<%= event.venue.location %>)</div>\n    </div>\n  </div>\n\n  <div class="pinButton" style="padding-right: 5px;">\n    <% if (isPinned) { %>\n    <i class="fa-solid fa-star" style="color: #e3e600;"></i>\n    <% } else { %>\n    <i class="fa-regular fa-star" style="color: #e3e600;"></i>\n    <% } %>\n  </div>\n\n</div>\n', __filename = "js/views/events/event.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<!-- SINGLE EVENT ON ALL TICKETS PAGE -->\n<div class="flextainer eventContainer" style="border-bottom: 1px solid #ddd; padding: 8px;">\n\n  <div style="margin-right: 10px; width:95%;" class="');
            __line = 4;
            __append(escapeFn(event.ownedByOffice ? "owned-tickets" : ""));
            __append('" data-event-date="');
            __append(escapeFn(event.occursAtRaw));
            __append('">\n    <div id="event-');
            __line = 5;
            __append(escapeFn(event.id));
            __append('" title="');
            __append(escapeFn(event.name));
            __append('" data-event-date="');
            __append(escapeFn(event.occursAtRaw));
            __append('" class="event">\n      <div style="font-weight: 700; color: #006699; font-size: 16px;">');
            __line = 6;
            __append(escapeFn(event.name));
            __append("</div>\n      <div>");
            __line = 7;
            __append(escapeFn(App.Utils.makeTimestampHumanForEvent(event.occursAtRaw, C.DateFormats.Long)));
            __append('</div>\n      <div style="color: #888;">');
            __line = 8;
            __append(escapeFn(event.venue.name));
            __append(" (");
            __append(escapeFn(event.venue.location));
            __append(')</div>\n    </div>\n  </div>\n\n  <div class="pinButton" style="padding-right: 5px;">\n    ');
            __line = 13;
            if (isPinned) {
                __append('\n    <i class="fa-solid fa-star" style="color: #e3e600;"></i>\n    ');
                __line = 15;
            } else {
                __append('\n    <i class="fa-regular fa-star" style="color: #e3e600;"></i>\n    ');
                __line = 17;
            }
            __append("\n  </div>\n\n</div>\n");
            __line = 21;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}