template = require('./legend.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.TicketGroups.Legend = App.Views.Base.BaseView.extend

  template: template

  className: 'modal hide fade'

  initialize: (options = {}) ->
    parent.initialize.call(@, options)
    @render()

  render: () ->
    @$el.html(
      @template()
    )
    # STANDARDIZED MODAL
    @$el.modal('show').on('hidden', () =>
      @remove()
    )




