template = require('./mass_index_table.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Inventory.MassIndexTable = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    {
      @collection
      @$container
    } = options
    @render()

  render: () ->
    data = {
      ticketGroups: @collection.jsonGroupedByEventId()
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )
    # Wire up tooltips & notes popovers.
    @$('span.tip-top').tooltip({ placement: 'top' })
    tgModels = @collection.models
    _.each(tgModels, (groupModel, index) =>
      App.Utils.notesPopover(
        @$('tr[data-ticket-group-id=' + groupModel.get('ticket_group_id') + '] .popover_notes'),
        groupModel
      )
    )
    return @$el

  # VIEW EVENTS //////////////////////////////////////////////////////////////
  events:
    'click tr': 'onTableRowClick'

  onTableRowClick: (e) ->
    $target = $(e.target)
    # Ignore notes link & checkbox
    if (
      $target.is('i') ||
      $target.is('span.popover_notes') ||
      $target.is('input')
    )
      $.noop()
    else
      $row = $(e.currentTarget)
      $checkbox = $row.find('input.mass_edit_checkbox')
      if ($checkbox)
        if ($checkbox.is(':checked'))
          $checkbox.removeAttr('checked')
        else
          $checkbox.attr('checked', true)
  #///////////////////////////////////////////////////////////////////////////
