template = require('./shipment_list.ejs')

App.Views.Base.FilteredTable = require('../base/filtered_table_view.coffee')

module.exports = App.Views.Shipping.List = App.Views.Base.FilteredTable.extend

  template: template

  initialize: (options = {}) ->
    {
    @collection
    @$container
    } = options

    @listenTo(@collection, 'reset', @onCollectionReset)
    @render()

  render: () ->
    @$container.html(
      @$el.html(
        @template()
      )
    )
    @delegateEvents()
    @initializeDropdowns()

    $content = @$('#filter_content')
    $collapseButton = @$('#filter_collapse_button')
    isHorizontalSplit = true
    options = {
      $content
      $collapseButton
      isHorizontalSplit
    }
    @collapsibleFilters = new App.Utils.CollapsibleWidget(options)

    App.Utils.dateRangePicker(@$('#created_date_range'), @onSentAtDateRangeChanged, @, C.DatePickerConfig.Past)
    @buttonLoader = new App.Utils.LoadingButton(@$('#apply_filter_button'))

    @renderTable(true)
    @startLoad()
    return @$el


  renderTable: (isInitialRender = false) ->
    @table?.remove()
    @table = new App.Views.Shipping.ShipmentTable({
      filterView: @
      $container: @$('#shipment_table_container')
      @collection
    })
    @tableLoader = new App.Utils.LoadingBox(@table.$el)
    if (!isInitialRender)
      @stopLoad()

# VIEW EVENTS ////////////////////////////////////////////////////////////////
  events:
    'click #apply_filter_button':   'onApplyFilterButtonClick'
    'click #reset_filter_button':   'onResetFilterButtonClick'

  onResetFilterButtonClick: (e) ->
    App.Controllers.shipmentController.resetIndex()

  onApplyFilterButtonClick: (e) ->
    @buttonLoader.startLoad()
    @tableLoader.startLoad()
    App.Controllers.shipmentController.filterIndex()

  startLoad: () ->
    @tableLoader.startLoad()
    @buttonLoader.startLoad()

  stopLoad: () ->
    @tableLoader.stopLoad()
    @buttonLoader.stopLoad()

  onSentAtDateRangeChanged: (startDate, endDate) ->
    @createdDateRange = {
      start: startDate
      end: endDate
    }

  filterParamMappings: () ->
    return {
    created_at:
      type: C.FilterTypes.DateRange
      el: '#created_date_range'
      val: @createdDateRange
    ship_to_name:
      type: C.FilterTypes.Text
      el: '#recipient_text'
    tracking_number:
      type: C.FilterTypes.Text
      el: '#tracking_num_text'
    state:
      type: C.FilterTypes.Select2
      el: '#status_select'
    }

  initializeDropdowns: () ->

    stateTypes = C.Options.Shipment.States
    stateTypes = _.map stateTypes, (key, val) ->
      return { id: val, text: key }

    @$('#status_select').select2({
      placeholder: 'All'
      data: stateTypes
      initSelection: (element, callback) ->
        data = {
          id: element.val()
          text: C.Options.Shipment.States[element.val()]
        }
        callback(data)
    })

# MODEL EVENTS /////////////////////////////////////////////////////////////
  onCollectionReset: () ->
    @renderTable()
#///////////////////////////////////////////////////////////////////////////