module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div class="span6 pull-right">\n    <div class="widget-box" style="margin-top: 0;">\n        <div class="widget-title">\n            <span class="icon">\n                <i class="fa-solid fa-cart-shopping"></i>\n            </span>\n            <h5>Totals</h5>\n        </div>\n        <div class="widget-content">\n            <div class="totals">\n                <div>\n                    <h3>Totals</h3>\n                    <table class="table">\n                        <tbody>\n                            <tr>\n                                <td>Credit Memo Total</td>\n                                <td><%= App.Utils.valueToCurrency(originalValue) %></td>\n                            </tr>\n                            <tr>\n                                <td>Balance/Pending Balance</td>\n                                <td><%= App.Utils.valueToCurrency(balance) + " / " + App.Utils.valueToCurrency(pendingBalance) %></td>\n                            </tr>\n                        </tbody>\n                    </table>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n', __filename = "js/views/credit_memos/totals.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div class="span6 pull-right">\n    <div class="widget-box" style="margin-top: 0;">\n        <div class="widget-title">\n            <span class="icon">\n                <i class="fa-solid fa-cart-shopping"></i>\n            </span>\n            <h5>Totals</h5>\n        </div>\n        <div class="widget-content">\n            <div class="totals">\n                <div>\n                    <h3>Totals</h3>\n                    <table class="table">\n                        <tbody>\n                            <tr>\n                                <td>Credit Memo Total</td>\n                                <td>');
            __line = 17;
            __append(escapeFn(App.Utils.valueToCurrency(originalValue)));
            __append("</td>\n                            </tr>\n                            <tr>\n                                <td>Balance/Pending Balance</td>\n                                <td>");
            __line = 21;
            __append(escapeFn(App.Utils.valueToCurrency(balance) + " / " + App.Utils.valueToCurrency(pendingBalance)));
            __append("</td>\n                            </tr>\n                        </tbody>\n                    </table>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n");
            __line = 30;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}