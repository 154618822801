template = require('./withdraw_or_deposit_funds.ejs')

# DEATHROW
App.Views.EvoPay.ModalAbstract = require('./evopay_modal_abstract_view.coffee')

module.exports = App.Views.EvoPay.DepositFunds = App.Views.EvoPay.ModalAbstract.extend

  form_template: template

  initialize: (options) ->
    @title        = "Deposit funds into EvoPay"
    @button       = "Deposit"
    @type         = "deposit"
    @instructions = @getInstructions()
    @super_initialize(options||{})


  getInstructions: () ->
    if(SESSION.early_evopay_clearance)
      return "<div>By submitting this form you are authorizing Ticket Evolution to transfer via ACH the specified funds out of your bank account on record and deposit the money into your EvoPay account. In most cases your deposit will become spendable on the schedule below, but may take 2–3 business days until the ACH has cleared.</div>
      <div>Monday–Friday:</div>
      <ul>
      <li>9:00 A.M. Eastern</li>
      <li>1:00 P.M. Eastern</li>
      <li>5:00 P.M. Eastern</li>
      </ul>"      
    return 'By submitting this form are authorizing Ticket Evolution to transfer via ACH the specified funds out of your bank account on record and deposit the money into your EvoPay account. The money will immediately show up in your EvoPay account as "Pending" but will take 48-72 business hours until the ACH has cleared.'
