template = require('./payments_table.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

module.exports = App.Views.RestrictedEvopayOrders.PaymentsTable = Backbone.View.extend

  template: template

  dataTableOptions: {
    bJQueryUI: true
    sDom: '<"">t<"F"fp>'
    bScrollInfinite: true
    bScrollCollapse: false
    iDisplayLength: 1000
    sScrollY: '700px'
    aaSorting: [
      [ 3, 'desc']  # Date
      [ 0, 'asc']
    ]
    aoColumns: [
      { sTitle: 'Event', sType: 'string' }
      { sTitle: 'Event Occurs At', sType: 'date' }
      { sTitle: 'EvoPay Amount', sType: 'string' }
      { sTitle: 'Restricted EvoPay Amount', sType: 'string' }
      { sTitle: 'Order Id', sType: 'string' }
      { sTitle: 'Order State', sType: 'string' }
    ]
  }

  initialize: (options = {}) ->
    {
      @collection
      @$container
    } = options

    @evopayTotal = 0

    if (_xhr)
      _xhr.abort()

    promise = @collection.fetchPromise()
    _xhr = promise.xhr
    App.Utils.requestKiller.add(_xhr)

    promise
    .then (result) =>
      @calculateTotals()
      @render()
      @paymentsLoadingBox.stopLoad()
    .fail () =>
      @paymentsLoadingBox.stopLoad()
      errorModal = new App.Views.Shared.BasicModal({
        header: 'Error fetching payments.'
        message: "There was a network error while fetching the evopayments"
      })
    .done()

    @render()
    @paymentsLoadingBox = new App.Utils.LoadingBox(@$('#payments_table_container'))
    @paymentsLoadingBox.startLoad()

  # VIEW EVENTS /////////////////////////////////////////////////////////////
  events:
    'click #exportButton': 'onExportButtonClick'

  render: () ->

    data = {
      payments: @collection.toPresenterJSON()
      @evopayTotal
    }
    @$el.html(@template(data))
    @$container.html(@$el)
    @delegateEvents()

    @endlessDataTable = new App.Utils.EndlessDataTable({
      @collection
      filterView: @
      @dataTableOptions
    })
    return @$el

  calculateTotals: () ->
    @collection.each((payment) =>
      @evopayTotal += payment.get('_amountAfterFactor')
    )

  getDataTable: () ->
    return @endlessDataTable

  onExportButtonClick: (e) ->
    filePrefix = "restricted_evopay_orders"
    filename = "#{ filePrefix }_#{ App.Utils.makeTimestampHuman(Date.now(), C.DateFormats.File) }"
    options = {
      filename
      isNotFilteredTable: true
    }
    App.Exporter.csvDataTable(@, options)