App.Collections.Base.PaginatedBase = require('../base/paginated_base_collection.coffee')
App.Models.V2.Order = require('../../models/v2/order_model.coffee')

parent = App.Collections.Base.PaginatedBase.prototype
module.exports = App.Collections.V2.Orders = App.Collections.Base.PaginatedBase.extend

  model: App.Models.V2.Order

  url: () ->
    urls = {
      orders: '/orders.json?type=Order&lightweight=true'
      pos: '/orders?type=PurchaseOrder&lightweight=true'
    }
    return urls[@ordersOrPOs]

  initialize: (models, options) ->
    {
      @ordersOrPOs
    } = options
    parent.initialize.call(@, models, options)

  parse: (json, options) ->
    if (@getMapping() == C.Mappings.OrderEndpoint.Order)
      # Will be wrapped with aggregates, pull out orders only.
      json = json.orders