template = require('./new.ejs')
customTicketGroup = require('./custom_ticket_group.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.EvoQuote.New = App.Views.Base.BaseView.extend

  template: template

  customTicketGroup: customTicketGroup

  CLIENT_AUTOCOMPLETE_LIMIT: 10

  initialize: (options = {}) ->
    parent.initialize.call(@, options)
    {
      @$container
    } = options
    @render()

  render: () ->
    @ticketGroupsCollection = new App.Collections.V3.TicketGroups(null, {
      mapping: C.Mappings.TicketGroupEndpoint.TicketGroup
    })

    if (App.EvoQuote.count())
      firstTicketGroup = App.EvoQuote.all()[0]
      event = firstTicketGroup._event
      @eventModel = new App.Models.V3.Event(event, {
        mapping: C.Mappings.Direct.Events.Event
      })

    _.each(App.EvoQuote.all(), (ticketGroup) =>
      delete ticketGroup._event
      @ticketGroupsCollection.add(new App.Models.V3.TicketGroup(ticketGroup, {
        mapping: C.Mappings.TicketGroupEndpoint.TicketGroup
      }))
    )
    existingClientTooltip = "Search and select an existing client to quickly send your Quote to a past client. This will populate the Name and Email field with the selected client's."
    quotePreparedForTooltip = "Enter the name of the actual client the EvoQuote is being prepared for in case you are working with an intermediary party."

    @$container.html(
      @$el.html(
        @template({
          ticketGroups: @ticketGroupsCollection.toPresenterJSON()
          event: @eventModel?.toPresenterJSON()
          existingClientTooltip
          quotePreparedForTooltip
        })
      )
    )
    @formErrors = new App.Utils.FormErrorManager(@$('#formErrors'))

    @$patronContainer =   @$('#patronContainer')
    @renderSelectablePatron()

    @initializeAutocomplete()
    @$('#note-tip').tooltip()
    @$('span.tip-top').tooltip({ placement: 'top' })
    options = {
      action: 'updateEvoquote'
    }
    App.router?.updateTopNav(options)
    return @$el

  events:
    'click .custom-ticket-group'   : 'buildCustomTicketGroup'
    'click .send-quote'            : 'sendQuote'
    'click #cancel-quote'          : 'cancelQuote'
    'click #remove-item'           : 'removeItem'

  renderSelectablePatron: () ->
    selectablePatronAutocomplete = new App.Views.Clients.PatronAutocomplete({
      $container: @$patronContainer
    })
    @listenTo(selectablePatronAutocomplete, C.Events.PatronAutocompleteChange, _.bind(@onSelectablePatronChange, @))

  onSelectablePatronChange: (patron) ->
    patron.fetchPromise()
    .then (result) =>
      clientJSON = result.toPresenterJSON()
      @$('#quote-email').val(clientJSON.emailAddress)
      @$('#quote-name').val(clientJSON.name)
      @clientEmail = clientJSON.emailAddress
      @clientName = clientJSON.name
      @clientId = clientJSON.id
    .done()

  sendQuote: (e) ->
    formItems = @$('tbody#quoteTable tr')
    items = []

    if (@clientId)
      client_id = @clientId

    name = @$('#quote-name').val()
    email = @$('#quote-email').val()

    notes = @$('#quote-notes').val()
    prepared_for = @$('#quote-prepared-for').val()
    cc_user = @$('#cc-user').val() == "on"
    seat_views = @$('#seat-views').val() == "on"
    event_id = @$('#event-header').data('event-id')

    formItems.each((index, tr) =>
      $tr = $(tr)
      item = {
        ticket_group_id: if $tr.hasClass('custom-row') then "0" else $tr.data('ticketGroupId')
        price:      $tr.find('td input#individual-price').val()
        quantity:   $tr.find('td #quantity').val()
        section:    $tr.find('td #custom-section').val()
        row:        $tr.find('td #custom-row').val()
        show_notes: $tr.find('td .showNotesCheckbox').is(':checked')
      }
      items.push(item)
    )

    evoQuote = new App.Models.V3.EvoQuote(null, {
      mapping: C.Mappings.Direct.EvoQuotes.EvoQuote
    })

    evoQuoteAttrs = {
      _eventId : event_id
      _clientId : client_id
      _email : email
      _name : name
      _preparedFor : prepared_for
      _ccUser: cc_user
      _notes: notes
#      _seatViews : seat_views
      _items: items
    }

    evoQuote.set(evoQuoteAttrs)
    evoQuote.purpose = C.Purposes.CreateEvoQuote

    loading = new App.Views.Shared.LoadingView()
    view = new App.Views.EvoQuote.Show()
    loading.render()

    if (evoQuote.isValid(true))

      evoQuote.savePromise()
      .then (savedModel) =>

        savedModel.fetchPromise()
        .then (model) =>

          loading.remove()
          App.EvoQuote.clear()
          @$el.html(view.render({
            model
          }))
          options = {
            action: 'updateEvoquote'
          }
          App.router.updateTopNav(options)

        .fail (errors) =>
          @formErrors.show({
            title: 'Failed fetching evoQuote'
            errors
          })
          loading.remove()
          options = {
            action: 'updateEvoquote'
          }
          App.router.updateTopNav(options)

        .done()

      .fail (errors) =>
        @formErrors.show({
          title: 'Failed to save evoQuote'
          errors
        })
        loading.remove()
        options = {
          action: 'updateEvoquote'
        }
        App.router.updateTopNav(options)

      .done()

    else
      @formErrors.show({
        title: 'Invalid evoQuote'
        errors: evoQuote.errors
      })
      loading.remove()
      options = {
        action: 'updateEvoquote'
      }
      App.router.updateTopNav(options)

  cancelQuote: (e) ->
    App.EvoQuote.clear()
    options = {
      action: 'updateEvoquote'
    }
    App.router.updateTopNav(options)
    @render()

  buildCustomTicketGroup: (e) ->
    brokerage = new App.Models.V3.Brokerage({
      id: SESSION.brokerage_id
    })
    loading = new App.Views.Shared.LoadingView()
    loading.render()
    if (!@brokerage)
      brokerage.fetchPromise()
      .then (model) =>
        @brokerage = brokerage
        @$('tbody#quoteTable').append(@customTicketGroup({
          brokerage: @brokerage
        }))
        loading.remove()
        options = {
          action: 'updateEvoquote'
        }
        App.router.updateTopNav(options)
      .fail (error) ->
        console.error(error.stack)
        @formErrors.show({
          title: 'Error fetching brokerage.'
          errors: error
        })
      .done()

  removeItem: (e) ->
    # if currentTarget doesn't pick up the correct row
    # use the custom count id
    row = $(e.currentTarget).parent().parent()
    itemCID = row.data('item-cid')
    if (itemCID)
      App.EvoQuote.remove(itemCID)
    row.remove()
    options = {
      action: 'updateEvoquote'
    }
    App.router.updateTopNav(options)

  initializeAutocomplete: () ->
    @$("#clients_autocomplete").select2({
      placeholder: "Client"
      minimumInputLength: 2
      ajax: {
        url: '/api_direct/v9/searches'
        dataType: 'json'
        data: (term, page) =>
          return {
            q: term
            types: ['clients']
            per_page: @CLIENT_AUTOCOMPLETE_LIMIT
          }

        results: (jsonData, page) =>
          # Select2 wants an array of objects with id & text.
          results = []
          _.each(jsonData, (item) =>
            id_email = [item.id, item.email, item.label]
            option = {
              text: item.label
              id: id_email
            }
            results.push(option)
          )
          return {
            results
          }

      }
    })
