module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div>\n\n  <div class="form-horizontal" style="background: #eee;">\n    <div class="control-group">\n      <label class="control-label">\n        Type\n      </label>\n      <div class="controls">\n        <select class="paymentType" name="paymentType">\n\n          <% _.each(allowedOptions, function(val, key) { %>\n          <option value="<%= key %>">\n            <%= val %>\n          </option>\n          <% }); %>\n\n          <% _.each(blockedOptions, function(val, key) { %>\n          <option value="<%= key %>" disabled="true">\n            <%= val %> (Premium Only)\n          </option>\n          <% }); %>\n\n        </select>\n      </div>\n    </div>\n  </div>\n\n  <div class="formErrors"></div>\n\n  <div class="paymentDetailsContainer">\n\n  </div>\n\n</div>', __filename = "js/views/buy_sell/payment.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div>\n\n  <div class="form-horizontal" style="background: #eee;">\n    <div class="control-group">\n      <label class="control-label">\n        Type\n      </label>\n      <div class="controls">\n        <select class="paymentType" name="paymentType">\n\n          ');
            __line = 11;
            _.each(allowedOptions, function(val, key) {
                __append('\n          <option value="');
                __line = 12;
                __append(escapeFn(key));
                __append('">\n            ');
                __line = 13;
                __append(escapeFn(val));
                __append("\n          </option>\n          ");
                __line = 15;
            });
            __append("\n\n          ");
            __line = 17;
            _.each(blockedOptions, function(val, key) {
                __append('\n          <option value="');
                __line = 18;
                __append(escapeFn(key));
                __append('" disabled="true">\n            ');
                __line = 19;
                __append(escapeFn(val));
                __append(" (Premium Only)\n          </option>\n          ");
                __line = 21;
            });
            __append('\n\n        </select>\n      </div>\n    </div>\n  </div>\n\n  <div class="formErrors"></div>\n\n  <div class="paymentDetailsContainer">\n\n  </div>\n\n</div>');
            __line = 34;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}