App.Collections.Base.BaseCollection = require('./base_collection.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.Base.PaginatedBase = App.Collections.Base.BaseCollection.extend

  initialize: (models, options = {}) ->
    parent.initialize.call(@, models, options)
    @currentPage = 1
    @perPage = options.perPage

  fetch: (options = {}) ->
    if (!options.isPaginated)
      @currentPage = 1
    Backbone.Collection.prototype.fetch.call(@, options)

  # Don't abuse this.
  fetchAll: (childOptions = {}) ->
    # Save child callbacks
    childSuccessCallback = childOptions.success || $.noop
    childErrorCallback = childOptions.error || $.noop

    currSize = @length

    count = 0
    EMERGENCY_BRAKES = 200

    doneCount = 0
    PARALLEL_COUNT = 5

    parentOptions = {
      success: (collection, response, options) =>
        if (count++ < EMERGENCY_BRAKES)
          if (@length > currSize)
            currSize = @length
            @fetchNextPage(combinedOptions)
          else
            doneCount++
            if (doneCount == PARALLEL_COUNT)
              childSuccessCallback(collection, response, options)
        else
          console.error("We made #{ EMERGENCY_BRAKES } requests during a fetchAll.  Aborting this.")
      error: (collection, response, options) ->
        childErrorCallback(collection, response, options)
    }
    # Overwrite childOptions with parentOptions
    combinedOptions = _.extend({}, childOptions, parentOptions)

    # Fetch five in parallel
    @fetchNextPage(combinedOptions)
    @fetchNextPage(combinedOptions)
    @fetchNextPage(combinedOptions)
    @fetchNextPage(combinedOptions)
    @fetchNextPage(combinedOptions)

  fetchNextPage: (childOptions = {}) ->
    # Save child callbacks
    childSuccessCallback = childOptions.success || $.noop
    childErrorCallback = childOptions.error || $.noop

    parentOptions = {
      data: {
        page: ++@currentPage
      }
      # http://stackoverflow.com/questions/13877391/backbone-collection-0-9-9-add-event-not-work
      update: true
      remove: false
      success: (collection, response, options) ->
        #@currentPage++
        childSuccessCallback(collection, response, options)
      error: (collection, response, options) ->
        childErrorCallback(collection, response, options)
    }

    if (@perPage)
      parentOptions.data.per_page = @perPage

    filterData = (childOptions.filter && childOptions.filter()) || {}

    # Overwrite childData with parentData
    combinedData = _.extend({}, childOptions.data, parentOptions.data, filterData)
    # Overwrite childOptions with parentOptions
    combinedOptions = _.extend({}, childOptions, parentOptions)
    combinedOptions.data = combinedData
    combinedOptions.isPaginated = true
    @fetch(combinedOptions)
