App.Views.Base.BaseView = require('../base/base_view.coffee')
template = require('./account.ejs')

EMAIL_REGEX = /\S+@\S+\.\S+/ig

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.User.Account = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    parent.initialize.call(@, options)
    {
      @$container
      @userModel
    } = options
    @render()

  render: () ->
    data = {
      user: @userModel.toPresenterJSON()
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )
    @formErrors = new App.Utils.FormErrorManager($('#formErrors'))
    @delegateEvents()
    return @$el

  # VIEW EVENTS ////////////////////////////////////////////////////////////////
  events:
    "click #saveButton": "onSaveButtonClick"

  onSaveButtonClick: (e) ->
    updateAttrs = {}
    newEmail = @$('#newEmail').val()
    newName = @$('#newName').val()
    newPassword = @$('#newPassword').val()
    newPhone = @$('#newPhone').val()
    newPwConfirm = @$('#newPasswordConfirm').val()
    oldPassword = @$('#oldPassword').val()

    if (oldPassword)
      if (newPassword && newPwConfirm)
        if (newPassword == newPwConfirm)
          updateAttrs.password = newPassword
          updateAttrs.password_confirmation = newPwConfirm
        else
          @formErrors.show({
            title: "Invalid password."
            errors: "Your password and password confirmation must match!"
          })
          return

      if (newEmail)
        if (@validateEmail(newEmail))
          updateAttrs.email = newEmail
        else
          @formErrors.show({
            title: "Invalid email."
            errors: "Please enter a valid email!"
          })
          return

      if (newName)
        updateAttrs.name = newName

      if (newPhone)
        updateAttrs.phone = newPhone

    else
      @formErrors.show({
        title: "Please enter your current password"
        errors: "You must provide your current password to make account changes."
      })
      return

    if (!_.isEmpty(updateAttrs))
      updateAttrs.old_password = oldPassword
      user_id = @userModel.get('_id')
      App.ErrorManager.ignoreNext(401)
      $.ajax({
        type: "PUT"
        dataType: "json"
        url: "/api_direct/v9/users/#{user_id}"
        data: JSON.stringify(updateAttrs)
        success: () =>
          @userModel.fetchPromise()
          .then () =>
            @render()
            @formErrors.show({
              type: "success"
              message: "Account successfully updated!"
            })
          .fail () =>
            @formErrors.show({
              title: "Unable to fetch user model..."
              errors: "If this error persists, try refreshing your browser."
            })
          .done()
          App.ErrorManager.resetTolerance(401)
        error: (exchange_error) =>
          @formErrors.show({
            title: "Invalid password."
            errors: exchange_error.responseJSON.error
          })
          App.ErrorManager.resetTolerance(401)
      })
    else
      @formErrors.show({
        title: "Nothing to update..."
        errors: "Please enter the fields you want updated."
      })
      return

  validateEmail: (email) ->
    return EMAIL_REGEX.test(email)