module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = "<%\n  var isBulkBuyEnabled = bulkBuyListings.length > 1;\n  var btnClass = isBulkBuyEnabled && 'btn-success' || '';\n  var isDisabled = !isBulkBuyEnabled && 'disabled' || '';\n%>\n\n<button class=\"btn <%= btnClass %> <%= isDisabled %>\" id=\"bulkBuyButton\" <%= isDisabled %> >Bulk Buy</button>", __filename = "js/views/ticket_groups/bulk_buy_button.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            var isBulkBuyEnabled = bulkBuyListings.length > 1;
            var btnClass = isBulkBuyEnabled && "btn-success" || "";
            var isDisabled = !isBulkBuyEnabled && "disabled" || "";
            __line = 5;
            __append('\n\n<button class="btn ');
            __line = 7;
            __append(escapeFn(btnClass));
            __append(" ");
            __append(escapeFn(isDisabled));
            __append('" id="bulkBuyButton" ');
            __append(escapeFn(isDisabled));
            __append(" >Bulk Buy</button>");
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}