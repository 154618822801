App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V2.Shipment = require('../../models/v2/shipment_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V2.Shipments = App.Collections.Base.BaseCollection.extend

  url: '/shipments'

  model: App.Models.V2.Shipment

  initialize: (models, options) ->
    {
      @holder_id
    } = options
    parent.initialize.call(models, options)

  fetch: (options = {}) ->
    options.data = _.extend((options.data || {}), {
      item_ids_only: true
      order_id:      @holder_id
    })
    parent.fetch.call(@, options)
