App.Controllers.Base.BaseController = require('./base/base_controller.coffee')

module.exports = class App.Controllers.EmailController extends App.Controllers.Base.BaseController

  _emailsCollection = null
  _emailsView = null
  _$container = null

  routeIndex: ($container, encodedFilterParams) ->
    @displayIndex({
      $container
      shouldReset: true
      encodedFilterParams
    })

  displayIndex: (options) ->
    {
      $container
      shouldReset
      encodedFilterParams
    } = options
    _$container = $container

    if (_xhr)
      _xhr.abort()

    if (!_emailsCollection || shouldReset)
      _emailsCollection = new App.Collections.V3.Emails(null, {
        mapping: C.Mappings.Direct.Emails.Email
      })

    if (!_emailsView || shouldReset)
      _emailsView = new App.Views.Email.List({
        $container: _$container
        collection: _emailsCollection
      })

    if (encodedFilterParams)
      params = App.Utils.decodeFilterParams(encodedFilterParams)
      _emailsView.reflectFilterParams(params)

    params = _emailsView.getFilterParams()

    # View is bound to collection reset event.
    _xhr = _emailsCollection.fetch({
      data: params
      reset: true
    })
    App.Utils.requestKiller.add(_xhr)

  routeEmailShow: ($container, id) ->
    model = new App.Models.V3.Email({ id }, {
      mapping: C.Mappings.Direct.Email.Email
    })

    loadingOverlay = new App.Views.Shared.LoadingView()
    loadingOverlay.render()

    model.fetchPromise()
    .then (fetchedModel) =>
      $container.html("<div style='height:40px; text-align: center; margin-top: 10px'><button onclick='javascript: App.Controllers.emailController.back()'>Back to Emails List</button></div>" + fetchedModel.get("_htmlBody"))
      loadingOverlay.remove()
    .fail () =>
      errorModal = new App.Views.Shared.BasicModal({
        header: 'Error fetching email.'
        message: "You might not have access to view this email..."
      })
      loadingOverlay.remove()
    .done()

  resetEmailsIndex: () ->
    App.router.navigate('/emails') # Don't trigger.
    @displayIndex({
      $container: _$container
      shouldReset: true
    })

  filterEmailsIndex: () ->
    encodedFilterParams = _emailsView.getEncodedFilterParams()
    App.router.navigate('/emails/filter/' + encodedFilterParams) # Don't trigger
    @displayIndex({
      $container: _$container
      shouldReset: false
      encodedFilterParams
    })

  back: () ->
   if(App.router.routesHit > 1)
    #more than one route hit -> user did not land to current page directly
    window.history.back()
   else
    #otherwise go to the home page. Use replaceState if available so
    #the navigation doesn't create an extra history entry
      App.router.navigate('emails', {trigger:true, replace:true})
