template = require('./order_table.ejs')
rowDetailsTemplate = require('./order_table_row_details.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

# v3OrderModel.toDataTableRowArray() outputs a row array like:
# row = [
#   "<a data-order-id='#{ p.id }' href='/order/#{ item.id }'>#{ item.orderIdWithGroup }</a>"
# This regex extracts that data-order-id value.
ORDER_ID_REGEX = /data-order-id=[\"|\'](\d+)[\"|\']/

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Orders.OrderTable = App.Views.Base.BaseView.extend

  template: template

  rowDetailsTemplate: rowDetailsTemplate

  initialize: (options = {}) ->
    {
      @collection
      @$container
      @filterView
      @model
      @isPO
    } = options
    # Dumb closure refs for createdRow callback.
    collectionRef = @collection
    filterViewRef = @filterView
    rowDetailsTemplateRef = @rowDetailsTemplate

    @dataTableOptions = {
      bJQueryUI: true
      sDom: '<"">t<"F"fp>'
      bScrollInfinite: true
      bScrollCollapse: false
      iDisplayLength: 1000
      aaSorting: [
        [ 2, 'desc']
      ]
      oLanguage: {
        sSearch: 'Filter Results: '
        sEmptyTable: 'Please select any of the available filter criteria in order to view your orders'
      }
      # How to add child rows dynamically to every dataTable row.
      # Works even for endlessDataTable.
      # Sorry the code is gross blame jQuery.dataTable.
      createdRow: (row, data, index) ->
        if (filterViewRef.$el.find('#includeDetails').is(':checked'))
          $tr = $(row)
          dtRow = @api().row($tr)
          orderId = $tr.data().orderId
          if (!orderId)
            orderId = (data[0].match(ORDER_ID_REGEX))[1]
          v3OrderModel = collectionRef.get(orderId)
          data = {
            orderItems: v3OrderModel.get('_orderItems').toPresenterJSON()
          }
          $expandedRow = rowDetailsTemplateRef(data)
          dtRow.child($expandedRow).show()
          $(dtRow.child()).addClass('orderItemDetails')
    }

    @dataTableOptions.aoColumns = [
      null
      null
      { sType: 'date' }
      null
      null
      { sType: 'currency' }
      { sType: 'currency' }
      { sType: 'date' }
      null
      null
      null
      { sType: 'currency' }
      null
    ]

    if (SESSION.role.can(App.Access.Resources.Orders.OrdersListView.SimpleFilters))
      @dataTableOptions.aoColumns = @dataTableOptions.aoColumns.slice(0,6).concat(@dataTableOptions.aoColumns.slice(11,12)).concat(@dataTableOptions.aoColumns.slice(9,10))
    else
      commissionIndex = 11
      @dataTableOptions.aoColumns.splice(commissionIndex, 1)

    if (SESSION.role.cannot(App.Access.Resources.Orders.OrdersListView.BuyerState))
      buyerStateIndex = 3
      @dataTableOptions.aoColumns.splice(buyerStateIndex, 1)

    @render()

  render: () ->
    templateData = {
      @isPO
      models: @collection.toPresenterJSON()
    }
    @$container.html(
      @$el.html(
        @template(templateData)
      )
    )
    @delegateEvents()

    prefabFilterContainerSize = if $('#prefabFilterContainer').length > 0 then $('#prefabFilterContainer').height() else 0
    @dataTableOptions.sScrollY = "#{ $(window).height() - prefabFilterContainerSize - 320 }px"

    @endlessDataTable = new App.Utils.EndlessDataTable({
      @filterView
      @collection
      @dataTableOptions
    })

    @$('.dataTables_scrollBody').css({ minHeight: $(window).height() - $('#prefabFilterContainer').height() - 400 })

    @$('span.tip-top').tooltip({ placement: 'top' })
    @dataTable = @getDataTable().DataTable()
    @collection.forEach((v3OrderModel) =>
      @renderNotesPopover(v3OrderModel)
    )
    return @$el

  getDataTable: () ->
    return @endlessDataTable

  exportTotals: () ->
    m = @model
    return {
      'Orders': m.get('total_entries')
      'Total': App.Utils.valueToCurrency(m.get('total_sum'))
      'Balance': App.Utils.valueToCurrency(m.get('balance_sum'))
    }

  renderNotesPopover: (v3OrderModel) ->
    $tr = $("tr[data-order-id=#{ v3OrderModel.id  }]")
    $el = $tr.find('.popover_notes')
    App.Utils.notesPopover($el, v3OrderModel)

