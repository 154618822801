template = require('./modal_upload_proof_of_transfer.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Shipping.ModalUploadProofOfTransfer = App.Views.Base.BaseView.extend

  template: template

  className: 'modal hide fade'

  initialize: (options = {}) ->
    parent.initialize.call(@, options)
    {
      @transferVerification
      @shipmentView
    } = options
    @render()

  submitProofOfTransfer: (event) ->
    event.preventDefault()
    event.stopPropagation()
    @loadingButton.startLoad()
    @base64EncodeFiles(@filesInput.files)
    .then (encodedFiles) =>
      encodedFiles = _.map(encodedFiles, (file) ->
        return file.split(',')[1]
      )
      @shipmentView.getShipmentFromOrder().uploadProofOfTransferPromise(
        { ...@uploadPayload(), ...{ files: encodedFiles } }
      )
        .then (model) =>
          $.gritter.add({
            title: 'Proof of Transfer uploaded.'
            text: 'The proof of transfer was uploaded successfully.'
          })
          @$el.modal('hide')
          @refreshShipment()
        .fail (errors) =>
          @formErrors.show({
            title: 'Proof of Transfer upload failed.'
            message: errors?.responseJSON?.message ||
                     _.pluck(errors?.responseJSON?.errors, 'message').join(', ') ||
                     errors
          })
          @loadingButton.stopLoad()
        .done()
    .fail (error) =>
      @formErrors.show({
        title: 'Proof of Transfer file parsing failed.'
        message: 'An error occurred while uploading the proof of transfer.'
      })
      @loadingButton.stopLoad()
    .done()

  readFileAsDataUrl: (file) ->
    deferred = Q.defer()
    fr = new FileReader

    fr.onload = ->
      deferred.resolve(fr.result)
      return

    fr.onerror = ->
      deferred.reject(fr)
      return

    fr.readAsDataURL(file)
    return deferred.promise

  base64EncodeFiles: (files) ->
    readers = []

    i = 0
    while i < files.length
      readers.push @readFileAsDataUrl(files[i])
      i++

    Q.all(readers)

  uploadPayload: () ->
    return @removeUndefinedValues({
      type: @typeInput.val()
      transfer_verification_id: @transferVerification?.id
    })

  removeUndefinedValues: (obj) ->
    return _.omit(obj, (value, key, info) ->
      return value == undefined
    )

  events:
    'submit #uploadProofOfTransferForm': 'submitProofOfTransfer'

  render: () ->
    data = {
      transferVerification: @transferVerification,
      shipmentIsDelivered: @shipmentIsDelivered()
    }
    @$el.html(
      @template(data)
    )
    @initializeVariables()
    @setFormInputs()
    # STANDARDIZED MODAL
    @$el.modal('show').on('hidden', () =>
      @remove()
    )

  initializeVariables: () ->
    @loadingButton = new App.Utils.LoadingButton @$("#uploadProofOfTransferSubmit")
    @formErrors = new App.Utils.FormErrorManager(@$('#formErrors'))
    @modal = @$el
    @filesInput = @$("#files")[0]
    @typeInput = @$("#type")
  
  setFormInputs: () ->
    if(@transferVerification?.type == 'Proof of Transfer')
      @typeInput.val(@transferVerification.type)
      @typeInput.prop('disabled', true)
    else
      @typeInput.val('')

  refreshShipment: () ->
    @shipmentView.overviewView.refreshShipment()

  shipmentIsDelivered: () ->
    @shipmentView.getShipmentFromOrder()?.attributes?.state == 'delivered'
