createTemplate = require('./shipment_create.ejs')
detailsTemplate = require('./shipment_details.ejs')
showTemplate = require('./shipment_show.ejs')
rateOptionsTemplate = require('./rate_options.ejs')
warningBoxTemplate = require('../warning_box.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')


###
Courier
  Name
  Company Name
  Address
  Phone Number
  Courier Company
Custom
  (No Details)
Fedex
  Name
  Company Name
  Address
  Phone Number
  Signature Type
  Residential
  Rate Type
Eticket
  Email Address
Local Pickup
  Name
  Phone Number
  Location Name
  Location Address
Offline
  (No Details)
Pickup at Office
  Name
  Office Address
  Phone Number
UPS
  Text Box
Will Call
  Name
  Phone Number
###

MODES = {
  CREATE: {}
  EDIT: {}
  SHOW: {}
}

SIGNATURE_TYPES = {
  '':                     'No Signature Type'
  INDIRECT:               'Indirect'
  NO_SIGNATURE_REQUIRED:  'No Signature Required'
  ADULT:                  'Adult'
  DIRECT:                 'Direct'
  SERVICE_DEFAULT:        'Service Default'
}

RESIDENTIAL_ONLY_TYPES = ['INDIRECT', 'NO_SIGNATURE_REQUIRED']

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Shipping.Shipment = App.Views.Base.BaseView.extend

  createTemplate: createTemplate

  detailsTemplate: detailsTemplate

  rateOptionsTemplate: rateOptionsTemplate

  showTemplate: showTemplate

  warningBoxTemplate: warningBoxTemplate

  initialize: (options = {}) ->
    {
      @action
      @$container
      @destinationPatron
      @isOrderOverview
      @isAdHocShipmentDestination
      @isAdHocShipmentOrigin
      @overviewView
      @originPatron
      @shipmentTypes
      @toggleValidShipment
      @v3OrderModel
      @firstTg
    } = options
    if (@isOrderOverview)
      @mode = MODES.SHOW
    else
      @mode = MODES.CREATE

    @localPickup = null
    @shippingCost = null
    @render()

  # VIEW EVENTS ////////////////////////////////////////////////////////////////
  events:
    'change input[name="shipmentType"]':       'onShipmentTypeChange'
    'change #serviceType':                     'onRateOptionChange'
    'change #isResidential':                   'onIsResidentialChange'
    'change .selectedTransferSource':          'onChangeTransferSource'
    'keyup #shippingCost':                     'onShippingCostChange'
    'click #abortEditShipmentButton':          'onAbortEditShipmentButton'
    'click #cancelShipmentButton':             'onCancelShipmentButtonClick'
    'click #editShipmentButton':               'onEditShipmentButtonClick'
    'click #saveShipmentButton':               'onSaveShipmentButtonClick'
    'click #generateAirbillButton':            'onGenerateAirbillButtonClick'
    'click #uploadAirbillButton':              'onUploadAirbillButtonClick'
    'click #requestAirbillButton':             'onRequestAirbillButtonClick'
    'click #emailAirbillButton':               'onEmailAirbillButtonClick'
    'click #markAsDeliveredButton':            'onMarkAsDeliveredButtonClick'
    'click #pendButton':                       'onPendButtonClick'
    'click #printAirbillButton':               'onPrintAirbillButtonClick'
    'click #requestDetailsButton':             'onRequestDetailsButtonClick'
    'click #submitTMMobileLinkButton':         'onSubmitTMMobileLinkButtonClick'
    'click .chooseShipmentButton':             'onChooseShipmentType'
    'click #proofOfTransfer':                  'onProofOfTransferClick'
    'click #requestProofOfTransferButton':     'onRequestProofOfTransferButtonClick'
    'click #uploadProofOfTransfer':            'onUploadProofOfTransferClick'

  onProofOfTransferClick: () ->
    shipment = @getShipmentFromOrder()
    transferVerifications = shipment.attributes.transfer_verifications.filter(
      (tv) -> tv.uploads.length > 0
    )
    legend = new App.Views.Shipping.ModalProofOfTransferUploads({
      transferVerifications
    })
    @modalProofOfTransferUploadsStore = new App.Stores.ProofOfTransferModals()
    @modalProofOfTransferUploadsStore.set({
      attributes: {
        shown: true
      }
    })

  onUploadProofOfTransferClick: () ->
    shipment = @getShipmentFromOrder()
    legend = new App.Views.Shipping.ModalUploadProofOfTransfer({
      transferVerification: @findProofOfTransferRequest()
      getShipmentFromOrder: @getShipmentFromOrder()
      shipmentView: this
    })
    @modalProofOfTransferUploadsStore = new App.Stores.UploadProofOfTransferModals()
    @modalProofOfTransferUploadsStore.set({
      attributes: {
        shown: true
      }
    })

  onPrintAirbillButtonClick: (e) ->
    id = $(e.target).data('shipmentId')

    shipment = new App.Models.V3.Shipment({id: id}, {
      mapping: C.Mappings.Direct.Shipments.Shipment
    })

    shipment.downloadAirbill(@formErrors)

  onChooseShipmentType: (e) ->
    shipmentTypeLabel = e.target.innerText
    shipmentType = e.target.getAttribute("data-shipment-type");
    confirmModalMessage = "By choosing #{shipmentTypeLabel} shipment type you are notifying the buyer that their delivery method will change to a form of a mobile transfer. You will not be able to go back to any non-transfer delivery method. Please ensure this is correct prior to making this change. Are you sure you want to proceed?"
    if (shipmentType == C.Eticket)
      confirmModalMessage = "By choosing Eticket shipment type you are notifying the buyer that their delivery method will change to Eticket or QR Code.  Please ensure this is correct prior to making this change. Are you sure you want to proceed?"

    @confirmModal = new App.Views.Shared.BasicModal({
      isConfirmation: true
      header: 'Choose Shipment Type Warning'
      message: confirmModalMessage
      onYes: @submitShipmentType.bind(@,shipmentType)
      icon: 'fa-solid fa-triangle-exclamation'
      bodyStyles: 'max-width: 700px'
    })

  onSubmitTMMobileLinkButtonClick: (e) ->
    tm_mobile_link = @$('#TMMobileLink').val()
    validLink = !!tm_mobile_link.match(/^(http|https):\/\//) && tm_mobile_link.split(" ").length == 1
    if( validLink )
      @confirmModal = new App.Views.Shared.BasicModal({
            isConfirmation: true
            header: 'Complete Order Warning'
            message: "After submitting this link you will no longer be able to modify it. Are you sure you want to proceed?"
            onYes: @submitTMMobileLink.bind(@,e,tm_mobile_link)
            icon: 'fa-solid fa-triangle-exclamation'
          })
    else
      @formErrors.show({
       title: "Wrong link structure"
       errors: "The link you submitted does not include the required link structure"
      })

  submitShipmentType: (shipmentType) ->
    @confirmModal?.remove()
    @loadingBox.startLoad()
    @getShipmentPromise(true, true)
      .then (shipmentModel) =>
        shipmentModel.patchPromise({mobile_transfer_type: shipmentType})
        .then () =>
          @loadingBox.stopLoad()
          @formErrors.show({
            type: 'success'
            title: 'Shipment type updated successfully.'
            message: "Shipment type has been successfully updated to #{shipmentType}."
          })
          @overviewView.refresh()
        .fail (errors) =>
          $(window).scrollTop(0)
          @loadingBox.stopLoad()
          @formErrors.show({
            title: 'Error updating the shipment type.'
            errors
          })
        .done()


  submitTMMobileLink: (e,tm_mobile_link) ->
    @confirmModal?.remove()
    @loadingBox.startLoad()
    @submitTMMobileLinkButton.startLoad()
    @getShipmentPromise()
      .then (shipmentModel) =>
        shipmentModel.completePromise({tm_mobile_link})
        .then (shipmentModel) =>
          @overviewView.refresh()
          @formErrors.show({
            type: 'success'
            title: 'Link submitted and order completed.'
            message: 'The TM Mobile link was submitted and the order was completed successfully.'
          })
        .fail (errors) =>
          $(window).scrollTop(0)
          @submitTMMobileLinkButton.stopLoad()
          @loadingBox.stopLoad()
          @formErrors.show({
            title: 'Error submitting link.'
            errors
          })
        .done()

  onRequestDetailsButtonClick: (e) ->
    @requestDetailsButton.startLoad()
    $button = $(e.currentTarget)
    data = $button.data()
    shipmentId = data.shipmentId
    @getShipmentFromOrder().requestDetailsPromise(shipmentId)
      .then (model) =>
        @formErrors.show({
          type: 'success'
          title: 'Shipment details request sent successfully.'
        })
        @requestDetailsButton.stopLoad()
      .fail (errors) =>
        @formErrors.show({
          title: 'Shipment details request failed.'
          errors
        })
        @requestDetailsButton.stopLoad()
      .done()

  getShipmentFromOrder: () ->
    return @v3OrderModel.get('_shipments').first()

  onShipmentTypeChange: (e) ->
    firstTg = @getFirstTG()
    shipment = @v3OrderModel && @getShipmentFromOrder()
    shipmentTypeId = shipment && shipment.get('_type')
    shipmentTypeId ||= firstTg && @shipmentTypes.findByType(firstTg.get?('_format'))?.get('_provider')

    if shipmentTypeId == undefined
      shipmentTypeId ||= @$('input[name="shipmentType"]:checked').val()

    shipmentTypeId ||= ''

    @toggleValidShipment?(false)
    @switchType(shipmentTypeId)

  onChangeTransferSource: () ->
    selectedTransferSource = @$('.selectedTransferSource').val()
    if (selectedTransferSource)
      @getShipmentFromOrder().set('_transferSource', selectedTransferSource)

  onRateOptionChange: (e) ->
    rateOptionId = @$('#serviceType').val()
    if (rateOptionId) # User may have navigated off FedEx before rate options loaded.
      rateOptionModel = @rateOptions.get(rateOptionId)
      cost = rateOptionModel.get('_price')
      if (@action == C.BuySellActions.Sell || @action == C.BuySellActions.BuySell || @action == C.BuySellActions.AffiliateSell)
        @$('#shippingCost').val(App.Utils.valueToCurrencyNo$(cost))
        @shippingCost = cost
        @trigger(C.Events.ShippingCostChange, cost)

  onIsResidentialChange: (e) ->
    if (@$('#isResidential').is(':checked'))
      allowedTypes = SIGNATURE_TYPES
    else
      allowedTypes = _.omit(SIGNATURE_TYPES, RESIDENTIAL_ONLY_TYPES)
    @$('#signatureType').empty()
    for key, val of allowedTypes
      @$('#signatureType').append("<option value='#{ key }'>#{ val }</option>")


  onShippingCostChange: (e) ->
    cost = parseFloat(@$('#shippingCost').val())
    @shippingCost = cost
    @trigger(C.Events.ShippingCostChange, cost)

  onAbortEditShipmentButton: (e) ->
    @transitionToShowMode()

  onCancelShipmentButtonClick: (e) ->
    shipment = @getShipmentFromOrder()
    @loadingBox.startLoad()
    shipment.cancelPromise()
    .then (model) =>
      @transitionToCreateMode()
    .fail (errors) =>
      @formErrors.show({
        title: "Error canceling shipment."
        errors
      })
      @loadingBox.stopLoad()
    .done()

  onEditShipmentButtonClick: (e) ->
    @transitionToEditMode()

  onSaveShipmentButtonClick: (e) ->
    shipment = @getShipmentFromOrder()
    newPhoneNumber = @phoneView.$('.selectedPhoneNumber')?.val()
    newName = @$("input[name='shipToName']").val()?.trim()  || null
    newNotes = @$("input[name='notes']").val()?.trim() || null
    phoneNumberChanged = @phoneView.useNewPhoneNumber || newPhoneNumber != "N/A" && newPhoneNumber != "ExistingPhoneNumber"
    nameChanged  = newName != (shipment.get('_shipToName')?.trim() || null )
    notesChanged = newNotes != (shipment.get('_notes')?.trim() || null)
    if (phoneNumberChanged || nameChanged || notesChanged)
      @loadingBox.startLoad()
      @getShipmentPromise(phoneNumberChanged)
      .then (shipmentModel) =>
        if (@mode == MODES.CREATE)
          promise = shipmentModel.createForOrderPromise({
            order: @v3OrderModel
          })

        if (@mode == MODES.EDIT)
          promise = shipmentModel.editForOrderPromise({
            order: @v3OrderModel
          })

        promise.then (shipmentModel) =>
          @v3OrderModel.fetchPromise()
          .then (orderModel) =>
            @transitionToShowMode()
      .fail (errors) =>
        @formErrors.show({
          title: "Error while creating shipment."
          errors
        })
        @loadingBox.stopLoad()
      .done()
    else
      @formErrors.show({
        title: "New details were not submitted ."
      })
  transitionToCreateMode: () ->
    @mode = MODES.CREATE
    @loadingBox.stopLoad()
    @render()

  transitionToEditMode: () ->
    @mode = MODES.EDIT
    @render()

  transitionToShowMode: () ->
    @mode = MODES.SHOW
    @loadingBox.stopLoad()
    @render()

  onGenerateAirbillButtonClick: (e) ->
    @loadingBox.startLoad()
    @getShipmentFromOrder().generateAirbillPromise()
    .then (response) =>
      @v3OrderModel.fetchPromise()
      .then (model) =>
        @transitionToShowMode()
      .fail (errors) =>
        @formErrors.show({
          title: 'Unable to refetch order shipment.'
          errors
        })
        @loadingBox.stopLoad()
    .fail (errors) =>
      @formErrors.show({
        title: 'Unable to generate airbill.'
        errors
      })
      @loadingBox.stopLoad()
    .done()

  onUploadAirbillButtonClick: (e) ->
    $button = $(e.currentTarget)
    data = $button.data()
    shipmentId = data.shipmentId
    trackingNumber = @$('#airbillTrackingNumber').val()
    carrier = @$('#carrierType').val()
    if (!trackingNumber || !carrier)
      @formErrors.show({
        title: 'You must provide the carrier and tracking number in order to upload the airbill.'
      })
      return

    fileChosenCallback = (error, fileModel) =>
      if (error)
        @formErrors.show({
          title: 'Unable to upload airbill.'
          errors
        })
      else
        @loadingBox.startLoad()
        App.Controllers.uploadsController.uploadAirbillPromise(fileModel, shipmentId, trackingNumber, carrier)
        .then (model) =>
          @overviewView.refresh()
        .fail (error) =>
          @formErrors.show({
            title: 'Error uploading airbill.'
            error
          })
          @loadingBox.stopLoad()
        .done()

    @uploader = new App.Utils.FileUploader({
      $fileInputEl: @$('#fileToUpload')
      callback: fileChosenCallback
      fileTypeFilter: C.FileFilters.PDF
      fileSizeLimit: 5000000 #~5MB
    })

    @$('#fileToUpload').trigger('click');

  onRequestAirbillButtonClick: (e) ->
    @requestAirbillLoadingButton.startLoad()
    $button = $(e.currentTarget)
    data = $button.data()
    shipmentId = data.shipmentId
    @getShipmentFromOrder().requestAirbillPromise(shipmentId)
    .then (model) =>
      @formErrors.show({
        type: 'success'
        title: 'Airbill request sent successfully.'
      })
      @requestAirbillLoadingButton.stopLoad()
    .fail (errors) =>
      @formErrors.show({
        title: 'Request airbill failed.'
        errors
      })
      @requestAirbillLoadingButton.stopLoad()
    .done()

  onEmailAirbillButtonClick: (e) ->
    @emailAirbillLoadingButton.startLoad()
    $emailInput = @$('.airbillEmailInput')
    $emailInput.attr('disabled', true)
    recipients = $emailInput.val()
    @getShipmentFromOrder().emailAirbillPromise(recipients)
    .then (model) =>
      @formErrors.show({
        type: 'success'
        title: 'Airbill email successfully.'
        message: "The airbill was sent to #{ recipients }."
      })
      $emailInput.removeAttr('disabled')
      @emailAirbillLoadingButton.stopLoad()
    .fail (errors) =>
      @formErrors.show({
        title: 'Email airbill failed.'
        errors
      })
      $emailInput.removeAttr('disabled')
      @emailAirbillLoadingButton.stopLoad()
    .done()

  onMarkAsDeliveredButtonClick: (e) ->
    loadingButton = new App.Utils.LoadingButton(@$(e.currentTarget))
    loadingButton.startLoad()
    @getShipmentFromOrder().markAsDeliveredPromise()
    .then (model) =>
      @overviewView.refreshShipment()
    .fail (errors) =>
      @formErrors.show({
        title: 'Unable to mark shipment as delivered.'
        errors
      })
      loadingButton.stopLoad()
    .done()

  onRequestProofOfTransferButtonClick: (e) ->
    loadingButton = new App.Utils.LoadingButton(@$(e.currentTarget))
    loadingButton.startLoad()
    @getShipmentFromOrder().requestProofOfTransferPromise()
    .then (model) =>
      @overviewView.refreshShipment()
    .fail (errors) =>
      @formErrors.show({
        title: 'Unable to request Proof of Transfer.'
        message: _.pluck(errors.responseJSON.errors , 'message').join(', ')
      })
      loadingButton.stopLoad()
    .done()

  onPendButtonClick: (e) ->
    loadingButton = new App.Utils.LoadingButton(@$(e.currentTarget))
    loadingButton.startLoad()
    @getShipmentFromOrder().pendPromise()
    .then (model) =>
      @overviewView.refreshShipment()
    .fail (errors) =>
      @formErrors.show({
        title: 'Unable to pend shipment.'
        errors
      })
      loadingButton.stopLoad()
    .done()

  #/////////////////////////////////////////////////////////////////////////////

  isEditingTevoPickup: (shipmentType) ->
    return (
      shipmentType == C.LocalPickup &&
      @mode == MODES.EDIT &&
      @v3OrderModel?.isTevoOrder()
    )

  canSkipPhoneSubmission: (shipmentType) ->
    if( shipmentType == C.LocalPickup )
      return true
    else
      return false

  reloadAddresses: () ->
    @loadingBox.startLoad()
    @destinationPatron.fetchPromise()
    .then (model) =>
      @onShipmentTypeChange()
    .done()

  isOrderAcceptedBySeller: () ->
    return @v3OrderModel?.get('_sellerState') == 'accepted'

  isOrderCompletedBySeller: () ->
    return @v3OrderModel?.get('_sellerState') == 'completed'

  render: () ->

    @shipmentTypes.sort()
    renderFlags = @overviewView?.getOrderPermissions() || {}
    {
      canDeliverBarcodes
      canGenerateAirbill
      canPrintAirbill
      canUploadAirbill
      canRequestAirbill
      canShipmentBeCancelledAndCreated
      canShipmentBeEdited
      canShipmentBeMarkedAsDelivered
      canShipmentBePended
      canViewShippingCost
      canRequestBuyerInfo
      canRequestSellerInfo
      canRequestProofOfTransfer
      buyerShipmentDetailsProvided
      canSubmitTMMobileLink
      canUpdateShipmentType
      canView1TMDSplitterLink
      hasProofOfTransferUploads
      findProofOfTransferRequest
      isBuyerView
      proofOfTransferRequested
      proofOfTransferRequestedOn
    } = renderFlags

    data = {
      canDeliverBarcodes
      canGenerateAirbill
      canPrintAirbill
      canUploadAirbill
      canRequestAirbill
      canShipmentBeCancelledAndCreated
      canShipmentBeEdited
      canShipmentBeMarkedAsDelivered
      canShipmentBePended
      canViewShippingCost
      canRequestBuyerInfo
      canRequestSellerInfo
      canRequestProofOfTransfer
      buyerShipmentDetailsProvided
      canSubmitTMMobileLink
      canUpdateShipmentType
      @canView1TMDSplitterLink
      @hasProofOfTransferUploads
      @findProofOfTransferRequest
      @proofOfTransferRequested
      @proofOfTransferRequestedOn
      @isBuyerView
      @isAdHocShipmentOrigin
      @isAdHocShipmentDestination
      isEditMode: false
      @isOrderOverview
      shipment: null
      @shipmentOptionCopy
      shipmentOptions: @shipmentTypes.toSelectOptions()
    }

    if (@mode == MODES.SHOW)
      template = @showTemplate
      shipment = @getShipmentFromOrder()
      @transferSourceView = null
      data.shipment = shipment.toPresenterJSON()
      data.order = @v3OrderModel.toPresenterJSON()
      if (shipment.get('_type') == C.TBDShipment && !@v3OrderModel?.isTevoOrder() && data.order.orderItems[0].format != "TM Mobile" && !@isBuyerView())
        @mode = MODES.CREATE

      if (shipment.get('_type') == C.LocalPickup)
        if @v3OrderModel?.isTevoOrder()
          infoCompleted = shipment.get('_shipFromAddress').isValid(true) && shipment.get('_shipFromName') && shipment.get('_shipFromPhoneNumber').isValid(true) && shipment.get('_pickupTimeRange')
        else
          infoCompleted = shipment.get('_phoneNumber').isValid(true) && shipment.get('_shipToName')
        if (!infoCompleted)
          @mode = MODES.EDIT

    if (@mode == MODES.CREATE)
      template = @createTemplate

    if (@mode == MODES.EDIT)
      template = @createTemplate
      data.isEditMode = true
      shipment = @getShipmentFromOrder()
      data.shipment = shipment.toPresenterJSON()

    @$container.html(
      @$el.html(
        template(data)
      )
    )

    if ( (@isOrderAcceptedBySeller() || @isOrderCompletedBySeller()) && shipment && (shipment.get('_type') == C.TMMobile || shipment.get('_type') == C.FlashSeats ))
      @renderTransferSourceForm()

    @delegateEvents()
    @loadingBox = new App.Utils.LoadingBox(@$container)
    @formErrors = new App.Utils.FormErrorManager(@$('.formErrors'))
    @emailAirbillLoadingButton = new App.Utils.LoadingButton(@$('#emailAirbillButton'))
    @requestAirbillLoadingButton = new App.Utils.LoadingButton(@$('#requestAirbillButton'))
    @requestDetailsButton = new App.Utils.LoadingButton(@$('#requestDetailsButton'))
    @submitTMMobileLinkButton = new App.Utils.LoadingButton(@$('#submitTMMobileLinkButton'))

    @$('.actLikeCurrency').formatCurrency()

    if (@isAdHocShipmentOrigin || @isAdHocShipmentDestination)
      if (@shipmentTypes.findByType(C.FedEx))
        @switchType(C.FedEx)
      else
        console.error('Cannot use adHocShipment without FedEx configured.')
    else if (@mode != MODES.SHOW)
      @onShipmentTypeChange()

    return @$el

  # RENDERING DETAILS ////////////////////////////////////////////////////////
  switchType: (shipmentType) ->
    @shipmentType = shipmentType
    @loadingBox.startLoad()
    @formErrors.reset()

    @addressView = null
    @emailView = null
    @phoneView = null

    if (@mode == MODES.EDIT)
      shippingCost = @v3OrderModel.get('_shipping')
      @shippingCost = shippingCost
      @$('#shippingCost').val(App.Utils.valueToCurrencyNo$(shippingCost))
    else
      if (shipmentType == C.FedEx)
        if (@action == C.BuySellActions.Sell || @action == C.BuySellActions.BuySell || @action == C.BuySellActions.AffiliateSell)
          @$('#shippingCost').val('0.00')
          @shippingCost = 0
      else
        if (shipmentType) # Office may have zero shipment types configured.
          shipmentModel = @shipmentTypes.findByType(shipmentType)
          # Note @shipmentTypes only contains YOUR shipment types.
          # On orderOverview, you may not have the one that corresponds to 'shipmentType'
          if (@action == C.BuySellActions.Sell || @action == C.BuySellActions.BuySell || @action == C.BuySellActions.AffiliateSell)
            cost = shipmentModel.get('_price')
            @shippingCost = cost
            @$('#shippingCost').val(App.Utils.valueToCurrencyNo$(cost))
            # Let render finish so listenTo can be bound before this triggers.
            # One shortcoming of always having initialize call render().
            setTimeout(() =>
              @trigger(C.Events.ShippingCostChange, cost)
            , 0)
        else
          shipmentType = null

    switch shipmentType

      # COURIER //////////////////////////////////////////////////////////////
      when C.Courier
        @renderDetails(shipmentType)
        @renderAddressForm(shipmentType)
        @renderPhoneForm(shipmentType)

      # CUSTOM ///////////////////////////////////////////////////////////////
      when C.Custom
        @renderDetails(shipmentType)

      # ETICKET //////////////////////////////////////////////////////////////
      when C.Eticket
        @renderDetails(shipmentType)
        @renderEmailForm(shipmentType)

      # FEDEX ////////////////////////////////////////////////////////////////
      when C.FedEx
        @renderDetails(shipmentType, {
          rateOptions: null
        })
        @onIsResidentialChange()
        @renderAddressForm(shipmentType)
        @renderPhoneForm(shipmentType)
        @renderEmailForm(shipmentType)
        @listenTo(@addressView, C.Events.AddressViewChange, _.bind(@onAddressChanged, @))
        if @originPatron && @destinationPatron && !_.isEmpty(@addressView.patron.addressOptions())
          @refreshFedExRateOptions()

      # TMMOBILE ////////////////////////////////////////////////////////////////
      when C.TMMobile
        @renderDetails(shipmentType)
        @renderEmailForm(shipmentType)
        @renderPhoneForm(shipmentType)

      # FLASH SEATS ////////////////////////////////////////////////////////////////
      when C.FlashSeats
        @renderDetails(shipmentType)
        @renderEmailForm(shipmentType)
        @renderPhoneForm(shipmentType)


      # GUEST LIST ////////////////////////////////////////////////////////////////
      when C.GuestList
        @renderDetails(shipmentType)

      # PAPERLESS ////////////////////////////////////////////////////////////////
      when C.Paperless
        @renderDetails(shipmentType)

      # PROVIDED AIRBILL  //////////////////////////////////////////////
      when C.ProvidedAirbill
        @renderDetails(shipmentType)

      # INSTANT DELIVERY /////////////////////////////////////////////////////
      when C.InstantDelivery
        @renderDetails(shipmentType)

      # LOCAL PICKUP /////////////////////////////////////////////////////////
      when C.LocalPickup
        @renderDetails(shipmentType)
        if @v3OrderModel?.isTevoOrder()
          @renderAddressForm(shipmentType)
        @renderPhoneForm(shipmentType)
        @renderEmailForm(shipmentType)

      # OFFLINE //////////////////////////////////////////////////////////////
      when C.Offline
        @renderDetails(shipmentType)

      # PICKUP AT OFFICE /////////////////////////////////////////////////////
      when C.PickupAtOffice
        @renderDetails(shipmentType)
        @renderAddressForm(shipmentType)
        @renderPhoneForm(shipmentType)
        @renderEmailForm(shipmentType)

      # TBD //////////////////////////////////////////////////////////////////
      when C.TBDShipment
        @renderDetails(shipmentType)

      # UPS //////////////////////////////////////////////////////////////////
      when C.UPS
        @renderDetails(shipmentType)

      # WILL CALL ////////////////////////////////////////////////////////////
      when C.WillCall
        @renderDetails(shipmentType)
        @renderPhoneForm(shipmentType)

      else
        @renderDetails(null)

  renderAddressForm: (shipmentType) ->
    $container = @$('.addressContainer')
    defaultToNewAddress = false
    isForFedEx = false
    isForLocalPickup = false
    patron = @destinationPatron
    if (@isAdHocShipmentOrigin)
      patron = @originPatron # Can be null if 'New Address' was chosen in adHocShipment.
      disableSaving = true
    if (@isAdHocShipmentDestination)
      patron = @destinationPatron # Can be null if 'New Address' was chosen in adHocShipment.
      disableSaving = true
    if (shipmentType == C.PickupAtOffice)
      patron = @originPatron
      disableAddNew = true
    if (shipmentType == C.FedEx)
      isForFedEx = true
    if (shipmentType == C.LocalPickup && @v3OrderModel?.isTevoOrder())
      patron = @originPatron
      defaultToNewAddress = false
      disableExisting = false
      disableSaving = false
      isForLocalPickup = true
    data = {
      $container
      @isAdHocShipmentOrigin
      defaultToNewAddress
      disableAddNew
      disableExisting
      disableSaving
      isForFedEx
      isForLocalPickup
      patron
    }

    if (@mode == MODES.EDIT)
      shipment = @getShipmentFromOrder()
      data.existingShipment = shipment
    @addressView = new App.Views.BuySell.Address(data)

  renderEmailForm: (shipmentType = '') ->
    $container = @$('.emailContainer')
    patron = @destinationPatron

    if (@isAdHocShipmentOrigin)
      patron = @originPatron  # Can be null if 'New Address' was chosen in adHocShipment.

    data = {
      $container
      patron
      shipmentType
    }
    if (@mode == MODES.EDIT)
      shipment = @getShipmentFromOrder()
      data.existingShipment = shipment
      data.shipmentEmail = shipment.get('_emailAddress')
    @emailView = new App.Views.BuySell.EmailAddress(data)

  renderTransferSourceForm: () ->
    $container = @$('.transferSourceContainer')
    data = {
      $container,
      selectedTransferSource: @getShipmentFromOrder().get("_transferSource")
    }
    @transferSourceView = new App.Views.BuySell.TransferSource(data)

  renderPhoneForm: (shipmentType) ->
    $container = @$('.phoneContainer')
    patron = @destinationPatron

    if (@isAdHocShipmentOrigin || @isEditingTevoPickup(shipmentType))
      patron = @originPatron # Can be null if 'New Address' was chosen in adHocShipment.

    data = {
      $container
      isEditingTevoPickup: @isEditingTevoPickup(shipmentType)
      patron
      canSkipPhoneSubmission: @canSkipPhoneSubmission(shipmentType)
    }

    if (@mode == MODES.EDIT)
      shipment = @getShipmentFromOrder()
      data.existingShipment = shipment

    @phoneView = new App.Views.BuySell.PhoneNumber(data)

  renderDetails: (shipmentType = '', options = {}) ->
    $container = @$('#shipmentDetailsContainer')

    data = {
      @isAdHocShipmentDestination
      @isAdHocShipmentOrigin
      isEditingTevoPickup: @isEditingTevoPickup(shipmentType)
      patron: @destinationPatron?.toPresenterJSON()
      shipment: null
      isTevoOrder: @v3OrderModel?.isTevoOrder()
      shipmentType
      isEditMode: false
      shipNameLabel: 'Ship to Name'
      shipCompanyLabel: 'Ship to Company'
    }

    if (@isAdHocShipmentOrigin)
      data.patron = @originPatron?.toPresenterJSON()
      data.shipNameLabel = 'Ship from Name'
      data.shipCompanyLabel = 'Ship from Company'

    if (options.rateOptions)
      data.rateOptions = rateOptions.toPresenterJSON()

    if (@mode == MODES.EDIT)
      shipment = @getShipmentFromOrder()
      data.shipment = shipment.toPresenterJSON()
      data.isEditMode = true

    @loadingBox.stopLoad()
    $container.html(
      @detailsTemplate(data)
    )
    @rateOptionErrors = new App.Utils.FormErrorManager(@$('.rateOptionsFormErrors'))
    if (shipmentType && shipmentType != C.FedEx)
      @toggleValidShipment?(true)

    @delegateEvents()

  #///////////////////////////////////////////////////////////////////////////

  refreshFedExRateOptions: () ->
    $container = @$('.rateOptionsContainer')
    @rateOptionErrors.reset()
    @loadingBoxRateOptions = new App.Utils.LoadingBox($container, { isInline: true })
    @loadingBoxRateOptions.startLoad()
    @addressView.getAddressPromise()
    .then (address) =>
      @rateOptions = new App.Collections.V3.RateOptions(null, {
        firstTg: @getFirstTG()
        destinationAddress: address
        originAddress: @originPatron?.address()
        mapping: C.Mappings.Indirect.RateOptions.RateOption
      })
      App.ErrorManager.ignoreNext(422)
      App.ErrorManager.ignoreNext(500)

      return @rateOptions.fetchPromise(@rateOptions.fetchOptions())
      .then (collection) =>
        App.ErrorManager.resetTolerance(422)
        App.ErrorManager.resetTolerance(500)
        @loadingBoxRateOptions.stopLoad()

        @displayInternationalWarning(@rateOptions)

        data = {
          rateOptions: @rateOptions.toSelectOptions()
        }
        @$('.rateOptionsContainer').html(
          @rateOptionsTemplate(data)
        )
        @delegateEvents()
        @onRateOptionChange()
        @toggleValidShipment?(true)
      .fail (errors) =>
        App.ErrorManager.resetTolerance(422)
        App.ErrorManager.resetTolerance(500)
        @loadingBoxRateOptions.stopLoad()
        if (errors && errors.responseText)
          json = JSON.parse(errors.responseText)
          if (json && json.error && json.error.substr(0, 15) == '#<NoMethodError') # FedEx is down
            errors = ['FedEx is unavailable.']
        @rateOptionErrors.show({
          title: "Unable to fetch rate options."
          errors
        })
    .fail (errors) =>
      @loadingBoxRateOptions.stopLoad()
    .done()

  displayInternationalWarning: (rateOptions) ->
    serviceTypes = Object.keys(rateOptions.toSelectOptions())
    isInternationalInArray = serviceTypes.some((item) => item.toUpperCase().includes('INTERNATIONAL'))
    alertBox = document.querySelector("#international-alert-box")
    if (isInternationalInArray)
      alertBox.innerHTML = warningBoxTemplate({text: "This shipment may require an International airbill"})
    else
      alertBox.innerHTML =  ''

  onAddressChanged: () ->
    @refreshFedExRateOptions()

  getFirstTG: () ->
    if @firstTg
      return @firstTg
    else
      @v3OrderModel?.get('_orderItems')?.first()?.get('_ticketGroup')

  shouldRequireTransferSouce: (changingShipmentType) ->
    return !changingShipmentType && @transferSourceView && @isOrderAcceptedBySeller()

  getShipmentPromise: (phoneChanged = true, changingShipmentType = false) ->
    promises = []
    if (@mode == MODES.SHOW)
      if (@shouldRequireTransferSouce(changingShipmentType))
        promises.push(@transferSourceView.getTransferSourcePromise())
        return Q.all(promises)
        .then () =>
          formData = @$el.serializeObject()
          @getShipmentFromOrder().set('_transferSource', formData.selectedTransferSource)
          return Q(@getShipmentFromOrder())
      return Q(@getShipmentFromOrder())

    if (@addressView)
      promises.push(@addressView.getAddressPromise())
    if (@emailView)
      promises.push(@emailView.getEmailPromise())
    if (@phoneView && phoneChanged)
      unless (@phoneView.$('.selectedPhoneNumber').is(":visible") && @phoneView.$('.selectedPhoneNumber').val() == "N/A")
        promises.push(@phoneView.getPhoneNumberPromise())

    return Q.all(promises)
    .then (arrayOfSavedModels) =>

      for model in arrayOfSavedModels
        if (model instanceof App.Models.V3.Address)
          if @shipmentType == C.LocalPickup
            shipFromAddress = model
          else
            address = model
        if (model instanceof App.Models.V3.EmailAddress)
          email = model
        if (model instanceof App.Models.V3.PhoneNumber)
          if @shipmentType == C.LocalPickup && @v3OrderModel?.isTevoOrder()
            shipFromPhoneNumber = model
          else
            phone = model
        if (model.useVenueAddress == true)
          useVenueAddress = true

      formData = @$el.serializeObject()
      type = formData.shipmentType


      if (@isAdHocShipmentOrigin || @isAdHocShipmentDestination)
        type = C.FedEx

      shipmentAttrs = {
        _address:             address
        _cost:                parseFloat(@$('#shippingCost').val()) || @shippingCost || 0
        _courier:             formData.courier
        _email:               email
        _locationName:        formData.locationName
        _phoneNumber:         phone
        _trackingNumber:      formData.trackingNumber
        _serviceType:         formData.serviceType
        _signatureType:       formData.signatureType
        _shipToCompanyName:   formData.shipToCompany
        _shipToName:          formData.shipToName
        _shipFromName:        formData.shipFromName
        _shipFromPhoneNumber: shipFromPhoneNumber
        _shipFromAddress:     shipFromAddress
        _pickupTimeRange:     formData.pickupTimeRange
        _pickupInstructions:  formData.pickupInstructions
        _notes:               formData.notes
        _type:                type
        _useVenueAddress:     useVenueAddress
      }

      if (@mode == MODES.EDIT)
        shipmentAttrs._cost = @v3OrderModel.get('_shipping')
        type = @getShipmentFromOrder().get('_type')
        shipmentAttrs._type = type

      # This field is hidden on form for this one specific case.
      if (@isEditingTevoPickup(type))
        shipmentAttrs._shipToName = @getShipmentFromOrder().get('_shipToName')

      if (type == C.FedEx)
        shipmentAttrs._isResidential = formData.isResidential

      shipment = new App.Models.V3.Shipment(null, {
        mapping: C.Mappings.Direct.Shipments.Shipment
        @isAdHocShipmentOrigin
        isTevoOrder: @v3OrderModel?.isTevoOrder()
      })
      # Set after initialization to avoid updateStandardAttributes().
      shipment.set(shipmentAttrs)

      if (shipment.isValid(true))
        @formErrors.reset()
        return shipment

      else
        errors = shipment.errors
        @formErrors.show({
          title: "Invalid shipment."
          errors
        })
        return Q.reject(errors)

  canView1TMDSplitterLink: () ->
    isAllowed = @order.client && @order.seller.id == SESSION.office_id ||
      !@order.client && @order.buyer.id == SESSION.office_id

    return isAllowed &&
      @shipment.tm_mobile_link &&
      @shipment.tm_mobile_link.includes(C.EXTERNAL_URLS.ims_itmd_domain)

  hasProofOfTransferUploads: () ->
    hasUploads = false
    _.each(@shipment.transfer_verifications, (transferVerification) ->
      if (transferVerification.uploads.length > 0)
        hasUploads = true
    )
    return hasUploads

  isBuyerView: () ->
    return @order?.buyer?.id == SESSION.office_id

  findProofOfTransferRequest: () ->
    transferVerifications = if (@shipment != undefined)
                              @shipment.transfer_verifications
                            else
                              @getShipmentFromOrder().attributes.transfer_verifications
    transferVerification = transferVerifications.find((transferVerification) ->
      return transferVerification.type == 'Proof of Transfer' &&
             transferVerification.uploads.length == 0
    )
    return transferVerification

  proofOfTransferRequested: () ->
    return @findProofOfTransferRequest() != undefined

  proofOfTransferRequestedOn: () ->
    return @findProofOfTransferRequest().created_at

  shipmentOptionCopy: {
    portal: {
      Eticket: 'E-tickets will be emailed as a PDF and must be printed out, unless otherwise stated in the ticket group notes. Delivery fees may apply.'
      FlashSeats: 'Please download the Flash Seats app and create an account to retrieve these tickets. Attendee(s) must display the tickets from the Flash Seats app when entering the venue. Delivery fees may apply.'
      FedEx: 'The company fulfilling this order will generate a FedEx Airbill using the information provided on this delivery. Please ensure all details are accurate.'
      GuestList: 'Attendees will gain entry to the event by presenting a government-issued ID that matches the attendee information provided at checkout. Handling fees may apply.'
      InstantDelivery: 'Once your order has been accepted, tickets will be delivered within a few minutes. Delivery fees may apply.'
      LocalPickup: 'Local Pickup information will be provided by the in hand date. Local or hotel delivery may also be available. Handling fees may apply.'
      ProvidedAirbill: 'Please select Provided Airbill to provide a shipping address at a later time. Selecting this delivery method means that you agree to pay the $45 Provided Airbill fee. A delivery address must be provided by the in hand date.'
      TMMobile: 'These are mobile transfer tickets. The tickets will be transferred via a third-party mobile app and the recipient will receive email instructions on how to create their account and retrieve their tickets. These tickets cannot be printed out and must be displayed on a mobile phone to enter the event. Delivery Fees may apply.'
    }
    ProvidedAirbill: 'Warning! If you select this delivery option, you will be responsible for providing a valid airbill in sufficient time to allow tickets to be shipped. Once available, upload the airbill onto the Order in Core.'
  }
