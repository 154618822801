App.Models.Base.BaseModel = require('../base/base_model.coffee')
App.Models.V2.Venue       = require('./venue_model.coffee')

parent = App.Models.Base.BaseModel.prototype
module.exports = App.Models.V2.Event = App.Models.Base.BaseModel.extend({

  defaults:
    _venue: new App.Models.V2.Venue(null, {
      mapping: C.Mappings.TicketGroupEndpoint.Venue
    })

  initialize: (attributes, options) ->
    parent.initialize.call(this, attributes, options)

  urlRoot: '/api_direct/v9/events/'

  fetch: (options) ->
    @mapping = C.Mappings.SingleEventEndpoint.Event
    parent.fetch.call(@, options)

  updateStandardAttributes: () ->
    json = @attributes
    attrs = {}

    if (@getMapping() == C.Mappings.SingleEventEndpoint.Event)
      attrs._id = json.id
      attrs._name = json.name
      attrs._occursAt = Date.parse(json.occurs_at)
      attrs._url = json.url
      attrs._venue = new App.Models.V2.Venue(json.venue, {
        mapping: C.Mappings.SingleEventEndpoint.Venue
      })

    if (@getMapping() == C.Mappings.SingleTicketGroupEndpoint.Event)
      attrs._id = json.id
      attrs._name = json.name
      attrs._occursAt = Date.parse(json.occurs_at)
      attrs._url = json.url

    if (@getMapping() == C.Mappings.SingleEventEndpoint.Event)
      attrs._venue = new App.Models.V2.Venue(json.venue, {
        mapping: C.Mappings.SingleEventEndpoint.Venue
      })

    if (@getMapping() == C.Mappings.TicketGroupEndpoint.Event)
      attrs._id = json.id
      attrs._isOwnedByOffice = json.owned_by_office
      attrs._name = json.name
      attrs._occursAt = Date.parse(json.occurs_at)
      attrs._url = json.url
      attrs._venue = new App.Models.V2.Venue(json.venue, {
        mapping: C.Mappings.TicketGroupEndpoint.Venue
      })

      ###
      category: {name:MLB,…}
      configuration: {seating_chart:{medium:/system/configurations/static_maps/6491/original.jpg?1351936857,…},…}
      long_term_popularity_score: 0.73918
      notes: null
      performances: [{primary:false, performer:{name:New York Yankees, url:/performers/15533, id:15533}},…]
      popularity_score: "0"
      products_count: 828
      products_eticket_count: 0
      state: "shown"
      updated_at: "2013-01-28T17:35:48Z"
      ###

    if (@getMapping() == C.Mappings.EventEndpoint.Event)
      attrs._id = json.id
      attrs._isOwnedByOffice = json.owned_by_office
      attrs._name = json.name
      attrs._occursAt = Date.parse(json.occurs_at)
      attrs._url = json.url
      attrs._venue = new App.Models.V2.Venue(json.venue, {
        mapping: C.Mappings.EventEndpoint.Venue
      })

    if (@getMapping() == C.Mappings.OrderEndpoint.Event)
      attrs._id = json.id
      attrs._name = json.name
      attrs._occursAt = Date.parse(json.occurs_at)
      attrs._url = json.url
      attrs._venue = new App.Models.V2.Venue(json.venue, {
        mapping: C.Mappings.OrderEndpoint.Venue
      })

    if (@getMapping() == C.Mappings.MassIndexEndpoint.Event)
      venueAttrs = _.pick(json,
        'venue_address_company',
        'venue_address_country_code',
        'venue_address_extended_address',
        'venue_address_id',
        'venue_address_label',
        'venue_address_locality',
        'venue_address_name',
        'venue_address_postal_code',
        'venue_address_region',
        'venue_address_street_address',
        'venue_id',
        'venue_name'
      )
      attrs._id = json.event_id
      attrs._isTBD = json.event_tbd
      attrs._name = json.event_name
      attrs._notes = json.event_notes
      attrs._occursAt = Date.parse(json.event_occurs_at)
      # WARNING: We're not using event_occurs_local
      attrs._occursAtLocal = moment(json.event_occurs_at)?.utc()
      attrs._occursAtRaw = json.event_occurs_at
      attrs._popularityScore = json.event_popularity_score
      attrs._shortTermPopularityScore = json.event_short_term_popularity_score
      attrs._state = json.event_state
      attrs._venue = new App.Models.V2.Venue(venueAttrs, {
        mapping: C.Mappings.MassIndexEndpoint.Venue
      })
      # COMLETE - KEEP THIS COMMENT

    @set(attrs)

  # PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      configuration: @get('_configuration')?.toPresenterJSON()
      id:               @get('_id')
      isOwnedByOffice:  @get('_isOwnedByOffice')
      fullDescription:  @fullDescription()
      name:             @get('_name')
      occursAt:         @get('_occursAt')
      occursAtRaw:      @get('_occursAtRaw')
      summary:          @summary()
      venue:            @get('_venue').toPresenterJSON()

    })
    return presented

  fullDescription: () ->
    venueJSON = @get('_venue')?.toPresenterJSON()
    return "
      #{ @get('_name') }<br/>
      #{ venueJSON && venueJSON.name }<br/>
      #{ App.Utils.makeTimestampHumanForEvent(@get('_occursAtRaw'), C.DateFormats.Long) }<br/>
    "

  summary: () ->
    venueJSON = @get('_venue').toPresenterJSON()
    return "
      #{ @get('_name') } on
      #{ App.Utils.makeTimestampHumanForEvent(@get('_occursAtRaw'), C.DateFormats.Long) } at
      #{ venueJSON.name } (#{ venueJSON.address?.locality }, #{ venueJSON.address?.region })
    "

#///////////////////////////////////////////////////////////////////////////

}, {
  # STATICS
  defaultMapping: C.Mappings.TicketGroupEndpoint.Event
})
