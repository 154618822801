App.Views.Base.BaseView = require('./base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Base.FilteredTable = App.Views.Base.BaseView.extend

  ###
  getFilterParams:
  Allows a set of filter to be converted into request parameters.

  reflectFilterParams:
  Takes a set of request parameters and edits the filter elements to display
  them as the selected items.

  A View extending this base class must specify filterParamMappings...
  which maps filter elements (html inputs) to request params.

  C.FilterTypes = {};
  C.FilterTypes.DateRange = 1;
  C.FilterTypes.Select2 = 2;
  C.FilterTypes.Select2Boolean = 3;
  C.FilterTypes.Text = 4;
  C.FilterTypes.Autocomplete = 5;
  C.FilterTypes.Grouping = 6;
  C.FilterTypes.FixedValForSelection = 7;
  C.FilterTypes.Default = 8;
  ###

  getEncodedFilterParams: () ->
    params = @getFilterParams(true)
    params = App.Utils.encodeFilterParams(params)
    return params

  getFilterParams: (forEncoding) ->
    params = {}
    filterMappings = @filterParamMappings()
    _.each(filterMappings, (mapping, param) =>

      switch (mapping.type)

        when C.FilterTypes.Default
          params[param] = mapping.val

        when C.FilterTypes.DateRange
          p = mapping.val
          if (p && p.start && p.end)
            # Handle stupid 'orders[date.gte]' style params.
            parts = param.split(']')
            suffix = ''
            param = parts[0]
            if (parts.length > 1)
              suffix = ']'
            params[param + '.gte' + suffix] = p.start.toDate().toISOString()
            params[param + '.lte' + suffix] = p.end.toDate().toISOString()

        when C.FilterTypes.Autocomplete
          pText = @$(mapping.el).val()
          if (mapping.sendsId && mapping.val)
            pId = mapping.val
            if (pId && pText) # Checking both lets us ignore stuff they delete
              if (forEncoding)
                params[param] = {
                  id: pId
                  text: pText
                }
              else
                params[param] = pId
          else
            if (pText.trim() != '')
              params[param] = pText

        when C.FilterTypes.Select2
          pText = @$(mapping.el).val()
          if (mapping.sendsId || mapping.isMultiple)
            data = @$(mapping.el).select2('data')
            if (forEncoding)
              p = data
            else
              if (mapping.isMultiple)
                p = []
                for el in data
                  if (el.id)
                    p.push(el.id)
                  else
                    p.push(el)
              else
                p = (data && data.id) || null
          else
            p = @$(mapping.el).select2('val')
          # Basically if p exists or is an array with length.
          if ((!_.isArray(p) && p) || (_.isArray(p) && p.length))
            if (mapping.ignoreAll && (pText == 'all' || pText == 'All' || pText == 'ALL'))
              $.noop()
            else
              params[param] = p
              # Inventory report
              if (mapping.wrappedInArray)
                params[param] = [p]
          break

        when C.FilterTypes.Select2Boolean
          pText = @$(mapping.el).val()
          if (pText == mapping.trueVal)
            params[param] = true
          else
            if (mapping.elseFalse)
              params[param] = false
            if (pText == mapping.falseVal)
              params[param] = false

        when C.FilterTypes.Text
          p = @$(mapping.el).val()
          if (p)
            params[param] = p

        when C.FilterTypes.Grouping
          # Used by Inventory Report.
          asc = {
            by_ticket_group_created_at: 1
            by_event_occurs_at: 1
          }
          p = @$(mapping.el).val()
          if (p == param)
            asc = asc.hasOwnProperty(p)
            val = asc ? 'asc' : 'desc'
            params[param] = val

        when C.FilterTypes.FixedValForSelection
          selectedVal = $(mapping.el).val()
          if (selectedVal == mapping.condition)
            params[param] = mapping.val


    ) # End _.each(filterMappings, (mapping, param) =>
    @priorFilterParams = params
    return params

  getFilterParamsOfCurrentResults: () ->
    return @priorFilterParams

  reflectFilterParams: (params) ->
    filterMappings = @filterParamMappings()
    _.each(params, (val, param) =>

      mapping = filterMappings[param]
      # when C.FilterTypes.DateRange
      if (!mapping && param.indexOf('.') > -1) # date range with .lte / .gte

        # Handle stupid 'orders[date.gte]' style params.
        if (param.split(']').length > 1)
          hasBrackets = true

        paramBeginning = paramMappingName = param.split('.')[0]
        if (hasBrackets)
          paramMappingName += ']'

        mapping = filterMappings[paramMappingName]

        startKey = paramBeginning + '.gte'
        endKey = paramBeginning + '.lte'
        if (hasBrackets)
          startKey += ']'
          endKey += ']'

        if (mapping.type == C.FilterTypes.DateRange) # always true but just to be safe
          startVal = params[startKey]
          endVal = params[endKey]

          if (
            C.Times.hasOwnProperty(startVal) &&
            C.Times.hasOwnProperty(endVal)
          )
            # We're using prefab filters like in orders dashboard.
            # They give params like event_date.gte='Next30' , not a ISO-8601 string, since these days are moving targets.
            # I.E. '30 Days from now' always changes.
            startDate = C.Times[startVal]
            endDate = C.Times[endVal]
          else
            # Normal usage.
            startDate = moment(startVal)
            endDate = moment(endVal)


          $el = @$(mapping.el)
          $el[0].setDateRange(startDate, endDate)

      else

        if (mapping)

          switch (mapping.type)

            when C.FilterTypes.Select2
              if (mapping.wrappedInArray)
                val = val.length && val[0]
              if (mapping.sendsId || mapping.isMultiple)
                @$(mapping.el).select2('data', val)
              else
                @$(mapping.el).select2('val', val)

            when C.FilterTypes.Autocomplete
              $el = @$(mapping.el)
              if (mapping.sendsId)
                $el[0].setClientOrOffice(val)
              else
                $el.val(val)

            when C.FilterTypes.Text
              @$(mapping.el).val(val)

            when C.FilterTypes.Select2Boolean
              $el = @$(mapping.el)
              if (_.isBoolean(val) && val == true)
                $el.select2('val', mapping.trueVal)
              else if ((_.isBoolean(val) && val == false) || (typeof(val) == 'undefined' && mapping.elseFalse))
                $el.select2('val', mapping.falseVal)

            when C.FilterTypes.Grouping
              $el = @$(mapping.el)
              $el.select2('val', param)

            when C.FilterTypes.FixedValForSelection
              $el = @$(mapping.el)
              if (val == mapping.val)
                $el.select2('val', mapping.condition)

        else
          console.log(param, ' not found in filterMappings.')

    ) # End _.each(params, (val, param) =>
