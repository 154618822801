App.Models.Base.BaseModel = require('../base/base_model.coffee')

parent = App.Models.Base.BaseModel.prototype
module.exports = App.Models.V2.Shipment = App.Models.Base.BaseModel.extend {

  has_one:
    email_address : 'email'
    address       : 'address'
  has_many:
    items: 'order_items'

  address_attributes: ["id", "street_address", "extended_address", "locality", "postal_code", "region", "country_code"]

  urlRoot: "/shipments"
  #setup

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  parse: (response, options)  ->
    #parse out snapshot if no address
    if !response.address and response.shipment_snapshot
      response.address = {}
      for attr in @address_attributes
        response.address[attr] = response.shipment_snapshot[attr]
    if response.airbill
      response.airbill = new App.Models.Airbill
        url: response.airbill,
        shipment_id: response.id
    if response.phone_number
      response.phone_number = new App.Models.PhoneNumber({number: response.phone_number})

    parent.parse.call(@, response, options)
    return response

  updateStandardAttributes: () ->
    json = @attributes
    attrs = {}
    # FIND WHICH MAPPING THESE APPLY TO
    attrs.address = new App.Models.V2.Address(json?.address?.attributes || json?.address, {
      holder_id: @id
      mapping: C.Mappings.Unknown
    })
    attrs.email_address = new App.Models.Email(json?.email_address?.attributes || json?.email_address, {
      holder_id: @id
      mapping: C.Mappings.Unknown
    })
    attrs.items = new App.Collections.V2.OrderItems(json?.items?.models || json?.items, {
      holder_id: @id
      mapping: C.Mappings.Unknown
    })
    @set(attrs)

  #validations
  validate: ()  ->

  toPresenterJSON: ->
    _.extend(@toJSON(),
      phone_number: @get('phone_number')?.toPresenterJSON()
      address:      @get('address')?.toPresenterJSON() if @get('address').shouldPresentAddress()
      signature_type: App.Models.V2.Shipment.find_signature(@get('signature_type'))
      emailAddress: @emailAddress()
      only_tracking: @only_tracking()
    )

  only_tracking: () ->
    @get('tracking_number') && ( !@get('airbill') || @get('airbill') == "" )

  emailAddress: () ->
    emailModel = @get('email_address')
    address = emailModel.get('address')
    return address

  toJSON: (options) ->
    json = parent.toJSON.call(@, options)
    if (json.address instanceof Backbone.Model)
      json.address =  json.address?.toJSON()
    if (json.email_address instanceof Backbone.Model)
      json.email_address = json.email_address?.toJSON()
    if (json.items instanceof Backbone.Model)
      json.items = json.items?.toJSON()
    return json

  #introspective
  isValid: ()  ->
    @validate()
    _.isEmpty(@errors)

  #??????????????????????????????????????????????????????????#
  isFedex: ()  ->
    @get("type") is C.FedEx

  #mutators
  addressify:  () ->
    args = {}
    for attr in @address_attributes
      args[attr] = @get(attr)
    new App.Models.V2.Address(args)

#actions
  cancel: (successCallback, errorCallback)  -> $.post("/shipments/" + @get("id") + "/cancel", successCallback).error(errorCallback)

  deliver: (successCallback, errorCallback)  -> $.post("/shipments/" + @get("id") + "/deliver", successCallback).error(errorCallback)

  pend: (successCallback, errorCallback)  -> $.post("/shipments/" + @get("id") + "/pend", successCallback).error(errorCallback)

  track: (uid) ->
    params = uid ? "?unique_identifier=" + uid : ""
    $.get("/shipments/" + @get("id") + "/track" + params)

  processFedex: () ->
    unless @canHaveAirbill()
      "can't have airbill for anything but fedex shipments"
    $.post("/shipments/" + @id + "/airbill")

  sendEmails:    (emails) -> $.post(@url() + "/email_airbill", {recipients:emails} )

  emailAirbill : (emails) -> return $.post(@url() + "/email_airbill", {recipients:emails} )

  clearUpdatableAttributes: () ->
    @set('phone_number', null)
    @set('email_address', null)
    @set('signature_type', "")
    @set('ship_to_name', "")
    @set('address', null)
    @set('cost', '0.0')
    @unset('existing_airbill')
}, {
#################################
#Static Methods
  signature_types:[
      {id:"INDIRECT",              text: "Indirect"}
      {id:"",                     text: "No Signature Type"}
      {id:"NO_SIGNATURE_REQUIRED", text: "No Signature Required"}
      {id:"ADULT",                 text: "Adult"}
      {id:"DIRECT",                text: "Direct"}
      {id:"SERVICE_DEFAULT",       text: "Service Default"}
  ]

  find_signature: (signatureType) -> {id: signatureType, text: App.Models.V2.Shipment.signature_to_s(signatureType)}

  signature_to_s: (signatureType) ->
    signature_type = App.Utils.falsyToEmptyString(signatureType)
    types = App.Models.V2.Shipment.signature_types
    types     =  _.filter types, (type) -> type.id is signature_type
    if types[0] then types[0].text else "No Signature Type"

}
