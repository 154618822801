template = require('./seat_selection.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.BuySell.SeatSelection = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    {
      @action
      @$container
      @canChooseSeats
      @seller
      @ticketGroupModel
      @shipmentType
    } = options

    if (_.isUndefined(@canChooseSeats))
      @canChooseSeats = SESSION.role.can(App.Access.Resources.Orders.BuySell.CanEnterSeats)

    @listenTo(@ticketGroupModel, 'change:_quantity', @onTicketGroupChange.bind(@))
    @render()

  render: () ->

    data = {
      @action
      @canChooseSeats
      @shipmentType
      seller: @seller?.toPresenterJSON()
      ticketGroup: @ticketGroupModel.toPresenterJSON()
    }

    @$container.html(
      @$el.html(
        @template(data)
      )
    )

    @$('select').select2()
    @onTicketGroupChange()
    return @$el

  # VIEW EVENTS //////////////////////////////////////////////////////////////
  events:
    'change .ticketGroupSeatLowOrHigh': 'onTicketGroupChange'
    'change .ticketGroupSeatOrder':     'onTicketGroupChange'
    'keyup .ticketGroupLowSeat':        'onTicketGroupChange'

  onTicketGroupChange: (e) ->

    # Sell Take, Sell Hold, Sell Cart.
    if (@ticketGroupModel.get('_heldSeats'))
      return

    # PO, Consignment.
    else if (@action == C.BuySellActions.PO || @action == C.BuySellActions.Consignment)
      @ticketGroupModel.calculateSeatsArray()
      return

    # Normal Buy, Sell, BuySell.
    else

      # Quantity.
      quantity = @ticketGroupModel.get('_quantity')

      # Low or High.
      seatsHighOrLow = @$('.ticketGroupSeatLowOrHigh').select2('val')
      if (@action == C.BuySellActions.Buy || @action == C.BuySellActions.BuySell || @action == C.BuySellActions.AffiliateSell)
        seatsHighOrLow = 'high'

      # Seat order.
      seatOrder = C.SeatOrder.Consecutive
      if (@$('.ticketGroupSeatOrder').length)
        seatOrder = @$('.ticketGroupSeatOrder').select2('val')
      @ticketGroupModel.set('_seatOrder', seatOrder)

      # Seat selection. (Has low seat been overridden?)
      lowSeat = parseInt(@$('.ticketGroupLowSeat').val())
      # If no user provided lowSeat
      if (isNaN(lowSeat))
        # If seat numbers exist on ticket group.
        if (@ticketGroupModel.hasSeatNumbers())
          availableSeatArray = @ticketGroupModel.availableSeatsHelper()
          if (seatsHighOrLow == 'low')
            seats = _.first(availableSeatArray, quantity)
          else
            seats = _.last(availableSeatArray, quantity)
          if (seats[0]) # It will be null if no seats on TG.
            lowSeat = parseInt(seats[0])
          @$('.ticketGroupSeats').text(
            App.Utils.seatsToSeatRange(seats)
          )

      if (isNaN(lowSeat))
        # None provided and none pre-existing.
        @ticketGroupModel.unset('_lowSeat')
        @$('.ticketGroupLowSeat').val('')
        @$('.ticketGroupHighSeat').val('')
      else
        multiplier = 1
        if (seatOrder == C.SeatOrder.OddEven)
          multiplier = 2
        highSeat = lowSeat + (quantity - 1) * multiplier
        @$('.ticketGroupLowSeat').val(lowSeat)
        @$('.ticketGroupHighSeat').val(highSeat)
        @ticketGroupModel.set('_lowSeat', lowSeat)

      @ticketGroupModel.calculateSeatsArray()

  getLowSeat: () ->
    return @ticketGroupModel.get('_lowSeat')

  getSeatOrder: () ->
    return @ticketGroupModel.get('_seatOrder')

  getTicketGroupModel: () ->
    return @ticketGroupModel
