template = require('./existing_draft.ejs');

module.exports = App.Views.PO.ExistingDraft = Backbone.View.extend({

  className: 'modal hide fade',

  initialize: function(opts) {
    var options = opts || {};
  },

  events: {
    'click [data-choice="yes"]': 'retrieveDraft',
    'click [data-choice="no"]': 'destroyDraft'
  },

  template: template,

  render: function() {
    var that = this;

    $(this.el).html(this.template());

    // STANDARDIZED MODAL
    this.$el.modal('show').on('hidden', function() {
      that.remove();
    });

  },

  destroyDraft: function() {
    this.trigger('destroy-draft');

    this.$el.modal('hide');
  },

  retrieveDraft: function () {
    this.trigger('retrieve-draft');

    this.$el.modal('hide');
  }

});