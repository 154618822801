App.Controllers.Base.BaseController = require('./base/base_controller.coffee')

module.exports = class App.Controllers.EvoQuoteController extends App.Controllers.Base.BaseController

  _evoQuoteCollection = null
  _evoQuoteView = null
  _$container = null
  _xhr = null

  routeIndex: ($container, encodedFilterParams) ->
    @displayIndex({
      $container
      shouldReset: true
      encodedFilterParams
    })

  resetIndex: () ->
    App.router.navigate('/evoquotes') # Don't trigger.
    @displayIndex({
      $container: _$container
      shouldReset: true
    })

  routeEvoQuoteNew: ($container) ->
    App.router.navigate('/evoquote/new') # Don't trigger.
    newView = new App.Views.EvoQuote.New({ $container })

  routeEvoQuoteShow: ($container, id) ->
    model = new App.Models.V3.EvoQuote({ id }, {
        mapping: C.Mappings.Direct.EvoQuotes.EvoQuote
      })
    evoQuoteShow = new App.Views.EvoQuote.Show()
    loadingOverlay = new App.Views.Shared.LoadingView()
    loadingOverlay.render()

    model.fetchPromise()
    .then (fetchedModel) =>
      $container.html(evoQuoteShow.render({model: fetchedModel}))
      loadingOverlay.remove()
    .fail (errors) =>
      errorModal = new App.Views.Shared.BasicModal({
        header: 'Error fetching evoQuote.'
        message: errors.message
      })
    .done()

  filterIndex: () ->
    encodedFilterParams = _evoQuoteView.getEncodedFilterParams()
    App.router.navigate('/evoquotes/filter/' + encodedFilterParams) # Don't trigger
    @displayIndex({
      $container: _$container
      shouldReset: false
      encodedFilterParams
    })

  displayIndex: (options) ->
    {
      $container
      shouldReset
      encodedFilterParams
    } = options
    _$container = $container

    if (_xhr)
      _xhr.abort()

    if (!_evoQuoteCollection || shouldReset)
      _evoQuoteCollection = new App.Collections.V3.EvoQuotes(null, {
        mapping: C.Mappings.Direct.EvoQuotes.EvoQuote
      })

    if (!_evoQuoteView || shouldReset)
      _evoQuoteView = new App.Views.EvoQuote.List({
        $container: _$container
        collection: _evoQuoteCollection
      })

    if (encodedFilterParams)
      params = App.Utils.decodeFilterParams(encodedFilterParams)
      _evoQuoteView.reflectFilterParams(params)

    params = _evoQuoteView.getFilterParams()

    # View is bound to collection reset event.
    _xhr = _evoQuoteCollection.fetch({
      data: params
      reset: true
    })
    App.Utils.requestKiller.add(_xhr)
