template = require('./send_payment.ejs')

App.Views.EvoPay.ModalAbstract = require('./evopay_modal_abstract_view.coffee')

parent = App.Views.EvoPay.ModalAbstract.prototype
module.exports = App.Views.EvoPay.SendPaymentModal = App.Views.EvoPay.ModalAbstract.extend

  form_template: template

  initialize: (options = {}) ->
   @title  = "Send A Payment"
   @button = "Send Payment"
   @type   = "transfer"
   @super_initialize(options)

  afterRender: () ->
    @initializeAutocomplete()

  childSerialize: (formData) ->
    delete formData.recipient_autocomplete
    return formData

  initializeAutocomplete: () ->
    App.Utils.autocomplete({
      selector: '#recipient_autocomplete'
      types: ['offices']
      select: (event, ui) =>
        item = ui.item
        @$('[name=recipient_id]').val(item.id)
    })