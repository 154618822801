App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V3.ShipmentType = require('../../models/v3/shipment_type_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V3.ShipmentTypes = App.Collections.Base.BaseCollection.extend

  url:   '/api_direct/v9/settings/shipping'

  model: App.Models.V3.ShipmentType

  initialize: (models, options = {}) ->
    parent.initialize.call(@, models, options)
    {
      @isPickupOnly
      @isPhysicalOnly
      @isEticketOnly
      @isFlashSeatOnly
      @isTMMobileOnly
      @isGuestlistOnly
      @isPaperlessOnly
      @isOfflineAllowed
      @isTBDAllowed
      @action
    } = options

  comparator: (shipmentTypeModel) ->
    return +(shipmentTypeModel.get('_position'))

  parse: (response) ->
    json = response.settings
    return json

  findByType: (type) ->
    return @find((shipmentTypeModel) ->
      return (shipmentTypeModel.get('_provider') == type)
    )

  toSelectOptions: () ->
    options = []

    @each((shipmentTypeModel) =>
      provider = shipmentTypeModel.get('_provider')
      if (provider == C.Offline)
        if (@isOfflineAllowed)
          options.push(shipmentTypeModel.toSelectOption())
      else if (provider == C.TBDShipment)
        if (@isTBDAllowed)
          options.push(shipmentTypeModel.toSelectOption())
      else
        options.push(shipmentTypeModel.toSelectOption())
    )

    # Leave only one FedEx element.  They all have id of C.FedEx
    options = _.uniq(options, (el) ->
      return el.id
    )

    if (@isPhysicalOnly)
      if(@isPickupOnly)
        options = _.filter(options, (option) ->
          return option.id == C.LocalPickup
        )
      else
        action = @action
        # For all physical tickets we always want to default to FedEx so we _.sortBy
        options = _.sortBy(_.filter(options, (option) ->
          if (SESSION.office.get('_isPOS') && action == C.BuySellActions.Sell)
            return [C.FedEx, C.ProvidedAirbill, C.Courier, C.LocalPickup, C.WillCall].includes(option.id)
          else
            return (option.id == C.FedEx || option.id == C.ProvidedAirbill)
        ), (option) => option.id == C.FedEx)

    if (@isEticketOnly)
      options = _.filter(options, (option) ->
        return option.id == C.Eticket
      )
    if (@isFlashSeatOnly)
      options = _.filter(options, (option) ->
        return option.id == C.FlashSeats
      )
    if (@isTMMobileOnly)
      options = _.filter(options, (option) ->
        return option.id == C.TMMobile
      )
    if (@isGuestlistOnly)
      options = _.filter(options, (option) ->
        return option.id == C.GuestList
      )
    if (@isPaperlessOnly)
      options = _.filter(options, (option) ->
        return option.id == C.Paperless
      )


    if (SESSION.role.cannot(App.Access.Resources.Shipping.ShipWithFedEx))
      options = _.filter(options, (option) ->
        return option.id != C.FedEx
      )

    # Sort based on user defined 'position's (settings app).
    options = options.sort((a, b) ->
      return a.position - b.position
    )

    return options
