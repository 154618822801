template = require('./modal.ejs')
App.Views.Base.BaseView = require('./base/base_view.coffee')

module.exports = App.Views.Export.Modal = App.Views.Base.BaseView.extend

  className:  'modal hide fade export_inventory'

  template:   template

  initialize: ->
    @model         = new App.Models.InventoryExport()
    @render()

  render: ->
    template = template
    @$el.html template
    @loadingButton = new App.Utils.LoadingButton @$("#export")
    # STANDARDIZED MODAL
    @$el.modal('show').on 'hidden', => @remove()

  events:
    'click .modal-body .btn' : 'onBtnClick'
    'click #export'          : 'onExportBtnClick'

  onBtnClick: (e) ->
    $button = $(e.currentTarget)
    $button.siblings().removeClass('active')
    $button.addClass('active')

  onExportBtnClick: ->
    _.each @$('[data-name]'), _.bind(@parseInput, @)
    @loadingButton.startLoad()
    @download
      fileUrl:  @model.exportUrl()
      callback: @loadingButton.stopLoad()

  parseInput: (input) ->
    $input = $(input)
    name   = $input.data('name')
    val    = $input.find('.active').data('value')   # for all the btns
    val    = $input.val() unless val                # for all the inputs
    if rgx = name.match(/marking_rule_(.*)/)
      nest_name   = rgx.pop() #method as example
      name        = 'marking_rule'
      hash        = @model.get(name)#grab existing hash
      hash[nest_name] = val #set updated value
      val = hash
    @model.set(name, val) if val
