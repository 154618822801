module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<table class="table table-bordered table-striped table-hover data-table">\n  <thead>\n    <tr>\n      <th>Order Id</th>\n      <th>Quantity</th>\n      <th>Section</th>\n      <th>Row</th>\n      <th>Seats</th>\n      <th>Price</th>\n      <th>Customer</th>\n      <th>Created By</th>\n      <th>Created At</th>\n    </tr>\n  </thead>\n  <tbody>\n<%\n_.each(soldOrderItemsOrWastedTicketGroups, function(orderItemOrTG) {\n  if (orderItemOrTG.orderId) {\n    // SOLD ITEMS\n    var orderItem = orderItemOrTG\n%>\n  <tr data-order-item="<%= orderItem.id %>">\n    <td>\n      <a href="/order/<%= orderItem.orderId %>">\n        <%= orderItem.orderId %>\n      </a>\n    </td>\n    <td><%= orderItem.quantity %></td>\n    <td><%= orderItem.section %></td>\n    <td><%= orderItem.row %></td>\n    <td><%= App.Utils.displaySeatArray(orderItem.seats) %></td>\n    <td><%= App.Utils.valueToCurrency(orderItem.price) %></td>\n    <td><%= orderItem.customer %></td>\n    <td><%= orderItem.createdBy %></td>\n    <td><%= App.Utils.makeTimestampHuman(orderItem.createdAt, C.DateFormats.TableDateWithTime) %></td>\n  </tr>\n<%\n  } else {\n    var ticketGroup = orderItemOrTG;\n%>\n  <tr>\n    <td>WASTED</td>\n    <td><%= ticketGroup.quantity %></td>\n    <td><%= ticketGroup.section %></td>\n    <td><%= ticketGroup.row %></td>\n    <td><%= ticketGroup.seatRange %></td>\n    <td><%= App.Utils.valueToCurrency(ticketGroup.priceWholesale) %></td>\n    <td></td>\n    <td></td>\n    <td><%= App.Utils.makeTimestampHuman(ticketGroup.createdAt, C.DateFormats.TableDateWithTime) %></td>\n  </tr>\n<%\n  }\n});\n%>\n  </tbody>\n</table>\n', __filename = "js/views/ticket_groups/sold_items_table.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<table class="table table-bordered table-striped table-hover data-table">\n  <thead>\n    <tr>\n      <th>Order Id</th>\n      <th>Quantity</th>\n      <th>Section</th>\n      <th>Row</th>\n      <th>Seats</th>\n      <th>Price</th>\n      <th>Customer</th>\n      <th>Created By</th>\n      <th>Created At</th>\n    </tr>\n  </thead>\n  <tbody>\n');
            __line = 16;
            _.each(soldOrderItemsOrWastedTicketGroups, function(orderItemOrTG) {
                if (orderItemOrTG.orderId) {
                    var orderItem = orderItemOrTG;
                    __line = 21;
                    __append('\n  <tr data-order-item="');
                    __line = 22;
                    __append(escapeFn(orderItem.id));
                    __append('">\n    <td>\n      <a href="/order/');
                    __line = 24;
                    __append(escapeFn(orderItem.orderId));
                    __append('">\n        ');
                    __line = 25;
                    __append(escapeFn(orderItem.orderId));
                    __append("\n      </a>\n    </td>\n    <td>");
                    __line = 28;
                    __append(escapeFn(orderItem.quantity));
                    __append("</td>\n    <td>");
                    __line = 29;
                    __append(escapeFn(orderItem.section));
                    __append("</td>\n    <td>");
                    __line = 30;
                    __append(escapeFn(orderItem.row));
                    __append("</td>\n    <td>");
                    __line = 31;
                    __append(escapeFn(App.Utils.displaySeatArray(orderItem.seats)));
                    __append("</td>\n    <td>");
                    __line = 32;
                    __append(escapeFn(App.Utils.valueToCurrency(orderItem.price)));
                    __append("</td>\n    <td>");
                    __line = 33;
                    __append(escapeFn(orderItem.customer));
                    __append("</td>\n    <td>");
                    __line = 34;
                    __append(escapeFn(orderItem.createdBy));
                    __append("</td>\n    <td>");
                    __line = 35;
                    __append(escapeFn(App.Utils.makeTimestampHuman(orderItem.createdAt, C.DateFormats.TableDateWithTime)));
                    __append("</td>\n  </tr>\n");
                    __line = 37;
                } else {
                    var ticketGroup = orderItemOrTG;
                    __line = 40;
                    __append("\n  <tr>\n    <td>WASTED</td>\n    <td>");
                    __line = 43;
                    __append(escapeFn(ticketGroup.quantity));
                    __append("</td>\n    <td>");
                    __line = 44;
                    __append(escapeFn(ticketGroup.section));
                    __append("</td>\n    <td>");
                    __line = 45;
                    __append(escapeFn(ticketGroup.row));
                    __append("</td>\n    <td>");
                    __line = 46;
                    __append(escapeFn(ticketGroup.seatRange));
                    __append("</td>\n    <td>");
                    __line = 47;
                    __append(escapeFn(App.Utils.valueToCurrency(ticketGroup.priceWholesale)));
                    __append("</td>\n    <td></td>\n    <td></td>\n    <td>");
                    __line = 50;
                    __append(escapeFn(App.Utils.makeTimestampHuman(ticketGroup.createdAt, C.DateFormats.TableDateWithTime)));
                    __append("</td>\n  </tr>\n");
                    __line = 52;
                }
            });
            __line = 55;
            __append("\n  </tbody>\n</table>\n");
            __line = 58;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}