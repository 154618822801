App.Models.Base.V3BaseModel = require('../base/v3_base_model.coffee')

parent = App.Models.Base.V3BaseModel.prototype
module.exports = App.Models.V3.Configuration = App.Models.Base.V3BaseModel.extend

  initialize: (attributes, options) ->
    parent.initialize.call(@, attributes, options)

  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.Direct.Events.Configuration)
      attrs._fanVenuesKey = json.fanVenuesKey
      attrs._id = json.id
      attrs._name = json.name
      attrs._seatingChartUrlLarge = json.seating_chart?.large
      attrs._seatingChartUrlMedium = json.seating_chart?.medium
      attrs._ticketUtilsId = json.ticket_utils_id
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.EventEndpoint.Configuration)
      attrs._fanVenuesKey = json.fanVenuesKey
      attrs._id = json.id
      attrs._name = json.name
      attrs._seatingChartUrlLarge = json.seating_chart?.large
      attrs._seatingChartUrlMedium = json.seating_chart?.medium
      attrs._ticketUtilsId = json.ticket_utils_id
      attrs._url = json.url
      # COMPLETE - KEEP THIS COMMENT

    @set(attrs)

# PRESENTATION /////////////////////////////////////////////////////////////
  toPresenterJSON: () ->
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      seatingChartUrlLarge: @get('_seatingChartUrlLarge')
      seatingChartUrlMedium: @get('_seatingChartUrlMedium')

    })
    return presented
#///////////////////////////////////////////////////////////////////////////