App.Controllers.Base.BaseController = require('./base/base_controller.coffee')

module.exports = class App.Controllers.BuySellController extends App.Controllers.Base.BaseController

  buy: (options = {}) ->
    {
      eventModel
      filters
      ticketGroupModel
    } = options
    action = C.BuySellActions.Buy

    buyOfficeId = ticketGroupModel.get('_office').id
    buyOffice = new App.Models.V3.Office({
      id: buyOfficeId
    }, {
      mapping: C.Mappings.Direct.Offices.Office
    })
    fixedPatron = buyOffice.toPatron()

    you = new App.Models.V3.Office({
      id: SESSION.office_id
    }, {
      mapping: C.Mappings.Direct.Offices.Office
    })
    you = you.toPatron()

    @renderWizardForOneTG({
      action
      eventModel
      filters
      fixedPatron
      ticketGroupModel
      you
    })

  buyPO: (options = {}) ->
    {
      eventsBeingPurchased
      isConsignment
    } = options
    action = C.BuySellActions.PO
    if (isConsignment)
      action = C.BuySellActions.Consignment

    you = new App.Models.V3.Office({
      id: SESSION.office_id
    }, {
      mapping: C.Mappings.Direct.Offices.Office
    })
    you = you.toPatron()

    @renderWizardForMultipleTGs({
      action
      eventCollection: eventsBeingPurchased
      you
    })

  sell: (options = {}) ->
    {
      eventModel
      filters
      ticketGroupModel
    } = options
    action = C.BuySellActions.Sell

    you = new App.Models.V3.Office({
      id: SESSION.office_id
    }, {
      mapping: C.Mappings.Direct.Offices.Office
    })
    you = you.toPatron()

    @renderWizardForOneTG({
      action
      eventModel
      filters
      ticketGroupModel
      you
    })

  sellHoldOrTake: (options = {}) ->
    {
      heldForType
      heldForId
      heldPrice
      heldSeats
      quantity
      ticketGroupId
    } = options

    action = C.BuySellActions.Sell
    promises = []

    you = new App.Models.V3.Office({
      id: SESSION.office_id
    }, {
      mapping: C.Mappings.Direct.Offices.Office
    })
    you = you.toPatron()

    ticketGroupModel = new App.Models.V3.TicketGroup({
      id: ticketGroupId
    }, {
      mapping: C.Mappings.Direct.TicketGroups.TicketGroup
    })
    promises.push(ticketGroupModel.fetchPromise())

    if (heldForType == 'Client')
      clientModel = new App.Models.V3.Client({
        id: heldForId
      }, {
        mapping: C.Mappings.Direct.Clients.Client
      })
      promises.push(clientModel.fetchPromise())
    else
      officeModel = new App.Models.V3.Office({
        id: heldForId
      }, {
        mapping: C.Mappings.Direct.Offices.Office
      })
      promises.push(officeModel.fetchPromise())

    Q.all(promises)
    .spread (ticketGroupModel, clientOrOfficeModel) =>

      # Fix ticketGroup seats so we use heldSeats, not the available ones.
      sortedSeats = heldSeats.sort((a, b) -> return a - b)

      seatOrder = C.SeatOrder.Consecutive
      if (App.Utils.isSeatArrayOddEven(sortedSeats))
        seatOrder = C.SeatOrder.OddEven

      ticketGroupModel.set({
        _heldSeats: sortedSeats
        _heldPrice: heldPrice
        _lowSeat: sortedSeats[0]
        _quantity: sortedSeats.length
        _seatOrder: seatOrder
        _ticketList: null # _ticketList is totally wrong info when there's held tickets.
                          # Remove it so seatRange() functions properly.
      })
      ticketGroupModel.calculateSeatsArray()

      selectablePatron = clientOrOfficeModel.toPatron()

      eventModel = new App.Models.V3.Event({
        id: ticketGroupModel.get('_event').id
      }, {
        mapping: C.Mappings.Direct.Events.Event
      })
      eventModel.fetchPromise()
      .then (eventModel) =>

        @renderWizardForOneTG({
          action
          eventModel
          selectablePatron
          ticketGroupModel
          you
        })

    .fail (errors) ->
      errorModal = new App.Views.Shared.BasicModal({
        header: 'Error fetching sell information.'
        message: errors.message
      })
      console.error(errors)
    .done()

  showProcessingPurchaseModal: () ->
    @processingPurchaseModal = new App.Views.Shared.BasicModal({
      header: 'DO NOT CLOSE TAB OR REFRESH!'
      message: "The seller of these tickets is using order integration to ensure the availability of your tickets. It can sometimes take up to a minute to finalize this process and reserve the tickets from the seller's point-of-sale. Please be patient while we finalize your order."
      bodyStyles: "max-width: 670px"
      icon: 'fa-circle-check'
      hideButtons: true
      preventClose: true
      withLoading: true
    })

  hideProcessingPurchaseModal: () ->
    if ( @processingPurchaseModal )
      @processingPurchaseModal.remove()


  sellCart: (options = {}) ->
    {
      cartModel
    } = options

    action = C.BuySellActions.Sell
    eventCollection = cartModel.exportForCheckout()
    you = new App.Models.V3.Office({
      id: SESSION.office_id
    }, {
      mapping: C.Mappings.Direct.Offices.Office
    })
    you = you.toPatron()

    @renderWizardForMultipleTGs({
      action
      eventCollection
      isCart: true
      you
    })

  affiliateSell: (options = {}) ->
    {
      eventModel
      filters
      ticketGroupModel
    } = options
    action = C.BuySellActions.AffiliateSell
    @doBuySell({
      action
      eventModel
      filters
      ticketGroupModel
    })

  buySell: (options = {}) ->
    {
      eventModel
      filters
      ticketGroupModel
    } = options
    action = C.BuySellActions.BuySell
    @doBuySell({
      action
      eventModel
      filters
      ticketGroupModel
    })

  doBuySell: (options = {}) ->
    {
      action
      eventModel
      filters
      ticketGroupModel
    } = options

    buyOfficeId = ticketGroupModel.get('_office').id
    buyOffice = new App.Models.V3.Office({
      id: buyOfficeId
    }, {
      mapping: C.Mappings.Direct.Offices.Office
    })
    fixedPatron = buyOffice.toPatron()

    you = new App.Models.V3.Office({
      id: SESSION.office_id
    }, {
      mapping: C.Mappings.Direct.Offices.Office
    })
    you = you.toPatron()

    @renderWizardForOneTG({
      action
      eventModel
      filters
      fixedPatron
      ticketGroupModel
      you
    })

  # @param filters [Object] The filters from a PourOver.Collection
  # @param ticketGroupModel [child] A Backbone model for the Ticket Group
  # @returns [Object] The filters that directly apply to the ticketGroupModel
  getRelevantFilters: (filters = {}, ticketGroupModel) ->
    modelIndex = undefined
    relevantFilters = {}

    for own filterName, filterValue of filters
      modelIndex ||= filterValue.collection.items.findIndex(
        (item) -> item._id == ticketGroupModel.id
      )

      if filterValue.current_query &&
         filterValue.current_query.stack &&
         filterValue.current_query.cids.indexOf(modelIndex) > -1
        relevantFilters[filterName] = filterValue

    relevantFilters

  renderWizardForMultipleTGs: (options = {}) ->
    {
      action
      eventCollection
      isCart
      you
    } = options
    App.router.resetCurrentState()

    buyOrder = new App.Models.V3.Order(null, {
      mapping: C.Mappings.Direct.Order.Order
    })

    sellOrder = new App.Models.V3.Order(null, {
      mapping: C.Mappings.Direct.Order.Order
    })

    $container = App.router.getContentContainer()
    @view = new App.Views.BuySell.BuySellWizard({
      $container
      action
      buyOrder
      eventCollection
      isCart
      sellOrder
      you
    })

  renderWizardForOneTG: (options = {}) ->
    {
      action
      eventModel
      filters
      fixedPatron
      ticketGroupModel
      selectablePatron
      you
    } = options
    App.router.resetCurrentState()

    buyOrder = new App.Models.V3.Order(null, {
      mapping: C.Mappings.Direct.Order.Order
    })

    sellOrder = new App.Models.V3.Order(null, {
      mapping: C.Mappings.Direct.Order.Order
    })

    #ticketGroupModel.original = ticketGroupModel.clone()
    ticketGroupCollection = new App.Collections.V3.TicketGroups()
    ticketGroupCollection.add(ticketGroupModel)

    eventModel.set('_ticketGroups', ticketGroupCollection)
    eventCollection = new App.Collections.V3.Events()
    eventCollection.add(eventModel)

    $container = App.router.getContentContainer()
    relevantFilters = @getRelevantFilters(filters, ticketGroupModel)
    @view = new App.Views.BuySell.BuySellWizard({
      $container
      action
      buyOrder
      eventCollection
      fixedPatron
      selectablePatron
      sellOrder
      you
      filters: relevantFilters
    })

  submitOrderPromise: (options = {}) ->
    {
      action
      autoPend
      buyOrder
      eventCollection
      fixedPatron
      fixedPayment
      holdForFraud
      isCart
      selectablePatron
      selectablePayment
      sellOrder
      shipment
      you
      taxQuote
    } = options
    deferred = Q.defer()

    @view.startLoad()

    if (action == C.BuySellActions.Buy || action == C.BuySellActions.BuySell || action == C.BuySellActions.AffiliateSell)
      buyer = you
      buyOrder.set('_items', eventCollection.toOrderItems({ price: 'wholesale' }))
      buyOrder.set('_shipment', shipment)
      buyOrder.set('_buyer', buyer)
      buyOrder.set('_buyerReference', buyOrder.get('_reference'))
      buyOrder.set('_seller', fixedPatron)
      buyOrder.set('_payment', fixedPayment)
      buyOrder.purpose = C.Purposes.SubmitOrder
      order = buyOrder

    if (action == C.BuySellActions.Sell || action == C.BuySellActions.BuySell || action == C.BuySellActions.AffiliateSell)
      seller = you
      sellOrder.set('_items', eventCollection.toOrderItems({ price: 'sale' }))
      sellOrder.set('_shipment', shipment)
      sellOrder.set('_seller', seller)
      sellOrder.set('_sellerReference', sellOrder.get('_reference'))
      sellOrder.set('_buyer', selectablePatron)
      sellOrder.set('_payment', selectablePayment)
      sellOrder.set('_buyOrder', buyOrder)
      if (isCart)
        sellOrder.set('_isCart', isCart)
      sellOrder.purpose = C.Purposes.SubmitOrder
      order = sellOrder

    if (action == C.BuySellActions.PO || action == C.BuySellActions.Consignment)
      buyer = you
      buyOrder.set('_items', eventCollection.toOrderItems({
        price: 'cost'
        isPO: true
      }))
      buyOrder.set('_shipment', shipment)
      buyOrder.set('_buyer', buyer)
      buyOrder.set('_buyerReference', buyOrder.get('_reference'))
      buyOrder.set('_seller', selectablePatron)
      buyOrder.set('_payment', selectablePayment)
      if (action == C.BuySellActions.Consignment)
        buyOrder.set('_isConsignment', true)
      buyOrder.purpose = C.Purposes.SubmitPO
      order = buyOrder

    if taxQuote?.get('_taxSignature')
      order.set('_taxSignature', taxQuote.get('_taxSignature'))

    order.action = action
    if (order.isValid(true))

      App.ErrorManager.ignoreNext(422)
      order.savePromise()
      .then (model) =>

        App.ErrorManager.resetTolerance(422)

        if (action == C.BuySellActions.PO || action == C.BuySellActions.Consignment)
          message = new App.Views.Shared.BasicModal({
            header: 'Purchase Order creation in progress.'
            message: "This process has been moved to the background.<br/>
                      You can continue working while we process and save the order information.<br/>
                      Check the top navigation bar for updates on this process."
            icon: 'fa-circle-check'
          })
          @view.stopLoad()
          eventCollection.store.clear()
          if (action == C.BuySellActions.Consignment)
            App.router.purchaseOrderConsignment()
          else
            App.router.purchaseOrder()

        else

          if (isCart)
            SESSION.carts.reset()

          if (holdForFraud)
            @loadOrder(order, deferred, autoPend)
          else
            if (SESSION.role.can(App.Access.Resources.Orders.BuySell.ShouldAutoAccept))
              order.acceptPromise()
              .then (model) =>
                @possiblyPendToSellerPromise({
                  autoPend
                  order
                })
                .then (model) =>
                  @view.stopLoad()
                  deferred.resolve(order)
                .fail (errors) =>
                  @view.stopLoad()
                  deferred.reject(new Error("The order was created but could not be auto-accepted."))
            else
              @loadOrder(order, deferred, autoPend)


      .fail (errors) =>
        @view.stopLoad()
        App.ErrorManager.resetTolerance(422)
        deferred.reject(errors)
      .done()

    else
      # Order validation failed.
      @view.stopLoad()
      errors = order.errors
      deferred.reject(errors)

    return deferred.promise


  loadOrder: (order, deferred, autoPend) ->
    # Gets us the child_orders for pendToSeller.
    order.fetchPromise()
    .then (model) =>

      @possiblyPendToSellerPromise({
        autoPend
        order
      })
      .then (model) =>
        @view.stopLoad()
        deferred.resolve(order)

  possiblyPendToSellerPromise: (options = {}) ->
    {
      autoPend
      order
    } = options
    if (autoPend)
      deferred = Q.defer()

      buyOrderId = order.get('child_orders')[0]
      childBuyOrder = new App.Models.V3.Order({
        id: buyOrderId
      }, {
        mapping: C.Mappings.Direct.Order.Order
      })
      childBuyOrder.fetchPromise()
      .then (model) =>
        orderItem = childBuyOrder.get('_orderItems').first()
        pendData = [{
          id: orderItem.id
          cost: App.Utils.roundCurrency(orderItem.get('price'))
        }]
        params = {
          order_item_links: pendData
          pos: true
          seller_type: 'Office'
          note: 'AUTOPEND DURING SELL'
        }
        $.post("/api_direct/v9/orders/#{ buyOrderId }/pend_to_seller", JSON.stringify(params) )
        .done (data) =>
          @redirectToOrder(order)
          deferred.resolve(order)
        .error (jqXHR, textStatus, errorThrown) =>
          errorMessage = {
            header: 'Error Pending to Seller'
            message: jqXHR.responseText
          }
          errorModal = new App.Views.Shared.BasicModal(errorMessage)
          @redirectToOrder(order)
          deferred.resolve(order)

      return deferred.promise
    else
      @redirectToOrder(order)
      return Q(order)

  redirectToOrder: (order) ->
    @view.stopLoad()
    window.App.ErrorManager.resetTolerance(422)
    App.router.navigate("/order/#{ order.id }", { trigger: true })
