App.Models.TicketGroup = require('../models/ticket_group_model');

module.exports = App.Collections.TicketGroups = Backbone.Collection.extend({

  model: App.Models.TicketGroup,

  useMassIndexEndpoint: false,

  url: function() {

    // MASS EDIT
    if (this.useMassIndexEndpoint) {
      return '/api_direct/v9/ticket_groups/mass_index';

    // DEFAULT TICKET GROUP BEHAVIOR
    } else {
      return '/api_direct/v9/ticket_groups';

    }
  },

  initialize: function (models, options) {
    var options     = options || {};
    this.eventId    = options.eventId; //delete when fetch is fixed
    this.useMassIndexEndpoint = options.useMassIndexEndpoint || false;
  },

  toPresenterJSON: function(options) {
    return _.map(this.models, function(model) {
      return model.toPresenterJSON(options);
    });
  },

  fetchFancy: function(FparkTtix, myOrAllTickets, FsoldTavail, params){
    var that, url;
    that = this
    url =  "/api_direct/v9/events/"+this.eventId+"/";
    url += FparkTtix   ? "tickets"  : "parking";
    if (myOrAllTickets == 'my')
      url += '/my'
    url += params      ? params     : "";

    return $.get(url, function(data){
        _.each(data, function (fetchedTicketGroup) {
          var ticketGroup = new App.Models.TicketGroup(fetchedTicketGroup);
          ticketGroup.set('eventId', that.eventId);
          that.add(ticketGroup);
        });
      });
  },

  fetchWastedAndSold: function(FparkTtix){
    var completed = $.Deferred();
    $.when(this.fetchSold(FparkTtix),this.fetchWasted(FparkTtix)).then(function(){
      completed.resolve();
    }, function(){completed.reject()});
    return completed;
  },

  fetchWasted: function(FparkTtix){
    var completed,that;
    completed = $.Deferred();
    that      = this;
    $.when(this.fetchFancy(FparkTtix, false, false, "?include_unavailable=true&show_past=true")).then(function(){
      that   = that.remove(that.filter(function(tg){
        //this is interesting because tgs from sold could be here already, but they have order
        //so its fine
        if(tg.order){ return false; }
        tg.wasted = true;
        return tg.get("ticket_states") && !_.has(tg.get("ticket_states"), "wasted");
      }));
      completed.resolve();
    }, function(){completed.reject()});
    return completed;
  },

  fetchSold:  function(FparkTtix) {
    var that, ticketGroup, completed, orders;
    that = this;
    completed = $.Deferred();
    //need to go in through orders to get these, and tack on the order as well.
    orders = new App.Collections.Orders(null, {ignoreShipments: true});
    orders.showList = true;
    orders.fetch({
      data: {orders: {sold: true, event_id: this.eventId}}, success: function () {
        if (orders.length > 0) {
          orders.each(function (order) {
            _.each(order.get('items'), function (order_item) {
              var add_to_parking, add_to_sold;
              add_to_parking = order_item.format === "parking" && !FparkTtix;
              add_to_sold = order_item.format !== "parking" && FparkTtix;//parking still needs to be implemented on pos.  we need ticket_groups_format
              ticketGroup = new App.Models.TicketGroup(order_item.ticket_group);
              ticketGroup.order = order;//switcharoo
              order.set("seats", order_item.ticket_group_seats);
              order.unset("ticketGroup");

              (add_to_parking || add_to_sold) && that.add(ticketGroup)
            });
          });
        }
        completed.resolve();
      }
    });
    return completed;
  }

});