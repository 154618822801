App.Collections.Base.BaseCollection = require('../base/base_collection.coffee')
App.Models.V3.RestrictedEvopayOrders = require('../../models/v3/restricted_evopay_orders_model.coffee')

parent = App.Collections.Base.BaseCollection.prototype
module.exports = App.Collections.V3.RestrictedEvopayOrders = App.Collections.Base.BaseCollection.extend

  model: App.Models.V3.RestrictedEvopayOrders

  url: () ->
    return "/api_direct/v9/evopay_managers/non_withdrawable_payments"

  initialize: (models, options = {}) ->
    parent.initialize.call(@, models, options)

  parse: (json) ->
    return json.payments