template = require('./consignment_accept_modal.ejs')
App.Views.Base.BaseView = require('../base/base_view.coffee')

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.Order.ConsignmentAcceptModal = Backbone.View.extend

  template:  template

  className: 'modal hide fade'

  initialize: (options = {}) ->
    {
      @v3OrderModel
      @overviewView
    } = options
    @render()

  render: () ->
    data = {}
    @$el.html(
      @template(data)
    )
    # STANDARDIZED MODAL
    @$el.modal('show').on('hidden', () =>
      @remove()
    )
    @loadingBox = new App.Utils.LoadingBox(@$el)
    @formErrors = new App.Utils.FormErrorManager(@$('.formErrors'))

  events:
    'click #acceptMoveButton': 'onAcceptMoveButtonClick'
    'click #acceptRemoveButton': 'onAcceptRemoveButtonClick'

  onAcceptMoveButtonClick:  (e) ->
    @accept(true)

  onAcceptRemoveButtonClick: (e) ->
    @accept(false)

  accept: (moveUnsoldTickets) ->
    @loadingBox.startLoad()
    @v3OrderModel.acceptPromise({ moveUnsoldTickets })
    .then (model) =>
      @overviewView.refresh()
      @loadingBox.stopLoad()
      @$el.modal('hide')
    .fail (errors) =>
      @formErrors.show({
        title: 'Could not accept order.'
        errors
      })
      @loadingBox.stopLoad()
    .done()