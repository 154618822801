module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<!-- Allowed to be wrapped with modal class since not a true view. -->\n<!-- STANDARDIZED APPROVED -->\n<div id="notes_modal" class="modal hide fade">\n  <div class="modal-header">\n    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n    <span class="icon"><i class="fa-solid fa-file-alt"></i></span>\n    <h3 class="modal-title">Notes</h3>\n  </div>\n  <div class="modal-body">\n    <%\nif (ticket_group_external_notes) {\n%>\n    <h5>Public</h5>\n    <p><%= ticket_group_external_notes %></p>\n    <%\n}\n\nif (ticket_group_internal_notes) {\n%>\n    <h5>Private</h5>\n    <p><%= ticket_group_internal_notes %></p>\n    <%\n}\n\nif (ticket_group_broker_notes) {\n%>\n    <h5>Broker</h5>\n    <p><%= ticket_group_broker_notes %></p>\n    <%\n}\n%>\n\n  </div>\n  <div class="modal-footer">\n    <a href="#" class="btn" data-dismiss="modal">Close</a>\n  </div>\n</div>\n', __filename = "js/views/inventory/notes_modal.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<!-- Allowed to be wrapped with modal class since not a true view. -->\n<!-- STANDARDIZED APPROVED -->\n<div id="notes_modal" class="modal hide fade">\n  <div class="modal-header">\n    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\n    <span class="icon"><i class="fa-solid fa-file-alt"></i></span>\n    <h3 class="modal-title">Notes</h3>\n  </div>\n  <div class="modal-body">\n    ');
            __line = 10;
            if (ticket_group_external_notes) {
                __line = 12;
                __append("\n    <h5>Public</h5>\n    <p>");
                __line = 14;
                __append(escapeFn(ticket_group_external_notes));
                __append("</p>\n    ");
                __line = 15;
            }
            if (ticket_group_internal_notes) {
                __line = 19;
                __append("\n    <h5>Private</h5>\n    <p>");
                __line = 21;
                __append(escapeFn(ticket_group_internal_notes));
                __append("</p>\n    ");
                __line = 22;
            }
            if (ticket_group_broker_notes) {
                __line = 26;
                __append("\n    <h5>Broker</h5>\n    <p>");
                __line = 28;
                __append(escapeFn(ticket_group_broker_notes));
                __append("</p>\n    ");
                __line = 29;
            }
            __line = 31;
            __append('\n\n  </div>\n  <div class="modal-footer">\n    <a href="#" class="btn" data-dismiss="modal">Close</a>\n  </div>\n</div>\n');
            __line = 38;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}