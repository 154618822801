template = require('./payment.ejs')
dropin = require('braintree-web-drop-in');
DETAILS_TEMPLATES               = {}
DETAILS_TEMPLATES[C.Cash]       = require('./payment_details/cash.ejs')
DETAILS_TEMPLATES[C.Check]      = require('./payment_details/check.ejs')
DETAILS_TEMPLATES[C.CreditCard] = require('./payment_details/credit_card.ejs')
DETAILS_TEMPLATES[C.CreditMemo] = require('./payment_details/credit_memo.ejs')
DETAILS_TEMPLATES[C.EvoPay]     = require('./payment_details/evopay.ejs')
DETAILS_TEMPLATES[C.MoneyOrder] = require('./payment_details/money_order.ejs')
DETAILS_TEMPLATES[C.NoPayment]  = require('./payment_details/no_payment.ejs')
DETAILS_TEMPLATES[C.PayPal]     = require('./payment_details/pay_pal.ejs')
DETAILS_TEMPLATES[C.TBDPayment] = require('./payment_details/tbd.ejs')
TermsAndConditionsTemplate = require('./payment_details/cc_terms_and_conditions.ejs')

App.Views.Base.BaseView = require('../base/base_view.coffee')

ALL_PAYMENT_TYPES = {
  all: 'All'
  offline: 'Offline'
  penalty: 'Penalty'
  'return': 'Return'
}
ALL_PAYMENT_TYPES[C.Cash]       = 'Cash'
ALL_PAYMENT_TYPES[C.Check]      = 'Check'
ALL_PAYMENT_TYPES[C.CreditCard] = 'Credit Card'
ALL_PAYMENT_TYPES[C.CreditMemo] = 'Credit Memo'
ALL_PAYMENT_TYPES[C.EvoPay]     = 'EvoPay'
ALL_PAYMENT_TYPES[C.ExternalCreditCard] = 'External Credit Card'
ALL_PAYMENT_TYPES[C.MoneyOrder] = 'Money Order'
ALL_PAYMENT_TYPES[C.NoPayment]  = 'No Payment'
ALL_PAYMENT_TYPES[C.PayPal]     = 'PayPal'
ALL_PAYMENT_TYPES[C.TBDPayment] = 'TBD'

parent = App.Views.Base.BaseView.prototype
module.exports = App.Views.BuySell.Payment = App.Views.Base.BaseView.extend

  template: template

  initialize: (options = {}) ->
    {
      @$container
      @action
      @disabledTypes
      @isRefund
      @order
      @payingPatron
    } = options
    @disabledTypes ||= {}
    if (@order)
      @listenTo(@order, 'change:_total', _.bind(@onOrderTotalChange, @))
    @render()

  # VIEW EVENTS ////////////////////////////////////////////////////////////////
  events:
    'change .paymentType':              'onPaymentTypeChange'
    'click #addEvoPayFundsButton':      'onAddEvoPayFundsButtonClick'
    'keyup .evoPayAddAmount':           'onEvoPayAddAmountChange'
    'click .termsAndConditionsButton':  'onTermsAndConditionsButtonClick'


  onPaymentTypeChange: (e) ->
    type = @$('.paymentType').val()
    @switchType(type)
    @trigger(C.Events.PaymentTypeChanged, type)

  onTermsAndConditionsButtonClick: (e) ->
    @termsAndConditionsModal = new App.Views.Shared.BasicModal({
      header: 'Terms and conditions'
      message: TermsAndConditionsTemplate()
    })

  onAddEvoPayFundsButtonClick: (e) ->
    @loadingBox.startLoad()
    @formErrors.reset()
    App.ErrorManager.ignoreNext(422)
    isAgreed = @$('.evoPayTermsCheckbox').is(':checked')
    if (isAgreed)
      attrs = {
        _amount:        @$('.evoPayAddAmount').val()
        _creditCardId:  @$('.selectedCardId').val()
        _type:          'deposit'
      }
      evoPayTransaction = new App.Models.V3.EvoPayTransaction(attrs, {
        mapping: C.Mappings.Direct.EvoPayTransactions.EvoPayTransaction
      })
      evoPayTransaction.savePromise()
      .then (model) =>
        App.ErrorManager.resetTolerance(422)
        @onEvoPaySelected(C.EvoPay)
        @loadingBox.stopLoad()
      .fail (errors) =>
        @formErrors.show({
          title: "Unable to charge credit card."
          errors
        })
        @loadingBox.stopLoad()
        App.ErrorManager.resetTolerance(422)
      .done()
    else
      @formErrors.show({
        title: "Please agree to the terms & conditions."
        message: "You must check the box to affirm you have read the terms & conditions and consent to them."
      })

  onEvoPayAddAmountChange: (e) ->
    amount = parseFloat(@$('.evoPayAddAmount').val())
    fee = amount * .03
    @$('.evoPayAddFee').val(App.Utils.valueToCurrencyNo$(fee))
    @$('.evoPayAddTotal').val(App.Utils.valueToCurrencyNo$(fee + amount))
  #/////////////////////////////////////////////////////////////////////////////

  render: () ->
    ALLOWED_PAYMENT_TYPES = {}

    # Buy specific options.
    if (@action == C.BuySellActions.Buy)
      if (SESSION.role.can(App.Access.Resources.PaymentTypes.EvoPay))
        ALLOWED_PAYMENT_TYPES.evopay = ALL_PAYMENT_TYPES.evopay
      if (SESSION.role.can(App.Access.Resources.PaymentTypes.BuyWithCreditCard))
        ALLOWED_PAYMENT_TYPES.credit_card = ALL_PAYMENT_TYPES.credit_card

    # Sell specific options.
    if (@action == C.BuySellActions.Sell)
      if (SESSION.role.can(App.Access.Resources.PaymentTypes.CreditCard))
        ALLOWED_PAYMENT_TYPES.credit_card = ALL_PAYMENT_TYPES.credit_card
      if (SESSION.role.can(App.Access.Resources.PaymentTypes.Cash))
        ALLOWED_PAYMENT_TYPES.cash = ALL_PAYMENT_TYPES.cash
      if (SESSION.role.can(App.Access.Resources.PaymentTypes.Check))
        ALLOWED_PAYMENT_TYPES.check = ALL_PAYMENT_TYPES.check
      if (SESSION.role.can(App.Access.Resources.PaymentTypes.MoneyOrder))
        ALLOWED_PAYMENT_TYPES.money_order = ALL_PAYMENT_TYPES.money_order
      if (SESSION.role.can(App.Access.Resources.PaymentTypes.PayPal))
        ALLOWED_PAYMENT_TYPES.pay_pal = ALL_PAYMENT_TYPES.pay_pal
      if (SESSION.role.can(App.Access.Resources.PaymentTypes.TBD))
        ALLOWED_PAYMENT_TYPES.tbd = ALL_PAYMENT_TYPES.tbd

#    if (SESSION.role.can(App.Access.Resources.PaymentTypes.CreditMemo))
#      ALLOWED_PAYMENT_TYPES.credit_memo = ALL_PAYMENT_TYPES.credit_memo
#    if (SESSION.role.can(App.Access.Resources.PaymentTypes.NoPayment))
#      ALLOWED_PAYMENT_TYPES.no_payment = ALL_PAYMENT_TYPES.no_payment

    @allowedOptions = ALLOWED_PAYMENT_TYPES

    for own type, val of @disabledTypes
      delete @allowedOptions[type]

    @blockedOptions = {}

    data = {
      @allowedOptions
      @blockedOptions
    }
    @$container.html(
      @$el.html(
        @template(data)
      )
    )
    @delegateEvents()
    @loadingBox = new App.Utils.LoadingBox(@$container)
    @formErrors = new App.Utils.FormErrorManager(@$('.formErrors'))

    @onPaymentTypeChange()
    return @$el

  # RENDERING DETAILS ////////////////////////////////////////////////////////
  switchType: (type) ->
    @loadingBox.startLoad()
    @formErrors.reset()

    switch type
      when C.Cash
        @renderDetails(type)
      when C.Check
        @renderDetails(type)
      when C.CreditCard
        @renderDetails(type)
        @onCreditSelected()
      when C.CreditMemo
        @renderDetails(type)
      when C.EvoPay
        @onEvoPaySelected(type)
      when C.MoneyOrder
        @renderDetails(type)
      when C.NoPayment
        @renderDetails(type)
      when C.PayPal
        @renderDetails(type)
      when C.TBDPayment
        @renderDetails(type)
      else @renderDetails(null)

  onCreditSelected: () ->
    @loadingBox.startLoad()
    braintree_customer_id = "#{@payingPatron.type}-#{@payingPatron.id}"
    fetch("/braintree/client_token/#{braintree_customer_id}?" + new URLSearchParams({
      action: @action,
    }))
      .then (response) =>
        if (!response.ok)
          throw new Error('Braintree network response was not ok')
        return response.json();
      .then (data) =>
        @renderBraintreeDropIn(data.clientToken)
      .catch (error) =>
        @loadingBox.stopLoad()
        @formErrors.show({
          title: "Sorry, unable to fetch payment method"
          message: "Please refresh and try again or choose a different payment method."
        })

  showServiceFeeTooltip: () ->
    @$('.buyServiceFeeContainer').show()
    @$('#serviceFeeTooltip').tooltip({
      placement: 'bottom'
      html: true
      title: "<span style=\'font-size: 14px;\'>Credit Card purchases incur a service fee of #{C.SERVICE_FEE_PRECENTAGE * 100}%. EvoPay purchases do not include any fees.</span>"
    })

  onEvoPaySelected: (type) ->
# For when we allow adding evopay funds with credit card.
#      Q.all([
#        @payingPatron.fetchEvoPayBalancePromise()
#        @payingPatron.fetchBrokerageCreditCardsPromise()
#      ])
#      .spread (evoPayBalanceResult, brokerageCreditCardsResult) =>
#        @renderDetails(type)
#        @onEvoPayAddAmountChange()
#      .done()
    @payingPatron.fetchEvoPayBalancePromise()
    .then (evoPayBalanceResult) =>
      @renderDetails(type)
      @onEvoPayAddAmountChange()
    .done()

  # Update the EvoPay math if shipping price changes.
  onOrderTotalChange: (e) ->
    type = @$('.paymentType').val()
    if (type == C.EvoPay)
      @renderDetails(type)

  renderDetails: (type) ->
    $container = @$('.paymentDetailsContainer')

    if (type)
      template = DETAILS_TEMPLATES[type]
      data = {
        @action
        @isRefund
        order: @order?.toPresenterJSON()
        patron: @payingPatron.toPresenterJSON()
      }
      $container.html(
        template(data)
      )
    else
      $container.empty()
      @formErrors.show({
        title: "No payment types configured."
        message: "Your account has no payment methods configured."
      })

    @loadingBox.stopLoad()
    @$('.actLikeCurrency').formatCurrency()
    @delegateEvents()

  #///////////////////////////////////////////////////////////////////////////

  getPaymentPromise: (paymentAttrs) ->

    # https://github.com/kriskowal/q/wiki/API-Reference
    formData = @$el.serializeObject()
    type = formData.paymentType
    paymentModel = new App.Models.V3.Payment()
    paymentAttrs._type = type

    if (type == C.Cash)
      paymentAttrs._isComplete = !!(formData.isComplete)

    if (type == C.Check)
      paymentAttrs._checkNumber = formData.checkNumber
      paymentAttrs._isComplete = !!(formData.isComplete)

    if (type == C.CreditCard)
      if (@braintreeDropinInstance)
        payload = await @braintreeDropinInstance.requestPaymentMethod()
        paymentAttrs._payment_method_nonce = payload.nonce

    if (type == C.CreditMemo)
      paymentAttrs._isComplete = !!(formData.isComplete)

    if (type == C.EvoPay)
      paymentAttrs._isComplete = !!(formData.isComplete)

    if (type == C.ExternalCreditCard)
      $.noop()

    if (type == C.MoneyOrder)
      paymentAttrs._checkNumber = formData.checkNumber
      paymentAttrs._isComplete = !!(formData.isComplete)

    if (type == C.NoPayment)
      $.noop()

    if (type == C.PayPal)
      paymentAttrs._isComplete = !!(formData.isComplete)
      paymentAttrs._transactionId = formData.transactionId

    if (type == C.TBDPayment)
      $.noop()

    paymentModel.set(paymentAttrs)
    if (paymentModel.isValid(true))
      return Q(paymentModel)

    else
      # paymentModel.errors._creditCard is an object.  Un-nest it.
      errors = App.Utils.flattenObject(paymentModel.errors)
      @formErrors.show({
        title:  "Payment error."
        errors
      })
      return Q.reject(errors)

  renderBraintreeDropIn: (authorization) ->
    # Need to specify because two different containers could be used in Buy/Sell for "Purchase" and "Sale"
    htmlContainer = @$container[0] # Convert to JavaScript from JQuery
    dropInDiv = htmlContainer.querySelector('.braintreeDropIn')

    dropin.create({
      authorization: authorization,
      selector: dropInDiv,
      vaultManager: true,
      card: {
        cardholderName: {
          required: true
        }
      },
      translations: {
        chooseAnotherWayToPay: 'Add a new card'
      }
    }).then (dropinInstance) =>
      @braintreeDropinInstance = dropinInstance

      @loadingBox.stopLoad()
      dropInDiv.style.display = 'block'
      if(@action == C.BuySellActions.Buy)
        @showServiceFeeTooltip()
