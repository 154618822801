App.Collections.Base.PaginatedBase = require('../base/paginated_base_collection.coffee')
App.Models.V3.PaymentOverview = require('../../models/v3/payment_overview_model.coffee')

parent = App.Collections.Base.PaginatedBase.prototype
module.exports = App.Collections.V3.PaymentOverviews = App.Collections.Base.PaginatedBase.extend

  model: App.Models.V3.PaymentOverview

  url: '/api_direct/v9/payments/status'

  initialize: (models, options = {}) ->
    parent.initialize.call(@, models, options)

  parse: (response, options) ->
    return response.payments