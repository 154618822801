module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<div class="form-horizontal">\n\n  <% if (action == C.BuySellActions.Sell) { %>\n  <div class="control-group">\n    <label class="control-label">\n    </label>\n    <div class="controls">\n      <label>\n        <input type="checkbox" name="isComplete" style="margin-right: 10px; margin-top: -3px;"/>\n        Is this payment complete?\n      </label>\n    </div>\n  </div>\n  <% } %>\n\n</div>', __filename = "js/views/buy_sell/payment_details/cash.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            __append('<div class="form-horizontal">\n\n  ');
            __line = 3;
            if (action == C.BuySellActions.Sell) {
                __append('\n  <div class="control-group">\n    <label class="control-label">\n    </label>\n    <div class="controls">\n      <label>\n        <input type="checkbox" name="isComplete" style="margin-right: 10px; margin-top: -3px;"/>\n        Is this payment complete?\n      </label>\n    </div>\n  </div>\n  ');
                __line = 14;
            }
            __append("\n\n</div>");
            __line = 16;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}