App.Models.Base.BaseModel = require('../base/base_model.coffee')

parent = App.Models.Base.BaseModel.prototype
module.exports = App.Models.V2.TicketGroup = App.Models.Base.BaseModel.extend({

  urlRoot: () ->
    return "/api_direct/v9/ticket_groups/"

  initialize: (attributes, options) ->
    parent.initialize.call(this, attributes, options)
    # OLD FIELDS
    @set('seat_range', @seatRangeStrict())
    @set('eventId', @get('_event')?.get('_id'))

  filterByStateJSON: (args) ->
    {state, json} = args
    seats  = json.ticket_group_ticket_seats
    states = json.ticket_group_ticket_states
    ids    = json.ticket_group_ticket_ids
    _.each states, (el, index) ->
      if (el != state)
        # We'll remove falsey vals usiing _.compact
        states[index] = false
        seats[index]  = false
        ids[index]    = false
    seats                           = _.compact(seats)
    states                          = _.compact(states)
    ids                             = _.compact(ids)
    count                           = _.size(seats)
    json.ticket_group_ticket_seats  = seats
    json.ticket_group_ticket_ids    = ids
    json.ticket_group_ticket_states = states
    json.ticket_group_tickets_count = count
    return json


  updateStandardAttributes: (json = @attributes) ->
    @set(json)
    attrs = {}

    if (@getMapping() == C.Mappings.TicketGroupEndpoint.TicketGroup)
      # This field is required to sort list view.
      attrs._brokerageAbbreviation = json.office?.brokerage?.abbreviation

      attrs._consignment = json.consignment
      attrs._consignmentCost = json.consignment_cost
      attrs._createdAt = Date.parse(json.created_at)
      # No longer coming - switched to lightweight endpoint.
      #attrs._event = new App.Models.V2.Event(json.event, {
      #  mapping: C.Mappings.TicketGroupEndpoint.Event
      #})
      attrs._evopayDiscount = json.evopay_discount
      attrs._evopayDiscountPrice = json.evopay_discount_price
      attrs._format = json.format
      attrs._freshness = json.freshness
      attrs._hasSpecTickets = json.has_spec_tickets
      attrs._id = json.id
      attrs._inHandOn = json.in_hand_on
      attrs._isBroadcast = @isBroadcast()
      attrs._isEticket = json.eticket
      attrs._isFeatured = json.featured
      attrs._isInHand = json.in_hand
      attrs._isWheelchair = json.wheelchair
      attrs._notesBroker = json.exchange_notes
      attrs._notesPublic = json.public_notes
      attrs._notesPrivate = json.private_notes
      attrs._office = new App.Models.V2.Office(json.office, {
        mapping: C.Mappings.TicketGroupEndpoint.Office
      })
      attrs._priceFace = json.face_value
      attrs._priceRetail = json.retail_price
      attrs._priceRetailOverride = json.retail_price_override
      attrs._priceWholesale = json.wholesale_price
      attrs._quantity = json.quantity
      attrs._row = json.row
      attrs._seats = json.seats
      attrs._section = json.section
      attrs._signature = json.signature
      attrs._splits = json.splits
      attrs._ticketCosts = json.ticket_costs
      attrs._ticketHoldIds = json.ticket_hold_ids
      attrs._ticketList = json.ticket_list
      attrs._ticketPOIds = json.ticket_purchase_order_ids
      attrs._ticketStates = json.ticket_states
      attrs._type = json.type
      attrs._updatedAt = Date.parse(json.updated_at)
      attrs._url = json.url
      attrs._viewType = json.view_type
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.OrderEndpoint.TicketGroup)
      attrs._brokerageId = json.brokerage_id
      attrs._event = new App.Models.V2.Event(json.event, {
        mapping: C.Mappings.OrderEndpoint.Event
      })
      attrs._format = json.format
      attrs._id = json.id
      attrs._inHandOn = json.in_hand_on
      attrs._isInHand = !!json.in_hand
      attrs._notesPublic = json.external_notes
      attrs._officeId = json.office_id
      attrs._priceRetail = json.retail_price
      attrs._priceWholesale = json.wholesale_price
      attrs._quantity = json.quantity
      attrs._remoteId = json.remote_id
      attrs._row = json.row
      attrs._seats = json.seats
      attrs._section = json.section
      attrs._url = json.url
      attrs._tickets = new App.Collections.V3.Tickets(json.tickets, {
        mapping: C.Mappings.OrderEndpoint.Tickets
      })
      #tickets: [{purchase_order_id:null, seat:null}, {purchase_order_id:null, seat:null},…]
      # COMPLETE - KEEP THIS COMMENT

    if (@getMapping() == C.Mappings.MassIndexEndpoint.TicketGroup)
      eventAttrs = _.pick(json,
        'event_id',
        'event_name',
        'event_notes',
        'event_occurs_at',
        'event_occurs_local',
        'event_popularity_score',
        'event_short_term_popularity_score',
        'event_state',
        'event_tbd'
      )
      venueAttrs = _.pick(json,
        'venue_address_company',
        'venue_address_country_code',
        'venue_address_extended_address',
        'venue_address_id',
        'venue_address_label',
        'venue_address_locality',
        'venue_address_name',
        'venue_address_postal_code',
        'venue_address_region',
        'venue_address_street_address',
        'venue_id',
        'venue_name'
      )
      _.extend(eventAttrs, venueAttrs)
      attrs._brokerageId = json.ticket_group_brokerage_id
      attrs._canOverrideSplits = json.ticket_group_split_override
      attrs._consignment = json.ticket_group_consignment
      attrs._cost = json.ticket_group_cost
      attrs._costPerTicket = json.ticket_group_cost / json.ticket_group_tickets_count
      attrs._createdAt = Date.parse(json.ticket_group_created_at)
      attrs._event = new App.Models.V2.Event(eventAttrs, {
        mapping: C.Mappings.MassIndexEndpoint.Event
      })
      attrs._format = json.ticket_group_format
      attrs._hasClubAccess = json.ticket_group_club_access
      attrs._hasSpecTickets = json.ticket_group_speculative
      attrs._id = json.ticket_group_id
      attrs._isBroadcast = @isBroadcast()
      attrs._isEticket = json.ticket_group_eticket
      attrs._inHandOn = json.ticket_group_in_hand_on
      attrs._isInHand = (json.ticket_group_in_hand == 't' || json.ticket_group_in_hand == true)
      attrs._notesBroker = json.ticket_group_broker_notes
      attrs._notesPublic = json.ticket_group_external_notes
      attrs._notesPrivate = json.ticket_group_internal_notes
      attrs._officeId = json.ticket_group_office_id
      attrs._priceFace = json.ticket_group_face_value
      attrs._priceRetail = json.ticket_group_retail_price_override
      attrs._priceRetailPerTicket = json.ticket_group_retail_price_override / json.ticket_group_tickets_count
      attrs._priceWholesale = json.ticket_group_price
      attrs._priceWholesalePerTicket = json.ticket_group_price / json.ticket_group_tickets_count
      attrs._quantity = json.ticket_group_tickets_count
      attrs._row = json.ticket_group_row
      attrs._seats = json.ticket_group_ticket_seats
      attrs._section = json.ticket_group_section
      attrs._ticketIds = json.ticket_group_ticket_ids
      attrs._ticketStates = json.ticket_group_ticket_states
      attrs._ticketsCost = json.tickets_cost
      attrs._ticketsSalePrice = json.tickets_sale_price
      attrs._ticketsSalePricePerTicket = json.tickets_sale_price / json.ticket_group_tickets_count
      attrs._ticketsState = json.tickets_state
      attrs._type = json.ticket_group_type
      attrs._viewType = json.ticket_group_view_type


    @set(attrs)

  # PREVENT toPresenterJSON from choking on nulls.
  defaults:
    ###
    _buyer: new App.Models.V2.Patron(null, {
      mapping: C.Mappings.OrderEndpoint.Patron
    })
    _office: new App.Models.V2.Office(null, {
      mapping: C.Mappings.TicketGroupEndpoint.Office
    })
    ###
    _ticketStates:
      available: 0
      consigned: 0

# PRESENTATION /////////////////////////////////////////////////////////////

  toPresenterJSON: (args = {}) ->
    {state} = args
    parent.toPresenterJSON.call(this)
    presented = _.extend(@toJSON(), {

      broadcastCSS:             @broadcastCSS()
      buyer:                    @get('_buyer')?.toPresenterJSON()
      canWaste:                 @canWaste()
      cost:                     App.Utils.valueToCurrency(@get('_cost'))
      costPerTicket:            App.Utils.valueToCurrency(@get('_costPerTicket'))
      createdAt:                @createdAt()
      event:                    @get('_event')?.toPresenterJSON()
      flags:                    @flags()
      format:                   @format()
      freshness:                @get('_freshness')
      id:                       @get('_id')
      inHand:                   @inHand()
      isBroadcast:              @isBroadcast()
      isOwned:                  @isOwned()
      myBrokerageId:            SESSION.brokerage_id
      office:                   @get('_office')?.toPresenterJSON()
      order:                    @get('_order')?.toPresenterJSON()
      orderItem:                @get('_orderItem')?.toPresenterJSON()
      quantity:                 @get('_quantity')
      quantityFromSeats:        @quantityFromSeats()
      quantitySeparated:        @quantitySeparated()
      retailPrice:              App.Utils.valueToCurrency(@get('_priceRetail'))
      retailPricePerTicket:     App.Utils.valueToCurrency(@get('_priceRetailPerTicket'))
      row:                      @get('_row')
      salePrice:                App.Utils.valueToCurrency(@get('_ticketsSalePrice'))
      salePricePerTicket:       App.Utils.valueToCurrency(@get('_ticketsSalePricePerTicket'))
      seats:                    @seats()
      seatRange:                @seatRangeStrict()
      section:                  @get('_section')
      seller:                   @get('_seller')
      soldQuantity:             @soldQuantity()
      soldSeatRange:            @soldSeatRange()
      tickets:                  @get('_tickets')?.toPresenterJSON()
      type:                     C.Options.TicketGroup.Types[@get('_type')]
      uniqueStates:             @uniqueTicketStates()
      viewType:                 @get('_viewType')
      wholesalePrice:           App.Utils.valueToCurrency(@get('_priceWholesale'))
      wholesalePricePerTicket:  App.Utils.valueToCurrency(@get('_priceWholesalePerTicket'))
    })
    presented = @filterByStateJSON({json: presented, state: state}) if state
    return presented

  broadcastCSS: () ->
    if (@isOwned())
      if (@isBroadcast())
        return 'success'
      else
        return 'error'
    return ''

  toExportColumns: ->
    json = @toPresenterJSON()
    [
      json.event_name
      App.Utils.makeTimestampHumanForEvent(json.event_occurs_at, C.DateFormats.TableDateWithTime)
      App.Utils.makeTimestampHuman(json.createdDate, C.DateFormats.TableDateWithTime)
      json.venue_name
      json.venue_address_locality
      json.venue_address_region
      json.type
      json.quantity
      json.uniqueStates
      json.section
      json.row
      json.seats
      json.salePricePerTicket
      json.costPerTicket
      json.wholesalePricePerTicket
      json.retailPricePerTicket
      json.flags
      json.viewType
    ]

  canWaste: () ->
    return (@get('_ticketStates').available || @get('_ticketStates').consigned)

  createdAt: () ->
    return @get('_createdAt') || @get('_order')?.get('_createdAt')

  flags: () ->
    flags = ''
    if (@get('_hasSpecTickets'))
      flags += C.FlagBadges.Spec
    if (@get('_notesBroker') || @get('_notesPrivate') || @get('_notesPublic'))
      flags += C.FlagBadges.Notes
    if (@get('_consignment'))
      if (_.contains(@get('_consignment'), 't'))
        flags += C.FlagBadges.Consignment
    if (@get('_format') == C.TicketFormats.Eticket)
      flags += C.FlagBadges.Eticket()
    if (@get('_isInHand'))
      flags += C.FlagBadges.InHand()
    else
      if (@get('_inHandOn'))
        flags += C.FlagBadges.InHandOn(@get('_inHandOn'))
      else
        flags += C.FlagBadges.NotInHand
    return flags

  inHand: () ->
    if (@get('_isInHand'))
      return C.FlagBadges.Yes
    if (@get('_inHandOn'))
      return App.Utils.makeTimestampHuman(@get('_inHandOn'), C.DateFormats.TableDate)
    return C.FlagBadges.No

  isBroadcast: () ->
    if (@getMapping() == C.Mappings.TicketGroupEndpoint.TicketGroup)
      broadcast = @get('broadcast')
    if (@getMapping() == C.Mappings.MassIndexEndpoint.TicketGroup)
      broadcast = @get('ticket_group_broadcast')
    return broadcast == 't' || (broadcast != 'f' && broadcast == true)

  isOwned: () ->
    if (@officeId() == SESSION.office_id)
      return true
    else
      return false

  officeId: () ->
    return @get('_officeId') || @get('_office').id

  quantityFromSeats: () ->
    if (@get('_ticketIds'))
      return @get('_ticketIds').length
    return 0

  quantitySeparated: () ->
    quantity = @get('_quantity')
    held = if @get('_ticketStates') then @get('_ticketStates').held else 0
    held ||= 0
    reserved = if @get('_ticketStates') then @get('_ticketStates').reserved else 0
    reserved ||= 0
    heldReserved = held + reserved
    sold = if @get('_ticketStates') then @get('_ticketStates').sold else 0
    sold ||= 0
    quantity = quantity - heldReserved - sold
    if held
      return quantity + "(#{held})"
    else
      return quantity

  seats: () ->
    return App.Utils.seatsToSeatRange(@get('_seats'))

  seatRangeStrict: () ->
    if (!@get('_ticketList'))
      return @getSeatRange()
    availableSeats = _.pluck(
      _.filter(
        @get('_ticketList'),
        @ticketIsAvailable
      ),
      'seat'
    )
    availableSeats = availableSeats.sort((a, b) -> return a - b )
    return App.Utils.seatsToSeatRange(availableSeats)

  getSeatRange: () ->
    if (@get('_seats') && @get('_seats').length) || @get('_lowSeat')
      if (@get('_seatOrder'))
        multiplier = if (@get('_seatOrder') == C.SeatOrder.Consecutive) then 1 else 2
      else
        multiplier = 1

      if (_.isFinite(+@get('_lowSeat')))
        lowSeat = +@get('_lowSeat')
      else
        lowSeat = _.sortBy(@get('_seats'), (seat) ->
          return +seat
        )[0]

      quantity = +@get('_quantity')
      quantity = if (quantity == 0) then @get('_seats').length else quantity
      highSeat = lowSeat + (quantity - 1) * multiplier
      return "#{lowSeat} - #{highSeat}"
    else
      return 'N/A'

  soldQuantity: () ->
    return @soldSeats().length

  soldSeats: () ->
    ticketList = @get('_ticketList')
    soldSeats = []
    if (ticketList)
      for ticket in ticketList
        if (ticket.state == 'sold')
          soldSeats.push(ticket.seat)
    return soldSeats

  soldSeatRange: () ->
    return App.Utils.seatsToSeatRange(@soldSeats()).join(', ')

  ticketIsAvailable: (ticket) ->
    currentDate  = new Date()
    notAvailable = (ticket.state != 'available')
    noSeat = (ticket.seat == 0)
    holdDate = new Date(ticket.hold_until + 'Z')
    onHold = (ticket.hold_id != 0) && (holdDate >= currentDate)
    return !(notAvailable || noSeat || onHold)

  uniqueTicketStates: () ->
    states = @get('_ticketStates')
    states = _.map(states, (state) ->
      return C.Options.TicketGroup.States[state]
    )
    states = _.uniq(states)
    if (states.length == 1)
      return states
    return states.join(', ')
  #///////////////////////////////////////////////////////////////////////////

  format: () ->
    if (@get('_format') == C.TicketFormats.Eticket)
      return "Eticket"
    if (@get('_format') == C.TicketFormats.Physical)
      return "Physical"
    if (@get('_format') == C.TicketFormats.FlashSeats)
      return "Flash Seats"
    if (@get('_format') == C.TicketFormats.Paperless)
      return "Paperless"
    if (@get('_format') == C.TicketFormats.GuestList)
      return "Guest List"
    if (@get('_format') == C.TicketFormats.TMMobile)
      return "TM Mobile"

  # TODO: STANDARDIZE THIS TO USE CALLBACKS NOT DEFERRED
  fetchHolds: () ->
    collection = new App.Collections.V2.TicketHolds(null, {
      eventId: @get('eventId')
      ticketGroupId: @get('id')
      mapping: C.Mappings.HeldTicketEndpoint.TicketHolds
    })
    deferred = collection.fetch({
      success: () =>
        @set('ticketHolds', collection)
    })
    return deferred

  hold: (holdData, callback) ->
    url = @url() + '/hold'
    @holdOrTake(holdData, url, callback)

  # STANDARD AJAX
  holdOrTake: (data, url, callback) ->
    params = {}
    params.low_seat =      data.lowSeat
    params.quantity =      data.quantity
    params.held_for_id =   data.heldForId
    params.held_for_type = data.heldForType
    params.hold_until =    data.holdUntil
    params.price =         data.price
    params.notes =         data.notes
    $.ajax({
      type: 'POST'
      url: url
      data: JSON.stringify(params)
      success: (data, status, xhr) =>
        # Save event
        data._event = @get('_event')
        @clear()
        @set(data)
        callback(null, @)
      error: (xhr, status, error) =>
        console.error(xhr, status, error)
        callback(new Error('Error performing hold or take.'))
    })

  take: (takeData, callback) ->
    url = @url() + '/take'
    @holdOrTake(takeData, url, callback)

  # STANDARD AJAX
  toggleBroadcast: (callback) ->
    url = @url() + '/broadcast'
    $.ajax({
      type: 'POST'
      url: url
      data: JSON.stringify({broadcast: !@isBroadcast()})
      success: (data, status, xhr) =>
        @clear()
        @set(data)
        callback(null, @)
      error: (xhr, status, error) =>
        console.error(xhr, status, error)
        callback(new Error('Error changing broadcast state.'))
    })

  # STANDARD AJAX
  waste: (reason, callback) ->
    url = @url() + '/waste'
    $.ajax({
      type: 'POST'
      url: url
      data: JSON.stringify({wasted_reason: reason})
      success: (data, status, xhr) =>
        @clear()
        @set(data)
        callback(null, @)
      error: (xhr, status, error) =>
        console.error(xhr, status, error)
        callback(new Error('Error wasting tickets.'))
    })

}, {
  # STATICS
  defaultMapping: C.Mappings.TicketGroupEndpoint.TicketGroup
})
