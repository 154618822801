module.exports = function anonymous(locals, escapeFn, include, rethrow) {
    rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc) {
        var lines = str.split("\n");
        var start = Math.max(lineno - 3, 0);
        var end = Math.min(lines.length, lineno + 3);
        var filename = esc(flnm);
        var context = lines.slice(start, end).map(function(line, i) {
            var curr = i + start + 1;
            return (curr == lineno ? " >> " : "    ") + curr + "| " + line;
        }).join("\n");
        err.path = filename;
        err.message = (filename || "ejs") + ":" + lineno + "\n" + context + "\n\n" + err.message;
        throw err;
    };
    escapeFn = escapeFn || function(markup) {
        return markup == undefined ? "" : String(markup).replace(_MATCH_HTML, encode_char);
    };
    var _ENCODE_HTML_RULES = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&#34;",
        "'": "&#39;"
    }, _MATCH_HTML = /[&<>'"]/g;
    function encode_char(c) {
        return _ENCODE_HTML_RULES[c] || c;
    }
    var __line = 1, __lines = '<%\nif (!_.size(deliveries)) {\n%>\n<h5 style="width: 100%; text-align:center; line-height: 150px;">Please use the filter to find deliveries.</h5>\n<%\n} else {\n%>\n<table id="delivery_table" class="table table-bordered table-striped table-hover data-table">\n\t<thead>\n\t\t<tr>\n\t\t\t<th>ID</th>\n\t\t\t<th>Type</th>\n\t\t\t<th>Recipient</th>\n      <th>Customer</th>\n\t\t\t<th>State</th>\n\t\t\t<th>First Event</th>\n\t\t\t<th>Order #</th>\n      <th>Order Creation Date</th>\n\t\t</tr>\n\t</thead>\n\t<tbody>\n<%\n  _.each(deliveries, function(item, i){\n%>\n\t\t<tr>\n\t\t\t<td><%= item.id %></td>\n\t\t\t<td><%= C.Options.Delivery.Types[item.type] %></td>\n\t\t\t<td><%= item.shipToName %></td>\n      <td><%= item.customerName %></td>\n      <td><%= item.state %></td>\n\t\t\t<td><%= App.Utils.makeTimestampHuman(item.firstEventOccursAt, C.DateFormats.TableDate) %></td>\n\t\t\t<td><a href="/order/<%= item.orderLinkId %>"><%= item.orderLinkId %></a></td>\n      <td><%= App.Utils.makeTimestampHuman(item.orderCreatedAt, C.DateFormats.TableDate) %></td>\n\t\t</tr>\n<%\n  });\n%>\n\t</tbody>\n</table>\n<%\n}\n%>\n', __filename = "js/views/deliveries/delivery_table.ejs";
    try {
        var __output = "";
        function __append(s) {
            if (s !== undefined && s !== null) __output += s;
        }
        with (locals || {}) {
            if (!_.size(deliveries)) {
                __line = 3;
                __append('\n<h5 style="width: 100%; text-align:center; line-height: 150px;">Please use the filter to find deliveries.</h5>\n');
                __line = 5;
            } else {
                __line = 7;
                __append('\n<table id="delivery_table" class="table table-bordered table-striped table-hover data-table">\n\t<thead>\n\t\t<tr>\n\t\t\t<th>ID</th>\n\t\t\t<th>Type</th>\n\t\t\t<th>Recipient</th>\n      <th>Customer</th>\n\t\t\t<th>State</th>\n\t\t\t<th>First Event</th>\n\t\t\t<th>Order #</th>\n      <th>Order Creation Date</th>\n\t\t</tr>\n\t</thead>\n\t<tbody>\n');
                __line = 22;
                _.each(deliveries, function(item, i) {
                    __line = 24;
                    __append("\n\t\t<tr>\n\t\t\t<td>");
                    __line = 26;
                    __append(escapeFn(item.id));
                    __append("</td>\n\t\t\t<td>");
                    __line = 27;
                    __append(escapeFn(C.Options.Delivery.Types[item.type]));
                    __append("</td>\n\t\t\t<td>");
                    __line = 28;
                    __append(escapeFn(item.shipToName));
                    __append("</td>\n      <td>");
                    __line = 29;
                    __append(escapeFn(item.customerName));
                    __append("</td>\n      <td>");
                    __line = 30;
                    __append(escapeFn(item.state));
                    __append("</td>\n\t\t\t<td>");
                    __line = 31;
                    __append(escapeFn(App.Utils.makeTimestampHuman(item.firstEventOccursAt, C.DateFormats.TableDate)));
                    __append('</td>\n\t\t\t<td><a href="/order/');
                    __line = 32;
                    __append(escapeFn(item.orderLinkId));
                    __append('">');
                    __append(escapeFn(item.orderLinkId));
                    __append("</a></td>\n      <td>");
                    __line = 33;
                    __append(escapeFn(App.Utils.makeTimestampHuman(item.orderCreatedAt, C.DateFormats.TableDate)));
                    __append("</td>\n\t\t</tr>\n");
                    __line = 35;
                });
                __line = 37;
                __append("\n\t</tbody>\n</table>\n");
                __line = 40;
            }
            __line = 42;
            __append("\n");
            __line = 43;
        }
        return __output;
    } catch (e) {
        rethrow(e, __lines, __filename, __line, escapeFn);
    }
}